<script>
import { defineComponent, h } from 'vue'

export default defineComponent({
  name: 'ObjectiveChartTableColorMark',
  props: {
    color: {
      type: String,
      required: true
    }
  },

  render() {
    return h('div', {
      class: 'ocm-Marker',
      style: { 'background-color': this.color }
    })
  }
})
</script>

<style scoped lang="scss">
.ocm-Marker {
  height: 6px;
  width: 6px;
  border-radius: 50%;
}
</style>
