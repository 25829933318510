<template>
  <div class="db-Loader">
    <SkeletonItem
      v-for="n in count"
      :key="n"
      :appearance="SQUARE"
      border-radius="24px"
      class="db-Loader_Item"
      color="var(--grey-3-next)"
      height="184px"
      width="200px"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'DashboardBoxesLoader'
})
</script>

<script setup>
import { SKELETON_APPEARANCES } from '@/utils/components-configurations/skeleton-item'

import SkeletonItem from '@/components/ui/SkeletonLoaders/SkeletonItem'

defineProps({
  count: {
    type: Number,
    default: 21
  }
})
const { SQUARE } = SKELETON_APPEARANCES
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';

.db-Loader {
  @include dashboard-card-layout();
}
.db-Loader_Item {
  min-width: 100%;
  max-width: 250px;
  flex: 1 1 200px;
}
</style>
