<template>
  <div class="esd-EmptyState">
    <ObjectivesEmptyHero />
    <slot />
  </div>
</template>

<script setup>
import ObjectivesEmptyHero from '@/components/objectives/ObjectivesEmptyHero'

defineOptions({
  name: 'EmptyStateDashboard'
})
</script>

<style lang="scss" scoped>
.esd-EmptyState {
  margin: 100px 40px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: fw('medium');
  font-size: $fs-32;
  line-height: 40px;
  color: $dark-1;
  font-family: $system-ui;
  gap: 49px;
}
</style>
