<template>
  <div
    :class="{ 'am-Wrapper-highlight': showHoverTrigger, 'am-Wrapper-menu-pinned': menuPinned }"
    class="am-Wrapper"
  >
    <div v-if="showHoverTrigger" class="am-Wrapper_Trigger" @mouseenter="onMouseEnter" />
    <AppMenuTrigger :menu-pinned="menuPinned" @toggle-menu="toggleMenu" />

    <div class="am-ContentWrapper">
      <div ref="menuReference" :class="classes">
        <div class="am-Content_Head">
          <AppMenuLogo />
          <AppMenuWorkspaceSelect
            v-if="!hideWorkspaceSelect"
            :disabled="disabled"
            :menu-pinned="menuPinned"
            :menu-showed="menuShowed"
          />

          <AppVerticalScrollGradient :arrived-state="arrivedState" no-border />
        </div>
        <div class="am-Content_Body">
          <AppMenuNav
            v-model:some-additional-menu-opened="someAdditionalMenuOpened"
            :disabled="disabled"
          />
        </div>
        <div v-if="isWebApp || isSalesforceApp" class="am-WebAppMenuContent">
          <UpgradeTierReminder v-if="isOwner && isOverLimitedLicenseStatus" />
          <BillingReminder v-else-if="isLicenseTrialStatus" />
          <AppBannerWrapper v-else />
          <InviteUsersToWorkspaceModal v-if="!isBillingPage">
            <template #trigger="{ onTriggerClick }">
              <AppButton
                class="am-InviteUsersTrigger"
                icon="invite-user"
                type="subtle"
                @click="onTriggerClick"
              >
                {{ $t('users.invite') }}
              </AppButton>
            </template>
          </InviteUsersToWorkspaceModal>
        </div>
        <!--   в слоте передает баннер для джира лицензий, из App.vue     -->
        <slot name="jiraAppLicenseBanner">
          <!--    Если не передается банер, то отображаем дефолтные     -->
          <AppBannerWrapper />
        </slot>

        <AppVerticalScrollGradient :arrived-state="arrivedState" is-bottom-placement />
      </div>

      <div class="am-Content_Footer">
        <AppMenuNav
          v-model:some-additional-menu-opened="someAdditionalMenuOpened"
          :disabled="disabled"
          :layout="APP_MENU_NAV_LAYOUTS.HORIZONTAL"
          :placement="NAV_ITEM_PLACEMENTS.BOTTOM"
          style="--gap: 0"
          @item-click="onMenuItemClick"
        >
          <template #list-prepend>
            <li>
              <AppMenuUserActions
                :menu-pinned="menuPinned"
                :menu-showed="menuShowed"
                @create-organization="isShowCreateOrganizationModal = true"
              />
            </li>
            <li v-if="isPortalHasContent">
              <portal-target :name="BOTTOM_NAV_NOTIFICATIONS_PORTAL_NAME" />
            </li>
          </template>
        </AppMenuNav>
      </div>
    </div>
  </div>
  <SupportModal v-model:show="isShowSupportModal" />

  <portal to="modal-windows">
    <Modal
      :scrollable-wrapper="false"
      :show="isShowReleaseUpdatesModal"
      class="am-ReleaseUpdatesModal"
      manual-close
      size="lg-next"
      @close="onReleaseUpdatesModalClose"
    >
      <template #header>
        <div class="am-ModalHead">
          <AppTitle :level="3" class="am-ModalHead_Title" disable-margin>
            {{ $t('menu.whats_new') }}
          </AppTitle>
        </div>
      </template>

      <template #before-close>
        <a :href="currentChangeLogLinks.SOURCE" class="am-ModalLink" target="_blank">
          <AppIcon height="24" icon-name="shortcut-next" width="24" />
          {{ $t('release_updates.link') }}
        </a>
      </template>
      <template #modal-body>
        <ReleaseUpdatesLoader v-if="isLoading" class="am-ModalBody_Loader" />
        <iframe
          v-show="!isLoading"
          :src="`${currentChangeLogLinks.IFRAME}&timestamp=${timestamp}`"
          class="am-ModalBody_Iframe"
          frameborder="0"
          @load="onIframeLoaded"
        />
      </template>

      <template #modal-footer>
        <div class="am-ModalFooter">
          {{ $t('release_updates.footer_text') }}
          <AppSocialMediaLinks />
        </div>
      </template>
    </Modal>

    <CreateOrganizationModal
      :show="isShowCreateOrganizationModal"
      @close-create-organization="isShowCreateOrganizationModal = false"
    />
  </portal>
</template>

<script>
import { isEmpty, isUndefined } from 'lodash'
import { PortalTarget, Wormhole } from 'portal-vue'
import { defineComponent } from 'vue'
import { mapActions, mapGetters, mapState } from 'vuex'

import { ROUTE_NAMES } from '@/routes/route-helpers'
import {
  APP_MENU_NAV_LAYOUTS,
  BOTTOM_NAV_NOTIFICATIONS_PORTAL_NAME,
  MENU_PINNED_STATUS_LS_KEY,
  NAV_ITEM_PLACEMENTS,
  useAppMenuHelpers
} from '@/utils/app-menu'
import {
  isJiraAppInjectionKey,
  isSalesforceAppInjectionKey,
  isWebAppInjectionKey
} from '@/utils/injection-keys'
import { LICENSE_TYPES } from '@/utils/license-types'
import { RELEASE_UPDATES_MENU_ITEM_NAME, SUPPORT_MENU_ITEM_NAME } from '@/utils/menu-items'
import { updateStorageByKey } from '@/utils/persist'
import { getCurrentPlatformChangelogLinks } from '@/utils/product-update'
import {
  DEFAULT_USERS_SETTINGS,
  RELEASE_UPDATES_MODAL_ID,
  USER_SETTINGS_MAPPER
} from '@/utils/user-settings'
import { PLUGIN_OPTIONS_KEYS } from '@root/template-options-keys'
import CreateOrganizationModal from '@shared-modules/components/modals/CreateOrganizationModal'
import InviteUsersToWorkspaceModal from '@shared-modules/components/modals/InviteUsersToWorkspaceModal'

import AppMenuLogo from '@/components/AppMenu/AppMenuLogo'
import AppMenuNav from '@/components/AppMenu/AppMenuNav'
import AppMenuTrigger from '@/components/AppMenu/AppMenuTrigger'
import AppMenuUserActions from '@/components/AppMenu/AppMenuUserActions'
import AppMenuWorkspaceSelect from '@/components/AppMenu/AppMenuWorkspaceSelect'
import SupportModal from '@/components/support/SupportModal'
import AppBannerWrapper from '@/components/ui/AppBanners/AppBannerWrapper'
import BillingReminder from '@/components/ui/AppBanners/BillingReminder'
import UpgradeTierReminder from '@/components/ui/AppBanners/UpgradeTierReminder'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppIcon from '@/components/ui/AppIcon/AppIcon'
import AppSocialMediaLinks from '@/components/ui/AppSocialMediaLinks/AppSocialMediaLinks'
import AppTitle from '@/components/ui/AppTitle/AppTitle'
import AppVerticalScrollGradient from '@/components/ui/AppVerticalScrollGradient/AppVerticalScrollGradient'
import Modal from '@/components/ui/Modal/Modal'
import ReleaseUpdatesLoader from '@/components/ui/SkeletonLoaders/ReleaseUpdatesLoader'

export default defineComponent({
  name: 'AppMenu',

  components: {
    SupportModal,
    AppButton,
    InviteUsersToWorkspaceModal,
    UpgradeTierReminder,
    BillingReminder,
    CreateOrganizationModal,
    AppVerticalScrollGradient,
    AppMenuLogo,
    AppMenuUserActions,
    PortalTarget,
    ReleaseUpdatesLoader,
    AppIcon,
    AppSocialMediaLinks,
    AppTitle,
    AppBannerWrapper,
    AppMenuWorkspaceSelect,
    AppMenuTrigger,
    AppMenuNav,
    Modal
  },

  inject: {
    isWebApp: {
      from: isWebAppInjectionKey
    },

    isSalesforceApp: {
      from: isSalesforceAppInjectionKey
    },

    isJiraApp: {
      from: isJiraAppInjectionKey
    }
  },

  props: {
    disabled: {
      type: Boolean
    }
  },

  setup() {
    const { menuReference, arrivedState } = useAppMenuHelpers()

    return {
      menuReference,
      arrivedState
    }
  },

  data() {
    return {
      someAdditionalMenuOpened: false,
      lastYScrollPosition: 0,
      isShowReleaseUpdatesModal: false,
      isShowSupportModal: false,
      isLoading: true,
      timestamp: Date.now(),
      isShowCreateOrganizationModal: false
    }
  },

  computed: {
    ...mapState('system', {
      menuShowed: state => state.appMenu.showed,
      menuPinned: state => state.appMenu.pinned
    }),

    ...mapState('pluginOptions', {
      onboarding: state => state[PLUGIN_OPTIONS_KEYS.ONBOARDING],
      isOwner: state => state[PLUGIN_OPTIONS_KEYS.IS_OWNER],
      licenseStatusId: state => state[PLUGIN_OPTIONS_KEYS.LICENSE_STATUS_ID]
    }),

    ...mapGetters('pluginOptions', {
      isPluginServer: 'isPluginServer'
    }),

    ...mapGetters('system', {
      releaseUpdatesModalId: 'releaseUpdatesModalId'
    }),

    NAV_ITEM_PLACEMENTS: () => NAV_ITEM_PLACEMENTS,

    APP_MENU_NAV_LAYOUTS: () => APP_MENU_NAV_LAYOUTS,

    BOTTOM_NAV_NOTIFICATIONS_PORTAL_NAME: () => BOTTOM_NAV_NOTIFICATIONS_PORTAL_NAME,

    isBillingPage() {
      return this.$route.name === ROUTE_NAMES.SETTINGS_BILLING_SETTINGS
    },

    isLicenseTrialStatus() {
      return (
        this.licenseStatusId === LICENSE_TYPES.WEB_APP_TRIAL ||
        this.licenseStatusId === LICENSE_TYPES.SALESFORCE_TRIAL
      )
    },

    isOverLimitedLicenseStatus() {
      return (
        this.licenseStatusId === LICENSE_TYPES.WEB_APP_OVERLIMIT ||
        this.licenseStatusId === LICENSE_TYPES.SALESFORCE_OVERLIMIT
      )
    },

    isPortalHasContent() {
      return !isEmpty(Wormhole.getContentForTarget(BOTTOM_NAV_NOTIFICATIONS_PORTAL_NAME))
    },

    currentChangeLogLinks() {
      const { isPluginServer, isJiraApp, isWebApp, isSalesforceApp } = this
      return getCurrentPlatformChangelogLinks({
        isPluginServer,
        isJiraApp,
        isWebApp,
        isSalesforceApp
      })
    },

    showHoverTrigger() {
      return !this.menuPinned && !this.menuShowed
    },

    hideWorkspaceSelect() {
      return !!this.$route.meta.hideWorkspaceSelectInAppMenu
    },

    classes() {
      return {
        'am-Content': true,
        'am-Content-without-workspace-select': this.hideWorkspaceSelect,
        'am-Content-additional-menu-opened': this.someAdditionalMenuOpened
      }
    }
  },

  watch: {
    isShowReleaseUpdatesModal: {
      handler(newVal) {
        if (newVal) {
          this.timestamp = Date.now()
        }
      },

      immediate: true
    },

    someAdditionalMenuOpened: {
      handler(newVal) {
        const amContentEl = this.$refs.menuReference
        if (amContentEl) {
          if (newVal) {
            this.lastYScrollPosition = amContentEl.scrollTop
            amContentEl.scrollTo(0, 0)
          } else {
            amContentEl.scrollTo(0, this.lastYScrollPosition)
          }
        }
      },

      immediate: true
    },

    releaseUpdatesModalId: {
      handler(newVal) {
        if (!isUndefined(newVal)) {
          this.resolveReleaseUpdatesModal()
        }
      },

      immediate: true
    }
  },

  methods: {
    ...mapActions('system', {
      toggleMenuPinnedStatus: 'toggleMenuPinnedStatus',
      toggleMenuShowedStatus: 'toggleMenuShowedStatus',
      updateUserSettings: 'updateUserSettings'
    }),

    onMenuItemClick(item) {
      const { name } = item
      if (name === RELEASE_UPDATES_MENU_ITEM_NAME) {
        this.showReleaseUpdatesModal()
      }

      if (name === SUPPORT_MENU_ITEM_NAME) {
        this.showSupportModal()
      }
    },

    onReleaseUpdatesModalClose() {
      this.isLoading = true
      this.isShowReleaseUpdatesModal = false
    },

    onIframeLoaded() {
      this.isLoading = false
    },

    resolveReleaseUpdatesModal() {
      const currentModalId = DEFAULT_USERS_SETTINGS[RELEASE_UPDATES_MODAL_ID]
      const userSavedModalId = this.releaseUpdatesModalId
      if (userSavedModalId < currentModalId) {
        if (!this.isShowReleaseUpdatesModal && !this.onboarding) {
          this.showReleaseUpdatesModal()
        }
        this.updateUserSettings({
          [USER_SETTINGS_MAPPER[RELEASE_UPDATES_MODAL_ID]]: currentModalId
        })
      }
    },

    showReleaseUpdatesModal() {
      this.isShowReleaseUpdatesModal = true
    },

    showSupportModal() {
      this.isShowSupportModal = true
    },

    toggleMenu() {
      this.toggleMenuPinnedStatus(!this.menuPinned)

      updateStorageByKey(MENU_PINNED_STATUS_LS_KEY, this.menuPinned)
    },

    onMouseEnter() {
      const { menuShowed } = this
      if (!menuShowed) {
        this.toggleMenuShowedStatus(true)
      }
    }
  }
})
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';
@import '~@/assets/styles/app-menu';

$menu-footer-padding-top: 8px;
$menu-footer-padding-bottom: 16px;
$menu-footer-height: calc(
  #{$menu-footer-padding-top} + #{$menu-footer-padding-bottom} + #{$menu-item-min-height}
);

.am-Wrapper {
  height: 100%;
  max-height: 100vh;
  width: 100%;
  position: sticky;
  top: 0;
  --menu-footer-height: #{$menu-footer-height};
  // box-shadow: inset -2px 0 0 #e6e9ed;
  box-shadow: 0 0 1px 0 rgba(22, 22, 29, 0.31), 0 3px 5px 0 rgba(22, 22, 29, 0.2),
    0 0 60px 0 rgba(0, 0, 0, 0.07);
  transition: box-shadow $menu-transition;

  // &-highlight {
  //   @media (any-hover: hover) {
  //     &:hover {
  //       // box-shadow: inset -2px 0 0 rgba($primary-color, 0.5);
  //     }
  //   }
  // }

  &-menu-pinned {
    @media (any-hover: hover) {
      &:hover {
        &:deep(.amt-Trigger) {
          opacity: 1;
        }
      }
    }
    &:deep(.amt-Trigger) {
      opacity: 0;
    }
  }
}

.am-Wrapper_Trigger {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 2;
}

.am-ContentWrapper {
  height: 100%;
  position: relative;
  overflow-x: hidden;
}

.am-Content {
  // padding: 0 16px;
  padding: 0 20px 0 20px;
  // height: 100%;
  height: calc(100% - $menu-footer-height);
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-x: hidden;
  @include styled-native-scrollbar();

  &-additional-menu-opened {
    overflow: hidden;
  }
}

.am-Content_Head {
  @extend %menu-header-styles;
}

.am-Content_Body {
  margin-bottom: auto;
}

/* necessary because sticky + flexbox + overflow-y don't work correct in FF */
// @-moz-document url-prefix() {
//   .am-Content_Body {
//     padding-bottom: var(--footer-height);
//   }
// }

// .am-Footer_Divider {
//   width: 100%;
//   --border-width: 2px;
//   margin: 8px 0;
//   color: #e6e9ed;
//   border-radius: 1px;
// }

.am-Footer_ReleaseUpdatesButton {
  padding: 8px 10px;
  width: 100%;
  font-family: $system-ui;
  font-style: normal;
  font-weight: fw('regular');
  font-size: $fs-14;
  transition: color $menu-transition;
  justify-content: flex-start;
  @extend %nav-item-styles;
}

.am-Content_Footer {
  // display: flex;
  // align-items: center;
  // flex-direction: column;
  // gap: 8px;
  // margin-top: auto;
  padding: $menu-footer-padding-top 0 $menu-footer-padding-bottom;
  overflow-x: hidden;
  position: static;
  // background: linear-gradient(0deg, $white calc(100% - 36px), rgba($white, 0) 100%);
}

.am-Header_Logo {
  display: flex;
  align-items: center;

  &:not(.am-Content-without-workspace-select &) {
    margin-bottom: 24px;
  }

  .am-Content-without-workspace-select & {
    margin-bottom: 8px;
  }
}

.am-LogoLink {
  display: flex;
  padding: 8px;
}

.am-Link {
  font-family: $system-ui;
  font-style: normal;
  font-weight: fw('regular');
  font-size: $fs-12;
  line-height: 16px;
  color: $dark-3;
}

.am-ReleaseUpdatesModal {
  --foter-height: 64px;
  --iframe-content-left-offset: 168.063px;
  &:deep(.uim-ModalHeader) {
    padding: 29px 40px 15px var(--iframe-content-left-offset);
    box-shadow: inset 0 -2px 0 $grey-2-next;
  }
}

.am-ModalLink {
  color: $primary-color-next;
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: $system-ui;
  font-size: $fs-12;
  line-height: 16px;
  font-weight: fw('regular');
}

.am-ModalHead_Title {
  font-size: $fs-32;
  line-height: 40px;
}

.am-ModalBody_Iframe,
.am-ModalBody_Loader {
  width: 100%;
  display: block;
  height: calc(100% - 84px - var(--foter-height)); // why 84px is modal header height
}

.am-ModalBody_Loader {
  overflow-y: auto;
}

.am-ModalFooter {
  height: var(--foter-height);
  padding: 20px 20px 20px var(--iframe-content-left-offset);
  box-shadow: inset 0 2px 0 $grey-2-next;
  --gap: 20px;
  --main-color: #{$grey-1-next};
  display: flex;
  align-items: center;
  gap: 20px;
  font-family: $system-ui;
  font-size: $fs-12;
  line-height: 16px;
  font-weight: fw('regular');
  color: $dark-3;
}

.am-InviteUsersTrigger {
  flex-shrink: 0;
  color: $primary-color-next;
  border: 2px solid rgba($primary-color-next, 0.2);

  &:hover {
    background-color: rgba($primary-color-next, 0.2);
    border-color: transparent;
  }

  &:active {
    background-color: lighten($primary-color-next, 55%);
  }
}

.am-WebAppMenuContent {
  display: grid;
  gap: 16px;

  &:not(:empty) {
    margin-top: 16px;
  }
}
</style>
