<template>
  <OkrFilterSelect
    :bottom-fixed-items="getBottomFixedItemsClearSelection('modelValue')"
    :loading="loading"
    :model-value="modelValue"
    :options="ownersOptions"
    :search-function="searchAssignees"
    class="cdo-Select"
    item-label="name"
    item-value="accountId"
    n-selected-label="filter.owners"
    prepend-icon="user-next"
    @update:model-value="updateValue"
    @update:options="onOptionsUpdate"
  >
    <template #option-label="{ option }">
      <OwnerFieldOption :option="option" />
    </template>
    <template #bottom-fixed-items="{ bottomFixedItems }">
      <div v-for="item in bottomFixedItems" :key="item.id">
        <BottomFixedSelectItem
          v-if="isClearSelectionAction(item.action)"
          @click="bottomFixedItemsHandle(item.action)"
        >
          {{ $t(item.text) }}
        </BottomFixedSelectItem>
      </div>
    </template>
  </OkrFilterSelect>
</template>

<script setup>
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import {
  isClearSelectionAction,
  SELECTS_WITH_CHECKBOX_ITEMS
} from '@/composables/bottom-fixed-items'
import { getAssignees } from '@/composables/fetchingData'
import { ASSIGNEE_ENTITY_KEYS } from '@/utils/entity-keys'
import { DEFAULT_VALUE_FOR_FILTER, SELECT_ALL_VALUE } from '@/utils/okr-elements/filters'
import { handleSelectInputWithSelectAll } from '@/utils/select'

import OwnerFieldOption from '@/components/form/OwnerFieldOption'
import BottomFixedSelectItem from '@/components/objectives/toolbar/BottomFixedSelectItem'
import OkrFilterSelect from '@/components/objectives/toolbar/OkrFilterSelect'

defineOptions({
  name: 'ConfluenceDashboardOwners'
})

const props = defineProps({
  modelValue: {
    type: Array,
    default: () => []
  },
  workspaceId: {
    type: Number,
    required: true
  }
})

const emit = defineEmits({
  'update:options': null,
  'update:modelValue': null
})
const { t } = useI18n()
const ownerOptions = ref([])
const loading = ref(false)
watch(
  () => props.workspaceId,
  async () => {
    try {
      if (!props.workspaceId) return
      loading.value = true
      const { options } = await getAssignees(null, props.workspaceId)
      ownerOptions.value = options.value
    } finally {
      loading.value = false
    }
  },
  { immediate: true }
)
const searchAssignees = async (searchString = null) => {
  const { options } = await getAssignees(searchString, props.workspaceId)
  return options
}

const ownersOptions = computed(() => {
  return [
    {
      [ASSIGNEE_ENTITY_KEYS.ACCOUNT_ID]: SELECT_ALL_VALUE,
      [ASSIGNEE_ENTITY_KEYS.NAME]: t('filter.all_owners')
    },
    ...ownerOptions.value
  ]
})
const bottomFixedItemsHandle = action => {
  if (isClearSelectionAction(action)) {
    updateValue(DEFAULT_VALUE_FOR_FILTER)
  }
}
const updateValue = newValue => {
  const oldValue = props.modelValue
  const { result } = handleSelectInputWithSelectAll(newValue, oldValue)
  emit('update:modelValue', result)
}

const getBottomFixedItemsClearSelection = key => {
  return props[key].includes(SELECT_ALL_VALUE) ? [] : SELECTS_WITH_CHECKBOX_ITEMS
}

const onOptionsUpdate = options => {
  ownerOptions.value = options
  emit('update:options', options)
}
</script>

<style lang="scss" scoped>
.cdo-Select {
  max-width: 220px;
}
</style>
