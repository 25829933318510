<template>
  <div class="owf-WeightsForm">
    <div class="owf-MainContent">
      <div class="owf-Header">
        <TitleWithAction>
          <template #prepend>
            {{ $t('objective.weights') }}
          </template>
          {{ modelValue?.name || '' }}
        </TitleWithAction>

        <AppRadioGroup
          :disabled="isOkrElementClosed(formModel) || isConfiguringDisabled"
          :model-value="formModel.weightsControl"
          :options="getWeightsOptions"
          class="owf-Mode"
          data-testid="mode-changer"
          name="weights"
          style="--option-padding: 0 8px 0 0"
          type="primary-next"
          @update:model-value="changeWeightsControl"
        >
          <template #item-label="{ item }">
            <AppRadioGroupNextOptionWithIcon :option="item" />
          </template>
        </AppRadioGroup>
        <OkrGradeValue :color="modelValue.gradeColor" :grade="modelValue.gradeToUse" />
      </div>

      <AppTable
        v-if="!okrWeightsLimitations.essentialPlanLimitations.isDisabledByPlan"
        :columns="TABLE_COLUMNS"
        :data="childObjectivesFormatted"
        :loading="isChildsLoading"
        no-border
        no-hover
        row-test-id="weights-table-row"
        type="primary-next"
      >
        <template #header-cell="{ column }">
          {{ $t(column.title) }}
        </template>
        <template #cell="{ columnKey, item }">
          <template v-if="columnKey === TABLE_COLUMNS_KEYS.CONTRIBUTE">
            <div class="owf-ContributeCell">
              <AppCheckbox
                :data-testid="getTestId(item.typeId)"
                :disabled="isOkrElementClosed(item)"
                :model-value="item.contribute"
                @update:model-value="updateObjectivesContribute(item.id)"
              />
              <div class="owf-ContributeCell_FullName">
                <OkrIcon :objective="item" />
                <TitleItems
                  :id-as-link="false"
                  :objective="item"
                  id-disabled-color="var(--primary-color-next)"
                  id-size="small"
                  style="--id-font-weight: 600"
                />
              </div>
            </div>
          </template>
          <template v-if="columnKey === TABLE_COLUMNS_KEYS.INTERVAL">
            <div
              v-tippy="{
                content: item.intervalName,
                placement: 'bottom-end'
              }"
              :class="{ 'owf-DisabledCell': isCellDisabled(item) }"
              class="owf-IntervalCell"
            >
              {{ item.intervalName }}
            </div>
          </template>
          <template v-if="columnKey === TABLE_COLUMNS_KEYS.WIGHT">
            <ObjectiveWeightControl
              :disabled="isCellDisabled(item) || isConfiguringDisabled"
              :is-contribute="item.contribute"
              :weight-multiplier="item.weightMultiplier"
              data-testid="weight-control"
              @update-weight-multiplier="updateWeightMultiplier($event, item.id)"
            />
          </template>
          <template v-if="columnKey === TABLE_COLUMNS_KEYS.IMPACT">
            <div :class="{ 'owf-DisabledCell': isCellDisabled(item) }" class="owf-ImpactCell">
              {{ getImpact(item) }}
            </div>
          </template>

          <template v-if="columnKey === TABLE_COLUMNS_KEYS.GRADE">
            <div class="owf-GradeCell">
              <OkrGrade
                :color="item.gradeColor"
                :grade="item.gradeToUse"
                :type-id="item.typeId"
                :weight="item.weight"
                :weight-multiplier="item.weightMultiplier"
                show-only-value
                type="default-next"
              />
            </div>
          </template>
        </template>

        <template #loading>
          <ObjectivesWeightsLoader :count="modelValue.childCount" />
        </template>
      </AppTable>

      <NoSearchResults v-else>
        {{ okrWeightsLimitations.essentialPlanLimitations.UNAVAILABLE_BY_PLAN_TOOLTIP }}
      </NoSearchResults>
    </div>
  </div>

  <div
    v-if="!okrWeightsLimitations.essentialPlanLimitations.isDisabledByPlan"
    class="owf-ConfirmActions"
  >
    <div v-if="!isAuto" class="owf-ConfirmActions_Message">
      <AppIcon class="owf-ConfirmActionsMessageIcon" height="24" icon-name="info-next" width="24" />
      <i18n-t keypath="weights.objective.manual.description" scope="global" tag="div">
        <template #mode>
          <b>{{ $t('weights.objective.manual_mode') }}</b>
        </template>
      </i18n-t>
    </div>
    <ModalFooterActions
      :disabled="loading"
      :loading="loading"
      hide-checkbox
      @close="close"
      @create="updateWeights"
    >
      <template #cancel-button-text>
        {{ $t('action.cancel') }}
      </template>

      <template #confirm-button-text> {{ $t('action.confirm') }}</template>
    </ModalFooterActions>
  </div>
</template>

<script>
// import { cloneDeep, has } from 'lodash'
import { isUndefined } from 'lodash'
import { defineComponent } from 'vue'

import ObjectivesInfoApiHandler, { SEARCH_TYPES } from '@/api/okr-elements'
import WeightApiHandler from '@/api/weight'
import { handleError } from '@/utils/error-handling'
import { TYPE_ID_NAMES } from '@/utils/objective-types'
import {
  isOkrElementClosed,
  OBJECTIVE_SORT_OPTIONS
  // objectiveIsJiraTask,
  // isKR
} from '@/utils/objectives'
import {
  CONTRIBUTE_DEFAULT_WEIGHT_MULTIPLIER,
  UNCONTRIBUTE_DEFAULT_WEIGHT_MULTIPLIER
} from '@/utils/okr-weights-helpers'
import { TABLE_COLUMNS_KEYS } from '@/utils/table-columns'
import { usePlansLimitations } from '@/utils/web-app/plans-limitations'

import OkrGradeValue from '@/components/form/OkrGradeValue'
import ModalFooterActions from '@/components/objectives/forms/ModalFooterActions'
import OkrIcon from '@/components/objectives/items/OkrIcon'
import TitleItems from '@/components/objectives/items/TitleItems'
import ObjectiveWeightControl from '@/components/objectives/ObjectiveWeightControl'
import OkrGrade from '@/components/objectives/OkrGrade'
import TitleWithAction from '@/components/objectives/TitleWithAction'
import AppCheckbox from '@/components/ui/AppCheckbox/AppCheckbox'
import AppIcon from '@/components/ui/AppIcon/AppIcon'
import AppRadioGroup from '@/components/ui/AppRadioGroup/AppRadioGroup'
import AppRadioGroupNextOptionWithIcon from '@/components/ui/AppRadioGroup/AppRadioGroupNextOptionWithIcon'
import NoSearchResults from '@/components/ui/NoSearchResults/NoSearchResults'
import ObjectivesWeightsLoader from '@/components/ui/SkeletonLoaders/ObjectivesWeightsLoader'
import AppTable from '@/components/ui/Table/AppTable'

const WEIGHTS_MODES = {
  AUTO: 'auto',
  MANUAL: 'manual'
}

const DEFAULT_FORM_MODEL = {
  weightsControl: WEIGHTS_MODES.AUTO,
  childObjectives: []
}

const TABLE_COLUMNS = [
  {
    title: 'custom_weights.contribute',
    key: TABLE_COLUMNS_KEYS.CONTRIBUTE,
    width: 'auto'
  },
  {
    title: 'field.quoter.title',
    key: TABLE_COLUMNS_KEYS.INTERVAL,
    width: 124
  },
  {
    title: 'custom_weights.weight',
    key: TABLE_COLUMNS_KEYS.WIGHT,
    width: 133
  },
  {
    title: 'custom_weights.impact',
    key: TABLE_COLUMNS_KEYS.IMPACT,
    width: 50
  },
  {
    title: 'objectives.table_header_grade',
    key: TABLE_COLUMNS_KEYS.GRADE,
    width: 93
  }
]

/**
 * In auto mode:
 * - on save change weightMultiplier and weight to null, so backend detects auto mode and calculates
 *   weights automatically
 */

// const updateAcc = ({ acc, key, val }) => {
//   acc[key] = has(acc, key) ? [...acc[key], val.displayId] : [val.displayId]
//   return acc
// }

const calculateTotalWeightsMultiplier = (objectives, valueForCalculate = undefined) => {
  return objectives.reduce((totalWeightsMultiplier, objective) => {
    let resolvedValueForCalculate = null
    if (isUndefined(valueForCalculate)) {
      resolvedValueForCalculate = objective.weightMultiplier
    } else {
      resolvedValueForCalculate = objective.contribute
        ? valueForCalculate
        : UNCONTRIBUTE_DEFAULT_WEIGHT_MULTIPLIER
    }
    return totalWeightsMultiplier + resolvedValueForCalculate
  }, UNCONTRIBUTE_DEFAULT_WEIGHT_MULTIPLIER)
}

export default defineComponent({
  name: 'ObjectiveWeights',

  components: {
    NoSearchResults,
    AppIcon,
    TitleWithAction,
    ModalFooterActions,
    AppRadioGroupNextOptionWithIcon,
    OkrGradeValue,
    OkrGrade,
    ObjectiveWeightControl,
    OkrIcon,
    AppCheckbox,
    ObjectivesWeightsLoader,
    AppTable,
    AppRadioGroup,
    TitleItems
  },

  props: {
    modelValue: {
      type: Object,
      default: null
    }
  },

  emits: { close: null, 'updated-weights': null },

  setup() {
    const { okrWeightsLimitations } = usePlansLimitations()

    return {
      okrWeightsLimitations
    }
  },

  data() {
    return {
      loading: false,
      isChildsLoading: false,
      localFormModel: { ...DEFAULT_FORM_MODEL },
      formModel: { ...DEFAULT_FORM_MODEL },
      areChildObjectivesLoaded: false
    }
  },

  computed: {
    TABLE_COLUMNS_KEYS: () => TABLE_COLUMNS_KEYS,

    TABLE_COLUMNS: () => TABLE_COLUMNS,

    getWeightsOptions() {
      return [
        { label: 'status.switch_equal', value: WEIGHTS_MODES.AUTO, icon: 'weights-equal' },
        {
          label: 'status.switch_manual',
          value: WEIGHTS_MODES.MANUAL,
          icon: 'weights-manual'
        }
      ]
    },

    contributedChildCount() {
      return this.childObjectivesFormatted.filter(objective => objective.contribute).length
    },

    isConfiguringDisabled() {
      const baseCondition = this.formModel.childObjectives.length < 2

      if (!this.isAuto) {
        return baseCondition || this.contributedChildCount < 2
      }

      return baseCondition
    },

    isAuto() {
      return this.formModel.weightsControl === WEIGHTS_MODES.AUTO
    },

    /** @public */
    areDataChanged() {
      return JSON.stringify(this.localFormModel) !== JSON.stringify(this.formModel)
    },

    //groupped() {
    //  return cloneDeep(QQQ).reduce((acc, val) => {
    //    const isObjective = !objectiveIsJiraTask(val) && !isKR(val)
    //    const OBJECTIVE_KEY = 'objectives'
    //    const KR_KEY = 'keyResults'
    //
    //    const RESOLVED_KEY = isObjective ? OBJECTIVE_KEY : KR_KEY
    //
    //    return cloneDeep(updateAcc({ acc, key: RESOLVED_KEY, val }))
    //  }, {})
    //},

    childObjectivesFormatted() {
      return this.formModel.childObjectives.map(objective => {
        let { weightMultiplier } = objective
        // auto
        if (objective.weight === null) {
          weightMultiplier = objective.contribute
            ? CONTRIBUTE_DEFAULT_WEIGHT_MULTIPLIER
            : UNCONTRIBUTE_DEFAULT_WEIGHT_MULTIPLIER
        }
        return {
          ...objective,
          weightMultiplier
        }
      })
    },

    getTotalWeightsMultiplier() {
      return calculateTotalWeightsMultiplier(this.childObjectivesFormatted)
    }
  },

  mounted() {
    if (this.modelValue) {
      this.formModel = { ...this.formModel, ...this.modelValue }
    }
    this.getChildObjectives()
  },

  methods: {
    isOkrElementClosed,

    isCellDisabled(item) {
      return this.isAuto || !item.contribute || this.isOkrElementClosed(item)
    },

    getTestId(typeId) {
      return `contribute-switch-${TYPE_ID_NAMES[typeId]}`
    },

    changeWeightsControl(type) {
      if (!this.isConfiguringDisabled) {
        this.formModel.weightsControl = type
      }
    },

    getImpact(objective) {
      if (!objective.contribute) {
        return `${UNCONTRIBUTE_DEFAULT_WEIGHT_MULTIPLIER}%`
      }

      if (this.formModel.weightsControl === WEIGHTS_MODES.AUTO) {
        const totalWeight = calculateTotalWeightsMultiplier(
          this.childObjectivesFormatted,
          CONTRIBUTE_DEFAULT_WEIGHT_MULTIPLIER
        )
        const impact =
          totalWeight === UNCONTRIBUTE_DEFAULT_WEIGHT_MULTIPLIER
            ? UNCONTRIBUTE_DEFAULT_WEIGHT_MULTIPLIER
            : (CONTRIBUTE_DEFAULT_WEIGHT_MULTIPLIER / totalWeight) * 100
        return `${impact.toFixed()}%`
      }

      const impact =
        this.getTotalWeightsMultiplier === UNCONTRIBUTE_DEFAULT_WEIGHT_MULTIPLIER
          ? UNCONTRIBUTE_DEFAULT_WEIGHT_MULTIPLIER
          : (objective.weightMultiplier / this.getTotalWeightsMultiplier) * 100
      return `${impact.toFixed()}%`
    },

    updateWeightMultiplier(value, id) {
      this.updateObjectivesWeightMultiplier({
        id,
        weightMultiplier: value,
        weight: this.isAuto ? null : 0 // 0 is temporary value for manual mode, the correct
        // one will be calculated on sending request
      })
    },

    async getChildObjectives() {
      const api = new ObjectivesInfoApiHandler()
      try {
        this.isChildsLoading = true
        const apiResponse = await api.getChildObjectives({
          parentId: this.formModel.id,
          // parentTypeId: this.formModel.typeId,
          intervalIds: [this.formModel.intervalId],
          workspaceId: this.formModel.workspaceId,
          searchType: SEARCH_TYPES.OKR_EXPLORER,
          order: [OBJECTIVE_SORT_OPTIONS.ORDER_ASC],
          childOrder: [OBJECTIVE_SORT_OPTIONS.ORDER_ASC],
          offset: 0,
          limit: 1000
        })
        const childObjectives = apiResponse.items
        this.formModel.weightsControl = apiResponse.weightManual
          ? WEIGHTS_MODES.MANUAL
          : WEIGHTS_MODES.AUTO
        this.formModel.childObjectives = [...childObjectives]
        this.$nextTick(() => {
          if (!this.areChildObjectivesLoaded) {
            this.localFormModel = {
              ...this.formModel
            }
          }
          this.areChildObjectivesLoaded = true
        })
      } finally {
        this.isChildsLoading = false
      }
    },

    async updateWeights() {
      const api = new WeightApiHandler()
      try {
        this.loading = true
        const updatedWeights = this.childObjectivesFormatted.map(
          ({ contribute, id, weightMultiplier, typeId }) => {
            let updatedWeight = 0
            let updatedWeightMultiplier = 0
            if (contribute) {
              updatedWeightMultiplier = weightMultiplier || 1
              updatedWeight = 0

              if (!this.isAuto && this.getTotalWeightsMultiplier !== 0) {
                updatedWeight =
                  Math.round(
                    (weightMultiplier / this.getTotalWeightsMultiplier + Number.EPSILON) * 100
                  ) / 100
              }
            }

            if (this.isAuto) {
              updatedWeight = null
              updatedWeightMultiplier = null
            }

            return {
              contribute,
              elementId: id,
              elementTypeId: typeId,
              weight: updatedWeight,
              weightMultiplier: updatedWeightMultiplier
            }
          }
        )
        const payload = {
          parentObjectiveId: this.formModel.id,
          weights: updatedWeights,
          workspaceId: this.formModel.workspaceId
        }
        await api.updateWeights(payload)

        this.$emit('updated-weights')
        this.close(false)
      } catch (error) {
        handleError({ error })
      } finally {
        this.loading = false
      }
    },

    close(checkDataChange = true) {
      this.$emit('close', { checkDataChange })
    },

    updateObjectivesContribute(id) {
      const itemIndex = this.formModel.childObjectives.findIndex(objective => objective.id === id)
      if (itemIndex === -1) {
        return
      }

      const updatedItem = this.formModel.childObjectives[itemIndex]
      updatedItem.contribute = !updatedItem.contribute
      updatedItem.weightMultiplier = updatedItem.contribute ? 1 : 0

      this.formModel.childObjectives[itemIndex] = updatedItem
    },

    updateObjectivesWeightMultiplier({ id, weightMultiplier, weight }) {
      const isObjective = objective => objective && objective && objective.id === id
      const isTask = objective => objective && objective.id === id
      const objectiveIndex = this.formModel.childObjectives.findIndex(
        objective => isObjective(objective) || isTask(objective)
      )
      if (objectiveIndex === -1) {
        return
      }

      const objective = this.formModel.childObjectives[objectiveIndex]
      const objectToChange = objective || objective
      objectToChange.weightMultiplier = weightMultiplier
      objectToChange.weight = weight
      objectToChange.contribute = Boolean(weightMultiplier && weightMultiplier > 0)

      this.formModel.childObjectives[objectiveIndex] = objective
    }
  }
})
</script>

<style lang="scss" scoped>
.owf-Mode {
  // margin: 22px 0 28px;
  // margin: 0 0 4px auto;
  margin-left: auto;
}

.owf-WeightsForm {
  display: flex;
  flex-direction: column;
  margin: 0 40px;
}

.owf-ConfirmActions {
  margin: 40px 0 20px;
  padding: 20px 40px 0;
  box-shadow: inset 0 1px 0 $grey-2-next;
  display: flex;
  align-items: flex-start;
  gap: 8px;
}

.owf-ConfirmActions_Message {
  margin-top: 6px;
  font-family: $system-ui;
  font-style: normal;
  font-weight: fw('regular');
  font-size: $fs-14;
  line-height: 20px;
  color: $dark-3;
  display: grid;
  grid-template-columns: 24px 1fr;
  gap: 8px;
  max-width: 520px;
}
.owf-ConfirmActionsMessageIcon {
  margin-top: -2px;
}

.owf-ContributeCell {
  margin-left: 4px;
  display: flex;
  align-items: center;
  overflow: hidden;
  gap: 12px;
}

.owf-ContributeCell_FullName {
  max-width: 450px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap: 8px;
}

.owf-IntervalCell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  align-self: center;
  text-align: right;
}

.owf-GradeCell {
  &:deep(.okg-OkrGrade) {
    justify-content: flex-end;
  }
}

.owf-ImpactCell {
  text-align: right;
}

.owf-DisabledCell {
  color: $grey-1-next;
}

.owf-MainContent {
  &:deep(.tb-HeaderCell-weight) {
    text-align: center;
  }

  &:deep(.tb-HeaderCell-interval),
  &:deep(.tb-HeaderCell-grade),
  &:deep(.tb-HeaderCell-impact) {
    text-align: right;
  }
}

.owf-Header {
  display: flex;
  align-items: flex-end;
  gap: 24px;
  margin-bottom: 28px;
  &:deep(.ogv-Value) {
    flex: 0 0 auto;
    line-height: 40px;
  }
}
</style>
