<template>
  <div class="ss-SynchronizationPage">
    <PageContentHeader
      :level="3"
      :max-width="HEADER_SIZES.LG"
      :title="t('menu.synchronization_settings')"
      no-margin
    />

    <div class="ss-Content">
      <i18n-t
        class="ss-Content_Description"
        keypath="settings.synchronization.description"
        scope="global"
        tag="div"
      >
        <template #break>
          <br />
          <br />
        </template>
      </i18n-t>
      <AppRadioGroup
        v-model="view"
        :options="TABS"
        name="menu-type"
        type="tab-like"
        @update:model-value="onViewChange"
      />

      <div v-if="contentLoaded" class="ss-Content_Settings">
        <div v-if="view === 'general_settings'" class="ss-TabContainer ss-TabContainer_General">
          <div class="ss-ContentFields">
            <SyncFieldsSelect
              v-model:value="startDateField"
              :disabled="saving"
              :fields="fields"
              :filed-type="FIELD_TYPES.START_DATE"
              :hidden-items="[endDateField ? endDateField : '']"
              :loading="loading"
            />
            <SyncFieldsSelect
              v-model:value="endDateField"
              :disabled="saving"
              :fields="fields"
              :filed-type="FIELD_TYPES.END_DATE"
              :hidden-items="[startDateField ? startDateField : '']"
              :loading="loading"
            />
            <SyncTypes
              v-if="isChangedValue && isFieldSelected"
              v-model:keep-value="keepValue"
              v-model:sync-direction="syncDirection"
              :data="syncTypes"
              :disabled="saving"
            />
            <div v-if="isChangedValue" class="ss-Controls">
              <LoadingCircle v-if="saving" size="xsmall" />
              <AppButton
                :disable="saveButtonDisabled"
                class="ss-SaveButton"
                loading-circle-color="var(--white-color)"
                @click="
                  saveSettings({
                    syncDirection: !startDateField && !endDateField ? 0 : syncDirection,
                    keepValue: !startDateField && !endDateField ? false : keepValue
                  })
                "
              >
                {{ saveButtonLabel }}
              </AppButton>
            </div>
          </div>
        </div>
        <div v-else class="ss-TabContainer ss-TabContainer_Projects">
          <div v-if="showTable" class="ss-ContentFields_Head">
            <AppButton
              :disable="saving"
              icon="plus-next"
              type="primary-next"
              @click="showAddProjectModal = true"
            >
              {{ t('settings.synchronization.add_custom_setting') }}
            </AppButton>
          </div>
          <ProjectsTable
            v-if="showTable"
            :columns="TABLE_COLUMNS"
            :data="tableData"
            class="ss-ProjectsTable"
            no-border
            type="primary-next"
          >
            <template #header-cell="{ column }">
              <template v-if="column.title">
                <span class="ss-ProjectsTable_Title">{{ t(column.title) }}</span>
              </template>
            </template>
            <template #cell="{ columnKey, item }">
              <template v-if="columnKey === TABLE_COLUMN_KEYS.TYPE">
                <div class="ss-ProjectsTable_Type">
                  <AppIcon
                    :icon-name="item.type === 2 ? 'replace_jira' : 'replace_okr'"
                    height="24"
                    width="24"
                  />
                  <span>{{
                    item.type === 2
                      ? t('settings.synchronization.oboard_replace_jira')
                      : t('settings.synchronization.jira_replace_oboard')
                  }}</span>
                </div>
              </template>
              <template v-if="columnKey === TABLE_COLUMN_KEYS.START_DATE_FIELD">
                {{
                  fields.find(field => field.id === item.startDateField)
                    ? `${fields.find(field => field.id === item.startDateField).name} (${
                        item.startDateField || ''
                      })`
                    : t('settings.synchronization.not_synchronized')
                }}
              </template>
              <template v-if="columnKey === TABLE_COLUMN_KEYS.END_DATE_FIELD">
                {{
                  fields.find(field => field.id === item.endDateField)
                    ? `${fields.find(field => field.id === item.endDateField).name} (${
                        item.endDateField || ''
                      })`
                    : t('settings.synchronization.not_synchronized')
                }}
              </template>
              <template v-if="columnKey === 'actions'">
                <div class="ss-Actions">
                  <AppButton
                    :disable="saving"
                    height="24"
                    icon="delete-next"
                    size="sm"
                    type="tertiary-next"
                    width="24"
                    @click="onDeleteProjectConfiguration(item)"
                  />
                </div>
              </template>
            </template>
          </ProjectsTable>
          <SettingsPagesEmptyState
            v-else
            class="ss-EmptyState"
            hero-height="128"
            hero-icon="projects-empty-state"
            hero-width="174"
          >
            <template #title> {{ $t('settings.synchronization.no_custom_settings') }}</template>
            <template #subtitle>
              {{ $t('settings.synchronization.add_custom_settings') }}
            </template>

            <template #action>
              <AppButton
                :disable="saving"
                height="24"
                icon="plus-next"
                type="primary-next"
                width="24"
                @click="showAddProjectModal = true"
              >
                {{ $t('settings.synchronization.add_custom_setting') }}
              </AppButton>
            </template>
          </SettingsPagesEmptyState>
        </div>
      </div>
    </div>
  </div>

  <SyncSettingsModalRemoveProject
    v-model:data="projectForRemove"
    v-model:show="showRemoveModal"
    @remove-project="removeProject"
  />
  <SyncSettingsModalAddProject
    v-model:show="showAddProjectModal"
    :configured-projects="configuredProjects"
    :fields="fields"
    :loading="loading"
    :projects="projects"
    @add-projects="addProjectToConfigure"
  />
</template>

<script setup>
import { isEmpty } from 'lodash'
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

import { tracker } from '@/tracking/amplitude'
import { EVENT_CATEGORIES, EVENT_NAMES } from '@/tracking/amplitude-helpers'
import { HEADER_SIZES } from '@/utils/components-configurations/page-content-header'
import { handleError } from '@/utils/error-handling'
import { showNotify } from '@/utils/notify'
import { checkResponseStatus, RESPONSE_STATUSES } from '@/utils/response-statuses'
import { FIELD_TYPES } from '@/utils/sync-settings'
import JiraFieldsMappingHandler from '@jira/api/jira-fields-mapping'

import AppButton from '@/components/ui/AppButton/AppButton'
import AppIcon from '@/components/ui/AppIcon/AppIcon'
import AppRadioGroup from '@/components/ui/AppRadioGroup/AppRadioGroup'
import LoadingCircle from '@/components/ui/LoadingCircle/LoadingCircle'
import PageContentHeader from '@/components/ui/PageContentHeader/PageContentHeader'
import SettingsPagesEmptyState from '@/components/ui/SettingsPagesEmptyState/SettingsPagesEmptyState'
import ProjectsTable from '@/components/ui/Table/AppTable'
import SyncFieldsSelect from '@jira/components/settings/SyncFieldsSelect'
import SyncTypes from '@jira/components/settings/SyncTypes/SyncTypes'
import SyncSettingsModalAddProject from '@jira/views/workspaces/settings/synchronizationSettings/SyncSettingsModalAddProject'
import SyncSettingsModalRemoveProject from '@jira/views/workspaces/settings/synchronizationSettings/SyncSettingsModalRemoveProject'

import { useSyncTypes, DEFAULT_PROJECT_CONFIGURATION } from './composable'

const { t } = useI18n()

const { syncTypes } = useSyncTypes()
const showRemoveModal = ref(false)
const saving = ref(false)
const showAddProjectModal = ref(false)
const syncDirection = ref(null)
const keepValue = ref(false)

const api = new JiraFieldsMappingHandler()

const FIELD_TYPE_IDS = {
  START_DATE: 1,
  END_DATE: 2
}

const view = ref('general_settings')
const TABS = [
  {
    value: 'general_settings',
    label: t('settings.synchronization.general_settings'),
    trackingTab: 'settings.synchronization.general_settings'
  },
  {
    value: 'custom_settings',
    label: t('settings.synchronization.custom_settings'),
    trackingTab: 'settings.synchronization.custom_settings'
  }
]

const TABLE_COLUMN_KEYS = {
  NAME: 'name',
  TYPE: 'type',
  START_DATE_FIELD: 'startDateField',
  END_DATE_FIELD: 'endDateField'
}
const TABLE_COLUMNS = [
  {
    title: 'settings.synchronization.jira_project_name',
    key: TABLE_COLUMN_KEYS.NAME,
    width: 'auto'
  },
  // {
  //   title: 'objective.sort_option_type',
  //   key: TABLE_COLUMN_KEYS.TYPE,
  //   width: 'auto'
  // },
  {
    title: 'settings.synchronization.select.start_date_label',
    key: TABLE_COLUMN_KEYS.START_DATE_FIELD,
    width: 'auto'
  },
  {
    title: 'settings.synchronization.select.end_date_label',
    key: TABLE_COLUMN_KEYS.END_DATE_FIELD,
    width: 'auto'
  },
  {
    key: 'actions',
    width: 56
  }
]

const store = useStore()

const userRoleForTracking = computed(() => {
  return store.getters['system/userRoleForTracking']
})

const onViewChange = view => {
  const page = TABS.find(page => page.value === view)
  if (page) {
    const trackerData = {
      category: EVENT_CATEGORIES.SETTINGS,
      subCategory: t('menu.synchronization_settings', 1, { locale: 'en' }),
      role: userRoleForTracking.value
    }

    if (page.trackingTab) {
      trackerData.tab = t(page.trackingTab, 1, { locale: 'en' })
    }

    tracker.logEvent(EVENT_NAMES.SETTINGS_OPENED, trackerData)
  }
}

const findItemForRestore = (mappingsData, typeId) =>
  mappingsData.find(item => item.typeId === typeId)

const isFieldStillExist = (fields, fieldId) => fields.some(field => field.id === fieldId)

const fieldForSelectingValue = 'key'
const fields = ref([])
const startDateField = ref(DEFAULT_PROJECT_CONFIGURATION.startDateField)
const endDateField = ref(DEFAULT_PROJECT_CONFIGURATION.endDateField)
const configuredProjects = ref([])
const projects = ref([])
const tableData = computed(() => {
  return configuredProjects.value.map(item => {
    const { name } = projects.value.find(
      project => item.projectId === project[fieldForSelectingValue]
    ) || {
      name: ''
    }
    return {
      ...item,
      name
    }
  })
})
const restoredStartDateField = ref(null)
const restoredEndDateField = ref(null)
const isChangedValue = computed(() => {
  return (
    restoredEndDateField.value !== endDateField.value ||
    restoredStartDateField.value !== startDateField.value
  )
})
const isFieldSelected = computed(
  () =>
    (endDateField.value && endDateField.value.length) ||
    (startDateField.value && startDateField.value.length)
)
const restoreMappingsValues = mappingsData => {
  const totalMappingsData = mappingsData.filter(item => item.projectId === null)
  const projectIds = projects.value.map(project => project[fieldForSelectingValue])

  configuredProjects.value = projectIds.reduce((acc, val) => {
    const fieldsForProject = mappingsData.filter(item => item.projectId === val)
    const isProjectStillExist = projects.value.some(
      project => project[fieldForSelectingValue] === val
    )

    if (!isEmpty(fieldsForProject) && isProjectStillExist) {
      const valueForStartDate = findItemForRestore(
        fieldsForProject,
        FIELD_TYPE_IDS.START_DATE
      )?.fieldId
      const valueForEndDate = findItemForRestore(fieldsForProject, FIELD_TYPE_IDS.END_DATE)?.fieldId

      const configuredProject = { projectId: val }

      if (valueForStartDate && isFieldStillExist(fields.value, valueForStartDate)) {
        configuredProject.startDateField = valueForStartDate
      }

      if (valueForEndDate && isFieldStillExist(fields.value, valueForEndDate)) {
        configuredProject.endDateField = valueForEndDate
      }

      acc = [...acc, { ...configuredProject }]
    }

    return acc
  }, [])

  if (!isEmpty(totalMappingsData)) {
    const valueForStartDate = findItemForRestore(
      totalMappingsData,
      FIELD_TYPE_IDS.START_DATE
    )?.fieldId
    const valueForEndDate = findItemForRestore(totalMappingsData, FIELD_TYPE_IDS.END_DATE)?.fieldId

    startDateField.value = valueForStartDate
    restoredStartDateField.value = valueForStartDate

    endDateField.value = valueForEndDate
    restoredEndDateField.value = valueForEndDate
  }
}

const addProjectToConfigure = async ({ syncDirection, keepValue, projectConfiguration }) => {
  configuredProjects.value.push(
    ...projectConfiguration.value.projectId.reduce((acc, curr) => {
      // eslint-disable-next-line no-unused-vars
      const { syncDirection, ...project } = projectConfiguration.value
      return [...acc, { ...project, projectId: curr }]
    }, [])
  )
  await saveSettings({ syncDirection, keepValue })
  showAddProjectModal.value = false
}
const showTable = computed(() => !isEmpty(tableData.value))

onMounted(() => {
  getData()
})

const contentLoaded = ref(false)
const loading = ref(false)
const getData = async () => {
  loading.value = true
  try {
    const [projectsData, fieldsData] = await Promise.all([api.getProjects(), api.getFields()])

    projects.value = projectsData.projects
    fields.value = [
      { name: t('settings.synchronization.not_synchronized'), id: null },
      ...fieldsData.fields
    ]

    const mappingsData = await api.getJiraFieldMappings()

    if (!isEmpty(mappingsData)) {
      restoreMappingsValues(mappingsData)
    }
  } catch (error) {
    handleError({ error })
  } finally {
    loading.value = false
    contentLoaded.value = true
  }
}

const projectForRemove = ref({})
const onDeleteProjectConfiguration = project => {
  projectForRemove.value = { ...project }
  showRemoveModal.value = true
}
const removeProject = ({ projectId, syncDirection, keepValue }) => {
  configuredProjects.value = configuredProjects.value.filter(
    project => project.projectId !== projectId
  )
  saveSettings({ syncDirection, keepValue })
}

// In case to set mapping to default should be checked existing previous value.
// For other should be checked selected field, at least one and syncDirection.
const restoredFields = computed(() => restoredEndDateField.value || restoredStartDateField.value)
const saveButtonDisabled = computed(() => {
  return (
    (restoredFields.value && !isFieldSelected.value
      ? !restoredFields.value
      : saving.value || !syncDirection.value || !isFieldSelected.value) ||
    loading.value ||
    saving.value
  )
})
const saveButtonLabel = computed(() =>
  saving.value
    ? t('settings.synchronization.sync_in_progress')
    : t('settings.synchronization.save_button')
)

const saveSettings = async ({ syncDirection, keepValue }) => {
  saving.value = true

  const payload = {
    mappings: [
      {
        fieldId: startDateField.value,
        projectId: null,
        typeId: FIELD_TYPE_IDS.START_DATE
      },
      {
        fieldId: endDateField.value,
        projectId: null,
        typeId: FIELD_TYPE_IDS.END_DATE
      },
      ...configuredProjects.value.flatMap(item => {
        const { projectId, startDateField, endDateField } = item
        return [
          { projectId, fieldId: startDateField, typeId: FIELD_TYPE_IDS.START_DATE },
          { projectId, fieldId: endDateField, typeId: FIELD_TYPE_IDS.END_DATE }
        ]
      })
    ].map(i => {
      if (!i.fieldId) {
        return { ...i, fieldId: null }
      }
      return { ...i }
    }),

    // Subtraction -2 need for each case when selected toggle.
    syncDirection: keepValue ? syncDirection - 2 : syncDirection
  }

  try {
    const eventName = isEmpty(projectForRemove.value)
      ? 'set sync settings'
      : 'removed sync settings'

    const trackerPayload = {
      category: EVENT_CATEGORIES.SETTINGS,
      direction: syncDirection
    }

    if (!isEmpty(projectForRemove.value)) {
      trackerPayload.project = projectForRemove.value.projectId
    }

    const { mappings } = await api.saveJiraFieldMappings(payload)
    if (!isEmpty(mappings)) {
      restoreMappingsValues(mappings)
    }

    tracker.logEvent(eventName, trackerPayload)

    showNotify()
  } catch (error) {
    if (checkResponseStatus({ error, status: RESPONSE_STATUSES.REQUEST_TIMEOUT })) {
      saveSettings({ keepValue, syncDirection })
    } else {
      handleError({ error })
    }
  }

  saving.value = false
}
</script>

<style lang="scss" scoped>
.ss-Content {
  padding-left: $page-left-padding;
  padding-right: $page-right-padding;
  width: 100%;
  max-width: $page-width-lg;
  padding-bottom: 50px;
  font-family: $system-ui;
}

.ss-Content_Description {
  font-weight: fw('regular');
  font-size: $fs-14;
  line-height: 20px;
  max-width: 600px;
  color: $dark-2;
  margin-bottom: 15px;
}

.ss-Content_Settings {
  display: grid;
  gap: 19px;
  width: 100%;
  margin-top: 16px;
}

.ss-ContentFields {
  display: flex;
  flex-direction: column;
  gap: 28px;
  max-width: 436px;
}

.ss-Actions {
  display: none;
  justify-content: center;
  gap: 8px;
}

.ss-Controls {
  margin-top: 24px;
  gap: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.ss-ContentFields_Head {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
}

.ss-TabContainer {
  display: flex;
  flex-direction: column;
  gap: 28px;
}
.ss-TabContainer_General {
  margin-top: 12px;
}
.ss-TabContainer_Projects {
  gap: 40px;
}
.ss-ProjectsTable_Type {
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: fw('regular');
  font-size: $fs-14;
  line-height: 20px;
  color: $dark-1;
}
</style>

<style lang="scss">
.ss-ProjectsTable {
  .tb-Table {
    .tb-RowWrapper {
      margin: 0 calc($page-left-padding * -1) 0 calc($page-right-padding * -1);
    }
    .tb-Row {
      padding: 0 $page-left-padding 0 $page-right-padding;
      &:hover {
        .ss-Actions {
          display: flex;
        }
      }
    }
  }
}
.ss-ModalWrapper {
  .as-AppDroplistButton_Content {
    padding: 6px;
  }
}
.ss-SynchronizationPage {
  .ss-EmptyState {
    margin-top: 64px;
    margin-bottom: 0;
  }
}
</style>
