export const SKELETON_SIZES = {
  XS: 'xs',
  SM: 'sm',
  MD: 'md',
  LG: 'lg',
  XL: 'xl'
}

export const SKELETON_APPEARANCES = {
  RECTANGLE: 'rectangle',
  CIRCLE: 'circle',
  SQUARE: 'square'
}
