<template>
  <div class="pr-PageWrapper">
    <DashboardToolbar
      v-model:search-string="searchString"
      v-model:selected-assignee-ids="selectedAssigneeIds"
      v-model:selected-group-ids="selectedGroupIds"
      v-model:selected-table-view="selectedTableView"
    />
    <router-view>
      <template #default="{ Component }">
        <component
          :is="Component"
          v-if="intervalId"
          :interval-id="intervalId"
          :search-string="searchString"
          :selected-assignee-ids="selectedAssigneeIds"
          :selected-group-ids="selectedGroupIds"
          :selected-table-view="selectedTableView"
        />
      </template>
    </router-view>
  </div>
</template>

<script setup>
import { ref } from 'vue'

import { STATUS_TABLE_VIEW } from '@/utils/dashboard'
import { DEFAULT_VALUE_FOR_FILTER } from '@/utils/okr-elements/filters'

import DashboardToolbar from '@/components/dashboard/DashboardToolbar'

defineProps({
  intervalId: {
    type: Number,
    default: 0
  }
})

defineOptions({
  name: 'PerformanceReport',
  inheritAttrs: false
})

const selectedAssigneeIds = ref(DEFAULT_VALUE_FOR_FILTER)
const selectedGroupIds = ref(DEFAULT_VALUE_FOR_FILTER)
const selectedTableView = ref(STATUS_TABLE_VIEW)
const searchString = ref('')
</script>

<style lang="scss" scoped>
.pr-PageWrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
</style>
