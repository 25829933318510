<template>
  <div class="fgas-Wrapper" data-auto-testid="form-grade-and-status">
    <OkrGradeValue :grade="grade" />

    <OkrGradeChart
      :grade="grade"
      :grade-color="isManualGrade ? gradeColor : null"
      :predicted-score="predictedScore"
      :show-predicted-score="showPredictedScore"
      class="fgas-Chart"
    />
    <div class="fgas-Status">
      <StatusSelect
        :disabled="disabled"
        :objective="formModel"
        autocalculate-toggle
        class="fgas-StatusSelect"
        @update:confidence-level-id="$emit('update:confidence-level-id', $event)"
      />

      <slot name="status-right" />
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'

import { OKR_STATUSES } from '@/utils/objectives'

import OkrGradeValue from '@/components/form/OkrGradeValue'
import OkrGradeChart from '@/components/objectives/grade-chart/OkrGradeChart'
import StatusSelect from '@/components/objectives/status-select/StatusSelect'

defineOptions({
  name: 'FormGradeAndStatus'
})

const props = defineProps({
  grade: {
    type: Number,
    required: true
  },

  formModel: {
    type: Object,
    required: true
  },

  disabled: {
    type: Boolean
  },

  predictedScore: {
    type: Number,
    default: -Infinity
  },

  showPredictedScore: {
    type: Boolean
  },

  gradeColor: {
    type: String,
    required: true
  }
})

defineEmits(['update:confidence-level-id'])

const isManualGrade = computed(() => {
  return props.formModel.confidenceLevelId !== OKR_STATUSES.AUTO
})
</script>

<style lang="scss" scoped>
.fgas-Wrapper {
  display: grid;
  gap: 8px;
}

.fgas-Chart {
  margin-top: 4px;
}

.fgas-Status {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  overflow: hidden;
  max-width: 100%;
  flex-wrap: wrap;
}

.fgas-StatusSelect {
  overflow: hidden;
  // width: max-content; // prevent button stretching by height
}
</style>
