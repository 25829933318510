<template>
  <div
    :class="{ 'owc-WeightsControl-hovered': showControl, 'owc-WeightsControl-disabled': disabled }"
    class="owc-WeightsControl"
    @mouseout="isHover = false"
    @mouseover="isHover = true"
  >
    <AppButton
      v-show="showControl"
      :disable="!weightMultiplier"
      class="owc-Button"
      icon="weights-minus"
      remove-padding
      size="sm"
      type="primary-next"
      @click="updateMultiplier(false)"
    />
    <span class="owc-Value">
      <AppIcon v-show="!showControl" height="24" icon-name="custom-weights-multiplier" width="24" />
      {{ resolvedWeightMultiplier }}
      <AppIcon v-show="!showControl" height="24" icon-name="custom-weights" width="24" />
    </span>
    <AppButton
      v-show="showControl"
      class="owc-Button"
      icon="plus-next"
      remove-padding
      size="sm"
      type="primary-next"
      @click="updateMultiplier"
    />
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'

import {
  CONTRIBUTE_DEFAULT_WEIGHT_MULTIPLIER,
  UNCONTRIBUTE_DEFAULT_WEIGHT_MULTIPLIER
} from '@/utils/okr-weights-helpers'

import AppButton from '@/components/ui/AppButton/AppButton'
import AppIcon from '@/components/ui/AppIcon/AppIcon'

defineOptions({
  name: 'ObjectiveWeightControl'
})

const props = defineProps({
  weightMultiplier: {
    type: Number,
    required: true
  },

  disabled: {
    type: Boolean,
    required: true
  },

  isContribute: {
    type: Boolean
  }
})

const resolvedWeightMultiplier = computed(() => {
  const disabledValue = props.isContribute
    ? CONTRIBUTE_DEFAULT_WEIGHT_MULTIPLIER
    : UNCONTRIBUTE_DEFAULT_WEIGHT_MULTIPLIER
  return props.disabled ? disabledValue : props.weightMultiplier
})

const emit = defineEmits({ 'update-weight-multiplier': null })

const isHover = ref(false)

const showControl = computed(() => !props.disabled && isHover.value)

const updateMultiplier = (isIncrease = true) => {
  if ((!isIncrease && !props.weightMultiplier) || !showControl.value) return
  const newMultiplier = isIncrease ? props.weightMultiplier + 1 : props.weightMultiplier - 1
  emit('update-weight-multiplier', newMultiplier)
}
</script>

<style lang="scss" scoped>
.owc-WeightsControl {
  display: grid;

  align-items: center;
  text-align: center;
  border: 2px solid transparent;
  border-radius: $border-radius-sm-next;
  padding: 2px;
  min-height: 32px;

  width: 100px;
  margin: 0 auto;

  &-hovered {
    grid-template-columns: 24px 1fr 24px;
  }

  &:not(&-disabled) {
    @media (any-hover: hover) {
      &:hover {
        border-color: $primary-color-next;
      }
    }
  }
}

.owc-Value {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  &:not(.owc-WeightsControl-disabled &) {
    color: $dark-1;
  }

  .owc-WeightsControl-disabled & {
    color: $grey-2-next;
  }

  .owc-WeightsControl-hovered & {
    grid-column-start: 2;
    width: 44px;
  }

  padding: 0 2px;
  font-size: $fs-14;
  font-weight: fw('semi-bold');
  color: $dark-1;
}
</style>
