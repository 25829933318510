<template>
  <div class="cte-TableExpandable">
    <AppTable
      :columns="columns"
      :data="data"
      :disable-user-select="disableUserSelect"
      :loading="loading"
      :offset-left="offsetLeft"
      :offset-right="offsetRight"
      :sticky-header="stickyHeader"
      class="cte-AppTable"
      no-border
      type="primary-next"
    >
      <template #header-cell="{ column }">
        <slot :column="column" name="header-cell" />
      </template>
      <template #row="rowSlotData">
        <TableExpandableRow
          :child-items="childItems"
          :column-styles="rowSlotData.columnStyles"
          :columns="rowSlotData.columns"
          :depth="0"
          :hide-expand-button-in-no-expandable-row="hideExpandButtonInNoExpandableRow"
          :hover-row="hoverRow"
          :index="rowSlotData.index"
          :item="rowSlotData.row"
          :item-value="itemValue"
          :offset-left="offsetLeft"
          :offset-right="offsetRight"
          :on-cell-click-self="rowSlotData.onCellClickSelf"
          :on-row-click-self="rowSlotData.onRowClickSelf"
          :row-is-expandable="rowIsExpandable"
          :row-is-expanded="rowIsExpanded"
          @expand="expand"
        >
          <template #cell="slotData">
            <slot
              :column-key="slotData.columnKey"
              :depth="slotData.depth"
              :index="slotData.index"
              :item="slotData.item"
              name="cell"
            />
          </template>

          <template #row-wrapper-after="{ item, childOffset }">
            <slot
              :child-items="childItems"
              :child-offset="childOffset"
              :item="item"
              :row-slot-data="rowSlotData"
              name="row-wrapper-after"
            />
          </template>
        </TableExpandableRow>
      </template>

      <template #loading>
        <slot name="loading" />
      </template>

      <template #footer>
        <slot name="footer" />
      </template>
    </AppTable>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

import { stringOrNullProp } from '@/utils/prop-validators'

import AppTable from '@/components/ui/Table/AppTable'

import { getRowId } from './table-expandable-utils'
import TableExpandableRow from './TableExpandableRow'

export default defineComponent({
  name: 'TableExpandable',

  components: {
    AppTable,
    TableExpandableRow
  },

  props: {
    columns: {
      type: Array,
      required: true
    },

    data: {
      type: Array,
      required: true
    },

    rowIsExpandable: {
      type: Object,
      default: () => ({})
    },

    itemValue: {
      type: String,
      default: 'id'
    },

    childItems: {
      type: Object,
      default: () => ({})
    },

    loading: {
      type: Boolean,
      default: false
    },

    hideExpandButtonInNoExpandableRow: {
      type: Boolean
    },

    offsetLeft: {
      type: [String, Number],
      default: ''
    },

    offsetRight: {
      type: [String, Number],
      default: ''
    },

    stickyHeader: {
      type: Boolean
    },

    hoverRow: {
      default: null,
      validator: v => stringOrNullProp(v)
    },

    disableUserSelect: {
      type: Boolean,
      default: true
    },

    isAutoCollapse: {
      type: Boolean,
      default: true
    }
  },

  emits: { expand: null },

  data() {
    return {
      rowIsExpanded: {}
    }
  },

  watch: {
    // if we delete last nested item, and then we added new item, parent row will be expanded by default
    // because we expand it earlier and this might be confusing for user
    // coz row will be expanded but haven't any nested items
    // once we delete last nested item, we need to collapse parent row
    childItems: {
      handler(newValue) {
        if (this.isAutoCollapse) {
          const childItemsKeys = Object.keys(newValue)

          for (let key in this.rowIsExpanded) {
            const rowIsExpandedKeyWithoutDepth = key.split('_')[1]
            if (!childItemsKeys.includes(rowIsExpandedKeyWithoutDepth)) {
              delete this.rowIsExpanded[key]
            }
          }
        }
      },

      immediate: true,
      deep: true
    }
  },

  methods: {
    /** @public */
    expandRootLevelElement({ item }) {
      const rowId = getRowId({ depth: 0, id: item[this.itemValue] })
      const rowIsExpanded = this.rowIsExpanded[rowId]

      const index = this.data.findIndex(i => i[this.itemValue] === item[this.itemValue])

      if (index !== -1) {
        if (!rowIsExpanded) {
          this.$emit('expand', { item, index })
        }

        this.rowIsExpanded[rowId] = !rowIsExpanded
      }
    },

    /** @public */
    expand({ item, depth, index }) {
      const rowId = getRowId({ depth, id: item[this.itemValue] })
      const rowIsExpanded = this.rowIsExpanded[rowId]
      if (!rowIsExpanded) {
        this.$emit('expand', { item, index })
      }
      this.rowIsExpanded[rowId] = !rowIsExpanded
    },

    /** @public */
    collapseAll() {
      this.rowIsExpanded = {}
    }
  }
})
</script>

<style lang="scss">
.cte-TableExpandable .cte-AppTable .tb-RowWrapper {
  border-bottom: none;
  &:hover {
    background-color: inherit;
  }

  &:after {
    display: none;
  }
}

.cte-TableExpandable .cte-AppTable {
  .tb-Table-primary-next.tb-Table-with-offset .tb-RowWrapper {
    padding-bottom: 0;
  }
}
</style>
