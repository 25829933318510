export const ACTIONS_KEYS = {
  DELETE: 'delete',
  EDIT: 'edit',
  REMOVE: 'remove',
  SYNC_NOW: 'syncNow',
  EXPORT: 'export',
  DEFAULT: 'default',
  ROLE: 'role',
  RENAME: 'rename',
  LINK_NEW_PLATFORM_GROUP: 'linkNewPlatformGroup',
  UNLINK_PLATFORM_GROUP: 'unlinkPlatformGroup',
  CHANGE_ROLE: 'changeRole',
  CHANGE_DEFAULT_GROUP: 'changeDefaultGroup',
  ADD_TO_FAVORITES: 'addToFavorites',
  REMOVE_FROM_FAVORITES: 'removeFromFavorites',
  DUPLICATE: 'duplicate',
  SHARE: 'share',
  TOGGLE_PRESENTATION: 'togglePresentation',
  ADD_SUB_GROUP: 'addSubGroup',
  MANAGE_USERS: 'manageUsers',
  ADD_TO_GROUP: 'addToGroup',
  ADD_TO_WORKSPACE: 'addToWorkspace',
  MANAGE_GROUP: 'manageGroup',
  REMOVE_FROM_GROUP: 'removeFromGroup',
  MERGE_GROUPS: 'mergeGroups',
  ACTIVATE: 'activate',
  INACTIVATE: 'inactivate',
  COPY_LINK: 'copyLink',
  GRANT_ACCESS: 'grantAccess',
  REVOKE_ACCESS: 'revokeAccess',
  GO_TO_PROFILE: 'goToProfile',
  SWITCH_ORGANIZATION: 'switchOrganization',
  LOGOUT: 'logout',
  BULK_EDIT: 'bulkEdit'
}
