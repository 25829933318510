<template>
  <div class="ml-List">
    <div class="ml-ScrollContainer">
      <div v-for="(group, groupIndex) in items" :key="`${group.title}-wrapper`" class="ml-Wrapper">
        <div class="ml-Title">
          {{ group.title }}
        </div>
        <div
          v-for="(commandItem, commandIndex) in group.commands"
          :key="commandItem.id"
          :ref="data => setActiveItem(data, isSelected(groupIndex, commandIndex))"
        >
          <AppButton
            :class="{ 'ml-Button-active': isSelected(groupIndex, commandIndex) }"
            :icon="commandItem.iconName"
            :style="commandItem.styles"
            class="ml-Button"
            style="--button-high-padding: 10px; --button-low-padding: 10px"
            type="outline"
            @click="selectItem(commandItem)"
          >
            <span class="ml-Button_Text">{{ commandItem.label }}</span>
          </AppButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, onMounted, onBeforeUnmount } from 'vue'

import { KEY_CODES } from '@/utils/key-codes'

import AppButton from '@/components/ui/AppButton/AppButton'

const props = defineProps({
  items: {
    type: Array,
    required: true
  },

  command: {
    // передается из VueRenderer (Suggestion feature)
    type: Function,
    required: true
  }
})

const activeItem = ref(null)
const selectedGroupIndex = ref(0)
const selectedCommandIndex = ref(0)

const setActiveItem = (data, isSelected) => {
  if (isSelected) {
    activeItem.value = data
  }
}

const selectItem = command => {
  props.command(command)
}

const isSelected = (groupIndex, commandIndex) => {
  return selectedGroupIndex.value === groupIndex && selectedCommandIndex.value === commandIndex
}

watch(
  () => props.items,
  () => {
    selectedGroupIndex.value = 0
    selectedCommandIndex.value = 0
  }
)

const handleKeyDown = ({ event }) => {
  if (event.keyCode === KEY_CODES.DOWN_ARROW) {
    event.preventDefault()
    event.stopPropagation()
    event.stopImmediatePropagation()
    if (!props.items.length) return false

    let newCommandIndex = selectedCommandIndex.value + 1
    let newGroupIndex = selectedGroupIndex.value
    const commands = props.items[selectedGroupIndex.value].commands

    if (newCommandIndex >= commands.length) {
      newCommandIndex = 0
      newGroupIndex++
    }

    if (newGroupIndex >= props.items.length) {
      newGroupIndex = 0
    }

    selectedCommandIndex.value = newCommandIndex
    selectedGroupIndex.value = newGroupIndex

    return true
  }

  if (event.keyCode === KEY_CODES.UP_ARROW) {
    event.preventDefault()
    event.stopPropagation()
    event.stopImmediatePropagation()
    if (!props.items.length) return false

    let newCommandIndex = selectedCommandIndex.value - 1
    let newGroupIndex = selectedGroupIndex.value

    if (newCommandIndex < 0) {
      newGroupIndex--
      newCommandIndex = props.items[newGroupIndex]?.commands.length - 1 || 0
    }

    if (newGroupIndex < 0) {
      newGroupIndex = props.items.length - 1
      newCommandIndex = props.items[newGroupIndex].commands.length - 1
    }

    selectedCommandIndex.value = newCommandIndex
    selectedGroupIndex.value = newGroupIndex

    return true
  }

  if (event.keyCode === KEY_CODES.ENTER) {
    if (!props.items.length || selectedGroupIndex.value === -1 || selectedCommandIndex.value === -1)
      return false
    const command = props.items[selectedGroupIndex.value].commands[selectedCommandIndex.value]

    selectItem(command)

    return true
  }

  return false
}

onMounted(() => {
  window.addEventListener('keydown', handleKeyDown)
})

watch(activeItem, () => {
  if (activeItem.value) {
    activeItem.value.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest'
    })
  }
})

onBeforeUnmount(() => {
  window.removeEventListener('keydown', handleKeyDown)
  activeItem.value = null
})

defineExpose({
  handleKeyDown
})
</script>

<style lang="scss">
@import '~@/assets/styles/mixins';

.ml-ScrollContainer {
  width: 200px;
  box-shadow: 0 0 1px 0 rgba($dark-1, 0.31), 0px 3px 5px 0px rgba($dark-1, 0.2);
  max-height: 300px;
  overflow: auto;
  border-radius: $border-radius-sm-next;
  @include styled-native-scrollbar();
}

.ml-Wrapper {
  display: grid;
}

.ml-Title {
  font-weight: fw('bold');
  font-size: $fs-12;
  line-height: 16px;
  color: $dark-3;
  margin: 18px 10px 10px;
}

.ml-Button {
  justify-content: flex-start;
  border: none;
  color: $dark-2;
  font-size: $fs-14;
  line-height: 20px;
  height: 44px;
  border-radius: 0;
  width: 100%;

  &-active {
    background-color: $grey-3-next;
  }
}

.ml-Button_Text {
  color: $dark-1;
  font-size: var(--font-size, #{$fs-14});
  font-weight: var(--font-weight, #{fw('regular')});
}

.ml-List {
  overflow: hidden;
}
</style>
