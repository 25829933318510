import CustomDashboardApiHandler from '@/api/custom-dashboard'
import {
  DASHBOARD_SET_DASHBOARD_LIST,
  DASHBOARD_SET_DASHBOARD_ITEM,
  TOGGLE_FAVORITE,
  DASHBOARD_SET_FAVORITE_DASHBOARD_LIST,
  DASHBOARD_SET_COUNT,
  SET_FILTERS
} from '@/store/mutation-types'
import { tracker } from '@/tracking/amplitude'
import { EVENT_CATEGORIES, EVENT_NAMES } from '@/tracking/amplitude-helpers'
import { handleError } from '@/utils/error-handling'

const state = {
  dashboardList: [],
  favoriteList: [],
  dashboardItem: {},
  dashboardTotalCount: 0,
  filters: {} // { page, pageSize, search, sort, order } need to sync requests from AppMenu.vue and DashboardList.vue
}

const getters = {}

const actions = {
  async getFavoriteList({ commit, rootGetters }, payload = {}) {
    const { showCustomDashboardForUsers } = rootGetters['system/dashboardsVisibility']

    if (!showCustomDashboardForUsers) {
      commit(DASHBOARD_SET_FAVORITE_DASHBOARD_LIST, [])
    } else {
      const customDashboardApi = new CustomDashboardApiHandler()
      try {
        const data = await customDashboardApi.getDashboardList(payload)

        commit(DASHBOARD_SET_FAVORITE_DASHBOARD_LIST, data)
      } catch (error) {
        handleError({ error })
      }
    }
  },

  async getDashboardList({ commit, dispatch, state, rootGetters }) {
    const { showCustomDashboardForUsers } = rootGetters['system/dashboardsVisibility']

    if (!showCustomDashboardForUsers) {
      commit(DASHBOARD_SET_DASHBOARD_LIST, [])
    } else {
      const customDashboardApi = new CustomDashboardApiHandler()
      try {
        dispatch('getDashboardCount', state.filters)
        const data = await customDashboardApi.getDashboardList(state.filters)
        commit(DASHBOARD_SET_DASHBOARD_LIST, data)
      } catch (error) {
        handleError({ error })
      }
    }
  },

  async getDashboardCount({ commit }, payload) {
    const customDashboardApi = new CustomDashboardApiHandler()
    try {
      const { count } = await customDashboardApi.getDashboardCount(payload)

      commit(DASHBOARD_SET_COUNT, count)
    } catch (error) {
      handleError({ error })
    }
  },

  async getDashboardItem({ commit, state }, id) {
    const customDashboardApi = new CustomDashboardApiHandler()
    const dashboardId = id || state.dashboardItem.id
    if (!dashboardId) {
      return
    }
    // eslint-disable-next-line no-useless-catch
    try {
      const data = await customDashboardApi.getDashboard({ id: dashboardId })
      commit(DASHBOARD_SET_DASHBOARD_ITEM, data)
      return data
    } catch (error) {
      throw error
    }
  },

  async toggleFavorite({ commit }, payload) {
    const customDashboardApi = new CustomDashboardApiHandler()
    try {
      if (payload.favorite) {
        await customDashboardApi.removeFromFavorites({ dashboardIds: [payload.id] })
        tracker.logEvent(EVENT_NAMES.CHANGE_FAVORITES, {
          category: EVENT_CATEGORIES.DASHBOARD,
          mode: 'removed',
          id: payload.id
        })
      } else {
        await customDashboardApi.addToFavorites({ dashboardIds: [payload.id] })
        tracker.logEvent(EVENT_NAMES.CHANGE_FAVORITES, {
          category: EVENT_CATEGORIES.DASHBOARD,
          mode: 'added',
          id: payload.id
        })
      }
      commit(TOGGLE_FAVORITE, payload)
    } catch (error) {
      handleError({ error })
    }
  },

  setFilters({ commit }, payload = {}) {
    commit(SET_FILTERS, payload)
  }
}

const mutations = {
  [DASHBOARD_SET_FAVORITE_DASHBOARD_LIST](state, favoriteList) {
    state.favoriteList = favoriteList
  },

  [DASHBOARD_SET_DASHBOARD_LIST](state, dashboardList) {
    state.dashboardList = dashboardList
  },

  [DASHBOARD_SET_DASHBOARD_ITEM](state, dashboardItem) {
    state.dashboardItem = dashboardItem
  },

  [TOGGLE_FAVORITE](state, payload) {
    const toggleFavorite = (list, item) => {
      const index = list.findIndex(dashboard => dashboard.id === item.id)
      if (index !== -1) {
        list[index].favorite = !list[index].favorite
      }
    }

    toggleFavorite(state.dashboardList, payload)

    toggleFavorite(state.favoriteList, payload)
  },

  [DASHBOARD_SET_COUNT](state, dashboardTotalCount) {
    state.dashboardTotalCount = dashboardTotalCount
  },

  [SET_FILTERS](state, payload) {
    state.filters = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
