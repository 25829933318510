<template>
  <AppSelect
    :dropdown-min-width="200"
    :is-highlighted="!!interval"
    :loading="loading"
    :model-value="interval"
    :offset="[0, 0]"
    :options="options"
    :search="false"
    :search-max-length="50"
    :type="SELECT_TYPES.SECONDARY_NEXT"
    append-icon-height="20"
    append-icon-width="20"
    class="ci-IntervalSelect"
    has-fixed-parent
    highlight-option
    icon="calendar-next"
    item-label="name"
    item-value="id"
    n-selected-label="field.quoter.title"
    no-left-padding
    position="bottom-start"
    prepend-icon="calendar-next"
    skeleton-loader
    skeleton-loader-height="100%"
    skeleton-loader-width="100%"
    style="--select-skeleton-left: 0; --select-skeleton-top: 0"
    @change="selectIntervals"
  >
    <template #option="intervalProps">
      <IntervalSelectOption v-bind="intervalProps" />
    </template>
    <template #button-content="{ option }">
      <AppSelectItem
        :show-delete-button="false"
        :show-selected-count-circle="false"
        :type="SELECT_TYPES.SECONDARY_NEXT"
      >
        <div>
          <span>
            {{ option?.name }}
          </span>
        </div>
      </AppSelectItem>
    </template>
  </AppSelect>
</template>

<script setup>
import { watch, ref, onMounted } from 'vue'

import { getIntervals } from '@/composables/fetchingData'
import { SELECT_TYPES } from '@/utils/components-configurations/app-select'
import { getSuitableInterval } from '@/utils/interval'

import IntervalSelectOption from '@/components/objectives/toolbar/IntervalSelectOption'
import AppSelect from '@/components/ui/AppSelect/AppSelect'
import AppSelectItem from '@/components/ui/AppSelect/AppSelectItem'

const emit = defineEmits({
  'update:interval': null
})
const props = defineProps({
  workspaceId: {
    type: Number,
    default: null
  },
  interval: {
    type: Number,
    default: null
  }
})

watch(
  () => props.workspaceId,
  async newValue => {
    if (newValue !== null) {
      await fetchIntervals()
    }
  }
)
const loading = ref(false)
const options = ref([])

const fetchIntervals = async () => {
  try {
    loading.value = true
    const { options: data, index } = await getIntervals(props.workspaceId)
    options.value = data.value
    if (!props.interval) {
      emit('update:interval', getSuitableInterval(data.value, options.value[index], true))
    }
  } finally {
    loading.value = false
  }
}
onMounted(() => {
  fetchIntervals()
})
const selectIntervals = value => {
  emit('update:interval', value)
}
</script>
<script>
//eslint-disable-next-line
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'DashboardIntervals'
})
</script>

<style lang="scss" scoped>
.ci-IntervalSelect {
  max-width: 220px;
}
</style>
