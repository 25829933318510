import ApiHandler from '@/api/base'

class WebAppAdminsApiHandler extends ApiHandler {
  async updateAdminRole({ accountId, adminRoleId }) {
    const { data } = await this.http.put('/admin/role', {
      accountId,
      adminRoleId
    })
    return data
  }
}

export default WebAppAdminsApiHandler
