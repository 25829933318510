<template>
  <indicatorPanel />
</template>
<script setup>
import { computed, h } from 'vue'

import { getResolvedWidth } from '@/utils/indicator-panel-helper'
import { indicatorPanelValidator } from '@/utils/prop-validators'

defineOptions({
  name: 'IndicatorPanel'
})

const props = defineProps({
  indicatorWidth: {
    type: [String, Number],
    default: 0,
    validator: indicatorPanelValidator
  },

  color: {
    type: String,
    default: '#0052CC'
  },

  backgroundColor: {
    type: String,
    default: '#E5EEFA'
  },

  rounded: {
    type: Boolean,
    default: true
  },

  roundedBar: {
    type: Boolean
  }
})

const resolvedIndicatorWidth = computed(() => {
  const resolvedWidth = getResolvedWidth(props.indicatorWidth)
  return `${resolvedWidth}%`
})

const indicatorPanel = () => {
  return h('div', {
    class: {
      'ip-IndicatorPanel': true,
      'ip-IndicatorPanel-rounded': props.rounded,
      'ip-IndicatorPanel-rounded-bar': props.roundedBar
    }
  })
}
</script>

<style lang="scss" scoped>
// eslint-disable-next-line
.ip-IndicatorPanel {
  position: relative;
  height: var(--height, 7px);
  width: 100%;
  overflow: hidden;
  background-color: v-bind(backgroundColor);

  &:after {
    content: '';
    position: absolute;
    height: 100%;
    background-color: v-bind(color);
    width: v-bind(resolvedIndicatorWidth);
    animation: loading cubic-bezier(0.17, 0.67, 0.83, 0.67) 0.5s;
    border-radius: var(--border-radius-progress, $border-radius-sm-next);
  }
  // eslint-disable-next-line
  &-rounded {
    border-radius: var(--border-radius, $border-radius-sm);
  }
  // eslint-disable-next-line
  &-rounded-bar {
    &:after {
      border-radius: inherit;
    }
  }
}

@keyframes loading {
  from {
    width: 0;
  }
  to {
    width: v-bind(resolvedIndicatorWidth);
  }
}
</style>
