<template>
  <div class="gs-Step">
    <slot name="step-title" />

    <InfiniteTableExpandable
      :child-items-key="CHILD_ITEMS_KEY"
      :columns="COLUMNS"
      :disable-user-select="false"
      :hover-row="tableHoverRow"
      :list-state="listState"
      :loading="listState.isLoading"
      :offset-left="TABLE_OFFSET"
      :offset-right="TABLE_OFFSET"
      class="gs-Table"
      sticky-header
      @toggle-expand-collapse="onExpandCollapseItem({ itemId: $event })"
    >
      <template #header-cell="{ column }">
        <template v-if="column.title">
          {{ $t(column.title) }}
        </template>
      </template>

      <template #cell="{ item, columnKey }">
        <template v-if="columnKey === TABLE_COLUMNS_KEYS.NAME">
          <GlobalGroupNameCell
            :filtered-out="!item.fitFilterCriteria"
            :group="item"
            hide-actions
            hide-group-info
            show-rename-button
            skip-permissions-check
            @click="onExpandCollapseItem({ itemId: item.id })"
            @group-updated="reloadAllGroups"
          />
        </template>
      </template>

      <template #footer>
        <NoSearchResults
          v-if="showNoSearchResults"
          :offset-left="TABLE_OFFSET"
          :offset-right="TABLE_OFFSET"
        >
          {{ $t('groups.no_groups') }}
        </NoSearchResults>

        <InfiniteScrollLoaderNext
          v-if="!listState.isAllItemsLoaded"
          :loading="listState.isLoading"
          @load-more="onLoadMore"
        />
      </template>

      <template #loading>
        <GlobalGroupsLoader
          :rows-count="7"
          :style="{
            '--inline-padding': TABLE_OFFSET
          }"
          hide-labels
          hide-workspaces
        />
      </template>
    </InfiniteTableExpandable>

    <slot name="step-message" />
  </div>
</template>

<script setup>
import { isEmpty } from 'lodash'
import { computed, ref } from 'vue'

import { useGlobalGroups } from '@/composables/global-groups'
import { CHILD_ITEMS_KEY } from '@/utils/global-groups'
import { TABLE_COLUMNS_KEYS } from '@/utils/table-columns'

import GlobalGroupNameCell from '@/components/global-groups/GlobalGroupNameCell'
import InfiniteScrollLoaderNext from '@/components/ui/InfiniteScrollLoaderNext'
import InfiniteTableExpandable from '@/components/ui/InfiniteTableExpandable/InfiniteTableExpandable'
import NoSearchResults from '@/components/ui/NoSearchResults/NoSearchResults'
import GlobalGroupsLoader from '@/components/ui/SkeletonLoaders/GlobalGroupsLoader'

defineOptions({
  name: 'GroupsStep'
})

const TABLE_OFFSET = '16px'

const COLUMNS = [
  {
    key: TABLE_COLUMNS_KEYS.NAME,
    title: 'group.label.name',
    width: 'auto'
  }
]

const { useGlobalGroupsTableHelpers } = useGlobalGroups()

const { listState, fetchGroupsForTable, reloadAllGroups, isFiltersUsed, onExpandCollapseItem } =
  useGlobalGroupsTableHelpers({
    childItemsKey: CHILD_ITEMS_KEY,
    saveExpandedItemsAndFiltersToQuery: false
  })

const tableHoverRow = ref(null)

const isNoGroups = computed(() => {
  return !listState.value.isLoading && isEmpty(listState.value.items)
})

const getGroupsForTable = async () => {
  await fetchGroupsForTable({
    expandAll: true
  })
}

const onLoadMore = async () => {
  if (!listState.value.isLoading) {
    await getGroupsForTable()
  }
}

const showNoSearchResults = computed(() => isNoGroups.value && isFiltersUsed.value)
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/wizard-steps';

.gs-Step {
  @extend %wizard-step-styles;
}

.gs-Table {
  width: 100%;
  --head-padding-bottom: 6px;
  --row-border-radius: #{$border-radius-sm-next};

  &:deep(.iter-Cell-name) {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
