<template>
  <div class="ctl-MenuListWrapper">
    <div
      v-for="([group, groupItems], groupIndex) in Object.entries(groups)"
      :key="group"
      class="ctl-MenuListWrapper-GroupItem"
    >
      <div v-if="groupIndex !== 0" class="ctl-MenuListWrapper-Divider" />
      <div v-for="item in groupItems" :key="item.name">
        <AppButton
          :disable="isDisableAction(item.action)"
          :icon="item.icon"
          class="ctl-MenuListWrapper-Item"
          size="sm"
          type="secondary-inline-next"
          @click="execAction(item.action)"
        >
          <span :style="{ '--color': item.color }" class="ctl-Item-Text">
            {{ $t(`action.${item.title}`) }}
          </span>
        </AppButton>
      </div>
    </div>
  </div>
</template>

<script setup>
import { groupBy, has } from 'lodash'
import { computed } from 'vue'

import AppButton from '@/components/ui/AppButton/AppButton'

defineOptions({
  name: 'CustomTableListActions'
})

const emit = defineEmits({
  execAction: null
})
const props = defineProps({
  editor: {
    type: Object,
    required: true
  },
  cellActions: {
    type: Array,
    required: true
  }
})
const needGrouping = computed(() => {
  return props.cellActions.every(item => has(item, 'group'))
})

const groups = computed(() => {
  return needGrouping.value ? groupBy(props.cellActions, 'group') : { all: props.cellActions }
})

const execAction = action => {
  emit('execAction', action)
}
const isDisableAction = action => {
  return !props.editor.can()[action]?.()
}
</script>

<style lang="scss" scoped>
.ctl-MenuListWrapper {
  border-radius: $border-radius-sm-next;
  background: $white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: $system-ui;
  overflow: hidden;

  &-GroupItem {
    width: 100%;
  }

  &-Divider {
    background: $grey-2-next;
    height: 1px;
    margin: 4px 10px;
  }

  &-Item {
    font-weight: fw('regular');
    font-family: $system-ui;
    font-size: $fs-14;
    line-height: 20px;
    height: 44px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    border-radius: 0;
    padding: 0 10px;

    &:hover {
      &:not(.ab-Button-disabled) {
        background-color: rgba($primary-color-next, 0.15);
      }
    }
  }
}
.ctl-Item-Text {
  color: var(--color);
}
</style>
