import { ref } from 'vue'
import { useRoute } from 'vue-router'

import ShortLinkApiHandler from '@/api/short-link'
import { handleError } from '@/utils/error-handling'

export const useCopySharedLinkModal = () => {
  const shareLinkLoading = ref(false)
  const showCopyShareLinkModal = ref(false)
  const shareToken = ref('')
  const route = useRoute()

  const onCopyClick = async () => {
    shareLinkLoading.value = true

    try {
      const api = new ShortLinkApiHandler()
      const data = await api.createShortLink({ link: route.fullPath })
      shareToken.value = data.token
      showCopyShareLinkModal.value = true
    } catch (error) {
      handleError({ error })
    } finally {
      shareLinkLoading.value = false
    }
  }

  return {
    onCopyClick,
    shareToken,
    shareLinkLoading,
    showCopyShareLinkModal
  }
}
