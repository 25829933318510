<template>
  <div class="ctl-Loader">
    <div v-for="row in ROWS" :key="row" class="ctl-Loader_Row">
      <div class="ctl-Cell">
        <SkeletonItem :appearance="SKELETON_APPEARANCES.SQUARE" :size="SM" />
        <SkeletonItem :size="XS" :width="row.name" />
      </div>

      <div class="ctl-Cell">
        <SkeletonItem :size="XS" :width="row.frequency" />
      </div>

      <div class="ctl-Cell">
        <SkeletonItem :size="XS" :width="row.reminder" />
      </div>

      <div class="ctl-Cell">
        <SkeletonItem :appearance="SKELETON_APPEARANCES.SQUARE" :size="SM" />
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  SKELETON_APPEARANCES,
  SKELETON_SIZES
} from '@/utils/components-configurations/skeleton-item'

import SkeletonItem from '@/components/ui/SkeletonLoaders/SkeletonItem'

defineOptions({
  name: 'CheckinsTableLoader'
})

const { XS, SM } = SKELETON_SIZES

const ROWS = [
  {
    id: 0,
    name: '190px',
    reminder: '120px',
    frequency: '160px'
  },
  {
    id: 1,
    name: '130px',
    reminder: '170px',
    frequency: '195px'
  },
  {
    id: 2,
    name: '490px',
    reminder: '200px',
    frequency: '140px'
  },
  {
    id: 3,
    name: '220px',
    reminder: '140px',
    frequency: '180px'
  },
  {
    id: 4,
    name: '90px',
    reminder: '180px',
    frequency: '122px'
  }
]
</script>

<style lang="scss" scoped>
.ctl-Loader_Row {
  height: 45px;
  display: flex;
  align-items: center;
  padding: 0 $page-right-padding 0 $page-left-padding;
  width: 100%;
}

.ctl-Cell {
  display: flex;
  align-items: center;

  &:not(:first-child) {
    gap: 8px;
  }

  &:first-child {
    flex: 1 1 100%;
    gap: 8px;
  }

  &:nth-child(2),
  &:nth-child(3) {
    flex: 1 0 220px;
  }

  &:nth-child(3) {
    padding-right: 12px;
    justify-content: flex-end;
  }

  &:last-child(3) {
    flex: 1 1 24px;
  }
}
</style>
