<template>
  <svg
    :data-target-id="iconConfig.targetId"
    :height="height"
    :width="width"
    aria-hidden="true"
    class="svg-icon ai-Icon"
  >
    <use :xlink:href="iconConfig.name" />
  </svg>
</template>

<script>
import { defineComponent } from 'vue'

// https://webpack.js.org/guides/dependency-management/#context-module-api
const requireAll = requireContext => requireContext.keys().map(requireContext)
const coreIcons = require.context('../../../assets/icons/', true, /\.svg$/)
requireAll(coreIcons)

export default defineComponent({
  name: 'AppIcon',
  props: {
    iconName: {
      // icon filename
      type: String,
      required: true
    },

    width: {
      type: [String, Number],
      default: 16
    },

    height: {
      type: [String, Number],
      default: 16
    }
  },

  computed: {
    iconConfig() {
      const { iconName } = this
      const targetId = iconName ? `icon-${iconName}` : ''

      return {
        targetId,
        name: `#${targetId}`
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.svg-icon {
  // width: 1em;
  // height: 1em;
  fill: currentColor; /* important */
  overflow: hidden;
}
</style>
