const EVENTS = {
  POINTER_MOVE: 'pointermove',
  POINTER_UP: 'pointerup',
  POINTER_DOWN: 'pointerdown',
  POINTER_CANCEL: 'pointercancel',
  POINTER_LEAVE: 'pointerleave',
  WHEEL: 'wheel',
  GESTURE_CHANGE: 'gesturechange',
  GESTURE_END: 'gestureend',
  GESTURE_START: 'gesturestart'
}

const {
  POINTER_MOVE,
  POINTER_UP,
  POINTER_DOWN,
  POINTER_CANCEL,
  POINTER_LEAVE,
  WHEEL,
  GESTURE_CHANGE,
  GESTURE_END,
  GESTURE_START
} = EVENTS

const getTransformStyle = ({ scale = 0, x = 0, y = 0 }) => {
  return `scale(${scale}) translate(${x}px, ${y}px)`
}

/**
 * @param {HTMLElement} element Element for which to get the transform values
 * @returns {{ scale: number, translateX: number, translateY: number }}
 *          Scale & translate values of an element
 */
export const getTransform = element => {
  const rawTransform = getComputedStyle(element).getPropertyValue('transform')
  if (rawTransform === 'none') {
    return {
      scale: 1,
      translateX: 0,
      translateY: 0
    }
  }
  const [scaleX, , , scaleY, scaledX, scaledY] = rawTransform.slice(7, -1).split(',')
  return {
    scale: Number(scaleX),
    translateX: scaledX / scaleX,
    translateY: scaledY / scaleY
  }
}

/**
 * @param {Event} ev Event which takes place in the DOM
 * @returns {boolean} Result of detection
 */
const detectTouchpad = ev => {
  // https://stackoverflow.com/a/62415754
  if (ev.wheelDeltaY !== undefined) {
    const isCase1 = ev.wheelDeltaY === ev.deltaY * -3
    const isCase2 = ev.wheelDeltaY % 120 !== 0
    return isCase1 || isCase2
  }
  return ev.deltaMode === 0
}

export const moveContainerToCanvasCenter = ({
  container,
  canvas,
  scale = 1,
  resetScale = false
}) => {
  if (resetScale) {
    // first of all we need to reset the scale
    // because we need to get the real size of the canvas
    canvas.style.transform = getTransformStyle({ scale })
  }

  const { height: containerHeight, width: containerWidth } = container.getBoundingClientRect()
  const { height: canvasHeight, width: canvasWidth } = canvas.getBoundingClientRect()
  const containerCenterX = containerWidth / 2
  const containerCenterY = containerHeight / 2
  const x = containerCenterX - canvasWidth / 2
  const y = containerCenterY - canvasHeight / 2
  canvas.style.transform = getTransformStyle({ scale, x, y })
}

/**
 * @typedef {Object} Scroller
 * @property {() => void} destroy - Function that removes attached events (clean up)
 */

/**
 * @param {Object} param
 * @param {HTMLElement} param.container Viewport-like container which defines scrollable area
 * @param {canvas} param.canvas Content that has to be available in a scrollable area
 * @param {string[]} param.ignored Array of CSS selectors to ignore (prevent grabbing)
 * @param {() => {}} callback - Function to call when container is scrolled
 * @returns {Scroller} Scroller object
 */
export const createScroller = ({ container, canvas, ignored }, callback = () => {}) => {
  const checkForIgnored = ev => {
    if (ev.target === container) {
      return false
    }

    const elements = ev.composedPath()
    const containerIndex = elements.indexOf(container)
    const elementsInContainer = elements.slice(0, containerIndex)

    // what exactly did we hit? Let's find out
    // returns true if an item in the chain is one of ignored elements
    return elementsInContainer.some(element => ignored.some(selector => element.matches(selector)))
  }

  const handlePointerDown = downEv => {
    if (downEv.button !== 0) {
      return
    }

    const isIgnoreHit = checkForIgnored(downEv)
    if (isIgnoreHit) {
      return
    }

    downEv.preventDefault()
    const { clientX: startX, clientY: startY } = downEv
    const { scale, translateX, translateY } = getTransform(canvas)

    const handlePointerMove = moveEv => {
      moveEv.preventDefault()
      const { clientX: moveX, clientY: moveY } = moveEv
      const deltaX = startX - moveX
      const deltaY = startY - moveY

      const resultedTranslateX = translateX - deltaX / scale
      const resultedTranslateY = translateY - deltaY / scale

      // eslint-disable-next-line no-param-reassign
      canvas.style.transform = getTransformStyle({
        scale,
        x: resultedTranslateX,
        y: resultedTranslateY
      })

      callback()
    }

    container.addEventListener(POINTER_MOVE, handlePointerMove)

    const cleanup = () => {
      container.removeEventListener(POINTER_MOVE, handlePointerMove)
      container.removeEventListener(POINTER_UP, cleanup)
      container.removeEventListener(POINTER_CANCEL, cleanup)
      container.removeEventListener(POINTER_LEAVE, cleanup)
    }

    container.addEventListener(POINTER_UP, cleanup)
    container.addEventListener(POINTER_CANCEL, cleanup)
    container.addEventListener(POINTER_LEAVE, cleanup)
  }

  const handleTrackpadScroll = ev => {
    ev.preventDefault()

    const isTouchpad = detectTouchpad(ev)
    if (!isTouchpad || ev.ctrlKey) {
      return
    }

    const { deltaX, deltaY } = ev
    const { scale, translateX, translateY } = getTransform(canvas)

    const resultedTranslateX = translateX - deltaX / scale
    const resultedTranslateY = translateY - deltaY / scale

    // eslint-disable-next-line no-param-reassign
    canvas.style.transform = getTransformStyle({
      scale,
      x: resultedTranslateX,
      y: resultedTranslateY
    })

    callback()
  }

  container.addEventListener(POINTER_DOWN, handlePointerDown)
  container.addEventListener(WHEEL, handleTrackpadScroll)

  return {
    destroy: () => {
      container.removeEventListener(POINTER_DOWN, handlePointerDown)
      container.removeEventListener(WHEEL, handleTrackpadScroll)
    },
    move: ({ x = 0, y = 0 }) => {
      const { scale, translateX, translateY } = getTransform(canvas)
      // eslint-disable-next-line no-param-reassign
      canvas.style.transform = getTransformStyle({ scale, x: translateX + x, y: translateY + y })

      callback()
    },
    moveTo: ({ x = 0, y = 0 }) => {
      const { scale } = getTransform(canvas)
      // eslint-disable-next-line no-param-reassign
      canvas.style.transform = getTransformStyle({ scale, x, y })

      callback()
    },
    // moveToCenterOfContainer: ({ container, canvas, scale = 1 }) => {
    //   moveContainerToCanvasCenter({ container, canvas, scale })
    //   callback()
    // },
    reset: () => {
      const { scale } = getTransform(canvas)
      // eslint-disable-next-line no-param-reassign
      canvas.style.transform = getTransformStyle({ scale })
      callback()
    }
  }
}

/**
 * @typedef {Object} Zoommer
 * @property {(scale: number) => void} zoomCenterTo - Function that sets a canvas
 *                                                    to the passed scale value
 * @property {() => void} destroy - Function that removes attached events (clean up)
 */

/**
 * @param {Object} param
 * @param {HTMLElement} param.container - Viewport-like container which defines zoomable area
 * @param {HTMLElement} param.canvas - Content that has to be available in a zoomable area
 * @param {number} param.min - Minimum value of allowed scale
 * @param {number} param.max - Maximum value of allowed scale
 * @param {() => {}} callback - Function to run when scale value changes
 * @returns {Zoommer} Zoommer object
 */
export const createZoommer = ({ container, canvas, min, max }, callback) => {
  const resolveScale = scale => {
    if (scale < min) {
      return min
    }
    if (scale > max) {
      return max
    }
    return scale
  }

  const getCoords = ({ element, ev, scale = 1 }) => {
    /**
     * Safari has a bug. clientX/Y returns the distance
     * relative to the top document instead of the closest one.
     */
    const rect = element.getBoundingClientRect()
    const scaledX = ev.clientX - rect.left
    const scaledY = ev.clientY - rect.top

    return {
      x: scaledX / scale,
      y: scaledY / scale
    }
  }

  const zoomTo = ({ containerX, containerY, canvasX, canvasY, scale }) => {
    const resolvedScale = resolveScale(scale)
    const offsetX = (containerX - canvasX * resolvedScale) / resolvedScale
    const offsetY = (containerY - canvasY * resolvedScale) / resolvedScale
    // eslint-disable-next-line no-param-reassign
    canvas.style.transform = getTransformStyle({
      scale: resolvedScale,
      x: offsetX,
      y: offsetY
    })

    const resultedTransform = getTransform(canvas)
    callback(resultedTransform)
  }

  const handleMouseWheel = ev => {
    ev.preventDefault()

    const isTouchpad = detectTouchpad(ev)
    if (isTouchpad && !ev.ctrlKey) {
      return
    }

    const { scale } = getTransform(canvas)
    const { x: containerX, y: containerY } = getCoords({
      element: container,
      ev
    })
    const { x: canvasX, y: canvasY } = getCoords({
      element: canvas,
      ev,
      scale
    })

    const getFactor = (delta, multiplier) => {
      const isZoom = delta < 0
      const normalizedDelta = Math.sqrt(Math.abs(delta))
      const stepUp = (normalizedDelta * multiplier) / 100

      if (isZoom) {
        return (multiplier + stepUp) / multiplier
      }
      const stepDown = stepUp / (1 + normalizedDelta / 100)
      return (multiplier - stepDown) / multiplier
    }

    const factor = getFactor(ev.deltaY, scale)
    const newScale = scale * factor

    zoomTo({
      containerX,
      containerY,
      canvasX,
      canvasY,
      scale: newScale
    })
  }

  const handleGesturestart = startEv => {
    startEv.preventDefault()

    const { scale } = getTransform(canvas)
    const { x: containerX, y: containerY } = getCoords({
      element: container,
      ev: startEv
    })
    const { x: canvasX, y: canvasY } = getCoords({
      element: canvas,
      ev: startEv,
      scale
    })

    const handleGesturechange = changeEv => {
      changeEv.preventDefault()
      const newScale = scale * changeEv.scale

      zoomTo({
        containerX,
        containerY,
        canvasX,
        canvasY,
        scale: newScale
      })
    }

    container.addEventListener(GESTURE_CHANGE, handleGesturechange)

    const cleanup = () => {
      container.removeEventListener(GESTURE_CHANGE, handleGesturechange)
      container.removeEventListener(GESTURE_END, cleanup)
    }

    container.addEventListener(GESTURE_END, cleanup)
  }

  const addScaleEventHandlers = () => {
    container.addEventListener(WHEEL, handleMouseWheel)
    container.addEventListener(GESTURE_START, handleGesturestart)
  }

  const removeScaleEventHandlers = () => {
    container.removeEventListener(WHEEL, handleMouseWheel)
    container.removeEventListener(GESTURE_START, handleGesturestart)
  }

  addScaleEventHandlers()

  return {
    zoomCenterTo: targetScale => {
      const rect = container.getBoundingClientRect()
      const fakeEvent = {
        clientX: rect.left + container.clientWidth / 2,
        clientY: rect.top + container.clientHeight / 2
      }
      const { scale } = getTransform(canvas)
      const { x: containerX, y: containerY } = getCoords({
        element: container,
        ev: fakeEvent
      })
      const { x: canvasX, y: canvasY } = getCoords({
        element: canvas,
        ev: fakeEvent,
        scale
      })

      zoomTo({
        containerX,
        containerY,
        canvasX,
        canvasY,
        scale: targetScale
      })
    },
    destroy: () => {
      removeScaleEventHandlers()
    },

    disableScale: () => {
      removeScaleEventHandlers()
    },

    enableScale: () => {
      addScaleEventHandlers()
    }
  }
}
