<template>
  <div ref="timelinesList" class="ot-TimelinesList">
    <OkrTimelinesListItem
      v-for="elementId in modelValue"
      :key="elementId"
      :ref="setReference"
      :active-view="activeView"
      :depth="depth"
      :expanded="
        listState.filtersValues[$options.FILTERS_KEYS.EXPANDED_ITEMS][`${elementId}-${depth}`]
      "
      :min-max-date="minMaxDate"
      :objective="listState.okrElements[elementId]"
      :one-day-width="oneDayWidth"
      @update-elements="updateElements"
      @on-okr-element-updated="onOkrElementUpdated($event)"
      @edit-okr-element="editElement(listState.okrElements[elementId])"
    />
  </div>

  <ObjectiveViewRowActions ref="actions" :depth="depth" source="roadmap" @update="updateElements" />
</template>

<script>
import { isUndefined } from 'lodash'
import { defineComponent } from 'vue'

import { listStateInjectionKey } from '@/utils/injection-keys'
import { FILTERS_KEYS } from '@/utils/okr-elements/filters'

import ObjectiveViewRowActions from '@/components/objectives/ObjectiveViewRowActions'
import OkrTimelinesListItem from '@/components/objectives/roadmap/OkrTimelinesListItem'

export default defineComponent({
  name: 'OkrTimelinesList',

  components: { ObjectiveViewRowActions, OkrTimelinesListItem },

  inject: {
    listState: {
      from: listStateInjectionKey
    }
  },

  inheritAttrs: false,

  props: {
    modelValue: {
      type: Array,
      required: true
    },

    depth: {
      type: Number,
      default: 0
    },

    oneDayWidth: {
      type: Number,
      required: true
    },

    minMaxDate: {
      type: Object,
      required: true
    },

    activeView: {
      type: String,
      required: true
    }
  },

  emits: {
    'on-okr-element-updated': null,
    'update-elements': null,
    'update:childListHeight': null
  },

  data() {
    return {
      references: [],
      resizeObserver: null
    }
  },

  mounted() {
    this.initResizeObserver()
  },

  beforeUnmount() {
    this.resizeObserver.disconnect()
    this.resizeObserver = null
    this.$emit('update:childListHeight', 0)
  },

  beforeUpdate() {
    this.references = []
  },

  FILTERS_KEYS,

  methods: {
    async initResizeObserver() {
      await this.$nextTick()
      const { timelinesList } = this.$refs
      this.resizeObserver = new ResizeObserver(() => {
        this.$emit('update:childListHeight', timelinesList.offsetHeight)
      })
      this.resizeObserver.observe(timelinesList)
    },

    updateElements(eventData) {
      this.$emit('update-elements', eventData)
    },

    editElement(element) {
      this.$refs.actions.editOkrElement(element)
    },

    /** @public */
    refreshPositions() {
      this.references.forEach(item => {
        item.refreshPositions()
      })
    },

    setReference(reference) {
      if (reference) {
        this.references.push(reference)
      }
    },

    onOkrElementUpdated(updatedData) {
      const {
        elementId,
        elementStartDate,
        dueDate,
        dueDateManual,
        startDateManual,
        predictedGrade,
        updatedColor,
        updatedGrade,
        automaticElementStartDate,
        automaticDueDate,
        confidenceLevelId,
        automaticConfidenceLevelId
      } = updatedData
      this.listState.okrElements[elementId].elementStartDate = elementStartDate
      this.listState.okrElements[elementId].dueDate = dueDate
      this.listState.okrElements[elementId].predictedGrade = predictedGrade
      this.listState.okrElements[elementId].gradeColor = updatedColor
      this.listState.okrElements[elementId].grade = updatedGrade
      this.listState.okrElements[elementId].gradeToUse = updatedGrade
      this.listState.okrElements[elementId].confidenceLevelId = confidenceLevelId
      this.listState.okrElements[elementId].automaticConfidenceLevelId = automaticConfidenceLevelId
      this.listState.okrElements[elementId].automaticDueDate = automaticDueDate
      this.listState.okrElements[elementId].automaticElementStartDate = automaticElementStartDate
      if (!isUndefined(dueDateManual) && !isUndefined(startDateManual)) {
        this.listState.okrElements[elementId].dueDateManual = dueDateManual
        this.listState.okrElements[elementId].startDateManual = startDateManual
      }
      this.$emit('on-okr-element-updated', updatedData)
    }
  }
})
</script>

<style lang="scss" scoped></style>
