<template>
  <AppModal
    :show="show"
    :title="getRemoveTitle"
    close-button-icon="close-next"
    size="sm-next"
    @on-close="onCloseRemoveModal"
  >
    <div class="ssmrp-ModalContent">
      <span class="ssmrp-ModalContent_Description">
        {{ t('settings.synchronization.synchronization', { projectName: data.name }) }}
      </span>
      <div class="ssmrp-ContentFields">
        <SyncTypes
          v-model:keep-value="projectForRemove.keepValue"
          v-model:sync-direction="projectForRemove.syncDirection"
          :data="syncTypes"
        />
      </div>
    </div>
    <template #footer>
      <div class="ssmrp-ModalContent_Footer">
        <AppButton type="ghost-next" @click="onCloseRemoveModal">
          {{ t('action.cancel') }}
        </AppButton>
        <AppButton :disable="!projectForRemove.syncDirection" type="danger" @click="removeProject">
          {{ t('settings.synchronization.delete_custom_settings') }}
        </AppButton>
      </div>
    </template>
  </AppModal>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import AppButton from '@/components/ui/AppButton/AppButton'
import AppModal from '@/components/ui/AppModal/AppModal'
import SyncTypes from '@jira/components/settings/SyncTypes/SyncTypes'

import { useSyncTypes } from './composable'

const { t } = useI18n()
const { syncTypes } = useSyncTypes()

const props = defineProps({
  show: {
    type: Boolean
  },

  data: {
    type: Object,
    default: () => ({ name: '', projectId: '' })
  }
})
const projectForRemove = ref({ syncDirection: null, keepValue: false })

const emit = defineEmits({
  'remove-project': null,
  'update:show': null,
  'update:data': null,
  'on-close': null
})
const getRemoveTitle = computed(() => {
  return t('action.delete_entity', { entity: props.data.name })
})

const onCloseRemoveModal = () => {
  emit('update:show', false)
  emit('update:data', {})
  projectForRemove.value = { syncDirection: null, keepValue: false }
}
const removeProject = () => {
  emit('remove-project', {
    syncDirection: projectForRemove.value.syncDirection,
    keepValue: projectForRemove.value.keepValue,
    projectId: props.data.projectId
  })
  onCloseRemoveModal()
}
</script>

<style lang="scss" scoped>
.ssmrp-ModalContent {
  font-family: $system-ui;
}
.ssmrp-ModalContent_Description {
  font-style: normal;
  font-family: $system-ui;
  font-weight: fw('regular');
  font-size: $fs-14;
  line-height: 18px;
  color: $dark-1;
  margin-bottom: 24px;
  display: flex;
}
.ssmrp-ModalContent_Footer {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  width: 100%;
}
.ssmrp-ContentFields {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 485px;
}
</style>
