<template>
  <portal to="modal-windows">
    <ObjectiveDeleteModal
      v-model:show="isConfirmDeleteShow"
      source="modal"
      :objective="objectiveData"
      @deleted="onObjectiveDeleted"
      @update:show="!$event ? (objectiveData = null) : undefined"
    />

    <ObjectiveModalMoveStepper
      v-model:show="showMoveModal"
      :objective-data="objectiveData"
      @updated="updatedObjective"
    />
  </portal>
</template>

<script setup>
import { ref } from 'vue'

import ObjectiveModalMoveStepper from '@/components/objectives/objective-move/ObjectiveModalMoveStepper'
import ObjectiveDeleteModal from '@/components/objectives/ObjectiveDeleteModal'

const emit = defineEmits({
  deleted: null,
  updated: null
})
const isConfirmDeleteShow = ref(false)
const objectiveData = ref(null)
const showMoveModal = ref(false)
const moveOkrElement = okrElement => {
  showMoveModal.value = true

  objectiveData.value = okrElement
}
const updatedObjective = payload => {
  emit('updated', payload)
}
const deleteOkrElement = element => {
  objectiveData.value = element

  isConfirmDeleteShow.value = true
}
defineExpose({
  moveOkrElement,
  deleteOkrElement
})

const onObjectiveDeleted = () => {
  emit('deleted', { keepObjectivesSize: true })
}
</script>
<script>
//eslint-disable-next-line
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ObjectiveModalActions'
})
</script>

<style lang="scss" scoped></style>
