import dayjs from 'dayjs'
import { isArray, isEmpty, isEqual, isNull, isUndefined } from 'lodash'

import i18n from '@/i18n'
import { tracker } from '@/tracking/amplitude'
import {
  EVENT_CATEGORIES,
  EVENT_NAMES,
  FILTER_LABELS_FOR_TRACKING,
  TRACKING_SHOW_ALL
} from '@/tracking/amplitude-helpers'
import { ALL_CUSTOM_FIELDS } from '@/utils/custom-fields/factory'
import { FILTERS_OPERATORS } from '@/utils/custom-fields/filters-operators'
import { UNSELECTED_DATE } from '@/utils/date'
import { REQUEST_ENTITY_KEYS } from '@/utils/entity-keys'
import { NUMBER_FILTER_DEFAULT_VALUE } from '@/utils/okr-elements/number-filter'
import { RELATIVE_VALUES_FILTER_KEYS } from '@/utils/okr-elements/relative-values-filter'
import { isLocalStorageAvailable, readFromStorageByKey, updateStorageByKey } from '@/utils/persist'
import { deleteQueryParameter, replaceQueryParameters, updateQueryParameter } from '@/utils/router'
import { getSelectWithSelectAllApiParameter, selectAllIsSelected } from '@/utils/select'

export const SELECT_ALL_VALUE = 0

export const DEFAULT_SELECTED_FILTERS_COUNT = 0

export const DEFAULT_VALUE_FOR_FILTER = [SELECT_ALL_VALUE]
export const DEFAULT_VALUE_FOR_COMMENT_INPUT_FILTER = 7

export const FILTERS_KEYS = {
  EXPANDED_ITEMS: 'expandedItems',
  ASSIGNEE_IDS: 'assigneeIds',
  GROUP_IDS: 'groupIds',
  GRADE_TYPES: 'gradeTypes',
  OKR_TYPE_IDS: 'OKRTypeIds',
  DUE_DATES: 'dueDates',
  START_DATES: 'startDates',
  LAST_GRADE_UPDATE_DATES: 'lastGradeUpdateDates',
  SORT_ORDER: 'sortOrder',
  SORT_CHILDREN: 'sortChildren',
  INTERVAL_IDS: 'intervalIds',
  FILTER_COLLAPSED_ITEMS: 'filterCollapsedItems',
  LABEL_IDS: 'labelIds',
  STAKEHOLDER_IDS: 'stakeholderIds',
  GROUP_TYPE: 'groupType',
  PAGINATION: 'pagination',
  CATEGORY: 'category',
  ASSIGNEE_ID: 'assigneeId',
  INTERVAL_ID: 'intervalId',
  OWNER_IDS: 'ownerIds',
  LAST_COMMENT: 'lastComment'
}

export const RESETTABLE_FILTERS_KEYS = Object.values(FILTERS_KEYS).filter(value => {
  const EXCLUDED_KEYS_FOR_RESET = [
    FILTERS_KEYS.EXPANDED_ITEMS,
    FILTERS_KEYS.FILTER_COLLAPSED_ITEMS,
    FILTERS_KEYS.SORT_ORDER,
    FILTERS_KEYS.SORT_CHILDREN,
    FILTERS_KEYS.INTERVAL_IDS
  ]
  return !EXCLUDED_KEYS_FOR_RESET.includes(value)
})
export const DATES_KEYS = {
  DUE_DATE_FROM: 'dueDateFrom',
  DUE_DATE_TO: 'dueDateTo',
  START_DATE_FROM: 'startDateFrom',
  START_DATE_TO: 'startDateTo',
  LAST_GRADE_UPDATE_DATE_FROM: 'lastGradeUpdateDateFrom',
  LAST_GRADE_UPDATE_DATE_TO: 'lastGradeUpdateDateTo'
}

export const VALUES_DATA_TYPES = {
  ARRAY: 'array',
  BOOLEAN: 'boolean',
  DATES: 'dates'
}

const { ARRAY, BOOLEAN, DATES } = VALUES_DATA_TYPES

export const FILTER_DATA_TYPES = {
  [FILTERS_KEYS.ASSIGNEE_IDS]: ARRAY,
  [FILTERS_KEYS.LABEL_IDS]: ARRAY,
  [FILTERS_KEYS.STAKEHOLDER_IDS]: ARRAY,
  [FILTERS_KEYS.GROUP_IDS]: ARRAY,
  [FILTERS_KEYS.GRADE_TYPES]: ARRAY,
  [FILTERS_KEYS.OKR_TYPE_IDS]: ARRAY,
  [FILTERS_KEYS.DUE_DATES]: DATES,
  [FILTERS_KEYS.START_DATES]: DATES,
  [FILTERS_KEYS.LAST_GRADE_UPDATE_DATES]: DATES,
  [FILTERS_KEYS.LAST_COMMENT]: ARRAY,
  [FILTERS_KEYS.INTERVAL_IDS]: ARRAY,
  [FILTERS_KEYS.SORT_ORDER]: ARRAY,
  [FILTERS_KEYS.SORT_CHILDREN]: BOOLEAN,
  [FILTERS_KEYS.EXPANDED_ITEMS]: ARRAY,
  [FILTERS_KEYS.FILTER_COLLAPSED_ITEMS]: ARRAY
}

export const DEFAULT_VALUE_FOR_LAST_COMMENT = [SELECT_ALL_VALUE, null]
export const FILTER_DEFAULT_VALUES = {
  [FILTERS_KEYS.ASSIGNEE_IDS]: DEFAULT_VALUE_FOR_FILTER,
  [FILTERS_KEYS.LABEL_IDS]: DEFAULT_VALUE_FOR_FILTER,
  [FILTERS_KEYS.STAKEHOLDER_IDS]: DEFAULT_VALUE_FOR_FILTER,
  [FILTERS_KEYS.GROUP_IDS]: DEFAULT_VALUE_FOR_FILTER,
  [FILTERS_KEYS.GRADE_TYPES]: DEFAULT_VALUE_FOR_FILTER,
  [FILTERS_KEYS.OKR_TYPE_IDS]: DEFAULT_VALUE_FOR_FILTER,
  [FILTERS_KEYS.DUE_DATES]: UNSELECTED_DATE,
  [FILTERS_KEYS.START_DATES]: UNSELECTED_DATE,
  [FILTERS_KEYS.LAST_GRADE_UPDATE_DATES]: UNSELECTED_DATE,
  [FILTERS_KEYS.LAST_COMMENT]: DEFAULT_VALUE_FOR_LAST_COMMENT,
  [FILTERS_KEYS.INTERVAL_IDS]: [],
  [FILTERS_KEYS.SORT_ORDER]: [],
  [FILTERS_KEYS.SORT_CHILDREN]: false,
  [FILTERS_KEYS.EXPANDED_ITEMS]: [],
  [FILTERS_KEYS.FILTER_COLLAPSED_ITEMS]: []
}

export const CUSTOM_FIELD_FILTER_DEFAULT_VALUES = {
  [ALL_CUSTOM_FIELDS.getTypeIds().MULTI_SELECT]: DEFAULT_VALUE_FOR_FILTER,
  [ALL_CUSTOM_FIELDS.getTypeIds().SINGLE_SELECT]: DEFAULT_VALUE_FOR_FILTER,
  [ALL_CUSTOM_FIELDS.getTypeIds().DATE]: UNSELECTED_DATE,
  [ALL_CUSTOM_FIELDS.getTypeIds().ASSIGNEE]: DEFAULT_VALUE_FOR_FILTER,
  [ALL_CUSTOM_FIELDS.getTypeIds().NUMBER]: NUMBER_FILTER_DEFAULT_VALUE,
  [ALL_CUSTOM_FIELDS.getTypeIds().MONEY]: NUMBER_FILTER_DEFAULT_VALUE
}

export const FILTER_TRACKING_KEY = {
  [FILTERS_KEYS.ASSIGNEE_IDS]: 'owners',
  [FILTERS_KEYS.LABEL_IDS]: 'labels',
  [FILTERS_KEYS.STAKEHOLDER_IDS]: 'stakeholders',
  [FILTERS_KEYS.GROUP_IDS]: 'groups',
  [FILTERS_KEYS.GRADE_TYPES]: 'status',
  [FILTERS_KEYS.OKR_TYPE_IDS]: 'OKR levels',
  [FILTERS_KEYS.DUE_DATES]: 'due date',
  [FILTERS_KEYS.START_DATES]: 'start date',
  [FILTERS_KEYS.LAST_GRADE_UPDATE_DATES]: 'last grade update date',
  [FILTERS_KEYS.LAST_COMMENT]: 'last comment',
  [FILTERS_KEYS.INTERVAL_IDS]: 'intervals',
  [FILTERS_KEYS.SORT_ORDER]: 'sort order',
  [FILTERS_KEYS.SORT_CHILDREN]: 'sort children',
  [FILTERS_KEYS.EXPANDED_ITEMS]: 'expanded items',
  [FILTERS_KEYS.FILTER_COLLAPSED_ITEMS]: 'collapsed items'
}

export const FILTER_PRESETS = {
  NONE: 'none',
  MY_OKR: 'myOKR',
  MY_GROUP: 'myGroup'
}

export const FILTER_LS_KEYS = {
  [FILTERS_KEYS.ASSIGNEE_IDS]: 'FILTER_ASSIGNEE_IDS',
  [FILTERS_KEYS.GROUP_IDS]: 'FILTER_GROUP_IDS',
  [FILTERS_KEYS.OKR_TYPE_IDS]: 'FILTER_OKR_TYPE_IDS',
  [FILTERS_KEYS.DUE_DATES]: 'FILTER_DUE_DATES',
  [FILTERS_KEYS.EXPANDED_ITEMS]: 'FILTER_EXPANDED_ITEMS'
}

export const HP_FILTERS_KEYS = {
  INTERVAL_ID: 'hpIntervalId',
  SELECTED_USER: 'hpSelectedUser',
  SELECTED_GROUP: 'hpSelectedGroup',
  SEARCH_TYPE_ID: 'hpSearchTypeId',
  INTERVAL_IDS: 'hpIntervalIds',
  START_DATES: 'hpStartDates',
  DUE_DATES: 'hpDueDates'
}

export const RESET_FILTER_TYPES = {
  CUSTOM: 'custom',
  MY_GROUP: 'myGroup',
  MY_OKR: 'myOKR'
}

/*
 * Restores saved filter value
 * check query parameters, if doesn't exist, check LS, else use default value
 *
 * NOTE: it only restores value and if it is changed(e.g. read from LS and differs from value in route query),
 * it DOESN'T update value in route, you need to do this additionaly.
 */
export const restoreFilterValue = (route, parameter, defaultValue, skipLsRestoring = false) => {
  let value
  const lsParameter = FILTER_LS_KEYS[parameter] || parameter
  if (parameter in route.query) {
    try {
      value = JSON.parse(route.query[parameter])
    } catch {
      console.error('Failed to parse query parameter', parameter)
    }
    if (value === undefined) {
      value = defaultValue
    }
  } else if (
    isLocalStorageAvailable() &&
    readFromStorageByKey(lsParameter) !== null &&
    !skipLsRestoring
  ) {
    value = readFromStorageByKey(lsParameter)
  } else {
    value = defaultValue
  }
  return value
}

export const saveFilterValue = (router, route, parameter, value) => {
  const lsParameter = FILTER_LS_KEYS[parameter] || parameter

  if (!isEqual(value, FILTER_DEFAULT_VALUES[parameter])) {
    updateQueryParameter(router, route, parameter, value)
  } else {
    deleteQueryParameter(router, route, parameter)
  }
  updateStorageByKey(lsParameter, value)
}

export const saveFilterValues = async (router, route, keys, values) => {
  const queryParameters = { ...route.query }
  keys.forEach((key, index) => {
    const value = values[index]
    const lsParameter = FILTER_LS_KEYS[key] || key
    updateStorageByKey(lsParameter, value)

    if (!isEqual(value, FILTER_DEFAULT_VALUES[key])) {
      queryParameters[key] = JSON.stringify(value)
    } else {
      delete queryParameters[key]
    }
  })

  await replaceQueryParameters(router, route, queryParameters)
}

export const sendFilterTrackerEvent = ({ section, label, tab, value }) => {
  const filteredValues = Array.isArray(value)
    ? value.filter(item => item !== SELECT_ALL_VALUE)
    : value
  const resolvedValue =
    isEmpty(filteredValues) || isNull(value) ? TRACKING_SHOW_ALL : filteredValues
  tracker.logEvent(EVENT_NAMES.FILTER_APPLIED, {
    category: EVENT_CATEGORIES.FILTERING,
    section,
    tab,
    label,
    value: resolvedValue
  })
}

export const sendCustomFieldFilterTrackerEvent = ({ section, label, tab }) => {
  tracker.logEvent(EVENT_NAMES.FILTER_APPLIED, {
    category: EVENT_CATEGORIES.FILTERING,
    section,
    tab,
    label
  })
}

export const sendIntervalFilterTrackerEvent = ({ newValue = [], intervals = [], section, tab }) => {
  const intervalNames = newValue
    .map(id => {
      const interval = intervals.find(interval => interval.id === id)
      return interval ? interval.name : null
    })
    .filter(Boolean)
  tracker.logEvent(EVENT_NAMES.FILTER_APPLIED, {
    category: EVENT_CATEGORIES.FILTERING,
    section,
    tab,
    label: FILTER_LABELS_FOR_TRACKING.INTERVALS,
    value: intervalNames
  })
}

export const sendIntervalFilterTimeRangeTrackerEvent = ({ section, value }) => {
  if (value) {
    tracker.logEvent(EVENT_NAMES.FILTER_APPLIED, {
      category: EVENT_CATEGORIES.FILTERING,
      label: FILTER_LABELS_FOR_TRACKING.INTERVALS,
      section,
      value
    })
  }
}

const isRangeSelected = (dates, isStartDates = true) => {
  if (isValidDatesArray(dates)) {
    const [start, end] = dates

    if (isStartDates) {
      return !isNull(start) && isNull(end)
    } else {
      return isNull(start) && !isNull(end)
    }
  }
  return false
}

const isEmptyDatesArray = dates => isValidDatesArray(dates) && dates.every(date => isNull(date))
export const isTimeRangeSelectedInsteadOfInterval = ({ startDates, dueDates, intervals }) => {
  const isStartDateRangeSelected = isRangeSelected(startDates)
  const isDueDateRangeSelected = isRangeSelected(dueDates, false)
  const isNoIntervalsSelected = Array.isArray(intervals) && isEmpty(intervals)

  const isBetweenRangeSelected =
    isStartDateRangeSelected && isDueDateRangeSelected && isNoIntervalsSelected

  const isBeforeRangeSelected =
    isDueDateRangeSelected && isNoIntervalsSelected && isEmptyDatesArray(startDates)

  const isSinceRangeSelected =
    isStartDateRangeSelected && isNoIntervalsSelected && isEmptyDatesArray(dueDates)

  return {
    isTimeRangeSelected: isBetweenRangeSelected || isBeforeRangeSelected || isSinceRangeSelected,
    isBetweenRangeSelected,
    isBeforeRangeSelected,
    isSinceRangeSelected
  }
}

const getDateFromString = dateString => dayjs(dateString).toDate()
export const isValidDateString = dateString => dayjs(dateString).isValid()

export const isValidCustomRange = dates => {
  return dates.every(date => isValidDateString(date) || isNull(date))
}

export const normalizeCustomRange = dates => {
  return [
    isValidDateString(dates[0]) ? getDateFromString(dates[0]) : UNSELECTED_DATE,
    isValidDateString(dates[1]) ? getDateFromString(dates[1]) : UNSELECTED_DATE
  ]
}

export const isValidDatesArray = dates => {
  return Array.isArray(dates) && dates.length === 2
}

const restoreDatesInDefaultWay = ({ dates, defaultValue }) => {
  const [dateFrom, dateTo] = dates
  return isValidDateString(dateFrom) && isValidDateString(dateTo)
    ? [getDateFromString(dateFrom), getDateFromString(dateTo)]
    : defaultValue
}

export const restoreRelativeValues = ({ restoredValue, keyProp, defaultValue }) => {
  const dataValid = !isNull(restoredValue) && !isUndefined(restoredValue) && isArray(restoredValue)

  const isValidDataProp = !isUndefined(keyProp) && [FILTERS_KEYS.LAST_COMMENT].includes(keyProp)

  if (!dataValid || !isValidDataProp) {
    return defaultValue
  }

  const isLastCommentUpdateDate = keyProp === FILTERS_KEYS.LAST_COMMENT

  if (isLastCommentUpdateDate) {
    const selectedValue = restoredValue[0]
    const inputValue = restoredValue[1]
    const commentOption = LAST_COMMENT_OPTIONS.find(item => item.value === selectedValue)

    if (!commentOption) {
      return defaultValue
    }

    const isValueValid = !isNull(selectedValue) && !isUndefined(selectedValue)

    const isValidRange = +inputValue > 0 && +inputValue <= 999
    const isValidInputValue = !isNaN(+inputValue) && (isValidRange || isNull(inputValue))

    const isAllowedInputValue = commentOption.withInput ? true : isNull(inputValue)

    if (isValueValid && isAllowedInputValue && isValidInputValue) {
      return restoredValue
    } else {
      return defaultValue
    }
  }

  return defaultValue
}
export const restoreDates = ({
  dates,
  defaultValue = null,
  isTimeRangeSelectedInsteadOfInterval = false,
  dateProp
}) => {
  const datesArrayValid = isValidDatesArray(dates)
  const isValidDateProp =
    !isUndefined(dateProp) &&
    [
      FILTERS_KEYS.DUE_DATES,
      FILTERS_KEYS.START_DATES,
      FILTERS_KEYS.LAST_GRADE_UPDATE_DATES,
      FILTERS_KEYS.LAST_COMMENT,
      ALL_CUSTOM_FIELDS.getTypeIds().DATE
    ].includes(dateProp)
  if (!datesArrayValid || !isValidDateProp) {
    return defaultValue
  }

  const isForLastGradeUpdateDates = dateProp === FILTERS_KEYS.LAST_GRADE_UPDATE_DATES
  const isForCustomFieldDateFilter = dateProp === ALL_CUSTOM_FIELDS.getTypeIds().DATE

  if (isForLastGradeUpdateDates || isForCustomFieldDateFilter) {
    return restoreDatesInDefaultWay({
      dates,
      defaultValue
    })
  }

  const isForStartDates = dateProp === FILTERS_KEYS.START_DATES

  if (isTimeRangeSelectedInsteadOfInterval) {
    if (isForStartDates) {
      const [startDateFrom] = dates
      return isValidDateString(startDateFrom)
        ? [getDateFromString(startDateFrom), UNSELECTED_DATE]
        : defaultValue
    } else {
      const [, dueDateTo] = dates
      return isValidDateString(dueDateTo)
        ? [UNSELECTED_DATE, getDateFromString(dueDateTo)]
        : defaultValue
    }
  } else {
    return restoreDatesInDefaultWay({
      dates,
      defaultValue
    })
  }
}

export const INTERVAL_TIME_RANGE_OPTIONS = {
  BETWEEN: {
    value: 'between',
    label: 'filter.custom_range.between'
  },
  BEFORE: {
    value: 'before',
    label: 'filter.custom_range.before'
  },
  SINCE: {
    value: 'since',
    label: 'filter.custom_range.since'
  }
}

export const LAST_COMMENT_UPDATE_DATE_FROM = 'lastCommentUpdateDateFrom'
export const LAST_COMMENT_UPDATE_DATE_TO = 'lastCommentUpdateDateTo'
export const SHOULD_BE_COMMENTED = 'shouldBeCommented'
export const NEVER_COMMENTED = 'neverCommented'

export const LAST_COMMENT_MIN_INPUT_VALUE = 1

export const LAST_COMMENT_INPUT_DIGIT_MAX_LENGTH = 3

const {
  LABEL,
  WITH_INPUT,
  VALUE,
  DEFAULT_INPUT_VALUE,
  DIGIT_MAX_LENGTH,
  MIN_INPUT_VALUE,
  GROUP,
  INPUT_AFTER_TEXT,
  LAYOUT_AS_COLUMN
} = RELATIVE_VALUES_FILTER_KEYS
export const LAST_COMMENT_OPTIONS = [
  {
    [LABEL]: i18n.global.t('filter.show_all'),
    trackLabel: TRACKING_SHOW_ALL,
    [VALUE]: 0,
    [GROUP]: '1'
  },
  {
    [LABEL]: i18n.global.t('filter.more_than'),
    trackLabel: 'More than',
    [VALUE]: LAST_COMMENT_UPDATE_DATE_TO,
    [GROUP]: '1',
    [DEFAULT_INPUT_VALUE]: DEFAULT_VALUE_FOR_COMMENT_INPUT_FILTER,
    [WITH_INPUT]: true,
    [INPUT_AFTER_TEXT]: i18n.global.t('objectives.days_ago'),
    [DIGIT_MAX_LENGTH]: LAST_COMMENT_INPUT_DIGIT_MAX_LENGTH,
    [MIN_INPUT_VALUE]: LAST_COMMENT_MIN_INPUT_VALUE,
    [LAYOUT_AS_COLUMN]: true
  },
  {
    [LABEL]: i18n.global.t('filter.less_than'),
    trackLabel: 'Less than',
    [VALUE]: LAST_COMMENT_UPDATE_DATE_FROM,
    [GROUP]: '1',
    [DEFAULT_INPUT_VALUE]: DEFAULT_VALUE_FOR_COMMENT_INPUT_FILTER,
    [WITH_INPUT]: true,
    [INPUT_AFTER_TEXT]: i18n.global.t('objectives.days_ago'),
    [DIGIT_MAX_LENGTH]: LAST_COMMENT_INPUT_DIGIT_MAX_LENGTH,
    [MIN_INPUT_VALUE]: LAST_COMMENT_MIN_INPUT_VALUE,
    [LAYOUT_AS_COLUMN]: true
  },
  {
    [LABEL]: i18n.global.t('filter.at_least_comment', { count: 1 }),
    trackLabel: 'At least 1 comment',
    [VALUE]: SHOULD_BE_COMMENTED,
    [GROUP]: '2'
  },
  {
    [LABEL]: i18n.global.t('filter.never_commented'),
    trackLabel: 'Never commented',
    [VALUE]: NEVER_COMMENTED,
    [GROUP]: '2'
  }
]

export const getValueForTrackingLstCommentFilter = ({ value }) => {
  if (Array.isArray(value) && value.length === 2) {
    const [operator, inputValue] = value
    const commentOption = LAST_COMMENT_OPTIONS.find(item => item.value === operator)

    if (commentOption) {
      return [LAST_COMMENT_UPDATE_DATE_TO, LAST_COMMENT_UPDATE_DATE_FROM].includes(
        commentOption.value
      )
        ? `${commentOption.trackLabel} ${inputValue}`
        : commentOption.trackLabel
    }

    return TRACKING_SHOW_ALL
  }

  return TRACKING_SHOW_ALL
}

export const createCustomFieldsFiltersPayload = ({
  customFields = [],
  filtersValues = {}
} = {}) => {
  const { OPERATOR_TYPE_ID, VALUE, FIELD_ID, FIELD_TYPE_ID } = REQUEST_ENTITY_KEYS
  return customFields
    .reduce((acc, val) => {
      const baseValue = isUndefined(filtersValues[val.id])
        ? CUSTOM_FIELD_FILTER_DEFAULT_VALUES[val.typeId]
        : filtersValues[val.id]

      let resolvedValue = undefined // if filter value is default, we don't need to send it to the server
      const COMMON_PAYLOAD = {
        [FIELD_ID]: val.id,
        [FIELD_TYPE_ID]: val.typeId
      }

      const isDateFilter = val.typeId === ALL_CUSTOM_FIELDS.getTypeIds().DATE
      const isMoneyOrNumberFilter =
        val.typeId === ALL_CUSTOM_FIELDS.getTypeIds().MONEY ||
        val.typeId === ALL_CUSTOM_FIELDS.getTypeIds().NUMBER

      if (isDateFilter) {
        if (!isNull(baseValue)) {
          resolvedValue = {
            ...COMMON_PAYLOAD,
            [OPERATOR_TYPE_ID]: FILTERS_OPERATORS.BETWEEN,
            [VALUE]: baseValue
          }
        }
      } else if (isMoneyOrNumberFilter) {
        if (!isEqual(baseValue, NUMBER_FILTER_DEFAULT_VALUE)) {
          const [operatorTypeId, value] = baseValue
          resolvedValue = {
            ...COMMON_PAYLOAD,
            operatorTypeId,
            value
          }
        }
      } else {
        const payloadValue = getSelectWithSelectAllApiParameter(baseValue)
        if (!isNull(payloadValue)) {
          resolvedValue = {
            ...COMMON_PAYLOAD,
            [OPERATOR_TYPE_ID]: FILTERS_OPERATORS.CONTAINS,
            [VALUE]: payloadValue
          }
        }
      }

      return [...acc, resolvedValue]
    }, [])
    .filter(Boolean)
}

export const calculateSelectedFiltersCount = ({
  filterValue = null,
  filterValueType = null
} = {}) => {
  if (isNull(filterValue) || isNull(filterValueType) || isUndefined(filterValue)) {
    return DEFAULT_SELECTED_FILTERS_COUNT
  }

  if (filterValueType === DATES) {
    return filterValue === UNSELECTED_DATE ? DEFAULT_SELECTED_FILTERS_COUNT : filterValue.length / 2
  } else if (filterValueType === ARRAY) {
    return selectAllIsSelected(filterValue) ? DEFAULT_SELECTED_FILTERS_COUNT : filterValue.length
  } else {
    throw new Error('Unsupported filter value type')
  }
}

export const isShowAllContain = fullDataOption => {
  return fullDataOption.some(
    op =>
      op.value === SELECT_ALL_VALUE ||
      op.id === SELECT_ALL_VALUE ||
      op.accountId === SELECT_ALL_VALUE
  )
}

export const DEFAULT_CUSTOM_RANGE_VALUE = [UNSELECTED_DATE, UNSELECTED_DATE]
