<template>
  <div class="twa-Title">
    <span v-if="$slots.prepend" class="twa-Title_Prepend">
      <slot name="prepend" />
    </span>
    <slot />
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TitleWithAction'
})
</script>

<script setup></script>

<style lang="scss" scoped>
.twa-Title {
  font-family: $system-ui;
  font-style: normal;
  font-weight: fw('medium');
  font-size: $fs-32;
  line-height: 40px;
  color: $dark-1;
  overflow: hidden;
  text-overflow: ellipsis;
}

.twa-Title_Prepend {
  color: $dark-3;
}
</style>
