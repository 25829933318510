<template>
  <AppModalWithConfirmation
    ref="appModalWithConfirmation"
    :confirm-close="areDataChanged"
    :show="show"
    :title="getModalTitle"
    class="lm-LevelModal"
    data-auto-testid="level-modal"
    data-testid="level-modal"
    hide-hero
    @on-close="onLevelModalClose"
  >
    <div class="lm-Body">
      <FormFieldNext :label="$t('levels.name')" data-auto-testid="manage-level-name">
        <AppInput
          ref="nameInput"
          v-model.trim="formModel[OKR_LEVEL_ENTITY_KEYS.NAME]"
          :is-error="isNameEmpty"
          :max-length="LEVEL_NAME_MAX_LENGTH"
          data-auto-testid="level-name-input"
          data-testid="level-name-input"
          size="xlg"
          style-type="primary"
          @focus="isNameEmpty = false"
          @update:model-value="validateName"
        />
        <AppFieldError
          v-if="isNameEmpty"
          :show="isNameEmpty"
          class="lm-FieldError"
          data-auto-testid="manage-level-name-is-error"
        >
          {{ $t('field.required') }}
        </AppFieldError>
      </FormFieldNext>

      <div data-auto-testid="manage-level-icon-create">
        <AppIconCreator class="lm-IconCreator">
          <template #first-term>
            <FormFieldNext :label="$t('levels.prefix')" class="lm-Prefix">
              <AppInput
                v-model.trim="formModel[OKR_LEVEL_ENTITY_KEYS.PREFIX]"
                :is-error="isPrefixEmpty"
                :max-length="LEVEL_PREFIX_MAX_LENGTH"
                data-auto-testid="level-prefix-input"
                data-testid="level-prefix-input"
                size="xlg"
                style-type="primary"
                @focus="isPrefixEmpty = false"
                @update:model-value="isPrefixEmpty = $event.length === 0"
              />
            </FormFieldNext>
          </template>

          <template #second-term>
            <AppPaletteColorPicker
              v-model:selected-color="formModel[OKR_LEVEL_ENTITY_KEYS.COLOR]"
              :palette="CATEGORIZED_COLORS"
            />
          </template>

          <template #result>
            <OkrIcon
              v-if="showIcon"
              :objective="iconObjective"
              data-auto-testid="result-icon"
              data-testid="result-icon"
            />
          </template>
        </AppIconCreator>
        <AppFieldError
          v-if="isPrefixEmpty"
          :show="isPrefixEmpty"
          class="lm-FieldError"
          data-auto-testid="manage-level-prefix-is-error"
        >
          <template v-if="isPrefixEmpty">
            {{ $t('field.required') }}
          </template>
        </AppFieldError>
      </div>

      <GlobalGroupsWorkspaceSelect
        v-if="!isEdit"
        v-model:selected-workspaces="formModel.workspaceIds"
        data-auto-testid="manage-level-select-ws"
        hide-all-workspaces-option-in-selected-items-list
      />
    </div>

    <template #footer-actions>
      <AppButton
        data-auto-testid="cancel-button"
        data-testid="cancel-button"
        type="ghost-next"
        @click="close"
      >
        {{ $t('action.cancel') }}
      </AppButton>

      <AppButton
        :disabled="loading"
        :loading="loading"
        data-auto-testid="confirm-button"
        data-testid="confirm-button"
        type="primary-next"
        @click="save"
      >
        <template v-if="!isEdit">
          {{ $t('action.create') }}
        </template>
        <template v-else>
          {{ $t('action.update') }}
        </template>
      </AppButton>
    </template>
  </AppModalWithConfirmation>
</template>

<script>
import { cloneDeep, isNull } from 'lodash'
import { defineComponent } from 'vue'

import LevelsApiHandler from '@/api/level'
import { tracker } from '@/tracking/amplitude'
import { EVENT_CATEGORIES } from '@/tracking/amplitude-helpers'
import { OKR_LEVEL_ENTITY_KEYS } from '@/utils/entity-keys'
import { handleError } from '@/utils/error-handling'
import { isStringEmpty } from '@/utils/general'
import { DEFAULT_VALUE_FOR_FILTER } from '@/utils/okr-elements/filters'
import {
  CATEGORIZED_COLORS,
  LEVEL_NAME_MAX_LENGTH,
  LEVEL_PREFIX_MAX_LENGTH,
  normalizeLevelColor
} from '@/utils/okr-levels'
import { selectAllIsSelected } from '@/utils/select'

import AppModalWithConfirmation from '@/components/AppModalWithConfirmation'
import AppFieldError from '@/components/form/AppFieldError'
import FormFieldNext from '@/components/form/FormFieldNext'
import GlobalGroupsWorkspaceSelect from '@/components/global-groups/GlobalGroupsWorkspaceSelect'
import OkrIcon from '@/components/objectives/items/OkrIcon'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppIconCreator from '@/components/ui/AppIconCreator/AppIconCreator'
import AppInput from '@/components/ui/AppInput/AppInput'
import AppPaletteColorPicker from '@/components/ui/AppPaletteColorPIcker/AppPaletteColorPicker'

const levelsApi = new LevelsApiHandler()

const DEFAULT_FORM_MODEL = {
  [OKR_LEVEL_ENTITY_KEYS.NAME]: '',
  [OKR_LEVEL_ENTITY_KEYS.PREFIX]: '',
  [OKR_LEVEL_ENTITY_KEYS.COLOR]: CATEGORIZED_COLORS.VIOLET[1],
  workspaceIds: cloneDeep(DEFAULT_VALUE_FOR_FILTER),
  allWorkspaces: false
}

export default defineComponent({
  name: 'LevelModal',

  components: {
    GlobalGroupsWorkspaceSelect,
    AppIconCreator,
    AppPaletteColorPicker,
    FormFieldNext,
    AppModalWithConfirmation,
    AppButton,
    AppInput,
    AppFieldError,
    OkrIcon
  },

  props: {
    show: {
      type: Boolean,
      default: false
    },

    modelValue: {
      type: Object,
      default: null
    }
  },

  emits: { 'update:show': null, create: null, edit: null },

  data() {
    return {
      formModel: cloneDeep(DEFAULT_FORM_MODEL),
      localFormModel: cloneDeep(DEFAULT_FORM_MODEL),
      isNameEmpty: false,
      isPrefixEmpty: false,
      isPrefixNotLetter: false,
      loading: false
    }
  },

  computed: {
    LEVEL_NAME_MAX_LENGTH: () => LEVEL_NAME_MAX_LENGTH,

    LEVEL_PREFIX_MAX_LENGTH: () => LEVEL_PREFIX_MAX_LENGTH,

    OKR_LEVEL_ENTITY_KEYS: () => OKR_LEVEL_ENTITY_KEYS,

    CATEGORIZED_COLORS: () => CATEGORIZED_COLORS,

    isEdit() {
      return !isNull(this.modelValue)
    },

    showIcon() {
      return this.formModel.prefix.trim().length > 0
    },

    getModalTitle() {
      return this.isEdit ? this.$t('levels.title.update') : this.$t('levels.title.create')
    },

    areDataChanged() {
      return JSON.stringify(this.localFormModel) !== JSON.stringify(this.formModel)
    },

    iconObjective() {
      return {
        levelColor: this.formModel.color.substring(1),
        levelPrefix: this.formModel.prefix
      }
    },

    isAllWorkspacesOptionSelected() {
      return selectAllIsSelected(this.formModel.workspaceIds)
    }
  },

  watch: {
    show(bool) {
      if (!bool) {
        this.onLevelModalClose()
        return
      }

      if (this.isEdit) {
        let { color } = this.modelValue

        this.formModel = {
          name: this.modelValue.name,
          prefix: this.modelValue.prefix,
          color: normalizeLevelColor({ color })
        }
      }
      this.localFormModel = { ...this.formModel }

      // nextTick and setTimeout(both!) are needed to make input always focused on modal window
      // opening because modal window(o-modal) has transition of opacity with 0.2s
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.nameInput?.focus()
        }, 200)
      })
    }
  },

  methods: {
    onLevelModalClose() {
      this.$emit('update:show', false)

      // wait for modal close
      setTimeout(() => {
        this.formModel = cloneDeep(DEFAULT_FORM_MODEL)
        this.localFormModel = cloneDeep(DEFAULT_FORM_MODEL)
        this.isNameEmpty = false
        this.isPrefixEmpty = false
        this.isPrefixNotLetter = false
      }, 300)
    },

    validateName() {
      this.isNameEmpty = isStringEmpty(this.formModel.name)
    },

    async save() {
      this.validateName()
      this.isPrefixEmpty = isStringEmpty(this.formModel.prefix)
      if (this.isPrefixEmpty) {
        this.formModel.prefix = ''
      }
      if (this.isNameEmpty || this.isPrefixEmpty) {
        return
      }

      let isSuccessful = true
      this.loading = true

      let level = null

      const requestMethod = this.isEdit
        ? payload => levelsApi.updateLevel(payload)
        : payload => levelsApi.createLevel(payload)

      try {
        const payload = {
          levelId: this.isEdit ? this.modelValue.id : undefined,
          ...this.formModel,
          color: this.formModel.color.substring(1), // remove '#' at the beginning
          orderValue: this.isEdit ? this.modelValue.orderValue : -1
        }

        if (!this.isEdit) {
          payload.workspaceIds = this.isAllWorkspacesOptionSelected
            ? []
            : this.formModel.workspaceIds
          payload.addToAllWorkspaces = this.isAllWorkspacesOptionSelected
        }

        const response = await requestMethod(payload)

        level = response.level

        if (!this.isEdit) {
          tracker.logEvent('Level created', {
            category: EVENT_CATEGORIES.LEVEL_MANAGEMENT,
            label: this.formModel.name
          })
        } else {
          tracker.logEvent('Level updated', {
            category: EVENT_CATEGORIES.LEVEL_MANAGEMENT,
            oldLabel: this.modelValue.name,
            label: this.formModel.name,
            prefix: this.formModel.prefix,
            color: this.formModel.color,
            oldPrefix: this.modelValue.prefix,
            oldColor: this.modelValue.color
          })
        }
      } catch (error) {
        isSuccessful = false
        handleError({ error })
      }

      this.loading = false
      if (isSuccessful && !this.isEdit) {
        this.$emit('create', level)
      }
      if (isSuccessful && this.isEdit) {
        this.$emit('edit', level)
      }
    },

    close() {
      this.$refs.appModalWithConfirmation.close()
    }
  }
})
</script>

<style lang="scss" scoped>
.lm-Body {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.lm-Prefix {
  width: 80px;
}

// .lm-Switch {
//   margin-top: 8px;
// }
</style>

<style lang="scss">
.lm-LevelModal .o-modal-footer {
  padding-top: 8px;
}

.o-form-error.lm-FieldError {
  min-height: 20px;
}
</style>
