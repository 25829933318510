import ApiHandler from '@/api/base'

class UserApiHandler extends ApiHandler {
  async getUserWorkspaces(userAccountId) {
    const { data } = await this.http.get('/user/workspaces', {
      params: {
        userAccountId
      }
    })

    return data
  }

  async getUserInfo(userAccountId) {
    const { data } = await this.http.get('/settings/users', {
      params: {
        userAccountIds: [userAccountId]
      }
    })

    return data
  }

  /*  async updateUser({
    accountId = null,
    defaultGroupId = null,
    workspaceId = null,
    roleId = null,
    groupIds = null
  }) {
    const { data } = await this.http.put('/user', {
      accountId,
      defaultGroupId,
      workspaceId,
      roleId,
      groupIds
    })

    return data
  }*/

  async updateUser({
    accountId = null,
    addGroupsAndWorkspacesToExisting = true, // if false then remove all groups and workspaces and add only new
    groupIds = null,
    workspaceParameters = [] // { workspaceId, roleId, defaultGroupId }
  }) {
    const { data } = await this.http.put('/internal/v2/user', {
      accountId,
      addGroupsAndWorkspacesToExisting,
      groupIds,
      workspaceParameters
    })
    return data
  }

  async removeUserFromWorkspace({ accountId, workspaceId }) {
    const { data } = await this.http.delete('/user', {
      data: {
        accountId,
        workspaceId
      }
    })

    return data
  }

  async removeUserFromPlugin({ accountId }) {
    const { data } = await this.http.delete('/user/removeFromPlugin', {
      data: {
        accountIds: [accountId]
      }
    })

    return data
  }

  async addUserToWorkspaces(workspaces = []) {
    const { data } = await this.http.post('/user/addToWorkspaces', {
      workspaces
    })

    return data
  }
}

export default UserApiHandler
