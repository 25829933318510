<template>
  <AppDialog
    :loading="loading"
    :show="show"
    :title="resolvedTitle"
    :type="DIALOG_TYPES.DELETE"
    data-auto-testid="delete-user-without-groups-modal"
    @on-confirm="onConfirm"
    @on-close="onClose"
  >
    <slot>
      {{ $t('confirm.user.message') }}
    </slot>
  </AppDialog>
</template>

<script>
import { defineComponent } from 'vue'

import { DIALOG_TYPES } from '@/utils/components-configurations/app-dialog'

import AppDialog from '@/components/AppDialog'

export default defineComponent({
  name: 'UserDeleteModal',

  components: {
    AppDialog
  },

  props: {
    show: {
      type: Boolean,
      default: false
    },

    title: {
      type: String,
      default: ''
    },

    loading: {
      type: Boolean
    }
  },

  emits: { 'on-confirm': null, 'on-close': null },

  computed: {
    DIALOG_TYPES: () => DIALOG_TYPES,

    resolvedTitle() {
      if (this.title) {
        return this.title
      }

      return this.$t('delete_user.title')
    }
  },

  methods: {
    onClose() {
      this.$emit('on-close')
    },

    onConfirm() {
      this.$emit('on-confirm')
    }
  }
})
</script>

<style lang="scss" scoped></style>
