<template>
  <AppDialog
    :loading="isLoading"
    :show="show"
    :title="$t('delete_confirm_workspace_title')"
    :type="DIALOG_TYPES.DELETE"
    data-auto-testid="delete-workspace-modal"
    @on-confirm="onConfirm"
    @on-close="onClose"
  >
    <i18n-t
      v-if="show"
      class="wd-DeleteConfirmMessage"
      keypath="delete_confirm_workspace_message"
      scope="global"
      tag="div"
    >
      <template #workspaceName>
        <span class="wd-DangerText">
          {{ workspaceToDelete.name }}
        </span>
      </template>
    </i18n-t>

    <KeywordInput ref="keywordInputReference" :keyword="KEYWORD" />
  </AppDialog>
</template>

<script>
import { defineComponent } from 'vue'

import { DIALOG_TYPES } from '@/utils/components-configurations/app-dialog'
import { KEYWORD } from '@/utils/helpers'

import AppDialog from '@/components/AppDialog'
import KeywordInput from '@/components/ui/KeywordInput/KeywordInput'

export default defineComponent({
  name: 'WorkspaceDeleteModal',

  components: {
    KeywordInput,
    AppDialog
  },

  props: {
    show: {
      type: Boolean,
      default: false
    },

    workspaceToDelete: {
      type: Object,
      default: () => ({})
    },

    isLoading: {
      type: Boolean
    }
  },

  emits: { 'on-confirm': null, 'on-close': null },

  data() {
    return {
      confirmKey: '',
      isConfirmKeyLengthNotValid: false,
      isConfirmKeyWrong: false
    }
  },

  computed: {
    DIALOG_TYPES: () => DIALOG_TYPES,
    KEYWORD: () => KEYWORD
  },

  methods: {
    clearForm() {
      this.confirmKey = ''
      this.isConfirmKeyLengthNotValid = false
      this.isConfirmKeyWrong = false
    },

    onClose() {
      this.$emit('on-close')
      this.clearForm()
    },

    onConfirm() {
      const isKeywordValid = this.$refs.keywordInputReference.validate()
      if (isKeywordValid) {
        this.$emit('on-confirm')
        this.clearForm()
      }
    }
  }
})
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/modal-danger-text';

.wd-DeleteConfirmMessage {
  padding-bottom: 24px;
}

.wd-DangerText {
  @extend %modal-danger-text;
}
</style>
