import { useRoute } from 'vue-router'
import { useStore } from 'vuex'

import { menuItemsNames } from '@/utils/menu-items'
import { SETTINGS_REFERER, USER_SETTINGS_MAPPER } from '@/utils/user-settings'

export const EXCLUDED_REFERERS = Object.values(menuItemsNames.SETTINGS.children).map(
  item => item.routeName
)

export const checkIsRefererExcluded = ({ route }) => {
  return route.matched.some(route => EXCLUDED_REFERERS.includes(route.name))
}

export const useBackSettingsBackButton = () => {
  const route = useRoute()
  const store = useStore()
  const saveSettingsReferer = async () => {
    const isExcludedReferer = checkIsRefererExcluded({ route })

    if (!isExcludedReferer) {
      await store.dispatch('system/updateUserSettings', {
        [USER_SETTINGS_MAPPER[SETTINGS_REFERER]]: route.fullPath
      })
    }
  }

  return {
    saveSettingsReferer
  }
}
