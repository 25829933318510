<template>
  <AppDroplist
    :hide-on-click="false"
    :theme="DROP_LIST_THEMES.LIGHT"
    append-to=".slu-Main"
    dropdown-width="280px"
    fit-content-width
    placement="bottom"
    trigger="mouseenter"
  >
    <template #button>
      <div class="si-Trigger">
        <span>
          {{ $t('objectives.predicted', { symbol: predictedScore > MAX_GRADE ? '≧' : null }) }}
          <span :style="{ color: `var(${getGradeColorVariable(gradeColor)})` }">
            {{ roundValue(resolvedPredictedScore) }}%
          </span>
        </span>
        <AppIcon height="24" icon-name="info-next" width="24" />
      </div>
    </template>
    <div
      :style="{
        '--color': `var(${getGradeColorVariable(gradeColor)})`
      }"
      class="si-TooltipContainer"
    >
      <i18n-t
        class="si-TooltipTitle si-TooltipTitle-bold"
        keypath="objectives.status_indicator.description.predicted"
        scope="global"
        tag="div"
      >
        <template #predicted>
          <span>{{ getSimplifiedNumber(predictedScore)[PLURALIZATION_KEYS.SIMPLIFIED] }}%</span>
        </template>
        <template #grade>
          <span>{{ getSimplifiedNumber(grade)[PLURALIZATION_KEYS.SIMPLIFIED] }}</span>
        </template>
        <template #passed>
          {{ Math.min(roundValue(passed), MAX_GRADE) }}
        </template>
      </i18n-t>

      <i18n-t
        class="si-TooltipTitle"
        keypath="objectives.status_indicator.description.grade"
        scope="global"
        tag="div"
      >
        <template #grade>
          <span>{{ getSimplifiedNumber(grade)[PLURALIZATION_KEYS.SIMPLIFIED] }}%</span>
        </template>
      </i18n-t>

      <i18n-t
        class="si-TooltipTitle"
        keypath="objectives.status_indicator.description.passed"
        scope="global"
        tag="div"
      >
        <template #passed>
          <b>{{ roundValue(passed) > MAX_GRADE ? '100' : roundValue(passed) }}%</b>
        </template>
      </i18n-t>
      <div class="si-Chart">
        <LineChart
          v-if="chartData"
          :aspect-ratio="1.6"
          :chart-data="chartData"
          :chart-type="CHART_TYPES.predictedScorePreview"
          :grade-values="gradeValues"
          full-size-chart
          set-y-from0-to100
          without-background
        />
      </div>
    </div>
  </AppDroplist>
</template>

<script>
import dayjs from 'dayjs'
import { defineComponent } from 'vue'
import { mapState } from 'vuex'

import { DROP_LIST_THEMES } from '@/utils/components-configurations/app-droplist'
import { compensateTimeZone } from '@/utils/date'
import { CHART_TYPES } from '@/utils/objective-chart'
import { getMinMaxDates, getSelectedIntervalDates } from '@/utils/okr-element-dates'
import { getGradeColorStyle, getGradeColorVariable } from '@/utils/okr-elements/grade'
import { getSimplifiedNumber, PLURALIZATION_KEYS } from '@/utils/pluralization'

import AppDroplist from '@/components/AppDroplist'
import LineChart from '@/components/LineChart/LineChart'
import AppIcon from '@/components/ui/AppIcon/AppIcon'

const MIN_GRADE = 0
const MAX_GRADE = 100

export default defineComponent({
  name: 'StatusIndicator',
  components: {
    LineChart,
    AppIcon,
    AppDroplist
  },

  props: {
    predictedScore: {
      type: [String, Number],
      default: 0
    },

    passed: {
      type: [String, Number],
      default: 0
    },

    grade: {
      type: [String, Number],
      default: MIN_GRADE
    },

    gradeColor: {
      type: String,
      required: true
    },

    okrElement: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapState('system', {
      settings: state => state.settings
    }),

    DROP_LIST_THEMES: () => DROP_LIST_THEMES,

    CHART_TYPES: () => CHART_TYPES,

    MAX_GRADE: () => MAX_GRADE,

    PLURALIZATION_KEYS: () => PLURALIZATION_KEYS,

    resolvedPredictedScore() {
      return Math.min(this.predictedScore, MAX_GRADE)
    },

    gradeForChart() {
      return this.roundValue(Math.min(this.grade, MAX_GRADE))
    },

    predictedScoreForChart() {
      return this.roundValue(this.resolvedPredictedScore)
    },

    selectedIntervalDates() {
      return getSelectedIntervalDates(this.okrElement)
    },

    minMaxDates() {
      return getMinMaxDates(
        this.selectedIntervalDates,
        this.okrElement.elementStartDate,
        this.okrElement.dueDate
      )
    },

    gradeValues() {
      return {
        grade: this.gradeForChart,
        predictedScore: this.predictedScoreForChart,
        thresholdBehind: this.settings.thresholdBehind,
        thresholdOnTrack: this.settings.thresholdOnTrack,
        fillThresholdLines: true
      }
    },

    chartData() {
      if (!this.okrElement) {
        return null
      }
      const today = +compensateTimeZone(dayjs(), true)

      const xmin = Math.min(today, +this.minMaxDates.minDate)
      const xmax = Math.max(today, +this.minMaxDates.maxDate)

      const { '--rgb-color': rgbColor } = getGradeColorStyle(this.gradeColor)

      const color = `rgb(${rgbColor})`

      return {
        chartLines: [
          {
            color,
            data: {
              [today]: this.gradeForChart
            }
          },
          {
            color: `rgb(${rgbColor}, 0.5)`,
            data: {
              [xmax]: this.predictedScoreForChart
            }
          },
          {
            color,
            data: {
              [xmin]: null,
              [xmax]: null
            }
          }
        ]
      }
    }
  },

  methods: {
    getSimplifiedNumber,

    getGradeColorVariable,

    roundValue(value) {
      return Math.round(value)
    }
  }
})
</script>

<style lang="scss" scoped>
.si-Trigger {
  display: flex;
  font-family: $system-ui;
  font-size: $fs-12;
  font-weight: fw('semi-bold');
  color: $dark-3;
  align-items: center;
  white-space: nowrap;
  flex-shrink: 0;
}

.si-TooltipContainer {
  padding: 20px 20px 23px;
  // box-shadow: $common-box-shadow;
}

.si-TooltipTitle {
  font-family: $system-ui;
  font-style: normal;

  font-size: $fs-12;
  line-height: 16px;
  color: $dark-3;

  &:not(:last-child) {
    margin-bottom: 4px;
  }

  span {
    color: var(--color);
    font-weight: fw('bold');
  }

  &:not(&-bold) {
    font-weight: fw('regular');
  }

  &-bold {
    font-weight: fw('bold');
  }
}

.si-Chart {
  margin-top: 12px;
}
</style>
