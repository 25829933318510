<template>
  <div class="otl-Loader">
    <div v-if="!hideHeader" class="otl-Loader_Head">
      <div
        v-for="(width, index) in cellsWidths"
        :key="`head-cell-${width}`"
        :style="{ width: width }"
        class="otl-HeadCell"
      >
        <SkeletonItem :size="XS" :width="headCells[index]" />
      </div>
      <div v-if="!hideOptions" class="otl-HeadCell">
        <SkeletonItem :appearance="SQUARE" :size="SM" />
      </div>
    </div>
    <div class="otl-Loader_Body">
      <div v-for="row in rows" :key="row.id" class="otl-BodyRow">
        <template
          v-for="(width, index) in bodyCells"
          :key="`body-cell-${row.id}-${index}-${width}`"
        >
          <div v-if="index" :style="{ width: cellsWidths[index] }" class="otl-BodyCell">
            <SkeletonItem :size="XS" :width="width" />
          </div>

          <div
            v-else
            :style="{ 'padding-left': `${row.offset}px`, width: cellsWidths[index] }"
            class="otl-NameCell"
          >
            <SkeletonItem :appearance="SQUARE" :size="SM" />
            <SkeletonItem :width="`${116 - row.halfOffset}px`" height="20px" />
            <SkeletonItem :width="`${88 - row.halfOffset}px`" class="otl-OkrName" height="18px" />
          </div>
        </template>

        <div v-if="!hideOptions" class="otl-BodyCell otl-BodyCell-options">
          <SkeletonItem :appearance="SQUARE" :size="SM" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'

import {
  SKELETON_APPEARANCES,
  SKELETON_SIZES
} from '@/utils/components-configurations/skeleton-item'

import SkeletonItem from '@/components/ui/SkeletonLoaders/SkeletonItem'

defineOptions({
  name: 'ObjectivesTableLoader'
})

const CELLS_WIDTHS = ['300px', '90px', '113px', '103px', '103px', '93px']

const HEAD_CELLS = ['170px', '38px', '66px', '45px', '40px', '58px']

const BODY_CELLS = ['250px', '37px', '113px', '103px', '76px', '93px']

const { XS, SM } = SKELETON_SIZES

const { SQUARE } = SKELETON_APPEARANCES

const props = defineProps({
  hideHeader: {
    type: Boolean
  },

  hideOptions: {
    type: Boolean
  },

  columnsCount: {
    type: Number,
    default: 6
  },

  headerHeight: {
    type: String,
    default: '32px'
  },

  initialColumns: {
    type: Array,
    default: undefined
  },

  rowCount: {
    type: Number,
    default: 15
  }
})
const ROWS = [
  {
    id: 0,
    depth: 1
  },
  {
    id: 1,
    depth: 1
  },
  {
    id: 2,
    depth: 2
  },
  {
    id: 3,
    depth: 3
  },
  {
    id: 4,
    depth: 2
  },
  {
    id: 5,
    depth: 2
  },
  {
    id: 6,
    depth: 2
  },
  {
    id: 7,
    depth: 3
  },
  {
    id: 8,
    depth: 4
  },
  {
    id: 9,
    depth: 4
  },
  {
    id: 10,
    depth: 4
  },
  {
    id: 11,
    depth: 5
  },
  {
    id: 12,
    depth: 5
  },
  {
    id: 13,
    depth: 5
  },
  {
    id: 14,
    depth: 2
  }
].slice(0, props.rowCount)

const cellsWidths = computed(() => CELLS_WIDTHS.slice(0, resolvedColumnsCount.value))

const headCells = computed(() => HEAD_CELLS.slice(0, resolvedColumnsCount.value))

const bodyCells = computed(() => BODY_CELLS.slice(0, resolvedColumnsCount.value))

const rows = ROWS.map(row => ({
  ...row,
  offset: row.depth * 32,
  halfOffset: row.depth * 12
}))

const resolvedColumnsCount = computed(() => {
  return props.initialColumns ? props.initialColumns.length : props.columnsCount
})
</script>

<style lang="scss" scoped>
.otl-Loader {
  width: 100%;
  padding: 0 $page-right-padding 0 $page-left-padding;
}

.otl-Loader_Head {
  display: flex;
  gap: 12px;
  height: v-bind(headerHeight);
  align-items: center;
  padding-left: 24px;
}

.otl-HeadCell {
  &:first-child {
    padding-left: 32px;
  }

  &:last-child {
    margin-left: auto;
  }
}

.otl-Loader_Body {
  padding-left: 24px;
}

.otl-BodyRow {
  display: flex;
  align-items: center;
  gap: 12px;
  height: 44px;
}

.otl-NameCell {
  display: flex;
  align-items: center;
  gap: 8px;
}

.otl-BodyCell {
  &:last-child {
    margin-left: auto;
  }

  &-options {
    display: flex;
    gap: 10px;
  }
}
</style>
