<template>
  <AppSelect
    :data-auto-testid="BULK_ACTIONS_OPERATION_VALUE_CONTROL_TEST_IDS.OPERATION_SELECT"
    :data-testid="BULK_ACTIONS_OPERATION_VALUE_CONTROL_TEST_IDS.OPERATION_SELECT"
    :loading="loading"
    :model-value="modelValue[OKR_BULK_UPDATE_ENTITY_KEYS.OPERATION]"
    :offset="[0, 0]"
    :options="getMappedOperations"
    :type="SELECT_TYPES.MODERN"
    item-label="name"
    skeleton-loader
    skeleton-loader-height="100%"
    skeleton-loader-width="100%"
    @update:model-value="onUpdateOperation"
  />

  <div
    v-if="showValueSelect"
    :class="{
      'boa-SelectWrapper-multi': isMulti
    }"
    class="boa-SelectWrapper"
  >
    <slot name="value-controller">
      <AppSelect
        :data-auto-testid="BULK_ACTIONS_OPERATION_VALUE_CONTROL_TEST_IDS.VALUE_SELECT"
        :data-testid="BULK_ACTIONS_OPERATION_VALUE_CONTROL_TEST_IDS.VALUE_SELECT"
        :dropdown-search="FIELD_PROPS[fieldId].dropdownSearch"
        :has-only-this-button="isMulti"
        :hide-selected-items-in-dropdown="isMulti"
        :item-label="itemLabel"
        :item-value="itemValue"
        :loading="loading"
        :model-value="modelValue.value"
        :multi="isMulti"
        :offset="FIELD_PROPS[fieldId].offset"
        :options="options"
        :search-function="searchFunction"
        :show-all-selected="isMulti"
        :show-selected-options-inside="isMulti"
        append-to="parent"
        boundary="scrollParent"
        skeleton-loader
        skeleton-loader-height="100%"
        skeleton-loader-width="100%"
        @hide="toggleValueDropdown(false)"
        @open="toggleValueDropdown"
        @update:options="emit('update:options', $event)"
        @update:model-value="onUpdateValue"
      >
        <template #option-label="{ option }">
          <Component
            :is="optionLabelComponent"
            v-if="option && optionLabelComponent"
            :group="option"
            :option="option"
          />
        </template>
        <template #button-content="{ options: buttonOptions }">
          <span v-if="isMulti && isEmpty(buttonOptions)" class="boa-SelectEmptyLabel">
            {{ emptyStateLabel || t('bulk_actions.set_value') }}
          </span>
        </template>
      </AppSelect>
    </slot>
  </div>
</template>

<script setup>
import { isEmpty } from 'lodash'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import { SELECT_TYPES } from '@/utils/components-configurations/app-select'
import { ALL_CUSTOM_FIELDS } from '@/utils/custom-fields/factory'
import { OKR_BULK_UPDATE_ENTITY_KEYS } from '@/utils/entity-keys'
import {
  BULK_FIELD_OPERATION_NAMES,
  BULK_FIELD_OPERATION_TYPE_IDS,
  CORE_FIELD_ALLOWED_BULK_OPERATIONS,
  CORE_FIELDS_IDS,
  CUSTOM_FIELD_ALLOWED_BULK_OPERATIONS
} from '@/utils/okr-elements-table-bulk-actions'

import OwnerFieldOption from '@/components/form/OwnerFieldOption'
import GlobalGroupsSelectOption from '@/components/global-groups/GlobalGroupsSelectOption'
import { BULK_ACTIONS_OPERATION_VALUE_CONTROL_TEST_IDS } from '@/components/objectives/bulk-edit/jest-helpers'
import AppSelect from '@/components/ui/AppSelect/AppSelect'

defineOptions({
  name: 'BulkActionsOperationValueControl'
})

const props = defineProps({
  options: {
    type: Array,
    default: () => []
  },

  modelValue: {
    type: Object,
    required: true
  },

  fieldId: {
    type: Number,
    required: true
  },

  loading: {
    type: Boolean
  },

  itemLabel: {
    type: String,
    default: 'name'
  },

  itemValue: {
    type: String,
    default: 'id'
  },

  searchFunction: {
    type: Function,
    default: null
  },

  isCustomField: {
    type: Boolean
  },

  emptyStateLabel: {
    type: String,
    default: ''
  }
})

const emit = defineEmits({
  'update:modelValue': null,
  'update:options': null,
  'toggle-value-dropdown': null
})

const OPTIONS_LABELS_COMPONENTS = {
  [CORE_FIELDS_IDS.GROUPS]: GlobalGroupsSelectOption,
  [CORE_FIELDS_IDS.STAKEHOLDERS]: OwnerFieldOption,
  [CORE_FIELDS_IDS.OWNER]: OwnerFieldOption
}

const CUSTOM_FIELDS_OPTIONS_LABELS_COMPONENTS = {
  [ALL_CUSTOM_FIELDS.getTypeIds().ASSIGNEE]: OwnerFieldOption
}

const optionLabelComponent = computed(() => {
  if (props.isCustomField) {
    return CUSTOM_FIELDS_OPTIONS_LABELS_COMPONENTS[props.fieldId]
  }
  return OPTIONS_LABELS_COMPONENTS[props.fieldId]
})

const MULTI_SELECT_PROPS = {
  dropdownSearch: true,
  multi: true,
  offset: [0, '-100%']
}

// const SINGLE_SELECT_PROPS = {
//   dropdownSearch: false,
//   multi: false,
//   offset: [0, 0]
// }

const CORE_FIELD_PROPS = {
  [CORE_FIELDS_IDS.GROUPS]: {
    ...MULTI_SELECT_PROPS
  },
  [CORE_FIELDS_IDS.STAKEHOLDERS]: {
    ...MULTI_SELECT_PROPS
  },
  [CORE_FIELDS_IDS.LABELS]: {
    ...MULTI_SELECT_PROPS
  }
}

const CUSTOM_FIELD_PROPS = {
  [ALL_CUSTOM_FIELDS.getTypeIds().ASSIGNEE]: {
    ...MULTI_SELECT_PROPS
  },
  [ALL_CUSTOM_FIELDS.getTypeIds().MULTI_SELECT]: {
    ...MULTI_SELECT_PROPS
  }
}

const FIELD_PROPS = props.isCustomField ? CUSTOM_FIELD_PROPS : CORE_FIELD_PROPS

const isMulti = computed(() => {
  return FIELD_PROPS[props.fieldId]?.multi || false
})

const showValueSelect = computed(
  () =>
    props.modelValue[OKR_BULK_UPDATE_ENTITY_KEYS.OPERATION] ===
      BULK_FIELD_OPERATION_TYPE_IDS.REPLACE ||
    props.modelValue[OKR_BULK_UPDATE_ENTITY_KEYS.OPERATION] === BULK_FIELD_OPERATION_TYPE_IDS.ADD
)

const { t } = useI18n()

const getMappedOperations = computed(() => {
  return getAllowedOperations.value.map(value => {
    const name = BULK_FIELD_OPERATION_NAMES[value]

    return { name: t(name), value }
  })
})

const onUpdateOperation = operation => {
  emit('update:modelValue', { [OKR_BULK_UPDATE_ENTITY_KEYS.VALUE]: null, operation })
}

const onUpdateValue = value => {
  emit('update:modelValue', {
    value,
    [OKR_BULK_UPDATE_ENTITY_KEYS.OPERATION]: props.modelValue[OKR_BULK_UPDATE_ENTITY_KEYS.OPERATION]
  })
}

const getAllowedOperations = computed(() => {
  if (props.isCustomField) {
    return CUSTOM_FIELD_ALLOWED_BULK_OPERATIONS[props.fieldId]
  }
  return CORE_FIELD_ALLOWED_BULK_OPERATIONS[props.fieldId]
})

const toggleValueDropdown = (value = true) => {
  emit('toggle-value-dropdown', value)
}
</script>

<style lang="scss" scoped>
.boa-SelectWrapper {
  position: relative;
  min-height: 40px;

  &-multi {
    :deep(.as-AppDroplistButton_Content) {
      padding: 6px 0 6px 6px;
    }
  }
}

.boa-SelectEmptyLabel {
  padding-left: 2px;
}
</style>
