import {
  SET_ORGANIZATION_ID,
  SET_IS_WEB_APP,
  SET_URLS,
  SET_ORGANIZATIONS,
  UPDATE_ORGANIZATION,
  SET_IS_CONNECTION_INITIALIZED_BY_ME,
  SET_IS_DISCONNECTION_INITIALIZED_BY_ME,
  SET_IS_SALESFORCE_APP
} from '@/store/mutation-types'

const normalizeOrganization = organization => {
  const { iss, color, name } = organization
  const [prefix] = Array.from(name.trim().toUpperCase()) // only Array.from works correctly if we have a string starting with emoji
  return {
    ...organization,
    id: iss,
    color: color || '#000000',
    prefix
  }
}

const state = {
  selectedOrganizationId: null,
  organizations: [],
  isWebApp: false,
  isSalesforceApp: false,
  urls: {
    webAppUrl: null,
    webAppLoginUrl: null
  },
  connectionWithJira: {
    isConnectionInitializedByMe: false,
    isDisconnectionInitializedByMe: false
  }
}

const mutations = {
  [SET_ORGANIZATION_ID](state, value) {
    state.selectedOrganizationId = value
  },

  [SET_ORGANIZATIONS](state, value) {
    state.organizations = value.map(normalizeOrganization)
  },

  [UPDATE_ORGANIZATION](state, value) {
    const index = state.organizations.findIndex(instance => instance.id === value.iss)
    if (index !== -1) {
      state.organizations.splice(index, 1, normalizeOrganization(value))
    }
  },

  [SET_URLS](state, value) {
    state.urls = value
  },

  [SET_IS_WEB_APP](state) {
    state.isWebApp = true
  },

  [SET_IS_SALESFORCE_APP](state) {
    state.isSalesforceApp = true
  },

  [SET_IS_CONNECTION_INITIALIZED_BY_ME](state, value) {
    state.connectionWithJira.isConnectionInitializedByMe = Boolean(value)
  },

  [SET_IS_DISCONNECTION_INITIALIZED_BY_ME](state, value) {
    state.connectionWithJira.isDisconnectionInitializedByMe = Boolean(value)
  }
}

const getters = {
  selectedOrganization: state => {
    return (
      state.organizations.find(instance => instance.id === state.selectedOrganizationId) || null
    )
  }
}

const actions = {
  setOrganization({ commit }, value) {
    commit(SET_ORGANIZATION_ID, value)
  },

  setOrganizations({ commit }, value) {
    commit(SET_ORGANIZATIONS, value)
  },

  updateOrganization({ commit }, value) {
    commit(UPDATE_ORGANIZATION, value)
  },

  setUrls({ commit }, value) {
    commit(SET_URLS, value)
  },

  setIsWebApp({ commit }) {
    commit(SET_IS_WEB_APP)
  },

  setIsSalesforceApp({ commit }) {
    commit(SET_IS_SALESFORCE_APP)
  },

  setIsConnectionInitializedByMe({ commit }, value) {
    commit(SET_IS_CONNECTION_INITIALIZED_BY_ME, value)
  },

  setIsDisconnectionInitializedByMe({ commit }, value) {
    commit(SET_IS_DISCONNECTION_INITIALIZED_BY_ME, value)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
