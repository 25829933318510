<script setup>
import { useRouter } from 'vue-router'

import { ROUTE_NAMES } from '@/routes/route-helpers'

import AppButton from '@/components/ui/AppButton/AppButton'

const props = defineProps({
  destinationRouteName: {
    type: String,
    default: ROUTE_NAMES.SETTINGS_INTEGRATIONS
  }
})

const router = useRouter()

const handle = () => {
  router.push({ name: String(props.destinationRouteName) })
}
</script>

<template>
  <div class="abb-BackButton">
    <AppButton
      data-export-ignore
      height="24"
      icon="arrow-left-next"
      remove-padding
      size="sm"
      type="ghost-next"
      width="24"
      @click="handle"
    />
  </div>
</template>

<style lang="scss" scoped>
.abb-BackButton {
  padding: 32px $page-right-padding 0 $page-left-padding;
}
</style>
