<template>
  <div :class="{ 'fnif-Wrapper-without-head': !showHead }" class="fnif-Wrapper">
    <div v-if="showHead" class="fnif-Head">
      <ExpandCollapseButton
        v-if="showExpandCollapseButton"
        :loading="isExpandAllLoading"
        @expand="$emit('expand-all')"
        @collapse="$emit('collapse-all')"
      />
      <div class="fnif-Head_Title">
        {{ title }}
      </div>
      <div v-if="$slots.actions" class="fnif-Head_Actions">
        <slot name="actions" />
      </div>
    </div>

    <div class="fnif-Body">
      <slot />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'FormNestedItemsField'
})
</script>

<script setup>
import ExpandCollapseButton from '@/components/ui/ExpandCollapseButton/ExpandCollapseButton'

defineProps({
  showHead: {
    type: Boolean
  },

  title: {
    type: String,
    default: 'Nested items'
  },

  showExpandCollapseButton: {
    type: Boolean
  },

  isExpandAllLoading: {
    type: Boolean
  }
})

defineEmits(['expand-all', 'collapse-all'])
</script>

<style lang="scss" scoped>
.fnif-Wrapper {
  &:not(&-without-head) {
    border: 2px solid $grey-3-next;
    border-radius: 12px;
  }
}

.fnif-Head {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 18px;
  border-bottom: 2px solid $grey-3-next;
}

.fnif-Head_Title {
  font-family: $system-ui;
  font-style: normal;
  font-weight: fw('semi-bold');
  font-size: $fs-14;
  line-height: 20px;
  color: $dark-1;
}

.fnif-Head_Actions {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: inherit;
}

.fnif-Body {
  &:not(.fnif-Wrapper-without-head &) {
    padding: 8px 0;
  }
}
</style>
