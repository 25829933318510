<template>
  <div :class="classes" :style="{ '--width': `${COLUMNS_SETTINGS.OPTIONS.defaultWidth}px` }">
    <!--         (userCanDelete || userCanUpdate || userCanCopy) -->
    <AppButton
      v-if="optionsMenuItems.length && userHasCreateAccess && userHasReadAccess"
      :id="`ac-Cell_OptionsTrigger-${uid}`"
      :class="{ 'ac-Trigger-active': isActionsShow }"
      icon="more-next"
      size="sm"
      type="tertiary-next"
      @click="actionsSelectInitialised = true"
    />

    <DropdownMenu
      v-if="actionsSelectInitialised"
      :append-to="appendTo"
      :disabled="loading || isRowLoading"
      :items="optionsMenuItems"
      :offset="[0, 0]"
      :show="isActionsShow"
      :to-selector="`#ac-Cell_OptionsTrigger-${uid}`"
      :type="DROPDOWN_MENU_TYPES.DEFAULT_NEXT"
      preferred-position="bottom-end"
      show-on-init
      @update:show="$emit('update-show', $event)"
      @item-click="$emit('options-menu-item-click', $event)"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue'

import { DROPDOWN_MENU_TYPES } from '@/utils/components-configurations/dropdown-menu'
import { COLUMNS_SETTINGS } from '@/utils/objective-table'
import { uid } from '@/utils/uid'

import AppButton from '@/components/ui/AppButton/AppButton'
import DropdownMenu from '@/components/ui/DropdownMenu/DropdownMenu'

export default defineComponent({
  name: 'ActionsCell',
  components: { DropdownMenu, AppButton },
  props: {
    userHasCreateAccess: {
      type: Boolean
    },

    userHasReadAccess: {
      type: Boolean
    },

    // userCanDelete: {
    //   type: Boolean
    // },
    //
    // userCanUpdate: {
    //   type: Boolean
    // },
    //
    // userCanCopy: {
    //   type: Boolean
    // },

    isRowLoading: {
      type: Boolean
    },

    loading: {
      type: Boolean
    },

    onboarding: {
      type: Boolean
    },

    rowHovered: {
      type: Boolean
    },

    depth: {
      type: Number,
      required: true
    },

    isLast: {
      type: Boolean
    },

    isActionsShow: {
      type: Boolean
    },

    isNestedShow: {
      type: Boolean
    },

    optionsMenuItems: {
      type: Array,
      required: true
    },

    isRoadmapView: {
      type: Boolean
    }
  },

  emits: {
    'add-button-click': null,
    'update-nested-show': null,
    'actions-menu-item-click': null,
    'options-menu-item-click': null,
    'update-show': null
  },

  data() {
    return {
      uid: uid(),
      actionsSelectInitialised: false
    }
  },

  computed: {
    DROPDOWN_MENU_TYPES: () => DROPDOWN_MENU_TYPES,

    COLUMNS_SETTINGS: () => COLUMNS_SETTINGS,

    classes() {
      return {
        'ac-Cell': true,
        'ac-Cell-rowHovered': this.rowHovered || this.isActionsShow || this.isNestedShow,
        'ac-Cell-onboarding': this.onboarding && this.depth === 0 && this.isLast
        // 'ac-Cell-loading': this.loading
      }
    },

    appendTo() {
      return this.isRoadmapView ? '.rm-OkrPage' : '.o-objective-table'
    }
  },

  methods: {
    //   /** @public */
    //   showDropdown() {
    //     this.$refs.addMenu.showDropdown()
    //   },
    //
    //   /** @public */
    //   hideDropdown() {
    //     this.$refs.addMenu.hideDropdown()
    //   },
  }
})
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';
.ac-Cell {
  display: flex;
  padding: 10px $page-right-padding 10px 0;
  flex-shrink: 0;
  justify-content: flex-end;
  align-items: center;
  opacity: 0;
  margin-left: auto;

  right: 0;
  position: sticky;
  top: 0;
  @include tableOptionsGradient($grey-3-next);

  width: var(--width);

  &:not(&-roadmapView) {
    width: var(--width);
  }

  &-onboarding {
    body.step-1:not(body.step-2) & {
      opacity: 1;
    }
  }
}

.ac-Cell-rowHovered {
  opacity: 1;
}

// .ac-Cell-loading {
//   opacity: 1;
//   @include tableOptionsGradient($white);
//
//   &.ac-Cell-rowHovered {
//     @include tableOptionsGradient($grey-3-next);
//   }
// }
/*
.ac-Cell_Loader {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
}

.ac-Cell_Plus {
  .ac-Cell-rowHovered &,
  .ac-Cell-onboarding & {
    opacity: 1;
  }
}

.ac-Cell_OptionsTrigger {
  opacity: 0;
  margin-left: 10px;

  .ac-Cell-roadmapView.ac-Cell-withoutActions & {
    margin-left: 34px;
  }

  .ac-Cell-rowHovered & {
    opacity: 1;
  }
}

.otr-Level {
  display: flex;
  align-items: center;
}

.otr-LevelName {
  margin-left: 16px;
  font-size: $fs-16;
}
*/
.ac-Trigger-active {
  background-color: $dark-2;
  color: $white;
}
</style>
