import ApiHandler from '@/api/base'

class PersonalSettingsApiHandler extends ApiHandler {
  async getWorkspacesForDefaultGroup() {
    const { data } = await this.http.get('/internal/v2/getWorkspacesForDefaultGroup')
    return data
  }

  async setDefaultGroup({ defaultGroupId = null, workspaceId = null } = {}) {
    return await this.http.put('/internal/v2/setDefaultGroup', {
      defaultGroupId,
      workspaceId
    })
  }
}

export default PersonalSettingsApiHandler
