<template>
  <div class="iu-Step">
    <slot name="step-hero" />

    <slot name="step-title" />

    <FormFieldNext :label="$t('organization.invite_users.email_label')" class="iu-Step_FromField">
      <EmailCollectorInput :model-value="users" @update:model-value="addEmailsToList" />
    </FormFieldNext>

    <AppTable
      v-if="!isEmpty(users)"
      :columns="COLUMNS"
      :data="users"
      class="iu-Table"
      no-border
      offset-left="16px"
      offset-right="16px"
      style="--head-padding-bottom: 6px; --row-border-radius: 6px"
      type="primary-next"
    >
      <template #header-cell="{ column }">
        <template v-if="column.title">
          {{ $t(column.title) }}
        </template>
      </template>
      <template #cell="{ columnKey, item }">
        <template v-if="columnKey === TABLE_COLUMNS_KEYS.USER_NAME">
          <div class="iu-EmailCell">
            <AppIcon class="iu-EmailCell_Icon" height="24" icon-name="email" width="24" />

            <span class="oboard-truncated-text">
              {{ item.email }}
            </span>
          </div>
        </template>

        <div v-if="columnKey === TABLE_COLUMNS_KEYS.ROLE" class="iu-RolesCell">
          <AppSelect
            v-model="item.roleId"
            :dropdown-search="false"
            :inline-loader="false"
            :max-height="264"
            :offset="[0, 0]"
            :options="Object.values(ROLES)"
            boundary="scrollParent"
            class="iu-RolesSelect"
            item-label="label"
            item-value="value"
            position="bottom-end"
          >
            <template #button="{ active, option }">
              <div
                v-if="option"
                :class="{ 'iu-RolesTrigger-active': active }"
                class="iu-RolesTrigger"
              >
                <span class="oboard-truncated-text">
                  {{ option.label }}
                </span>
                <AppIcon
                  class="iu-RolesTrigger_Chevron"
                  height="24"
                  icon-name="chevron-down-sm"
                  width="24"
                />
              </div>
            </template>
          </AppSelect>
        </div>

        <template v-if="columnKey === TABLE_COLUMNS_KEYS.ACTION">
          <AppButton
            icon="cross-circle-next"
            size="sm"
            type="ghost"
            @click="removeUser(item.email)"
          />
        </template>
      </template>
    </AppTable>
  </div>
</template>

<script setup>
import { has, isEmpty } from 'lodash'
import { onBeforeUnmount, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import WebAppOrganizationApiHandler from '@/api/web-app-organization'
import { trackInviteUsersEvent } from '@/tracking/gtm-helpers'
import { ONBOARDING_ADMIN_ROLE } from '@/utils/admin-roles'
import { handleError } from '@/utils/error-handling'
import { WORKSPACE_USER_ROLES } from '@/utils/objectives'
import { TABLE_COLUMNS_KEYS } from '@/utils/table-columns'
import EmailCollectorInput from '@shared-modules/components/EmailCollectorInput'

import FormFieldNext from '@/components/form/FormFieldNext'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppIcon from '@/components/ui/AppIcon/AppIcon'
import AppSelect from '@/components/ui/AppSelect/AppSelect'
import AppTable from '@/components/ui/Table/AppTable'

defineOptions({
  name: 'InviteUsersStep'
})

const COLUMNS = [
  {
    title: 'common.users',
    key: TABLE_COLUMNS_KEYS.USER_NAME,
    width: 'auto'
  },
  {
    key: TABLE_COLUMNS_KEYS.ROLE,
    width: 88
  },
  {
    key: TABLE_COLUMNS_KEYS.ACTION,
    width: 24
  }
]

const users = ref([])

const { t } = useI18n()

const ROLES = {
  USER: {
    value: WORKSPACE_USER_ROLES.USER,
    label: t('roles.user')
  },
  ADMIN: {
    value: ONBOARDING_ADMIN_ROLE,
    label: t('roles.admin')
  }
}

const addEmailsToList = validEmailsList => {
  users.value = validEmailsList.map(user => {
    return {
      email: user.email,
      roleId: has(user, 'roleId') ? user.roleId : ROLES.USER.value
    }
  })
}

const removeUser = email => {
  users.value = users.value.filter(item => item.email !== email)
}

const sendInviteToUsers = async () => {
  const api = new WebAppOrganizationApiHandler()

  try {
    await api.inviteUsersToCompany({ users: users.value })
    trackInviteUsersEvent()
  } catch (error) {
    handleError({ error })
  }
}

onBeforeUnmount(() => {
  if (!isEmpty(users.value)) {
    sendInviteToUsers()
  }
})
</script>
<style lang="scss" scoped>
@import '~@/assets/styles/wizard-steps';

.iu-Step {
  @extend %wizard-step-styles;
}

.iu-Step_FromField {
  width: 100%;
}

.iu-Table {
  width: 100%;
}

.iu-EmailCell {
  display: flex;
  gap: 8px;
  align-items: center;
  padding-right: 8px;
}

.iu-EmailCell_Icon {
  flex-shrink: 0;
  color: $dark-2;
}

.iu-RolesCell {
  display: flex;
  padding-right: 8px;
  justify-content: flex-end;
}

.iu-RolesSelect {
  max-width: 100%;
}

.iu-RolesTrigger {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.iu-RolesTrigger_Chevron {
  flex-shrink: 0;
  transition: transform $transition-fast;

  .iu-RolesTrigger-active & {
    transform: rotate(180deg);
  }
}
</style>
