<template>
  <portal to="modal-windows">
    <UserDeleteModal
      :loading="deleteUserLoading"
      :show="isShowUserDeleteModal"
      :title="deleteUserConfig.title"
      data-testid="user-delete-modal"
      @on-close="closeUserDeleteModal"
      @on-confirm="onDeleteUser"
    >
      <i18n-t :keypath="deleteUserConfig.description" scope="global">
        <template #userName>
          <span class="gmh-DangerText">
            {{ deleteUserConfig.userName }}
          </span>
        </template>

        <template #groupName>
          <span class="gmh-DangerText">
            {{ deleteUserConfig.groupName }}
          </span>
        </template>
      </i18n-t>
    </UserDeleteModal>

    <AddUsersToGroupModal
      :group="groupForAddUser"
      :show="isShowAddUsersToGroupModal"
      :tracking-source="linkGroupTrackingSource"
      data-testid="add-users-to-group-modal"
      @close="onCloseAddUsersToGroupModal"
      @on-users-added="onUsersAddedToGroup"
    />

    <LinkPlatformGroupsModal
      :group="groupForLinkPlatformGroups"
      :show="isShowLinkPlatformGroupsModal"
      :tracking-source="linkGroupTrackingSource"
      data-testid="link-platform-groups-modal"
      @close="onCloseLinkPlatformGroupsModal"
      @on-groups-linked="onGroupsLinked"
    />

    <UnlinkPlatformGroupsModal
      :group="groupForUnlinkPlatformGroups"
      :show="isShowUnlinkPlatformGroupsModal"
      :tracking-source="unlinkGroupTrackingSource"
      data-testid="unlink-platform-groups-modal"
      @close="onCloseUnlinkPlatformGroupsModal"
      @on-groups-unlinked="onGroupsUnlinked"
    />

    <AddGroupsToWorkspaceModal
      :opened="isAddGroupsToWorkspaceModalOpened"
      :workspace-id="workspaceId"
      @on-groups-added="onGroupsAddedToWorkspace"
      @on-close="onAddGroupsToWorkspaceModalClose"
    />

    <GlobalGroupsDeleteModal
      :group-to-delete="groupToDelete"
      :show="isShowDeleteGlobalGroupModal"
      :tracking-source="EVENT_SOURCES.GROUP_FORM"
      @close="onDeleteGroupModalClose"
      @on-group-deleted="onGroupDeleted"
    />
  </portal>
</template>

<script setup>
import { isEmpty, isNull } from 'lodash'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

import GlobalGroupsApiHandler from '@/api/global-groups'
import { tracker } from '@/tracking/amplitude'
import { EVENT_CATEGORIES, EVENT_SOURCES, TRACKING_UNKNOWN } from '@/tracking/amplitude-helpers'
import { handleError } from '@/utils/error-handling'
import { NOTIFICATION_TYPES, showNotify } from '@/utils/notify'
import {
  emitsValidator,
  ERRORS_TEXTS,
  GROUP_ID_KEY
} from '@/utils/workspace-configuration-modal-helpers'

import AddGroupsToWorkspaceModal from '@/components/global-groups/AddGroupsToWorkspaceModal'
import GlobalGroupsDeleteModal from '@/components/global-groups/GlobalGroupDeleteModal'
import AddUsersToGroupModal from '@/components/workspaces/configuration-modal/AddUsersToGroupModal'
import LinkPlatformGroupsModal from '@/components/workspaces/configuration-modal/LinkPlatformGroupsModal'
import UnlinkPlatformGroupsModal from '@/components/workspaces/configuration-modal/UnlinkPlatformGroupsModal'
import UserDeleteModal from '@/components/workspaces/UserDeleteModal'

defineOptions({
  name: 'GroupsModalsHub'
})

const props = defineProps({
  groups: {
    type: Array,
    required: true
  },

  workspaceId: {
    type: [String, Number],
    default: null
  },

  unlinkGroupTrackingSource: {
    type: String,
    default: TRACKING_UNKNOWN
  },

  linkGroupTrackingSource: {
    type: String,
    default: TRACKING_UNKNOWN
  }
})

const isShowUserDeleteModal = ref(false)
const userToDelete = ref(null)
const showUserDeleteModal = ({ user }) => {
  if (!user || isEmpty(user)) {
    throwError(ERRORS_TEXTS.NOT_DEFINED_USER)
  }

  userToDelete.value = { ...user }
  isShowUserDeleteModal.value = true
}

const closeUserDeleteModal = () => {
  isShowUserDeleteModal.value = false
  userToDelete.value = null
}

const { t } = useI18n()

const deleteUserConfig = computed(() => {
  const userName = userToDelete.value?.name || 'this user'
  const groupName =
    props.groups.find(group => group.id === userToDelete.value?.currentGroupId)?.name ||
    'this group'

  return {
    title: t('delete_user.title', {
      userName
    }),
    description: 'workspaces.delete_user_from_group',
    userName,
    groupName
  }
})

const deleteUserLoading = ref(false)
const onDeleteUser = async () => {
  deleteUserLoading.value = true
  const { currentGroupId, accountId } = userToDelete.value

  const api = new GlobalGroupsApiHandler()
  try {
    await api.removeUserFromGlobalGroup({
      groupId: currentGroupId,
      accountId
    })

    emit('user-deleted', {
      accountId,
      groupId: currentGroupId,
      callBack: () => closeUserDeleteModal()
    })
  } catch (error) {
    handleError({ error })
  } finally {
    deleteUserLoading.value = false
  }
}

const isShowAddUsersToGroupModal = ref(false)
const groupForAddUser = ref({})

const onCloseAddUsersToGroupModal = () => {
  isShowAddUsersToGroupModal.value = false
  groupForAddUser.value = {}
}

const showAddUsersToGroupModal = ({ group }) => {
  if (!group || isEmpty(group)) {
    throwError(ERRORS_TEXTS.NOT_DEFINED_GROUP)
  }

  groupForAddUser.value = { ...group }
  isShowAddUsersToGroupModal.value = true
}

const onUsersAddedToGroup = async groupId => {
  emit('reload-group', { groupId, callBack: () => onCloseAddUsersToGroupModal() })
}

const isShowLinkPlatformGroupsModal = ref(false)
const groupForLinkPlatformGroups = ref({})

const showLinkPlatformGroupsModal = ({ group }) => {
  if (!group || isEmpty(group)) {
    throwError(ERRORS_TEXTS.NOT_DEFINED_GROUP)
  }

  groupForLinkPlatformGroups.value = { ...group }
  isShowLinkPlatformGroupsModal.value = true
}

const onCloseLinkPlatformGroupsModal = () => {
  isShowLinkPlatformGroupsModal.value = false
  groupForLinkPlatformGroups.value = {}
}

const onGroupsLinked = async groupId => {
  emit('reload-group', { groupId, callBack: () => onCloseLinkPlatformGroupsModal() })
}

const isShowUnlinkPlatformGroupsModal = ref(false)
const groupForUnlinkPlatformGroups = ref({})

const showUnlinkPlatformGroupsModal = ({ group }) => {
  if (!group || isEmpty(group)) {
    throwError(ERRORS_TEXTS.NOT_DEFINED_GROUP)
  }

  groupForUnlinkPlatformGroups.value = { ...group }
  isShowUnlinkPlatformGroupsModal.value = true
}
const onCloseUnlinkPlatformGroupsModal = () => {
  isShowUnlinkPlatformGroupsModal.value = false
  groupForUnlinkPlatformGroups.value = {}
}

const onGroupsUnlinked = async groupId => {
  emit('reload-group', { groupId, callBack: () => onCloseUnlinkPlatformGroupsModal() })
}

const store = useStore()

const userRoleForTracking = computed(() => {
  return store.getters['system/userRoleForTracking']
})
const isAddGroupsToWorkspaceModalOpened = ref(false)
const showAddGroupsToWorkspaceModal = () => {
  if (isNull(props.workspaceId)) {
    throwError(ERRORS_TEXTS.NOT_DEFINED_WORKSPACE_ID)
  }
  isAddGroupsToWorkspaceModalOpened.value = true
  emit('child-modal-opened')

  tracker.logEvent('add group form opened', {
    category: EVENT_CATEGORIES.GROUP_MANAGEMENT,
    role: userRoleForTracking.value
  })
}

const onAddGroupsToWorkspaceModalClose = () => {
  emit('child-modal-closed')
  isAddGroupsToWorkspaceModalOpened.value = false
}
const onGroupsAddedToWorkspace = async () => {
  emit('reload-all-groups', { callBack: () => onAddGroupsToWorkspaceModalClose() })
}

const groupToDelete = ref({})
const isShowDeleteGlobalGroupModal = ref(false)

const showDeleteGlobalGroupModal = ({ group }) => {
  if (!group || isEmpty(group)) {
    throwError(ERRORS_TEXTS.NOT_DEFINED_GROUP)
  }

  groupToDelete.value = { ...group }
  isShowDeleteGlobalGroupModal.value = true
}
const onDeleteGroupModalClose = () => {
  isShowDeleteGlobalGroupModal.value = false
  groupToDelete.value = {}
}

const onGroupDeleted = async groupId => {
  emit('group-deleted', { groupId, callBack: () => onDeleteGroupModalClose() })
}

const emit = defineEmits({
  'reload-group': obj => emitsValidator({ obj, key: GROUP_ID_KEY }),
  'reload-all-groups': null,
  'child-modal-opened': null,
  'child-modal-closed': null,
  'group-deleted': obj => emitsValidator({ obj, key: GROUP_ID_KEY }),
  'user-deleted': obj => emitsValidator({ obj, key: GROUP_ID_KEY })
})

defineExpose({
  showUserDeleteModal,
  showAddUsersToGroupModal,
  showLinkPlatformGroupsModal,
  showUnlinkPlatformGroupsModal,
  showAddGroupsToWorkspaceModal,
  showDeleteGlobalGroupModal
})

const throwError = content => {
  showNotify({ expanded: true, content, type: NOTIFICATION_TYPES.ERROR })
  throw new Error(content)
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/modal-danger-text';

.gmh-DangerText {
  @extend %modal-danger-text;
}
</style>
