import { NOTIFICATION_CHANNELS } from '@/utils/okr-elements/notifications'

export const LIMIT = 10

export const NOTIFICATIONS_STATUSES = {
  CREATED: 1,
  READY_TO_SEND: 2,
  SENT: 3,
  DELIVERED: 4
}

export const NOTIFICATION_ATTRIBUTES = {
  GRADE: 1, // unused here, need for email notifications
  ELEMENT_ID: 2,
  ELEMENT_TYPE_ID: 3,
  ELEMENT_DISPLAY_ID: 4,
  AUTHOR_NAME: 5,
  AUTHOR_AVATAR: 6,
  COMMENT_ID: 7,
  ELEMENT_TITLE: 8,
  ELEMENT_LEVEL_PREFIX: 9,
  ELEMENT_LEVEL_COLOR: 10,
  WORKSPACE_ID: 11,
  COMMENT_TEXT: 12,
  OKR_URL: 14, // unused here, need for email notifications
  USER_PREFERENCES_URL: 15, // unused here, need for email notifications
  OBJ_STATUS_BEFORE: 16,
  OBJ_STATUS_AFTER: 17,
  TIMESTAMP: 20, // unused here, need for email notifications
  COORDINATE_UNIVERSAL_TIME: 21, // unused here, need for email notifications
  CURRENT_CONFIDENCE_LEVEL: 22,
  CONFIDENCE_LEVEL_CUSTOM_NAME_OLD: 23,
  CONFIDENCE_LEVEL_CUSTOM_NAME_NEW: 24,
  CURRENT_CONFIDENCE_LEVEL_CUSTOM_NAME: 25,
  SCHEDULE_NAME: 26
}

export const NOTIFICATION_TYPES = {
  USER_TAGGED: 1,
  GRADE_CHANGED: 2,
  MARKETING_PRODUCT_UPDATE: 3,
  MARKETING_PRODUCT_CASE_STUDY: 4,
  MARKETING_PRODUCT_PROMOS: 5,
  REPLY_COMMENT: 6,
  AGGREGATED_MESSAGE: 7,
  COMMENT: 8,
  CONFIDENCE_LEVEL_CHANGE: 9,
  SCHEDULE_REMINDER: 10
}

export const OWNER_CHANGES = 'OWNER_CHANGES'
export const REPLY_AND_MENTION = 'REPLY_AND_MENTION'
export const STAKEHOLDER_CHANGES = 'STAKEHOLDER_CHANGES'
export const REMINDER_CHANGES = 'REMINDER_CHANGES'

class NotificationSettingType {
  #typeId
  #channelsWhereAlwaysEnabled
  #notificationGroups

  constructor(typeId, channelsWhereAlwaysEnabled = [], notificationGroups = []) {
    this.#typeId = typeId
    this.#channelsWhereAlwaysEnabled = [...channelsWhereAlwaysEnabled]
    this.#notificationGroups = [...notificationGroups]
  }

  get typeId() {
    return this.#typeId
  }

  get channelsWhereAlwaysEnabled() {
    return this.#channelsWhereAlwaysEnabled
  }

  get notificationGroups() {
    return this.#notificationGroups
  }
}

const { typeId: inboxChannelTypeId } = NOTIFICATION_CHANNELS.INBOX

class NotificationSettingsConfig {
  #config

  constructor() {
    this.#config = {
      USER_TAGGED: new NotificationSettingType(1, [inboxChannelTypeId], [REPLY_AND_MENTION]),
      GRADE_CHANGED_FOR_OWNER: new NotificationSettingType(
        2,
        [inboxChannelTypeId],
        [OWNER_CHANGES]
      ),
      MARKETING_PRODUCT_UPDATE: new NotificationSettingType(3),
      MARKETING_PRODUCT_CASE_STUDY: new NotificationSettingType(4),
      MARKETING_PRODUCT_PROMOS: new NotificationSettingType(5),
      REPLY_COMMENT: new NotificationSettingType(6, [inboxChannelTypeId], [REPLY_AND_MENTION]),
      COMMENTED_FOR_OWNER: new NotificationSettingType(7, [inboxChannelTypeId], [OWNER_CHANGES]),
      CONFIDENCE_LEVEL_CHANGE_FOR_OWNER: new NotificationSettingType(
        8,
        [inboxChannelTypeId],
        [OWNER_CHANGES]
      ),
      GRADE_CHANGED_FOR_STAKEHOLDER: new NotificationSettingType(
        9,
        [inboxChannelTypeId],
        [STAKEHOLDER_CHANGES]
      ),
      COMMENTED_FOR_STAKEHOLDER: new NotificationSettingType(
        10,
        [inboxChannelTypeId],
        [STAKEHOLDER_CHANGES]
      ),
      CONFIDENCE_LEVEL_CHANGE_FOR_STAKEHOLDER: new NotificationSettingType(
        11,
        [inboxChannelTypeId],
        [STAKEHOLDER_CHANGES]
      ),
      CHECKIN_SCHEDULED: new NotificationSettingType(12, [inboxChannelTypeId], [REMINDER_CHANGES])
    }
  }

  get config() {
    return this.#config
  }

  get settingsTypesIds() {
    return Object.values(this.#config).map(item => item.typeId)
  }

  getNotificationSettingsTypesWhereChannelAlwaysEnabled({ channelId = null } = {}) {
    if (!channelId) return []

    return Object.values(this.#config)
      .filter(item => {
        return item.channelsWhereAlwaysEnabled.includes(channelId)
      })
      .map(item => item.typeId)
  }

  getNotificationSettingsTypesByGroupName({ groupName = '' } = {}) {
    if (!groupName) return []

    return Object.values(this.#config)
      .filter(item => {
        return item.notificationGroups.includes(groupName)
      })
      .map(item => item.typeId)
  }
}

export const NOTIFICATIONS_SETTINGS_CONFIG = new NotificationSettingsConfig()

export const GROUPED_NOTIFICATIONS = {
  [REPLY_AND_MENTION]: {
    value: 1,
    notificationSettingTypes: NOTIFICATIONS_SETTINGS_CONFIG.getNotificationSettingsTypesByGroupName(
      {
        groupName: REPLY_AND_MENTION
      }
    )
  },
  [OWNER_CHANGES]: {
    value: 2,
    notificationSettingTypes: NOTIFICATIONS_SETTINGS_CONFIG.getNotificationSettingsTypesByGroupName(
      {
        groupName: OWNER_CHANGES
      }
    )
  },
  [STAKEHOLDER_CHANGES]: {
    value: 3,
    notificationSettingTypes: NOTIFICATIONS_SETTINGS_CONFIG.getNotificationSettingsTypesByGroupName(
      {
        groupName: STAKEHOLDER_CHANGES
      }
    )
  },
  [REMINDER_CHANGES]: {
    value: 4,
    notificationSettingTypes: NOTIFICATIONS_SETTINGS_CONFIG.getNotificationSettingsTypesByGroupName(
      {
        groupName: REMINDER_CHANGES
      }
    )
  }
}
