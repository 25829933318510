import { getPluginOption } from '@/utils/plugin-options'

export const FILTERS_LAST_SELECTED_INTERVALS = 'filtersLastSelectedIntervals'

export const createUniqueKey = (baseUrl, organizationId = null) => {
  const keyByBaseUrl = baseUrl.replace(/(https?:\/\/)|(\/$)/g, '').toUpperCase()
  if (organizationId) {
    return `${keyByBaseUrl}[ORGANIZATION.${organizationId}]`
  }
  return keyByBaseUrl
}

const persistInterval = 24 * 60 * 60 * 1000 * 7 // 7 days
const TS = new Date().getTime() + persistInterval

export const isLocalStorageAvailable = () => {
  try {
    localStorage.setItem('test', 'test')
    localStorage.removeItem('test')
    return true
  } catch (e) {
    return false
  }
}

export const readFromStorageByKey = (storageKey, predefinedUniqueKey = null) => {
  // in some environments like private mode in chrome, LS is unavailable,
  // ignore writing to it to keep other functionality except saving working
  if (!isLocalStorageAvailable()) {
    return
  }
  const baseUrl = getPluginOption('baseUrl')
  const organizationId = getPluginOption('organizationId')
  const uniqueKey = predefinedUniqueKey || createUniqueKey(baseUrl, organizationId)
  let storage
  storage = localStorage.getItem(`${uniqueKey}_${storageKey}`)
  storage = JSON.parse(storage) || {}

  if (!storage.data && storage.data !== false) {
    return null
  }

  if (new Date().getTime() < storage.ts) {
    return storage.data
  }
  return null
}

export const updateStorageByKey = (storageKey, payload) => {
  const baseUrl = getPluginOption('baseUrl')
  const organizationId = getPluginOption('organizationId')
  const uniqueKey = createUniqueKey(baseUrl, organizationId)

  if (payload === undefined) {
    return Promise.reject(new Error('Payload have to contain something'))
  }

  // in some environments like private mode in chrome, LS is unavailable,
  // ignore writing to it to keep other functionality except saving working
  if (!isLocalStorageAvailable()) {
    return
  }

  try {
    localStorage.setItem(
      `${uniqueKey}_${storageKey}`,
      JSON.stringify({
        data: payload,
        ts: TS
      })
    )
    return Promise.resolve()
  } catch (e) {
    return Promise.reject(e)
  }
}
