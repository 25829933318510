<template>
  <div class="aas-Step">
    <slot name="step-hero" />

    <slot name="step-title" />

    <FormFieldNext :label="$t('onboarding.existed_admins')" class="aas-FormField">
      <div class="aas-UserField">
        <AppSelect
          :disabled-items="disabledItems"
          :loading="isAddAdminsLoading"
          :model-value="formModel.accountId"
          :offset="[0, '-100%']"
          :options="usersAsOptions"
          :placeholder="$t('placeholder.search')"
          :search-function="getPlatformUsers"
          append-to=".aas-UserField"
          dropdown-search
          item-label="name"
          item-value="accountId"
          skeleton-loader
          @update:options="allUsers = $event"
          @update:model-value="onAddAdmin"
        >
          <template #option-label="{ option }">
            <OwnerFieldOption
              :already-added="option.alreadyAdded"
              :option="option"
              label-key="name"
            >
              <template v-if="option.alreadyAdded" #badge>
                {{ $t('badge.already_added') }}
              </template>
            </OwnerFieldOption>
          </template>

          <template #button-content="{ option }">
            <template v-if="option">
              <OwnerFieldOption :option="option" label-key="name" />
            </template>
            <template v-else>
              {{ $t('admin.user') }}
            </template>
          </template>
        </AppSelect>
      </div>
    </FormFieldNext>

    <AppTable
      :columns="COLUMNS"
      :data="admins"
      :loading="isAdminsLoading"
      class="aas-Table"
      no-border
      offset-left="16px"
      offset-right="16px"
      style="--head-padding-bottom: 6px; --row-border-radius: 6px"
      type="primary-next"
    >
      <template #header-cell="{ column }">
        <template v-if="column.title">
          {{ $t(column.title) }}
        </template>
      </template>
      <template #cell="{ columnKey, item }">
        <template v-if="columnKey === TABLE_COLUMNS_KEYS.NAME">
          <UserNameCell :item="item" item-label="displayName" />
        </template>

        <template v-else-if="columnKey === TABLE_COLUMNS_KEYS.ACTION">
          <AppButton
            class="aas-DeleteAdminBtn"
            icon="delete-next"
            size="sm"
            type="tertiary-next"
            @click="onRemoveClick(item)"
          />
        </template>
      </template>

      <template #loading>
        <PluginAdminsTableLoader />
      </template>
    </AppTable>

    <portal to="modal-windows">
      <AdminDeleteModal
        :admin-to-delete="adminToDelete"
        :show="isShowAdminDeleteModal"
        @on-close="isShowAdminDeleteModal = false"
        @on-confirm="onDeleteAdmin"
      />
    </portal>
  </div>
</template>

<script setup>
import { computed, nextTick, onMounted, ref } from 'vue'

import PlatformApiHandler from '@/api/platform'
import WorkspaceAdministratorsApiHandler from '@/api/workspace-administrators'
import { tracker } from '@/tracking/amplitude'
import { EVENT_CATEGORIES, EVENT_SOURCES } from '@/tracking/amplitude-helpers'
import { handleAdministratorsList } from '@/utils/administrators'
import { REQUEST_ENTITY_KEYS } from '@/utils/entity-keys'
import { handleError } from '@/utils/error-handling'
import { TABLE_COLUMNS_KEYS } from '@/utils/table-columns'

import FormFieldNext from '@/components/form/FormFieldNext'
import OwnerFieldOption from '@/components/form/OwnerFieldOption'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppSelect from '@/components/ui/AppSelect/AppSelect'
import PluginAdminsTableLoader from '@/components/ui/SkeletonLoaders/PluginAdminsTableLoader'
import AppTable from '@/components/ui/Table/AppTable'
import AdminDeleteModal from '@/components/workspaces/AdminDeleteModal'
import UserNameCell from '@/views/workspaces/settings/plugin-users/UserNameCell'

defineOptions({
  name: 'AddAdministratorsStep'
})

const COLUMNS = [
  {
    title: 'onboarding.invite_admins',
    key: TABLE_COLUMNS_KEYS.NAME,
    width: 'auto'
  },
  {
    key: TABLE_COLUMNS_KEYS.ACTION,
    width: 24
  }
]

const admins = ref([])

const isAdminsLoading = ref(false)
const getAdmins = async () => {
  const adminsApi = new WorkspaceAdministratorsApiHandler()
  try {
    isAdminsLoading.value = true
    admins.value = await adminsApi.getAdmins()
  } catch (error) {
    handleError({ error })
  } finally {
    isAdminsLoading.value = false
  }
}

const DEFAULT_FORM_MODEL = {
  accountId: ''
}

const formModel = ref({ ...DEFAULT_FORM_MODEL })

const disabledItems = computed(() => admins.value.map(admin => admin.accountId))

const allUsers = ref([])

const getPlatformUsers = async (searchString = null) => {
  const platformApi = new PlatformApiHandler()

  let users = []
  try {
    users = await platformApi.getPlatformUsers({ searchString, [REQUEST_ENTITY_KEYS.LIMIT]: 50 })
  } catch (error) {
    handleError({ error })
  }

  return handleAdministratorsList({
    allAdministrators: admins.value,
    usersList: users
  })
}

const handleAdminTracking = (isAdded = true) => {
  const eventName = isAdded ? 'administrator added' : 'administrator removed'
  tracker.logEvent(eventName, {
    category: EVENT_CATEGORIES.ONBOARDING,
    source: EVENT_SOURCES.ONBOARDING
  })
}

const isAddAdminsLoading = ref(false)
const onAddAdmin = async accountId => {
  const adminsApi = new WorkspaceAdministratorsApiHandler()

  try {
    isAddAdminsLoading.value = true

    await adminsApi.addAdmin({
      accountId
    })

    admins.value = []
    handleAdminTracking()
    await getAdmins()
  } catch (error) {
    handleError({ error })
  } finally {
    isAddAdminsLoading.value = false
  }
}

const usersAsOptions = computed(() => {
  return handleAdministratorsList({
    allAdministrators: admins.value,
    usersList: allUsers.value
  })
})

const adminToDelete = ref(null)
const isShowAdminDeleteModal = ref(false)

const onRemoveClick = admin => {
  adminToDelete.value = admin
  nextTick(() => {
    isShowAdminDeleteModal.value = true
  })
}

const onDeleteAdmin = async () => {
  const adminsApi = new WorkspaceAdministratorsApiHandler()

  try {
    await adminsApi.removeAdmin({
      accountId: adminToDelete.value.accountId
    })
    isShowAdminDeleteModal.value = false
    adminToDelete.value = null
    admins.value = []
    handleAdminTracking(false)
    await getAdmins()
  } catch (error) {
    handleError({ error })
  }
}

onMounted(async () => {
  await getAdmins()
  allUsers.value = await getPlatformUsers()
})
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/app-table-helpers';

.aas-Step {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: var(--step-max-width);
  margin: 0 auto;
}
.aas-UserField {
  width: 100%;
  position: relative;
}

.aas-Table,
.aas-FormField {
  width: 100%;
}

.aas-DeleteAdminBtn {
  @extend %app-table-hidden-items;
}
</style>
