<template>
  <CellValue
    v-if="!item.isNestedTask"
    :user-has-read-access="readable"
    :user-has-update-access="updatable"
    cell-value=""
    inherit-cursor-style
  >
    <OkrGrade
      :id="`gr${uid}`"
      :class="{ 'gc-Grade': true, 'gc-Grade-updatable': updatable }"
      :color="item.gradeColor"
      :grade="item.gradeToUse"
      :grade-mode="item.gradeMode"
      :is-contribute="item.contribute"
      :readonly="!updatable"
      :show-multiplier="showMultiplier"
      :type-id="item.typeId"
      :weight="item.weight"
      :weight-multiplier="item.weightMultiplier"
      additional-wrapper-on-value
      hide-weight-icon
      multiplier-icon-name="table-grade-multiplier"
      multiplier-icon-size="16"
      type="default-next"
      weight-is-dimmed
      @click="onGradeClick"
    />

    <GradeSelect
      v-if="updatable && editInitialised"
      v-model:show="showSelect"
      :objective="item"
      :to-selector="`#gr${uid}`"
      @save="$emit('save-grade', $event)"
      @update:is-loading="$emit('loading', $event)"
      @error-on-save="$emit('error-on-save-grade', $event)"
    />
  </CellValue>
</template>

<script>
import { defineComponent } from 'vue'

import { uid } from '@/utils/uid'

import GradeSelect from '@/components/objectives/GradeSelect'
import OkrGrade from '@/components/objectives/OkrGrade'
import CellValue from '@/components/objectives/table/cells/CellValue'

export default defineComponent({
  name: 'GradeCell',

  components: {
    CellValue,
    OkrGrade,
    GradeSelect
  },

  props: {
    item: {
      type: Object,
      required: true
    },

    readable: {
      type: Boolean
    },

    updatable: {
      type: Boolean
    },

    showMultiplier: {
      type: Boolean,
      default: true
    }
  },

  emits: {
    loading: null,
    'editing-grade': null,
    'error-on-save-grade': null,
    'save-grade': null
  },

  data() {
    return {
      uid: uid(),
      editInitialised: false,
      showSelect: false
    }
  },

  watch: {
    updatable: {
      handler(newValue) {
        if (!newValue && this.editInitialised) {
          this.editInitialised = false
          this.showSelect = false
        }
      },

      immediate: true
    }
  },

  methods: {
    onGradeClick() {
      if (this.updatable) {
        if (!this.editInitialised) {
          this.editInitialised = true
        }

        this.showSelect = !this.showSelect
      }
    }
  }
})
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/grade-cell-variables';

.gc-Grade {
  :deep(.okg-MultiplierIcon) {
    margin-right: 4px;
  }
  :deep(.okg-OkrGrade_Value) {
    @extend %grade-cell-first-part-min-width;
  }

  :deep(.okg-OkrGradeValueWrapper) {
    font-weight: fw('semi-bold');
    padding: 2px 6px;
    max-width: 100%;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    border-radius: $border-radius-sm-next;
    vertical-align: middle;
  }

  &-updatable {
    .o-objective-row:hover & {
      :deep(.okg-OkrGradeValueWrapper) {
        background-color: rgba(var(--rgb-color), 0.2);
      }
    }
  }
}
</style>
