<template>
  <div class="wi-Wrapper" data-testid="workspace-intervals-tab">
    <div class="wi-Wrapper_Toolbar">
      <AppSelect
        v-model="filterBy"
        :offset="[0, 0]"
        :options="FILTER_OPTIONS"
        item-label="label"
        item-value="value"
        @update:model-value="onUpdateFilter"
      >
        <template #button="{ option, active }">
          <ConfigurationModalSelectTrigger :opened="active">
            {{ option.label }}
          </ConfigurationModalSelectTrigger>
        </template>
      </AppSelect>

      <AppButton
        v-if="isSuperUser"
        height="24"
        icon="plus-next"
        type="primary-next"
        width="24"
        @click="showCreateIntervalModal"
      >
        {{ $t('intervals.create_interval') }}
      </AppButton>
    </div>

    <div class="wi-Wrapper_Table">
      <AppTable
        v-if="showTable"
        :columns="TABLE_COLUMNS"
        :data="filteredIntervals"
        :disable-user-select="false"
        :hover-row="tableHoverRow"
        :loading="isLoading"
        border-on-last-row
        class="wi-Table"
        no-border
        offset-left="40px"
        offset-right="40px"
        sticky-header
        type="primary-next"
      >
        <template #header-cell="{ column }">
          <template v-if="column.title">
            <div v-if="column.key === TABLE_COLUMNS_KEYS.PERIOD" class="wi-PeriodHeadCell">
              {{ $t(column.title) }}
            </div>
            <template v-else>
              {{ $t(column.title) }}
            </template>
          </template>
        </template>
        <template #cell="{ columnKey, item, index }">
          <template v-if="columnKey === TABLE_COLUMNS_KEYS.NAME">
            <div class="wi-NameCell">
              <AppIcon class="wi-NameCell_Icon" height="24" icon-name="calendar-next" width="24" />

              <span
                :class="{ 'wi-NameCell_Label-clickable': isSuperUser && !item.backlog }"
                class="wi-NameCell_Label"
                @click="editInterval(item)"
              >
                {{ item.name }}
              </span>

              <AppButton
                class="wi-NameCell_Link"
                height="24"
                icon="shortcut-next"
                remove-padding
                size="sm"
                type="ghost-next"
                width="24"
                @click="goToInterval(item)"
              />
            </div>
          </template>

          <template v-else-if="columnKey === TABLE_COLUMNS_KEYS.PERIOD">
            <div
              :class="{
                'wi-PeriodCell-archived': isArchivedInterval(item),
                'wi-PeriodCell-without-icon': !item.backlog && !isActiveInterval(item)
              }"
              class="wi-PeriodCell"
            >
              <div v-if="isActiveInterval(item)" class="wi-PeriodCell_Marker">
                <ObjectiveChartTableColorMark color="var(--grade-high-color-next)" />
              </div>

              <template v-if="item.backlog">
                <AppInfoMessage>
                  {{ $t('intervals.backlog_description') }}
                </AppInfoMessage>
              </template>
              <template v-else>
                {{ formatDate(item.startDate, DATE_FORMAT) }} –
                {{ formatDate(item.endDate, DATE_FORMAT) }}
              </template>
            </div>
          </template>

          <template v-else-if="columnKey === TABLE_COLUMNS_KEYS.STATUS">
            <template v-if="!item.backlog">
              <ObjectiveStatus :status="Number(item.open)">
                {{ item.open ? $t('intervals.status.open') : $t('intervals.status.archived') }}
              </ObjectiveStatus>
            </template>
          </template>

          <template v-else-if="columnKey === TABLE_COLUMNS_KEYS.ACTIONS">
            <div v-if="showActions(item)" class="wi-Actions">
              <DropdownMenu
                :items="getDropdownMenuItems(item)"
                :offset="[0, 0]"
                :type="DROPDOWN_MENU_TYPES.DEFAULT_NEXT"
                preferred-position="bottom-end"
                @close="tableHoverRow = -1"
                @open="tableHoverRow = index"
                @item-click="onMenuActionsClick($event, item)"
              >
                <template #activator>
                  <AppButton icon="more-next" size="sm" type="tertiary-next" />
                </template>
              </DropdownMenu>
            </div>
          </template>
        </template>

        <template #loading>
          <IntervalsTableLoader />
        </template>

        <template #footer>
          <NoSearchResults v-if="isNoResults" offset-left="40px" offset-right="40px">
            {{ $t('intervals.no_results') }}
          </NoSearchResults>

          <AppTableCreateButton
            v-if="isSuperUser"
            full-width
            icon-name="plus-next"
            @click="showCreateIntervalModal"
          >
            {{ $t('intervals.create_interval') }}
          </AppTableCreateButton>
        </template>
      </AppTable>
    </div>

    <IntervalDeleteModal
      :interval="intervalToDelete"
      :show="isConfirmDeleteShow"
      @on-close="hideDeleteIntervalModal"
      @on-confirm="deleteInterval"
    />

    <IntervalEditModal
      :interval="intervalToEdit"
      :is-show="isEditIntervalModalShow"
      :workspace-id="workspaceData.id"
      @on-reload-intervals="fetchIntervals"
      @on-close="hideEditIntervalModal"
      @on-archived="logArchiveIntervalEvent"
    />

    <IntervalCreateModal
      :is-show="isCreateIntervalModalShow"
      :workspace-id="workspaceData.id"
      @on-close="hideCreateIntervalModal"
      @on-created="onIntervalCreated"
    />
  </div>
</template>

<script setup>
import { isEmpty } from 'lodash'
import { computed, inject, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

import IntervalsInfoApiHandler from '@/api/intervals-info'
import { tracker } from '@/tracking/amplitude'
import {
  EVENT_CATEGORIES,
  EVENT_SECTIONS,
  FILTER_LABELS_FOR_TRACKING,
  TAB_NAMES_FOR_TRACKING,
  trackApplySettingsPagesFilterEvent
} from '@/tracking/amplitude-helpers'
import { EVENT_NAMES } from '@/tracking/gtm-helpers'
import { gtmTracker } from '@/tracking/gtm-tracking'
import { DROPDOWN_MENU_TYPES } from '@/utils/components-configurations/dropdown-menu'
import { getDiffInDays, formatDate, localDateToUtc, utcDateToLocal } from '@/utils/date'
import { MENU_ITEMS_GROUPS } from '@/utils/dropdown-menu'
import { handleError } from '@/utils/error-handling'
import { useExternalLinkHelper } from '@/utils/external-link-composables'
import { EXTERNAL_LINK_HANDLER_SOURCES } from '@/utils/external-link-helper'
import { appPlatformInjectionKey, isSalesforceAppInjectionKey } from '@/utils/injection-keys'
import {
  getActiveIntervals,
  getArchivedIntervals,
  isBetweenDates,
  sortIntervals
} from '@/utils/interval'
import { OKR_ELEMENTS_TABLE_ACTIONS_MENU_ITEMS } from '@/utils/objectives'
import { TABLE_COLUMNS_KEYS } from '@/utils/table-columns'

import ObjectiveChartTableColorMark from '@/components/objectives/chart/ObjectiveChartTableColorMark'
import ObjectiveStatus from '@/components/objectives/ObjectiveStatus'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppIcon from '@/components/ui/AppIcon/AppIcon'
import AppInfoMessage from '@/components/ui/AppInfoMessage'
import AppSelect from '@/components/ui/AppSelect/AppSelect'
import AppTableCreateButton from '@/components/ui/AppTableCreateButton'
import DropdownMenu from '@/components/ui/DropdownMenu/DropdownMenu'
import NoSearchResults from '@/components/ui/NoSearchResults/NoSearchResults'
import IntervalsTableLoader from '@/components/ui/SkeletonLoaders/IntervalsTableLoader'
import AppTable from '@/components/ui/Table/AppTable'
import ConfigurationModalSelectTrigger from '@/components/workspaces/configuration-modal/ConfigurationModalSelectTrigger'
import IntervalCreateModal from '@/components/workspaces/configuration-modal/IntervalCreateModal'
import IntervalDeleteModal from '@/components/workspaces/configuration-modal/IntervalDeleteModal'
import IntervalEditModal from '@/components/workspaces/configuration-modal/IntervalEditModal'

defineOptions({
  name: 'WorkspaceIntervals'
})

const DATE_FORMAT = 'D MMM YYYY'

const FILTER_VALUES = {
  SHOW_ALL: 'show_all',
  ACTIVE: 'active',
  ARCHIVED: 'archived'
}

const { t } = useI18n()

const FILTER_OPTIONS = [
  {
    label: t('filter.show_all'),
    value: FILTER_VALUES.SHOW_ALL
  },
  {
    label: t('filter.active'),
    value: FILTER_VALUES.ACTIVE
  },
  {
    label: t('intervals.status.archived'),
    value: FILTER_VALUES.ARCHIVED
  }
]

const TABLE_COLUMNS = [
  {
    title: 'intervals.name',
    key: TABLE_COLUMNS_KEYS.NAME,
    width: 248
  },
  {
    title: 'field.period.title',
    key: TABLE_COLUMNS_KEYS.PERIOD,
    width: 'auto'
  },
  {
    title: 'field.status.title',
    key: TABLE_COLUMNS_KEYS.STATUS,
    width: 84
  },
  {
    key: TABLE_COLUMNS_KEYS.ACTIONS,
    width: 24
  }
]

const { REMOVE, EDIT } = OKR_ELEMENTS_TABLE_ACTIONS_MENU_ITEMS

const props = defineProps({
  workspaceData: {
    type: Object,
    required: true
  }
})

const filterBy = ref(FILTER_VALUES.SHOW_ALL)

const store = useStore()

const isPluginAdmin = computed(() => store.state.pluginOptions.isPluginAdmin)

const isSuperUser = computed(() => isPluginAdmin.value || props.workspaceData?.workspaceAdmin)

const ARCHIVE_ACTION = 'archive_interval'
const UNARCHIVE_ACTION = 'unarchive_interval'
const { EDITING, REMOVING } = MENU_ITEMS_GROUPS

const getDropdownMenuItems = interval => {
  const DEFAULT_MENU_ITEMS = [
    {
      name: EDIT.name,
      title: 'action.edit',
      icon: 'edit-next',
      group: EDITING
    },
    {
      name: REMOVE.name,
      title: 'action.delete',
      icon: 'delete-next',
      color: 'var(--grade-low-color-next)',
      group: REMOVING
    }
  ]

  const ARCHIVED_INTERVAL_ITEMS = [
    {
      name: UNARCHIVE_ACTION,
      title: 'action.reopen_interval',
      icon: 'unarchive-interval',
      group: EDITING
    }
  ]

  const OPEN_INTERVAL_ITEMS = [
    {
      name: ARCHIVE_ACTION,
      title: 'action.archive_interval',
      icon: 'archive-interval',
      group: EDITING
    }
  ]

  const result = isArchivedInterval(interval)
    ? [...DEFAULT_MENU_ITEMS, ...ARCHIVED_INTERVAL_ITEMS]
    : [...DEFAULT_MENU_ITEMS, ...OPEN_INTERVAL_ITEMS]

  return isSuperUser.value ? result : []
}

const showActions = interval => !interval.backlog && !isEmpty(getDropdownMenuItems(interval))

const changeIntervalStatus = async interval => {
  const newStatus = !interval.open

  const intervalsInfoApi = new IntervalsInfoApiHandler()

  try {
    const { id, name, endDate, startDate } = interval

    const start = utcDateToLocal(new Date(startDate))
    const end = utcDateToLocal(new Date(endDate))

    const formattedEndDate = new Date(+end)
    formattedEndDate.setHours(23, 59, 59)
    const payload = {
      id,
      name,
      open: Number(newStatus),
      workspaceId: props.workspaceData.id,
      startDate: localDateToUtc(start).getTime(),
      endDate: localDateToUtc(formattedEndDate).getTime()
    }

    const updatedInterval = await intervalsInfoApi.updateInterval(payload)

    if (!newStatus) {
      logArchiveIntervalEvent(updatedInterval)
    }

    await fetchIntervals()
  } catch (error) {
    handleError({ error })
  }
}

const logArchiveIntervalEvent = interval => {
  tracker.logEvent('Archived cycle', {
    category: EVENT_CATEGORIES.CYCLE_MANAGEMENT,
    label: interval.name,
    value: getDiffInDays(interval.startDate, interval.endDate)
  })
}

const isConfirmDeleteShow = ref(false)
const intervalToDelete = ref(null)
const showDeleteIntervalModal = interval => {
  intervalToDelete.value = interval
  isConfirmDeleteShow.value = true
}

const hideDeleteIntervalModal = () => {
  intervalToDelete.value = null
  isConfirmDeleteShow.value = false
}
const deleteInterval = async interval => {
  const intervalsInfoApi = new IntervalsInfoApiHandler()
  try {
    await intervalsInfoApi.removeInterval({
      id: interval.id,
      workspaceId: props.workspaceData.id
    })

    tracker.logEvent('Deleted cycle', {
      category: EVENT_CATEGORIES.CYCLE_MANAGEMENT,
      label: interval.name,
      value: getDiffInDays(interval.startDate, interval.endDate)
    })

    hideDeleteIntervalModal()
    await fetchIntervals()
  } catch (error) {
    handleError({ error })
  }
}

const onMenuActionsClick = (action, interval) => {
  const [method] = [
    [ARCHIVE_ACTION, UNARCHIVE_ACTION].includes(action) && changeIntervalStatus,
    action === REMOVE.name && showDeleteIntervalModal,
    action === EDIT.name && editInterval
  ].filter(Boolean)

  method(interval)
}

const isCreateIntervalModalShow = ref(false)

const showCreateIntervalModal = () => (isCreateIntervalModalShow.value = true)
const hideCreateIntervalModal = () => (isCreateIntervalModalShow.value = false)

const onIntervalCreated = interval => {
  tracker.logEvent('Created cycle', {
    category: EVENT_CATEGORIES.CYCLE_MANAGEMENT,
    label: interval.name,
    value: getDiffInDays(interval.startDate, interval.endDate)
  })

  gtmTracker.logEvent(EVENT_NAMES.INTERVAL_CREATED)

  fetchIntervals()
}

const isEditIntervalModalShow = ref(false)
const intervalToEdit = ref(null)
const editInterval = interval => {
  if (isSuperUser.value && !interval.backlog) {
    intervalToEdit.value = interval
    isEditIntervalModalShow.value = true
  }
}

const hideEditIntervalModal = () => {
  intervalToEdit.value = null
  isEditIntervalModalShow.value = false
}

const isLoading = ref(false)
const intervals = ref([])
const fetchIntervals = async () => {
  isLoading.value = true
  if (!isEmpty(intervals.value)) {
    intervals.value = []
  }

  const intervalsInfoApi = new IntervalsInfoApiHandler()

  try {
    intervals.value = await intervalsInfoApi.getAllIntervalsInfo({
      workspaceId: props.workspaceData.id
    })
  } catch (error) {
    handleError({ error })
  } finally {
    isLoading.value = false

    if (isNoResults.value && filterBy.value === FILTER_VALUES.ARCHIVED) {
      filterBy.value = FILTER_VALUES.SHOW_ALL
    }
  }
}

const showTable = computed(() => isLoading.value || !isEmpty(intervals.value))
const isNoResults = computed(() => {
  return !isLoading.value && isEmpty(filteredIntervals.value)
})

const filteredIntervals = computed(() => {
  const { value } = intervals
  const intervalsByFilter = {
    [FILTER_VALUES.SHOW_ALL]: value,
    [FILTER_VALUES.ACTIVE]: getActiveIntervals(value),
    [FILTER_VALUES.ARCHIVED]: getArchivedIntervals(value)
  }

  return sortIntervals(intervalsByFilter[filterBy.value])
})

const tableHoverRow = ref(-1)

const isArchivedInterval = interval => !interval.backlog && !interval.open

const isActiveInterval = interval =>
  !interval.backlog && isBetweenDates(interval.startDate, interval.endDate)

onMounted(() => {
  fetchIntervals()
})

const appPlatform = inject(appPlatformInjectionKey)
const isSalesforceApp = inject(isSalesforceAppInjectionKey)

const { handleExternalLink } = useExternalLinkHelper()

const goToInterval = interval => {
  tracker.logEvent('Check archived OKRs', {
    category: EVENT_CATEGORIES.CYCLE_MANAGEMENT,
    label: interval.name,
    value: getDiffInDays(interval.startDate, interval.endDate)
  })

  handleExternalLink({
    workspaceId: Number(props.workspaceData.id),
    intervalId: interval.id,
    source: EXTERNAL_LINK_HANDLER_SOURCES.INTERVALS_PAGE,
    withEmptyFilters: true,
    appPlatform,
    navigateByRouter: isSalesforceApp,
    saveWorkspaceIdToStore: isSalesforceApp
  })
}

const onUpdateFilter = () => {
  trackApplySettingsPagesFilterEvent({
    section: EVENT_SECTIONS.WORKSPACE,
    tab: TAB_NAMES_FOR_TRACKING.INTERVALS,
    label: FILTER_LABELS_FOR_TRACKING.STATUS
  })
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/workspace-configuration-modal';
@import '~@/assets/styles/app-table-helpers';

.wi-Wrapper {
  // padding: 0 40px;
}

.wi-Wrapper_Toolbar {
  @extend %tab-toolbar-styles;
}

.wi-Wrapper_Table {
  padding: 0 40px;
}

.wi-Table {
  font-family: $system-ui;
  --head-padding-top: #{$modal-tab-table-padding-top};
  --sticky-top: var(--wcm-tab-table-sticky-top, 0);
  font-style: normal;

  font-size: $fs-14;
  line-height: 20px;

  :deep(.tb-Cell-status) {
    line-height: normal;
  }
}

.wi-NameCell {
  display: flex;
  align-items: center;
  gap: 4px;
  padding-right: 4px;
}

.wi-NameCell_Icon {
  flex: 0 0 auto;
  color: $primary-color-next;
}

.wi-NameCell_Label {
  margin-left: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: fw('semi-bold');

  &:not(&-clickable) {
    cursor: default;
    color: $dark-1;
  }

  &-clickable {
    cursor: pointer;
    color: $primary-color-next;

    &:hover {
      text-decoration: underline;
    }
  }
}

.wi-PeriodCell {
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: fw('regular');

  &:not(&-archived) {
    color: $dark-1;
  }

  &-archived {
    color: $dark-3;
  }
}

.wi-PeriodHeadCell,
.wi-PeriodCell-without-icon {
  padding-left: 32px; // where 24px for icon and 8px for gap
}

.wi-PeriodCell_Marker {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wi-NameCell_Link,
.wi-Actions {
  @extend %app-table-hidden-items;
}
</style>
