<script setup>
import { isEmpty } from 'lodash'
import { computed, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'

import { SCHEDULE_SELECT_ATTRS } from '@/utils/check-ins/schedule-ui-helpers'
import { SCHEDULE_NOTIFICATION_CHANNELS } from '@/utils/check-ins/schedules-helpers'
import { NOTIFICATION_CHANNEL_ENTITY_KEYS } from '@/utils/entity-keys'

import FormCaption from '@/components/check-ins/FormCaption'
import { NOTIFICATION_WEBHOOK_FIELD_TEST_ID } from '@/components/check-ins/jest-helpers'
import AppSelect from '@/components/ui/AppSelect/AppSelect'

defineOptions({
  name: 'ScheduleWebhooksSelect',
  inheritAttrs: false
})

const model = defineModel({
  type: Array,
  required: true,
  set: value => value
})

const props = defineProps({
  loading: {
    type: Boolean
  },

  options: {
    type: Array,
    default: () => []
  },

  channelTypeId: {
    type: [String, Number],
    required: true
  }
})

const { t } = useI18n()

const emit = defineEmits({
  'request-options': null
})

onMounted(() => {
  if (isEmpty(props.options)) {
    emit('request-options')
  }
})

const getMessengerName = computed(() => {
  const channel = Object.values(SCHEDULE_NOTIFICATION_CHANNELS).find(
    channel => channel[NOTIFICATION_CHANNEL_ENTITY_KEYS.VALUE] === Number(props.channelTypeId)
  )

  return channel?.[NOTIFICATION_CHANNEL_ENTITY_KEYS.LABEL] || 'unknown channel'
})
</script>

<template>
  <FormCaption
    :data-autotestid="$attrs['data-auto-testid'] || null"
    :data-testid="$attrs['data-testid'] || null"
    :subtitle="
      t('schedule.notifications_webhook.subtitle', {
        messengerName: getMessengerName
      })
    "
    :title="
      t('schedule.notifications_webhook.title', {
        messengerName: getMessengerName
      })
    "
  >
    <div class="sws-SelectWrapper">
      <AppSelect
        v-model="model"
        :data-auto-testid="NOTIFICATION_WEBHOOK_FIELD_TEST_ID"
        :data-testid="NOTIFICATION_WEBHOOK_FIELD_TEST_ID"
        :dropdown-search="false"
        :loading="loading"
        :options="options"
        append-to="parent"
        boundary="scrollParent"
        dropdown-width="100%"
        hide-selected-items-in-dropdown
        multi
        show-all-selected
        v-bind="{
          ...SCHEDULE_SELECT_ATTRS,
          dropdownMinWidth: 220
        }"
      >
        <template #button-content="{ options: selectedOptions }">
          <div v-if="isEmpty(selectedOptions)" class="sws-EmptyOption">
            {{
              t('common.select_entity', {
                entity: t('notifications.channels').toLowerCase()
              })
            }}
          </div>
        </template>
      </AppSelect>
    </div>
  </FormCaption>
</template>

<style lang="scss" scoped>
.sws-SelectWrapper {
  min-width: 170px;
  position: relative;
  width: max-content;
  max-width: 100%;
  --select-skeleton-top: 0;
  --select-skeleton-left: 0;

  :deep(.as-AppDroplistButton_Content) {
    padding: 6px 0 6px 6px;
  }
}

.sws-EmptyOption {
  color: $dark-grey;
}
</style>
