<script>
import { defineComponent, h, resolveDirective, withDirectives } from 'vue'
import { useI18n } from 'vue-i18n'

import AppIcon from '@/components/ui/AppIcon/AppIcon'

export default defineComponent({
  name: 'WorkspaceIcon',
  props: {
    option: {
      type: Object,
      required: true
    },

    withLockIcon: {
      type: Boolean,
      default: true
    }
  },

  render() {
    const tippy = resolveDirective('tippy')
    const { t } = useI18n()
    return h(
      'div',
      {
        class: 'wi-WorkspaceIcon',
        style: { 'background-color': this.option.color }
      },
      h('div', {}, [
        this.option.key,
        !this.option.public && this.withLockIcon
          ? withDirectives(
              h('div', { class: 'wi-WorkspaceIcon-lock' }, [
                h(AppIcon, { iconName: 'lock-outline', width: '9', height: '9' })
              ]),
              [[tippy, { content: t('workspaces.private'), placement: 'bottom' }]]
            )
          : null
      ])
    )
  }
})
</script>

<style lang="scss" scoped>
.wi-WorkspaceIcon {
  color: $white;
  border-radius: $border-radius-sm-next;
  font-family: $system-ui;
  width: 24px;
  height: 24px;
  font-style: normal;
  font-weight: fw('bold');
  font-size: $fs-9;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;

  &-lock {
    position: absolute;
    left: 0;
    bottom: 0;
    background: $grey-2-next;
    box-shadow: $white 0 0 0 1px;
    display: flex;
    border-radius: 50%;
  }
}
</style>
