<template>
  <div class="rl-Loader">
    <div :class="{ 'rl-Loader_Table-fullWidth': hideHeader }" class="rl-Loader_Table">
      <ObjectivesTableLoader :columns-count="2" :hide-header="hideHeader" header-height="56px" />
    </div>
    <div v-if="!hideHeader" class="rl-Loader_Timelines">
      <div class="rl-Timelines_Head">
        <div v-for="n in 5" :key="`head-cell-${n}`" class="rl-Timeline_Head_Cell">
          <SkeletonItem :size="XS" width="72px" />
        </div>
      </div>
      <div class="rl-Timelines_Body">
        <div
          v-for="row in rows"
          :key="`row-${row.id}`"
          :style="{ 'padding-left': row.offset }"
          class="rl-TimelinesRow"
        >
          <SkeletonItem
            :height="row.height || ''"
            :size="row.size || MD"
            :width="row.width"
            border-radius="6px"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'

import { SKELETON_SIZES } from '@/utils/components-configurations/skeleton-item'

import ObjectivesTableLoader from '@/components/ui/SkeletonLoaders/ObjectivesTableLoader'
import SkeletonItem from '@/components/ui/SkeletonLoaders/SkeletonItem'

defineOptions({
  name: 'RoadmapLoader'
})

const { MD, XS } = SKELETON_SIZES

const ROWS = [
  {
    id: 0,
    width: '250px',
    offset: '64px',
    height: '28px'
  },
  {
    id: 1,
    width: '370px',
    offset: '320px',
    size: MD
  },
  {
    id: 2,
    width: '113px',
    offset: '360px',
    size: MD
  },
  {
    id: 3,
    width: '200px',
    offset: '400px',
    height: '28px'
  },
  {
    id: 4,
    width: '76px',
    offset: '420px',
    height: '28px'
  },
  {
    id: 5,
    width: '32px',
    offset: '260px',
    height: '28px'
  },
  {
    id: 6,
    width: '193px',
    offset: '222px',
    size: MD
  },
  {
    id: 7,
    width: '113px',
    offset: '680px',
    size: MD
  },
  {
    id: 8,
    width: '311px',
    offset: '720px',
    height: '28px'
  },
  {
    id: 9,
    width: '56px',
    offset: '186px',
    height: '28px'
  },
  {
    id: 10,
    width: '93px',
    offset: '313px',
    size: MD
  },
  {
    id: 11,
    width: '113px',
    offset: '32px',
    height: '28px'
  },
  {
    id: 12,
    width: '103px',
    offset: '196px',
    height: '28px'
  },
  {
    id: 13,
    width: '93px',
    offset: '282px',
    height: '28px'
  },
  {
    id: 14,
    width: '93px',
    offset: '613px',
    height: '28px'
  }
]

defineProps({
  hideHeader: {
    type: Boolean
  }
})

const rows = computed(() => ROWS)
</script>

<style lang="scss" scoped>
.rl-Loader {
  display: flex;
}

.rl-Loader_Table {
  width: 470px;
  overflow: hidden;

  &-fullWidth {
    width: 100%;
  }
}

.rl-Loader_Timelines {
  width: calc(100% - 470px);
}

.rl-Timelines_Head {
  height: 54px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 10px;
}

.rl-Timelines_Body {
  display: grid;
}

.rl-TimelinesRow {
  height: 44px;
  display: flex;
  align-items: center;
  overflow: hidden;
}
</style>
