<template>
  <span
    :class="{
      'csi-Item-truncated': truncated,
      'csi-Item-empty': isEmpty && !showPlugAsIcon,
      'csi-Item-empty-with-icon': isEmpty && showPlugAsIcon,
      'csi-Item-transparent': isTransparent,
      'csi-Item-editable': userHasUpdateAccess,
      'csi-Item-inaccessible': !userHasReadAccess || !userHasUpdateAccess,
      'csi-Item-active': userHasUpdateAccess && active,
      'csi-Item-block': isBlock
    }"
    class="csi-Item"
  >
    <template v-if="showPlugAsIcon">
      <OkrElementsTableCellPlug v-if="isEmpty" :icon-name="plugIcon" />
      <slot v-else />
    </template>
    <slot v-else />
  </span>
</template>

<script setup>
defineOptions({
  name: 'CellSelectItem'
})

import { isNull } from 'lodash'
import { computed } from 'vue'

import { PLUG_ICONS } from '@/utils/cell-helper'
import { stringOrNullProp } from '@/utils/prop-validators'

import OkrElementsTableCellPlug from '@/components/objectives/table/cells/OkrElementsTableCellPlug'

const props = defineProps({
  truncated: {
    type: Boolean
  },

  isEmpty: {
    type: Boolean
  },

  isTransparent: {
    type: Boolean
  },

  userHasUpdateAccess: {
    type: Boolean,
    required: true
  },

  userHasReadAccess: {
    type: Boolean,
    default: true
  },

  active: {
    type: Boolean
  },

  isBlock: {
    type: Boolean
  },

  plugIcon: {
    type: String,
    default: null,
    validator: v => stringOrNullProp(v) && Object.values(PLUG_ICONS).includes(v)
  }
})

const showPlugAsIcon = computed(() => {
  return !isNull(props.plugIcon) && props.userHasUpdateAccess
})
</script>

<style lang="scss" scoped>
.csi-Item {
  padding: var(--item-padding, 2px 6px);
  font-family: $system-ui;
  height: 24px;
  background-color: var(--item-background, #{$grey-3-next});
  border-radius: $border-radius-sm-next;
  min-width: 3.5ch;
  font-size: 14px;
  line-height: 20px;
  text-align: center;

  &-truncated {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &-transparent {
    background: transparent;
  }

  &-empty {
    background: transparent;
    width: 100%;
  }

  &-empty-with-icon {
    background: transparent;
    padding: 0;
    min-width: unset;
    opacity: 0; // !!!

    .o-objective-row:hover & {
      opacity: 1;
    }
  }

  /* eslint-disable-next-line vue-scoped-css/no-unused-selector */
  &-empty-with-icon#{&}-active {
    opacity: 1;
  }

  &-editable {
    cursor: pointer;
  }

  &-active {
    background-color: $dark-2;
    color: $white;
  }

  /* eslint-disable-next-line vue-scoped-css/no-unused-selector */
  &-empty#{&}-active {
    background: transparent;
  }

  .o-objective-row:hover &,
  .tb-Row:hover &,
  .te-Row:hover & {
    &:not(.csi-Item-empty):not(.csi-Item-inaccessible):not(.csi-Item-active):not(
        .csi-Item-empty-with-icon
      ) {
      background-color: var(--item-hover-background, #{$grey-2-next});
    }
  }
}
</style>
