import dayjs from 'dayjs'

import { getPluginOption } from '@/utils/plugin-options'
import { IS_DEVELOPMENT } from '@root/app-modes'

export const PLUGIN_TYPES = {
  cloud: 'cloud',
  server: 'server'
}

/* Return current jira instance url without slash at the end.
 * On Jira server it includes also path to plugin, not just host! */
export const getAtlassianBaseUrl = () => {
  if (IS_DEVELOPMENT) {
    return location.origin
  }

  let baseUrl = getPluginOption('atlassianBaseUrl')
  if (!baseUrl) {
    throw new Error(
      "Atlassian's baseUrl doesn't set. You have to set data-attr in template-options.js"
    )
  } else {
    if (baseUrl[baseUrl.length - 1] === '/') {
      baseUrl = baseUrl.slice(0, baseUrl.length - 1)
    }

    return baseUrl
  }
}

/* Return current jira instance url without slash at the end. */
export const getBaseUrl = () => {
  let baseUrl = getPluginOption('baseUrl')
  if (!baseUrl) {
    throw new Error("Atlassian's baseUrl doesn't set. You have to set it in template-options.js")
  }

  if (baseUrl[baseUrl.length - 1] === '/') {
    baseUrl = baseUrl.slice(0, baseUrl.length - 1)
  }

  return baseUrl
}

export const getUserAvatarUrl = user => user.avatarUrl

export const getAtlassianUserUrl = userId => {
  const base = getAtlassianBaseUrl()

  return `${base}/people/${userId}`
}

export const getAtlassianIssueUrl = issueId => {
  const base = getBaseUrl()

  return `${base}/browse/${issueId}`
}

export const getAtlassianIssueUrlForWebApp = issueId => {
  const base = getAtlassianBaseUrl()

  return `${base}/browse/${issueId}`
}

export const getAtlassianIssueUrlForSalesforceApp = issueId => {
  const base = getAtlassianBaseUrl()

  return `${base}/browse/${issueId}`
}

export const transformLocaleToDayjsFormat = locale => locale.replace('_', '-').toLowerCase()

export const getAvailableLocale = ({ locale, availableLocales, fallbackLocale }) => {
  // locales from JIRA
  // Do not use composable function here, because Jira will skip it

  if (availableLocales.includes(locale)) {
    return locale
  }

  const LOCALES = {
    zh_TW: 'zh_CN',
    pt_PT: 'pt_BR',
    en_US: 'en',
    en_GB: 'en',
    de_DE: 'de'
  }
  return LOCALES[locale] || fallbackLocale.value
}

export const setLocaleFromJira = ({ locale, fallbackLocale, availableLocales }) => {
  JIRA_CLOUD_API.user.getLocale(jiraLocale => {
    const lang = getAvailableLocale({ locale: jiraLocale, fallbackLocale, availableLocales })
    locale.value = lang
    dayjs.locale(transformLocaleToDayjsFormat(lang))
  })
}

export const JIRA_CLOUD_API = window.AP
export const JIRA_SERVER_API = window.JIRA
