export const COMMENT_SECTION_ID = 'comments-section'

const scrollToElementId = elementId => {
  const sectionElement = document.getElementById(elementId)
  if (sectionElement) {
    sectionElement.scrollIntoView()
  }
}

export const scrollToCommentsSection = okrPayload => {
  if (okrPayload?.scrollToComments) {
    // SetTimeout is fixing bug with opening objective form. Jira ticket OK-2749
    setTimeout(() => {
      scrollToElementId(COMMENT_SECTION_ID)
    }, 100)
  }
}
