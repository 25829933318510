<template>
  <div ref="homepageWrapper">
    <TaskIconForExport />
    <PageContentHeader :max-width="HEADER_SIZES.MD_NEXT" centered no-margin>
      <template #header-main-content>
        <div class="hp-Filters">
          <div class="hp-Filters_Select">
            <IntervalSelectWithCustomRange
              v-if="suitableInterval"
              v-model:interval-custom-date="intervalCustomDate"
              v-model:interval-custom-range="intervalCustomRange"
              v-model:selected-dates="selectedDates"
              v-model:selected-interval-custom-range-mode="selectedIntervalCustomRangeMode"
              v-model:selected-intervals="selectedIntervals"
              v-model:show-interval-custom-range-picker="showIntervalCustomRangePicker"
              :custom-range-options-based-on-filters="customRangeOptionsBasedOnFilters"
              :interval-fixed-items="INTERVALS_FIXED_ITEMS"
              :intervals="intervals"
              :is-between-mode-selected="isBetweenModeSelected"
              :is-time-range-selected="isTimeRangeSelected"
              :tracker-event-source="EVENT_SOURCES.HOMEPAGE"
              data-testid="interval-select"
              @select-intervals="selectIntervals"
            />
          </div>

          <div class="hp-Filters_Select">
            <AppSelect
              v-if="selectedUserOrGroup"
              v-model="selectedUserOrGroup"
              :dropdown-min-width="224"
              :group-by="item => item.category"
              :groups="groups"
              :icon="userSelected ? 'user-next' : 'team-next'"
              :offset="[0, 0]"
              :options="usersAndGroups"
              :search-function="getUsersAndGroups"
              :type="SELECT_TYPES.SECONDARY_NEXT"
              append-icon-height="20"
              append-icon-width="20"
              data-testid="user-or-group-select"
              dropdown-search
              is-highlighted
              item-label="name"
              item-value="id"
              @change="onChangeUsersAndGroups"
              @update:options="usersAndGroups = $event"
            >
              <template #option-label="{ option }">
                <OwnerFieldOption
                  v-if="option.accountId"
                  :option="option"
                  label-key="name"
                  no-avatar-icon-name="team-next"
                />
                <GlobalGroupsSelectOption v-else :group="option" />
              </template>

              <template #button-content="{ option }">
                <AppSelectItemWithTooltip :label="option.name" :tooltip-label="option.name" />
              </template>
            </AppSelect>
          </div>
        </div>
      </template>

      <AppExportLogo />

      <AppButton
        v-if="suitableInterval && selectedUserOrGroup"
        v-tippy="{
          content: $t('homepage.export_pdf'),
          placement: 'left',
          theme: DROP_LIST_THEMES.TRANSLUCENT_NEXT
        }"
        :disable="userOrGroupDataLoading"
        class="hp-ExportButton"
        data-export-ignore
        icon="pdf"
        remove-padding
        type="tertiary-next"
        @click="onExportClick"
      />

      <PersonalStatisticsOkrElementsModals
        v-if="suitableInterval && selectedUserOrGroup"
        ref="homePageOkrElementsActions"
        :interval-id="intervalForCreate"
        :selected-user-or-group="selectedUserOrGroup"
        :user-selected="userSelected"
        @update-okr-elements="updateOkrElements"
      />
    </PageContentHeader>

    <div class="hp-HomePage">
      <div class="hp-MainDataWrapper">
        <div class="hp-StyledWrapper">
          <MainStatistics
            :chart-data="chartData"
            :grade-data="gradeData"
            :progress-data="progressData"
            :search-type-id="searchTypeId"
            :user-or-group-data-loading="userOrGroupDataLoading"
            data-testid="main-statistics"
            @update:search-type-id="onUpdateSearchTypeId"
          />

          <div v-if="suitableInterval && selectedUserOrGroup" class="hp-OkrElements">
            <PersonalStatisticsOkrElements
              ref="okrsLists"
              :list-options="mainList"
              :search-type-id="searchTypeId"
              :selected-dates="selectedDates"
              :selected-group-name="selectedGroupName"
              :selected-intervals="selectedIntervals"
              :selected-user-name="selectedUserName"
              :selected-user-or-group="selectedUserOrGroup"
              :user-selected="userSelected"
              data-testid="okrsLists"
              show-last-comment
              @edit-element="editElement"
              @link-jira-issue="linkJiraIssue"
              @create-jira-issue="createJiraIssue"
              @create-key-result="createKeyResult"
              @update-okr-elements="updateOkrElements"
            />
          </div>
        </div>
        <!--        <div v-if="!userOrGroupDataLoading" class="hp-Reminder">-->
        <!--          <AppIcon height="24" icon-name="calendar-check" width="24" />-->
        <!--          <span>{{ 'Schedule 1.' }} {{ $t('reminders.time_update_progress') }}</span>-->
        <!--        </div>-->
        <PersonalStatisticsOkrElements
          v-if="suitableInterval && userSelected"
          ref="stakeholderList"
          :list-options="stakeholderList"
          :search-type-id="SEARCH_TYPES.HOMEPAGE"
          :selected-dates="selectedDates"
          :selected-intervals="selectedIntervals"
          :selected-user-name="selectedUserName"
          :selected-user-or-group="selectedUserOrGroup"
          :stakeholders-grade="stakeholdersGrade"
          :user-selected="userSelected"
          show-last-comment
          @edit-element="editElement"
          @link-jira-issue="linkJiraIssue"
          @create-jira-issue="createJiraIssue"
          @create-key-result="createKeyResult"
          @update-okr-elements="updateOkrElements"
        />
        <PersonalStatisticsSummary
          v-if="showSummary"
          :loading="userOrGroupDataLoading"
          :selected-user-name="selectedUserName"
          :summary="summary"
          :user-selected="userSelected"
          without-grade-coloring
          @update-selected-user-or-group="onUpdateSelectedUserOrGroup"
        />
        <PersonalStatisticsSummary
          v-if="!isEmpty(subgroups)"
          :loading="userOrGroupDataLoading"
          :summary="subgroups"
          user-selected
          without-grade-coloring
          @update-selected-user-or-group="onUpdateSelectedSubGroup"
        >
          <template #title>
            {{ $t('global_groups.sub_groups') }}
          </template>
        </PersonalStatisticsSummary>
      </div>
    </div>
  </div>

  <CreateJiraIssueForm ref="createJiraIssueForm" source="form" @save="onCreateJiraIssueFormSave" />
</template>

<script>
import dayjs from 'dayjs'
import html2canvas from 'html2canvas'
import { jsPDF } from 'jspdf'
import { cloneDeep, isEmpty, isNull } from 'lodash'
import { defineComponent, shallowRef } from 'vue'
import { mapActions, mapGetters, mapState } from 'vuex'

import HomePageApiHandler from '@/api/homepage'
import IntervalsInfoApiHandler from '@/api/intervals-info'
import { SEARCH_TYPES } from '@/api/okr-elements'
import { tracker } from '@/tracking/amplitude'
import { EVENT_SOURCES, EVENT_SUBCATEGORIES } from '@/tracking/amplitude-helpers'
import { DROP_LIST_THEMES } from '@/utils/components-configurations/app-droplist'
import { SELECT_TYPES } from '@/utils/components-configurations/app-select'
import { HEADER_SIZES } from '@/utils/components-configurations/page-content-header'
import { dateFormat, UNSELECTED_DATE } from '@/utils/date'
import { handleError } from '@/utils/error-handling'
import { getAvailableCustomRangeParams, GROUP_LIST_TYPES, USER_LIST_TYPES } from '@/utils/homepage'
import {
  addExtraSpaceForFirefox,
  addVisibilityForLogo,
  alignOkrIcons,
  ignoreElementsForExport,
  removeStatusBorders,
  removeStatusGradeBackground,
  replaceAvatars,
  replaceSvgIcons,
  replaceTaskIcons
} from '@/utils/html2canvas-helpers'
import {
  DEFAULT_DATES_RANGE,
  DEFAULT_INTERVAL_CUSTOM_DATE_OBJECT,
  getSuitableInterval,
  getAvailableSavedIntervals,
  UNSELECTED_INTERVAL,
  filterOnBacklogInterval,
  getActiveIntervals,
  displayedLockedDates
} from '@/utils/interval'
import { NOTIFICATION_TYPES, showNotify } from '@/utils/notify'
import { CHART_TYPES } from '@/utils/objective-chart'
import { OKR_FORM_VIEWS, HOMEPAGE_OKR_SEARCH_TYPE_IDS } from '@/utils/objectives'
import {
  HP_FILTERS_KEYS,
  INTERVAL_TIME_RANGE_OPTIONS,
  isTimeRangeSelectedInsteadOfInterval,
  isValidCustomRange,
  restoreFilterValue,
  normalizeCustomRange,
  DEFAULT_CUSTOM_RANGE_VALUE,
  sendIntervalFilterTrackerEvent
} from '@/utils/okr-elements/filters'
import { updateStorageByKey } from '@/utils/persist'
import { replaceQueryParameters } from '@/utils/router'

import AppExportLogo from '@/components/AppExportLogo'
import OwnerFieldOption from '@/components/form/OwnerFieldOption'
import GlobalGroupsSelectOption from '@/components/global-groups/GlobalGroupsSelectOption'
import MainStatistics from '@/components/personal-statistics/MainStatistics'
import PersonalStatisticsOkrElements from '@/components/personal-statistics/PersonalStatisticsOkrElements'
import PersonalStatisticsOkrElementsModals from '@/components/personal-statistics/PersonalStatisticsOkrElementsModals'
import PersonalStatisticsSummary from '@/components/personal-statistics/PersonalStatisticsSummary'
import AppButton from '@/components/ui/AppButton/AppButton'
// import AppIcon from '@/components/ui/AppIcon/AppIcon'
import AppSelect from '@/components/ui/AppSelect/AppSelect'
import AppSelectItemWithTooltip from '@/components/ui/AppSelect/AppSelectItemWithTooltip'
import {
  CUSTOM_RANGE_ITEM,
  isSelectedIntervalCustomRangeMode
} from '@/components/ui/IntervalSelectWithCustomRange/interval-select-with-custom-range-utils'
import IntervalSelectWithCustomRange from '@/components/ui/IntervalSelectWithCustomRange/IntervalSelectWithCustomRange'
import PageContentHeader from '@/components/ui/PageContentHeader/PageContentHeader'
import TaskIconForExport from '@/components/ui/TaskIconForExport'
import CreateJiraIssueForm from '@jira/components/objectives/forms/CreateJiraIssue'

const CATEGORIES = {
  USERS: 'users',
  GROUPS: 'groups'
}

export default defineComponent({
  name: 'HomePage',
  components: {
    // AppIcon,
    AppSelectItemWithTooltip,
    IntervalSelectWithCustomRange,
    GlobalGroupsSelectOption,
    TaskIconForExport,
    AppExportLogo,
    AppButton,
    MainStatistics,
    OwnerFieldOption,
    PersonalStatisticsOkrElementsModals,
    PersonalStatisticsOkrElements,
    CreateJiraIssueForm,
    PersonalStatisticsSummary,
    AppSelect,
    PageContentHeader
  },

  CHART_TYPES: { ...CHART_TYPES },

  data() {
    return {
      showIntervalCustomRangePicker: false,
      selectedIntervalCustomRangeMode: INTERVAL_TIME_RANGE_OPTIONS.BETWEEN.value,
      intervalCustomRange: cloneDeep(DEFAULT_DATES_RANGE),
      intervalCustomDate: cloneDeep(DEFAULT_INTERVAL_CUSTOM_DATE_OBJECT),
      selectedDates: {},
      selectedIntervals: [],

      intervals: [],
      usersAndGroups: [],
      selectedUserOrGroup: null,
      isUsersAndGroupsLoading: false,
      userOrGroupDataLoading: true,
      progressData: {
        objGap: {
          gap: 0,
          progress: 0
        },

        krGap: {
          gap: 0,
          progress: 0
        }
      },

      gradeData: {
        notStarted: 0,
        atRisk: 0,
        behind: 0,
        onTrack: 0,
        closed: 0,
        abandoned: 0
      },

      chartData: {},

      summary: [],
      subgroups: [],

      savedFilters: {},
      searchTypeId: HOMEPAGE_OKR_SEARCH_TYPE_IDS.OBJECTIVES,
      stakeholdersGrade: {
        objectives: null,
        krsAndTasks: null
      }
    }
  },

  VIEWS: OKR_FORM_VIEWS,

  computed: {
    HEADER_SIZES: () => HEADER_SIZES,

    SELECT_TYPES: () => SELECT_TYPES,

    EVENT_SOURCES: () => EVENT_SOURCES,

    DROP_LIST_THEMES: () => DROP_LIST_THEMES,

    displayedLockedDatesOption() {
      return displayedLockedDates({
        selectedDates: this.selectedDates,
        customRangeOptionsBasedOnFilters: this.customRangeOptionsBasedOnFilters
      })
    },

    suitableInterval() {
      return getSuitableInterval(this.intervals, this.selectedIntervals, true)
    },

    intervalForCreate() {
      return getSuitableInterval(this.intervals, this.selectedIntervals, this.isTimeRangeSelected)
    },

    getRequestPayload() {
      let payload = {
        workspaceId: this.selectedWorkspaceId,
        searchTypeId: this.searchTypeId,
        startDateFrom: UNSELECTED_DATE,
        dueDateTo: UNSELECTED_DATE,
        intervalIds: UNSELECTED_INTERVAL
      }

      if (this.isTimeRangeSelected) {
        payload = {
          ...payload,
          ...getAvailableCustomRangeParams({
            selectedDates: this.selectedDates,
            intervalIds: this.selectedIntervals
          })
        }
      } else {
        payload = {
          ...payload,
          intervalIds: this.selectedIntervals
        }
      }
      return payload
    },

    customRangeOptionsBasedOnFilters() {
      const { startDates, dueDates } = this.selectedDates

      return isTimeRangeSelectedInsteadOfInterval({
        startDates,
        dueDates,
        intervals: this.selectedIntervals
      })
    },

    isTimeRangeSelected() {
      return this.customRangeOptionsBasedOnFilters.isTimeRangeSelected
    },

    isBetweenModeSelected() {
      return isSelectedIntervalCustomRangeMode(this.selectedIntervalCustomRangeMode)
        .isBetweenModeSelected
    },

    INTERVALS_FIXED_ITEMS() {
      return [CUSTOM_RANGE_ITEM]
    },

    SEARCH_TYPES() {
      return SEARCH_TYPES
    },

    ...mapGetters('system', {
      getSearchTypeId: 'searchTypeId'
    }),

    ...mapState('workspaces', {
      selectedWorkspaceId: state => state.workspaceId
    }),

    isKrSearchType() {
      return this.searchTypeId === HOMEPAGE_OKR_SEARCH_TYPE_IDS.KEY_RESULTS
    },

    mainList() {
      const userObjectivesLists = [
        {
          id: 0,
          listType: USER_LIST_TYPES.OWNERS
        }

        // temporary commented till watchers functionality not added
        // {
        //   id: 2,
        //   listType: USER_TYPES.WATCHERS,
        // },
      ]
      const groupObjectivesLists = [
        {
          id: 3,
          listType: GROUP_LIST_TYPES.OWNERS
        }
      ]

      if (this.isKrSearchType) {
        userObjectivesLists.push({
          id: 1,
          listType: USER_LIST_TYPES.FOREIGN_OBJECTIVES
        })
        groupObjectivesLists.push({
          id: 1,
          listType: GROUP_LIST_TYPES.FOREIGN_OBJECTIVES
        })
      }

      return this.userSelected ? userObjectivesLists : groupObjectivesLists
    },

    stakeholderList() {
      const userObjectivesLists = [
        {
          id: 1,
          listType: USER_LIST_TYPES.STAKEHOLDERS
        }
      ]
      return this.userSelected ? userObjectivesLists : []
    },

    selectedUserName() {
      return this.userSelected
        ? this.usersAndGroups.find(({ accountId }) => accountId === this.selectedUserOrGroup)
            ?.name || ''
        : null
    },

    selectedGroupName() {
      return this.userSelected
        ? null
        : this.usersAndGroups.find(({ id }) => id === this.selectedUserOrGroup)?.name || ''
    },

    selectedFilters() {
      const { selectedUserOrGroup, userSelected, selectedIntervals } = this
      const selectedUserOrGroupKey = userSelected
        ? HP_FILTERS_KEYS.SELECTED_USER
        : HP_FILTERS_KEYS.SELECTED_GROUP

      return {
        [HP_FILTERS_KEYS.INTERVAL_IDS]: selectedIntervals,
        [HP_FILTERS_KEYS.START_DATES]: this.selectedDates.startDates || DEFAULT_CUSTOM_RANGE_VALUE,
        [HP_FILTERS_KEYS.DUE_DATES]: this.selectedDates.dueDates || DEFAULT_CUSTOM_RANGE_VALUE,
        [selectedUserOrGroupKey]: selectedUserOrGroup,
        [HP_FILTERS_KEYS.SEARCH_TYPE_ID]: this.searchTypeId
      }
    },

    groups() {
      return {
        groups: {
          name: this.$t('homepage.user_select.groups')
        },

        users: {
          name: this.$t('homepage.user_select.users')
        }
      }
    },

    showSummary() {
      return !isEmpty(this.summary)
    },

    userSelected() {
      return (
        !isEmpty(this.selectedUserOrGroup) &&
        this.getSelectedUserOrGroupCategory(this.selectedUserOrGroup) === CATEGORIES.USERS
      )
    }
  },

  watch: {
    selectedWorkspaceId: {
      async handler(newValue) {
        if (newValue !== null) {
          await this.getAllIntervals()
          await this.fetchInitialData()
        }
      },

      immediate: true
    },

    selectedUserOrGroup: {
      async handler(newValue) {
        if (!isNull(newValue) && !this.isUsersAndGroupsLoading) {
          const selectedCategory = this.getSelectedUserOrGroupCategory(newValue)
          this.getChartAndProgressData(selectedCategory)
          await this.$nextTick()
          this.refreshOkrsLists()
        }
      },

      immediate: true
    },

    selectedIntervals(newValue) {
      if (!isEmpty(newValue) && this.selectedUserOrGroup) {
        const selectedCategory = this.getSelectedUserOrGroupCategory()
        this.getChartAndProgressData(selectedCategory)
        this.refreshOkrsLists()
      }
    },

    selectedDates() {
      if (this.selectedUserOrGroup) {
        const selectedCategory = this.getSelectedUserOrGroupCategory()
        this.getChartAndProgressData(selectedCategory)
        this.refreshOkrsLists()
      }
    },

    selectedFilters: {
      handler(newValue) {
        const selectedFiltersValues = Object.values(newValue)
        let queryParameters = { ...this.$route.query }
        if (!selectedFiltersValues.some(value => value === null)) {
          Object.entries(newValue).forEach(([key, value]) => {
            if (this.userSelected) {
              queryParameters[HP_FILTERS_KEYS.SELECTED_GROUP] = JSON.stringify(null)
              updateStorageByKey(HP_FILTERS_KEYS.SELECTED_GROUP, null)
            } else {
              queryParameters[HP_FILTERS_KEYS.SELECTED_USER] = JSON.stringify(null)
              updateStorageByKey(HP_FILTERS_KEYS.SELECTED_USER, null)
            }

            if (queryParameters[key] !== JSON.stringify(value)) {
              queryParameters[key] = JSON.stringify(value)
            }
            updateStorageByKey(key, value)
          })

          replaceQueryParameters(this.$router, this.$route, queryParameters)
        }
      },

      deep: true,
      immediate: true
    }
  },

  methods: {
    isEmpty,
    ...mapActions('system', {
      toggleFullAppLoader: 'toggleFullAppLoader'
    }),

    selectIntervals(value, sendTrackerEvent = false) {
      this.showIntervalCustomRangePicker = false
      const newValue = filterOnBacklogInterval({
        intervals: this.intervals,
        value,
        selectedIntervals: this.selectedIntervals
      })

      this.selectedIntervals = newValue

      if (sendTrackerEvent) {
        sendIntervalFilterTrackerEvent({
          section: EVENT_SOURCES.HOMEPAGE,
          newValue: newValue,
          intervals: this.intervals
        })
      }
    },

    onUpdateSearchTypeId(newValue) {
      this.searchTypeId = newValue
      if (!isNull(newValue) && this.selectedUserOrGroup) {
        const selectedCategory = this.getSelectedUserOrGroupCategory()
        this.getChartAndProgressData(selectedCategory)
        this.refreshOkrsLists()
      }
    },

    async onExportClick() {
      await this.toggleFullAppLoader()
      await this.$nextTick()

      // setTimeout is for avoid start of export before the loader is shown and for showing chart
      setTimeout(async () => {
        const wrapper = this.$refs.homepageWrapper
        const { offsetWidth: width, offsetHeight: height } = wrapper
        const canvas = await html2canvas(wrapper, {
          onclone: clonedNode => {
            addExtraSpaceForFirefox(clonedNode)
            addVisibilityForLogo(clonedNode)
            alignOkrIcons(clonedNode)
            replaceAvatars(clonedNode)
            replaceTaskIcons(clonedNode)
            replaceSvgIcons(clonedNode)
            removeStatusBorders(clonedNode)
            removeStatusGradeBackground(clonedNode)
          },
          scale: 1.75,
          useCORS: true, // necessary to display base64 images
          imageTimeout: 0,
          width,
          height,
          ignoreElements: ignoreElementsForExport()
        })

        const dataUrl = canvas.toDataURL('image/png')

        const pdfFile = new jsPDF({
          orientation: width > height ? 'landscape' : 'portrait',
          unit: 'px',
          format: [wrapper.offsetWidth, wrapper.offsetHeight],
          compress: true
        })
        pdfFile.addImage(dataUrl, 'PNG', 0, 0, wrapper.offsetWidth, wrapper.offsetHeight)
        const date = dayjs().format(dateFormat)
        const suitableIntervalNames = this.intervals
          .filter(({ id }) => this.selectedIntervals.includes(id))
          .map(({ name }) => name)

        const intervalOrRangeName = this.isTimeRangeSelected
          ? this.displayedLockedDatesOption.full
          : suitableIntervalNames.join('_')
        const userOrGroupName = this.userSelected
          ? this.usersAndGroups.find(({ accountId }) => accountId === this.selectedUserOrGroup)
              ?.name || 'unknown user'
          : this.usersAndGroups.find(({ id }) => id === this.selectedUserOrGroup)?.name ||
            'unknown group'
        pdfFile.save(`report_${userOrGroupName}_${intervalOrRangeName}___${date}`)

        await this.toggleFullAppLoader()
      })
    },

    onChangeUsersAndGroups() {
      tracker.logEvent('Viewed progress', {
        subcategory: this.userSelected ? EVENT_SUBCATEGORIES.USER : EVENT_SUBCATEGORIES.TEAM,
        source: EVENT_SOURCES.FILTER
      })
    },

    async onUpdateSelectedSubGroup(id) {
      const { userSelected } = this

      const payload = { pickedGroupId: id }

      this.usersAndGroups = await this.getUsersAndGroups(null, payload)

      const manualSelectAllowed = this.checkIsManualSelectAllowed(id)

      if (manualSelectAllowed) {
        this.selectUserOrGroupManually(id)
      } else {
        const { $t } = this

        const errorMessageRole = userSelected
          ? $t('field.group.title')
          : $t('homepage.notify.role.user')

        const errorMessage = $t('homepage.notify.error', { role: errorMessageRole })

        showNotify({ title: errorMessage, type: NOTIFICATION_TYPES.ERROR })
      }
      tracker.logEvent('Viewed progress', {
        subcategory: this.userSelected ? EVENT_SUBCATEGORIES.USER : EVENT_SUBCATEGORIES.TEAM,
        source: EVENT_SOURCES.HOMEPAGE
      })
    },

    async onUpdateSelectedUserOrGroup(id) {
      const { userSelected } = this
      const manualSelectAllowed = this.checkIsManualSelectAllowed(id)
      if (manualSelectAllowed) {
        this.selectUserOrGroupManually(id)
      } else {
        const payload = userSelected ? { pickedGroupId: id } : { pickedAccountId: id }

        this.usersAndGroups = await this.getUsersAndGroups(null, payload)

        const manualSelectAllowed = this.checkIsManualSelectAllowed(id)

        if (manualSelectAllowed) {
          this.selectUserOrGroupManually(id)
        } else {
          const { $t } = this

          const errorMessageRole = userSelected
            ? $t('field.group.title')
            : $t('homepage.notify.role.user')

          const errorMessage = $t('homepage.notify.error', { role: errorMessageRole })

          showNotify({ title: errorMessage, type: NOTIFICATION_TYPES.ERROR })
        }
      }
      tracker.logEvent('Viewed progress', {
        subcategory: this.userSelected ? EVENT_SUBCATEGORIES.USER : EVENT_SUBCATEGORIES.TEAM,
        source: EVENT_SOURCES.HOMEPAGE
      })
    },

    selectUserOrGroupManually(id) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
      this.selectedUserOrGroup = id
    },

    checkIsManualSelectAllowed(id) {
      return this.usersAndGroups
        .map(item => {
          return item.id || item.accountId
        })
        .includes(id)
    },

    editElement({ view, item, scrollToComments }) {
      this.$refs.homePageOkrElementsActions.editObjective(view, item, { scrollToComments })
    },

    linkJiraIssue({ view, data }) {
      this.$refs.homePageOkrElementsActions.linkJiraIssue(view, data)
    },

    createJiraIssue(objective) {
      this.$refs.createJiraIssueForm.create(objective)
    },

    createKeyResult(objective) {
      this.$refs.homePageOkrElementsActions.createKR(OKR_FORM_VIEWS.KR, objective)
    },

    onCreateJiraIssueFormSave() {
      this.updateOkrElements()
    },

    getSelectedUserOrGroupCategory(id) {
      const idForCheck = id || this.selectedUserOrGroup
      return this.usersAndGroups.find(item => item.id === idForCheck)?.category
    },

    async fetchInitialData() {
      const { savedFilters } = this
      const searchTypeId = savedFilters[HP_FILTERS_KEYS.SEARCH_TYPE_ID]
      if (searchTypeId && Object.values(HOMEPAGE_OKR_SEARCH_TYPE_IDS).includes(searchTypeId)) {
        this.searchTypeId = savedFilters[HP_FILTERS_KEYS.SEARCH_TYPE_ID]
      } else {
        this.searchTypeId = this.getSearchTypeId
      }
      if (
        savedFilters[HP_FILTERS_KEYS.SELECTED_USER] ||
        savedFilters[HP_FILTERS_KEYS.SELECTED_GROUP]
      ) {
        const payload = {}

        if (savedFilters[HP_FILTERS_KEYS.SELECTED_USER]) {
          payload.pickedAccountId = savedFilters[HP_FILTERS_KEYS.SELECTED_USER]
        } else {
          payload.pickedGroupId = savedFilters[HP_FILTERS_KEYS.SELECTED_GROUP]
        }

        const [pickedId] = Object.values(payload)
        this.usersAndGroups = await this.getUsersAndGroups(null, payload)
        const manualSelectAllowed = this.checkIsManualSelectAllowed(pickedId)
        this.selectedUserOrGroup = manualSelectAllowed ? pickedId : this.usersAndGroups[0].id
      } else {
        this.usersAndGroups = await this.getUsersAndGroups()
        this.selectedUserOrGroup = this.usersAndGroups[0].id
      }
    },

    async getChartAndProgressData(category) {
      this.userOrGroupDataLoading = true

      await Promise.all([this.getGroupOrUserData(category), this.getChartData(category)])

      this.userOrGroupDataLoading = false
    },

    convertsUsersAndGroupsToOneList({ users, groups }) {
      const convertedUsers = users.map(user => {
        return {
          ...user,
          id: user.accountId,
          name: user.displayName,
          category: CATEGORIES.USERS
        }
      })
      const convertedGroups = groups.map(group => {
        return {
          ...group,
          category: CATEGORIES.GROUPS
        }
      })

      return convertedUsers.concat(convertedGroups)
    },

    restoreFilters() {
      const restoredFilters = {}
      Object.values(HP_FILTERS_KEYS).forEach(key => {
        const restoredValue = restoreFilterValue(this.$route, key, null)
        if (restoredValue) {
          restoredFilters[key] = restoredValue
        }
      })
      this.savedFilters = shallowRef({ ...restoredFilters })
    },

    async getAllIntervals() {
      this.restoreFilters()
      const lastSelectedIntervals = this.savedFilters[HP_FILTERS_KEYS.INTERVAL_IDS]

      const startDates = this.savedFilters[HP_FILTERS_KEYS.START_DATES]
      const dueDates = this.savedFilters[HP_FILTERS_KEYS.DUE_DATES]

      const api = new IntervalsInfoApiHandler()
      try {
        const { currentIndex, intervals } = await api.getIntervalsInfo({
          workspaceId: this.selectedWorkspaceId
        })
        this.intervals = getActiveIntervals(intervals)
        if (this.intervals.length > 0) {
          const getActiveInterval = () =>
            getSuitableInterval(this.intervals, [intervals[currentIndex].id], true)
          const isValidRange = dates => {
            const filteredDates = dates?.filter(item => item)
            return dates && !isEmpty(filteredDates) && isValidCustomRange(filteredDates)
          }

          if (!isEmpty(lastSelectedIntervals)) {
            this.selectedIntervals = getAvailableSavedIntervals({
              allIntervals: this.intervals,
              savedIntervals: lastSelectedIntervals
            })
          } else if (isValidRange(startDates) || isValidRange(dueDates)) {
            const normalizedStartDates = normalizeCustomRange(startDates)
            const normalizedDueDates = normalizeCustomRange(dueDates)
            this.selectedDates = {
              startDates: normalizedStartDates,
              dueDates: normalizedDueDates
            }
          } else {
            this.selectedIntervals = [getActiveInterval()]
          }
        }
      } catch (error) {
        handleError({ error })
      }
    },

    async getUsersAndGroups(searchString = null, pickedId = {}) {
      const api = new HomePageApiHandler()
      let result = []
      this.isUsersAndGroupsLoading = true
      try {
        const data = await api.getUsersAndGroups({
          searchString,
          workspaceId: this.selectedWorkspaceId,
          ...pickedId
        })
        result = this.convertsUsersAndGroupsToOneList(data)
      } catch (error) {
        handleError({ error })
      }
      this.isUsersAndGroupsLoading = false
      return result
    },

    async getChartData(category) {
      const api = new HomePageApiHandler()
      const defaultPayload = {
        ...this.getRequestPayload
      }

      try {
        const requestForUser = category === CATEGORIES.USERS
        if (requestForUser) {
          this.chartData = await api.getChartDataForUser({
            accountId: this.selectedUserOrGroup,
            ...defaultPayload
          })
        } else {
          this.chartData = await api.getChartDataForGroup({
            groupId: this.selectedUserOrGroup,
            ...defaultPayload
          })
        }
      } catch (error) {
        handleError({ error })
      }
    },

    async getGroupOrUserData(category) {
      const api = new HomePageApiHandler()
      const defaultPayload = {
        ...this.getRequestPayload
      }

      let response = {}

      try {
        const requestForUser = category === CATEGORIES.USERS
        if (requestForUser) {
          const [userData, stakeholderData] = await Promise.all([
            api.getUserData({
              accountId: this.selectedUserOrGroup,
              ...defaultPayload
            }),
            api.getStakeholdersGrade({
              accountId: this.selectedUserOrGroup,
              ...defaultPayload
            })
          ])

          response = userData
          this.stakeholdersGrade = stakeholderData
        } else {
          const [userData, stakeholderData] = await Promise.all([
            api.getGroupData({
              groupId: this.selectedUserOrGroup,
              ...defaultPayload
            }),
            api.getStakeholdersGrade({
              groupIds: [this.selectedUserOrGroup],
              ...defaultPayload
            })
          ])
          response = userData
          this.stakeholdersGrade = stakeholderData
        }

        const {
          onTrack,
          behind,
          atRisk,
          notStarted,
          closed,
          abandoned,
          objGap,
          krGap,
          teammates,
          groupsProgress,
          subgroups
        } = response

        this.gradeData.onTrack = onTrack
        this.gradeData.behind = behind
        this.gradeData.atRisk = atRisk
        this.gradeData.notStarted = notStarted
        this.gradeData.closed = closed
        this.gradeData.abandoned = abandoned

        this.progressData = {
          objGap,
          krGap
        }
        this.summary = requestForUser ? groupsProgress : teammates
        this.subgroups = subgroups
      } catch (error) {
        handleError({ error })
      }
    },

    updateOkrElements() {
      const selectedCategory = this.getSelectedUserOrGroupCategory(this.selectedUserOrGroup)
      this.getGroupOrUserData(selectedCategory)
      this.getChartData(selectedCategory)
      this.$refs.okrsLists.updateOkrElements()
      this.$refs.stakeholderList?.updateOkrElements()
    },

    refreshOkrsLists() {
      this.$refs.okrsLists.refreshData()
      this.$refs.stakeholderList?.refreshData()
    }
  }
})
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';

.hp-HomePage {
  padding: 0 $page-right-padding 32px $page-left-padding;
  display: flex;
  flex-direction: column;
  row-gap: 32px;
}

.hp-Filters {
  display: flex;
  gap: 8px;

  overflow: hidden;
}

.hp-Filters_Select {
  max-width: 260px;
}

//.hp-Reminder {
//  display: flex;
//  padding: 20px;
//  gap: 8px;
//  align-items: center;
//  font-weight: fw('semi-bold');
//  color: $primary-color-next;
//  background: rgba($primary-color-next, 0.1);
//  border-radius: $border-radius-md-next;
//}

.hp-OkrElements {
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  max-width: 976px;
  width: 100%;
  margin: 0 auto;
  padding: 0 32px 32px 32px;
  background: $white;
  border-radius: $border-radius-lg-next $border-radius-lg-next 22px 22px;
}

.hp-ExportButton {
  background-color: transparent;

  @include hoverState($grey-3-next, 1%);
  @include activeState($grey-3-next);
}

.hp-MainDataWrapper {
  max-width: var(--max-width, 976px);
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 39px;
  &:deep(.okg-OkrGrade_Value) {
    min-width: 45px;
  }
}
.hp-StyledWrapper {
  border-radius: $border-radius-lg-next;
  border: 2px solid $grey-2-next;
}
</style>
