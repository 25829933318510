import { isFunction } from 'lodash'
import { onBeforeUnmount, onMounted, ref, useTemplateRef } from 'vue'

export const useMarkAsRead = ({ item, callback, templateRef = '' } = {}) => {
  const observer = ref(null)
  const wrapperReference = useTemplateRef(templateRef) || null

  const initIntersectionObserver = () => {
    observer.value = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            if (isFunction(callback)) {
              callback()
            }
            destroyIntersectionObserver()
          }
        })
      },
      {
        threshold: 1
      }
    )
    observer.value.observe(wrapperReference.value)
  }

  const destroyIntersectionObserver = () => {
    observer.value.disconnect()
    observer.value = null
  }

  onMounted(() => {
    if (!item.isRead && wrapperReference.value) {
      initIntersectionObserver()
    }
  })

  onBeforeUnmount(() => {
    if (observer.value) {
      destroyIntersectionObserver()
    }
  })

  return {
    wrapperReference
  }
}
