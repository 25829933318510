<template>
  <div
    :class="{
      'te-RowWrapper-expanded': currentRowIsExpanded,
      'te-RowWrapper-only-nested-rows-line': item.calculateHeightOfNestedItemsOnlyForRows
    }"
    :style="rowStyle"
    class="te-RowWrapper"
  >
    <div class="te-RowWrapper_Content">
      <ContributeLine v-if="depth > 0" class="te-ContributeLine" />

      <div
        :class="{
          [`te-Row-depth-${depth}`]: true,
          'te-Row-haschildren': rowIsExpandable[item[itemValue]],
          'te-Row-hovered': hoverRow === item[itemValue]
        }"
        :data-testid="`expandable-table-row-${item[itemValue]}`"
        class="te-Row"
        @click.self="onClickSelf"
      >
        <AppButton
          v-if="isShowExpandButton"
          :class="{
            'te-IconArrow-expanded': currentRowIsExpanded,
            'te-IconArrow-hidden': !rowIsExpandable[item[itemValue]]
          }"
          class="te-IconArrow"
          height="24"
          icon="okr-table-expand"
          size="sm"
          type="subtle"
          width="24"
          @click="onExpandClick(item, index, depth)"
        />
        <div
          v-for="column in columns"
          :key="column.key"
          :class="[`te-Cell-${column.key}`, [`te-Cell-${column.key}-${index}`]]"
          :style="columnStyles[column.key]"
          class="te-Cell"
          @click.self="onCellClick(item, column, $event, index, depth)"
        >
          <slot :column-key="column.key" :depth="depth" :index="index" :item="item" name="cell">
            {{ item[column.key] }}
          </slot>
        </div>
      </div>

      <div class="te-Row_Border" />

      <template v-if="currentRowIsExpanded">
        <TableExpandableRow
          v-for="(childRow, childIndex) in children"
          :key="childIndex"
          :child-items="childItems"
          :column-styles="columnStyles"
          :columns="columns"
          :depth="depth + 1"
          :hide-expand-button-in-no-expandable-row="hideExpandButtonInNoExpandableRow"
          :hover-row="hoverRow"
          :index="childIndex"
          :item="childRow"
          :item-value="itemValue"
          :offset-left="offsetLeft"
          :offset-right="offsetRight"
          :row-is-expandable="rowIsExpandable"
          :row-is-expanded="rowIsExpanded"
          @expand="$emit('expand', $event)"
        >
          <template #cell="slotData">
            <slot
              :column-key="slotData.columnKey"
              :depth="slotData.depth"
              :index="slotData.index"
              :item="slotData.item"
              name="cell"
            />
          </template>

          <template #row-wrapper-after="{ childOffset }">
            <slot :child-offset="childOffset" :item="childRow" name="row-wrapper-after" />
          </template>
        </TableExpandableRow>
      </template>
    </div>

    <slot
      v-if="currentRowIsExpanded"
      :child-offset="getChildOffset()"
      :item="item"
      name="row-wrapper-after"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue'

import {
  memoizeExpandableTableContributeLineStyle,
  memoizeExpandableTableRowBorderStyle,
  memoizeExpandableTableRowOffset
} from '@/utils/memoizations'
import { stringOrNullProp } from '@/utils/prop-validators'

import ContributeLine from '@/components/objectives/forms/ContributeLine'
import AppButton from '@/components/ui/AppButton/AppButton'

import { getRowId } from './table-expandable-utils'

export default defineComponent({
  name: 'TableExpandableRow',
  components: {
    AppButton,
    ContributeLine
  },

  props: {
    depth: {
      type: Number,
      required: true
    },

    index: {
      type: Number,
      required: true
    },

    item: {
      type: Object,
      required: true
    },

    columns: {
      type: Array,
      required: true
    },

    columnStyles: {
      type: Object,
      default: () => ({})
    },

    rowIsExpandable: {
      type: Object,
      required: true
    },

    rowIsExpanded: {
      type: Object,
      required: true
    },

    childItems: {
      type: Object,
      required: true
    },

    itemValue: {
      type: String,
      required: true
    },

    onRowClickSelf: {
      type: Function,
      default: () => ({})
    },

    onCellClickSelf: {
      type: Function,
      default: () => ({})
    },

    hideExpandButtonInNoExpandableRow: {
      type: Boolean
    },

    offsetLeft: {
      type: [String, Number],
      default: ''
    },

    offsetRight: {
      type: [String, Number],
      default: ''
    },

    hoverRow: {
      default: null,
      validator: v => stringOrNullProp(v)
    }
  },

  emits: { expand: null },

  computed: {
    children() {
      return this.childItems[this.item[this.itemValue]] || []
    },

    currentRowIsExpanded() {
      return this.rowIsExpanded[
        getRowId({
          depth: this.depth,
          id: this.item[this.itemValue]
        })
      ]
    },

    rowStyle() {
      const offset = memoizeExpandableTableRowOffset(this.depth)

      return {
        '--offset-left': this.offsetLeft,
        '--offset-right': this.offsetRight,
        '--offset': `${offset}px`,
        '--border-offset': memoizeExpandableTableRowBorderStyle(offset, this.isShowExpandButton),
        ...memoizeExpandableTableContributeLineStyle(offset, this.depth)
      }
    },

    isShowExpandButton() {
      if (this.hideExpandButtonInNoExpandableRow) {
        return Boolean(this.rowIsExpandable[this.item[this.itemValue]])
      }

      return true
    }
  },

  methods: {
    onClickSelf($event) {
      if (this.rowIsExpandable[this.item[this.itemValue]]) {
        this.onRowClickSelf(this.item, $event)
        this.$emit('expand', { item: this.item, index: this.index, depth: this.depth })
      }
    },

    getChildOffset() {
      return memoizeExpandableTableRowOffset(this.depth + 1)
    },

    onCellClick(item, column, $event, index, depth) {
      if (this.rowIsExpandable[this.item[this.itemValue]]) {
        this.onCellClickSelf(item, column, $event)
        this.$emit('expand', { item, index, depth })
      }
    },

    onExpandClick(item, index, depth) {
      if (this.rowIsExpandable[this.item[this.itemValue]]) {
        this.$emit('expand', { item, index, depth })
      }
    }
  }
})
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';

.te-Row {
  display: flex;
  align-items: center;
  line-height: 24px;
  position: relative;
  overflow: hidden;
  padding: 10px var(--offset-right, 0px) 10px calc(var(--offset, 0px) + var(--offset-left, 0px));

  &-haschildren {
    cursor: pointer;
  }

  &:hover,
  &-hovered {
    background-color: $grey-3-next;
  }
}

%nested-items-line {
  &:before {
    content: '';
    position: absolute;
    width: 1px;

    height: var(
      --height,
      calc(100% - 62px)
    ); // 34 + 10 + 12 padding expand half height + 6px cl line
    top: var(--offset-top, 34px); // 10px padding top + 24px height of expand button

    left: calc(
      var(--offset-left, 0px) + var(--offset, 0px) + 12px
    ); // 12px → 24px (width of expand button) / 2
    background-color: $grey-2-next;
    z-index: 4;
    pointer-events: none;
  }
}

.te-RowWrapper {
  position: relative;
}

.te-RowWrapper_Content {
  position: relative;
}

.te-RowWrapper-expanded {
  &:not(.te-RowWrapper-only-nested-rows-line) {
    @extend %nested-items-line;
  }

  &.te-RowWrapper-only-nested-rows-line {
    > .te-RowWrapper_Content {
      @extend %nested-items-line;
    }
  }
}

.te-IconArrow {
  margin-right: 8px;
  width: 24px;
  flex-shrink: 0;

  &-expanded {
    transform: rotateZ(90deg);
  }

  &-hidden {
    visibility: hidden;
  }
}

.te-Cell {
  flex-shrink: 0;
  min-width: 0;
  font-weight: fw('regular');
  font-size: $fs-14;
  line-height: 20px;
}
.te-ContributeLine {
  z-index: 1;
  --height: 6px;
  left: calc(var(--offset-left, 0px) + var(--cl-offset, 0px));
  position: absolute;
  --width: var(--cl-width, 0px);
  top: var(
    --offset-contribute-top,
    16px
  ); // 10 px row padding top + 12px (half height of row content) - 6px height of Line
}

.te-Row_Border {
  height: 1px;

  @include tableLinesGradient(
    transparent,
    $grey-2-next,
    calc(var(--offset-left, 0px) + var(--border-offset)),
    var(--offset-right, 0px)
  );
}
</style>
