<template>
  <BulkActionsOperationValueControl
    v-model="localModelValue"
    :empty-state-label="
      t('bulk_actions.set_custom_field_value', {
        customFieldName: fieldName
      })
    "
    :field-id="fieldTypeId"
    :item-label="CUSTOM_FIELD_ENTITY_KEYS.NAME"
    :item-value="CUSTOM_FIELD_ENTITY_KEYS.ID"
    :options="options"
    :search-function="value => localSearch({ value, options })"
    is-custom-field
    @update:options="options = $event"
    @toggle-value-dropdown="emit('toggle-value-dropdown', $event)"
  />
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import { useCustomFieldsHelpers } from '@/utils/custom-fields/use-custom-fields'
import { CUSTOM_FIELD_ENTITY_KEYS } from '@/utils/entity-keys'
import { localSearch } from '@/utils/objectives'

import BulkActionsOperationValueControl from '@/components/objectives/bulk-edit/BulkActionsOperationValueControl'

defineOptions({
  name: 'MultiSelectField',
  inheritAttrs: false
})

const props = defineProps({
  modelValue: {
    type: Object,
    required: true
  },

  fieldTypeId: {
    type: [String, Number],
    required: true
  },

  fieldId: {
    type: [String, Number],
    required: true
  }
})

const { t } = useI18n()

const emit = defineEmits({
  'update:model-value': null,
  'toggle-value-dropdown': null
})

const { options, fieldName } = useCustomFieldsHelpers({
  fieldId: props.fieldId
})

const localModelValue = computed({
  get: () => {
    const { value, operation } = props.modelValue

    return {
      value,
      operation
    }
  },
  set: newValue => {
    emit('update:model-value', newValue)
  }
})
</script>

<style lang="scss" scoped></style>
