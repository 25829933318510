<template>
  <span
    :class="{ 'argn-Option-centered': centered, 'argn-Option-align-start': alignStart }"
    class="argn-Option"
  >
    <AppIcon :icon-name="option.icon" class="argn-Option_Icon" height="24" width="24" />
    <span
      :class="{
        'argn-Option_Label-truncated': truncated,
        'argn-Option_Label-multiline-truncated': multilineTruncated
      }"
      class="argn-Option_Label"
    >
      {{ $t(option.label) }}
    </span>
  </span>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AppRadioGroupNextOptionWithIcon'
})
</script>

<script setup>
import AppIcon from '@/components/ui/AppIcon/AppIcon'

defineProps({
  option: {
    type: Object,
    required: true
  },

  gap: {
    type: String,
    default: ''
  },

  centered: {
    type: Boolean
  },

  truncated: {
    type: Boolean
  },

  multilineTruncated: {
    type: Boolean
  },

  alignStart: {
    type: Boolean
  }
})
</script>

<style lang="scss" scoped>
.argn-Option {
  display: flex;
  gap: v-bind(gap);

  &:not(&-align-start) {
    align-items: center;
  }

  &-align-start {
    align-items: flex-start;
  }

  &-centered {
    justify-content: center;
  }
}

.argn-Option_Label {
  .argn-Option-align-start & {
    padding: var(--option-padding, 2px 0);
  }

  &-truncated {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &-multiline-truncated {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: var(--line-count, 2);
    white-space: normal;
    word-break: break-all;
  }
}

.argn-Option_Icon {
  flex-shrink: 0;
}
</style>
