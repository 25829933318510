import ApiHandler from '@/api/base'

class IntervalsInfoApiHandler extends ApiHandler {
  async getIntervalsInfo({ workspaceId }) {
    const { data } = await this.http.post('/getIntervalInfo', { workspaceId })
    return data
  }

  async getAllIntervalsInfo({ workspaceId }) {
    const { data } = await this.http.post('/getAllIntervals', { workspaceId })
    return data
  }

  async createInterval(payload) {
    const { data } = await this.http.post('/createInterval', payload)
    return data
  }

  async updateInterval(payload) {
    const { data } = await this.http.post('/updateInterval', payload)
    return data
  }

  async removeInterval({ id = null, workspaceId }) {
    const { data } = await this.http.post('/removeInterval', {
      id,
      workspaceId
    })
    return data
  }
}

export default IntervalsInfoApiHandler
