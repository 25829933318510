<template>
  <span
    :class="{
      'o-title': true,
      'o-title-unavailable': !userHasReadAccess
    }"
  >
    <ObjectiveId
      :clickable="idAsLink && userHasReadAccess"
      :disabled-color="idDisabledColor"
      :filtered-out="semiTransparentId"
      :size="idSize"
      :value="objective.displayId"
      @click="$emit('click-on-id')"
    />

    <ObjectiveName
      v-if="showObjectiveName"
      :filtered-out="semiTransparentId"
      :objective="objective"
      :with-tooltip="withTooltip"
      @click="$emit('click-on-name')"
    >
      <slot />
    </ObjectiveName>
  </span>
</template>

<script>
import { defineComponent } from 'vue'

import { currentUserCanReadObjective } from '@/utils/objectives'

import ObjectiveId from '@/components/objectives/ObjectiveId'
import ObjectiveName from '@/components/objectives/ObjectiveName'

export default defineComponent({
  name: 'TitleItems',

  components: {
    ObjectiveId,
    ObjectiveName
  },

  props: {
    objective: {
      type: Object,
      required: true
    },

    idAsLink: {
      type: Boolean,
      default: true
    },

    idSize: {
      type: String,
      default: 'default',
      validator: v => ['default', 'small', 'large'].includes(v)
    },

    semiTransparentId: {
      type: Boolean
    },

    showObjectiveName: {
      type: Boolean,
      default: true
    },

    withTooltip: {
      type: Boolean
    },

    idDisabledColor: {
      type: String,
      default: '#172B4D'
    }
  },

  emits: { 'click-on-name': null, 'click-on-id': null },

  computed: {
    userHasReadAccess() {
      return currentUserCanReadObjective(this.objective)
    }
  }
})
</script>

<style lang="scss" scoped>
.o-title {
  display: flex;
  align-items: center;
  max-width: 100%;
  overflow: hidden;
  width: 100%;
  gap: 4px;

  &-unavailable {
    color: $grey-1-next;
  }
}

:deep(.o-title-text-wrapper) {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}
</style>
