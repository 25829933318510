<template>
  <div class="lp-LevelPage">
    <PageContentHeader
      :description="isNoLevels ? null : $t('levels.page_description')"
      :level="3"
      :max-width="HEADER_SIZES.LG"
      :title="$t('levels.header_title')"
      no-margin
      style="--align-items: flex-start"
    >
      <AppButton
        v-if="!isNoLevels"
        v-tippy="{
          content: isOkrLevelsLimitExceeded ? okrLevelsLimitations.getDisabledButtonTooltip() : null
        }"
        :disable="isOkrLevelsLimitExceeded"
        height="24"
        icon="plus-next"
        type="primary-next"
        width="24"
        @click="onCreateLevelClick"
      >
        {{ $t('levels.title.create') }}
      </AppButton>
    </PageContentHeader>

    <div class="lp-MainContent">
      <TableSortable
        v-if="!isNoLevels"
        :columns="tableColumns"
        :data="levels"
        :hover-row="tableHoverRow"
        :loading="loading"
        class="lp-Table"
        offset-left="var(--page-left-padding)"
        offset-right="var(--page-right-padding)"
        sticky-header
        @drop="onTableRowDropped"
        @update:data="levels = $event"
      >
        <template #header-cell="{ column }">
          <template v-if="column.title">
            {{ $t(column.title) }}
          </template>
        </template>
        <template #cell="{ columnKey, item, index }">
          <template v-if="columnKey === TABLE_COLUMNS_KEYS.NAME">
            <div class="lp-NameRow">
              <OkrIcon :objective="levelToOkrIconElement(item)" />
              <a class="lp-Name" @click.prevent="onEditLevel(item)"> {{ item.name }} </a>
            </div>
          </template>
          <template v-if="columnKey === TABLE_COLUMNS_KEYS.WORKSPACE">
            <WorkspacesListCell :limit="3" :workspaces="item[OKR_LEVEL_ENTITY_KEYS.WORKSPACES]" />
          </template>
          <template v-if="columnKey === TABLE_COLUMNS_KEYS.ACTIONS">
            <div class="lp-Actions">
              <DropdownMenu
                :items="DROPDOWN_MENU_ACTIONS"
                :offset="[0, 0]"
                :type="DROPDOWN_MENU_TYPES.DEFAULT_NEXT"
                preferred-position="bottom-end"
                @close="tableHoverRow = -1"
                @open="tableHoverRow = index"
                @item-click="onMenuActionsClick($event, item)"
              >
                <template #activator>
                  <AppButton icon="more-next" size="sm" type="tertiary-next" />
                </template>
              </DropdownMenu>
            </div>
          </template>
        </template>
        <template #loading>
          <LevelsTableLoader />
        </template>

        <template #footer>
          <AppInfoMessage
            v-if="isOkrLevelsLimitExceeded"
            :type="INFO_MESSAGE_TYPES.WARNING"
            class="lp-Message"
          >
            {{
              $t('limitations.entity.limit_exceeded', {
                limit: okrLevelsLimitations.limit,
                entity: $t('levels.header_title')
              })
            }}
          </AppInfoMessage>

          <AppTableCreateButton v-else full-width icon-name="plus-next" @click="onCreateLevelClick">
            {{ $t('levels.title.create') }}
          </AppTableCreateButton>
        </template>
      </TableSortable>
    </div>

    <SettingsPagesEmptyState
      v-if="isNoLevels"
      hero-height="128"
      hero-icon="no-levels-hero"
      hero-width="142"
    >
      <template #title> {{ $t('levels.no_levels') }} </template>
      <template #subtitle> {{ $t('levels.no_levels_subtitle') }} </template>

      <template #action>
        <AppButton
          height="24"
          icon="plus-next"
          type="primary-next"
          width="24"
          @click="onCreateLevelClick"
        >
          {{ $t('levels.title.create') }}
        </AppButton>
      </template>
    </SettingsPagesEmptyState>

    <ManageLevelModal
      :level="levelToEdit"
      :opened="showManageLevelModal"
      @on-close="onManageLevelModalClose"
    />
    <LevelModal
      v-model:show="showLevelModal"
      :model-value="levelToEdit"
      @create="onLevelCreated"
      @edit="onLevelUpdated"
      @update:show="!$event ? (levelToEdit = null) : undefined"
    />
    <LevelDeleteModal
      :level-to-delete="levelToDelete"
      :show="showDeleteLevelModal"
      data-auto-testid="delete-level-modal"
      data-testid="delete-level-modal"
      @close="hideDeleteLevelModal"
      @confirm="removeLevel"
    />
    <!--       :levels="levels" -->
  </div>
</template>

<script>
import { isEmpty } from 'lodash'
import { defineComponent } from 'vue'
import { mapActions } from 'vuex'

import LevelApiHandler from '@/api/level'
import { tracker } from '@/tracking/amplitude'
import { EVENT_CATEGORIES } from '@/tracking/amplitude-helpers'
import { INFO_MESSAGE_TYPES } from '@/utils/components-configurations/app-info-message'
import { DROPDOWN_MENU_TYPES } from '@/utils/components-configurations/dropdown-menu'
import { HEADER_SIZES } from '@/utils/components-configurations/page-content-header'
import { MENU_ITEMS_GROUPS } from '@/utils/dropdown-menu'
import { OKR_LEVEL_ENTITY_KEYS } from '@/utils/entity-keys'
import { handleError } from '@/utils/error-handling'
import { levelToOkrIconElement, OKR_ELEMENTS_TABLE_ACTIONS_MENU_ITEMS } from '@/utils/objectives'
import { TABLE_COLUMNS_KEYS } from '@/utils/table-columns'
import { usePlansLimitations } from '@/utils/web-app/plans-limitations'

import OkrIcon from '@/components/objectives/items/OkrIcon'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppInfoMessage from '@/components/ui/AppInfoMessage'
import AppTableCreateButton from '@/components/ui/AppTableCreateButton'
import DropdownMenu from '@/components/ui/DropdownMenu/DropdownMenu'
import PageContentHeader from '@/components/ui/PageContentHeader/PageContentHeader'
import SettingsPagesEmptyState from '@/components/ui/SettingsPagesEmptyState/SettingsPagesEmptyState'
import LevelsTableLoader from '@/components/ui/SkeletonLoaders/LevelsTableLoader'
import TableSortable from '@/components/ui/Table/TableSortable/TableSortable'
import WorkspacesListCell from '@/components/ui/WorksapcesListCell/WorkspacesListCell'
import LevelDeleteModal from '@/components/workspaces/LevelDeleteModal'
import LevelModal from '@/components/workspaces/LevelModal'
import ManageLevelModal from '@/components/workspaces/ManageLevelModal'

const levelApi = new LevelApiHandler()

const { REMOVE, EDIT } = OKR_ELEMENTS_TABLE_ACTIONS_MENU_ITEMS

const DROPDOWN_MENU_ACTIONS = [
  {
    name: EDIT.name,
    title: 'action.edit',
    icon: 'edit-next',
    group: MENU_ITEMS_GROUPS.EDITING
  },
  {
    name: REMOVE.name,
    title: 'action.delete',
    icon: 'delete-next',
    color: 'var(--grade-low-color-next)',
    group: MENU_ITEMS_GROUPS.REMOVING
  }
]

export default defineComponent({
  name: 'LevelsPage',

  components: {
    ManageLevelModal,
    WorkspacesListCell,
    AppInfoMessage,
    SettingsPagesEmptyState,
    AppTableCreateButton,
    DropdownMenu,
    LevelsTableLoader,
    AppButton,
    TableSortable,
    PageContentHeader,
    OkrIcon,
    LevelModal,
    LevelDeleteModal
  },

  setup() {
    const { okrLevelsLimitations } = usePlansLimitations()

    return {
      okrLevelsLimitations
    }
  },

  data() {
    return {
      loading: false,
      tableColumns: [
        {
          title: 'levels.name',
          key: TABLE_COLUMNS_KEYS.NAME,
          width: 'auto'
        },
        {
          title: 'workspaces.workspaces',
          key: TABLE_COLUMNS_KEYS.WORKSPACE,
          width: 124
        },
        {
          key: TABLE_COLUMNS_KEYS.ACTIONS,
          width: 24
        }
      ],

      tableHoverRow: -1,
      levels: [],
      showLevelModal: false,
      showManageLevelModal: false,
      showDeleteLevelModal: false,
      levelToEdit: null,
      levelToDelete: null
    }
  },

  computed: {
    DROPDOWN_MENU_TYPES: () => DROPDOWN_MENU_TYPES,

    HEADER_SIZES: () => HEADER_SIZES,

    OKR_LEVEL_ENTITY_KEYS: () => OKR_LEVEL_ENTITY_KEYS,

    INFO_MESSAGE_TYPES: () => INFO_MESSAGE_TYPES,

    TABLE_COLUMNS_KEYS: () => TABLE_COLUMNS_KEYS,

    DROPDOWN_MENU_ACTIONS: () => DROPDOWN_MENU_ACTIONS,

    isNoLevels() {
      return isEmpty(this.levels) && !this.loading
    },

    isOkrLevelsLimitExceeded() {
      return this.okrLevelsLimitations.isLimitExceeded({
        count: this.levels.length
      })
    }
  },

  mounted() {
    this.getLevels()
  },

  methods: {
    ...mapActions('objectives', {
      setLevels: 'setLevels'
    }),

    levelToOkrIconElement,

    onMenuActionsClick(name, item) {
      if (name === EDIT.name) {
        this.onEditLevel(item)
      } else if (name === REMOVE.name) {
        this.onDeleteLevel(item)

        tracker.logEvent('Started level removal', {
          category: EVENT_CATEGORIES.LEVEL_MANAGEMENT,
          label: item.name
        })
      }
    },

    onEditLevel(level) {
      this.levelToEdit = level
      this.showManageLevelModal = true
    },

    onDeleteLevel(level) {
      this.levelToDelete = level
      this.showDeleteLevelModal = true
    },

    onLevelCreated() {
      this.showLevelModal = false
      this.updateSystemLevels()
    },

    onLevelUpdated() {
      this.showLevelModal = false
      this.showManageLevelModal = false
      this.updateSystemLevels()
    },

    onManageLevelModalClose({ reloadLevels = true } = {}) {
      this.showManageLevelModal = false
      this.showLevelModal = false
      this.levelToEdit = null
      if (reloadLevels) {
        this.updateSystemLevels()
      }
    },

    async getLevels() {
      this.loading = true
      try {
        this.levels = await levelApi.getLevels({
          workspaceIds: null,
          levelIds: null
        })
      } catch (error) {
        handleError({ error })
      } finally {
        this.loading = false
      }
    },

    async updateSystemLevels() {
      this.levels = []
      await this.getLevels()
      this.setLevels({
        levels: this.levels,
        updateFullLevelsList: true
      })
    },

    async removeLevel() {
      try {
        await levelApi.removeLevel({
          levelId: this.levelToDelete.id
        })

        tracker.logEvent('Level removed', {
          category: EVENT_CATEGORIES.LEVEL_MANAGEMENT,
          label: this.levelToDelete.name
        })

        this.hideDeleteLevelModal()
        this.updateSystemLevels()
      } catch (error) {
        handleError({ error })

        tracker.logEvent('Level removed failed', {
          category: EVENT_CATEGORIES.LEVEL_MANAGEMENT,
          label: this.levelToDelete.name
        })
      }
    },

    // firstItems(item) {
    //   return item.workspaces
    //     .slice(0, 2)
    //     .map(i => i.name)
    //     .join(', ')
    // },
    //
    // lastItems(item) {
    //   return item.workspaces
    //     .slice(2)
    //     .map(i => i.name)
    //     .join(', ')
    // },
    //
    // lastItemsCount(item) {
    //   return item.workspaces.slice(2).length
    // },

    onCreateLevelClick() {
      if (!this.isOkrLevelsLimitExceeded) {
        this.showLevelModal = true
      }
    },

    hideDeleteLevelModal() {
      this.showDeleteLevelModal = false
      this.levelToDelete = null
    },

    async onTableRowDropped({ item, orderValue }) {
      await levelApi.updateLevel({
        ...item,
        levelId: item.id,
        orderValue,
        workspaceIds: null
      })
      this.updateSystemLevels()
    }
  }
})
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/app-table-helpers';

.lp-LevelPage {
  padding-bottom: $page-bottom-padding;
}

.lp-MainContent {
  width: 100%;
  max-width: $page-width-lg;
  padding-left: $page-left-padding;
  padding-right: $page-right-padding;
  font-family: $system-ui;
  margin-top: 7px;
}

.lp-NameRow {
  display: flex;
  align-items: center;
}

.lp-Name {
  color: $primary-color-next;
  margin: 0 8px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: $fs-14;
  line-height: 20px;
  font-weight: fw('semi-bold');
}

// .lp-LastItemsCount {
//   cursor: pointer;
// }

.lp-Actions {
  @extend %sortable-table-hidden-items;
}

.lp-Table {
  --head-padding-top: 16px;
}

.lp-Table {
  --head-padding-top: 16px;
}

.lp-Message {
  margin-top: 10px;
  padding-left: $page-left-padding;
}
</style>
