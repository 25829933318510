import { has, isObject, uniq, uniqBy } from 'lodash'
import { computed, inject } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

import { getEmailsListFromString, isEmailString } from '@/utils/email-regex'
import { ASSIGNEE_ENTITY_KEYS, REQUEST_ENTITY_KEYS } from '@/utils/entity-keys'
import { isSalesforceAppInjectionKey, isWebAppInjectionKey } from '@/utils/injection-keys'
import { findGraterRole } from '@/utils/objectives'
import { PLUGIN_OPTIONS_KEYS } from '@root/template-options-keys'

export const USER_IDENTIFIER_VALID_KEY = 'userIdentifierValid'

export const IDENTIFIER_TYPE_IDS = {
  EMAIL: 2,
  ACCOUNT_ID: 1
}
export const createPayloadForAccountIdsAndEmails = ({ selectedItems = [] } = {}) => {
  const isArrayOfUserObjects = selectedItems.every(
    item => isObject(item) && has(item, REQUEST_ENTITY_KEYS.IDENTIFIER)
  )

  if (isArrayOfUserObjects) {
    return selectedItems.reduce(
      (acc, user) => {
        const { identifier } = user

        const isEmail = isEmailString({ string: identifier })

        if (isEmail) {
          acc.emails.push({
            ...user,
            identifierTypeId: IDENTIFIER_TYPE_IDS.EMAIL
          })
        } else {
          acc.accountIds.push({
            ...user,
            identifierTypeId: IDENTIFIER_TYPE_IDS.ACCOUNT_ID
          })
        }

        return acc
      },
      { emails: [], accountIds: [] }
    )
  }

  const emails = getEmailsListFromString(selectedItems.join(','))
  const accountIds = selectedItems.filter(item => !emails.includes(item))

  return {
    emails,
    accountIds
  }
}

export const getUserIdentifierTypeId = ({ identifier = '' } = {}) => {
  const isEmail = isEmailString({ string: identifier })

  return isEmail ? IDENTIFIER_TYPE_IDS.EMAIL : IDENTIFIER_TYPE_IDS.ACCOUNT_ID
}
export const enrichPayloadWithUserIdentifier = ({ payload = [] } = {}) => {
  return payload.map(identifier => {
    const identifierTypeId = getUserIdentifierTypeId({ identifier })

    return {
      [REQUEST_ENTITY_KEYS.IDENTIFIER]: identifier,
      identifierTypeId
    }
  })
}

export const useEmailsInSelect = () => {
  const isWebApp = inject(isWebAppInjectionKey)
  const isSalesforceApp = inject(isSalesforceAppInjectionKey)
  const store = useStore()
  const { t } = useI18n()
  const isPluginAdmin = computed(
    () => store.state.pluginOptions[PLUGIN_OPTIONS_KEYS.IS_PLUGIN_ADMIN]
  )
  // const isJiraConnected = computed(() => store.state.pluginOptions.jiraConnected)

  const allowAddEmailsFromSelect = computed(() => {
    return (isWebApp || isSalesforceApp) && isPluginAdmin.value
  })

  const userSelectLabelWithoutTranslation = computed(() => {
    return allowAddEmailsFromSelect.value ? 'action.select_user_or_email' : 'action.select_user'
  })

  const userSelectLabel = computed(() => {
    return t(userSelectLabelWithoutTranslation.value)
  })

  return {
    allowAddEmailsFromSelect,
    userSelectLabelWithoutTranslation,
    userSelectLabel
  }
}

const getUniqEmails = emails => uniqBy(emails, REQUEST_ENTITY_KEYS.IDENTIFIER)

export const unifyEmailsPayloadForGroup = ({ emails = [] } = {}) => {
  const uniqEmails = getUniqEmails(emails)

  return uniqEmails.map(email => {
    const itemsByEmail = emails.filter(
      item => item[REQUEST_ENTITY_KEYS.IDENTIFIER] === email[REQUEST_ENTITY_KEYS.IDENTIFIER]
    )
    return {
      ...email,
      [REQUEST_ENTITY_KEYS.GROUP_IDS]: uniq(
        itemsByEmail.flatMap(item => item[REQUEST_ENTITY_KEYS.GROUP_IDS])
      )
    }
  })
}

export const unifyEmailsPayloadForWorkspace = ({ emails = [] } = {}) => {
  const uniqEmails = getUniqEmails(emails)

  return uniqEmails.map(email => {
    const itemsByEmail = emails.filter(item => item[REQUEST_ENTITY_KEYS.IDENTIFIER] === email)

    const allRoles = itemsByEmail.flatMap(item => item[ASSIGNEE_ENTITY_KEYS.ROLE_ID])

    return {
      [REQUEST_ENTITY_KEYS.IDENTIFIER]: email,
      [ASSIGNEE_ENTITY_KEYS.ROLE_ID]: findGraterRole({ roles: allRoles })
    }
  })
}
