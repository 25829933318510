<template>
  <ObjectiveTree
    ref="tree"
    offset-left="var(--page-left-padding)"
    offset-right="var(--page-right-padding)"
    @reset-search-criteria="$emit('reset-search-criteria', $event)"
    @update-okr-data="$emit('update-data', $event)"
    @load-more="$emit('load-more', $event)"
  />
</template>

<script>
import { defineComponent } from 'vue'

import { clearMindmapCache } from '@/utils/memoizations'

import ObjectiveTree from '@/components/objectives/tree/ObjectiveTree'

export default defineComponent({
  name: 'OkrElementsTreePage',

  components: { ObjectiveTree },
  emits: { 'reset-search-criteria': null, 'update-data': null, 'load-more': null },

  beforeUnmount() {
    clearMindmapCache()
  },

  methods: {
    /** @public */
    requestData() {
      this.$refs.tree.requestData()
    }
  }
})
</script>

<style></style>
