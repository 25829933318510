<template>
  <div class="cftl-Loader">
    <div v-for="row in ROWS" :key="row.id" class="cftl-Loader_Row">
      <div class="cftl-Cell">
        <SkeletonItem :appearance="SQUARE" :size="SM" border-radius="6px" />
        <SkeletonItem :size="XS" :width="row.name" />
      </div>

      <div class="cftl-Cell">
        <SkeletonItem
          v-for="n in row.for"
          :key="n"
          :appearance="SQUARE"
          :size="SM"
          border-radius="6px"
        />
      </div>

      <div class="cftl-Cell">
        <SkeletonItem
          v-for="n in row.ws"
          :key="n"
          :appearance="SQUARE"
          :size="SM"
          border-radius="6px"
        />
      </div>

      <!--      <div class="cftl-Cell">
        <SkeletonItem v-if="row.required" :appearance="CIRCLE" height="16px" width="16px" />
      </div>-->

      <div class="cftl-Cell">
        <SkeletonItem
          :color="row.active ? 'var(--grade-high-color-next)' : 'var(--dark-3)'"
          :width="row.active ? '49px' : '63px'"
          border-radius="6px"
          height="24px"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CustomFieldsTableLoader'
})
</script>
<script setup>
import {
  SKELETON_APPEARANCES,
  SKELETON_SIZES
} from '@/utils/components-configurations/skeleton-item'

import SkeletonItem from '@/components/ui/SkeletonLoaders/SkeletonItem'

const { SQUARE } = SKELETON_APPEARANCES
const { XS, SM } = SKELETON_SIZES

const ROWS = [
  {
    id: 0,
    name: '188px',
    for: 1,
    ws: 4,
    required: true,
    active: true
  },
  {
    id: 1,
    name: '150px',
    for: 3,
    ws: 1,
    active: true
  },
  {
    id: 2,
    name: '127px',
    for: 1,
    ws: 1,
    required: true
  },
  {
    id: 3,
    name: '152px',
    for: 2,
    ws: 0,
    required: true
  },
  {
    id: 4,
    name: '125px',
    for: 3,
    ws: 2,
    active: true
  }
]
</script>

<style lang="scss" scoped>
.cftl-Loader {
  padding: 0 $page-right-padding 0 $page-left-padding;
}

.cftl-Loader_Row {
  display: flex;
  align-items: center;
  height: 44px;
}

.cftl-Cell {
  display: flex;
  align-items: center;

  &:nth-child(1) {
    gap: 8px;
    width: auto;
    flex: 1 1 100%;
  }

  &:nth-child(2) {
    width: 124px;
    flex: 0 0 124px;
  }

  &:nth-child(3) {
    width: 124px; // 108 when required field will return
    flex: 0 0 124px; // 108 when required field will return
  }

  &:nth-child(2),
  &:nth-child(3) {
    gap: 4px;
  }

  &:nth-child(4) {
    width: 143px;
    flex: 0 0 143px;
    opacity: 0.3;
  }

  /*  &:nth-child(4) {
    width: 135px;
    flex: 0 0 135px;
    justify-content: center;
  }

  &:nth-child(5) {
    width: 143px;
    flex: 0 0 143px;
    opacity: 0.3;
  }*/
}
</style>
