import { nextTick } from 'vue'

export const scrollSmooth = async elemRef => {
  await nextTick(() => {
    elemRef.value.scrollTo({
      top: elemRef.value.scrollHeight,
      behavior: 'smooth'
    })
  })
}
