<template>
  <AppModalWithConfirmation
    ref="appModalWithConfirmationReference"
    :confirm-close="areDataChanged"
    :show="show"
    :title="$t('global_groups.merge_groups')"
    hide-hero
    @on-close="closeModal"
  >
    <div class="mggm-ModalBody">
      <FormFieldNext :label="$t('group.label.name')">
        <AppInput
          ref="nameReference"
          v-model.trim="formModel[NAME]"
          :loading="isLoading"
          :max-length="GLOBAL_GROUP_NAME_MAX_LENGTH"
          data-testid="group-name-input"
          size="xlg"
          style-type="primary"
        />
      </FormFieldNext>

      <FormFieldNext :label="$t('workspace.label.description')">
        <AppTextarea
          v-model.trim="formModel[DESCRIPTION]"
          :max-length="250"
          :placeholder="$t('workspace.label.add_description')"
          :rows="1"
          data-testid="group-description-input"
          style="--input-padding: 8px"
        />
      </FormFieldNext>

      <AppIconCreator class="mggm-IconCreator" hide-result-label>
        <template #first-term>
          <GroupIconPicker
            v-model:selected-icon="formModel[ICON]"
            :loading="isLoading"
            :selected-color="formModel[COLOR]"
          />
        </template>

        <template #second-term>
          <AppPaletteColorPicker
            v-model:selected-color="formModel[COLOR]"
            :loading="isLoading"
            :palette="GROUPS_CATEGORIZED_COLORS"
            data-testid="palette-color-picker"
            style="--thumb-color: var(--dark-2)"
          />
        </template>

        <template #result>
          <GroupIcon :color="formModel[COLOR]" :icon-name="formModel[COLOR]" :loading="isLoading" />
        </template>
      </AppIconCreator>

      <FormFieldNext :label="$t('user.selected_groups')">
        <div class="mggm-GroupsList">
          <template v-if="isLoading">
            <SkeletonItem
              v-for="n in groupsForMerge.length"
              :key="n"
              :size="SKELETON_SIZES.SM"
              :width="getRandomWidth()"
            />
          </template>
          <template v-else>
            <GroupSelectLabel
              v-for="group in groupsData"
              :key="group[ID]"
              :group="group"
              class="mggm-GroupsList_Item"
              data-testid="selected-group-for-merge"
            />
          </template>
        </div>
      </FormFieldNext>

      <FormFieldNext
        v-if="!isEmpty(workspacesList) && !isLoading"
        :label="$t('workspaces.workspaces')"
      >
        <WorkspacesAffectMessage
          :limit="4"
          :workspaces="workspacesList"
          data-testid="affect-message"
        >
          {{ $t('global_groups.merge_affect') }}:
        </WorkspacesAffectMessage>
      </FormFieldNext>

      <FormFieldNext :label="$t('global_groups.select_parent')">
        <AppSelect
          v-model="formModel[PARENT_ID]"
          :hidden-items="groupsForMerge"
          :loading="isLoading"
          :offset="[0, -40]"
          :options="parentGroupsOptions"
          :search-function="
            $event => getParentGroups({ [REQUEST_ENTITY_KEYS.SEARCH_STRING]: $event })
          "
          class="mggm-ParentGroupSelect"
          data-testid="parent-group-select"
          dropdown-search
          item-label="name"
          item-value="id"
          skeleton-loader
          skeleton-loader-height="100%"
          skeleton-loader-width="100%"
          @update:options="parentGroups = $event"
        >
          <template #button-content="{ option }">
            <GlobalGroupsSelectOption
              v-if="option"
              :group="option"
              :is-not-set="option[ID] === NO_PARENT_GROUP_ID"
              :show-breadcrumbs-tooltip="false"
            />
          </template>
          <template #option-label="{ option }">
            <GlobalGroupsSelectOption
              v-if="option"
              :group="option"
              :is-not-set="option[ID] === NO_PARENT_GROUP_ID"
            />
          </template>
        </AppSelect>
      </FormFieldNext>
    </div>

    <template #footer-actions>
      <AppButton type="ghost-next" @click="close">
        {{ $t('action.cancel') }}
      </AppButton>
      <AppButton
        :disable="isLoading || isNameEmpty"
        :loading="isMerging || isLoading"
        data-testid="submit-button"
        type="primary-next"
        @click="save"
      >
        {{ $t('action.merge') }}
      </AppButton>
    </template>
  </AppModalWithConfirmation>
</template>

<script setup>
import {
  cloneDeep,
  isBoolean,
  isEmpty,
  isEqual,
  isNumber,
  isObject,
  isString,
  random,
  uniq
} from 'lodash'
import { computed, ref, watch } from 'vue'

import GlobalGroupsApiHandler from '@/api/global-groups'
import { SKELETON_SIZES } from '@/utils/components-configurations/skeleton-item'
import { GROUP_ENTITY_KEYS, REQUEST_ENTITY_KEYS } from '@/utils/entity-keys'
import { handleError } from '@/utils/error-handling'
import { isStringEmpty } from '@/utils/general'
import {
  GLOBAL_GROUP_NAME_MAX_LENGTH,
  GROUPS_CATEGORIZED_COLORS,
  NO_PARENT_GROUP_ID
} from '@/utils/global-groups'

import AppModalWithConfirmation from '@/components/AppModalWithConfirmation'
import FormFieldNext from '@/components/form/FormFieldNext'
import GlobalGroupsSelectOption from '@/components/global-groups/GlobalGroupsSelectOption'
import GroupIcon from '@/components/global-groups/GroupIcon'
import GroupIconPicker from '@/components/global-groups/GroupIconPicker'
import GroupSelectLabel from '@/components/global-groups/GroupSelectLabel'
import WorkspacesAffectMessage from '@/components/global-groups/WorkspacesAffectMessage'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppIconCreator from '@/components/ui/AppIconCreator/AppIconCreator'
import AppInput from '@/components/ui/AppInput/AppInput'
import AppPaletteColorPicker from '@/components/ui/AppPaletteColorPIcker/AppPaletteColorPicker'
import AppSelect from '@/components/ui/AppSelect/AppSelect'
import AppTextarea from '@/components/ui/AppTextarea/AppTextarea'
import SkeletonItem from '@/components/ui/SkeletonLoaders/SkeletonItem'

defineOptions({
  name: 'MergeGlobalGroupsModal'
})

const { ID, NAME, ICON, COLOR, PARENT_ID, WORKSPACES, DESCRIPTION } = GROUP_ENTITY_KEYS

const props = defineProps({
  show: {
    type: Boolean,
    default: false
  },

  groupsForMerge: {
    type: Array,
    default: () => [],
    validator: v => {
      let isValid = false

      if (Array.isArray(v)) {
        if (isEmpty(v)) {
          isValid = true
        } else {
          isValid = uniq(v).length >= 1 && v.every(id => isNumber(id) || isString(id))
        }
      }

      return isValid
    }
  }
})

const closeModal = () => {
  emit('update:show', false)
}

const appModalWithConfirmationReference = ref(null)

const close = () => {
  appModalWithConfirmationReference.value.close()
}

const emit = defineEmits({
  'update:show': value => isBoolean(value),
  'on-group-merged': value => isObject(value) && !isEmpty(value)
})

const areDataChanged = computed(() => {
  return !isEqual(formModel.value, initialFormModel.value)
})

const groupsData = ref([])
const parentGroups = ref([])

const DEFAULT_FORM_MODEL = {
  [NAME]: '',
  [COLOR]: GROUPS_CATEGORIZED_COLORS.VIOLET[0],
  [ICON]: '1',
  [PARENT_ID]: NO_PARENT_GROUP_ID,
  [DESCRIPTION]: ''
}

const formModel = ref(cloneDeep(DEFAULT_FORM_MODEL))
const initialFormModel = ref(cloneDeep(DEFAULT_FORM_MODEL))

const isLoading = ref(false)

const getRandomWidth = () => `${random(50, 170)}px`
const nameReference = ref(null)

const getParentGroups = async ({ searchString = null } = {}) => {
  const api = new GlobalGroupsApiHandler()

  try {
    return await api.getParentGroups({
      [REQUEST_ENTITY_KEYS.GROUP_ID]: null,
      [REQUEST_ENTITY_KEYS.PARENT_GROUP_ID]: null,
      searchString
    })
  } catch (error) {
    handleError({ error })
  }
}

const parentGroupsOptions = computed(() => {
  return [
    {
      [ID]: NO_PARENT_GROUP_ID
    },
    ...parentGroups.value
  ]
})

const getGroupsData = async () => {
  const api = new GlobalGroupsApiHandler()

  try {
    groupsData.value = await api.getGlobalGroupsByIds({
      [REQUEST_ENTITY_KEYS.GROUP_IDS]: props.groupsForMerge
    })

    setupFormModel()
    nameReference.value.focus()
  } catch (error) {
    handleError({ error })
  }
}

const getInitialData = async () => {
  isLoading.value = true

  const setParentGroups = async () => {
    parentGroups.value = await getParentGroups()
  }

  try {
    await Promise.all([setParentGroups(), getGroupsData()])
  } catch (error) {
    handleError({ error })
  } finally {
    isLoading.value = false
  }
}

const getRandomIndex = (min = 0, max) => {
  return random(min, max)
}

const setupFormModel = () => {
  const ICONS = 'icons'
  const COLORS = 'colors'
  const NAMES = 'names'
  const DESCRIPTIONS = 'descriptions'

  const groupsProperties = groupsData.value.reduce(
    (acc, group) => {
      return {
        [ICONS]: uniq([...acc[ICONS], group[ICON]]),
        [COLORS]: uniq([...acc[COLORS], group[COLOR]]),
        [NAMES]: uniq([...acc[NAMES], group[NAME]]),
        [DESCRIPTIONS]: uniq([...acc[DESCRIPTIONS], group[DESCRIPTION] || ''])
      }
    },
    { [ICONS]: [], [COLORS]: [], [NAMES]: [], [DESCRIPTIONS]: [] }
  )

  const randomIconIndex = getRandomIndex(groupsProperties[ICONS].length - 1)
  const randomColorIndex = getRandomIndex(groupsProperties[COLORS].length - 1)
  const randomNameIndex = getRandomIndex(groupsProperties[NAMES].length - 1)
  const randomDescriptionIndex = getRandomIndex(groupsProperties[DESCRIPTIONS].length - 1)

  formModel.value = {
    ...formModel.value,
    [ICON]: `${groupsProperties[ICONS][randomIconIndex]}`,
    [COLOR]: groupsProperties[COLORS][randomColorIndex],
    [NAME]: groupsProperties[NAMES][randomNameIndex],
    [DESCRIPTION]: groupsProperties[DESCRIPTIONS][randomDescriptionIndex]
  }

  initialFormModel.value = cloneDeep(formModel.value)
}

const workspacesList = computed(() => {
  return groupsData.value.reduce((acc, group) => {
    return [...acc, ...group[WORKSPACES]]
  }, [])
})

const isMerging = ref(false)
const save = async () => {
  isMerging.value = true

  const api = new GlobalGroupsApiHandler()

  try {
    const newGroup = await api.mergeGlobalGroups({
      [REQUEST_ENTITY_KEYS.GROUP_IDS]: props.groupsForMerge,
      ...formModel.value
    })

    emit('on-group-merged', newGroup)
  } catch (error) {
    handleError({ error })
  } finally {
    isMerging.value = false
  }
}

const isNameEmpty = computed(() => isStringEmpty(formModel.value[NAME]))

watch(
  () => props.show,
  async value => {
    if (!value) {
      formModel.value = cloneDeep(DEFAULT_FORM_MODEL)
      initialFormModel.value = cloneDeep(DEFAULT_FORM_MODEL)
      groupsData.value = []
      parentGroups.value = []
      closeModal()
      return
    }

    if (value) {
      await getInitialData()
    }
  },
  { immediate: true }
)
</script>

<style lang="scss" scoped>
.mggm-IconCreator {
  --select-width: 148px;
}

.mggm-ModalBody {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.mggm-GroupsList {
  min-height: 24px;

  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  align-items: center;
}

.mggm-GroupsList_Item {
  max-width: 100%;
  border-radius: $border-radius-sm-next;
}

.mggm-ParentGroupSelect {
  --select-skeleton-top: 0;
  --select-skeleton-left: 0;
}
</style>
