<template>
  <div
    :class="{
      'avs-Gradient-top': !isBottomPlacement,
      'avs-Gradient-bottom': isBottomPlacement,
      'avs-Gradient-arrived-top': arrivedState.top,
      'avs-Gradient-arrived-bottom': arrivedState.bottom,
      'avs-Gradient-no-border': noBorder
    }"
    class="avs-Gradient"
  />
</template>

<script setup>
import { has } from 'lodash'

defineOptions({
  name: 'AppVerticalScrollGradient'
})

defineProps({
  arrivedState: {
    type: Object,
    default: () => ({
      top: false,
      bottom: false
    }),
    validator: v => has(v, 'top') && has(v, 'bottom')
  },

  isBottomPlacement: {
    type: Boolean
  },

  noBorder: {
    type: Boolean
  }
})
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/app-menu';

$border-style: 1px solid $grey-2-next;

.avs-Gradient {
  pointer-events: none;
  display: block;
  min-height: 16px;
  position: sticky;
  --color: var(--gradient-color, #{$white});

  &:after {
    top: inherit;
    bottom: inherit;
    transition: 0.5s cubic-bezier(0.2, 0, 0, 1) 0s;
    pointer-events: none;
    content: '';
    min-height: var(--height, #{$scroll-gradient-height});
    width: 100%;
    left: 0;
    position: absolute;
    display: block;
  }

  &-bottom {
    bottom: 0;

    &:after {
      border-bottom: $border-style;
      background: linear-gradient(0deg, var(--color) 0%, transparent 100%) no-repeat;
    }
  }

  &-top {
    top: 0;

    &:after {
      border-top: $border-style;
      background: linear-gradient(0deg, transparent 0%, var(--color) 100%) no-repeat;
    }
  }

  // eslint-disable-next-line vue-scoped-css/no-unused-selector
  &-bottom#{&}-arrived-bottom {
    &:after {
      background-position-y: var(--height, #{$scroll-gradient-height});
    }
  }

  // eslint-disable-next-line vue-scoped-css/no-unused-selector
  &-top#{&}-arrived-top {
    &:after {
      background-position-y: var(--height, #{- $scroll-gradient-height});
    }
  }

  &-no-border {
    &:after {
      border: none;
    }
  }
}
</style>
