<template>
  <OkrFilterSelect
    :bottom-fixed-items="intervalFixedItems"
    :hidden-items="hiddenIntervals"
    :loading="intervalsAreLoading"
    :max-height="229"
    :model-value="selectedIntervals"
    :options="intervals"
    :prepend-icon="isTimeRangeSelected ? 'calendar-range' : 'calendar-next'"
    :search="false"
    highlight-option
    n-selected-label="field.quoter.title"
    @hide="updateShowIntervalCustomRangePicker(false)"
    @open="emit('open')"
    @update:model-value="onUpdateIntervals"
  >
    <template v-if="isTimeRangeSelected" #button-content>
      {{ displayedLockedDatesOption.full }}
    </template>
    <template #option="option">
      <IntervalSelectOption v-bind="option" />
    </template>
    <template #bottom-fixed-items="{ bottomFixedItems }">
      <template v-for="item in bottomFixedItems" :key="item.id">
        <AppLink v-if="item.path" :to="item.path">
          <template #default="{ href, onClick }">
            <AppLinkResolver
              :href="href"
              class="isw-BottomFixedItem_Interval"
              @click.prevent="
                onBottomFixedItemClick({
                  handler: onClick,
                  item
                })
              "
            >
              <BottomFixedSelectItem icon-name="edit-next">
                <span class="isw-BottomFixedItem_Interval">{{ $t(item.text) }}</span>
              </BottomFixedSelectItem>
            </AppLinkResolver>
          </template>
        </AppLink>

        <AppDroplist
          v-if="item.isRange"
          v-model="intervalCustomRangePicker"
          :offset="[0, 0]"
          append-to="parent"
          max-width="unset"
          position="bottom-start"
          @open="onActualizeSelectedCustomRangeMode"
        >
          <template #button>
            <AppButton
              :class="{
                'isw-RangeWrapper_Button-range-selected': isTimeRangeSelected
              }"
              :icon="isTimeRangeSelected ? 'calendar-range-full-fill' : 'calendar-range'"
              class="isw-RangeWrapper_Button"
              data-testid="custom-range-picker-trigger"
              height="24"
              type="tertiary-next"
              width="24"
            >
              <div class="isw-RangeButton_Label">
                {{ $t(item.text) }}

                <template v-if="isTimeRangeSelected">
                  {{ displayedLockedDatesOption.keyWord }}
                  <br />
                  {{ displayedLockedDatesOption.customRangePickerTrigger.start }}
                  <br
                    v-if="
                      displayedLockedDatesOption.customRangePickerTrigger.start &&
                      displayedLockedDatesOption.customRangePickerTrigger.end
                    "
                  />
                  {{ displayedLockedDatesOption.customRangePickerTrigger.end }}
                </template>
              </div>
            </AppButton>
          </template>

          <div class="isw-RangePicker">
            <div class="isw-RangePicker_Nav">
              <AppRadioGroup
                :model-value="selectedIntervalCustomRangeMode"
                :options="customRangeModeOptions"
                data-testid="custom-range-mode-switch"
                name="customRangeSwitch"
                type="tab-like"
                @update:model-value="updateSelectedIntervalCustomRangeMode"
              />

              <AppButton class="isw-RangePickerNav_Btn" type="tertiary-next" @click="setToday">
                {{ $t('filter.today') }}
              </AppButton>
            </div>

            <div class="isw-RangeWrapper">
              <DatePicker
                :append-to-body="false"
                :class="{ 'dp-full-width': !isBetweenModeSelected }"
                :lang="datepickerLang()"
                :model-value="
                  isBetweenModeSelected
                    ? intervalCustomRange
                    : intervalCustomDate[selectedIntervalCustomRangeMode]
                "
                :range="isBetweenModeSelected"
                data-testid="custom-range-calendar"
                inline
                open
                type="date"
                @update:model-value="onUpdateCustomRange"
              />
            </div>
          </div>
        </AppDroplist>
      </template>
    </template>
  </OkrFilterSelect>
</template>

<script setup>
import dayjs from 'dayjs'
import { cloneDeep, isEmpty, isEqual, isNull } from 'lodash'
import { computed, nextTick, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import DatePicker from 'vue2-datepicker'

import {
  compensateTimeZone,
  datepickerLang,
  getEndOfTheDay,
  getStartOfTheDay,
  localDateToUtc,
  UNSELECTED_DATE
} from '@/utils/date'
import {
  actualizeSelectedCustomRangeMode,
  DEFAULT_DATES_RANGE,
  DEFAULT_INTERVAL_CUSTOM_DATE_OBJECT,
  displayedLockedDates
} from '@/utils/interval'
import {
  FILTER_DEFAULT_VALUES,
  FILTERS_KEYS,
  INTERVAL_TIME_RANGE_OPTIONS,
  isValidDatesArray,
  sendIntervalFilterTimeRangeTrackerEvent
} from '@/utils/okr-elements/filters'

import AppDroplist from '@/components/AppDroplist'
import BottomFixedSelectItem from '@/components/objectives/toolbar/BottomFixedSelectItem'
import IntervalSelectOption from '@/components/objectives/toolbar/IntervalSelectOption'
import OkrFilterSelect from '@/components/objectives/toolbar/OkrFilterSelect'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppLink from '@/components/ui/AppLink/AppLink'
import AppLinkResolver from '@/components/ui/AppLink/AppLinkResolver'
import AppRadioGroup from '@/components/ui/AppRadioGroup/AppRadioGroup'

const props = defineProps({
  hiddenIntervals: {
    type: Array,
    default: () => []
  },

  selectedDates: {
    type: Object,
    required: true
  },

  intervalFixedItems: {
    type: Array,
    default: () => []
  },

  intervalsAreLoading: {
    type: Boolean
  },

  selectedIntervals: {
    type: Array,
    required: true
  },

  intervals: {
    type: Array,
    required: true
  },

  showIntervalCustomRangePicker: {
    type: Boolean
  },

  selectedIntervalCustomRangeMode: {
    type: String,
    required: true
  },

  intervalCustomDate: {
    type: Object,
    required: true
  },

  intervalCustomRange: {
    type: Array,
    required: true
  },

  isBetweenModeSelected: {
    type: Boolean
  },

  isTimeRangeSelected: {
    type: Boolean
  },

  customRangeOptionsBasedOnFilters: {
    type: Object,
    required: true
  },

  trackerEventSource: {
    type: String,
    default: ''
  }
})
const { BEFORE, SINCE } = INTERVAL_TIME_RANGE_OPTIONS

const emit = defineEmits([
  'update:show-interval-custom-range-picker',
  'on-bottom-fixed-item-click',
  'update:selected-interval-custom-range-mode',
  'update:selected-dates',
  'update:interval-custom-range',
  'update:interval-custom-date',
  'update:selected-intervals',
  'select-intervals',
  'open'
])

const updateShowIntervalCustomRangePicker = payload => {
  emit('update:show-interval-custom-range-picker', payload)
}

const intervalCustomRangePicker = computed({
  get() {
    return props.showIntervalCustomRangePicker
  },
  set(payload) {
    updateShowIntervalCustomRangePicker(payload)
  }
})

const onBottomFixedItemClick = payload => {
  emit('on-bottom-fixed-item-click', payload)
}

const updateIntervalCustomRange = payload => {
  emit('update:interval-custom-range', payload)
}

const onActualizeSelectedCustomRangeMode = () => {
  const modeBySelectedDates = actualizeSelectedCustomRangeMode({
    customRangeOptionsBasedOnFilters: props.customRangeOptionsBasedOnFilters,
    selectedIntervalCustomRangeMode: props.selectedIntervalCustomRangeMode
  })
  if (modeBySelectedDates) {
    updateSelectedIntervalCustomRangeMode(modeBySelectedDates)
  }
}
const updateSelectedIntervalCustomRangeMode = payload => {
  emit('update:selected-interval-custom-range-mode', payload)
}

const setToday = () => {
  const todayDate = dayjs().toDate()
  const resolvedResult = props.isBetweenModeSelected ? [todayDate, todayDate] : todayDate

  onUpdateCustomRange(resolvedResult)
}

const updatedSelectedDates = payload => {
  emit('update:selected-dates', payload)
}
const updatedIntervalCustomDate = payload => {
  emit('update:interval-custom-date', payload)
}

const updateSelectedIntervals = payload => {
  emit('update:selected-intervals', payload)
}

const setCustomRangeFilter = ({ startDates, dueDates }) => {
  updatedSelectedDates({
    [FILTERS_KEYS.START_DATES]: startDates,
    [FILTERS_KEYS.DUE_DATES]: dueDates
  })
  updateSelectedIntervals(cloneDeep(FILTER_DEFAULT_VALUES[FILTERS_KEYS.INTERVAL_IDS]))

  updateShowIntervalCustomRangePicker(false)
}

const onUpdateCustomRange = selectedDates => {
  if (props.isBetweenModeSelected) {
    if (!isEmpty(selectedDates) && isValidDatesArray(selectedDates)) {
      updateIntervalCustomRange(cloneDeep(selectedDates))
      const [startDate, endDate] = selectedDates

      const startOfTheDate = getStartOfTheDay(startDate)
      const endOfTheDate = getEndOfTheDay(endDate)

      setCustomRangeFilter({
        [FILTERS_KEYS.START_DATES]: [localDateToUtc(startOfTheDate), UNSELECTED_DATE],
        [FILTERS_KEYS.DUE_DATES]: [UNSELECTED_DATE, localDateToUtc(endOfTheDate)]
      })

      updatedIntervalCustomDate(cloneDeep(DEFAULT_INTERVAL_CUSTOM_DATE_OBJECT))
    }
  } else {
    if (!isNull(selectedDates)) {
      updatedIntervalCustomDate({
        ...DEFAULT_INTERVAL_CUSTOM_DATE_OBJECT,
        [props.selectedIntervalCustomRangeMode]: selectedDates
      })

      const isBeforeModeSelected = props.selectedIntervalCustomRangeMode === BEFORE.value

      if (isBeforeModeSelected) {
        const endDate = getEndOfTheDay(selectedDates)

        setCustomRangeFilter({
          [FILTERS_KEYS.START_DATES]: [UNSELECTED_DATE, UNSELECTED_DATE],
          [FILTERS_KEYS.DUE_DATES]: [UNSELECTED_DATE, localDateToUtc(endDate)]
        })
      } else {
        const startDate = getStartOfTheDay(selectedDates)

        setCustomRangeFilter({
          [FILTERS_KEYS.START_DATES]: [localDateToUtc(startDate), UNSELECTED_DATE],
          [FILTERS_KEYS.DUE_DATES]: [UNSELECTED_DATE, UNSELECTED_DATE]
        })
        updateIntervalCustomRange(cloneDeep(DEFAULT_DATES_RANGE))
      }
    }
  }

  if (props.trackerEventSource) {
    nextTick(() => {
      sendIntervalFilterTimeRangeTrackerEvent({
        section: props.trackerEventSource,
        value: displayedLockedDatesOption.value.full
      })
    })
  }
}

const onUpdateIntervals = intervals => {
  emit('select-intervals', intervals)
  if (props.trackerEventSource) {
    sendIntervalFilterTimeRangeTrackerEvent({
      section: props.trackerEventSource,
      value: intervals
    })
  }
}
const { t } = useI18n()

const customRangeModeOptions = computed(() => {
  return Object.values(INTERVAL_TIME_RANGE_OPTIONS).map(({ value, label }) => ({
    value,
    label: t(label)
  }))
})

const displayedLockedDatesOption = computed(() => {
  return displayedLockedDates({
    selectedDates: props.selectedDates,
    customRangeOptionsBasedOnFilters: props.customRangeOptionsBasedOnFilters
  })
})

watch(
  () => props.isTimeRangeSelected,
  async newValue => {
    if (newValue) {
      const { startDates, dueDates } = props.selectedDates

      onActualizeSelectedCustomRangeMode()

      await nextTick()

      const { isBetweenRangeSelected, isBeforeRangeSelected, isSinceRangeSelected } =
        props.customRangeOptionsBasedOnFilters

      const [startDateFrom] = startDates
      const [, dueDateTo] = dueDates

      if (
        isBetweenRangeSelected &&
        !isEqual(props.intervalCustomRange, [startDateFrom, dueDateTo])
      ) {
        updateIntervalCustomRange([
          compensateTimeZone(startDateFrom),
          compensateTimeZone(dueDateTo)
        ])
      }

      if (isBeforeRangeSelected && !isEqual(props.intervalCustomDate[BEFORE.value], dueDateTo)) {
        updatedIntervalCustomDate({
          [BEFORE.value]: compensateTimeZone(dueDateTo)
        })
      }

      if (isSinceRangeSelected && !isEqual(props.intervalCustomDate[SINCE.value], startDateFrom)) {
        updatedIntervalCustomDate({
          [SINCE.value]: compensateTimeZone(startDateFrom)
        })
      }
    } else {
      if (!isEqual(props.intervalCustomRange, DEFAULT_DATES_RANGE)) {
        updateIntervalCustomRange(cloneDeep(DEFAULT_DATES_RANGE))
      }

      if (!isEqual(props.intervalCustomDate, DEFAULT_INTERVAL_CUSTOM_DATE_OBJECT)) {
        updatedIntervalCustomDate(cloneDeep(DEFAULT_INTERVAL_CUSTOM_DATE_OBJECT))
      }
    }
  },
  { immediate: true }
)
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';
@import '~@/assets/styles/filters';

.isw-BottomFixedItem_Interval {
  color: $dark-1;
  text-decoration: none;
}

.isw-RangePicker {
  padding: 16px 0 9px;
}

.isw-RangePicker_Nav {
  box-sizing: border-box;
  padding: 0 20px;
  display: grid;
  grid-template-columns: 1fr auto;
  width: 100%;
  min-width: max-content;
  gap: 16px;
}

.isw-RangeWrapper {
  &:deep(.mx-input-wrapper) {
    display: none;
  }

  // &:deep(.mx-datepicker) {
  //   position: absolute;
  // }
}

.isw-RangePickerNav_Btn {
  background-color: $grey-3-next;

  @include hoverState($grey-3-next, 0%);
  @include activeState($grey-3-next);
}

.isw-RangeWrapper_Button {
  width: 100%;
  min-height: 44px;
  border-radius: 0;
  padding: 10px;
  justify-content: flex-start;
  background-color: transparent;
  text-align: left;
  height: auto;
  gap: 8px;
  line-height: 20px;

  &:not(&-range-selected) {
    @media (any-hover: hover) {
      &:hover {
        background-color: $grey-3-next;
      }
    }
    @include activeState($grey-3-next, 10%);
  }

  &-range-selected {
    background-color: rgba($primary-color-next, 0.15);
    color: $primary-color-next;
    align-items: flex-start;

    @media (any-hover: hover) {
      &:hover {
        background-color: rgba($primary-color-next, 0.2);
      }
    }
    @include activeState(rgba($primary-color-next, 0.25));
  }
}

.isw-RangeButton_Label {
  font-weight: fw('regular');

  &:not(.isw-RangeWrapper_Button-range-selected &) {
    color: $dark-1;
  }

  .isw-RangeWrapper_Button-range-selected & {
    color: inherit;
  }
}
</style>
