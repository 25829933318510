<template>
  <div class="dco-OkrNamesCounts">
    <span v-for="cell in getCells" :key="cell.okrKey">
      {{
        getSummary({
          ...cell
        })
      }}
      {{ $t(cell.text) }}
    </span>
  </div>
</template>

<script setup>
import { computed } from 'vue'

import { getSummary, KR_KEY, OBJ_KEY } from '@/utils/dashboard'

const props = defineProps({
  item: {
    type: Object,
    required: true
  },

  selectedTableView: {
    type: String,
    required: true
  }
})

const getCells = computed(() => [
  {
    data: props.item,
    filteredBy: props.selectedTableView,
    okrKey: OBJ_KEY,
    text: 'okr_elements.objectives'
  },
  {
    data: props.item,
    okrKey: KR_KEY,
    filteredBy: props.selectedTableView,
    text: 'objective.key_results'
  }
])
</script>

<style lang="scss" scoped>
.dco-OkrNamesCounts {
  display: flex;
  flex-direction: column;
  gap: 14px;
  color: $grey-1-next;
  text-align: right;
  font-size: $fs-12;
  font-style: normal;
  font-weight: fw('regular');
  line-height: 16px;
}
</style>
