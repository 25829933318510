<template>
  <AppDroplist
    v-model="opened"
    :data-auto-testid="DATE_FORM_FIELD_TEST_ID"
    :data-testid="DATE_FORM_FIELD_TEST_ID"
    :disabled="!userHasUpdateAccess"
    :offset="[0, 0]"
    :theme="DROP_LIST_THEMES.LIGHT"
    position="bottom-start"
  >
    <template #button>
      <OkrFormFieldSelectTriggerNext
        :empty-state-label="emptyStateLabel"
        :label="fieldName"
        :selected-options="selectedOptions"
        truncated-label
      >
        <template #label="{ resolvedLabel }">
          <CustomFieldLabel :field-id="fieldId"> {{ resolvedLabel }} </CustomFieldLabel>
        </template>
      </OkrFormFieldSelectTriggerNext>
    </template>
    <DatePicker
      :clearable="false"
      :format="dateFormat"
      :inline="true"
      :lang="datepickerLang()"
      :model-value="innerModelValue"
      @update:model-value="onDatePickerInput"
    >
      <template #footer="{ emit: emitEvent }">
        <a href="#" @click.prevent="emitEvent(null)"> {{ $t('datepicker.without_date') }} </a>
      </template>
    </DatePicker>
  </AppDroplist>
</template>
<script setup>
import dayjs from 'dayjs'
import { isNull } from 'lodash'
import { computed, ref } from 'vue'
import DatePicker from 'vue2-datepicker'

import { DROP_LIST_THEMES } from '@/utils/components-configurations/app-droplist'
import { DATE_FORM_FIELD_TEST_ID } from '@/utils/custom-fields/jest-helpers'
import { useCustomFieldsHelpers } from '@/utils/custom-fields/use-custom-fields'
import {
  dateFormat,
  datepickerLang,
  getValidDueOrStartDate,
  localDateToUtc,
  UNSELECTED_DATE
} from '@/utils/date'
import { OKR_DATES_SELECT_DATE_PROPS } from '@/utils/okr-element-dates'
import { stringOrNullProp } from '@/utils/prop-validators'

import AppDroplist from '@/components/AppDroplist'
import CustomFieldLabel from '@/components/custom-fields/okr-elements-form/CustomFieldLabel'
import OkrFormFieldSelectTriggerNext from '@/components/ui/AppSelect/TriggerButtons/OkrFormFieldSelectTriggerNext'

defineOptions({
  name: 'DateField',
  inheritAttrs: false
})

const props = defineProps({
  userHasUpdateAccess: {
    type: Boolean,
    required: true
  },

  fieldId: {
    type: [String, Number],
    required: true
  },

  modelValue: {
    required: true,
    validator: value => {
      return stringOrNullProp(value)
    }
  }
})

const selectedOptions = computed(() => {
  if (!isNull(props.modelValue)) {
    return dayjs(props.modelValue).format(dateFormat)
  }

  return props.modelValue
})

const opened = ref(false)

const emit = defineEmits({
  'update:model-value': null
})
const onDatePickerInput = date => {
  if (props.userHasUpdateAccess) {
    const value = localDateToUtc(
      getValidDueOrStartDate(date, OKR_DATES_SELECT_DATE_PROPS.START_DATE)
    )
    emit('update:model-value', {
      fieldId: props.fieldId,
      value
    })

    opened.value = false
  }
}

const { fieldName, emptyStateLabel } = useCustomFieldsHelpers({
  fieldId: props.fieldId
})

const innerModelValue = computed(() => {
  if (!isNull(props.modelValue)) {
    return dayjs(props.modelValue).toDate()
  }

  return UNSELECTED_DATE
})
</script>

<style lang="scss" scoped></style>
