<template>
  <AppButton
    class="rfb-ResetAction"
    icon-after="cross-circle-next"
    size="sm"
    type="ghost-next"
    @click="onClick"
  >
    {{ $t('filter.clear') }}
  </AppButton>
</template>

<script setup>
import AppButton from '@/components/ui/AppButton/AppButton'

defineOptions({
  name: 'ResetFilterButton'
})

const emit = defineEmits({
  click: null
})

const onClick = () => emit('click')
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';

.rfb-ResetAction {
  font-family: $system-ui;
  height: auto;
  font-style: normal;
  font-weight: fw('semi-bold');
  font-size: $fs-14;
  line-height: 20px;
  text-decoration: none;
  color: $dark-2;
  padding: 4px 4px 4px 8px;
  @include activityStates($dark-2, 10%, 'color');
}
</style>
