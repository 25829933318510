import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

export const DEFAULT_PROJECT_CONFIGURATION = {
  projectId: [],
  startDateField: null,
  endDateField: null,
  syncDirection: null,
  keepValue: false
}

export const useSyncTypes = () => {
  const { t } = useI18n()
  return {
    syncTypes: computed(() => {
      return [
        {
          icon: 'replace_jira-large',
          label: t('settings.synchronization.oboard_replace_jira'),
          message: t('settings.synchronization.jira_replace'),
          value: 4
        },
        {
          icon: 'replace_okr',
          label: t('settings.synchronization.jira_replace_oboard'),
          message: t('settings.synchronization.oboard_replace'),
          value: 3
        }
      ]
    })
  }
}
