import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

import { externalLinkHelper } from '@/utils/external-link-helper'
import { APP_PLATFORMS } from '@root/app-platforms'

export const useExternalLinkHelper = () => {
  const routerModule = useRouter()
  const store = useStore() || null

  const handleExternalLink = async ({
    id,
    typeId,
    intervalId,
    levelId,
    workspaceId,
    source,
    withEmptyFilters = false,
    appPlatform = APP_PLATFORMS.JIRA,
    navigateByRouter = false,
    saveWorkspaceIdToStore = false
  }) => {
    const router = navigateByRouter ? routerModule : null

    if (saveWorkspaceIdToStore && store) {
      await store.dispatch('workspaces/setWorkspaceId', Number(workspaceId))
    }

    externalLinkHelper({
      id,
      typeId,
      intervalId,
      levelId,
      workspaceId,
      source,
      withEmptyFilters,
      appPlatform,
      router
    })
  }

  return {
    handleExternalLink
  }
}
