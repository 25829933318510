<template>
  <AppSelect
    v-model="localModelValue"
    :loading="isLoading"
    :max-height="getMaxDropdownHeight()"
    :offset="[0, -40]"
    :options="options"
    :search-function="getAssignees"
    boundary="scrollParent"
    dropdown-search
    item-label="name"
    item-value="accountId"
    skeleton-loader
    skeleton-loader-height="100%"
    skeleton-loader-width="100%"
    split-first-option
    @update:options="assignees = $event"
  >
    <template #button-content="{ option }">
      <OwnerFieldOption
        v-if="
          option &&
          option[OKR_BULK_UPDATE_ENTITY_KEYS.OPERATION] !== BULK_FIELD_OPERATION_TYPE_IDS.KEEP_AS_IS
        "
        :option="option"
        label-key="name"
      />
    </template>
    <template #option-label="{ option }">
      <OwnerFieldOption
        v-if="
          option[OKR_BULK_UPDATE_ENTITY_KEYS.OPERATION] !== BULK_FIELD_OPERATION_TYPE_IDS.KEEP_AS_IS
        "
        :option="option"
        label-key="name"
      />
    </template>
  </AppSelect>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import AssigneesInfoApiHandler from '@/api/assignees-info'
import { ASSIGNEE_ENTITY_KEYS, OKR_BULK_UPDATE_ENTITY_KEYS } from '@/utils/entity-keys'
import { handleError } from '@/utils/error-handling'
import { getNotSetOwnerOption } from '@/utils/objectives'
import {
  BULK_FIELD_OPERATION_TYPE_IDS,
  createKeepAsIsOption,
  getMaxDropdownHeight,
  getOkrBulkFieldModelValueGetter,
  getOkrBulkFiledModelValueEmitPayload
} from '@/utils/okr-elements-table-bulk-actions'

import OwnerFieldOption from '@/components/form/OwnerFieldOption'
import AppSelect from '@/components/ui/AppSelect/AppSelect'

defineOptions({
  name: 'OwnerField',
  inheritAttrs: false
})

const props = defineProps({
  modelValue: {
    type: Object,
    required: true
  },

  workspaceId: {
    type: [String, Number],
    required: true
  }
})

const isLoading = ref(false)

const getAssignees = async (searchString = null) => {
  const api = new AssigneesInfoApiHandler()
  isLoading.value = true
  let result = []
  try {
    result = await api.getUsers({
      searchString,
      workspaceId: props.workspaceId
    })
  } catch (error) {
    handleError({ error })
  } finally {
    isLoading.value = false
  }
  return result
}

const assignees = ref([])

onMounted(async () => {
  assignees.value = await getAssignees()
})

const { t } = useI18n()

const options = computed(() => {
  return [
    {
      ...createKeepAsIsOption({
        [OKR_BULK_UPDATE_ENTITY_KEYS.VALUE_KEY]: ASSIGNEE_ENTITY_KEYS.ACCOUNT_ID,
        [OKR_BULK_UPDATE_ENTITY_KEYS.LABEL_KEY]: ASSIGNEE_ENTITY_KEYS.NAME
      })
    },
    {
      ...getNotSetOwnerOption(t),
      [OKR_BULK_UPDATE_ENTITY_KEYS.OPERATION]: BULK_FIELD_OPERATION_TYPE_IDS.CLEAR
    },
    ...assignees.value.map(user => {
      return {
        ...user,
        [OKR_BULK_UPDATE_ENTITY_KEYS.OPERATION]: BULK_FIELD_OPERATION_TYPE_IDS.REPLACE
      }
    })
  ]
})

const emit = defineEmits({
  'update:model-value': null
})

const localModelValue = computed({
  get: () => {
    const { operation, value } = props.modelValue
    if (operation === BULK_FIELD_OPERATION_TYPE_IDS.CLEAR) {
      return value
    }
    return getOkrBulkFieldModelValueGetter({
      modelValue: props.modelValue
    })
  },
  set: newValue => {
    const payload = getOkrBulkFiledModelValueEmitPayload({
      newValue,
      options: options.value,
      valueKey: ASSIGNEE_ENTITY_KEYS.ACCOUNT_ID
    })

    emit('update:model-value', payload)
  }
})
</script>

<style lang="scss" scoped></style>
