// typeId by type name
export const OBJECTIVE_TYPES = {
  PERSONAL: 1,
  GROUP: 2,
  COMPANY: 3,
  KR: 4,
  TASK: 5
}
export const TYPE_ID_NAMES = {
  [OBJECTIVE_TYPES.PERSONAL]: 'objective',
  [OBJECTIVE_TYPES.GROUP]: 'objective',
  [OBJECTIVE_TYPES.COMPANY]: 'objective',
  [OBJECTIVE_TYPES.KR]: 'kr',
  [OBJECTIVE_TYPES.TASK]: 'task'
}

export const FAKE_OBJECTIVE_TYPES = {
  NO_NESTED_TASKS_FAKE_ROW: 'umc82rgyi'
}
