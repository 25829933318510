<template>
  <div class="mp-MissingPage">
    <SettingsPagesEmptyState hero-height="203" hero-icon="404" hero-width="430">
      <template #title> {{ $t('missing_page.title') }} </template>
      <template #subtitle> {{ $t('missing_page.description') }} </template>

      <template #action>
        <AppButton type="primary-next" @click="goHome">
          {{ $t('missing_page.button') }}
        </AppButton>
      </template>
    </SettingsPagesEmptyState>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

import { ROUTE_NAMES } from '@/routes/route-helpers'

import AppButton from '@/components/ui/AppButton/AppButton'
import SettingsPagesEmptyState from '@/components/ui/SettingsPagesEmptyState/SettingsPagesEmptyState'

defineOptions({
  name: 'MissingPage'
})

const router = useRouter()
const store = useStore()

const workspaceId = computed(() => `${store.state.workspaces.workspaceId}`)

const goHome = () =>
  router.push({ name: ROUTE_NAMES.HOME_PAGE, params: { workspaceId: workspaceId.value } })
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/canvas-dimensions';
.mp-MissingPage {
  min-height: $default-canvas-height-non-objectives-page;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
</style>
