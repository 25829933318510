<template>
  <AppButton
    :id="`ggra-${groupId}`"
    :class="{ 'ggra-Trigger-active': isActionsShow }"
    data-testid="row-dropdown-trigger"
    icon="more-next"
    size="sm"
    type="tertiary-next"
    @click="onTriggerClick"
  />
  <DropdownMenu
    v-if="actionsMenuInitialized"
    :items="items"
    :offset="[0, 0]"
    :show="isActionsShow"
    :to-selector="`#ggra-${groupId}`"
    :type="DROPDOWN_MENU_TYPES.DEFAULT_NEXT"
    data-testid="row-dropdown-menu"
    preferred-position="bottom-end"
    show-on-init
    @close="onToggleTableHoverRow(true)"
    @open="onToggleTableHoverRow"
    @update:show="onUpdateShow"
    @item-click="onItemClick"
  />
</template>

<script setup>
import { ref } from 'vue'

import { DROPDOWN_MENU_TYPES } from '@/utils/components-configurations/dropdown-menu'

import AppButton from '@/components/ui/AppButton/AppButton'
import DropdownMenu from '@/components/ui/DropdownMenu/DropdownMenu'
import { getRowId } from '@/components/ui/InfiniteTableExpandable/infinite-table-expandable-utils'

defineOptions({
  name: 'GlobalGroupsRowActions',
  inheritAttrs: false
})

const props = defineProps({
  items: {
    type: Array,
    required: true
  },

  groupId: {
    type: [String, Number],
    required: true
  }
})

const isActionsShow = ref(false)
const actionsMenuInitialized = ref(false)

const onTriggerClick = () => {
  if (!actionsMenuInitialized.value) {
    actionsMenuInitialized.value = true
  }
}

const onUpdateShow = newValue => {
  isActionsShow.value = newValue
}

const emit = defineEmits({
  'item-click': item => item,
  'update:table-hover-row': value => value
})

const onItemClick = item => {
  emit('item-click', item)
}

const onToggleTableHoverRow = (resetValue = false) => {
  const newValue = resetValue ? null : getRowId(props.groupId)
  emit('update:table-hover-row', newValue)
}
</script>

<style lang="scss" scoped>
.ggra-Trigger-active {
  background-color: $dark-2;
  color: $white;
}
</style>
