<template>
  <AppButton
    :class="buttonClasses"
    :disable="disable"
    :height="iconHeight"
    :icon="icon"
    :icon-after="iconAfter"
    :size="size"
    :width="iconWidth"
    class="db-DescriptionButton"
    type="subtle"
    @click="$emit('click')"
  />
</template>

<script>
import { defineComponent } from 'vue'

import AppButton from '@/components/ui/AppButton/AppButton'

export default defineComponent({
  name: 'DescriptionButton',
  components: {
    AppButton
  },

  props: {
    icon: {
      type: String,
      required: true
    },

    active: {
      type: Boolean
    },

    disable: {
      type: Boolean
    },

    iconAfter: {
      type: String,
      default: ''
    },

    size: {
      type: String,
      default: 'sm'
    },

    iconHeight: {
      type: [String, Number],
      default: ''
    },

    iconWidth: {
      type: [String, Number],
      default: ''
    }
  },

  emits: { click: null },
  computed: {
    buttonClasses() {
      return {
        'db-DescriptionButton-active': this.active,
        [`db-Description-${this.size}`]: true
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.db-DescriptionButton-active {
  background: $dark-grey;
  color: $white;
}
</style>

<style lang="scss">
.db-DescriptionButton {
  border-radius: $border-radius-sm-next;
  height: 24px;
  line-height: 24px;

  &.db-Description-sm {
    width: 24px;
  }
  &.db-Description-md {
    padding: 0;
    width: 32px;
  }
}
</style>
