<template>
  <NodeViewWrapper>
    <div data-drag-handle data-type="image-upload">
      <div
        ref="dropZoneRef"
        :class="{ 'iu-Wrapper-drag-inside': isOverDropZone, 'iu-Wrapper-is-error': isError }"
        class="iu-Wrapper"
        contenteditable="false"
      >
        <LoadingCircle v-if="loading" size="large" />

        <template v-else>
          <AppIcon class="iu-Icon" height="96" icon-name="picture" width="96" />
          <div>
            <div class="iu-Description">
              {{ isOverDropZone ? $t('file_upload.drop_image_here') : $t('file_upload.drag_drop') }}

              <AppButton
                v-if="!isOverDropZone"
                :disabled="isOverDropZone"
                class="iu-UploadBtn"
                size="xss"
                type="link-next"
                @click="handleUploadClick"
              >
                {{ $t('file_upload.choose_img') }}
              </AppButton>
            </div>
            <div class="iu-SubDescription">{{ errorMsg || $t('file_upload.allowed_formats') }}</div>
          </div>
          <input
            ref="fileInput"
            :accept="IMAGE_FORMAT_STRING"
            class="iu-FileInput"
            type="file"
            @change="onFileChange"
          />
        </template>
      </div>
    </div>
  </NodeViewWrapper>
</template>

<script setup>
import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-3'
import { useDropZone } from '@vueuse/core'
import { ref, watch } from 'vue'

import FileUploaderApiHandler from '@/api/file-upload'
import {
  checkImagePixelSize,
  IMAGE_FORMAT_STRING,
  IMAGE_MIME_TYPES,
  checkFileSize,
  checkImageFileType
} from '@/utils/img-validation'

import AppButton from '@/components/ui/AppButton/AppButton'
import AppIcon from '@/components/ui/AppIcon/AppIcon'
import LoadingCircle from '@/components/ui/LoadingCircle/LoadingCircle'

const props = defineProps(nodeViewProps)

const onInsert = ({ url, id }) => {
  if (url) {
    props.editor.chain().focus(props.getPos()).setImageBlock({ src: url, id }).run()
    props.editor
      .chain()
      .focus(props.getPos() + 1)
      .run()
    props.deleteNode()
  }
}

const loading = ref(false)
const isError = ref(false)
const errorMsg = ref('')

const uploadFile = async files => {
  isError.value = false
  errorMsg.value = ''
  const file = files[0]

  if (!file) return

  try {
    const api = new FileUploaderApiHandler()
    await Promise.all([
      checkImagePixelSize({ file }),
      checkImageFileType({ file }),
      checkFileSize({ file })
    ])

    loading.value = true
    const formData = new FormData()
    formData.append('image', file)

    const { url, id } = await api.uploadImage({ formData })
    onInsert({ url, id })
  } catch (error) {
    isError.value = true
    errorMsg.value = error.message
  } finally {
    loading.value = false
  }
}

const fileInput = ref(null)

const handleUploadClick = () => {
  resetErrorState()
  fileInput.value?.click()
}

const dropZoneRef = ref(null)

const { isOverDropZone } = useDropZone(dropZoneRef, {
  onDrop: uploadFile,
  dataTypes: IMAGE_MIME_TYPES,
  multiple: false,
  preventDefaultForUnhandled: false
})

const resetErrorState = () => {
  isError.value = false
  errorMsg.value = ''
}
watch(
  () => isOverDropZone.value,
  () => {
    resetErrorState()
  }
)

const onFileChange = e => {
  const files = e.target.files
  uploadFile(files)
}
</script>

<style lang="scss" scoped>
.iu-Wrapper {
  border: 2px dashed $grey-2-next;
  border-radius: $border-radius-sm-next;
  color: $grey-2-next;
  text-align: center;
  padding: 20px;

  &-drag-inside {
    background: rgba($primary-color-next, 0.1);
    border-color: $primary-color-next;
  }

  &-is-error {
    background: rgba($grade-low-color-next, 0.1);
    border-color: $grade-low-color-next;
  }
}

.iu-SubDescription {
  color: $grey-1-next;
  font-size: $fs-12;
  line-height: 16px;
}

.iu-Icon {
  .iu-Wrapper-drag-inside & {
    color: $primary-color-next;
  }

  .iu-Wrapper-is-error & {
    color: $grade-low-color-next;
  }
}

.iu-SubDescription {
  .iu-Wrapper-is-error & {
    color: $grade-low-color-next;
  }
}

.iu-Description {
  margin: 8px 0;
  color: $dark-3;
  font-weight: fw('semi-bold');
  display: flex;
  align-items: center;
  justify-content: center;
}

.iu-UploadBtn {
  font-weight: fw('semi-bold');
  padding: 0 8px;
}

.iu-FileInput {
  display: none;
}
</style>
