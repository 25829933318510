export const RELATIVE_VALUES_FILTER_KEYS = {
  LABEL: 'label',
  WITH_INPUT: 'withInput',
  OPERATOR: 'operator',
  VALUE: 'value',
  DIGIT_MAX_LENGTH: 'digitMaxLength',
  MIN_INPUT_VALUE: 'minInputValue',
  DEFAULT_INPUT_VALUE: 'defaultInputValue',
  FRACTION: 'fraction',
  ALLOW_NEGATIVE: 'allowNegative',
  GROUP: 'group',
  INPUT_AFTER_TEXT: 'inputAfterText',
  LAYOUT_AS_COLUMN: 'layoutAsColumn'
}
