<template>
  <AppModalWithConfirmation
    :confirm-close="areDataChanged"
    :show="show"
    :title="$t('create.admin.title')"
    class="adm-Modal"
    hide-hero
    @on-close="onClose"
  >
    <div class="adm-ModalBody">
      <FormFieldNext :label="userSelectLabel">
        <div class="adm-UserField">
          <AppSelect
            ref="usersSelectReference"
            v-model="selectedUser"
            :allow-create-on-search="allowAddEmailsFromSelect"
            :disabled-items="disabledItems"
            :entity-for-create="CREATE_ON_SEARCH_ENTITIES.EMAIL"
            :is-error="isUserFieldEmpty"
            :loading="isSearchLoading"
            :offset="[0, '-100%']"
            :options="users"
            :placeholder="$t('placeholder.search')"
            :search-function="fetchUsers"
            append-to=".adm-UserField"
            dropdown-search
            item-label="name"
            item-value="accountId"
            skeleton-loader
            @open="isUserFieldEmpty = false"
            @update:options="users = $event"
          >
            <template #option-label="{ option }">
              <OwnerFieldOption
                :already-added="option.alreadyAdded"
                :option="option"
                label-key="name"
              >
                <template v-if="option.alreadyAdded" #badge>
                  {{ $t('badge.already_added') }}
                </template>
              </OwnerFieldOption>
            </template>

            <template #button-content="{ option }">
              <template v-if="option">
                <OwnerFieldOption :option="option" label-key="name" />
              </template>
              <template v-else>
                {{ $t('admin.user') }}
              </template>
            </template>
          </AppSelect>
        </div>

        <AppFieldError :show="isUserFieldEmpty" class="adm-FieldError">
          {{ $t('field.required') }}
        </AppFieldError>
      </FormFieldNext>
    </div>

    <template #footer-actions>
      <AppButton type="ghost-next" @click="onClose">
        {{ $t('action.cancel') }}
      </AppButton>

      <AppButton :disabled="loading" :loading="loading" type="primary-next" @click="addUsers">
        {{ $t('create.admin.title') }}
      </AppButton>
    </template>
  </AppModalWithConfirmation>
</template>

<script setup>
import { isNull } from 'lodash'
import { computed, nextTick, ref, watch } from 'vue'

import PlatformApiHandler from '@/api/platform'
import WebAppOrganizationApiHandler from '@/api/web-app-organization'
import WorkspaceAdministratorsApiHandler from '@/api/workspace-administrators'
import { tracker } from '@/tracking/amplitude'
import { EVENT_CATEGORIES, EVENT_SOURCES } from '@/tracking/amplitude-helpers'
import { ONBOARDING_ADMIN_ROLE } from '@/utils/admin-roles'
import { handleAdministratorsList } from '@/utils/administrators'
import { REQUEST_ENTITY_KEYS } from '@/utils/entity-keys'
import { ASSIGNEE_ENTITY_KEYS } from '@/utils/entity-keys'
import { handleError } from '@/utils/error-handling'
import { CREATE_ON_SEARCH_ENTITIES } from '@/utils/select'
import {
  getUserIdentifierTypeId,
  IDENTIFIER_TYPE_IDS,
  useEmailsInSelect
} from '@/utils/web-app/emails-in-select'

import AppModalWithConfirmation from '@/components/AppModalWithConfirmation'
import AppFieldError from '@/components/form/AppFieldError'
import FormFieldNext from '@/components/form/FormFieldNext'
import OwnerFieldOption from '@/components/form/OwnerFieldOption'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppSelect from '@/components/ui/AppSelect/AppSelect'

defineOptions({
  name: 'AdminModal'
})

const { allowAddEmailsFromSelect, userSelectLabel } = useEmailsInSelect()

const platformApi = new PlatformApiHandler()

const props = defineProps({
  show: {
    type: Boolean
  },

  existedAdmins: {
    type: Array,
    default: () => []
  }
})

const emit = defineEmits({
  'update:show': null,
  'admin-added': null
})

const selectedUser = ref(null)
const isUserFieldEmpty = ref(false)
const loading = ref(false)
const users = ref([])
const isSearchLoading = ref(false)
const usersSelectReference = ref(null)

const areDataChanged = computed(() => !isNull(selectedUser.value))

const disabledItems = computed(() =>
  props.existedAdmins.map(admin => admin[ASSIGNEE_ENTITY_KEYS.ACCOUNT_ID])
)

const setDefaultState = () => {
  selectedUser.value = null
  isUserFieldEmpty.value = false
  loading.value = false
  users.value = []
  isSearchLoading.value = false
}

const fetchUsers = async (searchString = null) => {
  let usersList = []
  isSearchLoading.value = true
  try {
    usersList = await platformApi.getPlatformUsers({
      searchString,
      [REQUEST_ENTITY_KEYS.LIMIT]: 50
    })
  } catch (error) {
    handleError({ error })
  }
  isSearchLoading.value = false

  return handleAdministratorsList({
    allAdministrators: props.existedAdmins,
    usersList
  })
}

const addUsers = async () => {
  if (isNull(selectedUser.value)) {
    isUserFieldEmpty.value = true
    return
  }

  const adminsApi = new WorkspaceAdministratorsApiHandler()
  const webAppApi = new WebAppOrganizationApiHandler()

  loading.value = true

  try {
    const identifierTypeId = getUserIdentifierTypeId({
      identifier: selectedUser.value
    })

    if (identifierTypeId === IDENTIFIER_TYPE_IDS.EMAIL) {
      await webAppApi.inviteUsersToCompany({
        users: [
          {
            email: selectedUser.value,
            roleId: ONBOARDING_ADMIN_ROLE
          }
        ]
      })
    } else {
      await adminsApi.addAdmin({
        [ASSIGNEE_ENTITY_KEYS.ACCOUNT_ID]: selectedUser.value
      })
    }

    emit('admin-added')

    tracker.logEvent('Administrator added', {
      category: EVENT_CATEGORIES.USER_MANAGEMENT,
      source: EVENT_SOURCES.ADMINISTRATOR_TAB
    })
  } catch (error) {
    handleError({ error })
  }

  loading.value = false
}

watch(
  () => props.show,
  async newValue => {
    if (newValue) {
      // nextTick and setTimeout(both!) are needed to make input always focused on modal window open
      await nextTick()
      users.value = await fetchUsers()
      usersSelectReference.value?.focus()
    } else {
      setDefaultState()
    }
  },
  {
    immediate: true
  }
)

const onClose = () => {
  emit('update:show', false)
}
</script>

<style lang="scss" scoped>
.adm-UserField {
  position: relative;
}

.adm-ModalBody {
  --gap: 0;
  --label-bottom-offset: 6px;
}

.adm-FieldError {
  min-height: 20px;
  display: flex;
  align-items: center;
}
</style>

<style lang="scss">
.adm-Modal {
  --footer-padding-top: 0;
}
</style>
