<template>
  <div :class="{ [`aa-Wrapper-${type}`]: type }" class="aa-Wrapper">
    <div class="aa-LeftSide">
      <AppIcon :icon-name="iconName" class="aa-Icon" height="24" width="24" />
      <slot name="title">
        <div class="aa-Title">{{ title }}</div>
      </slot>
    </div>
    <div v-if="withRightSide" class="aa-RightSide">
      <slot name="buttons">
        <slot name="additional-button" />
        <AppButton
          :icon="buttonIcon"
          :loading="loading"
          type="primary-next"
          @click="emit('action-click')"
        >
          {{ buttonText }}
        </AppButton>
      </slot>
    </div>
  </div>
</template>

<script setup>
import { TYPES } from '@/utils/components-configurations/promo-subscription'

import AppButton from '@/components/ui/AppButton/AppButton'
import AppIcon from '@/components/ui/AppIcon/AppIcon'

defineProps({
  type: {
    type: String,
    default: TYPES.PRIMARY,
    validator: value => Object.values(TYPES).includes(value)
  },

  iconName: {
    type: String,
    default: 'info-primary'
  },

  title: {
    type: String,
    default: ''
  },

  buttonText: {
    type: String,
    default: ''
  },

  buttonIcon: {
    type: String,
    default: 'arrow-up-next'
  },

  loading: {
    type: Boolean
  },

  withRightSide: {
    type: Boolean,
    default: true
  }
})

const emit = defineEmits(['action-click'])
</script>

<style lang="scss" scoped>
.aa-Wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  background: rgba(var(--primary-color-rgb-next), 0.1);
  border-radius: $border-radius-md-next;

  .aa-LeftSide {
    display: flex;
    align-items: var(--left-side-aligning, center);
    gap: 8px;
    color: $primary-color-next;
    font-size: $fs-14;
    font-weight: fw('semi-bold');
    line-height: 20px;
  }

  .aa-RightSide {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &-warning {
    background: rgba(var(--grade-medium-color-rgb-next), 0.1);

    .aa-LeftSide {
      color: $grade-medium-color-next;
    }
  }

  &-error {
    background: rgba(var(--grade-low-color-rgb-next), 0.1);

    .aa-LeftSide {
      color: $grade-low-color-next;
    }
  }
}

.aa-Icon {
  min-width: 24px;
}
</style>
