import { isNull, isUndefined } from 'lodash'

export const stringIsHex = string => /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(string)
export const isWhiteColor = color =>
  ['#fff', '#ffffff', 'transparent', 'fff', 'ffffff'].includes(color.toLowerCase()) ||
  isNull(color) ||
  isUndefined(color)

export const CATEGORIZED_COLORS = {
  BLACK: ['#172B4D', '#42526E', '#6B778C', '#B5BBC5'],
  BLUE: ['#0B3E8C', '#0052CC', '#407DD9', '#ACCBE6'],
  GREEN: ['#2B836D', '#36B27E', '#68C59E', '#89BFBC'],
  VIOLET: ['#5F4AA1', '#A769F6', '#B792F5', '#B6B8F3'],
  RED: ['#9D3D5A', '#F64963', '#F8778A', '#F9AA9C'],
  MIX: ['#744F48', '#C56F44', '#CA9B86', '#FAC372']
}

export const normalizeLevelColor = ({ color } = {}) => {
  if (!color) return CATEGORIZED_COLORS.VIOLET[1]

  let normalizedColor = color

  if (!stringIsHex(color)) {
    // color from API doesn't include '#'
    if (color.length === 3) {
      normalizedColor = color.padStart(4, '#')
    } else {
      normalizedColor = color.padStart(7, '#')
    }
  }

  return normalizedColor
}

export const LEVEL_NAME_MAX_LENGTH = 20
export const LEVEL_PREFIX_MAX_LENGTH = 2

export const OKR_LEVEL_WORKSPACES_ERRORS = {
  LAST_LEVEL_IN_WORKSPACE: {
    typeId: 1,
    message: 'levels.last_level'
  },
  EXISTING_OKR_ELEMENTS: {
    typeId: 2,
    message: 'levels.cant_remove_workspace'
  }
}

export const getErrorMessageByTypeId = ({ typeId } = {}) => {
  return (
    Object.values(OKR_LEVEL_WORKSPACES_ERRORS).find(error => error.typeId === typeId)?.message ||
    'levels.unknown_error'
  )
}
