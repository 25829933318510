<template>
  <div :class="{ [`aml-Logo-${placement}`]: true }" class="aml-Logo">
    <AppLink :to="`${ROUTE_PATHS.WORKSPACES}${workspaceId}`">
      <template #default="{ href, onClick }">
        <AppLinkResolver :href="href" class="aml-Logo_Link" @click.prevent="onClick">
          <AppLogo height="24" width="127" />
        </AppLinkResolver>
      </template>
    </AppLink>
  </div>
</template>

<script setup>
import { useStore } from 'vuex'

import { ROUTE_PATHS } from '@/routes/route-helpers'
import { APP_MENU_LOGO_PLACEMENTS } from '@/utils/app-menu'

import AppLogo from '@/components/AppLogo'
import AppLink from '@/components/ui/AppLink/AppLink'
import AppLinkResolver from '@/components/ui/AppLink/AppLinkResolver'

defineOptions({
  name: 'AppMenuLogo'
})

defineProps({
  placement: {
    type: String,
    default: APP_MENU_LOGO_PLACEMENTS.MAIN_MENU,
    validator: v => Object.values(APP_MENU_LOGO_PLACEMENTS).includes(v)
  }
})

const store = useStore()

const workspaceId = store.state.workspaces.workspaceId
</script>

<style lang="scss" scoped>
.aml-Logo {
  display: flex;
  align-items: center;

  &-main-menu {
    &:not(.am-Content-without-workspace-select &) {
      margin-bottom: 16px;
    }

    .am-Content-without-workspace-select & {
      margin-bottom: 0;
    }
  }

  &-additional-menu {
    margin-bottom: 16px;
  }
}

.aml-Logo_Link {
  display: flex;
  padding: 8px;
}
</style>
