<template>
  <AppModalWithConfirmation
    ref="confirmReference"
    :confirm-close="areDataChanged"
    :show="show"
    :size="500"
    :title="modalTitle"
    class="autw-Modal"
    hide-hero
    @on-close="onClose(false)"
  >
    <div class="autw-Head">
      <div class="autw-Head_Cell">
        {{ $t('field.workspace.title') }}
      </div>
      <div class="autw-Head_Cell">
        {{ $t('workspaces.role') }}
      </div>
    </div>

    <div ref="fieldSetsReference" class="autw-ModalBody">
      <div class="autw-Fields">
        <AddUserFieldGroup
          v-for="item in workspacesToAdd"
          :key="item.id"
          v-model:workspaces-to-add="workspacesToAdd"
          :existed-workspaces-ids="existedWorkspacesIds"
          :item="item"
          :roles="roles"
          :show-clear-button="isShowClearButton"
          data-testid="add-user-filed-group"
          @remove-item="onRemoveItem"
        />
      </div>
    </div>

    <AppInfoMessage class="autw-Message">
      {{ $t('add_users_without_group') }}
    </AppInfoMessage>

    <template v-if="isShowAddButton" #footer-prepend>
      <AppTableCreateButton
        data-testid="add-another-button"
        icon-name="plus-next"
        no-hover
        no-padding
        @click="onAddItemClick"
      >
        {{ $t('user_details.add_another') }}
      </AppTableCreateButton>
    </template>

    <template #footer-actions>
      <AppButton type="ghost-next" @click="close">
        {{ $t('action.cancel') }}
      </AppButton>
      <AppButton
        :disable="isLoading"
        :loading="isLoading"
        data-testid="add-user-to-workspace-modal-submit-button"
        type="primary-next"
        @click="save"
      >
        {{ $t('action.add') }}
      </AppButton>
    </template>
  </AppModalWithConfirmation>
</template>

<script setup>
import { isEqual } from 'lodash'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import UserApiHandler from '@/api/user-details'
import { scrollSmooth } from '@/composables/visualEffects'
import {
  EVENT_SOURCES,
  MODE_NAMES_FOR_TRACKING,
  trackAddUserEvent
} from '@/tracking/amplitude-helpers'
import { REQUEST_ENTITY_KEYS } from '@/utils/entity-keys'
import { handleError } from '@/utils/error-handling'
import { WORKSPACE_USER_ROLES } from '@/utils/objectives'
import { uid } from '@/utils/uid'
import { IDENTIFIER_TYPE_IDS } from '@/utils/web-app/emails-in-select'

import AppModalWithConfirmation from '@/components/AppModalWithConfirmation'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppInfoMessage from '@/components/ui/AppInfoMessage'
import AppTableCreateButton from '@/components/ui/AppTableCreateButton'
import AddUserFieldGroup from '@/views/workspaces/settings/plugin-users/AddUserFieldGroup'

defineOptions({
  name: 'AddUserToWorkspaceModal'
})

const props = defineProps({
  userData: {
    type: Object,
    required: true
  },

  show: {
    type: Boolean
  },

  existedWorkspacesIds: {
    type: Array,
    required: true
  },

  roles: {
    type: Array,
    required: true
  }
})

const DEFAULT_ITEM = {
  workspaceId: null,
  groupIds: [],
  roleId: WORKSPACE_USER_ROLES.USER,
  workspaceValid: true,
  groupsValid: true
}

const workspacesToAdd = ref([
  {
    id: uid(),
    ...DEFAULT_ITEM
  }
])

const onRemoveItem = id => {
  workspacesToAdd.value = workspacesToAdd.value.filter(item => item.id !== id)
}

const fieldSetsReference = ref(null)

const onAddItemClick = () => {
  workspacesToAdd.value.push({
    id: uid(),
    ...DEFAULT_ITEM
  })

  scrollSmooth(fieldSetsReference)
}

const MAX_COUNT = 10
const MIN_COUNT = 1

const isShowAddButton = computed(() => {
  return workspacesToAdd.value.length < MAX_COUNT
})

const isShowClearButton = computed(() => {
  return workspacesToAdd.value.length > MIN_COUNT
})

const emit = defineEmits(['update:show', 'reload-workspaces'])

const onClose = (reloadWorkspaces = false) => {
  workspacesToAdd.value = [
    {
      id: uid(),
      ...DEFAULT_ITEM
    }
  ]
  emit('update:show', false)
  if (reloadWorkspaces) {
    emit('reload-workspaces')
  }
}

const confirmReference = ref(null)
const close = () => {
  confirmReference.value.close()
}

const areDataChanged = computed(() => {
  const isMoreThenOneWorkspace = workspacesToAdd.value.length > 1
  if (isMoreThenOneWorkspace) {
    return true
  } else {
    const { workspaceId, groupIds, roleId } = workspacesToAdd.value[0]
    const defaultItem = {
      workspaceId: DEFAULT_ITEM.workspaceId,
      groupIds: DEFAULT_ITEM.groupIds,
      roleId: DEFAULT_ITEM.roleId
    }
    const firstItem = {
      workspaceId,
      groupIds,
      roleId
    }

    return !isEqual(defaultItem, firstItem)
  }
})

const { t } = useI18n()

const modalTitle = computed(() => {
  return `${t('action.add')} ${props.userData.name}`
})

const isLoading = ref(false)
const save = async () => {
  let isValid = true
  workspacesToAdd.value.forEach(item => {
    item.workspaceValid = !!item.workspaceId
    if (!item.workspaceValid) {
      isValid = false
    }
  })

  if (isValid) {
    isLoading.value = true

    const api = new UserApiHandler()

    const payload = workspacesToAdd.value.map(item => {
      const { groupIds, roleId, workspaceId } = item
      return {
        groupIds,
        roleId,
        workspaceId,
        [REQUEST_ENTITY_KEYS.IDENTIFIER]: props.userData.accountId,
        [REQUEST_ENTITY_KEYS.IDENTIFIER_TYPE_ID]: IDENTIFIER_TYPE_IDS.ACCOUNT_ID
      }
    })

    try {
      await api.addUserToWorkspaces(payload)
      isLoading.value = false

      trackAddUserEvent({
        mode: MODE_NAMES_FOR_TRACKING.ADD_TO_WORKSPACE,
        selectedUsers: [props.userData],
        source: EVENT_SOURCES.USER_PROFILE_PAGE
      })

      onClose(true)
    } catch (error) {
      handleError({ error })
      isLoading.value = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';

$message-offset-top: 10px;
$padding: 40px;
.autw-Message {
  margin-top: $message-offset-top;
  padding: 0 $padding;
}

.autw-ModalBody {
  overflow-y: auto;
  max-height: calc(
    100vh - (60px * 2) - 44px - 28px - 20px - 22px - 92px - 24px - #{$message-offset-top}
  ); // where 60 * 2 is Y margin of modal; 44px is modal head height; 28px is modal title height; 20px is modal title margin bottom; 22px is fields head height; 92px is modal footer height; 24px is height of message; 10px is message margin top
  padding: 0 $padding;
  @include styled-native-scrollbar();
}

.autw-Head {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  padding: 0 $padding 6px;
}

.autw-Head_Cell {
  font-weight: fw('bold');
  font-size: $fs-12;
  line-height: 16px;
  color: $dark-3;
}

.autw-Fields {
  gap: 20px;
  display: flex;
  flex-direction: column;
}
</style>

<style lang="scss">
.autw-Modal {
  .ad-Content_Title {
    padding: 0 40px;
  }
  .o-modal-content {
    .o-modal-body {
      padding: 0;
    }
  }
  // --footer-padding-top: 0;
  --dialog-content-padding-right: 0;
  --dialog-content-padding-left: 0;
}
</style>
