import ApiHandler from '@/api/base'

class RenameStatusApiHandler extends ApiHandler {
  async renameStatus({ statusId, newName } = {}) {
    // settingValue Boolean or Number at this moment
    const { data } = await this.http.put('/settings', {
      settingId: statusId + 100,
      settingValue: newName
    })
    return data
  }
}

export default RenameStatusApiHandler
