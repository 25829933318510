<script>
import { defineComponent, h } from 'vue'

import AppIcon from '@/components/ui/AppIcon/AppIcon'

export default defineComponent({
  name: 'AppLogo',
  components: { AppIcon },
  props: {
    type: {
      type: String,
      validator: v => ['grey', 'main', 'short'].includes(v),
      default: 'main'
    },

    width: {
      type: [String, Number],
      default: 16
    },

    height: {
      type: [String, Number],
      default: 16
    }
  },

  computed: {
    iconName() {
      return `oboard-logo-${this.type}`
    }
  },

  render() {
    return h(AppIcon, {
      iconName: this.iconName,
      height: this.height,
      width: this.width
    })
  }
})
</script>
