import { gtmTracker } from '@/tracking/gtm-tracking'

export const EVENT_NAMES = {
  SUBSCRIBE_VIEW: 'subscribeView',
  PAGE_VIEW: 'pageView',
  INTERVAL_CREATED: 'Interval created',
  WORKSPACE_CREATED: 'Workspace created',
  DASHBOARD_CREATED: 'Dashboard created',
  USER_INVITE_SENT: 'User invite sent',
  STARTED_JIRA_APP_CONNECTION: 'Started Jira app connection',
  JIRA_CONNECTION_SUCCESS: 'Jira connection success',
  OBJECTIVE_CREATED: 'Objective created',
  KR_CREATED: 'KR created',
  JIRA_TASK_LINKED: 'Jira task linked'
}

export const EVENT_KEYS = {
  PAGE_URL: 'page_url',
  EVENT: 'event',
  PAGE_NAME: 'page_name',
  INSTANCE_ID: 'instance_id',
  EMAIL: 'email'
}

export const trackInviteUsersEvent = () => {
  gtmTracker.logEvent(EVENT_NAMES.USER_INVITE_SENT)
}

export const trackJiraIssueLinkedEvent = () => {
  gtmTracker.logEvent(EVENT_NAMES.JIRA_TASK_LINKED)
}
