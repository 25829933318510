<template>
  <div :class="wrapperClasses">
    <slot>
      <div
        v-if="separatedLabel"
        :class="{
          'offn-Button_Label-notEmpty': isSomeValueSelected,
          'oboard-truncated-text': truncatedLabel
        }"
        class="offn-Button_Label"
      >
        <slot :resolved-label="resolvedLabel" name="label"> {{ resolvedLabel }} </slot>
      </div>
      <div v-if="separatedLabel ? isSomeValueSelected : true" class="offn-Button_Values">
        <div
          v-if="!separatedLabel"
          :class="{
            'offn-ValuesLabel-notEmpty': isSomeValueSelected,
            'oboard-truncated-text': truncatedLabel
          }"
          class="offn-ValuesLabel"
        >
          <slot :resolved-label="resolvedLabel" name="label"> {{ resolvedLabel }} </slot>
        </div>
        <slot name="values">
          <template v-if="isSomeValueSelected">
            <div
              v-for="(item, index) in limitedOptions"
              :key="item"
              :class="{
                'offn-ValueItem-single': !isMulti,
                'offn-ValueItem-first': isMulti && !index
              }"
              class="offn-ValueItem"
            >
              <slot :item="item" name="value-item-content">
                {{ item }}
              </slot>
            </div>
            <div v-if="otherQtyOptions > 0" class="offn-ValueItem">+{{ otherQtyOptions }}</div>
          </template>
        </slot>
      </div>
    </slot>
  </div>
</template>

<script setup>
import { isEmpty } from 'lodash'
import { computed } from 'vue'

import { OKR_FORM_FIELD_SELECT_TYPES } from '@/components/ui/AppSelect/TriggerButtons/types'

defineOptions({
  name: 'OkrFormFieldSelectTriggerNext'
})

const props = defineProps({
  selectedOptions: {
    type: [Array, String, Number],
    default: () => []
  },

  label: {
    type: String,
    default: ''
  },

  emptyStateLabel: {
    type: String,
    default: 'Select an option'
  },

  opened: {
    type: Boolean
  },

  separatedLabel: {
    type: Boolean
  },

  disabled: {
    type: Boolean
  },

  type: {
    type: String,
    default: 'default',
    validator: v => Object.values(OKR_FORM_FIELD_SELECT_TYPES).includes(v)
  },

  halfWidthItems: {
    type: Boolean
  },

  limit: {
    type: [String, Number],
    default: '',
    validator: v => {
      const number = Number(v)
      return !isNaN(number) || number > 0 || !Number.isInteger(number) || !Number.isFinite(number)
    }
  },

  truncatedLabel: {
    type: Boolean
  }
})

const isMulti = computed(() => Array.isArray(props.selectedOptions))

const isSomeValueSelected = computed(() => {
  return isMulti.value ? !isEmpty(props.selectedOptions) : !!props.selectedOptions
})

const values = computed(() => {
  return isMulti.value ? props.selectedOptions : [props.selectedOptions]
})

const limitedOptions = computed(() => {
  return props.limit > 0 ? values.value.filter((item, index) => index < props.limit) : values.value
})
const otherQtyOptions = computed(() => {
  return props.limit > 0 ? values.value.length - props.limit : null
})
const resolvedLabel = computed(() => {
  return isSomeValueSelected.value ? props.label : props.emptyStateLabel
})

const wrapperClasses = computed(() => {
  return {
    'offn-Button': true,
    'offn-Button-opened': props.opened,
    'offn-Button-separatedLabel': props.separatedLabel,
    'offn-Button-disabled': props.disabled,
    [`offn-Button-${props.type}`]: props.type,
    'offn-Button-halfWidthItems': props.halfWidthItems
  }
})
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';
$gap: 4px;
.offn-Button {
  font-family: $system-ui;
  min-height: 44px;
  border-radius: $border-radius-sm-next;
  padding: 10px;
  box-sizing: border-box;

  line-height: 20px;
  color: $grey-semi-medium;
  cursor: pointer;

  display: flex;
  align-items: center;
  gap: $gap;

  position: relative;

  &-disabled {
    pointer-events: none;
    opacity: var(--disabled-opacity, 0.8);
  }
  &-secondary {
    .offn-Button_Label,
    .offn-ValuesLabel {
      font-weight: fw('regular');
      font-size: $fs-14;
    }
  }

  &:not(&-opened) {
    @include hoverState($grey-3-next, 0%);
    @include activeState($grey-11, 0%);
  }

  &-opened {
    background-color: $grey-19;
  }

  &-flexWrap {
    flex-wrap: wrap;
  }
  &-halfWidthItems {
    .offn-ValuesLabel,
    .offn-Button_Label {
      max-width: calc((100% - #{$gap}) * 0.5);
      min-width: min-content;
    }
  }
}

.offn-ValuesLabel,
.offn-Button_Label {
  align-self: flex-start;
  line-height: 24px;
  font-weight: fw('regular');
  font-size: $fs-14;

  &:not(&-notEmpty) {
    width: 100%;
  }

  &-notEmpty {
    width: calc((100% - #{$gap}) * 0.42);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-line;

    &.oboard-truncated-text {
      white-space: nowrap;
    }
  }
}

.offn-ValuesLabel {
  margin-right: auto;
}

.offn-Button_Values {
  min-height: 24px;

  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: $gap;
  align-items: center;

  .offn-Button-separatedLabel & {
    width: calc((100% - #{$gap}) * 0.58);
  }
}

.offn-ValueItem {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  font-style: normal;
  font-weight: fw('regular');
  font-size: $fs-14;
  line-height: 20px;
  color: $dark-1;
  box-sizing: border-box;

  .offn-Button-separatedLabel & {
    max-width: 100%;
  }

  &-first {
    max-width: calc((100% - #{$gap}) * 0.58);

    .offn-Button-separatedLabel & {
      max-width: 100%;
    }
  }

  &:not(&-single) {
    padding: var(--value-item-padding, 0 6px);
    background-color: $grey-19;
    border-radius: $border-radius-sm-next;
  }
}
</style>
