<template>
  <SkeletonItem
    v-if="loading"
    :height="`${ICON_SIZE}px`"
    :width="`${ICON_SIZE}px`"
    border-radius="6px"
  />
  <div
    v-else
    :class="{ 'gi-GroupIcon-clickable': clickable }"
    :style="{
      '--size': `${ICON_SIZE}px`,
      '--color': color
    }"
    class="gi-GroupIcon"
    @click="onClick"
  >
    <AppIcon :height="ICON_SIZE" :icon-name="resolvedIconName" :width="ICON_SIZE" />
  </div>
</template>

<script setup>
import { computed } from 'vue'

import { GLOBAL_GROUPS_ICON_PREFIX, GROUPS_CATEGORIZED_COLORS } from '@/utils/global-groups'

import AppIcon from '@/components/ui/AppIcon/AppIcon'
import SkeletonItem from '@/components/ui/SkeletonLoaders/SkeletonItem'

defineOptions({
  name: 'GroupIcon'
})

const props = defineProps({
  iconName: {
    type: [String, Number],
    default: '1'
  },

  color: {
    type: String,
    default: GROUPS_CATEGORIZED_COLORS.BLACK[0]
  },

  loading: {
    type: Boolean
  },

  clickable: {
    type: Boolean
  }
})

const ICON_SIZE = 24

const resolvedIconName = computed(() => `${GLOBAL_GROUPS_ICON_PREFIX}${props.iconName}`)

const emit = defineEmits({
  click: null
})

const onClick = () => {
  if (props.clickable) {
    emit('click')
  }
}
</script>

<style lang="scss" scoped>
.gi-GroupIcon {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: $border-radius-sm-next;
  background-color: var(--color);
  color: $dark-2;
  transition: background-color $transition-fast, color $transition-fast;

  .csi-Item-active & {
    color: inherit;
  }

  &-clickable {
    cursor: pointer;
  }
}
</style>
