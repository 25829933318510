<template>
  <div>
    <CreateJiraIssueForm
      ref="createJiraIssueForm"
      :source="source"
      @save="onCreateJiraIssueFormSave"
    />

    <portal to="modal-windows">
      <ObjectiveModal
        v-if="showObjectiveModal"
        ref="objectiveModal"
        :child-parameters="listState.requestParameters"
        :children-order="listState.requestParameters.order"
        :source="source"
        @close="onObjectiveModalClose"
        @closed="showObjectiveModal = false"
        @element-created="onElementCreated"
      />

      <ObjectiveDeleteModal
        v-if="isConfirmDeleteShow"
        v-model:show="isConfirmDeleteShow"
        :objective="deleteObjectiveData"
        :source="source"
        @deleted="onObjectiveDeleted"
        @update:show="!$event ? (deleteObjectiveData = null) : undefined"
      />

      <ObjectiveModalMoveStepper
        v-if="showModalMove"
        v-model:show="showModalMove"
        :objective-data="modalMoveData"
        @updated="onMoveObjective"
      />
    </portal>
  </div>
</template>

<script>
import { isEmpty } from 'lodash'
import { defineComponent } from 'vue'
import { mapGetters, mapState } from 'vuex'

import {
  isJiraAppInjectionKey,
  isSalesforceAppInjectionKey,
  isWebAppInjectionKey,
  listStateInjectionKey
} from '@/utils/injection-keys'
import { NOTIFICATION_DURATIONS, showNotify } from '@/utils/notify'
import { OBJECTIVE_TYPES } from '@/utils/objective-types'
import {
  saveUpdatedElementParameters,
  objectiveIsJiraTask,
  currentUserCanCopyObjective,
  OKR_FORM_VIEWS,
  okrElementToLinkIssuePayload,
  isKR
} from '@/utils/objectives'
import { FILTERS_KEYS } from '@/utils/okr-elements/filters'
import {
  checkIsShowNotification,
  createdObjectiveModalNotificationActions,
  getCreatedElementNotificationTitle
} from '@/utils/okr-elements/notifications'
import { numberOrNullProp } from '@/utils/prop-validators'
import { createEditOkrElementPayload } from '@/utils/view-row-actions'

import ObjectiveModalMoveStepper from '@/components/objectives/objective-move/ObjectiveModalMoveStepper'
import ObjectiveDeleteModal from '@/components/objectives/ObjectiveDeleteModal'
import ObjectiveModal from '@/components/objectives/ObjectiveModal'
import CreateJiraIssueForm from '@jira/components/objectives/forms/CreateJiraIssue'

export default defineComponent({
  name: 'ObjectiveViewRowActions',

  components: {
    ObjectiveModal,
    CreateJiraIssueForm,
    ObjectiveDeleteModal,
    ObjectiveModalMoveStepper
  },

  inject: {
    listState: {
      from: listStateInjectionKey
    },

    isJiraApp: {
      from: isJiraAppInjectionKey
    },

    isWebApp: {
      from: isWebAppInjectionKey
    },

    isSalesforceApp: {
      from: isSalesforceAppInjectionKey
    }
  },

  props: {
    source: {
      type: String,
      default: ''
    },

    depth: {
      required: true,
      validator: v => numberOrNullProp(v)
    }
  },

  emits: { update: null },

  data() {
    return {
      isConfirmDeleteShow: false,
      deleteObjectiveData: null,
      showObjectiveModal: false,
      linkJiraTaskData: null,
      showModalMove: false,
      modalMoveData: null
    }
  },

  computed: {
    ...mapState('workspaces', {
      selectedWorkspaceId: state => state.workspaceId
    }),

    ...mapGetters('pluginOptions', {
      isPluginServer: 'isPluginServer'
    })
  },

  methods: {
    onMoveObjective() {
      this.$emit('update')
    },

    onCreateJiraIssueFormSave(objectives) {
      const blinkOkrElementIds = []

      objectives.forEach(okrElementInfo => {
        okrElementInfo.elements.forEach(element => {
          blinkOkrElementIds.push({
            uniqueId: element.uniqueId,
            onFirstLevel: false
          })

          const { parentId } = element
          const id = `${parentId}-${this.depth}`
          // new value is saved automatically after data update
          this.listState.filtersValues[FILTERS_KEYS.EXPANDED_ITEMS][id] = true

          saveUpdatedElementParameters(this.listState, okrElementInfo.updatedElementParameters)
        })
      })

      this.$emit('update', {
        blinkObjectiveIds: blinkOkrElementIds,
        keepObjectivesSize: true
      })
    },

    onElementCreated(elementType) {
      const title = getCreatedElementNotificationTitle(elementType)

      setTimeout(() => {
        // wait for modal to close
        this.$nextTick(() => {
          if (this.showObjectiveModal) {
            // show notify only if objective modal is open
            // that means that user created (KR, TASK, OBJECTIVE) from objective modal
            showNotify({
              title: this.$t(title)
            })
          }
        })
      })
    },

    handleCopyNotificationLink(createdElement) {
      if (isEmpty(createdElement)) {
        throw new Error('createdElement is empty')
      }
      createdObjectiveModalNotificationActions.COPY_LINK.handler({
        isPluginServer: this.isPluginServer,
        workspaceId: this.selectedWorkspaceId,
        createdElement,
        isJiraApp: this.isJiraApp,
        isWebApp: this.isWebApp
      })
      showNotify({ title: this.$t('notifications.link_copied') })
    },

    onObjectiveModalClose(eventData) {
      const isNeedShowNotification = checkIsShowNotification(eventData)
      let title = null
      let createdElement = null
      if (isNeedShowNotification.OBJECTIVE) {
        createdElement = eventData.createdObjective
        title = 'create.objective.success_title'
      }

      if (isNeedShowNotification.KR) {
        createdElement = eventData.createdKR
        title = 'create.keyResult.success_message'
      }

      if (title && createdElement) {
        showNotify({
          duration: NOTIFICATION_DURATIONS.SHORT,
          expanded: true,
          title: this.$t(title),
          content: this.$t('create.objective.success_description'),
          actions: [
            {
              ...createdObjectiveModalNotificationActions.OPEN,
              handler: () => {
                this.editOkrElement(createdElement)
              }
            },
            {
              ...createdObjectiveModalNotificationActions.COPY_LINK,
              handler: () => {
                this.handleCopyNotificationLink(createdElement)
              },
              shouldBeRemoved: this.isSalesforceApp // force remove copy link action for salesforce
            }
          ].filter(action => !action.shouldBeRemoved)
        })
      }

      if (isNeedShowNotification.LINK_JIRA_ISSUE) {
        const { createdTasks } = eventData
        createdTasks.forEach(createdElement => {
          showNotify({
            title: this.$t('create.task.success_message'),
            expanded: true,
            duration: NOTIFICATION_DURATIONS.SHORT,
            actions: [
              {
                ...createdObjectiveModalNotificationActions.OPEN,
                handler: () => {
                  this.editOkrElement(createdElement)
                }
              },
              {
                ...createdObjectiveModalNotificationActions.COPY_LINK,
                handler: () => {
                  this.handleCopyNotificationLink(createdElement)
                },
                shouldBeRemoved: this.isSalesforceApp // force remove copy link action for salesforce
              }
            ].filter(action => !action.shouldBeRemoved)
          })
        })
      }

      const parameters = {
        keepObjectivesSize: true
      }

      const createdObject = eventData.createdObjective || eventData.createdKR

      const handleOkrElement = element => {
        parameters.blinkObjectiveIds = [
          {
            uniqueId: element.uniqueId,
            onFirstLevel: false
          }
        ]

        const { parentId } = element
        const id = `${parentId}-${this.depth}`
        // new value is saved automatically after data update
        this.listState.filtersValues[FILTERS_KEYS.EXPANDED_ITEMS][id] = true
      }

      if (createdObject) {
        handleOkrElement(createdObject)
      } else if (eventData.createdTasks) {
        eventData.createdTasks.forEach(issue => handleOkrElement(issue))
      }

      this.$emit('update', parameters)
    },

    onObjectiveDeleted() {
      this.$emit('update', { keepObjectivesSize: true })

      if (this.showObjectiveModal) {
        this.$refs.objectiveModal.updateObjectiveChildObjectives()
      }
    },

    async linkTask() {
      this.showObjectiveModal = true
      await this.$nextTick()
      this.$refs.objectiveModal.openForm(
        OKR_FORM_VIEWS.LINK_JIRA_ISSUE,
        this.linkJiraTaskData,
        'grid'
      )
    },

    /* Public API */
    async createNewChildObjective(parentObjective, typeId, levelId) {
      this.showObjectiveModal = true
      await this.$nextTick()
      this.$refs.objectiveModal.openForm(
        OKR_FORM_VIEWS.OBJECTIVE,
        {
          typeId,
          intervalId: parentObjective.intervalId,
          parentId: parentObjective.id,
          workspaceId: parentObjective.workspaceId,
          depth: this.depth,
          automaticElementStartDate: parentObjective.automaticElementStartDate,
          automaticDueDate: parentObjective.automaticDueDate,
          intervalStartDate: parentObjective.intervalStartDate,
          intervalEndDate: parentObjective.intervalEndDate,
          levelId
        },
        'grid'
      )
    },

    /* Public API */
    async createNewKR(parentOkrElement) {
      this.showObjectiveModal = true
      await this.$nextTick()

      this.$refs.objectiveModal.openForm(
        OKR_FORM_VIEWS.KR,
        {
          typeId: OBJECTIVE_TYPES.KR,
          intervalId: parentOkrElement.intervalId,
          parentId: parentOkrElement.id,
          workspaceId: parentOkrElement.workspaceId,
          depth: this.depth,
          automaticElementStartDate: parentOkrElement.automaticElementStartDate,
          automaticDueDate: parentOkrElement.automaticDueDate,
          intervalStartDate: parentOkrElement.intervalStartDate,
          intervalEndDate: parentOkrElement.intervalEndDate
        },
        'grid'
      )
    },

    /** @public */
    moveOkrElement(payload) {
      this.showModalMove = true
      this.modalMoveData = payload
    },

    /** @public */
    createNewOkrElement(parentObjective, payload) {
      if (payload.typeId === 1) {
        this.createNewChildObjective(parentObjective, payload.typeId, payload.levelId)
      } else if (isKR(payload)) {
        this.createNewKR(parentObjective)
      } else if (payload.typeId === OBJECTIVE_TYPES.TASK && payload.action === 'link') {
        this.askForLinkJiraIssue(parentObjective)
      } else if (payload.typeId === OBJECTIVE_TYPES.TASK && payload.action === 'create') {
        this.$refs.createJiraIssueForm.create(parentObjective)
      }
    },

    askForLinkJiraIssue(parentObjective) {
      this.linkJiraTaskData = okrElementToLinkIssuePayload(parentObjective)
      this.linkTask()
    },

    /** @public */
    async editOkrElement(element, editPayload) {
      let payload = createEditOkrElementPayload(element)

      if (isKR(element)) {
        const { parentId, originalValue, currentValue, targetValue, fieldTypeId } = element

        payload = {
          ...payload,
          parentId,
          originalValue,
          currentValue,
          targetValue,
          fieldTypeId
        }

        if (fieldTypeId === 1) {
          payload = {
            ...payload,
            numberOriginalValue: originalValue,
            numberCurrentValue: currentValue,
            numberTargetValue: targetValue
          }
        }

        if (fieldTypeId === 2) {
          payload = {
            ...payload,
            percentOriginalValue: originalValue,
            percentCurrentValue: currentValue,
            percentTargetValue: targetValue
          }
        }

        if (fieldTypeId === 3) {
          payload.binaryValue = currentValue
        }

        this.showObjectiveModal = true
        await this.$nextTick()
        this.$refs.objectiveModal.openForm(OKR_FORM_VIEWS.KR, payload, 'grid', false, editPayload)
      } else if (objectiveIsJiraTask(element)) {
        this.linkJiraTaskData = element
        this.linkTask()
      } else {
        this.showObjectiveModal = true
        await this.$nextTick()
        this.$refs.objectiveModal.openForm(
          OKR_FORM_VIEWS.OBJECTIVE,
          payload,
          'grid',
          false,
          editPayload
        )
      }
    },

    /** @public */
    deleteOkrElement(element) {
      this.deleteObjectiveData = element
      this.isConfirmDeleteShow = true
    },

    /** @public */
    async duplicateOkrElement(okrElement) {
      this.showObjectiveModal = true
      await this.$nextTick()

      this.$refs.objectiveModal.openForm(
        OKR_FORM_VIEWS.DUPLICATE_OBJECTIVE,
        {
          typeId: okrElement.typeId,
          levelId: okrElement.levelId,
          intervalId: okrElement.intervalId,
          parentId: okrElement.parentId,
          contribute: okrElement.contribute,
          objectiveId: okrElement.id,
          name: okrElement.name,
          isCanCopy: currentUserCanCopyObjective(okrElement)
        },
        'grid'
      )
    },

    /** @public */
    async editOkrElementWeights(okrElement) {
      this.showObjectiveModal = true
      await this.$nextTick()

      this.$refs.objectiveModal.openForm(
        OKR_FORM_VIEWS.CUSTOM_WEIGHTS,
        {
          id: okrElement.id,
          typeId: okrElement.typeId,
          intervalId: okrElement.intervalId,
          name: `${okrElement.displayId} ${okrElement.name}`,
          workspaceId: okrElement.workspaceId
        },
        'grid'
      )
    }
  }
})
</script>

<style></style>
