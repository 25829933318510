<template>
  <CellValue :user-has-read-access="readable" :user-has-update-access="false" cell-value="">
    <template v-if="isJiraIssue">
      <AppTag
        :id="`st${uid}`"
        :content="item.jiraStatus"
        :content-background="resolvedJiraStatusColor.background"
        :content-color="resolvedJiraStatusColor.text"
      />
    </template>

    <ObjectiveStatus v-else :status="status" />
  </CellValue>
</template>

<script>
import { defineComponent } from 'vue'

import { calculateObjectiveStatus, objectiveIsJiraTask } from '@/utils/objectives'
import { uid } from '@/utils/uid'

import ObjectiveStatus from '@/components/objectives/ObjectiveStatus'
import CellValue from '@/components/objectives/table/cells/CellValue'
import AppTag from '@/components/ui/AppTag/AppTag'

export default defineComponent({
  name: 'StatusCell',

  components: {
    AppTag,
    CellValue,
    ObjectiveStatus
  },

  props: {
    item: {
      type: Object,
      required: true
    },

    readable: {
      type: Boolean
    }
  },

  data() {
    return {
      uid: uid()
    }
  },

  computed: {
    isJiraIssue() {
      return objectiveIsJiraTask(this.item)
    },

    resolvedJiraStatusColor() {
      return {
        background: this.item.jiraStatusColor?.background || '#dfe1e6',
        text: this.item.jiraStatusColor?.text || '#42526e'
      }
    },

    status() {
      return calculateObjectiveStatus(
        this.item.confidenceLevelId,
        this.item.automaticConfidenceLevelId
      )
    }
  }
})
</script>
