import ApiHandler from '@/api/base'

class PlatformApiHandler extends ApiHandler {
  async getPlatformGroups({ searchString = null, startAt = 0, limit = 50 } = {}) {
    const { data } = await this.http.get('/platform/groups', {
      params: {
        searchString,
        startAt,
        limit
      }
    })

    return data
  }

  async getPlatformUsers({ searchString = null, startAt = 0, limit = 10 }) {
    const { data } = await this.http.get('/platform/users', {
      params: {
        searchString,
        startAt,
        limit
      }
    })
    return data
  }
}

export default PlatformApiHandler
