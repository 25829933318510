<template>
  <div v-if="wrapperDisplayStatusByVIf" v-show="wrapperDisplayStatusByVShow" :class="classes">
    <div class="o-modal-backdrop" @click.self="tryClose"></div>
    <div
      :class="{ 'o-modal-content-wrapper-lock-y': !scrollableWrapper }"
      :style="{
        '--size': isSizeInPixels ? `${size}px` : null
      }"
      class="o-modal-content-wrapper"
    >
      <transition :name="transitionName" @after-enter="onAfterEnter" @before-enter="onBeforeEnter">
        <div
          v-if="contentDisplayStatusByVif"
          v-show="contentDisplayStatusByVShow"
          class="o-modal-content"
        >
          <div class="uim-Modal-TopPart">
            <slot name="top-part" />
          </div>

          <div class="uim-LineLoader">
            <slot name="loader" />
          </div>

          <div
            v-if="!hideHeader"
            :class="{
              'uim-ModalHeader-no-bottom-padding': disableHeaderBottomPadding,
              'uim-ModalHeader-sticky': stickyHeader
            }"
            class="uim-ModalHeader"
          >
            <slot name="header">
              <header v-show="title || subtitle || !subtitleSlotIsEmpty" class="uim-TitlesWrapper">
                <div v-show="subtitle || !subtitleSlotIsEmpty" class="uim-SubtitleWrapper">
                  <slot name="subtitle">
                    <AppTitle v-if="subtitle" class="uim-SubtitleText">
                      {{ subtitle }}
                    </AppTitle>
                  </slot>
                </div>

                <AppTitle v-if="title" :level="3" class="uim-TitleText">
                  {{ title }}
                </AppTitle>
              </header>
            </slot>
            <div v-if="!hideClose" class="uim-ClosePartWrapper">
              <div>
                <slot name="before-close" />
              </div>
              <div v-if="$slots.control" class="uim-Actions">
                <slot name="control" />
              </div>
              <AppButton
                v-tippy="{
                  content: $t('action.close')
                }"
                class="uim-Close"
                icon="close-next"
                size="sm"
                type="subtle"
                @click="tryClose"
              />
            </div>
          </div>

          <slot name="modal-body">
            <div class="o-modal-body">
              <slot />
            </div>
          </slot>
          <slot name="modal-footer">
            <div class="o-modal-footer">
              <slot name="footer" />
            </div>
          </slot>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { debounce, isNumber } from 'lodash'
import { defineComponent } from 'vue'

import { APP_MODAL_SIZES } from '@/utils/components-configurations/app-modal'
import { modalSizePropValidator } from '@/utils/prop-validators'
import { slotIsEmpty } from '@/utils/slots'

import AppButton from '@/components/ui/AppButton/AppButton'
import AppTitle from '@/components/ui/AppTitle/AppTitle'

const OPEN_ANIMATION_DURATION = 200
export default defineComponent({
  name: 'OModal',

  components: {
    AppTitle,
    AppButton
  },

  props: {
    title: {
      type: String,
      default: ''
    },

    subtitle: {
      type: String,
      default: ''
    },

    size: {
      type: [String, Number],
      default: APP_MODAL_SIZES.SM,
      validator: v => modalSizePropValidator(v)
    },

    show: {
      type: Boolean,
      default: false
    },

    manualClose: {
      type: Boolean
    },

    scrollableContent: {
      type: Boolean,
      default: true
    },

    scrollableWrapper: {
      type: Boolean,
      default: true
    },

    hideClose: {
      type: Boolean
    },

    hideHeader: {
      type: Boolean
    },

    disableHeaderBottomPadding: {
      type: Boolean
    },

    stickyHeader: {
      type: Boolean
    },

    useVShowDirective: {
      type: Boolean
    }
  },

  emits: { 'update:show': null, close: null, 'after-enter': null, 'before-enter': null },

  data() {
    return {
      contentDisplayStatusByVif: false,
      contentDisplayStatusByVShow: false
    }
  },

  computed: {
    transitionName() {
      return this.size === APP_MODAL_SIZES.FULLSCREEN ? 'modal-blur' : 'modal-slide'
    },

    isSizeInPixels() {
      return isNumber(this.size)
    },

    wrapperDisplayStatusByVIf() {
      if (this.useVShowDirective) {
        // v-if always returns true when we use v-show
        return true
      }

      return this.show
    },

    wrapperDisplayStatusByVShow() {
      if (this.useVShowDirective) {
        return this.show
      }

      // v-show always returns true when we use v-if
      return true
    },

    currentDisplayStatusForWrapper() {
      if (this.useVShowDirective) {
        return this.wrapperDisplayStatusByVShow
      }

      return this.wrapperDisplayStatusByVIf
    },

    currentDisplayStatusForContent() {
      if (this.useVShowDirective) {
        return this.contentDisplayStatusByVShow
      }

      return this.contentDisplayStatusByVif
    },

    classes() {
      return {
        'o-modal': true,
        'uim-Modal': true,
        [`o-modal-${this.size}`]: !this.isSizeInPixels,
        'o-modal-custom-size': this.isSizeInPixels,
        'o-modal-visible': this.currentDisplayStatusForContent,
        'uim-Modal-scrollablecontent': this.scrollableContent
      }
    },

    subtitleSlotIsEmpty() {
      return slotIsEmpty(this.$slots.subtitle)
    }
  },

  watch: {
    useVShowDirective: {
      handler(newValue) {
        // to avoid situation when we use v-show but v-if is not removed
        // or we use v-if but v-show is not removed
        if (newValue) {
          this.contentDisplayStatusByVif = true
          this.contentDisplayStatusByVShow = false
        } else {
          this.contentDisplayStatusByVif = false
          this.contentDisplayStatusByVShow = true
        }
      },

      immediate: true
    },

    currentDisplayStatusForContent: {
      handler(newValue) {
        if (newValue) {
          document.body.classList.add('overflow-hidden')
        } else {
          this.$nextTick(() => {
            setTimeout(() => {
              // remove class if there are no more opened modals
              const openedModals = document.querySelectorAll('.o-modal-visible')
              if (openedModals.length === 0) {
                document.body.classList.remove('overflow-hidden')
              }
            }, OPEN_ANIMATION_DURATION)
          })
        }

        if (newValue !== this.currentDisplayStatusForWrapper) {
          this.$emit('update:show', newValue)
        }
      }
      // immediate: true,
    },

    currentDisplayStatusForWrapper: {
      async handler(newValue) {
        if (newValue !== this.currentDisplayStatusForContent) {
          await this.$nextTick()
          this.setResolvedIsVisibleStatus(newValue)
        }
      },

      immediate: true
    }
  },

  created() {
    this.debounceTransitionEvent = debounce(this.emitTransitionEvent)
  },

  methods: {
    setResolvedIsVisibleStatus(newValue) {
      if (this.useVShowDirective) {
        this.contentDisplayStatusByVShow = newValue
        // always set v-if to true when we use v-show
        this.contentDisplayStatusByVif = true
      } else {
        this.contentDisplayStatusByVif = newValue
        // always set v-show to true when we use v-if
        this.contentDisplayStatusByVShow = true
      }
    },

    tryClose(event) {
      if (this.manualClose) {
        this.$emit('close', event)
      } else {
        this.setResolvedIsVisibleStatus(false)
      }
    },

    /** @public */
    close() {
      this.setResolvedIsVisibleStatus(false)
      setTimeout(() => {
        this.$emit('update:show', false)
      }, OPEN_ANIMATION_DURATION)
    },

    onAfterEnter() {
      this.debounceTransitionEvent('after-enter')
    },

    onBeforeEnter() {
      this.debounceTransitionEvent('before-enter')
    },

    emitTransitionEvent(name) {
      this.$emit(name)
    }
  }
})
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';
@import '~@/assets/styles/okr-modal';

.o-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 9999;

  .ian-Modal & {
    z-index: 9998;
  }

  &-backdrop,
  &-content-wrapper {
    height: 100%;
    width: 100%;
  }

  &-backdrop {
    background-color: rgba($dark-1, 0.8);
    position: fixed;
    left: 0;
    top: 0;
    backdrop-filter: blur(4px);
  }

  &-content-wrapper {
    &:not(&-lock-y) {
      overflow-y: auto;
      @include styled-native-scrollbar();
    }

    &-lock-y {
      overflow-y: hidden;
    }

    overflow-x: hidden;
    position: relative;
    margin-left: auto;
    width: fit-content;
  }

  &-content {
    position: relative;
    background-color: $white;
    box-shadow: 0 0 0 1px rgba($dark-1, 0.08), 0 2px 1px rgba($dark-1, 0.08),
      0 0 20px -6px rgba($dark-1, 0.31);
    text-align: left;
    margin-left: auto;
    min-height: 100%;
    transition: width 0s;
  }

  &-xs {
    .o-modal-content {
      width: 350px;
    }
  }

  &-xs-next {
    .o-modal-content {
      width: 480px;
    }
  }

  &-sm {
    .o-modal-content {
      width: 520px;
    }
  }

  &-md {
    .o-modal-content {
      width: 550px;
    }
  }

  &-custom-size {
    .o-modal-content {
      width: var(--size, 520px);
    }
  }

  &-lg {
    .o-modal-content {
      width: 940px;
    }
  }

  &-lg-next {
    .o-modal-content {
      // width: $lg-next-modal-width;
      width: calc(#{$lg-next-modal-width} + #{$depth-shift});
    }

    .o-modal-body {
      padding-left: var(--modal-body-padding-left, #{$depth-shift});
    }
  }

  &-fullscreen {
    .o-modal-content,
    .o-modal-content-wrapper {
      width: 100%;
    }

    .o-modal-body {
      padding-left: var(--modal-body-padding-left, #{$depth-shift});
    }
  }

  &-xlg-next {
    .o-modal-content {
      // width: $xlg-next-modal-width;
      width: calc(#{$xlg-next-modal-width} + #{$depth-shift});
    }

    .o-modal-body {
      padding-left: $depth-shift;
    }
  }

  &-header {
    display: flex;
    justify-content: space-between;
    padding: 20px 20px 14px;
    h4 {
      margin: 0;
      font-size: $fs-20;
    }
  }

  &-body {
    padding: var(--modal-body-padding, (14px 20px 20px));
  }
  &-footer {
    padding: var(--modal-footer-padding, (14px 20px 20px));
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    &:empty {
      display: none;
    }
  }
  &-actions {
    margin-left: auto;
    display: flex;

    &:deep(.ab-Button) {
      margin-left: 8px;
      min-width: 120px;

      &:first-of-type {
        margin-left: 0;
      }
    }
  }
}

.modal-slide-enter-active {
  animation: modal-slide-in $transition-fast;
}
.modal-slide-leave-active {
  animation: modal-slide-in $transition-fast reverse;
}
@keyframes modal-slide-in {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: none;
  }
}

.modal-blur-enter-from,
.modal-leave-to {
  filter: blur(40px);
}

.uim-Modal-scrollablecontent {
  .o-modal-content {
    height: 100%;
  }

  .o-modal-body {
    overflow: hidden;
  }
}

.o-modal .o-modal .o-modal-content-wrapper {
  background-color: transparent;
}

.uim-ModalHeader {
  display: flex;
  justify-content: space-between;
  padding: var(--modal-header-padding, (40px 40px 10px));
  gap: 16px;

  &-no-bottom-padding {
    padding-bottom: 0;
  }

  &-sticky {
    position: sticky;
    top: var(--modal-header-sticky-top, 0);
    background-color: $white;
    z-index: 6;
  }
}

.uim-ClosePartWrapper {
  display: flex;
  align-items: center;
  gap: 16px;
  // margin: 12px 0 0 8px;
}

.uim-Close {
  // margin: 0 40px 0 16px;
}

.uim-LineLoader {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  width: 100%;
}

.uim-Actions {
  margin: 0 0 0 16px;
}
.uim-TitlesWrapper {
  overflow: hidden;
}

.uim-TitleText {
  margin: var(--modal-title-margin, (0 0 24px 24px));
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  .uim-SubtitleWrapper:not(:empty) + & {
    margin-top: 18px;
  }
}

.uim-SubtitleText {
  color: $grey-1;
  font-size: $fs-12;
  font-weight: fw('regular');
  line-height: 16px;
}
</style>
