import ApiHandler from '@/api/base'

class IntegrationApiHandler extends ApiHandler {
  async getChannelsAttributes({ attributeTypeId, channelTypeId }) {
    const { data } = await this.http.get('/notification/channels/attributes', {
      params: {
        attributeTypeId,
        channelTypeId
      }
    })
    return data.map(item => {
      return {
        ...item,
        attributeValue: JSON.parse(item.attributeValue)
      }
    })
  }

  async createChannelsAttributes({ channelTypeId, attributeTypeId, attributeValue }) {
    const { data } = await this.http.post('/notification/channels/attributes', {
      channelTypeId,
      attributeTypeId,
      attributeValue: JSON.stringify(attributeValue)
    })

    return data
  }

  async getChannelsAttributesById({ id }) {
    const { data } = await this.http.get(`/notification/channels/attributes/${id}`)

    return data
  }

  async deleteChannelsAttributesById({ id }) {
    const { data } = await this.http.delete(`/notification/channels/attributes/${id}`)

    return data
  }
}

export default IntegrationApiHandler
