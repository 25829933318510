import { cloneDeep } from 'lodash'

import { BILLING_ROUTES } from '@/routes/billing-routes'

// import BrandingSettings from '@/views/workspaces/settings/branding-settings'

export const WEB_APP_SETTINGS_ROUTES = [
  cloneDeep(BILLING_ROUTES)
  // {
  //   path: ROUTE_PATHS.SETTINGS_BRANDING,
  //   name: ROUTE_NAMES.SETTINGS_BRANDING,
  //   component: BrandingSettings
  // }
]
