<template>
  <div class="pa-Loader">
    <div v-for="row in ROWS" :key="row" class="pa-Loader_Row">
      <div class="pa-Cell">
        <SkeletonItem :appearance="SKELETON_APPEARANCES.CIRCLE" :size="SM" />
        <SkeletonItem :size="XS" :width="row" />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PluginAdminsTableLoader'
})
</script>
<script setup>
import {
  SKELETON_APPEARANCES,
  SKELETON_SIZES
} from '@/utils/components-configurations/skeleton-item'

const { SM, XS } = SKELETON_SIZES

import SkeletonItem from '@/components/ui/SkeletonLoaders/SkeletonItem'

const ROWS = ['110px', '120px', '90px', '200px', '38px']
</script>

<style lang="scss" scoped>
.pa-Loader_Row {
  height: 45px;
  padding: 0 $page-right-padding 0 $page-left-padding;
  display: flex;
  align-items: center;
}

.pa-Cell {
  display: flex;
  align-items: center;
  gap: 8px;
}
</style>
