<template>
  <div class="di-DashboardItem">
    <div class="di-DataWrapper">
      <slot>
        <span class="di-Data">
          <AnimatedNumber key="animatedNumber" v-slot="{ displayValue }" :end-amount="data">
            {{ displayValue }}
          </AnimatedNumber>
        </span>
      </slot>
    </div>
    <div
      :class="{
        'di-TextWrapper-inline': inlineText
      }"
      class="di-TextWrapper"
    >
      <div class="di-Title">{{ title }}</div>
      <div class="di-Subtitle">{{ subtitle }}</div>
    </div>
    <slot name="bottom">
      <IndicatorPanel
        :indicator-width="0"
        background-color="var(--grey-2-next)"
        color="var(--white-color)"
        style="--height: 2px"
      />
    </slot>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

import AnimatedNumber from '@/components/ui/AnimatedNumber/AnimatedNumber'
import IndicatorPanel from '@/components/ui/IndicatorPanel/IndicatorPanel'

export default defineComponent({
  name: 'DashboardItem',
  components: { AnimatedNumber, IndicatorPanel },
  props: {
    title: {
      type: String,
      default: ''
    },

    subtitle: {
      type: String,
      default: ''
    },

    data: {
      type: [String, Number],
      default: ''
    },

    inlineText: {
      type: Boolean
    }
  }
})
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';

.di-DashboardItem {
  padding: 20px;
  min-width: 200px;
  max-width: 255px;
  flex: 1 1 200px;
  min-height: 184px;
  border-radius: $border-radius-lg-next;
  background: var(--background-color, $grey-3-next);
  display: flex;
  flex-direction: column;
  font-family: $system-ui;
  transition: background-color $transition-fast;
}

%title-element {
  font-size: $fs-14;
  overflow-wrap: break-word;

  line-height: 20px;
  .di-TextWrapper-inline & {
    display: inline;
    &:not(:last-child) {
      margin-right: 4px;
    }
  }
}

.di-Title {
  @extend %title-element;
  font-weight: var(--title-weight, fw('semi-bold'));
  color: var(--title-color, $dark-1);
}

.di-Subtitle {
  @extend %title-element;
  color: var(--subtitle-color, $dark-3);
  text-transform: lowercase;
  font-weight: var(--subtitle-weight, fw('regular'));
}

.di-DataWrapper {
  //flex: 1 1 auto;
  margin: 0 0 14px;
}

.di-Data {
  font-size: $fs-56;
  color: var(--data-color, $dark-1);
  font-weight: fw('medium');
  line-height: 64px;
}
.di-TextWrapper {
  flex: 1 1 auto;
  margin: 0 0 6px 0;
}
</style>
