import { TABLE_COLUMNS_KEYS } from '@/utils/table-columns'

export const WS_CONFIGURATION_TABS = {
  GROUPS: TABLE_COLUMNS_KEYS.GROUPS,
  USERS: TABLE_COLUMNS_KEYS.USERS,
  INTERVALS: TABLE_COLUMNS_KEYS.INTERVALS,
  SETTINGS: TABLE_COLUMNS_KEYS.SETTINGS
}

export const WORKSPACE_CATEGORIZED_COLORS = {
  BLUE: ['#0052CC', '#0747A6', '#284472'],
  VIOLET: ['#6554C0', '#7910AA', '#AD00FF'],
  GREEN: ['#00A3BF', '#36B37E', '#6EBD09'],
  MIXED: ['#FF5630', '#FFAB00', '#EF26C6']
}
