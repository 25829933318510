<template>
  <div class="ts-Thresholds">
    <AppTitle :level="4" class="ts-OptionTitle" disable-margin>
      {{ $t('settings.company.okr_grading') }}
    </AppTitle>

    <vue-slider
      v-model="gradeValues"
      :disabled="loading"
      :dot-options="dotOptions"
      :dot-size="24"
      :enable-cross="false"
      :max="getMaxValue"
      :min="0"
      :min-range="1"
      :process="trackColor"
      :rail-style="{
        height: '6px'
      }"
      class="ts-Slider"
      tooltip="always"
      @drag-start="onDragStart"
      @drag-end="onChangeGrade"
    >
      <template #dot="{ index }">
        <div :style="getDotStyles(index)" class="ts-SliderDot">
          <AppIcon height="24" icon-name="chevrons-left-right" width="24" />
        </div>
      </template>
      <template #tooltip="{ index }">
        <div class="ts-TooltipContent">
          <span
            v-for="(content, contentIndex) in getTooltipContent(index)"
            :key="content"
            :style="getTooltipStyle(index + contentIndex, !!contentIndex)"
          >
            {{ content }}
          </span>
        </div>
      </template>
    </vue-slider>

    <AppInfoMessage class="ts-Thresholds_Description">
      {{ t('settings.company.adjust_thresholds') }}
    </AppInfoMessage>
  </div>
</template>

<script setup>
import { difference } from 'lodash'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import VueSlider from 'vue-slider-component'
import { useStore } from 'vuex'

import { useCompanySettings } from '@/utils/company-settings'
import { COMPANY_SETTINGS_ENTITY_KEYS } from '@/utils/entity-keys'
import { handleError } from '@/utils/error-handling'

import AppIcon from '@/components/ui/AppIcon/AppIcon'
import AppInfoMessage from '@/components/ui/AppInfoMessage'
import AppTitle from '@/components/ui/AppTitle/AppTitle'

defineOptions({
  name: 'ThresholdsSettings'
})

const { saveSettingsParameter, loading } = useCompanySettings()

const store = useStore()

const settings = computed(() => store.state.system.settings)

const getMaxValue = computed(() => {
  return settings.value.thresholdOnTrack > 100 ? settings.value.thresholdOnTrack : 100
})

const gradeValues = ref([
  0,
  settings.value.thresholdBehind,
  settings.value.thresholdOnTrack,
  getMaxValue.value
])

const localGradeValues = ref([...gradeValues.value])

const dotOptions = [
  {
    disabled: true
  },
  {},
  {},
  {
    disabled: true
  }
]

const trackColor = dotsPos => {
  return [
    [dotsPos[0], dotsPos[1], { backgroundColor: 'var(--grade-low-color-next)' }],
    [dotsPos[1], dotsPos[2], { backgroundColor: 'var(--grade-medium-color-next)' }],
    [dotsPos[2], dotsPos[3], { backgroundColor: 'var(--grade-high-color-next)' }]
  ]
}

const onDragStart = () => {
  localGradeValues.value = [...gradeValues.value]
}
const onChangeGrade = async index => {
  const payload = {
    1: COMPANY_SETTINGS_ENTITY_KEYS.THRESHOLD_BEHIND,
    2: COMPANY_SETTINGS_ENTITY_KEYS.THRESHOLD_ON_TRACK
  }

  // send only changed value, checking need because we can moving 2 dots but library get only 1 index for us

  const diff = difference(localGradeValues.value, gradeValues.value)

  try {
    if (diff.length === 1) {
      await saveSettingsParameter({
        parameter: payload[index],
        value: gradeValues.value[index]
      })
    }

    if (diff.length === 2) {
      // if we change 2 thresholds at one time
      // indexes: 1 for first trigger 2 — for second,
      // so we need to save them in right order
      // if we move first dot to the right, we need to save far right dot first
      // if we move second dot to the left, we need to save far left dot first
      const orderByIndex = {
        1: [2, 1],
        2: [1, 2]
      }

      const [firstIndex, secondIndex] = orderByIndex[index]
      await saveSettingsParameter({
        parameter: payload[firstIndex],
        value: gradeValues.value[firstIndex]
      })
      await saveSettingsParameter({
        parameter: payload[secondIndex],
        value: gradeValues.value[secondIndex]
      })
    }
  } catch (error) {
    handleError({ error })
  }
}

const getTooltipStyle = (index, hideSiblingContent = false) => {
  const tooltipStyles = {
    0: {
      color: 'var(--grade-low-color-next)'
    },
    1: {
      color: 'var(--grade-medium-color-next)'
    },
    2: {
      color: 'var(--grade-high-color-next)'
    },
    3: {
      display: 'none'
    }
  }

  // next statements about hide tooltip from primary dot, depend on distance between dots

  if (gradeValues.value[1] - gradeValues.value[0] <= 15 && !hideSiblingContent) {
    tooltipStyles[1] = {
      display: 'none'
    }
  }
  if (gradeValues.value[2] - gradeValues.value[1] <= 15 && !hideSiblingContent) {
    tooltipStyles[2] = {
      display: 'none'
    }
  }
  if (gradeValues.value[2] - gradeValues.value[0] <= 30 && !hideSiblingContent) {
    tooltipStyles[2] = {
      display: 'none'
    }
  }
  return tooltipStyles[index]
}

const getDotStyles = index => {
  const dotStyles = {
    0: {
      display: 'none'
    },
    1: {
      background: 'var(--grade-low-color-next)'
    },
    2: {
      background: 'var(--grade-medium-color-next)'
    },
    3: {
      display: 'none'
    }
  }
  return dotStyles[index]
}
const { t } = useI18n()
const getTooltipContent = index => {
  const atRisk = `${t('dashboard.at_risk')}: 0-${gradeValues.value[1]}`
  const behind = `${t('dashboard.behind')}: ${gradeValues.value[1]}-${gradeValues.value[2]}`
  const onTrack = `${t('dashboard.on_track')}: ${gradeValues.value[2]}-${gradeValues.value[3]}`

  const strings = {
    0: [atRisk],
    1: [behind],
    2: [onTrack]
  }
  const isBehindCloseToAtRisk = gradeValues.value[1] - gradeValues.value[0] <= 15
  const isOnTrackCloseToBehind = gradeValues.value[2] - gradeValues.value[1] <= 15
  const isOnTrackCloseToAtRisk = gradeValues.value[2] - gradeValues.value[0] <= 30

  if (isBehindCloseToAtRisk && isOnTrackCloseToAtRisk) {
    strings[0] = [atRisk, behind, onTrack]
  } else if (isBehindCloseToAtRisk) {
    strings[0] = [atRisk, behind]
  } else if (isOnTrackCloseToBehind) {
    strings[1] = [behind, onTrack]
  }
  return strings[index]
}
</script>

<style lang="scss" scoped>
.ts-OptionTitle {
  font-family: $system-ui;
  margin-bottom: 19px;
}

// eslint-disable-next-line vue-scoped-css/no-unused-selector
.ts-Slider {
  margin: 40px 0 0 0;

  &.vue-slider-disabled {
    opacity: 1;
  }

  &:deep(.vue-slider-rail) {
    .vue-slider-dot-tooltip-top {
      transform: translate(0%, -100%);
    }
  }
}

.ts-SliderDot {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  color: $white;
  cursor: col-resize;
}

.ts-TooltipContent {
  font-size: $fs-12;
  font-style: normal;
  font-weight: fw('bold');
  line-height: 16px;
  text-transform: uppercase;
  width: max-content;
  display: flex;
  gap: 8px;
}

.ts-Thresholds_Description {
  margin-top: 16px;
}
</style>
