<template>
  <FormFieldNext :disabled="!userHasUpdateAccess">
    <template #label>
      <CustomFieldLabel :field-id="fieldId" />
    </template>
    <AppTextarea
      v-model="localValue"
      :data-auto-testid="LONG_TEXT_FORM_FIELD_TEST_ID"
      :data-testid="LONG_TEXT_FORM_FIELD_TEST_ID"
      :max-length="2000"
      :placeholder="emptyStateLabel"
      :readonly="!userHasUpdateAccess"
      @blur="onBlur"
    />
  </FormFieldNext>
</template>

<script setup>
import { computed } from 'vue'

import { LONG_TEXT_FORM_FIELD_TEST_ID } from '@/utils/custom-fields/jest-helpers'
import { useCustomFieldsHelpers } from '@/utils/custom-fields/use-custom-fields'

import CustomFieldLabel from '@/components/custom-fields/okr-elements-form/CustomFieldLabel'
import FormFieldNext from '@/components/form/FormFieldNext'
import AppTextarea from '@/components/ui/AppTextarea/AppTextarea'

defineOptions({
  name: 'LongText'
})

const props = defineProps({
  userHasUpdateAccess: {
    type: Boolean,
    required: true
  },

  fieldId: {
    type: [String, Number],
    required: true
  },

  modelValue: {
    type: String,
    required: true
  }
})

const emit = defineEmits({
  'update:model-value': null,
  'update-element': null
})

const localValue = computed({
  get: () => props.modelValue,
  set: value => {
    emit('update:model-value', {
      fieldId: props.fieldId,
      value,
      updateElement: false
    })
  }
})

const onBlur = () => {
  if (props.userHasUpdateAccess) {
    emit('update-element', props.fieldId)
  }
}

const { emptyStateLabel } = useCustomFieldsHelpers({
  fieldId: props.fieldId
})
</script>

<style lang="scss" scoped></style>
