<template>
  <span class="ct-Text" @click="onClick">
    <slot />

    <AppDroplist
      :hide-on-click="false"
      :model-value="copied"
      :offset="[0, 16]"
      :theme="DROP_LIST_THEMES.TRANSLUCENT_NEXT"
      placement="top"
      trigger="manual"
    >
      {{ $t('text_copied') }}
    </AppDroplist>
  </span>
</template>

<script setup>
import { isString } from 'lodash'
import { useSlots, ref } from 'vue'

import { DROP_LIST_THEMES } from '@/utils/components-configurations/app-droplist'
import { copyToClipboard } from '@/utils/general'

import AppDroplist from '@/components/AppDroplist'

defineOptions({
  name: 'CopyableText'
})

const slots = useSlots()
const copied = ref(false)

let timer = null

const onClick = () => {
  copied.value = false
  const [{ children }] = slots.default()
  if (isString(children)) {
    copyToClipboard(children)
    copied.value = true
    if (timer) {
      clearTimeout(timer)
    }
    setupTimer()
  }
}

const setupTimer = () => {
  timer = setTimeout(() => {
    copied.value = false
  }, 2000)
}
</script>

<style lang="scss" scoped>
.ct-Text {
  display: inline-block;
  cursor: pointer;
  color: var(--color, #{$grade-low-color-next});
  font-weight: fw('bold');
}
</style>
