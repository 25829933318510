<template>
  <button :class="classes" type="button" @click="onClick">
    <AppIcon
      v-if="showIcon"
      :height="iconSizes.height"
      :icon-name="icon"
      :width="iconSizes.width"
    />
    <slot />
    <AppIcon
      v-if="showIconAfter"
      :height="iconSizes.height"
      :icon-name="iconAfter"
      :width="iconSizes.width"
      class="after"
    />

    <LoadingCircle v-if="loading" :color="loadingCircleColor" class="ab-Loader" size="xsmall" />
  </button>
</template>

<script setup>
import { computed, useSlots } from 'vue'

import { BUTTON_TYPES, BUTTON_SIZES } from '@/utils/components-configurations/app-button'

import AppIcon from '@/components/ui/AppIcon/AppIcon'
import LoadingCircle from '@/components/ui/LoadingCircle/LoadingCircle'

const props = defineProps({
  size: {
    type: String,
    default: BUTTON_SIZES.MD,
    validator: v => Object.values(BUTTON_SIZES).includes(v)
  },

  type: {
    type: String,
    default: 'primary-next',
    validator: v => BUTTON_TYPES.includes(v)
  },

  icon: {
    type: String,
    default: ''
  },

  iconAfter: {
    type: String,
    default: ''
  },

  width: {
    type: [String, Number],
    default: ''
  },

  height: {
    type: [String, Number],
    default: ''
  },

  disable: {
    type: Boolean
  },

  loading: {
    type: Boolean
  },

  round: {
    type: Boolean
  },

  removePadding: {
    type: Boolean
  },

  loadingCircleColor: {
    type: String,
    default: '#ffffff'
  },

  withoutActivityState: {
    type: Boolean
  },

  hideIconOnLoading: {
    type: Boolean
  }
})
const emit = defineEmits({ click: null })
const slots = useSlots()

const classes = computed(() => ({
  'ab-Button': true,
  'ab-Button-disabled': props.disable,
  'ab-Button-woContent': !slots.default,
  'ab-Button-round': props.round,
  'ab-Button-noPadding': props.removePadding,
  [`ab-Button-${props.size}`]: true,
  [`ab-Button-${props.type}`]: true,
  'ab-PointerEventNone': props.loading,
  'ab-Button-with-icon': !!props.icon && !props.iconAfter,
  'ab-Button-with-after-icon': (!!props.iconAfter && !props.icon) || props.loading,
  'ab-Button-both-icons': !!props.icon && (!!props.iconAfter || props.loading),
  'ab-Button-woActivityStates': props.withoutActivityState
}))

const MIN_ICON_SIZE = 16
const DEFAULT_ICON_SIZE = 24

const getIconSize = ({ property, size }) => {
  if (property) {
    return property
  }

  if (size === BUTTON_SIZES.XSS || size === BUTTON_SIZES.XS) {
    return MIN_ICON_SIZE
  }

  return DEFAULT_ICON_SIZE
}

const iconSizes = computed(() => ({
  width: getIconSize({ property: props.width, size: props.size }),
  height: getIconSize({ property: props.height, size: props.size })
}))

const onClick = eventData => {
  if (!props.disable && !props.loading) {
    emit('click', eventData)
  }
}

const showIcon = computed(() => {
  if (props.icon) {
    return props.hideIconOnLoading ? !props.loading : true
  }

  return false
})

const showIconAfter = computed(() => {
  if (props.iconAfter) {
    return props.hideIconOnLoading ? !props.loading : true
  }

  return false
})
</script>

<style lang="scss">
@import '~@/assets/styles/mixins';

%disabledTransparentState {
  &.ab-Button-disabled {
    background-color: transparent;

    &:hover,
    &:active,
    &:focus {
      background-color: transparent;
      text-decoration: none;
    }
  }
}

$disabled-color: rgba($dark-1, 0.04);
.ab-Button {
  display: flex;
  align-items: center;
  justify-content: var(--justify-content, center);
  border-width: 0;
  max-width: 100%;
  font-size: $fs-14;
  font-weight: fw('medium');
  border-radius: $border-radius-sm-next;
  outline: none !important;
  text-decoration: none;
  white-space: nowrap;
  box-shadow: 0 0 0 2px inherit;
  cursor: pointer;
  transition: background 0.1s ease-out, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38);
  transition-duration: 0.1s, 0.15s;
  width: auto;
  background-color: transparent;
  text-align: center;
  gap: var(--gap, 4px);
  font-family: $system-ui;

  &-noPadding {
    padding: 0;
  }

  &-xs {
    height: 16px;
    padding: 0;

    &.ab-Button-woContent {
      // icon only
      width: 16px;
    }
  }

  &-xss {
    height: 20px;
    padding: 0;

    &.ab-Button-woContent {
      // icon only
      width: 20px;
    }
  }

  &-sm {
    height: 24px;
    padding: 0;

    &.ab-Button-woContent {
      // icon only
      width: 24px;
    }
  }

  &-md#{&}-with-icon {
    padding: 0 var(--button-high-padding, 16px) 0 var(--button-low-padding, 6px);
  }

  &-md#{&}-with-after-icon {
    padding: 0 var(--button-low-padding, 6px) 0 var(--button-high-padding, 16px);
  }

  &-md#{&}-both-icons {
    padding: 0 var(--button-low-padding, 6px);
  }

  &-md#{&}-noPadding {
    padding: 0;
  }

  &-md {
    height: 32px;
    padding: 0 var(--button-high-padding, 16px);

    &.ab-Button-woContent {
      // icon only
      width: 32px;
    }
  }

  &-xmd {
    height: 32px;
    padding: 0 48px;
  }

  &-lg {
    height: 36px;
    padding: 0 6px;
  }

  &-lg-next {
    height: 40px;
    padding: 0 var(--button-high-padding, 16px);
  }

  &-xlg {
    height: 48px;
    padding: 6px 30px;
    font-size: $fs-16;
    min-width: 270px;
    display: block;
  }

  &-xsm {
    padding: 4px 12px 4px 8px;
    font-size: $fs-14;
  }

  &-default {
    color: $dark-2;

    @include hoverState($primary-color-next, 0%, color);
    @include activeState($dark-2, 10%, color);
  }

  &-primary {
    background: $primary-color;
    color: $white;

    @include activityStates($primary-color);
  }

  &-primary-next,
  &-success {
    font-family: $system-ui;
    font-weight: fw('semi-bold');
    color: $white;

    border-radius: $border-radius-sm-next;
  }

  &-primary-next {
    background: $primary-color-next;
    @include activityStates($primary-color-next);
  }

  &-success {
    background: $grade-high-color-next;
    @include activityStates($grade-high-color-next);
  }

  &-outline {
    background: transparent;
    color: $dark-1;
    border: 2px solid $grey-2-next;
    border-radius: $border-radius-sm-next;
    font-weight: fw('regular');
    @include hoverState($grey-3-next, 0%);
    @include hoverState($grey-2-next, 0%, 'border-color');
    @include activeState($grey-3-next, 5%);
    @include activeState($grey-2-next, 5%, 'border-color');
  }

  &-primary-next#{&}-sm,
  &-success#{&}-sm {
    border-radius: $border-radius-sm;
  }

  &-secondary {
    background-color: $main-bg-grey;
    color: $dark-grey;

    @include hoverState($grey-medium, 0%);
    @include activeState($grey-medium);
  }

  &-secondary-next {
    background-color: $grey-1-next;
    color: $dark-grey;

    @include hoverState($grey-1-next, 0%);
    @include activeState($grey-1-next);
  }

  &-tertiary {
    background-color: $grey-medium;
    color: $dark-grey;

    @include hoverState($main-bg-grey, 0%);
    @include activeState($main-bg-grey, 10%);
  }

  &-tertiary-next {
    background-color: $grey-2-next;
    color: $dark-2;

    @include hoverState($grey-2-next, 0%);
    @include activeState($grey-2-next);
    border-radius: $border-radius-sm-next;
  }

  &-tour {
    background-color: $purple-1;
    color: $white;

    @include activityStates($purple-1);
  }

  &-black-primary {
    color: $dark-1;

    &:hover {
      color: lighten($dark-1, 15%);
    }

    &:active {
      color: lighten($dark-1, 10%);
    }
  }

  &-subtle {
    background-color: transparent;
    color: $dark-grey;

    &:hover {
      background-color: lighten($dark-grey, 60%);
    }

    &:active {
      background-color: lighten($dark-grey, 55%);
    }

    @extend %disabledTransparentState;
  }

  &-ghost {
    background-color: transparent;
    color: $dark-grey;

    &:hover {
      background-color: lighten($dark-grey, 60%);
    }

    &:active {
      background-color: lighten($dark-grey, 55%);
    }

    @extend %disabledTransparentState;
  }

  &-ghost-next {
    font-family: $system-ui;
    font-weight: fw('semi-bold');
    color: $dark-3;
    border-radius: var(--border-radius, #{$border-radius-sm-next});

    &:hover {
      background-color: lighten($dark-3, 48%);
    }

    &:active {
      background-color: lighten($dark-3, 40%);
    }

    @extend %disabledTransparentState;
  }

  &-link {
    color: $primary-color;

    font-weight: fw('regular');
    background: transparent;

    &:hover {
      text-decoration: underline;
    }

    @extend %disabledTransparentState;
  }

  &-link-next {
    color: $primary-color-next;
    font-family: $system-ui;
    font-weight: fw('regular');
    background: transparent;
    gap: 8px;

    &:hover {
      text-decoration: underline;
    }

    @extend %disabledTransparentState;
  }

  &-link-black {
    color: $dark-1;
    font-weight: fw('regular');

    &:hover {
      text-decoration: underline;
    }

    &:active {
      background: lighten($dark-1, 55%);
    }
  }

  &-link-dark {
    background-color: transparent;
    color: $dark-3;
    font-weight: fw('regular');
    font-family: $system-ui;

    &:hover {
      text-decoration: none;
      color: $primary-color-next;
    }

    @extend %disabledTransparentState;
  }

  &-link-secondary {
    font-weight: fw('medium');
    background-color: transparent;
    color: $dark-1;

    @include activityStates($dark-1, 10%, 'color');

    &:hover {
      text-decoration: none;
    }

    @extend %disabledTransparentState;
  }

  &-secondary-inline {
    background-color: transparent;
    color: $grey-17;
    font-weight: fw('regular');

    &:hover {
      color: $grey-1;
    }

    &:active {
      background-color: lighten($grey-1, 55%);
    }

    @extend %disabledTransparentState;
  }

  &-secondary-inline-next {
    font-family: $system-ui;
    background-color: transparent;
    color: $dark-1;
    font-weight: fw('regular');

    &:hover {
      color: $grey-1;
    }

    &:active {
      background-color: lighten($dark-1, 55%);
    }

    @extend %disabledTransparentState;
  }

  &-warning {
    background-color: $grade-medium-color-next;
    color: $blue-4;

    @include activityStates($grade-medium-color-next);
  }

  &-danger {
    background-color: $grade-low-color-next;
    color: $white;
    font-family: $system-ui;
    border-radius: $border-radius-sm-next;

    @include activityStates($grade-low-color-next);
  }

  &-modern {
    background-color: $grey-16;
    color: $dark-1;
    padding: 4px 12px;
    height: 24px;

    @include activityStates($grey-16);

    &.ab-Button-noPadding {
      padding: 0;
    }
  }

  &-simple {
    background-color: $grey-2-next;
    border: 1px solid $grey-2-next;
    color: $dark-grey;
    font-family: $system-ui;
    @include hoverState($grey-2-next, 10%, 'background-color');
    @include hoverState($grey-2-next, 10%, 'border-color');

    &.ab-Button-noPadding {
      padding: 0;
    }

    &:active {
      border-color: $dark-grey;
      background-color: $dark-grey;
      color: $white;
    }
  }

  &-simple-next {
    background-color: $white;
    border: 2px solid $grey-2-next;
    color: $dark-3;
    border-radius: $border-radius-sm-next;
    font-family: $system-ui;
    @include hoverState($grey-2-next, 5%, 'background-color');
    @include activeState($grey-2-next, 3%, 'background-color');

    &.ab-Button-noPadding {
      padding: 0;
    }
  }

  &-woActivityStates {
    &:hover {
      background-color: transparent;
    }

    &:active {
      background-color: transparent;
    }
  }

  &-disabled {
    background-color: $disabled-color;
    color: $grey-15;
    cursor: not-allowed;

    &:active {
      pointer-events: none;
    }

    &:hover,
    &:focus,
    &:active {
      color: $grey-15;
      background-color: $disabled-color;
    }
  }

  &-disabled#{&}-default {
    background-color: transparent;
    color: $grey-1-next;

    &:hover,
    &:focus,
    &:active {
      color: $grey-1-next;
      background-color: transparent;
    }
  }

  &-round {
    border-radius: 50%;
  }
}

.ab-PointerEventNone {
  pointer-events: none;
}

.ab-Loader {
  min-width: 24px;
}
</style>
