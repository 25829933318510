<template>
  <div
    ref="table"
    :class="{
      'o-objective-table': true,
      'o-objective-table-dragging': dragging,
      'o-objective-table-scrollable': !disableHorizontalScroll
    }"
    :style="{
      '--rest-columns-template': restColumnsTemplate,
      '--full-width': `${allColumnsWidth}px`,
      '--bulk-actions-width': listState.bulkActions.enabled
        ? 'var(--okr-table-row-controls-button-width)'
        : '0px'
    }"
  >
    <div
      v-if="showHeader"
      ref="tableHead"
      :class="{ sticky: isSticky }"
      :style="{
        'padding-left': offsetLeft,
        'padding-right': disableHorizontalScroll ? offsetRight : 0,
        'padding-top': headPaddingTop,
        'margin-left': `calc(${offsetLeft} * -1)`,
        'margin-right': `calc(${offsetRight} * -1)`
      }"
      class="o-objective-head"
    >
      <div
        v-for="column in allColumns"
        :key="column"
        :ref="data => setHeaderRef(data, column === COLUMNS.NAME)"
        :class="getHeaderColumnClasses(column)"
        :style="{ width: getColumnWidth(column) }"
        @dblclick="restoreColumnWidth(column)"
      >
        <div v-if="column === COLUMNS.NAME" class="ot-NameCellSpacer">
          <AppButton
            v-if="hideableKrs && isKrsHeaderScrollAbove && showKrsList"
            v-tippy="{
              content: $t(`filter.${showKrs ? 'hide' : 'show'}_krs`)
            }"
            :icon="showKrs ? 'watch-line-grey' : 'not-watch'"
            height="24"
            remove-padding
            size="sm"
            type="ghost-next"
            width="24"
            @click="onShowHideKrs"
          />
        </div>
        <div v-if="column === COLUMNS.NAME" class="ot-ExpandCollapse">
          <ExpandCollapseButton
            v-if="!hideExpandCollapseButton"
            :loading="isInfiniteLoading"
            @collapse="$emit('collapse-all')"
            @expand="$emit('expand-all')"
          />
        </div>

        <div
          v-if="resizeableColumns && column !== COLUMNS.OPTIONS"
          class="ota-Resizer"
          @mousedown="resizeColumnStart(column, $event)"
        />

        <template v-if="column === COLUMNS.GRADE">
          <span class="ot-Objective_TableColumnGradeFirstWord">
            {{ $t(`objectives.table_header_${column}`) }}
          </span>
          <AppIcon
            class="ot-Objective_TableColumnGradeMultiplierIcon"
            height="16"
            icon-name="table-grade-multiplier"
            width="16"
          />
          {{ $t(`objectives.table_header_weight`) }}
          <div v-if="getSortOrder(column)" class="ot-Objective_Sort">
            <AppIcon :icon-name="getSortIcon(column)" class="ot-Objective_SortIcon" />
            <sup class="ot-Sort_Order">{{ getSortOrder(column) }}</sup>
          </div>
        </template>

        <template v-else-if="column === COLUMNS.OPTIONS">
          <ObjectiveTableHeaderOptions
            :columns="columns"
            :default-columns="enabledColumnsByDefault"
            :enabled-columns="tableState.columns"
            :workspace-id="workspaceId"
            @update:enabled-columns="onEnabledColumnsChanged"
            @update:columns="onColumnsChanged"
          />
        </template>

        <template v-else>
          <template v-if="column === COLUMNS.NAME">
            <span class="ot-Objective_TableColumnName">
              {{
                isKrsHeaderScrollAbove
                  ? $t('objectives.krs')
                  : $t(`objectives.table_header_${COLUMNS.NAME}`)
              }}
            </span>
          </template>
          <template v-else>
            <span class="ot-Objective_TableColumnName">
              {{ getColumnName(column) }}
            </span>
          </template>
          <div v-if="getSortOrder(column)" class="ot-Objective_Sort">
            <AppIcon :icon-name="getSortIcon(column)" class="ot-Objective_SortIcon" />
            <sup class="ot-Sort_Order">{{ getSortOrder(column) }}</sup>
          </div>
        </template>
      </div>
    </div>
    <div
      ref="tableBody"
      :class="{
        'ot-TableBodyWrapper-scrollable': !disableHorizontalScroll,
        'ot-TableBodyWrapper-no-padding-bottom': noPaddingBottom
      }"
      :style="rowListStyles"
      class="ot-TableBodyWrapper"
    >
      <ObjectiveTableRowList
        v-model="listState.objectives"
        :disable-save-filter-values="disableSaveFilterValues"
        :draggable="rootElementsAreDraggable"
        :edit-external="editExternal"
        :first-level-draggable="firstLevelDraggable"
        :hide-okr-element-rename="hideOkrElementRename"
        :hide-table-row-actions="hideTableHeaderActions"
        :table-row-grade-configurable="tableRowGradeConfigurable"
        :workspace-id="workspaceId"
        @updated="onRowListUpdated"
      >
        <template #footer>
          <template v-if="showKrsList">
            <div ref="sectionKrsHeader" :style="sectionKrsHeaderStyles" class="ot-SectionKrsHeader">
              <AppButton
                v-if="hideableKrs"
                v-tippy="{
                  content: $t(`filter.${showKrs ? 'hide' : 'show'}_krs`)
                }"
                :icon="showKrs ? 'watch-line-grey' : 'not-watch'"
                data-testid="hide-krs-button"
                height="24"
                remove-padding
                size="sm"
                type="ghost-next"
                width="24"
                @click="onShowHideKrs"
              />

              <portal to="ot-navigation-after">
                <OkrTableShowKrsSwitch
                  v-if="showKrsList && hideableKrs"
                  :model-value="showKrs"
                  name="okr-explorer-show-krs-switch"
                  @update:model-value="onShowHideKrs"
                />
              </portal>

              {{ $t('objectives.krs') }}
            </div>

            <ObjectiveTableRowList
              v-show="showKrs"
              v-model="listState.krs"
              :disable-save-filter-values="disableSaveFilterValues"
              :edit-external="editExternal"
              :hide-okr-element-rename="hideOkrElementRename"
              :hide-table-row-actions="hideTableHeaderActions"
              :table-row-grade-configurable="tableRowGradeConfigurable"
              :workspace-id="workspaceId"
              @updated="onRowListUpdated"
            />
          </template>

          <InfiniteScrollLoader
            :identifier="infiniteId"
            :load-on-start="loadObjectivesOnStart"
            @infinite="$emit('load-more', $event)"
            @update-is-infinite-loading="isInfiniteLoading = $event"
          >
            <template #no-results>
              <ObjectivesEmpty
                :filter-values="filterValues"
                :filters-used="!listState.haveAllFiltersDefaultValues"
                :objective-levels="objectiveLevels"
                :user-can-create-objectives="userCanCreateObjectives"
                @create-objective="$emit('create-objective', $event)"
                @reset-search-criteria="resetSearchCriteria"
              />
            </template>

            <template #loader="{ isFirstLoad }">
              <RoadmapLoader v-if="isRoadmapView && isFirstLoad" :hide-header="hideLoaderHeader" />
              <ObjectivesTableLoader
                v-else-if="isFirstLoad"
                :hide-header="hideLoaderHeader"
                :hide-options="!!initialColumns"
                :initial-columns="initialColumns"
              />
              <ObjectivesTableLoader v-else :row-count="1" class="ot-LoadingTable" hide-header />
            </template>
          </InfiniteScrollLoader>
        </template>
      </ObjectiveTableRowList>
    </div>
    <AppSnackbar
      v-if="!hideBulkActions"
      :actions="snackbarActions"
      :count="listState.bulkActions.selectedElements.length"
      class="ot-Snackbar"
      @close="clearBulkActionsList"
      @action-click="onSnackbarActionClick"
    />
  </div>
  <portal v-if="!hideBulkActions" to="ot-navigation-end">
    <AppButton
      :class="{
        'ot-BulkActionsButton-active': listState.bulkActions.enabled
      }"
      class="ot-BulkActionsButton"
      height="24"
      icon="bulk-actions"
      type="none"
      width="24"
      @click="toggleBulkActions"
    >
      {{ $t('action.bulk_edit') }}
    </AppButton>
  </portal>

  <portal to="modal-windows">
    <BulkEditModal
      v-if="!hideBulkActions"
      :fields-for-edit="bulkEditFields"
      :opened="bulkEditModalOpened"
      :selected-element-ids="listState.bulkActions.selectedElements"
      :workspace-id="workspaceId"
      @on-close="closeBulkEditModal"
    />

    <AppDialog
      v-if="!hideBulkActions"
      :loading="isBulkDeleteLoading"
      :show="isShowBulkDeleteModal"
      :title="$t('bulk_actions.delete_modal.title', listState.bulkActions.selectedElements.length)"
      :type="DIALOG_TYPES.DELETE"
      @on-confirm="onBulkDeleteConfirm"
      @on-close="onBulkDeleteClose"
    >
      <i18n-t keypath="bulk_actions.delete_modal.subtitle">
        <template #entity>
          <b>
            {{ $t('entity.element', listState.bulkActions.selectedElements.length) }}
          </b>
        </template>
      </i18n-t>
    </AppDialog>
  </portal>
</template>

<script>
import { capitalize, isBoolean, isEmpty, isEqual } from 'lodash'
import { defineComponent } from 'vue'
import { mapActions, mapGetters, mapState } from 'vuex'

import OkrElementsBulkUpdateApiHandler from '@/api/okr-bulk-update'
import { tracker } from '@/tracking/amplitude'
import { EVENT_CATEGORIES } from '@/tracking/amplitude-helpers'
import { ACTIONS_KEYS } from '@/utils/actions-keys'
import { DIALOG_TYPES } from '@/utils/components-configurations/app-dialog'
import { ALL_CUSTOM_FIELDS } from '@/utils/custom-fields/factory'
import { useCustomFieldsHelpers } from '@/utils/custom-fields/use-custom-fields'
import { handleError } from '@/utils/error-handling'
import { listStateInjectionKey, tableStateInjectionKey } from '@/utils/injection-keys'
import { clearTableCache } from '@/utils/memoizations'
import { showNotify } from '@/utils/notify'
import {
  COLUMNS,
  COLUMNS_SETTINGS,
  CONFIGURABLE_COLUMNS,
  ENABLED_COLUMNS_BY_DEFAULT,
  getAvailableColumns,
  highlightRow,
  isElementInViewPort
} from '@/utils/objective-table'
import {
  // clearExplorerUniqueIds,
  currentUserCanDeleteObjective,
  OBJECTIVE_SORT_OPTIONS,
  OKR_VIEW_PAGES
} from '@/utils/objectives'
import { FILTERS_KEYS } from '@/utils/okr-elements/filters'
import { isLocalStorageAvailable, updateStorageByKey } from '@/utils/persist'
import { DESC } from '@/utils/sort-options'
import { disconnectSyncScroll, initSyncScroll, SYNC_AXES } from '@/utils/sync-scroll'
import {
  DEFAULT_USERS_SETTINGS,
  getOkrElementsTableSettingsKeys,
  getResolvedRestoredValue,
  OKR_EXPLORER_SHOW_KRS,
  USER_SETTINGS_MAPPER
} from '@/utils/user-settings'

import AppDialog from '@/components/AppDialog'
import BulkEditModal from '@/components/objectives/bulk-edit/BulkEditModal'
import ObjectivesEmpty from '@/components/objectives/ObjectivesEmpty'
import OkrTableShowKrsSwitch from '@/components/objectives/OkrTableShowKrsSwitch'
import ObjectiveTableHeaderOptions from '@/components/objectives/table/ObjectiveTableHeaderOptions'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppIcon from '@/components/ui/AppIcon/AppIcon'
import AppSnackbar from '@/components/ui/AppSnackbar/AppSnackbar'
import ExpandCollapseButton from '@/components/ui/ExpandCollapseButton/ExpandCollapseButton'
import InfiniteScrollLoader from '@/components/ui/InfiniteScrollLoader/InfiniteScrollLoader'
import ObjectivesTableLoader from '@/components/ui/SkeletonLoaders/ObjectivesTableLoader'
import RoadmapLoader from '@/components/ui/SkeletonLoaders/RoadmapLoader'

import ObjectiveTableRowList from './ObjectiveTableRowList'

const LS_SHOW_KRS_KEY = 'OKR_EXPLORER_SHOW_KRS'
const LS_ENABLED_COLUMNS_KEY = 'objectiveTableEnabledColumns'
const LS_COLUMNS_WIDTH_KEY = 'objectiveTableColumnWidth'

const COLUMN_DEFAULT_WIDTH = Object.values(COLUMNS_SETTINGS).reduce((acc, val) => {
  const { key, defaultWidth } = val
  return { ...acc, [key]: defaultWidth }
}, {})

const COLUMN_MIN_WIDTH = Object.values(COLUMNS_SETTINGS).reduce((acc, val) => {
  const { key, minWidth } = val
  return { ...acc, [key]: minWidth }
}, {})

const SYNC_SCROLL_GROUP_NAME = 'alignmentTable'

// const checkIsShowFilterButton = ({ tableState = {}, userHasReadAccess = true, objective = {} }) => {
//   return (
//     tableState.showFilterButtons &&
//     userHasReadAccess &&
//     objective?.childCount &&
//     objective?.visibleCount !== objective?.childCount
//   )
// }

const getRowHtmlElements = async rowId => {
  /**
   * @param {String} rowId - uniqueId of objective
   * return {Promise} - array of html elements
   * description: function to get html elements of objective row by uniqueId
   * we wait for elements to be rendered in the DOM
   * and now we should do this with timeout coz nextTick and requestAnimationFrame too short
   * that cause now with virtual scroll we set up uuid for each element in request of all elements
   * and this request is doing when we create a new element
   */
  return new Promise(resolve => {
    const className = `.o-objective-row-${rowId}`
    let items = document.querySelectorAll(className)

    if (isEmpty(items)) {
      setTimeout(() => {
        items = document.querySelectorAll(className)
        resolve(items)
      }, 1)
    } else {
      resolve(items)
    }
  })
}

export default defineComponent({
  name: 'ObjectiveTable',

  components: {
    AppDialog,
    BulkEditModal,
    AppSnackbar,
    ObjectiveTableHeaderOptions,
    ExpandCollapseButton,
    OkrTableShowKrsSwitch,
    AppIcon,
    RoadmapLoader,
    ObjectivesTableLoader,
    AppButton,
    ObjectiveTableRowList,
    InfiniteScrollLoader,
    ObjectivesEmpty
  },

  provide() {
    return {
      [tableStateInjectionKey]: this.tableState
    }
  },

  inject: {
    listState: {
      from: listStateInjectionKey
    }
  },

  props: {
    tabName: {
      type: String,
      default: 'OKR Explorer'
    },

    offsetLeft: {
      type: [String, Number],
      default: ''
    },

    headPaddingTop: {
      type: String,
      default: ''
    },

    offsetRight: {
      type: [String, Number],
      default: ''
    },

    showFilterButtons: {
      type: Boolean
    },

    hideableKrs: {
      type: Boolean
    },

    transparentFiltered: {
      type: Boolean
    },

    initialColumns: {
      type: Array,
      default: undefined
    },

    withKrsList: {
      type: Boolean
    },

    hideTableHeaderActions: {
      type: Boolean
    },

    hideTableHeader: {
      type: Boolean
    },

    workspaceId: {
      type: [String, Number],
      required: true
    },

    firstLevelDraggable: {
      type: Boolean
    },

    tableRowGradeConfigurable: {
      type: Boolean,
      default: true
    },

    userCanCreateObjectives: {
      type: Boolean
    },

    objectiveLevels: {
      type: Array,
      default: () => []
    },

    loadObjectivesOnStart: {
      type: Boolean
    },

    filterValues: {
      type: Object,
      default: () => ({})
    },

    disableRowsDrag: {
      type: Boolean
    },

    editExternal: {
      type: Boolean
    },

    noPaddingBottom: {
      type: Boolean
    },

    headStickyOffset: {
      type: [String, Number],
      default: 74
    },

    resizeableColumns: {
      type: Boolean
    },

    disableSaveFilterValues: {
      type: Boolean
    },

    disableHorizontalScroll: {
      type: Boolean
    },

    tablePlacement: {
      type: String,
      default: ''
    },

    enabledColumnsByDefault: {
      type: Array,
      default: () => [...ENABLED_COLUMNS_BY_DEFAULT]
    },

    headerHeight: {
      type: String,
      default: '32px'
    },

    hideHeadCellsSortIcons: {
      type: Boolean
    },

    hideExpandCollapseButton: {
      type: Boolean
    },

    hideOkrElementRename: {
      type: Boolean
    },

    hideBulkActions: {
      type: Boolean
    }
  },

  emits: {
    'load-more': null,
    'create-objective': null,
    'update-okr-data': null,
    'reset-search-criteria': null,
    'expand-all': null,
    'collapse-all': null
  },

  setup() {
    const getColumnName = column => {
      const { columnName } = useCustomFieldsHelpers({
        fieldId: column
      })
      return columnName.value
    }

    return {
      getColumnName
    }
  },

  data() {
    return {
      tableState: {
        columns: [],
        showFilterButtons: this.showFilterButtons,
        transparentFiltered: this.transparentFiltered,
        mayBeDraggable: false,
        columnWidth: {}
      },

      isExpandAll: false,
      isSticky: false,
      // offsetTop: 0,
      columns: [],

      infiniteId: +new Date(),
      showKrs: false,
      dragging: false,

      columnResize: null,
      tableResizeObserver: null,
      nameHeaderRef: null,

      mouseX: null,
      currentWidth: null,

      isKrsHeaderScrollAbove: false,

      isInfiniteLoading: false,

      isBulkValidateLoading: false,
      bulkEditFields: {},
      bulkEditModalOpened: false,
      isBulkDeleteLoading: false,
      isShowBulkDeleteModal: false
    }
  },

  computed: {
    DIALOG_TYPES() {
      return DIALOG_TYPES
    },

    ...mapState('objectives', {
      sorting: 'sorting'
    }),

    ...mapGetters('system', {
      okrElementsTableEnabledColumns: 'okrElementsTableEnabledColumns',
      okrElementsTableColumnsWidth: 'okrElementsTableColumnsWidth'
    }),

    ...mapGetters('customFields', {
      columnsIdsByWorkspaceId: 'columnsIdsByWorkspaceId',
      fieldById: 'fieldById',
      checkIsCustomField: 'isCustomField',
      allCustomFieldsIds: 'allColumnsIds'
    }),

    ...mapState('customFields', {
      allCustomFields: state => state.allFields
    }),

    currentWorkspaceCustomFieldsIds() {
      // check $store existing cause in jira gadget we haven't store and that might crash app
      if (!this.$store) {
        return []
      }

      return this.columnsIdsByWorkspaceId(this.workspaceId)
    },

    okExplorerShowKrs() {
      // call from $store  with OR operator instead of ...mapState cause in jira gadget we haven't store
      return (
        this.$store?.getters['system/okExplorerShowKrs'] ||
        DEFAULT_USERS_SETTINGS[OKR_EXPLORER_SHOW_KRS]
      )
    },

    appLicenseBannerHeight() {
      // call from $store  with OR operator instead of ...mapState cause in jira gadget we haven't store
      return this.$store?.state.system.appLicenseBannerHeight || 0
    },

    externalEnvironmentNavbarHeight() {
      // call from $store with OR operator instead of ...mapState cause in jira gadget we haven't store
      return this.$store?.state.system.externalEnvironmentElementsHeight.navbar || 0
    },

    pageHeaderHeight() {
      // call from $store with OR operator instead of ...mapState cause in jira gadget we haven't store
      return this.$store?.state.system.pageHeaderHeight || 0
    },

    COLUMNS: () => COLUMNS,

    LS_COLUMNS_WIDTH_KEY() {
      return `${LS_COLUMNS_WIDTH_KEY}${capitalize(this.tablePlacement)}`.trim()
    },

    LS_ENABLED_COLUMNS_KEY() {
      return `${LS_ENABLED_COLUMNS_KEY}${capitalize(this.tablePlacement)}`.trim()
    },

    ENABLED_COLUMNS_KEY() {
      return USER_SETTINGS_MAPPER[
        getOkrElementsTableSettingsKeys(this.tablePlacement).enabledColumns
      ]
    },

    COLUMNS_WIDTH_KEY() {
      return USER_SETTINGS_MAPPER[getOkrElementsTableSettingsKeys(this.tablePlacement).columnsWidth]
    },

    // showAllFilteredItemsShowToggle() {
    //   if (this.listState.view === OKR_VIEW_PAGES.ALIGNMENT) {
    //     return Object.values(this.listState.okrElements).some(objective => {
    //       return checkIsShowFilterButton({
    //         tableState: this.tableState,
    //         userHasReadAccess: currentUserCanReadObjective(objective),
    //         objective
    //       })
    //     })
    //   }
    //   return false
    // },

    hideLoaderHeader() {
      return this.listState.page > 1 || this.isExpandAll
    },

    isRoadmapView() {
      return this.listState.view === OKR_VIEW_PAGES.ROADMAP
    },

    showHeader() {
      return !this.noObjectives && !this.hideTableHeader
    },

    showKrsList() {
      return this.withKrsList ? !isEmpty(this.listState.krs) : false
    },

    noObjectives() {
      return isEmpty(this.listState.objectives) && isEmpty(this.listState.krs)
    },

    restColumnsTemplate() {
      const availableColumns = getAvailableColumns({
        columnsList: this.tableState.columns,
        currentWorkspaceCustomFieldsIds: this.currentWorkspaceCustomFieldsIds
      })
      return availableColumns
        .map(column => {
          return `${this.tableState.columnWidth[column]}px`
        })
        .join(' ')
    },

    // allColumnsWidth() {
    //   return Object.values(this.tableState.columns).reduce((acc, val) => {
    //     return acc + this.tableState.columnWidth[val]
    //   }, this.tableState.columnWidth[COLUMNS_SETTINGS.NAME.key] + this.tableState.columnWidth[COLUMNS_SETTINGS.OPTIONS.key] + 32)
    // },

    allColumnsWidth() {
      return this.allColumns.reduce((acc, val) => {
        return acc + this.tableState.columnWidth[val]
      }, 32)
    },

    rowListStyles() {
      return {
        // negate value(also variable):
        // https://github.com/css-modules/postcss-icss-values/issues/64#issuecomment-241285628
        'margin-left': `calc(${this.offsetLeft} * -1)`,
        'margin-right': `calc(${this.offsetRight} * -1)`
      }
    },

    sectionKrsHeaderStyles() {
      return {
        'margin-left': this.offsetLeft,
        'margin-right': this.offsetRight
      }
    },

    rootElementsAreDraggable() {
      return !(
        this.listState.filtersValues[FILTERS_KEYS.SORT_ORDER].length > 0 ||
        this.tabName === 'OKR Explorer'
      )
    },

    mayBeDraggable() {
      if (this.disableRowsDrag) {
        return false
      } else {
        const { childOrder } = this.listState.requestParameters
        return childOrder.length === 1 && childOrder[0] === OBJECTIVE_SORT_OPTIONS.ORDER_ASC
      }
    },

    allColumns() {
      const allColumns = [COLUMNS.NAME, ...this.tableState.columns]

      if (!this.hideTableHeaderActions) {
        allColumns.push(COLUMNS.OPTIONS)
      }

      return getAvailableColumns({
        columnsList: allColumns,
        currentWorkspaceCustomFieldsIds: this.currentWorkspaceCustomFieldsIds
      })
    },

    snackbarActions() {
      const isDeleteActionDisabled = this.listState.bulkActions.selectedElements.some(id => {
        const element = this.listState.okrElements[id] || {
          permissions: []
        }
        return !currentUserCanDeleteObjective(element)
      })

      return [
        {
          name: ACTIONS_KEYS.BULK_EDIT,
          title: 'action.bulk_edit',
          loading: this.isBulkValidateLoading,
          color: 'var(--dark-2)'
        },
        {
          name: ACTIONS_KEYS.DELETE,
          title: 'action.delete',
          icon: 'delete-next',
          buttonType: 'link-secondary',
          color: 'var(--grey-2-next)',
          disable: isDeleteActionDisabled,
          tooltipContent: isDeleteActionDisabled ? this.$t('bulk_actions.delete_disabled') : null,

          removePadding: true
        }
      ]
    }
  },

  watch: {
    showKrsList: {
      handler(newValue) {
        if (newValue) {
          this.$nextTick(() => {
            const replaceOffset = [
              this.appLicenseBannerHeight,
              this.externalEnvironmentNavbarHeight,
              this.pageHeaderHeight
            ].reduce((acc, val) => acc + val, 0)

            const observer = new IntersectionObserver(
              entries => {
                entries.forEach(entry => {
                  if (entry.target === this.$refs.sectionKrsHeader) {
                    if (entry.isIntersecting) {
                      if (this.isKrsHeaderScrollAbove) {
                        this.isKrsHeaderScrollAbove = false
                      }
                      return
                    }

                    this.isKrsHeaderScrollAbove = entry.boundingClientRect.top <= replaceOffset
                  }
                })
              },
              { threshold: 0, rootMargin: `-${replaceOffset}px 0px 0px 0px` }
            )
            observer.observe(this.$refs.sectionKrsHeader)
          })
        } else {
          this.isKrsHeaderScrollAbove = false
        }
      },

      immediate: true
    },

    showHeader: {
      handler(newValue) {
        if (!this.disableHorizontalScroll) {
          if (newValue) {
            this.$nextTick(() => {
              const elements = [
                {
                  element: this.$refs.tableHead,
                  skipAddingListener: true
                },
                {
                  element: this.$refs.tableBody
                }
              ]
              initSyncScroll({
                elements,
                syncAxes: [SYNC_AXES.X],
                groupName: SYNC_SCROLL_GROUP_NAME
              })
            })
          } else {
            disconnectSyncScroll(SYNC_SCROLL_GROUP_NAME)
          }
        }
      },

      immediate: true
    },

    tabName: {
      handler(newValue) {
        this.tableState.tabName = newValue
      },

      immediate: true
    },

    showFilterButtons(newValue) {
      this.tableState.showFilterButtons = newValue
    },

    transparentFiltered(newValue) {
      this.tableState.transparentFiltered = newValue
    },

    mayBeDraggable: {
      handler(newValue) {
        this.tableState.mayBeDraggable = newValue
      },

      immediate: true
    },

    'listState.blinkObjectiveIds': {
      handler(newValue) {
        if (newValue.length > 0) {
          newValue.forEach(async objective => {
            const items = await getRowHtmlElements(objective.uniqueId)

            items.forEach((element, index) => {
              // if (
              //   (objective.onFirstLevel && element.dataset.depth === '0') ||
              //   (!objective.onFirstLevel && element.dataset.depth !== '0')
              // ) {
              //   element.classList.add('animation')
              //   setTimeout(() => {
              //     element.classList.remove('animation')
              //   }, 1500) // duration of animation
              // }

              if (!index) {
                const { top } = element.getBoundingClientRect()
                if (!isElementInViewPort(element)) {
                  window.scrollTo({
                    top,
                    behavior: 'smooth'
                  })
                }
                this.$nextTick(() => {
                  highlightRow(element)
                })
              } else {
                highlightRow(element)
              }
            })
          })
          this.listState.blinkObjectiveIds = []
        }
      },

      deep: true
    },

    'listState.objectives': {
      handler(newValue) {
        if (isEmpty(newValue)) {
          this.showKrs = true
        } else {
          const resolvedRestoredValue = getResolvedRestoredValue({
            valueFromSettings: this.okExplorerShowKrs,
            localStorageKey: LS_SHOW_KRS_KEY,
            isBooleanOrNumberValueRestores: true
          })

          if (isBoolean(resolvedRestoredValue)) {
            this.showKrs = resolvedRestoredValue
          }
        }
      },

      immediate: true,
      deep: true
    }
  },

  mounted() {
    // this.applyScrollListener()
    this.setupColumns()

    if (this.disableHorizontalScroll) {
      this.tableResizeObserver = new ResizeObserver(this.onTableResize)
      this.tableResizeObserver.observe(this.$refs.table)
      this.onTableResize()
    }
  },

  created() {
    this.tableState.offsetLeft = this.offsetLeft
  },

  beforeUpdate() {
    this.nameHeaderRef = null
  },

  beforeUnmount() {
    // this.detachScrollListener()
    if (this.tableResizeObserver) {
      this.tableResizeObserver.disconnect()
      this.tableResizeObserver = null
    }
    clearTableCache()
    disconnectSyncScroll(SYNC_SCROLL_GROUP_NAME)
    // clearExplorerUniqueIds()
  },

  methods: {
    ...mapActions('system', {
      updateUserSettings: 'updateUserSettings'
    }),

    setupColumns() {
      // set initial state
      this.columns = [...CONFIGURABLE_COLUMNS]
      this.tableState.columnWidth = { ...COLUMN_DEFAULT_WIDTH }

      if (this.initialColumns !== undefined) {
        // that means that we have initial columns from parent component like jira dashboard
        const defaultColumns = [COLUMNS.NAME]
        this.tableState.columns = this.initialColumns.filter(item => !defaultColumns.includes(item))
      } else {
        // that means that we use columns from user settings
        // enrich columns with custom fields
        this.columns = [...this.columns, ...this.allCustomFieldsIds]

        const customFieldsColumnsWidths = this.allCustomFields.reduce((acc, val) => {
          const { typeId } = val
          const { hasTableColumn, defaultWidth } = ALL_CUSTOM_FIELDS.getFieldOptionsByTypeId(typeId)
          if (hasTableColumn) {
            return {
              ...acc,
              [val.id]: defaultWidth
            }
          }
          return acc
        }, {})

        // enrich columns widths with custom fields
        this.tableState.columnWidth = {
          ...this.tableState.columnWidth,
          ...customFieldsColumnsWidths
        }

        this.restoreColumns()
      }
    },

    getColumnSortKey(columnKey) {
      const isCustomFieldColumn = this.checkIsCustomField({ fieldId: columnKey })

      if (isCustomFieldColumn) {
        return null
      }
      return Object.values(COLUMNS_SETTINGS).find(column => column.key === columnKey).sortKey
    },

    getSortIcon(columnKey) {
      return this.sorting[this.getColumnSortKey(columnKey)]?.order === DESC
        ? 'arrow-down-next'
        : 'arrow-up-next'
    },

    getSortOrder(columnKey) {
      if (this.hideHeadCellsSortIcons) {
        return false
      }
      return this.sorting[this.getColumnSortKey(columnKey)]?.index
    },

    getHeaderColumnClasses(column) {
      const columnResizeable = this.resizeableColumns ? column !== COLUMNS.OPTIONS : false
      return {
        'o-objective-tcell': true,
        [`o-objective-tcell-${column}`]: true,
        'o-objective-tcell-resizeable': columnResizeable
      }
    },

    setHeaderRef(reference, isNameColumn = false) {
      if (reference && isNameColumn) {
        this.nameHeaderRef = reference
      }
    },

    onTableResize() {
      const { nameHeaderRef } = this
      if (nameHeaderRef) {
        this.tableState.columnWidth.name = nameHeaderRef.offsetWidth
      }
    },

    resizeColumnStart(column, e) {
      this.columnResize = column
      this.mouseX = e.clientX
      this.currentWidth = this.tableState.columnWidth[column]
      document.addEventListener('mousemove', this.resizeColumnNext)
      document.addEventListener('mouseup', this.leaveResizingNext)
    },

    resizeColumnNext(e) {
      const column = this.columnResize
      const dx = e.clientX - this.mouseX

      const newColumnWidth = this.currentWidth + dx

      let minWidth = COLUMN_MIN_WIDTH[column]

      const isCustomFieldColumn = this.checkIsCustomField({ fieldId: column })

      if (isCustomFieldColumn) {
        const fieldParams = this.fieldById({ fieldId: column })
        if (fieldParams) {
          minWidth = ALL_CUSTOM_FIELDS.getFieldOptionsByTypeId(fieldParams.typeId).minWidth
        } else {
          minWidth = 120 // for insurance
        }
      }

      const resolvedWidth = newColumnWidth <= minWidth ? minWidth : newColumnWidth

      this.tableState.columnWidth[column] = resolvedWidth
    },

    async leaveResizingNext() {
      document.removeEventListener('mousemove', this.resizeColumnNext)
      document.removeEventListener('mouseup', this.leaveResizingNext)

      this.mouseX = null
      this.currentWidth = null

      updateStorageByKey(this.LS_COLUMNS_WIDTH_KEY, this.tableState.columnWidth)

      await this.updateUserSettings({
        [this.COLUMNS_WIDTH_KEY]: this.tableState.columnWidth
      })
    },

    getColumnWidth(column) {
      return `${this.tableState.columnWidth[column]}px`
    },

    async restoreColumnWidth(column) {
      if (!this.resizeableColumns || column === COLUMNS.OPTIONS) {
        // - options column is not resizable
        return
      }

      const isCustomFieldColumn = this.checkIsCustomField({ fieldId: column })

      if (isCustomFieldColumn) {
        const fieldParams = this.fieldById({ fieldId: column })
        if (fieldParams) {
          const { typeId } = fieldParams
          const { defaultWidth } = ALL_CUSTOM_FIELDS.getFieldOptionsByTypeId(typeId)

          this.tableState.columnWidth[column] = defaultWidth
        }
      } else {
        this.tableState.columnWidth[column] = COLUMN_DEFAULT_WIDTH[column]
      }

      updateStorageByKey(this.LS_COLUMNS_WIDTH_KEY, this.tableState.columnWidth)
      if (
        !isEqual(
          this.okrElementsTableColumnsWidth(this.tablePlacement),
          this.tableState.columnWidth
        )
      ) {
        await this.updateUserSettings({
          [this.COLUMNS_WIDTH_KEY]: this.tableState.columnWidth
        })
      }
    },

    /** @public */
    requestData(expandAll) {
      this.isExpandAll = expandAll
      this.infiniteId += 1
    },

    // onScroll() {
    //   const base = 118
    //   const el = this.$refs.tableHead
    //
    //   if (!el) return
    //   const { top } = el.getBoundingClientRect()
    //   if (!this.isSticky) {
    //     this.offsetTop = top + window.scrollY - base
    //   }
    //   this.isSticky = this.offsetTop < window.scrollY
    //
    //   if (this.isSticky) {
    //     document.body.classList.add('sticky-table-head')
    //     return
    //   }
    //   document.body.classList.remove('sticky-table-head')
    // },
    //
    // applyScrollListener() {
    //   window.addEventListener('scroll', this.onScroll, { passive: true })
    // },
    //
    // detachScrollListener() {
    //   window.removeEventListener('scroll', this.onScroll, { passive: true })
    // },

    async onEnabledColumnsChanged(columns) {
      this.tableState.columns = columns
      updateStorageByKey(this.LS_ENABLED_COLUMNS_KEY, columns)
      if (!isEqual(this.okrElementsTableEnabledColumns(this.tablePlacement), columns)) {
        await this.updateUserSettings({
          [this.ENABLED_COLUMNS_KEY]: columns
        })
      }
    },

    onColumnsChanged(columns) {
      this.columns = columns
    },

    restoreColumns() {
      const resolvedRestoredColumns = getResolvedRestoredValue({
        localStorageKey: this.LS_ENABLED_COLUMNS_KEY,
        valueFromSettings: this.okrElementsTableEnabledColumns(this.tablePlacement)
      })

      if (resolvedRestoredColumns) {
        this.tableState.columns = resolvedRestoredColumns.filter(column =>
          this.columns.includes(column)
        )
        this.columns = [
          ...this.columns.filter(column => !resolvedRestoredColumns.includes(column)),
          ...this.tableState.columns
        ]
      } else {
        this.tableState.columns = [
          ...this.columns.filter(column => this.enabledColumnsByDefault.includes(column))
        ]
      }

      const resolvedRestoredColumnWidth = getResolvedRestoredValue({
        localStorageKey: this.LS_COLUMNS_WIDTH_KEY,
        valueFromSettings: this.okrElementsTableColumnsWidth(this.tablePlacement)
      })

      if (resolvedRestoredColumnWidth) {
        for (const key in resolvedRestoredColumnWidth) {
          const isCustomFieldColumn = this.checkIsCustomField({ fieldId: key })
          const restoredWidth = resolvedRestoredColumnWidth[key]

          if (isCustomFieldColumn) {
            const fieldParams = this.fieldById({ fieldId: key })
            if (fieldParams) {
              const { typeId } = fieldParams
              const { minWidth } = ALL_CUSTOM_FIELDS.getFieldOptionsByTypeId(typeId)

              this.tableState.columnWidth[key] = restoredWidth < minWidth ? minWidth : restoredWidth
            }
          }
          if (Object.values(COLUMNS).includes(key)) {
            const minWidth = COLUMN_MIN_WIDTH[key]
            if (key === COLUMNS.OPTIONS) {
              this.tableState.columnWidth[key] = minWidth
            } else {
              this.tableState.columnWidth[key] = restoredWidth < minWidth ? minWidth : restoredWidth
            }
          }
        }
      }
    },

    onRowListUpdated(eventData) {
      this.$emit('update-okr-data', {
        keepObjectivesSize: true,
        blinkObjectiveIds:
          eventData && eventData.blinkObjectiveIds ? eventData.blinkObjectiveIds : []
      })
    },

    resetSearchCriteria() {
      this.$emit('reset-search-criteria')
    },

    async onShowHideKrs() {
      this.showKrs = !this.showKrs
      if (isLocalStorageAvailable()) {
        updateStorageByKey(LS_SHOW_KRS_KEY, this.showKrs)
      }

      if (this.resizeableColumns) {
        await this.updateUserSettings({
          [USER_SETTINGS_MAPPER[OKR_EXPLORER_SHOW_KRS]]: this.showKrs
        })
      }
    },

    toggleBulkActions() {
      this.listState.bulkActions.toggleBulkActions()

      this.clearBulkActionsList()
    },

    async clearBulkActionsList() {
      await this.$nextTick()

      this.listState.bulkActions.clearSelectedElements()
    },

    onSnackbarActionClick(name) {
      if (this.isBulkValidateLoading) return

      if (name === ACTIONS_KEYS.BULK_EDIT) {
        this.validateBulkUpdate()
      }

      if (name === ACTIONS_KEYS.DELETE) {
        this.isShowBulkDeleteModal = true
      }
    },

    async validateBulkUpdate() {
      const api = new OkrElementsBulkUpdateApiHandler()
      this.isBulkValidateLoading = true
      try {
        this.bulkEditFields = await api.validateBulkUpdate({
          elementIds: this.listState.bulkActions.selectedElements || [],
          workspaceId: Number(this.workspaceId)
        })
        await this.$nextTick()
        this.bulkEditModalOpened = true
      } catch (error) {
        handleError({ error })
      } finally {
        this.isBulkValidateLoading = false
      }
    },

    closeBulkEditModal({ reloadData = false } = {}) {
      this.bulkEditModalOpened = false
      this.bulkEditFields = {}

      if (reloadData) {
        this.listState.bulkActions.disableBulkActions()
        this.onRowListUpdated()
      }
    },

    onBulkDeleteClose() {
      this.isShowBulkDeleteModal = false
    },

    async onBulkDeleteConfirm() {
      const api = new OkrElementsBulkUpdateApiHandler()
      this.isBulkDeleteLoading = true

      try {
        const elementIds = this.listState.bulkActions.selectedElements || []
        await api.bulkDeleteOkrElements({
          elementIds
        })
        showNotify({
          title: this.$t(
            'bulk_actions.delete_notification',
            this.listState.bulkActions.selectedElements.length
          )
        })
        tracker.logEvent('elements deleted bulk', {
          category: EVENT_CATEGORIES.OKR_MANAGEMENT,
          value: elementIds.length
        })
        this.listState.bulkActions.clearSelectedElements()
        this.isShowBulkDeleteModal = false

        this.onRowListUpdated()
      } catch (error) {
        handleError({ error })
      } finally {
        this.isBulkDeleteLoading = false
      }
    }
  }
})
</script>

<style lang="scss">
.o-objective-cell {
  // box-shadow: inset -1px -1px 0 $grey-10;
}
</style>

<style lang="scss" scoped>
@import '~@/assets/styles/canvas-dimensions';
@import '~@/assets/styles/mixins';
@import '~@/assets/styles/grade-cell-variables';

.ot-ExpandCollapse {
  margin-right: 8px;
  min-width: 24px;
}

.ot-NameCellSpacer {
  width: calc(
    var(--okr-table-row-controls-button-width) +
      var(--bulk-actions-width, var(--okr-table-row-controls-button-width))
  );
}

.o-objective-table {
  user-select: none;
  font-size: $fs-14;
}

.o-objective-head {
  display: flex;
  background: $white;
  position: sticky;
  z-index: 5;
  box-sizing: border-box;
  overflow: hidden;
  padding: 0;
  height: v-bind(headerHeight);
  top: calc(
    var(--objectives-page-header-height, (calc(v-bind(headStickyOffset) * 1px))) +
      var(--app-license-banner-height, 0px)
  );
  .o-objective-table-scrollable & {
    // border-bottom: 1px solid;
    // border-image-slice: 1;
    //
    // border-image-source: linear-gradient(
    //   to right,
    //   transparent 0%,
    //   transparent 32px,
    //   black 32px,
    //   black calc(100% - 32px),
    //   transparent calc(100% - 32px),
    //   transparent 100%
    // );

    &:after {
      content: '';
      width: calc(var(--full-width, 100%) - #{$page-right-padding} - #{$page-left-padding});
      min-width: calc(100% - 64px);
      height: 2px;
      background: $grey-2-next;
      position: absolute;
      left: $page-left-padding;
      bottom: 0;

      .rm-OkrPage_Table & {
        width: calc(var(--full-width, 100%) - 32px);
        min-width: calc(100% - 32px);
      }
    }
  }

  .o-objective-tcell {
    position: relative;
    display: flex;
    align-items: center;
    font-size: $fs-12;
    line-height: 16px;
    color: $dark-3;
    font-family: $system-ui;
    font-weight: fw('bold');
    // z-index: 4;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    flex-shrink: 0;

    // border-bottom: 1px solid;

    overflow: initial;
    width: var(--width);
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 12px;

    &:not(.o-objective-table-scrollable &) {
      box-shadow: inset 0 -2px 0 $grey-2-next;
    }

    // &-resizeable:hover {
    //   background-color: $main-bg-grey;
    // }

    &-name {
      padding-left: 0;

      &:not(.o-objective-table-scrollable &) {
        flex: 1;
        width: auto;
      }
    }

    &-options {
      display: flex;
      gap: 4px;
      align-items: center;
      justify-content: flex-end;
      padding-top: 0;
      padding-bottom: 0;
      padding-right: $page-right-padding;
      margin-left: auto;
      position: sticky;
      right: 0;
      @include tableOptionsGradient();

      .o-droplist-standalone {
        min-height: 16px;
      }
    }
  }

  .o-objective-tcell-large {
    &-assignee {
      width: 160px;
    }

    &-group {
      width: 150px;
    }
  }
}

.o-objective-table-loading {
  display: flex;
  justify-content: center;
  position: relative;
  min-height: 49px;
  border-bottom: 1px solid $grey-medium;
  margin: 0 40px;
}

.o-infinite-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 15px;

  // span {
  //   padding-left: 15px;
  //   font-size: $fs-14;
  //   color: $grey-semi-medium;
  // }
}

.ot-SectionKrsHeader {
  display: flex;
  align-items: center;
  font-weight: fw('bold');
  font-size: $fs-12;
  padding-top: 32px;
  padding-bottom: 2px;
  border-bottom: 2px solid $grey-2-next;
  gap: 32px;
  font-family: $system-ui;
  line-height: 16px;
  color: $dark-3;
}

.ota-Resizer {
  width: 0;
  background-color: transparent;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  cursor: col-resize;
  // z-index: 1;
  opacity: 0;

  &:before {
    content: '';
    position: absolute;
    transform: translateX(50%);
    top: 0;
    right: 0;
    width: 11px;
    height: 100%;
    cursor: col-resize;
    background: linear-gradient(
      to right,
      transparent 0%,
      transparent 5px,
      $grey-11 5px,
      $grey-11 6px,
      transparent 6px,
      transparent 100%
    );
  }

  &:hover,
  &:active {
    opacity: 1;
    &:before {
      background: linear-gradient(
        to right,
        transparent 0%,
        transparent 4px,
        $blue-8 4px,
        $blue-8 7px,
        transparent 7px,
        transparent 100%
      );
    }
  }

  .o-objective-head:hover & {
    opacity: 1;
  }
}

.ot-TableBodyWrapper {
  &:not(&-no-padding-bottom) {
    padding-bottom: 100px;
  }

  &-scrollable {
    overflow: auto hidden;
    display: grid;
    @include styled-native-scrollbar();

    $min-height: calc(#{$default-canvas-height} - v-bind(headerHeight));

    min-height: $min-height;

    .rm-OkrPage & {
      min-height: calc(#{$min-height} - var(--roadmap-footer-height, 0));
    }

    .rm-OkrPage-fullscreen & {
      min-height: calc(
        #{$fullscreen-canvas-height} - v-bind(headerHeight) - var(--roadmap-footer-height, 0)
      );
    }
  }
}
.ot-Objective_Sort {
  height: 20px;
  width: 20px;
  font-weight: fw('regular');
  line-height: 10px;
  color: $dark-3;
  .ot-Objective_SortIcon {
    min-height: 20px;
    min-width: 20px;
  }
  .ot-Sort_Order {
    top: -1.3em;
    left: -0.8em;
    font-weight: fw('regular');
    font-size: $fs-8;
    line-height: 10px;
  }
}
.ot-Objective_TableColumnName {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.ot-Objective_TableColumnGradeFirstWord {
  @extend %grade-cell-first-part-min-width;
  padding-right: 6px;
  text-align: right;
}

.ot-Objective_TableColumnGradeMultiplierIcon {
  flex: 0 0 auto;
  margin-right: 4px;
}

.ot-LoadingTable {
  max-width: 100vw;
  /*  position: sticky;
  top: 0;
  left: 0;
  padding-top: 10px;
  padding-bottom: 10px;*/
}

.ot-Snackbar {
  --snackbar-left: calc(50% + var(--menu-width) / 2);
  // --snackbar-left: calc(var(--page-left-padding) + var(--menu-width))
  // --snackbar-transform: 0;
  transition: $menu-transition;
  --snackbar-z-index: calc(#{$menu-z-index} - 1);
  --checkmark-margin: 0 4px;
}

.ot-BulkActionsButton {
  background-color: $grey-3-next;
  color: $dark-2;
  font-size: $fs-12;
  line-height: 16px;

  @include hoverState($grey-3-next, 2%);
  @include activeState($dark-2, 0%);
  @include activeState($white, 0%, 'color');

  &-active {
    background-color: $dark-2;
    color: $white;

    @include hoverState($dark-2, 5%);
    @include activeState($dark-2, 5%);
  }
}
</style>

<style lang="scss">
@import '~@/assets/styles/mixins';
.o-objective-table {
  .sortable-chosen {
    z-index: 101;
    background: $white;
    box-shadow: 0 4px 16px rgba($dark-1, 0.2), 0 1px 2px rgba($dark-1, 0.31);
    cursor: grabbing;
    will-change: box-shadow;

    .ac-Cell {
      @include tableOptionsGradient();
    }
  }
}

.o-objective-table-dragging .o-title-text {
  user-select: none;
}
</style>
