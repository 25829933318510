export const handleOrder = ({ newIndex, oldIndex, data }) => {
  let orderValue
  let newRealIndex
  if (oldIndex < newIndex) {
    // move to bottom
    const previousElement = data[newIndex - 1]
    const elBeforeOrderValue = previousElement.orderValue
    const elBeforeRealIndex = previousElement.realIndex
    orderValue = elBeforeOrderValue + 0.5
    newRealIndex = elBeforeRealIndex + 1
  } else {
    // move to top
    const previousElement = data[newIndex + 1]
    const elBeforeOrderValue = previousElement.orderValue
    const elBeforeRealIndex = previousElement.realIndex
    orderValue = elBeforeOrderValue - 0.5
    newRealIndex = elBeforeRealIndex - 1
  }

  return {
    orderValue,
    newRealIndex
  }
}
