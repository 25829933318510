<template>
  <AppModalWithConfirmation
    ref="appModalWithConfirmation"
    :confirm-close="areDataChanged"
    :show="show"
    :title="$t('labels.create')"
    class="lf-LabelModal"
    hide-hero
    @on-close="onLabelModalClose"
  >
    <FormFieldNext :label="$t('labels.label')">
      <AppInput
        ref="name"
        v-model.trim="formModel.name"
        max-length="50"
        size="xlg"
        style-type="primary"
      />
    </FormFieldNext>
    <template #footer-actions>
      <AppButton type="ghost-next" @click="close">
        {{ $t('action.cancel') }}
      </AppButton>
      <AppButton
        :disable="loading || isNameEmpty"
        :loading="loading"
        type="primary-next"
        @click="save"
      >
        {{ $t('action.create') }}
      </AppButton>
    </template>
  </AppModalWithConfirmation>
</template>

<script>
import { defineComponent } from 'vue'

import LabelsApiHandler from '@/api/labels'
import { handleError } from '@/utils/error-handling'
import { isStringEmpty } from '@/utils/general'

import AppModalWithConfirmation from '@/components/AppModalWithConfirmation'
import FormFieldNext from '@/components/form/FormFieldNext'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppInput from '@/components/ui/AppInput/AppInput'

const labelsApi = new LabelsApiHandler()

const DEFAULT_FORM_MODEL = {
  name: ''
}

export default defineComponent({
  name: 'LabelModal',

  components: {
    FormFieldNext,
    AppModalWithConfirmation,
    AppButton,
    AppInput
  },

  props: {
    show: {
      type: Boolean,
      default: false
    }
  },

  emits: { 'update:show': null, create: null },

  data() {
    return {
      formModel: { ...DEFAULT_FORM_MODEL },
      localFormModel: { ...DEFAULT_FORM_MODEL },
      loading: false
    }
  },

  computed: {
    areDataChanged() {
      return JSON.stringify(this.localFormModel) !== JSON.stringify(this.formModel)
    },

    isNameEmpty() {
      return isStringEmpty(this.formModel.name)
    }
  },

  watch: {
    show: {
      async handler(bool) {
        if (!bool) {
          this.onLabelModalClose()
          return
        }

        this.setFocusOnName()
      },

      immediate: true
    }
  },

  methods: {
    onLabelModalClose() {
      this.formModel = JSON.parse(JSON.stringify({ ...DEFAULT_FORM_MODEL }))
      this.localFormModel = JSON.parse(JSON.stringify({ ...DEFAULT_FORM_MODEL }))

      this.$emit('update:show', false)
    },

    async save() {
      this.loading = true
      let label = null

      try {
        label = await labelsApi.createLabel({ name: this.formModel.name })
        this.$emit('create', label)
      } catch (error) {
        handleError({ error })
      }

      this.loading = false
    },

    close() {
      this.$refs.appModalWithConfirmation.close()
    },

    setFocusOnName() {
      // nextTick is needed to make input always focused on modal window
      // opening because modal window(o-modal) has transition of opacity with 0.2s
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.name.focus()
        }, 100)
      })
    }
  }
})
</script>

<style lang="scss">
.lf-LabelModal .o-modal-footer {
  padding-top: 0;
}

.o-form-error.lf-FieldError {
  min-height: 20px;
}
</style>
