<template>
  <div
    :class="{
      'ain-Wrapper-readonly': readonly,
      'ain-Wrapper-column-layout': columnLayout
    }"
    class="ain-Wrapper"
  >
    <label v-if="label" :for="`input-${inputUid}`" class="ain-Label">
      {{ label }}
    </label>
    <div :class="classes">
      <input
        :id="`input-${inputUid}`"
        ref="inputReference"
        :readonly="readonly"
        class="ain-Input"
        v-bind="bindingAttrs"
        @blur="onBlur"
        @focus="onFocus"
        @keydown.enter="onKeyEnter"
        @keydown.esc="onKeyEsc"
      />
      <span v-if="hasArrowControls" class="ain-Arrows">
        <AppButton
          v-for="action in ARROWS_ACTIONS"
          :key="action.id"
          :disable="isArrowDisabled(action.id)"
          class="ain-Arrows_Button"
          height="12"
          icon="input-number-arrow"
          remove-padding
          type="default"
          width="24"
          @click="onArrowClick(action.id)"
        />
      </span>
      <ClearButton
        v-else-if="hasClearAction"
        v-visible="!readonly && modelValue !== ''"
        class="ain-ClearButton"
        @click="onClear"
      />
    </div>
  </div>
</template>

<script setup>
import IMask from 'imask'
import { clamp, debounce, isNull, isUndefined } from 'lodash'
import { onMounted, ref, computed, onBeforeUnmount, nextTick, watch, useAttrs } from 'vue'

import { uid } from '@/utils/uid'

import AppButton from '@/components/ui/AppButton/AppButton'
import ClearButton from '@/components/ui/ClearButton/ClearButton'

defineOptions({
  name: 'AppInputNumberNext'
})

const inputReference = ref(null)

const props = defineProps({
  fraction: {
    type: Number,
    default: 0
  },

  digitMaxLength: {
    type: Number,
    default: 4
  },

  modelValue: {
    type: [Number, String],
    required: true
  },

  label: {
    type: String,
    default: ''
  },

  readonly: {
    type: Boolean
  },

  suffix: {
    type: String,
    default: ''
  },

  prefix: {
    type: String,
    default: ''
  },

  returnValueAsString: {
    type: Boolean
  },

  columnLayout: {
    type: Boolean
  },

  allowNegative: {
    type: Boolean
  },

  max: {
    type: Number,
    default: null
  },

  min: {
    type: Number,
    default: null
  },

  hasClearAction: {
    type: Boolean
  },

  hasArrowControls: {
    type: Boolean
  },

  arrowControlsStep: {
    type: Number,
    default: 1
  },

  debounceTimeout: {
    type: Number,
    default: 0,
    validator: v => v >= 0 && Number.isSafeInteger(v) && Number.isFinite(v) && !Number.isNaN(v)
  },

  allowEmptyValue: {
    type: Boolean
  },

  autoFocus: {
    type: Boolean
  }
})

const ARROWS_ACTIONS = {
  INCREASE: {
    id: '+'
  },
  DECREASE: {
    id: '-'
  }
}

const inputUid = uid()

const classes = computed(() => {
  return {
    'ain-InputWrapper': true,
    // 'ain-InputWrapper-error': showError.value,
    'ain-InputWrapper-readonly': props.readonly,
    'ain-InputWrapper-with-action': props.hasClearAction || props.hasArrowControls
  }
})

const onBlur = () => {
  setInputValue()
  emit('blur')
}

const onFocus = () => {
  const value = mask.value.unmaskedValue ? mask.value.unmaskedValue : props.modelValue
  const isValueCanBeEmpty = props.allowEmptyValue && value === ''
  if (Number(value) === 0 && !isValueCanBeEmpty) {
    nextTick(() => {
      selectAll()
    })
  }
}

const focus = () => {
  inputReference.value.focus()
}

const selectAll = () => {
  inputReference.value.select()
}

const updateMask = () => setInputValue()

defineExpose({
  focus,
  selectAll,
  updateMask,
  onFocus
})

const onKeyEnter = () => {
  inputReference.value.blur()
}

const onKeyEsc = () => {
  inputReference.value.blur()
}

const setInputValue = () => {
  nextTick(() => {
    // create other instance of IMask to get correct masked value with prefix, suffix, separators, etc.
    const maskHandler = IMask.createMask(maskConfiguration.value)
    let valueForResolve = `${props.prefix} ${props.modelValue}${props.suffix}`

    if (props.prefix && !props.suffix && props.modelValue === '') {
      // that need to set focus in correct position
      // like USD | ; not USD|
      valueForResolve = `${props.prefix.trim()} `
      maskHandler.resolve(valueForResolve)
    } else {
      maskHandler.resolve(valueForResolve.trim())
    }

    inputReference.value.value = maskHandler.value
    mask.value?.updateValue()
  })
}

watch(
  () => [props.suffix, props.prefix],
  () => {
    mask.value.updateOptions({
      mask: `${props.prefix} num${props.suffix}`.trim()
    })
    setInputValue()
  }
)

watch(
  () => props.modelValue,
  () => {
    if (props.readonly) {
      updateMask()
    }
  }
)

watch(
  () => props.readonly,
  (newValue, oldValue) => {
    if (newValue !== oldValue && !isUndefined(oldValue)) {
      updateMask()
    }
  }
)

const mask = ref(null)

const getMaxDigit = num => Number(`1e${num}`) - 1

const calculateMin = ({ maxDigit, maxFraction }) => Number(`-${maxDigit}.${maxFraction}`)

const emit = defineEmits({ 'update:modelValue': null, blur: null })

const getMinValueForSpecifiedMinProp = ({ maxDigit, maxFraction }) => {
  if (props.allowNegative && props.min >= 0) {
    throw new Error('min prop must be negative if allowNegative is true')
  }

  if (!props.allowNegative && props.min < 0) {
    throw new Error('min prop must be positive if allowNegative is false')
  }

  const calculatedMin = calculateMin({ maxDigit, maxFraction })

  if (props.min < calculatedMin) {
    throw new Error(
      `min prop must be greater than ${calculatedMin} depending on digitMaxLength and fraction props`
    )
  }

  return Number(props.min)
}

const getMinValueForNotSpecifiedMinProp = ({ maxDigit, maxFraction }) => {
  if (props.allowNegative) {
    return Number(`-${maxDigit}.${maxFraction}`)
  }

  return 0
}

const MAP_TO_RADIX_SYMBOLS = [',', '+']

const maskConfiguration = computed(() => {
  const maxDigit = getMaxDigit(props.digitMaxLength)
  const maxFraction = getMaxDigit(props.fraction)

  let min

  if (!isNull(props.min)) {
    min = getMinValueForSpecifiedMinProp({ maxDigit, maxFraction })
  } else {
    min = getMinValueForNotSpecifiedMinProp({ maxDigit, maxFraction })
  }

  let max = Number(`${maxDigit}.${maxFraction}`)

  if (!isNull(props.max)) {
    max = props.max > min ? props.max : max
  }

  return {
    lazy: false,
    mask: `${props.prefix} num${props.suffix}`.trim(),
    blocks: {
      num: {
        mask: Number,
        // other options are optional with defaults below
        scale: props.fraction, // digits after point, 0 for integers
        signed: props.allowNegative, // disallow negative
        thousandsSeparator: ' ', // any single char
        padFractionalZeros: false, // if true, then pads zeros at end to the length of scale
        normalizeZeros: true, // appends or removes zeros at ends
        radix: '.', // fractional delimiter
        mapToRadix: props.allowNegative ? MAP_TO_RADIX_SYMBOLS : [...MAP_TO_RADIX_SYMBOLS, '-'], // symbols to process as radix
        max,
        min
      }
    }
  }
})
const initMask = () => {
  setInputValue()
  mask.value = IMask(inputReference.value, maskConfiguration.value)

  mask.value.on('accept', () => {
    if (props.debounceTimeout >= 200) {
      onDebouncedUpdateModelValue()
    } else {
      onUpdateModelValue()
    }
  })
}

const onUpdateModelValue = () => {
  let value = mask.value.unmaskedValue ? mask.value.unmaskedValue : 0

  const isEmptyValue = props.allowEmptyValue && mask.value.unmaskedValue === ''

  if (isEmptyValue) {
    value = ''
  }

  if (props.returnValueAsString) {
    emit('update:modelValue', value)
  } else {
    const resolvedValue = isEmptyValue ? value : Number(value)
    emit('update:modelValue', resolvedValue)
  }
}

const onDebouncedUpdateModelValue = debounce(onUpdateModelValue, props.debounceTimeout)

const onClear = async () => {
  emit('update:modelValue', '')
  await nextTick()
  setInputValue()

  focus()
}

const getNewValue = arrowId => {
  const currentValue = mask.value.unmaskedValue ? mask.value.unmaskedValue : props.modelValue

  const direction = Math.sign(Number(`${arrowId}1`))

  return Number(currentValue) + direction * props.arrowControlsStep
}

const isArrowDisabled = id => {
  if (!mask.value) {
    return true
  }

  if (props.readonly) {
    return true
  }

  const { min, max } = maskConfiguration.value.blocks.num

  const newValue = getNewValue(id)

  return newValue < min || newValue > max
}

const onArrowClick = id => {
  const { min, max } = maskConfiguration.value.blocks.num

  let newValue = getNewValue(id)

  newValue = clamp(newValue, min, max)

  if (props.returnValueAsString) {
    emit('update:modelValue', String(newValue))
  } else {
    emit('update:modelValue', newValue)
  }

  nextTick(() => {
    setInputValue()
    focus()
  })
}

onMounted(() => {
  initMask()

  if (!props.readonly && props.autoFocus) {
    focus()
  }
})

onBeforeUnmount(() => {
  mask.value.destroy()
})

const attrs = useAttrs()

const bindingAttrs = computed(() => {
  const DATA_TEST_ID = 'data-testid'
  const DATA_AUTO_TEST_ID = 'data-auto-testid'
  const INPUT_ELEMENT = 'input-element'
  return {
    ...attrs,
    [DATA_TEST_ID]: attrs[DATA_TEST_ID] ? `${attrs[DATA_TEST_ID]}-${INPUT_ELEMENT}` : null,

    [DATA_AUTO_TEST_ID]: attrs[DATA_AUTO_TEST_ID]
      ? `${attrs[DATA_AUTO_TEST_ID]}-${INPUT_ELEMENT}`
      : null
  }
})
</script>

<style lang="scss" scoped>
.ain-Wrapper {
  gap: 6px;
  font-family: $system-ui;
  max-width: 100%;
  position: relative;

  &:not(&-column-layout) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &-column-layout {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
  }
}
.ain-Label {
  font-weight: fw('bold');
  font-size: $fs-12;
  line-height: 16px;
  color: $dark-3;
  .ain-Wrapper-readonly & {
    color: $grey-1-next;
  }
}

.ain-Input {
  outline: none;
  font-family: inherit;
  color: $dark-1;
  font-style: normal;
  font-weight: var(--font-weight, fw('semi-bold'));
  font-size: var(--font-size, #{$fs-20});
  height: 100%;
  border: none;
  padding: 0 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: transparent;
  width: 100%;
  caret-color: $primary-color-next;

  &:read-only {
    color: $dark-3;
  }
}

.ain-InputWrapper {
  // overflow: hidden;
  border: 2px solid $grey-2-next;
  border-radius: $border-radius-sm-next;
  transition: border-color $transition-fast ease-in-out,
    background-color $transition-fast ease-in-out;
  height: 40px;
  background-color: $white;
  display: flex;
  align-items: center;
  position: relative;
  box-sizing: border-box;

  &-error {
    border-color: var(--grade-low-color-next);
  }

  &-readonly {
    border-color: $grey-2-next;
    background: $grey-2-next;
  }

  &-with-action {
    padding-right: 6px;
  }
}

.ain-InputWrapper:not(.ain-InputWrapper-error):not(.ain-InputWrapper-readonly) {
  &:focus-within {
    border-color: $primary-color-next;
    background-color: $white;
  }
}

.ain-InputWrapper:not(.ain-InputWrapper-error):not(.ain-InputWrapper-readonly):not(:focus-within) {
  &:hover {
    border-color: $grey-1-next;
    background-color: $grey-3-next;
  }
}

.ain-Error {
  position: absolute;
  right: -20px;
  display: flex;
}

.ain-Error_Icon {
  display: flex;
}

.ain-Error_Content {
  padding: 12px;
  font-size: $fs-12;
  font-family: $system-ui;
  line-height: 1.2;
}

.ain-ErrorsList {
  margin: 0;
  list-style: none;
}

.ain-ClearButton {
  flex-shrink: 0;
}

.ain-Arrows {
  flex-shrink: 0;
  height: 100%;
  width: 24px;
  display: flex;
  flex-direction: column;
}

.ain-Arrows_Button {
  height: 50%;
  width: 100%;
  display: flex;
  align-items: flex-end;

  + .ain-Arrows_Button {
    transform: rotate(180deg);
  }
}
</style>
