<template>
  <div
    :class="{ 'iter-Wrapper-expanded': expanded && isExpandable }"
    :style="rowStyle"
    class="iter-Wrapper"
  >
    <div class="iter-Wrapper_Content">
      <ContributeLine v-if="depth" class="iter-ContributeLine" />
      <div
        :id="`iter-Row-${item.id}`"
        :class="{
          'iter-Row-expandable': isExpandable,
          'iter-Row-hovered': hoverRow === getRowId(item.id),
          'iter-Row-filtered-out': !item.fitFilterCriteria,
          'iter-Row-selected': selectedItems.includes(item.id) && hasBulkAction
        }"
        class="iter-Row"
        data-testid="infinite-table-row"
        @click.self="onClickSelf"
      >
        <div
          v-for="column in resolvedColumns.bulkActionColumn"
          :key="column.key"
          :class="[`iter-Cell-${column.key}`]"
          :style="columnStyles[column.key]"
          class="iter-Cell-bulk-action"
        >
          <slot :column-key="column.key" :depth="depth" :item="item" name="cell">
            {{ item[column.key] }}
          </slot>
        </div>
        <AppButton
          v-if="isExpandable"
          :aria-expanded="expanded"
          :class="{
            'iter-IconArrow-expanded': expanded
          }"
          class="iter-IconArrow"
          data-testid="row-expand-collapse-button"
          height="24"
          icon="okr-table-expand"
          size="sm"
          type="subtle"
          width="24"
          @click="onExpandCollapse(item.id)"
        />

        <div
          v-for="column in resolvedColumns.columnsWithoutBulkAction"
          :key="column.key"
          :class="[`iter-Cell-${column.key}`]"
          :style="columnStyles[column.key]"
        >
          <slot :column-key="column.key" :depth="depth" :item="item" name="cell">
            {{ item[column.key] }}
          </slot>
        </div>
      </div>
    </div>

    <div
      :class="{
        'iter-Row_Border-row-selected': selectedItems.includes(item.id) && hasBulkAction
      }"
      class="iter-Row_Border"
    />

    <template v-if="isExpandable && expanded">
      <InfiniteTableExpandableRow
        v-for="childItem in item[childItemsKey]"
        :key="`${childItem.id}-${depth}`"
        :child-items-key="childItemsKey"
        :column-styles="columnStyles"
        :columns="columns"
        :depth="depth + 1"
        :hover-row="hoverRow"
        :item="childItem"
        :list-state="listState"
        :offset-left="offsetLeft"
        :offset-right="offsetRight"
        :selected-items="selectedItems"
        @toggle-expand-collapse="onExpandCollapse($event)"
      >
        <template #cell="slotData">
          <slot
            :column-key="slotData.columnKey"
            :depth="slotData.depth"
            :item="slotData.item"
            name="cell"
          />
        </template>
      </InfiniteTableExpandableRow>
    </template>
  </div>
</template>

<script setup>
import { computed } from 'vue'

import {
  EXPAND_COLLAPSE_BUTTON_WIDTH_WITH_GAP,
  memoizeInfiniteExpandableTableContributeLineStyle,
  memoizeInfiniteExpandableTableRowBorderStyle,
  memoizeInfiniteExpandableTableRowOffset
} from '@/utils/memoizations'
import { stringOrNullProp } from '@/utils/prop-validators'
import { TABLE_COLUMNS_KEYS } from '@/utils/table-columns'

import ContributeLine from '@/components/objectives/forms/ContributeLine'
import AppButton from '@/components/ui/AppButton/AppButton'
import { getRowId } from '@/components/ui/InfiniteTableExpandable/infinite-table-expandable-utils'

defineOptions({
  name: 'InfiniteTableExpandableRow'
})

const props = defineProps({
  item: {
    type: Object,
    required: true
  },

  depth: {
    type: Number,
    default: 0
  },

  columns: {
    type: Array,
    required: true
  },

  columnStyles: {
    type: Object,
    default: () => ({})
  },

  offsetLeft: {
    type: [String, Number],
    default: ''
  },

  offsetRight: {
    type: [String, Number],
    default: ''
  },

  hoverRow: {
    default: null,
    validator: v => stringOrNullProp(v)
  },

  selectedItems: {
    type: Array,
    default: () => []
  },

  childItemsKey: {
    type: String,
    default: 'childItems'
  },

  listState: {
    type: Object,
    required: true
  }
})

const hasBulkAction = computed(() => props.columns.some(column => column.isBulkAction))

const resolvedColumns = computed(() => {
  const columnsWithoutBulkAction = props.columns.filter(column => !column.isBulkAction)
  const bulkActionColumn = props.columns.filter(column => column.isBulkAction)

  return {
    columnsWithoutBulkAction,
    bulkActionColumn
  }
})

const isExpandable = computed(() => Boolean(props.item.childCount))

const expanded = computed(() => {
  return Boolean(props.listState.expandedItems[props.item.id])
})

const emit = defineEmits({
  'toggle-expand-collapse': null,
  'expand-item-chain': null
})
const onExpandCollapse = id => {
  emit('toggle-expand-collapse', id)
}

const rowStyle = computed(() => {
  const bulkActionSize = hasBulkAction.value
    ? props.columns.find(column => column.key === TABLE_COLUMNS_KEYS.BULK_CHECKBOX).width
    : 0

  const offset = memoizeInfiniteExpandableTableRowOffset(props.depth)
  const resolvedOffset = isExpandable.value
    ? offset
    : offset + EXPAND_COLLAPSE_BUTTON_WIDTH_WITH_GAP

  return {
    '--bulk-action-size': `${bulkActionSize}px`,
    '--table-offset-left': props.offsetLeft,
    '--table-offset-right': props.offsetRight,
    '--row-offset-left': `${resolvedOffset}px`,
    '--resolved-row-offset-left': hasBulkAction.value ? '0px' : `${resolvedOffset}px`,
    '--border-offset-left': memoizeInfiniteExpandableTableRowBorderStyle(offset),
    ...memoizeInfiniteExpandableTableContributeLineStyle(offset, isExpandable.value)
  }
})

const onClickSelf = () => {
  if (isExpandable.value) {
    onExpandCollapse(props.item.id)
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';
.iter-IconArrow {
  margin-right: 8px;
  width: 24px;
  flex-shrink: 0;

  &-expanded {
    transform: rotateZ(90deg);
  }

  .iter-Row-filtered-out & {
    opacity: 0.5;
  }
}

.iter-Wrapper {
  position: relative;
}

.iter-Wrapper-expanded {
  > .iter-Wrapper_Content {
    &:before {
      content: '';
      position: absolute;
      width: 1px;

      height: calc(100% - 62px); // 34 + 10 + 12 padding expand half height + 6px cl line
      top: 34px; // 10px padding top + 24px height of expand button

      left: calc(
        var(--row-offset-left, 0px) + (var(--table-offset-left, 0px)) + var(--bulk-action-size, 0px) +
          (var(--okr-table-row-controls-button-width, 0px) / 2)
      );
      background-color: $grey-2-next;
      z-index: 4;
      pointer-events: none;
    }
  }
}

// eslint-disable-next-line vue-scoped-css/no-unused-selector
.iter-Row {
  display: flex;
  align-items: center;
  line-height: 24px;
  position: relative;
  overflow: hidden;
  padding: 10px var(--table-offset-right, 0px) 10px
    calc(var(--resolved-row-offset-left, 0px) + var(--table-offset-left, 0px));
  border-radius: var(--row-border-radius, 0);

  // &-haschildren {
  //   cursor: pointer;
  // }

  &:hover,
  &-hovered,
  &-selected {
    background-color: $grey-3-next;
  }

  &.animation {
    // should be larger as next row z-index and table header
    // z-index: 6;

    transition: background-color $transition-normal cubic-bezier(0.1, 0.7, 1, 0.1);

    animation: shine 1500ms 1, growth 300ms 1;

    @keyframes growth {
      0% {
        height: 0;
      }
      100% {
        height: 44px;
      }
    }

    @keyframes shine {
      0% {
        background-color: $white;
      }
      50% {
        background-color: rgba($grade-high-color-next, 0.15);
      }
      100% {
        background-color: $white;
      }
    }
  }
}

.iter-ContributeLine {
  z-index: 1;
  --height: 6px;
  left: calc(var(--table-offset-left, 0px) + var(--cl-offset, 0px) + var(--bulk-action-size, 0px));
  position: absolute;
  --width: var(--cl-width, 0px);
  top: 16px; // 10 px row padding top + 12px (half height of row content) - 6px height of Line
}

.iter-Row_Border {
  height: 1px;

  --start-color: transparent;

  &-row-selected {
    --start-color: #{$grey-3-next};
  }

  @include tableLinesGradient(
    var(--start-color),
    $grey-2-next,
    calc(var(--table-offset-left, 0px) + var(--border-offset-left) + var(--bulk-action-size, 0px)),
    var(--table-offset-right, 0px)
  );
}

.iter-Cell-bulk-action {
  margin-right: var(--row-offset-left, 0px);
  height: 24px;
}
</style>
