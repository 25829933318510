const ICON_PREFIX = 'emoji-category-'

export const EMOJI_LIST = [
  {
    category: 'Smileys & Emotion',
    label: '🙂',
    icon: `${ICON_PREFIX}smileys`,
    data: [
      {
        emoji: '😀',
        category: 'Smileys & Emotion',
        searchQuery: 'grinning facesmileys & emotiongrinningsmilehappy'
      },
      {
        emoji: '😃',
        category: 'Smileys & Emotion',
        searchQuery: 'grinning face with big eyessmileys & emotionsmileyhappyjoyhaha'
      },
      {
        emoji: '😄',
        category: 'Smileys & Emotion',
        searchQuery: 'grinning face with smiling eyessmileys & emotionsmilehappyjoylaughpleased'
      },
      {
        emoji: '😁',
        category: 'Smileys & Emotion',
        searchQuery: 'beaming face with smiling eyessmileys & emotiongrin'
      },
      {
        emoji: '😆',
        category: 'Smileys & Emotion',
        searchQuery: 'grinning squinting facesmileys & emotionlaughingsatisfiedhappyhaha'
      },
      {
        emoji: '😅',
        category: 'Smileys & Emotion',
        searchQuery: 'grinning face with sweatsmileys & emotionsweat_smilehot'
      },
      {
        emoji: '🤣',
        category: 'Smileys & Emotion',
        searchQuery: 'rolling on the floor laughingsmileys & emotionrofllollaughing'
      },
      {
        emoji: '😂',
        category: 'Smileys & Emotion',
        searchQuery: 'face with tears of joysmileys & emotionjoytears'
      },
      {
        emoji: '🙂',
        category: 'Smileys & Emotion',
        searchQuery: 'slightly smiling facesmileys & emotionslightly_smiling_face'
      },
      {
        emoji: '🙃',
        category: 'Smileys & Emotion',
        searchQuery: 'upside-down facesmileys & emotionupside_down_face'
      },
      {
        emoji: '😉',
        category: 'Smileys & Emotion',
        searchQuery: 'winking facesmileys & emotionwinkflirt'
      },
      {
        emoji: '😊',
        category: 'Smileys & Emotion',
        searchQuery: 'smiling face with smiling eyessmileys & emotionblushproud'
      },
      {
        emoji: '😇',
        category: 'Smileys & Emotion',
        searchQuery: 'smiling face with halosmileys & emotioninnocentangel'
      },
      {
        emoji: '🥰',
        category: 'Smileys & Emotion',
        searchQuery: 'smiling face with heartssmileys & emotionsmiling_face_with_three_heartslove'
      },
      {
        emoji: '😍',
        category: 'Smileys & Emotion',
        searchQuery: 'smiling face with heart-eyessmileys & emotionheart_eyeslovecrush'
      },
      {
        emoji: '🤩',
        category: 'Smileys & Emotion',
        searchQuery: 'star-strucksmileys & emotionstar_struckeyes'
      },
      {
        emoji: '😘',
        category: 'Smileys & Emotion',
        searchQuery: 'face blowing a kisssmileys & emotionkissing_heartflirt'
      },
      {
        emoji: '😗',
        category: 'Smileys & Emotion',
        searchQuery: 'kissing facesmileys & emotionkissing'
      },
      {
        emoji: '☺️',
        category: 'Smileys & Emotion',
        searchQuery: 'smiling facesmileys & emotionrelaxedblushpleased'
      },
      {
        emoji: '😚',
        category: 'Smileys & Emotion',
        searchQuery: 'kissing face with closed eyessmileys & emotionkissing_closed_eyes'
      },
      {
        emoji: '😙',
        category: 'Smileys & Emotion',
        searchQuery: 'kissing face with smiling eyessmileys & emotionkissing_smiling_eyes'
      },
      {
        emoji: '🥲',
        category: 'Smileys & Emotion',
        searchQuery: 'smiling face with tearsmileys & emotionsmiling_face_with_tear'
      },
      {
        emoji: '😋',
        category: 'Smileys & Emotion',
        searchQuery: 'face savoring foodsmileys & emotionyumtonguelick'
      },
      {
        emoji: '😛',
        category: 'Smileys & Emotion',
        searchQuery: 'face with tonguesmileys & emotionstuck_out_tongue'
      },
      {
        emoji: '😜',
        category: 'Smileys & Emotion',
        searchQuery:
          'winking face with tonguesmileys & emotionstuck_out_tongue_winking_eyepranksilly'
      },
      {
        emoji: '🤪',
        category: 'Smileys & Emotion',
        searchQuery: 'zany facesmileys & emotionzany_facegoofywacky'
      },
      {
        emoji: '😝',
        category: 'Smileys & Emotion',
        searchQuery: 'squinting face with tonguesmileys & emotionstuck_out_tongue_closed_eyesprank'
      },
      {
        emoji: '🤑',
        category: 'Smileys & Emotion',
        searchQuery: 'money-mouth facesmileys & emotionmoney_mouth_facerich'
      },
      {
        emoji: '🤗',
        category: 'Smileys & Emotion',
        searchQuery: 'hugging facesmileys & emotionhugs'
      },
      {
        emoji: '🤭',
        category: 'Smileys & Emotion',
        searchQuery: 'face with hand over mouthsmileys & emotionhand_over_mouthquietwhoops'
      },
      {
        emoji: '🤫',
        category: 'Smileys & Emotion',
        searchQuery: 'shushing facesmileys & emotionshushing_facesilencequiet'
      },
      {
        emoji: '🤔',
        category: 'Smileys & Emotion',
        searchQuery: 'thinking facesmileys & emotionthinking'
      },
      {
        emoji: '🤐',
        category: 'Smileys & Emotion',
        searchQuery: 'zipper-mouth facesmileys & emotionzipper_mouth_facesilencehush'
      },
      {
        emoji: '🤨',
        category: 'Smileys & Emotion',
        searchQuery: 'face with raised eyebrowsmileys & emotionraised_eyebrowsuspicious'
      },
      {
        emoji: '😐',
        category: 'Smileys & Emotion',
        searchQuery: 'neutral facesmileys & emotionneutral_facemeh'
      },
      {
        emoji: '😑',
        category: 'Smileys & Emotion',
        searchQuery: 'expressionless facesmileys & emotionexpressionless'
      },
      {
        emoji: '😶',
        category: 'Smileys & Emotion',
        searchQuery: 'face without mouthsmileys & emotionno_mouthmutesilence'
      },
      {
        emoji: '😶‍🌫️',
        category: 'Smileys & Emotion',
        searchQuery: 'face in cloudssmileys & emotionface_in_clouds'
      },
      {
        emoji: '😏',
        category: 'Smileys & Emotion',
        searchQuery: 'smirking facesmileys & emotionsmirksmug'
      },
      {
        emoji: '😒',
        category: 'Smileys & Emotion',
        searchQuery: 'unamused facesmileys & emotionunamusedmeh'
      },
      {
        emoji: '🙄',
        category: 'Smileys & Emotion',
        searchQuery: 'face with rolling eyessmileys & emotionroll_eyes'
      },
      {
        emoji: '😬',
        category: 'Smileys & Emotion',
        searchQuery: 'grimacing facesmileys & emotiongrimacing'
      },
      {
        emoji: '😮‍💨',
        category: 'Smileys & Emotion',
        searchQuery: 'face exhalingsmileys & emotionface_exhaling'
      },
      {
        emoji: '🤥',
        category: 'Smileys & Emotion',
        searchQuery: 'lying facesmileys & emotionlying_faceliar'
      },
      {
        emoji: '😌',
        category: 'Smileys & Emotion',
        searchQuery: 'relieved facesmileys & emotionrelievedwhew'
      },
      {
        emoji: '😔',
        category: 'Smileys & Emotion',
        searchQuery: 'pensive facesmileys & emotionpensive'
      },
      {
        emoji: '😪',
        category: 'Smileys & Emotion',
        searchQuery: 'sleepy facesmileys & emotionsleepytired'
      },
      {
        emoji: '🤤',
        category: 'Smileys & Emotion',
        searchQuery: 'drooling facesmileys & emotiondrooling_face'
      },
      {
        emoji: '😴',
        category: 'Smileys & Emotion',
        searchQuery: 'sleeping facesmileys & emotionsleepingzzz'
      },
      {
        emoji: '😷',
        category: 'Smileys & Emotion',
        searchQuery: 'face with medical masksmileys & emotionmasksickill'
      },
      {
        emoji: '🤒',
        category: 'Smileys & Emotion',
        searchQuery: 'face with thermometersmileys & emotionface_with_thermometersick'
      },
      {
        emoji: '🤕',
        category: 'Smileys & Emotion',
        searchQuery: 'face with head-bandagesmileys & emotionface_with_head_bandagehurt'
      },
      {
        emoji: '🤢',
        category: 'Smileys & Emotion',
        searchQuery: 'nauseated facesmileys & emotionnauseated_facesickbarfdisgusted'
      },
      {
        emoji: '🤮',
        category: 'Smileys & Emotion',
        searchQuery: 'face vomitingsmileys & emotionvomiting_facebarfsick'
      },
      {
        emoji: '🤧',
        category: 'Smileys & Emotion',
        searchQuery: 'sneezing facesmileys & emotionsneezing_faceachoosick'
      },
      {
        emoji: '🥵',
        category: 'Smileys & Emotion',
        searchQuery: 'hot facesmileys & emotionhot_faceheatsweating'
      },
      {
        emoji: '🥶',
        category: 'Smileys & Emotion',
        searchQuery: 'cold facesmileys & emotioncold_facefreezingice'
      },
      {
        emoji: '🥴',
        category: 'Smileys & Emotion',
        searchQuery: 'woozy facesmileys & emotionwoozy_facegroggy'
      },
      {
        emoji: '😵',
        category: 'Smileys & Emotion',
        searchQuery: 'knocked-out facesmileys & emotiondizzy_face'
      },
      {
        emoji: '😵‍💫',
        category: 'Smileys & Emotion',
        searchQuery: 'face with spiral eyessmileys & emotionface_with_spiral_eyes'
      },
      {
        emoji: '🤯',
        category: 'Smileys & Emotion',
        searchQuery: 'exploding headsmileys & emotionexploding_headmindblown'
      },
      {
        emoji: '🤠',
        category: 'Smileys & Emotion',
        searchQuery: 'cowboy hat facesmileys & emotioncowboy_hat_face'
      },
      {
        emoji: '🥳',
        category: 'Smileys & Emotion',
        searchQuery: 'partying facesmileys & emotionpartying_facecelebrationbirthday'
      },
      {
        emoji: '🥸',
        category: 'Smileys & Emotion',
        searchQuery: 'disguised facesmileys & emotiondisguised_face'
      },
      {
        emoji: '😎',
        category: 'Smileys & Emotion',
        searchQuery: 'smiling face with sunglassessmileys & emotionsunglassescool'
      },
      {
        emoji: '🤓',
        category: 'Smileys & Emotion',
        searchQuery: 'nerd facesmileys & emotionnerd_facegeekglasses'
      },
      {
        emoji: '🧐',
        category: 'Smileys & Emotion',
        searchQuery: 'face with monoclesmileys & emotionmonocle_face'
      },
      {
        emoji: '😕',
        category: 'Smileys & Emotion',
        searchQuery: 'confused facesmileys & emotionconfused'
      },
      {
        emoji: '😟',
        category: 'Smileys & Emotion',
        searchQuery: 'worried facesmileys & emotionworriednervous'
      },
      {
        emoji: '🙁',
        category: 'Smileys & Emotion',
        searchQuery: 'slightly frowning facesmileys & emotionslightly_frowning_face'
      },
      {
        emoji: '☹️',
        category: 'Smileys & Emotion',
        searchQuery: 'frowning facesmileys & emotionfrowning_face'
      },
      {
        emoji: '😮',
        category: 'Smileys & Emotion',
        searchQuery: 'face with open mouthsmileys & emotionopen_mouthsurpriseimpressedwow'
      },
      {
        emoji: '😯',
        category: 'Smileys & Emotion',
        searchQuery: 'hushed facesmileys & emotionhushedsilencespeechless'
      },
      {
        emoji: '😲',
        category: 'Smileys & Emotion',
        searchQuery: 'astonished facesmileys & emotionastonishedamazedgasp'
      },
      {
        emoji: '😳',
        category: 'Smileys & Emotion',
        searchQuery: 'flushed facesmileys & emotionflushed'
      },
      {
        emoji: '🥺',
        category: 'Smileys & Emotion',
        searchQuery: 'pleading facesmileys & emotionpleading_facepuppyeyes'
      },
      {
        emoji: '😦',
        category: 'Smileys & Emotion',
        searchQuery: 'frowning face with open mouthsmileys & emotionfrowning'
      },
      {
        emoji: '😧',
        category: 'Smileys & Emotion',
        searchQuery: 'anguished facesmileys & emotionanguishedstunned'
      },
      {
        emoji: '😨',
        category: 'Smileys & Emotion',
        searchQuery: 'fearful facesmileys & emotionfearfulscaredshockedoops'
      },
      {
        emoji: '😰',
        category: 'Smileys & Emotion',
        searchQuery: 'anxious face with sweatsmileys & emotioncold_sweatnervous'
      },
      {
        emoji: '😥',
        category: 'Smileys & Emotion',
        searchQuery: 'sad but relieved facesmileys & emotiondisappointed_relievedphewsweatnervous'
      },
      {
        emoji: '😢',
        category: 'Smileys & Emotion',
        searchQuery: 'crying facesmileys & emotioncrysadtear'
      },
      {
        emoji: '😭',
        category: 'Smileys & Emotion',
        searchQuery: 'loudly crying facesmileys & emotionsobsadcrybawling'
      },
      {
        emoji: '😱',
        category: 'Smileys & Emotion',
        searchQuery: 'face screaming in fearsmileys & emotionscreamhorrorshocked'
      },
      {
        emoji: '😖',
        category: 'Smileys & Emotion',
        searchQuery: 'confounded facesmileys & emotionconfounded'
      },
      {
        emoji: '😣',
        category: 'Smileys & Emotion',
        searchQuery: 'persevering facesmileys & emotionperseverestruggling'
      },
      {
        emoji: '😞',
        category: 'Smileys & Emotion',
        searchQuery: 'disappointed facesmileys & emotiondisappointedsad'
      },
      {
        emoji: '😓',
        category: 'Smileys & Emotion',
        searchQuery: 'downcast face with sweatsmileys & emotionsweat'
      },
      {
        emoji: '😩',
        category: 'Smileys & Emotion',
        searchQuery: 'weary facesmileys & emotionwearytired'
      },
      {
        emoji: '😫',
        category: 'Smileys & Emotion',
        searchQuery: 'tired facesmileys & emotiontired_faceupsetwhine'
      },
      {
        emoji: '🥱',
        category: 'Smileys & Emotion',
        searchQuery: 'yawning facesmileys & emotionyawning_face'
      },
      {
        emoji: '😤',
        category: 'Smileys & Emotion',
        searchQuery: 'face with steam from nosesmileys & emotiontriumphsmug'
      },
      {
        emoji: '😡',
        category: 'Smileys & Emotion',
        searchQuery: 'pouting facesmileys & emotionragepoutangry'
      },
      {
        emoji: '😠',
        category: 'Smileys & Emotion',
        searchQuery: 'angry facesmileys & emotionangrymadannoyed'
      },
      {
        emoji: '🤬',
        category: 'Smileys & Emotion',
        searchQuery: 'face with symbols on mouthsmileys & emotioncursing_facefoul'
      },
      {
        emoji: '😈',
        category: 'Smileys & Emotion',
        searchQuery: 'smiling face with hornssmileys & emotionsmiling_impdevilevilhorns'
      },
      {
        emoji: '👿',
        category: 'Smileys & Emotion',
        searchQuery: 'angry face with hornssmileys & emotionimpangrydevilevilhorns'
      },
      {
        emoji: '💀',
        category: 'Smileys & Emotion',
        searchQuery: 'skullsmileys & emotionskulldeaddangerpoison'
      },
      {
        emoji: '☠️',
        category: 'Smileys & Emotion',
        searchQuery: 'skull and crossbonessmileys & emotionskull_and_crossbonesdangerpirate'
      },
      {
        emoji: '💩',
        category: 'Smileys & Emotion',
        searchQuery: 'pile of poosmileys & emotionhankeypoopshitcrap'
      },
      {
        emoji: '🤡',
        category: 'Smileys & Emotion',
        searchQuery: 'clown facesmileys & emotionclown_face'
      },
      {
        emoji: '👹',
        category: 'Smileys & Emotion',
        searchQuery: 'ogresmileys & emotionjapanese_ogremonster'
      },
      {
        emoji: '👺',
        category: 'Smileys & Emotion',
        searchQuery: 'goblinsmileys & emotionjapanese_goblin'
      },
      {
        emoji: '👻',
        category: 'Smileys & Emotion',
        searchQuery: 'ghostsmileys & emotionghosthalloween'
      },
      { emoji: '👽', category: 'Smileys & Emotion', searchQuery: 'aliensmileys & emotionalienufo' },
      {
        emoji: '👾',
        category: 'Smileys & Emotion',
        searchQuery: 'alien monstersmileys & emotionspace_invadergameretro'
      },
      { emoji: '🤖', category: 'Smileys & Emotion', searchQuery: 'robotsmileys & emotionrobot' },
      {
        emoji: '😺',
        category: 'Smileys & Emotion',
        searchQuery: 'grinning catsmileys & emotionsmiley_cat'
      },
      {
        emoji: '😸',
        category: 'Smileys & Emotion',
        searchQuery: 'grinning cat with smiling eyessmileys & emotionsmile_cat'
      },
      {
        emoji: '😹',
        category: 'Smileys & Emotion',
        searchQuery: 'cat with tears of joysmileys & emotionjoy_cat'
      },
      {
        emoji: '😻',
        category: 'Smileys & Emotion',
        searchQuery: 'smiling cat with heart-eyessmileys & emotionheart_eyes_cat'
      },
      {
        emoji: '😼',
        category: 'Smileys & Emotion',
        searchQuery: 'cat with wry smilesmileys & emotionsmirk_cat'
      },
      {
        emoji: '😽',
        category: 'Smileys & Emotion',
        searchQuery: 'kissing catsmileys & emotionkissing_cat'
      },
      {
        emoji: '🙀',
        category: 'Smileys & Emotion',
        searchQuery: 'weary catsmileys & emotionscream_cathorror'
      },
      {
        emoji: '😿',
        category: 'Smileys & Emotion',
        searchQuery: 'crying catsmileys & emotioncrying_cat_facesadtear'
      },
      {
        emoji: '😾',
        category: 'Smileys & Emotion',
        searchQuery: 'pouting catsmileys & emotionpouting_cat'
      },
      {
        emoji: '🙈',
        category: 'Smileys & Emotion',
        searchQuery: 'see-no-evil monkeysmileys & emotionsee_no_evilmonkeyblindignore'
      },
      {
        emoji: '🙉',
        category: 'Smileys & Emotion',
        searchQuery: 'hear-no-evil monkeysmileys & emotionhear_no_evilmonkeydeaf'
      },
      {
        emoji: '🙊',
        category: 'Smileys & Emotion',
        searchQuery: 'speak-no-evil monkeysmileys & emotionspeak_no_evilmonkeymutehush'
      },
      {
        emoji: '💋',
        category: 'Smileys & Emotion',
        searchQuery: 'kiss marksmileys & emotionkisslipstick'
      },
      {
        emoji: '💌',
        category: 'Smileys & Emotion',
        searchQuery: 'love lettersmileys & emotionlove_letteremailenvelope'
      },
      {
        emoji: '💘',
        category: 'Smileys & Emotion',
        searchQuery: 'heart with arrowsmileys & emotioncupidloveheart'
      },
      {
        emoji: '💝',
        category: 'Smileys & Emotion',
        searchQuery: 'heart with ribbonsmileys & emotiongift_heartchocolates'
      },
      {
        emoji: '💖',
        category: 'Smileys & Emotion',
        searchQuery: 'sparkling heartsmileys & emotionsparkling_heart'
      },
      {
        emoji: '💗',
        category: 'Smileys & Emotion',
        searchQuery: 'growing heartsmileys & emotionheartpulse'
      },
      {
        emoji: '💓',
        category: 'Smileys & Emotion',
        searchQuery: 'beating heartsmileys & emotionheartbeat'
      },
      {
        emoji: '💞',
        category: 'Smileys & Emotion',
        searchQuery: 'revolving heartssmileys & emotionrevolving_hearts'
      },
      {
        emoji: '💕',
        category: 'Smileys & Emotion',
        searchQuery: 'two heartssmileys & emotiontwo_hearts'
      },
      {
        emoji: '💟',
        category: 'Smileys & Emotion',
        searchQuery: 'heart decorationsmileys & emotionheart_decoration'
      },
      {
        emoji: '❣️',
        category: 'Smileys & Emotion',
        searchQuery: 'heart exclamationsmileys & emotionheavy_heart_exclamation'
      },
      {
        emoji: '💔',
        category: 'Smileys & Emotion',
        searchQuery: 'broken heartsmileys & emotionbroken_heart'
      },
      {
        emoji: '❤️‍🔥',
        category: 'Smileys & Emotion',
        searchQuery: 'heart on firesmileys & emotionheart_on_fire'
      },
      {
        emoji: '❤️‍🩹',
        category: 'Smileys & Emotion',
        searchQuery: 'mending heartsmileys & emotionmending_heart'
      },
      {
        emoji: '❤️',
        category: 'Smileys & Emotion',
        searchQuery: 'red heartsmileys & emotionheartlove'
      },
      {
        emoji: '🧡',
        category: 'Smileys & Emotion',
        searchQuery: 'orange heartsmileys & emotionorange_heart'
      },
      {
        emoji: '💛',
        category: 'Smileys & Emotion',
        searchQuery: 'yellow heartsmileys & emotionyellow_heart'
      },
      {
        emoji: '💚',
        category: 'Smileys & Emotion',
        searchQuery: 'green heartsmileys & emotiongreen_heart'
      },
      {
        emoji: '💙',
        category: 'Smileys & Emotion',
        searchQuery: 'blue heartsmileys & emotionblue_heart'
      },
      {
        emoji: '💜',
        category: 'Smileys & Emotion',
        searchQuery: 'purple heartsmileys & emotionpurple_heart'
      },
      {
        emoji: '🤎',
        category: 'Smileys & Emotion',
        searchQuery: 'brown heartsmileys & emotionbrown_heart'
      },
      {
        emoji: '🖤',
        category: 'Smileys & Emotion',
        searchQuery: 'black heartsmileys & emotionblack_heart'
      },
      {
        emoji: '🤍',
        category: 'Smileys & Emotion',
        searchQuery: 'white heartsmileys & emotionwhite_heart'
      },
      {
        emoji: '💯',
        category: 'Smileys & Emotion',
        searchQuery: 'hundred pointssmileys & emotion100scoreperfect'
      },
      {
        emoji: '💢',
        category: 'Smileys & Emotion',
        searchQuery: 'anger symbolsmileys & emotionangerangry'
      },
      {
        emoji: '💥',
        category: 'Smileys & Emotion',
        searchQuery: 'collisionsmileys & emotionboomcollisionexplode'
      },
      {
        emoji: '💫',
        category: 'Smileys & Emotion',
        searchQuery: 'dizzysmileys & emotiondizzystar'
      },
      {
        emoji: '💦',
        category: 'Smileys & Emotion',
        searchQuery: 'sweat dropletssmileys & emotionsweat_dropswaterworkout'
      },
      {
        emoji: '💨',
        category: 'Smileys & Emotion',
        searchQuery: 'dashing awaysmileys & emotiondashwindblowfast'
      },
      { emoji: '🕳️', category: 'Smileys & Emotion', searchQuery: 'holesmileys & emotionhole' },
      { emoji: '💣', category: 'Smileys & Emotion', searchQuery: 'bombsmileys & emotionbombboom' },
      {
        emoji: '💬',
        category: 'Smileys & Emotion',
        searchQuery: 'speech balloonsmileys & emotionspeech_ballooncomment'
      },
      {
        emoji: '👁️‍🗨️',
        category: 'Smileys & Emotion',
        searchQuery: 'eye in speech bubblesmileys & emotioneye_speech_bubble'
      },
      {
        emoji: '🗨️',
        category: 'Smileys & Emotion',
        searchQuery: 'left speech bubblesmileys & emotionleft_speech_bubble'
      },
      {
        emoji: '🗯️',
        category: 'Smileys & Emotion',
        searchQuery: 'right anger bubblesmileys & emotionright_anger_bubble'
      },
      {
        emoji: '💭',
        category: 'Smileys & Emotion',
        searchQuery: 'thought balloonsmileys & emotionthought_balloonthinking'
      },
      { emoji: '💤', category: 'Smileys & Emotion', searchQuery: 'zzzsmileys & emotionzzzsleeping' }
    ]
  },
  {
    category: 'People & Body',
    label: '✋',
    icon: `${ICON_PREFIX}people`,
    data: [
      {
        emoji: '👋',
        category: 'People & Body',
        searchQuery: 'waving handpeople & bodywavegoodbye'
      },
      {
        emoji: '🤚',
        category: 'People & Body',
        searchQuery: 'raised back of handpeople & bodyraised_back_of_hand'
      },
      {
        emoji: '🖐️',
        category: 'People & Body',
        searchQuery: 'hand with fingers splayedpeople & bodyraised_hand_with_fingers_splayed'
      },
      {
        emoji: '✋',
        category: 'People & Body',
        searchQuery: 'raised handpeople & bodyhandraised_handhighfivestop'
      },
      {
        emoji: '🖖',
        category: 'People & Body',
        searchQuery: 'vulcan salutepeople & bodyvulcan_saluteprosperspock'
      },
      { emoji: '👌', category: 'People & Body', searchQuery: 'ok handpeople & bodyok_hand' },
      {
        emoji: '🤌',
        category: 'People & Body',
        searchQuery: 'pinched fingerspeople & bodypinched_fingers'
      },
      {
        emoji: '🤏',
        category: 'People & Body',
        searchQuery: 'pinching handpeople & bodypinching_hand'
      },
      {
        emoji: '✌️',
        category: 'People & Body',
        searchQuery: 'victory handpeople & bodyvvictorypeace'
      },
      {
        emoji: '🤞',
        category: 'People & Body',
        searchQuery: 'crossed fingerspeople & bodycrossed_fingersluckhopeful'
      },
      {
        emoji: '🤟',
        category: 'People & Body',
        searchQuery: 'love-you gesturepeople & bodylove_you_gesture'
      },
      {
        emoji: '🤘',
        category: 'People & Body',
        searchQuery: 'sign of the hornspeople & bodymetal'
      },
      {
        emoji: '🤙',
        category: 'People & Body',
        searchQuery: 'call me handpeople & bodycall_me_hand'
      },
      {
        emoji: '👈',
        category: 'People & Body',
        searchQuery: 'backhand index pointing leftpeople & bodypoint_left'
      },
      {
        emoji: '👉',
        category: 'People & Body',
        searchQuery: 'backhand index pointing rightpeople & bodypoint_right'
      },
      {
        emoji: '👆',
        category: 'People & Body',
        searchQuery: 'backhand index pointing uppeople & bodypoint_up_2'
      },
      {
        emoji: '🖕',
        category: 'People & Body',
        searchQuery: 'middle fingerpeople & bodymiddle_fingerfu'
      },
      {
        emoji: '👇',
        category: 'People & Body',
        searchQuery: 'backhand index pointing downpeople & bodypoint_down'
      },
      {
        emoji: '☝️',
        category: 'People & Body',
        searchQuery: 'index pointing uppeople & bodypoint_up'
      },
      {
        emoji: '👍',
        category: 'People & Body',
        searchQuery: 'thumbs uppeople & body+1thumbsupapproveok'
      },
      {
        emoji: '👎',
        category: 'People & Body',
        searchQuery: 'thumbs downpeople & body-1thumbsdowndisapprovebury'
      },
      {
        emoji: '✊',
        category: 'People & Body',
        searchQuery: 'raised fistpeople & bodyfist_raisedfistpower'
      },
      {
        emoji: '👊',
        category: 'People & Body',
        searchQuery: 'oncoming fistpeople & bodyfist_oncomingfacepunchpunchattack'
      },
      {
        emoji: '🤛',
        category: 'People & Body',
        searchQuery: 'left-facing fistpeople & bodyfist_left'
      },
      {
        emoji: '🤜',
        category: 'People & Body',
        searchQuery: 'right-facing fistpeople & bodyfist_right'
      },
      {
        emoji: '👏',
        category: 'People & Body',
        searchQuery: 'clapping handspeople & bodyclappraiseapplause'
      },
      {
        emoji: '🙌',
        category: 'People & Body',
        searchQuery: 'raising handspeople & bodyraised_handshooray'
      },
      { emoji: '👐', category: 'People & Body', searchQuery: 'open handspeople & bodyopen_hands' },
      {
        emoji: '🤲',
        category: 'People & Body',
        searchQuery: 'palms up togetherpeople & bodypalms_up_together'
      },
      {
        emoji: '🤝',
        category: 'People & Body',
        searchQuery: 'handshakepeople & bodyhandshakedeal'
      },
      {
        emoji: '🙏',
        category: 'People & Body',
        searchQuery: 'folded handspeople & bodypraypleasehopewish'
      },
      {
        emoji: '✍️',
        category: 'People & Body',
        searchQuery: 'writing handpeople & bodywriting_hand'
      },
      {
        emoji: '💅',
        category: 'People & Body',
        searchQuery: 'nail polishpeople & bodynail_carebeautymanicure'
      },
      { emoji: '🤳', category: 'People & Body', searchQuery: 'selfiepeople & bodyselfie' },
      {
        emoji: '💪',
        category: 'People & Body',
        searchQuery: 'flexed bicepspeople & bodymuscleflexbicepstrongworkout'
      },
      {
        emoji: '🦾',
        category: 'People & Body',
        searchQuery: 'mechanical armpeople & bodymechanical_arm'
      },
      {
        emoji: '🦿',
        category: 'People & Body',
        searchQuery: 'mechanical legpeople & bodymechanical_leg'
      },
      { emoji: '🦵', category: 'People & Body', searchQuery: 'legpeople & bodyleg' },
      { emoji: '🦶', category: 'People & Body', searchQuery: 'footpeople & bodyfoot' },
      { emoji: '👂', category: 'People & Body', searchQuery: 'earpeople & bodyearhearsoundlisten' },
      {
        emoji: '🦻',
        category: 'People & Body',
        searchQuery: 'ear with hearing aidpeople & bodyear_with_hearing_aid'
      },
      { emoji: '👃', category: 'People & Body', searchQuery: 'nosepeople & bodynosesmell' },
      { emoji: '🧠', category: 'People & Body', searchQuery: 'brainpeople & bodybrain' },
      {
        emoji: '🫀',
        category: 'People & Body',
        searchQuery: 'anatomical heartpeople & bodyanatomical_heart'
      },
      { emoji: '🫁', category: 'People & Body', searchQuery: 'lungspeople & bodylungs' },
      { emoji: '🦷', category: 'People & Body', searchQuery: 'toothpeople & bodytooth' },
      { emoji: '🦴', category: 'People & Body', searchQuery: 'bonepeople & bodybone' },
      { emoji: '👀', category: 'People & Body', searchQuery: 'eyespeople & bodyeyeslookseewatch' },
      { emoji: '👁️', category: 'People & Body', searchQuery: 'eyepeople & bodyeye' },
      { emoji: '👅', category: 'People & Body', searchQuery: 'tonguepeople & bodytonguetaste' },
      { emoji: '👄', category: 'People & Body', searchQuery: 'mouthpeople & bodylipskiss' },
      { emoji: '👶', category: 'People & Body', searchQuery: 'babypeople & bodybabychildnewborn' },
      { emoji: '🧒', category: 'People & Body', searchQuery: 'childpeople & bodychild' },
      { emoji: '👦', category: 'People & Body', searchQuery: 'boypeople & bodyboychild' },
      { emoji: '👧', category: 'People & Body', searchQuery: 'girlpeople & bodygirlchild' },
      { emoji: '🧑', category: 'People & Body', searchQuery: 'personpeople & bodyadult' },
      {
        emoji: '👱',
        category: 'People & Body',
        searchQuery: 'person: blond hairpeople & bodyblond_haired_person'
      },
      {
        emoji: '👨',
        category: 'People & Body',
        searchQuery: 'manpeople & bodymanmustachefatherdad'
      },
      {
        emoji: '🧔',
        category: 'People & Body',
        searchQuery: 'person: beardpeople & bodybearded_person'
      },
      { emoji: '🧔‍♂️', category: 'People & Body', searchQuery: 'man: beardpeople & bodyman_beard' },
      {
        emoji: '🧔‍♀️',
        category: 'People & Body',
        searchQuery: 'woman: beardpeople & bodywoman_beard'
      },
      {
        emoji: '👨‍🦰',
        category: 'People & Body',
        searchQuery: 'man: red hairpeople & bodyred_haired_man'
      },
      {
        emoji: '👨‍🦱',
        category: 'People & Body',
        searchQuery: 'man: curly hairpeople & bodycurly_haired_man'
      },
      {
        emoji: '👨‍🦳',
        category: 'People & Body',
        searchQuery: 'man: white hairpeople & bodywhite_haired_man'
      },
      { emoji: '👨‍🦲', category: 'People & Body', searchQuery: 'man: baldpeople & bodybald_man' },
      { emoji: '👩', category: 'People & Body', searchQuery: 'womanpeople & bodywomangirls' },
      {
        emoji: '👩‍🦰',
        category: 'People & Body',
        searchQuery: 'woman: red hairpeople & bodyred_haired_woman'
      },
      {
        emoji: '🧑‍🦰',
        category: 'People & Body',
        searchQuery: 'person: red hairpeople & bodyperson_red_hair'
      },
      {
        emoji: '👩‍🦱',
        category: 'People & Body',
        searchQuery: 'woman: curly hairpeople & bodycurly_haired_woman'
      },
      {
        emoji: '🧑‍🦱',
        category: 'People & Body',
        searchQuery: 'person: curly hairpeople & bodyperson_curly_hair'
      },
      {
        emoji: '👩‍🦳',
        category: 'People & Body',
        searchQuery: 'woman: white hairpeople & bodywhite_haired_woman'
      },
      {
        emoji: '🧑‍🦳',
        category: 'People & Body',
        searchQuery: 'person: white hairpeople & bodyperson_white_hair'
      },
      { emoji: '👩‍🦲', category: 'People & Body', searchQuery: 'woman: baldpeople & bodybald_woman' },
      {
        emoji: '🧑‍🦲',
        category: 'People & Body',
        searchQuery: 'person: baldpeople & bodyperson_bald'
      },
      {
        emoji: '👱‍♀️',
        category: 'People & Body',
        searchQuery: 'woman: blond hairpeople & bodyblond_haired_womanblonde_woman'
      },
      {
        emoji: '👱‍♂️',
        category: 'People & Body',
        searchQuery: 'man: blond hairpeople & bodyblond_haired_man'
      },
      {
        emoji: '🧓',
        category: 'People & Body',
        searchQuery: 'older personpeople & bodyolder_adult'
      },
      { emoji: '👴', category: 'People & Body', searchQuery: 'old manpeople & bodyolder_man' },
      { emoji: '👵', category: 'People & Body', searchQuery: 'old womanpeople & bodyolder_woman' },
      {
        emoji: '🙍',
        category: 'People & Body',
        searchQuery: 'person frowningpeople & bodyfrowning_person'
      },
      {
        emoji: '🙍‍♂️',
        category: 'People & Body',
        searchQuery: 'man frowningpeople & bodyfrowning_man'
      },
      {
        emoji: '🙍‍♀️',
        category: 'People & Body',
        searchQuery: 'woman frowningpeople & bodyfrowning_woman'
      },
      {
        emoji: '🙎',
        category: 'People & Body',
        searchQuery: 'person poutingpeople & bodypouting_face'
      },
      {
        emoji: '🙎‍♂️',
        category: 'People & Body',
        searchQuery: 'man poutingpeople & bodypouting_man'
      },
      {
        emoji: '🙎‍♀️',
        category: 'People & Body',
        searchQuery: 'woman poutingpeople & bodypouting_woman'
      },
      {
        emoji: '🙅',
        category: 'People & Body',
        searchQuery: 'person gesturing nopeople & bodyno_goodstophaltdenied'
      },
      {
        emoji: '🙅‍♂️',
        category: 'People & Body',
        searchQuery: 'man gesturing nopeople & bodyno_good_manng_manstophaltdenied'
      },
      {
        emoji: '🙅‍♀️',
        category: 'People & Body',
        searchQuery: 'woman gesturing nopeople & bodyno_good_womanng_womanstophaltdenied'
      },
      {
        emoji: '🙆',
        category: 'People & Body',
        searchQuery: 'person gesturing okpeople & bodyok_person'
      },
      {
        emoji: '🙆‍♂️',
        category: 'People & Body',
        searchQuery: 'man gesturing okpeople & bodyok_man'
      },
      {
        emoji: '🙆‍♀️',
        category: 'People & Body',
        searchQuery: 'woman gesturing okpeople & bodyok_woman'
      },
      {
        emoji: '💁',
        category: 'People & Body',
        searchQuery: 'person tipping handpeople & bodytipping_hand_personinformation_desk_person'
      },
      {
        emoji: '💁‍♂️',
        category: 'People & Body',
        searchQuery: 'man tipping handpeople & bodytipping_hand_mansassy_maninformation'
      },
      {
        emoji: '💁‍♀️',
        category: 'People & Body',
        searchQuery: 'woman tipping handpeople & bodytipping_hand_womansassy_womaninformation'
      },
      {
        emoji: '🙋',
        category: 'People & Body',
        searchQuery: 'person raising handpeople & bodyraising_hand'
      },
      {
        emoji: '🙋‍♂️',
        category: 'People & Body',
        searchQuery: 'man raising handpeople & bodyraising_hand_man'
      },
      {
        emoji: '🙋‍♀️',
        category: 'People & Body',
        searchQuery: 'woman raising handpeople & bodyraising_hand_woman'
      },
      {
        emoji: '🧏',
        category: 'People & Body',
        searchQuery: 'deaf personpeople & bodydeaf_person'
      },
      { emoji: '🧏‍♂️', category: 'People & Body', searchQuery: 'deaf manpeople & bodydeaf_man' },
      { emoji: '🧏‍♀️', category: 'People & Body', searchQuery: 'deaf womanpeople & bodydeaf_woman' },
      {
        emoji: '🙇',
        category: 'People & Body',
        searchQuery: 'person bowingpeople & bodybowrespectthanks'
      },
      {
        emoji: '🙇‍♂️',
        category: 'People & Body',
        searchQuery: 'man bowingpeople & bodybowing_manrespectthanks'
      },
      {
        emoji: '🙇‍♀️',
        category: 'People & Body',
        searchQuery: 'woman bowingpeople & bodybowing_womanrespectthanks'
      },
      {
        emoji: '🤦',
        category: 'People & Body',
        searchQuery: 'person facepalmingpeople & bodyfacepalm'
      },
      {
        emoji: '🤦‍♂️',
        category: 'People & Body',
        searchQuery: 'man facepalmingpeople & bodyman_facepalming'
      },
      {
        emoji: '🤦‍♀️',
        category: 'People & Body',
        searchQuery: 'woman facepalmingpeople & bodywoman_facepalming'
      },
      { emoji: '🤷', category: 'People & Body', searchQuery: 'person shruggingpeople & bodyshrug' },
      {
        emoji: '🤷‍♂️',
        category: 'People & Body',
        searchQuery: 'man shruggingpeople & bodyman_shrugging'
      },
      {
        emoji: '🤷‍♀️',
        category: 'People & Body',
        searchQuery: 'woman shruggingpeople & bodywoman_shrugging'
      },
      {
        emoji: '🧑‍⚕️',
        category: 'People & Body',
        searchQuery: 'health workerpeople & bodyhealth_worker'
      },
      {
        emoji: '👨‍⚕️',
        category: 'People & Body',
        searchQuery: 'man health workerpeople & bodyman_health_workerdoctornurse'
      },
      {
        emoji: '👩‍⚕️',
        category: 'People & Body',
        searchQuery: 'woman health workerpeople & bodywoman_health_workerdoctornurse'
      },
      { emoji: '🧑‍🎓', category: 'People & Body', searchQuery: 'studentpeople & bodystudent' },
      {
        emoji: '👨‍🎓',
        category: 'People & Body',
        searchQuery: 'man studentpeople & bodyman_studentgraduation'
      },
      {
        emoji: '👩‍🎓',
        category: 'People & Body',
        searchQuery: 'woman studentpeople & bodywoman_studentgraduation'
      },
      { emoji: '🧑‍🏫', category: 'People & Body', searchQuery: 'teacherpeople & bodyteacher' },
      {
        emoji: '👨‍🏫',
        category: 'People & Body',
        searchQuery: 'man teacherpeople & bodyman_teacherschoolprofessor'
      },
      {
        emoji: '👩‍🏫',
        category: 'People & Body',
        searchQuery: 'woman teacherpeople & bodywoman_teacherschoolprofessor'
      },
      { emoji: '🧑‍⚖️', category: 'People & Body', searchQuery: 'judgepeople & bodyjudge' },
      {
        emoji: '👨‍⚖️',
        category: 'People & Body',
        searchQuery: 'man judgepeople & bodyman_judgejustice'
      },
      {
        emoji: '👩‍⚖️',
        category: 'People & Body',
        searchQuery: 'woman judgepeople & bodywoman_judgejustice'
      },
      { emoji: '🧑‍🌾', category: 'People & Body', searchQuery: 'farmerpeople & bodyfarmer' },
      { emoji: '👨‍🌾', category: 'People & Body', searchQuery: 'man farmerpeople & bodyman_farmer' },
      {
        emoji: '👩‍🌾',
        category: 'People & Body',
        searchQuery: 'woman farmerpeople & bodywoman_farmer'
      },
      { emoji: '🧑‍🍳', category: 'People & Body', searchQuery: 'cookpeople & bodycook' },
      { emoji: '👨‍🍳', category: 'People & Body', searchQuery: 'man cookpeople & bodyman_cookchef' },
      {
        emoji: '👩‍🍳',
        category: 'People & Body',
        searchQuery: 'woman cookpeople & bodywoman_cookchef'
      },
      { emoji: '🧑‍🔧', category: 'People & Body', searchQuery: 'mechanicpeople & bodymechanic' },
      {
        emoji: '👨‍🔧',
        category: 'People & Body',
        searchQuery: 'man mechanicpeople & bodyman_mechanic'
      },
      {
        emoji: '👩‍🔧',
        category: 'People & Body',
        searchQuery: 'woman mechanicpeople & bodywoman_mechanic'
      },
      {
        emoji: '🧑‍🏭',
        category: 'People & Body',
        searchQuery: 'factory workerpeople & bodyfactory_worker'
      },
      {
        emoji: '👨‍🏭',
        category: 'People & Body',
        searchQuery: 'man factory workerpeople & bodyman_factory_worker'
      },
      {
        emoji: '👩‍🏭',
        category: 'People & Body',
        searchQuery: 'woman factory workerpeople & bodywoman_factory_worker'
      },
      {
        emoji: '🧑‍💼',
        category: 'People & Body',
        searchQuery: 'office workerpeople & bodyoffice_worker'
      },
      {
        emoji: '👨‍💼',
        category: 'People & Body',
        searchQuery: 'man office workerpeople & bodyman_office_workerbusiness'
      },
      {
        emoji: '👩‍💼',
        category: 'People & Body',
        searchQuery: 'woman office workerpeople & bodywoman_office_workerbusiness'
      },
      { emoji: '🧑‍🔬', category: 'People & Body', searchQuery: 'scientistpeople & bodyscientist' },
      {
        emoji: '👨‍🔬',
        category: 'People & Body',
        searchQuery: 'man scientistpeople & bodyman_scientistresearch'
      },
      {
        emoji: '👩‍🔬',
        category: 'People & Body',
        searchQuery: 'woman scientistpeople & bodywoman_scientistresearch'
      },
      {
        emoji: '🧑‍💻',
        category: 'People & Body',
        searchQuery: 'technologistpeople & bodytechnologist'
      },
      {
        emoji: '👨‍💻',
        category: 'People & Body',
        searchQuery: 'man technologistpeople & bodyman_technologistcoder'
      },
      {
        emoji: '👩‍💻',
        category: 'People & Body',
        searchQuery: 'woman technologistpeople & bodywoman_technologistcoder'
      },
      { emoji: '🧑‍🎤', category: 'People & Body', searchQuery: 'singerpeople & bodysinger' },
      {
        emoji: '👨‍🎤',
        category: 'People & Body',
        searchQuery: 'man singerpeople & bodyman_singerrockstar'
      },
      {
        emoji: '👩‍🎤',
        category: 'People & Body',
        searchQuery: 'woman singerpeople & bodywoman_singerrockstar'
      },
      { emoji: '🧑‍🎨', category: 'People & Body', searchQuery: 'artistpeople & bodyartist' },
      {
        emoji: '👨‍🎨',
        category: 'People & Body',
        searchQuery: 'man artistpeople & bodyman_artistpainter'
      },
      {
        emoji: '👩‍🎨',
        category: 'People & Body',
        searchQuery: 'woman artistpeople & bodywoman_artistpainter'
      },
      { emoji: '🧑‍✈️', category: 'People & Body', searchQuery: 'pilotpeople & bodypilot' },
      { emoji: '👨‍✈️', category: 'People & Body', searchQuery: 'man pilotpeople & bodyman_pilot' },
      {
        emoji: '👩‍✈️',
        category: 'People & Body',
        searchQuery: 'woman pilotpeople & bodywoman_pilot'
      },
      { emoji: '🧑‍🚀', category: 'People & Body', searchQuery: 'astronautpeople & bodyastronaut' },
      {
        emoji: '👨‍🚀',
        category: 'People & Body',
        searchQuery: 'man astronautpeople & bodyman_astronautspace'
      },
      {
        emoji: '👩‍🚀',
        category: 'People & Body',
        searchQuery: 'woman astronautpeople & bodywoman_astronautspace'
      },
      {
        emoji: '🧑‍🚒',
        category: 'People & Body',
        searchQuery: 'firefighterpeople & bodyfirefighter'
      },
      {
        emoji: '👨‍🚒',
        category: 'People & Body',
        searchQuery: 'man firefighterpeople & bodyman_firefighter'
      },
      {
        emoji: '👩‍🚒',
        category: 'People & Body',
        searchQuery: 'woman firefighterpeople & bodywoman_firefighter'
      },
      {
        emoji: '👮',
        category: 'People & Body',
        searchQuery: 'police officerpeople & bodypolice_officercoplaw'
      },
      {
        emoji: '👮‍♂️',
        category: 'People & Body',
        searchQuery: 'man police officerpeople & bodypolicemanlawcop'
      },
      {
        emoji: '👮‍♀️',
        category: 'People & Body',
        searchQuery: 'woman police officerpeople & bodypolicewomanlawcop'
      },
      {
        emoji: '🕵️',
        category: 'People & Body',
        searchQuery: 'detectivepeople & bodydetectivesleuth'
      },
      {
        emoji: '🕵️‍♂️',
        category: 'People & Body',
        searchQuery: 'man detectivepeople & bodymale_detectivesleuth'
      },
      {
        emoji: '🕵️‍♀️',
        category: 'People & Body',
        searchQuery: 'woman detectivepeople & bodyfemale_detectivesleuth'
      },
      { emoji: '💂', category: 'People & Body', searchQuery: 'guardpeople & bodyguard' },
      { emoji: '💂‍♂️', category: 'People & Body', searchQuery: 'man guardpeople & bodyguardsman' },
      {
        emoji: '💂‍♀️',
        category: 'People & Body',
        searchQuery: 'woman guardpeople & bodyguardswoman'
      },
      { emoji: '🥷', category: 'People & Body', searchQuery: 'ninjapeople & bodyninja' },
      {
        emoji: '👷',
        category: 'People & Body',
        searchQuery: 'construction workerpeople & bodyconstruction_workerhelmet'
      },
      {
        emoji: '👷‍♂️',
        category: 'People & Body',
        searchQuery: 'man construction workerpeople & bodyconstruction_worker_manhelmet'
      },
      {
        emoji: '👷‍♀️',
        category: 'People & Body',
        searchQuery: 'woman construction workerpeople & bodyconstruction_worker_womanhelmet'
      },
      {
        emoji: '🤴',
        category: 'People & Body',
        searchQuery: 'princepeople & bodyprincecrownroyal'
      },
      {
        emoji: '👸',
        category: 'People & Body',
        searchQuery: 'princesspeople & bodyprincesscrownroyal'
      },
      {
        emoji: '👳',
        category: 'People & Body',
        searchQuery: 'person wearing turbanpeople & bodyperson_with_turban'
      },
      {
        emoji: '👳‍♂️',
        category: 'People & Body',
        searchQuery: 'man wearing turbanpeople & bodyman_with_turban'
      },
      {
        emoji: '👳‍♀️',
        category: 'People & Body',
        searchQuery: 'woman wearing turbanpeople & bodywoman_with_turban'
      },
      {
        emoji: '👲',
        category: 'People & Body',
        searchQuery: 'person with skullcappeople & bodyman_with_gua_pi_mao'
      },
      {
        emoji: '🧕',
        category: 'People & Body',
        searchQuery: 'woman with headscarfpeople & bodywoman_with_headscarfhijab'
      },
      {
        emoji: '🤵',
        category: 'People & Body',
        searchQuery: 'person in tuxedopeople & bodyperson_in_tuxedogroommarriagewedding'
      },
      {
        emoji: '🤵‍♂️',
        category: 'People & Body',
        searchQuery: 'man in tuxedopeople & bodyman_in_tuxedo'
      },
      {
        emoji: '🤵‍♀️',
        category: 'People & Body',
        searchQuery: 'woman in tuxedopeople & bodywoman_in_tuxedo'
      },
      {
        emoji: '👰',
        category: 'People & Body',
        searchQuery: 'person with veilpeople & bodyperson_with_veilmarriagewedding'
      },
      {
        emoji: '👰‍♂️',
        category: 'People & Body',
        searchQuery: 'man with veilpeople & bodyman_with_veil'
      },
      {
        emoji: '👰‍♀️',
        category: 'People & Body',
        searchQuery: 'woman with veilpeople & bodywoman_with_veilbride_with_veil'
      },
      {
        emoji: '🤰',
        category: 'People & Body',
        searchQuery: 'pregnant womanpeople & bodypregnant_woman'
      },
      {
        emoji: '🤱',
        category: 'People & Body',
        searchQuery: 'breast-feedingpeople & bodybreast_feedingnursing'
      },
      {
        emoji: '👩‍🍼',
        category: 'People & Body',
        searchQuery: 'woman feeding babypeople & bodywoman_feeding_baby'
      },
      {
        emoji: '👨‍🍼',
        category: 'People & Body',
        searchQuery: 'man feeding babypeople & bodyman_feeding_baby'
      },
      {
        emoji: '🧑‍🍼',
        category: 'People & Body',
        searchQuery: 'person feeding babypeople & bodyperson_feeding_baby'
      },
      { emoji: '👼', category: 'People & Body', searchQuery: 'baby angelpeople & bodyangel' },
      {
        emoji: '🎅',
        category: 'People & Body',
        searchQuery: 'santa clauspeople & bodysantachristmas'
      },
      {
        emoji: '🤶',
        category: 'People & Body',
        searchQuery: 'mrs. clauspeople & bodymrs_claussanta'
      },
      { emoji: '🧑‍🎄', category: 'People & Body', searchQuery: 'mx clauspeople & bodymx_claus' },
      { emoji: '🦸', category: 'People & Body', searchQuery: 'superheropeople & bodysuperhero' },
      {
        emoji: '🦸‍♂️',
        category: 'People & Body',
        searchQuery: 'man superheropeople & bodysuperhero_man'
      },
      {
        emoji: '🦸‍♀️',
        category: 'People & Body',
        searchQuery: 'woman superheropeople & bodysuperhero_woman'
      },
      {
        emoji: '🦹',
        category: 'People & Body',
        searchQuery: 'supervillainpeople & bodysupervillain'
      },
      {
        emoji: '🦹‍♂️',
        category: 'People & Body',
        searchQuery: 'man supervillainpeople & bodysupervillain_man'
      },
      {
        emoji: '🦹‍♀️',
        category: 'People & Body',
        searchQuery: 'woman supervillainpeople & bodysupervillain_woman'
      },
      { emoji: '🧙', category: 'People & Body', searchQuery: 'magepeople & bodymagewizard' },
      {
        emoji: '🧙‍♂️',
        category: 'People & Body',
        searchQuery: 'man magepeople & bodymage_manwizard'
      },
      {
        emoji: '🧙‍♀️',
        category: 'People & Body',
        searchQuery: 'woman magepeople & bodymage_womanwizard'
      },
      { emoji: '🧚', category: 'People & Body', searchQuery: 'fairypeople & bodyfairy' },
      { emoji: '🧚‍♂️', category: 'People & Body', searchQuery: 'man fairypeople & bodyfairy_man' },
      {
        emoji: '🧚‍♀️',
        category: 'People & Body',
        searchQuery: 'woman fairypeople & bodyfairy_woman'
      },
      { emoji: '🧛', category: 'People & Body', searchQuery: 'vampirepeople & bodyvampire' },
      {
        emoji: '🧛‍♂️',
        category: 'People & Body',
        searchQuery: 'man vampirepeople & bodyvampire_man'
      },
      {
        emoji: '🧛‍♀️',
        category: 'People & Body',
        searchQuery: 'woman vampirepeople & bodyvampire_woman'
      },
      { emoji: '🧜', category: 'People & Body', searchQuery: 'merpersonpeople & bodymerperson' },
      { emoji: '🧜‍♂️', category: 'People & Body', searchQuery: 'mermanpeople & bodymerman' },
      { emoji: '🧜‍♀️', category: 'People & Body', searchQuery: 'mermaidpeople & bodymermaid' },
      { emoji: '🧝', category: 'People & Body', searchQuery: 'elfpeople & bodyelf' },
      { emoji: '🧝‍♂️', category: 'People & Body', searchQuery: 'man elfpeople & bodyelf_man' },
      { emoji: '🧝‍♀️', category: 'People & Body', searchQuery: 'woman elfpeople & bodyelf_woman' },
      { emoji: '🧞', category: 'People & Body', searchQuery: 'geniepeople & bodygenie' },
      { emoji: '🧞‍♂️', category: 'People & Body', searchQuery: 'man geniepeople & bodygenie_man' },
      {
        emoji: '🧞‍♀️',
        category: 'People & Body',
        searchQuery: 'woman geniepeople & bodygenie_woman'
      },
      { emoji: '🧟', category: 'People & Body', searchQuery: 'zombiepeople & bodyzombie' },
      { emoji: '🧟‍♂️', category: 'People & Body', searchQuery: 'man zombiepeople & bodyzombie_man' },
      {
        emoji: '🧟‍♀️',
        category: 'People & Body',
        searchQuery: 'woman zombiepeople & bodyzombie_woman'
      },
      {
        emoji: '💆',
        category: 'People & Body',
        searchQuery: 'person getting massagepeople & bodymassagespa'
      },
      {
        emoji: '💆‍♂️',
        category: 'People & Body',
        searchQuery: 'man getting massagepeople & bodymassage_manspa'
      },
      {
        emoji: '💆‍♀️',
        category: 'People & Body',
        searchQuery: 'woman getting massagepeople & bodymassage_womanspa'
      },
      {
        emoji: '💇',
        category: 'People & Body',
        searchQuery: 'person getting haircutpeople & bodyhaircutbeauty'
      },
      {
        emoji: '💇‍♂️',
        category: 'People & Body',
        searchQuery: 'man getting haircutpeople & bodyhaircut_man'
      },
      {
        emoji: '💇‍♀️',
        category: 'People & Body',
        searchQuery: 'woman getting haircutpeople & bodyhaircut_woman'
      },
      { emoji: '🚶', category: 'People & Body', searchQuery: 'person walkingpeople & bodywalking' },
      {
        emoji: '🚶‍♂️',
        category: 'People & Body',
        searchQuery: 'man walkingpeople & bodywalking_man'
      },
      {
        emoji: '🚶‍♀️',
        category: 'People & Body',
        searchQuery: 'woman walkingpeople & bodywalking_woman'
      },
      {
        emoji: '🧍',
        category: 'People & Body',
        searchQuery: 'person standingpeople & bodystanding_person'
      },
      {
        emoji: '🧍‍♂️',
        category: 'People & Body',
        searchQuery: 'man standingpeople & bodystanding_man'
      },
      {
        emoji: '🧍‍♀️',
        category: 'People & Body',
        searchQuery: 'woman standingpeople & bodystanding_woman'
      },
      {
        emoji: '🧎',
        category: 'People & Body',
        searchQuery: 'person kneelingpeople & bodykneeling_person'
      },
      {
        emoji: '🧎‍♂️',
        category: 'People & Body',
        searchQuery: 'man kneelingpeople & bodykneeling_man'
      },
      {
        emoji: '🧎‍♀️',
        category: 'People & Body',
        searchQuery: 'woman kneelingpeople & bodykneeling_woman'
      },
      {
        emoji: '🧑‍🦯',
        category: 'People & Body',
        searchQuery: 'person with white canepeople & bodyperson_with_probing_cane'
      },
      {
        emoji: '👨‍🦯',
        category: 'People & Body',
        searchQuery: 'man with white canepeople & bodyman_with_probing_cane'
      },
      {
        emoji: '👩‍🦯',
        category: 'People & Body',
        searchQuery: 'woman with white canepeople & bodywoman_with_probing_cane'
      },
      {
        emoji: '🧑‍🦼',
        category: 'People & Body',
        searchQuery: 'person in motorized wheelchairpeople & bodyperson_in_motorized_wheelchair'
      },
      {
        emoji: '👨‍🦼',
        category: 'People & Body',
        searchQuery: 'man in motorized wheelchairpeople & bodyman_in_motorized_wheelchair'
      },
      {
        emoji: '👩‍🦼',
        category: 'People & Body',
        searchQuery: 'woman in motorized wheelchairpeople & bodywoman_in_motorized_wheelchair'
      },
      {
        emoji: '🧑‍🦽',
        category: 'People & Body',
        searchQuery: 'person in manual wheelchairpeople & bodyperson_in_manual_wheelchair'
      },
      {
        emoji: '👨‍🦽',
        category: 'People & Body',
        searchQuery: 'man in manual wheelchairpeople & bodyman_in_manual_wheelchair'
      },
      {
        emoji: '👩‍🦽',
        category: 'People & Body',
        searchQuery: 'woman in manual wheelchairpeople & bodywoman_in_manual_wheelchair'
      },
      {
        emoji: '🏃',
        category: 'People & Body',
        searchQuery: 'person runningpeople & bodyrunnerrunningexerciseworkoutmarathon'
      },
      {
        emoji: '🏃‍♂️',
        category: 'People & Body',
        searchQuery: 'man runningpeople & bodyrunning_manexerciseworkoutmarathon'
      },
      {
        emoji: '🏃‍♀️',
        category: 'People & Body',
        searchQuery: 'woman runningpeople & bodyrunning_womanexerciseworkoutmarathon'
      },
      {
        emoji: '💃',
        category: 'People & Body',
        searchQuery: 'woman dancingpeople & bodywoman_dancingdancerdress'
      },
      {
        emoji: '🕺',
        category: 'People & Body',
        searchQuery: 'man dancingpeople & bodyman_dancingdancer'
      },
      {
        emoji: '🕴️',
        category: 'People & Body',
        searchQuery: 'person in suit levitatingpeople & bodybusiness_suit_levitating'
      },
      {
        emoji: '👯',
        category: 'People & Body',
        searchQuery: 'people with bunny earspeople & bodydancersbunny'
      },
      {
        emoji: '👯‍♂️',
        category: 'People & Body',
        searchQuery: 'men with bunny earspeople & bodydancing_menbunny'
      },
      {
        emoji: '👯‍♀️',
        category: 'People & Body',
        searchQuery: 'women with bunny earspeople & bodydancing_womenbunny'
      },
      {
        emoji: '🧖',
        category: 'People & Body',
        searchQuery: 'person in steamy roompeople & bodysauna_personsteamy'
      },
      {
        emoji: '🧖‍♂️',
        category: 'People & Body',
        searchQuery: 'man in steamy roompeople & bodysauna_mansteamy'
      },
      {
        emoji: '🧖‍♀️',
        category: 'People & Body',
        searchQuery: 'woman in steamy roompeople & bodysauna_womansteamy'
      },
      {
        emoji: '🧗',
        category: 'People & Body',
        searchQuery: 'person climbingpeople & bodyclimbingbouldering'
      },
      {
        emoji: '🧗‍♂️',
        category: 'People & Body',
        searchQuery: 'man climbingpeople & bodyclimbing_manbouldering'
      },
      {
        emoji: '🧗‍♀️',
        category: 'People & Body',
        searchQuery: 'woman climbingpeople & bodyclimbing_womanbouldering'
      },
      {
        emoji: '🤺',
        category: 'People & Body',
        searchQuery: 'person fencingpeople & bodyperson_fencing'
      },
      {
        emoji: '🏇',
        category: 'People & Body',
        searchQuery: 'horse racingpeople & bodyhorse_racing'
      },
      { emoji: '⛷️', category: 'People & Body', searchQuery: 'skierpeople & bodyskier' },
      {
        emoji: '🏂',
        category: 'People & Body',
        searchQuery: 'snowboarderpeople & bodysnowboarder'
      },
      { emoji: '🏌️', category: 'People & Body', searchQuery: 'person golfingpeople & bodygolfing' },
      {
        emoji: '🏌️‍♂️',
        category: 'People & Body',
        searchQuery: 'man golfingpeople & bodygolfing_man'
      },
      {
        emoji: '🏌️‍♀️',
        category: 'People & Body',
        searchQuery: 'woman golfingpeople & bodygolfing_woman'
      },
      { emoji: '🏄', category: 'People & Body', searchQuery: 'person surfingpeople & bodysurfer' },
      {
        emoji: '🏄‍♂️',
        category: 'People & Body',
        searchQuery: 'man surfingpeople & bodysurfing_man'
      },
      {
        emoji: '🏄‍♀️',
        category: 'People & Body',
        searchQuery: 'woman surfingpeople & bodysurfing_woman'
      },
      {
        emoji: '🚣',
        category: 'People & Body',
        searchQuery: 'person rowing boatpeople & bodyrowboat'
      },
      {
        emoji: '🚣‍♂️',
        category: 'People & Body',
        searchQuery: 'man rowing boatpeople & bodyrowing_man'
      },
      {
        emoji: '🚣‍♀️',
        category: 'People & Body',
        searchQuery: 'woman rowing boatpeople & bodyrowing_woman'
      },
      {
        emoji: '🏊',
        category: 'People & Body',
        searchQuery: 'person swimmingpeople & bodyswimmer'
      },
      {
        emoji: '🏊‍♂️',
        category: 'People & Body',
        searchQuery: 'man swimmingpeople & bodyswimming_man'
      },
      {
        emoji: '🏊‍♀️',
        category: 'People & Body',
        searchQuery: 'woman swimmingpeople & bodyswimming_woman'
      },
      {
        emoji: '⛹️',
        category: 'People & Body',
        searchQuery: 'person bouncing ballpeople & bodybouncing_ball_personbasketball'
      },
      {
        emoji: '⛹️‍♂️',
        category: 'People & Body',
        searchQuery: 'man bouncing ballpeople & bodybouncing_ball_manbasketball_man'
      },
      {
        emoji: '⛹️‍♀️',
        category: 'People & Body',
        searchQuery: 'woman bouncing ballpeople & bodybouncing_ball_womanbasketball_woman'
      },
      {
        emoji: '🏋️',
        category: 'People & Body',
        searchQuery: 'person lifting weightspeople & bodyweight_liftinggymworkout'
      },
      {
        emoji: '🏋️‍♂️',
        category: 'People & Body',
        searchQuery: 'man lifting weightspeople & bodyweight_lifting_mangymworkout'
      },
      {
        emoji: '🏋️‍♀️',
        category: 'People & Body',
        searchQuery: 'woman lifting weightspeople & bodyweight_lifting_womangymworkout'
      },
      {
        emoji: '🚴',
        category: 'People & Body',
        searchQuery: 'person bikingpeople & bodybicyclist'
      },
      { emoji: '🚴‍♂️', category: 'People & Body', searchQuery: 'man bikingpeople & bodybiking_man' },
      {
        emoji: '🚴‍♀️',
        category: 'People & Body',
        searchQuery: 'woman bikingpeople & bodybiking_woman'
      },
      {
        emoji: '🚵',
        category: 'People & Body',
        searchQuery: 'person mountain bikingpeople & bodymountain_bicyclist'
      },
      {
        emoji: '🚵‍♂️',
        category: 'People & Body',
        searchQuery: 'man mountain bikingpeople & bodymountain_biking_man'
      },
      {
        emoji: '🚵‍♀️',
        category: 'People & Body',
        searchQuery: 'woman mountain bikingpeople & bodymountain_biking_woman'
      },
      {
        emoji: '🤸',
        category: 'People & Body',
        searchQuery: 'person cartwheelingpeople & bodycartwheeling'
      },
      {
        emoji: '🤸‍♂️',
        category: 'People & Body',
        searchQuery: 'man cartwheelingpeople & bodyman_cartwheeling'
      },
      {
        emoji: '🤸‍♀️',
        category: 'People & Body',
        searchQuery: 'woman cartwheelingpeople & bodywoman_cartwheeling'
      },
      {
        emoji: '🤼',
        category: 'People & Body',
        searchQuery: 'people wrestlingpeople & bodywrestling'
      },
      {
        emoji: '🤼‍♂️',
        category: 'People & Body',
        searchQuery: 'men wrestlingpeople & bodymen_wrestling'
      },
      {
        emoji: '🤼‍♀️',
        category: 'People & Body',
        searchQuery: 'women wrestlingpeople & bodywomen_wrestling'
      },
      {
        emoji: '🤽',
        category: 'People & Body',
        searchQuery: 'person playing water polopeople & bodywater_polo'
      },
      {
        emoji: '🤽‍♂️',
        category: 'People & Body',
        searchQuery: 'man playing water polopeople & bodyman_playing_water_polo'
      },
      {
        emoji: '🤽‍♀️',
        category: 'People & Body',
        searchQuery: 'woman playing water polopeople & bodywoman_playing_water_polo'
      },
      {
        emoji: '🤾',
        category: 'People & Body',
        searchQuery: 'person playing handballpeople & bodyhandball_person'
      },
      {
        emoji: '🤾‍♂️',
        category: 'People & Body',
        searchQuery: 'man playing handballpeople & bodyman_playing_handball'
      },
      {
        emoji: '🤾‍♀️',
        category: 'People & Body',
        searchQuery: 'woman playing handballpeople & bodywoman_playing_handball'
      },
      {
        emoji: '🤹',
        category: 'People & Body',
        searchQuery: 'person jugglingpeople & bodyjuggling_person'
      },
      {
        emoji: '🤹‍♂️',
        category: 'People & Body',
        searchQuery: 'man jugglingpeople & bodyman_juggling'
      },
      {
        emoji: '🤹‍♀️',
        category: 'People & Body',
        searchQuery: 'woman jugglingpeople & bodywoman_juggling'
      },
      {
        emoji: '🧘',
        category: 'People & Body',
        searchQuery: 'person in lotus positionpeople & bodylotus_positionmeditation'
      },
      {
        emoji: '🧘‍♂️',
        category: 'People & Body',
        searchQuery: 'man in lotus positionpeople & bodylotus_position_manmeditation'
      },
      {
        emoji: '🧘‍♀️',
        category: 'People & Body',
        searchQuery: 'woman in lotus positionpeople & bodylotus_position_womanmeditation'
      },
      {
        emoji: '🛀',
        category: 'People & Body',
        searchQuery: 'person taking bathpeople & bodybathshower'
      },
      {
        emoji: '🛌',
        category: 'People & Body',
        searchQuery: 'person in bedpeople & bodysleeping_bed'
      },
      {
        emoji: '🧑‍🤝‍🧑',
        category: 'People & Body',
        searchQuery: 'people holding handspeople & bodypeople_holding_handscoupledate'
      },
      {
        emoji: '👭',
        category: 'People & Body',
        searchQuery: 'women holding handspeople & bodytwo_women_holding_handscoupledate'
      },
      {
        emoji: '👫',
        category: 'People & Body',
        searchQuery: 'woman and man holding handspeople & bodycoupledate'
      },
      {
        emoji: '👬',
        category: 'People & Body',
        searchQuery: 'men holding handspeople & bodytwo_men_holding_handscoupledate'
      },
      { emoji: '💏', category: 'People & Body', searchQuery: 'kisspeople & bodycouplekiss' },
      {
        emoji: '👩‍❤️‍💋‍👨',
        category: 'People & Body',
        searchQuery: 'kiss: woman, manpeople & bodycouplekiss_man_woman'
      },
      {
        emoji: '👨‍❤️‍💋‍👨',
        category: 'People & Body',
        searchQuery: 'kiss: man, manpeople & bodycouplekiss_man_man'
      },
      {
        emoji: '👩‍❤️‍💋‍👩',
        category: 'People & Body',
        searchQuery: 'kiss: woman, womanpeople & bodycouplekiss_woman_woman'
      },
      {
        emoji: '💑',
        category: 'People & Body',
        searchQuery: 'couple with heartpeople & bodycouple_with_heart'
      },
      {
        emoji: '👩‍❤️‍👨',
        category: 'People & Body',
        searchQuery: 'couple with heart: woman, manpeople & bodycouple_with_heart_woman_man'
      },
      {
        emoji: '👨‍❤️‍👨',
        category: 'People & Body',
        searchQuery: 'couple with heart: man, manpeople & bodycouple_with_heart_man_man'
      },
      {
        emoji: '👩‍❤️‍👩',
        category: 'People & Body',
        searchQuery: 'couple with heart: woman, womanpeople & bodycouple_with_heart_woman_woman'
      },
      {
        emoji: '👪',
        category: 'People & Body',
        searchQuery: 'familypeople & bodyfamilyhomeparentschild'
      },
      {
        emoji: '👨‍👩‍👦',
        category: 'People & Body',
        searchQuery: 'family: man, woman, boypeople & bodyfamily_man_woman_boy'
      },
      {
        emoji: '👨‍👩‍👧',
        category: 'People & Body',
        searchQuery: 'family: man, woman, girlpeople & bodyfamily_man_woman_girl'
      },
      {
        emoji: '👨‍👩‍👧‍👦',
        category: 'People & Body',
        searchQuery: 'family: man, woman, girl, boypeople & bodyfamily_man_woman_girl_boy'
      },
      {
        emoji: '👨‍👩‍👦‍👦',
        category: 'People & Body',
        searchQuery: 'family: man, woman, boy, boypeople & bodyfamily_man_woman_boy_boy'
      },
      {
        emoji: '👨‍👩‍👧‍👧',
        category: 'People & Body',
        searchQuery: 'family: man, woman, girl, girlpeople & bodyfamily_man_woman_girl_girl'
      },
      {
        emoji: '👨‍👨‍👦',
        category: 'People & Body',
        searchQuery: 'family: man, man, boypeople & bodyfamily_man_man_boy'
      },
      {
        emoji: '👨‍👨‍👧',
        category: 'People & Body',
        searchQuery: 'family: man, man, girlpeople & bodyfamily_man_man_girl'
      },
      {
        emoji: '👨‍👨‍👧‍👦',
        category: 'People & Body',
        searchQuery: 'family: man, man, girl, boypeople & bodyfamily_man_man_girl_boy'
      },
      {
        emoji: '👨‍👨‍👦‍👦',
        category: 'People & Body',
        searchQuery: 'family: man, man, boy, boypeople & bodyfamily_man_man_boy_boy'
      },
      {
        emoji: '👨‍👨‍👧‍👧',
        category: 'People & Body',
        searchQuery: 'family: man, man, girl, girlpeople & bodyfamily_man_man_girl_girl'
      },
      {
        emoji: '👩‍👩‍👦',
        category: 'People & Body',
        searchQuery: 'family: woman, woman, boypeople & bodyfamily_woman_woman_boy'
      },
      {
        emoji: '👩‍👩‍👧',
        category: 'People & Body',
        searchQuery: 'family: woman, woman, girlpeople & bodyfamily_woman_woman_girl'
      },
      {
        emoji: '👩‍👩‍👧‍👦',
        category: 'People & Body',
        searchQuery: 'family: woman, woman, girl, boypeople & bodyfamily_woman_woman_girl_boy'
      },
      {
        emoji: '👩‍👩‍👦‍👦',
        category: 'People & Body',
        searchQuery: 'family: woman, woman, boy, boypeople & bodyfamily_woman_woman_boy_boy'
      },
      {
        emoji: '👩‍👩‍👧‍👧',
        category: 'People & Body',
        searchQuery: 'family: woman, woman, girl, girlpeople & bodyfamily_woman_woman_girl_girl'
      },
      {
        emoji: '👨‍👦',
        category: 'People & Body',
        searchQuery: 'family: man, boypeople & bodyfamily_man_boy'
      },
      {
        emoji: '👨‍👦‍👦',
        category: 'People & Body',
        searchQuery: 'family: man, boy, boypeople & bodyfamily_man_boy_boy'
      },
      {
        emoji: '👨‍👧',
        category: 'People & Body',
        searchQuery: 'family: man, girlpeople & bodyfamily_man_girl'
      },
      {
        emoji: '👨‍👧‍👦',
        category: 'People & Body',
        searchQuery: 'family: man, girl, boypeople & bodyfamily_man_girl_boy'
      },
      {
        emoji: '👨‍👧‍👧',
        category: 'People & Body',
        searchQuery: 'family: man, girl, girlpeople & bodyfamily_man_girl_girl'
      },
      {
        emoji: '👩‍👦',
        category: 'People & Body',
        searchQuery: 'family: woman, boypeople & bodyfamily_woman_boy'
      },
      {
        emoji: '👩‍👦‍👦',
        category: 'People & Body',
        searchQuery: 'family: woman, boy, boypeople & bodyfamily_woman_boy_boy'
      },
      {
        emoji: '👩‍👧',
        category: 'People & Body',
        searchQuery: 'family: woman, girlpeople & bodyfamily_woman_girl'
      },
      {
        emoji: '👩‍👧‍👦',
        category: 'People & Body',
        searchQuery: 'family: woman, girl, boypeople & bodyfamily_woman_girl_boy'
      },
      {
        emoji: '👩‍👧‍👧',
        category: 'People & Body',
        searchQuery: 'family: woman, girl, girlpeople & bodyfamily_woman_girl_girl'
      },
      {
        emoji: '🗣️',
        category: 'People & Body',
        searchQuery: 'speaking headpeople & bodyspeaking_head'
      },
      {
        emoji: '👤',
        category: 'People & Body',
        searchQuery: 'bust in silhouettepeople & bodybust_in_silhouetteuser'
      },
      {
        emoji: '👥',
        category: 'People & Body',
        searchQuery: 'busts in silhouettepeople & bodybusts_in_silhouetteusersgroupteam'
      },
      {
        emoji: '🫂',
        category: 'People & Body',
        searchQuery: 'people huggingpeople & bodypeople_hugging'
      },
      {
        emoji: '👣',
        category: 'People & Body',
        searchQuery: 'footprintspeople & bodyfootprintsfeettracks'
      }
    ]
  },
  {
    category: 'Animals & Nature',
    label: '🐶',
    icon: `${ICON_PREFIX}animals`,
    data: [
      {
        emoji: '🐵',
        category: 'Animals & Nature',
        searchQuery: 'monkey faceanimals & naturemonkey_face'
      },
      { emoji: '🐒', category: 'Animals & Nature', searchQuery: 'monkeyanimals & naturemonkey' },
      { emoji: '🦍', category: 'Animals & Nature', searchQuery: 'gorillaanimals & naturegorilla' },
      {
        emoji: '🦧',
        category: 'Animals & Nature',
        searchQuery: 'orangutananimals & natureorangutan'
      },
      { emoji: '🐶', category: 'Animals & Nature', searchQuery: 'dog faceanimals & naturedogpet' },
      { emoji: '🐕', category: 'Animals & Nature', searchQuery: 'doganimals & naturedog2' },
      {
        emoji: '🦮',
        category: 'Animals & Nature',
        searchQuery: 'guide doganimals & natureguide_dog'
      },
      {
        emoji: '🐕‍🦺',
        category: 'Animals & Nature',
        searchQuery: 'service doganimals & natureservice_dog'
      },
      { emoji: '🐩', category: 'Animals & Nature', searchQuery: 'poodleanimals & naturepoodledog' },
      { emoji: '🐺', category: 'Animals & Nature', searchQuery: 'wolfanimals & naturewolf' },
      { emoji: '🦊', category: 'Animals & Nature', searchQuery: 'foxanimals & naturefox_face' },
      { emoji: '🦝', category: 'Animals & Nature', searchQuery: 'raccoonanimals & natureraccoon' },
      { emoji: '🐱', category: 'Animals & Nature', searchQuery: 'cat faceanimals & naturecatpet' },
      { emoji: '🐈', category: 'Animals & Nature', searchQuery: 'catanimals & naturecat2' },
      {
        emoji: '🐈‍⬛',
        category: 'Animals & Nature',
        searchQuery: 'black catanimals & natureblack_cat'
      },
      { emoji: '🦁', category: 'Animals & Nature', searchQuery: 'lionanimals & naturelion' },
      { emoji: '🐯', category: 'Animals & Nature', searchQuery: 'tiger faceanimals & naturetiger' },
      { emoji: '🐅', category: 'Animals & Nature', searchQuery: 'tigeranimals & naturetiger2' },
      { emoji: '🐆', category: 'Animals & Nature', searchQuery: 'leopardanimals & natureleopard' },
      { emoji: '🐴', category: 'Animals & Nature', searchQuery: 'horse faceanimals & naturehorse' },
      {
        emoji: '🐎',
        category: 'Animals & Nature',
        searchQuery: 'horseanimals & natureracehorsespeed'
      },
      { emoji: '🦄', category: 'Animals & Nature', searchQuery: 'unicornanimals & natureunicorn' },
      { emoji: '🦓', category: 'Animals & Nature', searchQuery: 'zebraanimals & naturezebra' },
      { emoji: '🦌', category: 'Animals & Nature', searchQuery: 'deeranimals & naturedeer' },
      { emoji: '🦬', category: 'Animals & Nature', searchQuery: 'bisonanimals & naturebison' },
      { emoji: '🐮', category: 'Animals & Nature', searchQuery: 'cow faceanimals & naturecow' },
      { emoji: '🐂', category: 'Animals & Nature', searchQuery: 'oxanimals & natureox' },
      {
        emoji: '🐃',
        category: 'Animals & Nature',
        searchQuery: 'water buffaloanimals & naturewater_buffalo'
      },
      { emoji: '🐄', category: 'Animals & Nature', searchQuery: 'cowanimals & naturecow2' },
      { emoji: '🐷', category: 'Animals & Nature', searchQuery: 'pig faceanimals & naturepig' },
      { emoji: '🐖', category: 'Animals & Nature', searchQuery: 'piganimals & naturepig2' },
      { emoji: '🐗', category: 'Animals & Nature', searchQuery: 'boaranimals & natureboar' },
      {
        emoji: '🐽',
        category: 'Animals & Nature',
        searchQuery: 'pig noseanimals & naturepig_nose'
      },
      { emoji: '🐏', category: 'Animals & Nature', searchQuery: 'ramanimals & natureram' },
      { emoji: '🐑', category: 'Animals & Nature', searchQuery: 'eweanimals & naturesheep' },
      { emoji: '🐐', category: 'Animals & Nature', searchQuery: 'goatanimals & naturegoat' },
      {
        emoji: '🐪',
        category: 'Animals & Nature',
        searchQuery: 'camelanimals & naturedromedary_cameldesert'
      },
      {
        emoji: '🐫',
        category: 'Animals & Nature',
        searchQuery: 'two-hump camelanimals & naturecamel'
      },
      { emoji: '🦙', category: 'Animals & Nature', searchQuery: 'llamaanimals & naturellama' },
      { emoji: '🦒', category: 'Animals & Nature', searchQuery: 'giraffeanimals & naturegiraffe' },
      {
        emoji: '🐘',
        category: 'Animals & Nature',
        searchQuery: 'elephantanimals & natureelephant'
      },
      { emoji: '🦣', category: 'Animals & Nature', searchQuery: 'mammothanimals & naturemammoth' },
      {
        emoji: '🦏',
        category: 'Animals & Nature',
        searchQuery: 'rhinocerosanimals & naturerhinoceros'
      },
      {
        emoji: '🦛',
        category: 'Animals & Nature',
        searchQuery: 'hippopotamusanimals & naturehippopotamus'
      },
      { emoji: '🐭', category: 'Animals & Nature', searchQuery: 'mouse faceanimals & naturemouse' },
      { emoji: '🐁', category: 'Animals & Nature', searchQuery: 'mouseanimals & naturemouse2' },
      { emoji: '🐀', category: 'Animals & Nature', searchQuery: 'ratanimals & naturerat' },
      {
        emoji: '🐹',
        category: 'Animals & Nature',
        searchQuery: 'hamsteranimals & naturehamsterpet'
      },
      {
        emoji: '🐰',
        category: 'Animals & Nature',
        searchQuery: 'rabbit faceanimals & naturerabbitbunny'
      },
      { emoji: '🐇', category: 'Animals & Nature', searchQuery: 'rabbitanimals & naturerabbit2' },
      {
        emoji: '🐿️',
        category: 'Animals & Nature',
        searchQuery: 'chipmunkanimals & naturechipmunk'
      },
      { emoji: '🦫', category: 'Animals & Nature', searchQuery: 'beaveranimals & naturebeaver' },
      {
        emoji: '🦔',
        category: 'Animals & Nature',
        searchQuery: 'hedgehoganimals & naturehedgehog'
      },
      { emoji: '🦇', category: 'Animals & Nature', searchQuery: 'batanimals & naturebat' },
      { emoji: '🐻', category: 'Animals & Nature', searchQuery: 'bearanimals & naturebear' },
      {
        emoji: '🐻‍❄️',
        category: 'Animals & Nature',
        searchQuery: 'polar bearanimals & naturepolar_bear'
      },
      { emoji: '🐨', category: 'Animals & Nature', searchQuery: 'koalaanimals & naturekoala' },
      { emoji: '🐼', category: 'Animals & Nature', searchQuery: 'pandaanimals & naturepanda_face' },
      { emoji: '🦥', category: 'Animals & Nature', searchQuery: 'slothanimals & naturesloth' },
      { emoji: '🦦', category: 'Animals & Nature', searchQuery: 'otteranimals & natureotter' },
      { emoji: '🦨', category: 'Animals & Nature', searchQuery: 'skunkanimals & natureskunk' },
      {
        emoji: '🦘',
        category: 'Animals & Nature',
        searchQuery: 'kangarooanimals & naturekangaroo'
      },
      { emoji: '🦡', category: 'Animals & Nature', searchQuery: 'badgeranimals & naturebadger' },
      {
        emoji: '🐾',
        category: 'Animals & Nature',
        searchQuery: 'paw printsanimals & naturefeetpaw_prints'
      },
      {
        emoji: '🦃',
        category: 'Animals & Nature',
        searchQuery: 'turkeyanimals & natureturkeythanksgiving'
      },
      { emoji: '🐔', category: 'Animals & Nature', searchQuery: 'chickenanimals & naturechicken' },
      { emoji: '🐓', category: 'Animals & Nature', searchQuery: 'roosteranimals & naturerooster' },
      {
        emoji: '🐣',
        category: 'Animals & Nature',
        searchQuery: 'hatching chickanimals & naturehatching_chick'
      },
      {
        emoji: '🐤',
        category: 'Animals & Nature',
        searchQuery: 'baby chickanimals & naturebaby_chick'
      },
      {
        emoji: '🐥',
        category: 'Animals & Nature',
        searchQuery: 'front-facing baby chickanimals & naturehatched_chick'
      },
      { emoji: '🐦', category: 'Animals & Nature', searchQuery: 'birdanimals & naturebird' },
      { emoji: '🐧', category: 'Animals & Nature', searchQuery: 'penguinanimals & naturepenguin' },
      { emoji: '🕊️', category: 'Animals & Nature', searchQuery: 'doveanimals & naturedovepeace' },
      { emoji: '🦅', category: 'Animals & Nature', searchQuery: 'eagleanimals & natureeagle' },
      { emoji: '🦆', category: 'Animals & Nature', searchQuery: 'duckanimals & natureduck' },
      { emoji: '🦢', category: 'Animals & Nature', searchQuery: 'swananimals & natureswan' },
      { emoji: '🦉', category: 'Animals & Nature', searchQuery: 'owlanimals & natureowl' },
      { emoji: '🦤', category: 'Animals & Nature', searchQuery: 'dodoanimals & naturedodo' },
      { emoji: '🪶', category: 'Animals & Nature', searchQuery: 'featheranimals & naturefeather' },
      {
        emoji: '🦩',
        category: 'Animals & Nature',
        searchQuery: 'flamingoanimals & natureflamingo'
      },
      { emoji: '🦚', category: 'Animals & Nature', searchQuery: 'peacockanimals & naturepeacock' },
      { emoji: '🦜', category: 'Animals & Nature', searchQuery: 'parrotanimals & natureparrot' },
      { emoji: '🐸', category: 'Animals & Nature', searchQuery: 'froganimals & naturefrog' },
      {
        emoji: '🐊',
        category: 'Animals & Nature',
        searchQuery: 'crocodileanimals & naturecrocodile'
      },
      {
        emoji: '🐢',
        category: 'Animals & Nature',
        searchQuery: 'turtleanimals & natureturtleslow'
      },
      { emoji: '🦎', category: 'Animals & Nature', searchQuery: 'lizardanimals & naturelizard' },
      { emoji: '🐍', category: 'Animals & Nature', searchQuery: 'snakeanimals & naturesnake' },
      {
        emoji: '🐲',
        category: 'Animals & Nature',
        searchQuery: 'dragon faceanimals & naturedragon_face'
      },
      { emoji: '🐉', category: 'Animals & Nature', searchQuery: 'dragonanimals & naturedragon' },
      {
        emoji: '🦕',
        category: 'Animals & Nature',
        searchQuery: 'sauropodanimals & naturesauropoddinosaur'
      },
      {
        emoji: '🦖',
        category: 'Animals & Nature',
        searchQuery: 't-rexanimals & naturet-rexdinosaur'
      },
      {
        emoji: '🐳',
        category: 'Animals & Nature',
        searchQuery: 'spouting whaleanimals & naturewhalesea'
      },
      { emoji: '🐋', category: 'Animals & Nature', searchQuery: 'whaleanimals & naturewhale2' },
      {
        emoji: '🐬',
        category: 'Animals & Nature',
        searchQuery: 'dolphinanimals & naturedolphinflipper'
      },
      { emoji: '🦭', category: 'Animals & Nature', searchQuery: 'sealanimals & natureseal' },
      { emoji: '🐟', category: 'Animals & Nature', searchQuery: 'fishanimals & naturefish' },
      {
        emoji: '🐠',
        category: 'Animals & Nature',
        searchQuery: 'tropical fishanimals & naturetropical_fish'
      },
      {
        emoji: '🐡',
        category: 'Animals & Nature',
        searchQuery: 'blowfishanimals & natureblowfish'
      },
      { emoji: '🦈', category: 'Animals & Nature', searchQuery: 'sharkanimals & natureshark' },
      { emoji: '🐙', category: 'Animals & Nature', searchQuery: 'octopusanimals & natureoctopus' },
      {
        emoji: '🐚',
        category: 'Animals & Nature',
        searchQuery: 'spiral shellanimals & natureshellseabeach'
      },
      { emoji: '🐌', category: 'Animals & Nature', searchQuery: 'snailanimals & naturesnailslow' },
      {
        emoji: '🦋',
        category: 'Animals & Nature',
        searchQuery: 'butterflyanimals & naturebutterfly'
      },
      { emoji: '🐛', category: 'Animals & Nature', searchQuery: 'buganimals & naturebug' },
      { emoji: '🐜', category: 'Animals & Nature', searchQuery: 'antanimals & natureant' },
      {
        emoji: '🐝',
        category: 'Animals & Nature',
        searchQuery: 'honeybeeanimals & naturebeehoneybee'
      },
      { emoji: '🪲', category: 'Animals & Nature', searchQuery: 'beetleanimals & naturebeetle' },
      {
        emoji: '🐞',
        category: 'Animals & Nature',
        searchQuery: 'lady beetleanimals & naturelady_beetlebug'
      },
      { emoji: '🦗', category: 'Animals & Nature', searchQuery: 'cricketanimals & naturecricket' },
      {
        emoji: '🪳',
        category: 'Animals & Nature',
        searchQuery: 'cockroachanimals & naturecockroach'
      },
      { emoji: '🕷️', category: 'Animals & Nature', searchQuery: 'spideranimals & naturespider' },
      {
        emoji: '🕸️',
        category: 'Animals & Nature',
        searchQuery: 'spider webanimals & naturespider_web'
      },
      {
        emoji: '🦂',
        category: 'Animals & Nature',
        searchQuery: 'scorpionanimals & naturescorpion'
      },
      {
        emoji: '🦟',
        category: 'Animals & Nature',
        searchQuery: 'mosquitoanimals & naturemosquito'
      },
      { emoji: '🪰', category: 'Animals & Nature', searchQuery: 'flyanimals & naturefly' },
      { emoji: '🪱', category: 'Animals & Nature', searchQuery: 'wormanimals & natureworm' },
      {
        emoji: '🦠',
        category: 'Animals & Nature',
        searchQuery: 'microbeanimals & naturemicrobegerm'
      },
      {
        emoji: '💐',
        category: 'Animals & Nature',
        searchQuery: 'bouquetanimals & naturebouquetflowers'
      },
      {
        emoji: '🌸',
        category: 'Animals & Nature',
        searchQuery: 'cherry blossomanimals & naturecherry_blossomflowerspring'
      },
      {
        emoji: '💮',
        category: 'Animals & Nature',
        searchQuery: 'white floweranimals & naturewhite_flower'
      },
      { emoji: '🏵️', category: 'Animals & Nature', searchQuery: 'rosetteanimals & naturerosette' },
      { emoji: '🌹', category: 'Animals & Nature', searchQuery: 'roseanimals & natureroseflower' },
      {
        emoji: '🥀',
        category: 'Animals & Nature',
        searchQuery: 'wilted floweranimals & naturewilted_flower'
      },
      {
        emoji: '🌺',
        category: 'Animals & Nature',
        searchQuery: 'hibiscusanimals & naturehibiscus'
      },
      {
        emoji: '🌻',
        category: 'Animals & Nature',
        searchQuery: 'sunfloweranimals & naturesunflower'
      },
      { emoji: '🌼', category: 'Animals & Nature', searchQuery: 'blossomanimals & natureblossom' },
      {
        emoji: '🌷',
        category: 'Animals & Nature',
        searchQuery: 'tulipanimals & naturetulipflower'
      },
      {
        emoji: '🌱',
        category: 'Animals & Nature',
        searchQuery: 'seedlinganimals & natureseedlingplant'
      },
      {
        emoji: '🪴',
        category: 'Animals & Nature',
        searchQuery: 'potted plantanimals & naturepotted_plant'
      },
      {
        emoji: '🌲',
        category: 'Animals & Nature',
        searchQuery: 'evergreen treeanimals & natureevergreen_treewood'
      },
      {
        emoji: '🌳',
        category: 'Animals & Nature',
        searchQuery: 'deciduous treeanimals & naturedeciduous_treewood'
      },
      {
        emoji: '🌴',
        category: 'Animals & Nature',
        searchQuery: 'palm treeanimals & naturepalm_tree'
      },
      { emoji: '🌵', category: 'Animals & Nature', searchQuery: 'cactusanimals & naturecactus' },
      {
        emoji: '🌾',
        category: 'Animals & Nature',
        searchQuery: 'sheaf of riceanimals & natureear_of_rice'
      },
      { emoji: '🌿', category: 'Animals & Nature', searchQuery: 'herbanimals & natureherb' },
      {
        emoji: '☘️',
        category: 'Animals & Nature',
        searchQuery: 'shamrockanimals & natureshamrock'
      },
      {
        emoji: '🍀',
        category: 'Animals & Nature',
        searchQuery: 'four leaf cloveranimals & naturefour_leaf_cloverluck'
      },
      {
        emoji: '🍁',
        category: 'Animals & Nature',
        searchQuery: 'maple leafanimals & naturemaple_leafcanada'
      },
      {
        emoji: '🍂',
        category: 'Animals & Nature',
        searchQuery: 'fallen leafanimals & naturefallen_leafautumn'
      },
      {
        emoji: '🍃',
        category: 'Animals & Nature',
        searchQuery: 'leaf fluttering in windanimals & natureleavesleaf'
      }
    ]
  },
  {
    category: 'Food & Drink',
    label: '🍉',
    icon: `${ICON_PREFIX}food`,
    data: [
      { emoji: '🍇', category: 'Food & Drink', searchQuery: 'grapesfood & drinkgrapes' },
      { emoji: '🍈', category: 'Food & Drink', searchQuery: 'melonfood & drinkmelon' },
      { emoji: '🍉', category: 'Food & Drink', searchQuery: 'watermelonfood & drinkwatermelon' },
      {
        emoji: '🍊',
        category: 'Food & Drink',
        searchQuery: 'tangerinefood & drinktangerineorangemandarin'
      },
      { emoji: '🍋', category: 'Food & Drink', searchQuery: 'lemonfood & drinklemon' },
      { emoji: '🍌', category: 'Food & Drink', searchQuery: 'bananafood & drinkbananafruit' },
      { emoji: '🍍', category: 'Food & Drink', searchQuery: 'pineapplefood & drinkpineapple' },
      { emoji: '🥭', category: 'Food & Drink', searchQuery: 'mangofood & drinkmango' },
      { emoji: '🍎', category: 'Food & Drink', searchQuery: 'red applefood & drinkapple' },
      {
        emoji: '🍏',
        category: 'Food & Drink',
        searchQuery: 'green applefood & drinkgreen_applefruit'
      },
      { emoji: '🍐', category: 'Food & Drink', searchQuery: 'pearfood & drinkpear' },
      { emoji: '🍑', category: 'Food & Drink', searchQuery: 'peachfood & drinkpeach' },
      { emoji: '🍒', category: 'Food & Drink', searchQuery: 'cherriesfood & drinkcherriesfruit' },
      {
        emoji: '🍓',
        category: 'Food & Drink',
        searchQuery: 'strawberryfood & drinkstrawberryfruit'
      },
      { emoji: '🫐', category: 'Food & Drink', searchQuery: 'blueberriesfood & drinkblueberries' },
      { emoji: '🥝', category: 'Food & Drink', searchQuery: 'kiwi fruitfood & drinkkiwi_fruit' },
      { emoji: '🍅', category: 'Food & Drink', searchQuery: 'tomatofood & drinktomato' },
      { emoji: '🫒', category: 'Food & Drink', searchQuery: 'olivefood & drinkolive' },
      { emoji: '🥥', category: 'Food & Drink', searchQuery: 'coconutfood & drinkcoconut' },
      { emoji: '🥑', category: 'Food & Drink', searchQuery: 'avocadofood & drinkavocado' },
      {
        emoji: '🍆',
        category: 'Food & Drink',
        searchQuery: 'eggplantfood & drinkeggplantaubergine'
      },
      { emoji: '🥔', category: 'Food & Drink', searchQuery: 'potatofood & drinkpotato' },
      { emoji: '🥕', category: 'Food & Drink', searchQuery: 'carrotfood & drinkcarrot' },
      { emoji: '🌽', category: 'Food & Drink', searchQuery: 'ear of cornfood & drinkcorn' },
      {
        emoji: '🌶️',
        category: 'Food & Drink',
        searchQuery: 'hot pepperfood & drinkhot_pepperspicy'
      },
      { emoji: '🫑', category: 'Food & Drink', searchQuery: 'bell pepperfood & drinkbell_pepper' },
      { emoji: '🥒', category: 'Food & Drink', searchQuery: 'cucumberfood & drinkcucumber' },
      { emoji: '🥬', category: 'Food & Drink', searchQuery: 'leafy greenfood & drinkleafy_green' },
      { emoji: '🥦', category: 'Food & Drink', searchQuery: 'broccolifood & drinkbroccoli' },
      { emoji: '🧄', category: 'Food & Drink', searchQuery: 'garlicfood & drinkgarlic' },
      { emoji: '🧅', category: 'Food & Drink', searchQuery: 'onionfood & drinkonion' },
      { emoji: '🍄', category: 'Food & Drink', searchQuery: 'mushroomfood & drinkmushroom' },
      { emoji: '🥜', category: 'Food & Drink', searchQuery: 'peanutsfood & drinkpeanuts' },
      { emoji: '🌰', category: 'Food & Drink', searchQuery: 'chestnutfood & drinkchestnut' },
      { emoji: '🍞', category: 'Food & Drink', searchQuery: 'breadfood & drinkbreadtoast' },
      { emoji: '🥐', category: 'Food & Drink', searchQuery: 'croissantfood & drinkcroissant' },
      {
        emoji: '🥖',
        category: 'Food & Drink',
        searchQuery: 'baguette breadfood & drinkbaguette_bread'
      },
      { emoji: '🫓', category: 'Food & Drink', searchQuery: 'flatbreadfood & drinkflatbread' },
      { emoji: '🥨', category: 'Food & Drink', searchQuery: 'pretzelfood & drinkpretzel' },
      { emoji: '🥯', category: 'Food & Drink', searchQuery: 'bagelfood & drinkbagel' },
      { emoji: '🥞', category: 'Food & Drink', searchQuery: 'pancakesfood & drinkpancakes' },
      { emoji: '🧇', category: 'Food & Drink', searchQuery: 'wafflefood & drinkwaffle' },
      { emoji: '🧀', category: 'Food & Drink', searchQuery: 'cheese wedgefood & drinkcheese' },
      {
        emoji: '🍖',
        category: 'Food & Drink',
        searchQuery: 'meat on bonefood & drinkmeat_on_bone'
      },
      {
        emoji: '🍗',
        category: 'Food & Drink',
        searchQuery: 'poultry legfood & drinkpoultry_legmeatchicken'
      },
      { emoji: '🥩', category: 'Food & Drink', searchQuery: 'cut of meatfood & drinkcut_of_meat' },
      { emoji: '🥓', category: 'Food & Drink', searchQuery: 'baconfood & drinkbacon' },
      {
        emoji: '🍔',
        category: 'Food & Drink',
        searchQuery: 'hamburgerfood & drinkhamburgerburger'
      },
      { emoji: '🍟', category: 'Food & Drink', searchQuery: 'french friesfood & drinkfries' },
      { emoji: '🍕', category: 'Food & Drink', searchQuery: 'pizzafood & drinkpizza' },
      { emoji: '🌭', category: 'Food & Drink', searchQuery: 'hot dogfood & drinkhotdog' },
      { emoji: '🥪', category: 'Food & Drink', searchQuery: 'sandwichfood & drinksandwich' },
      { emoji: '🌮', category: 'Food & Drink', searchQuery: 'tacofood & drinktaco' },
      { emoji: '🌯', category: 'Food & Drink', searchQuery: 'burritofood & drinkburrito' },
      { emoji: '🫔', category: 'Food & Drink', searchQuery: 'tamalefood & drinktamale' },
      {
        emoji: '🥙',
        category: 'Food & Drink',
        searchQuery: 'stuffed flatbreadfood & drinkstuffed_flatbread'
      },
      { emoji: '🧆', category: 'Food & Drink', searchQuery: 'falafelfood & drinkfalafel' },
      { emoji: '🥚', category: 'Food & Drink', searchQuery: 'eggfood & drinkegg' },
      {
        emoji: '🍳',
        category: 'Food & Drink',
        searchQuery: 'cookingfood & drinkfried_eggbreakfast'
      },
      {
        emoji: '🥘',
        category: 'Food & Drink',
        searchQuery: 'shallow pan of foodfood & drinkshallow_pan_of_foodpaellacurry'
      },
      { emoji: '🍲', category: 'Food & Drink', searchQuery: 'pot of foodfood & drinkstew' },
      { emoji: '🫕', category: 'Food & Drink', searchQuery: 'fonduefood & drinkfondue' },
      {
        emoji: '🥣',
        category: 'Food & Drink',
        searchQuery: 'bowl with spoonfood & drinkbowl_with_spoon'
      },
      { emoji: '🥗', category: 'Food & Drink', searchQuery: 'green saladfood & drinkgreen_salad' },
      { emoji: '🍿', category: 'Food & Drink', searchQuery: 'popcornfood & drinkpopcorn' },
      { emoji: '🧈', category: 'Food & Drink', searchQuery: 'butterfood & drinkbutter' },
      { emoji: '🧂', category: 'Food & Drink', searchQuery: 'saltfood & drinksalt' },
      { emoji: '🥫', category: 'Food & Drink', searchQuery: 'canned foodfood & drinkcanned_food' },
      { emoji: '🍱', category: 'Food & Drink', searchQuery: 'bento boxfood & drinkbento' },
      {
        emoji: '🍘',
        category: 'Food & Drink',
        searchQuery: 'rice crackerfood & drinkrice_cracker'
      },
      { emoji: '🍙', category: 'Food & Drink', searchQuery: 'rice ballfood & drinkrice_ball' },
      { emoji: '🍚', category: 'Food & Drink', searchQuery: 'cooked ricefood & drinkrice' },
      { emoji: '🍛', category: 'Food & Drink', searchQuery: 'curry ricefood & drinkcurry' },
      {
        emoji: '🍜',
        category: 'Food & Drink',
        searchQuery: 'steaming bowlfood & drinkramennoodle'
      },
      { emoji: '🍝', category: 'Food & Drink', searchQuery: 'spaghettifood & drinkspaghettipasta' },
      {
        emoji: '🍠',
        category: 'Food & Drink',
        searchQuery: 'roasted sweet potatofood & drinksweet_potato'
      },
      { emoji: '🍢', category: 'Food & Drink', searchQuery: 'odenfood & drinkoden' },
      { emoji: '🍣', category: 'Food & Drink', searchQuery: 'sushifood & drinksushi' },
      {
        emoji: '🍤',
        category: 'Food & Drink',
        searchQuery: 'fried shrimpfood & drinkfried_shrimptempura'
      },
      {
        emoji: '🍥',
        category: 'Food & Drink',
        searchQuery: 'fish cake with swirlfood & drinkfish_cake'
      },
      { emoji: '🥮', category: 'Food & Drink', searchQuery: 'moon cakefood & drinkmoon_cake' },
      { emoji: '🍡', category: 'Food & Drink', searchQuery: 'dangofood & drinkdango' },
      { emoji: '🥟', category: 'Food & Drink', searchQuery: 'dumplingfood & drinkdumpling' },
      {
        emoji: '🥠',
        category: 'Food & Drink',
        searchQuery: 'fortune cookiefood & drinkfortune_cookie'
      },
      { emoji: '🥡', category: 'Food & Drink', searchQuery: 'takeout boxfood & drinktakeout_box' },
      { emoji: '🦀', category: 'Food & Drink', searchQuery: 'crabfood & drinkcrab' },
      { emoji: '🦞', category: 'Food & Drink', searchQuery: 'lobsterfood & drinklobster' },
      { emoji: '🦐', category: 'Food & Drink', searchQuery: 'shrimpfood & drinkshrimp' },
      { emoji: '🦑', category: 'Food & Drink', searchQuery: 'squidfood & drinksquid' },
      { emoji: '🦪', category: 'Food & Drink', searchQuery: 'oysterfood & drinkoyster' },
      { emoji: '🍦', category: 'Food & Drink', searchQuery: 'soft ice creamfood & drinkicecream' },
      { emoji: '🍧', category: 'Food & Drink', searchQuery: 'shaved icefood & drinkshaved_ice' },
      { emoji: '🍨', category: 'Food & Drink', searchQuery: 'ice creamfood & drinkice_cream' },
      { emoji: '🍩', category: 'Food & Drink', searchQuery: 'doughnutfood & drinkdoughnut' },
      { emoji: '🍪', category: 'Food & Drink', searchQuery: 'cookiefood & drinkcookie' },
      {
        emoji: '🎂',
        category: 'Food & Drink',
        searchQuery: 'birthday cakefood & drinkbirthdayparty'
      },
      { emoji: '🍰', category: 'Food & Drink', searchQuery: 'shortcakefood & drinkcakedessert' },
      { emoji: '🧁', category: 'Food & Drink', searchQuery: 'cupcakefood & drinkcupcake' },
      { emoji: '🥧', category: 'Food & Drink', searchQuery: 'piefood & drinkpie' },
      {
        emoji: '🍫',
        category: 'Food & Drink',
        searchQuery: 'chocolate barfood & drinkchocolate_bar'
      },
      { emoji: '🍬', category: 'Food & Drink', searchQuery: 'candyfood & drinkcandysweet' },
      { emoji: '🍭', category: 'Food & Drink', searchQuery: 'lollipopfood & drinklollipop' },
      { emoji: '🍮', category: 'Food & Drink', searchQuery: 'custardfood & drinkcustard' },
      { emoji: '🍯', category: 'Food & Drink', searchQuery: 'honey potfood & drinkhoney_pot' },
      {
        emoji: '🍼',
        category: 'Food & Drink',
        searchQuery: 'baby bottlefood & drinkbaby_bottlemilk'
      },
      { emoji: '🥛', category: 'Food & Drink', searchQuery: 'glass of milkfood & drinkmilk_glass' },
      {
        emoji: '☕',
        category: 'Food & Drink',
        searchQuery: 'hot beveragefood & drinkcoffeecafeespresso'
      },
      { emoji: '🫖', category: 'Food & Drink', searchQuery: 'teapotfood & drinkteapot' },
      {
        emoji: '🍵',
        category: 'Food & Drink',
        searchQuery: 'teacup without handlefood & drinkteagreenbreakfast'
      },
      { emoji: '🍶', category: 'Food & Drink', searchQuery: 'sakefood & drinksake' },
      {
        emoji: '🍾',
        category: 'Food & Drink',
        searchQuery: 'bottle with popping corkfood & drinkchampagnebottlebubblycelebration'
      },
      { emoji: '🍷', category: 'Food & Drink', searchQuery: 'wine glassfood & drinkwine_glass' },
      {
        emoji: '🍸',
        category: 'Food & Drink',
        searchQuery: 'cocktail glassfood & drinkcocktaildrink'
      },
      {
        emoji: '🍹',
        category: 'Food & Drink',
        searchQuery: 'tropical drinkfood & drinktropical_drinksummervacation'
      },
      { emoji: '🍺', category: 'Food & Drink', searchQuery: 'beer mugfood & drinkbeerdrink' },
      {
        emoji: '🍻',
        category: 'Food & Drink',
        searchQuery: 'clinking beer mugsfood & drinkbeersdrinks'
      },
      {
        emoji: '🥂',
        category: 'Food & Drink',
        searchQuery: 'clinking glassesfood & drinkclinking_glassescheerstoast'
      },
      {
        emoji: '🥃',
        category: 'Food & Drink',
        searchQuery: 'tumbler glassfood & drinktumbler_glasswhisky'
      },
      {
        emoji: '🥤',
        category: 'Food & Drink',
        searchQuery: 'cup with strawfood & drinkcup_with_straw'
      },
      { emoji: '🧋', category: 'Food & Drink', searchQuery: 'bubble teafood & drinkbubble_tea' },
      {
        emoji: '🧃',
        category: 'Food & Drink',
        searchQuery: 'beverage boxfood & drinkbeverage_box'
      },
      { emoji: '🧉', category: 'Food & Drink', searchQuery: 'matefood & drinkmate' },
      { emoji: '🧊', category: 'Food & Drink', searchQuery: 'icefood & drinkice_cube' },
      { emoji: '🥢', category: 'Food & Drink', searchQuery: 'chopsticksfood & drinkchopsticks' },
      {
        emoji: '🍽️',
        category: 'Food & Drink',
        searchQuery: 'fork and knife with platefood & drinkplate_with_cutlerydiningdinner'
      },
      {
        emoji: '🍴',
        category: 'Food & Drink',
        searchQuery: 'fork and knifefood & drinkfork_and_knifecutlery'
      },
      { emoji: '🥄', category: 'Food & Drink', searchQuery: 'spoonfood & drinkspoon' },
      {
        emoji: '🔪',
        category: 'Food & Drink',
        searchQuery: 'kitchen knifefood & drinkhochoknifecutchop'
      },
      { emoji: '🏺', category: 'Food & Drink', searchQuery: 'amphorafood & drinkamphora' }
    ]
  },
  {
    category: 'Travel & Places',
    label: '🌍',
    icon: `${ICON_PREFIX}travel`,
    data: [
      {
        emoji: '🌍',
        category: 'Travel & Places',
        searchQuery: 'globe showing europe-africatravel & placesearth_africaglobeworldinternational'
      },
      {
        emoji: '🌎',
        category: 'Travel & Places',
        searchQuery: 'globe showing americastravel & placesearth_americasglobeworldinternational'
      },
      {
        emoji: '🌏',
        category: 'Travel & Places',
        searchQuery: 'globe showing asia-australiatravel & placesearth_asiaglobeworldinternational'
      },
      {
        emoji: '🌐',
        category: 'Travel & Places',
        searchQuery:
          'globe with meridianstravel & placesglobe_with_meridiansworldglobalinternational'
      },
      {
        emoji: '🗺️',
        category: 'Travel & Places',
        searchQuery: 'world maptravel & placesworld_maptravel'
      },
      { emoji: '🗾', category: 'Travel & Places', searchQuery: 'map of japantravel & placesjapan' },
      { emoji: '🧭', category: 'Travel & Places', searchQuery: 'compasstravel & placescompass' },
      {
        emoji: '🏔️',
        category: 'Travel & Places',
        searchQuery: 'snow-capped mountaintravel & placesmountain_snow'
      },
      { emoji: '⛰️', category: 'Travel & Places', searchQuery: 'mountaintravel & placesmountain' },
      { emoji: '🌋', category: 'Travel & Places', searchQuery: 'volcanotravel & placesvolcano' },
      {
        emoji: '🗻',
        category: 'Travel & Places',
        searchQuery: 'mount fujitravel & placesmount_fuji'
      },
      { emoji: '🏕️', category: 'Travel & Places', searchQuery: 'campingtravel & placescamping' },
      {
        emoji: '🏖️',
        category: 'Travel & Places',
        searchQuery: 'beach with umbrellatravel & placesbeach_umbrella'
      },
      { emoji: '🏜️', category: 'Travel & Places', searchQuery: 'deserttravel & placesdesert' },
      {
        emoji: '🏝️',
        category: 'Travel & Places',
        searchQuery: 'desert islandtravel & placesdesert_island'
      },
      {
        emoji: '🏞️',
        category: 'Travel & Places',
        searchQuery: 'national parktravel & placesnational_park'
      },
      { emoji: '🏟️', category: 'Travel & Places', searchQuery: 'stadiumtravel & placesstadium' },
      {
        emoji: '🏛️',
        category: 'Travel & Places',
        searchQuery: 'classical buildingtravel & placesclassical_building'
      },
      {
        emoji: '🏗️',
        category: 'Travel & Places',
        searchQuery: 'building constructiontravel & placesbuilding_construction'
      },
      { emoji: '🧱', category: 'Travel & Places', searchQuery: 'bricktravel & placesbricks' },
      { emoji: '🪨', category: 'Travel & Places', searchQuery: 'rocktravel & placesrock' },
      { emoji: '🪵', category: 'Travel & Places', searchQuery: 'woodtravel & placeswood' },
      { emoji: '🛖', category: 'Travel & Places', searchQuery: 'huttravel & placeshut' },
      { emoji: '🏘️', category: 'Travel & Places', searchQuery: 'housestravel & placeshouses' },
      {
        emoji: '🏚️',
        category: 'Travel & Places',
        searchQuery: 'derelict housetravel & placesderelict_house'
      },
      { emoji: '🏠', category: 'Travel & Places', searchQuery: 'housetravel & placeshouse' },
      {
        emoji: '🏡',
        category: 'Travel & Places',
        searchQuery: 'house with gardentravel & placeshouse_with_garden'
      },
      {
        emoji: '🏢',
        category: 'Travel & Places',
        searchQuery: 'office buildingtravel & placesoffice'
      },
      {
        emoji: '🏣',
        category: 'Travel & Places',
        searchQuery: 'japanese post officetravel & placespost_office'
      },
      {
        emoji: '🏤',
        category: 'Travel & Places',
        searchQuery: 'post officetravel & placeseuropean_post_office'
      },
      { emoji: '🏥', category: 'Travel & Places', searchQuery: 'hospitaltravel & placeshospital' },
      { emoji: '🏦', category: 'Travel & Places', searchQuery: 'banktravel & placesbank' },
      { emoji: '🏨', category: 'Travel & Places', searchQuery: 'hoteltravel & placeshotel' },
      {
        emoji: '🏩',
        category: 'Travel & Places',
        searchQuery: 'love hoteltravel & placeslove_hotel'
      },
      {
        emoji: '🏪',
        category: 'Travel & Places',
        searchQuery: 'convenience storetravel & placesconvenience_store'
      },
      { emoji: '🏫', category: 'Travel & Places', searchQuery: 'schooltravel & placesschool' },
      {
        emoji: '🏬',
        category: 'Travel & Places',
        searchQuery: 'department storetravel & placesdepartment_store'
      },
      { emoji: '🏭', category: 'Travel & Places', searchQuery: 'factorytravel & placesfactory' },
      {
        emoji: '🏯',
        category: 'Travel & Places',
        searchQuery: 'japanese castletravel & placesjapanese_castle'
      },
      {
        emoji: '🏰',
        category: 'Travel & Places',
        searchQuery: 'castletravel & placeseuropean_castle'
      },
      {
        emoji: '💒',
        category: 'Travel & Places',
        searchQuery: 'weddingtravel & placesweddingmarriage'
      },
      {
        emoji: '🗼',
        category: 'Travel & Places',
        searchQuery: 'tokyo towertravel & placestokyo_tower'
      },
      {
        emoji: '🗽',
        category: 'Travel & Places',
        searchQuery: 'statue of libertytravel & placesstatue_of_liberty'
      },
      { emoji: '⛪', category: 'Travel & Places', searchQuery: 'churchtravel & placeschurch' },
      { emoji: '🕌', category: 'Travel & Places', searchQuery: 'mosquetravel & placesmosque' },
      {
        emoji: '🛕',
        category: 'Travel & Places',
        searchQuery: 'hindu templetravel & placeshindu_temple'
      },
      {
        emoji: '🕍',
        category: 'Travel & Places',
        searchQuery: 'synagoguetravel & placessynagogue'
      },
      {
        emoji: '⛩️',
        category: 'Travel & Places',
        searchQuery: 'shinto shrinetravel & placesshinto_shrine'
      },
      { emoji: '🕋', category: 'Travel & Places', searchQuery: 'kaabatravel & placeskaaba' },
      { emoji: '⛲', category: 'Travel & Places', searchQuery: 'fountaintravel & placesfountain' },
      { emoji: '⛺', category: 'Travel & Places', searchQuery: 'tenttravel & placestentcamping' },
      { emoji: '🌁', category: 'Travel & Places', searchQuery: 'foggytravel & placesfoggykarl' },
      {
        emoji: '🌃',
        category: 'Travel & Places',
        searchQuery: 'night with starstravel & placesnight_with_stars'
      },
      {
        emoji: '🏙️',
        category: 'Travel & Places',
        searchQuery: 'cityscapetravel & placescityscapeskyline'
      },
      {
        emoji: '🌄',
        category: 'Travel & Places',
        searchQuery: 'sunrise over mountainstravel & placessunrise_over_mountains'
      },
      { emoji: '🌅', category: 'Travel & Places', searchQuery: 'sunrisetravel & placessunrise' },
      {
        emoji: '🌆',
        category: 'Travel & Places',
        searchQuery: 'cityscape at dusktravel & placescity_sunset'
      },
      {
        emoji: '🌇',
        category: 'Travel & Places',
        searchQuery: 'sunsettravel & placescity_sunrise'
      },
      {
        emoji: '🌉',
        category: 'Travel & Places',
        searchQuery: 'bridge at nighttravel & placesbridge_at_night'
      },
      {
        emoji: '♨️',
        category: 'Travel & Places',
        searchQuery: 'hot springstravel & placeshotsprings'
      },
      {
        emoji: '🎠',
        category: 'Travel & Places',
        searchQuery: 'carousel horsetravel & placescarousel_horse'
      },
      {
        emoji: '🎡',
        category: 'Travel & Places',
        searchQuery: 'ferris wheeltravel & placesferris_wheel'
      },
      {
        emoji: '🎢',
        category: 'Travel & Places',
        searchQuery: 'roller coastertravel & placesroller_coaster'
      },
      { emoji: '💈', category: 'Travel & Places', searchQuery: 'barber poletravel & placesbarber' },
      {
        emoji: '🎪',
        category: 'Travel & Places',
        searchQuery: 'circus tenttravel & placescircus_tent'
      },
      {
        emoji: '🚂',
        category: 'Travel & Places',
        searchQuery: 'locomotivetravel & placessteam_locomotivetrain'
      },
      {
        emoji: '🚃',
        category: 'Travel & Places',
        searchQuery: 'railway cartravel & placesrailway_car'
      },
      {
        emoji: '🚄',
        category: 'Travel & Places',
        searchQuery: 'high-speed traintravel & placesbullettrain_sidetrain'
      },
      {
        emoji: '🚅',
        category: 'Travel & Places',
        searchQuery: 'bullet traintravel & placesbullettrain_fronttrain'
      },
      { emoji: '🚆', category: 'Travel & Places', searchQuery: 'traintravel & placestrain2' },
      { emoji: '🚇', category: 'Travel & Places', searchQuery: 'metrotravel & placesmetro' },
      {
        emoji: '🚈',
        category: 'Travel & Places',
        searchQuery: 'light railtravel & placeslight_rail'
      },
      { emoji: '🚉', category: 'Travel & Places', searchQuery: 'stationtravel & placesstation' },
      { emoji: '🚊', category: 'Travel & Places', searchQuery: 'tramtravel & placestram' },
      { emoji: '🚝', category: 'Travel & Places', searchQuery: 'monorailtravel & placesmonorail' },
      {
        emoji: '🚞',
        category: 'Travel & Places',
        searchQuery: 'mountain railwaytravel & placesmountain_railway'
      },
      { emoji: '🚋', category: 'Travel & Places', searchQuery: 'tram cartravel & placestrain' },
      { emoji: '🚌', category: 'Travel & Places', searchQuery: 'bustravel & placesbus' },
      {
        emoji: '🚍',
        category: 'Travel & Places',
        searchQuery: 'oncoming bustravel & placesoncoming_bus'
      },
      {
        emoji: '🚎',
        category: 'Travel & Places',
        searchQuery: 'trolleybustravel & placestrolleybus'
      },
      { emoji: '🚐', category: 'Travel & Places', searchQuery: 'minibustravel & placesminibus' },
      {
        emoji: '🚑',
        category: 'Travel & Places',
        searchQuery: 'ambulancetravel & placesambulance'
      },
      {
        emoji: '🚒',
        category: 'Travel & Places',
        searchQuery: 'fire enginetravel & placesfire_engine'
      },
      {
        emoji: '🚓',
        category: 'Travel & Places',
        searchQuery: 'police cartravel & placespolice_car'
      },
      {
        emoji: '🚔',
        category: 'Travel & Places',
        searchQuery: 'oncoming police cartravel & placesoncoming_police_car'
      },
      { emoji: '🚕', category: 'Travel & Places', searchQuery: 'taxitravel & placestaxi' },
      {
        emoji: '🚖',
        category: 'Travel & Places',
        searchQuery: 'oncoming taxitravel & placesoncoming_taxi'
      },
      {
        emoji: '🚗',
        category: 'Travel & Places',
        searchQuery: 'automobiletravel & placescarred_car'
      },
      {
        emoji: '🚘',
        category: 'Travel & Places',
        searchQuery: 'oncoming automobiletravel & placesoncoming_automobile'
      },
      {
        emoji: '🚙',
        category: 'Travel & Places',
        searchQuery: 'sport utility vehicletravel & placesblue_car'
      },
      {
        emoji: '🛻',
        category: 'Travel & Places',
        searchQuery: 'pickup trucktravel & placespickup_truck'
      },
      {
        emoji: '🚚',
        category: 'Travel & Places',
        searchQuery: 'delivery trucktravel & placestruck'
      },
      {
        emoji: '🚛',
        category: 'Travel & Places',
        searchQuery: 'articulated lorrytravel & placesarticulated_lorry'
      },
      { emoji: '🚜', category: 'Travel & Places', searchQuery: 'tractortravel & placestractor' },
      {
        emoji: '🏎️',
        category: 'Travel & Places',
        searchQuery: 'racing cartravel & placesracing_car'
      },
      {
        emoji: '🏍️',
        category: 'Travel & Places',
        searchQuery: 'motorcycletravel & placesmotorcycle'
      },
      {
        emoji: '🛵',
        category: 'Travel & Places',
        searchQuery: 'motor scootertravel & placesmotor_scooter'
      },
      {
        emoji: '🦽',
        category: 'Travel & Places',
        searchQuery: 'manual wheelchairtravel & placesmanual_wheelchair'
      },
      {
        emoji: '🦼',
        category: 'Travel & Places',
        searchQuery: 'motorized wheelchairtravel & placesmotorized_wheelchair'
      },
      {
        emoji: '🛺',
        category: 'Travel & Places',
        searchQuery: 'auto rickshawtravel & placesauto_rickshaw'
      },
      {
        emoji: '🚲',
        category: 'Travel & Places',
        searchQuery: 'bicycletravel & placesbikebicycle'
      },
      {
        emoji: '🛴',
        category: 'Travel & Places',
        searchQuery: 'kick scootertravel & placeskick_scooter'
      },
      {
        emoji: '🛹',
        category: 'Travel & Places',
        searchQuery: 'skateboardtravel & placesskateboard'
      },
      {
        emoji: '🛼',
        category: 'Travel & Places',
        searchQuery: 'roller skatetravel & placesroller_skate'
      },
      { emoji: '🚏', category: 'Travel & Places', searchQuery: 'bus stoptravel & placesbusstop' },
      { emoji: '🛣️', category: 'Travel & Places', searchQuery: 'motorwaytravel & placesmotorway' },
      {
        emoji: '🛤️',
        category: 'Travel & Places',
        searchQuery: 'railway tracktravel & placesrailway_track'
      },
      { emoji: '🛢️', category: 'Travel & Places', searchQuery: 'oil drumtravel & placesoil_drum' },
      { emoji: '⛽', category: 'Travel & Places', searchQuery: 'fuel pumptravel & placesfuelpump' },
      {
        emoji: '🚨',
        category: 'Travel & Places',
        searchQuery: 'police car lighttravel & placesrotating_light911emergency'
      },
      {
        emoji: '🚥',
        category: 'Travel & Places',
        searchQuery: 'horizontal traffic lighttravel & placestraffic_light'
      },
      {
        emoji: '🚦',
        category: 'Travel & Places',
        searchQuery: 'vertical traffic lighttravel & placesvertical_traffic_lightsemaphore'
      },
      {
        emoji: '🛑',
        category: 'Travel & Places',
        searchQuery: 'stop signtravel & placesstop_sign'
      },
      {
        emoji: '🚧',
        category: 'Travel & Places',
        searchQuery: 'constructiontravel & placesconstructionwip'
      },
      { emoji: '⚓', category: 'Travel & Places', searchQuery: 'anchortravel & placesanchorship' },
      {
        emoji: '⛵',
        category: 'Travel & Places',
        searchQuery: 'sailboattravel & placesboatsailboat'
      },
      { emoji: '🛶', category: 'Travel & Places', searchQuery: 'canoetravel & placescanoe' },
      {
        emoji: '🚤',
        category: 'Travel & Places',
        searchQuery: 'speedboattravel & placesspeedboatship'
      },
      {
        emoji: '🛳️',
        category: 'Travel & Places',
        searchQuery: 'passenger shiptravel & placespassenger_shipcruise'
      },
      { emoji: '⛴️', category: 'Travel & Places', searchQuery: 'ferrytravel & placesferry' },
      {
        emoji: '🛥️',
        category: 'Travel & Places',
        searchQuery: 'motor boattravel & placesmotor_boat'
      },
      { emoji: '🚢', category: 'Travel & Places', searchQuery: 'shiptravel & placesship' },
      {
        emoji: '✈️',
        category: 'Travel & Places',
        searchQuery: 'airplanetravel & placesairplaneflight'
      },
      {
        emoji: '🛩️',
        category: 'Travel & Places',
        searchQuery: 'small airplanetravel & placessmall_airplaneflight'
      },
      {
        emoji: '🛫',
        category: 'Travel & Places',
        searchQuery: 'airplane departuretravel & placesflight_departure'
      },
      {
        emoji: '🛬',
        category: 'Travel & Places',
        searchQuery: 'airplane arrivaltravel & placesflight_arrival'
      },
      {
        emoji: '🪂',
        category: 'Travel & Places',
        searchQuery: 'parachutetravel & placesparachute'
      },
      { emoji: '💺', category: 'Travel & Places', searchQuery: 'seattravel & placesseat' },
      {
        emoji: '🚁',
        category: 'Travel & Places',
        searchQuery: 'helicoptertravel & placeshelicopter'
      },
      {
        emoji: '🚟',
        category: 'Travel & Places',
        searchQuery: 'suspension railwaytravel & placessuspension_railway'
      },
      {
        emoji: '🚠',
        category: 'Travel & Places',
        searchQuery: 'mountain cablewaytravel & placesmountain_cableway'
      },
      {
        emoji: '🚡',
        category: 'Travel & Places',
        searchQuery: 'aerial tramwaytravel & placesaerial_tramway'
      },
      {
        emoji: '🛰️',
        category: 'Travel & Places',
        searchQuery: 'satellitetravel & placesartificial_satelliteorbitspace'
      },
      {
        emoji: '🚀',
        category: 'Travel & Places',
        searchQuery: 'rockettravel & placesrocketshiplaunch'
      },
      {
        emoji: '🛸',
        category: 'Travel & Places',
        searchQuery: 'flying saucertravel & placesflying_saucerufo'
      },
      {
        emoji: '🛎️',
        category: 'Travel & Places',
        searchQuery: 'bellhop belltravel & placesbellhop_bell'
      },
      { emoji: '🧳', category: 'Travel & Places', searchQuery: 'luggagetravel & placesluggage' },
      {
        emoji: '⌛',
        category: 'Travel & Places',
        searchQuery: 'hourglass donetravel & placeshourglasstime'
      },
      {
        emoji: '⏳',
        category: 'Travel & Places',
        searchQuery: 'hourglass not donetravel & placeshourglass_flowing_sandtime'
      },
      { emoji: '⌚', category: 'Travel & Places', searchQuery: 'watchtravel & placeswatchtime' },
      {
        emoji: '⏰',
        category: 'Travel & Places',
        searchQuery: 'alarm clocktravel & placesalarm_clockmorning'
      },
      {
        emoji: '⏱️',
        category: 'Travel & Places',
        searchQuery: 'stopwatchtravel & placesstopwatch'
      },
      {
        emoji: '⏲️',
        category: 'Travel & Places',
        searchQuery: 'timer clocktravel & placestimer_clock'
      },
      {
        emoji: '🕰️',
        category: 'Travel & Places',
        searchQuery: 'mantelpiece clocktravel & placesmantelpiece_clock'
      },
      {
        emoji: '🕛',
        category: 'Travel & Places',
        searchQuery: 'twelve o’clocktravel & placesclock12'
      },
      {
        emoji: '🕧',
        category: 'Travel & Places',
        searchQuery: 'twelve-thirtytravel & placesclock1230'
      },
      { emoji: '🕐', category: 'Travel & Places', searchQuery: 'one o’clocktravel & placesclock1' },
      {
        emoji: '🕜',
        category: 'Travel & Places',
        searchQuery: 'one-thirtytravel & placesclock130'
      },
      { emoji: '🕑', category: 'Travel & Places', searchQuery: 'two o’clocktravel & placesclock2' },
      {
        emoji: '🕝',
        category: 'Travel & Places',
        searchQuery: 'two-thirtytravel & placesclock230'
      },
      {
        emoji: '🕒',
        category: 'Travel & Places',
        searchQuery: 'three o’clocktravel & placesclock3'
      },
      {
        emoji: '🕞',
        category: 'Travel & Places',
        searchQuery: 'three-thirtytravel & placesclock330'
      },
      {
        emoji: '🕓',
        category: 'Travel & Places',
        searchQuery: 'four o’clocktravel & placesclock4'
      },
      {
        emoji: '🕟',
        category: 'Travel & Places',
        searchQuery: 'four-thirtytravel & placesclock430'
      },
      {
        emoji: '🕔',
        category: 'Travel & Places',
        searchQuery: 'five o’clocktravel & placesclock5'
      },
      {
        emoji: '🕠',
        category: 'Travel & Places',
        searchQuery: 'five-thirtytravel & placesclock530'
      },
      { emoji: '🕕', category: 'Travel & Places', searchQuery: 'six o’clocktravel & placesclock6' },
      {
        emoji: '🕡',
        category: 'Travel & Places',
        searchQuery: 'six-thirtytravel & placesclock630'
      },
      {
        emoji: '🕖',
        category: 'Travel & Places',
        searchQuery: 'seven o’clocktravel & placesclock7'
      },
      {
        emoji: '🕢',
        category: 'Travel & Places',
        searchQuery: 'seven-thirtytravel & placesclock730'
      },
      {
        emoji: '🕗',
        category: 'Travel & Places',
        searchQuery: 'eight o’clocktravel & placesclock8'
      },
      {
        emoji: '🕣',
        category: 'Travel & Places',
        searchQuery: 'eight-thirtytravel & placesclock830'
      },
      {
        emoji: '🕘',
        category: 'Travel & Places',
        searchQuery: 'nine o’clocktravel & placesclock9'
      },
      {
        emoji: '🕤',
        category: 'Travel & Places',
        searchQuery: 'nine-thirtytravel & placesclock930'
      },
      {
        emoji: '🕙',
        category: 'Travel & Places',
        searchQuery: 'ten o’clocktravel & placesclock10'
      },
      {
        emoji: '🕥',
        category: 'Travel & Places',
        searchQuery: 'ten-thirtytravel & placesclock1030'
      },
      {
        emoji: '🕚',
        category: 'Travel & Places',
        searchQuery: 'eleven o’clocktravel & placesclock11'
      },
      {
        emoji: '🕦',
        category: 'Travel & Places',
        searchQuery: 'eleven-thirtytravel & placesclock1130'
      },
      { emoji: '🌑', category: 'Travel & Places', searchQuery: 'new moontravel & placesnew_moon' },
      {
        emoji: '🌒',
        category: 'Travel & Places',
        searchQuery: 'waxing crescent moontravel & placeswaxing_crescent_moon'
      },
      {
        emoji: '🌓',
        category: 'Travel & Places',
        searchQuery: 'first quarter moontravel & placesfirst_quarter_moon'
      },
      {
        emoji: '🌔',
        category: 'Travel & Places',
        searchQuery: 'waxing gibbous moontravel & placesmoonwaxing_gibbous_moon'
      },
      {
        emoji: '🌕',
        category: 'Travel & Places',
        searchQuery: 'full moontravel & placesfull_moon'
      },
      {
        emoji: '🌖',
        category: 'Travel & Places',
        searchQuery: 'waning gibbous moontravel & placeswaning_gibbous_moon'
      },
      {
        emoji: '🌗',
        category: 'Travel & Places',
        searchQuery: 'last quarter moontravel & placeslast_quarter_moon'
      },
      {
        emoji: '🌘',
        category: 'Travel & Places',
        searchQuery: 'waning crescent moontravel & placeswaning_crescent_moon'
      },
      {
        emoji: '🌙',
        category: 'Travel & Places',
        searchQuery: 'crescent moontravel & placescrescent_moonnight'
      },
      {
        emoji: '🌚',
        category: 'Travel & Places',
        searchQuery: 'new moon facetravel & placesnew_moon_with_face'
      },
      {
        emoji: '🌛',
        category: 'Travel & Places',
        searchQuery: 'first quarter moon facetravel & placesfirst_quarter_moon_with_face'
      },
      {
        emoji: '🌜',
        category: 'Travel & Places',
        searchQuery: 'last quarter moon facetravel & placeslast_quarter_moon_with_face'
      },
      {
        emoji: '🌡️',
        category: 'Travel & Places',
        searchQuery: 'thermometertravel & placesthermometer'
      },
      { emoji: '☀️', category: 'Travel & Places', searchQuery: 'suntravel & placessunnyweather' },
      {
        emoji: '🌝',
        category: 'Travel & Places',
        searchQuery: 'full moon facetravel & placesfull_moon_with_face'
      },
      {
        emoji: '🌞',
        category: 'Travel & Places',
        searchQuery: 'sun with facetravel & placessun_with_facesummer'
      },
      {
        emoji: '🪐',
        category: 'Travel & Places',
        searchQuery: 'ringed planettravel & placesringed_planet'
      },
      { emoji: '⭐', category: 'Travel & Places', searchQuery: 'startravel & placesstar' },
      { emoji: '🌟', category: 'Travel & Places', searchQuery: 'glowing startravel & placesstar2' },
      {
        emoji: '🌠',
        category: 'Travel & Places',
        searchQuery: 'shooting startravel & placesstars'
      },
      {
        emoji: '🌌',
        category: 'Travel & Places',
        searchQuery: 'milky waytravel & placesmilky_way'
      },
      { emoji: '☁️', category: 'Travel & Places', searchQuery: 'cloudtravel & placescloud' },
      {
        emoji: '⛅',
        category: 'Travel & Places',
        searchQuery: 'sun behind cloudtravel & placespartly_sunnyweathercloud'
      },
      {
        emoji: '⛈️',
        category: 'Travel & Places',
        searchQuery: 'cloud with lightning and raintravel & placescloud_with_lightning_and_rain'
      },
      {
        emoji: '🌤️',
        category: 'Travel & Places',
        searchQuery: 'sun behind small cloudtravel & placessun_behind_small_cloud'
      },
      {
        emoji: '🌥️',
        category: 'Travel & Places',
        searchQuery: 'sun behind large cloudtravel & placessun_behind_large_cloud'
      },
      {
        emoji: '🌦️',
        category: 'Travel & Places',
        searchQuery: 'sun behind rain cloudtravel & placessun_behind_rain_cloud'
      },
      {
        emoji: '🌧️',
        category: 'Travel & Places',
        searchQuery: 'cloud with raintravel & placescloud_with_rain'
      },
      {
        emoji: '🌨️',
        category: 'Travel & Places',
        searchQuery: 'cloud with snowtravel & placescloud_with_snow'
      },
      {
        emoji: '🌩️',
        category: 'Travel & Places',
        searchQuery: 'cloud with lightningtravel & placescloud_with_lightning'
      },
      { emoji: '🌪️', category: 'Travel & Places', searchQuery: 'tornadotravel & placestornado' },
      { emoji: '🌫️', category: 'Travel & Places', searchQuery: 'fogtravel & placesfog' },
      {
        emoji: '🌬️',
        category: 'Travel & Places',
        searchQuery: 'wind facetravel & placeswind_face'
      },
      {
        emoji: '🌀',
        category: 'Travel & Places',
        searchQuery: 'cyclonetravel & placescycloneswirl'
      },
      { emoji: '🌈', category: 'Travel & Places', searchQuery: 'rainbowtravel & placesrainbow' },
      {
        emoji: '🌂',
        category: 'Travel & Places',
        searchQuery: 'closed umbrellatravel & placesclosed_umbrellaweatherrain'
      },
      {
        emoji: '☂️',
        category: 'Travel & Places',
        searchQuery: 'umbrellatravel & placesopen_umbrella'
      },
      {
        emoji: '☔',
        category: 'Travel & Places',
        searchQuery: 'umbrella with rain dropstravel & placesumbrellarainweather'
      },
      {
        emoji: '⛱️',
        category: 'Travel & Places',
        searchQuery: 'umbrella on groundtravel & placesparasol_on_groundbeach_umbrella'
      },
      {
        emoji: '⚡',
        category: 'Travel & Places',
        searchQuery: 'high voltagetravel & placeszaplightningthunder'
      },
      {
        emoji: '❄️',
        category: 'Travel & Places',
        searchQuery: 'snowflaketravel & placessnowflakewintercoldweather'
      },
      {
        emoji: '☃️',
        category: 'Travel & Places',
        searchQuery: 'snowmantravel & placessnowman_with_snowwinterchristmas'
      },
      {
        emoji: '⛄',
        category: 'Travel & Places',
        searchQuery: 'snowman without snowtravel & placessnowmanwinter'
      },
      { emoji: '☄️', category: 'Travel & Places', searchQuery: 'comettravel & placescomet' },
      { emoji: '🔥', category: 'Travel & Places', searchQuery: 'firetravel & placesfireburn' },
      {
        emoji: '💧',
        category: 'Travel & Places',
        searchQuery: 'droplettravel & placesdropletwater'
      },
      { emoji: '🌊', category: 'Travel & Places', searchQuery: 'water wavetravel & placesoceansea' }
    ]
  },
  {
    category: 'Activities',
    label: '🎇',
    icon: `${ICON_PREFIX}activity`,
    data: [
      {
        emoji: '🎃',
        category: 'Activities',
        searchQuery: 'jack-o-lanternactivitiesjack_o_lanternhalloween'
      },
      {
        emoji: '🎄',
        category: 'Activities',
        searchQuery: 'christmas treeactivitieschristmas_tree'
      },
      {
        emoji: '🎆',
        category: 'Activities',
        searchQuery: 'fireworksactivitiesfireworksfestivalcelebration'
      },
      { emoji: '🎇', category: 'Activities', searchQuery: 'sparkleractivitiessparkler' },
      { emoji: '🧨', category: 'Activities', searchQuery: 'firecrackeractivitiesfirecracker' },
      { emoji: '✨', category: 'Activities', searchQuery: 'sparklesactivitiessparklesshiny' },
      { emoji: '🎈', category: 'Activities', searchQuery: 'balloonactivitiesballoonpartybirthday' },
      { emoji: '🎉', category: 'Activities', searchQuery: 'party popperactivitiestadahoorayparty' },
      { emoji: '🎊', category: 'Activities', searchQuery: 'confetti ballactivitiesconfetti_ball' },
      { emoji: '🎋', category: 'Activities', searchQuery: 'tanabata treeactivitiestanabata_tree' },
      { emoji: '🎍', category: 'Activities', searchQuery: 'pine decorationactivitiesbamboo' },
      { emoji: '🎎', category: 'Activities', searchQuery: 'japanese dollsactivitiesdolls' },
      { emoji: '🎏', category: 'Activities', searchQuery: 'carp streameractivitiesflags' },
      { emoji: '🎐', category: 'Activities', searchQuery: 'wind chimeactivitieswind_chime' },
      {
        emoji: '🎑',
        category: 'Activities',
        searchQuery: 'moon viewing ceremonyactivitiesrice_scene'
      },
      { emoji: '🧧', category: 'Activities', searchQuery: 'red envelopeactivitiesred_envelope' },
      { emoji: '🎀', category: 'Activities', searchQuery: 'ribbonactivitiesribbon' },
      {
        emoji: '🎁',
        category: 'Activities',
        searchQuery: 'wrapped giftactivitiesgiftpresentbirthdaychristmas'
      },
      {
        emoji: '🎗️',
        category: 'Activities',
        searchQuery: 'reminder ribbonactivitiesreminder_ribbon'
      },
      { emoji: '🎟️', category: 'Activities', searchQuery: 'admission ticketsactivitiestickets' },
      { emoji: '🎫', category: 'Activities', searchQuery: 'ticketactivitiesticket' },
      {
        emoji: '🎖️',
        category: 'Activities',
        searchQuery: 'military medalactivitiesmedal_military'
      },
      {
        emoji: '🏆',
        category: 'Activities',
        searchQuery: 'trophyactivitiestrophyawardcontestwinner'
      },
      {
        emoji: '🏅',
        category: 'Activities',
        searchQuery: 'sports medalactivitiesmedal_sportsgoldwinner'
      },
      {
        emoji: '🥇',
        category: 'Activities',
        searchQuery: '1st place medalactivities1st_place_medalgold'
      },
      {
        emoji: '🥈',
        category: 'Activities',
        searchQuery: '2nd place medalactivities2nd_place_medalsilver'
      },
      {
        emoji: '🥉',
        category: 'Activities',
        searchQuery: '3rd place medalactivities3rd_place_medalbronze'
      },
      { emoji: '⚽', category: 'Activities', searchQuery: 'soccer ballactivitiessoccersports' },
      { emoji: '⚾', category: 'Activities', searchQuery: 'baseballactivitiesbaseballsports' },
      { emoji: '🥎', category: 'Activities', searchQuery: 'softballactivitiessoftball' },
      { emoji: '🏀', category: 'Activities', searchQuery: 'basketballactivitiesbasketballsports' },
      { emoji: '🏐', category: 'Activities', searchQuery: 'volleyballactivitiesvolleyball' },
      {
        emoji: '🏈',
        category: 'Activities',
        searchQuery: 'american footballactivitiesfootballsports'
      },
      {
        emoji: '🏉',
        category: 'Activities',
        searchQuery: 'rugby footballactivitiesrugby_football'
      },
      { emoji: '🎾', category: 'Activities', searchQuery: 'tennisactivitiestennissports' },
      { emoji: '🥏', category: 'Activities', searchQuery: 'flying discactivitiesflying_disc' },
      { emoji: '🎳', category: 'Activities', searchQuery: 'bowlingactivitiesbowling' },
      { emoji: '🏏', category: 'Activities', searchQuery: 'cricket gameactivitiescricket_game' },
      { emoji: '🏑', category: 'Activities', searchQuery: 'field hockeyactivitiesfield_hockey' },
      { emoji: '🏒', category: 'Activities', searchQuery: 'ice hockeyactivitiesice_hockey' },
      { emoji: '🥍', category: 'Activities', searchQuery: 'lacrosseactivitieslacrosse' },
      { emoji: '🏓', category: 'Activities', searchQuery: 'ping pongactivitiesping_pong' },
      { emoji: '🏸', category: 'Activities', searchQuery: 'badmintonactivitiesbadminton' },
      { emoji: '🥊', category: 'Activities', searchQuery: 'boxing gloveactivitiesboxing_glove' },
      {
        emoji: '🥋',
        category: 'Activities',
        searchQuery: 'martial arts uniformactivitiesmartial_arts_uniform'
      },
      { emoji: '🥅', category: 'Activities', searchQuery: 'goal netactivitiesgoal_net' },
      { emoji: '⛳', category: 'Activities', searchQuery: 'flag in holeactivitiesgolf' },
      { emoji: '⛸️', category: 'Activities', searchQuery: 'ice skateactivitiesice_skateskating' },
      {
        emoji: '🎣',
        category: 'Activities',
        searchQuery: 'fishing poleactivitiesfishing_pole_and_fish'
      },
      { emoji: '🤿', category: 'Activities', searchQuery: 'diving maskactivitiesdiving_mask' },
      {
        emoji: '🎽',
        category: 'Activities',
        searchQuery: 'running shirtactivitiesrunning_shirt_with_sashmarathon'
      },
      { emoji: '🎿', category: 'Activities', searchQuery: 'skisactivitiesski' },
      { emoji: '🛷', category: 'Activities', searchQuery: 'sledactivitiessled' },
      { emoji: '🥌', category: 'Activities', searchQuery: 'curling stoneactivitiescurling_stone' },
      { emoji: '🎯', category: 'Activities', searchQuery: 'bullseyeactivitiesdarttarget' },
      { emoji: '🪀', category: 'Activities', searchQuery: 'yo-yoactivitiesyo_yo' },
      { emoji: '🪁', category: 'Activities', searchQuery: 'kiteactivitieskite' },
      {
        emoji: '🎱',
        category: 'Activities',
        searchQuery: 'pool 8 ballactivities8ballpoolbilliards'
      },
      {
        emoji: '🔮',
        category: 'Activities',
        searchQuery: 'crystal ballactivitiescrystal_ballfortune'
      },
      { emoji: '🪄', category: 'Activities', searchQuery: 'magic wandactivitiesmagic_wand' },
      { emoji: '🧿', category: 'Activities', searchQuery: 'nazar amuletactivitiesnazar_amulet' },
      {
        emoji: '🎮',
        category: 'Activities',
        searchQuery: 'video gameactivitiesvideo_gameplaycontrollerconsole'
      },
      { emoji: '🕹️', category: 'Activities', searchQuery: 'joystickactivitiesjoystick' },
      { emoji: '🎰', category: 'Activities', searchQuery: 'slot machineactivitiesslot_machine' },
      {
        emoji: '🎲',
        category: 'Activities',
        searchQuery: 'game dieactivitiesgame_diedicegambling'
      },
      { emoji: '🧩', category: 'Activities', searchQuery: 'puzzle pieceactivitiesjigsaw' },
      { emoji: '🧸', category: 'Activities', searchQuery: 'teddy bearactivitiesteddy_bear' },
      { emoji: '🪅', category: 'Activities', searchQuery: 'piñataactivitiespinata' },
      { emoji: '🪆', category: 'Activities', searchQuery: 'nesting dollsactivitiesnesting_dolls' },
      { emoji: '♠️', category: 'Activities', searchQuery: 'spade suitactivitiesspades' },
      { emoji: '♥️', category: 'Activities', searchQuery: 'heart suitactivitieshearts' },
      { emoji: '♦️', category: 'Activities', searchQuery: 'diamond suitactivitiesdiamonds' },
      { emoji: '♣️', category: 'Activities', searchQuery: 'club suitactivitiesclubs' },
      { emoji: '♟️', category: 'Activities', searchQuery: 'chess pawnactivitieschess_pawn' },
      { emoji: '🃏', category: 'Activities', searchQuery: 'jokeractivitiesblack_joker' },
      { emoji: '🀄', category: 'Activities', searchQuery: 'mahjong red dragonactivitiesmahjong' },
      {
        emoji: '🎴',
        category: 'Activities',
        searchQuery: 'flower playing cardsactivitiesflower_playing_cards'
      },
      {
        emoji: '🎭',
        category: 'Activities',
        searchQuery: 'performing artsactivitiesperforming_artstheaterdrama'
      },
      {
        emoji: '🖼️',
        category: 'Activities',
        searchQuery: 'framed pictureactivitiesframed_picture'
      },
      {
        emoji: '🎨',
        category: 'Activities',
        searchQuery: 'artist paletteactivitiesartdesignpaint'
      },
      { emoji: '🧵', category: 'Activities', searchQuery: 'threadactivitiesthread' },
      { emoji: '🪡', category: 'Activities', searchQuery: 'sewing needleactivitiessewing_needle' },
      { emoji: '🧶', category: 'Activities', searchQuery: 'yarnactivitiesyarn' },
      { emoji: '🪢', category: 'Activities', searchQuery: 'knotactivitiesknot' }
    ]
  },
  {
    category: 'Objects',
    label: '👔',
    icon: `${ICON_PREFIX}objects`,
    data: [
      { emoji: '👓', category: 'Objects', searchQuery: 'glassesobjectseyeglassesglasses' },
      { emoji: '🕶️', category: 'Objects', searchQuery: 'sunglassesobjectsdark_sunglasses' },
      { emoji: '🥽', category: 'Objects', searchQuery: 'gogglesobjectsgoggles' },
      { emoji: '🥼', category: 'Objects', searchQuery: 'lab coatobjectslab_coat' },
      { emoji: '🦺', category: 'Objects', searchQuery: 'safety vestobjectssafety_vest' },
      { emoji: '👔', category: 'Objects', searchQuery: 'necktieobjectsnecktieshirtformal' },
      { emoji: '👕', category: 'Objects', searchQuery: 't-shirtobjectsshirttshirt' },
      { emoji: '👖', category: 'Objects', searchQuery: 'jeansobjectsjeanspants' },
      { emoji: '🧣', category: 'Objects', searchQuery: 'scarfobjectsscarf' },
      { emoji: '🧤', category: 'Objects', searchQuery: 'glovesobjectsgloves' },
      { emoji: '🧥', category: 'Objects', searchQuery: 'coatobjectscoat' },
      { emoji: '🧦', category: 'Objects', searchQuery: 'socksobjectssocks' },
      { emoji: '👗', category: 'Objects', searchQuery: 'dressobjectsdress' },
      { emoji: '👘', category: 'Objects', searchQuery: 'kimonoobjectskimono' },
      { emoji: '🥻', category: 'Objects', searchQuery: 'sariobjectssari' },
      {
        emoji: '🩱',
        category: 'Objects',
        searchQuery: 'one-piece swimsuitobjectsone_piece_swimsuit'
      },
      { emoji: '🩲', category: 'Objects', searchQuery: 'briefsobjectsswim_brief' },
      { emoji: '🩳', category: 'Objects', searchQuery: 'shortsobjectsshorts' },
      { emoji: '👙', category: 'Objects', searchQuery: 'bikiniobjectsbikinibeach' },
      { emoji: '👚', category: 'Objects', searchQuery: 'woman’s clothesobjectswomans_clothes' },
      { emoji: '👛', category: 'Objects', searchQuery: 'purseobjectspurse' },
      { emoji: '👜', category: 'Objects', searchQuery: 'handbagobjectshandbagbag' },
      { emoji: '👝', category: 'Objects', searchQuery: 'clutch bagobjectspouchbag' },
      { emoji: '🛍️', category: 'Objects', searchQuery: 'shopping bagsobjectsshoppingbags' },
      { emoji: '🎒', category: 'Objects', searchQuery: 'backpackobjectsschool_satchel' },
      { emoji: '🩴', category: 'Objects', searchQuery: 'thong sandalobjectsthong_sandal' },
      { emoji: '👞', category: 'Objects', searchQuery: 'man’s shoeobjectsmans_shoeshoe' },
      {
        emoji: '👟',
        category: 'Objects',
        searchQuery: 'running shoeobjectsathletic_shoesneakersportrunning'
      },
      { emoji: '🥾', category: 'Objects', searchQuery: 'hiking bootobjectshiking_boot' },
      { emoji: '🥿', category: 'Objects', searchQuery: 'flat shoeobjectsflat_shoe' },
      { emoji: '👠', category: 'Objects', searchQuery: 'high-heeled shoeobjectshigh_heelshoe' },
      { emoji: '👡', category: 'Objects', searchQuery: 'woman’s sandalobjectssandalshoe' },
      { emoji: '🩰', category: 'Objects', searchQuery: 'ballet shoesobjectsballet_shoes' },
      { emoji: '👢', category: 'Objects', searchQuery: 'woman’s bootobjectsboot' },
      { emoji: '👑', category: 'Objects', searchQuery: 'crownobjectscrownkingqueenroyal' },
      { emoji: '👒', category: 'Objects', searchQuery: 'woman’s hatobjectswomans_hat' },
      { emoji: '🎩', category: 'Objects', searchQuery: 'top hatobjectstophathatclassy' },
      {
        emoji: '🎓',
        category: 'Objects',
        searchQuery: 'graduation capobjectsmortar_boardeducationcollegeuniversitygraduation'
      },
      { emoji: '🧢', category: 'Objects', searchQuery: 'billed capobjectsbilled_cap' },
      { emoji: '🪖', category: 'Objects', searchQuery: 'military helmetobjectsmilitary_helmet' },
      {
        emoji: '⛑️',
        category: 'Objects',
        searchQuery: 'rescue worker’s helmetobjectsrescue_worker_helmet'
      },
      { emoji: '📿', category: 'Objects', searchQuery: 'prayer beadsobjectsprayer_beads' },
      { emoji: '💄', category: 'Objects', searchQuery: 'lipstickobjectslipstickmakeup' },
      { emoji: '💍', category: 'Objects', searchQuery: 'ringobjectsringweddingmarriageengaged' },
      { emoji: '💎', category: 'Objects', searchQuery: 'gem stoneobjectsgemdiamond' },
      { emoji: '🔇', category: 'Objects', searchQuery: 'muted speakerobjectsmutesoundvolume' },
      { emoji: '🔈', category: 'Objects', searchQuery: 'speaker low volumeobjectsspeaker' },
      { emoji: '🔉', category: 'Objects', searchQuery: 'speaker medium volumeobjectssoundvolume' },
      {
        emoji: '🔊',
        category: 'Objects',
        searchQuery: 'speaker high volumeobjectsloud_soundvolume'
      },
      {
        emoji: '📢',
        category: 'Objects',
        searchQuery: 'loudspeakerobjectsloudspeakerannouncement'
      },
      { emoji: '📣', category: 'Objects', searchQuery: 'megaphoneobjectsmega' },
      { emoji: '📯', category: 'Objects', searchQuery: 'postal hornobjectspostal_horn' },
      { emoji: '🔔', category: 'Objects', searchQuery: 'bellobjectsbellsoundnotification' },
      { emoji: '🔕', category: 'Objects', searchQuery: 'bell with slashobjectsno_bellvolumeoff' },
      { emoji: '🎼', category: 'Objects', searchQuery: 'musical scoreobjectsmusical_score' },
      { emoji: '🎵', category: 'Objects', searchQuery: 'musical noteobjectsmusical_note' },
      { emoji: '🎶', category: 'Objects', searchQuery: 'musical notesobjectsnotesmusic' },
      {
        emoji: '🎙️',
        category: 'Objects',
        searchQuery: 'studio microphoneobjectsstudio_microphonepodcast'
      },
      { emoji: '🎚️', category: 'Objects', searchQuery: 'level sliderobjectslevel_slider' },
      { emoji: '🎛️', category: 'Objects', searchQuery: 'control knobsobjectscontrol_knobs' },
      { emoji: '🎤', category: 'Objects', searchQuery: 'microphoneobjectsmicrophonesing' },
      { emoji: '🎧', category: 'Objects', searchQuery: 'headphoneobjectsheadphonesmusicearphones' },
      { emoji: '📻', category: 'Objects', searchQuery: 'radioobjectsradiopodcast' },
      { emoji: '🎷', category: 'Objects', searchQuery: 'saxophoneobjectssaxophone' },
      { emoji: '🪗', category: 'Objects', searchQuery: 'accordionobjectsaccordion' },
      { emoji: '🎸', category: 'Objects', searchQuery: 'guitarobjectsguitarrock' },
      {
        emoji: '🎹',
        category: 'Objects',
        searchQuery: 'musical keyboardobjectsmusical_keyboardpiano'
      },
      { emoji: '🎺', category: 'Objects', searchQuery: 'trumpetobjectstrumpet' },
      { emoji: '🎻', category: 'Objects', searchQuery: 'violinobjectsviolin' },
      { emoji: '🪕', category: 'Objects', searchQuery: 'banjoobjectsbanjo' },
      { emoji: '🥁', category: 'Objects', searchQuery: 'drumobjectsdrum' },
      { emoji: '🪘', category: 'Objects', searchQuery: 'long drumobjectslong_drum' },
      {
        emoji: '📱',
        category: 'Objects',
        searchQuery: 'mobile phoneobjectsiphonesmartphonemobile'
      },
      {
        emoji: '📲',
        category: 'Objects',
        searchQuery: 'mobile phone with arrowobjectscallingcallincoming'
      },
      { emoji: '☎️', category: 'Objects', searchQuery: 'telephoneobjectsphonetelephone' },
      {
        emoji: '📞',
        category: 'Objects',
        searchQuery: 'telephone receiverobjectstelephone_receiverphonecall'
      },
      { emoji: '📟', category: 'Objects', searchQuery: 'pagerobjectspager' },
      { emoji: '📠', category: 'Objects', searchQuery: 'fax machineobjectsfax' },
      { emoji: '🔋', category: 'Objects', searchQuery: 'batteryobjectsbatterypower' },
      { emoji: '🔌', category: 'Objects', searchQuery: 'electric plugobjectselectric_plug' },
      { emoji: '💻', category: 'Objects', searchQuery: 'laptopobjectscomputerdesktopscreen' },
      { emoji: '🖥️', category: 'Objects', searchQuery: 'desktop computerobjectsdesktop_computer' },
      { emoji: '🖨️', category: 'Objects', searchQuery: 'printerobjectsprinter' },
      { emoji: '⌨️', category: 'Objects', searchQuery: 'keyboardobjectskeyboard' },
      { emoji: '🖱️', category: 'Objects', searchQuery: 'computer mouseobjectscomputer_mouse' },
      { emoji: '🖲️', category: 'Objects', searchQuery: 'trackballobjectstrackball' },
      { emoji: '💽', category: 'Objects', searchQuery: 'computer diskobjectsminidisc' },
      { emoji: '💾', category: 'Objects', searchQuery: 'floppy diskobjectsfloppy_disksave' },
      { emoji: '💿', category: 'Objects', searchQuery: 'optical diskobjectscd' },
      { emoji: '📀', category: 'Objects', searchQuery: 'dvdobjectsdvd' },
      { emoji: '🧮', category: 'Objects', searchQuery: 'abacusobjectsabacus' },
      { emoji: '🎥', category: 'Objects', searchQuery: 'movie cameraobjectsmovie_camerafilmvideo' },
      { emoji: '🎞️', category: 'Objects', searchQuery: 'film framesobjectsfilm_strip' },
      { emoji: '📽️', category: 'Objects', searchQuery: 'film projectorobjectsfilm_projector' },
      { emoji: '🎬', category: 'Objects', searchQuery: 'clapper boardobjectsclapperfilm' },
      { emoji: '📺', category: 'Objects', searchQuery: 'televisionobjectstv' },
      { emoji: '📷', category: 'Objects', searchQuery: 'cameraobjectscameraphoto' },
      {
        emoji: '📸',
        category: 'Objects',
        searchQuery: 'camera with flashobjectscamera_flashphoto'
      },
      { emoji: '📹', category: 'Objects', searchQuery: 'video cameraobjectsvideo_camera' },
      { emoji: '📼', category: 'Objects', searchQuery: 'videocassetteobjectsvhs' },
      {
        emoji: '🔍',
        category: 'Objects',
        searchQuery: 'magnifying glass tilted leftobjectsmagsearchzoom'
      },
      {
        emoji: '🔎',
        category: 'Objects',
        searchQuery: 'magnifying glass tilted rightobjectsmag_right'
      },
      { emoji: '🕯️', category: 'Objects', searchQuery: 'candleobjectscandle' },
      { emoji: '💡', category: 'Objects', searchQuery: 'light bulbobjectsbulbidealight' },
      { emoji: '🔦', category: 'Objects', searchQuery: 'flashlightobjectsflashlight' },
      {
        emoji: '🏮',
        category: 'Objects',
        searchQuery: 'red paper lanternobjectsizakaya_lanternlantern'
      },
      { emoji: '🪔', category: 'Objects', searchQuery: 'diya lampobjectsdiya_lamp' },
      {
        emoji: '📔',
        category: 'Objects',
        searchQuery: 'notebook with decorative coverobjectsnotebook_with_decorative_cover'
      },
      { emoji: '📕', category: 'Objects', searchQuery: 'closed bookobjectsclosed_book' },
      { emoji: '📖', category: 'Objects', searchQuery: 'open bookobjectsbookopen_book' },
      { emoji: '📗', category: 'Objects', searchQuery: 'green bookobjectsgreen_book' },
      { emoji: '📘', category: 'Objects', searchQuery: 'blue bookobjectsblue_book' },
      { emoji: '📙', category: 'Objects', searchQuery: 'orange bookobjectsorange_book' },
      { emoji: '📚', category: 'Objects', searchQuery: 'booksobjectsbookslibrary' },
      { emoji: '📓', category: 'Objects', searchQuery: 'notebookobjectsnotebook' },
      { emoji: '📒', category: 'Objects', searchQuery: 'ledgerobjectsledger' },
      { emoji: '📃', category: 'Objects', searchQuery: 'page with curlobjectspage_with_curl' },
      { emoji: '📜', category: 'Objects', searchQuery: 'scrollobjectsscrolldocument' },
      {
        emoji: '📄',
        category: 'Objects',
        searchQuery: 'page facing upobjectspage_facing_updocument'
      },
      { emoji: '📰', category: 'Objects', searchQuery: 'newspaperobjectsnewspaperpress' },
      {
        emoji: '🗞️',
        category: 'Objects',
        searchQuery: 'rolled-up newspaperobjectsnewspaper_rollpress'
      },
      { emoji: '📑', category: 'Objects', searchQuery: 'bookmark tabsobjectsbookmark_tabs' },
      { emoji: '🔖', category: 'Objects', searchQuery: 'bookmarkobjectsbookmark' },
      { emoji: '🏷️', category: 'Objects', searchQuery: 'labelobjectslabeltag' },
      { emoji: '💰', category: 'Objects', searchQuery: 'money bagobjectsmoneybagdollarcream' },
      { emoji: '🪙', category: 'Objects', searchQuery: 'coinobjectscoin' },
      { emoji: '💴', category: 'Objects', searchQuery: 'yen banknoteobjectsyen' },
      { emoji: '💵', category: 'Objects', searchQuery: 'dollar banknoteobjectsdollarmoney' },
      { emoji: '💶', category: 'Objects', searchQuery: 'euro banknoteobjectseuro' },
      { emoji: '💷', category: 'Objects', searchQuery: 'pound banknoteobjectspound' },
      {
        emoji: '💸',
        category: 'Objects',
        searchQuery: 'money with wingsobjectsmoney_with_wingsdollar'
      },
      {
        emoji: '💳',
        category: 'Objects',
        searchQuery: 'credit cardobjectscredit_cardsubscription'
      },
      { emoji: '🧾', category: 'Objects', searchQuery: 'receiptobjectsreceipt' },
      { emoji: '💹', category: 'Objects', searchQuery: 'chart increasing with yenobjectschart' },
      { emoji: '✉️', category: 'Objects', searchQuery: 'envelopeobjectsenvelopeletteremail' },
      { emoji: '📧', category: 'Objects', searchQuery: 'e-mailobjectsemaile-mail' },
      {
        emoji: '📨',
        category: 'Objects',
        searchQuery: 'incoming envelopeobjectsincoming_envelope'
      },
      {
        emoji: '📩',
        category: 'Objects',
        searchQuery: 'envelope with arrowobjectsenvelope_with_arrow'
      },
      { emoji: '📤', category: 'Objects', searchQuery: 'outbox trayobjectsoutbox_tray' },
      { emoji: '📥', category: 'Objects', searchQuery: 'inbox trayobjectsinbox_tray' },
      { emoji: '📦', category: 'Objects', searchQuery: 'packageobjectspackageshipping' },
      {
        emoji: '📫',
        category: 'Objects',
        searchQuery: 'closed mailbox with raised flagobjectsmailbox'
      },
      {
        emoji: '📪',
        category: 'Objects',
        searchQuery: 'closed mailbox with lowered flagobjectsmailbox_closed'
      },
      {
        emoji: '📬',
        category: 'Objects',
        searchQuery: 'open mailbox with raised flagobjectsmailbox_with_mail'
      },
      {
        emoji: '📭',
        category: 'Objects',
        searchQuery: 'open mailbox with lowered flagobjectsmailbox_with_no_mail'
      },
      { emoji: '📮', category: 'Objects', searchQuery: 'postboxobjectspostbox' },
      { emoji: '🗳️', category: 'Objects', searchQuery: 'ballot box with ballotobjectsballot_box' },
      { emoji: '✏️', category: 'Objects', searchQuery: 'pencilobjectspencil2' },
      { emoji: '✒️', category: 'Objects', searchQuery: 'black nibobjectsblack_nib' },
      { emoji: '🖋️', category: 'Objects', searchQuery: 'fountain penobjectsfountain_pen' },
      { emoji: '🖊️', category: 'Objects', searchQuery: 'penobjectspen' },
      { emoji: '🖌️', category: 'Objects', searchQuery: 'paintbrushobjectspaintbrush' },
      { emoji: '🖍️', category: 'Objects', searchQuery: 'crayonobjectscrayon' },
      { emoji: '📝', category: 'Objects', searchQuery: 'memoobjectsmemopencildocumentnote' },
      { emoji: '💼', category: 'Objects', searchQuery: 'briefcaseobjectsbriefcasebusiness' },
      { emoji: '📁', category: 'Objects', searchQuery: 'file folderobjectsfile_folderdirectory' },
      { emoji: '📂', category: 'Objects', searchQuery: 'open file folderobjectsopen_file_folder' },
      {
        emoji: '🗂️',
        category: 'Objects',
        searchQuery: 'card index dividersobjectscard_index_dividers'
      },
      { emoji: '📅', category: 'Objects', searchQuery: 'calendarobjectsdatecalendarschedule' },
      { emoji: '📆', category: 'Objects', searchQuery: 'tear-off calendarobjectscalendarschedule' },
      { emoji: '🗒️', category: 'Objects', searchQuery: 'spiral notepadobjectsspiral_notepad' },
      { emoji: '🗓️', category: 'Objects', searchQuery: 'spiral calendarobjectsspiral_calendar' },
      { emoji: '📇', category: 'Objects', searchQuery: 'card indexobjectscard_index' },
      {
        emoji: '📈',
        category: 'Objects',
        searchQuery: 'chart increasingobjectschart_with_upwards_trendgraphmetrics'
      },
      {
        emoji: '📉',
        category: 'Objects',
        searchQuery: 'chart decreasingobjectschart_with_downwards_trendgraphmetrics'
      },
      { emoji: '📊', category: 'Objects', searchQuery: 'bar chartobjectsbar_chartstatsmetrics' },
      { emoji: '📋', category: 'Objects', searchQuery: 'clipboardobjectsclipboard' },
      { emoji: '📌', category: 'Objects', searchQuery: 'pushpinobjectspushpinlocation' },
      {
        emoji: '📍',
        category: 'Objects',
        searchQuery: 'round pushpinobjectsround_pushpinlocation'
      },
      { emoji: '📎', category: 'Objects', searchQuery: 'paperclipobjectspaperclip' },
      { emoji: '🖇️', category: 'Objects', searchQuery: 'linked paperclipsobjectspaperclips' },
      { emoji: '📏', category: 'Objects', searchQuery: 'straight rulerobjectsstraight_ruler' },
      { emoji: '📐', category: 'Objects', searchQuery: 'triangular rulerobjectstriangular_ruler' },
      { emoji: '✂️', category: 'Objects', searchQuery: 'scissorsobjectsscissorscut' },
      { emoji: '🗃️', category: 'Objects', searchQuery: 'card file boxobjectscard_file_box' },
      { emoji: '🗄️', category: 'Objects', searchQuery: 'file cabinetobjectsfile_cabinet' },
      { emoji: '🗑️', category: 'Objects', searchQuery: 'wastebasketobjectswastebaskettrash' },
      { emoji: '🔒', category: 'Objects', searchQuery: 'lockedobjectslocksecurityprivate' },
      { emoji: '🔓', category: 'Objects', searchQuery: 'unlockedobjectsunlocksecurity' },
      { emoji: '🔏', category: 'Objects', searchQuery: 'locked with penobjectslock_with_ink_pen' },
      {
        emoji: '🔐',
        category: 'Objects',
        searchQuery: 'locked with keyobjectsclosed_lock_with_keysecurity'
      },
      { emoji: '🔑', category: 'Objects', searchQuery: 'keyobjectskeylockpassword' },
      { emoji: '🗝️', category: 'Objects', searchQuery: 'old keyobjectsold_key' },
      { emoji: '🔨', category: 'Objects', searchQuery: 'hammerobjectshammertool' },
      { emoji: '🪓', category: 'Objects', searchQuery: 'axeobjectsaxe' },
      { emoji: '⛏️', category: 'Objects', searchQuery: 'pickobjectspick' },
      { emoji: '⚒️', category: 'Objects', searchQuery: 'hammer and pickobjectshammer_and_pick' },
      {
        emoji: '🛠️',
        category: 'Objects',
        searchQuery: 'hammer and wrenchobjectshammer_and_wrench'
      },
      { emoji: '🗡️', category: 'Objects', searchQuery: 'daggerobjectsdagger' },
      { emoji: '⚔️', category: 'Objects', searchQuery: 'crossed swordsobjectscrossed_swords' },
      { emoji: '🔫', category: 'Objects', searchQuery: 'water pistolobjectsgunshootweapon' },
      { emoji: '🪃', category: 'Objects', searchQuery: 'boomerangobjectsboomerang' },
      { emoji: '🏹', category: 'Objects', searchQuery: 'bow and arrowobjectsbow_and_arrowarchery' },
      { emoji: '🛡️', category: 'Objects', searchQuery: 'shieldobjectsshield' },
      { emoji: '🪚', category: 'Objects', searchQuery: 'carpentry sawobjectscarpentry_saw' },
      { emoji: '🔧', category: 'Objects', searchQuery: 'wrenchobjectswrenchtool' },
      { emoji: '🪛', category: 'Objects', searchQuery: 'screwdriverobjectsscrewdriver' },
      { emoji: '🔩', category: 'Objects', searchQuery: 'nut and boltobjectsnut_and_bolt' },
      { emoji: '⚙️', category: 'Objects', searchQuery: 'gearobjectsgear' },
      { emoji: '🗜️', category: 'Objects', searchQuery: 'clampobjectsclamp' },
      { emoji: '⚖️', category: 'Objects', searchQuery: 'balance scaleobjectsbalance_scale' },
      { emoji: '🦯', category: 'Objects', searchQuery: 'white caneobjectsprobing_cane' },
      { emoji: '🔗', category: 'Objects', searchQuery: 'linkobjectslink' },
      { emoji: '⛓️', category: 'Objects', searchQuery: 'chainsobjectschains' },
      { emoji: '🪝', category: 'Objects', searchQuery: 'hookobjectshook' },
      { emoji: '🧰', category: 'Objects', searchQuery: 'toolboxobjectstoolbox' },
      { emoji: '🧲', category: 'Objects', searchQuery: 'magnetobjectsmagnet' },
      { emoji: '🪜', category: 'Objects', searchQuery: 'ladderobjectsladder' },
      { emoji: '⚗️', category: 'Objects', searchQuery: 'alembicobjectsalembic' },
      { emoji: '🧪', category: 'Objects', searchQuery: 'test tubeobjectstest_tube' },
      { emoji: '🧫', category: 'Objects', searchQuery: 'petri dishobjectspetri_dish' },
      { emoji: '🧬', category: 'Objects', searchQuery: 'dnaobjectsdna' },
      {
        emoji: '🔬',
        category: 'Objects',
        searchQuery: 'microscopeobjectsmicroscopesciencelaboratoryinvestigate'
      },
      { emoji: '🔭', category: 'Objects', searchQuery: 'telescopeobjectstelescope' },
      { emoji: '📡', category: 'Objects', searchQuery: 'satellite antennaobjectssatellitesignal' },
      {
        emoji: '💉',
        category: 'Objects',
        searchQuery: 'syringeobjectssyringehealthhospitalneedle'
      },
      { emoji: '🩸', category: 'Objects', searchQuery: 'drop of bloodobjectsdrop_of_blood' },
      { emoji: '💊', category: 'Objects', searchQuery: 'pillobjectspillhealthmedicine' },
      { emoji: '🩹', category: 'Objects', searchQuery: 'adhesive bandageobjectsadhesive_bandage' },
      { emoji: '🩺', category: 'Objects', searchQuery: 'stethoscopeobjectsstethoscope' },
      { emoji: '🚪', category: 'Objects', searchQuery: 'doorobjectsdoor' },
      { emoji: '🛗', category: 'Objects', searchQuery: 'elevatorobjectselevator' },
      { emoji: '🪞', category: 'Objects', searchQuery: 'mirrorobjectsmirror' },
      { emoji: '🪟', category: 'Objects', searchQuery: 'windowobjectswindow' },
      { emoji: '🛏️', category: 'Objects', searchQuery: 'bedobjectsbed' },
      { emoji: '🛋️', category: 'Objects', searchQuery: 'couch and lampobjectscouch_and_lamp' },
      { emoji: '🪑', category: 'Objects', searchQuery: 'chairobjectschair' },
      { emoji: '🚽', category: 'Objects', searchQuery: 'toiletobjectstoiletwc' },
      { emoji: '🪠', category: 'Objects', searchQuery: 'plungerobjectsplunger' },
      { emoji: '🚿', category: 'Objects', searchQuery: 'showerobjectsshowerbath' },
      { emoji: '🛁', category: 'Objects', searchQuery: 'bathtubobjectsbathtub' },
      { emoji: '🪤', category: 'Objects', searchQuery: 'mouse trapobjectsmouse_trap' },
      { emoji: '🪒', category: 'Objects', searchQuery: 'razorobjectsrazor' },
      { emoji: '🧴', category: 'Objects', searchQuery: 'lotion bottleobjectslotion_bottle' },
      { emoji: '🧷', category: 'Objects', searchQuery: 'safety pinobjectssafety_pin' },
      { emoji: '🧹', category: 'Objects', searchQuery: 'broomobjectsbroom' },
      { emoji: '🧺', category: 'Objects', searchQuery: 'basketobjectsbasket' },
      { emoji: '🧻', category: 'Objects', searchQuery: 'roll of paperobjectsroll_of_papertoilet' },
      { emoji: '🪣', category: 'Objects', searchQuery: 'bucketobjectsbucket' },
      { emoji: '🧼', category: 'Objects', searchQuery: 'soapobjectssoap' },
      { emoji: '🪥', category: 'Objects', searchQuery: 'toothbrushobjectstoothbrush' },
      { emoji: '🧽', category: 'Objects', searchQuery: 'spongeobjectssponge' },
      {
        emoji: '🧯',
        category: 'Objects',
        searchQuery: 'fire extinguisherobjectsfire_extinguisher'
      },
      { emoji: '🛒', category: 'Objects', searchQuery: 'shopping cartobjectsshopping_cart' },
      { emoji: '🚬', category: 'Objects', searchQuery: 'cigaretteobjectssmokingcigarette' },
      { emoji: '⚰️', category: 'Objects', searchQuery: 'coffinobjectscoffinfuneral' },
      { emoji: '🪦', category: 'Objects', searchQuery: 'headstoneobjectsheadstone' },
      { emoji: '⚱️', category: 'Objects', searchQuery: 'funeral urnobjectsfuneral_urn' },
      { emoji: '🗿', category: 'Objects', searchQuery: 'moaiobjectsmoyaistone' },
      { emoji: '🪧', category: 'Objects', searchQuery: 'placardobjectsplacard' }
    ]
  },
  {
    category: 'Symbols',
    label: '⚠️',
    icon: `${ICON_PREFIX}symbol`,
    data: [
      { emoji: '🏧', category: 'Symbols', searchQuery: 'atm signsymbolsatm' },
      {
        emoji: '🚮',
        category: 'Symbols',
        searchQuery: 'litter in bin signsymbolsput_litter_in_its_place'
      },
      { emoji: '🚰', category: 'Symbols', searchQuery: 'potable watersymbolspotable_water' },
      {
        emoji: '♿',
        category: 'Symbols',
        searchQuery: 'wheelchair symbolsymbolswheelchairaccessibility'
      },
      { emoji: '🚹', category: 'Symbols', searchQuery: 'men’s roomsymbolsmens' },
      { emoji: '🚺', category: 'Symbols', searchQuery: 'women’s roomsymbolswomens' },
      { emoji: '🚻', category: 'Symbols', searchQuery: 'restroomsymbolsrestroomtoilet' },
      { emoji: '🚼', category: 'Symbols', searchQuery: 'baby symbolsymbolsbaby_symbol' },
      { emoji: '🚾', category: 'Symbols', searchQuery: 'water closetsymbolswctoiletrestroom' },
      { emoji: '🛂', category: 'Symbols', searchQuery: 'passport controlsymbolspassport_control' },
      { emoji: '🛃', category: 'Symbols', searchQuery: 'customssymbolscustoms' },
      { emoji: '🛄', category: 'Symbols', searchQuery: 'baggage claimsymbolsbaggage_claimairport' },
      { emoji: '🛅', category: 'Symbols', searchQuery: 'left luggagesymbolsleft_luggage' },
      { emoji: '⚠️', category: 'Symbols', searchQuery: 'warningsymbolswarningwip' },
      {
        emoji: '🚸',
        category: 'Symbols',
        searchQuery: 'children crossingsymbolschildren_crossing'
      },
      { emoji: '⛔', category: 'Symbols', searchQuery: 'no entrysymbolsno_entrylimit' },
      {
        emoji: '🚫',
        category: 'Symbols',
        searchQuery: 'prohibitedsymbolsno_entry_signblockforbidden'
      },
      { emoji: '🚳', category: 'Symbols', searchQuery: 'no bicyclessymbolsno_bicycles' },
      { emoji: '🚭', category: 'Symbols', searchQuery: 'no smokingsymbolsno_smoking' },
      { emoji: '🚯', category: 'Symbols', searchQuery: 'no litteringsymbolsdo_not_litter' },
      {
        emoji: '🚱',
        category: 'Symbols',
        searchQuery: 'non-potable watersymbolsnon-potable_water'
      },
      { emoji: '🚷', category: 'Symbols', searchQuery: 'no pedestrianssymbolsno_pedestrians' },
      { emoji: '📵', category: 'Symbols', searchQuery: 'no mobile phonessymbolsno_mobile_phones' },
      { emoji: '🔞', category: 'Symbols', searchQuery: 'no one under eighteensymbolsunderage' },
      { emoji: '☢️', category: 'Symbols', searchQuery: 'radioactivesymbolsradioactive' },
      { emoji: '☣️', category: 'Symbols', searchQuery: 'biohazardsymbolsbiohazard' },
      { emoji: '⬆️', category: 'Symbols', searchQuery: 'up arrowsymbolsarrow_up' },
      { emoji: '↗️', category: 'Symbols', searchQuery: 'up-right arrowsymbolsarrow_upper_right' },
      { emoji: '➡️', category: 'Symbols', searchQuery: 'right arrowsymbolsarrow_right' },
      { emoji: '↘️', category: 'Symbols', searchQuery: 'down-right arrowsymbolsarrow_lower_right' },
      { emoji: '⬇️', category: 'Symbols', searchQuery: 'down arrowsymbolsarrow_down' },
      { emoji: '↙️', category: 'Symbols', searchQuery: 'down-left arrowsymbolsarrow_lower_left' },
      { emoji: '⬅️', category: 'Symbols', searchQuery: 'left arrowsymbolsarrow_left' },
      { emoji: '↖️', category: 'Symbols', searchQuery: 'up-left arrowsymbolsarrow_upper_left' },
      { emoji: '↕️', category: 'Symbols', searchQuery: 'up-down arrowsymbolsarrow_up_down' },
      { emoji: '↔️', category: 'Symbols', searchQuery: 'left-right arrowsymbolsleft_right_arrow' },
      {
        emoji: '↩️',
        category: 'Symbols',
        searchQuery: 'right arrow curving leftsymbolsleftwards_arrow_with_hookreturn'
      },
      {
        emoji: '↪️',
        category: 'Symbols',
        searchQuery: 'left arrow curving rightsymbolsarrow_right_hook'
      },
      {
        emoji: '⤴️',
        category: 'Symbols',
        searchQuery: 'right arrow curving upsymbolsarrow_heading_up'
      },
      {
        emoji: '⤵️',
        category: 'Symbols',
        searchQuery: 'right arrow curving downsymbolsarrow_heading_down'
      },
      {
        emoji: '🔃',
        category: 'Symbols',
        searchQuery: 'clockwise vertical arrowssymbolsarrows_clockwise'
      },
      {
        emoji: '🔄',
        category: 'Symbols',
        searchQuery: 'counterclockwise arrows buttonsymbolsarrows_counterclockwisesync'
      },
      { emoji: '🔙', category: 'Symbols', searchQuery: 'back arrowsymbolsback' },
      { emoji: '🔚', category: 'Symbols', searchQuery: 'end arrowsymbolsend' },
      { emoji: '🔛', category: 'Symbols', searchQuery: 'on! arrowsymbolson' },
      { emoji: '🔜', category: 'Symbols', searchQuery: 'soon arrowsymbolssoon' },
      { emoji: '🔝', category: 'Symbols', searchQuery: 'top arrowsymbolstop' },
      { emoji: '🛐', category: 'Symbols', searchQuery: 'place of worshipsymbolsplace_of_worship' },
      { emoji: '⚛️', category: 'Symbols', searchQuery: 'atom symbolsymbolsatom_symbol' },
      { emoji: '🕉️', category: 'Symbols', searchQuery: 'omsymbolsom' },
      { emoji: '✡️', category: 'Symbols', searchQuery: 'star of davidsymbolsstar_of_david' },
      { emoji: '☸️', category: 'Symbols', searchQuery: 'wheel of dharmasymbolswheel_of_dharma' },
      { emoji: '☯️', category: 'Symbols', searchQuery: 'yin yangsymbolsyin_yang' },
      { emoji: '✝️', category: 'Symbols', searchQuery: 'latin crosssymbolslatin_cross' },
      { emoji: '☦️', category: 'Symbols', searchQuery: 'orthodox crosssymbolsorthodox_cross' },
      {
        emoji: '☪️',
        category: 'Symbols',
        searchQuery: 'star and crescentsymbolsstar_and_crescent'
      },
      { emoji: '☮️', category: 'Symbols', searchQuery: 'peace symbolsymbolspeace_symbol' },
      { emoji: '🕎', category: 'Symbols', searchQuery: 'menorahsymbolsmenorah' },
      {
        emoji: '🔯',
        category: 'Symbols',
        searchQuery: 'dotted six-pointed starsymbolssix_pointed_star'
      },
      { emoji: '♈', category: 'Symbols', searchQuery: 'ariessymbolsaries' },
      { emoji: '♉', category: 'Symbols', searchQuery: 'taurussymbolstaurus' },
      { emoji: '♊', category: 'Symbols', searchQuery: 'geminisymbolsgemini' },
      { emoji: '♋', category: 'Symbols', searchQuery: 'cancersymbolscancer' },
      { emoji: '♌', category: 'Symbols', searchQuery: 'leosymbolsleo' },
      { emoji: '♍', category: 'Symbols', searchQuery: 'virgosymbolsvirgo' },
      { emoji: '♎', category: 'Symbols', searchQuery: 'librasymbolslibra' },
      { emoji: '♏', category: 'Symbols', searchQuery: 'scorpiosymbolsscorpius' },
      { emoji: '♐', category: 'Symbols', searchQuery: 'sagittariussymbolssagittarius' },
      { emoji: '♑', category: 'Symbols', searchQuery: 'capricornsymbolscapricorn' },
      { emoji: '♒', category: 'Symbols', searchQuery: 'aquariussymbolsaquarius' },
      { emoji: '♓', category: 'Symbols', searchQuery: 'piscessymbolspisces' },
      { emoji: '⛎', category: 'Symbols', searchQuery: 'ophiuchussymbolsophiuchus' },
      {
        emoji: '🔀',
        category: 'Symbols',
        searchQuery: 'shuffle tracks buttonsymbolstwisted_rightwards_arrowsshuffle'
      },
      { emoji: '🔁', category: 'Symbols', searchQuery: 'repeat buttonsymbolsrepeatloop' },
      { emoji: '🔂', category: 'Symbols', searchQuery: 'repeat single buttonsymbolsrepeat_one' },
      { emoji: '▶️', category: 'Symbols', searchQuery: 'play buttonsymbolsarrow_forward' },
      { emoji: '⏩', category: 'Symbols', searchQuery: 'fast-forward buttonsymbolsfast_forward' },
      {
        emoji: '⏭️',
        category: 'Symbols',
        searchQuery: 'next track buttonsymbolsnext_track_button'
      },
      {
        emoji: '⏯️',
        category: 'Symbols',
        searchQuery: 'play or pause buttonsymbolsplay_or_pause_button'
      },
      { emoji: '◀️', category: 'Symbols', searchQuery: 'reverse buttonsymbolsarrow_backward' },
      { emoji: '⏪', category: 'Symbols', searchQuery: 'fast reverse buttonsymbolsrewind' },
      {
        emoji: '⏮️',
        category: 'Symbols',
        searchQuery: 'last track buttonsymbolsprevious_track_button'
      },
      { emoji: '🔼', category: 'Symbols', searchQuery: 'upwards buttonsymbolsarrow_up_small' },
      { emoji: '⏫', category: 'Symbols', searchQuery: 'fast up buttonsymbolsarrow_double_up' },
      { emoji: '🔽', category: 'Symbols', searchQuery: 'downwards buttonsymbolsarrow_down_small' },
      { emoji: '⏬', category: 'Symbols', searchQuery: 'fast down buttonsymbolsarrow_double_down' },
      { emoji: '⏸️', category: 'Symbols', searchQuery: 'pause buttonsymbolspause_button' },
      { emoji: '⏹️', category: 'Symbols', searchQuery: 'stop buttonsymbolsstop_button' },
      { emoji: '⏺️', category: 'Symbols', searchQuery: 'record buttonsymbolsrecord_button' },
      { emoji: '⏏️', category: 'Symbols', searchQuery: 'eject buttonsymbolseject_button' },
      { emoji: '🎦', category: 'Symbols', searchQuery: 'cinemasymbolscinemafilmmovie' },
      { emoji: '🔅', category: 'Symbols', searchQuery: 'dim buttonsymbolslow_brightness' },
      { emoji: '🔆', category: 'Symbols', searchQuery: 'bright buttonsymbolshigh_brightness' },
      { emoji: '📶', category: 'Symbols', searchQuery: 'antenna barssymbolssignal_strengthwifi' },
      { emoji: '📳', category: 'Symbols', searchQuery: 'vibration modesymbolsvibration_mode' },
      {
        emoji: '📴',
        category: 'Symbols',
        searchQuery: 'mobile phone offsymbolsmobile_phone_offmuteoff'
      },
      { emoji: '♀️', category: 'Symbols', searchQuery: 'female signsymbolsfemale_sign' },
      { emoji: '♂️', category: 'Symbols', searchQuery: 'male signsymbolsmale_sign' },
      {
        emoji: '⚧️',
        category: 'Symbols',
        searchQuery: 'transgender symbolsymbolstransgender_symbol'
      },
      { emoji: '✖️', category: 'Symbols', searchQuery: 'multiplysymbolsheavy_multiplication_x' },
      { emoji: '➕', category: 'Symbols', searchQuery: 'plussymbolsheavy_plus_sign' },
      { emoji: '➖', category: 'Symbols', searchQuery: 'minussymbolsheavy_minus_sign' },
      { emoji: '➗', category: 'Symbols', searchQuery: 'dividesymbolsheavy_division_sign' },
      { emoji: '♾️', category: 'Symbols', searchQuery: 'infinitysymbolsinfinity' },
      { emoji: '‼️', category: 'Symbols', searchQuery: 'double exclamation marksymbolsbangbang' },
      {
        emoji: '⁉️',
        category: 'Symbols',
        searchQuery: 'exclamation question marksymbolsinterrobang'
      },
      { emoji: '❓', category: 'Symbols', searchQuery: 'red question marksymbolsquestionconfused' },
      { emoji: '❔', category: 'Symbols', searchQuery: 'white question marksymbolsgrey_question' },
      {
        emoji: '❕',
        category: 'Symbols',
        searchQuery: 'white exclamation marksymbolsgrey_exclamation'
      },
      {
        emoji: '❗',
        category: 'Symbols',
        searchQuery: 'red exclamation marksymbolsexclamationheavy_exclamation_markbang'
      },
      { emoji: '〰️', category: 'Symbols', searchQuery: 'wavy dashsymbolswavy_dash' },
      {
        emoji: '💱',
        category: 'Symbols',
        searchQuery: 'currency exchangesymbolscurrency_exchange'
      },
      {
        emoji: '💲',
        category: 'Symbols',
        searchQuery: 'heavy dollar signsymbolsheavy_dollar_sign'
      },
      { emoji: '⚕️', category: 'Symbols', searchQuery: 'medical symbolsymbolsmedical_symbol' },
      {
        emoji: '♻️',
        category: 'Symbols',
        searchQuery: 'recycling symbolsymbolsrecycleenvironmentgreen'
      },
      { emoji: '⚜️', category: 'Symbols', searchQuery: 'fleur-de-lissymbolsfleur_de_lis' },
      { emoji: '🔱', category: 'Symbols', searchQuery: 'trident emblemsymbolstrident' },
      { emoji: '📛', category: 'Symbols', searchQuery: 'name badgesymbolsname_badge' },
      {
        emoji: '🔰',
        category: 'Symbols',
        searchQuery: 'japanese symbol for beginnersymbolsbeginner'
      },
      { emoji: '⭕', category: 'Symbols', searchQuery: 'hollow red circlesymbolso' },
      { emoji: '✅', category: 'Symbols', searchQuery: 'check mark buttonsymbolswhite_check_mark' },
      {
        emoji: '☑️',
        category: 'Symbols',
        searchQuery: 'check box with checksymbolsballot_box_with_check'
      },
      { emoji: '✔️', category: 'Symbols', searchQuery: 'check marksymbolsheavy_check_mark' },
      { emoji: '❌', category: 'Symbols', searchQuery: 'cross marksymbolsx' },
      {
        emoji: '❎',
        category: 'Symbols',
        searchQuery: 'cross mark buttonsymbolsnegative_squared_cross_mark'
      },
      { emoji: '➰', category: 'Symbols', searchQuery: 'curly loopsymbolscurly_loop' },
      { emoji: '➿', category: 'Symbols', searchQuery: 'double curly loopsymbolsloop' },
      {
        emoji: '〽️',
        category: 'Symbols',
        searchQuery: 'part alternation marksymbolspart_alternation_mark'
      },
      {
        emoji: '✳️',
        category: 'Symbols',
        searchQuery: 'eight-spoked asterisksymbolseight_spoked_asterisk'
      },
      {
        emoji: '✴️',
        category: 'Symbols',
        searchQuery: 'eight-pointed starsymbolseight_pointed_black_star'
      },
      { emoji: '❇️', category: 'Symbols', searchQuery: 'sparklesymbolssparkle' },
      { emoji: '©️', category: 'Symbols', searchQuery: 'copyrightsymbolscopyright' },
      { emoji: '®️', category: 'Symbols', searchQuery: 'registeredsymbolsregistered' },
      { emoji: '™️', category: 'Symbols', searchQuery: 'trade marksymbolstmtrademark' },
      { emoji: '#️⃣', category: 'Symbols', searchQuery: 'keycap: #symbolshashnumber' },
      { emoji: '*️⃣', category: 'Symbols', searchQuery: 'keycap: *symbolsasterisk' },
      { emoji: '0️⃣', category: 'Symbols', searchQuery: 'keycap: 0symbolszero' },
      { emoji: '1️⃣', category: 'Symbols', searchQuery: 'keycap: 1symbolsone' },
      { emoji: '2️⃣', category: 'Symbols', searchQuery: 'keycap: 2symbolstwo' },
      { emoji: '3️⃣', category: 'Symbols', searchQuery: 'keycap: 3symbolsthree' },
      { emoji: '4️⃣', category: 'Symbols', searchQuery: 'keycap: 4symbolsfour' },
      { emoji: '5️⃣', category: 'Symbols', searchQuery: 'keycap: 5symbolsfive' },
      { emoji: '6️⃣', category: 'Symbols', searchQuery: 'keycap: 6symbolssix' },
      { emoji: '7️⃣', category: 'Symbols', searchQuery: 'keycap: 7symbolsseven' },
      { emoji: '8️⃣', category: 'Symbols', searchQuery: 'keycap: 8symbolseight' },
      { emoji: '9️⃣', category: 'Symbols', searchQuery: 'keycap: 9symbolsnine' },
      { emoji: '🔟', category: 'Symbols', searchQuery: 'keycap: 10symbolskeycap_ten' },
      {
        emoji: '🔠',
        category: 'Symbols',
        searchQuery: 'input latin uppercasesymbolscapital_abcdletters'
      },
      { emoji: '🔡', category: 'Symbols', searchQuery: 'input latin lowercasesymbolsabcd' },
      { emoji: '🔢', category: 'Symbols', searchQuery: 'input numberssymbols1234numbers' },
      { emoji: '🔣', category: 'Symbols', searchQuery: 'input symbolssymbolssymbols' },
      { emoji: '🔤', category: 'Symbols', searchQuery: 'input latin letterssymbolsabcalphabet' },
      { emoji: '🅰️', category: 'Symbols', searchQuery: 'a button (blood type)symbolsa' },
      { emoji: '🆎', category: 'Symbols', searchQuery: 'ab button (blood type)symbolsab' },
      { emoji: '🅱️', category: 'Symbols', searchQuery: 'b button (blood type)symbolsb' },
      { emoji: '🆑', category: 'Symbols', searchQuery: 'cl buttonsymbolscl' },
      { emoji: '🆒', category: 'Symbols', searchQuery: 'cool buttonsymbolscool' },
      { emoji: '🆓', category: 'Symbols', searchQuery: 'free buttonsymbolsfree' },
      { emoji: 'ℹ️', category: 'Symbols', searchQuery: 'informationsymbolsinformation_source' },
      { emoji: '🆔', category: 'Symbols', searchQuery: 'id buttonsymbolsid' },
      { emoji: 'Ⓜ️', category: 'Symbols', searchQuery: 'circled msymbolsm' },
      { emoji: '🆕', category: 'Symbols', searchQuery: 'new buttonsymbolsnewfresh' },
      { emoji: '🆖', category: 'Symbols', searchQuery: 'ng buttonsymbolsng' },
      { emoji: '🅾️', category: 'Symbols', searchQuery: 'o button (blood type)symbolso2' },
      { emoji: '🆗', category: 'Symbols', searchQuery: 'ok buttonsymbolsokyes' },
      { emoji: '🅿️', category: 'Symbols', searchQuery: 'p buttonsymbolsparking' },
      { emoji: '🆘', category: 'Symbols', searchQuery: 'sos buttonsymbolssoshelpemergency' },
      { emoji: '🆙', category: 'Symbols', searchQuery: 'up! buttonsymbolsup' },
      { emoji: '🆚', category: 'Symbols', searchQuery: 'vs buttonsymbolsvs' },
      { emoji: '🈁', category: 'Symbols', searchQuery: 'japanese “here” buttonsymbolskoko' },
      {
        emoji: '🈂️',
        category: 'Symbols',
        searchQuery: 'japanese “service charge” buttonsymbolssa'
      },
      {
        emoji: '🈷️',
        category: 'Symbols',
        searchQuery: 'japanese “monthly amount” buttonsymbolsu6708'
      },
      {
        emoji: '🈶',
        category: 'Symbols',
        searchQuery: 'japanese “not free of charge” buttonsymbolsu6709'
      },
      { emoji: '🈯', category: 'Symbols', searchQuery: 'japanese “reserved” buttonsymbolsu6307' },
      {
        emoji: '🉐',
        category: 'Symbols',
        searchQuery: 'japanese “bargain” buttonsymbolsideograph_advantage'
      },
      { emoji: '🈹', category: 'Symbols', searchQuery: 'japanese “discount” buttonsymbolsu5272' },
      {
        emoji: '🈚',
        category: 'Symbols',
        searchQuery: 'japanese “free of charge” buttonsymbolsu7121'
      },
      { emoji: '🈲', category: 'Symbols', searchQuery: 'japanese “prohibited” buttonsymbolsu7981' },
      {
        emoji: '🉑',
        category: 'Symbols',
        searchQuery: 'japanese “acceptable” buttonsymbolsaccept'
      },
      {
        emoji: '🈸',
        category: 'Symbols',
        searchQuery: 'japanese “application” buttonsymbolsu7533'
      },
      {
        emoji: '🈴',
        category: 'Symbols',
        searchQuery: 'japanese “passing grade” buttonsymbolsu5408'
      },
      { emoji: '🈳', category: 'Symbols', searchQuery: 'japanese “vacancy” buttonsymbolsu7a7a' },
      {
        emoji: '㊗️',
        category: 'Symbols',
        searchQuery: 'japanese “congratulations” buttonsymbolscongratulations'
      },
      { emoji: '㊙️', category: 'Symbols', searchQuery: 'japanese “secret” buttonsymbolssecret' },
      {
        emoji: '🈺',
        category: 'Symbols',
        searchQuery: 'japanese “open for business” buttonsymbolsu55b6'
      },
      { emoji: '🈵', category: 'Symbols', searchQuery: 'japanese “no vacancy” buttonsymbolsu6e80' },
      { emoji: '🔴', category: 'Symbols', searchQuery: 'red circlesymbolsred_circle' },
      { emoji: '🟠', category: 'Symbols', searchQuery: 'orange circlesymbolsorange_circle' },
      { emoji: '🟡', category: 'Symbols', searchQuery: 'yellow circlesymbolsyellow_circle' },
      { emoji: '🟢', category: 'Symbols', searchQuery: 'green circlesymbolsgreen_circle' },
      { emoji: '🔵', category: 'Symbols', searchQuery: 'blue circlesymbolslarge_blue_circle' },
      { emoji: '🟣', category: 'Symbols', searchQuery: 'purple circlesymbolspurple_circle' },
      { emoji: '🟤', category: 'Symbols', searchQuery: 'brown circlesymbolsbrown_circle' },
      { emoji: '⚫', category: 'Symbols', searchQuery: 'black circlesymbolsblack_circle' },
      { emoji: '⚪', category: 'Symbols', searchQuery: 'white circlesymbolswhite_circle' },
      { emoji: '🟥', category: 'Symbols', searchQuery: 'red squaresymbolsred_square' },
      { emoji: '🟧', category: 'Symbols', searchQuery: 'orange squaresymbolsorange_square' },
      { emoji: '🟨', category: 'Symbols', searchQuery: 'yellow squaresymbolsyellow_square' },
      { emoji: '🟩', category: 'Symbols', searchQuery: 'green squaresymbolsgreen_square' },
      { emoji: '🟦', category: 'Symbols', searchQuery: 'blue squaresymbolsblue_square' },
      { emoji: '🟪', category: 'Symbols', searchQuery: 'purple squaresymbolspurple_square' },
      { emoji: '🟫', category: 'Symbols', searchQuery: 'brown squaresymbolsbrown_square' },
      {
        emoji: '⬛',
        category: 'Symbols',
        searchQuery: 'black large squaresymbolsblack_large_square'
      },
      {
        emoji: '⬜',
        category: 'Symbols',
        searchQuery: 'white large squaresymbolswhite_large_square'
      },
      {
        emoji: '◼️',
        category: 'Symbols',
        searchQuery: 'black medium squaresymbolsblack_medium_square'
      },
      {
        emoji: '◻️',
        category: 'Symbols',
        searchQuery: 'white medium squaresymbolswhite_medium_square'
      },
      {
        emoji: '◾',
        category: 'Symbols',
        searchQuery: 'black medium-small squaresymbolsblack_medium_small_square'
      },
      {
        emoji: '◽',
        category: 'Symbols',
        searchQuery: 'white medium-small squaresymbolswhite_medium_small_square'
      },
      {
        emoji: '▪️',
        category: 'Symbols',
        searchQuery: 'black small squaresymbolsblack_small_square'
      },
      {
        emoji: '▫️',
        category: 'Symbols',
        searchQuery: 'white small squaresymbolswhite_small_square'
      },
      {
        emoji: '🔶',
        category: 'Symbols',
        searchQuery: 'large orange diamondsymbolslarge_orange_diamond'
      },
      {
        emoji: '🔷',
        category: 'Symbols',
        searchQuery: 'large blue diamondsymbolslarge_blue_diamond'
      },
      {
        emoji: '🔸',
        category: 'Symbols',
        searchQuery: 'small orange diamondsymbolssmall_orange_diamond'
      },
      {
        emoji: '🔹',
        category: 'Symbols',
        searchQuery: 'small blue diamondsymbolssmall_blue_diamond'
      },
      {
        emoji: '🔺',
        category: 'Symbols',
        searchQuery: 'red triangle pointed upsymbolssmall_red_triangle'
      },
      {
        emoji: '🔻',
        category: 'Symbols',
        searchQuery: 'red triangle pointed downsymbolssmall_red_triangle_down'
      },
      {
        emoji: '💠',
        category: 'Symbols',
        searchQuery: 'diamond with a dotsymbolsdiamond_shape_with_a_dot_inside'
      },
      { emoji: '🔘', category: 'Symbols', searchQuery: 'radio buttonsymbolsradio_button' },
      {
        emoji: '🔳',
        category: 'Symbols',
        searchQuery: 'white square buttonsymbolswhite_square_button'
      },
      {
        emoji: '🔲',
        category: 'Symbols',
        searchQuery: 'black square buttonsymbolsblack_square_button'
      }
    ]
  },
  {
    category: 'Flags',
    label: '🚩',
    icon: `${ICON_PREFIX}flag`,
    data: [
      {
        emoji: '🏁',
        category: 'Flags',
        searchQuery: 'chequered flagflagscheckered_flagmilestonefinish'
      },
      {
        emoji: '🚩',
        category: 'Flags',
        searchQuery: 'triangular flagflagstriangular_flag_on_post'
      },
      { emoji: '🎌', category: 'Flags', searchQuery: 'crossed flagsflagscrossed_flags' },
      { emoji: '🏴', category: 'Flags', searchQuery: 'black flagflagsblack_flag' },
      { emoji: '🏳️', category: 'Flags', searchQuery: 'white flagflagswhite_flag' },
      { emoji: '🏳️‍🌈', category: 'Flags', searchQuery: 'rainbow flagflagsrainbow_flagpride' },
      { emoji: '🏳️‍⚧️', category: 'Flags', searchQuery: 'transgender flagflagstransgender_flag' },
      { emoji: '🏴‍☠️', category: 'Flags', searchQuery: 'pirate flagflagspirate_flag' },
      {
        emoji: '🇦🇨',
        category: 'Flags',
        searchQuery: 'flag: ascension islandflagsascension_island'
      },
      { emoji: '🇦🇩', category: 'Flags', searchQuery: 'flag: andorraflagsandorra' },
      {
        emoji: '🇦🇪',
        category: 'Flags',
        searchQuery: 'flag: united arab emiratesflagsunited_arab_emirates'
      },
      { emoji: '🇦🇫', category: 'Flags', searchQuery: 'flag: afghanistanflagsafghanistan' },
      {
        emoji: '🇦🇬',
        category: 'Flags',
        searchQuery: 'flag: antigua & barbudaflagsantigua_barbuda'
      },
      { emoji: '🇦🇮', category: 'Flags', searchQuery: 'flag: anguillaflagsanguilla' },
      { emoji: '🇦🇱', category: 'Flags', searchQuery: 'flag: albaniaflagsalbania' },
      { emoji: '🇦🇲', category: 'Flags', searchQuery: 'flag: armeniaflagsarmenia' },
      { emoji: '🇦🇴', category: 'Flags', searchQuery: 'flag: angolaflagsangola' },
      { emoji: '🇦🇶', category: 'Flags', searchQuery: 'flag: antarcticaflagsantarctica' },
      { emoji: '🇦🇷', category: 'Flags', searchQuery: 'flag: argentinaflagsargentina' },
      { emoji: '🇦🇸', category: 'Flags', searchQuery: 'flag: american samoaflagsamerican_samoa' },
      { emoji: '🇦🇹', category: 'Flags', searchQuery: 'flag: austriaflagsaustria' },
      { emoji: '🇦🇺', category: 'Flags', searchQuery: 'flag: australiaflagsaustralia' },
      { emoji: '🇦🇼', category: 'Flags', searchQuery: 'flag: arubaflagsaruba' },
      { emoji: '🇦🇽', category: 'Flags', searchQuery: 'flag: åland islandsflagsaland_islands' },
      { emoji: '🇦🇿', category: 'Flags', searchQuery: 'flag: azerbaijanflagsazerbaijan' },
      {
        emoji: '🇧🇦',
        category: 'Flags',
        searchQuery: 'flag: bosnia & herzegovinaflagsbosnia_herzegovina'
      },
      { emoji: '🇧🇧', category: 'Flags', searchQuery: 'flag: barbadosflagsbarbados' },
      { emoji: '🇧🇩', category: 'Flags', searchQuery: 'flag: bangladeshflagsbangladesh' },
      { emoji: '🇧🇪', category: 'Flags', searchQuery: 'flag: belgiumflagsbelgium' },
      { emoji: '🇧🇫', category: 'Flags', searchQuery: 'flag: burkina fasoflagsburkina_faso' },
      { emoji: '🇧🇬', category: 'Flags', searchQuery: 'flag: bulgariaflagsbulgaria' },
      { emoji: '🇧🇭', category: 'Flags', searchQuery: 'flag: bahrainflagsbahrain' },
      { emoji: '🇧🇮', category: 'Flags', searchQuery: 'flag: burundiflagsburundi' },
      { emoji: '🇧🇯', category: 'Flags', searchQuery: 'flag: beninflagsbenin' },
      { emoji: '🇧🇱', category: 'Flags', searchQuery: 'flag: st. barthélemyflagsst_barthelemy' },
      { emoji: '🇧🇲', category: 'Flags', searchQuery: 'flag: bermudaflagsbermuda' },
      { emoji: '🇧🇳', category: 'Flags', searchQuery: 'flag: bruneiflagsbrunei' },
      { emoji: '🇧🇴', category: 'Flags', searchQuery: 'flag: boliviaflagsbolivia' },
      {
        emoji: '🇧🇶',
        category: 'Flags',
        searchQuery: 'flag: caribbean netherlandsflagscaribbean_netherlands'
      },
      { emoji: '🇧🇷', category: 'Flags', searchQuery: 'flag: brazilflagsbrazil' },
      { emoji: '🇧🇸', category: 'Flags', searchQuery: 'flag: bahamasflagsbahamas' },
      { emoji: '🇧🇹', category: 'Flags', searchQuery: 'flag: bhutanflagsbhutan' },
      { emoji: '🇧🇻', category: 'Flags', searchQuery: 'flag: bouvet islandflagsbouvet_island' },
      { emoji: '🇧🇼', category: 'Flags', searchQuery: 'flag: botswanaflagsbotswana' },
      { emoji: '🇧🇾', category: 'Flags', searchQuery: 'flag: belarusflagsbelarus' },
      { emoji: '🇧🇿', category: 'Flags', searchQuery: 'flag: belizeflagsbelize' },
      { emoji: '🇨🇦', category: 'Flags', searchQuery: 'flag: canadaflagscanada' },
      {
        emoji: '🇨🇨',
        category: 'Flags',
        searchQuery: 'flag: cocos (keeling) islandsflagscocos_islandskeeling'
      },
      { emoji: '🇨🇩', category: 'Flags', searchQuery: 'flag: congo - kinshasaflagscongo_kinshasa' },
      {
        emoji: '🇨🇫',
        category: 'Flags',
        searchQuery: 'flag: central african republicflagscentral_african_republic'
      },
      {
        emoji: '🇨🇬',
        category: 'Flags',
        searchQuery: 'flag: congo - brazzavilleflagscongo_brazzaville'
      },
      { emoji: '🇨🇭', category: 'Flags', searchQuery: 'flag: switzerlandflagsswitzerland' },
      { emoji: '🇨🇮', category: 'Flags', searchQuery: 'flag: côte d’ivoireflagscote_divoireivory' },
      { emoji: '🇨🇰', category: 'Flags', searchQuery: 'flag: cook islandsflagscook_islands' },
      { emoji: '🇨🇱', category: 'Flags', searchQuery: 'flag: chileflagschile' },
      { emoji: '🇨🇲', category: 'Flags', searchQuery: 'flag: cameroonflagscameroon' },
      { emoji: '🇨🇳', category: 'Flags', searchQuery: 'flag: chinaflagscnchina' },
      { emoji: '🇨🇴', category: 'Flags', searchQuery: 'flag: colombiaflagscolombia' },
      {
        emoji: '🇨🇵',
        category: 'Flags',
        searchQuery: 'flag: clipperton islandflagsclipperton_island'
      },
      { emoji: '🇨🇷', category: 'Flags', searchQuery: 'flag: costa ricaflagscosta_rica' },
      { emoji: '🇨🇺', category: 'Flags', searchQuery: 'flag: cubaflagscuba' },
      { emoji: '🇨🇻', category: 'Flags', searchQuery: 'flag: cape verdeflagscape_verde' },
      { emoji: '🇨🇼', category: 'Flags', searchQuery: 'flag: curaçaoflagscuracao' },
      {
        emoji: '🇨🇽',
        category: 'Flags',
        searchQuery: 'flag: christmas islandflagschristmas_island'
      },
      { emoji: '🇨🇾', category: 'Flags', searchQuery: 'flag: cyprusflagscyprus' },
      { emoji: '🇨🇿', category: 'Flags', searchQuery: 'flag: czechiaflagsczech_republic' },
      { emoji: '🇩🇪', category: 'Flags', searchQuery: 'flag: germanyflagsdeflaggermany' },
      { emoji: '🇩🇬', category: 'Flags', searchQuery: 'flag: diego garciaflagsdiego_garcia' },
      { emoji: '🇩🇯', category: 'Flags', searchQuery: 'flag: djiboutiflagsdjibouti' },
      { emoji: '🇩🇰', category: 'Flags', searchQuery: 'flag: denmarkflagsdenmark' },
      { emoji: '🇩🇲', category: 'Flags', searchQuery: 'flag: dominicaflagsdominica' },
      {
        emoji: '🇩🇴',
        category: 'Flags',
        searchQuery: 'flag: dominican republicflagsdominican_republic'
      },
      { emoji: '🇩🇿', category: 'Flags', searchQuery: 'flag: algeriaflagsalgeria' },
      { emoji: '🇪🇦', category: 'Flags', searchQuery: 'flag: ceuta & melillaflagsceuta_melilla' },
      { emoji: '🇪🇨', category: 'Flags', searchQuery: 'flag: ecuadorflagsecuador' },
      { emoji: '🇪🇪', category: 'Flags', searchQuery: 'flag: estoniaflagsestonia' },
      { emoji: '🇪🇬', category: 'Flags', searchQuery: 'flag: egyptflagsegypt' },
      { emoji: '🇪🇭', category: 'Flags', searchQuery: 'flag: western saharaflagswestern_sahara' },
      { emoji: '🇪🇷', category: 'Flags', searchQuery: 'flag: eritreaflagseritrea' },
      { emoji: '🇪🇸', category: 'Flags', searchQuery: 'flag: spainflagsesspain' },
      { emoji: '🇪🇹', category: 'Flags', searchQuery: 'flag: ethiopiaflagsethiopia' },
      { emoji: '🇪🇺', category: 'Flags', searchQuery: 'flag: european unionflagseueuropean_union' },
      { emoji: '🇫🇮', category: 'Flags', searchQuery: 'flag: finlandflagsfinland' },
      { emoji: '🇫🇯', category: 'Flags', searchQuery: 'flag: fijiflagsfiji' },
      {
        emoji: '🇫🇰',
        category: 'Flags',
        searchQuery: 'flag: falkland islandsflagsfalkland_islands'
      },
      { emoji: '🇫🇲', category: 'Flags', searchQuery: 'flag: micronesiaflagsmicronesia' },
      { emoji: '🇫🇴', category: 'Flags', searchQuery: 'flag: faroe islandsflagsfaroe_islands' },
      { emoji: '🇫🇷', category: 'Flags', searchQuery: 'flag: franceflagsfrfrancefrench' },
      { emoji: '🇬🇦', category: 'Flags', searchQuery: 'flag: gabonflagsgabon' },
      { emoji: '🇬🇧', category: 'Flags', searchQuery: 'flag: united kingdomflagsgbukflagbritish' },
      { emoji: '🇬🇩', category: 'Flags', searchQuery: 'flag: grenadaflagsgrenada' },
      { emoji: '🇬🇪', category: 'Flags', searchQuery: 'flag: georgiaflagsgeorgia' },
      { emoji: '🇬🇫', category: 'Flags', searchQuery: 'flag: french guianaflagsfrench_guiana' },
      { emoji: '🇬🇬', category: 'Flags', searchQuery: 'flag: guernseyflagsguernsey' },
      { emoji: '🇬🇭', category: 'Flags', searchQuery: 'flag: ghanaflagsghana' },
      { emoji: '🇬🇮', category: 'Flags', searchQuery: 'flag: gibraltarflagsgibraltar' },
      { emoji: '🇬🇱', category: 'Flags', searchQuery: 'flag: greenlandflagsgreenland' },
      { emoji: '🇬🇲', category: 'Flags', searchQuery: 'flag: gambiaflagsgambia' },
      { emoji: '🇬🇳', category: 'Flags', searchQuery: 'flag: guineaflagsguinea' },
      { emoji: '🇬🇵', category: 'Flags', searchQuery: 'flag: guadeloupeflagsguadeloupe' },
      {
        emoji: '🇬🇶',
        category: 'Flags',
        searchQuery: 'flag: equatorial guineaflagsequatorial_guinea'
      },
      { emoji: '🇬🇷', category: 'Flags', searchQuery: 'flag: greeceflagsgreece' },
      {
        emoji: '🇬🇸',
        category: 'Flags',
        searchQuery:
          'flag: south georgia & south sandwich islandsflagssouth_georgia_south_sandwich_islands'
      },
      { emoji: '🇬🇹', category: 'Flags', searchQuery: 'flag: guatemalaflagsguatemala' },
      { emoji: '🇬🇺', category: 'Flags', searchQuery: 'flag: guamflagsguam' },
      { emoji: '🇬🇼', category: 'Flags', searchQuery: 'flag: guinea-bissauflagsguinea_bissau' },
      { emoji: '🇬🇾', category: 'Flags', searchQuery: 'flag: guyanaflagsguyana' },
      { emoji: '🇭🇰', category: 'Flags', searchQuery: 'flag: hong kong sar chinaflagshong_kong' },
      {
        emoji: '🇭🇲',
        category: 'Flags',
        searchQuery: 'flag: heard & mcdonald islandsflagsheard_mcdonald_islands'
      },
      { emoji: '🇭🇳', category: 'Flags', searchQuery: 'flag: hondurasflagshonduras' },
      { emoji: '🇭🇷', category: 'Flags', searchQuery: 'flag: croatiaflagscroatia' },
      { emoji: '🇭🇹', category: 'Flags', searchQuery: 'flag: haitiflagshaiti' },
      { emoji: '🇭🇺', category: 'Flags', searchQuery: 'flag: hungaryflagshungary' },
      { emoji: '🇮🇨', category: 'Flags', searchQuery: 'flag: canary islandsflagscanary_islands' },
      { emoji: '🇮🇩', category: 'Flags', searchQuery: 'flag: indonesiaflagsindonesia' },
      { emoji: '🇮🇪', category: 'Flags', searchQuery: 'flag: irelandflagsireland' },
      { emoji: '🇮🇱', category: 'Flags', searchQuery: 'flag: israelflagsisrael' },
      { emoji: '🇮🇲', category: 'Flags', searchQuery: 'flag: isle of manflagsisle_of_man' },
      { emoji: '🇮🇳', category: 'Flags', searchQuery: 'flag: indiaflagsindia' },
      {
        emoji: '🇮🇴',
        category: 'Flags',
        searchQuery: 'flag: british indian ocean territoryflagsbritish_indian_ocean_territory'
      },
      { emoji: '🇮🇶', category: 'Flags', searchQuery: 'flag: iraqflagsiraq' },
      { emoji: '🇮🇷', category: 'Flags', searchQuery: 'flag: iranflagsiran' },
      { emoji: '🇮🇸', category: 'Flags', searchQuery: 'flag: icelandflagsiceland' },
      { emoji: '🇮🇹', category: 'Flags', searchQuery: 'flag: italyflagsititaly' },
      { emoji: '🇯🇪', category: 'Flags', searchQuery: 'flag: jerseyflagsjersey' },
      { emoji: '🇯🇲', category: 'Flags', searchQuery: 'flag: jamaicaflagsjamaica' },
      { emoji: '🇯🇴', category: 'Flags', searchQuery: 'flag: jordanflagsjordan' },
      { emoji: '🇯🇵', category: 'Flags', searchQuery: 'flag: japanflagsjpjapan' },
      { emoji: '🇰🇪', category: 'Flags', searchQuery: 'flag: kenyaflagskenya' },
      { emoji: '🇰🇬', category: 'Flags', searchQuery: 'flag: kyrgyzstanflagskyrgyzstan' },
      { emoji: '🇰🇭', category: 'Flags', searchQuery: 'flag: cambodiaflagscambodia' },
      { emoji: '🇰🇮', category: 'Flags', searchQuery: 'flag: kiribatiflagskiribati' },
      { emoji: '🇰🇲', category: 'Flags', searchQuery: 'flag: comorosflagscomoros' },
      { emoji: '🇰🇳', category: 'Flags', searchQuery: 'flag: st. kitts & nevisflagsst_kitts_nevis' },
      { emoji: '🇰🇵', category: 'Flags', searchQuery: 'flag: north koreaflagsnorth_korea' },
      { emoji: '🇰🇷', category: 'Flags', searchQuery: 'flag: south koreaflagskrkorea' },
      { emoji: '🇰🇼', category: 'Flags', searchQuery: 'flag: kuwaitflagskuwait' },
      { emoji: '🇰🇾', category: 'Flags', searchQuery: 'flag: cayman islandsflagscayman_islands' },
      { emoji: '🇰🇿', category: 'Flags', searchQuery: 'flag: kazakhstanflagskazakhstan' },
      { emoji: '🇱🇦', category: 'Flags', searchQuery: 'flag: laosflagslaos' },
      { emoji: '🇱🇧', category: 'Flags', searchQuery: 'flag: lebanonflagslebanon' },
      { emoji: '🇱🇨', category: 'Flags', searchQuery: 'flag: st. luciaflagsst_lucia' },
      { emoji: '🇱🇮', category: 'Flags', searchQuery: 'flag: liechtensteinflagsliechtenstein' },
      { emoji: '🇱🇰', category: 'Flags', searchQuery: 'flag: sri lankaflagssri_lanka' },
      { emoji: '🇱🇷', category: 'Flags', searchQuery: 'flag: liberiaflagsliberia' },
      { emoji: '🇱🇸', category: 'Flags', searchQuery: 'flag: lesothoflagslesotho' },
      { emoji: '🇱🇹', category: 'Flags', searchQuery: 'flag: lithuaniaflagslithuania' },
      { emoji: '🇱🇺', category: 'Flags', searchQuery: 'flag: luxembourgflagsluxembourg' },
      { emoji: '🇱🇻', category: 'Flags', searchQuery: 'flag: latviaflagslatvia' },
      { emoji: '🇱🇾', category: 'Flags', searchQuery: 'flag: libyaflagslibya' },
      { emoji: '🇲🇦', category: 'Flags', searchQuery: 'flag: moroccoflagsmorocco' },
      { emoji: '🇲🇨', category: 'Flags', searchQuery: 'flag: monacoflagsmonaco' },
      { emoji: '🇲🇩', category: 'Flags', searchQuery: 'flag: moldovaflagsmoldova' },
      { emoji: '🇲🇪', category: 'Flags', searchQuery: 'flag: montenegroflagsmontenegro' },
      { emoji: '🇲🇫', category: 'Flags', searchQuery: 'flag: st. martinflagsst_martin' },
      { emoji: '🇲🇬', category: 'Flags', searchQuery: 'flag: madagascarflagsmadagascar' },
      {
        emoji: '🇲🇭',
        category: 'Flags',
        searchQuery: 'flag: marshall islandsflagsmarshall_islands'
      },
      { emoji: '🇲🇰', category: 'Flags', searchQuery: 'flag: north macedoniaflagsmacedonia' },
      { emoji: '🇲🇱', category: 'Flags', searchQuery: 'flag: maliflagsmali' },
      { emoji: '🇲🇲', category: 'Flags', searchQuery: 'flag: myanmar (burma)flagsmyanmarburma' },
      { emoji: '🇲🇳', category: 'Flags', searchQuery: 'flag: mongoliaflagsmongolia' },
      { emoji: '🇲🇴', category: 'Flags', searchQuery: 'flag: macao sar chinaflagsmacau' },
      {
        emoji: '🇲🇵',
        category: 'Flags',
        searchQuery: 'flag: northern mariana islandsflagsnorthern_mariana_islands'
      },
      { emoji: '🇲🇶', category: 'Flags', searchQuery: 'flag: martiniqueflagsmartinique' },
      { emoji: '🇲🇷', category: 'Flags', searchQuery: 'flag: mauritaniaflagsmauritania' },
      { emoji: '🇲🇸', category: 'Flags', searchQuery: 'flag: montserratflagsmontserrat' },
      { emoji: '🇲🇹', category: 'Flags', searchQuery: 'flag: maltaflagsmalta' },
      { emoji: '🇲🇺', category: 'Flags', searchQuery: 'flag: mauritiusflagsmauritius' },
      { emoji: '🇲🇻', category: 'Flags', searchQuery: 'flag: maldivesflagsmaldives' },
      { emoji: '🇲🇼', category: 'Flags', searchQuery: 'flag: malawiflagsmalawi' },
      { emoji: '🇲🇽', category: 'Flags', searchQuery: 'flag: mexicoflagsmexico' },
      { emoji: '🇲🇾', category: 'Flags', searchQuery: 'flag: malaysiaflagsmalaysia' },
      { emoji: '🇲🇿', category: 'Flags', searchQuery: 'flag: mozambiqueflagsmozambique' },
      { emoji: '🇳🇦', category: 'Flags', searchQuery: 'flag: namibiaflagsnamibia' },
      { emoji: '🇳🇨', category: 'Flags', searchQuery: 'flag: new caledoniaflagsnew_caledonia' },
      { emoji: '🇳🇪', category: 'Flags', searchQuery: 'flag: nigerflagsniger' },
      { emoji: '🇳🇫', category: 'Flags', searchQuery: 'flag: norfolk islandflagsnorfolk_island' },
      { emoji: '🇳🇬', category: 'Flags', searchQuery: 'flag: nigeriaflagsnigeria' },
      { emoji: '🇳🇮', category: 'Flags', searchQuery: 'flag: nicaraguaflagsnicaragua' },
      { emoji: '🇳🇱', category: 'Flags', searchQuery: 'flag: netherlandsflagsnetherlands' },
      { emoji: '🇳🇴', category: 'Flags', searchQuery: 'flag: norwayflagsnorway' },
      { emoji: '🇳🇵', category: 'Flags', searchQuery: 'flag: nepalflagsnepal' },
      { emoji: '🇳🇷', category: 'Flags', searchQuery: 'flag: nauruflagsnauru' },
      { emoji: '🇳🇺', category: 'Flags', searchQuery: 'flag: niueflagsniue' },
      { emoji: '🇳🇿', category: 'Flags', searchQuery: 'flag: new zealandflagsnew_zealand' },
      { emoji: '🇴🇲', category: 'Flags', searchQuery: 'flag: omanflagsoman' },
      { emoji: '🇵🇦', category: 'Flags', searchQuery: 'flag: panamaflagspanama' },
      { emoji: '🇵🇪', category: 'Flags', searchQuery: 'flag: peruflagsperu' },
      {
        emoji: '🇵🇫',
        category: 'Flags',
        searchQuery: 'flag: french polynesiaflagsfrench_polynesia'
      },
      {
        emoji: '🇵🇬',
        category: 'Flags',
        searchQuery: 'flag: papua new guineaflagspapua_new_guinea'
      },
      { emoji: '🇵🇭', category: 'Flags', searchQuery: 'flag: philippinesflagsphilippines' },
      { emoji: '🇵🇰', category: 'Flags', searchQuery: 'flag: pakistanflagspakistan' },
      { emoji: '🇵🇱', category: 'Flags', searchQuery: 'flag: polandflagspoland' },
      {
        emoji: '🇵🇲',
        category: 'Flags',
        searchQuery: 'flag: st. pierre & miquelonflagsst_pierre_miquelon'
      },
      {
        emoji: '🇵🇳',
        category: 'Flags',
        searchQuery: 'flag: pitcairn islandsflagspitcairn_islands'
      },
      { emoji: '🇵🇷', category: 'Flags', searchQuery: 'flag: puerto ricoflagspuerto_rico' },
      {
        emoji: '🇵🇸',
        category: 'Flags',
        searchQuery: 'flag: palestinian territoriesflagspalestinian_territories'
      },
      { emoji: '🇵🇹', category: 'Flags', searchQuery: 'flag: portugalflagsportugal' },
      { emoji: '🇵🇼', category: 'Flags', searchQuery: 'flag: palauflagspalau' },
      { emoji: '🇵🇾', category: 'Flags', searchQuery: 'flag: paraguayflagsparaguay' },
      { emoji: '🇶🇦', category: 'Flags', searchQuery: 'flag: qatarflagsqatar' },
      { emoji: '🇷🇪', category: 'Flags', searchQuery: 'flag: réunionflagsreunion' },
      { emoji: '🇷🇴', category: 'Flags', searchQuery: 'flag: romaniaflagsromania' },
      { emoji: '🇷🇸', category: 'Flags', searchQuery: 'flag: serbiaflagsserbia' },
      { emoji: '🇷🇼', category: 'Flags', searchQuery: 'flag: ukraineflagsukraine' },
      { emoji: '🇸🇦', category: 'Flags', searchQuery: 'flag: saudi arabiaflagssaudi_arabia' },
      { emoji: '🇸🇧', category: 'Flags', searchQuery: 'flag: solomon islandsflagssolomon_islands' },
      { emoji: '🇸🇨', category: 'Flags', searchQuery: 'flag: seychellesflagsseychelles' },
      { emoji: '🇸🇩', category: 'Flags', searchQuery: 'flag: sudanflagssudan' },
      { emoji: '🇸🇪', category: 'Flags', searchQuery: 'flag: swedenflagssweden' },
      { emoji: '🇸🇬', category: 'Flags', searchQuery: 'flag: singaporeflagssingapore' },
      { emoji: '🇸🇭', category: 'Flags', searchQuery: 'flag: st. helenaflagsst_helena' },
      { emoji: '🇸🇮', category: 'Flags', searchQuery: 'flag: sloveniaflagsslovenia' },
      {
        emoji: '🇸🇯',
        category: 'Flags',
        searchQuery: 'flag: svalbard & jan mayenflagssvalbard_jan_mayen'
      },
      { emoji: '🇸🇰', category: 'Flags', searchQuery: 'flag: slovakiaflagsslovakia' },
      { emoji: '🇸🇱', category: 'Flags', searchQuery: 'flag: sierra leoneflagssierra_leone' },
      { emoji: '🇸🇲', category: 'Flags', searchQuery: 'flag: san marinoflagssan_marino' },
      { emoji: '🇸🇳', category: 'Flags', searchQuery: 'flag: senegalflagssenegal' },
      { emoji: '🇸🇴', category: 'Flags', searchQuery: 'flag: somaliaflagssomalia' },
      { emoji: '🇸🇷', category: 'Flags', searchQuery: 'flag: surinameflagssuriname' },
      { emoji: '🇸🇸', category: 'Flags', searchQuery: 'flag: south sudanflagssouth_sudan' },
      {
        emoji: '🇸🇹',
        category: 'Flags',
        searchQuery: 'flag: são tomé & príncipeflagssao_tome_principe'
      },
      { emoji: '🇸🇻', category: 'Flags', searchQuery: 'flag: el salvadorflagsel_salvador' },
      { emoji: '🇸🇽', category: 'Flags', searchQuery: 'flag: sint maartenflagssint_maarten' },
      { emoji: '🇸🇾', category: 'Flags', searchQuery: 'flag: syriaflagssyria' },
      { emoji: '🇸🇿', category: 'Flags', searchQuery: 'flag: eswatiniflagsswaziland' },
      {
        emoji: '🇹🇦',
        category: 'Flags',
        searchQuery: 'flag: tristan da cunhaflagstristan_da_cunha'
      },
      {
        emoji: '🇹🇨',
        category: 'Flags',
        searchQuery: 'flag: turks & caicos islandsflagsturks_caicos_islands'
      },
      { emoji: '🇹🇩', category: 'Flags', searchQuery: 'flag: chadflagschad' },
      {
        emoji: '🇹🇫',
        category: 'Flags',
        searchQuery: 'flag: french southern territoriesflagsfrench_southern_territories'
      },
      { emoji: '🇹🇬', category: 'Flags', searchQuery: 'flag: togoflagstogo' },
      { emoji: '🇹🇭', category: 'Flags', searchQuery: 'flag: thailandflagsthailand' },
      { emoji: '🇹🇯', category: 'Flags', searchQuery: 'flag: tajikistanflagstajikistan' },
      { emoji: '🇹🇰', category: 'Flags', searchQuery: 'flag: tokelauflagstokelau' },
      { emoji: '🇹🇱', category: 'Flags', searchQuery: 'flag: timor-lesteflagstimor_leste' },
      { emoji: '🇹🇲', category: 'Flags', searchQuery: 'flag: turkmenistanflagsturkmenistan' },
      { emoji: '🇹🇳', category: 'Flags', searchQuery: 'flag: tunisiaflagstunisia' },
      { emoji: '🇹🇴', category: 'Flags', searchQuery: 'flag: tongaflagstonga' },
      { emoji: '🇹🇷', category: 'Flags', searchQuery: 'flag: turkeyflagstrturkey' },
      {
        emoji: '🇹🇹',
        category: 'Flags',
        searchQuery: 'flag: trinidad & tobagoflagstrinidad_tobago'
      },
      { emoji: '🇹🇻', category: 'Flags', searchQuery: 'flag: tuvaluflagstuvalu' },
      { emoji: '🇹🇼', category: 'Flags', searchQuery: 'flag: taiwanflagstaiwan' },
      { emoji: '🇹🇿', category: 'Flags', searchQuery: 'flag: tanzaniaflagstanzania' },
      { emoji: '🇺🇦', category: 'Flags', searchQuery: 'flag: ukraineflagsukraine' },
      { emoji: '🇺🇬', category: 'Flags', searchQuery: 'flag: ugandaflagsuganda' },
      {
        emoji: '🇺🇲',
        category: 'Flags',
        searchQuery: 'flag: u.s. outlying islandsflagsus_outlying_islands'
      },
      { emoji: '🇺🇳', category: 'Flags', searchQuery: 'flag: united nationsflagsunited_nations' },
      {
        emoji: '🇺🇸',
        category: 'Flags',
        searchQuery: 'flag: united statesflagsusflagunitedamerica'
      },
      { emoji: '🇺🇾', category: 'Flags', searchQuery: 'flag: uruguayflagsuruguay' },
      { emoji: '🇺🇿', category: 'Flags', searchQuery: 'flag: uzbekistanflagsuzbekistan' },
      { emoji: '🇻🇦', category: 'Flags', searchQuery: 'flag: vatican cityflagsvatican_city' },
      {
        emoji: '🇻🇨',
        category: 'Flags',
        searchQuery: 'flag: st. vincent & grenadinesflagsst_vincent_grenadines'
      },
      { emoji: '🇻🇪', category: 'Flags', searchQuery: 'flag: venezuelaflagsvenezuela' },
      {
        emoji: '🇻🇬',
        category: 'Flags',
        searchQuery: 'flag: british virgin islandsflagsbritish_virgin_islands'
      },
      {
        emoji: '🇻🇮',
        category: 'Flags',
        searchQuery: 'flag: u.s. virgin islandsflagsus_virgin_islands'
      },
      { emoji: '🇻🇳', category: 'Flags', searchQuery: 'flag: vietnamflagsvietnam' },
      { emoji: '🇻🇺', category: 'Flags', searchQuery: 'flag: vanuatuflagsvanuatu' },
      { emoji: '🇼🇫', category: 'Flags', searchQuery: 'flag: wallis & futunaflagswallis_futuna' },
      { emoji: '🇼🇸', category: 'Flags', searchQuery: 'flag: samoaflagssamoa' },
      { emoji: '🇽🇰', category: 'Flags', searchQuery: 'flag: kosovoflagskosovo' },
      { emoji: '🇾🇪', category: 'Flags', searchQuery: 'flag: yemenflagsyemen' },
      { emoji: '🇾🇹', category: 'Flags', searchQuery: 'flag: mayotteflagsmayotte' },
      { emoji: '🇿🇦', category: 'Flags', searchQuery: 'flag: south africaflagssouth_africa' },
      { emoji: '🇿🇲', category: 'Flags', searchQuery: 'flag: zambiaflagszambia' },
      { emoji: '🇿🇼', category: 'Flags', searchQuery: 'flag: zimbabweflagszimbabwe' },
      { emoji: '🏴󠁧󠁢󠁥󠁮󠁧󠁿', category: 'Flags', searchQuery: 'flag: englandflagsengland' },
      { emoji: '🏴󠁧󠁢󠁳󠁣󠁴󠁿', category: 'Flags', searchQuery: 'flag: scotlandflagsscotland' },
      { emoji: '🏴󠁧󠁢󠁷󠁬󠁳󠁿', category: 'Flags', searchQuery: 'flag: walesflagswales' }
    ]
  }
]
