<template>
  <div v-if="userData" class="oa-Wrapper">
    <AppRadioGroup
      v-model="view"
      :options="menuItems"
      class="oa-Nav"
      name="menu-type"
      type="tab-like"
    />

    <div :id="COMMENT_SECTION_ID" class="oa-Body">
      <div :class="itemsClasses" class="oa-Body_Items">
        <CommentList
          v-if="view === VIEW_TYPES.COMMENTS"
          ref="commentList"
          :items="comments"
          :objective="modelValue"
          :user-data="userData"
          can-be-replied
          infinite-loading
          @remove="onCommentRemove"
          @update="reloadComments"
          @update-from-data="onNewCommentCreate"
          @infinite-load="getComments"
        />
        <ActivityLog v-else ref="activityLog" :objective="modelValue" />
      </div>

      <CommentForm
        v-if="view === VIEW_TYPES.COMMENTS"
        :active="commentFormIsActive"
        :my-avatar="userData.userAvatarUrl"
        :objective="modelValue"
        :tracking-source="EVENT_SOURCES.FORM"
        class="oa-CommentForm"
        tracking-mode="main"
        @create="onNewCommentCreate"
        @update:active="commentFormIsActive = $event"
      />
    </div>
  </div>
</template>

<script>
import { isEmpty } from 'lodash'
import { defineComponent } from 'vue'
import { mapActions, mapState } from 'vuex'

import CommentsApiHandler from '@/api/comments'
import { EVENT_SOURCES } from '@/tracking/amplitude-helpers'
import { handleError } from '@/utils/error-handling'
import { commentFormInjectionKey } from '@/utils/injection-keys'
import { showNotify } from '@/utils/notify'
import { getIdsInMentions, resolveMentions } from '@/utils/rich-edit'
import { COMMENT_SECTION_ID } from '@/utils/scrollTo-utils'
import { isVisibleInScrollableParent } from '@/utils/window'

import ActivityLog from '@/components/objectives/forms/objective-form/ActivityLog'
import CommentForm from '@/components/objectives/forms/objective-form/CommentForm'
import CommentList from '@/components/objectives/forms/objective-form/CommentList'
import AppRadioGroup from '@/components/ui/AppRadioGroup/AppRadioGroup'

const commentsApi = new CommentsApiHandler()

const VIEW_TYPES = {
  COMMENTS: 'COMMENTS',
  ACTIVITY_LOG: 'ACTIVITY_LOG'
}

export default defineComponent({
  name: 'OkrElementActivities',
  components: {
    ActivityLog,
    CommentList,
    CommentForm,
    AppRadioGroup
  },

  provide() {
    return {
      [commentFormInjectionKey]: this.commentForm
    }
  },

  props: {
    modelValue: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      commentFormIsActive: false,
      view: VIEW_TYPES.COMMENTS,
      comments: [],
      page: 0,
      commentListLoadNextItems: true, // true - load next items, false - reload current items
      commentForm: {
        opened: undefined // undefined if no comment form is opened and uid of component if it is
      },

      allCommentsLoaded: false
    }
  },

  computed: {
    EVENT_SOURCES() {
      return EVENT_SOURCES
    },

    ...mapState('system', {
      userData: state => state.userData
    }),

    COMMENT_SECTION_ID: () => COMMENT_SECTION_ID,
    VIEW_TYPES: () => VIEW_TYPES,

    itemsClasses() {
      return {
        'oa-Body_Items': true,
        'oa-Body_Items-empty': this.view === VIEW_TYPES.COMMENTS && isEmpty(this.comments)
      }
    },

    menuItems() {
      return [
        {
          value: VIEW_TYPES.COMMENTS,
          label: this.$t('menu.comments')
        },
        {
          value: VIEW_TYPES.ACTIVITY_LOG,
          label: this.$t('menu.activity_log')
        }
      ]
    }
  },

  watch: {
    view(newValue) {
      if (newValue !== VIEW_TYPES.COMMENTS) {
        this.resetComments()
      }
    },

    'modelValue.id'() {
      this.resetComments()
      this.$refs.commentList?.updateItems()
      this.refreshData()
    }
  },

  methods: {
    ...mapActions('objectives', {
      getUsersForMentioning: 'getUsersForMentioning'
    }),

    resetComments() {
      this.comments = []
      this.page = 0
      this.commentListLoadNextItems = true
      this.allCommentsLoaded = false
    },

    /** @public */
    refreshData() {
      if (this.view === VIEW_TYPES.ACTIVITY_LOG) {
        this.refreshActivityLog()
      }
      if (this.view === VIEW_TYPES.COMMENTS) {
        this.resetComments()
        this.$refs.commentList?.updateItems()
      }
    },

    refreshActivityLog() {
      this.$refs.activityLog.refreshData()
    },

    /** @public */
    reloadComments(commentData) {
      if (commentData) {
        const { commentId, text } = commentData
        const deepUpdateCommentText = comments => {
          comments.forEach(comment => {
            if (comment.id === commentId) {
              comment.text = text
            } else if (!isEmpty(comment.childComments)) {
              deepUpdateCommentText(comment.childComments)
            }
          })
        }
        deepUpdateCommentText(this.comments)
      }
      this.allCommentsLoaded = false
      this.commentListLoadNextItems = false
      this.$refs.commentList.updateItems()
    },

    onNewCommentCreate(commentsInfo) {
      this.comments = commentsInfo.comments
      this.allCommentsLoaded = true
      if (commentsInfo.createdCommentId) {
        this.$nextTick(() => {
          const commentElement = document.querySelector(
            `#ci-CommentItem-${commentsInfo.createdCommentId}`
          )
          if (commentElement && !isVisibleInScrollableParent(commentElement)) {
            commentElement.scrollIntoView('center')
          }
        })
      }
    },

    async getComments($state) {
      if (this.allCommentsLoaded) {
        $state.complete()
        return
      }

      let offset = 0
      let limit = this.page * 5
      const itemsOnPage = 5
      if (this.commentListLoadNextItems) {
        offset = this.page * itemsOnPage
        limit = 5
        this.page += 1
      }

      try {
        const response = await commentsApi.getComments({
          limit,
          objectiveId: this.modelValue.id,
          offset
        })
        const commentsWithResolvedMentions = await this.resolveCommentMentions(response)
        if (this.commentListLoadNextItems) {
          this.comments = this.comments.concat(commentsWithResolvedMentions)
        } else {
          this.comments = commentsWithResolvedMentions
        }

        if (response.length > 0) {
          $state.loaded()
        }

        if (response.length < limit) {
          $state.complete()
        }
      } catch (error) {
        handleError({ error })
      }

      this.commentListLoadNextItems = true
    },

    async resolveCommentMentions(comments) {
      const userIds = []
      comments.forEach(comment => {
        userIds.push(...getIdsInMentions(comment.text))

        comment.childComments?.forEach(childComment => {
          userIds.push(...getIdsInMentions(childComment.text))
        })
      })

      const users = await this.getUsersForMentioning({
        accountIds: userIds,
        workspaceId: this.modelValue.workspaceId
      })

      comments.forEach(comment => {
        comment.text = resolveMentions(comment.text, userIds, users)

        comment.childComments?.forEach(childComment => {
          childComment.text = resolveMentions(childComment.text, userIds, users)
        })
      })

      return comments
    },

    /* undo remove should be handled either in root comment list or outside of comment list,
        because after deleting the last comment in child comment list, the component disappears
        and undo will not work */
    async undoRemove(item) {
      const commentsApi = new CommentsApiHandler()

      try {
        await commentsApi.removeComment({
          commentId: item.id,
          remove: false
        })
        this.reloadComments()
      } catch (error) {
        handleError({ error })
      }
    },

    onCommentRemove(item) {
      this.reloadComments()
      showNotify({
        title: this.$t('notifications.comment_deleted'),
        btnText: this.$t('notifications.comment_undo'),
        btnIcon: 'refresh-next',
        onBtnClick: () => this.undoRemove(item),
        hideOnBtnClick: true
      })
    }
  }
})
</script>

<style lang="scss" scoped>
.oa-Wrapper {
  display: flex;
  flex-direction: column;
  // gap: 16px;
  margin-top: 16px;
  position: relative;
}

.oa-Nav {
  // margin-bottom: 16px;
}

.oa-Body_Items {
  &:not(&-empty) {
    padding-top: 16px;
  }
}

.oa-CommentForm {
  margin-top: auto;
  position: sticky;
  bottom: 0;
  z-index: 3;
  padding: 16px 0 24px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.5) 8px,
    rgba(255, 255, 255, 1) 16px,
    rgba(255, 255, 255, 1) 100%
  );

  // &::before,
  // &::after {
  //   content: '';
  //   display: block;
  //   height: 16px;
  //   position: sticky;
  // }
  //
  // &::before {
  //   top: 54px; /* shadow is at bottom of element, so at 48 + 16 = 64px */
  //   box-shadow: $black 0 2px 0 0;
  // }
  //
  // &::after {
  //   /* linear gradient from background color to transparent acts as
  //    a transition effect so the shadow appears gradually */
  //   background: linear-gradient(
  //     $white 10%,
  //     rgba($white, 0.8) 50%,
  //     rgba($white, 0.4) 70%,
  //     transparent
  //   );
  //   top: 0;
  //   /* cover should fall over shadow */
  //   z-index: 2;
  // }
}
</style>
