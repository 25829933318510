<template>
  <div v-if="isOwner">
    <AppUnavailablePageLayout>
      <router-view v-if="ALLOWED_ROUTES.includes(route.name)" v-slot="{ Component }">
        <component :is="Component" />
      </router-view>
      <BillingSettings v-else />
    </AppUnavailablePageLayout>
  </div>
  <LicenseInactiveView v-else />
</template>

<script setup>
defineOptions({
  name: 'AppBillingMissing'
})

import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'

import { ROUTE_NAMES } from '@/routes/route-helpers'
import { PLUGIN_OPTIONS_KEYS } from '@root/template-options-keys'
import AppUnavailablePageLayout from '@shared-modules/components/AppUnavailablePageLayout'
import BillingSettings from '@shared-modules/views/workspaces/settings/billing/billing-settings'

import LicenseInactiveView from '@/views/LicenseInactiveView'

const ALLOWED_ROUTES = [
  ROUTE_NAMES.SETTINGS_BILLING_SETTINGS,
  ROUTE_NAMES.SETTINGS_BILLING_INFORMATION,
  ROUTE_NAMES.SETTINGS_BILLING,
  ROUTE_NAMES.SETTINGS_BILLING_PROMO
]

const store = useStore()
const isOwner = computed(() => store.state.pluginOptions[PLUGIN_OPTIONS_KEYS.IS_OWNER])
const route = useRoute()
</script>

<style lang="scss" scoped></style>
