<template>
  <div class="scr-Item">
    <FormFieldNext :label="t('common.on_the_entity')" inline>
      <AppSelect
        v-model="model[REMINDER_ENTITY_KEYS.DAYS_SHIFT]"
        :data-auto-testid="REMINDER_EXECUTION_DAY_FIELD_TEST_ID"
        :data-testid="REMINDER_EXECUTION_DAY_FIELD_TEST_ID"
        :options="Object.values(REMINDER_EXECUTION_DAY_OPTIONS)"
        v-bind="SCHEDULE_SELECT_ATTRS"
      />
    </FormFieldNext>

    <FormFieldNext :label="t('common.at')" inline>
      <div class="scr-Item_Time">
        <AppSelect
          v-model="model[REMINDER_ENTITY_KEYS.MINUTES]"
          :data-auto-testid="REMINDER_EXECUTION_TIME_FIELD_TEST_ID"
          :data-testid="REMINDER_EXECUTION_TIME_FIELD_TEST_ID"
          :options="REMINDER_TIME_OF_DAY_OPTIONS"
          v-bind="SCHEDULE_SELECT_ATTRS"
        />

        <div style="position: relative">
          <AppSelect
            v-model="model[REMINDER_ENTITY_KEYS.TIMEZONE_ID]"
            :data-auto-testid="REMINDER_TIMEZONE_FIELD_TEST_ID"
            :data-testid="REMINDER_TIMEZONE_FIELD_TEST_ID"
            :options="timezonesOptions"
            :search-function="value => localSearch({ value, options: timezonesOptions })"
            :type="SELECT_TYPES.MODERN"
            append-to="parent"
            dropdown-search
            v-bind="{
              ...SCHEDULE_SELECT_ATTRS,
              offset: [0, '-100%'],
              dropdownMinWidth: 220
            }"
          />
        </div>
      </div>
    </FormFieldNext>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'

import { SCHEDULE_SELECT_ATTRS } from '@/utils/check-ins/schedule-ui-helpers'
import {
  REMINDER_EXECUTION_DAY_OPTIONS,
  REMINDER_TIME_OF_DAY_OPTIONS
} from '@/utils/check-ins/schedules-helpers'
import { SELECT_TYPES } from '@/utils/components-configurations/app-select'
import { REMINDER_ENTITY_KEYS } from '@/utils/entity-keys'
import { localSearch } from '@/utils/objectives'
import { timezonesOptions } from '@/utils/timezones'

import {
  REMINDER_EXECUTION_DAY_FIELD_TEST_ID,
  REMINDER_EXECUTION_TIME_FIELD_TEST_ID,
  REMINDER_TIMEZONE_FIELD_TEST_ID
} from '@/components/check-ins/jest-helpers'
import FormFieldNext from '@/components/form/FormFieldNext'
import AppSelect from '@/components/ui/AppSelect/AppSelect'

defineOptions({
  name: 'ScheduleReminderItem'
})

const { t } = useI18n()

const model = defineModel({
  type: Object,
  required: true,
  set: value => value
})
</script>

<style lang="scss" scoped>
.scr-Item {
  --inner-gap: 8px;
  --gap: var(--inner-gap);
  display: flex;
  align-items: center;
  gap: var(--gap);
}

.scr-Item_Time {
  display: flex;
  align-items: center;
  gap: var(--inner-gap);
}
</style>
