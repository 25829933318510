<template>
  <div class="dgc-Cell">
    <span v-if="!isGroupsExist" class="dgc-Cell_Message">
      {{ $t('user.preferences.no_groups_for_user') }}
    </span>

    <template v-else>
      <CellSelectItem
        v-if="defaultGroupKit"
        :id="`gr${uniqId}`"
        :active="opened"
        :style="{
          '--item-background': defaultGroupKit.color,
          '--item-padding': 0
        }"
        class="dgc-GroupLabel"
        truncated
        user-has-read-access
        user-has-update-access
        @click="editInitialised = true"
      >
        <GroupSelectLabel :group="defaultGroupKit" :loading="isLoading" no-fill />
      </CellSelectItem>

      <AppButton
        v-else
        :id="`gr${uniqId}`"
        :class="{
          'dgc-PlusButton-active': opened
        }"
        height="24"
        icon="plus-next"
        remove-padding
        size="sm"
        type="tertiary-next"
        width="24"
        @click="editInitialised = true"
      />

      <AppSelect
        v-if="editInitialised"
        v-model="selectedDefaultGroup"
        :offset="[0, 0]"
        :options="groups"
        :search-function="value => localSearch({ value, options: groups })"
        :to-selector="`#gr${uniqId}`"
        append-to=".up-MainContent"
        boundary="scrollParent"
        dropdown-search
        dropdown-width="220px"
        item-label="name"
        item-value="id"
        show-on-init
        @hide="onHide"
        @opened="opened = true"
      >
        <template #option-label="{ option }">
          <GlobalGroupsSelectOption v-if="option" :group="option" />
        </template>
      </AppSelect>
    </template>
  </div>
</template>

<script setup>
import { isEmpty, isNull } from 'lodash'
import { computed, nextTick, ref } from 'vue'

import PersonalSettingsApiHandler from '@/api/personal-settings'
import { tracker } from '@/tracking/amplitude'
import { EVENT_CATEGORIES } from '@/tracking/amplitude-helpers'
import { GROUP_ENTITY_KEYS } from '@/utils/entity-keys'
import { handleError } from '@/utils/error-handling'
import { localSearch } from '@/utils/objectives'
import { numberOrNullProp } from '@/utils/prop-validators'
import { uid } from '@/utils/uid'

import GlobalGroupsSelectOption from '@/components/global-groups/GlobalGroupsSelectOption'
import GroupSelectLabel from '@/components/global-groups/GroupSelectLabel'
import CellSelectItem from '@/components/objectives/table/cells/CellSelectItem'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppSelect from '@/components/ui/AppSelect/AppSelect'

defineOptions({
  name: 'DefaultGroupCell'
})

const { ID } = GROUP_ENTITY_KEYS

const props = defineProps({
  groups: {
    type: Array,
    required: true
  },

  defaultGroupId: {
    required: true,
    validator: v => numberOrNullProp(v)
  },

  workspaceId: {
    type: [String, Number],
    required: true
  }
})

const uniqId = uid()

const editInitialised = ref(false)

const opened = ref(false)

const selectedDefaultGroup = computed({
  get: () => props.defaultGroupId,
  set: value => {
    onUpdateDefaultGroup(value)
  }
})

const isLoading = ref(false)

const emit = defineEmits({
  'update-default-group': null
})
const onUpdateDefaultGroup = async defaultGroupId => {
  if (defaultGroupId === props.defaultGroupId) {
    return
  }
  isLoading.value = true
  const api = new PersonalSettingsApiHandler()

  try {
    await api.setDefaultGroup({
      defaultGroupId,
      workspaceId: props.workspaceId
    })

    tracker.logEvent('Update default group', {
      category: EVENT_CATEGORIES.PERSONAL_SETTINGS,
      workspace: props.workspaceId,
      group: props.groups.find(group => group[GROUP_ENTITY_KEYS.ID] === defaultGroupId)?.[
        GROUP_ENTITY_KEYS.NAME
      ]
    })

    emit('update-default-group', {
      defaultGroupId,
      workspaceId: props.workspaceId
    })
  } catch (error) {
    handleError({ error })
  } finally {
    isLoading.value = false
  }
}

const isGroupsExist = computed(() => !isEmpty(props.groups))

const defaultGroupKit = computed(() => {
  if (isNull(props.defaultGroupId) || !isGroupsExist.value) {
    return null
  }

  const defaultGroup = props.groups.find(group => group[ID] === props.defaultGroupId)

  if (defaultGroup) {
    return defaultGroup
  } else {
    return null
  }
})

const onHide = () => {
  opened.value = false
  nextTick(() => {
    editInitialised.value = false
  })
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/app-table-helpers';
@import '~@/assets/styles/mixins';

.dgc-Cell {
  display: flex;
}

.dgc-Cell_Message {
  @extend %app-table-hidden-items;
  color: $dark-3;
  font-size: $fs-12;
  font-style: normal;
  font-weight: fw('regular');
  line-height: 16px;
}

.dgc-GroupLabel {
  --item-hover-background: #{getGlobalGroupHoverColor(var(--item-background))};
}

.dgc-PlusButton-active {
  color: $white;
  background: $dark-2;
}
</style>
