<template>
  <div class="ops-Switch">
    <div v-if="showTitle" class="ops-Switch_Title">
      {{ $t('objectives.period_mode.title') }}
    </div>
    <AppRadioGroup
      :disabled="disabled"
      :model-value="activeMode"
      :name="name"
      :options="modes"
      class="ops-Switch_Controls"
      style="--option-padding: 0 8px 0 0"
      type="primary-next"
      @update:model-value="setActiveMode"
    >
      <template #item-label="{ item }">
        <AppRadioGroupNextOptionWithIcon :option="item" />
      </template>
    </AppRadioGroup>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

import { isManualPeriodModeEnabled, PERIOD_MODES } from '@/utils/okr-element-dates'

import AppRadioGroup from '@/components/ui/AppRadioGroup/AppRadioGroup'
import AppRadioGroupNextOptionWithIcon from '@/components/ui/AppRadioGroup/AppRadioGroupNextOptionWithIcon'

export default defineComponent({
  name: 'OkrPeriodModeSwitch',
  components: { AppRadioGroupNextOptionWithIcon, AppRadioGroup },
  props: {
    startDateManual: {
      type: Number,
      required: true,
      validator: v => [0, 1].includes(v)
    },

    dueDateManual: {
      type: Number,
      required: true,
      validator: v => [0, 1].includes(v)
    },

    showTitle: {
      type: Boolean,
      default: true
    },

    name: {
      type: String,
      required: true
    },

    disabled: {
      type: Boolean
    }
  },

  emits: {
    'update-period-mode': null
  },

  computed: {
    activeMode() {
      return isManualPeriodModeEnabled(this.startDateManual, this.dueDateManual)
        ? PERIOD_MODES.MANUAL.value
        : PERIOD_MODES.AUTO.value
    },

    modes() {
      return Object.values(PERIOD_MODES)
    }
  },

  methods: {
    setActiveMode(mode) {
      this.$emit('update-period-mode', mode)
    }
  }
})
</script>

<style lang="scss" scoped>
.ops-Switch_Title {
  margin-bottom: 8px;
  color: $dark-1;
  font-family: $system-ui;
  font-style: normal;
  font-weight: fw('bold');
  font-size: $fs-14;
  line-height: 20px;
}
</style>
