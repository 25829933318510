<template>
  <div class="nsr-Wrapper">
    <ObjectivesEmptyHero />
    <div class="nsr-Description">
      <slot />
    </div>
  </div>
</template>

<script setup>
import ObjectivesEmptyHero from '@/components/objectives/ObjectivesEmptyHero'

defineOptions({
  name: 'NoSearchResults'
})

defineProps({
  offsetLeft: {
    type: [String, Number],
    default: ''
  },

  offsetRight: {
    type: [String, Number],
    default: ''
  }
})
</script>

<style lang="scss" scoped>
.nsr-Wrapper {
  border: 2px solid $grey-3-next;
  border-radius: $border-radius-md-next;
  margin: 6px v-bind(offsetLeft) 0 v-bind(offsetRight);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 23px;
  padding: 18px 18px 31px;

  font-style: normal;
  font-weight: fw('regular');
  font-size: $fs-14;
  line-height: 20px;
  color: $dark-3;
  font-family: $system-ui;
}
</style>
