<template>
  <div class="pm-Wrapper">
    <div class="pm-Item">
      <div class="pm-Item_Title">{{ $t('subscription.payment_method') }}</div>
      <div>
        <div class="pm-Item_CardItem">
          <div class="pm-CardItem_Title">
            <div>
              <div class="pm-Item_PaymentType">
                {{ paymentMethod.type }}

                <StatusBadge
                  v-if="paymentMethod.type"
                  :data="statusData"
                  no-border
                  style="--border-radius: 12px; --font-size: 8px; --line-height: 10px"
                >
                  {{ statusData.label }}
                </StatusBadge>
              </div>
              <span v-if="paymentMethod.last4Digits" class="pm-Item_Subtitle">
                {{ paymentMethod.brand }} ****
                {{ paymentMethod.last4Digits }}
              </span>
            </div>
          </div>

          <div v-if="paymentMethod.expMonth && paymentMethod.expYear" class="pm-Item_Subtitle">
            {{ $t('subscription.expires') }} {{ paymentMethod.expMonth }} /
            {{ paymentMethod.expYear }}
          </div>
          <AppButton
            class="pm-Item_EditBtn"
            remove-padding
            type="link-next"
            @click="fetchBillingPortalSession"
          >
            {{ paymentMethod.type ? $t('action.edit') : $t('action.add_method') }}
          </AppButton>
        </div>
      </div>
    </div>
    <div class="pm-Item">
      <div class="pm-Item_Title">{{ $t('subscription.billing_address') }}</div>
      <div>
        <div class="pm-Item_CardItem">
          <div v-if="!isEmpty(billingAddress)" class="pm-Item_Subtitle">
            {{ getBillingAddressWithComa }}
          </div>
          <AppButton
            class="pm-Item_EditBtn"
            remove-padding
            type="link-next"
            @click="goToBillingInformation"
          >
            {{ getBillingAddressWithComa ? $t('action.edit') : $t('action.add_address') }}
          </AppButton>
        </div>
      </div>
    </div>
    <div v-if="customerInfo.taxId" class="pm-Item">
      <div class="pm-Item_Title">{{ $t('subscription.tax_id') }}</div>
      <div>
        <div class="pm-Item_CardItem">
          <div class="pm-Item_Subtitle">{{ customerInfo.taxId }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
defineOptions({
  name: 'PaymentMethods'
})

import { isEmpty } from 'lodash'
import { computed, inject } from 'vue'
import { useRouter } from 'vue-router'

import BillingApiHandler from '@/api/billing'
import { ROUTE_NAMES } from '@/routes/route-helpers'
import { tracker } from '@/tracking/amplitude'
import { EVENT_CATEGORIES } from '@/tracking/amplitude-helpers'
import { handleError } from '@/utils/error-handling'
import { OPEN_LINK_TARGETS, openLink } from '@/utils/external-link-helper'
import { isSalesforceAppInjectionKey } from '@/utils/injection-keys'

import StatusBadge from '@/components/objectives/StatusBadge'
import AppButton from '@/components/ui/AppButton/AppButton'

const props = defineProps({
  customerInfo: {
    type: Object,
    default: () => ({})
  }
})

// const PAYMENT_TYPES = {
//   CARD: 'card',
//   BANK_ACCOUNT: 'bank_account',
//   PAYPAL: 'paypal'
// }

const statusData = {
  label: 'DEFAULT',
  value: 'default',
  color: 'var(--grade-high-color-next)',
  backgroundColor: 'rgba(var(--grade-high-color-rgb-next), 0.2)'
}

const isSalesforceApp = inject(isSalesforceAppInjectionKey)

const fetchBillingPortalSession = async () => {
  const BillingApi = new BillingApiHandler()
  try {
    const { link } = await BillingApi.getBillingPortalSession()

    const target = isSalesforceApp ? OPEN_LINK_TARGETS.BLANK : OPEN_LINK_TARGETS.SELF

    openLink({
      url: link,
      useBrowserWorkaround: isSalesforceApp,
      target
    })

    tracker.logEvent('edit card requested', {
      category: EVENT_CATEGORIES.BILLING
    })
  } catch (error) {
    handleError({ error })
  }
}

const router = useRouter()

const goToBillingInformation = () => {
  router.push({ name: ROUTE_NAMES.SETTINGS_BILLING_INFORMATION })
}

const paymentMethod = computed(() => props.customerInfo?.paymentMethod || {})
const billingAddress = computed(() => props.customerInfo?.billingAddress || {})

const getBillingAddressWithComa = computed(() => {
  return [
    billingAddress.value.country,
    billingAddress.value.city,
    billingAddress.value.state,
    billingAddress.value.line1,
    billingAddress.value.line2,
    billingAddress.value.postalCode
  ]
    .filter(val => val)
    .join(', ')
})
</script>

<style lang="scss" scoped>
.pm-Wrapper {
  display: grid;
  grid-template-columns: 241px 241px 241px;
  justify-content: space-between;
}
.pm-CardItem_Title {
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 0 0 3px 0;
}
.pm-Item_Subtitle {
  font-size: $fs-12;
  color: $dark-2;
  margin: 0 0 4px 0;
  overflow: hidden;
  text-overflow: ellipsis;
}
.pm-Item_PaymentType {
  color: $dark-1;
  font-size: $fs-14;
  line-height: 20px;
  font-weight: fw('semi-bold');
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}
.pm-Item_EditBtn {
  font-size: $fs-12;
}
.pm-Item_Title {
  font-size: $fs-12;
  line-height: 16px;
  color: $dark-3;
  font-weight: fw('bold');
  margin: 0 0 11px 0;
}
</style>
