import ApiHandler from '@/api/base'
import { switchDevOrganization } from '@/utils/web-app/organization-helpers'

class WebAppOrganizationApiHandler extends ApiHandler {
  async renameOrganizationAndRootGroup({ name }) {
    const { data } = await this.http.put('/organizations/onboarding-organization', {
      name
    })

    return data
  }

  async renameOrganization({ name }) {
    const { data } = await this.http.put('/organizations', {
      name
    })

    return data
  }

  async createOrganization({ name }) {
    const { data } = await this.http.post('/organizations', {
      name
    })

    return data
  }

  async switchOrganization({ iss }) {
    const { data } = await this.http.post('/organization', {
      iss
    })

    const { accessToken, refreshToken } = data

    await switchDevOrganization({
      iss,
      accessToken,
      refreshToken
    })
  }

  async inviteUsersToCompany({ users }) {
    await this.http.post('/inviteToCompany', users)
  }
}

export default WebAppOrganizationApiHandler
