<template>
  <div class="cdt-Loader">
    <div v-for="row in ROWS" :key="row" class="cdt-Loader_Row">
      <div class="cdt-Cell">
        <SkeletonItem
          :appearance="SKELETON_APPEARANCES.SQUARE"
          :size="SM"
          border-radius="16px"
          height="24px"
          width="24px"
        />
      </div>
      <div class="cdt-Cell">
        <SkeletonItem :size="XS" :width="row.name" />
      </div>
      <div class="cdt-Cell">
        <SkeletonItem :size="SM" :width="row.grade" border-radius="6px" height="24px" />
      </div>
      <div class="cdt-Cell">
        <SkeletonItem
          :appearance="SKELETON_APPEARANCES.SQUARE"
          :size="SM"
          border-radius="16px"
          height="24px"
          width="24px"
        />
        <SkeletonItem :size="SM" :width="row.owner" border-radius="6px" height="24px" />
      </div>
      <div class="cdt-Cell">
        <SkeletonItem :size="XS" :width="row.visibility" />
      </div>
      <div class="cdt-Cell" />
    </div>
    <div class="cdt-FooterNav">
      <SkeletonItem height="32px" width="92px" />
      <SkeletonItem :size="XS" width="65px" />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CustomDashboardTableLoader'
})
</script>
<script setup>
import {
  SKELETON_APPEARANCES,
  SKELETON_SIZES
} from '@/utils/components-configurations/skeleton-item'

const { SM, XS } = SKELETON_SIZES

import SkeletonItem from '@/components/ui/SkeletonLoaders/SkeletonItem'

const ROWS = [
  {
    id: 0,
    name: '90px',
    grade: '70px',
    owner: '70px',
    visibility: '50px',
    action: '24px'
  },
  {
    id: 1,
    name: '110px',
    grade: '60px',
    owner: '100px',
    visibility: '50px',
    action: '24px'
  },
  {
    id: 2,
    name: '160px',
    grade: '60px',
    owner: '100px',
    visibility: '50px',
    action: '24px'
  },
  {
    id: 3,
    name: '250px',
    grade: '60px',
    owner: '100px',
    visibility: '50px',
    action: '24px'
  },
  {
    id: 4,
    name: '200px',
    grade: '60px',
    owner: '100px',
    visibility: '50px',
    action: '24px'
  },
  {
    id: 5,
    name: '300px',
    grade: '60px',
    owner: '100px',
    visibility: '50px',
    action: '24px'
  },
  {
    id: 6,
    name: '200px',
    grade: '60px',
    owner: '100px',
    visibility: '50px',
    action: '24px'
  },
  {
    id: 7,
    name: '100px',
    grade: '60px',
    owner: '100px',
    visibility: '50px',
    action: '24px'
  },
  {
    id: 8,
    name: '180px',
    grade: '60px',
    owner: '100px',
    visibility: '50px',
    action: '24px'
  },
  {
    id: 9,
    name: '100px',
    grade: '60px',
    owner: '100px',
    visibility: '50px',
    action: '24px'
  },
  {
    id: 10,
    name: '100px',
    grade: '60px',
    owner: '100px',
    visibility: '50px',
    action: '24px'
  }
]
</script>

<style lang="scss" scoped>
.cdt-Loader {
  padding: 10px var(--page-right-padding) 0 var(--page-left-padding);
}
.cdt-Loader_Row {
  height: 45px;
  display: flex;
  align-items: center;
  &:first-child {
    height: 24px;
  }
}

.cdt-Cell {
  display: flex;
  align-items: center;

  &:not(:first-child) {
    gap: 4px;
  }

  &:first-child {
    flex: 0 0 48px;
  }

  &:nth-child(2) {
    gap: 4px;
    flex: 1 1 100%;
  }
  &:nth-child(3) {
    flex: 0 0 152px;
    gap: 8px;
  }

  &:nth-child(4) {
    flex: 0 0 182px;
    gap: 8px;
  }

  &:nth-child(5) {
    flex: 0 0 184px;
    padding-left: 32px;
    gap: 8px;
  }

  &:nth-child(6) {
    flex: 0 0 24px;
    gap: 8px;
  }
}
.cdt-FooterNav {
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0 32px;
}
</style>
