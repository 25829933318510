export const APP_MODAL_SIZES = {
  XS: 'xs',
  XS_NEXT: 'xs-next',
  SM: 'sm',
  SM_NEXT: 'sm-next',
  MD: 'md',
  MD_NEXT: 'md-next',
  LG: 'lg',
  LG_NEXT: 'lg-next',
  XL: 'xl',
  XL_NEXT: 'xlg-next',
  FULL_PAGE: 'full-page',
  FULLSCREEN: 'fullscreen'
}
