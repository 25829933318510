<template>
  <div class="df-DescriptionField" tabindex="0">
    <div ref="editorRef" :class="editorClasses">
      <div v-if="editor" v-show="active" class="df-Toolbar">
        <AppSelect
          :append-to="getAppendToElementClass"
          :dropdown-min-width="200"
          :model-value="textFormat"
          :offset="[0, 0]"
          :options="textFormats"
          class="df-TextFormats"
        >
          <template #button="{ option, active: TextFormatActive }">
            <div
              :class="{ 'df-TextFormats_DroplistButton-active': TextFormatActive }"
              class="df-TextFormats_DroplistButton"
            >
              <span class="df-DroplistButton_Text">
                {{ option.label }}
              </span>
              <AppIcon
                class="df-DroplistButton_Icon"
                height="24"
                icon-name="arrow-down-black"
                width="24"
              />
            </div>
          </template>
          <template #option="{ option, onSelect, selected }">
            <div
              :class="{
                [`df-TextFormats_Heading-${option.value}`]: option.value,
                'df-TextFormats_Heading-active': selected
              }"
              class="df-TextFormats_Heading"
              @click="setTextFormat(option, onSelect)"
            >
              {{ option.label }}
            </div>
          </template>
        </AppSelect>

        <div class="df-ToolbarSplitter" />

        <DescriptionButton
          :active="editor.isActive('bold')"
          icon="format-bold"
          @click="editor.chain().focus().toggleBold().run()"
        />

        <DescriptionButton
          :active="editor.isActive('italic')"
          icon="format-italic"
          @click="editor.chain().focus().toggleItalic().run()"
        />

        <AppSelect
          ref="textColorPicker"
          :append-to="getAppendToElementClass"
          :offset="[0, 0]"
          :options="COLORS"
          content-class="df-ColorText_ContentOptions"
          dropdown-width="260px"
        >
          <template #button="{ active: activeTextColorPicker }">
            <div class="df-ColorTextWrapper">
              <DescriptionButton
                :active="activeTextColorPicker || editor.isActive('textStyle')"
                icon="format-color-picker"
                icon-height="24"
                icon-width="32"
                size="md"
              />
              <div
                :style="{
                  background: getColorAttribute('textStyle')
                }"
                class="df-ColorTextWrapper_Marker"
              />
            </div>
          </template>
          <template #option="{ option }">
            <div class="df-ColorTextWrapper_OptionWrapper">
              <div
                :class="{
                  'df-ColorTextWrapper_Option-active': editor.isActive('textStyle', {
                    color: option
                  })
                }"
                :style="{ 'background-color': option }"
                class="df-ColorTextWrapper_Option"
                @click="onTextColorChange(option)"
              >
                <AppIcon
                  v-if="editor.isActive('textStyle', { color: option })"
                  :style="{ color: ICON_COLORS[option] }"
                  height="22"
                  icon-name="check"
                  width="22"
                />
              </div>
            </div>
          </template>
        </AppSelect>

        <AppSelect
          :append-to="getAppendToElementClass"
          :dropdown-min-width="220"
          :group-by="item => item.group"
          :model-value="moreFormat"
          :offset="[0, 0]"
          :options="moreFormats"
          :show-group-header="false"
          max-height="240"
          show-group-divider
        >
          <template #option="{ option, onSelect }">
            <!-- eslint-disable vue/no-v-html -->
            <div
              :class="{
                'df-TextFormats_Heading-active': editor.isActive(option.value.toLowerCase())
              }"
              class="df-TextFormats_Heading"
              @click="onMoreFormatChange(option, onSelect)"
            >
              <template v-if="option.labelDescription">
                {{ $t(option.label) }}
                <i18n-t :keypath="option.labelDescription" :tag="option.tag" scope="global" />
              </template>
              <i18n-t v-else :keypath="option.label" :tag="option.tag" scope="global" />
            </div>
          </template>
          <template #button="{ active: activeMoreFormats }">
            <DescriptionButton
              :active="activeMoreFormats || isActiveAnyMoreFormats"
              icon="format-more"
            />
          </template>
        </AppSelect>

        <div class="df-ToolbarSplitter" />

        <DescriptionButton
          :active="editor.isActive('bulletList')"
          icon="list_bulleted"
          @click="editor.chain().focus().toggleBulletList().run()"
        />

        <DescriptionButton
          :active="editor.isActive('orderedList')"
          icon="list_numbered"
          @click="editor.chain().focus().toggleOrderedList().run()"
        />

        <div class="df-ToolbarSplitter" />

        <!--    forbidden to insert table when another table is active   -->
        <template v-if="!simpleView">
          <DescriptionButton
            :disable="editor.isActive('table')"
            icon="table-icon"
            @click="insertTable"
          />

          <AppDroplist
            v-model="showUrlModal"
            :append-to="getAppendToElementClass"
            :offset="[0, 10]"
            :theme="DROP_LIST_THEMES.LIGHT"
            has-fixed-parent
            max-width="595"
            position="bottom"
          >
            <template #button>
              <DescriptionButton :active="showUrlModal || editor.isActive('link')" icon="link" />
            </template>
            <div class="df-Toolbar_LinkWrapper">
              <AppInput
                v-model.trim="url"
                :placeholder="$t('action.paste_link')"
                class="df-LinkWrapper_Input"
                icon="link"
                size="xlg"
                style-type="secondary"
              />
              <AppButton
                class="df-LinkWrapper_Button"
                size="lg-next"
                type="primary-next"
                @click="onConfirmSetUrl"
              >
                {{ $t('action.add_link') }}
              </AppButton>
            </div>
          </AppDroplist>

          <AppEmojiPicker @select-emoji="insertEmoji" />
          <div class="df-ToolbarSplitter" />
        </template>
        <AddGifsDroplist
          v-if="isHidden(TIPTAP_EXTENSION_NAMES.GIFS)"
          ref="gifDroplistRef"
          :editor="editor"
          :placement="placement"
        />
        <AppDroplist
          v-model="showAddYouTubeModal"
          :append-to="getAppendToElementClass"
          :offset="[0, 10]"
          :theme="DROP_LIST_THEMES.LIGHT"
          has-fixed-parent
          max-width="595"
          position="bottom"
        >
          <div class="df-Toolbar_LinkWrapper">
            <AppInput
              v-model="url"
              :placeholder="$t('action.paste_yt_video_link')"
              class="df-LinkWrapper_Input"
              icon="link"
              size="xlg"
              style-type="secondary"
            />
            <AppButton
              class="df-LinkWrapper_Button"
              size="lg-next"
              type="primary-next"
              @click="onConfirmAddYouTubeUrl"
            >
              {{ $t('action.add_video') }}
            </AppButton>
          </div>
        </AppDroplist>

        <AppSelect
          :append-to="getAppendToElementClass"
          :dropdown-min-width="200"
          :offset="[0, 0]"
          :options="simpleViewList"
          position="bottom-end"
        >
          <template #option="{ option, onSelect }">
            <div
              :class="{
                'df-TextFormats_Heading-active': option.isActive,
                'df-TextFormats_Heading-disabled': option.disabled
              }"
              class="df-TextFormats_Heading df-SimpleViewList"
              @click="
                () => {
                  execAction(option.action)
                  onSelect(option)
                }
              "
            >
              <AppIcon :icon-name="option.icon" height="24" width="24"></AppIcon>
              {{ option.title }}
            </div>
          </template>
          <template #button="{ active: activeSimpleView }">
            <DescriptionButton
              :active="activeSimpleView"
              icon="add-more"
              icon-height="24"
              icon-width="32"
              size="md"
            />
          </template>
        </AppSelect>
        <!--   This and next row needs for displaying droplist content in the simple view     -->

        <AppDroplist
          v-if="simpleView"
          v-model="showUrlModal"
          :append-to="getAppendToElementClass"
          :offset="[0, 10]"
          :theme="DROP_LIST_THEMES.LIGHT"
          has-fixed-parent
          max-width="595"
          position="bottom"
        >
          <template #button>
            <div />
          </template>
          <div class="df-Toolbar_LinkWrapper">
            <AppInput
              v-model.trim="url"
              :placeholder="$t('action.paste_link')"
              class="df-LinkWrapper_Input"
              icon="link"
              size="xlg"
              style-type="secondary"
            />
            <AppButton
              class="df-LinkWrapper_Button"
              size="lg-next"
              type="primary-next"
              @click="onConfirmSetUrl"
            >
              {{ $t('action.add_link') }}
            </AppButton>
          </div>
        </AppDroplist>
        <AppEmojiPicker
          v-if="simpleView"
          ref="emojiPickerRef"
          :show-button-trigger-button="!simpleView"
          @select-emoji="insertEmoji"
        />
      </div>

      <div
        v-if="!active && isDescriptionEmpty && showPlaceholder"
        class="df-Placeholder"
        @click="showEditor"
      >
        <span class="df-Placeholder_Text">{{ placeholder }}</span>
      </div>

      <EditorContent
        v-if="active || !isDescriptionEmpty"
        :editor="editor"
        class="df-Content"
        @click="onClickDescriptionField"
      />
    </div>

    <div v-if="active" class="df-ActionsAndCounter">
      <div v-if="actions" class="df-Actions">
        <div class="df-Actions_Item">
          <AppButton
            :disable="(!allowEmpty && isDescriptionEmpty) || !isDescriptionValid || disable"
            type="primary-next"
            @click="onSave"
          >
            {{ $t('action.save') }}
          </AppButton>
        </div>
        <div class="df-Actions_Item">
          <AppButton type="ghost-next" @click="onCancel">
            {{ $t('action.cancel') }}
          </AppButton>
        </div>
      </div>
      <div
        v-if="!(!actions && charactersKept > 500)"
        :class="{
          'df-DescriptionCounter': true,
          'df-DescriptionCounter-negative': !isDescriptionValid
        }"
      >
        {{ charactersKept }}
      </div>
    </div>
  </div>
</template>

<script>
// Do not update tiptap, because in next versions it has some performance problems. Current versions work without bugs (we didn't find :)

import CharacterCount from '@tiptap/extension-character-count'
import { Color } from '@tiptap/extension-color'
import Image from '@tiptap/extension-image'
import Link from '@tiptap/extension-link'
import Mention from '@tiptap/extension-mention'
import Placeholder from '@tiptap/extension-placeholder'
import Subscript from '@tiptap/extension-subscript'
import Superscript from '@tiptap/extension-superscript'
import TableRow from '@tiptap/extension-table-row'
import TaskItem from '@tiptap/extension-task-item'
import TaskList from '@tiptap/extension-task-list'
import TextStyle from '@tiptap/extension-text-style'
import Underline from '@tiptap/extension-underline'
import Youtube from '@tiptap/extension-youtube'
import StarterKit from '@tiptap/starter-kit'
import { Editor, EditorContent, VueRenderer } from '@tiptap/vue-3'
import { isEmpty } from 'lodash'
import tippy from 'tippy.js'
import { defineComponent } from 'vue'
import { mapActions } from 'vuex'

import UsersApi from '@/api/workspace-users'
import { DROP_LIST_THEMES } from '@/utils/components-configurations/app-droplist'
import { handleError } from '@/utils/error-handling'
import { isStringEmpty } from '@/utils/general'
import { descriptionFieldStateInjectionKey } from '@/utils/injection-keys'
import { unshiftKeydownEventListener, removeKeydownEventListener } from '@/utils/keyevents'
import { getIdsInMentions, resolveMentions } from '@/utils/rich-edit'
import {
  MORE_FORMATS_ACTIONS,
  ICON_COLORS,
  COLORS,
  YOUTUBE_VIDEO_WIDTH,
  YOUTUBE_VIDEO_HEIGHT,
  getCurrentTip,
  TEXT_FORMATS,
  getSimpleViewList,
  getMoreFormats,
  HEADING_LEVELS,
  removeSlashCommandShortcut,
  insertEmptySpaces,
  MENU_LIST,
  insertTable,
  DESCRIPTION_FIELD_PLACEMENTS,
  TIPTAP_EXTENSION_NAMES
} from '@/utils/tiptap-utils'

import AppDroplist from '@/components/AppDroplist'
import AppEmojiPicker from '@/components/AppEmojiPicker'
import DescriptionButton from '@/components/objectives/forms/DescriptionButton'
import AddGifsDroplist from '@/components/TipTapExtensions/AddGifs/AddGifsDroplist'
import ImageBlock from '@/components/TipTapExtensions/ImageBlock/image-block'
import ImageUpload from '@/components/TipTapExtensions/ImageUpload/image-upload'
import SlashCommand from '@/components/TipTapExtensions/SlashCommand/slash-command'
import TiptapTable from '@/components/TipTapExtensions/Table/tiptap-table'
import TiptapTableCell from '@/components/TipTapExtensions/Table/tiptap-table-cell'
import TiptapTableHeader from '@/components/TipTapExtensions/Table/tiptap-table-header'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppIcon from '@/components/ui/AppIcon/AppIcon'
import AppInput from '@/components/ui/AppInput/AppInput'
import AppSelect from '@/components/ui/AppSelect/AppSelect'

import MentionList from './MentionList'

export default defineComponent({
  name: 'DescriptionField',

  components: {
    AddGifsDroplist,
    AppButton,
    EditorContent,
    AppSelect,
    DescriptionButton,
    AppIcon,
    AppEmojiPicker,
    AppDroplist,
    AppInput
  },

  provide() {
    return {
      [descriptionFieldStateInjectionKey]: {
        getActiveState: () => this.active
      }
    }
  },

  props: {
    modelValue: {
      type: String,
      default: ''
    },

    actions: {
      type: Boolean,
      default: true
    },

    readonly: {
      type: Boolean
    },

    workspaceId: {
      type: [Number, String],
      required: true
    },

    maxLength: {
      type: Number,
      default: 18000
    },

    // Note: without placeholder cursor on focused field is invisible in Safari
    placeholder: {
      type: String,
      default: ''
    },

    allowEmpty: {
      type: Boolean,
      default: true
    },

    blurOnSave: {
      type: Boolean,
      default: true
    },

    styled: {
      type: Boolean,
      default: true
    },

    showPlaceholder: {
      type: Boolean,
      default: true
    },

    simpleView: {
      type: Boolean
    },

    disable: {
      type: Boolean
    },

    appendMentionToBody: {
      type: Boolean,
      default: true
    },

    showOnInit: {
      type: Boolean
    },

    placement: {
      type: String,
      default: DESCRIPTION_FIELD_PLACEMENTS
    },

    hiddenFeatures: {
      type: Array,
      default: () => []
    }
  },

  emits: { 'update:modelValue': null, cancel: null, save: null },

  data() {
    return {
      active: false,
      editor: null,
      textFormat: 'paragraph',
      moreFormat: '',
      localValue: this.modelValue,
      editorPopup: null,
      editorComponent: null,
      showUrlModal: false,
      showAddYouTubeModal: false,
      url: '',
      showSimpleList: false
    }
  },

  computed: {
    COLORS: () => COLORS,
    ICON_COLORS: () => ICON_COLORS,
    DROP_LIST_THEMES: () => DROP_LIST_THEMES,
    textFormats: () => TEXT_FORMATS,
    TIPTAP_EXTENSION_NAMES: () => TIPTAP_EXTENSION_NAMES,

    editorClasses() {
      return {
        'df-Editor': true,
        'df-Editor-inactive': !this.active,
        'df-Editor-active': this.active,
        'df-Editor-styled': this.styled,
        'df-Editor-readonly': this.readonly
      }
    },

    simpleViewList() {
      return getSimpleViewList({
        editor: this.editor,
        simpleView: this.simpleView,
        showUrlModal: this.showUrlModal,
        hiddenFeatures: this.hiddenFeatures
      })
    },

    isActiveAnyMoreFormats() {
      return !isEmpty(
        this.moreFormats.filter(format => this.editor.isActive(format.value.toLowerCase()))
      )
    },

    moreFormats() {
      return getMoreFormats({ editor: this.editor })
    },

    isDescriptionEmpty() {
      return !(this.editor && !this.editor.isEmpty)
    },

    isDescriptionValid() {
      return this.charactersKept > -1
    },

    charactersKept() {
      // return this.maxLength - this.editor.storage.characterCount.characters()
      return this.maxLength - this.modelValue.length
    },

    getAppendToElement() {
      return () => (this.appendMentionToBody ? document.body : this.$refs.editorRef)
    },

    getAppendToElementClass() {
      return this.appendMentionToBody ? 'body' : '.df-Editor'
    }
  },

  watch: {
    modelValue(newValue) {
      if (this.localValue !== this.modelValue) {
        this.localValue = newValue
        this.fixMentions()
        // setContent in fixMentions makes editor focused in safari, avoid this
        // this.blur()
      }
    },

    localValue(newValue) {
      if (this.localValue !== this.modelValue) {
        this.$emit('update:modelValue', newValue)
      }
    },

    readonly() {
      this.editor.setEditable(!this.readonly)
    }
  },

  mounted() {
    this.$el.addEventListener('keydown', this.keyListener)
    this.$el.addEventListener('keyup', this.keyListener)
    this.$el.addEventListener('keypress', this.keyListener)
    unshiftKeydownEventListener('esc', this.onEsc)
    this.fixMentions()
    this.editor = new Editor({
      content: this.localValue,
      editable: !this.readonly,
      editorProps: {
        attributes: {
          class: 'df-EditorContent'
        }
      },

      extensions: [
        StarterKit,
        Image,
        ImageBlock,
        ImageUpload,
        SlashCommand.configure({
          target: '.df-Editor',
          appendTo: this.getAppendToElement,
          attributes: {
            hiddenFeatures: this.hiddenFeatures,
            data: MENU_LIST,
            commands: {
              [TIPTAP_EXTENSION_NAMES.GIFS]: {
                action: () => this.openGifDroplist()
              },
              [TIPTAP_EXTENSION_NAMES.YOUTUBE]: {
                action: () => this.addYouTubeVideo()
              }
            }
          }
        }),
        Youtube.configure({
          controls: false,
          nocookie: true,
          width: YOUTUBE_VIDEO_WIDTH,
          height: YOUTUBE_VIDEO_HEIGHT
        }),
        TaskList,
        TaskItem.configure({
          nested: true
        }),
        Link,
        Subscript,
        Underline,
        Superscript,
        TextStyle,
        Color,
        TiptapTable,
        TiptapTableHeader,
        TiptapTableCell,
        TableRow,
        CharacterCount.configure({
          limit: this.maxLength
        }),
        Placeholder.configure({
          placeholder: this.placeholder
        }),
        Mention.configure({
          HTMLAttributes: {
            class: 'mention'
          },
          renderLabel({ options, node }) {
            return `${options.suggestion.char}${node.attrs.label ?? node.attrs.id}`
          },
          suggestion: {
            items: async ({ query }) => {
              let users = []
              const usersApi = new UsersApi()

              if (!this.active) return
              try {
                users = await usersApi.getUsersForMentioning({
                  searchString: query,
                  workspaceId: this.workspaceId
                })
                this.addUsersFromList(users)
              } catch (error) {
                handleError({ error })
              }
              return users
            },
            render: () => {
              let component
              let popup

              return {
                onStart: props => {
                  if (!props.clientRect) return
                  const clientRect = props.clientRect()
                  // https://tiptap.dev/examples/suggestions
                  if (
                    clientRect.x === 0 &&
                    clientRect.y === 0 &&
                    clientRect.width === 0 &&
                    clientRect.height === 0
                  ) {
                    // something gone wrong, don't display a popup
                    // it's a case when user deletes mention and also quickly
                    // '@' symbol at the beginning, request for mention suggestions
                    // is sent, but response come after mention onExit lifycecle hook
                    // and mention suggestions are shown, however shouldn't
                    return
                  }
                  component = new VueRenderer(MentionList, {
                    props,
                    editor: props.editor
                  })
                  this.editorComponent = component

                  popup = tippy('body', {
                    getReferenceClientRect: props.clientRect,
                    appendTo: this.getAppendToElement,
                    content: component.element,
                    showOnCreate: true,
                    interactive: true,
                    trigger: 'manual',
                    placement: 'bottom-start',
                    theme: `${DROP_LIST_THEMES.LIGHT} ${DROP_LIST_THEMES.NO_PADDING}`,
                    arrow: false
                  })
                  this.editorPopup = popup
                },
                onUpdate(props) {
                  if (!props.clientRect) return

                  if (!component) return

                  component.updateProps(props)
                  if (!(popup && popup[0])) {
                    return
                  }

                  if (popup[0].popperInstance) {
                    popup[0].popperInstance.reference = {
                      clientWidth: 0,
                      clientHeight: 0,
                      getBoundingClientRect() {
                        return props.clientRect()
                      }
                    }
                  }
                },
                onKeyDown: props => {
                  return component?.ref?.onKeyDown(props)
                },
                onExit() {
                  if (popup && popup[0]) {
                    popup[0].destroy()
                  }
                  if (component) {
                    component.destroy()
                  }
                  this.editorPopup = null
                  this.editorComponent = null
                }
              }
            }
          }
        })
      ],

      onUpdate: ({ editor }) => {
        this.$nextTick(() => {
          this.localValue = editor.getHTML()
        })
      },

      onSelectionUpdate: ({ editor }) => {
        const { LEVEL_1, LEVEL_2, LEVEL_3, LEVEL_4, LEVEL_5, LEVEL_6 } = HEADING_LEVELS
        // select value should be updated manually
        const [textFormat] = [
          editor.isActive('heading', { level: LEVEL_1 }) && LEVEL_1,
          editor.isActive('heading', { level: LEVEL_2 }) && LEVEL_2,
          editor.isActive('heading', { level: LEVEL_3 }) && LEVEL_3,
          editor.isActive('heading', { level: LEVEL_4 }) && LEVEL_4,
          editor.isActive('heading', { level: LEVEL_5 }) && LEVEL_5,
          editor.isActive('heading', { level: LEVEL_6 }) && LEVEL_6,
          'paragraph'
        ].filter(Boolean)
        this.textFormat = textFormat
      }
    })
    this.active = this.showOnInit
  },

  beforeUnmount() {
    this.$el.removeEventListener('keydown', this.keyListener)
    this.editor.destroy()
    removeKeydownEventListener('esc', this.onEsc)
  },

  methods: {
    getCurrentTip,
    ...mapActions('objectives', {
      addUsersFromList: 'addUsersFromList',
      getUsersForMentioning: 'getUsersForMentioning'
    }),

    isHidden(featureName) {
      return !this.hiddenFeatures.includes(featureName)
    },

    openGifDroplist() {
      this.$refs.gifDroplistRef.openGifs()
    },

    async replyOnComment(payload) {
      await this.$nextTick()
      if (this.editor) {
        this.editor
          .chain()
          .focus()
          .insertContent({
            type: 'mention',
            attrs: {
              id: payload.accountId,
              label: payload.userName
            }
          })
          .insertContent(' ')
          .run()
      }

      await this.$nextTick()
      this.editor?.commands.focus()
    },

    onClickDescriptionField(e) {
      if (e.target.href) {
        this.editor.commands.blur() // for blur editor when link opening
      } else if (!e.target.href && !this.readonly) {
        this.showEditor()
      }
    },

    execAction(action) {
      this[action]()
    },

    onConfirmSetUrl() {
      if (isStringEmpty(this.url)) {
        return
      }

      let validatedUrl = this.url
      if (!this.url.includes('://')) {
        validatedUrl = 'https://'.concat(this.url)
      }

      this.setLink(validatedUrl)
      this.url = ''
    },

    onConfirmAddYouTubeUrl() {
      if (isStringEmpty(this.url)) {
        return
      }

      let validatedUrl = this.url
      if (!this.url.includes('://')) {
        validatedUrl = 'https://'.concat(this.url)
      }

      this.setYouTubeLink(validatedUrl)
      this.url = ''
    },

    insertTable() {
      // Hardbreak need for create <p> tag before table, its needed because its a lot of difficult to blur the table
      removeSlashCommandShortcut(this.editor)
      insertEmptySpaces(this.editor)

      insertTable(this.editor)
    },

    insertLink() {
      this.showUrlModal = true
    },

    addYouTubeVideo() {
      this.showAddYouTubeModal = true
    },

    setImageUpload() {
      removeSlashCommandShortcut(this.editor)
      insertEmptySpaces(this.editor)

      this.editor.chain().focus().setImageUpload().run()
    },

    addDivider() {
      this.editor.chain().focus().setHorizontalRule().run()
    },

    addTaskList() {
      this.editor.chain().focus().toggleTaskList().run()
    },

    insertCodeSnippet() {
      this.editor.chain().focus().toggleCode().run()
    },

    insertMention() {
      this.editor.chain().focus().insertContent('@').run()
    },

    insertQuote() {
      this.editor.chain().focus().toggleBlockquote().run()
    },

    showEmojiPicker() {
      this.$refs.emojiPickerRef.opened = true
    },

    getColorAttribute(attribute) {
      return this.editor?.getAttributes(attribute)?.color
    },

    onTextColorChange(value) {
      if (this.getColorAttribute('textStyle') === value) {
        this.editor.chain().focus().unsetColor().run()
      } else {
        this.editor.chain().focus().setColor(value).run()
      }
      this.$refs.textColorPicker.hideDropdown()
    },

    showEditor() {
      if (!this.readonly) {
        this.active = true
        this.focus()
      }
    },

    onCancel() {
      this.onEsc({ focus: false })
      this.$emit('cancel')
      this.active = false
    },

    onSave() {
      this.$emit('save')
      if (this.blurOnSave) {
        this.blur()
      }
    },

    setLink(url) {
      this.editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run()
      this.showUrlModal = false
    },

    setYouTubeLink(url) {
      this.editor.commands.setYoutubeVideo({
        src: url,
        width: YOUTUBE_VIDEO_WIDTH,
        height: YOUTUBE_VIDEO_HEIGHT
      })
      this.showAddYouTubeModal = false
    },

    onMoreFormatChange(option, onSelect) {
      const { value } = option
      onSelect(value)
      if (value === MORE_FORMATS_ACTIONS.CLEAR) {
        this.editor.chain().focus().clearNodes().unsetAllMarks().run()
      } else {
        if (value === MORE_FORMATS_ACTIONS.SUBSCRIPT && this.editor.isActive('superscript')) {
          this.editor.chain().focus().unsetSuperscript().run()
        } else if (
          value === MORE_FORMATS_ACTIONS.SUPERSCRIPT &&
          this.editor.isActive('subscript')
        ) {
          this.editor.chain().focus().unsetSubscript().run()
        }
        this.editor.chain().focus()[`toggle${value}`]().run()
      }
    },

    insertEmoji(value) {
      this.editor.commands.insertContent(value)
      this.editor.chain().focus()
    },

    setTextFormat(option, onSelect) {
      const { value } = option
      this.textFormat = value

      onSelect(option)
      if (value === 'paragraph') {
        this.editor.chain().focus().setParagraph().run()
      }
      this.editor.chain().focus().toggleHeading({ level: value }).run()
    },

    /** @public */
    focus() {
      this.active = true
      this.$nextTick(() => setTimeout(() => this.editor.chain().focus().run(), 100))
    },

    blur() {
      this.active = false
      this.$nextTick(() =>
        setTimeout(() => {
          this.editor.chain().blur().run()
        }, 100)
      )
    },

    async fixMentions() {
      let content = this.localValue
      const accountIds = getIdsInMentions(content)
      const users = await this.getUsersForMentioning({
        accountIds,
        workspaceId: this.workspaceId
      })
      content = resolveMentions(content, accountIds, users)
      this.editor?.commands.setContent(content, false)
    },

    onEsc() {
      let closed = false
      if (this.editorPopup && this.editorPopup[0]) {
        this.editorPopup[0].destroy()
        closed = true
        this.editorPopup = null
      }
      if (this.editorComponent) {
        this.editorComponent.destroy()
        closed = true
        this.editorComponent = null
      }

      if (!closed) {
        // blur manually instead of blur method because text editor should be blurred
        // only and kept active
        this.editor.commands.blur()
      }

      if (closed) {
        return false
      } else if (this.active) {
        this.$emit('cancel')
        this.active = false
        return false
      }
    },

    keyListener(event) {
      /* Jira Server has own shortcuts, and they raise even in editor. Prevent this behavior. */
      // duplicate keyCode with key, because in keypress(at least in Firefox 93) keyCode is always 0
      event.stopPropagation()
      event.stopImmediatePropagation()
    }
  }
})
</script>

<style lang="scss" scoped>
// eslint-disable-next-line vue-scoped-css/no-unused-selector
%field-hover {
  &:hover {
    &:not(.df-Editor-readonly &) {
      &:after {
        cursor: text;
        background-color: $grey-3-next;
      }
    }
  }
  &:not(.df-Editor-active &) {
    &:after {
      display: block;
      content: '';
      position: absolute;
      height: 100%;
      left: -10px;
      top: 0;
      border-radius: $border-radius-sm-next;
      z-index: 1;
      width: calc(100% + 20px);
      pointer-events: none;
    }
  }
}

.df-Editor {
  display: flex;
  flex-direction: column;
  background-color: $white;
  overflow-wrap: break-word;
  border-radius: $border-radius-md-next;

  &-active {
    padding: 10px;
    border: 2px solid $grey-3-next;
  }
}

.df-Content {
  display: flex;
  flex: 1 1 auto;
  height: min-content;
  border-radius: $border-radius-md;
  position: relative;
  cursor: text;

  @extend %field-hover;

  .df-Editor-styled & {
    padding: 12px 0;
  }

  .df-Editor-active & {
    padding: 0 10px 15px;
  }

  .df-Editor-active:hover & {
    background-color: transparent;
    cursor: initial;
  }
}

.df-ActionsAndCounter {
  display: flex;
  justify-content: space-between;
  margin: 8px 0;
}

.df-Actions {
  display: flex;

  &_Item {
    margin-right: 8px;
  }
}

.df-Toolbar {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 16px;
  gap: 7px;
  position: sticky;
  top: 5px;
  left: 0;
  background: $white;
  z-index: 3;
}

.df-ToolbarSplitter {
  width: 1px;
  height: 24px;
  background-color: $grey-medium;
}

.df-Placeholder {
  color: $dark-3;
  padding: 12px 0;
  border-radius: $border-radius-md-next;
  position: relative;
  cursor: text;

  @extend %field-hover;

  &_Text {
    position: relative;
    z-index: 5;
  }
}

.df-DescriptionCounter {
  display: flex;
  align-self: center;
  justify-content: flex-end;
  font-weight: fw('regular');
  font-size: $fs-12;
  line-height: 16px;
  font-family: $system-ui;
  color: $grey-1-next;

  &-negative {
    color: $grade-low-color-next;
  }
}
</style>

<style lang="scss">
@import '~@/assets/styles/mixins';

.df-Editor {
  &-readonly {
    [data-type='image-upload'] {
      display: none;
    }
  }
}
.df-EditorContent {
  width: 100%;
  word-break: break-word;
  position: relative;
  z-index: 2;
  &:not(.df-Editor-active &) {
    cursor: text;

    ul[data-type='taskList'] {
      label {
        pointer-events: none;
      }
    }
  }

  ul {
    padding-left: 18px;
    margin-bottom: 0;
  }

  ol {
    padding-left: 16px;
    margin: 0;
  }
  p[data-placeholder] {
    caret-color: $primary-color-next;
    &.is-editor-empty:first-child::before {
      color: $grey-1-next;
    }
  }

  p {
    margin-bottom: 0;
  }

  * + p {
    margin-bottom: 0;
    margin-top: 0.75rem;
  }

  p.is-editor-empty:first-child::before {
    content: attr(data-placeholder);
    float: left;
    color: $grey-10;
    pointer-events: none;
    height: 0;
  }

  span.mention {
    @extend %mention-item;
  }
}

.df-DescriptionField {
  margin-bottom: 8px;
  font-family: $system-ui;

  .df-TextFormats {
    min-width: 125px;

    .df-TextFormats_DroplistButton {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: transparent;
      border-color: transparent;
      min-height: 32px;
      color: $dark-grey;
      font-weight: fw('semi-bold');
      border-radius: 6px;
      padding: 4px 4px 4px 12px;
      font-family: $system-ui;
      cursor: pointer;
      &:hover {
        background-color: $grey-3-next;
      }

      .df-DroplistButton_Text {
        color: $dark-grey;
      }
      .df-DroplistButton_Icon {
        transition: transform $transition-fast ease;
      }
      &-active {
        background: $dark-2;
        color: $white;
        .df-DroplistButton_Icon {
          transform: rotate(180deg);
        }
        &:hover {
          @include hoverState($dark-2);
        }
        .df-DroplistButton_Text {
          color: $white;
        }
      }
      &-focused {
        background: $dark-grey;
        color: $white;

        .as-AppDroplistButton_Text {
          color: $white;
        }

        &:hover {
          border-color: transparent;
          background: $dark-grey;
        }
      }
    }
  }
}

.ProseMirror blockquote,
.ci-Message blockquote {
  padding: 10px 20px 10px 16px;
  border-left: 2px solid $grey-11;
}

.ProseMirror blockquote {
  margin: 14px;
}

.ci-Message blockquote {
  margin: 0 0 4px;
}

.df-ColorTextWrapper {
  position: relative;

  .df-ColorTextWrapper_Marker {
    position: absolute;
    left: 3px;
    right: 0;
    bottom: 3px;
    margin: 0 0 0 2px;
    width: 11px;
    height: 3px;
    border-radius: 3px;
    pointer-events: none;
    background: linear-gradient(to right, #172b4d, #36b27e, #0052cc, #f64963);
  }
}

.df-ColorTextWrapper_OptionWrapper {
  border: 2px solid transparent;
  margin: 0;
  display: flex;
  align-items: center;
  border-radius: 10px;

  &:hover {
    border-color: $grey-2-next;
  }
}

.df-ColorTextWrapper_Option {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $black;
  height: 22px;
  width: 22px;
  border-radius: $border-radius-md;
  border: 1px solid rgba(23, 43, 77, 0.12);
  cursor: pointer;
}

.df-ColorText_ContentOptions {
  .as-UngroupedItems {
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
    gap: 2px;
    justify-content: space-between;
  }
}

.tableWrapper {
  overflow-x: auto;
}

.df-SimpleViewList {
  display: flex;
  align-items: center;
  gap: 8px;
}

.df-TextFormats_Heading {
  padding: 12px 10px;
  cursor: pointer;

  &:hover,
  &-active {
    background-color: rgba($primary-color-next, 0.15);
  }

  &-disabled {
    pointer-events: none;
    background-color: transparent;
    color: $grey-semi-medium;

    &:active {
      cursor: not-allowed;
    }
  }

  &-paragraph {
    @include setHeading($fs-14, 20px, fw('regular'));
  }

  &-1 {
    @include setHeading($fs-24, 28px);
  }

  &-2 {
    @include setHeading($fs-20, 28px);
  }

  &-3 {
    @include setHeading($fs-18, 20px);
  }

  &-4 {
    @include setHeading($fs-16, 20px);
  }

  &-5 {
    @include setHeading($fs-14, 20px);
  }

  &-6 {
    @include setHeading($fs-12, 16px, fw('bold'));
    color: $grey-semi-medium;
  }
}

.df-Toolbar_LinkWrapper {
  padding: 20px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.df-LinkWrapper {
  &_Input {
    min-width: 460px;
  }
}
</style>

<style lang="scss">
@import '~@/assets/styles/tiptap-styles';
</style>
