<template>
  <div class="ocwt-Loader">
    <div class="ocwt-Loader_Chart">
      <SkeletonItem width="100%" height="100%" />
    </div>

    <div class="ocwt-Loader_Toggle">
      <SkeletonItem width="265px" border-radius="6px" />
      <SkeletonItem width="275px" border-radius="6px" />
    </div>

    <div class="ocwt-Loader_Table">
      <div class="ocwt-Loader_TableHead">
        <div class="ocwt-LoaderCell">
          <SkeletonItem width="6px" height="6px" :appearance="CIRCLE" />
        </div>
        <div class="ocwt-LoaderCell">
          <SkeletonItem :size="XS" :appearance="SQUARE" />
        </div>
        <div class="ocwt-LoaderCell">
          <SkeletonItem width="38px" :size="XS" />
        </div>
        <div v-for="n in REST_CELLS_COUNT" :key="`head-cell-${n}`" class="ocwt-LoaderCell">
          <SkeletonItem width="52px" :size="XS" />
        </div>
      </div>
      <div class="ocwt-Loader_TableRow">
        <div class="ocwt-LoaderCell">
          <SkeletonItem width="6px" height="6px" :appearance="CIRCLE" />
        </div>
        <div class="ocwt-LoaderCell">
          <SkeletonItem :size="XS" :appearance="SQUARE" />
        </div>
        <div class="ocwt-LoaderCell">
          <SkeletonItem :size="SM" :appearance="SQUARE" />
          <SkeletonItem width="50px" :size="XS" />
        </div>
        <div v-for="n in REST_CELLS_COUNT" :key="`row-cell-${n}`" class="ocwt-LoaderCell">
          <SkeletonItem width="16px" :size="XS" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'OkrChartWithTableLoader'
})
</script>

<script setup>
import {
  SKELETON_APPEARANCES,
  SKELETON_SIZES
} from '@/utils/components-configurations/skeleton-item'

import SkeletonItem from '@/components/ui/SkeletonLoaders/SkeletonItem'

const { XS, SM } = SKELETON_SIZES
const { SQUARE, CIRCLE } = SKELETON_APPEARANCES

const REST_CELLS_COUNT = 5
</script>

<style scoped lang="scss">
.ocwt-Loader_Chart {
  margin-top: 10px;
  padding: 0 28px 0 46px;
  height: 266px;
}

.ocwt-Loader_Toggle {
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 52px 0 24px;
}

.ocwt-Loader_Table {
  overflow: hidden;
}

.ocwt-Loader {
  &_TableHead,
  &_TableRow {
    display: grid;
    grid-template-columns: 24px 24px 276px repeat(5, 1fr);
    align-items: center;
  }

  &_TableHead {
    height: 32px;
  }

  &_TableRow {
    height: 46px;
  }
}

.ocwt-LoaderCell {
  display: flex;
  &:nth-child(1) {
    justify-content: center;
  }
  &:nth-child(2) {
    justify-content: center;
  }
  &:nth-child(3) {
    gap: 8px;
    align-items: center;
    padding-left: 8px;
  }
  &:nth-child(n + 4) {
    justify-content: flex-start;
    padding-left: 20px;
  }
}
</style>
