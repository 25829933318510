<template>
  <CustomFieldNumberInput
    :key="fieldId"
    :field-id="fieldId"
    :field-type-id="fieldTypeId"
    :model-value="modelValue"
    @update:model-value="emit('update:model-value', $event)"
  />
</template>

<script setup>
import CustomFieldNumberInput from '@/components/objectives/bulk-edit/custom-fields/CustomFieldNumberInput'

defineOptions({
  name: 'NumberField',
  inheritAttrs: false
})

defineProps({
  modelValue: {
    type: Object,
    required: true
  },

  fieldId: {
    type: [String, Number],
    required: true
  },

  fieldTypeId: {
    type: [String, Number],
    required: true
  }
})

const emit = defineEmits({
  'update:model-value': null
})
</script>

<style lang="scss" scoped></style>
