<template>
  <FormFieldNext class="ki-FormField">
    <template #label>
      <i18n-t keypath="delete_confirm_interval_input_label" scope="global">
        <template #keyword>
          <CopyableText>
            {{ keyword }}
          </CopyableText>
        </template>
      </i18n-t>
    </template>
    <AppInput
      v-model.uppercase.trim="modelValue"
      :data-testid="$attrs['input-data-testid'] || 'keyword-input'"
      :is-error="showKeywordErrors"
      :max-length="maxLength"
      data-auto-testid="keyword-input"
      size="xlg"
      style-type="primary"
      @focus="isKeywordFieldValid = true"
    />
    <AppFieldError
      v-if="showKeywordErrors"
      :data-testid="$attrs['error-message-data-testid'] || 'keyword-error'"
      :show="showKeywordErrors"
      class="dm-FieldError"
    >
      {{ t(errorText) }}
    </AppFieldError>
  </FormFieldNext>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import AppFieldError from '@/components/form/AppFieldError'
import FormFieldNext from '@/components/form/FormFieldNext'
import AppInput from '@/components/ui/AppInput/AppInput'
import CopyableText from '@/components/ui/CopyableText'

defineOptions({
  name: 'KeywordInput'
})

const props = defineProps({
  keyword: {
    type: String,
    required: true
  }
})

const { t } = useI18n()

const modelValue = ref('')
const isKeywordFieldValid = ref(true)

const showKeywordErrors = computed(() => !isKeywordFieldValid.value)

const errorText = computed(() => {
  return modelValue.value ? 'field.interval_wrong_keyword' : 'field.required'
})

const validate = () => {
  isKeywordFieldValid.value = modelValue.value === props.keyword
  return isKeywordFieldValid.value
}

defineExpose({
  validate
})

const maxLength = computed(() => {
  return props.keyword.length
})
</script>

<style lang="scss" scoped>
.ki-FormField {
  --gap: 0;
  --label-bottom-offset: 6px;
  position: relative;
  --color: #{$dark-3};
}
</style>
