<template>
  <AppDialog
    :closeable="!isEdit"
    :show="show"
    class="com-Dialog"
    hide-hero
    size="full-page"
    @on-close="onCancel"
  >
    <StepBullets :current-step="1" :total-count="1" />
    <div :class="{ 'com-ModalContent-edit': isEdit }" class="com-ModalContent">
      <TitleWithAction> {{ t('organization.create_title') }}</TitleWithAction>

      <FormFieldNext :label="$t('organization.name.label')" class="com-ModalContent_FormField">
        <AppInput
          v-model.trim="name"
          :is-error="Boolean(errorText)"
          :max-length="50"
          blur-on-enter
          size="xlg"
          style-type="primary"
          @focus="removeNameErrors"
        />
        <AppFieldError v-if="Boolean(errorText)" :show="Boolean(errorText)">
          {{ $t(errorText) }}
        </AppFieldError>
      </FormFieldNext>

      <AppInfoMessage>
        <i18n-t keypath="organization.create_message">
          <template #break>
            <br />
          </template>
        </i18n-t>
      </AppInfoMessage>

      <AppDivider class="com-Divider" no-margin />
    </div>

    <template #footer>
      <div class="com-ModalFooter">
        <AppButton v-if="!isEdit" :loading="loading" type="ghost-next" @click="onCancel">
          {{ t('action.cancel') }}
        </AppButton>
        <AppButton
          :loading="loading"
          class="com-ConfirmButton"
          icon-after="chevron-right-next"
          type="primary-next"
          @click="onContinue"
        >
          {{ t('action.continue') }}
        </AppButton>
      </div>
    </template>
  </AppDialog>
</template>

<script setup>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

import WebAppOrganizationApiHandler from '@/api/web-app-organization'
import { handleError } from '@/utils/error-handling'
import { useReloadPage } from '@/utils/reload-page'
import { useOrganizationName } from '@/utils/web-app/organization-helpers'

import AppDialog from '@/components/AppDialog'
import AppFieldError from '@/components/form/AppFieldError'
import FormFieldNext from '@/components/form/FormFieldNext'
import TitleWithAction from '@/components/objectives/TitleWithAction'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppDivider from '@/components/ui/AppDivider'
import AppInfoMessage from '@/components/ui/AppInfoMessage'
import AppInput from '@/components/ui/AppInput/AppInput'
import StepBullets from '@/components/ui/StepBullets/StepBullets'

defineOptions({
  name: 'CreateOrganizationModal'
})

const props = defineProps({
  isEdit: {
    type: Boolean
  },

  show: {
    type: Boolean,
    default: true
  }
})

const emit = defineEmits({
  'close-create-organization': null
})

const { t } = useI18n()

const loading = ref(false)

const { reloadPage } = useReloadPage()

const createOrganization = async () => {
  loading.value = true
  const api = new WebAppOrganizationApiHandler()

  try {
    const { iss } = await api.createOrganization({ name: name.value })
    await api.switchOrganization({ iss })
    emit('close-create-organization')
    reloadPage()
  } catch (error) {
    handleError({ error })
  } finally {
    loading.value = false
  }
}

const store = useStore()
const renameOrganization = async () => {
  loading.value = true
  const api = new WebAppOrganizationApiHandler()

  try {
    const organization = await api.renameOrganizationAndRootGroup({ name: name.value.trim() })
    await store.dispatch('appContext/updateOrganization', organization)
    await store.dispatch('system/fetchInitialWorkspaces')
    emit('close-create-organization')
  } catch (error) {
    handleError({ error })
  } finally {
    loading.value = false
  }
}

const { name, isNameValid, isNameLengthValid, validateName, removeNameErrors, errorText } =
  useOrganizationName()

const onContinue = () => {
  validateName()

  if (isNameValid.value && isNameLengthValid.value) {
    if (props.isEdit) {
      renameOrganization()
    } else {
      createOrganization()
    }
  }
}

const onCancel = () => {
  emit('close-create-organization')
  removeNameErrors()

  if (!props.isEdit) {
    name.value = ''
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/wizard-steps';

.com-Dialog {
  --dialog-content-padding-top: 80px;
  --bulltes-width: var(--step-max-width);
  --step-max-width: #{$step-max-width};
}

.com-ModalContent {
  @extend %wizard-step-styles;
  padding-top: 36px;

  &-edit {
    padding-top: 60px;
  }
}

.com-ModalContent_FormField {
  width: 100%;
}

.com-ModalFooter {
  display: flex;
  justify-content: flex-end;
  width: $step-max-width;
  margin-inline: auto;
  gap: 8px;
}

.com-Divider {
  width: 100%;
}

.com-ConfirmButton {
  --button-low-padding: 4px;
}
</style>
