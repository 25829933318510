<template>
  <div
    ref="row"
    :class="classes"
    :style="{
      '--background-color': colors.color,
      '--rgb-background-color': colors.RGBColor,
      '--explanation-bg': colors.explanationBg
    }"
    class="ot-ListItem"
    @click="onClick"
    @mouseout="onMouseOut"
    @mouseover="onMouseOver"
  >
    <AppButton
      v-show="!showGhostTimeline && showNavButtons.left"
      v-tippy="{
        content: tooltipContent.startDate,
        placement: 'right'
      }"
      class="otl-NavButton otl-NavButton-left"
      height="16"
      icon="chevron-left"
      remove-padding
      size="xss"
      type="modern"
      width="16"
      @click="onNavigationClick"
    />

    <OkrTimeline
      ref="timeline"
      v-model:show-nav-buttons="showNavButtons"
      :active-view="activeView"
      :child-list-height="childListHeight"
      :depth="depth"
      :expanded="expanded"
      :is-auto-period-mode="isAutoPeriodMode"
      :min-max-date="minMaxDate"
      :objective="objective"
      :one-day-width="oneDayWidth"
      :show-ghost-timeline="showGhostTimeline"
      @on-okr-element-updated="$emit('on-okr-element-updated', $event)"
      @on-timeline-interacted="timelineInteracted = $event"
      @edit-okr-element="$emit('edit-okr-element', $event)"
      @update-depended-elements="updateDependedElements"
      @update-changed-elements="updateChangedElements"
    />

    <AppButton
      v-show="!showGhostTimeline && showNavButtons.right"
      v-tippy="{
        content: tooltipContent.endDate,
        placement: 'left'
      }"
      class="otl-NavButton otl-NavButton-right"
      height="16"
      icon="chevron-right"
      remove-padding
      size="xss"
      type="modern"
      width="16"
      @click="onNavigationClick(false)"
    />
  </div>
  <OkrTimelinesList
    v-if="expanded"
    ref="list"
    v-model:child-list-height="childListHeight"
    :active-view="activeView"
    :depth="depth + 1"
    :min-max-date="minMaxDate"
    :model-value="childIds"
    :one-day-width="oneDayWidth"
    @edit-okr-element="$emit('edit-okr-element', $event)"
    @update-elements="$emit('update-elements', $event)"
  />
</template>

<script>
import dayjs from 'dayjs'
import { defineAsyncComponent, defineComponent } from 'vue'
import { mapGetters } from 'vuex'

import { listStateInjectionKey, roadmapStateInjectionKey } from '@/utils/injection-keys'
import { memoizeExplanationBg, memoizeHexToRgb } from '@/utils/memoizations'
import {
  currentUserCanUpdateObjective,
  filteredObjectivesToDisplay,
  isOkrElementClosed,
  objectiveIsJiraTask,
  saveUpdatedElementParameters,
  updateDependedElements
} from '@/utils/objectives'
import { isManualPeriodModeEnabled } from '@/utils/okr-element-dates'
import { ISSUE_TYPES, ROADMAP_DATE_FORMAT } from '@/utils/roadmap'

import OkrTimeline from '@/components/objectives/roadmap/OkrTimeline'
import AppButton from '@/components/ui/AppButton/AppButton'

const {
  STORY,
  TASK,
  BUG,
  EPIC,
  SUBTASK,
  SUB_TASK,
  ORC_BUG,
  ORC_EPIC,
  ORC_TASK,
  ORC_STORY,
  ORC_SUBTASK
} = ISSUE_TYPES

const WITHOUT_ICON_TYPE = {
  withoutIcon: '#2684FF'
}

const CLOUD_STORY_COLOR = '#63ba3c'
const CLOUD_TASK_COLOR = '#4bade8'
const CLOUD_EPIC_COLOR = '#904ee2'
const CLOUD_BUG_COLOR = '#e5493a'

const CLOUD_ISSUE_COLORS = {
  [STORY]: CLOUD_STORY_COLOR,
  [ORC_STORY]: CLOUD_STORY_COLOR,
  [TASK]: CLOUD_TASK_COLOR,
  [ORC_TASK]: CLOUD_TASK_COLOR,
  [SUBTASK]: CLOUD_TASK_COLOR,
  [ORC_SUBTASK]: CLOUD_TASK_COLOR,
  [SUB_TASK]: CLOUD_TASK_COLOR,
  [EPIC]: CLOUD_EPIC_COLOR,
  [ORC_EPIC]: CLOUD_EPIC_COLOR,
  [BUG]: CLOUD_BUG_COLOR,
  [ORC_BUG]: CLOUD_BUG_COLOR,
  ...WITHOUT_ICON_TYPE
}

const SERVER_STORY_COLOR = '#36b37e'
const SERVER_EPIC_COLOR = '#6554c0'
const SERVER_BUG_COLOR = '#ff5630'

const SERVER_ISSUE_COLORS = {
  [STORY]: SERVER_STORY_COLOR,
  [ORC_STORY]: SERVER_STORY_COLOR,
  [TASK]: WITHOUT_ICON_TYPE.withoutIcon,
  [ORC_TASK]: WITHOUT_ICON_TYPE.withoutIcon,
  [SUBTASK]: WITHOUT_ICON_TYPE.withoutIcon,
  [ORC_SUBTASK]: WITHOUT_ICON_TYPE.withoutIcon,
  [EPIC]: SERVER_EPIC_COLOR,
  [ORC_EPIC]: SERVER_EPIC_COLOR,
  [BUG]: SERVER_BUG_COLOR,
  [ORC_BUG]: SERVER_BUG_COLOR,
  [SUB_TASK]: WITHOUT_ICON_TYPE.withoutIcon,
  ...WITHOUT_ICON_TYPE
}

export default defineComponent({
  name: 'OkrTimelinesListItem',
  components: {
    AppButton,
    OkrTimelinesList: defineAsyncComponent(() =>
      import('@/components/objectives/roadmap/OkrTimelinesList')
    ),

    OkrTimeline
  },

  inject: {
    listState: {
      from: listStateInjectionKey
    },

    roadmapState: {
      from: roadmapStateInjectionKey
    }
  },

  props: {
    objective: {
      type: Object,
      required: true
    },

    expanded: {
      type: Boolean
    },

    depth: {
      type: Number,
      required: true
    },

    oneDayWidth: {
      type: Number,
      required: true
    },

    minMaxDate: {
      type: Object,
      required: true
    },

    activeView: {
      type: String,
      required: true
    }
  },

  emits: {
    'on-okr-element-updated': null,
    'edit-okr-element': null,
    'update-elements': null
  },

  data() {
    return {
      timelineInteracted: false,
      childListHeight: 0,
      showNavButtons: {
        left: false,
        right: false
      }
    }
  },

  computed: {
    ...mapGetters('pluginOptions', {
      isPluginServer: 'isPluginServer'
    }),

    classes() {
      return {
        'ot-ListItem-withGhostTimeline': this.showGhostTimeline && this.userCanUpdateObjective,
        'ot-ListItem-interacted': this.timelineInteracted,
        'ot-ListItem-filteredOut': this.filteredOut,
        'ot-ListItem-disabled': isOkrElementClosed(this.objective)
      }
    },

    colors() {
      const { levelColor } = this.objective
      const color = `#${levelColor}`
      if (this.isJiraIssue) {
        const { issueIcon } = this.objective
        const colorsByType = this.isPluginServer ? SERVER_ISSUE_COLORS : CLOUD_ISSUE_COLORS

        const issueColor = issueIcon
          ? colorsByType[this.objective.issueType] || color
          : colorsByType.withoutIcon
        return {
          color: issueColor,
          RGBColor: memoizeHexToRgb(issueColor)
        }
      } else {
        const RGBColor = memoizeHexToRgb(color)
        return {
          color,
          RGBColor,
          explanationBg: memoizeExplanationBg(RGBColor, this.isAutoPeriodMode)
        }
      }
    },

    filteredOut() {
      return !this.objective.fitFilterCriteria
    },

    userCanUpdateObjective() {
      return currentUserCanUpdateObjective(this.objective)
    },

    showFilteredChildren() {
      return this.listState.showFilteredChildrenObjectives[this.objective.uniqueId] || false
    },

    childIds() {
      const allChildren = this.listState.okrElementChildren[this.objective.uniqueId] || []
      return this.showFilteredChildren
        ? allChildren
        : filteredObjectivesToDisplay(allChildren, this.listState)
    },

    isJiraIssue() {
      return objectiveIsJiraTask(this.objective)
    },

    isAutoPeriodMode() {
      return (
        !this.isJiraIssue &&
        !isManualPeriodModeEnabled(this.objective.startDateManual, this.objective.dueDateManual)
      )
    },

    showGhostTimeline() {
      if (this.isAutoPeriodMode) {
        return false
      }
      return Boolean(!this.objective.dueDate && !this.objective.elementStartDate)
    },

    tooltipContent() {
      const { dueDate, elementStartDate, intervalStartDate, intervalEndDate } = this.objective
      const dueDateSelected = Boolean(this.objective.dueDate)
      const startDateSelected = Boolean(this.objective.elementStartDate)
      const startDate = startDateSelected ? elementStartDate : intervalStartDate
      const endDate = dueDateSelected ? dueDate : intervalEndDate
      return {
        startDate: dayjs(startDate).format(ROADMAP_DATE_FORMAT),
        endDate: dayjs(endDate).format(ROADMAP_DATE_FORMAT)
      }
    }
  },

  watch: {
    timelineInteracted(newValue) {
      this.roadmapState.someTimelineInteracted = newValue
    }
  },

  methods: {
    /** @public */
    refreshPositions() {
      this.$refs.timeline.refreshPositions()
      this.$refs.list?.refreshPositions()
    },

    onMouseOver() {
      if (this.userCanUpdateObjective) {
        this.$refs.timeline.onRowMouseOver()
      }
    },

    onMouseOut(e) {
      if (this.showGhostTimeline && this.userCanUpdateObjective) {
        this.$refs.timeline.onRowMouseOut()
      }
      if (!this.showGhostTimeline) {
        const isOveredOnTimeline =
          e.toElement &&
          (e.toElement.classList.contains('ot-Timeline') ||
            e.toElement.classList.contains('ot-Timeline_Resizer'))
        if (!isOveredOnTimeline) {
          this.$refs.timeline.onRowMouseOut()
        }
        if ((e.toElement && e.toElement.parentNode !== this.$refs.row) || !e.toElement) {
          this.hideNavButtons()
        }
      }
    },

    hideNavButtons() {
      this.showNavButtons.left = false
      this.showNavButtons.right = false
    },

    onClick(e) {
      if (this.showGhostTimeline && this.userCanUpdateObjective) {
        this.$refs.timeline.onRowClick(e)
      }
    },

    onNavigationClick(isLeftSide = true) {
      this.$refs.timeline.scrollToSide(isLeftSide)
      this.hideNavButtons()
    },

    updateDependedElements(elements) {
      updateDependedElements(this.listState, elements)
    },

    updateChangedElements(updatedElementParameters) {
      saveUpdatedElementParameters(this.listState, updatedElementParameters)
    }
  }
})
</script>

<style lang="scss" scoped>
.ot-ListItem {
  position: relative;
  height: 45px;
  border-bottom: 1px solid $grey-2-next;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &-interacted {
    overflow: hidden;
  }

  &-withGhostTimeline {
    overflow: hidden;
    cursor: pointer;
  }

  &-filteredOut {
    &:not(.ot-ListItem-withGhostTimeline) {
      opacity: 0.5;
    }
  }

  &-disabled {
    cursor: not-allowed;
  }
}

.otl-NavButton {
  position: sticky;
  top: 0;
  height: 20px;
  z-index: 2;
  color: $white;
  background-color: rgba($dark-1, 0.2);
  &:deep(.svg-icon) {
    pointer-events: none;
  }

  &-left {
    left: 12px;
  }

  &-right {
    // margin-left: auto;
    right: 12px;
  }
}
</style>
