import ApiHandler from '@/api/base'
import { CONNECTION_STATUSES } from '@/utils/web-app/connections'

class WebAppConnectionsApiHandler extends ApiHandler {
  async getIntegrationToken() {
    const { data } = await this.http.post('settings/integration/createToken')
    return data
  }

  async setupConnection({ token }) {
    return this.http.post('settings/integration/connect', {
      token
    })
  }

  async getConnectionDisconnectionStatusLongPolling({ timeout = 2000, requestUrl = '' } = {}) {
    if (!requestUrl) {
      throw new Error('requestUrl is required')
    }

    const { data } = await this.http.get(requestUrl)

    const { statusId, error } = data

    return new Promise((resolve, reject) => {
      try {
        if (
          [
            CONNECTION_STATUSES.CONNECTION_PENDING,
            CONNECTION_STATUSES.DISCONNECTION_PENDING
          ].includes(statusId)
        ) {
          setTimeout(() => {
            this.getConnectionDisconnectionStatusLongPolling({ timeout, requestUrl }).then(resolve)
          }, timeout)
        } else {
          resolve({ statusId, error })
        }
      } catch (error) {
        reject(error)
      }
    })
  }

  async getDisconnectionStatusLongPolling({ timeout = 2000 } = {}) {
    return this.getConnectionDisconnectionStatusLongPolling({
      timeout,
      requestUrl: '/settings/integration/connectionStatus'
    })
  }

  async getConnectionStatusLongPolling({ timeout = 2000 } = {}) {
    return this.getConnectionDisconnectionStatusLongPolling({
      timeout,
      requestUrl: '/settings/integration/connectionStatus'
    })
  }

  async disconnect() {
    return this.http.post('settings/integration/disconnect')
  }
}

export default WebAppConnectionsApiHandler
