export const NAME_FIELD_TEST_ID = 'name-field'
export const FREQUENCY_FIELD_TEST_ID = 'frequency-field'
export const START_DATE_FIELD_TEST_ID = 'start-from-field'
export const REPEAT_INTERVAL_FIELD_TEST_ID = 'repeat-interval-field'
export const REPEAT_UNIT_FIELD_TEST_ID = 'repeat-unit-field'
export const WEEKDAY_FIELD_TEST_ID = 'weekday-field'
export const NTH_WEEKDAY_FIELD_TEST_ID = 'nth-weekday-field'
export const WEEKDAYS_FIELD_TEST_ID = 'weekdays-field'
export const REMINDER_EXECUTION_DAY_FIELD_TEST_ID = 'reminder-execution-day-field'
export const REMINDER_EXECUTION_TIME_FIELD_TEST_ID = 'reminder-execution-time-field'
export const REMINDER_TIMEZONE_FIELD_TEST_ID = 'reminder-timezone-field'
export const NOTIFICATION_CHANNEL_TOGGLE_TEST_ID = 'notification-channel-toggle'
export const NOTIFICATION_WEBHOOK_FIELD_TEST_ID = 'notification-webhook-field'
export const NOTIFICATION_WEBHOOK_FIELD_WRAPPER_TEST_ID = 'notification-webhook-field-wrapper'
export const PARTICIPANTS_STRATEGY_FIELD_TEST_ID = 'participants-strategy'
export const EXCLUDED_GROUPS_FIELD_TEST_ID = 'excluded-groups-field'
export const EXCLUDED_USERS_FIELD_TEST_ID = 'excluded-users-field'
export const GROUPS_FIELD_TEST_ID = 'groups-field'
export const USERS_FIELD_TEST_ID = 'users-field'
export const ADD_FILTER_BUTTON_TEST_ID = 'add-filter-button'
export const REMOVE_FILTER_BUTTON_TEST_ID = 'remove-filter-button'
export const FILTERS_TABLE_TEST_ID = 'filters-table'
