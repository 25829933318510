<template>
  <div class="oee-Empty">
    <ObjectivesEmptyHero v-if="!hideHero" />

    <div class="oee-Empty_Title">
      <slot>
        {{ $t('filter.no_objective_jira_dashboard') }}
      </slot>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

import ObjectivesEmptyHero from '@/components/objectives/ObjectivesEmptyHero'

export default defineComponent({
  name: 'OkrElementsEmpty',
  components: { ObjectivesEmptyHero },
  props: {
    hideHero: {
      type: Boolean
    }
  }
})
</script>

<style lang="scss" scoped>
.oee-Empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  border: 2px solid $grey-2-next;
  box-sizing: border-box;
  border-radius: $border-radius-md-next;
  padding: 18px 20px 17px;
  text-align: center;
  font-style: normal;
  font-weight: fw('regular');
  font-size: $fs-14;
  line-height: 20px;
  font-family: $system-ui;
  color: $dark-3;
}
</style>
