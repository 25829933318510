import { utcDateToLocal } from '@/utils/date'

export const hideDueDateFooter = (intervalEndDate, elementStartDate) => {
  return Boolean(
    elementStartDate &&
      utcDateToLocal(new Date(elementStartDate)).getTime() >
        utcDateToLocal(new Date(intervalEndDate)).getTime()
  )
}

export const hideStartDateFooter = (intervalStartDate, elementDueDate) => {
  return Boolean(
    elementDueDate &&
      utcDateToLocal(new Date(elementDueDate)).getTime() <
        utcDateToLocal(new Date(intervalStartDate)).getTime()
  )
}

export const getSelectedIntervalDates = OKRData => {
  const { intervalStartDate, intervalEndDate, automaticElementStartDate, automaticDueDate } =
    OKRData
  return {
    intervalStartDate: intervalStartDate || automaticElementStartDate,
    intervalEndDate: intervalEndDate || automaticDueDate
  }
}

export const getMinMaxDates = (selectedIntervalDates, elementStartDate, elementDueDate) => {
  const { intervalStartDate, intervalEndDate } = selectedIntervalDates
  return {
    minDate: elementStartDate
      ? utcDateToLocal(new Date(elementStartDate))
      : utcDateToLocal(new Date(intervalStartDate)),

    maxDate: elementDueDate
      ? utcDateToLocal(new Date(elementDueDate))
      : utcDateToLocal(new Date(intervalEndDate))
  }
}

export const OKR_DATES_SELECT_DATE_PROPS = {
  DUE_DATE: 'dueDate',
  START_DATE: 'elementStartDate'
}

export const PERIOD_MODES = {
  MANUAL: {
    value: 1,
    label: 'period_mode.manual',
    icon: 'period-mode-manual-next',
    tooltip: 'period_mode.manual.tooltip'
  },
  AUTO: {
    value: 0,
    label: 'period_mode.auto',
    icon: 'period-mode-auto-next',
    tooltip: 'period_mode.auto.tooltip'
  }
}

export const isManualPeriodModeEnabled = (startDateManual, dueDateManual) => {
  return [startDateManual, dueDateManual].some(mode => mode === PERIOD_MODES.MANUAL.value)
}
