<template>
  <AppDroplist
    v-model="opened"
    :hide-on-click="false"
    max-width="300px"
    position="bottom-end"
    trigger="manual"
    @close="resetOptions"
  >
    <template #button>
      <AppButton
        v-tippy="{
          content: $t('roadmap.export_beta'),
          placement: 'top'
        }"
        :class="{
          're-DropdownToggle-navigation': isInNavigation,
          're-DropdownToggle-active': opened
        }"
        :type="buttonOptions.type"
        class="re-DropdownToggle"
        icon="roadmap-export"
        remove-padding
        @click="onClick"
      >
        <template v-if="isInNavigation"> {{ $t('roadmap.export') }} </template>
      </AppButton>
    </template>

    <div ref="exportDropdown" class="re-ExportWrapper">
      <div class="re-ExportOptions">
        <AppRadioGroup
          v-model="selectedType"
          :options="EXPORT_OPTIONS"
          class="re-ExportOptions_Switch"
          name="export-type"
          style="--option-padding: 0 8px 0 0"
          type="primary-next"
        >
          <template #item-label="{ item }">
            <AppRadioGroupNextOptionWithIcon :option="item" />
          </template>
        </AppRadioGroup>

        <AppInfoMessage v-if="selectedType === EXPORT_TYPES.VISIBLE">
          {{ $t('roadmap.export_option.visible.hint') }}
        </AppInfoMessage>

        <AppCheckbox
          v-if="selectedType === EXPORT_TYPES.FULL"
          v-model="isMultipleExport"
          :size="CHECKBOX_SIZES.SM"
        >
          <div class="re-ExportOptionsItem_Label">
            {{ $t('roadmap.export_option.multiple.label') }}
          </div>
        </AppCheckbox>
      </div>

      <div v-if="selectedType === EXPORT_TYPES.FULL" class="re-CropSizeOptions">
        <div class="re-CropSizeOptions_Actions">
          <div class="re-CropRange">
            <AppInputRange
              v-model="cropSize"
              :disabled="!isMultipleExport"
              :max="DEFAULT_CROP_SIZE"
              :min="4e3"
              :show-percentage="false"
              :step="1e3"
              track-width="100%"
            />
          </div>
          <div :class="{ 're-CropValue-disabled': !isMultipleExport }" class="re-CropValue">
            {{ $t('roadmap.export.width') }} {{ cropSize }} {{ $t('units.px') }}
          </div>

          <AppInfoMessage v-if="isMultipleExport">
            {{ $t('roadmap.export.max_width') }}
            <ul class="re-CropSizeOptions_HintList">
              <li>
                {{ $t('roadmap.export.max_width_item', { app: $t('figma'), width: 4096 }) }}
              </li>
              <li>
                {{ $t('roadmap.export.max_width_item', { app: $t('miro'), width: 8192 }) }}
              </li>
            </ul>
          </AppInfoMessage>
        </div>
      </div>

      <div class="re-ExportFooter">
        <AppButton
          v-tippy="{
            content: isSafari() ? $t('roadmap.export.safari_note') : null,
            placement: 'top',
            theme: `${DROP_LIST_THEMES.TRANSLUCENT_NEXT} ${DROP_LIST_THEMES.TEXT_CENTER}`
          }"
          :disable="isExportDisable"
          class="re-ExportButton"
          type="primary-next"
          @click="onExportClick"
        >
          {{ $t('roadmap.export') }}
        </AppButton>
      </div>
    </div>
  </AppDroplist>
</template>

<script setup>
import { onClickOutside } from '@vueuse/core'
import { ref, computed, watch } from 'vue'

import { CHECKBOX_SIZES } from '@/utils/components-configurations/app-checkbox'
import { DROP_LIST_THEMES } from '@/utils/components-configurations/app-droplist'
import { isSafari } from '@/utils/general'
import { EXPORT_TYPES } from '@/utils/roadmap'

import AppDroplist from '@/components/AppDroplist'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppCheckbox from '@/components/ui/AppCheckbox/AppCheckbox'
import AppInfoMessage from '@/components/ui/AppInfoMessage'
import AppInputRange from '@/components/ui/AppInputRange/AppInputRange'
import AppRadioGroup from '@/components/ui/AppRadioGroup/AppRadioGroup'
import AppRadioGroupNextOptionWithIcon from '@/components/ui/AppRadioGroup/AppRadioGroupNextOptionWithIcon'

defineOptions({
  name: 'RoadmapExport'
})

const DEFAULT_CROP_SIZE = 1e4

const props = defineProps({
  isInNavigation: {
    type: Boolean
  }
})

const emit = defineEmits(['toggle-show-area', 'export-roadmap'])

const buttonOptions = computed(() => {
  const { isInNavigation } = props
  return {
    type: isInNavigation ? 'tertiary-next' : 'tertiary'
  }
})

const opened = ref(false)
const onClick = () => {
  opened.value = !opened.value
}

const selectedType = ref(EXPORT_TYPES.VISIBLE)
const isMultipleExport = ref(false)

const EXPORT_OPTIONS = [
  {
    value: EXPORT_TYPES.VISIBLE,
    label: 'roadmap.export_option.visible.label',
    icon: 'roadmap-export-visible'
  },
  {
    value: EXPORT_TYPES.FULL,
    label: 'roadmap.export_option.full.label',
    icon: 'roadmap-export-whole'
  }
]

watch(
  () => [opened.value, selectedType.value],
  ([opened, selectedType]) => {
    if (opened) {
      emit('toggle-show-area', selectedType === EXPORT_TYPES.VISIBLE)
    } else {
      emit('toggle-show-area', false)
    }
  },
  { immediate: true }
)

const cropSize = ref(DEFAULT_CROP_SIZE)

const isExportDisable = computed(() => !selectedType.value)

const exportDropdown = ref(null)

onClickOutside(exportDropdown, e => {
  if (opened.value) {
    if (Array.from(e.composedPath()).some(item => item.classList?.contains('re-DropdownToggle'))) {
      return
    }
    opened.value = false
  }
})

const onExportClick = () => {
  const exportType =
    selectedType.value === EXPORT_TYPES.FULL && isMultipleExport.value
      ? EXPORT_TYPES.MULTIPLE
      : selectedType.value
  const payload = {
    exportType
  }

  if (exportType === EXPORT_TYPES.MULTIPLE) {
    payload.cropSize = cropSize.value
  }

  if (!isExportDisable.value) {
    opened.value = false
    emit('export-roadmap', payload)
  }
}

const resetOptions = () => {
  selectedType.value = EXPORT_TYPES.VISIBLE
  cropSize.value = DEFAULT_CROP_SIZE
  isMultipleExport.value = false
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';

.re-ExportWrapper {
  padding: 20px;
  display: grid;
  gap: 16px;
}

.re-ExportOptions {
  display: flex;
  flex-direction: column;
  gap: 6px;
  align-items: flex-start;
  width: min-content;
}

.re-ExportOptions_Switch {
  margin-bottom: 8px;
}

.re-ExportOptionsItem_Label {
  color: $dark-1;
  font-family: $system-ui;
  cursor: pointer;
  font-weight: fw('regular');
  line-height: $fs-20;
  font-size: $fs-14;
}

.re-ExportFooter {
  padding-top: 16px;
  box-shadow: inset 0 1px 0 $grey-2-next;
}

.re-ExportButton {
  margin-left: auto;
}

.re-CropSizeOptions_Actions {
  display: grid;
  gap: 14px;
}

.re-CropSizeOptions_HintList {
  margin: 0;
}

.re-CropValue {
  min-width: 8ch;
  font-family: $system-ui;
  padding: 8px;
  border: 2px solid $grey-2-next;
  border-radius: $border-radius-sm-next;
  font-style: normal;
  font-weight: fw('regular');
  font-size: $fs-14;
  line-height: $fs-20;

  &:not(&-disabled) {
    color: $dark-3;
  }

  &-disabled {
    color: $grey-1-next;
  }
}

.re-CropRange {
  flex: 1;
}

.re-DropdownToggle {
  min-width: 32px;
  font-size: $fs-12;
  line-height: 16px;
  background-color: transparent;

  font-family: $system-ui;
  &-navigation {
    background-color: $grey-3-next;

    padding: 4px 8px 4px 4px;
  }

  &:not(&-active) {
    @include hoverState($grey-3-next, 1%);
    @include activeState($grey-3-next);
  }

  &-active {
    background-color: $dark-2;
    color: $white;
  }
}
</style>
