<template>
  <router-view />
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'BasicLayout'
})
</script>

<style lang="scss" scoped>
/* styles */
</style>
