import ApiHandler from '@/api/base'
import { UNSELECTED_DATE } from '@/utils/date'
import { UNSELECTED_INTERVAL } from '@/utils/interval'

class HomePageApiHandler extends ApiHandler {
  async getUsersAndGroups({
    searchString = null,
    workspaceId,
    pickedGroupId = null,
    pickedAccountId = null
  }) {
    const { data } = await this.http.get('/homepage/usersAndGroups', {
      params: {
        searchString,
        workspaceId,
        pickedAccountId,
        pickedGroupId
      }
    })
    return data
  }

  async getChartDataForUser({
    accountId,
    workspaceId,
    searchTypeId,
    intervalIds = UNSELECTED_INTERVAL,
    dueDateTo = UNSELECTED_DATE,
    startDateFrom = UNSELECTED_DATE
  }) {
    const { data } = await this.http.get('/chart/user', {
      params: {
        accountId,
        workspaceId,
        searchTypeId,
        granulation: null,
        intervalIds,
        dueDateTo,
        startDateFrom
      }
    })
    return data
  }

  async getChartDataForGroup({
    groupId,
    workspaceId,
    searchTypeId,
    intervalIds = UNSELECTED_INTERVAL,
    dueDateTo = UNSELECTED_DATE,
    startDateFrom = UNSELECTED_DATE
  }) {
    const { data } = await this.http.get('/chart/group', {
      params: {
        groupId,
        workspaceId,
        searchTypeId,
        granulation: null,
        intervalIds,
        dueDateTo,
        startDateFrom
      }
    })
    return data
  }

  async getGroupData({
    groupId,
    workspaceId,
    searchTypeId,
    intervalIds = UNSELECTED_INTERVAL,
    dueDateTo = UNSELECTED_DATE,
    startDateFrom = UNSELECTED_DATE
  }) {
    const { data } = await this.http.get('/homepage/groupData', {
      params: {
        groupId,
        workspaceId,
        searchTypeId,
        intervalIds,
        dueDateTo,
        startDateFrom
      }
    })
    return data
  }

  async getUserData({
    accountId,
    workspaceId,
    searchTypeId,
    intervalIds = UNSELECTED_INTERVAL,
    dueDateTo = UNSELECTED_DATE,
    startDateFrom = UNSELECTED_DATE
  }) {
    const { data } = await this.http.get('/homepage/userData', {
      params: {
        accountId,
        workspaceId,
        searchTypeId,
        intervalIds,
        dueDateTo,
        startDateFrom
      }
    })
    return data
  }

  async getStakeholdersGrade({
    accountId,
    workspaceId,
    groupIds,
    intervalIds = UNSELECTED_INTERVAL,
    dueDateTo = UNSELECTED_DATE,
    startDateFrom = UNSELECTED_DATE
  }) {
    const { data } = await this.http.get('/homepage/getStakeholderGrade', {
      params: {
        accountId,
        workspaceId,
        groupIds,
        intervalIds,
        dueDateTo,
        startDateFrom
      }
    })
    return data
  }
}

export default HomePageApiHandler
