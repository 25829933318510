<template>
  <AppModal
    :closable="closeable"
    :hide-header="hideHeader"
    :show="show"
    :size="size"
    class="ad-Dialog"
    close-button-icon="close-next"
    @on-close="onClose()"
  >
    <div :class="{ [`ad-Content-${size}`]: true }" class="ad-Content">
      <AppIcon
        v-if="!hideHero"
        :icon-name="ICONS[type]"
        class="ad-Content_Hero"
        height="48"
        width="48"
      />
      <AppTitle v-if="title" :level="3" class="ad-Content_Title" disable-top-margin>
        {{ title }}
      </AppTitle>
      <slot />
    </div>

    <template v-if="!hideFooter" #footer>
      <div class="ad-Footer">
        <slot name="footer-prepend" />
        <slot :on-close="onClose" :on-confirm="onConfirm" name="footer">
          <div class="ad-Footer_Actions">
            <slot name="footer-actions">
              <AppButton
                :loading="loading"
                data-auto-testid="dialog-cancel-button"
                data-testid="dialog-cancel-button"
                type="ghost-next"
                @click="onClose"
              >
                <slot name="cancel-btn-text">
                  {{ $t('action.cancel') }}
                </slot>
              </AppButton>
              <slot name="button-confirm">
                <AppButton
                  :disable="disableConfirmButton"
                  :loading="loading || loadingConfirmButton"
                  :type="confirmBtnType"
                  data-auto-testid="dialog-submit-button"
                  data-testid="dialog-submit-button"
                  @click="onConfirm"
                >
                  <slot name="confirm-btn-text">
                    {{ confirmBtnText }}
                  </slot>
                </AppButton>
              </slot>
            </slot>
          </div>
        </slot>
      </div>
    </template>
  </AppModal>
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import { DIALOG_TYPES } from '@/utils/components-configurations/app-dialog'

import AppButton from '@/components/ui/AppButton/AppButton'
import AppIcon from '@/components/ui/AppIcon/AppIcon'
import AppModal from '@/components/ui/AppModal/AppModal'
import AppTitle from '@/components/ui/AppTitle/AppTitle'

defineOptions({
  name: 'AppDialog'
})

const props = defineProps({
  size: {
    type: [String, Number],
    default: 'sm'
  },

  title: {
    type: String,
    default: ''
  },

  show: {
    type: Boolean
  },

  disableConfirmButton: {
    type: Boolean
  },

  loadingConfirmButton: {
    type: Boolean
  },

  hideHero: {
    type: Boolean
  },

  hideFooter: {
    type: Boolean
  },

  closeable: {
    type: Boolean,
    default: true
  },

  loading: {
    type: Boolean
  },

  type: {
    type: String,
    default: DIALOG_TYPES.WARNING,
    validator: v => Object.values(DIALOG_TYPES).includes(v)
  },

  hideHeader: {
    type: Boolean
  }
})

const emit = defineEmits({ 'on-close': null, 'on-confirm': null })

const onClose = () => emit('on-close')
const onConfirm = () => emit('on-confirm')

const confirmBtnType = computed(() => {
  return [DIALOG_TYPES.DELETE, DIALOG_TYPES.ERROR, DIALOG_TYPES.NOT_ALLOWED].includes(props.type)
    ? 'danger'
    : 'primary-next'
})

const { t } = useI18n()

const confirmBtnText = computed(() => {
  return props.type === DIALOG_TYPES.DELETE ? t('action.delete') : t('action.confirm')
})

const ICONS = {
  [DIALOG_TYPES.WARNING]: 'warning-modal-hero',
  [DIALOG_TYPES.DELETE]: 'delete-modal-hero',
  [DIALOG_TYPES.SUCCESS]: 'success-modal-hero',
  [DIALOG_TYPES.ERROR]: 'error-modal-hero',
  [DIALOG_TYPES.INFO]: 'info-modal-hero',
  [DIALOG_TYPES.NOT_ALLOWED]: 'not-allowed-modal-hero'
}
</script>

<style lang="scss" scoped>
.ad-Content {
  padding: var(--dialog-content-padding-top, 0) var(--dialog-content-padding-right, 20px)
    var(--dialog-content-padding-bottom, 0) var(--dialog-content-padding-left, 20px);
  font-family: $system-ui;

  color: $dark-2;
  font-size: $fs-14;
  line-height: 20px;
}

.ad-Content_Hero {
  margin-bottom: 10px;
}

.ad-Content_Title {
  font-family: $system-ui;
  color: $dark-1;
  font-weight: fw('semi-bold');
  line-height: 28px;
  --white-space: var(--title-white-space, nowrap);
}

.ad-Footer {
  padding: var(--footer-padding-top, 20px) var(--footer-padding-right, 20px)
    var(--footer-padding-bottom, 20px) var(--footer-padding-left, 20px);
  gap: 8px;
  width: 100%;
  display: flex;
  align-items: center;
}

.ad-Footer_Actions {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: inherit;
}
</style>

<style lang="scss">
.ad-Dialog {
  .o-modal-content {
    color: $blue-4;

    .o-modal-header {
      padding: 20px 20px 0;
    }

    .o-modal-body {
      padding: 0 20px;
    }

    .o-modal-footer {
      padding: 0 20px 20px;
    }
  }
}

.o-confirm {
  &-actions {
    display: flex;
    margin-left: auto;
    align-items: center;

    .ab-Button {
      margin-left: 8px;

      &:first-of-type {
        margin-left: 0;
      }
    }
  }
}
</style>
