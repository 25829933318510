<template>
  <div class="updg-MainContent">
    <AppTitle :level="4" disable-top-margin>
      {{ $t('user.preferences.default_group', 2) }}
    </AppTitle>

    <AppTable
      :columns="COLUMNS"
      :data="userWorkspaces"
      :disable-user-select="false"
      :loading="isLoading"
      border-on-last-row
      class="updgTable"
      no-border
      offset-left="var(--page-left-padding)"
      offset-right="var(--page-right-padding)"
      sticky-header
      style="--head-padding-top: 8px"
      type="primary-next"
    >
      <template #cell="{ columnKey, item }">
        <template v-if="columnKey === NAME">
          <WorkspaceNameCell :name-as-link="false" :workspace="item" />
        </template>
        <template v-if="columnKey === DEFAULT_GROUP">
          <DefaultGroupCell
            :default-group-id="item.defaultGroupId"
            :groups="item.groups"
            :workspace-id="item.id"
            @update-default-group="onUpdateDefaultGroup"
          />
        </template>
      </template>
      <template #loading>
        <DefaultGroupsLoader />
      </template>
    </AppTable>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import PersonalSettingsApiHandler from '@/api/personal-settings'
import { handleError } from '@/utils/error-handling'
import { TABLE_COLUMNS_KEYS } from '@/utils/table-columns'

import DefaultGroupCell from '@/components/global-groups/DefaultGroupCell'
import AppTitle from '@/components/ui/AppTitle/AppTitle'
import DefaultGroupsLoader from '@/components/ui/SkeletonLoaders/DefaultGroupsLoader'
import AppTable from '@/components/ui/Table/AppTable'
import WorkspaceNameCell from '@/components/workspaces/WorkspaceNameCell'

defineOptions({
  name: 'UserPreferencesDefaultGroupsTab'
})

const { NAME, DEFAULT_GROUP } = TABLE_COLUMNS_KEYS

const { t } = useI18n()

const COLUMNS = [
  {
    title: t('workspaces.name'),
    key: NAME,
    width: 'auto'
  },
  {
    title: t('user.preferences.default_group'),
    key: DEFAULT_GROUP,
    width: 576
  }
]

const isLoading = ref(false)
const userWorkspaces = ref([])
const getInitialData = async () => {
  const api = new PersonalSettingsApiHandler()
  isLoading.value = true
  try {
    const { workspaces } = await api.getWorkspacesForDefaultGroup()
    userWorkspaces.value = workspaces
  } catch (error) {
    handleError({ error })
  } finally {
    isLoading.value = false
  }
}

const onUpdateDefaultGroup = ({ defaultGroupId = null, workspaceId = null } = {}) => {
  if (!defaultGroupId || !workspaceId) return

  const workspace = userWorkspaces.value.find(({ id }) => id === workspaceId)

  if (workspace) {
    workspace.defaultGroupId = defaultGroupId
  }
}

onMounted(() => {
  getInitialData()
})
</script>

<style lang="scss" scoped>
.updg-MainContent {
  width: 100%;
  max-width: $page-width-lg;
  font-family: $system-ui;
}
</style>
