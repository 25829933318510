<template>
  <FormFieldNext :disabled="!userHasUpdateAccess">
    <template #label>
      <CustomFieldLabel :field-id="fieldId" />
    </template>
    <AppInput
      v-model="localValue"
      :data-auto-testid="SINGLE_LINE_TEXT_FORM_FIELD_TEST_ID"
      :data-testid="SINGLE_LINE_TEXT_FORM_FIELD_TEST_ID"
      :placeholder="emptyStateLabel"
      :readonly="!userHasUpdateAccess"
      blur-on-enter
      max-length="37"
      size="xlg"
      style="
        --readonly-color: var(--dark-3);
        --readonly-background-color: var(--grey-2-next);
        --readonly-border-color: var(--grey-2-next);
      "
      style-type="primary"
      @blur="onBlur"
    />
  </FormFieldNext>
</template>
<script setup>
import { computed } from 'vue'

import { SINGLE_LINE_TEXT_FORM_FIELD_TEST_ID } from '@/utils/custom-fields/jest-helpers'
import { useCustomFieldsHelpers } from '@/utils/custom-fields/use-custom-fields'

import CustomFieldLabel from '@/components/custom-fields/okr-elements-form/CustomFieldLabel'
import FormFieldNext from '@/components/form/FormFieldNext'
import AppInput from '@/components/ui/AppInput/AppInput'

defineOptions({
  name: 'SingleLineText'
})

const props = defineProps({
  userHasUpdateAccess: {
    type: Boolean,
    required: true
  },

  fieldId: {
    type: [String, Number],
    required: true
  },

  modelValue: {
    type: String,
    required: true
  }
})

const emit = defineEmits({
  'update:model-value': null,
  'update-element': null
})

const localValue = computed({
  get: () => props.modelValue,
  set: value => {
    emit('update:model-value', {
      fieldId: props.fieldId,
      value,
      updateElement: false
    })
  }
})

const onBlur = () => {
  if (props.userHasUpdateAccess) {
    emit('update-element', props.fieldId)
  }
}

const { emptyStateLabel } = useCustomFieldsHelpers({
  fieldId: props.fieldId
})
</script>

<style lang="scss" scoped></style>
