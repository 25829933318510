<template>
  <AppDialog :show="show" :title="$t('token.copy.title')" hide-hero @on-close="close">
    <AppInfoMessage :type="infoMessageType">
      {{ $t('token.copy.note') }}
    </AppInfoMessage>

    <FormFieldNext :label="$t('token.copy.label')" class="ctm-TokenWrapper">
      <div :class="{ 'ctm-Token-copied': isCopied }" class="ctm-Token">
        {{ token.token }}
      </div>
    </FormFieldNext>

    <template #button-confirm>
      <AppButton height="24" icon="copy-next" type="primary-next" width="24" @click="onCopyToken">
        {{ $t('action.copy') }}
      </AppButton>
    </template>
  </AppDialog>
</template>

<script>
import { defineComponent } from 'vue'

import { INFO_MESSAGE_TYPES } from '@/utils/components-configurations/app-info-message'
import { copyToClipboard } from '@/utils/general'
import { objectOrNullProp } from '@/utils/prop-validators'

import AppDialog from '@/components/AppDialog'
import FormFieldNext from '@/components/form/FormFieldNext'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppInfoMessage from '@/components/ui/AppInfoMessage'

export default defineComponent({
  name: 'CopyTokenModal',

  components: {
    FormFieldNext,
    AppInfoMessage,
    AppDialog,
    AppButton
  },

  props: {
    show: {
      type: Boolean,
      default: false
    },

    token: {
      validator: v => objectOrNullProp(v),
      required: true
    }
  },

  emits: { 'update:show': null },

  data() {
    return {
      isCopied: false
    }
  },

  computed: {
    infoMessageType() {
      return this.isCopied ? INFO_MESSAGE_TYPES.SUCCESS : INFO_MESSAGE_TYPES.WARNING
    }
  },

  watch: {
    show(newValue) {
      if (!newValue) {
        this.isCopied = false
      }
    }
  },

  methods: {
    close() {
      this.$emit('update:show', false)
    },

    onCopyToken() {
      copyToClipboard(this.token.token)
      this.isCopied = true
    }
  }
})
</script>

<style lang="scss" scoped>
.ctm-Token {
  border-radius: $border-radius-sm-next;
  padding: 10px;
  font-style: normal;
  font-weight: fw('regular');
  font-size: $fs-14;
  color: $dark-1;
  word-break: break-word;

  &:not(&-copied) {
    background-color: $grey-3-next;
  }

  &-copied {
    background-color: rgba($grade-high-color-next, 0.1);
  }
}

.ctm-TokenWrapper {
  margin-top: 23px;
}
</style>
