<template>
  <div class="at-TokensPage">
    <AppBackButton />

    <PageContentHeader
      :level="3"
      :max-width="HEADER_SIZES.LG"
      :title="t('menu.api_tokens')"
      class="at-TokensPage_Header"
      no-margin
    >
      <AppButton
        v-if="!isNoTokens"
        v-tippy="{
          content: createButtonDisabled ? 'You used all available tokens' : null,
          placement: 'top'
        }"
        :disable="createButtonDisabled"
        height="24"
        icon="plus-next"
        type="primary-next"
        width="24"
        @click="onCreateTokenClick"
      >
        {{ t('tokens.create_token') }}
      </AppButton>
    </PageContentHeader>
    <div v-if="!isNoTokens" class="at-Description">
      <i18n-t keypath="tokens.description" scope="global">
        <template #break>
          <br />
        </template>
        <template #usedTokensCount>
          {{ tokens.length }}
        </template>
      </i18n-t>
    </div>

    <div class="at-MainContent">
      <AppTable
        v-if="!isNoTokens"
        :columns="tableColumns"
        :data="tokens"
        :disable-user-select="false"
        :loading="areTokensLoading"
        border-on-last-row
        class="at-Table"
        no-border
        offset-left="var(--page-left-padding)"
        offset-right="var(--page-right-padding)"
        sticky-header
        type="primary-next"
      >
        <template #header-cell="{ column }">
          <template v-if="column.title">
            {{ t(column.title) }}
          </template>
        </template>
        <template #cell="{ columnKey, item, index }">
          <template v-if="columnKey === TABLE_COLUMNS_KEYS.NUMBER">
            <div class="at-TokenNumber">
              {{ index + 1 }}
            </div>
          </template>
          <template v-if="columnKey === TABLE_COLUMNS_KEYS.LABEL">
            <div class="at-TokenName">{{ item.name }}</div>
          </template>

          <!--
            <template v-else-if="columnKey === TABLE_COLUMNS_KEYS.LAST_ACCESSED">
              {{ getLastAccessed(item) }}
            </template>
            -->

          <template v-else-if="columnKey === TABLE_COLUMNS_KEYS.ACTION">
            <AppButton
              class="at-DeleteTokenBtn"
              icon="delete-next"
              size="sm"
              type="tertiary-next"
              @click="onTokenRevoke(item)"
            />
          </template>
        </template>
        <template #loading>
          <ApiTokensLoader />
        </template>

        <template #footer>
          <AppTableCreateButton
            v-if="!createButtonDisabled"
            full-width
            icon-name="plus-next"
            @click="onCreateTokenClick"
          >
            {{ t('tokens.create_token') }}
          </AppTableCreateButton>

          <AppInfoMessage
            v-if="isTokensLimitExceeded"
            :type="INFO_MESSAGE_TYPES.WARNING"
            class="at-Message"
          >
            {{
              t('tokens.limit_exceeded', {
                limit: MAX_TOKENS_COUNT
              })
            }}
          </AppInfoMessage>
        </template>
      </AppTable>
    </div>

    <SettingsPagesEmptyState
      v-if="isNoTokens"
      hero-height="128"
      hero-icon="no-tokens-hero"
      hero-width="142"
    >
      <template #title>
        {{ t('tokens.no_tokens') }}
      </template>
      <template #subtitle> {{ t('tokens.no_tokens_subtitle') }}</template>

      <template #action>
        <AppButton
          class="at-CreateTokenBtn"
          height="24"
          icon="plus-next"
          type="primary-next"
          width="24"
          @click="onCreateTokenClick"
        >
          {{ t('tokens.create_token') }}
        </AppButton>
      </template>
    </SettingsPagesEmptyState>

    <TokenModal v-model:show="showTokenModal" @create="onTokenCreated" />

    <CopyTokenModal
      v-model:show="showCopyTokenModal"
      :token="tokenToCopy"
      @update:show="onCopyTokenModalInput"
    />

    <TokenDeleteModal
      :show="showTokenDeleteModal"
      :token="tokenToDelete"
      @on-close="hideTokenDeleteModal"
      @on-confirm="revokeToken"
    />
  </div>
</template>

<script setup>
import { isEmpty } from 'lodash'
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import WorkspaceTokensApiHandler from '@/api/workspace-tokens'
import { tracker } from '@/tracking/amplitude'
import { EVENT_CATEGORIES } from '@/tracking/amplitude-helpers'
import { INFO_MESSAGE_TYPES } from '@/utils/components-configurations/app-info-message'
import { HEADER_SIZES } from '@/utils/components-configurations/page-content-header'
import { handleError } from '@/utils/error-handling'
import { showNotify } from '@/utils/notify'
import { TABLE_COLUMNS_KEYS } from '@/utils/table-columns'

import AppBackButton from '@/components/ui/AppBackButton'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppInfoMessage from '@/components/ui/AppInfoMessage'
import AppTableCreateButton from '@/components/ui/AppTableCreateButton'
import PageContentHeader from '@/components/ui/PageContentHeader/PageContentHeader'
import SettingsPagesEmptyState from '@/components/ui/SettingsPagesEmptyState/SettingsPagesEmptyState'
import ApiTokensLoader from '@/components/ui/SkeletonLoaders/ApiTokensLoader'
import AppTable from '@/components/ui/Table/AppTable'
import CopyTokenModal from '@/components/workspaces/CopyTokenModal'
import TokenDeleteModal from '@/components/workspaces/TokenDeleteModal'
import TokenModal from '@/components/workspaces/TokenModal'

const { t } = useI18n()

const tokens = ref([])
const areTokensLoading = ref(false)

const isNoTokens = computed(() => {
  return isEmpty(tokens.value) && !areTokensLoading.value
})

const MAX_TOKENS_COUNT = 10
const isTokensLimitExceeded = computed(() => {
  return tokens.value.length >= MAX_TOKENS_COUNT
})

const createButtonDisabled = computed(() => {
  return areTokensLoading.value || isTokensLimitExceeded.value
})

const showTokenModal = ref(false)
const onCreateTokenClick = () => {
  if (!createButtonDisabled.value) {
    showTokenModal.value = true
  }
}
const tokensApi = new WorkspaceTokensApiHandler()

const getTokens = async () => {
  try {
    areTokensLoading.value = true
    tokens.value = await tokensApi.getTokens()
  } catch (error) {
    handleError({ error })
  } finally {
    areTokensLoading.value = false
  }
}
onMounted(() => {
  getTokens()
})

const tokenToCopy = ref(null)
const showCopyTokenModal = ref(false)
const onTokenCreated = token => {
  showTokenModal.value = false
  getTokens()

  tracker.logEvent('Token created', {
    category: EVENT_CATEGORIES.SETTINGS
  })

  tokenToCopy.value = token
  showCopyTokenModal.value = true
}
const onCopyTokenModalInput = value => {
  if (!value) {
    tokenToCopy.value = null
  }
}
const showTokenDeleteModal = ref(false)
const tokenToDelete = ref(null)
const hideTokenDeleteModal = () => {
  showTokenDeleteModal.value = false
}
const revokeToken = async () => {
  try {
    await tokensApi.removeToken({
      tokenId: tokenToDelete.value.id
    })

    hideTokenDeleteModal()

    tracker.logEvent('Token revoked', {
      category: EVENT_CATEGORIES.SETTINGS
    })

    showNotify({
      title: t('token.toast.revoke_message')
    })
  } catch (error) {
    handleError({ error })
  }
  tokenToDelete.value = null
  await getTokens()
}
const onTokenRevoke = token => {
  tokenToDelete.value = token
  showTokenDeleteModal.value = true
}

const tableColumns = [
  {
    title: 'tokens.token_number',
    key: TABLE_COLUMNS_KEYS.NUMBER,
    width: 24
  },
  {
    title: 'tokens.label',
    key: TABLE_COLUMNS_KEYS.LABEL,
    width: 'auto'
  },
  // {
  //   title: 'tokens.last_accessed',
  //   key: TABLE_COLUMNS_KEYS.LAST_ACCESSED,
  //   width: 224
  // },
  {
    key: TABLE_COLUMNS_KEYS.ACTION,
    width: 24
  }
]
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/app-table-helpers';

.at-TokensPage {
  width: 100%;
  max-width: $page-width-lg;
  padding-bottom: $page-bottom-padding;
}

.at-TokensPage_Header {
  &:deep(.pc-Title) {
    min-height: 32px;
  }
}

.at-MainContent {
  width: 100%;
  max-width: $page-width-md;
  padding-left: $page-left-padding;
  padding-right: $page-right-padding;
}

.at-Description {
  font-style: normal;
  font-weight: fw('regular');
  font-size: $fs-14;
  line-height: 20px;
  color: $dark-2;
  padding: 13px $page-right-padding 0 $page-left-padding;
  font-family: $system-ui;
  width: 100%;
  max-width: $page-width-md;
}

.at-TokenName,
.at-TokenNumber {
  font-style: normal;
  font-weight: fw('semi-bold');
  font-size: $fs-14;
  line-height: 20px;
}

.at-TokenName {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: $dark-1;
}

.at-TokenNumber {
  color: $dark-3;
}

.at-DeleteTokenBtn {
  @extend %app-table-hidden-items;
}

.at-Table {
  --head-padding-top: 13px;
}

.at-Message {
  margin-top: 10px;
  padding-left: $page-left-padding;
}
</style>
