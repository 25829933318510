<template>
  <div class="lap-LabelsPage">
    <PageContentHeader
      :level="3"
      :max-width="HEADER_SIZES.LG"
      :title="$t('field.labels.title')"
      no-margin
      style="--padding-bottom: 16px; --align-items: flex-start"
    >
      <div class="lap-Toolbar_Right">
        <AppSearch v-model="searchString" />
        <AppButton
          class="lap-CreateLabelBtn"
          height="24"
          icon="plus-next"
          type="primary-next"
          width="24"
          @click="showLabelModal = true"
        >
          {{ $t('labels.create') }}
        </AppButton>
      </div>
    </PageContentHeader>
    <div v-if="!isNoLabels" class="lap-Toolbar">
      <AppCheckbox
        v-model="allowLabelCreation"
        style="--label-color: var(--dark-2); --checkmark-margin: 4px"
        @update:model-value="save"
      >
        {{ $t('labels.switch_label') }}
      </AppCheckbox>
    </div>
    <div class="lap-MainContent">
      <AppTable
        v-show="!isNoLabels"
        ref="table"
        :columns="tableColumns"
        :data="labelsToShow"
        :disable-user-select="false"
        :loading="areLabelsLoading"
        border-on-last-row
        class="lap-Table"
        infinite-loading
        no-border
        offset-left="var(--page-left-padding)"
        offset-right="var(--page-right-padding)"
        sticky-header
        type="primary-next"
        @infinite-load="onInfiniteLoad"
      >
        <template #header-cell="{ column }">
          <template v-if="column.title">
            {{ $t(column.title) }}
          </template>
        </template>
        <template #cell="{ columnKey, item }">
          <template v-if="columnKey === TABLE_COLUMNS_KEYS.LABEL">
            <CellSelectItem class="lap-LabelItem" truncated user-has-update-access>
              {{ item.name }}
            </CellSelectItem>
          </template>

          <template v-else-if="columnKey === TABLE_COLUMNS_KEYS.ACTION">
            <AppButton
              class="lap-DeleteLabelBtn"
              icon="delete-next"
              size="sm"
              type="tertiary-next"
              @click="onRemoveClick(item)"
            />
          </template>
        </template>

        <template #no-results>
          <NoSearchResults
            offset-left="var(--page-left-padding)"
            offset-right="var(--page-right-padding)"
          >
            {{ $t('labels.no_results') }}
          </NoSearchResults>
        </template>
        <template #loading>
          <LabelsTableLoader />
        </template>

        <template #footer>
          <AppTableCreateButton full-width icon-name="plus-next" @click="showLabelModal = true">
            {{ $t('labels.create') }}
          </AppTableCreateButton>
        </template>
      </AppTable>
    </div>

    <SettingsPagesEmptyState
      v-if="isNoLabels"
      hero-height="160"
      hero-icon="no-labels-hero"
      hero-width="142"
    >
      <template #title> {{ $t('labels.no_labels') }} </template>
      <template #subtitle> {{ $t('labels.no_labels_subtitle') }} </template>

      <template #action>
        <AppButton
          height="24"
          icon="plus-next"
          type="primary-next"
          width="24"
          @click="showLabelModal = true"
        >
          {{ $t('labels.create') }}
        </AppButton>
      </template>
    </SettingsPagesEmptyState>

    <LabelModal v-model:show="showLabelModal" @create="onLabelCreated" />

    <LabelDeleteModal
      :label-to-delete="labelToDelete"
      :show="showLabelDeleteModal"
      @on-close="hideLabelDeleteModal"
      @on-confirm="deleteLabel"
    />
  </div>
</template>

<script>
import { isEmpty } from 'lodash'
import { defineComponent } from 'vue'
import { mapActions, mapState } from 'vuex'

import LabelsApiHandler from '@/api/labels'
import PluginApiHandler from '@/api/plugin'
import { tracker } from '@/tracking/amplitude'
import {
  EVENT_CATEGORIES,
  EVENT_SOURCES,
  trackLabelCreatedEvent
} from '@/tracking/amplitude-helpers'
import { SETTING_PARAMETERS } from '@/utils/company-settings'
import { HEADER_SIZES } from '@/utils/components-configurations/page-content-header'
import { COMPANY_SETTINGS_ENTITY_KEYS } from '@/utils/entity-keys'
import { handleError } from '@/utils/error-handling'
import { showNotify } from '@/utils/notify'
import { localSearch } from '@/utils/objectives'
import { TABLE_COLUMNS_KEYS } from '@/utils/table-columns'

import CellSelectItem from '@/components/objectives/table/cells/CellSelectItem'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppCheckbox from '@/components/ui/AppCheckbox/AppCheckbox'
import AppTableCreateButton from '@/components/ui/AppTableCreateButton'
import NoSearchResults from '@/components/ui/NoSearchResults/NoSearchResults'
import PageContentHeader from '@/components/ui/PageContentHeader/PageContentHeader'
import AppSearch from '@/components/ui/Search/Search'
import SettingsPagesEmptyState from '@/components/ui/SettingsPagesEmptyState/SettingsPagesEmptyState'
import LabelsTableLoader from '@/components/ui/SkeletonLoaders/LabelsTableLoader'
import AppTable from '@/components/ui/Table/AppTable'
import LabelDeleteModal from '@/components/workspaces/LabelDeleteModal'
import LabelModal from '@/components/workspaces/LabelModal'

const labelsApi = new LabelsApiHandler()

export default defineComponent({
  name: 'LabelsLayout',

  components: {
    AppSearch,
    SettingsPagesEmptyState,
    NoSearchResults,
    AppCheckbox,
    AppTableCreateButton,
    CellSelectItem,
    LabelsTableLoader,
    PageContentHeader,
    AppButton,
    AppTable,
    LabelModal,
    LabelDeleteModal
  },

  PAGE_SIZE: 50,

  data() {
    return {
      tableColumns: [
        {
          title: 'labels.label',
          key: TABLE_COLUMNS_KEYS.LABEL,
          width: 'auto'
        },
        {
          key: TABLE_COLUMNS_KEYS.ACTION,
          width: 32
        }
      ],

      labels: [],
      searchString: '',
      areLabelsLoading: false,
      labelsAreLoaded: false,
      labelToDelete: null,
      showLabelModal: false,
      showLabelDeleteModal: false,
      allowLabelCreation: false,

      page: 0
    }
  },

  computed: {
    ...mapState('system', {
      settings: state => state.settings
    }),

    HEADER_SIZES: () => HEADER_SIZES,

    TABLE_COLUMNS_KEYS: () => TABLE_COLUMNS_KEYS,

    isNoLabels() {
      return isEmpty(this.labels) && !this.areLabelsLoading
    },

    filteredLabels() {
      // return this.labels.filter(label =>
      //   label.name.toLowerCase().startsWith(this.searchString.toLowerCase())
      // )

      return localSearch({
        value: this.searchString,
        options: this.labels
      })
    },

    labelsToShow() {
      return this.filteredLabels.slice(0, this.page * this.$options.PAGE_SIZE)
    }
  },

  watch: {
    searchString() {
      this.page = 0
      this.$nextTick(() => {
        this.$refs.table?.updateItems()
      })
    }
  },

  mounted() {
    this.allowLabelCreation = this.settings[COMPANY_SETTINGS_ENTITY_KEYS.ALLOW_LABEL_CREATION]
  },

  methods: {
    ...mapActions('system', {
      setSettingsParameter: 'setSettingsParameter'
    }),

    async getLabels(searchString = null) {
      try {
        this.areLabelsLoading = true
        this.labels = await labelsApi.getLabels({ name: searchString })
        await this.$nextTick()
        this.$refs.table?.updateItems()
      } catch (error) {
        handleError({ error })
      } finally {
        this.areLabelsLoading = false
      }
    },

    async deleteLabel() {
      try {
        await labelsApi.deleteLabel({
          id: this.labelToDelete.id
        })

        tracker.logEvent('Label deleted', {
          category: EVENT_CATEGORIES.SETTINGS,
          label: this.labelToDelete.name
        })

        this.hideLabelDeleteModal()
      } catch (error) {
        handleError({ error })
      }
      this.labelToDelete = null
      this.getLabels()
    },

    onLabelCreated(newLabel) {
      this.showLabelModal = false

      trackLabelCreatedEvent({
        source: EVENT_SOURCES.SETTINGS,
        label: newLabel.name
      })

      showNotify({ title: this.$t('labels.toast.create_message') })
      this.getLabels()
    },

    onRemoveClick(label) {
      this.labelToDelete = label
      this.showLabelDeleteModal = true
    },

    hideLabelDeleteModal() {
      this.showLabelDeleteModal = false
    },

    async save(value) {
      try {
        const api = new PluginApiHandler()
        await api.updateSettings({
          settingId: SETTING_PARAMETERS.ALLOW_LABEL_CREATION,
          settingValue: value
        })
        await this.setSettingsParameter({
          parameter: COMPANY_SETTINGS_ENTITY_KEYS.ALLOW_LABEL_CREATION,
          value
        })
      } catch (error) {
        handleError({ error })
      }
    },

    async onInfiniteLoad($state) {
      if (!this.labelsAreLoaded) {
        await this.getLabels()
        this.labelsAreLoaded = true
      }

      this.page += 1
      if (this.filteredLabels.length > 0) {
        $state.loaded()
      }
      if (this.filteredLabels.length <= this.page * this.$options.PAGE_SIZE) {
        $state.complete()
      }
    }
  }
})
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/app-table-helpers';

.lap-LabelsPage {
  padding-bottom: $page-bottom-padding;
  width: 100%;
  max-width: $page-width-lg;
}

.lap-MainContent {
  width: 100%;
  max-width: $page-width-md;
  padding-left: $page-left-padding;
  padding-right: $page-right-padding;
  font-family: $system-ui;
}

.lap-Toolbar {
  padding-left: $page-left-padding;
  padding-right: $page-right-padding;
  display: flex;
  gap: 16px;
  justify-content: space-between;
  align-items: center;
  // margin-bottom: 24px;
}

.lap-Toolbar_Right {
  display: flex;
  gap: inherit;
  align-items: center;
}

.lap-LabelItem {
  display: inline-block;
  max-width: 100%;
  vertical-align: middle;
}

.lap-DeleteLabelBtn {
  margin-left: auto;
  @extend %app-table-hidden-items;
}

.lap-Table {
  --head-padding-top: 20px;
}
</style>
