// must be exported as a module coz
// defineProps() in <script setup> cannot reference locally declared variables because
// it will be hoisted outside the setup() function.
export const SIZES = {
  XS: 'xs',
  SM: 'sm',
  MD: 'md',
  LG: 'lg',
  XL: 'xl'
}
