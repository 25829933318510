import ApiHandler from '@/api/base'
import { WORKSPACE_USER_ROLES } from '@/utils/objectives'

class PluginApiHandler extends ApiHandler {
  async getInitValues({ filterWorkspacesWhereUserDisabled = false } = {}) {
    const { data } = await this.http.get('/getInitValues')
    return {
      ...data,
      userWorkspaces: filterWorkspacesWhereUserDisabled
        ? data.userWorkspaces.filter(workspace => {
            return workspace.userRoleId !== WORKSPACE_USER_ROLES.NO_ACCESS
          })
        : data.userWorkspaces
    }
  }

  async updateSettings({ settingId, settingValue } = {}) {
    // settingValue Boolean or Number at this moment
    const { data } = await this.http.put('/settings', {
      settingId,
      settingValue: Number(settingValue)
    })
    return data
  }

  async updateUserSettings(userSettings) {
    const { data } = await this.http.put('/userSettings', {
      settings: {
        ...userSettings
      }
    })
    return data
  }
}

export default PluginApiHandler
