<template>
  <ObjectiveMoveModal
    :disable="isDisable"
    :loading="loading"
    :show="show"
    :title="t('objective.select_assignee')"
    @close="hideModal"
    @proceed="proceed"
    @prev-step="prevStep"
  >
    <AppInfoMessage :type="INFO_MESSAGE_TYPES.WARNING" class="sma-Subtitle">
      {{ t('move.select_assignee_subtitle') }}
    </AppInfoMessage>
    <ObjectiveMoveConflictItems
      :conflict-items="conflicts"
      :select-items="assigneeOptionsMapped"
      :select-placeholder="$t('common.select_assignee')"
      :selected-items="selectedAssignees"
      item-label="name"
      item-value="accountId"
      @update:model-value="updateConflict"
    >
      <template #prepend-input="{ conflict }">
        <AppAvatar :avatar-url="conflict.avatarUrl" no-margins />
      </template>
      <template #prepend-select-icon="{ selectedItem }">
        <AppIcon
          v-if="selectedItem?.iconName"
          :icon-name="selectedItem.iconName"
          class="sma-PrependIcon"
          height="24"
          width="24"
        />
        <AppAvatar v-else-if="selectedItem" :avatar-url="selectedItem.avatarUrl" no-margins />
      </template>

      <template #select-option-label="{ option }">
        <OwnerFieldOption :option="option" />
      </template>
    </ObjectiveMoveConflictItems>
  </ObjectiveMoveModal>
</template>

<script setup>
import { nextTick, ref, onMounted, computed } from 'vue'
import { useI18n } from 'vue-i18n'

import AssigneesInfoApiHandler from '@/api/assignees-info'
import { INFO_MESSAGE_TYPES } from '@/utils/components-configurations/app-info-message'
import { handleError } from '@/utils/error-handling'

import OwnerFieldOption from '@/components/form/OwnerFieldOption'
import ObjectiveMoveConflictItems from '@/components/objectives/objective-move/ObjectiveMoveConflictItems'
import ObjectiveMoveModal from '@/components/objectives/objective-move/ObjectiveMoveModal'
import AppAvatar from '@/components/ui/AppAvatar/AppAvatar'
import AppIcon from '@/components/ui/AppIcon/AppIcon'
import AppInfoMessage from '@/components/ui/AppInfoMessage'

const props = defineProps({
  show: {
    type: Boolean
  },
  formModel: {
    type: Object,
    required: true
  },
  conflicts: {
    type: Array,
    default: () => []
  },
  loading: {
    type: Boolean
  }
})
const { t } = useI18n()
const emit = defineEmits({
  'update:show': null,
  proceed: null,
  'prev-step': null
})
const hideModal = async () => {
  emit('update:show', false)
  await nextTick()
}
const selectedAssignees = ref(props.conflicts.map(conflict => conflict.accountIdToChange))
const proceed = () => {
  const conflictAssignees = props.conflicts.map((conflict, conflictIndex) => {
    return {
      ...conflict,
      accountIdToChange: selectedAssignees.value[conflictIndex]
    }
  })
  emit('proceed', conflictAssignees)
}
const updateConflict = ({ index, value }) => {
  selectedAssignees.value[index] = value
}
const prevStep = () => {
  emit('prev-step')
}
const areAssigneesLoading = ref(false)
const assigneeOptions = ref([])

const assigneeOptionsMapped = computed(() => {
  return [
    // { displayName: t('objective.clear_assignee'), accountId: null, iconName: 'unassigned-owner' },
    ...assigneeOptions.value
  ]
})
const getAssignees = async (searchString = null) => {
  let result = []
  const api = new AssigneesInfoApiHandler()

  areAssigneesLoading.value = true
  const parameters = {
    workspaceId: props.formModel.workspaceId,
    searchString
  }
  try {
    result = await api.getUsers(parameters)
    assigneeOptions.value = result
  } catch (error) {
    handleError({ error })
  } finally {
    areAssigneesLoading.value = false
  }
  return result
}
const isDisable = computed(() => {
  return selectedAssignees.value.some(assignee => !assignee)
})

onMounted(() => {
  getAssignees()
})
</script>
<script>
//eslint-disable-next-line
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'SelectMoveAssignee'
})
</script>

<style lang="scss" scoped>
.sma-Subtitle {
  margin-bottom: 35px;
}
.sma-PrependIcon {
  margin-right: 8px;
}
</style>
