import ApiHandler from '@/api/base'

class ObjectiveChartsApiHandler extends ApiHandler {
  async getChartDataForElement({ elementId, granulation = null, workspaceId }) {
    const { data } = await this.http.get('/chart/element', {
      params: {
        elementId,
        granulation,
        workspaceId
      }
    })
    return data
  }
}

export default ObjectiveChartsApiHandler
