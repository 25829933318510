<template>
  <AppDialog
    :show="show"
    :title="$t('delete_confirm_token_title')"
    :type="DIALOG_TYPES.DELETE"
    @on-confirm="onConfirm"
    @on-close="onClose"
  >
    <template v-if="show && token">
      {{ $t('delete_confirm_token_message_start') }}
      <span class="td-DangerText"> {{ token.name }} </span>?
      {{ $t('delete_confirm_token_message_end') }}
    </template>

    <template #confirm-btn-text>
      {{ $t('action.revoke') }}
    </template>
  </AppDialog>
</template>

<script>
import { defineComponent } from 'vue'

import { DIALOG_TYPES } from '@/utils/components-configurations/app-dialog'
import { objectOrNullProp } from '@/utils/prop-validators'

import AppDialog from '@/components/AppDialog'

export default defineComponent({
  name: 'TokenDeleteModal',

  components: {
    AppDialog
  },

  props: {
    show: {
      type: Boolean,
      default: false
    },

    token: {
      validator: v => objectOrNullProp(v),
      required: true
    }
  },

  emits: { 'on-confirm': null, 'on-close': null },

  computed: {
    DIALOG_TYPES: () => DIALOG_TYPES
  },

  methods: {
    onClose() {
      this.$emit('on-close')
    },

    onConfirm() {
      this.$emit('on-confirm')
    }
  }
})
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/modal-danger-text';

.td-DangerText {
  @extend %modal-danger-text;
}
</style>
