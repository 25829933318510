<template>
  <div v-if="!isEmpty(resolvedLabels)" class="gl-Labels">
    <div
      v-for="label in resolvedLabels"
      :key="label.key"
      :data-testid="label.key"
      class="gl-Labels_Item"
    >
      <AppIcon :icon-name="label.icon" height="24" width="24" />
      <slot :name="label.key" />
    </div>
  </div>
</template>

<script setup>
import { isEmpty } from 'lodash'
import { computed } from 'vue'

import { GLOBAL_GROUPS_NAME_CELL_LABELS } from '@/utils/global-groups'

import { globalGroupNameCellListValidator } from '@/components/AppMenu/props-validators'
import AppIcon from '@/components/ui/AppIcon/AppIcon'

defineOptions({
  name: 'GroupLabels'
})

const props = defineProps({
  labelsList: {
    type: Array,
    default: () => Object.values(GLOBAL_GROUPS_NAME_CELL_LABELS),
    validator: value =>
      globalGroupNameCellListValidator({ value, whiteList: GLOBAL_GROUPS_NAME_CELL_LABELS })
  }
})

const LABELS_OPTIONS = {
  [GLOBAL_GROUPS_NAME_CELL_LABELS.USERS_COUNT]: {
    key: 'user-count',
    icon: 'group-user'
  },
  [GLOBAL_GROUPS_NAME_CELL_LABELS.SUB_GROUPS_COUNT]: {
    key: 'child-count',
    icon: 'subgroup'
  }
}

const resolvedLabels = computed(() => {
  return props.labelsList.map(label => LABELS_OPTIONS[label])
})
</script>

<style lang="scss" scoped>
.gl-Labels {
  display: flex;
  gap: 4px;
}

.gl-Labels_Item {
  align-items: center;
  min-width: 40px;
  display: flex;
  color: $dark-3;
  background-color: $grey-3-next;
  border-radius: $border-radius-sm-next;
  padding: 0 6px 0 2px;
  font-size: $fs-12;
  font-weight: fw('bold');
}
</style>
