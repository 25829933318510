<template>
  <div class="dgl-Loader">
    <div v-for="row in ROWS" :key="row.id" class="wtl-Loader_Row">
      <div class="wtl-Cell">
        <SkeletonItem :appearance="SQUARE" :size="SM" border-radius="6px" />
        <SkeletonItem :size="XS" :width="row.name" border-radius="6px" color="var(--grey-3-next)" />
      </div>

      <div class="wtl-Cell">
        <template v-if="row.group">
          <SkeletonItem v-if="row.isPlus" :width="row.group" height="24px" />
          <SkeletonItem v-else :color="getRandomGroupColor()" :width="row.group" height="24px" />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'DefaultGroupsLoader'
})
</script>

<script setup>
import {
  SKELETON_APPEARANCES,
  SKELETON_SIZES
} from '@/utils/components-configurations/skeleton-item'
import { getRandomGroupColor } from '@/utils/global-groups'

import SkeletonItem from '@/components/ui/SkeletonLoaders/SkeletonItem'

const { SM, XS } = SKELETON_SIZES

const { SQUARE } = SKELETON_APPEARANCES

const ROWS = [
  {
    id: 0,
    name: '90px',
    group: '280px'
  },
  {
    id: 1,
    name: '13px'
  },
  {
    id: 2,
    name: '50px'
  },
  {
    id: 3,
    name: '192px',
    group: '24px',
    isPlus: true
  },
  {
    id: 4,
    name: '16px',
    group: '170px'
  },
  {
    id: 5,
    name: '20px',
    group: '60px'
  },
  {
    id: 6,
    name: '47px',
    group: '354px'
  },
  {
    id: 7,
    name: '185px',
    group: '40px'
  },
  {
    id: 8,
    name: '210px'
  },
  {
    id: 9,
    name: '82px',
    group: '112px'
  }
]
</script>

<style lang="scss" scoped>
.wtl-Loader_Row {
  height: 45px;
  padding: 0 $page-right-padding 0 $page-left-padding;
  display: flex;
  align-items: center;
}

.wtl-Cell {
  display: flex;
  align-items: center;

  &:first-child {
    gap: 8px;
    flex: 1 0 auto;
  }

  &:nth-child(2) {
    width: 576px;
  }
}
</style>
