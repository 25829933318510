<template>
  <div class="slu-SidebarLayout">
    <div :class="{ 'slu-Main-reversed': reversed }" class="slu-Main">
      <div
        :ref="REFERENCES.CONTENT"
        :class="{ [CUSTOM_SCROLLBAR_CLASS]: true }"
        class="slu-Content"
      >
        <slot />
      </div>
      <div :ref="REFERENCES.SIDEBAR" class="slu-Sidebar">
        <slot name="sidebar" />
      </div>
    </div>
    <div v-if="!footerIsEmpty" class="slu-Footer">
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
import { isEmpty, isEqual } from 'lodash'
import PerfectScrollbar from 'perfect-scrollbar'
import { defineComponent } from 'vue'

import 'perfect-scrollbar/css/perfect-scrollbar.css'
import { CUSTOM_SCROLLBAR_CLASS } from '@/utils/general'
import { slotIsEmpty } from '@/utils/slots'

const REFERENCES = {
  SIDEBAR: 'sidebar',
  CONTENT: 'content'
}

export default defineComponent({
  name: 'SidebarLayout',

  props: {
    reversed: {
      type: Boolean
    },

    updateScrollbarTriggers: {
      type: Array,
      default: () => []
    },

    scrollToTopOnScrollbarUpdate: {
      type: Boolean
    }
  },

  data() {
    return {
      psInstances: {
        ...Object.fromEntries(Object.keys(REFERENCES).map(item => [item, null]))
      }
    }
  },

  computed: {
    REFERENCES: () => REFERENCES,
    CUSTOM_SCROLLBAR_CLASS: () => CUSTOM_SCROLLBAR_CLASS,

    footerIsEmpty() {
      return slotIsEmpty(this.$slots.footer)
    }

    // styles() {
    //   const { gap, sidebarWidth } = this
    //   if (this.reversed) {
    //     return {
    //        [REFERENCES.CONTENT]: {
    //          padding: `0 ${gap}px 0 0`,
    //          flex: `0 0 calc(100% - ${sidebarWidth} - ${gap}px)`
    //        },
    //        [REFERENCES.SIDEBAR]: {
    //          padding: `0 ${gap}px 0 ${gap}px`,
    //          flex: `0 0 calc(${sidebarWidth} + ${gap}px)`
    //        }
    //     }
    //   }
    //   return {
    //      [REFERENCES.CONTENT]: {
    //        padding: `0 ${gap}px 0 ${gap}px`,
    //        flex: `0 0 calc(100% - ${sidebarWidth})`
    //      },
    //      [REFERENCES.SIDEBAR]: {
    //        padding: `0 ${gap}px 0 0`,
    //        flex: `0 0 ${sidebarWidth}`
    //      }
    //   }
    // }
  },

  watch: {
    updateScrollbarTriggers: {
      handler(newValue, oldValue) {
        if (newValue && oldValue && !isEqual(newValue, oldValue) && !isEmpty(newValue)) {
          const { psInstances } = this
          Object.keys(psInstances).forEach(instance => {
            if (psInstances[instance]) {
              requestAnimationFrame(() => {
                if (this.scrollToTopOnScrollbarUpdate) {
                  psInstances[instance].element.scrollTop = 0
                }
                psInstances[instance].update()
              })
            }
          })
        }
      },

      deep: true,
      immediate: true
    }
  },

  mounted() {
    Object.entries(REFERENCES).forEach(([key, reference]) => {
      this.psInstances[key] = new PerfectScrollbar(this.$refs[reference], {
        suppressScrollX: true,
        swipeEasing: true
      })
    })
  },

  beforeUnmount() {
    const { psInstances } = this
    Object.keys(psInstances).forEach(instance => {
      if (psInstances[instance]) {
        psInstances[instance].destroy()
        psInstances[instance] = null
      }
    })
  }
})
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';
@import '~@/assets/styles/okr-modal';

.slu-SidebarLayout {
  height: 100%;
  display: grid;
  grid-auto-rows: auto 1fr;

  --scrollbar-width: 10px;
  --sidebar-gap: var(--sidebar-layout-gap, #{$okr-element-modal-default-gap});
  --resolved-gap: max(var(--sidebar-gap), var(--scrollbar-width));
  --sidebar-width: var(--sidebar-layout-sidebar-width, 280px);

  width: 100%;
  margin-inline: auto;
  /**
  @note max width can be specified in the parent component
  @note by using the --okr-element-form-content-max-width variable
  @note same variable is used for OkrBreadcrumbs.vue component
  @note but also can be specified by own variable --sidebar-layout-width
  @note for using in another components without breadcrumbs
   */
  max-width: calc(
    var(--okr-element-form-content-max-width, var(--sidebar-layout-width, auto)) +
      (var(--resolved-gap) * 2)
  );
}

.slu-Main {
  display: flex;
  overflow: hidden;

  &-reversed {
    flex-direction: row-reverse;
  }
}

.slu-Content {
  position: relative;
  overflow-y: auto;
}

.slu-Sidebar {
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
}

%scroll-gradient {
  content: '';
  position: sticky;
  height: 26px;
  width: 100%;
  display: block;
  z-index: 3;
  pointer-events: none;
}

.slu-Content {
  padding: 0 var(--resolved-gap) 0 var(--resolved-gap);
  flex: 0 0 calc(100% - var(--sidebar-width));

  .slu-Main-reversed & {
    padding: 0 var(--resolved-gap) 0 0;
    flex: 0 0 calc(100% - var(--sidebar-width) - var(--resolved-gap));
  }
}

.slu-Sidebar {
  padding: 0 var(--resolved-gap) 0 0;
  flex: 0 0 var(--sidebar-width);

  .slu-Main-reversed & {
    padding: 0 var(--resolved-gap) 0 var(--resolved-gap);
    flex: 0 0 calc(var(--sidebar-width) + var(--resolved-gap));
  }
}

.slu-Content,
.slu-Sidebar {
  &:before {
    @extend %scroll-gradient;
    top: 0;

    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 1) 15%,
      rgba(255, 255, 255, 0.4) 80%,
      rgba(167, 105, 246, 0) 100%
    );
  }
}

.slu-Sidebar {
  &:after {
    @extend %scroll-gradient;
    bottom: 0;

    background: linear-gradient(
      180deg,
      rgba(167, 105, 246, 0) 15%,
      rgba(255, 255, 255, 0.4) 80%,
      rgba(255, 255, 255, 1) 100%
    );
  }
}

.slu-Footer {
  padding: 20px var(--resolved-gap);
  margin-top: 16px;
  position: relative;

  &:before {
    position: absolute;
    content: '';
    height: 1px;
    width: calc(100% + #{$depth-shift});
    background-color: $grey-2-next;
    left: calc(-1 * #{$depth-shift});
    top: 0;
    pointer-events: none;
  }
}

.slu-Content,
.slu-Sidebar {
  &:deep(.ps__rail-y) {
    width: var(--scrollbar-width);
    right: calc((var(--resolved-gap) - var(--scrollbar-width)) / 2);
    background: transparent;

    &:hover {
      width: var(--scrollbar-width);
      .ps__thumb-y {
        width: 8px;
      }
    }

    .ps__thumb-y {
      right: 1px;
      max-width: 8px;
    }
  }
}
</style>
