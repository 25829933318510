<template>
  <span v-if="item.lastGradeUpdateDate" class="oboard-truncated-text lgu-Cell">
    {{ formatDate(item.lastGradeUpdateDate) }}
  </span>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'LastGradeUpdateCell'
})
</script>

<script setup>
import { formatDate } from '@/utils/date'

defineProps({
  item: {
    type: Object,
    required: true
  }
})
</script>

<style lang="scss" scoped>
.lgu-Cell {
  opacity: 0.8;
}
</style>
