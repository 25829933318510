<template>
  <div
    v-if="isStakeholderListType ? !isEmptyObjectives : true"
    :class="{
      'psoe-List_Stakeholder': isStakeholderListType,
      [`psoe-List-search-type-${searchTypeName}`]: searchTypeId
    }"
    :data-auto-testid="`search-type-${searchTypeName}-list-type-${listType}`"
    :data-testid="`search-type-${searchTypeName}-list-type-${listType}`"
    class="psoe-List"
  >
    <OkrElementsList
      :hide-actions="hideActions"
      :objectives="objectives"
      :show-last-comment="showLastComment"
      :tracking-source="EVENT_SOURCES.HOMEPAGE"
      always-show-weight
      class="psoe-List_Items"
      dim-all-weights
      has-add-kr-action
      hide-weight-icon
      highlight-zero-depth
      item-type="primary-next"
      show-avatar-replacer
      show-grade-info
      show-grade-update
      some-item-is-expandable
      transparent-filtered
      type="default-next"
      with-virtual-scroll
      @expand-item="expandItem"
      @update-elements="updateElements"
      @okr-element-deleted="$emit('okr-element-deleted')"
      @edit-element="$emit('edit-element', $event)"
      @link-jira-issue="$emit('link-jira-issue', $event)"
      @create-jira-issue="$emit('create-jira-issue', $event)"
      @create-key-result="$emit('create-key-result', $event)"
    >
      <div class="psoe-TitleWrapper">
        <AppTitle
          :class="{ 'psoe-List_Title-no-objectives': isEmptyObjectives }"
          :level="3"
          class="psoe-List_Title"
          disable-margin
        >
          {{ title }}
        </AppTitle>
        <div class="psoe-List_RightSide">
          <div>
            <div v-if="isStakeholderListType && !isEmptyObjectives" class="psoe-StakeholderWrapper">
              {{ $t('dashboard.avg_progress') }}
              <ListItemGradeInfo
                :grade="objectives.length"
                :grade-info="{}"
                :icon-height="24"
                :icon-width="24"
                :options="getStakeholderOptions"
                class="psoe-GradeInfo"
                data-export-ignore
              />
            </div>
          </div>
          <span v-if="!isEmptyObjectives" class="psoe-StakeholderWrapper">
            {{ $t('objectives.table_header_gradeUpdated') }}
          </span>
        </div>
      </div>

      <AppDivider v-if="!isEmptyObjectives && !loading" border-width="2px" no-margin />

      <template v-if="isEmptyObjectives && !loading" #no-objectives>
        <OkrElementsEmpty hide-hero>
          {{ getEmptyStateText }}
        </OkrElementsEmpty>
      </template>
    </OkrElementsList>
    <UserReportOkrElementsLoader v-if="loading" />
    <AppLoadMoreButton v-if="showLoadMoreButton && !loading" @click="loadMore" />
  </div>
</template>

<script>
import { isEmpty } from 'lodash'
import { defineComponent } from 'vue'

import ObjectivesApiHandler, { SEARCH_TYPES } from '@/api/okr-elements'
import { EVENT_SOURCES } from '@/tracking/amplitude-helpers'
import { handleError } from '@/utils/error-handling'
import { getAvailableCustomRangeParams, USER_LIST_TYPES } from '@/utils/homepage'
import { UNSELECTED_INTERVAL } from '@/utils/interval'
import { HOMEPAGE_OKR_SEARCH_TYPE_IDS } from '@/utils/objectives'
import { stringOrNullProp } from '@/utils/prop-validators'

import OkrElementsEmpty from '@/components/home-page/OkrElementsEmpty'
import ListItemGradeInfo from '@/components/objectives/okr-elements-list/ListItemGradeInfo'
import OkrElementsList from '@/components/objectives/okr-elements-list/OkrElementsList'
import AppDivider from '@/components/ui/AppDivider'
import AppLoadMoreButton from '@/components/ui/AppLoadMoreButton/AppLoadMoreButton'
import AppTitle from '@/components/ui/AppTitle/AppTitle'
import UserReportOkrElementsLoader from '@/components/ui/SkeletonLoaders/UserReportOkrElementsLoader'

const DEFAULT_PAYLOAD = {
  dueDateFrom: null,
  dueDateTo: null,
  levelIds: null,
  gradeTypes: null,
  typeIds: null,
  order: [1],
  childOrder: [1],
  searchType: SEARCH_TYPES.HOMEPAGE,
  searchString: null
}

const DEFAULT_LIMIT = 10

export default defineComponent({
  name: 'PersonalStatisticsOkrElementsList',
  components: {
    ListItemGradeInfo,
    AppLoadMoreButton,
    AppDivider,
    UserReportOkrElementsLoader,
    OkrElementsEmpty,
    OkrElementsList,
    AppTitle
  },

  props: {
    selectedIntervals: {
      type: Array,
      required: true
    },

    selectedDates: {
      type: Object,
      required: true
    },

    selectedUserOrGroup: {
      type: [String, Number],
      required: true
    },

    userSelected: {
      type: Boolean
    },

    listType: {
      type: String,
      validator: v => Object.values(USER_LIST_TYPES).includes(v),
      default: null
    },

    predefinedWorkspaceId: {
      type: Number,
      default: null
    },

    selectedUserName: {
      default: '',
      validator: v => stringOrNullProp(v)
    },

    selectedGroupName: {
      default: '',
      validator: v => stringOrNullProp(v)
    },

    hideActions: {
      type: Boolean
    },

    stakeholdersGrade: {
      type: Object,
      default: () => ({})
    },

    searchTypeId: {
      type: Number,
      default: null
    },

    showLastComment: {
      type: Boolean
    }
  },

  emits: {
    'link-jira-issue': null,
    'create-jira-issue': null,
    'okr-element-deleted': null,
    'edit-element': null,
    'create-key-result': null
  },

  data() {
    return {
      loading: false,
      offset: 0,
      showLoadMoreButton: true,
      objectives: [],
      expandedItems: [],
      limit: DEFAULT_LIMIT
    }
  },

  computed: {
    EVENT_SOURCES: () => EVENT_SOURCES,

    isEmptyObjectives() {
      return isEmpty(this.objectives)
    },

    searchTypeName() {
      return this.searchTypeId === HOMEPAGE_OKR_SEARCH_TYPE_IDS.OBJECTIVES ? 'objectives' : 'krs'
    },

    payloadKeys() {
      return {
        [USER_LIST_TYPES.OWNERS]: 'ownerIds',
        [USER_LIST_TYPES.FOREIGN_OBJECTIVES]: 'ownerIds',
        [USER_LIST_TYPES.STAKEHOLDERS]: 'stakeholderIds',
        [USER_LIST_TYPES.WATCHERS]: 'watcherIds'
      }
    },

    role() {
      const { listType, $t } = this
      if (listType === null) {
        return $t('homepage.role.group')
      } else {
        const { OWNERS, STAKEHOLDERS, WATCHERS } = USER_LIST_TYPES
        const roles = {
          [OWNERS]: $t('homepage.role.owner'),
          [STAKEHOLDERS]: $t('homepage.role.stakeholder'),
          [WATCHERS]: $t('homepage.role.watcher')
        }

        return roles[listType]
      }
    },

    isForeignObjectiveRole() {
      return this.listType === USER_LIST_TYPES.FOREIGN_OBJECTIVES
    },

    getEmptyStateText() {
      if (this.isForeignObjectiveRole) {
        return this.$t('homepage.okr_elements_empty_foreign_objectives', {
          name: this.selectedUserName || this.selectedGroupName
        })
      } else {
        return this.searchTypeId === HOMEPAGE_OKR_SEARCH_TYPE_IDS.KEY_RESULTS
          ? this.$t('homepage.okr_elements_empty_krs')
          : this.$t('homepage.okr_elements_empty', { role: this.role })
      }
    },

    requestPayload() {
      const {
        selectedIntervals,
        userSelected,
        selectedUserOrGroup,
        $route,
        listType,
        offset,
        limit,
        expandedItems: itemsToOpen
      } = this
      const workspaceId =
        this.predefinedWorkspaceId !== null ? this.predefinedWorkspaceId : $route.params.workspaceId
      // let searchTypeId = this.searchTypeId
      let inForeignObjectives = null

      // if (listType === USER_LIST_TYPES.OWNERS) {
      //   searchTypeId = HOMEPAGE_OKR_SEARCH_TYPE_IDS.OBJECTIVES
      // } else if (listType === USER_LIST_TYPES.FOREIGN_OBJECTIVES) {
      //   searchTypeId = HOMEPAGE_OKR_SEARCH_TYPE_IDS.KEY_RESULTS
      //   inForeignObjectives = true
      // }
      if (listType === USER_LIST_TYPES.FOREIGN_OBJECTIVES) {
        inForeignObjectives = true
      }

      return {
        workspaceId,
        groupIds: userSelected ? null : [selectedUserOrGroup],
        [this.payloadKeys[listType]]: userSelected ? [selectedUserOrGroup] : null,
        intervalIds: isEmpty(selectedIntervals) ? UNSELECTED_INTERVAL : selectedIntervals,
        offset,
        limit,
        itemsToOpen,

        ...DEFAULT_PAYLOAD,
        searchType: this.searchTypeId,
        inForeignObjectives,
        ...getAvailableCustomRangeParams({
          selectedDates: this.selectedDates,
          intervalIds: this.selectedIntervals
        })
      }
    },

    title() {
      if (this.userSelected) {
        const titles = {
          [USER_LIST_TYPES.OWNERS]: 'homepage.objectives.owner',
          [USER_LIST_TYPES.FOREIGN_OBJECTIVES]: 'homepage.objectives.foreign_objectives',
          [USER_LIST_TYPES.STAKEHOLDERS]: 'homepage.objectives.stakeholder',
          [USER_LIST_TYPES.WATCHERS]: 'homepage.objectives.watcher'
        }
        return this.$t(titles[this.listType], { userName: this.selectedUserName })
      } else {
        const titles = {
          [USER_LIST_TYPES.OWNERS]: 'homepage.objectives_title',
          [USER_LIST_TYPES.FOREIGN_OBJECTIVES]: 'homepage.objectives.foreign_objectives'
        }
        return this.$t(titles[this.listType], {
          groupName: this.selectedGroupName,
          userName: this.selectedGroupName
        })
      }
    },

    isStakeholderListType() {
      return this.listType === USER_LIST_TYPES.STAKEHOLDERS
    },

    getStakeholderOptions() {
      return [
        {
          title: this.$t('okr_elements.objectives'),
          value: this.stakeholdersGrade.objectives
        },
        {
          title: this.$t('objective.key_results'),
          value: this.stakeholdersGrade.krsAndTasks
        }
      ]
    }
  },

  methods: {
    expandItem(id) {
      const { expandedItems } = this
      this.expandedItems = expandedItems.includes(id)
        ? expandedItems.filter(item => item !== id)
        : [...expandedItems, id]
    },

    async fetchObjectives(replaceElements = false) {
      this.loading = true
      const currentOffset = this.offset
      if (replaceElements) {
        this.limit = currentOffset || DEFAULT_LIMIT
        this.offset = 0
      }
      const objectivesApi = new ObjectivesApiHandler()
      try {
        const data = await objectivesApi.getObjectives({ ...this.requestPayload })
        this.showLoadMoreButton = data.length === DEFAULT_LIMIT
        if (this.showLoadMoreButton) {
          this.offset += this.limit
        }
        this.objectives = replaceElements ? data : [...this.objectives, ...data]
      } catch (error) {
        handleError({ error })
      }
      // if (replaceElements) {
      //   this.offset = currentOffset
      //   this.limit = DEFAULT_LIMIT
      // }
      this.loading = false
    },

    loadMore() {
      this.fetchObjectives()
    },

    /** @public */
    refreshData() {
      this.objectives = []
      this.expandedItems = []
      this.offset = 0
      this.limit = 10
      this.fetchObjectives()
    },

    /** @public */
    updateElements() {
      this.fetchObjectives(true)
    }
  }
})
</script>

<style lang="scss" scoped>
.psoe-List {
  --item-border-radius: #{$border-radius-md};
  --menu-trigger-postion: static;
  --right-items-gap: 24px;
}
.psoe-List_Stakeholder {
  border: 2px solid $grey-3-next;
  border-radius: $border-radius-lg-next;
  padding: 32px;
}
:deep(.psoe-List_Items) {
  margin: 0 -18px;
}

.psoe-List_Title {
  margin-bottom: 13px;
  line-height: 28px;
  font-weight: fw('semi-bold');
  font-family: $system-ui;
  color: $dark-1;

  &-no-objectives {
    color: $dark-3;
  }
}

.psoe-TitleWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.psoe-StakeholderWrapper {
  font-family: $system-ui;
  font-weight: fw('bold');
  font-size: $fs-12;
  line-height: 16px;
  color: $dark-3;
  display: flex;
  align-items: center;
  cursor: default;
  min-width: 148px;
}
.psoe-List {
  &:deep(.oeli-ItemContent-on-depth-0) {
    .okg-OkrGrade_MultiplierNext {
      visibility: hidden;
    }
  }
}

.psoe-GradeInfo {
  &:deep(.gi-Icon) {
    color: $dark-3;
    width: 24px;
  }
}
.psoe-List_RightSide {
  display: flex;
  justify-content: space-between;
  width: 398px;
}
</style>
