<template>
  <div class="ci-Wrapper">
    <div :id="`ci-CommentItem-${item.id}`" class="ci-CommentItem">
      <ContributeLine
        v-if="isShowContributeLine"
        :class="{
          'ci-CommentItem_ContributeLine-withReplyForm': replyFormOpen && !isChildCommentsExist,
          'ci-CommentItem_ContributeLine-isReplyComment': isReplyComment
        }"
        class="ci-CommentItem_ContributeLine"
      />
      <AppAvatar :avatar-url="item.userAvatarUrl" class="ci-Avatar" />
      <div ref="mainContentRef" class="ci-MainContent">
        <div class="ci-InfoAndActionsWrapper">
          <div class="ci-Name" data-testid="user-name">
            {{ item.userName }}
          </div>
          <div class="ci-Time" data-testid="create-date">
            {{ calculateCommentTime(item.createDate) }}
          </div>
          <div
            v-if="item.editDate"
            v-tippy="{
              content: editInfo,
              placement: 'top'
            }"
            class="ci-Edit"
          >
            {{ $t('comments.edited') }}
          </div>
          <div class="ci-Actions" data-testid="all-actions">
            <AppButton
              class="ci-ReplyBtn"
              data-testid="reply-button"
              size="sm"
              type="none"
              @click="onReplyBtnClick(null)"
            >
              <span class="ci-ReplyBtn_Content">
                <AppIcon class="ci-ReplyBtn_Icon" height="24" icon-name="reply" width="24" />
                {{ $t('action.reply') }}
              </span>
            </AppButton>
            <DropdownMenu
              v-if="!hideActions"
              :items="menuActions"
              :offset="[0, 0]"
              :type="DROPDOWN_MENU_TYPES.DEFAULT_NEXT"
              data-testid="dropdown-menu"
              @item-click="onMenuActionsClick($event, item.id)"
            >
              <template #activator>
                <AppButton class="ci-ReplyBtn" icon="more-next" size="sm" type="subtle" />
              </template>
            </DropdownMenu>
          </div>
        </div>

        <!-- eslint-disable-next-line vue/no-v-html -->
        <DescriptionField
          v-if="!isEdit"
          :model-value="item.text"
          :show-placeholder="false"
          :styled="false"
          :workspace-id="objective.workspaceId"
          readonly
          simple-view
        />
        <div
          v-if="!isReplyComment && getOtherChildCount > 0"
          class="ci-MoreReplies"
          data-testid="more-replies"
        >
          {{ $t('comments.more_reply', getOtherChildCount) }}
        </div>

        <CommentForm
          v-if="isEdit"
          :append-mention-to-body="appendMentionToBody"
          :hidden-features="hiddenFeatures"
          :model-value="item"
          :my-avatar="userData.userAvatarUrl"
          :objective="objective"
          :tracking-source="trackingSource"
          active
          data-testid="comment-form"
          tracking-mode="main"
          @update="$emit('update', $event)"
          @update:active="isEdit = $event"
        />
      </div>
    </div>

    <div v-if="isChildCommentsExist || replyFormOpen" class="ci-Replies">
      <CommentList
        v-if="isChildCommentsExist"
        :can-be-replied="false"
        :hide-actions="hideActions"
        :items="item.childComments"
        :objective="objective"
        :parent-reply-form-open="replyFormOpen"
        :user-data="userData"
        data-testid="child-comments"
        is-reply-comment
        @remove="$emit('remove', $event)"
        @reply="onReplyBtnClick"
        @update="$emit('update-reply', $event)"
      />

      <CommentForm
        v-if="replyFormOpen"
        ref="commentForm"
        :append-mention-to-body="appendMentionToBody"
        :hidden-features="hiddenFeatures"
        :my-avatar="userData.userAvatarUrl"
        :objective="objective"
        :reply-to="item.id"
        :tracking-source="trackingSource"
        active
        data-testid="reply-form"
        tracking-mode="reply"
        @create="$emit('create-reply', $event)"
        @update="$emit('update-reply', $event)"
        @update:active="setReplyFormOpen"
      />
    </div>
  </div>
</template>

<script>
import { has, isEmpty } from 'lodash'
import { defineComponent } from 'vue'
import { mapState } from 'vuex'

import CommentsApiHandler from '@/api/comments'
import { TRACKING_UNKNOWN } from '@/tracking/amplitude-helpers'
import { DROPDOWN_MENU_TYPES } from '@/utils/components-configurations/dropdown-menu'
import { getMonthList, getTimeDiffs } from '@/utils/date'
import { MENU_ITEMS_GROUPS } from '@/utils/dropdown-menu'
import { handleError } from '@/utils/error-handling'

import ContributeLine from '@/components/objectives/forms/ContributeLine'
import DescriptionField from '@/components/objectives/forms/DescriptionField'
import CommentForm from '@/components/objectives/forms/objective-form/CommentForm'
import CommentList from '@/components/objectives/forms/objective-form/CommentList'
import AppAvatar from '@/components/ui/AppAvatar/AppAvatar'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppIcon from '@/components/ui/AppIcon/AppIcon'
import DropdownMenu from '@/components/ui/DropdownMenu/DropdownMenu'

export default defineComponent({
  name: 'CommentItem',
  components: {
    DescriptionField,
    AppAvatar,
    AppButton,
    DropdownMenu,
    CommentForm,
    CommentList,

    ContributeLine,
    AppIcon
  },

  props: {
    item: {
      type: Object,
      required: true
    },

    userData: {
      type: Object,
      required: true
    },

    objective: {
      type: Object,
      required: true
    },

    canBeReplied: {
      type: Boolean,
      default: false
    },

    hideActions: {
      type: Boolean
    },

    isReplyComment: {
      type: Boolean
    },

    isLast: {
      type: Boolean
    },

    parentReplyFormOpen: {
      type: Boolean
    },

    trackingSource: {
      type: String,
      default: TRACKING_UNKNOWN
    },

    hiddenFeatures: {
      type: Array,
      default: () => []
    },

    appendMentionToBody: {
      type: Boolean,
      default: true
    }
  },

  emits: { update: null, 'update-reply': null, remove: null, 'create-reply': null, reply: null },

  data() {
    return {
      isEdit: false,
      replyFormOpen: false,
      editableCommentId: null,
      resizeObserver: null,
      mainContentHeight: '0px'
    }
  },

  computed: {
    DROPDOWN_MENU_TYPES: () => DROPDOWN_MENU_TYPES,

    ...mapState('pluginOptions', {
      isPluginAdmin: state => state.isPluginAdmin
    }),

    getOtherChildCount() {
      return this.item.childCount - 1
    },

    isShowContributeLine() {
      const { isLast, isReplyComment, isChildCommentsExist, replyFormOpen, parentReplyFormOpen } =
        this

      // If this comment is the last comment, and the parent has a reply form open, we show the contribute line.
      const showContributeLineIfLastCommentAndParentHasReplyFormOpen =
        !isLast && (isChildCommentsExist || replyFormOpen || isReplyComment)

      // If this comment has child comments or a reply form open, we show the contribute line.
      const showContributeLineIfCommentHasChildCommentsOrReplyFormOpen =
        (isLast && parentReplyFormOpen) || isChildCommentsExist || replyFormOpen

      return (
        showContributeLineIfLastCommentAndParentHasReplyFormOpen ||
        showContributeLineIfCommentHasChildCommentsOrReplyFormOpen
      )
    },

    isChildCommentsExist() {
      return has(this.item, 'childComments') && !isEmpty(this.item.childComments)
    },

    editInfo() {
      return `${this.calculateCommentTime(this.item.editDate)} by ${this.item.userName}`
    },

    menuActions() {
      const result = []
      if (this.userData.userAccountId === this.item.accountId) {
        result.push({
          name: 'edit',
          title: 'action.edit',
          icon: 'edit-next',
          group: MENU_ITEMS_GROUPS.EDITING
        })
      }

      if (this.isPluginAdmin || this.userData.userAccountId === this.item.accountId) {
        result.push({
          name: 'remove',
          title: 'action.delete',
          icon: 'delete-next',
          group: MENU_ITEMS_GROUPS.REMOVING,
          color: 'var(--grade-low-color-next)'
        })
      }

      return result
    }
  },

  mounted() {
    this.initResizeObserver()
  },

  beforeUnmount() {
    this.disconnectObserver()
  },

  methods: {
    setReplyFormOpen(value) {
      this.replyFormOpen = value
    },

    async initResizeObserver() {
      await this.$nextTick()
      const { mainContentRef } = this.$refs
      this.resizeObserver = new ResizeObserver(() => {
        this.mainContentHeight = mainContentRef.getBoundingClientRect().height + 'px'
      })

      this.resizeObserver.observe(mainContentRef)
    },

    disconnectObserver() {
      if (this.resizeObserver) {
        this.resizeObserver.disconnect()
        this.resizeObserver = null
      }
    },

    calculateCommentTime(item) {
      const createdOn = new Date(item)
      const now = new Date()

      const { diffForDay, diffForHour, diffForMin } = getTimeDiffs(now, createdOn)

      const formatHours = `0${createdOn.getHours()}`.slice(-2)
      const formatMins = `0${createdOn.getMinutes()}`.slice(-2)

      if (diffForDay > 7) {
        return `${
          getMonthList()[createdOn.getMonth()]
        } ${createdOn.getDate()}, ${formatHours}:${formatMins}`
      }

      if (diffForDay >= 1) {
        return `${
          getMonthList()[createdOn.getMonth()]
        } ${createdOn.getDate()}, ${createdOn.getFullYear()} ${formatHours}:${formatMins}`
      }

      if (diffForHour >= 1 && diffForDay < 1) {
        return `${diffForHour} ${this.$t('objective.hours_ago')}`
      }

      if (diffForHour < 1 && diffForMin >= 1) {
        return `${diffForMin} ${this.$t('token.minutes_ago')}`
      }

      if (diffForMin < 1) {
        return this.$t('objective.a_moment_ago')
      }

      return `${diffForDay} ${this.$t('objectives.days_ago')}`
    },

    onMenuActionsClick(name, editableCommentId) {
      if (name === 'remove') {
        this.remove()
      } else {
        this.edit(editableCommentId)
      }
    },

    async remove() {
      try {
        const commentsApi = new CommentsApiHandler()
        await commentsApi.removeComment({
          commentId: this.item.id,
          remove: true
        })
        this.$emit('remove', this.item)
      } catch (error) {
        handleError({ error })
      }
    },

    edit(editableCommentId) {
      this.isEdit = true
      this.editableCommentId = editableCommentId
    },

    onReplyBtnClick(payload) {
      if (this.canBeReplied === false) {
        this.$emit('reply', this.item)
      } else {
        this.replyFormOpen = true
        const commentPayload = payload || this.item

        this.$nextTick(() => {
          this.$refs.commentForm.replyOnComment(commentPayload)
        })
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.ci-Wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.ci-CommentItem {
  display: flex;
  position: relative;
}

.ci-MainContent {
  flex: 1 1 auto;
  font-family: $system-ui;
  font-style: normal;
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow: hidden;
}

.ci-InfoAndActionsWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  height: 24px;
}

.ci-Name {
  font-weight: fw('semi-bold');
  color: $primary-color-next;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ci-Avatar {
  align-self: flex-start;
  position: sticky;
  top: 0;
  z-index: 1;
}

.ci-Time,
.ci-Edit {
  font-style: normal;
  font-weight: fw('regular');
  font-size: $fs-14;
  line-height: 20px;
  color: $dark-3;
  flex: 0 0 auto;
}

//.ci-Message {
//  line-height: 24px;
//}
.ci-Actions {
  display: flex;
  visibility: hidden;
  gap: 16px;
  align-items: center;
  flex: 0 0 auto;
  margin-left: auto;

  .ci-CommentItem:hover & {
    visibility: visible;
  }
  &:deep(.o-droplist--expanded) {
    .ci-ReplyBtn {
      visibility: visible;
    }
  }
}

// .ci-EmojiMenu {
//   margin-right: 4px;
// }

//.ci-Message {
//  width: 100%;
//  word-break: break-word;
//  font-weight: fw('regular');
//  font-size: $fs-14;
//  line-height: 20px;
//  color: $dark-1;
//}

.ci-ReplyBtn {
  color: $dark-2;
  font-family: $system-ui;
  font-weight: fw('regular');
  visibility: hidden;

  &_Content {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .ci-CommentItem:hover & {
    visibility: visible;
  }
  &:hover {
    color: $primary-color-next;
    .ci-ReplyBtn_Icon {
      background-color: $grey-3-next;
      border-radius: $border-radius-sm-next;
    }
  }
}

.ci-Replies {
  margin-left: 32px;
  //margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  &:deep(.cf-CommentForm) {
    padding: 0;
    z-index: 2;
  }
}

.ci-CommentItem_ContributeLine {
  position: absolute;
  left: 11px;
  top: 33px;
  --height: calc(100% + 4px);
  //--height: 100%;
  z-index: 1;
  &-isReplyComment {
    left: -21px;
    --height: calc(100% + 34px);
    top: 5px;
  }
  &-withReplyForm {
    --height: calc(100% + 12px);
  }
}
.ci-MoreReplies {
  font-size: $fs-14;
  font-weight: fw('regular');
  line-height: 20px;
  color: $dark-3;
  margin-top: 2px;
}
</style>

<style lang="scss">
@import '~@/assets/styles/mixins';

.ci-CommentItem {
  .o-droplist-standalone {
    min-height: 16px;
  }

  .o-textarea-field {
    width: calc(100% - 52px);
  }

  //.ci-Message * + p {
  //  margin-bottom: 0;
  //  margin-top: 0.75rem;
  //}
  //.ci-Message p {
  //  margin-bottom: 0;
  //}
  //
  //.ci-Message span.mention {
  //  @extend %mention-item;
  //}
  //
  //.ci-Message ol {
  //  padding-left: 18px;
  //  margin: 0;
  //}
  //
  //.ci-Message ul {
  //  padding-left: 18px;
  //  margin-bottom: 0;
  //}
}
</style>
