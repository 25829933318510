export const DEV_ACCESS_TOKEN_LS_KEY = 'DEV_ACCESS_TOKEN'
export const DEV_REFRESH_TOKEN_LS_KEY = 'DEV_REFRESH'

export const getDevAccessToken = () => {
  const devAccessToken = localStorage.getItem(DEV_ACCESS_TOKEN_LS_KEY)
  return devAccessToken || process.env.VUE_APP_WEB_APP_DEV_3_ACCESS_TOKEN
}

export const saveDevAccessToken = (token, saveFromTemplateOptions = false) => {
  if (saveFromTemplateOptions) {
    localStorage.setItem(DEV_ACCESS_TOKEN_LS_KEY, process.env.VUE_APP_WEB_APP_DEV_3_ACCESS_TOKEN)
    return
  }
  localStorage.setItem(DEV_ACCESS_TOKEN_LS_KEY, token)
}
export const getDevRefreshToken = () => {
  const devRefreshToken = localStorage.getItem(DEV_REFRESH_TOKEN_LS_KEY)
  return devRefreshToken || process.env.VUE_APP_WEB_APP_DEV_3_REFRESH_TOKEN
}

export const saveDevRefreshToken = (token, saveFromTemplateOptions = false) => {
  if (saveFromTemplateOptions) {
    localStorage.setItem(DEV_REFRESH_TOKEN_LS_KEY, process.env.VUE_APP_WEB_APP_DEV_3_REFRESH_TOKEN)
    return
  }
  localStorage.setItem(DEV_REFRESH_TOKEN_LS_KEY, token)
}
