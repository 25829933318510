import ApiHandler from '@/api/base'

class BillingApiHandler extends ApiHandler {
  async getBillingPlanDetails() {
    const { data } = await this.http.get('/billing/plans/current')
    return data
  }

  async updateSubscription({ planName, billingCycle, tierIndex }) {
    const { data } = await this.http.put('/billing/plans/current', {
      planName,
      billingCycle,
      tierIndex
    })
    return data
  }

  async getBillingPortalSession() {
    const { data } = await this.http.post('/billing/customer/portal')
    return data
  }

  async getCustomerInfo() {
    const { data } = await this.http.get('/billing/customer/contacts')
    return data
  }

  async getCountryCodes() {
    const { data } = await this.http.get('/billing/country-codes')
    return data
  }

  async getStateCodes({ countryCode }) {
    const { data } = await this.http.get(`/billing/state-codes`, {
      params: {
        countryCode
      }
    })
    return data
  }

  async saveCustomerInfo({
    billingName,
    billingPhone,
    billingEmail,
    billingAddressLine1,
    billingAddressLine2,
    billingAddressCity,
    billingAddressState,
    billingAddressPostalCode,
    billingAddressCountry
  }) {
    const { data } = await this.http.put('/billing/customer/contacts', {
      billingName,
      billingPhone,
      billingEmail,
      billingAddressLine1,
      billingAddressLine2,
      billingAddressCity,
      billingAddressState,
      billingAddressPostalCode,
      billingAddressCountry
    })
    return data
  }

  async getTiers({ name, cycle = 'year' }) {
    const { data } = await this.http.get(`/billing/plans/tiers`, {
      params: {
        name,
        cycle
      }
    })
    return data
  }

  async updateTiers({ tierIndex }) {
    const { data } = await this.http.put(`/billing/plans/current/tier`, {
      tierIndex
    })
    return data
  }

  async cancelSubscription() {
    const { data } = await this.http.delete('/billing/plans/current')
    return data
  }

  async renewSubscription() {
    const { data } = await this.http.post('/billing/plans/current/renew')
    return data
  }

  async contactUs({ customerName, businessEmail, companyName, companySize }) {
    const { data } = await this.http.post('/billing/contact-us', {
      customerName,
      businessEmail,
      companyName,
      companySize
    })
    return data
  }

  async getUpcomingInvoice({ name, cycle, tierIndex = null }) {
    const { data } = await this.http.get(`/billing/invoices/upcoming`, {
      params: {
        name,
        cycle,
        tierIndex
      }
    })
    return data
  }

  async getCheckoutSession({ name, cycle, tierIndex = null }) {
    const { data } = await this.http.post(`/billing/plans/checkout-session`, {
      name,
      cycle,
      tierIndex
    })
    return data
  }

  async getBillingInvoices({ startingAfter = null, limit = 5 }) {
    const { data } = await this.http.get('/billing/invoices', {
      params: {
        limit,
        startingAfter
      }
    })
    return data
  }

  async cancelDowngrade() {
    const { data } = await this.http.post('/billing/plans/current/downgrade/cancel')
    return data
  }

  async refreshSubscriptionStatus() {
    return this.http.post('/billing/status/refresh')
  }

  async earlyBirdCheckoutSession() {
    const { data } = await this.http.get('/billing/plans/early-bird/checkout-session')
    return data
  }
}

export default BillingApiHandler
