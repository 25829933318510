<template>
  <BulkActionsOperationValueControl
    v-model="localModelValue"
    :empty-state-label="
      t('bulk_actions.select_custom_field_value', {
        customFieldName: fieldName
      })
    "
    :field-id="fieldTypeId"
    :item-label="ASSIGNEE_ENTITY_KEYS.NAME"
    :item-value="ASSIGNEE_ENTITY_KEYS.ACCOUNT_ID"
    :loading="isLoading"
    :options="assignees"
    :search-function="getAssignees"
    is-custom-field
    @update:options="assignees = $event"
    @toggle-value-dropdown="emit('toggle-value-dropdown', $event)"
  />
</template>

<script setup>
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { useFetchAssignees } from '@/utils/custom-fields/use-assignees'
import { useCustomFieldsHelpers } from '@/utils/custom-fields/use-custom-fields'
import { ASSIGNEE_ENTITY_KEYS } from '@/utils/entity-keys'

import BulkActionsOperationValueControl from '@/components/objectives/bulk-edit/BulkActionsOperationValueControl'

defineOptions({
  name: 'AssigneeField',
  inheritAttrs: false
})

const props = defineProps({
  modelValue: {
    type: Object,
    required: true
  },

  workspaceId: {
    type: [String, Number],
    required: true
  },

  fieldTypeId: {
    type: [String, Number],
    required: true
  },

  fieldId: {
    type: [String, Number],
    required: true
  }
})

const { t } = useI18n()

const { fieldName } = useCustomFieldsHelpers({ fieldId: props.fieldId })

const assignees = ref([])

const { isLoading, getAssignees } = useFetchAssignees({
  workspaceId: props.workspaceId,
  selectedAssignees: []
})

onMounted(async () => {
  assignees.value = await getAssignees()
})

const emit = defineEmits({
  'update:model-value': null,
  'toggle-value-dropdown': null
})

const localModelValue = computed({
  get: () => {
    const { value, operation } = props.modelValue

    return {
      value,
      operation
    }
  },
  set: newValue => {
    emit('update:model-value', newValue)
  }
})
</script>

<style lang="scss" scoped></style>
