<template>
  <div class="bs-Step">
    <slot name="step-title" />

    <div class="bs-Step_Boxes">
      <template v-for="(box, index) in BOXES" :key="box.color">
        <a
          :href="box.link"
          :style="{
            '--color': `var(${box.color})`,
            '--color-rgb': box.rgb
          }"
          class="bs-Boxes_Item"
          target="_blank"
          @click="onLinkClick(box.tracking)"
        >
          <AppImage v-if="box.person" :name="box.person" height="64" width="64" />

          <div class="bs-BoxContent">
            <AppTitle :level="3" class="bs-BoxTitle" disable-margin>
              {{ box.title }}
            </AppTitle>

            <i18n-t :keypath="box.subtitle" class="bs-BoxSubtitle" scope="global" tag="div" />
            <div class="bs-BoxButton">
              {{ box.linkText }}

              <AppIcon height="24" icon-name="shortcut" width="24" />
            </div>
          </div>
        </a>

        <AppDivider v-if="BOXES.length > 1 && index + 1 < BOXES.length" no-margin />
      </template>
    </div>
  </div>
</template>

<script setup>
import { lowerCase } from 'lodash'
import { useI18n } from 'vue-i18n'

import { tracker } from '@/tracking/amplitude'
import { EVENT_CATEGORIES } from '@/tracking/amplitude-helpers'
import { ONBOARDING_ROLES } from '@/utils/components-configurations/onboarding-hub'
import { VIKTOR_CALENDLY_LINK } from '@/utils/helpers'
import { memoizeGetCssVariableValue, memoizeHexToRgb } from '@/utils/memoizations'

import AppDivider from '@/components/ui/AppDivider'
import AppIcon from '@/components/ui/AppIcon/AppIcon'
import AppImage from '@/components/ui/AppImage'
import AppTitle from '@/components/ui/AppTitle/AppTitle'

defineOptions({
  name: 'BookingStep'
})

const { t } = useI18n()

const props = defineProps({
  role: {
    type: String,
    required: true,
    validator: v => Object.values(ONBOARDING_ROLES).includes(v)
  }
})

const BOXES = [
  {
    id: 0,
    title: 'onboarding.demo.product_demo',
    subtitle: 'onboarding.demo.product_demo.subtitle',
    linkText: 'support.okr_review.link.text',
    // hero: 'okr-review',
    // linkIcon: 'booking',
    color: '--grade-high-color-next',
    link: VIKTOR_CALENDLY_LINK,
    tracking: 'product demo',
    person: 'onboarding-demo'
  },

  {
    id: 1,
    title: 'support.okr_consulting',
    subtitle: 'onboarding.okr_consulting.subtitle',
    linkText: 'support.okr_consulting.link.text',
    //  hero: 'okr-consulting',
    // linkIcon: 'booking',
    color: '--grade-medium-color-next',
    link: 'https://calendly.com/dmytro-yarmak/1-hour-meeting',
    tracking: 'consulting',
    person: 'onboarding-consulting'
  }
].map(box => {
  const { color, title, linkText, ...rest } = box
  const hex = memoizeGetCssVariableValue(color)
  const rgb = memoizeHexToRgb(hex.trim())
  return {
    ...rest,
    hex,
    color,
    rgb,
    title: t(title),
    linkText: t(linkText)
  }
})

const onLinkClick = eventValue => {
  tracker.logEvent('clicked demo link', {
    category: EVENT_CATEGORIES.ONBOARDING,
    value: eventValue,
    role: lowerCase(props.role)
  })
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/marketing-boxes';
@import '~@/assets/styles/wizard-steps';

.bs-Step {
  @extend %wizard-step-styles;
}

.bs-Step_Boxes {
  display: grid;
  gap: inherit;
}

.bs-Boxes_Item {
  // --padding: 32px;
  //  @extend %box-style;
  text-decoration: none;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 16px;
}

// .bs-BoxHero {
//   //   @extend %box-hero-style;
// }

.bs-BoxContent {
  --box-content-gap: 8px;
  @extend %box-content-style;
}

.bs-BoxTitle {
  @extend %box-title-style;
}

.bs-BoxSubtitle {
  @extend %box-subtitle-style;
}

.bs-BoxButton {
  margin-top: calc(20px - var(--box-content-gap, 0));
  @extend %box-link-style;
  min-width: unset;
  --padding: 4px 4px 4px 16px;
}
</style>
