const CHANGELOG_LINKS = {
  JIRA_CLOUD: {
    IFRAME: 'https://oboard.io/page-iframe?jira-cloud',
    SOURCE: 'https://oboard.io/changelog?jira-cloud'
  },

  JIRA_SERVER: {
    IFRAME: 'https://oboard.io/page-iframe?jira-dc',
    SOURCE: 'https://oboard.io/changelog?jira-dc'
  },

  SALESFORCE: {
    // IFRAME: 'https://oboard.io/page-iframe?salesforce',
    // SOURCE: 'https://oboard.io/changelog?salesforce',
    IFRAME: 'https://oboard.io/page-iframe?oboard-web-app',
    SOURCE: 'https://oboard.io/changelog?oboard-web-app'
  },

  WEB_APP: {
    IFRAME: 'https://oboard.io/page-iframe?oboard-web-app',
    SOURCE: 'https://oboard.io/changelog?oboard-web-app'
  }
}

export const getCurrentPlatformChangelogLinks = ({
  isPluginServer = false,
  isJiraApp = true,
  isWebApp = false,
  isSalesforceApp = false
} = {}) => {
  const { JIRA_CLOUD, JIRA_SERVER, SALESFORCE, WEB_APP } = CHANGELOG_LINKS

  const [links] = [
    isJiraApp && isPluginServer && JIRA_SERVER,
    isJiraApp && !isPluginServer && JIRA_CLOUD,
    isWebApp && WEB_APP,
    isSalesforceApp && SALESFORCE,
    JIRA_CLOUD
  ].filter(Boolean)

  return links
}
