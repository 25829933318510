<template>
  <AppButton
    class="alm-Button"
    data-export-ignore
    height="24"
    icon="arrow-down-next"
    remove-padding
    type="link-next"
    width="24"
    @click="$emit('click')"
  >
    {{ $t('action.load_more') }}
  </AppButton>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AppLoadMoreButton'
})
</script>

<script setup>
import AppButton from '@/components/ui/AppButton/AppButton'

defineEmits(['click'])
</script>

<style lang="scss" scoped>
.alm-Button {
  gap: 8px;
  font-weight: fw('semi-bold');
}
</style>
