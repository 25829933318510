<template>
  <AppDialog
    :loading="isLoading"
    :show="show"
    :title="$t('custom_fields.delete_title')"
    :type="DIALOG_TYPES.DELETE"
    @on-confirm="onConfirm"
    @on-close="onClose"
  >
    <div class="cfd-ModalBody">
      <i18n-t
        v-if="show"
        keypath="custom_fields.delete_field_confirm_message"
        scope="global"
        tag="div"
      >
        <template #fieldName>
          <span class="cfd-DangerText">
            {{ fieldToDelete.name }}
          </span>
        </template>
      </i18n-t>

      <KeywordInput
        ref="keywordInputReference"
        :error-message-data-testid="CUSTOM_FIELD_DELETE_MODAL_ERROR_MESSAGE_TEST_ID"
        :input-data-testid="CUSTOM_FIELD_DELETE_MODAL_INPUT_TEST_ID"
        :keyword="KEYWORD"
      />
    </div>
  </AppDialog>
</template>

<script setup>
import { ref, watch } from 'vue'

import CustomFieldsApiHandler from '@/api/custom-fields'
import { tracker } from '@/tracking/amplitude'
import { EVENT_CATEGORIES } from '@/tracking/amplitude-helpers'
import { DIALOG_TYPES } from '@/utils/components-configurations/app-dialog'
import {
  CUSTOM_FIELD_DELETE_MODAL_ERROR_MESSAGE_TEST_ID,
  CUSTOM_FIELD_DELETE_MODAL_INPUT_TEST_ID
} from '@/utils/custom-fields/jest-helpers'
import { handleError } from '@/utils/error-handling'
import { KEYWORD } from '@/utils/helpers'

import AppDialog from '@/components/AppDialog'
import KeywordInput from '@/components/ui/KeywordInput/KeywordInput'

defineOptions({
  name: 'CustomFieldDeleteModal'
})

const props = defineProps({
  show: {
    type: Boolean
  },

  fieldToDelete: {
    type: Object,
    default: () => ({})
  }
})

const emit = defineEmits({
  close: null,
  'on-field-deleted': null
})

const isLoading = ref(false)

const keywordInputReference = ref(null)

const onConfirm = async () => {
  const isKeywordValid = keywordInputReference.value.validate()

  if (!isKeywordValid) {
    return
  }

  isLoading.value = true
  const api = new CustomFieldsApiHandler()

  try {
    const { id, name: label } = props.fieldToDelete
    await api.deleteCustomField({
      id
    })

    tracker.logEvent('custom field removed', {
      category: EVENT_CATEGORIES.SETTINGS,
      label,
      id
    })

    emit('on-field-deleted', props.fieldToDelete.id)
  } catch (error) {
    handleError({ error })
  } finally {
    isLoading.value = false
  }
}
const onClose = () => {
  emit('close')
}

const keyword = ref('')
const isKeywordFieldValid = ref(true)

const setDefaultState = () => {
  keyword.value = ''
  isKeywordFieldValid.value = true
}

watch(
  () => props.show,
  async newValue => {
    if (!newValue) {
      setDefaultState()
    }
  },
  {
    immediate: true
  }
)
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/modal-danger-text';

.cfd-DangerText {
  @extend %modal-danger-text;
}

.cfd-ModalBody {
  display: grid;
  gap: 20px;
}
</style>
