<template>
  <Loader />
</template>

<script setup>
import { h } from 'vue'

import { memoizeHexToRgb } from '@/utils/memoizations'

import LoadingCircle from '@/components/ui/LoadingCircle/LoadingCircle'

defineOptions({
  name: 'FullPageLoader'
})

const props = defineProps({
  options: {
    type: Object,
    default: () => ({})
  }
})
const Loader = () => {
  // const scopeId = getScopeId(getCurrentInstance())
  // const ITEMS_COUNT = 3
  return h(
    'div',
    {
      class: 'fp-Loader',
      style: {
        '--alpha': props.options.alpha,
        '--background-color': memoizeHexToRgb(props.options.backgroundColor)
      }
    },
    h(LoadingCircle, {
      color: props.options.circleColor,
      size: 'xl'
    })
    //   [
    //     h(
    //       'div',
    //       { ...scopeId, class: 'fp-Loader_Spinner' },
    //       Array.from({ length: ITEMS_COUNT }, (_, i) =>
    //         h('div', { ...scopeId, class: 'fp-SpinnerItem' })
    //       )
    //     )
    // ]
  )
}
</script>

<style lang="scss" scoped>
/* this eslint plugin won't work with vue 3 render function in <script setup> */
/* eslint-disable vue-scoped-css/no-unused-selector */
/* eslint-disable-next-line */

.fp-Loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(var(--background-color), var(--alpha));
  z-index: 10000;
  backdrop-filter: blur(3px);
  display: flex;
  align-items: center;
  justify-content: center;
  // --size: 75px;
  // --blue: #5879ee;
  // --red: #f64963;
  // --orange: #ff8541;
  // --violet: #a769f6;
}
/*
.fp-Loader_Spinner {
  --animation-duration: 5000ms;
  position: relative;
  width: var(--size);
  height: var(--size);
  transform: rotate(45deg);
}

.fp-SpinnerItem {
  --item-size: calc(var(--size) / 2);
  position: absolute;
  width: var(--item-size);
  height: var(--item-size);
  background: var(--primary-color);

  &:nth-child(1) {
    --primary-color: var(--blue);
    top: 0;
    left: 0;
    animation: spinner3A var(--animation-duration) linear infinite;

    @keyframes spinner3A {
      0%,
      8.33%,
      16.66%,
      100% {
        transform: translate(0%, 0%);
      }

      24.99%,
      33.32%,
      41.65% {
        transform: translate(100%, 0%);
      }

      49.98%,
      58.31%,
      66.64% {
        transform: translate(100%, 100%);
      }

      74.97%,
      83.30%,
      91.63% {
        transform: translate(0%, 100%);
      }
    }
  }

  &:nth-child(2) {
    --primary-color: var(--red);
    top: 0;
    left: var(--item-size);
    animation: spinner3B var(--animation-duration) linear infinite;

    @keyframes spinner3B {
      0%,
      8.33%,
      91.63%,
      100% {
        transform: translate(0%, 0%);
      }

      16.66%,
      24.99%,
      33.32% {
        transform: translate(0%, 100%);
      }

      41.65%,
      49.98%,
      58.31% {
        transform: translate(-100%, 100%);
      }

      66.64%,
      74.97%,
      83.30% {
        transform: translate(-100%, 0%);
      }
    }
  }

  &:nth-child(3) {
    --primary-color: var(--orange);
    top: var(--item-size);
    left: var(--item-size);
    animation: spinner3C var(--animation-duration) linear infinite;

    @keyframes spinner3C {
      0%,
      83.30%,
      91.63%,
      100% {
        transform: translate(0, 0);
      }

      8.33%,
      16.66%,
      24.99% {
        transform: translate(-100%, 0);
      }

      33.32%,
      41.65%,
      49.98% {
        transform: translate(-100%, -100%);
      }

      58.31%,
      66.64%,
      74.97% {
        transform: translate(0, -100%);
      }
    }
  }
}
*/
/* eslint-enable vue-scoped-css/no-unused-selector */
</style>
