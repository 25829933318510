<template>
  <div class="agtw-Loader">
    <div
      v-for="row in ROWS"
      :key="row.id"
      :style="{ '--depth': row.depth }"
      class="agtw-Loader_Row"
    >
      <div class="agtw-Cell">
        <SkeletonItem :appearance="SQUARE" :size="XS" border-radius="4px" />
      </div>
      <div class="agtw-Cell">
        <SkeletonItem v-if="row.expandable" :appearance="SQUARE" :size="SM" border-radius="6px" />
        <div v-else class="agtw-Cell_ExpandPlug"></div>
        <SkeletonItem :appearance="SQUARE" :color="row.color" :size="SM" border-radius="6px" />
        <SkeletonItem :size="XS" :width="row.name" />
        <div class="agtw-Cell_Labels">
          <SkeletonItem :size="SM" :width="row.users" />
          <SkeletonItem :size="SM" :width="row.subGroups" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AddGroupsToWorkspaceLoader'
})
</script>

<script setup>
import {
  SKELETON_APPEARANCES,
  SKELETON_SIZES
} from '@/utils/components-configurations/skeleton-item'
import { ADD_GROUP_TO_WORKSPACE_TABLE_OFFSET } from '@/utils/global-groups'

import { ROWS } from '@/components/ui/SkeletonLoaders/global-groups'
import SkeletonItem from '@/components/ui/SkeletonLoaders/SkeletonItem'

const { SQUARE } = SKELETON_APPEARANCES
const { XS, SM } = SKELETON_SIZES
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/global-groups-loaders';

.agtw-Loader {
  padding: 0 v-bind(ADD_GROUP_TO_WORKSPACE_TABLE_OFFSET);
}
.agtw-Loader_Row {
  @extend %row-style;
}

.agtw-Cell {
  @extend %cell-style;

  &:nth-child(1) {
    width: 32px;
  }

  &:nth-child(2) {
    @extend %deep-cell-style;
  }
}

.agtw-Cell_Labels {
  @extend %cell-labels-style;
}

.agtw-Cell_ExpandPlug {
  @extend %expand-plug-style;
}
</style>
