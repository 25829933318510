import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'

import { getResolvedRoutes } from '@/routes'
import { store } from '@/store'
import { CUSTOM_DASHBOARD_QUERY_KEYS } from '@/utils/custom-dashboard-helper'
import { CUSTOM_FIELDS_FILTERS_QUERY_KEY } from '@/utils/custom-fields/helpers'
import { LS_KEYS } from '@/utils/local-storage-keys'
import { FILTERS_KEYS, HP_FILTERS_KEYS } from '@/utils/okr-elements/filters'
import { readFromStorageByKey, updateStorageByKey } from '@/utils/persist'
import {
  CREATE_GROUP_KEY,
  EDIT_ELEMENT_QUERY_KEYS,
  EDIT_GROUP_KEY,
  FILTER_PRESET_KEY,
  INTERVAL_ID_KEY,
  SHOW_COMMENT_KEY,
  WORKSPACE_ID_KEY,
  OPENED_NOTIFICATIONS_KEY,
  LEVEL_ID,
  REFERER,
  EDIT_WORKSPACE_ACTIVE_TAB_QUERY_KEY,
  EDIT_WORKSPACE_QUERY_KEY,
  GLOBAL_GROUPS_EXPANDED_ITEMS,
  PAYMENT_STATUS
} from '@/utils/query-parameters'
import { JIRA_CLOUD_API } from '@jira/util'
import { IS_DEVELOPMENT } from '@root/app-modes'
import { APP_PLATFORMS } from '@root/app-platforms'

import { USERS_QUERY_KEYS } from '@/views/workspaces/settings/plugin-users/users-query-params'

const saveRoute = route => {
  updateStorageByKey(LS_KEYS.LAST_ROUTE_PATH, route.fullPath)

  if (JIRA_CLOUD_API && JIRA_CLOUD_API.history) {
    JIRA_CLOUD_API.history.pushState(route.fullPath, '', route.fullPath)
  }
}

const routerFactory = ({
  routerBase = '/',
  isHashMode = false,
  appPlatform = APP_PLATFORMS.WEB_APP,
  isOwner = false
} = {}) => {
  const history = isHashMode ? createWebHashHistory(routerBase) : createWebHistory(routerBase)
  const router = createRouter({
    history,
    routes: getResolvedRoutes({ appPlatform, isOwner })
  })

  let isFirstTransition = true

  function filterQueryParameters(queryParameters) {
    // Query params should be filtered, because in cloud version jira can add and remove parameters
    // and they can be mixed with route query parameters. They can be also sensitive, such as jwt
    // token so filter them
    const allowedQueryParams = [
      ...Object.values(FILTERS_KEYS),
      ...Object.values(EDIT_ELEMENT_QUERY_KEYS),
      OPENED_NOTIFICATIONS_KEY,
      EDIT_WORKSPACE_QUERY_KEY,
      EDIT_WORKSPACE_ACTIVE_TAB_QUERY_KEY,
      GLOBAL_GROUPS_EXPANDED_ITEMS,
      SHOW_COMMENT_KEY,
      CREATE_GROUP_KEY,
      EDIT_GROUP_KEY,
      WORKSPACE_ID_KEY,
      FILTER_PRESET_KEY,
      INTERVAL_ID_KEY, // for dashboard
      LEVEL_ID,
      REFERER,
      ...Object.values(HP_FILTERS_KEYS),
      ...Object.values(USERS_QUERY_KEYS),
      ...Object.values(CUSTOM_DASHBOARD_QUERY_KEYS),
      CUSTOM_FIELDS_FILTERS_QUERY_KEY,
      PAYMENT_STATUS
    ]
    const result = {}
    Object.keys(queryParameters).forEach(key => {
      if (allowedQueryParams.includes(key)) {
        result[key] = queryParameters[key]
      }
    })
    return result
  }

  function passStateToNext(state, next, to, filteredQueryParameters) {
    if (state.length > 0) {
      if (state === to.path) {
        next()
      } else {
        // check if `to` has valid path
        let resolved = router.resolve(to)
        if (resolved.path !== '/') {
          next({
            path: resolved.path,
            query: filteredQueryParameters,
            hash: to.hash
          })
        } else {
          // check if state is valid path
          resolved = router.resolve({ path: state })
          if (resolved.href === '/') {
            next({ path: '/' })
          } else {
            next({
              path: resolved.path,
              query: filteredQueryParameters,
              hash: to.hash
            })
          }
        }
      }
    } else {
      next({ path: to.path, query: filteredQueryParameters, hash: to.hash })
    }
  }

  router.beforeEach((to, from, next) => {
    if (!isFirstTransition) {
      next()
      return
    }

    let filteredQueryParameters = filterQueryParameters(to.query)
    isFirstTransition = false

    if (store.state.pluginOptions.onboarding) {
      next({ path: '/', query: { redirectTo: 'first-alignment' } })
      return
    }

    let nextPath = readFromStorageByKey(LS_KEYS.LAST_ROUTE_PATH) || ''

    const resolvedRouteFromNextPath = router.resolve(nextPath)
    // if path restored from LS(e.g. user opens jira plugin from jira navigation),
    // don't take editObjective, editKR and editLinkJiraIssue into account to avoid opening modals
    Object.values(EDIT_ELEMENT_QUERY_KEYS).forEach(key => {
      delete resolvedRouteFromNextPath.query?.[key]
    })

    if (!JIRA_CLOUD_API) {
      if (!IS_DEVELOPMENT) {
        console.warn('No routing support')
      }
      passStateToNext(nextPath, next, to, filteredQueryParameters)
    } else {
      JIRA_CLOUD_API.getLocation(location => {
        const url = document.createElement('a')
        url.href = location
        let currentPath = url.hash
        // remove '#!' at the beginning
        if (currentPath.length > 2) {
          currentPath = currentPath.slice(2)
        }
        const resolvedRouteFromLocation = router.resolve(currentPath)
        if (resolvedRouteFromLocation.path !== '/') {
          nextPath = resolvedRouteFromLocation.fullPath
          filteredQueryParameters = filterQueryParameters(resolvedRouteFromLocation.query)
        } else {
          // resolve query parameters from next path(saved last path if there is such)
          filteredQueryParameters = resolvedRouteFromNextPath.query
        }

        passStateToNext(nextPath, next, to, filteredQueryParameters)
      })
    }
  })

  router.afterEach(to => {
    // on first load of plugin page path can be '/index.html', ignore it
    // because it is not valid route path
    if (to.path === '/index.html') {
      return
    }

    saveRoute(to)
  })

  return router
}

export default routerFactory
