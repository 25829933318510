<template>
  <div
    :data-testid="$attrs['data-testid'] ? `${$attrs['data-testid']}-wrapper` : null"
    class="ata-Field"
  >
    <textarea
      ref="textarea"
      v-model="localValue"
      :class="{ 'ata-Field_Input-error': isError }"
      :maxlength="maxLength"
      :placeholder="placeholder"
      :readonly="readonly"
      :rows="rows"
      class="ata-Field_Input"
      v-bind="bindingAttrs"
      @blur="$emit('blur')"
      @keydown.esc="$emit('blur')"
    />
    <AppFieldError v-if="isError" :show="isError" class="ata-Field_Error">
      {{ $t('field.required') }}
    </AppFieldError>
  </div>
</template>

<script setup>
import { useTextareaAutosize } from '@vueuse/core'
import { computed, watch, nextTick, useAttrs } from 'vue'

import AppFieldError from '@/components/form/AppFieldError'

defineOptions({
  name: 'AppTextarea',
  inheritAttrs: false
})

const { triggerResize, textarea } = useTextareaAutosize({ styleProp: 'minHeight' })

const props = defineProps({
  modelValue: {
    required: true,
    type: String
  },

  readonly: {
    type: Boolean
  },

  placeholder: {
    type: String,
    default: ''
  },

  isError: {
    type: Boolean
  },

  maxLength: {
    type: Number,
    default: 512
  },

  rows: {
    type: Number,
    default: 4
  }
})

watch(
  () => props.modelValue,
  () => {
    nextTick(() => {
      triggerResize()
    })
  },
  { immediate: true }
)

const emit = defineEmits(['blur', 'update:model-value'])

const focus = () => {
  textarea.value.focus()
}

defineExpose({ focus })

// const resizeObserver = ref(null)

// const textareaWidth = ref(null)

// watch(textareaWidth, (newValue, oldValue) => {
//   if (newValue !== oldValue && oldValue !== null) {
//     nextTick(() => {
//       triggerResize()
//     })
//   }
// })

// const setTextareaWidth = () => {
//   if (textarea.value) {
//     textareaWidth.value = textarea.value.offsetWidth
//   }
// }

// const initResizeObserver = () => {
//   resizeObserver.value = new ResizeObserver(() => {
//     setTextareaWidth()
//   })
//
//   resizeObserver.value.observe(textarea.value)
// }

// const disconnectResizeObserver = () => {
//   if (resizeObserver.value) {
//     resizeObserver.value.disconnect()
//     resizeObserver.value = null
//   }
// }

// onMounted(() => {
//   initResizeObserver()
//   setTextareaWidth()
// })

// onBeforeUnmount(() => {
//   disconnectResizeObserver()
// })

const localValue = computed({
  get() {
    return props.modelValue
  },
  set(v) {
    if (!props.readonly) {
      emit('update:model-value', v)
    }
  }
})
const attrs = useAttrs()
const bindingAttrs = computed(() => {
  const DATA_TEST_ID = 'data-testid'
  const DATA_AUTO_TEST_ID = 'data-auto-testid'
  const INPUT_ELEMENT = 'textarea-element'
  return {
    ...attrs,
    [DATA_TEST_ID]: attrs[DATA_TEST_ID] ? `${attrs[DATA_TEST_ID]}-${INPUT_ELEMENT}` : null,

    [DATA_AUTO_TEST_ID]: attrs[DATA_AUTO_TEST_ID]
      ? `${attrs[DATA_AUTO_TEST_ID]}-${INPUT_ELEMENT}`
      : null
  }
})
</script>

<style lang="scss" scoped>
.ata-Field {
  display: grid;
  gap: 4px;
  position: relative;
  min-height: 40px;
}

.ata-Field_Input {
  resize: none;
  overflow: hidden;
  width: 100%;
  background-color: $white;
  border: 2px solid $grey-2-next;
  border-radius: $border-radius-sm-next;
  padding: var(--input-padding, 8px 8px 15px);
  font-family: $system-ui;
  min-height: 100px;
  line-height: 19px;
  caret-color: $primary-color-next;
  color: $dark-1;

  &:not(&:read-only) {
    &:focus {
      border-color: $primary-color-next;
    }
  }

  &::placeholder {
    color: $placeholder-color;
  }

  &:-moz-placeholder,
  &::-moz-placeholder {
    color: $grey-1-next;
  }

  &:read-only {
    cursor: not-allowed;
    border-color: $grey-2-next;
    background: $grey-2-next;
    color: $dark-3;
  }

  &-error {
    color: $grade-low-color-next;
    caret-color: $grade-low-color-next;

    &::placeholder {
      color: inherit;
    }

    &:-moz-placeholder,
    &::-moz-placeholder {
      color: inherit;
    }
  }
}

.ata-Field_Error {
  justify-self: flex-start;
}
</style>
