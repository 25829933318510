<template>
  <AppDialog
    :show="show"
    :size="480"
    :type="DIALOG_TYPES.INFO"
    hide-hero
    style="--footer-padding-top: 40px"
    @on-close="onClose"
  >
    <div class="mpd-Wrapper">
      <AppTitle :level="2" class="mpd-Title" disable-margin>
        {{ isAnnualOnly ? $t('subscription.manage_tier') : $t('subscription.manage_plan') }}
      </AppTitle>
      <div>
        <AppDivider class="mpd-Divider" />
        <div class="mpd-SubtitleWrapper">
          <span class="mpd-Subtitle">
            <AppIcon height="24" icon-name="checkbox" width="24" />
            {{ getSubscriptionDetails.name }}
          </span>
          <AppButton v-if="withSwitchPlan" type="link-next" @click="onSwitchPlan">
            {{ $t('subscription.switch_plan') }}
          </AppButton>
        </div>
        <AppDivider class="mpd-Divider" />
      </div>

      <FormFieldNext>
        <template #label>
          <span class="mpd-SelectLabel">
            {{
              isAnnual ? $t('subscription.select_user_tier') : $t('subscription.number_of_users')
            }}
            <AppIcon
              v-tippy="{
                content: isAnnual
                  ? $t('subscription.select_user_tier_tooltip')
                  : $t('subscription.number_of_users_tooltip'),
                placement: 'top'
              }"
              height="24"
              icon-name="info-next"
              width="24"
            />
          </span>
        </template>
        <template v-if="isAnnual">
          <AppSelect
            v-model="tierIndex"
            :is-error="isError"
            :loading="loaders.tiers"
            :offset="[0, 0]"
            :options="tiersOptions"
            class="mpd-Input"
            item-label="label"
            item-value="index"
            @change="onChangeUserTiers"
            @update:model-value="fetchUpcomingInvoice"
          >
            <template #option-label="{ option }">
              <span class="mpd-SelectOption">
                {{ option.label }}
                <span
                  v-if="option.index === tiersData.currentTierIndex"
                  class="mpd-SelectOptionSubtitle"
                >
                  {{ $t('subscription.current_tier') }}
                </span>
              </span>
            </template>
          </AppSelect>
          <AppFieldError v-if="isError" :show="isError">
            {{ $t('subscription.tier_upper_limit') }}
          </AppFieldError>
        </template>

        <AppInput
          v-else
          :model-value="currentSubscription.usersCount"
          class="mpd-Input"
          readonly
          size="xlg"
          style="
            --readonly-background-color: var(--grey-3-next);
            --readonly-color: var(--grade-default-color);
          "
          style-type="primary"
        />
      </FormFieldNext>
      <FormFieldNext>
        <template #label>{{ $t('subscription.select_subscription_cycle') }}</template>
        <CycleSwitch
          v-model:selected-cycle="selectedPeriod"
          :current-subscription="getSubscriptionDetails"
          :cycles="getSelectedPlan.cycles"
          @update:selected-cycle="fetchUpcomingInvoice"
        />
      </FormFieldNext>
      <div v-if="!loaders.upcomingInvoice">
        <div class="mpd-AmountDescription">
          <span>{{ getSelectedPlan.name }}</span>
          <span>{{ getSelectedPlanSum }}</span>
        </div>
        <div
          v-if="upcomingInvoiceData.billingDate"
          class="mpd-AmountDescription mpd-AmountDescription-small"
        >
          {{ getBillingDate }}
        </div>
        <div class="mpd-AmountDescription mpd-AmountDescription-small mpd-AmountDescription-divide">
          {{ getBillingDetailsDescription }}
        </div>

        <!--            <p class="mpd-AmountDescription">-->
        <!--              <span>{{ $t('subscription.total_pay') }}</span>-->
        <!--              <span>{{ getUpcomingTotal }}</span>-->
        <!--            </p>-->

        <!--          <div v-if="upcomingInvoiceData.startingBalance" class="mpd-AmountDescription">-->
        <!--            <span>{{ $t('subscription.applied_balance') }}</span>-->
        <!--            <span>{{ getUpcomingAppliedBalance }}</span>-->
        <!--          </div>-->
        <!--          <div-->
        <!--            v-for="(totalTaxAmount, totalTaxAmountIndex) in upcomingInvoiceData.totalTaxAmounts"-->
        <!--            :key="totalTaxAmountIndex"-->
        <!--            class="mpd-AmountDescription"-->
        <!--          >-->
        <!--            &lt;!&ndash; eslint-disable-next-line &ndash;&gt;-->
        <!--            <span> VAT ({{ totalTaxAmount.percentage }}%) </span>-->
        <!--            <span>-->
        <!--              {{ getAmount(totalTaxAmount.amount) }}-->
        <!--            </span>-->
        <!--          </div>-->

        <div class="mpd-AmountDescription">
          <span>
            {{ getAmountDueText }}
          </span>
          <span>{{ getUpcomingAmountDue }}</span>
        </div>

        <!--          <div-->
        <!--            v-if="currentSubscription.paymentMethod"-->
        <!--            class="mpd-AmountDescription mpd-AmountDescription-small"-->
        <!--          >-->
        <!--            <span>{{ $t('subscription.next_bill_date') }}</span>-->
        <!--            <span>{{ getDateFormatted(upcomingInvoiceData.createdDate, 'DD-MM-YYYY') }}</span>-->
        <!--          </div>-->

        <!--            <p v-if="getOnBillsDate" class="mpd-AmountDescription mpd-AmountDescription-small">-->
        <!--              {{ getOnBillsDate }}-->
        <!--            </p>-->
      </div>
      <div v-else>
        <SkeletonItem class="mpd-AmountDescription" height="18px" width="100%" />
        <SkeletonItem class="mpd-AmountDescription" height="18px" width="100%" />
        <SkeletonItem class="mpd-AmountDescription" height="18px" width="100%" />
        <SkeletonItem class="mpd-AmountDescription" height="18px" width="100%" />
      </div>
    </div>
    <template #footer>
      <div class="mpd-Footer">
        <AppButton
          :disable="isCurrentPlan || isError"
          :loading="loaders.proceed"
          class="mpd-SaveBtn"
          size="lg-next"
          @click="proceed"
        >
          {{ isCurrentPlan ? getCurrentText : $t('action.proceed') }}
        </AppButton>
        <div
          v-if="subscriptionStatuses.isTrial || subscriptionStatuses.isCanceled"
          class="mpd-AmountDescription mpd-AmountDescription-small"
        >
          {{ $t('subscription.final_amount_text') }}
        </div>
      </div>
    </template>
  </AppDialog>
  <SubscriptionUpdatedDialog v-model:is-show="isShowSubscriptionUpdatedDialog" />
  <SubscriptionWasntUpdatedDialog v-model:is-show="isShowSubscriptionWasntUpdatedDialog" />
</template>

<script setup>
// import dayjs from 'dayjs'
import { computed, nextTick, ref, watch, inject } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

import BillingApiHandler from '@/api/billing'
import { tracker } from '@/tracking/amplitude'
import { EVENT_CATEGORIES } from '@/tracking/amplitude-helpers'
import {
  YEAR,
  ESSENTIAL,
  formatAmount,
  getAmount,
  getDateFormatted,
  getPeriodName,
  MONTH,
  PROFESSIONAL,
  SUBSCRIPTION_PLANS
} from '@/utils/billing-settings'
import { DIALOG_TYPES } from '@/utils/components-configurations/app-dialog'
import { handleError } from '@/utils/error-handling'
import { OPEN_LINK_TARGETS, openLink } from '@/utils/external-link-helper'
import { isSalesforceAppInjectionKey } from '@/utils/injection-keys'
import { PLUGIN_OPTIONS_KEYS } from '@root/template-options-keys'
import CycleSwitch from '@shared-modules/components/billing-settings/CycleSwitch'
import SubscriptionUpdatedDialog from '@shared-modules/components/billing-settings/SubscriptionUpdatedDialog'
import SubscriptionWasntUpdatedDialog from '@shared-modules/components/billing-settings/SubscriptionWasntUpdatedDialog'

import AppDialog from '@/components/AppDialog'
import AppFieldError from '@/components/form/AppFieldError'
import FormFieldNext from '@/components/form/FormFieldNext'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppDivider from '@/components/ui/AppDivider'
import AppIcon from '@/components/ui/AppIcon/AppIcon'
import AppInput from '@/components/ui/AppInput/AppInput'
import AppSelect from '@/components/ui/AppSelect/AppSelect'
import AppTitle from '@/components/ui/AppTitle/AppTitle'
import SkeletonItem from '@/components/ui/SkeletonLoaders/SkeletonItem'

defineOptions({
  name: 'ManagePlanDialog'
})

const props = defineProps({
  show: {
    type: Boolean,
    required: true
  },

  isAnnualOnly: {
    type: Boolean
  },

  withSwitchPlan: {
    type: Boolean
  },

  currentSubscription: {
    type: Object,
    required: true
  },

  chosenSubscription: {
    type: Object,
    default: () => ({})
  },

  withUpgradeTier: {
    type: Boolean
  }
})
const store = useStore()

const licenseStatusId = computed(
  () => store.state.pluginOptions[PLUGIN_OPTIONS_KEYS.LICENSE_STATUS_ID]
)

const getTrackingSubscriptionUpdatePayload = computed(() => {
  const subcategory =
    (props.currentSubscription.name === ESSENTIAL &&
      props.chosenSubscription.name === PROFESSIONAL) ||
    props.withUpgradeTier
      ? 'upgrade'
      : 'downgrade'
  const subscription_cycle = getPeriodName(selectedPeriod.value)

  return {
    category: EVENT_CATEGORIES.BILLING,
    plan: getSubscriptionDetails.value.name.toLowerCase(),
    subcategory,
    subscription_cycle: subscription_cycle.toLowerCase(),
    number_of_users:
      props.currentSubscription.maxUsersCount || props.currentSubscription.usersCount,
    current_license: licenseStatusId.value
  }
})
watch(
  () => props.show,
  newValue => {
    if (newValue && !subscriptionStatuses.value.isTrial && !subscriptionStatuses.value.isCanceled) {
      tracker.logEvent('update subscription opened', {
        ...getTrackingSubscriptionUpdatePayload.value
      })

      if (!newValue) {
        isError.value = false
      }
    }
  }
)

const emit = defineEmits(['update:show', 'update:chosenSubscription'])
const onClose = () => {
  emit('update:show', false)
}

const selectedPlan = ref(ESSENTIAL)
const selectedPeriod = ref(MONTH)

const subscriptionStatuses = computed(() => store.getters['system/subscriptionStatuses'])

const getSubscriptionDetails = computed(() => {
  if (props.isAnnualOnly) {
    return props.currentSubscription
  } else {
    return props.chosenSubscription
  }
})

const tierIndex = ref(null)
const isSelectedAnnual = computed(() => selectedPeriod.value === YEAR)

const isCurrentPlan = computed(() => {
  const isEqualPlan = selectedPlan.value === props.currentSubscription.name
  const isEqualPeriod = selectedPeriod.value === props.currentSubscription.period
  const isEqualTier = tierIndex.value === tiersData.value.currentTierIndex

  if (subscriptionStatuses.value.isCanceled) {
    return false
  } else if (props.isAnnualOnly) {
    return isEqualTier
  } else if (isSelectedAnnual.value) {
    return isEqualPlan && isEqualPeriod && isEqualTier
  } else {
    return isEqualPlan && isEqualPeriod && !subscriptionStatuses.value.isTrial
  }
})

const getSubscriptionPlans = computed(() => {
  if (props.isAnnualOnly) {
    return SUBSCRIPTION_PLANS.filter(plan => plan.value === getSubscriptionDetails.value.name).map(
      plan => ({
        ...plan,
        cycles: plan.cycles.filter(cycle => cycle.period === YEAR)
      })
    )
  } else {
    return SUBSCRIPTION_PLANS
  }
})

const getSelectedPlan = computed(() => {
  return getSubscriptionPlans.value.find(plan => plan.value === selectedPlan.value) || {}
})

const isAnnual = computed(() => isSelectedAnnual.value || props.isAnnualOnly)

const tiersData = ref({
  tiers: [],
  currentTierIndex: null
})

const isError = ref(false)
const checkIsError = () => {
  const tier = tiersData.value.tiers[tierIndex.value]
  const usersCount = tier.usersTo || tier.usersFrom
  isError.value = usersCount < props.currentSubscription.usersCount
}

const tiersOptions = computed(() => {
  return tiersData.value.tiers.map(tier => ({
    ...tier,
    label: tier.usersTo
      ? t('subscription.up_to_count_users', tier.usersTo)
      : `${tier.usersFrom}+ ${t('common.users').toLowerCase()}`,
    text: tier.usersTo ? `${tier.usersFrom}-${tier.usersTo}` : `${tier.usersFrom}+`
  }))
})

const fetchTiers = async () => {
  try {
    loaders.value.tiers = true
    loaders.value.upcomingInvoice = true
    loaders.value.proceed = true
    const BillingApi = new BillingApiHandler()
    tiersData.value = await BillingApi.getTiers({ name: getSubscriptionDetails.value.name })

    const tier =
      tiersData.value.tiers.find(tier => tier.usersTo >= props.currentSubscription.usersCount) ||
      tiersData.value.tiers[0]

    if (props.withUpgradeTier) {
      tierIndex.value = Number.isInteger(tiersData.value.currentTierIndex)
        ? tiersData.value.currentTierIndex
        : tier.index
    } else {
      tierIndex.value = tier.index
    }
  } catch (error) {
    handleError({ error })
  } finally {
    loaders.value.tiers = false
    loaders.value.proceed = false
    loaders.value.upcomingInvoice = false
  }
}

const loaders = ref({
  tiers: false,
  proceed: false,
  upcomingInvoice: false
})

const isSalesforceApp = inject(isSalesforceAppInjectionKey)

const proceed = async () => {
  const BillingApi = new BillingApiHandler()

  if (subscriptionStatuses.value.isCanceled || subscriptionStatuses.value.isTrial) {
    try {
      loaders.value.proceed = true
      const { url } = await BillingApi.getCheckoutSession({
        name: selectedPlan.value,
        cycle: selectedPeriod.value,
        tierIndex: tierIndex.value
      })

      const cycle = getPeriodName(selectedPeriod.value)

      tracker.logEvent('navigated to stripe', {
        category: EVENT_CATEGORIES.BILLING,
        plan: selectedPlan.value.toLowerCase(),
        subscription_cycle: cycle,
        number_of_users:
          props.currentSubscription.maxUsersCount || props.currentSubscription.usersCount
      })

      const target = isSalesforceApp ? OPEN_LINK_TARGETS.BLANK : OPEN_LINK_TARGETS.SELF

      openLink({
        url,
        useBrowserWorkaround: isSalesforceApp,
        target
      })
    } catch (error) {
      handleError({ error })
    } finally {
      loaders.value.proceed = false
    }
  } else if (props.withUpgradeTier) {
    try {
      loaders.value.proceed = true
      await BillingApi.updateTiers({
        tierIndex: tierIndex.value
      })
      isShowSubscriptionUpdatedDialog.value = true
      onClose()
      // await store.dispatch('system/fetchBillingPlanDetails')
    } catch (error) {
      isShowSubscriptionWasntUpdatedDialog.value = true
      onClose()
      handleError({ error })
    } finally {
      loaders.value.proceed = false
    }
  } else {
    try {
      loaders.value.proceed = true
      await BillingApi.updateSubscription({
        planName: selectedPlan.value,
        billingCycle: selectedPeriod.value,
        tierIndex: tierIndex.value
      })
      isShowSubscriptionUpdatedDialog.value = true
      onClose()
      // await store.dispatch('system/fetchBillingPlanDetails')

      tracker.logEvent('updated subscription', {
        ...getTrackingSubscriptionUpdatePayload.value
      })
    } catch (error) {
      isShowSubscriptionWasntUpdatedDialog.value = true
      onClose()
      handleError({ error })
    } finally {
      loaders.value.proceed = false
    }
  }
}

const upcomingInvoiceData = ref({})
const fetchUpcomingInvoice = async () => {
  if (!props.currentSubscription.paymentMethod) return
  const BillingApi = new BillingApiHandler()

  try {
    loaders.value.upcomingInvoice = true

    upcomingInvoiceData.value = await BillingApi.getUpcomingInvoice({
      name: selectedPlan.value,
      cycle: selectedPeriod.value,
      tierIndex: tierIndex.value
    })
  } catch (error) {
    handleError({ error })
  } finally {
    loaders.value.upcomingInvoice = false
  }
}

// const getUpcomingTotal = computed(() => getAmount(Math.max(upcomingInvoiceData.value.total, 0)))

const getUpcomingAmountDue = computed(() => {
  if (!props.currentSubscription.paymentMethod) {
    return getSelectedPlanSum.value
  } else {
    return getAmount(upcomingInvoiceData.value.amountDue)
  }
})

// const getUpcomingAppliedBalance = computed(() =>
//   getAmount(Math.abs(upcomingInvoiceData.value.startingBalance))
// )

const isShowSubscriptionUpdatedDialog = ref(false)
const isShowSubscriptionWasntUpdatedDialog = ref(false)

const onSwitchPlan = async () => {
  const getNewSelectedPlan = selectedPlan.value === ESSENTIAL ? PROFESSIONAL : ESSENTIAL
  selectedPlan.value = getNewSelectedPlan
  const planOption = SUBSCRIPTION_PLANS.find(plan => plan.value === getNewSelectedPlan)

  emit('update:chosenSubscription', planOption)
  await nextTick()
  await fetchTiers()
  await fetchUpcomingInvoice()
}

watch(
  () => props.show,
  async show => {
    if (show) {
      selectedPlan.value = getSubscriptionDetails.value.name
      selectedPeriod.value = getSubscriptionDetails.value.period

      if (props.isAnnualOnly) {
        selectedPeriod.value = YEAR
      }
      await fetchTiers()
      await fetchUpcomingInvoice()
    }
  }
)

const { t } = useI18n()
// const getOnBillsDate = computed(() => {
//   const date = dayjs(getDateFormatted(upcomingInvoiceData.value.createdDate))
//
//   if (date.isToday()) {
//     return null
//   }
//
//   return t('subscription.bills_on_date', {
//     plan: getSelectedPlan.value.name,
//     date: date.format('DD MMM')
//   })
// })

const getCurrentText = computed(() => {
  return props.isAnnualOnly
    ? t('subscription.your_current_tier')
    : t('subscription.your_current_plan')
})

const getCurrentPrice = computed(() => {
  return props.chosenSubscription.price || getSelectedPlan.value.cycles[0].price
})

const getSelectedPlanSum = computed(() => {
  const tier = tiersOptions.value[tierIndex.value] || tiersOptions.value[0]

  if (isAnnual.value) {
    return getAmount(tier.flatAmount)
  } else {
    return formatAmount(props.currentSubscription.usersCount * getCurrentPrice.value)
  }
})

const getBillingDetailsDescription = computed(() => {
  return isAnnual.value
    ? t(`subscription.user_tier_billed_annually`, {
        label: tiersOptions.value[tierIndex.value]?.label
      })
    : t('subscription.per_user_billed_monthly', {
        usersCount: props.currentSubscription.usersCount,
        price: getCurrentPrice.value
      })
})

const getBillingDate = computed(() => {
  return `${t('subscription.billing_date')} (${getDateFormatted(
    upcomingInvoiceData.value.billingDate,
    'DD-MM-YYYY'
  )})`
})

const getAmountDueText = computed(() => {
  return subscriptionStatuses.value.isTrial || subscriptionStatuses.value.isCanceled
    ? t('subscription.amount_due') + '*'
    : t('subscription.amount_due')
})

const onChangeUserTiers = () => {
  checkIsError()
  const tier = tiersOptions.value[tierIndex.value]
  tracker.logEvent('user tier changed', {
    category: EVENT_CATEGORIES.BILLING,
    tier_level: tier.text
  })
}
</script>

<style lang="scss" scoped>
.mpd-Wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.mpd-Title {
  margin: 0 0 20px;
  font-size: $fs-32;
  line-height: 40px;
}

.mpd-SubtitleWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
}

.mpd-Subtitle {
  font-size: $fs-20;
  line-height: 28px;
  font-weight: fw('semi-bold');
  color: $dark-1;
  display: flex;
  align-items: center;
  gap: 8px;
}

.mpd-SelectLabel {
  display: flex;
  align-items: center;
}

.mpd-Input {
  width: 196px;
  &:deep(.o-input-field),
  &:deep(.as-AppDroplistButton_Text) {
    font-size: $fs-20;
    line-height: 28px;
    font-weight: fw('semi-bold');
  }
}

.mpd-AmountDescription {
  font-size: $fs-14;
  line-height: 18px;
  color: $dark-2;
  font-weight: fw('semi-bold');
  margin: 0 0 3px 0;
  display: flex;
  justify-content: space-between;
  &:last-child {
    margin-bottom: 0;
  }
  &-small {
    color: $dark-3;
    font-size: $fs-12;
    line-height: 16px;
    font-weight: fw('regular');
  }
  &-divide {
    margin-bottom: 20px;
  }
}

.mpd-SaveBtn {
  font-weight: fw('semi-bold');
  width: 100%;
}

.mpd-SelectOption {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.mpd-SelectOptionSubtitle {
  font-size: $fs-12;
  line-height: 16px;
  color: $dark-3;
}
.mpd-Footer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  align-items: center;
}

.mpd-Divider {
  margin: 0;
}
</style>
