import ApiHandler from '@/api/base'

class WorkspaceTokensApiHandler extends ApiHandler {
  async getTokens() {
    const { data } = await this.http.get('/tokens')
    return data
  }

  async createToken({ name } = {}) {
    const { data } = await this.http.post('/tokens', {
      name
    })
    return data
  }

  async removeToken({ tokenId } = {}) {
    const { data } = await this.http.delete('/tokens', {
      params: { tokenId }
    })
    return data
  }
}

export default WorkspaceTokensApiHandler
