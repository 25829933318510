<template>
  <portal to="modal-windows">
    <AppDialog
      :show="show"
      :size="915"
      :type="DIALOG_TYPES.INFO"
      hide-footer
      hide-hero
      style="--footer-padding-top: 40px"
      @on-close="onClose"
      @on-confirm="onConfirm"
    >
      <div class="udd-Users" style="--page-left-padding: 40px; --page-right-padding: 40px">
        <div class="udd-Header">
          <AppTitle :level="3" disable-margin>
            {{ $t('common.users') }}
          </AppTitle>
          <span class="udd-Subtitle">
            {{
              $t('subscription.from_to_users', {
                from: subscription.usersCount,
                to: subscription.maxUsersCount
              })
            }}
          </span>
        </div>
        <BillingUsersTable @on-delete-users="onDeleteUsers" />
      </div>
    </AppDialog>
  </portal>
</template>

<script setup>
defineOptions({
  name: 'UserDeleteDialog'
})
// import { useStore } from 'vuex'

import { DIALOG_TYPES } from '@/utils/components-configurations/app-dialog'
import { useReloadPage } from '@/utils/reload-page'
import BillingUsersTable from '@shared-modules/components/billing-settings/BillingUsersTable'

import AppDialog from '@/components/AppDialog'
import AppTitle from '@/components/ui/AppTitle/AppTitle'

const emit = defineEmits(['update:show'])

defineProps({
  show: {
    type: Boolean,
    required: true
  },

  subscription: {
    type: Object,
    default: () => ({})
  }
})

const { reloadPage } = useReloadPage()

const onClose = () => {
  emit('update:show', false)
}
const onConfirm = () => {
  emit('update:show', false)
}
// const store = useStore()

const onDeleteUsers = () => {
  setTimeout(() => {
    reloadPage()
  }, 1000)
  // store.dispatch('system/fetchBillingPlanDetails')
}
</script>

<style lang="scss" scoped>
.udd-Users {
  :deep(.us-Wrapper) {
    padding: 0 0 8px 0;
  }
}
.udd-Header {
  display: flex;
  align-items: baseline;
  gap: 16px;
}
.udd-Subtitle {
  color: $grade-low-color-next;
  font-size: $fs-14;
  font-weight: fw('semi-bold');
}
</style>
