<template>
  <span class="o-form-error">
    <template v-if="show">
      <slot />
    </template>
  </span>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AppFieldError',

  props: {
    show: {
      type: Boolean,
      required: true
    }
  }
})
</script>

<style lang="scss">
.o-form {
  &-error {
    font-family: $system-ui;
    color: $grade-low-color-next;
    font-style: normal;
    font-weight: fw('regular');
    font-size: $fs-12;
    line-height: 16px;
  }
}
</style>
