<template>
  <AppButton
    class="cfb-Button"
    icon="cross-circle-next"
    size="sm"
    type="secondary-inline-next"
    @click="onClick"
  >
    <slot>
      {{ t('filter.clear_all') }}
    </slot>
  </AppButton>
</template>

<script setup>
import { useI18n } from 'vue-i18n'

import AppButton from '@/components/ui/AppButton/AppButton'

defineOptions({
  name: 'ClearFiltersButton'
})

const { t } = useI18n()

const emit = defineEmits({
  click: null
})
const onClick = () => emit('click')
</script>

<style lang="scss" scoped>
.cfb-Button {
  color: $dark-3;

  &:deep(.svg-icon) {
    border-radius: $border-radius-md;
  }

  &:hover {
    &:deep(.svg-icon) {
      background-color: lighten($dark-grey, 60%);
    }
  }

  &:active {
    background: transparent;

    &:deep(.svg-icon) {
      background-color: lighten($dark-grey, 55%);
    }
  }
}
</style>
