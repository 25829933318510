import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
import { isEmpty, isEqual } from 'lodash'

import i18n from '@/i18n'
import { compensateTimeZone, getDisplayedDates } from '@/utils/date'
import { INTERVAL_TIME_RANGE_OPTIONS } from '@/utils/okr-elements/filters'

dayjs.extend(quarterOfYear)
dayjs.extend(isBetween)

export const isBetweenDates = (start, end) => dayjs().isBetween(start, end, 'days', '[]')

export const predefinedCycles = (fromCurrentQuarter = false, quartersCount = 4) => {
  const currentQuarter = fromCurrentQuarter ? dayjs(Date.now()).quarter() : 1

  return [...Array(quartersCount).keys()].map(i => {
    const start = dayjs()
      .quarter(i + currentQuarter)
      .startOf('quarter')
    const end = dayjs()
      .quarter(i + currentQuarter)
      .endOf('quarter')
    return {
      value: i,
      label: `Q${start.quarter()}’${start.year()}`,
      start: start.toString(),
      end: end.toString()
    }
  })
}

export const getBacklogIntervalId = intervals => {
  const backlogInterval = intervals.find(interval => interval.backlog)
  const [firstFoundedInterval] = intervals
  const backlogIntervalIdOrFirstFounded = backlogInterval || firstFoundedInterval
  return isEmpty(intervals) ? null : backlogIntervalIdOrFirstFounded.id
}

export const getSuitableInterval = (
  allIntervals,
  selectedIntervals,
  setCurrentInterval = false
) => {
  const backlogIntervalId = getBacklogIntervalId(allIntervals)
  const onlyRealIntervals = allIntervals.filter(interval => !interval.backlog && interval.open)
  const firstRealIntervalId = isEmpty(onlyRealIntervals)
    ? backlogIntervalId
    : onlyRealIntervals[0].id

  if (isEmpty(selectedIntervals) && !setCurrentInterval) {
    return firstRealIntervalId
  } else if (setCurrentInterval) {
    const currentInterval = allIntervals.find(interval => {
      const { startDate, endDate } = interval
      return isBetweenDates(startDate, endDate) && !interval.backlog && interval.open
    })
    return currentInterval ? currentInterval.id : firstRealIntervalId
  } else {
    const [firstSelectedInterval] = selectedIntervals
    return firstSelectedInterval
  }
}

export const savedIntervalAvailable = (allIntervals, savedInterval) => {
  return allIntervals.map(interval => interval.id).includes(savedInterval)
}

export const getAvailableSavedIntervals = ({ allIntervals, savedIntervals }) => {
  const allIntervalIds = allIntervals.map(interval => interval.id)
  const validSavedIntervals = savedIntervals.filter(savedInterval =>
    allIntervalIds.includes(savedInterval)
  )

  if (isEmpty(validSavedIntervals)) {
    return [getSuitableInterval(allIntervals, validSavedIntervals, true)]
  } else {
    return validSavedIntervals
  }
}

export const sortIntervals = intervals => {
  return intervals.sort((a, b) => {
    if (b.backlog) return 1

    if (a.open && !b.open) {
      return -1
    }
    if (!a.open && b.open) {
      return 1
    }
    return a.startDate - b.startDate
  })
}

export const getActiveIntervals = intervals => intervals.filter(interval => interval.open)

export const getArchivedIntervals = intervals => intervals.filter(interval => !interval.open)

export const isIntervalNameReserved = name => {
  const reservedNames = ['Future (backlog)']
  return reservedNames.includes(name.trim())
}

export const filterOnBacklogInterval = ({ intervals, value, selectedIntervals }) => {
  let newValue = []
  const backlogIntervalId = getBacklogIntervalId(intervals)
  if (isEmpty(value)) {
    newValue = [getSuitableInterval(intervals, [], true)]
  } else {
    const selectedIntervalsWithoutBacklog = value.filter(item => item !== backlogIntervalId)
    const backlogIntervalAdded =
      isEqual(selectedIntervalsWithoutBacklog, selectedIntervals) &&
      value.includes(backlogIntervalId)
    newValue =
      backlogIntervalAdded || selectedIntervalsWithoutBacklog.length === 0
        ? [backlogIntervalId]
        : selectedIntervalsWithoutBacklog
  }
  return newValue
}

export const displayedLockedDates = ({ selectedDates, customRangeOptionsBasedOnFilters }) => {
  const {
    isBeforeRangeSelected,
    isBetweenRangeSelected,
    isSinceRangeSelected,
    isTimeRangeSelected
  } = customRangeOptionsBasedOnFilters

  if (!isTimeRangeSelected) {
    return {}
  }

  const { startDates, dueDates } = selectedDates
  const [startDateFrom] = startDates
  const [, dueDateTo] = dueDates

  let selectedDatesValue = []

  if (isBetweenRangeSelected) {
    selectedDatesValue = [startDateFrom, dueDateTo]
  }

  if (isBeforeRangeSelected) {
    selectedDatesValue = [dueDateTo, dueDateTo]
  }

  if (isSinceRangeSelected) {
    selectedDatesValue = [startDateFrom, startDateFrom]
  }

  let result = getDisplayedDates({
    selectedDates: selectedDatesValue.map(date => compensateTimeZone(date))
  })

  const DASH = '—'

  if (isBetweenRangeSelected) {
    const keyWord = i18n.global.t('filter.custom_range.between')
    result = {
      ...result,
      keyWord: keyWord.toLowerCase(),

      customRangePickerTrigger: {
        start: `${result.start} ${DASH}`,
        end: result.end
      }
    }
  }

  if (isBeforeRangeSelected) {
    const keyWord = i18n.global.t('filter.custom_range.before')
    result = {
      ...result,
      start: DASH,
      keyWord: keyWord.toLowerCase(),

      full: `${keyWord} ${result.end}`,
      customRangePickerTrigger: {
        start: '',
        end: result.end
      }
    }
  }

  if (isSinceRangeSelected) {
    const keyWord = i18n.global.t('filter.custom_range.since')
    result = {
      ...result,
      end: DASH,
      keyWord: keyWord.toLowerCase(),
      full: `${keyWord} ${result.start}`,
      customRangePickerTrigger: {
        start: result.start,
        end: ''
      }
    }
  }

  return result
}
const { BEFORE, SINCE } = INTERVAL_TIME_RANGE_OPTIONS

export const DEFAULT_DATES_RANGE = []
export const DEFAULT_INTERVAL_CUSTOM_DATE = null
export const DEFAULT_INTERVAL_CUSTOM_DATE_OBJECT = {
  [BEFORE.value]: DEFAULT_INTERVAL_CUSTOM_DATE,
  [SINCE.value]: DEFAULT_INTERVAL_CUSTOM_DATE
}

export const actualizeSelectedCustomRangeMode = ({
  customRangeOptionsBasedOnFilters,
  selectedIntervalCustomRangeMode
}) => {
  const {
    isBetweenRangeSelected,
    isBeforeRangeSelected,
    isSinceRangeSelected,
    isTimeRangeSelected
  } = customRangeOptionsBasedOnFilters

  if (isTimeRangeSelected) {
    const { BETWEEN, BEFORE, SINCE } = INTERVAL_TIME_RANGE_OPTIONS

    const [modeBySelectedDates] = [
      isBetweenRangeSelected && BETWEEN.value,
      isBeforeRangeSelected && BEFORE.value,
      isSinceRangeSelected && SINCE.value
    ].filter(Boolean)

    if (selectedIntervalCustomRangeMode !== modeBySelectedDates) {
      return modeBySelectedDates
    }
    return null
  }
  return null
}

export const UNSELECTED_INTERVAL = null
