<template>
  <div
    :data-auto-test-inital-data-loaded="initialDataAreLoaded"
    class="lji-LinkJiraIssueModal"
    data-auto-testid="issue-form"
  >
    <portal :to="portalName">
      <AppSelect
        ref="parentSelect"
        v-model="formModel.parentId"
        :bottom-fixed-items="getBottomFixedItems"
        :content-class="['lji-ParentObjective']"
        :disabled="disableOnEdit"
        :dropdown-search="true"
        :item-label="item => `${item.displayId} ${item.name}`"
        :loading="isParentObjectivesLoading"
        :offset="[0, 0]"
        :options="parentObjectives"
        :required="true"
        :search-function="getParentObjectives"
        data-auto-testid="parent-field"
        data-testid="parent-field"
        item-value="id"
        @update:options="parentObjectives = $event"
        @update:model-value="onParameterUpdate($options.OKR_PARAMETERS_SAVING_STATUSES.PARENT)"
      >
        <template #button="{ option, active }">
          <OkrElementSelectItem
            v-if="option"
            :active="active"
            :id-as-link="parenElementReadable && isEdit"
            :objective="option"
            data-auto-testid="parent-element"
            rotate-toggle-sign
            @click-on-id="onParentObjectiveIdClick"
          />
          <ImmediateParentLoader v-else />
        </template>

        <template #option-label="{ option }">
          <ObjectiveSelectItem v-if="option" :objective="option" :show-interval="true" />
        </template>
        <template #bottom-fixed-items="{ bottomFixedItems, searchString }">
          <div v-for="item in bottomFixedItems" :key="item.id">
            <BottomFixedSelectItem v-if="!searchString" icon-name="info-next" no-hover>
              {{ item.text }}
            </BottomFixedSelectItem>
          </div>
        </template>
      </AppSelect>

      <div class="lji-ContributeWrapper">
        <AppCheckbox
          v-model="formModel.contribute"
          :disabled="disableOnEdit"
          data-auto-testid="contribute-switch"
          @update:model-value="
            onParameterUpdate($options.OKR_PARAMETERS_SAVING_STATUSES.CONTRIBUTE)
          "
        >
          {{ $t('field.contribute.label') }}
        </AppCheckbox>
      </div>
    </portal>

    <SidebarLayout>
      <div class="lji-TitleWrapper">
        <LockMessage
          v-if="lockedMessagesStates.isShowNoPermissionsMessage"
          :type="LOCK_MESSAGE_TYPES.WARNING"
          class="lji-NoPermissionsMessage"
        >
          {{ $t('lock_message', { element: $t('element.type.jira_issue') }) }}
        </LockMessage>
        <SkeletonItem v-if="areIssuesLoading" :size="SKELETON_SIZES.LG" width="100%" />
        <TitleWithAction v-else>
          <template v-if="selectedIssueName" #prepend>
            {{ $t('link_jira_issue.linked_issue') }}
          </template>
          {{ selectedIssueName || $t('action.link_jira_issue') }}
        </TitleWithAction>
      </div>
      <AppRadioGroup
        v-if="!isEdit"
        :disabled="!userData.hasAccessToJira"
        :model-value="selectedSearchType"
        :options="searchTypes"
        class="lji-TitleRadioGroup"
        name="searchType"
        type="tab-like"
        @update:model-value="selectSearchType"
      />

      <LockMessage
        v-if="lockedMessagesStates.isShowClosedParentMessage"
        :type="LOCK_MESSAGE_TYPES.WARNING"
        class="lji-LockMessage"
        icon-name="warning-icon"
      >
        <i18n-t keypath="lock_message.entity_parent_closed" scope="global">
          <template #parentOkr>{{ getParentOkrTypeName }}</template>
          <template #statusName> {{ getParentElementClosedStatusName }}</template>
        </i18n-t>
      </LockMessage>

      <template v-if="jiraNameSearchTypeSelected">
        <AppSelect
          ref="search"
          v-model="formModel.taskId"
          :disabled="isTaskIdSelectDisabled"
          :item-label="item => getSelectTitle(item)"
          :loading="areIssuesLoading"
          :offset="[0, 0]"
          :options="issues"
          :placeholder="$t('field.issue.name_placeholder')"
          :search-function="getIssues"
          :type="SELECT_TYPES.MODERN_LARGE_LIGHT_NEXT"
          data-auto-testid="issues-field"
          data-testid="issues-field"
          dropdown-search
          item-value="key"
          required
          @update:options="issues = $event"
          @update:model-value="onParameterUpdate($options.OKR_PARAMETERS_SAVING_STATUSES.NAME)"
        >
          <template #button="{ active, option }">
            <LinkToParentButton
              v-if="!option || !option.id"
              :hide-icon="areIssuesLoading"
              :is-active="active"
              type="secondary"
            />
          </template>
          <template #prepend-icon="{ option }">
            <div class="lji-SelectPrepend">
              <template v-if="option && formModel.taskId">
                <img :src="option.fields.issuetype.iconUrl" alt="" class="lji-TaskIcon" />
              </template>
              <template v-else>
                <AppIcon :height="24" :width="24" icon-name="search" />
                <span class="lji-TextPlaceholder">{{ $t('action.link_jira_issue') }}</span>
              </template>
            </div>
          </template>

          <template #option-label="{ option }">
            <span
              v-tippy="{
                content: getSelectTitle(option)
              }"
              class="lji-SelectOption"
            >
              <img :src="option.fields.issuetype.iconUrl" alt="" class="lji-TaskIcon" />
              <span class="lji-SelectOption-Text">{{ getSelectTitle(option) }}</span>
            </span>
          </template>
          <template #button-content="{ option }">
            <span
              v-tippy="{
                content: getSelectTitle(option)
              }"
              class="lji-SelectOption"
            >
              <span class="lji-SelectOption-Text">{{ getSelectTitle(option) }}</span>
            </span>
          </template>
        </AppSelect>

        <div
          v-if="isEdit"
          :class="{ 'lji-ResultTypeWrapper-isAutoMode': isAutoMode }"
          class="lji-ResultTypeWrapper"
        >
          <!--          // PENDING: feature OK-3106-->
          <!--          <ResultTypeSettings-->
          <!--            :class="{ 'lji-ResultTypeSettings-isAutoMode': isAutoMode }"-->
          <!--            :count="resultTypeSettingsCount"-->
          <!--            :type="getResultTypeSettingsType"-->
          <!--          >-->
          <ResultTypeSettings :count="resultTypeSettingsCount">
            <template #left-side>
              <AppRadioGroup
                :disabled="resultTypeSettingsDisabledStates.select"
                :model-value="resolvedFieldId"
                :options="RESULT_TYPE_OPTIONS"
                data-auto-testid="value-type-field"
                data-testid="value-type-field"
                name="result-type"
                style="--option-padding: 0 8px 0 0"
                type="primary-next"
                @update:model-value="changeResultType"
              >
                <template #item-label="{ item }">
                  <AppRadioGroupNextOptionWithIcon :option="item" />
                </template>
              </AppRadioGroup>
            </template>

            <template #right-side>
              <FormFieldNext v-if="isAutoMode">
                <div class="lji-AutoModeWrapper">
                  <div class="lji-AutoModeLeftSide">
                    <AppIcon class="lji-JiraIcon" height="64" icon-name="jira-icon" width="64" />

                    <span class="lji-AutoModeLeftSide_Text">
                      {{ $t('jira.auto_grade.description') }}
                    </span>
                  </div>
                  <div class="lji-AutoModeRightSide" />
                </div>
              </FormFieldNext>
              <ResultTypeSettingsDefaultRightSide
                v-else
                v-model:current-value="currentValue"
                v-model:original-value="originalValue"
                v-model:target-value="targetValue"
                :editable="editable"
                :field-type-id="resolvedFieldId"
                :form-model="formModel"
                :is-auto-mode="isAutoMode"
                :is-binary="isBinary"
                :is-binary-select-disabled="isBinarySelectDisabled"
                :is-edit="isEdit"
                :is-locked-on-percent="isLockedOnPercent"
                :values-are-readonly="valuesAreReadonly"
                @update:binary-value="onBinaryValueUpdate"
                @blur:original-value="onOriginalValueUpdate"
                @blur:current-value="onCurrentValueUpdate"
                @blur:target-value="onTargetValueUpdate"
              />
            </template>
          </ResultTypeSettings>
        </div>
        <ExternalJiraLink
          v-if="formModel.taskId"
          :jira-issue-id="formModel.taskId"
          class="lji-ExternalLink"
        >
          {{ $t('link_jira_issue.open_task') }}
        </ExternalJiraLink>
      </template>

      <div v-else class="lji-JqlSearchInputWrapper">
        <AppInput
          v-model="formModel.jql"
          :placeholder="$t('field.issue.jql_placeholder')"
          class="lji-JqlSearchInputWrapper-Input"
          size="xlg"
          style-type="primary"
          @update:model-value="onJqlSearchQueryUpdate"
          @keydown.enter="updateJqlIssues"
        >
          <template #prepend>
            <div class="lji-JqlInputPrepend">
              <div v-if="areIssuesLoading" class="lji-JqlInputLoader">
                <LoadingCircle size="xsmall" />
              </div>
              <AppIcon
                v-else-if="jqlError && isJqlResponded"
                height="24"
                icon-name="jql-error"
                width="24"
              />
              <AppIcon
                v-else-if="isJqlResponded"
                height="24"
                icon-name="check-circle-success"
                width="24"
              />
              <AppIcon v-else height="24" icon-name="search-next" width="24" />
            </div>
          </template>
        </AppInput>
        <AppButton
          :remove-padding="true"
          size="lg-next"
          type="primary-next"
          @click="updateJqlIssues"
        >
          {{ $t('placeholder.search') }}
        </AppButton>
      </div>

      <div v-if="isShowJqlResultDescription" class="lji-JqlResultDescription">
        <div class="lji-JqlResultDescription-Description">
          <span v-if="jqlError === JQL_MESSAGES.BAD_REQUEST" class="lji-JqlResultDescription-Text">
            {{ $t('link_jira_issue.jql_error') }}<br />
            <span v-for="warning in jqlWarnings" :key="warning"> {{ warning }}<br /> </span>
          </span>
          <span v-else-if="jqlError === JQL_MESSAGES.TIMEOUT" class="lji-JqlResultDescription-Text">
            <AppIcon height="24" icon-name="info-next" width="24" />
            {{ $t('link_jira_issue.jql_timeout') }}
          </span>
          <div v-else class="lji-JqlResultDescription-Summary">
            <span class="lji-JqlResultDescription-Text">
              <AppIcon height="24" icon-name="info-next" width="24" />
              {{ $t('link_jira_issue.jira_issues_found', { count: totalJqlIssues }) }}
            </span>
            <span v-if="totalJqlIssues > MAX_JQL_LINK_COUNT" class="lji-JqlResultDescription-Text">
              <AppIcon height="24" icon-name="info-next" width="24" />
              ({{ $t('link_jira_issue.import_count_jira_issues', MAX_JQL_LINK_COUNT) }})
            </span>
            <a :href="jqlResultsLink" class="lji-JqlResultDescription-Text" target="_blank">
              <AppIcon height="24" icon-name="shortcut-next" width="24" />
              {{ $t('link_jira_issue.check_results') }}
            </a>
          </div>
        </div>
      </div>

      <OkrElementsFormCustomFieldsHub
        v-model:loading="contentCustomFieldsLoading"
        :data-testid="OKR_ELEMENT_FORM_CUSTOM_FIELD_HUB_TEST_ID"
        :element-id="modelValue.id || null"
        :fields-values="customFieldsValues"
        :is-edit="isEdit"
        :okr-type-id="CUSTOM_FIELDS_OKR_TYPE_IDS.TASK.id"
        :user-has-update-access="userCanUpdateCustomFields"
        :workspace-id="workspaceId"
        @update-field-value="onUpdateCustomFieldValue"
      />

      <template #sidebar>
        <div class="lji-Sidebar">
          <FormJiraIssueGradeAndStatus
            v-if="isEdit"
            :grade="modelValue.gradeToUse"
            :jira-status="modelValue.jiraStatus"
            :jira-status-color="modelValue.jiraStatusColor"
          />

          <FormItemsGroup :disabled="disableOnEdit" data-auto-testid="general-fields-group">
            <AppSelect
              v-model="formModel.ownerId"
              :disabled="disableOnEdit"
              :loading="areAssigneesLoading"
              :offset="[0, 0]"
              :options="ownerFieldOptions"
              :search-function="getAssignees"
              data-auto-testid="owner-field"
              data-testid="owner-field"
              dropdown-search
              item-label="name"
              item-value="accountId"
              skeleton-loader
              @change="onOwnerSelect"
              @update:options="assignees = $event"
              @update:model-value="onParameterUpdate($options.OKR_PARAMETERS_SAVING_STATUSES.OWNER)"
            >
              <template #button="{ option, active }">
                <OkrFormFieldSelectTriggerNext :opened="active">
                  <OwnerFieldOption v-if="option" :option="option" />
                </OkrFormFieldSelectTriggerNext>
              </template>
              <template #option-label="{ option }">
                <OwnerFieldOption :option="option" />
              </template>
            </AppSelect>

            <AppSelect
              v-model="formModel.groups"
              :disabled="disableOnEdit"
              :loading="areGroupsLoading"
              :offset="[0, 0]"
              :options="groups"
              :search-function="getGroups"
              data-auto-testid="groups-field"
              data-testid="groups-field"
              has-only-this-button
              hide-selected-items-in-dropdown
              inline-search
              item-label="name"
              item-value="id"
              multi
              show-selected-options-inside
              skeleton-loader
              @change="onGroupSelect"
              @update:options="groups = $event"
              @update:model-value="onParameterUpdate($options.OKR_PARAMETERS_SAVING_STATUSES.GROUP)"
            >
              <template #option-label="{ option }">
                <GlobalGroupsSelectOption v-if="option" :group="option" />
              </template>
              <template #button="{ fullDataOption, active }">
                <OkrFormFieldSelectTriggerNext
                  :empty-state-label="$t('field.group.placeholder')"
                  :label="$t('field.groups.title')"
                  :opened="active"
                  :selected-options="fullDataOption"
                  style="--value-item-padding: 0"
                >
                  <template #value-item-content="{ item }">
                    <GroupSelectLabel v-if="item" :group="item" />
                  </template>
                </OkrFormFieldSelectTriggerNext>
              </template>
            </AppSelect>
          </FormItemsGroup>
          <FormItemsGroup data-auto-testid="common-fields-group">
            <OkrFormFieldSelectTriggerNext
              :disabled="isDateFieldDisabled"
              :opened="showDatesDropdown"
              data-auto-testid="dates-field-wrapper"
              data-testid="dates-field-wrapper"
              @click="onDatesTriggerClick"
            >
              <template #label>
                <div class="lji-DatesLabel">
                  <span class="lji-DatesLabel_Text">
                    {{ $t('objectives.table_header_dates') }}
                  </span>
                  <span class="lji-DatesLabel_Value">
                    <OkrDatesDropdownTrigger
                      :active="showDatesDropdown"
                      :form-model="formModel"
                      multi-line
                      type="default-next"
                    />

                    <OkrDatesDropdown
                      v-if="!isDateFieldDisabled && initialDataAreLoaded"
                      :follow-cursor="false"
                      :objective="formModel"
                      :offset="[0, 10]"
                      :show-dates-dropdown="showDatesDropdown"
                      append-to=".lji-LinkJiraIssueModal"
                      data-auto-testid="dates-field"
                      data-testid="dates-field"
                      position="bottom"
                      split-update-date-events
                      @hide-dates-dropdown="showDatesDropdown = false"
                      @update-element-date="onUpdateElementDate"
                      @update-period-mode="updatePeriodMode"
                    />
                  </span>
                </div>
              </template>
            </OkrFormFieldSelectTriggerNext>
          </FormItemsGroup>

          <OkrElementsFormCustomFieldsHub
            v-model:loading="sidebarCustomFieldsLoading"
            :data-testid="OKR_ELEMENT_FORM_CUSTOM_FIELD_HUB_TEST_ID"
            :element-id="modelValue.id || null"
            :fields-values="customFieldsValues"
            :is-edit="isEdit"
            :okr-type-id="CUSTOM_FIELDS_OKR_TYPE_IDS.TASK.id"
            :placement="CUSTOM_FIELDS_PLACEMENTS.SIDEBAR"
            :user-has-update-access="userCanUpdateCustomFields"
            :workspace-id="workspaceId"
            @update-field-value="onUpdateCustomFieldValue"
          />
        </div>
      </template>

      <template #footer>
        <ModalFooterActions
          v-if="!isEdit"
          v-model="createAnotherStatus"
          :disabled="saveButtonDisabled"
          :loading="loading"
          data-auto-testid="create-okr-footer"
          data-testid="create-okr-footer"
          @close="close"
          @create="save"
        >
          <template #checkbox-label>
            {{ $t('link_jira_issue.link_another_task') }}
          </template>

          <template #cancel-button-text>
            {{ $t('action.cancel') }}
          </template>

          <template #confirm-button-text> {{ $t('action.link') }}</template>
        </ModalFooterActions>
      </template>
    </SidebarLayout>
  </div>
</template>

<script>
import { cloneDeep, isEmpty, isNull, isUndefined, uniqBy } from 'lodash'
import { defineComponent } from 'vue'
import { mapState, mapGetters } from 'vuex'

import AssigneesInfoApiHandler from '@/api/assignees-info'
import GlobalGroupsApiHandler from '@/api/global-groups'
import ObjectivesInfoApiHandler from '@/api/okr-elements'
import { BOTTOM_INFO_PANEL } from '@/composables/bottom-fixed-items'
import { tracker } from '@/tracking/amplitude'
import {
  EVENT_CATEGORIES,
  EVENT_SOURCES,
  trackManualUpdateGradeEvent
} from '@/tracking/amplitude-helpers'
import { trackJiraIssueLinkedEvent } from '@/tracking/gtm-helpers'
import { SELECT_TYPES } from '@/utils/components-configurations/app-select'
import { LOCK_MESSAGE_TYPES } from '@/utils/components-configurations/lock-message'
import { SKELETON_SIZES } from '@/utils/components-configurations/skeleton-item'
import { CUSTOM_FIELDS_PLACEMENTS } from '@/utils/custom-fields/factory'
import {
  createInitialCustomFieldsValuesForOkrElement,
  CUSTOM_FIELDS_OKR_TYPE_IDS,
  DEFAULT_CUSTOM_FIELDS_VALUES_FOR_OKR_ELEMENT
} from '@/utils/custom-fields/helpers'
import { OKR_ELEMENT_FORM_CUSTOM_FIELD_HUB_TEST_ID } from '@/utils/custom-fields/jest-helpers'
import {
  ASSIGNEE_ENTITY_KEYS,
  COMPANY_SETTINGS_ENTITY_KEYS,
  OKR_ELEMENT_ENTITY_KEYS
} from '@/utils/entity-keys'
import { handleError } from '@/utils/error-handling'
import { checkSomeValueIsTruthy } from '@/utils/general'
import { OBJECTIVE_TYPES, TYPE_ID_NAMES } from '@/utils/objective-types'
import {
  currentUserCanUpdateObjective,
  convertUsersListToOwnerAndStakeholders,
  createUsersList,
  convertGroupsListToGroups,
  createGroupsList,
  OKR_ELEMENT_PARAMETERS_SAVING_STATUSES,
  OKR_FORM_VIEWS,
  KR_VALUE_TYPES,
  AUTO_GRADE_OPTION_VALUE,
  getNotSetOwnerOption,
  currentUserCanReadObjective,
  isOkrElementClosed,
  isKR,
  getClosedStatusName
} from '@/utils/objectives'
import { PORTAL_NAMES } from '@/utils/okr-breadcrumbs'
import { OKR_DATES_SELECT_DATE_PROPS, PERIOD_MODES } from '@/utils/okr-element-dates'
import {
  notEqualStringifiedValues,
  DEFAULT_RESULT_TYPE_VALUES,
  DEFAULT_RESULT_TYPE_OPTIONS,
  getCurrentValue,
  getResultTypeValues,
  getResultTypeSettingsDisabledStates,
  GRADE_MODES,
  getOriginalValue,
  isBinarySelectDisabled,
  getTargetValue
} from '@/utils/okr-element-values'
import {
  createGetUsersPayload,
  getLockMessagesStates,
  getUpdatedGroupsList,
  isFormModelsEqual
} from '@/utils/okr-elements-forms-helpers'
import { EDIT_ELEMENT_QUERY_KEYS } from '@/utils/query-parameters'
import { replaceQueryParameters } from '@/utils/router'
import { getNavigationTabName } from '@/utils/tracking'
import IssuesApiHandler from '@jira/api/issues'
import { getBaseUrl } from '@jira/util'

import OkrElementsFormCustomFieldsHub from '@/components/custom-fields/okr-elements-form/OkrElementsFormCustomFieldsHub'
import FormFieldNext from '@/components/form/FormFieldNext'
import FormItemsGroup from '@/components/form/FormItemsGroup'
import FormJiraIssueGradeAndStatus from '@/components/form/FormJiraIssueGradeAndStatus'
import { SETTINGS_COUNT } from '@/components/form/key-result/result-type-settings'
import ResultTypeSettings from '@/components/form/key-result/ResultTypeSettings'
import ResultTypeSettingsDefaultRightSide from '@/components/form/key-result/ResultTypeSettingsDefaultRightSide'
import LockMessage from '@/components/form/LockMessage'
import OwnerFieldOption from '@/components/form/OwnerFieldOption'
import GlobalGroupsSelectOption from '@/components/global-groups/GlobalGroupsSelectOption'
import GroupSelectLabel from '@/components/global-groups/GroupSelectLabel'
import ExternalJiraLink from '@/components/objectives/ExternalJiraLink'
import LinkToParentButton from '@/components/objectives/forms/LinkToParentButton'
import ModalFooterActions from '@/components/objectives/forms/ModalFooterActions'
import ObjectiveSelectItem from '@/components/objectives/forms/ObjectiveSelectItem'
import OkrElementSelectItem from '@/components/objectives/forms/OkrElementSelectItem'
import OkrDatesDropdown from '@/components/objectives/OkrDatesDropdown'
import OkrDatesDropdownTrigger from '@/components/objectives/OkrDatesDropdownTrigger'
import TitleWithAction from '@/components/objectives/TitleWithAction'
import BottomFixedSelectItem from '@/components/objectives/toolbar/BottomFixedSelectItem'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppCheckbox from '@/components/ui/AppCheckbox/AppCheckbox'
import AppIcon from '@/components/ui/AppIcon/AppIcon'
import AppInput from '@/components/ui/AppInput/AppInput'
import AppRadioGroup from '@/components/ui/AppRadioGroup/AppRadioGroup'
import AppRadioGroupNextOptionWithIcon from '@/components/ui/AppRadioGroup/AppRadioGroupNextOptionWithIcon'
import AppSelect from '@/components/ui/AppSelect/AppSelect'
import OkrFormFieldSelectTriggerNext from '@/components/ui/AppSelect/TriggerButtons/OkrFormFieldSelectTriggerNext'
import LoadingCircle from '@/components/ui/LoadingCircle/LoadingCircle'
import SidebarLayout from '@/components/ui/SidebarLayout/SidebarLayout'
import ImmediateParentLoader from '@/components/ui/SkeletonLoaders/ImmediateParentLoader'
import SkeletonItem from '@/components/ui/SkeletonLoaders/SkeletonItem'

import 'vue2-datepicker/index.css'

const RESULT_TYPE_OPTIONS = [
  { icon: 'jira-icon', label: 'jira.auto_grade', value: AUTO_GRADE_OPTION_VALUE },
  ...DEFAULT_RESULT_TYPE_OPTIONS
]

const MAX_JQL_LINK_COUNT = 50

const DEFAULT_FORM_MODEL = {
  taskId: null,
  ownerId: null,
  parentId: null,
  groups: [],
  contribute: true,
  dueDate: null,
  elementStartDate: null,
  startDateManual: PERIOD_MODES.MANUAL.value,
  dueDateManual: PERIOD_MODES.MANUAL.value,
  automaticElementStartDate: null,
  automaticDueDate: null,
  jql: '',
  // is not editable in form, but needed for request
  intervalId: null,
  workspaceId: null,
  description: '',
  stakeholders: [],

  ...DEFAULT_RESULT_TYPE_VALUES,
  gradeMode: GRADE_MODES.AUTO,
  private: false
}
const JIRA_NAME = 'JIRA_NAME'
const JQL = 'JQL'

export default defineComponent({
  name: 'LinkJiraIssue',

  components: {
    OkrElementsFormCustomFieldsHub,
    GlobalGroupsSelectOption,
    GroupSelectLabel,
    BottomFixedSelectItem,
    ExternalJiraLink,
    ResultTypeSettingsDefaultRightSide,
    ResultTypeSettings,
    AppRadioGroupNextOptionWithIcon,
    FormFieldNext,
    LockMessage,
    TitleWithAction,
    ModalFooterActions,
    FormJiraIssueGradeAndStatus,
    ImmediateParentLoader,
    FormItemsGroup,
    OwnerFieldOption,
    OkrFormFieldSelectTriggerNext,
    AppRadioGroup,
    OkrDatesDropdown,
    OkrDatesDropdownTrigger,
    OkrElementSelectItem,
    AppButton,
    AppIcon,
    AppInput,
    AppSelect,
    AppCheckbox,
    LoadingCircle,
    ObjectiveSelectItem,
    SidebarLayout,
    LinkToParentButton,
    SkeletonItem
  },

  props: {
    modelValue: {
      type: Object,
      default: null
    },

    source: {
      type: String,
      default: ''
    }
  },

  OKR_DATES_SELECT_DATE_PROPS: { ...OKR_DATES_SELECT_DATE_PROPS },

  emits: {
    'saving-started': null,
    'saving-ended': null,
    'on-created': null,
    close: null,
    'on-parent-objective-id-click': null,
    'update:model-value': null
  },

  data() {
    return {
      formModel: { ...DEFAULT_FORM_MODEL },
      localFormModel: { ...DEFAULT_FORM_MODEL },
      loading: false,
      createAnotherStatus: false,
      depth: -1,

      issues: [],
      areIssuesLoading: false,

      parentObjectives: [],
      isParentObjectivesLoading: false,
      areParentObjectivesLoaded: false,

      assignees: [],
      areAssigneesLoading: false,
      areAssigneesLoaded: false,
      isOwnerManuallyChanged: false,

      groups: [],
      showGroupNotSetOption: false,
      isGroupManuallyChanged: false,
      areGroupsLoading: false,
      jqlError: null,
      jqlWarnings: [],
      totalJqlIssues: 0,

      selectedSearchType: JIRA_NAME,
      jqlRequestTimerId: 0,

      initialDataAreLoaded: false,
      isJqlResponded: false,

      showDatesDropdown: false,

      binaryValueIsSaving: false,
      originalValueIsSaving: false,
      currentValueIsSaving: false,
      targetValueIsSaving: false,

      ...Object.fromEntries(
        Object.values(OKR_ELEMENT_PARAMETERS_SAVING_STATUSES).map(item => [item, false])
      ),

      contentCustomFieldsLoading: false,
      sidebarCustomFieldsLoading: false,
      customFieldsValues: DEFAULT_CUSTOM_FIELDS_VALUES_FOR_OKR_ELEMENT
    }
  },

  OKR_PARAMETERS_SAVING_STATUSES: { ...OKR_ELEMENT_PARAMETERS_SAVING_STATUSES },

  computed: {
    ...mapGetters('customFields', {
      fieldsByWorkspaceId: 'fieldsByWorkspaceId'
    }),

    // PENDING: feature OK-3106
    // ...mapGetters('system', {
    //   okrElementModalFullscreenState: 'okrElementModalFullscreenState'
    // }),

    ...mapState('system', {
      settings: state => state.settings,
      userData: state => state.userData || {}
    }),

    ...mapGetters('objectives', {
      getLevelItem: 'getLevelItem'
    }),

    SELECT_TYPES: () => SELECT_TYPES,

    SKELETON_SIZES: () => SKELETON_SIZES,

    OKR_ELEMENT_FORM_CUSTOM_FIELD_HUB_TEST_ID: () => OKR_ELEMENT_FORM_CUSTOM_FIELD_HUB_TEST_ID,

    CUSTOM_FIELDS_OKR_TYPE_IDS: () => CUSTOM_FIELDS_OKR_TYPE_IDS,

    CUSTOM_FIELDS_PLACEMENTS: () => CUSTOM_FIELDS_PLACEMENTS,

    MAX_JQL_LINK_COUNT: () => MAX_JQL_LINK_COUNT,

    RESULT_TYPE_OPTIONS: () => RESULT_TYPE_OPTIONS,

    LOCK_MESSAGE_TYPES: () => LOCK_MESSAGE_TYPES,

    // PENDING: feature OK-3106
    // isChild() {
    //   return true
    // },
    //
    // getResultTypeSettingsType() {
    //   const { CENTERED, DEFAULT } = RESULT_TYPE_SETTINGS_TYPES
    //   return this.okrElementModalFullscreenState && !this.isChild ? CENTERED : DEFAULT
    // },

    currentWorkspaceCustomFields() {
      return this.fieldsByWorkspaceId(this.workspaceId)
    },

    isClosedParent() {
      if (!this.formModel.parentId) return false
      return this.selectedParentElement && isOkrElementClosed(this.selectedParentElement)
    },

    isTaskIdSelectDisabled() {
      if (!this.userData.hasAccessToJira) return true

      // https://oboard.atlassian.net/jira/software/c/projects/OK/boards/14?quickFilter=114&selectedIssue=OK-3009 ↓
      return this.isEdit
    },

    disableOnEdit() {
      if (!this.initialDataAreLoaded) return true
      return this.isEdit && !this.editable
    },

    getParentOkrTypeName() {
      return isKR(this.selectedParentElement)
        ? this.$t('element.type.key_result')
        : this.$t('okr_elements.objective')
    },

    getParentElementClosedStatusName() {
      const statusName = getClosedStatusName({
        okrElement: this.selectedParentElement
      })
      return this.$t(statusName).toLowerCase()
    },

    selectedParentElement() {
      return this.parentObjectives.find(objective => objective.id === this.formModel.parentId)
    },

    parenElementReadable() {
      return currentUserCanReadObjective(this.selectedParentElement)
    },

    ownerFieldOptions() {
      return uniqBy(
        [getNotSetOwnerOption(this.$t), ...this.assignees],
        ASSIGNEE_ENTITY_KEYS.ACCOUNT_ID
      )
    },

    getBottomFixedItems() {
      return [
        {
          id: 0,
          text: this.$t('objectives.last_count_items_shown', {
            count: 10,
            entity: this.$t('objectives.okr', 2)
          }),

          action: BOTTOM_INFO_PANEL
        }
      ]
    },

    workspaceId() {
      if (this.modelValue && this.modelValue.workspaceId && this.modelValue.workspaceId !== -1) {
        return this.modelValue.workspaceId
      }
      return this.$route.params.workspaceId
    },

    resolvedFieldId() {
      if (this.isAutoMode) {
        return AUTO_GRADE_OPTION_VALUE
      }
      return this.formModel.fieldTypeId
    },

    targetValue: {
      get() {
        return getTargetValue(this.formModel)
      },

      set(value) {
        if (this.formModel.fieldTypeId === KR_VALUE_TYPES.NUMBER) {
          this.formModel.numberTargetValue = value
        } else if (this.formModel.fieldTypeId === KR_VALUE_TYPES.PERCENT) {
          this.formModel.percentTargetValue = value
        }
      }
    },

    currentValue: {
      get() {
        return getCurrentValue(this.formModel)
      },

      set(value) {
        if (this.formModel.fieldTypeId === KR_VALUE_TYPES.NUMBER) {
          this.formModel.numberCurrentValue = value
        } else if (this.formModel.fieldTypeId === KR_VALUE_TYPES.PERCENT) {
          this.formModel.percentCurrentValue = value
        }
        // else {
        //   this.formModel.currentValue = value;
        // }
      }
    },

    originalValue: {
      get() {
        return getOriginalValue(this.formModel)
      },

      set(value) {
        if (this.formModel.fieldTypeId === KR_VALUE_TYPES.NUMBER) {
          this.formModel.numberOriginalValue = value
        } else if (this.formModel.fieldTypeId === KR_VALUE_TYPES.PERCENT) {
          this.formModel.percentOriginalValue = value
        }
      }
    },

    isBinarySelectDisabled() {
      return isBinarySelectDisabled(this.modelValue)
    },

    isLockedOnPercent() {
      return this.modelValue?.existContributeChildren
    },

    valuesAreReadonly() {
      return this.isLockedOnPercent || (this.modelValue && this.modelValue.existContributeChildren)
    },

    resultTypeSettingsDisabledStates() {
      const { select, isSwitch, input } = getResultTypeSettingsDisabledStates({
        isEdit: this.isEdit,
        editable: this.editable,
        isLockedOnPercent: this.isLockedOnPercent,
        isBinarySelectDisabled: this.isBinarySelectDisabled,
        valuesAreReadonly: this.valuesAreReadonly
      })
      return {
        select,
        switch: isSwitch,
        input
      }
    },

    resultTypeSettingsCount() {
      if (this.isAutoMode) {
        return SETTINGS_COUNT[1]
      }
      if (this.isBinary) {
        return SETTINGS_COUNT[1]
      }

      if (this.isEdit && !this.isBinary) {
        return SETTINGS_COUNT[3]
      }

      return SETTINGS_COUNT[2]
    },

    isAutoMode() {
      return this.formModel.gradeMode === GRADE_MODES.AUTO
    },

    isBinary() {
      return this.formModel.fieldTypeId === KR_VALUE_TYPES.BINARY
    },

    isPercent() {
      return this.formModel.fieldTypeId === KR_VALUE_TYPES.PERCENT
    },

    JQL_MESSAGES() {
      return {
        BAD_REQUEST: 'bad_request',
        TIMEOUT: 'timeout'
      }
    },

    selectedIssueName() {
      const { formModel, issues } = this
      const { taskId } = formModel

      if (!taskId || isEmpty(issues)) {
        return null
      } else {
        const selectedIssue = issues.find(({ key }) => key === taskId)

        return selectedIssue ? this.getSelectTitle(selectedIssue) : null
      }
    },

    portalName() {
      return PORTAL_NAMES[OKR_FORM_VIEWS.LINK_JIRA_ISSUE]
    },

    isDateFieldDisabled() {
      if (!this.userData.hasAccessToJira) return true

      return this.isEdit && !this.userCanUpdateObjective
    },

    searchTypes() {
      return [
        {
          label: this.$t('users.table_header_name'),
          value: JIRA_NAME
        },
        {
          label: this.$t('link_jira_issue.jql'),
          value: JQL
        }
      ]
    },

    isShowJqlResultDescription() {
      return this.jqlSearchTypeSelected && this.isJqlResponded && !this.areIssuesLoading
    },

    jqlSearchTypeSelected() {
      return this.selectedSearchType === JQL
    },

    jiraNameSearchTypeSelected() {
      return this.selectedSearchType === JIRA_NAME
    },

    editDataAreSaving() {
      return (
        this.isEdit &&
        checkSomeValueIsTruthy(
          this.binaryValueIsSaving,
          this.originalValueIsSaving,
          this.currentValueIsSaving,
          this.targetValueIsSaving,
          ...Object.values(OKR_ELEMENT_PARAMETERS_SAVING_STATUSES).map(item => this[item]),
          this.contentCustomFieldsLoading,
          this.sidebarCustomFieldsLoading
        )
      )
    },

    editable() {
      if (!this.isEdit) {
        return false
      }
      return this.userCanUpdateObjective
    },

    isEdit() {
      return Boolean(this.modelValue && this.modelValue.id)
    },

    areDataChanged() {
      return !isFormModelsEqual({
        localFormModel: this.localFormModel,
        formModel: this.formModel,
        skipDescriptionCheck: true
      })
      // return JSON.stringify(this.localFormModel) !== JSON.stringify(this.formModel)
    },

    saveButtonDisabled() {
      if (this.jiraNameSearchTypeSelected) {
        return this.formModel.taskId === null
      }
      return this.jqlError || !this.issues.length || this.areIssuesLoading || !this.isJqlResponded
    },

    jqlResultsLink() {
      return `${getBaseUrl()}/issues/?jql=${encodeURIComponent(this.formModel.jql)}`
    },

    userCanUpdateObjective() {
      return this.modelValue && currentUserCanUpdateObjective(this.modelValue)
    },

    userCanUpdateCustomFields() {
      if (this.isEdit) {
        return this.userCanUpdateObjective
      }

      return true
    },

    lockedMessagesStates() {
      return getLockMessagesStates({
        isEdit: this.isEdit,
        modelValue: this.modelValue,
        isClosedParent: this.isClosedParent,
        contribute: this.formModel.contribute
      })
    }
  },

  watch: {
    async modelValue(newValue) {
      if (newValue) {
        this.saveModelValueToFormModel()
        this.saveFormModelLocal()
        this.initCustomFieldsValues()
      }
    },

    editDataAreSaving(newValue) {
      if (newValue) {
        this.$emit('saving-started')
      } else {
        this.$emit('saving-ended')
      }
    },

    'formModel.ownerId': async function ownerIdWatcher() {
      if (this.areAssigneesLoaded) {
        this.groups = await this.getGroups()
      }
    }
  },

  created() {
    this.saveModelValueToFormModel()
  },

  mounted() {
    this.areGroupsLoading = true
    this.isParentObjectivesLoading = true
    this.areAssigneesLoading = true

    if (this.isEdit) {
      this.isGroupManuallyChanged = true
      this.isOwnerManuallyChanged = true
    }
    if (this.isEdit && !this.valuesAreReadonly && !this.isBinary && !this.isAutoMode) {
      this.setFocusOnCurrent()
    }

    this.fetchData()

    if (!this.isEdit) {
      // wait for modal open animation
      setTimeout(() => {
        this.setFocusOnSearch()
      }, 200)
    }

    this.saveFormModelLocal()
    this.initialDataAreLoaded = true
  },

  methods: {
    setFocusOnCurrent() {
      // nextTick and setTimeout(both!) are needed to make input always focused on modal window
      // opening because modal window(o-modal) has transition of opacity with 0.2s
      this.$nextTick(() => {
        setTimeout(() => {
          if (this.$refs.current) {
            this.$refs.current.selectAll()
          }
        }, 100)
      })
    },

    saveModelValueToFormModel() {
      if (this.modelValue) {
        if (this.modelValue.depth) {
          this.depth = this.modelValue.depth
        }

        this.formModel = {
          ...this.formModel,
          ...this.modelValue,
          taskId: this.modelValue.jiraIssueId || null,
          ...convertUsersListToOwnerAndStakeholders(this.modelValue.users),
          groups: convertGroupsListToGroups(this.modelValue.groups),
          parentId: this.modelValue.parentId,
          dueDate: this.modelValue.dueDate ? new Date(this.modelValue.dueDate) : null,
          elementStartDate: this.modelValue.elementStartDate
            ? new Date(this.modelValue.elementStartDate)
            : null,

          workspaceId: this.modelValue.workspaceId,
          intervalId: this.modelValue.intervalId,

          fieldTypeId: this.modelValue.fieldTypeId || DEFAULT_FORM_MODEL.fieldTypeId,
          originalValue: this.modelValue.originalValue || DEFAULT_FORM_MODEL.originalValue,
          currentValue: this.modelValue.currentValue || DEFAULT_FORM_MODEL.currentValue,
          targetValue: this.modelValue.targetValue || DEFAULT_FORM_MODEL.targetValue,
          gradeMode: this.modelValue.gradeMode || DEFAULT_FORM_MODEL.gradeMode,
          gradeToUse: this.modelValue.gradeToUse,
          autoGrade: this.modelValue.autoGrade,
          grade: this.modelValue.grade,
          contribute: isUndefined(this.modelValue.contribute)
            ? this.settings[COMPANY_SETTINGS_ENTITY_KEYS.TASK_CONTRIBUTE_BY_DEFAULT]
            : this.modelValue.contribute,

          private: isUndefined(this.modelValue.private)
            ? DEFAULT_FORM_MODEL.private
            : this.modelValue.private
        }

        if (this.isEdit) {
          const values = getResultTypeValues(this.formModel)

          this.formModel = {
            ...this.formModel,
            ...values
          }
        }
      }
    },

    async onCurrentValueUpdate() {
      if (
        this.isEdit &&
        notEqualStringifiedValues(this.currentValue, this.modelValue.currentValue)
      ) {
        this.currentValueIsSaving = true
        await this.updateLinkJiraIssue()
        trackManualUpdateGradeEvent({
          confidenceLevel: this.modelValue.jiraStatus,
          id: this.modelValue.id,
          issueType: TYPE_ID_NAMES[OBJECTIVE_TYPES.TASK],
          source: EVENT_SOURCES.FORM,
          value: this.currentValue
        })
        this.currentValueIsSaving = false
      }
    },

    async onTargetValueUpdate() {
      if (this.isEdit && notEqualStringifiedValues(this.targetValue, this.modelValue.targetValue)) {
        this.targetValueIsSaving = true
        await this.updateLinkJiraIssue()
        this.targetValueIsSaving = false
      }
    },

    async onOriginalValueUpdate() {
      if (
        this.isEdit &&
        notEqualStringifiedValues(this.originalValue, this.modelValue.originalValue)
      ) {
        this.originalValueIsSaving = true
        await this.updateLinkJiraIssue()
        this.originalValueIsSaving = false
      }
    },

    async onBinaryValueUpdate(value) {
      this.formModel.binaryValue = value
      this.binaryValueIsSaving = true
      await this.updateLinkJiraIssue()
      if (this.isEdit) {
        trackManualUpdateGradeEvent({
          confidenceLevel: this.modelValue.jiraStatus,
          id: this.modelValue.id,
          issueType: TYPE_ID_NAMES[OBJECTIVE_TYPES.TASK],
          source: EVENT_SOURCES.FORM,
          value: value * 100 // for convert boolean to percent
        })
      }
      this.binaryValueIsSaving = false
    },

    async changeResultType(resultType) {
      if (resultType !== AUTO_GRADE_OPTION_VALUE) {
        this.formModel.gradeMode = GRADE_MODES.MANUAL
      } else {
        this.formModel.gradeMode = GRADE_MODES.AUTO
      }
      this.formModel.fieldTypeId = resultType
      this[OKR_ELEMENT_PARAMETERS_SAVING_STATUSES.TYPE] = true
      await this.updateLinkJiraIssue()
      this[OKR_ELEMENT_PARAMETERS_SAVING_STATUSES.TYPE] = false
    },

    getSelectTitle(issue) {
      return issue ? `${issue.key} ${issue.fields.summary}` : ''
    },

    updatePeriodMode(mode) {
      this.formModel.startDateManual = mode
      this.formModel.dueDateManual = mode
      if (mode) {
        this.formModel.elementStartDate = this.formModel.automaticElementStartDate
        this.formModel.dueDate = this.formModel.automaticDueDate
      } else {
        this.formModel.elementStartDate = null
        this.formModel.dueDate = null
      }

      this.onParameterUpdate(OKR_ELEMENT_PARAMETERS_SAVING_STATUSES.PERIOD_MODE)
    },

    async onParameterUpdate(savingStatus) {
      this[savingStatus] = true
      await this.updateLinkJiraIssue()
      this[savingStatus] = false
    },

    async onUpdateCustomFieldValue({ fieldId, value }) {
      this.customFieldsValues[fieldId] = value
    },

    onDatesTriggerClick() {
      if (!this.isDateFieldDisabled) {
        this.showDatesDropdown = !this.showDatesDropdown
      }
    },

    onUpdateElementDate(params) {
      const { val, dateProp, savingStatus } = params

      this.formModel[dateProp] = val

      this.onParameterUpdate(savingStatus)
    },

    async updateLinkJiraIssue() {
      if (this.initialDataAreLoaded && this.isEdit && this.areDataChanged) {
        // wait for refs after result type change
        await this.$nextTick()

        await this.save(false)
        this.saveFormModelLocal()
      }
    },

    initCustomFieldsValues() {
      this.customFieldsValues = {
        ...createInitialCustomFieldsValuesForOkrElement({
          isEdit: this.isEdit,
          elementTypeId: OBJECTIVE_TYPES.TASK,
          modelValueCustomFields: this.modelValue?.customFields,
          currentWorkspaceCustomFields: this.currentWorkspaceCustomFields
        })
      }
    },

    saveFormModelLocal() {
      this.localFormModel = cloneDeep(this.formModel)
    },

    async fetchData() {
      const handleAssignees = async () => {
        this.assignees = await this.getAssignees(null, true)
      }

      const handleIssues = async () => {
        this.issues = await this.getIssues()
      }

      const handleParentObjectives = async () => {
        this.parentObjectives = await this.getParentObjectives()
      }

      const handleGroups = async () => {
        this.groups = await this.getGroups()
      }

      if (!this.isEdit) {
        await handleAssignees()
      }

      const promises = [handleIssues(), handleParentObjectives(), handleGroups()]

      if (this.isEdit) {
        promises.unshift(handleAssignees())
      }

      Promise.all(promises).then(() => {
        this.initCustomFieldsValues()
        this.saveFormModelLocal()
        this.initialDataAreLoaded = true
      })
    },

    async save(close = true) {
      this.loading = true

      let currentValue = +this.currentValue
      if (!this.isBinary && !this.isEdit) {
        currentValue = +this.originalValue
      }

      let payload = {
        contribute: this.formModel.contribute,
        parentId: this.formModel.parentId,
        users: createUsersList(this.formModel),
        groups: createGroupsList(this.formModel.groups),
        workspaceId: this.formModel.workspaceId,
        dueDate: this.formModel.dueDate,
        elementStartDate: this.formModel.elementStartDate,
        intervalId: this.formModel.intervalId,
        levelId: this.getLevelItem('typeId', OBJECTIVE_TYPES.TASK).id,
        startDateManual: this.formModel.startDateManual,
        dueDateManual: this.formModel.dueDateManual,

        gradeMode: this.formModel.gradeMode,
        targetValue: this.isBinary ? 1 : +this.targetValue,
        fieldTypeId: this.formModel.fieldTypeId,
        originalValue: this.isBinary ? 0 : +this.originalValue,
        currentValue,
        private: this.modelValue.private
      }

      if (!this.isEdit) {
        payload.customFields = this.customFieldsValues
      }

      if (this.isAutoMode) {
        payload = {
          ...payload,
          // undefined values needed to clear this fields in create request
          targetValue: undefined,
          originalValue: undefined,
          currentValue: undefined
        }
      }
      if (this.isAutoMode && this.isEdit) {
        payload = {
          ...payload,
          // undefined values needed to clear this fields in create request
          fieldTypeId: undefined
        }
      }

      let task = null
      let isSuccessful = true
      let createdTask = null

      try {
        const objectivesApi = new ObjectivesInfoApiHandler()
        if (!this.isEdit) {
          if (this.jqlSearchTypeSelected) {
            payload.jiraIssueIds = this.issues.map(item => item.key)
          } else {
            payload.jiraIssueIds = [this.formModel.taskId]
          }
          payload = {
            ...payload,
            // undefined values needed to clear this fields in create request
            targetValue: undefined,
            originalValue: undefined,
            currentValue: undefined
          }

          task = await objectivesApi.createOkrElement(payload)
          if (task.elements.length > 0) {
            tracker.logEvent('Linked Jira-issue', {
              category: EVENT_CATEGORIES.OKR_MANAGEMENT,
              label: task.elements[0].issueType,
              event_ref: 'Plugin',
              source: this.source,
              tab: getNavigationTabName(this.$route),
              contribution: task.elements[0].contribute ? 'Yes' : 'No',
              ent_id: task.elements[0].displayId,
              link_type: this.selectedSearchType
            })

            trackJiraIssueLinkedEvent()
          }
        } else {
          payload.elementId = this.modelValue.id
          payload.jiraIssueId = this.formModel.taskId
          task = await objectivesApi.updateOkrElement(payload)
          const updatedElementParameters = task.updatedElementParameters
          const isParentUpdated = updatedElementParameters.some(
            ({ elementId }) => elementId === this.formModel.parentId
          )
          if (isParentUpdated) {
            this.parentObjectives = await this.getParentObjectives()
          }
        }
      } catch (error) {
        isSuccessful = false
        handleError({ error })
      }

      this.loading = false
      // if (isSuccessful && !this.isEdit && !this.createAnotherStatus) {
      //   this.$emit('on-created', task)
      // }

      if (isSuccessful) {
        if (!this.isEdit) {
          this.$emit('on-created', task)
          createdTask = task.elements[0]
        } else {
          createdTask = task.element
        }

        if (!this.isEdit && this.createAnotherStatus) {
          this.scheduleCreateAnother()
          this.setFocusOnSearch()
          // showNotify({
          //   title: this.$t('create.task.success_message')
          // })
        } else if (close) {
          const closeEventData = {
            checkDataChange: false,
            createdTasks: !this.isEdit ? task.elements : null,
            updatedElementParameters: task.updatedElementParameters
          }
          this.close(closeEventData)
        } else {
          this.$emit('update:model-value', createdTask)
        }
      }
    },

    async getAssignees(searchString = null, selectUser = false) {
      let result = []
      const api = new AssigneesInfoApiHandler()

      const { OWNER_ID } = OKR_ELEMENT_ENTITY_KEYS

      this.areAssigneesLoading = true
      const payload = createGetUsersPayload({
        workspaceId: this.workspaceId,
        searchString,
        userData: this.userData,
        isOwnerManuallyChanged: this.isOwnerManuallyChanged,
        isEdit: this.isEdit,
        formModel: this.formModel
      })

      try {
        const users = await api.getUsers(payload)

        result = [...users]

        if (
          !isEmpty(users) &&
          isNull(this.formModel[OWNER_ID]) &&
          selectUser &&
          !this.isOwnerManuallyChanged
        ) {
          this.formModel[OWNER_ID] = this.userData.userAccountId
        }

        if (!this.isEdit && isNull(this.formModel[OWNER_ID])) {
          this.formModel[OWNER_ID] = this.userData.userAccountId
        }

        this.$nextTick(() => {
          if (!this.areAssigneesLoaded) {
            this.localFormModel[OWNER_ID] = this.formModel[OWNER_ID]
          }
          this.areAssigneesLoaded = true
        })
      } catch (error) {
        handleError({ error })
      } finally {
        this.areAssigneesLoading = false
      }

      return result
    },

    async getParentObjectives(searchString = null) {
      const api = new ObjectivesInfoApiHandler()
      this.isParentObjectivesLoading = true

      const {
        intervalId,
        parentId // parentTypeId,
      } = this.formModel

      const payload = {
        elementTypeId: OBJECTIVE_TYPES.TASK,
        intervalId,
        elementId: this.modelValue?.id || null,
        parentId,
        searchString,
        workspaceId: this.formModel.workspaceId
      }
      let parentObjectives = []
      let indexToSelect = -1

      try {
        const { currentIndex, elements } = await api.getParentObjectiveInfo(payload)
        parentObjectives = elements
        indexToSelect = currentIndex
      } catch (error) {
        handleError({ error })
      } finally {
        this.isParentObjectivesLoading = false
      }

      if (searchString === null) {
        if (!this.formModel.parentId) {
          this.formModel.parentId = parentObjectives[indexToSelect].id
        }

        this.$nextTick(() => {
          if (!this.areParentObjectivesLoaded) {
            this.localFormModel.parentId = this.formModel.parentId
          }
          this.areParentObjectivesLoaded = true
        })
      }

      return parentObjectives
    },

    async onParentObjectiveIdClick(item) {
      const { $router, $route } = this
      let newQuery = cloneDeep($route.query)
      delete newQuery[EDIT_ELEMENT_QUERY_KEYS.EDIT_LINK_JIRA_ISSUE]
      await replaceQueryParameters($router, $route, {
        ...newQuery
      })

      this.$emit('on-parent-objective-id-click', item)
      this.$refs.parentSelect.hideDropdown()
    },

    async getGroups(searchString = null) {
      const api = new GlobalGroupsApiHandler()
      this.areGroupsLoading = true
      let result = []

      const { ownerId, groups: selectedGroups } = this.formModel

      try {
        // const { selectedIndexes, groups } = await api.getGroupsInfo({
        const groups = await api.getGroupsForFilter({
          searchString,
          groupIds: this.isGroupManuallyChanged ? selectedGroups : null,
          workspaceIds: [this.formModel.workspaceId]
        })
        this.isTeamsLoading = false

        if (!this.isEdit) {
          this.formModel.groups = getUpdatedGroupsList({
            users: this.assignees,
            ownerId,
            isGroupManuallyChanged: this.isGroupManuallyChanged,
            selectedGroups: this.formModel.groups
          })
        }

        this.$nextTick(() => {
          if (!this.areGroupsLoaded) {
            this.localFormModel.groups = this.formModel.groups
          }
          this.areGroupsLoaded = true
        })

        result = groups
      } catch (error) {
        handleError({ error })
      } finally {
        this.areGroupsLoading = false
      }

      return result
    },

    async getIssues(searchString = null) {
      const issuesApi = new IssuesApiHandler()

      this.areIssuesLoading = true

      // eslint-disable-next-line no-useless-escape
      const urlPattern = /^(?:\w+:)?\/\/([^\s.]+\.\S{2}[\:?\d]*)\S*$/
      // eslint-disable-next-line no-useless-escape
      const regGetId = /\/browse\/([^\?]+)/

      // const regGetId = /[^/?]+\b(?=\?)|[^/?]+$/g

      let resolvedSearchString = searchString

      if (urlPattern.test(searchString)) {
        const match = searchString.match(regGetId)
        if (match) {
          resolvedSearchString = match[1]
        }
      }

      const payload = {
        searchString: resolvedSearchString
      }

      if (this.isEdit) {
        payload.taskId = this.modelValue.id
      }

      let issues = []
      try {
        const response = await issuesApi.getIssues(payload)
        issues = response.issues
      } catch (error) {
        handleError({ error })
      }

      this.areIssuesLoading = false

      return issues
    },

    updateJqlIssues() {
      const jql = this.searchQuery
      if (this.jqlRequestTimerId) {
        clearTimeout(this.jqlRequestTimerId)
      }
      this.jqlRequestTimerId = setTimeout(async () => {
        this.issues = await this.getIssuesByJql(jql)
      }, 500)
    },

    async getIssuesByJql(jql = '') {
      const issuesApi = new IssuesApiHandler()

      // if (jql.length === 0) {
      //   return [];
      // }
      this.areIssuesLoading = true
      let issues = []
      this.jqlError = null
      this.jqlWarnings = []
      this.totalJqlIssues = 0
      try {
        const response = await issuesApi.getIssuesByJql({
          jql
        })
        issues = response.issues
        this.totalJqlIssues = response.total
        if (response.warnings.length > 0) {
          this.jqlWarnings = response.warnings
          this.jqlError = this.JQL_MESSAGES.BAD_REQUEST
        }
      } catch (error) {
        if (error.code === 'ECONNABORTED') {
          this.jqlError = this.JQL_MESSAGES.TIMEOUT
        } else {
          this.jqlError = this.JQL_MESSAGES.BAD_REQUEST
        }
      } finally {
        this.isJqlResponded = true
        this.areIssuesLoading = false
      }
      return issues
    },

    onGroupSelect() {
      this.isGroupManuallyChanged = true
    },

    onOwnerSelect() {
      this.isOwnerManuallyChanged = true
    },

    close({ checkDataChange = true, createdTasks = null, updatedTask = null } = {}) {
      this.$emit('close', { checkDataChange, createdTasks, updatedTask })
    },

    scheduleCreateAnother() {
      this.formModel.taskId = null
      this.formModel.jql = ''
      this.setFocusOnSearch()
    },

    setFocusOnSearch() {
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.search.focus()
        }, 100)
      })
    },

    async onSelectedSearchTypeChange() {
      if (this.jiraNameSearchTypeSelected) {
        this.jqlError = null
        this.jqlWarnings = []
        this.totalJqlIssues = 0
        await this.$nextTick()
        // this.$refs.search.setSearchString(this.formModel.jql)
        this.issues = await this.getIssues()
      } else {
        this.issues = await this.getIssuesByJql(this.formModel.jql)
      }
    },

    onJqlSearchQueryUpdate(newValue) {
      this.isJqlResponded = false
      this.searchQuery = newValue
    },

    selectSearchType(searchType) {
      this.selectedSearchType = searchType
      this.onSelectedSearchTypeChange()
    }
  }
})
</script>

<style lang="scss" scoped>
.lji-TaskIcon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
  vertical-align: middle;
}

.lji-LinkJiraIssueModal {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  // margin-top: 22px;
}

.lji-ExternalLink {
  margin-top: 20px;
}

.lji-JqlResultDescription {
  color: $dark-3;
  font-style: normal;
  font-weight: fw('regular');
  font-family: $system-ui;
  font-size: $fs-14;
  line-height: 18px;
  padding-top: 8px;
  min-height: 44px;

  &-Description {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &-Summary {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
  }

  &-Text {
    display: flex;
    align-items: center;
    gap: 6px;
  }
}

.lji-SelectPrepend {
  display: flex;
  margin-left: 4px;
  align-items: center;
  color: $placeholder-color;
}

.lji-SelectOption {
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: $system-ui;

  &-Text {
    vertical-align: middle;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.lji-TextPlaceholder {
  font-style: normal;
  font-weight: fw('regular');
  font-size: $fs-14;
  line-height: 20px;
  color: $placeholder-color;
  margin-left: 12px;
}

.lji-ContributeWrapper {
  padding-top: 10px;
  padding-left: 14px;

  &:deep(.ac-Text) {
    line-height: normal;
  }
}

.lji-DatesLabel_Text {
  font-weight: fw('regular');
  font-size: $fs-14;
  align-self: flex-start;
}

.lji-DatesLabel_Value {
  width: auto;
  flex: 1;
  text-align: right;
  .is-safari & {
    // Fix for safari breaking wbr
    //overflow: -webkit-paged-x;
  }
}

.lji-DatesLabel {
  display: flex;
  align-items: center;
  gap: 4px;
}

.lji-Sidebar {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.lji-ResultTypeWrapper {
  margin: 8px 0 0 0;
  &-isAutoMode {
    &:deep(.rts-Container) {
      padding: 0;
    }
  }
}

.lji-AutoModeWrapper {
  display: flex;
  justify-content: space-between;
}
.lji-AutoModeRightSide {
  display: flex;
  border: 2px solid $grey-3-next;
  background: top right url('~@/assets/images/jira-in-progress-large.svg') no-repeat, $white;
  width: 280px;
  flex: 0 0 auto;
  border-radius: $border-radius-md-next;
}
.lji-AutoModeLeftSide {
  display: flex;
  flex-direction: column;
  font-weight: fw('semi-bold');
  font-size: $fs-14;
  line-height: 20px;
  color: $dark-1;
  font-family: $system-ui;
  width: 100%;
  margin: 10px 0 23px 10px;
}
.lji-AutoModeLeftSide_Text {
  width: 207px;
  margin-left: 10px;
}
.lji-JiraIcon {
  //margin: -5px 0 15px -10px;
  margin-bottom: 15px;
}

.lji-LockMessage {
  margin: 0 0 8px 0;
}

.lji-JqlInputPrepend {
  display: flex;
  align-items: center;
}

.lji-JqlInputLoader {
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lji-TitleWrapper {
  margin-bottom: 8px;
}

.lji-TitleRadioGroup {
  width: 100%;
  margin: 0 0 8px 0;
}

.lji-JqlSearchInputWrapper {
  background: $grey-3-next;
  border: 2px solid $grey-2-next;
  border-radius: 12px;
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 8px;

  &-Input {
    width: 100%;
  }
}

.lji-NoPermissionsMessage {
  margin-bottom: 26px;
  --icon-color: #{$grade-medium-color-next};
}
</style>

<style lang="scss">
.lji-LinkJiraIssueModal .o-input-issue .o-select-search:focus::placeholder {
  color: rgba($grey-18, 0.5);
}

.lji-LinkJiraIssueModal .as-AppSelectOptionsItem_Icon {
  height: 20px;
  width: 20px;
  display: inline-block;
  margin-right: 6px;
  background-repeat: no-repeat;
  background-size: 100%;
}

.lji-LinkJiraIssueModal .as-AppDroplistLabel {
  color: $grey-semi-medium;
}

.lji-MarginMinuse .as-AppDroplistButton,
.lji-MarginMinuse .mx-input {
  margin-left: -8px;
  max-width: calc(100% + 8px);
}

.lji-searchType {
  .as-AppDroplistButton,
  .as-AppDroplistButton:hover {
    background-color: transparent;
    border-color: transparent;
  }

  .as-AppDroplistButton_Content {
    padding-left: 0;
    margin-left: -2px;
  }

  .as-AppDroplistButton_Text {
    color: $dark-1;
    font-style: normal;
    font-weight: fw('regular');
    font-size: $fs-14;
    line-height: 20px;
  }

  .as-AppDroplistButton_Icon {
    margin-left: 0;
  }

  .as-AppDroplistButton_Icon-append {
    margin-right: 0;
  }
}

// PENDING: feature OK-3106
//.lji-ResultTypeSettings {
//  &-isAutoMode {
//    &.rts-Wrapper-centered .rts-Options_Wrapper {
//      max-width: 100%;
//      .lji-AutoModeLeftSide_Text {
//        width: 100%;
//      }
//    }
//  }
//}
</style>
