import { isEmpty } from 'lodash'

export const RESPONSE_STATUSES = {
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  REQUEST_TIMEOUT: 408,
  CONFLICT: 409,
  INTERNAL_SERVER_ERROR: 500
}

export const checkResponseStatus = ({ error = {}, status } = {}) => {
  if (isEmpty(error) || !status) {
    return false
  }

  return error?.response?.status === status
}

export const isSomeOfResponseStatuses = ({ error = {}, statuses = [] } = {}) => {
  if (isEmpty(error) || !statuses.length) {
    return false
  }

  return statuses.includes(error?.response?.status)
}
