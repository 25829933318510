<template>
  <div
    :class="{ 'oesi-SelectItem-focused': active, [`oesi-SelectItem-${type}`]: type }"
    class="oesi-SelectItem"
  >
    <OkrElementsListItem
      :allowed-depth="0"
      :focused="active"
      :handle-click="false"
      :id-as-link="idAsLink"
      :objective="objective"
      :rotate-toggle-sign="rotateToggleSign"
      external-actions-listener
      show-interval
      show-only-grade-value
      type="secondary"
      @on-menu-item-click="onMenuItemClick"
    >
      <template #actions>
        <span class="oesi-SelectItem_Chevron">
          <AppIcon height="24" icon-name="arrow-down-black" width="24" />
        </span>
      </template>
    </OkrElementsListItem>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

import OkrElementsListItem from '@/components/objectives/okr-elements-list/OkrElementsListItem'
import AppIcon from '@/components/ui/AppIcon/AppIcon'

const TYPES = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary'
}

export default defineComponent({
  name: 'OkrElementSelectItem',
  components: { AppIcon, OkrElementsListItem },
  props: {
    objective: {
      type: Object,
      required: true
    },

    active: {
      type: Boolean
    },

    rotateToggleSign: {
      type: Boolean
    },

    type: {
      type: String,
      default: TYPES.PRIMARY,
      validator: v => Object.values(TYPES).includes(v)
    },

    idAsLink: {
      type: Boolean,
      default: true
    }
  },

  emits: {
    'click-on-id': null
  },

  methods: {
    onMenuItemClick({ item }) {
      this.$emit('click-on-id', item)
    }
  }
})
</script>

<style lang="scss" scoped>
.oesi-SelectItem {
  border-radius: $border-radius-sm-next;
  overflow: hidden;
  cursor: pointer;
  --right-items-gap: 24px;
  // border: 1px solid $azure-medium;

  // &-focused {
  //   border: 1px solid $primary-color;
  // }

  &-secondary {
    border-radius: $border-radius-md-next;
    height: 64px;

    border: 2px solid $grey-3-next;
    padding: 0 8px;

    &:deep(.oeli-ItemWrapper) {
      height: 100%;
    }
  }
}

.oesi-SelectItem_Chevron {
  transition: transform $transition-normal;
  display: flex;
  .oesi-SelectItem-focused & {
    transform: rotate(-180deg);
  }
}
</style>
