<template>
  <OkrFilterSelect
    :bottom-fixed-items="getBottomFixedItemsClearSelection('modelValue')"
    :dropdown-min-width="260"
    :loading="loading"
    :model-value="modelValue"
    :options="groupsOptions"
    :search-function="searchGroups"
    class="cdg-Select"
    n-selected-label="filter.groups"
    prepend-icon="team-next"
    @update:model-value="updateValue"
    @update:options="onOptionsUpdate"
  >
    <template #option-label="{ option }">
      <GlobalGroupsSelectOption v-if="option" :group="option" />
    </template>

    <template #bottom-fixed-items="{ bottomFixedItems }">
      <div v-for="item in bottomFixedItems" :key="item.id">
        <BottomFixedSelectItem
          v-if="isClearSelectionAction(item.action)"
          @click="bottomFixedItemsHandle(item.action)"
        >
          {{ $t(item.text) }}
        </BottomFixedSelectItem>
      </div>
    </template>
  </OkrFilterSelect>
</template>

<script setup>
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import {
  isClearSelectionAction,
  SELECTS_WITH_CHECKBOX_ITEMS
} from '@/composables/bottom-fixed-items'
import { useGroups } from '@/composables/fetchingData'
import { GROUP_ENTITY_KEYS } from '@/utils/entity-keys'
import { DEFAULT_VALUE_FOR_FILTER, SELECT_ALL_VALUE } from '@/utils/okr-elements/filters'
import { handleSelectInputWithSelectAll } from '@/utils/select'

import GlobalGroupsSelectOption from '@/components/global-groups/GlobalGroupsSelectOption'
import BottomFixedSelectItem from '@/components/objectives/toolbar/BottomFixedSelectItem'
import OkrFilterSelect from '@/components/objectives/toolbar/OkrFilterSelect'

defineOptions({
  name: 'ConfluenceDashboardGroups'
})

const props = defineProps({
  modelValue: {
    type: Array,
    default: () => []
  },
  workspaceId: {
    type: Number,
    required: true
  }
})

const emit = defineEmits({
  'update:options': null,
  'update:modelValue': null
})
const { t } = useI18n()
const loading = ref(false)
const data = ref([])

watch(
  () => props.workspaceId,
  async () => {
    if (!props.workspaceId) return
    loading.value = true
    const { options } = await useGroups(null, props.workspaceId)
    data.value = options.value
    loading.value = false
  },
  { immediate: true }
)

const searchGroups = async searchString => {
  const { options } = await useGroups(searchString, props.workspaceId)
  return options
}
const groupsOptions = computed(() => {
  return [
    {
      [GROUP_ENTITY_KEYS.ID]: SELECT_ALL_VALUE,
      [GROUP_ENTITY_KEYS.NAME]: t('filter.all_groups')
    },
    ...data.value
  ]
})

const updateValue = newValue => {
  const oldValue = props.modelValue
  const { result } = handleSelectInputWithSelectAll(newValue, oldValue)
  emit('update:modelValue', result)
}

const getBottomFixedItemsClearSelection = key => {
  return props[key].includes(SELECT_ALL_VALUE) ? [] : SELECTS_WITH_CHECKBOX_ITEMS
}
const bottomFixedItemsHandle = action => {
  if (isClearSelectionAction(action)) {
    updateValue(DEFAULT_VALUE_FOR_FILTER)
  }
}

const onOptionsUpdate = options => {
  data.value = options
  emit('update:options', options)
}
</script>

<style lang="scss" scoped>
.cdg-Select {
  max-width: 220px;
}
</style>
