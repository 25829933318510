<template>
  <div class="jal-Wrapper">
    <span class="jal-Title">
      <slot />
    </span>
  </div>
</template>

<script setup>
defineOptions({
  name: 'JiraAppLicenseBanner'
})
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';

.jal-Wrapper {
  border: 2px solid $grey-2-next;
  border-radius: $border-radius-md-next;
  padding: 20px 8px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.jal-Title {
  color: $dark-2;
  font-size: $fs-14;
  line-height: 20px;
  font-weight: fw('semi-bold');
}
</style>
