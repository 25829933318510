<template>
  <div class="wgt-Loader">
    <div v-for="row in ROWS" :key="row.id" class="wgt-Loader_Row">
      <div class="wgt-Cell">
        <SkeletonItem :appearance="SQUARE" :color="row.color" :size="SM" border-radius="6px" />
        <SkeletonItem :size="XS" :width="row.name" />
        <SkeletonItem :size="SM" :width="row.users" />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'WorkspaceGroupsTableLoader'
})
</script>

<script setup>
import {
  SKELETON_APPEARANCES,
  SKELETON_SIZES
} from '@/utils/components-configurations/skeleton-item'

import { ROWS } from '@/components/ui/SkeletonLoaders/global-groups'
import SkeletonItem from '@/components/ui/SkeletonLoaders/SkeletonItem'

const { XS, SM } = SKELETON_SIZES
const { SQUARE } = SKELETON_APPEARANCES
</script>

<style lang="scss" scoped>
.wgt-Loader_Row {
  display: flex;
  align-items: center;
  height: 45px;
  padding: 0 40px;
}

.wgt-Cell {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 369px;
  flex: 0 0 369px;
  padding-left: 32px;
}
</style>
