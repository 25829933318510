<template>
  <span
    :class="{
      'sb-StatusBadge-loading': loading,
      'sb-StatusBadge-noBorder': noBorder
    }"
    :style="{
      color: data.color,
      '--rgb-color': data.rgbColor,
      '--border-color': data.borderColor,
      '--background-color': data.backgroundColor
    }"
    class="sb-StatusBadge oboard-truncated-text"
  >
    <slot :label="data.label">
      {{ data.label }}
    </slot>
    <slot name="after" />
    <SkeletonItem
      v-if="loading"
      :color="data.color"
      border-radius="0"
      class="sb-StatusBadge_Loader"
      height="100%"
      width="100%"
    />
  </span>
</template>

<script>
import { defineComponent } from 'vue'

import SkeletonItem from '@/components/ui/SkeletonLoaders/SkeletonItem'

export default defineComponent({
  name: 'StatusBadge',

  components: {
    SkeletonItem
  },

  props: {
    data: {
      type: Object,
      default: () => ({})
    },

    loading: {
      type: Boolean
    },

    noBorder: {
      type: Boolean
    }
  }
})
</script>

<style lang="scss" scoped>
.sb-StatusBadge {
  display: block;
  font-style: normal;
  font-weight: fw('bold');
  text-transform: uppercase;
  font-size: var(--font-size, $fs-12);
  line-height: var(--line-height, 16px);
  padding: var(--padding, 2px 4px);
  border-radius: var(--border-radius, $border-radius-sm-next);
  width: min-content;
  max-width: 100%;
  background-color: var(--background-color, transparent);

  &:not(&-noBorder) {
    border: 2px solid var(--border-color, rgba(var(--rgb-color), 0.3));
  }

  &-loading {
    position: relative;
  }
}

.sb-StatusBadge_Loader {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.3;
}
</style>
