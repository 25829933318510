<template>
  <indicatorPanel>
    <div ref="indicatorPanelProgressRef" class="ipwp-IndicatorPanel-Progress">
      <div
        ref="indicatorPanelContentRef"
        :style="{ color: `var(${resolvedContentStyles.color})` }"
        class="ipwp-IndicatorPanel-content"
      >
        <slot />
      </div>
    </div>
  </indicatorPanel>
</template>
<script setup>
import { useResizeObserver } from '@vueuse/core'
import { computed, h, onMounted, ref, watch } from 'vue'

import { getIndicatorPanelStyles } from '@/utils/dashboard'
import { getResolvedWidth } from '@/utils/indicator-panel-helper'
import { indicatorPanelValidator } from '@/utils/prop-validators'

defineOptions({
  name: 'IndicatorPanelWithProgress'
})

const props = defineProps({
  indicatorWidth: {
    type: [String, Number],
    default: 0,
    validator: indicatorPanelValidator
  },

  color: {
    type: String,
    default: 'var(--primary-color-next)'
  },

  backgroundColor: {
    type: String,
    default: '#E5EEFA'
  }
})

const indicatorPanelProgressRef = ref(null)
const indicatorPanelContentRef = ref(null)
const indicatorPanelRef = ref(null)

const resolvedIndicatorWidth = computed(() => {
  const resolvedWidth = getResolvedWidth(props.indicatorWidth)
  return `${resolvedWidth}%`
})

const resolvedContentStyles = ref({})

const setContentStyles = async () => {
  let progressWidth = 0

  if (indicatorPanelRef.value) {
    // This condition need for CONFLUENCE SERVER BUILD.
    progressWidth =
      (parseInt(resolvedIndicatorWidth.value) / 100) *
      indicatorPanelRef.value.getBoundingClientRect().width
  }

  const contentWidth = indicatorPanelContentRef.value?.getBoundingClientRect().width
  const { color, transform, left, right } = getIndicatorPanelStyles({
    progressWidth,
    contentWidth: contentWidth
  })
  resolvedContentStyles.value = {
    transform,
    color,
    left,
    right
  }
}
watch(
  () => props.indicatorWidth,
  () => {
    setContentStyles()
  }
)
onMounted(() => {
  useResizeObserver(indicatorPanelRef, () => {
    setContentStyles()
  })
})
const indicatorPanel = (_, { slots }) => {
  return h(
    'div',
    {
      ref: indicatorPanelRef,
      class: {
        'ipwp-IndicatorPanel': true,
        'ipwp-IndicatorPanel-rounded': props.rounded,
        'ipwp-IndicatorPanel-rounded-bar': props.roundedBar
      }
    },
    [h(slots.default)]
  )
}
</script>

<style lang="scss" scoped>
// eslint-disable-next-line
.ipwp-IndicatorPanel {
  position: relative;
  height: var(--height, 7px);
  width: 100%;
  overflow: hidden;
  background-color: v-bind(backgroundColor);
  display: flex;
  align-items: flex-start;
  border-radius: var(--border-radius, $border-radius-sm);

  .ipwp-IndicatorPanel-Progress {
    position: relative;
    height: 100%;
    background-color: v-bind(color);
    width: v-bind(resolvedIndicatorWidth);
    animation: loading cubic-bezier(0.17, 0.67, 0.83, 0.67) 0.5s;
    border-radius: var(--border-radius-progress, $border-radius-sm-next);
  }
}
.ipwp-IndicatorPanel-content {
  position: absolute;
  left: v-bind('resolvedContentStyles.left');
  right: v-bind('resolvedContentStyles.right');
  color: v-bind('resolvedContentStyles.color');
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: fit-content;
  min-width: max-content;
  //transform: v-bind('resolvedContentStyles.transform');
  z-index: 10;
  font-weight: fw('semi-bold');
  font-size: $fs-14;
  line-height: 20px;
  font-family: $system-ui;
  animation: loadingContentText cubic-bezier(0.17, 0.67, 0.83, 0.67) 0.6s; // Animation issue in export to PDF
}
@keyframes loading {
  from {
    width: 0;
  }
  to {
    width: v-bind(resolvedIndicatorWidth);
  }
}
@keyframes loadingContentText {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
