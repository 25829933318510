import ApiHandler from '@/api/base'

class CommentsApiHandler extends ApiHandler {
  async getComments({ limit = null, objectiveId, offset = null } = {}) {
    const { data } = await this.http.get('/comments', {
      params: {
        limit,
        objectiveId,
        offset
      }
    })
    return data
  }

  async createComment({ objectiveId = null, parentId = null, text = null } = {}) {
    const { data } = await this.http.post('/comments', {
      objectiveId,
      parentId,
      text
    })
    return data
  }

  async updateComment({ commentId = null, text = null } = {}) {
    const { data } = await this.http.put('/comments', {
      commentId,
      text
    })
    return data
  }

  async removeComment({ commentId, remove } = {}) {
    const { data } = await this.http.delete('/comments', {
      data: {
        commentId,
        delete: remove
      }
    })
    return data
  }

  async addReaction({ commentId, reactionCode, workspaceId } = {}) {
    const { data } = await this.http.post('/reactions', {
      commentId,
      reactionCode,
      workspaceId
    })
    return data
  }

  async removeReaction({ commentId, reactionCode, workspaceId } = {}) {
    const { data } = await this.http.delete('/comments/reactions', {
      commentId,
      reactionCode,
      workspaceId
    })
    return data
  }

  async getCommentTree({ commentId } = {}) {
    const { data } = await this.http.get('/comments/comment/tree', {
      params: {
        commentId
      }
    })
    return data
  }
}

export default CommentsApiHandler
