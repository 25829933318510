<template>
  <AppModalWithConfirmation
    ref="appModalWithConfirmation"
    :confirm-close="areDataChanged"
    :show="isShow"
    :title="$t('intervals.edit_interval', { intervalName: interval?.name })"
    class="o-create-interval-modal"
    data-auto-testid="edit-interval-modal"
    hide-hero
    size="sm-next"
    @on-close="onModalClose"
  >
    <div class="iem-Body">
      <FormFieldNext :label="$t('intervals.name')" class="iem-NameField">
        <AppInput
          v-model.trim="formModel.title"
          :is-error="isTitleEmpty || isNameReserved"
          max-length="20"
          size="xlg"
          style-type="primary"
          @update:model-value="
            () => {
              isTitleEmpty = false
              isNameReserved = false
            }
          "
        />

        <AppFieldError :show="isTitleEmpty || isNameReserved" class="iem-NameFieldError">
          <template v-if="isTitleEmpty">
            {{ $t('field.required') }}
          </template>
          <template v-else-if="isNameReserved">
            {{ $t('field.name_reserved') }}
          </template>
        </AppFieldError>
      </FormFieldNext>

      <div class="iem-Dates">
        <FormFieldNext :disabled="!formModel.status" :label="$t('filter.start_date')">
          <DatePicker
            v-model="formModel.start"
            :clearable="false"
            :disabled="!formModel.status"
            :disabled-date="notAfterEnd"
            :editable="false"
            :format="DATE_FORMAT"
            :lang="datepickerOptions"
            class="iem-Datepicker"
            popup-class="dp-Datepicker"
          >
            <template #icon-calendar>
              <AppIcon height="24" icon-name="calendar-next" width="24" />
            </template>
          </DatePicker>
        </FormFieldNext>

        <FormFieldNext :disabled="!formModel.status" :label="$t('interval.label.end_date')">
          <DatePicker
            v-model="formModel.end"
            :clearable="false"
            :disabled="!formModel.status"
            :disabled-date="notBeforeStart"
            :editable="false"
            :format="DATE_FORMAT"
            :lang="datepickerOptions"
            class="iem-Datepicker"
            popup-class="dp-Datepicker"
          >
            <template #icon-calendar>
              <AppIcon height="24" icon-name="calendar-next" width="24" />
            </template>
          </DatePicker>
        </FormFieldNext>
      </div>
    </div>

    <template #footer-prepend>
      <AppRadioGroup
        v-model="formModel.status"
        :options="STATUS_OPTIONS"
        name="interval-status"
        style="--option-padding: 0 8px 0 0"
        type="primary-next"
      >
        <template #item-label="{ item }">
          <AppRadioGroupNextOptionWithIcon :option="item" />
        </template>
      </AppRadioGroup>
    </template>
    <template #footer-actions>
      <AppButton type="ghost-next" @click="close">
        {{ $t('action.cancel') }}
      </AppButton>

      <AppButton :loading="loading" type="primary-next" @click="save">
        {{ $t('action.update') }}
      </AppButton>
    </template>
  </AppModalWithConfirmation>
</template>

<script>
import { defineComponent } from 'vue'
import DatePicker from 'vue2-datepicker'

import 'vue2-datepicker/index.css'

import IntervalsInfoApiHandler from '@/api/intervals-info'
import { datepickerLang, localDateToUtc, utcDateToLocal } from '@/utils/date'
import { handleError } from '@/utils/error-handling'
import { isStringEmpty } from '@/utils/general'
import { isIntervalNameReserved } from '@/utils/interval'
import { objectOrNullProp } from '@/utils/prop-validators'

import AppModalWithConfirmation from '@/components/AppModalWithConfirmation'
import AppFieldError from '@/components/form/AppFieldError'
import FormFieldNext from '@/components/form/FormFieldNext'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppIcon from '@/components/ui/AppIcon/AppIcon'
import AppInput from '@/components/ui/AppInput/AppInput'
import AppRadioGroup from '@/components/ui/AppRadioGroup/AppRadioGroup'
import AppRadioGroupNextOptionWithIcon from '@/components/ui/AppRadioGroup/AppRadioGroupNextOptionWithIcon'

const DEFAULT_FORM_MODEL = {
  title: '',
  period: '',
  start: null,
  status: null,
  end: null
}

const STATUS_OPTIONS = [
  {
    label: 'intervals.status.open',
    value: true,
    icon: 'unarchive-interval'
  },
  {
    label: 'intervals.status.archived',
    value: false,
    icon: 'archive-interval'
  }
]

const DATE_FORMAT = 'D MMM YYYY'

export default defineComponent({
  name: 'IntervalEditModal',
  components: {
    AppRadioGroupNextOptionWithIcon,
    AppRadioGroup,
    FormFieldNext,
    DatePicker,
    AppModalWithConfirmation,
    AppButton,
    AppInput,
    AppFieldError,
    AppIcon
  },

  props: {
    isShow: {
      type: Boolean,
      required: true
    },

    interval: {
      required: true,
      validator: v => objectOrNullProp(v)
    },

    workspaceId: {
      type: Number,
      required: true
    }
  },

  emits: { 'on-archived': null, 'on-close': null, 'on-reload-intervals': null },

  data() {
    return {
      formModel: { ...DEFAULT_FORM_MODEL },
      localFormModel: { ...DEFAULT_FORM_MODEL },
      initialStatus: true,
      isTitleEmpty: false,
      isNameReserved: false,
      loading: false
    }
  },

  computed: {
    STATUS_OPTIONS: () => STATUS_OPTIONS,

    DATE_FORMAT: () => DATE_FORMAT,

    isReservedNames() {
      return isIntervalNameReserved(this.formModel.title)
    },

    datepickerOptions() {
      return datepickerLang()
    },

    areDataChanged() {
      return JSON.stringify(this.localFormModel) !== JSON.stringify(this.formModel)
    }
  },

  watch: {
    isShow: {
      handler(newValue) {
        if (!newValue) {
          this.localFormModel = { ...DEFAULT_FORM_MODEL }
          return
        }

        this.initialStatus = this.interval.open
        this.formModel = {
          title: this.interval.name,
          start: utcDateToLocal(new Date(this.interval.startDate)),
          end: utcDateToLocal(new Date(this.interval.endDate)),
          status: this.interval.open
        }
        this.localFormModel = { ...this.formModel }
      },

      immediate: true
    }
  },

  methods: {
    notAfterEnd(date) {
      if (this.formModel.end) {
        return date.getTime() > this.formModel.end.getTime()
      }
      return false
    },

    notBeforeStart(date) {
      if (this.formModel.start) {
        return date.getTime() < this.formModel.start.getTime()
      }
      return false
    },

    async save() {
      if (isStringEmpty(this.formModel.title)) {
        this.isTitleEmpty = true
        return
      }

      if (this.isReservedNames) {
        this.isNameReserved = true
        return
      }

      let isSuccessful = true
      const formattedEndDate = new Date(+this.formModel.end)
      formattedEndDate.setHours(23, 59, 59)

      this.loading = true
      const payload = {
        id: this.interval.id,
        name: this.formModel.title,
        startDate: localDateToUtc(this.formModel.start).getTime(),
        endDate: localDateToUtc(formattedEndDate).getTime(),
        open: Number(this.formModel.status),
        workspaceId: this.workspaceId
      }

      const intervalsInfoApi = new IntervalsInfoApiHandler()

      try {
        const updatedInterval = await intervalsInfoApi.updateInterval(payload)

        if (this.initialStatus && !this.formModel.status) {
          this.$emit('on-archived', updatedInterval)
        }

        this.$emit('on-reload-intervals')
      } catch (error) {
        isSuccessful = false
        handleError({ error })
      }

      setTimeout(() => {
        this.loading = false
      }, 300)

      if (isSuccessful) {
        this.onModalClose()
      }
    },

    onModalClose() {
      this.isTitleEmpty = false
      this.isNameReserved = false
      this.$emit('on-close')
    },

    close() {
      this.$refs.appModalWithConfirmation.close()
    }
  }
})
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/interval-modal';

.iem-Datepicker {
  @extend %interval-datepicker-styles;
}

.iem-Body {
  @extend %interval-modal-body-styles;
}

.iem-NameField {
  @extend %interval-modal-name-field-styles;
}

.iem-NameFieldError {
  @extend %interval-modal-name-error-styles;
}

.iem-Dates {
  @extend %interval-modal-dates-wrapper-styles;
  grid-template-columns: 1fr 1fr;
}
</style>
