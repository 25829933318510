<template>
  <FormFieldNext
    v-for="field in fields"
    :key="field.fieldId"
    :label="field.name"
    class="cofh-FormField"
  >
    <Component
      :is="FIELD_COMPONENTS_BY_TYPE_ID[field.fieldId]"
      :model-value="modelValue[field.fieldId]"
      :workspace-id="workspaceId"
      @update:model-value="
        onUpdateModelValue({
          ...$event,
          fieldId: field.fieldId
        })
      "
      @toggle-value-dropdown="
        onToggleValueDropdown({
          value: $event,
          fieldId: field.fieldId
        })
      "
    />
  </FormFieldNext>
</template>

<script setup>
import { CORE_FIELDS_IDS } from '@/utils/okr-elements-table-bulk-actions'

import FormFieldNext from '@/components/form/FormFieldNext'
import ContributeField from '@/components/objectives/bulk-edit/core-fields/ContributeField'
import GroupsField from '@/components/objectives/bulk-edit/core-fields/GroupsField'
import IntervalField from '@/components/objectives/bulk-edit/core-fields/IntervalField'
import LabelsField from '@/components/objectives/bulk-edit/core-fields/LabelsField'
import OkrLevelsField from '@/components/objectives/bulk-edit/core-fields/OkrLevelsField'
import OwnerField from '@/components/objectives/bulk-edit/core-fields/OwnerField'
import StakeholdersField from '@/components/objectives/bulk-edit/core-fields/StakeholdersField'

defineOptions({
  name: 'BulkEditCoreFieldsHub',
  inheritAttrs: false
})

defineProps({
  fields: {
    type: Array,
    required: true
  },

  modelValue: {
    type: Object,
    required: true
  },

  workspaceId: {
    type: [String, Number],
    required: true
  }
})

const FIELD_COMPONENTS_BY_TYPE_ID = {
  [CORE_FIELDS_IDS.LABELS]: LabelsField,
  [CORE_FIELDS_IDS.GROUPS]: GroupsField,
  [CORE_FIELDS_IDS.STAKEHOLDERS]: StakeholdersField,
  [CORE_FIELDS_IDS.OWNER]: OwnerField,
  [CORE_FIELDS_IDS.INTERVAL]: IntervalField,
  [CORE_FIELDS_IDS.CONTRIBUTE]: ContributeField,
  [CORE_FIELDS_IDS.OKR_LEVELS]: OkrLevelsField
}

const emit = defineEmits({
  'update:model-value': null,
  'toggle-value-dropdown': null
})

const onUpdateModelValue = data => {
  emit('update:model-value', data)
}

const onToggleValueDropdown = data => {
  emit('toggle-value-dropdown', data)
}
</script>

<style lang="scss" scoped>
.cofh-FormField {
  position: relative;
}
</style>
