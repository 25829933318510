<template>
  <div class="but-Wrapper">
    <div class="but-Filters">
      <OkrFilterSelect
        :bottom-fixed-items="getBottomFixedItemsClearSelection(filtersValues, GROUP_IDS)"
        :dropdown-min-width="300"
        :loading="loaders.groups"
        :model-value="filtersValues[GROUP_IDS]"
        :options="optionsData.groups"
        :search-function="searchString => fetchGroupsForFilter({ searchString })"
        has-only-this-button
        n-selected-label="group.groups"
        prepend-icon="team-next"
        @update:model-value="changeSelectedValue($event, GROUP_IDS)"
        @update:options="optionsData.groups = $event"
      >
        <template #option-label="{ option }">
          <GlobalGroupsSelectOption v-if="option" :group="option" />
        </template>

        <template #bottom-fixed-items="{ bottomFixedItems }">
          <div v-for="item in bottomFixedItems" :key="item.id">
            <BottomFixedSelectItem
              v-if="isClearSelectionAction(item.action)"
              @click="bottomFixedItemsHandle(item.action, GROUP_IDS)"
            >
              {{ $t(item.text) }}
            </BottomFixedSelectItem>
          </div>
        </template>
      </OkrFilterSelect>

      <OkrFilterSelect
        :bottom-fixed-items="getBottomFixedItemsClearSelection(filtersValues, ACCOUNT_IDS)"
        :dropdown-min-width="200"
        :loading="loaders.accounts"
        :model-value="filtersValues[ACCOUNT_IDS]"
        :options="optionsData.accounts"
        :search-function="$event => useFetchUserFilter($event, filtersValues)"
        class="but-Filters_FilterSelect"
        has-only-this-button
        item-value="accountId"
        n-selected-label="common.users"
        prepend-icon="user-next"
        @update:model-value="changeSelectedValue($event, ACCOUNT_IDS)"
        @update:options="optionsData.accounts = $event"
      >
        <template #option-label="{ option }">
          <OwnerFieldOption :option="option" label-key="name" />
        </template>
        <template #bottom-fixed-items="{ bottomFixedItems }">
          <div v-for="item in bottomFixedItems" :key="item.id">
            <BottomFixedSelectItem
              v-if="isClearSelectionAction(item.action)"
              @click="bottomFixedItemsHandle(item.action, ACCOUNT_IDS)"
            >
              {{ $t(item.text) }}
            </BottomFixedSelectItem>
          </div>
        </template>
      </OkrFilterSelect>
      <OkrFilterSelect
        :bottom-fixed-items="getBottomFixedItemsClearSelection(filtersValues, ROLE_IDS)"
        :dropdown-min-width="200"
        :dropdown-search="false"
        :loading="loaders.roles"
        :model-value="filtersValues[ROLE_IDS]"
        :options="optionsData.roles"
        class="but-Filters_FilterSelect"
        has-only-this-button
        item-label="label"
        item-value="value"
        n-selected-label="users.roles.title"
        prepend-icon="active"
        @update:model-value="changeSelectedValue($event, ROLE_IDS)"
      >
        <template #bottom-fixed-items="{ bottomFixedItems }">
          <div v-for="item in bottomFixedItems" :key="item.id">
            <BottomFixedSelectItem
              v-if="isClearSelectionAction(item.action)"
              @click="bottomFixedItemsHandle(item.action, ROLE_IDS)"
            >
              {{ $t(item.text) }}
            </BottomFixedSelectItem>
          </div>
        </template>
      </OkrFilterSelect>

      <ResetFilterButton v-if="showResetFilters" @click="onResetFilters" />
    </div>

    <UsersTableLoader v-if="loaders.table" :max-rows="4" />
    <AppTable
      v-else-if="tableUsersData.length && !loaders.table"
      :columns="TABLE_COLUMNS"
      :data="tableUsersData"
      :hover-row="tableHoverRow"
      no-border
      offset-left="var(--page-left-padding)"
      offset-right="var(--page-right-padding)"
      type="primary-next"
    >
      <template #header-cell="{ column }">
        <template v-if="column.key === CHECKBOX">
          <AppCheckbox
            v-model="selectAll"
            :size="CHECKBOX_SIZES.SM"
            data-testid="select-all-checkbox"
            @update:model-value="onToggleAllUsers"
          />
        </template>
      </template>

      <template #cell="{ columnKey, item, index }">
        <template v-if="columnKey === CHECKBOX">
          <AppCheckboxListItem
            :data-testid="`select-user-${index}`"
            :model-value="selectedUsers"
            :val="item.accountId"
            @update:model-value="onChangeCheckboxListItem"
          />
        </template>
        <template v-if="columnKey === NAME">
          <UserNameCell :item="item" item-label="name" />
        </template>

        <template v-if="columnKey === SYNC && isShowSyncIconCell(item)">
          <div class="but-SyncCell">
            <AppIcon height="24" icon-name="replace_jira" width="24" />
          </div>
        </template>

        <template v-if="columnKey === GROUPS">
          <div class="but-GroupsCell">
            <LimitTagList
              v-if="!isEmpty(item.groups)"
              :entity="LIMIT_TAG_LIST_ENTITIES.GROUP"
              :items="item.groups"
              :limit="3"
              style="--list-gap: 4px; --count-offset: 0"
              tooltip-value="name"
            >
              <template #item="{ item: group }">
                <GroupIcon :color="group.color" :icon-name="group.icon" class="but-GroupIcon" />
              </template>
            </LimitTagList>
          </div>
        </template>
        <template v-if="columnKey === ROLES_IN_THE_WORKSPACES">
          <span
            v-tippy="{
              content: useGetUserRolesText(item.workspaces, optionsData.roles).tooltipText,
              placement: 'bottom-start',
              theme: `${DROP_LIST_THEMES.COMMON_TOOLTIP_THEMES} ${DROP_LIST_THEMES.SEMI_BOLD_TEXT}`,
              allowHTML: true
            }"
          >
            {{ useGetUserRolesText(item.workspaces, optionsData.roles).label }}
          </span>
        </template>
      </template>
    </AppTable>
    <NoSearchResults v-else class="but-NoSearchResult">
      {{ t('users.no_matching_filters') }}
      <AppButton class="but-BtnResetFilters" size="sm" type="link-next" @click="onResetFilters">
        {{ $t('search_criteria.reset') }}
      </AppButton>
    </NoSearchResults>
    <div class="but-SnackbarWrapper">
      <AppSnackbar
        :actions="snackbarActions"
        :count="selectedUsers.length"
        class="but-SnackbarContainer"
        inline
        style="--text-color: var(--dark-2)"
        @close="clearCheckboxes"
        @action-click="onSnackbarActionClick"
      />
      <AppInfoMessage v-if="selectedUsers.length" :type="INFO_MESSAGE_TYPES.DEFAULT">
        {{ $t('subscription.web_app_only_delete_from_oboard') }}
      </AppInfoMessage>
    </div>
    <AppPagination
      v-if="tableUsersData.length"
      :current-page="currentPage"
      :items-count="totalUsers"
      :items-on-page="itemsOnPage"
      :items-shown="tableUsersData.length"
      :total-page="totalPage"
      @update:items-on-page="changeItemsOnPage"
      @update:current-page="changeCurrentPage"
    />
  </div>

  <RevokeWebAccessDialog
    v-model:show="showUserBulkRevokeModal"
    :users-count="selectedUsers.length"
    @on-close="closeUsersRevokeModal"
    @on-confirm="revokeWebAccessOrRemove"
  />
</template>

<script setup>
import { cloneDeep, isEmpty } from 'lodash'
import { computed, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import WebAppUsersApiHandler from '@/api/web-app-users'
import {
  clearSelection,
  getBottomFixedItemsClearSelection,
  isClearSelectionAction
} from '@/composables/bottom-fixed-items'
import { useGlobalGroups } from '@/composables/global-groups'
import { useGetPaginationData } from '@/composables/pagination'
import {
  useFetchPluginUsers,
  useFetchRoles,
  useFetchUserFilter,
  useGetSelectedUsers,
  useGetUserRolesText,
  useInitialGroupsForFilter
} from '@/composables/plugin-users'
import { ACTIONS_KEYS } from '@/utils/actions-keys'
import { CHECKBOX_SIZES } from '@/utils/components-configurations/app-checkbox'
import { DROP_LIST_THEMES } from '@/utils/components-configurations/app-droplist'
import { INFO_MESSAGE_TYPES } from '@/utils/components-configurations/app-info-message'
import { LIMIT_TAG_LIST_ENTITIES } from '@/utils/components-configurations/limit-tag-list'
import { handleError } from '@/utils/error-handling'
import { showNotify } from '@/utils/notify'
import { DEFAULT_VALUE_FOR_FILTER, SELECT_ALL_VALUE } from '@/utils/okr-elements/filters'
import { WEB_APP_ONLY, JIRA_AND_WEB_APP } from '@/utils/platforms-helpers'
import { getNewSelectWithSelectAllValue } from '@/utils/select'
import { TABLE_COLUMNS_KEYS } from '@/utils/table-columns'
import { checkFormModelOnDefaultValue, isShowSyncIconCell } from '@/utils/users-helpers'
import RevokeWebAccessDialog from '@shared-modules/components/billing-settings/RevokeWebAccessDialog'

import AppCheckboxListItem from '@/components/form/AppCheckboxListItem'
import LimitTagList from '@/components/form/LimitTagList'
import OwnerFieldOption from '@/components/form/OwnerFieldOption'
import GlobalGroupsSelectOption from '@/components/global-groups/GlobalGroupsSelectOption'
import GroupIcon from '@/components/global-groups/GroupIcon'
import BottomFixedSelectItem from '@/components/objectives/toolbar/BottomFixedSelectItem'
import OkrFilterSelect from '@/components/objectives/toolbar/OkrFilterSelect'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppCheckbox from '@/components/ui/AppCheckbox/AppCheckbox'
import AppIcon from '@/components/ui/AppIcon/AppIcon'
import AppInfoMessage from '@/components/ui/AppInfoMessage'
import AppPagination from '@/components/ui/AppPagination/AppPagination'
import AppSnackbar from '@/components/ui/AppSnackbar/AppSnackbar'
import NoSearchResults from '@/components/ui/NoSearchResults/NoSearchResults'
import ResetFilterButton from '@/components/ui/ResetFilterButton'
import UsersTableLoader from '@/components/ui/SkeletonLoaders/UsersTableLoader'
import AppTable from '@/components/ui/Table/AppTable'
import UserNameCell from '@/views/workspaces/settings/plugin-users/UserNameCell'
import { USERS_QUERY_KEYS } from '@/views/workspaces/settings/plugin-users/users-query-params'

defineOptions({
  name: 'BillingUsersTable'
})

const { t } = useI18n()

const emit = defineEmits(['on-delete-users'])

const { GROUP_IDS, ACCOUNT_IDS, ROLE_IDS, PLATFORM_IDS } = USERS_QUERY_KEYS

const DEFAULT_FORM_MODEL = {
  [GROUP_IDS]: DEFAULT_VALUE_FOR_FILTER,
  [ACCOUNT_IDS]: DEFAULT_VALUE_FOR_FILTER,
  [ROLE_IDS]: DEFAULT_VALUE_FOR_FILTER,
  [PLATFORM_IDS]: DEFAULT_VALUE_FOR_FILTER
}
const filtersValues = ref({ ...DEFAULT_FORM_MODEL })
const loaders = ref({
  table: false,
  groups: false,
  accounts: false,
  roles: false
})

const showResetFilters = computed(() => {
  return checkFormModelOnDefaultValue(filtersValues.value)
})

const onResetFilters = () => {
  filtersValues.value = cloneDeep(DEFAULT_FORM_MODEL)
  currentPage.value = START_PAGE
  reloadTableData()
}

const changeSelectedValue = async (newValue, key) => {
  filtersValues.value[key] = getNewSelectWithSelectAllValue(newValue, filtersValues.value[key])

  // trackApplySettingsPagesFilterEvent({
  //   section: EVENT_SECTIONS.BILLING,
  //   label: FILTER_LABELS_FOR_TRACKING[key]
  // })

  await reloadTableData()
}

const { fetchGroupsForFilter, ALL_GROUPS_OPTION } = useGlobalGroups()

const optionsData = ref({
  groups: [ALL_GROUPS_OPTION],
  accounts: [
    {
      name: t('filter.all_users'),
      accountId: SELECT_ALL_VALUE
    }
  ],
  roles: []
})

const {
  START_PAGE,
  currentPage,
  itemsOnPage,
  total: totalUsers,
  totalPage
} = useGetPaginationData()

const tableUsersData = ref([])
watch(
  () => filtersValues.value,
  async () => {
    currentPage.value = START_PAGE
  },
  { deep: true }
)

const reloadTableData = async (clearSelection = true) => {
  if (clearSelection) {
    clearCheckboxes()
  }
  try {
    loaders.value.table = true

    const payload = cloneDeep(filtersValues.value)

    payload[PLATFORM_IDS] = [WEB_APP_ONLY, JIRA_AND_WEB_APP]

    const { users, total } = await useFetchPluginUsers(payload, {
      currentPage: currentPage.value,
      itemsOnPage: itemsOnPage.value
    })
    tableUsersData.value = users
    totalUsers.value = total
  } finally {
    loaders.value.table = false
  }
}
const { getInitialGroupsForFilter } = useInitialGroupsForFilter()

const getInitData = async () => {
  optionsData.value.roles = await useFetchRoles()
  loaders.value.groups = true
  loaders.value.accounts = true
  loaders.value.table = true
  loaders.value.roles = true
  try {
    const [groupsData, accountsData] = await Promise.all([
      getInitialGroupsForFilter({
        selectedGroups: filtersValues.value[GROUP_IDS]
      }),
      useFetchUserFilter(null, filtersValues.value)
    ])
    optionsData.value.groups = [...optionsData.value.groups, ...groupsData]
    optionsData.value.accounts = [...optionsData.value.accounts, ...accountsData]
  } finally {
    loaders.value.groups = false
    loaders.value.accounts = false
    loaders.value.roles = false
  }
}

onMounted(async () => {
  await getInitData()
  await reloadTableData()
})

const tableHoverRow = ref(-1)
const { CHECKBOX, NAME, ROLES_IN_THE_WORKSPACES, SYNC, GROUPS } = TABLE_COLUMNS_KEYS

const TABLE_COLUMNS = [
  {
    key: CHECKBOX,
    width: 28
  },
  {
    title: t('users.table_header_name'),
    key: NAME,
    width: 'auto'
  },
  {
    key: SYNC,
    width: 32
  },
  {
    title: t('objectives.table_header_groups'),
    key: GROUPS,
    width: 360
  },
  {
    title: t('settings.roles_in_workspaces'),
    key: ROLES_IN_THE_WORKSPACES,
    width: 146
  }
]

const { selectedUsers, selectAll, clearCheckboxes, onToggleAllUsers, onChangeCheckboxListItem } =
  useGetSelectedUsers(tableUsersData)

const snackbarActions = computed(() => {
  return [
    {
      name: ACTIONS_KEYS.REVOKE_ACCESS,
      icon: 'delete-next',
      title: 'action.revoke_web_access',
      color: 'var(--grade-low-color-next)'
    }
  ]
})

const onSnackbarActionClick = name => {
  if (name === ACTIONS_KEYS.REVOKE_ACCESS) {
    showUserBulkRevokeModal.value = true
  }
}

const bottomFixedItemsHandle = async (action, key) => {
  if (isClearSelectionAction(action)) {
    clearSelection(filtersValues.value, key)
    await reloadTableData()
  }
}

const showUserBulkRevokeModal = ref(false)

const closeUsersRevokeModal = () => {
  showUserBulkRevokeModal.value = false
}

const revokeWebAccessOrRemove = async () => {
  try {
    const webAppUsersApi = new WebAppUsersApiHandler()
    await webAppUsersApi.revokeWebAccessOrRemove({
      accountIds: selectedUsers.value
    })
    showNotify({ title: t('notifications.user_deleted', selectedUsers.value.length) })

    emit('on-delete-users')
    closeUsersRevokeModal()
    await reloadTableData()
  } catch (error) {
    handleError({ error })
  }
}

const changeCurrentPage = page => {
  currentPage.value = page
  reloadTableData()
}
const changeItemsOnPage = value => {
  itemsOnPage.value = value
  currentPage.value = START_PAGE
  reloadTableData()
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/app-table-helpers';
@import '~@/assets/styles/mixins';

.but-Filters {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 20px;
}
.but-Filters_FilterSelect {
  max-width: 158px;
}

.but-Wrapper {
  width: 100%;
  max-width: $page-width-lg;
  margin-top: 16px;
  padding-bottom: 8px;
  --select-skeleton-left: 0;
  --select-skeleton-top: 0;
}

.but-SnackbarWrapper {
  display: flex;
  align-items: center;
  overflow: hidden;
  gap: 16px;
  position: relative;
}

.but-BtnResetFilters {
  margin: auto;
}

.but-SyncCell {
  display: flex;
  align-items: center;
}

.but-GroupIcon {
  .tb-Row:hover &,
  .tb-RowWrapper-hovered & {
    background-color: #{getGlobalGroupHoverColor(var(--color))};
  }
}

.but-GroupsCell {
  display: flex;
  padding-right: 8px;
  gap: 4px;
}

.but-NoSearchResult {
  margin-bottom: 32px;
}
</style>
