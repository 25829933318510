import { ROUTE_NAMES, ROUTE_PATHS } from '@/routes/route-helpers'

import DashboardLayout from '@/views/workspaces/_workspaceId/dashboard'
import CustomDashboard from '@/views/workspaces/_workspaceId/dashboard/customDashboard'
import CustomDashboardItem from '@/views/workspaces/_workspaceId/dashboard/customDashboardItem'
import CustomDashboardList from '@/views/workspaces/_workspaceId/dashboard/customDashboardList'
import DashboardAccessDenied from '@/views/workspaces/_workspaceId/dashboard/dashboardAccessDenied'
import General from '@/views/workspaces/_workspaceId/dashboard/general'
import Groups from '@/views/workspaces/_workspaceId/dashboard/groups'
import People from '@/views/workspaces/_workspaceId/dashboard/people'
import PerformanceReport from '@/views/workspaces/_workspaceId/dashboard/performanceReport'

export default {
  path: ROUTE_PATHS.DASHBOARD,
  component: DashboardLayout,
  redirect: {
    name: ROUTE_NAMES.DASHBOARD_GENERAL
  },
  children: [
    {
      path: ROUTE_PATHS.DASHBOARD_GENERAL,
      name: ROUTE_NAMES.DASHBOARD_GENERAL,
      component: General
    },
    {
      path: ROUTE_PATHS.DASHBOARD_PERFORMANCE_REPORT,
      name: ROUTE_NAMES.DASHBOARD_PERFORMANCE_REPORT,
      component: PerformanceReport,
      redirect: {
        name: ROUTE_NAMES.DASHBOARD_PERFORMANCE_REPORT_BY_GROUPS
      },
      children: [
        {
          path: ROUTE_PATHS.DASHBOARD_PERFORMANCE_REPORT_BY_GROUPS,
          name: ROUTE_NAMES.DASHBOARD_PERFORMANCE_REPORT_BY_GROUPS,
          component: Groups
        },
        {
          path: ROUTE_PATHS.DASHBOARD_PERFORMANCE_REPORT_BY_PEOPLE,
          name: ROUTE_NAMES.DASHBOARD_PERFORMANCE_REPORT_BY_PEOPLE,
          component: People
        }
      ]
    },
    {
      path: ROUTE_PATHS.CUSTOM_DASHBOARD,
      name: ROUTE_NAMES.CUSTOM_DASHBOARD,
      component: CustomDashboard,
      redirect: {
        name: ROUTE_NAMES.CUSTOM_DASHBOARD_LIST
      },
      children: [
        {
          path: ROUTE_PATHS.CUSTOM_DASHBOARD_LIST,
          name: ROUTE_NAMES.CUSTOM_DASHBOARD_LIST,
          component: CustomDashboardList
        },
        {
          path: `${ROUTE_PATHS.CUSTOM_DASHBOARD_ITEM}/:id`,
          name: ROUTE_NAMES.CUSTOM_DASHBOARD_ITEM,
          component: CustomDashboardItem,
          meta: {
            hideNavigation: true
          }
        },
        {
          path: ROUTE_PATHS.CUSTOM_DASHBOARD_ACCESS_DENIED,
          name: ROUTE_NAMES.CUSTOM_DASHBOARD_ACCESS_DENIED,
          component: DashboardAccessDenied,
          meta: {
            hideNavigation: true
          }
        }
      ]
    },
    {
      path: ROUTE_PATHS.DASHBOARD_PERFORMANCE_REPORT_BY_GROUPS, // not working "*" or "groups|people" patterns
      redirect: {
        name: ROUTE_NAMES.DASHBOARD_PERFORMANCE_REPORT_BY_GROUPS
      }
    },
    {
      path: ROUTE_PATHS.DASHBOARD_PERFORMANCE_REPORT_BY_PEOPLE, // not working "*" or "groups|people" patterns
      redirect: {
        name: ROUTE_NAMES.DASHBOARD_PERFORMANCE_REPORT_BY_PEOPLE
      }
    }
  ]
}
