<template>
  <span :class="{ 'ocn-Name-hidden': !userHasReadAccess }" class="ocn-Name">
    {{ displayText }}
  </span>
</template>

<script setup>
import { computed } from 'vue'

import { currentUserCanReadObjective } from '@/utils/objectives'

defineOptions({
  name: 'OkrElementCipheredName'
})
const props = defineProps({
  okrElement: {
    type: Object,
    required: true
  }
})

const userHasReadAccess = computed(() => {
  return currentUserCanReadObjective(props.okrElement)
})

const displayText = computed(() => {
  return userHasReadAccess.value ? props.okrElement.name : '∗∗∗∗∗∗∗∗∗∗∗'
})
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/ciphered-text';

.ocn-Name {
  &-hidden {
    @include cipheredText(
      var(--text-color, #{$primary-color-next}),
      var(--overlay-color, #{$white})
    );
  }
}
</style>
