import { ORGANIZATION_ID, PLUGIN_OPTIONS_KEYS } from '@root/template-options-keys'

const options = {
  [PLUGIN_OPTIONS_KEYS.BASE_URL]: null,
  [PLUGIN_OPTIONS_KEYS.ATLASSIAN_BASE_URL]: null,
  [ORGANIZATION_ID]: null
}

export const setPluginOptions = (pluginOptions = {}) => {
  Object.entries(pluginOptions).forEach(([key, val]) => {
    if (Object.keys(options).includes(key)) {
      options[key] = val
    }
  })
}

export const getPluginOption = (name = '') => options[name] || null
