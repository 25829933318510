<template>
  <div class="is-Step">
    <slot name="step-title" />

    <FormFieldNext :label="t('workspaces.intervals')" class="is-FormField">
      <template v-if="isLoading">
        <SkeletonItem
          v-for="n in loadersCount"
          :key="n"
          :size="SKELETON_SIZES.LG"
          style="--skeleton-width: 100%"
        />
      </template>

      <template v-else>
        <EntityButton
          v-for="interval in intervals"
          :key="interval.id"
          :size="BUTTON_SIZES.LG_NEXT"
          type="outline"
          @click="onEditInterval({ interval })"
        >
          <AppIcon height="24" icon-name="calendar-next" style="flex-shrink: 0" width="24" />
          <span class="oboard-truncated-text">
            {{ interval.name }}
          </span>
        </EntityButton>
      </template>

      <AppTableCreateButton
        :disable="isLoading"
        full-width
        icon-name="plus-next"
        no-hover
        style="--padding-left: 0"
        @click="showCreateIntervalModal"
      >
        {{ t('action.add_another') }}
      </AppTableCreateButton>

      <slot name="step-message" />
    </FormFieldNext>

    <portal to="modal-windows">
      <IntervalCreateModal
        :is-show="isCreateIntervalModalShow"
        :workspace-id="selectedWorkspace[WORKSPACE_ENTITY_KEYS.ID]"
        @on-close="hideCreateIntervalModal"
        @on-created="onIntervalCreated"
      />

      <IntervalEditModal
        :interval="intervalToEdit"
        :is-show="isEditIntervalModalShow"
        :workspace-id="selectedWorkspace[WORKSPACE_ENTITY_KEYS.ID]"
        @on-reload-intervals="fetchIntervals"
        @on-close="hideEditIntervalModal"
      />
    </portal>
  </div>
</template>
<script setup>
import { isEmpty } from 'lodash'
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

import IntervalsInfoApiHandler from '@/api/intervals-info'
import { BUTTON_SIZES } from '@/utils/components-configurations/app-button'
import { SKELETON_SIZES } from '@/utils/components-configurations/skeleton-item'
import { WORKSPACE_ENTITY_KEYS } from '@/utils/entity-keys'
import { handleError } from '@/utils/error-handling'

import FormFieldNext from '@/components/form/FormFieldNext'
import AppIcon from '@/components/ui/AppIcon/AppIcon'
import AppTableCreateButton from '@/components/ui/AppTableCreateButton'
import SkeletonItem from '@/components/ui/SkeletonLoaders/SkeletonItem'
import EntityButton from '@/components/ui/WorkspaceSetupSteps/EntityButton'
import IntervalCreateModal from '@/components/workspaces/configuration-modal/IntervalCreateModal'
import IntervalEditModal from '@/components/workspaces/configuration-modal/IntervalEditModal'

defineOptions({
  name: 'IntervalsStep'
})

const { t } = useI18n()

const store = useStore()

const selectedWorkspace = computed(() => {
  return store.getters['workspaces/selectedWorkspace']
})

const isLoading = ref(false)
const intervals = ref([])
const fetchIntervals = async () => {
  isLoading.value = true
  if (!isEmpty(intervals.value)) {
    intervals.value = []
  }

  const intervalsInfoApi = new IntervalsInfoApiHandler()

  try {
    const result = await intervalsInfoApi.getAllIntervalsInfo({
      workspaceId: selectedWorkspace.value[WORKSPACE_ENTITY_KEYS.ID]
    })

    intervals.value = result.filter(interval => !interval.backlog)
    loadersCount.value = intervals.value.length
  } catch (error) {
    handleError({ error })
  } finally {
    isLoading.value = false
  }
}

const isCreateIntervalModalShow = ref(false)

const showCreateIntervalModal = () => (isCreateIntervalModalShow.value = true)
const hideCreateIntervalModal = () => {
  isCreateIntervalModalShow.value = false
  fetchIntervals()
}

const isEditIntervalModalShow = ref(false)
const intervalToEdit = ref(null)
const onEditInterval = ({ interval }) => {
  if (!interval.backlog) {
    intervalToEdit.value = interval
    isEditIntervalModalShow.value = true
  }
}

const hideEditIntervalModal = () => {
  intervalToEdit.value = null
  isEditIntervalModalShow.value = false
}

const onIntervalCreated = () => {
  fetchIntervals()
}
onMounted(() => {
  fetchIntervals()
})

const loadersCount = ref(3)
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/wizard-steps';

.is-Step {
  @extend %wizard-step-styles;
}

.is-FormField {
  width: 100%;
}
</style>
