class DropListThemes {
  constructor() {
    this.NO_SHADOW_NEXT = 'no-shadow-next'
    this.LIGHT = 'light'
    this.NO_PADDING = 'no-padding'
    this.NO_SHADOW = 'no-shadow'
    this.OBOARD_LIGHT = 'oboard-light'
    this.TRANSLUCENT_NEXT = 'translucent-next'
    this.TRANSLUCENT = 'translucent'
    this.WORD_BREAK = 'word-break'
    this.NO_PADDING_FORCE = 'no-padding-force'
    this.TRANSPARENT = 'transparent'
    this.SEMI_BOLD_TEXT = 'semi-bold-text'
    this.TEXT_CENTER = 'text-center'
    this.WHITE_SPACE_PRE_LINE = 'white-space-pre-line'
    this.PADDING_DEFAULT = 'padding-default'
    this.COMMON_THEMES = `${this.NO_SHADOW_NEXT} ${this.LIGHT}`
    this.OLD_THEMES = `${this.NO_PADDING} ${this.NO_SHADOW} ${this.OBOARD_LIGHT}`
    this.COMMON_TOOLTIP_THEMES = `${this.TRANSLUCENT_NEXT} ${this.WORD_BREAK}`
  }
}

export const DROP_LIST_THEMES = new DropListThemes()
