<template>
  <AppRadioGroup
    :disabled="disabled"
    :model-value="modelValue"
    :name="name"
    :options="options"
    size="xs"
    style="--fill: var(--grey-3-next); --option-padding: 0px 8px 0 4px"
    type="primary-next"
    @update:model-value="emit('update:modelValue', $event)"
  >
    <template #item-label="{ item }">
      <AppRadioGroupNextOptionWithIcon :option="item" gap="4px" />
    </template>
  </AppRadioGroup>
</template>

<script setup>
import AppRadioGroup from '@/components/ui/AppRadioGroup/AppRadioGroup'
import AppRadioGroupNextOptionWithIcon from '@/components/ui/AppRadioGroup/AppRadioGroupNextOptionWithIcon'

defineOptions({
  name: 'AppRadioGroupCommon'
})

defineProps({
  modelValue: {
    type: [Number, Boolean, String],
    required: true
  },

  options: {
    type: Array,
    default: () => []
  },

  name: {
    type: String,
    default: ''
  },

  disabled: {
    type: Boolean
  }
})
const emit = defineEmits(['update:modelValue'])
</script>
<style lang="scss" scoped></style>
