import { UNSELECTED_DATE } from '@/utils/date'
import { WORKSPACE_ID_KEY } from '@/utils/query-parameters'
import { selectAllIsSelected } from '@/utils/select'

export const FILTERS_KEYS = {
  ASSIGNEE_IDS: 'assigneeIds',
  SORT_BY: 'sortBy',
  GROUP_TYPE: 'groupType',
  PAGINATION: 'pagination',
  CATEGORY: 'category',
  ASSIGNEE_ID: 'assigneeId',
  INTERVAL_ID: 'intervalId',
  GROUP_ID: 'groupId',
  SEARCH_TYPE_ID: 'searchTypeId',
  SELECTED_TABLE_VIEW: 'selectedTableView',
  CUSTOM_DASHBOARD_ID: 'customDashboardId',
  LAYOUT_ORIENTATION: 'layoutOrientation',
  START_DATES: 'startDates',
  DUE_DATES: 'dueDates',
  SELECTED_INTERVALS: 'selectedIntervals',
  SELECTED_DATES: 'selectedDates'
}

export const BACKEND_FILTER_IDS = {
  [WORKSPACE_ID_KEY]: 1,
  [FILTERS_KEYS.INTERVAL_ID]: 2,
  [FILTERS_KEYS.ASSIGNEE_IDS]: 4,
  [FILTERS_KEYS.GROUP_TYPE]: 15,
  [FILTERS_KEYS.SORT_BY]: 16,
  [FILTERS_KEYS.PAGINATION]: 17,
  [FILTERS_KEYS.CATEGORY]: 18,
  [FILTERS_KEYS.ASSIGNEE_ID]: 19,
  [FILTERS_KEYS.GROUP_ID]: 20,
  [FILTERS_KEYS.SEARCH_TYPE_ID]: 21,
  [FILTERS_KEYS.SEARCH_TYPE_ID]: 21,
  [FILTERS_KEYS.CUSTOM_DASHBOARD_ID]: 22,
  [FILTERS_KEYS.LAYOUT_ORIENTATION]: 23,
  [FILTERS_KEYS.SELECTED_TABLE_VIEW]: 24,
  [FILTERS_KEYS.SELECTED_INTERVALS]: 25,
  [FILTERS_KEYS.SELECTED_DATES]: 26
}

export const FRONTEND_FILTER_NAMES_BY_ID = Object.fromEntries(
  Object.entries(BACKEND_FILTER_IDS).map(item => {
    return item.reverse()
  })
)

export const normalizeSavedConfigFilters = filters => {
  return Object.fromEntries(
    Object.entries(filters).map(([key, val]) => {
      return [FRONTEND_FILTER_NAMES_BY_ID[key], JSON.parse(val)]
    })
  )
}

export const prepareFiltersPayload = (filters = {}) => {
  return Object.fromEntries(
    Object.entries(filters).map(([key, val]) => {
      const normalizedValue = selectAllIsSelected(val) ? null : val
      return [BACKEND_FILTER_IDS[key], JSON.stringify(normalizedValue)]
    })
  )
}

export const getParsedSelectedDates = selectedDates => {
  return {
    startDates: [selectedDates ? selectedDates[0] : UNSELECTED_DATE, UNSELECTED_DATE],
    dueDates: [UNSELECTED_DATE, selectedDates ? selectedDates[1] : UNSELECTED_DATE]
  }
}
