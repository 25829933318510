<template>
  <CustomFieldNumberInput
    :key="fieldId"
    :field-id="fieldId"
    :field-type-id="fieldTypeId"
    :model-value="modelValue"
    :prefix="currency"
    @update:model-value="emit('update:model-value', $event)"
  />
</template>

<script setup>
import { useCustomFieldsHelpers } from '@/utils/custom-fields/use-custom-fields'

import CustomFieldNumberInput from '@/components/objectives/bulk-edit/custom-fields/CustomFieldNumberInput'

defineOptions({
  name: 'MoneyField',
  inheritAttrs: false
})

const props = defineProps({
  modelValue: {
    type: Object,
    required: true
  },

  fieldId: {
    type: [String, Number],
    required: true
  },

  fieldTypeId: {
    type: [String, Number],
    required: true
  }
})

const { currency } = useCustomFieldsHelpers({
  fieldId: props.fieldId
})

const emit = defineEmits({
  'update:model-value': null
})
</script>

<style lang="scss" scoped></style>
