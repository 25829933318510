<template>
  <span :style="getStyles">
    {{
      isAvailableCell
        ? getSimplifiedNumber(item[type].averageTotalPercent)[PLURALIZATION_KEYS.SIMPLIFIED]
        : '0'
    }}%
  </span>
</template>

<script setup>
import { isNull, isUndefined } from 'lodash'
import { computed } from 'vue'

import { getGradeColorStyle } from '@/utils/okr-elements/grade'
import { getSimplifiedNumber, PLURALIZATION_KEYS } from '@/utils/pluralization'

const props = defineProps({
  item: {
    type: Object,
    required: true
  },

  type: {
    type: String,
    required: true
  },

  withStyles: {
    type: Boolean,
    default: true
  }
})

const isAvailableCell = computed(
  () =>
    !isNull(props.item[props.type].averageTotalPercent) &&
    !isUndefined(props.item[props.type].averageTotalPercent)
)

const getStyles = computed(() => {
  if (props.withStyles) {
    return getGradeColorStyle(props.item[props.type].averageTotalColor)
  }
  return {}
})
</script>
<style lang="scss" scoped></style>
