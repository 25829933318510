const ACCESS_TOKEN = 'accessToken'
const REFRESH_TOKEN = 'refreshToken'
const PLUGIN_TYPE = 'pluginType'
const BASE_URL = 'baseUrl'
const ONBOARDING = 'onboarding'
const ATLASSIAN_BASE_URL = 'atlassianBaseUrl'
const IS_PLUGIN_ADMIN = 'isPluginAdmin'
const BACKEND_VERSION = 'backendVersion'
const API_BASE = 'apiBase'
const ROUTER_BASE = 'routerBase'
const ISS = 'iss'
const PRODUCTION = 'production'
const ORGANIZATION_ID = 'organizationId'
const WEB_APP_URL = 'webAppUrl'
const WEB_APP_LOGIN_URL = 'webAppLoginUrl'
const WEB_APP_CONNECTED = 'webAppConnected'
const JIRA_CONNECTED = 'jiraConnected'
const CREATE_ORGANIZATION = 'createOrganization'
const DASHBOARD_ITEM_ID = 'dashboardItemId'
const VERSION = 'version'
const JIRA_ISSUE_ID = 'jiraIssueId'
const SETUP_NAME = 'setupName'
const IS_OWNER = 'isOwner'
const SUBSCRIPTION_PLAN = 'subscriptionPlan'
const LICENSE_STATUS_ID = 'licenseStatusId'
const LICENSE_STATUS_DAYS_LEFT = 'licenseStatusDaysLeft'
const INSTANCE_STATE_ID = 'instanceStateId'

const PLUGIN_OPTIONS_KEYS = {
  PLUGIN_TYPE: PLUGIN_TYPE,
  BASE_URL: BASE_URL,
  ONBOARDING: ONBOARDING,
  CREATE_ORGANIZATION: CREATE_ORGANIZATION,
  ATLASSIAN_BASE_URL: ATLASSIAN_BASE_URL,
  IS_PLUGIN_ADMIN: IS_PLUGIN_ADMIN,
  LICENSE_STATUS_ID: LICENSE_STATUS_ID,
  LICENSE_STATUS_DAYS_LEFT: LICENSE_STATUS_DAYS_LEFT,
  BACKEND_VERSION: BACKEND_VERSION,
  WEB_APP_CONNECTED: WEB_APP_CONNECTED,
  JIRA_CONNECTED: JIRA_CONNECTED,
  SETUP_NAME: SETUP_NAME,
  IS_OWNER: IS_OWNER,
  SUBSCRIPTION_PLAN: SUBSCRIPTION_PLAN,
  INSTANCE_STATE_ID: INSTANCE_STATE_ID,
  ISS: ISS
}

const CREATE_AUTH_APP_PLUGIN_OPTIONS = [
  ACCESS_TOKEN,
  REFRESH_TOKEN,
  ...Object.values(PLUGIN_OPTIONS_KEYS)
]

const CREATE_AUTH_APP_PLUGIN_OPTIONS_OBJECT = Object.fromEntries(
  CREATE_AUTH_APP_PLUGIN_OPTIONS.map(key => [key, key])
)

const JIRA_APP_PLUGIN_OPTIONS = [
  API_BASE,
  ROUTER_BASE,
  PLUGIN_TYPE,
  ISS,
  BASE_URL,
  ATLASSIAN_BASE_URL,
  PRODUCTION,
  WEB_APP_URL,
  WEB_APP_LOGIN_URL
]

const WEB_APP_PLUGIN_OPTIONS = [
  API_BASE,
  ROUTER_BASE,
  PLUGIN_TYPE,
  ISS,
  BASE_URL,
  ATLASSIAN_BASE_URL,
  PRODUCTION,
  ORGANIZATION_ID,
  WEB_APP_URL,
  WEB_APP_LOGIN_URL
]

const SALESFORCE_PLUGIN_OPTIONS = [...WEB_APP_PLUGIN_OPTIONS]

const CONFLUENCE_APP_PLUGIN_OPTIONS = [
  REFRESH_TOKEN,
  PLUGIN_TYPE,
  BASE_URL,
  API_BASE,
  ONBOARDING,
  ATLASSIAN_BASE_URL,
  IS_PLUGIN_ADMIN,
  ACCESS_TOKEN
]

const CONFLUENCE_MACRO_PLUGIN_OPTIONS = [ATLASSIAN_BASE_URL, BASE_URL, API_BASE, PLUGIN_TYPE, ISS]

const GADGET_PLUGIN_OPTIONS = [
  DASHBOARD_ITEM_ID,
  ATLASSIAN_BASE_URL,
  BASE_URL,
  API_BASE,
  PLUGIN_TYPE,
  ISS,
  PRODUCTION
]

const JIRA_ISSUE_SIDEBAR_PLUGIN_OPTIONS = [
  JIRA_ISSUE_ID,
  BASE_URL,
  API_BASE,
  PLUGIN_TYPE,
  ISS,
  ATLASSIAN_BASE_URL,
  PRODUCTION
]

const LOGIN_APP_PLUGIN_OPTIONS = [API_BASE, WEB_APP_URL, WEB_APP_LOGIN_URL]

const SALESFORCE_INSTALLATION_APP_PLUGIN_OPTIONS = [API_BASE, ORGANIZATION_ID]

const COMMON_BOOLEAN_KEYS = [IS_PLUGIN_ADMIN, ONBOARDING]

const COMMON_NUMBER_KEYS = [LICENSE_STATUS_DAYS_LEFT]

module.exports = {
  PLUGIN_OPTIONS_KEYS,
  CREATE_AUTH_APP_PLUGIN_OPTIONS_OBJECT,
  CONFLUENCE_MACRO_PLUGIN_OPTIONS,
  DASHBOARD_ITEM_ID,
  VERSION,
  CREATE_AUTH_APP_PLUGIN_OPTIONS,
  JIRA_APP_PLUGIN_OPTIONS,
  WEB_APP_PLUGIN_OPTIONS,
  SALESFORCE_PLUGIN_OPTIONS,
  CONFLUENCE_APP_PLUGIN_OPTIONS,
  GADGET_PLUGIN_OPTIONS,
  JIRA_ISSUE_SIDEBAR_PLUGIN_OPTIONS,
  LOGIN_APP_PLUGIN_OPTIONS,
  SALESFORCE_INSTALLATION_APP_PLUGIN_OPTIONS,
  COMMON_BOOLEAN_KEYS,
  COMMON_NUMBER_KEYS,
  CREATE_ORGANIZATION,
  SETUP_NAME,
  WEB_APP_CONNECTED,
  JIRA_CONNECTED,
  IS_OWNER,
  SUBSCRIPTION_PLAN,
  LICENSE_STATUS_DAYS_LEFT,
  LICENSE_STATUS_ID,
  INSTANCE_STATE_ID,
  ORGANIZATION_ID
}
