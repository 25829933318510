<template>
  <component
    :is="componentData[step]"
    :conflicts="modelConflicts[step]"
    :data="formModel"
    :form-model="formModel"
    :loading="loading"
    :objective-data="objectiveData"
    :show="show"
    @proceed="payload => proceedNextStep(payload, step)"
    @update:show="closeModal"
    @prev-step="prevStep"
  />
</template>

<script setup>
import { watch, ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

import ObjectivesApiHandler from '@/api/okr-elements'
import { handleError } from '@/utils/error-handling'
import { showNotify } from '@/utils/notify'
import { objectOrNullProp } from '@/utils/prop-validators'

import SelectGroupModal from '@/components/objectives/objective-move/SelectGroupModal'
import SelectLevelModal from '@/components/objectives/objective-move/SelectLevelModal'
import SelectAssigneeModal from '@/components/objectives/objective-move/SelectMoveAssignee'
import SelectWorkspaceModal from '@/components/objectives/objective-move/SelectWorkspaceModal'

const props = defineProps({
  objectiveData: {
    required: true,
    validator: v => objectOrNullProp(v)
  },
  show: {
    type: Boolean
  }
})
const emit = defineEmits({
  'update:show': null,
  updated: null
})

const store = useStore()
const workspaceId = computed(() => store.state.workspaces.workspaceId)
const DEFAULT_FORM_MODEL = {
  workspaceId: workspaceId.value,
  intervalId: null,
  parentId: null,
  moveNested: false,
  moveCrossWorkspace: false
}

const MOVE = 'move'
const LEVELS = 'levels'
const GROUPS = 'groups'
const USERS = 'users'
const conflictSteps = [LEVELS, USERS, GROUPS]
const step = ref(MOVE)
const stepsRoad = ref([])

const componentData = computed(() => {
  return {
    [MOVE]: SelectWorkspaceModal,
    [LEVELS]: SelectLevelModal,
    [GROUPS]: SelectGroupModal,
    [USERS]: SelectAssigneeModal
  }
})
const setStep = value => {
  step.value = value
}
const prevStep = () => {
  setStep(stepsRoad.value.pop())
}
const closeModal = () => {
  emit('update:show', false)
  resetFormModel()
}
const resetFormModel = () => {
  setStep(MOVE)
  formModel.value = { ...DEFAULT_FORM_MODEL }
}

const formModel = ref({ ...DEFAULT_FORM_MODEL })

const proceedNextStep = (data, key) => {
  if (key === MOVE) {
    formModel.value = data
  } else {
    modelConflicts.value = { ...modelConflicts.value, [key]: data }
  }
  moveOkrElement()
}

const dataConflicts = ref(null)
const modelConflicts = ref({})

const objectivesApi = new ObjectivesApiHandler()
const loading = ref(false)
const { t } = useI18n()
const isAnyConflict = payload => {
  return Object.values(payload)
    .flat()
    .some(conflictItem => !conflictItem.idToChange && !conflictItem.accountIdToChange)
}

// const getSessionStorageKey = id => `move-objective-${id}`

const moveOkrElement = async () => {
  try {
    const { workspaceId, intervalId, parentId, moveNested, moveCrossWorkspace } = formModel.value
    loading.value = true
    const payload = {
      workspaceId,
      elementId: props.objectiveData.id,
      intervalId,
      parentId,
      moveNested,
      moveCrossWorkspace,
      conflicts: modelConflicts.value
    }
    const { conflicts } = await objectivesApi.moveOkrElement(payload)
    dataConflicts.value = conflicts
    modelConflicts.value = conflicts
    setConflictModal()
    // eslint-disable-next-line no-empty
    if (isAnyConflict(conflicts)) {
      // if (window.sessionStorage) {
      //   window.sessionStorage.setItem(
      //     getSessionStorageKey(props.objectiveData.id),
      //     JSON.stringify({ payload, conflicts, step: step.value, stepsRoad: stepsRoad.value })
      //   )
      // }
    } else {
      showNotify({
        title: t('move.success_moved')
      })
      emit('updated', { workspaceId: formModel.value.workspaceId })
      // if (window.sessionStorage) {
      //   window.sessionStorage.removeItem(getSessionStorageKey(props.objectiveData.id))
      // }
      resetFormModel()
      closeModal()
    }
  } catch (error) {
    handleError({ error })
  } finally {
    loading.value = false
  }
}
// watch(
//   () => props.show,
//   value => {
//     if (value) {
//       if (window.sessionStorage) {
//         const data = window.sessionStorage.getItem(getSessionStorageKey(props.objectiveData.id))
//         if (data) {
//           const sessionData = JSON.parse(data)
//           step.value = sessionData.step
//           stepsRoad.value = sessionData.stepsRoad
//           formModel.value = sessionData.payload
//           modelConflicts.value = sessionData.conflicts
//           dataConflicts.value = sessionData.conflicts
//         }
//       }
//     }
//   }
// )

const setConflictModal = () => {
  for (let i = 0; i < conflictSteps.length; i += 1) {
    if (isAnyConflict(dataConflicts.value[conflictSteps[i]])) {
      stepsRoad.value.push(step.value)
      setStep(conflictSteps[i])
      break
    }
  }
}

watch(
  () => formModel.value.moveNested,
  value => {
    if (!value) {
      formModel.value.moveCrossWorkspace = false
    }
  }
)
</script>
<script>
//eslint-disable-next-line
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ObjectiveModalMoveStepper'
})
</script>

<style scoped></style>
