import ApiHandler from '@/api/base'
import { LAYOUT_VIEW_TYPES, VERTICAL } from '@/utils/custom-dashboard-helper'

class CustomDashboardApiHandler extends ApiHandler {
  async createDashboard({
    name = '',
    backgroundId = 0,
    layoutTypeId = LAYOUT_VIEW_TYPES[VERTICAL]
  } = {}) {
    const { data } = await this.http.post('/dashboard', {
      name,
      backgroundId,
      layoutTypeId,
      private: true
    })
    return data
  }
  async getDashboard({ id = null } = {}) {
    const { data } = await this.http.get(`/dashboard/${id}`)
    return data
  }

  async updateDashboard({
    id = null,
    name = '',
    backgroundId = 0,
    layoutTypeId,
    isPrivate = true
  } = {}) {
    const { data } = await this.http.put(`/dashboard/${id}`, {
      name,
      backgroundId,
      layoutTypeId,
      private: isPrivate
    })
    return data
  }

  async deleteDashboard({ id = null } = {}) {
    const { data } = await this.http.delete(`/dashboard/${id}`)
    return data
  }

  async addDashboardElement({ dashboardId = null, elementId = null } = {}) {
    const { data } = await this.http.post('/dashboard/element', {
      dashboardId,
      elementId
    })
    return data
  }

  async removeDashboardElement({ dashboardId = null, elementId = null } = {}) {
    const { data } = await this.http.delete('/dashboard/element', {
      data: {
        dashboardId,
        elementId
      }
    })
    return data
  }
  async getDashboardElements({ searchString = null, limit = 20, excludeElementIds = [] } = {}) {
    const { data } = await this.http.get(`/dashboard/elements`, {
      params: {
        searchString,
        limit,
        excludeElementIds
      }
    })
    return data
  }

  async getDashboardList({
    searchString = null,
    ownerIds = null,
    sort = null,
    offset = 0,
    limit = 50
  } = {}) {
    const { data } = await this.http.get('/dashboard/all', {
      params: {
        searchString,
        ownerIds,
        sort,
        limit,
        offset
      }
    })
    return data
  }

  async getDashboardCount({
    ownerIds = [],
    searchString = null,
    pageSize = null,
    isPrivate = null
  } = {}) {
    const { data } = await this.http.get(`/dashboard/count`, {
      params: {
        ownerIds,
        searchString,
        pageSize,
        isPrivate
      }
    })
    return data
  }

  async getDashboardChart({ id = null, granulation = null } = {}) {
    const { data } = await this.http.get(`/dashboard/${id}/chart`, {
      params: {
        granulation
      }
    })
    return data
  }

  async getShareCandidates({ dashboardId = null, searchString = null } = {}) {
    const { data } = await this.http.get(`/dashboard/shareCandidates`, {
      params: {
        dashboardId,
        searchString
      }
    })
    return data
  }
  async addShareEntities({ dashboardId = null, users = null, groups = null } = {}) {
    const { data } = await this.http.post(`/dashboard/share`, {
      dashboardId,
      users,
      groups
    })
    return data
  }
  async removeShareEntities({
    dashboardId = null,
    userShareIds = null,
    groupShareIds = null
  } = {}) {
    const { data } = await this.http.delete(`/dashboard/share`, {
      data: {
        dashboardId,
        userShareIds,
        groupShareIds
      }
    })
    return data
  }
  async addToFavorites({ dashboardIds = null } = {}) {
    const { data } = await this.http.post(`/dashboard/favorites`, {
      dashboardIds
    })
    return data
  }
  async removeFromFavorites({ dashboardIds = null } = {}) {
    const { data } = await this.http.delete(`/dashboard/favorites`, {
      data: {
        dashboardIds
      }
    })
    return data
  }

  async duplicateDashboard({ dashboardId = null, newName = null, includeShare = false } = {}) {
    const { data } = await this.http.post(`/dashboard/duplicate`, {
      dashboardId,
      newName,
      includeShare
    })
    return data
  }

  async getDashboardUsers({ selectedIds = null, searchString } = {}, limit = 10) {
    const { data } = await this.http.get(`/dashboard/users`, {
      params: {
        selectedIds,
        searchString,
        limit
      }
    })
    return data
  }
}
export default CustomDashboardApiHandler
