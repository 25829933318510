<script setup>
import { computed, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

import { ROUTE_NAMES } from '@/routes/route-helpers'
import { UNSELECTED_DATE } from '@/utils/date'
import { HP_FILTERS_KEYS } from '@/utils/okr-elements/filters'

import OboardLogoLoader from '@/components/ui/SkeletonLoaders/OboardLogoLoader'

defineOptions({
  name: 'CheckInsLayout'
})

const store = useStore()
const router = useRouter()

const workspaceId = computed(() => `${store.state.workspaces.workspaceId}`)
const userData = computed(() => store.state.system.userData)

watch(
  [workspaceId, userData],
  ([newWorkspaceId, newUserData], [oldWorkspaceId]) => {
    if (newWorkspaceId && newUserData && !oldWorkspaceId) {
      const { userAccountId } = newUserData

      if (!userAccountId) {
        router.push({ name: ROUTE_NAMES.HOME_PAGE, params: { workspaceId: newWorkspaceId } })
        return
      }

      router.push({
        name: ROUTE_NAMES.HOME_PAGE,
        params: { workspaceId: newWorkspaceId },
        query: {
          [HP_FILTERS_KEYS.SELECTED_USER]: `"${userAccountId}"`,
          [HP_FILTERS_KEYS.SELECTED_GROUP]: null,
          [HP_FILTERS_KEYS.INTERVAL_IDS]: JSON.stringify([]),
          [HP_FILTERS_KEYS.START_DATES]: JSON.stringify([UNSELECTED_DATE, UNSELECTED_DATE]),
          [HP_FILTERS_KEYS.DUE_DATES]: JSON.stringify([UNSELECTED_DATE, UNSELECTED_DATE])
        }
      })
    }
  },
  {
    immediate: true
  }
)
</script>

<template>
  <OboardLogoLoader />
</template>

<style lang="scss" scoped></style>
