import { isEmpty } from 'lodash'
import { ref } from 'vue'

import AssigneesInfoApiHandler from '@/api/assignees-info'
import DashboardApiHandler from '@/api/dashboard'
import GlobalGroupsApiHandler from '@/api/global-groups'
import HomePageApiHandler from '@/api/homepage'
import IntervalsInfoApiHandler from '@/api/intervals-info'
import WorkspacesApiHandler from '@/api/workspaces'
import { handleError } from '@/utils/error-handling'
import { getActiveIntervals, UNSELECTED_INTERVAL } from '@/utils/interval'
import { localSearch } from '@/utils/objectives'
import { getSelectWithSelectAllApiParameter } from '@/utils/select'
import {
  ascSortValue,
  avgDecreaseValue,
  avgIncreaseValue,
  descSortValue,
  sortAvgDecreaseValue,
  sortAvgIncreaseValue
} from '@/utils/sort-options'

export const getAssignees = async (searchString = null, workspaceId) => {
  const options = ref([])
  const loading = ref(false)
  const api = new AssigneesInfoApiHandler()

  loading.value = true
  const parameters = {
    workspaceId,
    searchString
  }

  try {
    options.value = await api.getUsers(parameters)
  } catch (error) {
    handleError({ error })
  } finally {
    loading.value = false
  }

  return { options, loading }
}

export const getAllUserWorkspaces = async (searchString = null) => {
  const api = new WorkspacesApiHandler()
  const loading = ref(false)
  const options = ref([])
  loading.value = true
  try {
    const data = await api.getAllUserWorkspaces({ searchString })
    options.value = data.filter(ws => options.value.findIndex(workspace => ws.id === workspace.id))
  } catch (error) {
    handleError({ error })
  } finally {
    loading.value = false
  }
  return { options, loading }
}

export const getIntervals = async workspaceId => {
  const loading = ref(false)
  const options = ref([])
  const index = ref(0)
  loading.value = true

  const intervalsInfoApi = new IntervalsInfoApiHandler()
  try {
    const { intervals, currentIndex } = await intervalsInfoApi.getIntervalsInfo({
      workspaceId
    })
    options.value = getActiveIntervals(intervals)
    index.value = currentIndex
  } catch (error) {
    handleError({ error })
  } finally {
    loading.value = false
  }
  return { loading, options, index }
}

export const useGroups = async (searchString = null, workspaceId) => {
  const api = new GlobalGroupsApiHandler()
  const loading = ref(false)
  const options = ref([])
  loading.value = true
  try {
    options.value = await api.getGroupsForFilter({
      searchString,
      workspaceIds: [workspaceId]
    })
  } catch (error) {
    handleError({ error })
  } finally {
    loading.value = false
  }
  return { loading, options }
}

const convertsUsersAndGroupsToOneList = ({ users, groups }) => {
  const CATEGORIES = {
    USERS: 'users',
    GROUPS: 'groups'
  }
  const convertedUsers = users.map(user => {
    return {
      ...user,
      id: user.accountId,
      name: user.displayName,
      category: CATEGORIES.USERS
    }
  })
  const convertedGroups = groups.map(group => {
    return {
      ...group,
      category: CATEGORIES.GROUPS
    }
  })

  return convertedUsers.concat(convertedGroups)
}
export const useUsersAndGroups = async (searchString = null, pickedId = {}, workspaceId) => {
  const api = new HomePageApiHandler()
  const options = ref([])
  try {
    const data = await api.getUsersAndGroups({
      searchString,
      workspaceId,
      ...pickedId
    })
    options.value = convertsUsersAndGroupsToOneList(data)
  } catch (error) {
    handleError({ error })
  }
  return { options }
}

const CATEGORIES = {
  USERS: 'users',
  GROUPS: 'groups'
}

const getIntervalIds = intervalIds => {
  return isEmpty(intervalIds) ? UNSELECTED_INTERVAL : intervalIds
}
export const useGroupOrUserData = async ({
  category,
  intervalIds,
  startDateFrom,
  dueDateTo,
  workspaceId,
  assigneeId,
  searchTypeId
}) => {
  const gradeData = ref({
    atRisk: 0,
    behind: 0,
    onTrack: 0
  })
  const progressData = ref({
    objGap: {
      gap: 0,
      progress: 0
    },

    krGap: {
      gap: 0,
      progress: 0
    }
  })
  const summary = ref([])
  const subgroupsData = ref([])

  const api = new HomePageApiHandler()
  const defaultPayload = {
    workspaceId,
    searchTypeId,
    intervalIds: getIntervalIds(intervalIds),
    startDateFrom,
    dueDateTo
  }

  let response = {}
  let stakeholdersGrade = ref({})
  try {
    const requestForUser = category === CATEGORIES.USERS
    if (requestForUser) {
      response = await api.getUserData({
        accountId: assigneeId,
        ...defaultPayload
      })
      stakeholdersGrade.value = await api.getStakeholdersGrade({
        accountId: assigneeId,
        ...defaultPayload
      })
    } else {
      response = await api.getGroupData({
        groupId: assigneeId,
        ...defaultPayload
      })
      stakeholdersGrade.value = await api.getStakeholdersGrade({
        groupIds: [assigneeId],
        ...defaultPayload
      })
    }
    const {
      onTrack,
      behind,
      atRisk,
      notStarted,
      closed,
      abandoned,
      teammates,
      groupsProgress,
      subgroups,
      objGap,
      krGap
    } = response
    gradeData.value.onTrack = onTrack
    gradeData.value.behind = behind
    gradeData.value.atRisk = atRisk
    gradeData.value.notStarted = notStarted
    gradeData.value.closed = closed
    gradeData.value.abandoned = abandoned

    progressData.value = {
      objGap,
      krGap
    }
    summary.value = requestForUser ? groupsProgress : teammates
    subgroupsData.value = subgroups
  } catch (error) {
    handleError({ error })
  }
  return { gradeData, progressData, summary, stakeholdersGrade, subgroupsData }
}

export const useChartData = async ({
  category,
  intervalIds,
  startDateFrom,
  dueDateTo,
  workspaceId,
  assigneeId,
  searchTypeId
}) => {
  const api = new HomePageApiHandler()
  const chartData = ref([])
  const defaultPayload = {
    intervalIds: getIntervalIds(intervalIds),
    startDateFrom,
    dueDateTo,
    workspaceId: workspaceId,
    searchTypeId: searchTypeId
  }

  try {
    const requestForUser = category === CATEGORIES.USERS
    if (requestForUser) {
      chartData.value = await api.getChartDataForUser({
        accountId: assigneeId,
        ...defaultPayload
      })
    } else {
      chartData.value = await api.getChartDataForGroup({
        groupId: assigneeId,
        ...defaultPayload
      })
    }
  } catch (error) {
    handleError({ error })
  }
  return { chartData }
}

export const useReportByGroup = async ({
  assigneeId,
  intervalId,
  workspaceId,
  groupId,
  searchString
}) => {
  const dashboardApi = new DashboardApiHandler()
  const options = ref([])
  try {
    const { groupsInfo } = await dashboardApi.getReportByGroup({
      userIds: assigneeId[0] === 0 ? null : assigneeId,
      groupIds: groupId[0] === 0 ? null : groupId,
      intervalId: intervalId,
      workspaceId,
      searchString
    })
    options.value = groupsInfo
  } catch (error) {
    handleError({ error })
  }
  return { options }
}

export const useReportByUser = async ({
  intervalId,
  workspaceId,
  assigneeId,
  groupId,
  searchString
}) => {
  const dashboardApi = new DashboardApiHandler()
  const options = ref([])
  try {
    const { users } = await dashboardApi.getReportByUser({
      intervalId,
      workspaceId,
      userIds: getSelectWithSelectAllApiParameter(assigneeId),
      groupIds: getSelectWithSelectAllApiParameter(groupId),
      searchString
    })

    options.value = users
  } catch (error) {
    handleError({ error })
  }
  return { options }
}

export const useGetFilteredData = ({
  data,
  options,
  sortBy,
  searchString,
  currentPage,
  itemsOnPage,
  key = 'groupName'
}) => {
  if (!data) {
    return []
  }

  let optionsLocal = [...options]

  if (sortBy === ascSortValue || sortBy === descSortValue) {
    const collator = new Intl.Collator(undefined, {
      numeric: true,
      sensitivity: 'base'
    })
    optionsLocal.sort((item1, item2) => collator.compare(item1[key].trim(), item2[key].trim()))

    if (sortBy === descSortValue) {
      optionsLocal = optionsLocal.reverse()
    }
  }

  if (sortBy === avgDecreaseValue) {
    optionsLocal = sortAvgDecreaseValue(optionsLocal)
  }

  if (sortBy === avgIncreaseValue) {
    optionsLocal = sortAvgIncreaseValue(optionsLocal)
  }

  if (searchString !== '') {
    optionsLocal = localSearch({
      value: searchString,
      options: optionsLocal,
      key
    })
  }

  return optionsLocal.slice((currentPage - 1) * itemsOnPage, currentPage * itemsOnPage)
}
