import i18n from '@/i18n'

export const readImage = ({ file }) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    const img = new Image()

    reader.onload = e => {
      img.src = e.target.result
    }
    reader.onerror = () => {
      reject(new Error(i18n.global.t('img.failed_to_read_file')))
    }
    img.onerror = () => {
      reject(new Error(i18n.global.t('img.error_loading_img')))
    }

    img.onload = () => resolve(img)

    reader.readAsDataURL(file)
  })
}

export const MAX_PIXEL_HEIGHT = 10000
export const MAX_PIXEL_WIDTH = 10000

export const IMAGE_FORMAT_STRING = '.jpg,.jpeg,.png,.webp,.gif,.bmp'

export const IMAGE_MIME_TYPES = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/webp',
  'image/gif',
  'image/bmp'
]

export const checkImageFileType = ({ file, allowedFormats = IMAGE_MIME_TYPES } = {}) => {
  return new Promise((resolve, reject) => {
    if (allowedFormats.includes(file.type)) {
      resolve()
    } else {
      reject(new Error(i18n.global.t('img.not_valid_img_format')))
    }
  })
}

export const checkImagePixelSize = ({
  file,
  maxWidth = MAX_PIXEL_WIDTH,
  maxHeight = MAX_PIXEL_HEIGHT
}) => {
  return new Promise((resolve, reject) => {
    readImage({ file })
      .then(img => {
        if (img.naturalWidth >= maxWidth || img.naturalHeight >= maxHeight) {
          reject(
            new Error(
              i18n.global.t('img.image_is_large_than_allowed', {
                maxWidth,
                maxHeight
              })
            )
          )
        }
        resolve()
      })
      .catch(error => {
        reject(new Error(error.message))
      })
  })
}

const MAX_IMG_SIZE_MB = 10

export const checkFileSize = ({ file, maxSizeMB = MAX_IMG_SIZE_MB }) => {
  return new Promise((resolve, reject) => {
    const maxSizeBytes = maxSizeMB * 1024 * 1024
    if (file.size <= maxSizeBytes) {
      resolve()
    } else {
      reject(new Error(i18n.global.t('img.file_is_too_big')))
    }
  })
}
