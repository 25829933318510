export const PLURALIZATION_KEYS = {
  VALUE: 'value',
  SYMBOL: 'symbol',
  FLOAT_NUMBER: 'floatNumber',
  SIMPLIFIED: 'simplified',
  INTEGER_NUMBER: 'integerNumber',
  DEFAULT_NUMBER: 'defaultNumber'
}

const { VALUE, SYMBOL, FLOAT_NUMBER, SIMPLIFIED, INTEGER_NUMBER, DEFAULT_NUMBER } =
  PLURALIZATION_KEYS

export const getSimplifiedNumber = (n, isLowerCase = false) => {
  const si = [
    { [VALUE]: 1, [SYMBOL]: '' },
    { [VALUE]: 1e3, [SYMBOL]: 'K' },
    { [VALUE]: 1e6, [SYMBOL]: 'M' },
    { [VALUE]: 1e9, [SYMBOL]: 'B' },
    { [VALUE]: 1e12, [SYMBOL]: 'T' },
    { [VALUE]: 1e15, [SYMBOL]: 'P' },
    { [VALUE]: 1e18, [SYMBOL]: 'E' }
  ]
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
  let i
  for (i = si.length - 1; i > 0; i--) {
    if (n >= si[i][VALUE]) {
      break
    }
  }

  const resolvedSymbol = isLowerCase ? si[i][SYMBOL].toLowerCase() : si[i][SYMBOL]

  const floatNumber = (n / si[i][VALUE])
    .toString()
    .match(/^-?\d+(?:\.\d{0,1})?/)[0]
    .replace(rx, '$1')

  return {
    [FLOAT_NUMBER]: Number(floatNumber),
    [SIMPLIFIED]: `${floatNumber}${resolvedSymbol}`,
    [SYMBOL]: resolvedSymbol,
    [INTEGER_NUMBER]: Math.floor(Number(floatNumber)),
    [DEFAULT_NUMBER]: Number(n)
  }
}
