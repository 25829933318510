<template>
  <SkeletonItem width="100%" border-radius="6px" height="44px" color="var(--grey-2-next)" />

  <SkeletonItem
    v-if="withCheckbox"
    :appearance="SQUARE"
    color="var(--grey-2-next)"
    :size="XS"
    class="ipl-Checkbox"
  />
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ImmediateParentLoader'
})
</script>

<script setup>
import {
  SKELETON_APPEARANCES,
  SKELETON_SIZES
} from '@/utils/components-configurations/skeleton-item'

import SkeletonItem from '@/components/ui/SkeletonLoaders/SkeletonItem'

const { SQUARE } = SKELETON_APPEARANCES
const { XS } = SKELETON_SIZES

defineProps({
  withCheckbox: {
    type: Boolean
  }
})
</script>

<style scoped lang="scss">
.ipl-Checkbox {
  margin: 10px auto 0 14px;
}
</style>
