<template>
  <BulkActionsOperationValueControl
    v-model="localModelValue"
    :empty-state-label="t('field.labels.bulk_placeholder')"
    :field-id="CORE_FIELDS_IDS.LABELS"
    :item-label="LABEL_ENTITY_KEYS.NAME"
    :item-value="LABEL_ENTITY_KEYS.ID"
    :loading="isLoading"
    :options="options"
    :search-function="getLabels"
    @update:options="options = $event"
    @toggle-value-dropdown="emit('toggle-value-dropdown', $event)"
  />
</template>

<script setup>
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import LabelsApiHandler from '@/api/labels'
import { LABEL_ENTITY_KEYS } from '@/utils/entity-keys'
import { handleError } from '@/utils/error-handling'
import { CORE_FIELDS_IDS } from '@/utils/okr-elements-table-bulk-actions'

import BulkActionsOperationValueControl from '@/components/objectives/bulk-edit/BulkActionsOperationValueControl'

defineOptions({
  name: 'LabelsField'
})

const props = defineProps({
  modelValue: {
    type: Object,
    required: true
  }
})

const { t } = useI18n()

const options = ref([])

const isLoading = ref(false)

const getLabels = async (searchString = null) => {
  let result = []
  const api = new LabelsApiHandler()
  isLoading.value = true

  try {
    result = await api.getLabels({ name: searchString })
  } catch (error) {
    handleError({ error })
  } finally {
    isLoading.value = false
  }

  return result
}

onMounted(async () => {
  options.value = await getLabels()
})

const emit = defineEmits({
  'update:model-value': null,
  'toggle-value-dropdown': null
})

const localModelValue = computed({
  get: () => {
    const { value, operation } = props.modelValue

    return {
      value,
      operation
    }
  },
  set: newValue => {
    emit('update:model-value', newValue)
  }
})
</script>

<style lang="scss" scoped></style>
