<template>
  <div class="wp-Picker">
    <AppCheckbox
      v-for="day in SCHEDULE_DAYS_OF_WEEK"
      :key="day.value"
      v-model="selectedValues"
      v-tippy="{
        content: day[SCHEDULE_ENTITY_KEYS.NAME],
        placement: 'top'
      }"
      :size="CHECKBOX_SIZES.CUSTOM"
      :value="day.value"
      name="weekday-picker"
    >
      <template #checkmark-content>
        <span class="wp-Shortcut">
          {{ day[SCHEDULE_ENTITY_KEYS.SHORT_NAME] }}
        </span>
      </template>
    </AppCheckbox>
  </div>
</template>

<script setup>
import { SCHEDULE_DAYS_OF_WEEK } from '@/utils/check-ins/schedule-ui-helpers'
import { CHECKBOX_SIZES } from '@/utils/components-configurations/app-checkbox'
import { SCHEDULE_ENTITY_KEYS } from '@/utils/entity-keys'

import AppCheckbox from '@/components/ui/AppCheckbox/AppCheckbox'

defineOptions({
  name: 'WeekdayPicker'
})

const selectedValues = defineModel({
  type: Array,
  required: true,
  set: value => value
})
</script>

<style lang="scss" scoped>
.wp-Picker {
  display: flex;
  align-items: center;
  gap: 4px;
  --checkmark-size: 40px;
  --checkmark-image: none;
  --checkmark-border-radius: 50%;
  --checkmark-border-color: #{$grey-2-next};
  --checkmark-color: #{$dark-2};
}

.wp-Shortcut {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $fs-14;
  font-weight: fw('semi-bold');
  line-height: 1;
}
</style>
