<template>
  <FormFieldNext class="sfs-Field">
    <template #label>
      <div class="sfs-ContentFieldsLabel">
        {{ $t(currentTextContent.label) }}
      </div>
    </template>

    <AppSelect
      v-model="valueModel"
      :disabled="disabled"
      :hidden-items="hiddenItems"
      :loading="loading"
      :offset="[0, 0]"
      :options="fieldsOptions"
      :type="SELECT_TYPES.MODERN"
      item-label="label"
      item-value="id"
    />
  </FormFieldNext>
</template>

<script>
import { defineComponent } from 'vue'

import { SELECT_TYPES } from '@/utils/components-configurations/app-select'
import { stringOrNullProp } from '@/utils/prop-validators'
import { FIELD_TYPES } from '@/utils/sync-settings'

import FormFieldNext from '@/components/form/FormFieldNext'
import AppSelect from '@/components/ui/AppSelect/AppSelect'

const TEXT_CONTENT = {
  [FIELD_TYPES.START_DATE]: {
    label: 'settings.synchronization.select.start_date_label',
    placeholder: 'settings.synchronization.select.start_date_placeholder'
  },
  [FIELD_TYPES.END_DATE]: {
    label: 'settings.synchronization.select.end_date_label',
    placeholder: 'settings.synchronization.select.end_date_placeholder'
  }
}

export default defineComponent({
  name: 'SyncFieldsSelect',
  components: { AppSelect, FormFieldNext },
  props: {
    filedType: {
      type: String,
      default: FIELD_TYPES.START_DATE,
      validator: v => Object.values(FIELD_TYPES).includes(v)
    },

    fields: {
      type: Array,
      required: true
    },

    value: {
      required: true,
      validator: v => stringOrNullProp(v)
    },

    hiddenItems: {
      type: Array,
      default: () => []
    },

    loading: {
      type: Boolean
    },

    disabled: {
      type: Boolean
    }
  },

  emits: {
    'update:value': null
  },

  computed: {
    SELECT_TYPES() {
      return SELECT_TYPES
    },

    fieldsOptions() {
      return this.fields.map(field => ({
        ...field,
        label: field.key ? `${field.name} (${field.key})` : field.name
      }))
    },

    currentTextContent() {
      return TEXT_CONTENT[this.filedType]
    },

    valueModel: {
      get() {
        return this.value
      },

      set(val) {
        this.$emit('update:value', val)
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.sfs-ContentFieldsLabel {
  font-weight: fw('bold');
  font-size: $fs-12;
  line-height: 16px;
  color: $dark-3;
}
</style>
