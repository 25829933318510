<template>
  <div class="wtl-Loader">
    <div v-for="row in ROWS" :key="row.id" class="wtl-Loader_Row">
      <div class="wtl-Cell">
        <SkeletonItem
          :appearance="SQUARE"
          :size="SM"
          border-radius="6px"
          color="var(--grey-3-next)"
        />
        <SkeletonItem :size="XS" :width="row.name" border-radius="6px" color="var(--grey-3-next)" />
      </div>
      <div v-for="n in 3" :key="n" class="wtl-Cell">
        <SkeletonItem :size="XS" color="var(--grey-3-next)" width="46px" />
      </div>

      <div class="wtl-Cell">
        <SkeletonItem
          v-if="row.private"
          :appearance="SQUARE"
          :size="SM"
          border-radius="6px"
          color="var(--grey-3-next)"
        />
      </div>

      <div class="wtl-Cell">
        <SkeletonItem :size="XS" color="var(--grey-3-next)" width="40px" />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'WorkspacesTableLoader'
})
</script>

<script setup>
import {
  SKELETON_APPEARANCES,
  SKELETON_SIZES
} from '@/utils/components-configurations/skeleton-item'

const { SM, XS } = SKELETON_SIZES

const { SQUARE } = SKELETON_APPEARANCES

import SkeletonItem from '@/components/ui/SkeletonLoaders/SkeletonItem'

const ROWS = [
  {
    id: 0,
    name: '90px'
  },
  {
    id: 1,
    name: '13px'
  },
  {
    id: 2,
    name: '50px',
    private: true
  },
  {
    id: 3,
    name: '192px'
  },
  {
    id: 4,
    name: '16px',
    private: true
  },
  {
    id: 5,
    name: '20px',
    private: true
  },
  {
    id: 6,
    name: '47px',
    private: true
  },
  {
    id: 7,
    name: '185px',
    private: true
  },
  {
    id: 8,
    name: '210px'
  },
  {
    id: 9,
    name: '82px'
  }
]
</script>

<style lang="scss" scoped>
.wtl-Loader_Row {
  height: 45px;
  padding: 0 $page-right-padding 0 $page-left-padding;
  display: flex;
  align-items: center;
}

.wtl-Cell {
  display: flex;
  align-items: center;

  &:first-child {
    gap: 8px;
    flex: 1 0 auto;
  }

  &:nth-child(2) {
    width: 110px;
  }

  &:nth-child(3) {
    width: 116px;
  }

  &:nth-child(4) {
    width: 125px;
  }

  &:nth-child(5) {
    width: 32px;
  }

  &:nth-child(6) {
    width: 129px;
  }
}
</style>
