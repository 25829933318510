import { useI18n } from 'vue-i18n'

import { memoizeGetCssVariableValue, memoizeHexToRgb } from '@/utils/memoizations'

export const SUPPORT_URL = 'https://oboard.atlassian.net/servicedesk/customer/portal/2'
export const HELP_CENTER_URL = 'https://oboard.atlassian.net/wiki/spaces/OBPFJ/overview'
export const SECURITY_URL =
  'https://developer.atlassian.com/platform/marketplace/security-requirements/#connect-apps'
export const BOOKING_URL =
  'https://calendly.com/nikita-korotkov/cadence-call-oboard?utm_source=oboard-support-page&utm_medium=referral'
export const VIDEO_GUIDES_URL =
  'https://youtube.com/playlist?list=PLkfdrMXQ8IxWP1vgaHFYNB3Fq2-w7YpIa&feature=shared&utm_source=oboard-support-page&utm_medium=banner&utm_campaign=guide_video'

export const CONSULTING_URL =
  'https://calendly.com/dmytro-yarmak/free-okr-review?utm_source=oboard-support-page&utm_medium=referral'
export const DEFINE_YOUR_OKR_URL =
  'https://form.typeform.com/to/EkExO0kY?utm_source=oboard-support-page&utm_medium=referral'

export const SUPPORT_BOXES = {
  CONTACT_US: {
    id: 'contact-us',
    title: 'support.contact_us',
    subtitle: 'support.support.subtitle',
    linkText: 'support.btn_write_to_support_title',
    hero: 'mail',
    color: '--primary-color-next',
    link: SUPPORT_URL,
    tracking: 'customer support'
  },

  HELP_CENTER: {
    id: 'help-center',
    title: 'support.help_center',
    subtitle: 'support.help_center.subtitle',
    linkText: 'support.help_center.link.text',
    hero: 'question',
    color: '--purple',
    link: HELP_CENTER_URL,
    tracking: 'help center'
  },

  ONBOARDING_CALL: {
    id: 'onboarding-call',
    title: 'support.onboarding_call',
    subtitle: 'support.onboarding_call.subtitle',
    linkText: 'support.onboarding_call.link.text',
    color: '--grade-high-color-next',
    link: BOOKING_URL,
    tracking: 'onboarding call'
  },

  VIDEO_GUIDES: {
    id: 'video-guides',
    title: 'support.video_guides',
    subtitle: 'support.video_guides.subtitle',
    linkText: 'support.video_guides.link.text',
    hero: 'youtube-colored',
    color: '--grade-low-color-next',
    link: VIDEO_GUIDES_URL,
    tracking: 'video guides'
  },

  OKR_CONSULTING: {
    id: 'okr-consulting',
    title: 'support.okr_consulting',
    subtitle: 'support.okr_consulting.subtitle',
    linkText: 'support.okr_consulting.link.text.next',
    color: '--grade-medium-color-next',
    link: CONSULTING_URL,
    tracking: 'okr consulting'
  },

  DEFINE_YOUR_OKR: {
    id: 'define-your-okr',
    title: 'support.define_your_okr',
    subtitle: 'support.define_your_okr.subtitle',
    linkText: 'support.define_your_okr.link.text',
    hero: 'multiselect',
    color: '--primary-color-next',
    link: DEFINE_YOUR_OKR_URL,
    tracking: 'okr consulting'
  }
}

export const useSupportHelpers = () => {
  const { t } = useI18n()

  const prepareBoxItem = box => {
    const { color, title, linkText, ...rest } = box
    const hex = memoizeGetCssVariableValue(color)
    const rgb = memoizeHexToRgb(hex.trim())
    return {
      ...rest,
      hex,
      rgb,
      title: t(title),
      linkText: t(linkText)
    }
  }

  return {
    prepareBoxItem
  }
}
