<template>
  <div>
    <portal to="ot-navigation-after">
      <OkrTableShowKrsSwitch v-model="treeState.showKrs" name="okr-map-show-krs-switch" />
    </portal>

    <div :class="{ 'oob-ObjectiveTree-fullscreen': isFullscreen }" class="oob-ObjectiveTree">
      <div ref="container" class="oob-ContentWrapper">
        <div ref="canvas" class="oob-Content">
          <ObjectiveTreeRowList
            :model-value="listState.objectives"
            @update="update"
            @request-offset="onRequestOffset"
          />
          <InfiniteScrollLoader
            ref="scrollLoader"
            :identifier="infiniteId"
            :load-on-start="false"
            @infinite="$emit('load-more', $event)"
            @update-is-infinite-loading="isInfiniteLoading = $event"
          >
            <template #no-results>
              <ObjectivesEmpty
                :filters-used="!listState.haveAllFiltersDefaultValues"
                :objective-levels="objectiveLevels"
                :user-can-create-objectives="userCanCreateObjectives"
                class="oob-ObjectiveTree-empty"
                disable-top-margin
                @mounted="centerContent"
                @create-objective="onCreateObjectiveClick($event.typeId)"
                @reset-search-criteria="resetSearchCriteria"
              />
            </template>

            <template #loader>
              <MindmapLoader />
            </template>
          </InfiniteScrollLoader>
        </div>
      </div>

      <div class="oob-TreeFooter">
        <ViewSettings
          :disable-zoom="listState.noResults"
          :is-loading="isInfiniteLoading"
          :max="max"
          :min="min"
          :step="step"
          :tree-type="treeState.activeTreeType"
          :zoom="treeState.zoom"
          @fullscreen="onFullscreen"
          @overview="onOverview"
          @zoom="onZoom"
          @collapse-all="$emit('collapse-all')"
          @expand-all="$emit('expand-all')"
          @update-tree-type="onUpdateTreeType"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapActions, mapGetters, mapState } from 'vuex'

import { listStateInjectionKey, treeStateInjectionKey } from '@/utils/injection-keys'
import { currentUserCanCreateObjective } from '@/utils/objectives'
import { AVAILABLE_TREE_TYPES, TREE_TYPES } from '@/utils/okr-map'
import { isLocalStorageAvailable, readFromStorageByKey, updateStorageByKey } from '@/utils/persist'
import {
  createZoommer,
  createScroller,
  moveContainerToCanvasCenter
} from '@/utils/zoommer-scroller'

import ObjectivesEmpty from '@/components/objectives/ObjectivesEmpty'
import OkrTableShowKrsSwitch from '@/components/objectives/OkrTableShowKrsSwitch'
import ViewSettings from '@/components/objectives/tree/ViewSettings'
import InfiniteScrollLoader from '@/components/ui/InfiniteScrollLoader/InfiniteScrollLoader'
import MindmapLoader from '@/components/ui/SkeletonLoaders/MindmapLoader'

import ObjectiveTreeRowList from './ObjectiveTreeRowList'
// import AppRadioGroup from "@/components/ui/AppRadioGroup/AppRadioGroup";

const SHOW_KRS_STORAGE_KEY = 'SHOW_KRS'

const LS_MINDMAP_TREE_TYPE_KEY = 'mindmapTreeType'

const DEFAULT_ZOOM = 100

export default defineComponent({
  name: 'ObjectiveTree',

  components: {
    OkrTableShowKrsSwitch,
    MindmapLoader,
    // AppRadioGroup,
    ObjectiveTreeRowList,
    ViewSettings,
    InfiniteScrollLoader,
    ObjectivesEmpty
  },

  provide() {
    return {
      [treeStateInjectionKey]: this.treeState
    }
  },

  inject: {
    listState: {
      from: listStateInjectionKey
    }
  },

  emits: {
    'create-objective': null,
    'load-more': null,
    'update-okr-data': null,
    'reset-search-criteria': null,
    'expand-all': null,
    'collapse-all': null
  },

  data() {
    return {
      treeState: {
        tabName: '',
        activeTreeType: TREE_TYPES.COMPACT.value,
        zoom: DEFAULT_ZOOM,
        canvas: null, // will be assigned on mounted hook
        showKrs: false
      },

      step: 10,
      min: 10,
      max: 200,

      infiniteId: +new Date(),
      zoommer: null,
      scroller: null,

      isInfiniteLoading: false
    }
  },

  computed: {
    ...mapState('system', {
      fullscreen: state => state.fullscreen
    }),

    ...mapGetters('workspaces', {
      selectedWorkspace: 'selectedWorkspace'
    }),

    ...mapState('pluginOptions', {
      isPluginAdmin: state => state.isPluginAdmin
    }),

    ...mapState('objectives', {
      objectiveLevels: state => state.levels
    }),

    isFullscreen() {
      return this.fullscreen
    },

    userCanCreateObjectives() {
      return currentUserCanCreateObjective(this.selectedWorkspace, this.isPluginAdmin)
    }
  },

  watch: {
    'listState.noResults': {
      async handler(noResults) {
        if (noResults) {
          this.destroyScroller()
          this.destroyZoommer()
        } else {
          this.createScroller()
          this.createZoommer()
          this.scroller.reset()
        }
      }
    },

    'treeState.showKrs': {
      handler(newValue) {
        if (isLocalStorageAvailable()) {
          updateStorageByKey(SHOW_KRS_STORAGE_KEY, newValue)
        }
      }
    }
  },

  mounted() {
    const showKrs = readFromStorageByKey(SHOW_KRS_STORAGE_KEY)
    this.treeState.showKrs = showKrs !== null ? showKrs : false

    this.restoreTreeTypeSettings()

    this.treeState.canvas = this.$refs.canvas

    if (!this.listState.noResults) {
      this.createZoommer()
      this.createScroller()
    }
  },

  beforeUnmount() {
    if (this.isFullscreen) {
      this.toggleFullscreen()
    }

    this.destroyScroller()
    this.destroyZoommer()
  },

  methods: {
    ...mapActions('system', {
      toggleFullscreen: 'toggleFullscreen'
    }),

    async onCreateObjectiveClick(levelId) {
      this.$emit('create-objective', {
        levelId
      })
    },

    restoreTreeTypeSettings() {
      const restoredTreeType = readFromStorageByKey(LS_MINDMAP_TREE_TYPE_KEY)

      if (
        restoredTreeType &&
        AVAILABLE_TREE_TYPES.includes(restoredTreeType) &&
        restoredTreeType !== this.treeState.activeTreeType
      ) {
        this.treeState.activeTreeType = restoredTreeType
      }
    },

    centerContent() {
      this.treeState.zoom = DEFAULT_ZOOM
      moveContainerToCanvasCenter({
        container: this.$refs.container,
        canvas: this.$refs.canvas,
        resetScale: true
      })
    },

    createZoommer() {
      this.zoommer = createZoommer(
        {
          container: this.$refs.container,
          canvas: this.$refs.canvas,
          min: this.min / DEFAULT_ZOOM,
          max: this.max / DEFAULT_ZOOM
        },
        ({ scale }) => {
          this.treeState.zoom = Math.round(scale * DEFAULT_ZOOM)
          this.loadMoreIfNeeded()
        }
      )
    },

    createScroller() {
      this.scroller = createScroller(
        {
          container: this.$refs.container,
          canvas: this.$refs.canvas,
          ignored: ['.ti-TreeItem', '.ki-KrList']
        },
        () => {
          this.loadMoreIfNeeded()
        }
      )
    },

    destroyScroller() {
      this.scroller?.destroy()
      this.scroller = null
    },

    destroyZoommer() {
      this.zoommer?.destroy()
      this.zoommer = null
    },

    /** @public */
    requestData() {
      this.infiniteId += 1
    },

    onZoom(zoom) {
      this.zoommer?.zoomCenterTo(zoom / DEFAULT_ZOOM)
    },

    onRequestOffset(offset) {
      this.scroller?.move({ y: -offset })
    },

    onUpdateTreeType(type) {
      if (this.treeState.activeTreeType !== type) {
        this.treeState.activeTreeType = type
        updateStorageByKey(LS_MINDMAP_TREE_TYPE_KEY, type)
      }
    },

    onOverview() {
      const { container, canvas } = this.$refs
      const containerAspectRatio = container.clientWidth / container.clientHeight
      const canvasAspectRatio = canvas.clientWidth / canvas.clientHeight
      const fitBy = dimension => {
        let mainProp
        let crossProp
        let axis
        if (dimension === 'width') {
          mainProp = 'clientWidth'
          crossProp = 'clientHeight'
          axis = 'y'
        } else if (dimension === 'height') {
          mainProp = 'clientHeight'
          crossProp = 'clientWidth'
          axis = 'x'
        }
        const rawScale = container[mainProp] / canvas[mainProp]
        const getScale = rawScale => {
          const min = this.min / DEFAULT_ZOOM
          const max = this.max / DEFAULT_ZOOM
          if (rawScale <= min) {
            return min
          }
          if (rawScale >= max) {
            return max
          }
          return rawScale
        }
        const scale = getScale(rawScale)
        this.zoommer.zoomCenterTo(scale)
        const diff = (container[crossProp] - canvas[crossProp] * scale) / 2 / scale
        this.scroller.moveTo({ [axis]: diff })
      }
      if (containerAspectRatio > canvasAspectRatio) {
        fitBy('height')
      } else {
        fitBy('width')
      }
    },

    onFullscreen() {
      this.toggleFullscreen()
    },

    resetSearchCriteria() {
      this.$emit('reset-search-criteria')
    },

    update(eventData) {
      this.$emit('update-okr-data', {
        keepObjectivesSize: true,
        blinkObjectiveIds:
          eventData && eventData.blinkObjectiveIds ? eventData.blinkObjectiveIds : []
      })
    },

    loadMoreIfNeeded() {
      const { bottom } = this.$refs.canvas.getBoundingClientRect()
      if (bottom - 150 < window.innerHeight) {
        this.$refs.scrollLoader.loadNextItems()
      }
    }
  }
})
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/canvas-dimensions';

$footer-height: 64px;

.oob-ObjectiveTree {
  background-color: $white;
  padding: 0 $page-right-padding 0 $page-left-padding;
  // background-image: url('~@/assets/images/mindmap-bg.png');
  // background-repeat: no-repeat;
  // background-position: bottom right;
  height: calc(#{$default-canvas-height} - #{$footer-height});
  position: relative;
  user-select: none;

  &-fullscreen {
    height: calc(#{$fullscreen-canvas-height} - #{$footer-height});
  }
}

.oob-TreeFooter {
  height: $footer-height;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.oob-ContentWrapper {
  overflow: hidden;
  height: 100%;
  background-color: $grey-2-next;
  border-radius: $border-radius-md-next;
}

.oob-Content {
  // padding: 20px $page-right-padding 20px $page-left-padding;
  padding: 40px;
  width: min-content;
  transform-origin: 0 0;
  position: relative;
  top: 0;

  /* eslint-disable-next-line */ // this class append dynamically with js classList.add function
  &-animate {
    transition: $transition-fast top linear;
  }
}
.oob-ObjectiveTree-empty {
  white-space: nowrap;
}
// .oob-TreeNavigation {
//   display: flex;
//   align-items: center;
//
//   &_Label {
//     color: $grey-semi-medium;
//     margin-right: 16px;
//   }
// }
</style>
