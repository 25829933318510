<template>
  <AppDroplist
    ref="droplist"
    v-model="isOpened"
    :append-to="appendTo"
    :has-fixed-parent="hasFixedParent"
    :hide-on-click="hideOnClick"
    :offset="offset"
    :position="preferredPosition"
    :show-on-init="showOnInit"
    :theme="theme"
    :to-selector="toSelector"
    :width-as-parent="widthAsParent"
    boundary="viewport"
    class="o-dropdown-menu"
    max-width="maxWidth"
  >
    <template #button>
      <slot :is-opened="isOpened" name="activator" />
    </template>

    <div :class="contentClass">
      <div
        :class="{ [`dm-List-${type}`]: type }"
        class="dm-List"
        data-auto-testid="dropdown-menu-list"
      >
        <div
          v-for="[group, groupItems] in Object.entries(groups)"
          :key="group"
          class="dm-List_Group"
        >
          <DropdownMenuItem
            v-for="item in groupItems"
            :key="item.name"
            :class="{
              'dm-List_Item-disabled': item.disabled,
              [`dm-List_Item-${item.name}`]: true
            }"
            :item="item"
            :style="{ '--color': item.color, '--icon-color': item.iconColor }"
            class="dm-List_Item"
            @click="onItemClick(item.name, item.disabled, item.nestedItems)"
          >
            <template #item-label="{ item: itemData }">
              <slot :item="itemData" name="item-label" />
            </template>
            <div v-if="item.nestedItems && item.nestedItems.length" class="dm-ListItemNested">
              <AppIcon height="24" icon-name="okr-table-expand" width="24" />

              <AppDroplist
                :ref="setReference"
                :offset="[0, 0]"
                :to-selector="`.dm-List_Item-${item.name}`"
                :type="DROPDOWN_MENU_TYPES.DEFAULT_NEXT"
                append-to="parent"
                class="o-dropdown-menu"
                position="right-start"
                trigger="mouseenter"
              >
                <div
                  :class="{ [`dm-List-${type}`]: type }"
                  class="dm-List"
                  data-auto-testid="nested-dropdown-menu-list"
                >
                  <div class="dm-List_Group">
                    <DropdownMenuItem
                      v-for="nestedItem in item.nestedItems"
                      :key="nestedItem.name"
                      :item="nestedItem"
                      class="dm-List_Item"
                      @click="onItemClick(nestedItem.name, nestedItem.disabled)"
                    />
                  </div>
                </div>
              </AppDroplist>
            </div>
          </DropdownMenuItem>
        </div>
      </div>
    </div>
  </AppDroplist>
</template>

<script>
import { isEmpty, isUndefined } from 'lodash'
import { defineComponent, computed } from 'vue'

import { DROP_LIST_THEMES } from '@/utils/components-configurations/app-droplist'
import { DROPDOWN_MENU_TYPES } from '@/utils/components-configurations/dropdown-menu'
import { useGroupOptions } from '@/utils/dropdown-menu'

import AppDroplist from '@/components/AppDroplist'
import AppIcon from '@/components/ui/AppIcon/AppIcon'
import DropdownMenuItem from '@/components/ui/DropdownMenu/DropdownMenuItem'

export default defineComponent({
  name: 'DropdownMenu',

  components: {
    DropdownMenuItem,
    AppDroplist,
    AppIcon
  },

  props: {
    items: {
      type: Array,
      default: () => []
    },

    show: {
      type: Boolean,
      default: false
    },

    preferredPosition: {
      type: String,
      default: 'left'
    },

    appendTo: {
      type: String,
      default: ''
    },

    contentClass: {
      type: Array,
      default: () => []
    },

    hasFixedParent: {
      type: Boolean,
      default: false
    },

    hideOnClick: {
      type: Boolean,
      default: true
    },

    toSelector: {
      type: String,
      default: null
    },

    showOnInit: {
      type: Boolean
    },

    widthAsParent: {
      type: Boolean
    },

    offset: {
      type: Array,
      default: () => [0, 8]
    },

    type: {
      type: String,
      default: DROPDOWN_MENU_TYPES.DEFAULT,
      validator: v => Object.values(DROPDOWN_MENU_TYPES).includes(v)
    }
  },

  emits: { 'update:show': null, open: null, close: null, 'item-click': null },

  setup(props) {
    const groups = computed(() => {
      const { groups: data, needGrouping } = useGroupOptions(props.items)

      let groups = data.value

      if (props.type !== DROPDOWN_MENU_TYPES.DEFAULT_NEXT && !needGrouping.value) {
        groups = { all: props.items }
      }

      return groups
    })

    return {
      groups
    }
  },

  data() {
    return {
      isOpened: this.show,
      nestedReferences: []
    }
  },

  computed: {
    DROPDOWN_MENU_TYPES: () => DROPDOWN_MENU_TYPES,

    theme() {
      return this.type === DROPDOWN_MENU_TYPES.DEFAULT_NEXT
        ? DROP_LIST_THEMES.COMMON_THEMES
        : DROP_LIST_THEMES.LIGHT
    }
  },

  watch: {
    show(newValue) {
      if (this.isOpened !== newValue) {
        this.isOpened = newValue
      }
    },

    isOpened(newValue) {
      if (this.show !== newValue) {
        this.$emit('update:show', newValue)
      }

      if (newValue) {
        this.$emit('open')
      } else {
        this.$emit('close')
      }
    }
  },

  beforeUnmount() {
    this.nestedReferences = []
  },

  beforeUpdate() {
    this.nestedReferences = []
  },

  methods: {
    setReference(reference) {
      if (reference) {
        this.nestedReferences.push(reference)
      }
    },

    onItemClick(name, disabled = false, nestedItems = []) {
      const hasNestedItems = !isUndefined(nestedItems) && !isEmpty(nestedItems)
      if (!disabled && !hasNestedItems) {
        this.nestedReferences.forEach(ref => ref.hide())
        this.$refs.droplist.hide()
        this.$emit('item-click', name)
        this.isOpened = false
      }
    },

    /** @public */
    toggle() {
      this.isOpened = !this.isOpened
    },

    /** @public */
    close() {
      if (this.isOpened) {
        this.isOpened = false
      }
    }
  }
})
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';
@import '~@/assets/styles/dropdown-menu';

.udm-Header {
  margin: 0;
  line-height: 1.33;
  text-transform: uppercase;
  font-size: 0.775rem;
  color: $grey-12;
  padding: 8px 8px 0;
  text-align: left;
  font-family: $system-ui;
}

.dm-ListItemNested {
  margin-left: auto;
  display: flex;
  color: $dark-2;
}

.dm-List {
  display: flex;
  flex-direction: column;

  border-radius: inherit;
  overflow: hidden;
  font-family: $system-ui;

  &:not(&-default-next) {
    padding: 8px 0;
  }

  &-default-next {
    min-width: 200px;
    border-radius: $border-radius-sm-next;
    overflow: hidden;
  }
}

.dm-List_Item {
  display: flex;
  width: 100%;
  outline: none;
  text-align: left;
  cursor: pointer;

  align-items: var(--align, center);

  background: transparent;
  box-sizing: border-box;

  &-disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &-disabled#{&}-disabled-with-reason {
    opacity: initial;
    flex-wrap: wrap;
  }

  .dm-List-default & {
    padding: 8px 20px;
    gap: 16px;

    font-size: 1rem;

    @include hoverState($main-bg-grey, 0%);
    @include activeState($main-bg-grey, 10%);
  }

  .dm-List-default-next & {
    padding: 10px;
    gap: 8px;

    font-style: normal;
    font-weight: fw('regular');
    font-size: $fs-14;
    line-height: 20px;
    color: var(--color, $dark-1);

    @include hoverState($grey-3-next, 0%);
    @include activeState($grey-3-next, 10%);
  }
}

.dm-List_Group {
  .dm-List-default-next & {
    @extend %group-divider;
  }
}
</style>
