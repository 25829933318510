<template>
  <div class="mum-Loader">
    <div v-for="row in ROWS" :key="row.id" class="mum-Loader_Row">
      <div class="mum-Cell">
        <SkeletonItem :appearance="SQUARE" :size="SM" border-radius="6px" class="mum-Cell_Icon" />
        <SkeletonItem :size="XS" :width="row.name" />
        <SkeletonItem :appearance="CIRCLE" height="20px" width="20px" />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ManageUsersTableLoader'
})
</script>

<script setup>
import {
  SKELETON_APPEARANCES,
  SKELETON_SIZES
} from '@/utils/components-configurations/skeleton-item'

import SkeletonItem from '@/components/ui/SkeletonLoaders/SkeletonItem'

const { XS, SM } = SKELETON_SIZES
const { SQUARE, CIRCLE } = SKELETON_APPEARANCES

const ROWS = [
  {
    id: 0,
    name: '35px'
  },
  {
    id: 1,
    name: '185px'
  }
]
</script>

<style lang="scss" scoped>
.mum-Loader_Row {
  display: flex;
  align-items: center;
  height: 45px;
  padding: 0 40px;
}

.mum-Cell {
  display: flex;
  align-items: center;
  gap: 4px;
  width: 347px;
  flex: 0 0 347px;
}

.mum-Cell_Icon {
  margin-right: 4px;
}
</style>
