export const LICENSE_TYPES = {
  WEB_APP_NONE: '0',
  WEB_APP_TRIAL: '1',
  WEB_APP_ACTIVE: '2',
  WEB_APP_OVERLIMIT: '3',
  WEB_APP_BLOCKED: '4',
  WEB_APP_PAST_DUE: '5',
  WEB_APP_TO_BE_CANCELED: '6',
  WEB_APP_CANCELED: '7',

  JIRA_NONE: '0',
  JIRA_TRIAL: '1',
  JIRA_STANDARD: '2',

  SALESFORCE_NONE: '0',
  SALESFORCE_TRIAL: '1',
  SALESFORCE_ACTIVE: '2',
  SALESFORCE_OVERLIMIT: '3',
  SALESFORCE_BLOCKED: '4',
  SALESFORCE_PAST_DUE: '5',
  SALESFORCE_TO_BE_CANCELED: '6',
  SALESFORCE_CANCELED: '7'
}
