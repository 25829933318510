<template>
  <div :class="{ 'upc-Cell-loading': loading }" class="upc-Cell">
    <AppIcon
      v-for="platform in resolvedPlatforms"
      :key="platform.id"
      :icon-name="platform.icon"
      :style="{ '--order': platform.order }"
      class="upc-Cell_Icon"
      height="24"
      width="24"
    />
  </div>
</template>

<script setup>
import { computed } from 'vue'

import { getPlatformOptionsById } from '@/utils/platforms-helpers'

import AppIcon from '@/components/ui/AppIcon/AppIcon'

defineOptions({
  name: 'UserPlatformsCell'
})

const props = defineProps({
  platforms: {
    type: Array,
    required: true
  },

  loading: {
    type: Boolean
  }
})

const resolvedPlatforms = computed(() => {
  return props.platforms.map(platform => {
    return getPlatformOptionsById(platform)
  })
})

const MAX_PLATFORMS_COUNT = 2
</script>

<style lang="scss" scoped>
.upc-Cell {
  display: grid;
  grid-template-columns: repeat(v-bind(MAX_PLATFORMS_COUNT), 24px);
}

@keyframes MoveUpDown {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(3px);
  }
  75% {
    transform: translateY(-3px);
  }
  100% {
    transform: translateY(0px);
  }
}

.upc-Cell_Icon {
  .upc-Cell-loading & {
    animation: MoveUpDown cubic-bezier(0.17, 0.67, 0.83, 0.67) 0.5s infinite;
  }

  grid-column-start: var(--order);

  &:nth-child(2n + 1) {
    animation-direction: reverse;
  }
}
</style>
