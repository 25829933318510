<template>
  <portal to="modal-windows">
    <AppModalWithConfirmation
      ref="confirmReference"
      :confirm-close="areDataChanged"
      :show="show"
      :title="t('workspace.change_role', { qty: selectedUsers.length }, selectedUsers.length)"
      class="curm-Modal"
      hide-hero
      size="sm"
      @on-close="onClose(false)"
    >
      <div class="curm-Body">
        <FormFieldNext :label="t('workspaces.role')">
          <AppSelect
            v-model="selectedRole"
            :offset="[0, 0]"
            :options="rolesOptions"
            append-to=".curm-Body"
            boundary="scrollParent"
            item-label="label"
            item-value="value"
          >
          </AppSelect>
        </FormFieldNext>
      </div>
      <template #footer-actions>
        <AppButton type="ghost-next" @click="close">
          {{ $t('action.cancel') }}
        </AppButton>
        <AppButton
          :disable="isLoading || isDisableSaving"
          :loading="isLoading"
          type="primary-next"
          @click="save"
        >
          {{ t('action.update') }}
        </AppButton>
      </template>
    </AppModalWithConfirmation>
  </portal>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import WorkspaceUsersApiHandler from '@/api/workspace-users'
import { handleError } from '@/utils/error-handling'
import { showNotify } from '@/utils/notify'

import AppModalWithConfirmation from '@/components/AppModalWithConfirmation'
import FormFieldNext from '@/components/form/FormFieldNext'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppSelect from '@/components/ui/AppSelect/AppSelect'

defineOptions({
  name: 'ChangeUserRoleModal'
})

const props = defineProps({
  show: {
    type: Boolean
  },
  roles: {
    type: Array,
    required: true
  },
  selectedUsers: {
    type: Array,
    required: true
  },
  workspaceId: {
    type: Number,
    required: true
  }
})
const areDataChanged = computed(() => {
  return !!selectedRole.value
})

const { t } = useI18n()

const selectedRole = ref(null)

const emit = defineEmits(['update:show', 'reload-data'])

const isLoading = ref(false)
const isDisableSaving = computed(() => {
  return !selectedRole.value || !props.selectedUsers.length
})
const onClose = (reloadData = false) => {
  selectedRole.value = null
  emit('update:show', false)
  if (reloadData) {
    emit('reload-data')
  }
}
const save = async () => {
  isLoading.value = true
  const usersApi = new WorkspaceUsersApiHandler()
  try {
    await usersApi.updateUserRoleBulk({
      roleId: selectedRole.value,
      accountIds: props.selectedUsers,
      workspaceId: props.workspaceId
    })
    showNotify({ title: t('notifications.role_changed') })
    onClose(true)
  } catch (error) {
    handleError({ error })
  } finally {
    isLoading.value = false
  }
}
const confirmReference = ref(null)
const close = () => {
  confirmReference.value.close()
}
const rolesOptions = computed(() => {
  return props.roles.filter(({ value }) => value)
})
</script>

<style scoped></style>
