<template>
  <LicenseInactiveView>
    <template #logo>
      <LoadingCircle class="acd-Loader" size="xl" />
    </template>
    <div class="acd-Title">
      {{ description }}
    </div>
  </LicenseInactiveView>
</template>

<script setup>
import { computed, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

import WebAppConnectionsApiHandler from '@/api/web-app-connections'
import { EVENT_NAMES } from '@/tracking/gtm-helpers'
import { gtmTracker } from '@/tracking/gtm-tracking'
import { handleError } from '@/utils/error-handling'
import { INSTANCE_STATES } from '@/utils/instance-states'
import { NOTIFICATION_TYPES, showNotify } from '@/utils/notify'
import { useReloadPage } from '@/utils/reload-page'
import { CONNECTION_STATUSES } from '@/utils/web-app/connections'
import { PLUGIN_OPTIONS_KEYS } from '@root/template-options-keys'

import LoadingCircle from '@/components/ui/LoadingCircle/LoadingCircle'
import LicenseInactiveView from '@/views/LicenseInactiveView'

defineOptions({
  name: 'AppConnectionDisconnectionInProgress'
})

const props = defineProps({
  isDisconnection: {
    type: Boolean
  }
})

const { t } = useI18n()

const { reloadPage } = useReloadPage()

const api = new WebAppConnectionsApiHandler()

const store = useStore()

const isConnectionInitializedByMe = computed(() => {
  return store.state.appContext.connectionWithJira.isConnectionInitializedByMe
})

const isDisconnectionInitializedByMe = computed(() => {
  return store.state.appContext.connectionWithJira.isDisconnectionInitializedByMe
})

const runLongPolling = async () => {
  try {
    const requestMethod = props.isDisconnection
      ? () => api.getDisconnectionStatusLongPolling()
      : () => api.getConnectionStatusLongPolling()
    const { statusId, error } = await requestMethod()

    if ([CONNECTION_STATUSES.NONE, CONNECTION_STATUSES.SUCCESS].includes(statusId)) {
      if (isConnectionInitializedByMe.value) {
        gtmTracker.logEvent(EVENT_NAMES.JIRA_CONNECTION_SUCCESS)
      }
      reloadPage()
    }

    if (statusId === CONNECTION_STATUSES.FAILED) {
      if (error && (isConnectionInitializedByMe.value || isDisconnectionInitializedByMe.value)) {
        showNotify({ content: error, type: NOTIFICATION_TYPES.ERROR, expanded: true })
      }

      store.dispatch('appContext/setIsConnectionInitializedByMe', false)
      store.dispatch('appContext/setIsDisconnectionInitializedByMe', false)

      store.dispatch('pluginOptions/setPluginOptions', {
        [PLUGIN_OPTIONS_KEYS.INSTANCE_STATE_ID]: INSTANCE_STATES.ACTIVE.id
      })
    }

    // console.log(status)
  } catch (error) {
    handleError({ error })
  }
}

onMounted(() => {
  runLongPolling()
})

const description = computed(() => {
  return props.isDisconnection
    ? t('connections.disconnection_in_progress')
    : t('connections.connection_in_progress')
})
</script>

<style lang="scss" scoped>
.acd-Loader {
  margin-bottom: 56px;
}

.acd-Title {
  position: relative;

  &:after,
  &:before {
    content: '';
    animation: ellipsis 2s infinite;
  }

  &:before {
    color: transparent;
  }
}

@keyframes ellipsis {
  25% {
    content: '.';
  }
  50% {
    content: '..';
  }
  75% {
    content: '...';
  }
}
</style>
