<template>
  <NumberFilter
    :key="`ws-${workspaceId}—mf-${fieldId}`"
    :empty-state-label="emptyStateLabel"
    :field-name="fieldName"
    :filter-value="filterValue"
    :selected-options="displayValue"
    @update-filter-value="onUpdateModelValue"
    @initial-data-loaded="onInitialDataLoaded"
  />
</template>

<script setup>
defineOptions({
  name: 'MoneyFieldFilter',
  inheritAttrs: false
})

import { isEqual } from 'lodash'
import { computed, ref, unref } from 'vue'

import { useCustomFieldsHelpers } from '@/utils/custom-fields/use-custom-fields'
import { getNumberFilterDisplayValue } from '@/utils/okr-elements/number-filter'

import NumberFilter from '@/components/custom-fields/okr-elements-filters/NumberFilter'

const props = defineProps({
  fieldId: {
    type: [String, Number],
    required: true
  },

  filterValue: {
    type: Array,
    required: true
  },

  workspaceId: {
    type: [String, Number],
    required: true
  }
})

const emit = defineEmits({
  'initial-data-loaded': null,
  'update-filter-value': null
})

const isFilterValidated = ref(false)

const onInitialDataLoaded = () => {
  emit('initial-data-loaded')
  isFilterValidated.value = true
}

const { fieldName, emptyStateLabel, currency } = useCustomFieldsHelpers({
  fieldId: props.fieldId
})

const displayValue = computed(() => {
  return getNumberFilterDisplayValue({
    filterValue: props.filterValue,
    isFilterValidated: unref(isFilterValidated),
    currency: currency.value
  })
})

const onUpdateModelValue = ({ value }) => {
  if (!isEqual(props.filterValue, value)) {
    emit('update-filter-value', {
      value,
      fieldId: props.fieldId
    })
  }
}
</script>

<style lang="scss" scoped></style>
