<template>
  <div v-show="availableTeleports.includes(placement)" :id="placement" />
</template>

<script setup>
import { ADDITIONAL_PARAMS_FIELDS_PLACEMENTS } from '@/utils/custom-fields/factory'

defineProps({
  availableTeleports: {
    type: Array,
    required: true
  },

  placement: {
    type: String,
    required: true,
    validator: v => Object.values(ADDITIONAL_PARAMS_FIELDS_PLACEMENTS).includes(v)
  }
})
</script>

<style lang="scss" scoped></style>
