<template>
  <div class="dcwt-Content">
    <AppTitle v-if="!hideTitle" :level="3" disable-top-margin>
      {{ $t('objective.chart.progress') }}
    </AppTitle>
    <template v-if="tableReady">
      <LineChart
        :aspect-ratio="2.74"
        :chart-data="chartData"
        :chart-type="chartType"
        :granulation="granulation"
        :split-enabled="split"
        :table-configured-chart-lines="tableConfiguredChartLines"
        :time-intervals-count="defaultTimeIntervalsCount"
        is-linear
      />

      <ObjectiveChartTable
        v-model:period="formModel.period"
        v-model:split="split"
        :available-granulation="getAvailableGranulation"
        :columns="columns"
        :rows="rows"
        :show-split-toggle="showSplitToggle"
        @update="onUpdate"
        @update:period="onChangePeriod"
        @set-time-intervals-count="setTimeIntervalsCount"
      >
        <template #body-cell-name-text="{ item }">
          <div class="oboard-truncated-text">
            <OkrElementCipheredName
              :okr-element="item"
              style="--text-color: var(--grade-default-color)"
            />
          </div>
        </template>
      </ObjectiveChartTable>
    </template>
    <OkrChartWithTableLoader v-else />
  </div>
</template>

<script>
import { isEmpty } from 'lodash'
import { defineComponent } from 'vue'

import CustomDashboardApiHandler from '@/api/custom-dashboard'
import { useNormalizeChartLines } from '@/composables/objectiveChartComposable'
import { handleError } from '@/utils/error-handling'
import { CHART_TYPES, PERIODS, prepareTable, TIME_INTERVALS_COUNT } from '@/utils/objective-chart'

import LineChart from '@/components/LineChart/LineChart'
import ObjectiveChartTable from '@/components/objectives/chart/ObjectiveChartTable'
import AppTitle from '@/components/ui/AppTitle/AppTitle'
import OkrElementCipheredName from '@/components/ui/OkrElementCipheredName'
import OkrChartWithTableLoader from '@/components/ui/SkeletonLoaders/OkrChartWithTableLoader'

export default defineComponent({
  name: 'DashboardChartWithTable',
  components: {
    OkrElementCipheredName,
    OkrChartWithTableLoader,
    LineChart,
    AppTitle,
    ObjectiveChartTable
  },

  props: {
    chartType: {
      type: String,
      default: CHART_TYPES.simple,
      validator: v => Object.values(CHART_TYPES).includes(v)
    },

    elementId: {
      type: [String, Number],
      required: true
    },

    storyData: {
      type: Object,
      default: () => ({})
    },

    hideTitle: {
      type: Boolean
    },

    title: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      tableConfiguredChartLines: [],
      columns: null,
      rows: null,
      split: false,
      showSplitToggle: true,
      chartData: {},
      formModel: {
        period: PERIODS.DEFAULT.value,
        timeIntervalsCount: TIME_INTERVALS_COUNT
      },

      granulation: PERIODS.DEFAULT.value,
      defaultTimeIntervalsCount: TIME_INTERVALS_COUNT
    }
  },

  computed: {
    getAvailableGranulation() {
      return this.chartData.chartLines ? this.chartData.chartLines[0].availableGranulation : []
    },

    tableReady() {
      return ![this.columns, this.rows].includes(null)
    }
  },

  watch: {
    split(split) {
      // get the parent or children based on the `split` value
      const source = split ? this.chartData.chartLines[0].childElements : this.chartData

      // prepare table data
      const { columns, rows } = prepareTable(source)
      this.columns = columns
      this.rows = rows
    }
  },

  mounted() {
    this.getChartData()
  },

  methods: {
    setTimeIntervalsCount(value) {
      this.formModel.timeIntervalsCount = value
    },

    onChangePeriod() {
      this.getChartData()
    },

    // USE THAT WHEN TABLE WILL RETURNED
    onUpdate(active) {
      // get the parent or children based on the `split` value
      const chartLines = this.split
        ? this.chartData.chartLines[0].childElements.chartLines
        : this.chartData.chartLines

      // filter out to those who are active
      this.tableConfiguredChartLines = chartLines.filter(item => active.includes(item.id))
    },

    async getChartData() {
      const customDashboardApi = new CustomDashboardApiHandler()
      let result = {}
      try {
        result = await customDashboardApi.getDashboardChart({
          id: this.elementId,
          granulation: this.formModel.period
        })
        result.chartLines = useNormalizeChartLines(result.chartLines)
        this.chartData = result
        result.chartLines = result.chartLines.map(line => {
          line.name = this.title
          return line
        })

        // prepare table data
        const source = this.split ? this.chartData.chartLines[0].childElements : this.chartData

        const { columns, rows } = prepareTable(source)
        this.columns = columns
        this.rows = rows

        // disable the `split` if there are no children available
        const [firstChartLine] = this.chartData.chartLines
        this.showSplitToggle = !isEmpty(firstChartLine.childElements.chartLines)

        this.granulation = this.formModel.period
        this.defaultTimeIntervalsCount = this.formModel.timeIntervalsCount
      } catch (error) {
        handleError({ error })
      }
      if (Object.values(this.storyData).length) {
        result = JSON.parse(JSON.stringify(this.storyData))
        result.chartLines = useNormalizeChartLines(result.chartLines)
        this.chartData = result

        // prepare table data
        const source = this.split ? this.chartData.chartLines[0].childElements : this.chartData

        const { columns, rows } = prepareTable(source)
        this.columns = columns
        this.rows = rows

        // disable the `split` if there are no children available
        const [firstChartLine] = this.chartData.chartLines
        this.showSplitToggle = !isEmpty(firstChartLine.childElements.chartLines)

        this.granulation = this.formModel.period
        this.defaultTimeIntervalsCount = this.formModel.timeIntervalsCount
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.dcwt-Content {
  padding: 0 20px 4px 40px;
}
</style>
