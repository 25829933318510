<template>
  <div class="pfo-Option">
    <AppIcon
      v-if="option.icon"
      :icon-name="option.icon"
      class="pfo-Option_Icon"
      height="24"
      width="24"
    />
    <span class="oboard-truncated-text">{{ option.label }}</span>
  </div>
</template>

<script setup>
import AppIcon from '@/components/ui/AppIcon/AppIcon'

defineOptions({
  name: 'PlatformFilterOption'
})

defineProps({
  option: {
    type: Object,
    required: true
  }
})
</script>

<style lang="scss" scoped>
.pfo-Option {
  display: flex;
  align-items: center;
  gap: 8px;
  max-width: 100%;
}

.pfo-Option_Icon {
  flex-shrink: 0;
}
</style>
