<template>
  <SelectFieldFilter
    :key="`ws-${workspaceId}—ssf-${fieldId}`"
    :field-id="fieldId"
    :filter-value="filterValue"
    :workspace-id="workspaceId"
    @update-filter-value="emit('update-filter-value', $event)"
    @initial-data-loaded="emit('initial-data-loaded')"
  />
</template>

<script setup>
import SelectFieldFilter from '@/components/custom-fields/okr-elements-filters/SelectFieldFilter'

defineOptions({
  name: 'SingleSelectFilter',
  inheritAttrs: false
})

const emit = defineEmits({
  'update-filter-value': null,
  'initial-data-loaded': null
})

defineProps({
  fieldId: {
    type: [String, Number],
    required: true
  },

  workspaceId: {
    type: [String, Number],
    required: true
  },

  filterValue: {
    type: Array,
    required: true
  }
})
</script>

<style lang="scss" scoped></style>
