import { isEqual } from 'lodash'

/*
 * Update query parameter value if value differs from current one.
 *
 * IMPORTANT: Safari has limitation of 100 history updates in 30 seconds, so if you have multiple updates after
 * each other, prefer bulk update(see `replaceQueryParameters`).
 */
export async function updateQueryParameter(router, route, key, value, stringify = true) {
  const resolvedValue = stringify ? JSON.stringify(value) : value
  if (key in route.query && `${route.query[key]}` === resolvedValue) {
    return
  }

  await router.replace({
    query: { ...route.query, [key]: resolvedValue }
  })
}

export const replaceQueryParameters = async (router, route, newParameters) => {
  if (isEqual(newParameters, route.query)) {
    return
  }

  await router.replace({
    query: newParameters
  })
}

/*
 * Delete query parameter if it exists.
 */
export const deleteQueryParameter = async (router, route, key) => {
  if (!(key in route.query)) {
    return
  }

  const query = { ...route.query }
  delete query[key]
  await router.replace({ query })
}
