<template>
  <div class="oe-Empty">
    <div class="oe-Empty_Content">
      <template v-if="showNoObjectivesByFilters">
        <ObjectivesEmptyHero class="oe-EmptyContentHero" name="empty-state-hero" />
        <i18n-t
          :keypath="objectivesEmptyMessage"
          class="oe-Text filters-used"
          scope="global"
          tag="p"
        >
          <template #break>
            <br />
          </template>
        </i18n-t>
        <i18n-t
          v-if="filterValuesEmpty && userCanCreateObjectives"
          class="oe-Text_Subtitle"
          keypath="filter.no_objective_subtitle"
          scope="global"
          tag="div"
        >
          <template #creating>
            <div>
              <OkrElementCreator
                :objective-levels="objectiveLevels"
                :select-options-list-test-id="EMPTY_STATE_OKR_ELEMENT_CREATOR_TEST_ID"
                dropdown-position="bottom"
                @on-create-okr-element="onCreateObjectiveClick"
              >
                <template #trigger="{ triggerSelector }">
                  <AppButton
                    :class="triggerSelector"
                    class="oe-Subtitle_CreateObjective"
                    height="20"
                    remove-padding
                    type="link-next"
                    width="20"
                  >
                    {{ $t('filter.creating_objective') }}
                  </AppButton>
                </template>
              </OkrElementCreator>
            </div>
          </template>
        </i18n-t>

        <AppButton
          class="om-BtnResetFilters"
          size="sm"
          type="link-next"
          @click="$emit('reset-search-criteria')"
        >
          {{ $t('search_criteria.reset') }}
        </AppButton>
      </template>

      <template v-else>
        <ObjectivesEmptyHero class="oe-EmptyContentHero" name="empty-state-hero" />

        <p class="oe-Text">{{ $t('filter.no_objective_title') }}</p>

        <template v-if="userCanCreateObjectives">
          <p class="oe-Text_Subtitle">{{ $t('filter.no_objective_message') }}</p>

          <AppDivider class="oe-Divider" />

          <OkrElementCreator
            :objective-levels="objectiveLevels"
            :select-options-list-test-id="EMPTY_STATE_BY_INTERVALS_OKR_ELEMENT_CREATOR_TEST_ID"
            dropdown-position="bottom"
            @on-create-okr-element="onCreateObjectiveClick"
          >
            <template #trigger="{ triggerSelector }">
              <AppButton
                :class="triggerSelector"
                height="24"
                icon="plus-next"
                type="primary-next"
                width="24"
              >
                {{ $t('action.create_objective') }}
              </AppButton>
            </template>
          </OkrElementCreator>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import { isEmpty } from 'lodash'
import { defineComponent } from 'vue'

import ObjectivesEmptyHero from '@/components/objectives/ObjectivesEmptyHero'
import OkrElementCreator from '@/components/objectives/OkrElementCreator'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppDivider from '@/components/ui/AppDivider'
import {
  EMPTY_STATE_BY_INTERVALS_OKR_ELEMENT_CREATOR_TEST_ID,
  EMPTY_STATE_OKR_ELEMENT_CREATOR_TEST_ID
} from '@/components/ui/AppSelect/jest-helpers'

export default defineComponent({
  name: 'ObjectivesEmpty',

  components: {
    AppDivider,
    ObjectivesEmptyHero,
    OkrElementCreator,
    AppButton
  },

  props: {
    filtersUsed: {
      type: Boolean
    },

    userCanCreateObjectives: {
      type: Boolean
    },

    objectiveLevels: {
      type: Array,
      default: () => []
    },

    filterValues: {
      type: Object,
      default: () => ({})
    },

    disableTopMargin: {
      type: Boolean
    }
  },

  emits: { mounted: null, 'create-objective': null, 'reset-search-criteria': null },

  computed: {
    EMPTY_STATE_OKR_ELEMENT_CREATOR_TEST_ID: () => EMPTY_STATE_OKR_ELEMENT_CREATOR_TEST_ID,
    EMPTY_STATE_BY_INTERVALS_OKR_ELEMENT_CREATOR_TEST_ID: () =>
      EMPTY_STATE_BY_INTERVALS_OKR_ELEMENT_CREATOR_TEST_ID,

    objectivesEmptyMessage() {
      return this.filterValuesEmpty ? 'filter.no_objective' : 'filter.no_objective_jira_dashboard'
    },

    filterValuesEmpty() {
      return isEmpty(this.filterValues)
    },

    showNoObjectivesByFilters() {
      return this.filterValuesEmpty ? this.filtersUsed : true
    },

    marginTop() {
      return this.disableTopMargin ? 0 : '84px'
    }
  },

  mounted() {
    this.$emit('mounted')
  },

  methods: {
    onCreateObjectiveClick(name) {
      this.$emit('create-objective', {
        typeId: name
      })
    }
  }
})
</script>

<style lang="scss" scoped>
.oe-Empty {
  width: 100%;
  height: auto;
  margin-top: v-bind(marginTop);
}

.oe-Empty_Content {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  padding: 0 16px;
  margin: 0 auto;

  .oe-Text {
    font-family: $system-ui;
    margin-bottom: 16px;
    font-weight: fw('medium');
    font-size: $fs-32;
    line-height: 40px;
    text-align: center;
    color: $dark-1;
    &_Subtitle {
      font-weight: fw('regular');
      font-size: $fs-14;
      line-height: 20px;
      text-align: center;
      color: $dark-3;
      display: flex;
      align-items: center;
      gap: 6px;
      font-family: $system-ui;
      margin-bottom: 16px;
    }
  }
}

.oe-Divider {
  margin: 0 auto 24px;
  width: 100%;
  max-width: 600px;
}

.oe-EmptyContentHero {
  margin-bottom: 49px;
}
.oe-Subtitle_CreateObjective {
  font-family: $system-ui;
  line-height: 20px;
}
</style>
