<template>
  <FormFieldNext :disabled="disabled" :style="styles" class="of-Wrapper">
    <template #label>
      <div class="of-Label">
        {{ t('custom_fields.options') }}
        <AppDivider :is-error="!validationStatus[REQUIRED_STATUS]" class="of-Divider" no-margin />
      </div>
    </template>
    <SlickList
      v-if="!isEmpty(localOptions)"
      :class="{ 'of-Options-disabled': disabled }"
      :distance="10"
      :dragged-settling-duration="1"
      :list="localOptions"
      :lock-offset="['5%', '5%']"
      :transition-duration="200"
      class="of-Options"
      helper-class="of-Option-grabbing"
      lock-axis="y"
      lock-to-container-edges
      use-drag-handle
      @sort-end="onSortEnd({ ...$event })"
    >
      <SlickItem
        v-for="(option, index) in localOptions"
        :key="option.id"
        :disabled="disabled"
        :index="index"
        class="of-Option"
      >
        <AppIcon v-handle class="of-Drag" height="24" icon-name="drag-next" width="24" />
        <AppInput
          v-model="option.name"
          :data-testid="CUSTOM_FIELD_MODAL_OPTIONS_FIELD_OPTION_INPUT_TEST_ID"
          :is-error="isShowOptionError(option.id)"
          :placeholder="getPlaceholder(option.id)"
          :readonly="disabled"
          max-length="20"
          show-error-for-placeholder
          size="xlg"
          style-type="primary"
          @focus="removeError(option.id)"
        />

        <AppButton
          v-if="!disabled"
          :data-testid="CUSTOM_FIELD_MODAL_OPTIONS_FIELD_OPTION_REMOVE_BUTTON_TEST_ID"
          class="of-RemoveOption"
          icon="close-sm"
          size="sm"
          type="ghost"
          @click="onClearClick(option.id)"
        />
      </SlickItem>
    </SlickList>

    <AppFieldError
      v-if="!validationStatus[REQUIRED_STATUS]"
      :data-testid="CUSTOM_FIELD_MODAL_OPTIONS_FIELD_ERROR_TEST_ID"
      :show="!validationStatus[REQUIRED_STATUS]"
    >
      {{ $t('field.required') }}
    </AppFieldError>

    <AppInfoMessage
      v-if="!isEmpty(localOptions)"
      :type="INFO_MESSAGE_TYPES.WARNING"
      class="cfm-Message"
    >
      <i18n-t :keypath="fieldMessageKeyPath" scope="global">
        <template #break>
          <br />
        </template>
      </i18n-t>
    </AppInfoMessage>
    <AppTableCreateButton
      v-if="isShowAddButton"
      :data-testid="CUSTOM_FIELD_MODAL_OPTIONS_FIELD_ADD_BUTTON_TEST_ID"
      icon-name="plus-next"
      no-hover
      style="--padding-left: 0"
      @click="addNewOption"
    >
      {{ t('custom_fields.options.add') }}
    </AppTableCreateButton>
  </FormFieldNext>
</template>

<script setup>
import { cloneDeep, has, isEmpty, isNull } from 'lodash'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { SlickItem, SlickList, HandleDirective as vHandle } from 'vue-slicksort'

import { INFO_MESSAGE_TYPES } from '@/utils/components-configurations/app-info-message'
import { ADDITIONAL_PARAMS_SETTINGS } from '@/utils/custom-fields/factory'
import { MAX_OPTIONS_COUNT, REQUIRED_STATUS } from '@/utils/custom-fields/helpers'
import {
  CUSTOM_FIELD_MODAL_OPTIONS_FIELD_ADD_BUTTON_TEST_ID,
  CUSTOM_FIELD_MODAL_OPTIONS_FIELD_ERROR_TEST_ID,
  CUSTOM_FIELD_MODAL_OPTIONS_FIELD_OPTION_INPUT_TEST_ID,
  CUSTOM_FIELD_MODAL_OPTIONS_FIELD_OPTION_REMOVE_BUTTON_TEST_ID
} from '@/utils/custom-fields/jest-helpers'
import { uid } from '@/utils/uid'

import AppFieldError from '@/components/form/AppFieldError'
import FormFieldNext from '@/components/form/FormFieldNext'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppDivider from '@/components/ui/AppDivider'
import AppIcon from '@/components/ui/AppIcon/AppIcon'
import AppInfoMessage from '@/components/ui/AppInfoMessage'
import AppInput from '@/components/ui/AppInput/AppInput'
import AppTableCreateButton from '@/components/ui/AppTableCreateButton'

const props = defineProps({
  modelValue: {
    type: Array,
    required: true
  },

  validationStatus: {
    type: Object,
    required: true
  },

  disabled: {
    type: Boolean
  }
})

const emit = defineEmits({
  'update:model-value': null,
  'remove-error': null
})

const removeError = (errorKey = undefined) => {
  emit('remove-error', {
    fieldKey: ADDITIONAL_PARAMS_SETTINGS.OPTIONS.typeId,
    errorKey
  })
}

const localOptions = computed({
  get: () => props.modelValue,
  set: value => updateOptions(value)
})

const isShowOptionError = id => {
  return has(props.validationStatus, id) && !props.validationStatus[id]
}

const { t } = useI18n()
const getPlaceholder = id => {
  return isShowOptionError(id) ? t('field.required') : t('custom_fields.options.placeholder')
}

const addNewOption = () => {
  const newOption = {
    id: uid(),
    name: ''
  }

  updateOptions([
    ...props.modelValue,
    {
      ...newOption
    }
  ])

  removeError()
}

const onClearClick = id => {
  updateOptions(props.modelValue.filter(option => option.id !== id))
  removeError(id)
}

const onSortEnd = ({ oldIndex, newIndex }) => {
  if (isNull(newIndex) || oldIndex === newIndex) {
    return
  }

  const copy = cloneDeep(props.modelValue)

  copy.splice(newIndex, 0, copy.splice(oldIndex, 1)[0])

  updateOptions(copy)
}

const updateOptions = (options = []) => emit('update:model-value', options)

const styles = computed(() => {
  if (isEmpty(props.modelValue)) {
    return {}
  }

  return {
    '--gap': '20px'
  }
})

const fieldMessageKeyPath = computed(() => {
  return props.disabled
    ? 'custom_fields.warning.options.edit'
    : 'custom_fields.warning.options.create'
})

const isShowAddButton = computed(() => {
  return !props.disabled && props.modelValue.length < MAX_OPTIONS_COUNT
})
</script>

<style lang="scss" scoped>
.of-Wrapper {
  user-select: none;
}

.of-Divider {
  margin-top: 6px;
}
.of-Option {
  position: relative;
  display: grid;
  align-items: center;
  gap: 8px;
  grid-template-columns: auto 1fr;
}

.of-Drag {
  cursor: grab;

  .of-Options-disabled & {
    cursor: default;
  }

  .of-Option-grabbing & {
    cursor: grabbing;
  }
}

.of-RemoveOption {
  position: absolute;
  top: 50%;
  right: -24px;
  transform: translate3d(0, -50%, 0);
  color: $grey-1-next;
}
// eslint-disable-next-line vue-scoped-css/no-unused-selector
.of-Option-grabbing {
  cursor: grabbing;
}

.of-Options {
  display: grid;
  gap: inherit;
}
</style>

<style lang="scss">
.of-Option-grabbing {
  z-index: 10000;
  pointer-events: all !important;
}
</style>
