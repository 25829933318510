<template>
  <div class="vs-ViewSettings">
    <AppRadioGroup
      :model-value="treeType"
      :options="treeTypes"
      class="vs-CardViewOptions"
      name="tree-type-switch"
      style="--option-padding: 0"
      type="primary-next"
      @update:model-value="setActiveView"
    >
      <template #item-label="{ item }">
        <AppIcon :icon-name="item.icon" class="vs-CardViewOptionIcon" height="24" width="24" />
      </template>
    </AppRadioGroup>

    <div class="vs-ViewSettings_ButtonsGroup">
      <AppButton
        v-for="button in EXPAND_COLLAPSE_BUTTONS"
        :key="button.icon"
        v-tippy="{
          content: $t(button.tooltip)
        }"
        :disable="disableZoom"
        :loading="isLoading"
        class="vs-ViewSettings_Button"
        loading-circle-color="var(--dark-2)"
        remove-padding
        type="tertiary-next"
        @click="$emit(button.event)"
      >
        <AppIcon v-if="!isLoading" :icon-name="button.icon" height="24" width="24" />
      </AppButton>
    </div>

    <AppDroplist
      v-model="showLegend"
      :theme="DROP_LIST_THEMES.TRANSLUCENT_NEXT"
      arrow
      position="top"
      trigger="mouseenter click"
    >
      <template #button>
        <AppButton
          :class="{ 'vs-ViewSettings_Button-active': showLegend }"
          class="vs-ViewSettings_Button"
          icon="info-next"
          remove-padding
          type="tertiary-next"
        />
      </template>

      <div class="vs-Legend">
        <div class="vs-Legend_Title">
          {{ $t('tree.legend') }}
        </div>

        <ul class="vs-Legend_List">
          <li v-for="item in LEGEND_ITEMS" :key="item.icon" class="vs-LegendListItem">
            <AppIcon :icon-name="item.icon" class="vs-LegendListItem_Icon" height="24" width="24" />
            <span class="vs-LegendListItem_Label">
              {{ $t(item.label) }}
            </span>
          </li>
        </ul>
      </div>
    </AppDroplist>

    <AppButton
      v-tippy="{
        content: $t('tree.fit_content_size')
      }"
      :disable="disableZoom"
      class="vs-ViewSettings_Button"
      icon="fit-screen"
      remove-padding
      type="tertiary-next"
      @click="$emit('overview')"
    />

    <div class="vs-ViewSettings_ButtonsGroup">
      <AppButton
        :disable="zoom <= min || disableZoom"
        class="vs-ViewSettings_Button"
        icon="minus-small"
        remove-padding
        type="tertiary-next"
        @click="$emit('zoom', zoom - step)"
      />

      <AppButton
        :disable="disableZoom"
        class="vs-ViewSettings_Button vs-ViewSettings_Button-reset"
        remove-padding
        type="tertiary-next"
        @click="$emit('zoom', 100)"
      >
        {{ zoom }}%
      </AppButton>

      <AppButton
        :disable="zoom >= max || disableZoom"
        class="vs-ViewSettings_Button"
        icon="plus-small"
        remove-padding
        type="tertiary-next"
        @click="$emit('zoom', zoom + step)"
      />
    </div>

    <AppButton
      :icon="fullscreenIcon"
      class="vs-ViewSettings_Button"
      data-testid="fullscreen-button"
      remove-padding
      type="tertiary-next"
      @click="$emit('fullscreen')"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapState } from 'vuex'

import { DROP_LIST_THEMES } from '@/utils/components-configurations/app-droplist'
import { TREE_TYPES } from '@/utils/okr-map'

import AppDroplist from '@/components/AppDroplist'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppIcon from '@/components/ui/AppIcon/AppIcon'
import AppRadioGroup from '@/components/ui/AppRadioGroup/AppRadioGroup'

const LEGEND_ITEMS = [
  {
    icon: 'mindmap-legend-expand',
    label: 'tree.legend.show_child_items'
  },
  {
    icon: 'mindmap-legend-eye',
    label: 'tree.legend.show_filtered_items'
  },
  {
    icon: 'mindmap-legend-eye-off',
    label: 'tree.legend.hide_filtered_items'
  },
  {
    icon: 'mindmap-legend-contribute',
    label: 'field.contribute.label'
  },
  {
    icon: 'mindmap-legend-contribute-off',
    label: 'tree.legend.not_contribute'
  }
]

const EXPAND_COLLAPSE_BUTTONS = [
  {
    tooltip: 'filter.expand_all',
    icon: 'table-expand-next',
    event: 'expand-all'
  },
  {
    tooltip: 'filter.collapse_all',
    icon: 'table-collapse-next',
    event: 'collapse-all'
  }
]

export default defineComponent({
  name: 'ViewSettings',

  components: {
    AppIcon,
    AppDroplist,
    AppRadioGroup,
    AppButton
  },

  props: {
    zoom: {
      type: Number,
      required: true
    },

    min: {
      type: Number,
      default: 10
    },

    max: {
      type: Number,
      default: 200
    },

    step: {
      type: Number,
      default: 10
    },

    disableZoom: {
      type: Boolean
    },

    isLoading: {
      type: Boolean
    },

    treeType: {
      type: String,
      required: true
    }
  },

  emits: {
    fullscreen: null,
    zoom: null,
    overview: null,
    'collapse-all': null,
    'expand-all': null,
    'update-tree-type': null
  },

  data() {
    return {
      showLegend: false
    }
  },

  computed: {
    ...mapState('system', {
      fullscreen: 'fullscreen'
    }),

    DROP_LIST_THEMES: () => DROP_LIST_THEMES,

    EXPAND_COLLAPSE_BUTTONS: () => EXPAND_COLLAPSE_BUTTONS,

    fullscreenIcon() {
      return this.fullscreen ? 'full-screen-off-next' : 'full-screen-on-next'
    },

    treeTypes() {
      return Object.values(TREE_TYPES)
    },

    LEGEND_ITEMS: () => LEGEND_ITEMS
  },

  methods: {
    setActiveView(type) {
      this.$emit('update-tree-type', type)
    }
  }
})
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';
@import '~@/assets/styles/mind-road-map-footer';

.vs-ViewSettings {
  @extend %actions-wrapper;
}

.vs-ViewSettings_Button {
  @extend %button-styles;

  &-active {
    @extend %active-button-styles;
  }

  &-reset {
    min-width: 52px;
    @extend %center-button;
  }
}

.vs-ViewSettings_ButtonsGroup {
  @extend %buttons-group-styles;
}

.vs-Legend {
  padding: 8px 0;
  font-family: $system-ui;
  font-style: normal;
  font-size: $fs-14;
  line-height: 20px;
  width: 200px;
  white-space: normal;
}

.vs-Legend_Title {
  font-weight: fw('semi-bold');
  color: $white;
  margin-bottom: 15px;
}

.vs-Legend_List {
  margin: 0;
  list-style: none;
  color: $grey-2-next;
  font-weight: fw('regular');
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.vs-LegendListItem {
  display: flex;
  align-items: flex-start;
  gap: 8px;
}

.vs-LegendListItem_Icon {
  flex: 0 0 auto;
}

.vs-LegendListItem_Label {
  padding-top: 2px;
}

.vs-CardViewOptions {
  &:deep(.arg-Option) {
    display: flex;
  }
}

.vs-CardViewOptionIcon {
  display: flex;
}
</style>
