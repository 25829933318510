export const useNormalizeChartLines = chartLines => {
  return chartLines.map(cl => {
    if (cl.childElements) {
      cl.childElements.chartLines = useNormalizeChartLines(cl.childElements.chartLines)
    }
    return {
      ...cl,
      data: Object.fromEntries(Object.entries(cl.data).filter(([, y]) => y !== null))
    }
  })
}
