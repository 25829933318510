<template>
  <div class="asm-Links">
    <a
      v-for="link in Object.values(LINKS)"
      :key="link.icon"
      :href="link.url"
      :style="{ '--color': link.color }"
      class="asm-Links_Item"
      target="_blank"
    >
      <AppIcon :icon-name="link.icon" height="24" width="24" />
    </a>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AppSocialMediaLinks'
})
</script>

<script setup>
import AppIcon from '@/components/ui/AppIcon/AppIcon'

const LINKS = {
  // FACEBOOK: {
  //   icon: 'facebook',
  //   color: '#1B74E4',
  //   url: 'https://www.facebook.com/oboard.okr.board'
  // },
  //
  // TWITTER: {
  //   icon: 'twitter',
  //   url: 'https://twitter.com/oboardio',
  //   color: '#1d9bf0'
  // },

  LINKEDIN: {
    icon: 'linkedin',
    url: 'https://www.linkedin.com/company/oboard-plugin-for-jira/',
    color: '#0a66c2'
  },

  YOUTUBE: {
    icon: 'youtube',
    url: 'https://www.youtube.com/@oboard',
    color: '#ff0000'
  }
}
</script>

<style lang="scss" scoped>
.asm-Links {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--gap, 40px);
}

.asm-Links_Item {
  display: flex;
  transition: color $transition-fast ease-in-out;
  text-decoration: none;
  color: var(--main-color, #{$dark-3});
  @media (any-hover: hover) {
    &:hover {
      color: var(--color);
    }
  }
}
</style>
