import { isEmpty } from 'lodash'

import { OKR_FORM_VIEWS } from '@/utils/objectives'

const DEFAULT_PORTAL_NAME = 'immediate-parent'

export const PORTAL_NAMES = {
  [OKR_FORM_VIEWS.OBJECTIVE]: `${DEFAULT_PORTAL_NAME}-objective`,
  [OKR_FORM_VIEWS.KR]: `${DEFAULT_PORTAL_NAME}-kr`,
  [OKR_FORM_VIEWS.LINK_JIRA_ISSUE]: `${DEFAULT_PORTAL_NAME}-task`
}

export const getPortalName = (view, isEdit = true) => {
  if (view === OKR_FORM_VIEWS.OBJECTIVE && !isEdit) {
    return `${PORTAL_NAMES[OKR_FORM_VIEWS.OBJECTIVE]}-create`
  }

  if (view === OKR_FORM_VIEWS.KR && !isEdit) {
    return `${PORTAL_NAMES[OKR_FORM_VIEWS.KR]}-create`
  }

  return PORTAL_NAMES[view]
}

export const getBreadcrumbsDepth = (breadcrumbs, isZeroDepth = false) => {
  if (isZeroDepth) {
    return { '--depth': 0 }
  }
  // const depth = isEmpty(breadcrumbs) ? 0 : breadcrumbs.length - 1 || 1
  const depth = isEmpty(breadcrumbs) ? 1 : breadcrumbs.length - 1 || 1 // 1 because we also have a root breadcrumb
  return { '--depth': depth }
}
