<template>
  <div v-show="false" />
</template>

<script>
import { defineComponent } from 'vue'
import { mapState, mapGetters } from 'vuex'

import ObjectivesInfoApiHandler from '@/api/okr-elements'
import { tracker } from '@/tracking/amplitude'
import { EVENT_CATEGORIES } from '@/tracking/amplitude-helpers'
import { trackJiraIssueLinkedEvent } from '@/tracking/gtm-helpers'
import { COMPANY_SETTINGS_ENTITY_KEYS } from '@/utils/entity-keys'
import { handleError } from '@/utils/error-handling'
import { OBJECTIVE_TYPES } from '@/utils/objective-types'
import { createUsersList, createGroupsList } from '@/utils/objectives'
import { getNavigationTabName } from '@/utils/tracking'
import { JIRA_CLOUD_API, JIRA_SERVER_API } from '@jira/util'

const objectivesApi = new ObjectivesInfoApiHandler()

export default defineComponent({
  name: 'CreateJiraIssue',
  props: {
    source: {
      type: String,
      default: ''
    }
  },

  emits: { save: null },

  computed: {
    ...mapState('system', {
      userData: state => state.userData,
      settings: state => state.settings
    }),

    ...mapGetters('objectives', {
      getLevelItem: 'getLevelItem'
    }),

    ...mapGetters('system', {
      userGroupsByWorkspace: 'userGroupsByWorkspace'
    })
  },

  methods: {
    /** @public */
    create(parentObjective) {
      // check server API
      if (JIRA_SERVER_API && JIRA_SERVER_API.Forms) {
        const issueForm = JIRA_SERVER_API.Forms.createCreateIssueForm()
        issueForm.bind('sessionComplete', (ev, issues) => {
          this.onJiraIssuesCreated(issues, parentObjective)
        })

        issueForm.asDialog().show()
      } else {
        console.warn('Fail to found API to create Jira issue in Jira Server')
      }

      // check cloud API
      if (!JIRA_CLOUD_API) {
        return
      }
      JIRA_CLOUD_API.jira.openCreateIssueDialog(async issues => {
        await this.onJiraIssuesCreated(issues, parentObjective)
      })
    },

    async onJiraIssuesCreated(issues, parentObjective) {
      if (!issues || !issues.length) {
        return
      }
      const promises = []
      const ownerId = this.userData?.userAccountId || null
      const groups = this.userGroupsByWorkspace(parentObjective.workspaceId) || []

      issues.forEach(issue => {
        let name = ''
        let jiraIssueId = ''
        // recognize cloud by AP and server by JIRA
        if (JIRA_CLOUD_API && issue && issue.fields && issue.fields) {
          name = `${issue.key} ${issue.fields.summary}`
          jiraIssueId = issue.key
        } else if (JIRA_SERVER_API && issue && issue.createdIssueDetails) {
          name = issue.createdIssueDetails.fields
            ? `${issue.createdIssueDetails.key} ${issue.createdIssueDetails.fields.summary}`
            : ''
          jiraIssueId = issue.createdIssueDetails.key
        }

        const payload = {
          contribute: this.settings[COMPANY_SETTINGS_ENTITY_KEYS.TASK_CONTRIBUTE_BY_DEFAULT],
          jiraIssueIds: [jiraIssueId],
          name,
          parentId: parentObjective.id,
          users: createUsersList({ ownerId }),
          groups: createGroupsList(groups),
          workspaceId: parentObjective.workspaceId,
          dueDate: parentObjective.dueDate,
          intervalId: parentObjective.intervalId,
          levelId: this.getLevelItem('typeId', OBJECTIVE_TYPES.TASK).id
        }

        const promise = this.save(payload)
        promises.push(promise)
      })

      const objectives = await Promise.all(promises)
      this.$emit(
        'save',
        objectives.filter(objective => objective !== null)
      )
    },

    async save(payload) {
      let task = null
      const resolvedPayload = {
        ...payload,
        // undefined values needed to clear this fields in create request
        targetValue: undefined,
        originalValue: undefined,
        currentValue: undefined
      }
      try {
        task = await objectivesApi.createOkrElement(resolvedPayload)
        if (task.elements.length > 0) {
          tracker.logEvent('Linked Jira-issue', {
            category: EVENT_CATEGORIES.OKR_MANAGEMENT,
            label: task.elements[0].issueType,
            event_ref: 'Plugin',
            source: this.source,
            contribution: task.elements[0].contribute ? 'Yes' : 'No',
            tab: getNavigationTabName(this.$route),
            ent_id: task.elements[0].displayId
          })

          trackJiraIssueLinkedEvent()
        }
      } catch (error) {
        handleError({ error })
      }

      return task
    }
  }
})
</script>

<style></style>
