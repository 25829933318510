<template>
  <FormFieldNext :disabled="!userHasUpdateAccess" style="--gap: 0">
    <template #label>
      <CustomFieldLabel :field-id="fieldId" />

      <AppDivider class="lf-Divider" no-margin />
    </template>

    <div
      v-if="!isEmpty(modelValue)"
      :class="{
        'lf-LinksList-disabled': !userHasUpdateAccess
      }"
      :data-auto-testid="LINKS_FORM_FIELD_TEST_ID"
      :data-testid="LINKS_FORM_FIELD_TEST_ID"
      class="lf-LinksList"
    >
      <div v-for="link in modelValue" :key="link.uid || link.id" class="lf-LinksList_Item">
        <a :href="link.url" class="lf-Link" target="_blank">
          <AppIcon class="lf-Link_Icon" height="24" icon-name="link" width="24" />
          <span class="lf-Link_Text oboard-truncated-text">
            {{ getLinkName(link) }}
          </span>
        </a>

        <div class="lf-Actions">
          <AppButton
            v-for="item in linksActions"
            :key="item.action"
            v-tippy="{
              content: item.tooltip,
              placement: 'top'
            }"
            :icon="item.icon"
            size="sm"
            type="tertiary-next"
            @click="handleLinkAction({ action: item.action, id: link.id })"
          />
        </div>
      </div>
    </div>

    <AppDroplist
      v-if="!isLinksLimitExceeded && userHasUpdateAccess"
      v-model="isShowAddLinkDropdown"
      :hide-on-click="false"
      :offset="[0, 0]"
      max-width="unset"
      position="bottom-end"
      trigger="manual"
      width-as-parent
      @close="onCloseAddLinkDropdown"
    >
      <template #button>
        <AppTableCreateButton
          :data-auto-testid="LINKS_FORM_FIELD_ADD_BUTTON_TEST_ID"
          :data-testid="LINKS_FORM_FIELD_ADD_BUTTON_TEST_ID"
          class="lf-AddLinkButton"
          full-width
          icon-name="plus-next"
          no-hover
          style="--padding-left: 0; --padding-bottom: 0"
          @click="addNewLink"
        >
          {{ $t('action.add_link') }}
        </AppTableCreateButton>
      </template>

      <div ref="addLinkDropdown" class="lf-AddLinkDropdown">
        <AppInput
          v-model.trim="linkFormModel.url"
          :max-length="2048"
          :placeholder="$t('action.paste_link')"
          icon="link"
          size="xlg"
          style-type="primary"
        />

        <AppInput
          v-model.trim="linkFormModel.name"
          :max-length="20"
          :placeholder="$t('custom_fields.link.name.placeholder')"
          icon="cf-single-line"
          size="xlg"
          style-type="primary"
        />

        <div class="lf-AddLinkDropdown_Controls">
          <AppButton type="ghost-next" @click="onCloseAddLinkDropdown">
            {{ $t('action.cancel') }}
          </AppButton>

          <AppButton :disable="isCreateLinkDisabled" type="primary-next" @click="onAddLink">
            {{ $t('action.add_link') }}
          </AppButton>
        </div>
      </div>
    </AppDroplist>

    <AppInfoMessage
      v-if="isLinksLimitExceeded && userHasUpdateAccess"
      :type="INFO_MESSAGE_TYPES.WARNING"
      class="lf-Message"
    >
      {{
        t('custom_fields.link.limit_exceeded', {
          limit: LINKS_MAX_COUNT
        })
      }}
    </AppInfoMessage>
  </FormFieldNext>
</template>

<script setup>
import { onClickOutside } from '@vueuse/core'
import { isEmpty } from 'lodash'
import { computed, nextTick, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { ACTIONS_KEYS } from '@/utils/actions-keys'
import { INFO_MESSAGE_TYPES } from '@/utils/components-configurations/app-info-message'
import {
  LINKS_FORM_FIELD_ADD_BUTTON_TEST_ID,
  LINKS_FORM_FIELD_TEST_ID
} from '@/utils/custom-fields/jest-helpers'
import { copyToClipboard, isStringEmpty } from '@/utils/general'
import { showNotify } from '@/utils/notify'
import { uid } from '@/utils/uid'

import AppDroplist from '@/components/AppDroplist'
import CustomFieldLabel from '@/components/custom-fields/okr-elements-form/CustomFieldLabel'
import FormFieldNext from '@/components/form/FormFieldNext'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppDivider from '@/components/ui/AppDivider'
import AppIcon from '@/components/ui/AppIcon/AppIcon'
import AppInfoMessage from '@/components/ui/AppInfoMessage'
import AppInput from '@/components/ui/AppInput/AppInput'
import AppTableCreateButton from '@/components/ui/AppTableCreateButton'

defineOptions({
  name: 'LinksField'
})

const props = defineProps({
  userHasUpdateAccess: {
    type: Boolean,
    required: true
  },

  fieldId: {
    type: [String, Number],
    required: true
  },

  modelValue: {
    type: Array,
    required: true
  }
})

const DEFAULT_LINK = {
  name: '',
  url: ''
}

const LINKS_MAX_COUNT = 30

const { t } = useI18n()

const ACTIONS = [
  {
    action: ACTIONS_KEYS.COPY_LINK,
    icon: 'copy-standard',
    tooltip: t('breadcrumb.copy_link')
  },
  {
    action: ACTIONS_KEYS.DELETE,
    icon: 'delete-next',
    checkUpdatePermission: true,
    tooltip: null
  }
]

const linksActions = computed(() => {
  return ACTIONS.filter(action => {
    if (action.checkUpdatePermission) {
      return props.userHasUpdateAccess
    }
    return true
  })
})

const isLinksLimitExceeded = computed(() => props.modelValue.length >= LINKS_MAX_COUNT)

const isShowAddLinkDropdown = ref(false)

const linkFormModel = ref({
  ...DEFAULT_LINK
})
const onCloseAddLinkDropdown = () => {
  isShowAddLinkDropdown.value = false
  nextTick(() => {
    linkFormModel.value = {
      ...DEFAULT_LINK
    }
  })
}
const addNewLink = () => {
  isShowAddLinkDropdown.value = !isShowAddLinkDropdown.value
}

const addLinkDropdown = ref(null)

onClickOutside(addLinkDropdown, e => {
  if (isShowAddLinkDropdown.value) {
    if (Array.from(e.composedPath()).some(item => item.classList?.contains('lf-AddLinkButton'))) {
      return
    }
    onCloseAddLinkDropdown()
  }
})

const isCreateLinkDisabled = computed(() => {
  return isStringEmpty(linkFormModel.value.url)
})

const onAddLink = async () => {
  const newLink = {
    ...linkFormModel.value,
    uid: uid()
  }
  updateLinksList({
    links: [...props.modelValue, newLink]
  })
  onCloseAddLinkDropdown()
}

const getLinkName = link => link.name.trim() || link.url

const onCopyLink = ({ id }) => {
  const link = props.modelValue.find(link => link.id === id)
  if (link) {
    copyToClipboard(link.url)
    showNotify({ title: t('notifications.link_copied') })
  }
}

const onDeleteLink = ({ id }) => {
  updateLinksList({
    links: props.modelValue.filter(link => link.id !== id)
  })
}

const handleLinkAction = ({ action, id }) => {
  const [method] = [
    action === ACTIONS_KEYS.COPY_LINK && onCopyLink,
    action === ACTIONS_KEYS.DELETE && onDeleteLink
  ].filter(Boolean)

  method({ id })
}

const emit = defineEmits({
  'update:model-value': null
})
const updateLinksList = ({ links }) => {
  // Keep BE created ids to prevent recreation of existed links
  // remove id and uid key for link created here
  emit('update:model-value', {
    fieldId: props.fieldId,
    value: links.map(link => {
      const { id, name, url } = link
      const result = {
        name: name.trim(),
        url: url.trim()
      }

      if (id) {
        result.id = id
      }

      return result
    })
  })
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';

.lf-Divider {
  margin-top: 6px;
}

.lf-AddLinkDropdown {
  padding: 20px;
  display: grid;
  gap: 10px;
}

.lf-LinksList {
  display: flex;
  flex-direction: column;
  margin: 0 calc(var(--group-padding) * -1);
}

.lf-LinksList_Item {
  display: flex;
  align-items: center;
  gap: 8px;
  overflow: hidden;

  padding: 0 var(--group-padding);
  transition: background-color $transition-fast;
  position: relative;

  &:after {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    content: '';
    @include tableLinesGradient(
      transparent,
      $grey-2-next,
      var(--group-padding),
      var(--group-padding)
    );
  }

  @media (any-hover: hover) {
    &:hover {
      background-color: $grey-3-next;
    }
  }

  &:last-child {
    .lf-LinksList-disabled & {
      &:after {
        display: none;
      }
    }
  }
}

.lf-Link {
  display: flex;
  align-items: center;
  gap: inherit;
  overflow: hidden;
  min-height: 45px;

  max-width: 100%;
}

.lf-Link_Icon {
  flex: 0 0 24px;
}

.lf-AddLinkDropdown_Controls {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
}

.lf-Actions {
  flex-shrink: 0;
  display: none;
  margin-left: auto;
  gap: inherit;

  .lf-LinksList_Item:hover & {
    display: flex;
  }
}

.lf-Message {
  margin-top: 10px;
}
</style>
