<template>
  <AppDroplist v-model="opened" :offset="[0, 0]" position="top-end">
    <template #button>
      <DescriptionButton v-if="showButtonTriggerButton" :active="opened" icon="emoji" />
    </template>
    <div class="aep-Wrapper">
      <div class="aep-Navbar">
        <AppButton
          v-for="(emojiCategory, emojiCategoryIndex) in EMOJI_LIST"
          :key="emojiCategory.category"
          :class="{
            'aep-NavButton-active':
              getCategoryName(emojiCategoryIndex) === activeCategory && !searchString
          }"
          :disable="!!searchString"
          :icon="emojiCategory.icon"
          class="aep-NavButton"
          height="24"
          remove-padding
          size="sm"
          type="ghost"
          width="24"
          @click="scrollToCategory(getCategoryName(emojiCategoryIndex))"
        />
      </div>

      <AppSearch v-model="searchString" class="aep-Search" width="100%" />

      <ul ref="categoryListTarget" class="aep-CategoryList">
        <li
          v-for="(emojiCategory, emojiCategoryIndex) in getEmojiList"
          :id="getCategoryName(emojiCategoryIndex)"
          :key="emojiCategory.category"
          ref="categoryListRef"
          class="aep-CategoryList_Item"
        >
          <AppTitle
            :class="{ 'aep-CategoryList_Title-no-result': isNoResults }"
            :level="5"
            class="aep-CategoryList_Title"
            disable-margin
          >
            {{ emojiCategory.category }}
          </AppTitle>
          <ul class="aep-CategoryList_Emojis">
            <li
              v-for="item in emojiCategory.data"
              :key="item.emoji"
              class="aep-Emojis_Item"
              @click="selectEmoji(item.emoji)"
            >
              {{ item.emoji }}
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </AppDroplist>
</template>

<script setup>
import { flatten, isEmpty } from 'lodash'
import { computed, onBeforeUnmount, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import { EMOJI_LIST } from '@/utils/emoji-list'

import AppDroplist from '@/components/AppDroplist'
import DescriptionButton from '@/components/objectives/forms/DescriptionButton'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppTitle from '@/components/ui/AppTitle/AppTitle'
import AppSearch from '@/components/ui/Search/Search'

defineProps({
  showButtonTriggerButton: {
    type: Boolean,
    default: true
  }
})
const opened = ref(false)
const emit = defineEmits({
  'select-emoji': null
})

const getCategoryName = id => `category-${id}`

const DEFAULT_CATEGORY_INDEX = 0

const categoryListTarget = ref(null)
const activeCategory = ref(getCategoryName(DEFAULT_CATEGORY_INDEX))
const categoryListRef = ref([])
const searchString = ref('')
const { t } = useI18n()

const isNoResults = computed(() => isEmpty(fastSearchEmoji.value))

const getEmojiList = computed(() => {
  if (searchString.value) {
    const category = isNoResults.value
      ? t('placeholder.no_results')
      : t('placeholder.search_results')
    return [{ category, data: fastSearchEmoji.value }]
  } else {
    return EMOJI_LIST
  }
})

const fastSearchEmoji = computed(() => {
  const query = searchString.value.toLowerCase()

  return getNormalizedData.value.filter(emoji => {
    return emoji.searchQuery.includes(query)
  })
})

const getNormalizedData = computed(() => {
  return flatten(EMOJI_LIST.map(emoji => emoji.data))
})

defineExpose({
  opened
})

const selectEmoji = emoji => {
  emit('select-emoji', emoji)
}
const scrollToCategory = emojiCategoryId => {
  const category = categoryListRef.value.find(item => item.id === emojiCategoryId)
  categoryListTarget.value.scrollTo({ top: category.offsetTop - 120, behavior: 'smooth' }) // 120 => search height + margin after search + padding container + toolbar height + margin after toolbar
}

const intersectionObserver = ref(null)

const initIntersectionObserver = () => {
  intersectionObserver.value = new IntersectionObserver(category => {
    const [entry] = category
    if (entry.isIntersecting) {
      activeCategory.value = entry.target.id
    }
  }, {})

  categoryListRef.value.forEach(category => {
    intersectionObserver.value.observe(category)
  })
}

const disconnectObserver = () => {
  if (intersectionObserver.value) {
    intersectionObserver.value.disconnect()
    intersectionObserver.value = null
  }
}

watch(
  () => opened.value,
  newValue => {
    if (newValue) {
      initIntersectionObserver()
    } else {
      disconnectObserver()
      searchString.value = ''
      activeCategory.value = getCategoryName(DEFAULT_CATEGORY_INDEX)
    }
  }
)

watch(
  () => categoryListRef.value,
  () => {
    if (intersectionObserver.value) {
      categoryListRef.value.forEach(category => {
        intersectionObserver.value.observe(category)
      })
      activeCategory.value = getCategoryName(DEFAULT_CATEGORY_INDEX)
    }
  },
  { deep: true }
)

onBeforeUnmount(() => {
  disconnectObserver()
})
</script>
<script>
//eslint-disable-next-line import/order
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AppEmojiPicker'
})
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';

.aep-CategoryList {
  display: grid;
  list-style: none;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 250px;
  margin: 0;
  @include styled-native-scrollbar();
}

.aep-CategoryList_Emojis {
  list-style: none;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 8px;
  font-size: $fs-26;
  margin: 0;
}

.aep-Emojis_Item {
  cursor: pointer;
  border-radius: $border-radius-sm;
  width: 35px;
  text-align: center;
  @include activityStates($placeholder-color, 10%);
}

.aep-Wrapper {
  position: relative;
  border-radius: $border-radius-md;
  overflow: hidden;
  padding: 20px;
}

.aep-Navbar {
  max-width: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  list-style: none;
  box-shadow: inset 0 1px 0 $grey-medium;
  box-sizing: border-box;
  background: $grey-3-next;
  border-radius: $border-radius-sm-next;
  margin: 0 0 16px 0;
  padding: 4px;
  gap: 11px;
}

.aep-NavButton-active {
  background: $white;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.15), 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: $border-radius-sm;
}

.aep-CategoryList_Title {
  position: sticky;
  top: 0;
  background: $white;
  font-weight: fw('bold');
  font-size: $fs-12;
  line-height: 16px;
  font-family: $system-ui;
  padding-top: 0;
  padding-bottom: 16px;
  color: $dark-2;
  &-no-result {
    padding: 0;
  }
}

.aep-Search {
  margin: 0 0 16px 0;
}
</style>
