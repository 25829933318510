<template>
  <FormFieldNext :label="label" inline>
    <div class="sps-SelectFieldWrapper">
      <AppSelect
        v-model="model"
        :data-autotestid="$attrs['data-auto-testid'] || null"
        :data-testid="$attrs['data-testid'] || null"
        :hidden-items="hiddenItems"
        :is-error="isError"
        :item-label="itemLabel"
        :item-value="itemValue"
        :loading="loading"
        :options="options"
        :search-function="searchFunction"
        append-to="parent"
        boundary="scrollParent"
        dropdown-search
        dropdown-width="100%"
        hide-selected-items-in-dropdown
        multi
        show-all-selected
        show-selected-options-inside
        v-bind="{
          ...SCHEDULE_SELECT_ATTRS,
          offset: [0, '-100%'],
          dropdownMinWidth: 220
        }"
        @hide="toggleOpenDropdown(false)"
        @open="onOpen"
        @update:options="onUpdateOptions"
        @update:model-value="emit('update:model-value', $event)"
      >
        <template #option-label="{ option }">
          <slot :option="option" name="option-label" />
        </template>
        <template #button-content="{ options: selectedOptions }">
          <div v-if="isEmpty(selectedOptions)" class="sps-EmptyOption">
            <AppIcon v-if="emptyStateIcon" :icon-name="emptyStateIcon" height="24" width="24" />
            <span class="oboard-truncated-text">
              <slot name="empty-state-label">
                {{ t('common.select_entity') }}
              </slot>
            </span>
          </div>
        </template>
      </AppSelect>
    </div>
    <slot v-if="isError" name="error" />
  </FormFieldNext>
</template>

<script setup>
import { isEmpty } from 'lodash'
import { onBeforeUnmount } from 'vue'
import { useI18n } from 'vue-i18n'

import { SCHEDULE_SELECT_ATTRS } from '@/utils/check-ins/schedule-ui-helpers'

import FormFieldNext from '@/components/form/FormFieldNext'
import AppIcon from '@/components/ui/AppIcon/AppIcon'
import AppSelect from '@/components/ui/AppSelect/AppSelect'

defineOptions({
  name: 'ScheduleParticipantsSelect'
})

const model = defineModel({
  type: Array,
  required: true,
  set: value => value
})

defineProps({
  label: {
    type: String,
    default: ''
  },

  itemLabel: {
    type: String,
    default: 'label'
  },

  itemValue: {
    type: String,
    default: 'value'
  },

  loading: {
    type: Boolean
  },

  options: {
    type: Array,
    default: () => []
  },

  searchFunction: {
    type: Function,
    default: null
  },

  emptyStateIcon: {
    type: String,
    default: ''
  },

  isError: {
    type: Boolean
  },

  hiddenItems: {
    type: Array,
    default: () => []
  }
})

const { t } = useI18n()

const emit = defineEmits({
  'update:options': null,
  'update:model-value': null,
  open: null,
  'toggle-dropdown': null
})

const onUpdateOptions = newOptions => {
  emit('update:options', newOptions)
}

const onOpen = () => {
  emit('open')
  toggleOpenDropdown()
}

const toggleOpenDropdown = (value = true) => {
  emit('toggle-dropdown', value)
}

onBeforeUnmount(() => {
  toggleOpenDropdown(false)
})
</script>

<style lang="scss" scoped>
.sps-SelectFieldWrapper {
  --select-skeleton-top: 0;
  --select-skeleton-left: 0;
  min-width: 170px;
  position: relative;

  :deep(.as-AppDroplistButton_Content) {
    padding: 6px 0 6px 6px;
  }
}

.sps-EmptyOption {
  display: flex;
  align-items: center;
  color: $dark-grey;
}
</style>
