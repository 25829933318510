<template>
  <div class="ssp-Page">
    <PageContentHeader
      :description="t('statuses.page_description')"
      :level="3"
      :max-width="HEADER_SIZES.LG"
      :title="t('filter.statuses')"
      no-margin
    />
    <div class="ssp-MainContent">
      <StatusSettingsTable
        :table-data="PROGRESS_STATUSES"
        data-auto-testid="progress-statuses-table"
        data-testid="progress-statuses-table"
        @menu-action-click="onMenuActionsClick"
      />
      <StatusSettingsTable
        :table-data="OKR_STATUSES_DISABLED"
        data-auto-testid="close-statuses-table"
        data-testid="close-statuses-table"
        @menu-action-click="onMenuActionsClick"
      >
        <template #header-cell-name-title>
          {{ t('statuses.close_statuses') }}
        </template>

        <template #footer>
          <LockMessage class="spp-Message">
            {{ t('statuses.close_statuses.description') }}
          </LockMessage>
        </template>
      </StatusSettingsTable>

      <ThresholdsSettings />
    </div>
    <RenameStatusModal
      :show="isShowRenameStatusModal"
      :status-properties="statusForRename"
      @close="onRenameStatusModalClose"
      @status-renamed="onStatusRenamed"
    />
  </div>
</template>

<script setup>
import { nextTick, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import RenameStatusApiHandler from '@/api/rename-status'
import { HEADER_SIZES } from '@/utils/components-configurations/page-content-header'
import { STATUS_NAMES_ENTITY_KEYS } from '@/utils/entity-keys'
import { handleError } from '@/utils/error-handling'
import { showNotify } from '@/utils/notify'
import { OKR_STATUSES, OKR_STATUSES_DISABLED } from '@/utils/objectives'
import { ALL_STATUS_NAMES } from '@/utils/status-names-factory'

import LockMessage from '@/components/form/LockMessage'
import StatusSettingsTable from '@/components/status-settings/StatusSettingsTable'
import PageContentHeader from '@/components/ui/PageContentHeader/PageContentHeader'
import RenameStatusModal from '@/components/workspaces/RenameStatusModal'
import ThresholdsSettings from '@/views/workspaces/settings/ThresholdsSettings'

const { t } = useI18n()

const ACTIONS = {
  RENAME: 'rename',
  RESTORE_DEFAULT: 'restore-default'
}

defineOptions({
  name: 'StatusSettingsLayout'
})

const { ON_TRACK, AT_RISK, BEHIND, NOT_STARTED } = OKR_STATUSES

const PROGRESS_STATUSES = [NOT_STARTED, AT_RISK, BEHIND, ON_TRACK]

const statusForRename = ref({})
const isShowRenameStatusModal = ref(false)

const onRestoreDefault = async ({ statusId }) => {
  const statusProperties = ALL_STATUS_NAMES.statusProperties[statusId]

  const api = new RenameStatusApiHandler()

  try {
    await api.renameStatus({
      statusId,
      newName: statusProperties[STATUS_NAMES_ENTITY_KEYS.DEFAULT_NAME]
    })
    ALL_STATUS_NAMES.restoreDefault({ statusId })
    showSuccessNotification()
  } catch (error) {
    handleError({ error })
  }
}

const onMenuActionsClick = async ({ action, statusId }) => {
  if (action === ACTIONS.RENAME) {
    statusForRename.value = {
      ...ALL_STATUS_NAMES.statusProperties[statusId],
      statusId
    }
    await nextTick()
    isShowRenameStatusModal.value = true
  }

  if (action === ACTIONS.RESTORE_DEFAULT) {
    await onRestoreDefault({ statusId })
  }
}

const onRenameStatusModalClose = () => {
  isShowRenameStatusModal.value = false
  statusForRename.value = {}
}

const onStatusRenamed = ({ statusId, newName }) => {
  ALL_STATUS_NAMES.updateStatusTranslation({
    statusId,
    [STATUS_NAMES_ENTITY_KEYS.CUSTOMER_NAME]: newName
  })
  showSuccessNotification()
}

const showSuccessNotification = () => {
  showNotify({ title: t('statuses.notifications.renamed') })
}
</script>

<style lang="scss" scoped>
.ssp-MainContent {
  width: 100%;
  max-width: $page-width-md;
  padding-left: $page-left-padding;
  padding-right: $page-right-padding;
  margin-top: 20px;
  --head-padding-top: 8px;
  display: grid;
  gap: 40px;
}

.spp-Message {
  --padding: 8px #{$page-right-padding} 8px #{$page-left-padding};
  --color: #{$dark-3};
}
</style>
