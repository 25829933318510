<template>
  <div class="pss-Summary">
    <div class="pss-TitleWrapper">
      <AppTitle :level="3" class="pss-Summary_Title" disable-margin>
        <slot name="title">{{ title }}</slot>
      </AppTitle>
      <div class="pss-ProgressTitle">
        {{ $t('dashboard.avg_progress') }}
      </div>
    </div>

    <AppDivider border-width="2px" no-margin />

    <UserReportSummaryLoader v-if="loading" />

    <div v-else class="pss-Summary_Items">
      <HomePageSummaryItem
        v-for="item in items"
        :key="item.id"
        :item="item"
        :user-selected="userSelected"
        :without-grade-coloring="withoutGradeColoring"
        @update-selected-user-or-group="$emit('update-selected-user-or-group', $event)"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

import { stringOrNullProp } from '@/utils/prop-validators'

import HomePageSummaryItem from '@/components/personal-statistics/PersonalStatisticsSummaryItem'
import AppDivider from '@/components/ui/AppDivider'
import AppTitle from '@/components/ui/AppTitle/AppTitle'
import UserReportSummaryLoader from '@/components/ui/SkeletonLoaders/UserReportSummaryLoader'

export default defineComponent({
  name: 'PersonalStatisticsSummary',
  components: { AppDivider, UserReportSummaryLoader, AppTitle, HomePageSummaryItem },
  props: {
    userSelected: {
      type: Boolean
    },

    summary: {
      type: Array,
      required: true
    },

    loading: {
      type: Boolean
    },

    selectedUserName: {
      default: '',
      validator: v => stringOrNullProp(v)
    },

    withoutGradeColoring: {
      type: Boolean
    }
  },

  emits: {
    'update-selected-user-or-group': null
  },

  computed: {
    title() {
      return this.userSelected
        ? this.$t('homepage.user_summary.title', { userName: this.selectedUserName })
        : this.$t('homepage.group_summary.title')
    },

    items() {
      return this.summary.map(item => {
        return {
          ...item,
          id: item.id || item.accountId
        }
      })
    }
  }
})
</script>

<style lang="scss" scoped>
.pss-Summary {
  max-width: var(--max-width, 976px);
  width: 100%;
  margin: 0 auto;
  border: 2px solid $grey-3-next;
  border-radius: $border-radius-lg-next;
  padding: 32px;
}

.pss-Summary_Title {
  margin-bottom: 13px;
  color: $dark-1;
  font-family: $system-ui;
  line-height: 28px;
  font-weight: fw('semi-bold');
}
.pss-ProgressTitle {
  font-family: $system-ui;
  font-weight: fw('bold');
  font-size: $fs-12;
  line-height: 16px;
  color: $dark-3;
  margin-right: 320px;
}
.pss-TitleWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
