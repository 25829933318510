<template>
  <div class="cdni-NestedItemsWrapper" data-auto-testid="custom-dashboard-objective-nexted-items">
    <FormNestedItemsField :show-expand-collapse-button="false" show-head>
      <OkrElementsList
        :allowed-depth="0"
        :fetch-child-objectives-on-expand="false"
        :objectives="objective.childElements"
        :show-status="false"
        :some-item-is-expandable="false"
        always-show-weight
        hide-actions
        item-type="primary-next"
        show-avatar-replacer
        show-current-target
        show-groups
        type="default-next"
        with-virtual-scroll
        @edit-element="onOpenObjectiveDetails"
      >
        <template v-if="!objective.childElements.length" #no-objectives>
          <div class="cdni-EmptyState">
            <ObjectivesEmptyHero
              class="otl-BacklogError_Hero"
              height="128"
              name="empty-state-kitten"
              width="142"
            />
            <span>{{ $t('objective.no_key_results_for_selected_objective') }}</span>
          </div>
        </template>
      </OkrElementsList>
    </FormNestedItemsField>
  </div>
</template>

<script setup>
import FormNestedItemsField from '@/components/form/FormNestedItemsField'
import ObjectivesEmptyHero from '@/components/objectives/ObjectivesEmptyHero'
import OkrElementsList from '@/components/objectives/okr-elements-list/OkrElementsList'

defineOptions({
  name: 'CustomDashboardNestedItems'
})

defineProps({
  objective: {
    type: Object,
    required: true
  }
})
const emit = defineEmits({
  'open-objective': null
})
const onOpenObjectiveDetails = ({ item }) => {
  emit('open-objective', item)
}
</script>

<style lang="scss" scoped>
.cdni-NestedItemsWrapper {
  width: 100%;
  background: $white;
  &:deep(.fnif-Head) {
    display: none;
  }
}
.cdni-EmptyState {
  font-weight: fw('regular');
  font-family: $system-ui;
  font-size: $fs-14;
  line-height: 20px;
  color: $dark-3;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 25px;
  padding: 40px 0 28px;
}
</style>
