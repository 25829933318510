<template>
  <DateCell
    :readable="readable"
    :item="item"
    :updatable="updatable"
    :date-prop="OKR_DATES_SELECT_DATE_PROPS.START_DATE"
    @update-dates="$emit('update-dates', $event)"
  />
</template>

<script>
import { defineComponent } from 'vue'

import { OKR_DATES_SELECT_DATE_PROPS } from '@/utils/okr-element-dates'

import DateCell from '@/components/objectives/table/cells/DateCell'

export default defineComponent({
  name: 'StartDateCell',
  components: { DateCell },

  props: {
    item: {
      type: Object,
      required: true
    },

    readable: {
      type: Boolean
    },

    updatable: {
      type: Boolean
    }
  },

  emits: {
    'update-dates': null
  },

  computed: {
    OKR_DATES_SELECT_DATE_PROPS: () => OKR_DATES_SELECT_DATE_PROPS
  }
})
</script>
