import { isEmpty } from 'lodash'

import {
  CUSTOM_FIELDS_FILTERS_QUERY_KEY,
  DEFAULT_CUSTOM_FIELD_FILTERS_VALUE,
  getCustomFieldFiltersQuery
} from '@/utils/custom-fields/helpers'
import { DATES_KEYS, FILTER_DEFAULT_VALUES, FILTERS_KEYS } from '@/utils/okr-elements/filters'
import { WORKSPACE_ID_KEY } from '@/utils/query-parameters'
import { selectAllIsSelected } from '@/utils/select'

export const BACKEND_FILTER_IDS = {
  [WORKSPACE_ID_KEY]: 1,
  [FILTERS_KEYS.INTERVAL_IDS]: 2,
  [FILTERS_KEYS.ASSIGNEE_IDS]: 4,
  [FILTERS_KEYS.GROUP_IDS]: 5,
  [FILTERS_KEYS.OKR_TYPE_IDS]: 6,
  [FILTERS_KEYS.GRADE_TYPES]: 7,
  [DATES_KEYS.DUE_DATE_FROM]: 8,
  [DATES_KEYS.DUE_DATE_TO]: 9,
  [FILTERS_KEYS.LABEL_IDS]: 11,
  [FILTERS_KEYS.STAKEHOLDER_IDS]: 12,
  [DATES_KEYS.START_DATE_FROM]: 13,
  [DATES_KEYS.START_DATE_TO]: 14,
  [DATES_KEYS.LAST_GRADE_UPDATE_DATE_FROM]: 15,
  [DATES_KEYS.LAST_GRADE_UPDATE_DATE_TO]: 16,
  [FILTERS_KEYS.LAST_COMMENT]: 17,
  [CUSTOM_FIELDS_FILTERS_QUERY_KEY]: 18
}

export const FRONTEND_FILTER_NAMES_BY_ID = Object.fromEntries(
  Object.entries(BACKEND_FILTER_IDS).map(item => {
    return item.reverse()
  })
)

export const convertDatesToStartEnd = (dates, BACKEND_FILTER_IDS) => {
  const { startDates, dueDates, lastGradeUpdateDates } = dates
  const staredDatesSelected = Array.isArray(startDates)
  const dueDatesSelected = Array.isArray(dueDates)
  const lastGradeUpdateDatesSelected = Array.isArray(lastGradeUpdateDates)
  const {
    startDateFrom,
    startDateTo,
    dueDateFrom,
    dueDateTo,
    lastGradeUpdateDateFrom,
    lastGradeUpdateDateTo
  } = BACKEND_FILTER_IDS

  const [startDateStart, startDateEnd] = staredDatesSelected ? startDates : [startDates, startDates]
  const [dueDateStart, dueDateEnd] = dueDatesSelected ? dueDates : [dueDates, dueDates]
  const [lastGradeUpdateDateStart, lastGradeUpdateDateEnd] = lastGradeUpdateDatesSelected
    ? lastGradeUpdateDates
    : [lastGradeUpdateDates, lastGradeUpdateDates]

  return {
    [startDateFrom]: JSON.stringify(startDateStart),
    [startDateTo]: JSON.stringify(startDateEnd),
    [dueDateFrom]: JSON.stringify(dueDateStart),
    [dueDateTo]: JSON.stringify(dueDateEnd),
    [lastGradeUpdateDateFrom]: JSON.stringify(lastGradeUpdateDateStart),
    [lastGradeUpdateDateTo]: JSON.stringify(lastGradeUpdateDateEnd)
  }
}

export const prepareFiltersPayload = (filtersValues, workspaceId) => {
  const {
    INTERVAL_IDS,
    ASSIGNEE_IDS,
    GROUP_IDS,
    OKR_TYPE_IDS,
    GRADE_TYPES,
    LABEL_IDS,
    DUE_DATES,
    START_DATES,
    LAST_GRADE_UPDATE_DATES,
    LAST_COMMENT,
    STAKEHOLDER_IDS
  } = FILTERS_KEYS
  const filters = {
    [INTERVAL_IDS]: filtersValues[INTERVAL_IDS],
    [ASSIGNEE_IDS]: filtersValues[ASSIGNEE_IDS],
    [GROUP_IDS]: filtersValues[GROUP_IDS],
    [OKR_TYPE_IDS]: filtersValues[OKR_TYPE_IDS],
    [GRADE_TYPES]: filtersValues[GRADE_TYPES],
    [LABEL_IDS]: filtersValues[LABEL_IDS],
    [WORKSPACE_ID_KEY]: workspaceId,
    [STAKEHOLDER_IDS]: filtersValues[STAKEHOLDER_IDS],
    [LAST_COMMENT]: filtersValues[LAST_COMMENT],
    [CUSTOM_FIELDS_FILTERS_QUERY_KEY]:
      filtersValues[CUSTOM_FIELDS_FILTERS_QUERY_KEY] || DEFAULT_CUSTOM_FIELD_FILTERS_VALUE
  }

  const convertedValues = Object.fromEntries(
    Object.entries(filters).map(([key, val]) => {
      if (key === CUSTOM_FIELDS_FILTERS_QUERY_KEY) {
        let normalizedValue = DEFAULT_CUSTOM_FIELD_FILTERS_VALUE
        if (!isEmpty(val)) {
          normalizedValue = getCustomFieldFiltersQuery({ customFieldsFiltersValues: val })
        }
        return [BACKEND_FILTER_IDS[key], JSON.stringify(normalizedValue)]
      }
      const normalizedValue = selectAllIsSelected(val) ? FILTER_DEFAULT_VALUES[key] : val
      return [BACKEND_FILTER_IDS[key], JSON.stringify(normalizedValue)]
    })
  )

  const datesPayload = {
    startDates: filtersValues[START_DATES],
    dueDates: filtersValues[DUE_DATES],
    lastGradeUpdateDates: filtersValues[LAST_GRADE_UPDATE_DATES]
  }

  return {
    ...convertedValues,
    ...convertDatesToStartEnd(datesPayload, BACKEND_FILTER_IDS)
  }
}
