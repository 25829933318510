export const BUTTON_TYPES = [
  'default',
  'none',
  'primary',
  'primary-next',
  'black-primary',
  'secondary',
  'secondary-next',
  'tertiary',
  'tertiary-next',
  'subtle',
  'secondary-inline',
  'secondary-inline-next',
  'link',
  'link-next',
  'link-dark',
  'link-secondary',
  'link-black',
  'danger',
  'warning',
  'tour',
  'ghost',
  'ghost-next',
  'modern',
  'simple',
  'simple-next',
  'success',
  'outline'
]

export const BUTTON_SIZES = {
  XSS: 'xss',
  XS: 'xs',
  SM: 'sm',
  MD: 'md',
  XMD: 'xmd',
  LG: 'lg',
  XLG: 'xlg',
  XSM: 'xsm',
  LG_NEXT: 'lg-next'
}
