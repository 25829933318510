<template>
  <span
    :id="id || undefined"
    :class="{
      'cv-CellValue': true,
      'cv-CellValue-editable': cellValue && userHasUpdateAccess,
      'cv-CellValue-inaccessible': !userHasReadAccess,
      'cv-CellValue-inherit-cursor': inheritCursorStyle
    }"
    :title="showNativeTooltip ? cellValue : null"
    @click="$emit('click')"
  >
    <template v-if="cellValue !== null">
      {{ cellValue }}
      <slot />
    </template>
  </span>
</template>

<script>
import { defineComponent } from 'vue'

import { stringOrNullProp } from '@/utils/prop-validators'

export default defineComponent({
  name: 'CellValue',

  props: {
    id: {
      type: String,
      default: null
    },

    cellValue: {
      validator: value => stringOrNullProp(value),
      required: true
    },

    userHasUpdateAccess: {
      type: Boolean,
      required: true
    },

    userHasReadAccess: {
      type: Boolean,
      default: true
    },

    showNativeTooltip: {
      type: Boolean
    },

    inheritCursorStyle: {
      type: Boolean
    }
  },

  emits: { click: null }
})
</script>

<style lang="scss" scoped>
.cv-CellValue {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-height: 20px;
  cursor: default;

  &-editable {
    color: $primary-color;
    cursor: pointer;

    &:hover {
      color: $blue-1;
      text-decoration: underline;
    }
  }

  &-inaccessible {
    color: $dark-1;
  }

  &-inherit-cursor {
    cursor: inherit;
  }
}
</style>
