<template>
  <span
    v-if="statusObject"
    :class="{
      [`obs-ObjectiveStatus-${type}`]: type,
      'obs-ObjectiveStatus-loading': loading,
      'obs-ObjectiveStatus-noBorder': noBorder
    }"
    :style="{
      color: statusObject.color,
      '--rgb-color': statusObject.rgbColor,
      '--border-color': statusObject.borderColor,
      '--background-color': statusObject.backgroundColor
    }"
    class="obs-ObjectiveStatus"
  >
    <slot :label="$t(statusObject.label)">
      {{ $t(statusObject.label) }}
    </slot>
    <slot name="after" />
    <SkeletonItem
      v-if="loading"
      :color="statusObject.color"
      border-radius="0"
      class="obs-ObjectiveStatus_Loader"
      height="100%"
      width="100%"
    />
  </span>
</template>

<script>
import { defineComponent } from 'vue'

import { OBJECTIVE_STATUS_ALL_OPTIONS } from '@/utils/objectives'

import SkeletonItem from '@/components/ui/SkeletonLoaders/SkeletonItem'

const TYPES = {
  DEFAULT: 'default'
}

export default defineComponent({
  name: 'ObjectiveStatus',

  components: {
    SkeletonItem
  },

  props: {
    status: {
      type: Number,
      required: true
    },

    type: {
      type: String,
      default: TYPES.DEFAULT,
      validator: v => Object.values(TYPES).includes(v)
    },

    loading: {
      type: Boolean
    },

    noBorder: {
      type: Boolean
    }
  },

  computed: {
    statusObject() {
      return OBJECTIVE_STATUS_ALL_OPTIONS.find(option => option.value === this.status)
    }
  }
})
</script>

<style lang="scss" scoped>
.obs-ObjectiveStatus {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: $system-ui;
  font-style: normal;
  font-weight: fw('bold');
  text-transform: uppercase;
  font-size: $fs-12;
  line-height: 16px;
  padding: var(--padding, 2px 4px);
  border-radius: $border-radius-sm-next;
  white-space: nowrap;
  width: min-content;
  max-width: 100%;
  background-color: var(--background-color, transparent);

  &:not(&-noBorder) {
    border: 2px solid var(--border-color, rgba(var(--rgb-color), 0.3));
  }

  &-loading {
    position: relative;
  }
}

.obs-ObjectiveStatus_Loader {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.3;
}
</style>
