<template>
  <template v-if="!item.isNestedTask">
    <OkrDatesDropdownTrigger
      :active="showDatesDropdown"
      :date-for-show="dateProp"
      :disabled="!updatable && readable"
      :form-model="item"
      :is-auto-period-mode="isAutoPeriodMode"
      :plug-icon="PLUG_ICONS.DATE"
      :user-has-read-access="readable"
      :user-has-update-access="updatable"
      class="dc-DropdownTrigger"
      data-testid="date-dropdown-trigger"
      style="--disabled-opacity: 1"
      type="default-next"
      @click="onDatesTriggerClick"
    />

    <OkrDatesDropdown
      v-if="updatable && editInitialised"
      :follow-cursor="false"
      :is-auto-period-mode="isAutoPeriodMode"
      :loading="datesDropdownLoading"
      :objective="item"
      :offset="[0, 12]"
      :open-calendar-on-init="dateProp"
      :show-dates-dropdown="showDatesDropdown"
      :show-period-mode-switch="!isJiraIssue"
      append-to="body"
      data-testid="date-selector"
      position="bottom"
      @hide-dates-dropdown="showDatesDropdown = false"
      @update-element-date="onUpdateElementDate"
      @update-period-mode="updatePeriodMode"
    />
  </template>
</template>

<script>
import { omit } from 'lodash'
import { defineComponent } from 'vue'

import ObjectivesInfoApiHandler from '@/api/okr-elements'
import { PLUG_ICONS } from '@/utils/cell-helper'
import { handleError } from '@/utils/error-handling'
import { objectiveIsJiraTask } from '@/utils/objectives'
import { isManualPeriodModeEnabled, OKR_DATES_SELECT_DATE_PROPS } from '@/utils/okr-element-dates'

import OkrDatesDropdown from '@/components/objectives/OkrDatesDropdown'
import OkrDatesDropdownTrigger from '@/components/objectives/OkrDatesDropdownTrigger'

export default defineComponent({
  name: 'DateCell',
  components: { OkrDatesDropdown, OkrDatesDropdownTrigger },
  inheritAttrs: false,

  props: {
    item: {
      type: Object,
      required: true
    },

    readable: {
      type: Boolean
    },

    updatable: {
      type: Boolean
    },

    dateProp: {
      type: String,
      default: null,
      validator: v => Object.values(OKR_DATES_SELECT_DATE_PROPS).includes(v)
    }
  },

  emits: {
    'update-dates': null
  },

  data() {
    return {
      showDatesDropdown: false,
      datesDropdownLoading: false,
      editInitialised: false
    }
  },

  computed: {
    PLUG_ICONS() {
      return PLUG_ICONS
    },

    isAutoPeriodMode() {
      return (
        !this.isJiraIssue &&
        !isManualPeriodModeEnabled(this.item.startDateManual, this.item.dueDateManual)
      )
    },

    isJiraIssue() {
      return objectiveIsJiraTask(this.item)
    }
  },

  methods: {
    onDatesTriggerClick() {
      if (this.updatable) {
        this.editInitialised = true
        this.$nextTick(() => {
          this.showDatesDropdown = !this.showDatesDropdown
        })
      }
    },

    async onUpdateElementDate(dates) {
      this.datesDropdownLoading = true
      const api = new ObjectivesInfoApiHandler()

      const { id: elementId, parentId, intervalId } = this.item

      const payload = {
        elementId,
        parentId,
        intervalId,
        ...omit(dates, 'dateProp')
      }

      try {
        const updatedData = await api.updateOkrElement(payload)
        this.$emit('update-dates', updatedData)
      } catch (error) {
        handleError({ error })
      }
      this.datesDropdownLoading = false
    },

    async updatePeriodMode(mode) {
      this.datesDropdownLoading = true
      const api = new ObjectivesInfoApiHandler()

      const { id: elementId, parentId, intervalId } = this.item

      const payload = {
        elementId,
        parentId,
        intervalId,
        startDateManual: mode,
        dueDateManual: mode
      }

      if (mode) {
        payload.elementStartDate = this.item.automaticElementStartDate
        payload.dueDate = this.item.automaticDueDate
      } else {
        payload.elementStartDate = null
        payload.dueDate = null
      }

      try {
        const updatedData = await api.updateOkrElement(payload)
        this.$emit('update-dates', updatedData)
      } catch (error) {
        handleError({ error })
      }
      this.datesDropdownLoading = false
    }
  }
})
</script>

<style lang="scss" scoped>
// eslint-disable-next-line vue-scoped-css/no-unused-selector
.dc-DropdownTrigger {
  margin: 0;
  padding: 2px 6px;
  border-radius: $border-radius-sm-next;
  border: none;
  vertical-align: middle;
  width: auto;

  position: relative;

  &.odt-Trigger-empty {
    width: 100%;
  }

  &.odt-Trigger-empty-with-icon {
    padding: 0;
    display: flex;
    opacity: 0; // !!!
    width: auto;

    .o-objective-row:hover & {
      opacity: 1;
    }
  }

  &.odt-Trigger-active.odt-Trigger-empty-with-icon {
    opacity: 1;
    background-color: $dark-2;
    color: $white;
  }

  &.odt-Trigger-active:not(.odt-Trigger-empty):not(.odt-Trigger-empty-with-icon) {
    background-color: $dark-2;
    color: $white;
  }

  &:not(.odt-Trigger-active):not(.odt-Trigger-empty):not(.odt-Trigger-inaccessible):not(
      .odt-Trigger-empty-with-icon
    ) {
    @media (any-hover: hover) {
      &:hover {
        // text-decoration: underline;
        background-color: $grey-2-next;
      }
    }
  }

  .o-objective-row:hover & {
    &:not(.odt-Trigger-active):not(.odt-Trigger-inaccessible):not(.odt-Trigger-empty):not(
        .odt-Trigger-empty-with-icon
      ) {
      background-color: $grey-2-next;
    }
  }
}
</style>
