<template>
  <div class="ntr-Row">
    <div class="ntr-Row_Content">
      <AppIcon height="24" icon-name="info-next" width="24" />

      {{ t('table.no_nested_issues') }}
    </div>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'

import AppIcon from '@/components/ui/AppIcon/AppIcon'

defineOptions({
  name: 'NoNestedTasksRow'
})

const { t } = useI18n()
</script>

<style lang="scss" scoped>
.ntr-Row {
  margin-left: var(--margin-left);
}

.ntr-Row_Content {
  display: flex;
  align-items: center;
  gap: 8px;
  padding-block: 10px;
  font-size: $fs-12;
  color: $dark-3;
  line-height: 16px;
}
</style>
