import dayjs from 'dayjs'
import localeData from 'dayjs/plugin/localeData'
import { clone, cloneDeep, flatMap, groupBy, has, isEmpty, mapValues, omit, uniq } from 'lodash'

import i18n from '@/i18n'
import { DEFAULT_SCHEDULE_NAME } from '@/utils/check-ins/schedule-ui-helpers'
import { parseParticipantsPayloadToFromModel } from '@/utils/check-ins/schedules-participants'
import { localDateToUtc, utcDateToLocal } from '@/utils/date'
import {
  NOTIFICATION_CHANNEL_ENTITY_KEYS,
  REMINDER_ENTITY_KEYS,
  SCHEDULE_ENTITY_KEYS,
  SCHEDULE_FREQUENCY_INNER_PARAMS_ENTITY_KEYS
} from '@/utils/entity-keys'
import { isStringEmpty } from '@/utils/general'
import { NOTIFICATION_CHANNELS } from '@/utils/okr-elements/notifications'
import { uid } from '@/utils/uid'

import { ALLOWED_MESSENGERS_TYPE_IDS } from '@/views/workspaces/settings/integrations/integration-utils'

dayjs.extend(localeData)

const { NAME, FREQUENCY_TYPE_ID, START_FROM, TYPE_ID, PARAMS, REMINDERS } = SCHEDULE_ENTITY_KEYS

const { t } = i18n.global

export const SCHEDULE_NAME_MAX_LENGTH = 63
export const REPEAT_INTERVAL_MAX_VALUE = 99
export const REPEAT_INTERVAL_MIN_VALUE = 1
export const SCHEDULE_LIMIT = 10

export const REPEAT_UNITS = {
  WEEK: 'week-unit',
  MONTH: 'month-unit'
}

export const NTH_WEEKDAY_VALUES = {
  NTH_DAY: 'nth-day',
  DAY_NUMBER: 'day-number'
}

const getFrequencyName = (index = 0) => t(`schedule_frequencies[${index}]`)

class Frequency {
  #resolvedTypeId
  #privateParams = {
    isCustomMonthSchedule: false,
    isScheduleByNthDay: false,
    isScheduleByDayNumber: false
  }
  #fieldsForPayloadGeneration = []

  constructor(
    name,
    typeId,
    resolvedTypeId = null,
    privateParams = {},
    availableFields = [],
    fieldsForPayloadGenerator = []
  ) {
    this[NAME] = name
    this[TYPE_ID] = typeId
    this.availableFields = [...availableFields]
    this.#fieldsForPayloadGeneration = [...fieldsForPayloadGenerator]
    this.#resolvedTypeId = resolvedTypeId

    this.#privateParams = {
      ...this.#privateParams,
      ...cloneDeep(privateParams)
    }

    this.isFieldAvailable = function (field) {
      return this.availableFields.includes(field)
    }
  }

  get fieldsForPayloadGeneration() {
    return this.#fieldsForPayloadGeneration || []
  }

  get resolvedTypeId() {
    return this.#resolvedTypeId || this[TYPE_ID]
  }

  get isCustomMonthSchedule() {
    return this.#getPrivateParamValue('isCustomMonthSchedule')
  }

  get isScheduleByNthDay() {
    return this.#getPrivateParamValue('isScheduleByNthDay')
  }

  get isScheduleByDayNumber() {
    return this.#getPrivateParamValue('isScheduleByDayNumber')
  }

  #getPrivateParamValue = key => {
    return this.#privateParams[key] || false
  }
}

export const SCHEDULE_FREQUENCIES = {
  WEEKLY: new Frequency(
    getFrequencyName(0),
    1,
    null,
    {},
    [SCHEDULE_FREQUENCY_INNER_PARAMS_ENTITY_KEYS.WEEKDAY],
    [SCHEDULE_FREQUENCY_INNER_PARAMS_ENTITY_KEYS.WEEKDAY]
  ),
  BI_WEEKLY: new Frequency(
    getFrequencyName(1),
    2,
    null,
    {},
    [SCHEDULE_FREQUENCY_INNER_PARAMS_ENTITY_KEYS.WEEKDAY],
    [SCHEDULE_FREQUENCY_INNER_PARAMS_ENTITY_KEYS.WEEKDAY]
  ),
  MONTHLY: new Frequency(getFrequencyName(2), 'monthly'),
  CUSTOM: new Frequency(getFrequencyName(3), 'custom')
}

export const SCHEDULE_SYSTEM_FREQUENCIES = {
  MONTHLY_BY_NTH_DAY: new Frequency(
    'Monthly by nth day',
    3,
    SCHEDULE_FREQUENCIES.MONTHLY[TYPE_ID],
    {
      isScheduleByNthDay: true
    },
    [SCHEDULE_FREQUENCY_INNER_PARAMS_ENTITY_KEYS.NTH_WEEKDAY],
    [
      SCHEDULE_FREQUENCY_INNER_PARAMS_ENTITY_KEYS.WEEKDAY,
      SCHEDULE_FREQUENCY_INNER_PARAMS_ENTITY_KEYS.NTH_WEEKDAY
    ]
  ),
  MONTHLY_BY_DAY_NUMBER: new Frequency(
    'Monthly by day number',
    4,
    SCHEDULE_FREQUENCIES.MONTHLY[TYPE_ID],
    {
      isScheduleByDayNumber: true
    },
    [SCHEDULE_FREQUENCY_INNER_PARAMS_ENTITY_KEYS.NTH_WEEKDAY],
    [SCHEDULE_FREQUENCY_INNER_PARAMS_ENTITY_KEYS.MONTH_DAY]
  ),
  CUSTOM_WEEK: new Frequency(
    'Custom week',
    5,
    SCHEDULE_FREQUENCIES.CUSTOM[TYPE_ID],
    {},
    [
      SCHEDULE_FREQUENCY_INNER_PARAMS_ENTITY_KEYS.WEEKDAYS,
      SCHEDULE_FREQUENCY_INNER_PARAMS_ENTITY_KEYS.REPEAT_INTERVAL,
      SCHEDULE_FREQUENCY_INNER_PARAMS_ENTITY_KEYS.REPEAT_UNIT
    ],
    [
      SCHEDULE_FREQUENCY_INNER_PARAMS_ENTITY_KEYS.REPEAT_INTERVAL,
      SCHEDULE_FREQUENCY_INNER_PARAMS_ENTITY_KEYS.WEEKDAYS
    ]
  ),
  CUSTOM_MONTH_BY_NTH_DAY: new Frequency(
    'Custom month by nth day',
    6,
    SCHEDULE_FREQUENCIES.CUSTOM[TYPE_ID],
    {
      isCustomMonthSchedule: true,
      isScheduleByNthDay: true
    },
    [
      SCHEDULE_FREQUENCY_INNER_PARAMS_ENTITY_KEYS.REPEAT_INTERVAL,
      SCHEDULE_FREQUENCY_INNER_PARAMS_ENTITY_KEYS.REPEAT_UNIT,
      SCHEDULE_FREQUENCY_INNER_PARAMS_ENTITY_KEYS.NTH_WEEKDAY
    ],
    [
      SCHEDULE_FREQUENCY_INNER_PARAMS_ENTITY_KEYS.REPEAT_INTERVAL,
      SCHEDULE_FREQUENCY_INNER_PARAMS_ENTITY_KEYS.WEEKDAY,
      SCHEDULE_FREQUENCY_INNER_PARAMS_ENTITY_KEYS.NTH_WEEKDAY
    ]
  ),
  CUSTOM_MONTH_BY_DAY_NUMBER: new Frequency(
    'Custom month by day number',
    7,
    SCHEDULE_FREQUENCIES.CUSTOM[TYPE_ID],
    {
      isCustomMonthSchedule: true,
      isScheduleByDayNumber: true
    },
    [
      SCHEDULE_FREQUENCY_INNER_PARAMS_ENTITY_KEYS.REPEAT_INTERVAL,
      SCHEDULE_FREQUENCY_INNER_PARAMS_ENTITY_KEYS.REPEAT_UNIT,
      SCHEDULE_FREQUENCY_INNER_PARAMS_ENTITY_KEYS.NTH_WEEKDAY
    ],
    [
      SCHEDULE_FREQUENCY_INNER_PARAMS_ENTITY_KEYS.REPEAT_INTERVAL,
      SCHEDULE_FREQUENCY_INNER_PARAMS_ENTITY_KEYS.MONTH_DAY
    ]
  )
}

class Frequencies {
  constructor() {
    this.frequenciesByTypeId = [
      ...Object.values(SCHEDULE_FREQUENCIES),
      ...Object.values(SCHEDULE_SYSTEM_FREQUENCIES)
    ].reduce((acc, val) => {
      return {
        ...acc,
        [val[TYPE_ID]]: val
      }
    }, {})

    this.getFrequencyByTypeId = typeId =>
      this.frequenciesByTypeId[typeId] || SCHEDULE_FREQUENCIES.WEEKLY
  }
}

const FREQUENCIES_BY_TYPE_ID = new Frequencies()

export const DEFAULT_FORM_MODEL = {
  [NAME]: '',
  [FREQUENCY_TYPE_ID]: SCHEDULE_FREQUENCIES.WEEKLY[TYPE_ID],
  [START_FROM]: new Date()
}

export const FREQUENCY_INNER_PARAMS = {
  [SCHEDULE_FREQUENCY_INNER_PARAMS_ENTITY_KEYS.WEEKDAY]: null,
  [SCHEDULE_FREQUENCY_INNER_PARAMS_ENTITY_KEYS.NTH_WEEKDAY]: NTH_WEEKDAY_VALUES.NTH_DAY,
  [SCHEDULE_FREQUENCY_INNER_PARAMS_ENTITY_KEYS.REPEAT_INTERVAL]: 3,
  [SCHEDULE_FREQUENCY_INNER_PARAMS_ENTITY_KEYS.REPEAT_UNIT]: REPEAT_UNITS.WEEK,
  [SCHEDULE_FREQUENCY_INNER_PARAMS_ENTITY_KEYS.WEEKDAYS]: []
}

export const isWeeklyOrBiWeeklySchedule = (frequencyTypeId = null) => {
  return [SCHEDULE_FREQUENCIES.WEEKLY[TYPE_ID], SCHEDULE_FREQUENCIES.BI_WEEKLY[TYPE_ID]].includes(
    frequencyTypeId
  )
}

const isScheduleByNthDay = (frequencyTypeId = null) => {
  return FREQUENCIES_BY_TYPE_ID.getFrequencyByTypeId(frequencyTypeId).isScheduleByNthDay
}

const isScheduleByDayNumber = (frequencyTypeId = null) => {
  return FREQUENCIES_BY_TYPE_ID.getFrequencyByTypeId(frequencyTypeId).isScheduleByDayNumber
}

const isCustomMonthSchedule = (frequencyTypeId = null) => {
  return FREQUENCIES_BY_TYPE_ID.getFrequencyByTypeId(frequencyTypeId).isCustomMonthSchedule
}

export const isFieldAvailable = ({
  systemTypeId = SCHEDULE_FREQUENCIES.WEEKLY[TYPE_ID],
  field = SCHEDULE_FREQUENCY_INNER_PARAMS_ENTITY_KEYS.WEEKDAY
}) => {
  const frequencyByTypeId = FREQUENCIES_BY_TYPE_ID.getFrequencyByTypeId(systemTypeId)

  return frequencyByTypeId.isFieldAvailable(field)
}

export const ERROR_MESSAGE = 'Invalid frequency params'

export const getScheduleTypeIdByFrequencyParams = ({
  currentTypeId = SCHEDULE_FREQUENCIES.WEEKLY[TYPE_ID],
  frequencyParams = FREQUENCY_INNER_PARAMS
} = {}) => {
  if (isWeeklyOrBiWeeklySchedule(currentTypeId)) {
    return currentTypeId
  }
  const selectedNthWeekday =
    frequencyParams[SCHEDULE_FREQUENCY_INNER_PARAMS_ENTITY_KEYS.NTH_WEEKDAY]

  if (currentTypeId === SCHEDULE_FREQUENCIES.MONTHLY[TYPE_ID]) {
    const [systemTypeIdByParams] = [
      selectedNthWeekday === NTH_WEEKDAY_VALUES.NTH_DAY &&
        SCHEDULE_SYSTEM_FREQUENCIES.MONTHLY_BY_NTH_DAY[TYPE_ID],

      selectedNthWeekday === NTH_WEEKDAY_VALUES.DAY_NUMBER &&
        SCHEDULE_SYSTEM_FREQUENCIES.MONTHLY_BY_DAY_NUMBER[TYPE_ID]
    ].filter(Boolean)

    if (!systemTypeIdByParams) {
      throw new Error(ERROR_MESSAGE)
    }

    return systemTypeIdByParams
  }

  if (currentTypeId === SCHEDULE_FREQUENCIES.CUSTOM[TYPE_ID]) {
    const selectedRepeatUnit =
      frequencyParams[SCHEDULE_FREQUENCY_INNER_PARAMS_ENTITY_KEYS.REPEAT_UNIT]

    const [systemTypeIdByParams] = [
      selectedRepeatUnit === REPEAT_UNITS.WEEK && SCHEDULE_SYSTEM_FREQUENCIES.CUSTOM_WEEK[TYPE_ID],

      selectedRepeatUnit === REPEAT_UNITS.MONTH &&
        selectedNthWeekday === NTH_WEEKDAY_VALUES.NTH_DAY &&
        SCHEDULE_SYSTEM_FREQUENCIES.CUSTOM_MONTH_BY_NTH_DAY[TYPE_ID],

      selectedRepeatUnit === REPEAT_UNITS.MONTH &&
        selectedNthWeekday === NTH_WEEKDAY_VALUES.DAY_NUMBER &&
        SCHEDULE_SYSTEM_FREQUENCIES.CUSTOM_MONTH_BY_DAY_NUMBER[TYPE_ID]
    ].filter(Boolean)

    if (!systemTypeIdByParams) {
      throw new Error(ERROR_MESSAGE)
    }

    return systemTypeIdByParams
  }
}

export const SCHEDULE_PARAMS_KEYS_MAPPING = {
  [SCHEDULE_FREQUENCY_INNER_PARAMS_ENTITY_KEYS.WEEKDAY]: SCHEDULE_ENTITY_KEYS.WEEK_DAY_ID,
  [SCHEDULE_FREQUENCY_INNER_PARAMS_ENTITY_KEYS.NTH_WEEKDAY]:
    SCHEDULE_ENTITY_KEYS.WEEK_DAY_NTH_NUMBER,
  [SCHEDULE_FREQUENCY_INNER_PARAMS_ENTITY_KEYS.REPEAT_INTERVAL]:
    SCHEDULE_ENTITY_KEYS.REPEAT_FREQUENCY,
  [SCHEDULE_FREQUENCY_INNER_PARAMS_ENTITY_KEYS.WEEKDAYS]: SCHEDULE_ENTITY_KEYS.WEEK_DAY_IDS
}

export const createScheduleParamsPayload = ({
  scheduleData = DEFAULT_FORM_MODEL,
  frequencyParams = FREQUENCY_INNER_PARAMS,
  selectedDateDayNumber = null,
  nthDayNumber = null
} = {}) => {
  const params = {
    ...cloneDeep(frequencyParams),
    [SCHEDULE_FREQUENCY_INNER_PARAMS_ENTITY_KEYS.MONTH_DAY]: selectedDateDayNumber
  }

  const typeId = scheduleData[FREQUENCY_TYPE_ID]

  const systemTypeId = getScheduleTypeIdByFrequencyParams({
    currentTypeId: typeId,
    frequencyParams: params
  })

  const currentScheduleParams =
    FREQUENCIES_BY_TYPE_ID.getFrequencyByTypeId(systemTypeId).fieldsForPayloadGeneration

  for (const key in params) {
    if (!currentScheduleParams.includes(key)) {
      delete params[key]
    }
  }

  for (const key in SCHEDULE_PARAMS_KEYS_MAPPING) {
    let normalizedValue = params[key]

    if (key === SCHEDULE_FREQUENCY_INNER_PARAMS_ENTITY_KEYS.NTH_WEEKDAY) {
      normalizedValue = nthDayNumber
    }

    if (has(params, key)) {
      params[SCHEDULE_PARAMS_KEYS_MAPPING[key]] = normalizedValue
      delete params[key]
    }
  }

  const normalizedDate = dayjs(scheduleData[START_FROM]).startOf('day')

  const name = scheduleData[NAME].trim().slice(0, SCHEDULE_NAME_MAX_LENGTH).trim()

  return {
    [NAME]: isStringEmpty(name) ? DEFAULT_SCHEDULE_NAME : name,
    [START_FROM]: Number(localDateToUtc(normalizedDate)),
    [FREQUENCY_TYPE_ID]: systemTypeId,
    [PARAMS]: params
  }
}

export const parseNotificationsPayloadToFormModel = ({
  notifications = [
    {
      typeId: NOTIFICATION_CHANNELS.INBOX.typeId,
      attributes: []
    }
  ]
} = {}) => {
  const selectedChannels = uniq([
    NOTIFICATION_CHANNELS.INBOX.typeId,
    ...notifications.map(({ typeId }) => typeId)
  ])

  const channelsAttributes = Object.values(notifications).reduce((acc, chanel) => {
    return {
      ...acc,
      [chanel.typeId]: mapValues(
        groupBy(chanel.attributes, NOTIFICATION_CHANNEL_ENTITY_KEYS.TYPE_ID),
        group => group.map(({ id }) => id)
      )
    }
  }, {})

  return {
    selectedChannels: [...selectedChannels],
    channelsAttributes: {
      ...DEFAULT_NOTIFICATIONS.channelsAttributes,
      ...channelsAttributes
    }
  }
}

export const parsePayloadToFormModel = ({ payload = {} } = {}) => {
  const params = clone(payload[PARAMS])
  const typeId = payload[FREQUENCY_TYPE_ID]

  const defaultFrequencyParams = cloneDeep(FREQUENCY_INNER_PARAMS)

  for (const key in defaultFrequencyParams) {
    const mappedKey = SCHEDULE_PARAMS_KEYS_MAPPING[key] || key
    let resolvedValue = params[mappedKey] || FREQUENCY_INNER_PARAMS[key]

    if (key === SCHEDULE_FREQUENCY_INNER_PARAMS_ENTITY_KEYS.NTH_WEEKDAY) {
      if (isScheduleByNthDay(typeId)) {
        resolvedValue = NTH_WEEKDAY_VALUES.NTH_DAY
      }

      if (isScheduleByDayNumber(typeId)) {
        resolvedValue = NTH_WEEKDAY_VALUES.DAY_NUMBER
      }
    }

    if (
      key === SCHEDULE_FREQUENCY_INNER_PARAMS_ENTITY_KEYS.REPEAT_UNIT &&
      isCustomMonthSchedule(typeId)
    ) {
      resolvedValue = REPEAT_UNITS.MONTH
    }

    defaultFrequencyParams[key] = resolvedValue
  }

  return {
    generalData: {
      [NAME]: payload[NAME],
      [START_FROM]: utcDateToLocal(dayjs(payload[START_FROM]).toDate()),
      [FREQUENCY_TYPE_ID]: FREQUENCIES_BY_TYPE_ID.getFrequencyByTypeId(typeId).resolvedTypeId
    },
    frequencyParamsData: defaultFrequencyParams,
    remindersData: cloneDeep(payload[REMINDERS]).map(reminder => {
      return {
        ...reminder,
        [REMINDER_ENTITY_KEYS.ID]: uid()
      }
    }),
    notificationsData: parseNotificationsPayloadToFormModel({
      notifications: cloneDeep(payload[SCHEDULE_ENTITY_KEYS.NOTIFICATION_CHANNEL_IDS])
    }),
    participantsData: parseParticipantsPayloadToFromModel({
      participants: cloneDeep(payload[SCHEDULE_ENTITY_KEYS.PARTICIPANTS])
    })
  }
}

const isValidNumber = n => {
  return Number.isFinite(n) && !Number.isNaN(n) && Number.isInteger(n)
}

/**
 * @param {number} min
 * @param {number} max
 * @param {number} step
 * @returns {number[]}
 */
export const createRangeValues = ({ min = 0, max = 100, step = 1 } = {}) => {
  if (!isValidNumber(min) || !isValidNumber(max) || !isValidNumber(step)) {
    throw new Error('Invalid range values')
  }

  if (max > 9999) {
    throw new Error('Max value cannot be greater than 9999')
  }

  if (min > max) {
    throw new Error('Min value cannot be greater than max value')
  }

  if (step <= 0 || min + step > max) {
    throw new Error('Invalid step value')
  }

  const arrayLength = Math.floor((max - min) / step) + 1 // +1 to include max value
  return Array.from({ length: arrayLength }, (_, index) => {
    return min + index * step
  })
}

export const TIME_COUNT = 60

export const REMINDER_TIME_OF_DAY_OPTIONS = createRangeValues({
  min: 0,
  max: 23 * TIME_COUNT, // 23 hours in minutes
  step: 60 // 60 minutes
}).map(minutes => {
  return {
    isDefault: minutes === 10 * TIME_COUNT, // 10:00
    label: dayjs().hour(0).minute(0).add(minutes, 'minute').format('h:mm a'),
    value: minutes
  }
})

export const REMINDER_EXECUTION_DAY_OPTIONS = {
  DUE_DAY: {
    [REMINDER_ENTITY_KEYS.VALUE]: 0,
    [REMINDER_ENTITY_KEYS.LABEL]: t('schedule.due_day')
  },

  DAY_BEFORE: {
    [REMINDER_ENTITY_KEYS.VALUE]: -1,
    [REMINDER_ENTITY_KEYS.LABEL]: t('schedule.day_before_due')
  }
}

export const DEFAULT_REMINDER = {
  [REMINDER_ENTITY_KEYS.ID]: '4qi9hf0yx',
  [REMINDER_ENTITY_KEYS.DAYS_SHIFT]:
    REMINDER_EXECUTION_DAY_OPTIONS.DUE_DAY[REMINDER_ENTITY_KEYS.VALUE],
  [REMINDER_ENTITY_KEYS.MINUTES]:
    REMINDER_TIME_OF_DAY_OPTIONS.find(({ isDefault }) => isDefault)?.value || 0,
  [REMINDER_ENTITY_KEYS.TIMEZONE_ID]: 0 // UTC
}

export const DEFAULT_REMINDERS = [clone(DEFAULT_REMINDER)]

export const createRemindersPayload = ({ reminders = DEFAULT_REMINDERS } = {}) => {
  return reminders.map(rm => omit(rm, [REMINDER_ENTITY_KEYS.ID]))
}

export const SCHEDULE_NOTIFICATION_CHANNELS = {
  INBOX: {
    [NOTIFICATION_CHANNEL_ENTITY_KEYS.VALUE]: NOTIFICATION_CHANNELS.INBOX.typeId,
    [NOTIFICATION_CHANNEL_ENTITY_KEYS.LABEL]: t('schedule.notifications.in_app'),
    [NOTIFICATION_CHANNEL_ENTITY_KEYS.DISABLED]: true,
    [NOTIFICATION_CHANNEL_ENTITY_KEYS.ENABLED_BY_DEFAULT]: true
  },
  EMAIL: {
    [NOTIFICATION_CHANNEL_ENTITY_KEYS.VALUE]: NOTIFICATION_CHANNELS.EMAIL.typeId,
    [NOTIFICATION_CHANNEL_ENTITY_KEYS.LABEL]: t('user.preferences.user.email.label'),
    [NOTIFICATION_CHANNEL_ENTITY_KEYS.ENABLED_BY_DEFAULT]: true
  },
  SLACK: {
    [NOTIFICATION_CHANNEL_ENTITY_KEYS.VALUE]: NOTIFICATION_CHANNELS.SLACK.typeId,
    [NOTIFICATION_CHANNEL_ENTITY_KEYS.LABEL]: t('schedule.notifications.slack')
  },
  TEAMS: {
    [NOTIFICATION_CHANNEL_ENTITY_KEYS.VALUE]: NOTIFICATION_CHANNELS.TEAMS.typeId,
    [NOTIFICATION_CHANNEL_ENTITY_KEYS.LABEL]: t('schedule.notifications.teams')
  },
  DISCORD: {
    [NOTIFICATION_CHANNEL_ENTITY_KEYS.VALUE]: NOTIFICATION_CHANNELS.DISCORD.typeId,
    [NOTIFICATION_CHANNEL_ENTITY_KEYS.LABEL]: t('schedule.notifications.discord')
  }
}

export const DEFAULT_NOTIFICATIONS = {
  selectedChannels: Object.values(SCHEDULE_NOTIFICATION_CHANNELS).reduce((acc, ch) => {
    if (ch[NOTIFICATION_CHANNEL_ENTITY_KEYS.ENABLED_BY_DEFAULT]) {
      return [...acc, ch[NOTIFICATION_CHANNEL_ENTITY_KEYS.VALUE]]
    }
    return acc
  }, []),
  channelsAttributes: Object.values(SCHEDULE_NOTIFICATION_CHANNELS).reduce((acc, { value }) => {
    const channel =
      Object.values(NOTIFICATION_CHANNELS).find(ch => ch.typeId === value) ||
      NOTIFICATION_CHANNELS.INBOX

    const attributes = Object.entries(channel.attributes).reduce(
      (attributesAcc, [typeId, attribute]) => {
        return {
          ...attributesAcc,
          [typeId]: cloneDeep(attribute.defaultValue)
        }
      },
      {}
    )

    return { ...acc, [value]: attributes }
  }, {})
}

export const createNotificationsPayload = ({
  selectedChannels = DEFAULT_NOTIFICATIONS.selectedChannels,
  channelsAttributes = DEFAULT_NOTIFICATIONS.channelsAttributes
} = {}) => {
  const channelsWithAttributes = uniq([
    SCHEDULE_NOTIFICATION_CHANNELS.INBOX.value,
    ...selectedChannels
  ]).map(channelId => {
    const allAttributes = channelsAttributes[channelId] || {}

    const normalizedAttributes = flatMap(allAttributes, (values, typeId) => {
      return values.map(value => {
        return {
          [NOTIFICATION_CHANNEL_ENTITY_KEYS.TYPE_ID]: Number(typeId),
          [NOTIFICATION_CHANNEL_ENTITY_KEYS.ID]: value
        }
      })
    })

    return {
      [NOTIFICATION_CHANNEL_ENTITY_KEYS.TYPE_ID]: channelId,
      [NOTIFICATION_CHANNEL_ENTITY_KEYS.ATTRIBUTES]: normalizedAttributes
    }
  })

  return channelsWithAttributes.filter(item => {
    if (ALLOWED_MESSENGERS_TYPE_IDS.includes(item[NOTIFICATION_CHANNEL_ENTITY_KEYS.TYPE_ID])) {
      return !isEmpty(item[NOTIFICATION_CHANNEL_ENTITY_KEYS.ATTRIBUTES])
    }

    return true
  })
}
