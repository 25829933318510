import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

dayjs.extend(timezone)
dayjs.extend(utc)

export const timezonesOptions = [
  { name: 'UTC', value: 0, label: 'UTC (GMT+0)' },
  { name: 'Africa/Accra', value: 1, label: 'Africa/Accra (GMT+0)' },
  { name: 'Africa/Addis_Ababa', value: 2, label: 'Africa/Addis_Ababa (GMT+3)' },
  { name: 'Africa/Algiers', value: 3, label: 'Africa/Algiers (GMT+1)' },
  { name: 'Africa/Asmara', value: 4, label: 'Africa/Asmara (GMT+3)' },
  { name: 'Africa/Asmera', value: 5, label: 'Africa/Asmera (GMT+3)' },
  { name: 'Africa/Bamako', value: 6, label: 'Africa/Bamako (GMT+0)' },
  { name: 'Africa/Bangui', value: 7, label: 'Africa/Bangui (GMT+1)' },
  { name: 'Africa/Banjul', value: 8, label: 'Africa/Banjul (GMT+0)' },
  { name: 'Africa/Bissau', value: 9, label: 'Africa/Bissau (GMT+0)' },
  { name: 'Africa/Blantyre', value: 10, label: 'Africa/Blantyre (GMT+2)' },
  { name: 'Africa/Brazzaville', value: 11, label: 'Africa/Brazzaville (GMT+1)' },
  { name: 'Africa/Bujumbura', value: 12, label: 'Africa/Bujumbura (GMT+2)' },
  { name: 'Africa/Cairo', value: 13, label: 'Africa/Cairo (GMT+2)' },
  { name: 'Africa/Casablanca', value: 14, label: 'Africa/Casablanca (GMT+1)' },
  { name: 'Africa/Ceuta', value: 15, label: 'Africa/Ceuta (GMT+2)' },
  { name: 'Africa/Conakry', value: 16, label: 'Africa/Conakry (GMT+0)' },
  { name: 'Africa/Dakar', value: 17, label: 'Africa/Dakar (GMT+0)' },
  { name: 'Africa/Dar_es_Salaam', value: 18, label: 'Africa/Dar_es_Salaam (GMT+3)' },
  { name: 'Africa/Djibouti', value: 19, label: 'Africa/Djibouti (GMT+3)' },
  { name: 'Africa/Douala', value: 20, label: 'Africa/Douala (GMT+1)' },
  { name: 'Africa/El_Aaiun', value: 21, label: 'Africa/El_Aaiun (GMT+1)' },
  { name: 'Africa/Freetown', value: 22, label: 'Africa/Freetown (GMT+0)' },
  { name: 'Africa/Gaborone', value: 23, label: 'Africa/Gaborone (GMT+2)' },
  { name: 'Africa/Harare', value: 24, label: 'Africa/Harare (GMT+2)' },
  { name: 'Africa/Johannesburg', value: 25, label: 'Africa/Johannesburg (GMT+2)' },
  { name: 'Africa/Juba', value: 26, label: 'Africa/Juba (GMT+2)' },
  { name: 'Africa/Kampala', value: 27, label: 'Africa/Kampala (GMT+3)' },
  { name: 'Africa/Khartoum', value: 28, label: 'Africa/Khartoum (GMT+2)' },
  { name: 'Africa/Kigali', value: 29, label: 'Africa/Kigali (GMT+2)' },
  { name: 'Africa/Kinshasa', value: 30, label: 'Africa/Kinshasa (GMT+1)' },
  { name: 'Africa/Lagos', value: 31, label: 'Africa/Lagos (GMT+1)' },
  { name: 'Africa/Libreville', value: 32, label: 'Africa/Libreville (GMT+1)' },
  { name: 'Africa/Lome', value: 33, label: 'Africa/Lome (GMT+0)' },
  { name: 'Africa/Luanda', value: 34, label: 'Africa/Luanda (GMT+1)' },
  { name: 'Africa/Lubumbashi', value: 35, label: 'Africa/Lubumbashi (GMT+2)' },
  { name: 'Africa/Lusaka', value: 36, label: 'Africa/Lusaka (GMT+2)' },
  { name: 'Africa/Malabo', value: 37, label: 'Africa/Malabo (GMT+1)' },
  { name: 'Africa/Maputo', value: 38, label: 'Africa/Maputo (GMT+2)' },
  { name: 'Africa/Maseru', value: 39, label: 'Africa/Maseru (GMT+2)' },
  { name: 'Africa/Mbabane', value: 40, label: 'Africa/Mbabane (GMT+2)' },
  { name: 'Africa/Mogadishu', value: 41, label: 'Africa/Mogadishu (GMT+3)' },
  { name: 'Africa/Monrovia', value: 42, label: 'Africa/Monrovia (GMT+0)' },
  { name: 'Africa/Nairobi', value: 43, label: 'Africa/Nairobi (GMT+3)' },
  { name: 'Africa/Ndjamena', value: 44, label: 'Africa/Ndjamena (GMT+1)' },
  { name: 'Africa/Niamey', value: 45, label: 'Africa/Niamey (GMT+1)' },
  { name: 'Africa/Nouakchott', value: 46, label: 'Africa/Nouakchott (GMT+0)' },
  { name: 'Africa/Ouagadougou', value: 47, label: 'Africa/Ouagadougou (GMT+0)' },
  { name: 'Africa/Porto-Novo', value: 48, label: 'Africa/Porto-Novo (GMT+1)' },
  { name: 'Africa/Sao_Tome', value: 49, label: 'Africa/Sao_Tome (GMT+0)' },
  { name: 'Africa/Timbuktu', value: 50, label: 'Africa/Timbuktu (GMT+0)' },
  { name: 'Africa/Tripoli', value: 51, label: 'Africa/Tripoli (GMT+2)' },
  { name: 'Africa/Tunis', value: 52, label: 'Africa/Tunis (GMT+1)' },
  { name: 'Africa/Windhoek', value: 53, label: 'Africa/Windhoek (GMT+2)' },
  { name: 'America/Adak', value: 54, label: 'America/Adak (GMT-9)' },
  { name: 'America/Anchorage', value: 55, label: 'America/Anchorage (GMT-8)' },
  { name: 'America/Anguilla', value: 56, label: 'America/Anguilla (GMT-4)' },
  { name: 'America/Antigua', value: 57, label: 'America/Antigua (GMT-4)' },
  { name: 'America/Araguaina', value: 58, label: 'America/Araguaina (GMT-3)' },
  {
    name: 'America/Argentina/Buenos_Aires',
    value: 59,
    label: 'America/Argentina/Buenos_Aires (GMT-3)'
  },
  { name: 'America/Argentina/Catamarca', value: 60, label: 'America/Argentina/Catamarca (GMT-3)' },
  {
    name: 'America/Argentina/ComodRivadavia',
    value: 61,
    label: 'America/Argentina/ComodRivadavia (GMT-3)'
  },
  { name: 'America/Argentina/Cordoba', value: 62, label: 'America/Argentina/Cordoba (GMT-3)' },
  { name: 'America/Argentina/Jujuy', value: 63, label: 'America/Argentina/Jujuy (GMT-3)' },
  { name: 'America/Argentina/La_Rioja', value: 64, label: 'America/Argentina/La_Rioja (GMT-3)' },
  { name: 'America/Argentina/Mendoza', value: 65, label: 'America/Argentina/Mendoza (GMT-3)' },
  {
    name: 'America/Argentina/Rio_Gallegos',
    value: 66,
    label: 'America/Argentina/Rio_Gallegos (GMT-3)'
  },
  { name: 'America/Argentina/Salta', value: 67, label: 'America/Argentina/Salta (GMT-3)' },
  { name: 'America/Argentina/San_Juan', value: 68, label: 'America/Argentina/San_Juan (GMT-3)' },
  { name: 'America/Argentina/San_Luis', value: 69, label: 'America/Argentina/San_Luis (GMT-3)' },
  { name: 'America/Argentina/Tucuman', value: 70, label: 'America/Argentina/Tucuman (GMT-3)' },
  { name: 'America/Argentina/Ushuaia', value: 71, label: 'America/Argentina/Ushuaia (GMT-3)' },
  { name: 'America/Aruba', value: 72, label: 'America/Aruba (GMT-4)' },
  { name: 'America/Asuncion', value: 73, label: 'America/Asuncion (GMT-4)' },
  { name: 'America/Atikokan', value: 74, label: 'America/Atikokan (GMT-5)' },
  { name: 'America/Atka', value: 75, label: 'America/Atka (GMT-9)' },
  { name: 'America/Bahia', value: 76, label: 'America/Bahia (GMT-3)' },
  { name: 'America/Bahia_Banderas', value: 77, label: 'America/Bahia_Banderas (GMT-5)' },
  { name: 'America/Barbados', value: 78, label: 'America/Barbados (GMT-4)' },
  { name: 'America/Belem', value: 79, label: 'America/Belem (GMT-3)' },
  { name: 'America/Belize', value: 80, label: 'America/Belize (GMT-6)' },
  { name: 'America/Blanc-Sablon', value: 81, label: 'America/Blanc-Sablon (GMT-4)' },
  { name: 'America/Boa_Vista', value: 82, label: 'America/Boa_Vista (GMT-4)' },
  { name: 'America/Bogota', value: 83, label: 'America/Bogota (GMT-5)' },
  { name: 'America/Boise', value: 84, label: 'America/Boise (GMT-6)' },
  { name: 'America/Buenos_Aires', value: 85, label: 'America/Buenos_Aires (GMT-3)' },
  { name: 'America/Cambridge_Bay', value: 86, label: 'America/Cambridge_Bay (GMT-6)' },
  { name: 'America/Campo_Grande', value: 87, label: 'America/Campo_Grande (GMT-4)' },
  { name: 'America/Cancun', value: 88, label: 'America/Cancun (GMT-5)' },
  { name: 'America/Caracas', value: 89, label: 'America/Caracas (GMT-4)' },
  { name: 'America/Catamarca', value: 90, label: 'America/Catamarca (GMT-3)' },
  { name: 'America/Cayenne', value: 91, label: 'America/Cayenne (GMT-3)' },
  { name: 'America/Cayman', value: 92, label: 'America/Cayman (GMT-5)' },
  { name: 'America/Chicago', value: 93, label: 'America/Chicago (GMT-5)' },
  { name: 'America/Chihuahua', value: 94, label: 'America/Chihuahua (GMT-6)' },
  { name: 'America/Coral_Harbour', value: 95, label: 'America/Coral_Harbour (GMT-5)' },
  { name: 'America/Cordoba', value: 96, label: 'America/Cordoba (GMT-3)' },
  { name: 'America/Costa_Rica', value: 97, label: 'America/Costa_Rica (GMT-6)' },
  { name: 'America/Creston', value: 98, label: 'America/Creston (GMT-7)' },
  { name: 'America/Cuiaba', value: 99, label: 'America/Cuiaba (GMT-4)' },
  { name: 'America/Curacao', value: 100, label: 'America/Curacao (GMT-4)' },
  { name: 'America/Danmarkshavn', value: 101, label: 'America/Danmarkshavn (GMT+0)' },
  { name: 'America/Dawson', value: 102, label: 'America/Dawson (GMT-7)' },
  { name: 'America/Dawson_Creek', value: 103, label: 'America/Dawson_Creek (GMT-7)' },
  { name: 'America/Denver', value: 104, label: 'America/Denver (GMT-6)' },
  { name: 'America/Detroit', value: 105, label: 'America/Detroit (GMT-4)' },
  { name: 'America/Dominica', value: 106, label: 'America/Dominica (GMT-4)' },
  { name: 'America/Edmonton', value: 107, label: 'America/Edmonton (GMT-6)' },
  { name: 'America/Eirunepe', value: 108, label: 'America/Eirunepe (GMT-5)' },
  { name: 'America/El_Salvador', value: 109, label: 'America/El_Salvador (GMT-6)' },
  { name: 'America/Ensenada', value: 110, label: 'America/Ensenada (GMT-7)' },
  { name: 'America/Fort_Wayne', value: 111, label: 'America/Fort_Wayne (GMT-4)' },
  { name: 'America/Fortaleza', value: 112, label: 'America/Fortaleza (GMT-3)' },
  { name: 'America/Glace_Bay', value: 113, label: 'America/Glace_Bay (GMT-3)' },
  { name: 'America/Godthab', value: 114, label: 'America/Godthab (GMT-2)' },
  { name: 'America/Goose_Bay', value: 115, label: 'America/Goose_Bay (GMT-3)' },
  { name: 'America/Grand_Turk', value: 116, label: 'America/Grand_Turk (GMT-4)' },
  { name: 'America/Grenada', value: 117, label: 'America/Grenada (GMT-4)' },
  { name: 'America/Guadeloupe', value: 118, label: 'America/Guadeloupe (GMT-4)' },
  { name: 'America/Guatemala', value: 119, label: 'America/Guatemala (GMT-6)' },
  { name: 'America/Guayaquil', value: 120, label: 'America/Guayaquil (GMT-5)' },
  { name: 'America/Guyana', value: 121, label: 'America/Guyana (GMT-4)' },
  { name: 'America/Halifax', value: 122, label: 'America/Halifax (GMT-3)' },
  { name: 'America/Havana', value: 123, label: 'America/Havana (GMT-4)' },
  { name: 'America/Hermosillo', value: 124, label: 'America/Hermosillo (GMT-7)' },
  {
    name: 'America/Indiana/Indianapolis',
    value: 125,
    label: 'America/Indiana/Indianapolis (GMT-4)'
  },
  { name: 'America/Indiana/Knox', value: 126, label: 'America/Indiana/Knox (GMT-5)' },
  { name: 'America/Indiana/Marengo', value: 127, label: 'America/Indiana/Marengo (GMT-4)' },
  { name: 'America/Indiana/Petersburg', value: 128, label: 'America/Indiana/Petersburg (GMT-4)' },
  { name: 'America/Indiana/Tell_City', value: 129, label: 'America/Indiana/Tell_City (GMT-5)' },
  { name: 'America/Indiana/Vevay', value: 130, label: 'America/Indiana/Vevay (GMT-4)' },
  { name: 'America/Indiana/Vincennes', value: 131, label: 'America/Indiana/Vincennes (GMT-4)' },
  { name: 'America/Indiana/Winamac', value: 132, label: 'America/Indiana/Winamac (GMT-4)' },
  { name: 'America/Indianapolis', value: 133, label: 'America/Indianapolis (GMT-4)' },
  { name: 'America/Inuvik', value: 134, label: 'America/Inuvik (GMT-6)' },
  { name: 'America/Iqaluit', value: 135, label: 'America/Iqaluit (GMT-4)' },
  { name: 'America/Jamaica', value: 136, label: 'America/Jamaica (GMT-5)' },
  { name: 'America/Jujuy', value: 137, label: 'America/Jujuy (GMT-3)' },
  { name: 'America/Juneau', value: 138, label: 'America/Juneau (GMT-8)' },
  { name: 'America/Kentucky/Louisville', value: 139, label: 'America/Kentucky/Louisville (GMT-4)' },
  { name: 'America/Kentucky/Monticello', value: 140, label: 'America/Kentucky/Monticello (GMT-4)' },
  { name: 'America/Knox_IN', value: 141, label: 'America/Knox_IN (GMT-5)' },
  { name: 'America/Kralendijk', value: 142, label: 'America/Kralendijk (GMT-4)' },
  { name: 'America/La_Paz', value: 143, label: 'America/La_Paz (GMT-4)' },
  { name: 'America/Lima', value: 144, label: 'America/Lima (GMT-5)' },
  { name: 'America/Los_Angeles', value: 145, label: 'America/Los_Angeles (GMT-7)' },
  { name: 'America/Louisville', value: 146, label: 'America/Louisville (GMT-4)' },
  { name: 'America/Lower_Princes', value: 147, label: 'America/Lower_Princes (GMT-4)' },
  { name: 'America/Maceio', value: 148, label: 'America/Maceio (GMT-3)' },
  { name: 'America/Managua', value: 149, label: 'America/Managua (GMT-6)' },
  { name: 'America/Manaus', value: 150, label: 'America/Manaus (GMT-4)' },
  { name: 'America/Marigot', value: 151, label: 'America/Marigot (GMT-4)' },
  { name: 'America/Martinique', value: 152, label: 'America/Martinique (GMT-4)' },
  { name: 'America/Matamoros', value: 153, label: 'America/Matamoros (GMT-5)' },
  { name: 'America/Mazatlan', value: 154, label: 'America/Mazatlan (GMT-6)' },
  { name: 'America/Mendoza', value: 155, label: 'America/Mendoza (GMT-3)' },
  { name: 'America/Menominee', value: 156, label: 'America/Menominee (GMT-5)' },
  { name: 'America/Merida', value: 157, label: 'America/Merida (GMT-5)' },
  { name: 'America/Metlakatla', value: 158, label: 'America/Metlakatla (GMT-8)' },
  { name: 'America/Mexico_City', value: 159, label: 'America/Mexico_City (GMT-5)' },
  { name: 'America/Miquelon', value: 160, label: 'America/Miquelon (GMT-2)' },
  { name: 'America/Moncton', value: 161, label: 'America/Moncton (GMT-3)' },
  { name: 'America/Monterrey', value: 162, label: 'America/Monterrey (GMT-5)' },
  { name: 'America/Montevideo', value: 163, label: 'America/Montevideo (GMT-3)' },
  { name: 'America/Montreal', value: 164, label: 'America/Montreal (GMT-4)' },
  { name: 'America/Montserrat', value: 165, label: 'America/Montserrat (GMT-4)' },
  { name: 'America/Nassau', value: 166, label: 'America/Nassau (GMT-4)' },
  { name: 'America/New_York', value: 167, label: 'America/New_York (GMT-4)' },
  { name: 'America/Nipigon', value: 168, label: 'America/Nipigon (GMT-4)' },
  { name: 'America/Nome', value: 169, label: 'America/Nome (GMT-8)' },
  { name: 'America/Noronha', value: 170, label: 'America/Noronha (GMT-2)' },
  { name: 'America/North_Dakota/Beulah', value: 171, label: 'America/North_Dakota/Beulah (GMT-5)' },
  { name: 'America/North_Dakota/Center', value: 172, label: 'America/North_Dakota/Center (GMT-5)' },
  {
    name: 'America/North_Dakota/New_Salem',
    value: 173,
    label: 'America/North_Dakota/New_Salem (GMT-5)'
  },
  { name: 'America/Ojinaga', value: 174, label: 'America/Ojinaga (GMT-6)' },
  { name: 'America/Panama', value: 175, label: 'America/Panama (GMT-5)' },
  { name: 'America/Pangnirtung', value: 176, label: 'America/Pangnirtung (GMT-4)' },
  { name: 'America/Paramaribo', value: 177, label: 'America/Paramaribo (GMT-3)' },
  { name: 'America/Phoenix', value: 178, label: 'America/Phoenix (GMT-7)' },
  { name: 'America/Port-au-Prince', value: 179, label: 'America/Port-au-Prince (GMT-4)' },
  { name: 'America/Port_of_Spain', value: 180, label: 'America/Port_of_Spain (GMT-4)' },
  { name: 'America/Porto_Acre', value: 181, label: 'America/Porto_Acre (GMT-5)' },
  { name: 'America/Porto_Velho', value: 182, label: 'America/Porto_Velho (GMT-4)' },
  { name: 'America/Puerto_Rico', value: 183, label: 'America/Puerto_Rico (GMT-4)' },
  { name: 'America/Rainy_River', value: 184, label: 'America/Rainy_River (GMT-5)' },
  { name: 'America/Rankin_Inlet', value: 185, label: 'America/Rankin_Inlet (GMT-5)' },
  { name: 'America/Recife', value: 186, label: 'America/Recife (GMT-3)' },
  { name: 'America/Regina', value: 187, label: 'America/Regina (GMT-6)' },
  { name: 'America/Resolute', value: 188, label: 'America/Resolute (GMT-5)' },
  { name: 'America/Rio_Branco', value: 189, label: 'America/Rio_Branco (GMT-5)' },
  { name: 'America/Rosario', value: 190, label: 'America/Rosario (GMT-3)' },
  { name: 'America/Santa_Isabel', value: 191, label: 'America/Santa_Isabel (GMT-7)' },
  { name: 'America/Santarem', value: 192, label: 'America/Santarem (GMT-3)' },
  { name: 'America/Santiago', value: 193, label: 'America/Santiago (GMT-4)' },
  { name: 'America/Santo_Domingo', value: 194, label: 'America/Santo_Domingo (GMT-4)' },
  { name: 'America/Sao_Paulo', value: 195, label: 'America/Sao_Paulo (GMT-3)' },
  { name: 'America/Scoresbysund', value: 196, label: 'America/Scoresbysund (GMT+0)' },
  { name: 'America/Shiprock', value: 197, label: 'America/Shiprock (GMT-6)' },
  { name: 'America/Sitka', value: 198, label: 'America/Sitka (GMT-8)' },
  { name: 'America/St_Barthelemy', value: 199, label: 'America/St_Barthelemy (GMT-4)' },
  { name: 'America/St_Johns', value: 200, label: 'America/St_Johns (GMT-2.5)' },
  { name: 'America/St_Kitts', value: 201, label: 'America/St_Kitts (GMT-4)' },
  { name: 'America/St_Lucia', value: 202, label: 'America/St_Lucia (GMT-4)' },
  { name: 'America/St_Thomas', value: 203, label: 'America/St_Thomas (GMT-4)' },
  { name: 'America/St_Vincent', value: 204, label: 'America/St_Vincent (GMT-4)' },
  { name: 'America/Swift_Current', value: 205, label: 'America/Swift_Current (GMT-6)' },
  { name: 'America/Tegucigalpa', value: 206, label: 'America/Tegucigalpa (GMT-6)' },
  { name: 'America/Thule', value: 207, label: 'America/Thule (GMT-3)' },
  { name: 'America/Thunder_Bay', value: 208, label: 'America/Thunder_Bay (GMT-4)' },
  { name: 'America/Tijuana', value: 209, label: 'America/Tijuana (GMT-7)' },
  { name: 'America/Toronto', value: 210, label: 'America/Toronto (GMT-4)' },
  { name: 'America/Tortola', value: 211, label: 'America/Tortola (GMT-4)' },
  { name: 'America/Vancouver', value: 212, label: 'America/Vancouver (GMT-7)' },
  { name: 'America/Virgin', value: 213, label: 'America/Virgin (GMT-4)' },
  { name: 'America/Whitehorse', value: 214, label: 'America/Whitehorse (GMT-7)' },
  { name: 'America/Winnipeg', value: 215, label: 'America/Winnipeg (GMT-5)' },
  { name: 'America/Yakutat', value: 216, label: 'America/Yakutat (GMT-8)' },
  { name: 'America/Yellowknife', value: 217, label: 'America/Yellowknife (GMT-6)' },
  { name: 'Antarctica/Casey', value: 218, label: 'Antarctica/Casey (GMT+11)' },
  { name: 'Antarctica/Davis', value: 219, label: 'Antarctica/Davis (GMT+7)' },
  { name: 'Antarctica/DumontDUrville', value: 220, label: 'Antarctica/DumontDUrville (GMT+10)' },
  { name: 'Antarctica/Macquarie', value: 221, label: 'Antarctica/Macquarie (GMT+10)' },
  { name: 'Antarctica/Mawson', value: 222, label: 'Antarctica/Mawson (GMT+5)' },
  { name: 'Antarctica/McMurdo', value: 223, label: 'Antarctica/McMurdo (GMT+12)' },
  { name: 'Antarctica/Palmer', value: 224, label: 'Antarctica/Palmer (GMT-3)' },
  { name: 'Antarctica/Rothera', value: 225, label: 'Antarctica/Rothera (GMT-3)' },
  { name: 'Antarctica/South_Pole', value: 226, label: 'Antarctica/South_Pole (GMT+12)' },
  { name: 'Antarctica/Syowa', value: 227, label: 'Antarctica/Syowa (GMT+3)' },
  { name: 'Antarctica/Troll', value: 228, label: 'Antarctica/Troll (GMT+2)' },
  { name: 'Arctic/Longyearbyen', value: 230, label: 'Arctic/Longyearbyen (GMT+2)' },
  { name: 'Asia/Aden', value: 231, label: 'Asia/Aden (GMT+3)' },
  { name: 'Asia/Almaty', value: 232, label: 'Asia/Almaty (GMT+6)' },
  { name: 'Asia/Amman', value: 233, label: 'Asia/Amman (GMT+3)' },
  { name: 'Asia/Anadyr', value: 234, label: 'Asia/Anadyr (GMT+12)' },
  { name: 'Asia/Aqtau', value: 235, label: 'Asia/Aqtau (GMT+5)' },
  { name: 'Asia/Aqtobe', value: 236, label: 'Asia/Aqtobe (GMT+5)' },
  { name: 'Asia/Ashgabat', value: 237, label: 'Asia/Ashgabat (GMT+5)' },
  { name: 'Asia/Ashkhabad', value: 238, label: 'Asia/Ashkhabad (GMT+5)' },
  { name: 'Asia/Baghdad', value: 239, label: 'Asia/Baghdad (GMT+3)' },
  { name: 'Asia/Bahrain', value: 240, label: 'Asia/Bahrain (GMT+3)' },
  { name: 'Asia/Baku', value: 241, label: 'Asia/Baku (GMT+4)' },
  { name: 'Asia/Bangkok', value: 242, label: 'Asia/Bangkok (GMT+7)' },
  { name: 'Asia/Beirut', value: 243, label: 'Asia/Beirut (GMT+3)' },
  { name: 'Asia/Bishkek', value: 244, label: 'Asia/Bishkek (GMT+6)' },
  { name: 'Asia/Brunei', value: 245, label: 'Asia/Brunei (GMT+8)' },
  { name: 'Asia/Calcutta', value: 246, label: 'Asia/Calcutta (GMT+5.5)' },
  { name: 'Asia/Choibalsan', value: 248, label: 'Asia/Choibalsan (GMT+8)' },
  { name: 'Asia/Chongqing', value: 249, label: 'Asia/Chongqing (GMT+8)' },
  { name: 'Asia/Chungking', value: 250, label: 'Asia/Chungking (GMT+8)' },
  { name: 'Asia/Colombo', value: 251, label: 'Asia/Colombo (GMT+5.5)' },
  { name: 'Asia/Dacca', value: 252, label: 'Asia/Dacca (GMT+6)' },
  { name: 'Asia/Damascus', value: 253, label: 'Asia/Damascus (GMT+3)' },
  { name: 'Asia/Dhaka', value: 254, label: 'Asia/Dhaka (GMT+6)' },
  { name: 'Asia/Dili', value: 255, label: 'Asia/Dili (GMT+9)' },
  { name: 'Asia/Dubai', value: 256, label: 'Asia/Dubai (GMT+4)' },
  { name: 'Asia/Dushanbe', value: 257, label: 'Asia/Dushanbe (GMT+5)' },
  { name: 'Asia/Gaza', value: 258, label: 'Asia/Gaza (GMT+3)' },
  { name: 'Asia/Harbin', value: 259, label: 'Asia/Harbin (GMT+8)' },
  { name: 'Asia/Hebron', value: 260, label: 'Asia/Hebron (GMT+3)' },
  { name: 'Asia/Ho_Chi_Minh', value: 261, label: 'Asia/Ho_Chi_Minh (GMT+7)' },
  { name: 'Asia/Hong_Kong', value: 262, label: 'Asia/Hong_Kong (GMT+8)' },
  { name: 'Asia/Hovd', value: 263, label: 'Asia/Hovd (GMT+7)' },
  { name: 'Asia/Istanbul', value: 265, label: 'Asia/Istanbul (GMT+3)' },
  { name: 'Asia/Jakarta', value: 266, label: 'Asia/Jakarta (GMT+7)' },
  { name: 'Asia/Jayapura', value: 267, label: 'Asia/Jayapura (GMT+9)' },
  { name: 'Asia/Jerusalem', value: 268, label: 'Asia/Jerusalem (GMT+3)' },
  { name: 'Asia/Kabul', value: 269, label: 'Asia/Kabul (GMT+4.5)' },
  { name: 'Asia/Karachi', value: 271, label: 'Asia/Karachi (GMT+5)' },
  { name: 'Asia/Kashgar', value: 272, label: 'Asia/Kashgar (GMT+6)' },
  { name: 'Asia/Kathmandu', value: 273, label: 'Asia/Kathmandu (GMT+5.75)' },
  { name: 'Asia/Katmandu', value: 274, label: 'Asia/Katmandu (GMT+5.75)' },
  { name: 'Asia/Khandyga', value: 275, label: 'Asia/Khandyga (GMT+9)' },
  { name: 'Asia/Kolkata', value: 276, label: 'Asia/Kolkata (GMT+5.5)' },
  { name: 'Asia/Kuala_Lumpur', value: 278, label: 'Asia/Kuala_Lumpur (GMT+8)' },
  { name: 'Asia/Kuching', value: 279, label: 'Asia/Kuching (GMT+8)' },
  { name: 'Asia/Kuwait', value: 280, label: 'Asia/Kuwait (GMT+3)' },
  { name: 'Asia/Macao', value: 281, label: 'Asia/Macao (GMT+8)' },
  { name: 'Asia/Macau', value: 282, label: 'Asia/Macau (GMT+8)' },
  { name: 'Asia/Magadan', value: 283, label: 'Asia/Magadan (GMT+11)' },
  { name: 'Asia/Makassar', value: 284, label: 'Asia/Makassar (GMT+8)' },
  { name: 'Asia/Manila', value: 285, label: 'Asia/Manila (GMT+8)' },
  { name: 'Asia/Muscat', value: 286, label: 'Asia/Muscat (GMT+4)' },
  { name: 'Asia/Nicosia', value: 287, label: 'Asia/Nicosia (GMT+3)' },
  { name: 'Asia/Oral', value: 291, label: 'Asia/Oral (GMT+5)' },
  { name: 'Asia/Phnom_Penh', value: 292, label: 'Asia/Phnom_Penh (GMT+7)' },
  { name: 'Asia/Pontianak', value: 293, label: 'Asia/Pontianak (GMT+7)' },
  { name: 'Asia/Pyongyang', value: 294, label: 'Asia/Pyongyang (GMT+9)' },
  { name: 'Asia/Qatar', value: 295, label: 'Asia/Qatar (GMT+3)' },
  { name: 'Asia/Qyzylorda', value: 296, label: 'Asia/Qyzylorda (GMT+5)' },
  { name: 'Asia/Rangoon', value: 297, label: 'Asia/Rangoon (GMT+6.5)' },
  { name: 'Asia/Riyadh', value: 298, label: 'Asia/Riyadh (GMT+3)' },
  { name: 'Asia/Saigon', value: 299, label: 'Asia/Saigon (GMT+7)' },
  { name: 'Asia/Samarkand', value: 301, label: 'Asia/Samarkand (GMT+5)' },
  { name: 'Asia/Seoul', value: 302, label: 'Asia/Seoul (GMT+9)' },
  { name: 'Asia/Shanghai', value: 303, label: 'Asia/Shanghai (GMT+8)' },
  { name: 'Asia/Singapore', value: 304, label: 'Asia/Singapore (GMT+8)' },
  { name: 'Asia/Taipei', value: 306, label: 'Asia/Taipei (GMT+8)' },
  { name: 'Asia/Tashkent', value: 307, label: 'Asia/Tashkent (GMT+5)' },
  { name: 'Asia/Tbilisi', value: 308, label: 'Asia/Tbilisi (GMT+4)' },
  { name: 'Asia/Tehran', value: 309, label: 'Asia/Tehran (GMT+4.5)' },
  { name: 'Asia/Tel_Aviv', value: 310, label: 'Asia/Tel_Aviv (GMT+3)' },
  { name: 'Asia/Thimbu', value: 311, label: 'Asia/Thimbu (GMT+6)' },
  { name: 'Asia/Thimphu', value: 312, label: 'Asia/Thimphu (GMT+6)' },
  { name: 'Asia/Tokyo', value: 313, label: 'Asia/Tokyo (GMT+9)' },
  { name: 'Asia/Ujung_Pandang', value: 314, label: 'Asia/Ujung_Pandang (GMT+8)' },
  { name: 'Asia/Ulaanbaatar', value: 315, label: 'Asia/Ulaanbaatar (GMT+8)' },
  { name: 'Asia/Ulan_Bator', value: 316, label: 'Asia/Ulan_Bator (GMT+8)' },
  { name: 'Asia/Urumqi', value: 317, label: 'Asia/Urumqi (GMT+6)' },
  { name: 'Asia/Ust-Nera', value: 318, label: 'Asia/Ust-Nera (GMT+10)' },
  { name: 'Asia/Vientiane', value: 319, label: 'Asia/Vientiane (GMT+7)' },
  { name: 'Asia/Yerevan', value: 323, label: 'Asia/Yerevan (GMT+4)' },
  { name: 'Atlantic/Azores', value: 324, label: 'Atlantic/Azores (GMT+0)' },
  { name: 'Atlantic/Bermuda', value: 325, label: 'Atlantic/Bermuda (GMT-3)' },
  { name: 'Atlantic/Canary', value: 326, label: 'Atlantic/Canary (GMT+1)' },
  { name: 'Atlantic/Cape_Verde', value: 327, label: 'Atlantic/Cape_Verde (GMT-1)' },
  { name: 'Atlantic/Faeroe', value: 328, label: 'Atlantic/Faeroe (GMT+1)' },
  { name: 'Atlantic/Faroe', value: 329, label: 'Atlantic/Faroe (GMT+1)' },
  { name: 'Atlantic/Jan_Mayen', value: 330, label: 'Atlantic/Jan_Mayen (GMT+2)' },
  { name: 'Atlantic/Madeira', value: 331, label: 'Atlantic/Madeira (GMT+1)' },
  { name: 'Atlantic/Reykjavik', value: 332, label: 'Atlantic/Reykjavik (GMT+0)' },
  { name: 'Atlantic/South_Georgia', value: 333, label: 'Atlantic/South_Georgia (GMT-2)' },
  { name: 'Atlantic/St_Helena', value: 334, label: 'Atlantic/St_Helena (GMT+0)' },
  { name: 'Atlantic/Stanley', value: 335, label: 'Atlantic/Stanley (GMT-3)' },
  { name: 'Australia/ACT', value: 336, label: 'Australia/ACT (GMT+10)' },
  { name: 'Australia/Adelaide', value: 337, label: 'Australia/Adelaide (GMT+9.5)' },
  { name: 'Australia/Brisbane', value: 338, label: 'Australia/Brisbane (GMT+10)' },
  { name: 'Australia/Broken_Hill', value: 339, label: 'Australia/Broken_Hill (GMT+9.5)' },
  { name: 'Australia/Canberra', value: 340, label: 'Australia/Canberra (GMT+10)' },
  { name: 'Australia/Currie', value: 341, label: 'Australia/Currie (GMT+10)' },
  { name: 'Australia/Darwin', value: 342, label: 'Australia/Darwin (GMT+9.5)' },
  { name: 'Australia/Eucla', value: 343, label: 'Australia/Eucla (GMT+8.75)' },
  { name: 'Australia/Hobart', value: 344, label: 'Australia/Hobart (GMT+10)' },
  { name: 'Australia/LHI', value: 345, label: 'Australia/LHI (GMT+10.5)' },
  { name: 'Australia/Lindeman', value: 346, label: 'Australia/Lindeman (GMT+10)' },
  { name: 'Australia/Lord_Howe', value: 347, label: 'Australia/Lord_Howe (GMT+10.5)' },
  { name: 'Australia/Melbourne', value: 348, label: 'Australia/Melbourne (GMT+10)' },
  { name: 'Australia/NSW', value: 349, label: 'Australia/NSW (GMT+10)' },
  { name: 'Australia/North', value: 350, label: 'Australia/North (GMT+9.5)' },
  { name: 'Australia/Perth', value: 351, label: 'Australia/Perth (GMT+8)' },
  { name: 'Australia/Queensland', value: 352, label: 'Australia/Queensland (GMT+10)' },
  { name: 'Australia/South', value: 353, label: 'Australia/South (GMT+9.5)' },
  { name: 'Australia/Sydney', value: 354, label: 'Australia/Sydney (GMT+10)' },
  { name: 'Australia/Tasmania', value: 355, label: 'Australia/Tasmania (GMT+10)' },
  { name: 'Australia/Victoria', value: 356, label: 'Australia/Victoria (GMT+10)' },
  { name: 'Australia/West', value: 357, label: 'Australia/West (GMT+8)' },
  { name: 'Australia/Yancowinna', value: 358, label: 'Australia/Yancowinna (GMT+9.5)' },
  { name: 'Brazil/Acre', value: 359, label: 'Brazil/Acre (GMT-5)' },
  { name: 'Brazil/DeNoronha', value: 360, label: 'Brazil/DeNoronha (GMT-2)' },
  { name: 'Brazil/East', value: 361, label: 'Brazil/East (GMT-3)' },
  { name: 'Brazil/West', value: 362, label: 'Brazil/West (GMT-4)' },
  { name: 'CET', value: 363, label: 'CET (GMT+2)' },
  { name: 'CST6CDT', value: 364, label: 'CST6CDT (GMT-5)' },
  { name: 'Canada/Atlantic', value: 365, label: 'Canada/Atlantic (GMT-3)' },
  { name: 'Canada/Central', value: 366, label: 'Canada/Central (GMT-5)' },
  { name: 'Canada/East-Saskatchewan', value: 367, label: 'Canada/East-Saskatchewan (GMT-6)' },
  { name: 'Canada/Eastern', value: 368, label: 'Canada/Eastern (GMT-4)' },
  { name: 'Canada/Mountain', value: 369, label: 'Canada/Mountain (GMT-6)' },
  { name: 'Canada/Newfoundland', value: 370, label: 'Canada/Newfoundland (GMT-2.5)' },
  { name: 'Canada/Pacific', value: 371, label: 'Canada/Pacific (GMT-7)' },
  { name: 'Canada/Saskatchewan', value: 372, label: 'Canada/Saskatchewan (GMT-6)' },
  { name: 'Canada/Yukon', value: 373, label: 'Canada/Yukon (GMT-7)' },
  { name: 'Chile/Continental', value: 374, label: 'Chile/Continental (GMT-4)' },
  { name: 'Chile/EasterIsland', value: 375, label: 'Chile/EasterIsland (GMT-6)' },
  { name: 'Cuba', value: 376, label: 'Cuba (GMT-4)' },
  { name: 'EET', value: 377, label: 'EET (GMT+3)' },
  { name: 'EST', value: 378, label: 'EST (GMT-5)' },
  { name: 'EST5EDT', value: 379, label: 'EST5EDT (GMT-4)' },
  { name: 'Egypt', value: 380, label: 'Egypt (GMT+2)' },
  { name: 'Eire', value: 381, label: 'Eire (GMT+1)' },
  { name: 'Etc/GMT', value: 382, label: 'Etc/GMT (GMT+0)' },
  { name: 'Etc/GMT+0', value: 383, label: 'Etc/GMT+0 (GMT+0)' },
  { name: 'Etc/GMT+1', value: 384, label: 'Etc/GMT+1 (GMT-1)' },
  { name: 'Etc/GMT+10', value: 385, label: 'Etc/GMT+10 (GMT-10)' },
  { name: 'Etc/GMT+11', value: 386, label: 'Etc/GMT+11 (GMT-11)' },
  { name: 'Etc/GMT+12', value: 387, label: 'Etc/GMT+12 (GMT-12)' },
  { name: 'Etc/GMT+2', value: 388, label: 'Etc/GMT+2 (GMT-2)' },
  { name: 'Etc/GMT+3', value: 389, label: 'Etc/GMT+3 (GMT-3)' },
  { name: 'Etc/GMT+4', value: 390, label: 'Etc/GMT+4 (GMT-4)' },
  { name: 'Etc/GMT+5', value: 391, label: 'Etc/GMT+5 (GMT-5)' },
  { name: 'Etc/GMT+6', value: 392, label: 'Etc/GMT+6 (GMT-6)' },
  { name: 'Etc/GMT+7', value: 393, label: 'Etc/GMT+7 (GMT-7)' },
  { name: 'Etc/GMT+8', value: 394, label: 'Etc/GMT+8 (GMT-8)' },
  { name: 'Etc/GMT+9', value: 395, label: 'Etc/GMT+9 (GMT-9)' },
  { name: 'Etc/GMT-0', value: 396, label: 'Etc/GMT-0 (GMT+0)' },
  { name: 'Etc/GMT-1', value: 397, label: 'Etc/GMT-1 (GMT+1)' },
  { name: 'Etc/GMT-10', value: 398, label: 'Etc/GMT-10 (GMT+10)' },
  { name: 'Etc/GMT-11', value: 399, label: 'Etc/GMT-11 (GMT+11)' },
  { name: 'Etc/GMT-12', value: 400, label: 'Etc/GMT-12 (GMT+12)' },
  { name: 'Etc/GMT-13', value: 401, label: 'Etc/GMT-13 (GMT+13)' },
  { name: 'Etc/GMT-14', value: 402, label: 'Etc/GMT-14 (GMT+14)' },
  { name: 'Etc/GMT-2', value: 403, label: 'Etc/GMT-2 (GMT+2)' },
  { name: 'Etc/GMT-3', value: 404, label: 'Etc/GMT-3 (GMT+3)' },
  { name: 'Etc/GMT-4', value: 405, label: 'Etc/GMT-4 (GMT+4)' },
  { name: 'Etc/GMT-5', value: 406, label: 'Etc/GMT-5 (GMT+5)' },
  { name: 'Etc/GMT-6', value: 407, label: 'Etc/GMT-6 (GMT+6)' },
  { name: 'Etc/GMT-7', value: 408, label: 'Etc/GMT-7 (GMT+7)' },
  { name: 'Etc/GMT-8', value: 409, label: 'Etc/GMT-8 (GMT+8)' },
  { name: 'Etc/GMT-9', value: 410, label: 'Etc/GMT-9 (GMT+9)' },
  { name: 'Etc/GMT0', value: 411, label: 'Etc/GMT0 (GMT+0)' },
  { name: 'Etc/Greenwich', value: 412, label: 'Etc/Greenwich (GMT+0)' },
  { name: 'Etc/UCT', value: 413, label: 'Etc/UCT (GMT+0)' },
  { name: 'Etc/UTC', value: 414, label: 'Etc/UTC (GMT+0)' },
  { name: 'Etc/Universal', value: 415, label: 'Etc/Universal (GMT+0)' },
  { name: 'Etc/Zulu', value: 416, label: 'Etc/Zulu (GMT+0)' },
  { name: 'Europe/Amsterdam', value: 417, label: 'Europe/Amsterdam (GMT+2)' },
  { name: 'Europe/Andorra', value: 418, label: 'Europe/Andorra (GMT+2)' },
  { name: 'Europe/Athens', value: 419, label: 'Europe/Athens (GMT+3)' },
  { name: 'Europe/Belfast', value: 420, label: 'Europe/Belfast (GMT+1)' },
  { name: 'Europe/Belgrade', value: 421, label: 'Europe/Belgrade (GMT+2)' },
  { name: 'Europe/Berlin', value: 422, label: 'Europe/Berlin (GMT+2)' },
  { name: 'Europe/Bratislava', value: 423, label: 'Europe/Bratislava (GMT+2)' },
  { name: 'Europe/Brussels', value: 424, label: 'Europe/Brussels (GMT+2)' },
  { name: 'Europe/Bucharest', value: 425, label: 'Europe/Bucharest (GMT+3)' },
  { name: 'Europe/Budapest', value: 426, label: 'Europe/Budapest (GMT+2)' },
  { name: 'Europe/Busingen', value: 427, label: 'Europe/Busingen (GMT+2)' },
  { name: 'Europe/Chisinau', value: 428, label: 'Europe/Chisinau (GMT+3)' },
  { name: 'Europe/Copenhagen', value: 429, label: 'Europe/Copenhagen (GMT+2)' },
  { name: 'Europe/Dublin', value: 430, label: 'Europe/Dublin (GMT+1)' },
  { name: 'Europe/Gibraltar', value: 431, label: 'Europe/Gibraltar (GMT+2)' },
  { name: 'Europe/Guernsey', value: 432, label: 'Europe/Guernsey (GMT+1)' },
  { name: 'Europe/Helsinki', value: 433, label: 'Europe/Helsinki (GMT+3)' },
  { name: 'Europe/Isle_of_Man', value: 434, label: 'Europe/Isle_of_Man (GMT+1)' },
  { name: 'Europe/Istanbul', value: 435, label: 'Europe/Istanbul (GMT+3)' },
  { name: 'Europe/Jersey', value: 436, label: 'Europe/Jersey (GMT+1)' },
  { name: 'Europe/Königsberg', value: 437, label: 'Europe/Königsberg (GMT+2)' },
  { name: 'Europe/Kyiv', value: 438, label: 'Europe/Kyiv (GMT+3)' },
  { name: 'Europe/Lisbon', value: 439, label: 'Europe/Lisbon (GMT+1)' },
  { name: 'Europe/Ljubljana', value: 440, label: 'Europe/Ljubljana (GMT+2)' },
  { name: 'Europe/London', value: 441, label: 'Europe/London (GMT+1)' },
  { name: 'Europe/Luxembourg', value: 442, label: 'Europe/Luxembourg (GMT+2)' },
  { name: 'Europe/Madrid', value: 443, label: 'Europe/Madrid (GMT+2)' },
  { name: 'Europe/Malta', value: 444, label: 'Europe/Malta (GMT+2)' },
  { name: 'Europe/Mariehamn', value: 445, label: 'Europe/Mariehamn (GMT+3)' },
  { name: 'Europe/Minsk', value: 446, label: 'Europe/Minsk (GMT+3)' },
  { name: 'Europe/Monaco', value: 447, label: 'Europe/Monaco (GMT+2)' },
  { name: 'Europe/Nicosia', value: 449, label: 'Europe/Nicosia (GMT+3)' },
  { name: 'Europe/Oslo', value: 450, label: 'Europe/Oslo (GMT+2)' },
  { name: 'Europe/Paris', value: 451, label: 'Europe/Paris (GMT+2)' },
  { name: 'Europe/Podgorica', value: 452, label: 'Europe/Podgorica (GMT+2)' },
  { name: 'Europe/Prague', value: 453, label: 'Europe/Prague (GMT+2)' },
  { name: 'Europe/Riga', value: 454, label: 'Europe/Riga (GMT+3)' },
  { name: 'Europe/Rome', value: 455, label: 'Europe/Rome (GMT+2)' },
  { name: 'Europe/San_Marino', value: 457, label: 'Europe/San_Marino (GMT+2)' },
  { name: 'Europe/Sarajevo', value: 458, label: 'Europe/Sarajevo (GMT+2)' },
  { name: 'Europe/Simferopol', value: 459, label: 'Europe/Simferopol (GMT+3)' },
  { name: 'Europe/Skopje', value: 460, label: 'Europe/Skopje (GMT+2)' },
  { name: 'Europe/Sofia', value: 461, label: 'Europe/Sofia (GMT+3)' },
  { name: 'Europe/Stockholm', value: 462, label: 'Europe/Stockholm (GMT+2)' },
  { name: 'Europe/Tallinn', value: 463, label: 'Europe/Tallinn (GMT+3)' },
  { name: 'Europe/Tirane', value: 464, label: 'Europe/Tirane (GMT+2)' },
  { name: 'Europe/Tiraspol', value: 465, label: 'Europe/Tiraspol (GMT+3)' },
  { name: 'Europe/Uzhgorod', value: 466, label: 'Europe/Uzhgorod (GMT+3)' },
  { name: 'Europe/Vaduz', value: 467, label: 'Europe/Vaduz (GMT+2)' },
  { name: 'Europe/Vatican', value: 468, label: 'Europe/Vatican (GMT+2)' },
  { name: 'Europe/Vienna', value: 469, label: 'Europe/Vienna (GMT+2)' },
  { name: 'Europe/Vilnius', value: 470, label: 'Europe/Vilnius (GMT+3)' },
  { name: 'Europe/Volgograd', value: 471, label: 'Europe/Volgograd (GMT+3)' },
  { name: 'Europe/Warsaw', value: 472, label: 'Europe/Warsaw (GMT+2)' },
  { name: 'Europe/Zagreb', value: 473, label: 'Europe/Zagreb (GMT+2)' },
  { name: 'Europe/Zaporozhye', value: 474, label: 'Europe/Zaporozhye (GMT+3)' },
  { name: 'Europe/Zurich', value: 475, label: 'Europe/Zurich (GMT+2)' },
  { name: 'GB', value: 476, label: 'GB (GMT+1)' },
  { name: 'GB-Eire', value: 477, label: 'GB-Eire (GMT+1)' },
  { name: 'GMT', value: 478, label: 'GMT (GMT+0)' },
  { name: 'GMT+0', value: 479, label: 'GMT+0 (GMT+0)' },
  { name: 'GMT-0', value: 480, label: 'GMT-0 (GMT+0)' },
  { name: 'GMT0', value: 481, label: 'GMT0 (GMT+0)' },
  { name: 'Greenwich', value: 482, label: 'Greenwich (GMT+0)' },
  { name: 'HST', value: 483, label: 'HST (GMT-10)' },
  { name: 'Hongkong', value: 484, label: 'Hongkong (GMT+8)' },
  { name: 'Iceland', value: 485, label: 'Iceland (GMT+0)' },
  { name: 'Indian/Antananarivo', value: 486, label: 'Indian/Antananarivo (GMT+3)' },
  { name: 'Indian/Chagos', value: 487, label: 'Indian/Chagos (GMT+6)' },
  { name: 'Indian/Christmas', value: 488, label: 'Indian/Christmas (GMT+7)' },
  { name: 'Indian/Cocos', value: 489, label: 'Indian/Cocos (GMT+6.5)' },
  { name: 'Indian/Comoro', value: 490, label: 'Indian/Comoro (GMT+3)' },
  { name: 'Indian/Kerguelen', value: 491, label: 'Indian/Kerguelen (GMT+5)' },
  { name: 'Indian/Mahe', value: 492, label: 'Indian/Mahe (GMT+4)' },
  { name: 'Indian/Maldives', value: 493, label: 'Indian/Maldives (GMT+5)' },
  { name: 'Indian/Mauritius', value: 494, label: 'Indian/Mauritius (GMT+4)' },
  { name: 'Indian/Mayotte', value: 495, label: 'Indian/Mayotte (GMT+3)' },
  { name: 'Indian/Reunion', value: 496, label: 'Indian/Reunion (GMT+4)' },
  { name: 'Iran', value: 497, label: 'Iran (GMT+4.5)' },
  { name: 'Israel', value: 498, label: 'Israel (GMT+3)' },
  { name: 'Jamaica', value: 499, label: 'Jamaica (GMT-5)' },
  { name: 'Japan', value: 500, label: 'Japan (GMT+9)' },
  { name: 'Kwajalein', value: 501, label: 'Kwajalein (GMT+12)' },
  { name: 'Libya', value: 502, label: 'Libya (GMT+2)' },
  { name: 'MET', value: 503, label: 'MET (GMT+2)' },
  { name: 'MST', value: 504, label: 'MST (GMT-7)' },
  { name: 'MST7MDT', value: 505, label: 'MST7MDT (GMT-6)' },
  { name: 'Mexico/BajaNorte', value: 506, label: 'Mexico/BajaNorte (GMT-7)' },
  { name: 'Mexico/BajaSur', value: 507, label: 'Mexico/BajaSur (GMT-6)' },
  { name: 'Mexico/General', value: 508, label: 'Mexico/General (GMT-5)' },
  { name: 'NZ', value: 509, label: 'NZ (GMT+12)' },
  { name: 'NZ-CHAT', value: 510, label: 'NZ-CHAT (GMT+12.75)' },
  { name: 'Navajo', value: 511, label: 'Navajo (GMT-6)' },
  { name: 'PRC', value: 512, label: 'PRC (GMT+8)' },
  { name: 'PST8PDT', value: 513, label: 'PST8PDT (GMT-7)' },
  { name: 'Pacific/Apia', value: 514, label: 'Pacific/Apia (GMT+13)' },
  { name: 'Pacific/Auckland', value: 515, label: 'Pacific/Auckland (GMT+12)' },
  { name: 'Pacific/Bougainville', value: 516, label: 'Pacific/Bougainville (GMT+11)' },
  { name: 'Pacific/Chatham', value: 517, label: 'Pacific/Chatham (GMT+12.75)' },
  { name: 'Pacific/Chuuk', value: 518, label: 'Pacific/Chuuk (GMT+10)' },
  { name: 'Pacific/Easter', value: 519, label: 'Pacific/Easter (GMT-6)' },
  { name: 'Pacific/Efate', value: 520, label: 'Pacific/Efate (GMT+11)' },
  { name: 'Pacific/Enderbury', value: 521, label: 'Pacific/Enderbury (GMT+13)' },
  { name: 'Pacific/Fakaofo', value: 522, label: 'Pacific/Fakaofo (GMT+13)' },
  { name: 'Pacific/Fiji', value: 523, label: 'Pacific/Fiji (GMT+12)' },
  { name: 'Pacific/Funafuti', value: 524, label: 'Pacific/Funafuti (GMT+12)' },
  { name: 'Pacific/Galapagos', value: 525, label: 'Pacific/Galapagos (GMT-6)' },
  { name: 'Pacific/Gambier', value: 526, label: 'Pacific/Gambier (GMT-9)' },
  { name: 'Pacific/Guadalcanal', value: 527, label: 'Pacific/Guadalcanal (GMT+11)' },
  { name: 'Pacific/Guam', value: 528, label: 'Pacific/Guam (GMT+10)' },
  { name: 'Pacific/Honolulu', value: 529, label: 'Pacific/Honolulu (GMT-10)' },
  { name: 'Pacific/Johnston', value: 530, label: 'Pacific/Johnston (GMT-10)' },
  { name: 'Pacific/Kiritimati', value: 531, label: 'Pacific/Kiritimati (GMT+14)' },
  { name: 'Pacific/Kosrae', value: 532, label: 'Pacific/Kosrae (GMT+11)' },
  { name: 'Pacific/Kwajalein', value: 533, label: 'Pacific/Kwajalein (GMT+12)' },
  { name: 'Pacific/Majuro', value: 534, label: 'Pacific/Majuro (GMT+12)' },
  { name: 'Pacific/Marquesas', value: 535, label: 'Pacific/Marquesas (GMT-9.5)' },
  { name: 'Pacific/Midway', value: 536, label: 'Pacific/Midway (GMT-11)' },
  { name: 'Pacific/Nauru', value: 537, label: 'Pacific/Nauru (GMT+12)' },
  { name: 'Pacific/Niue', value: 538, label: 'Pacific/Niue (GMT-11)' },
  { name: 'Pacific/Norfolk', value: 539, label: 'Pacific/Norfolk (GMT+11)' },
  { name: 'Pacific/Noumea', value: 540, label: 'Pacific/Noumea (GMT+11)' },
  { name: 'Pacific/Pago_Pago', value: 541, label: 'Pacific/Pago_Pago (GMT-11)' },
  { name: 'Pacific/Palau', value: 542, label: 'Pacific/Palau (GMT+9)' },
  { name: 'Pacific/Pitcairn', value: 543, label: 'Pacific/Pitcairn (GMT-8)' },
  { name: 'Pacific/Pohnpei', value: 544, label: 'Pacific/Pohnpei (GMT+11)' },
  { name: 'Pacific/Ponape', value: 545, label: 'Pacific/Ponape (GMT+11)' },
  { name: 'Pacific/Port_Moresby', value: 546, label: 'Pacific/Port_Moresby (GMT+10)' },
  { name: 'Pacific/Rarotonga', value: 547, label: 'Pacific/Rarotonga (GMT-10)' },
  { name: 'Pacific/Saipan', value: 548, label: 'Pacific/Saipan (GMT+10)' },
  { name: 'Pacific/Samoa', value: 549, label: 'Pacific/Samoa (GMT-11)' },
  { name: 'Pacific/Tahiti', value: 550, label: 'Pacific/Tahiti (GMT-10)' },
  { name: 'Pacific/Tarawa', value: 551, label: 'Pacific/Tarawa (GMT+12)' },
  { name: 'Pacific/Tongatapu', value: 552, label: 'Pacific/Tongatapu (GMT+13)' },
  { name: 'Pacific/Truk', value: 553, label: 'Pacific/Truk (GMT+10)' },
  { name: 'Pacific/Wake', value: 554, label: 'Pacific/Wake (GMT+12)' },
  { name: 'Pacific/Wallis', value: 555, label: 'Pacific/Wallis (GMT+12)' },
  { name: 'Pacific/Yap', value: 556, label: 'Pacific/Yap (GMT+10)' },
  { name: 'Poland', value: 557, label: 'Poland (GMT+2)' },
  { name: 'Portugal', value: 558, label: 'Portugal (GMT+1)' },
  { name: 'ROC', value: 559, label: 'ROC (GMT+8)' },
  { name: 'ROK', value: 560, label: 'ROK (GMT+9)' },
  { name: 'Singapore', value: 561, label: 'Singapore (GMT+8)' },
  { name: 'Turkey', value: 562, label: 'Turkey (GMT+3)' },
  { name: 'UCT', value: 563, label: 'UCT (GMT+0)' },
  { name: 'US/Alaska', value: 564, label: 'US/Alaska (GMT-8)' },
  { name: 'US/Aleutian', value: 565, label: 'US/Aleutian (GMT-9)' },
  { name: 'US/Arizona', value: 566, label: 'US/Arizona (GMT-7)' },
  { name: 'US/Central', value: 567, label: 'US/Central (GMT-5)' },
  { name: 'US/East-Indiana', value: 568, label: 'US/East-Indiana (GMT-4)' },
  { name: 'US/Eastern', value: 569, label: 'US/Eastern (GMT-4)' },
  { name: 'US/Hawaii', value: 570, label: 'US/Hawaii (GMT-10)' },
  { name: 'US/Indiana-Starke', value: 571, label: 'US/Indiana-Starke (GMT-5)' },
  { name: 'US/Michigan', value: 572, label: 'US/Michigan (GMT-4)' },
  { name: 'US/Mountain', value: 573, label: 'US/Mountain (GMT-6)' },
  { name: 'US/Pacific', value: 574, label: 'US/Pacific (GMT-7)' },
  { name: 'US/Pacific-New', value: 575, label: 'US/Pacific-New (GMT-7)' },
  { name: 'US/Samoa', value: 576, label: 'US/Samoa (GMT-11)' },
  { name: 'Africa/Abidjan', value: 577, label: 'Africa/Abidjan (GMT+0)' },
  { name: 'Universal', value: 578, label: 'Universal (GMT+0)' },
  { name: 'W-SU', value: 579, label: 'W-SU (GMT+3)' },
  { name: 'WET', value: 580, label: 'WET (GMT+1)' },
  { name: 'Zulu', value: 581, label: 'Zulu (GMT+0)' }
]
