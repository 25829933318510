<template>
  <div class="dc-Wrapper">
    <div v-if="hasFullSizeToggle && chartDataReceived" class="dc-FullSize">
      <AppButton
        class="dc-FullSize_Toggle"
        icon="full-screen-next-on"
        type="primary-next"
        @click="toggleFullChartModal"
      >
        {{ $t('objective.chart.fullSize') }}
      </AppButton>
    </div>
    <div class="dc-Content">
      <!-- :aspect-ratio="4.72" -->
      <LineChart
        v-if="chartDataReceived"
        :aspect-ratio="1.65"
        :border-color="borderColor"
        :chart-data="chartData"
        :chart-type="chartType"
        :time-intervals-count="getTimeIntervalsCount"
        full-size-chart
        set-y-from0-to100
        without-background
      />
      <LoadingCircle v-else min-height="62px" size="small" />
    </div>
  </div>

  <portal to="modal-windows">
    <Modal
      v-if="chartDataReceived && hasFullSizeToggle"
      :scrollable-content="false"
      :show="showFullChartModal"
      :title="title"
      class="dc-FullSizeModal"
      manual-close
      size="lg-next"
      @close="toggleFullChartModal"
    >
      <DashboardChartWithTable
        v-if="showFullChartModal"
        :chart-type="$options.CHART_TYPES.detailedNext"
        :element-id="elementId"
        :title="title"
        hide-title
        is-linear
      />
    </Modal>
  </portal>
</template>

<script>
import { isEmpty, cloneDeep } from 'lodash'
import { defineComponent } from 'vue'

import { useNormalizeChartLines } from '@/composables/objectiveChartComposable'
import { memoizeGetCssVariableValue } from '@/utils/memoizations'
import { CHART_TYPES } from '@/utils/objective-chart'
import { getGradeColorVariable } from '@/utils/okr-elements/grade'

import DashboardChartWithTable from '@/components/dashboard/custom-dashboard/chart/DashboardChartWithTable'
import LineChart from '@/components/LineChart/LineChart'
import AppButton from '@/components/ui/AppButton/AppButton'
import LoadingCircle from '@/components/ui/LoadingCircle/LoadingCircle'
import Modal from '@/components/ui/Modal/Modal'

export default defineComponent({
  name: 'DashboardChart',

  components: {
    Modal,
    LineChart,
    AppButton,
    DashboardChartWithTable,
    LoadingCircle
  },

  inheritAttrs: false,

  props: {
    chartType: {
      type: String,
      default: CHART_TYPES.dashboardPreview,
      validator: v => Object.values(CHART_TYPES).includes(v)
    },

    elementId: {
      type: [String, Number],
      required: true
    },

    hasFullSizeToggle: {
      type: Boolean
    },

    gradeColor: {
      type: String,
      default: null
    },

    externalChartData: {
      type: Object,
      default: () => ({})
    },

    title: {
      type: String,
      required: true
    }
  },

  CHART_TYPES: { ...CHART_TYPES },

  data() {
    return {
      showFullChartModal: false,
      chartData: {}
    }
  },

  computed: {
    getTimeIntervalsCount() {
      return 5 // подогнанное значение чтобы был оффсет с двух сторон
    },

    chartDataReceived() {
      return !isEmpty(this.chartData)
    },

    borderColor() {
      if (this.gradeColor) {
        const variable = getGradeColorVariable(this.gradeColor)
        return memoizeGetCssVariableValue(variable)
      } else {
        return null
      }
    }
  },

  watch: {
    externalChartData: {
      handler() {
        this.getChartData()
      },

      deep: true
    }
  },

  mounted() {
    this.getChartData()
  },

  methods: {
    async getChartData() {
      const result = cloneDeep(this.externalChartData)
      result.chartLines = useNormalizeChartLines(result.chartLines)
      result.chartLines = result.chartLines.map(line => {
        line.name = this.title
        return line
      })
      this.chartData = result
    },

    toggleFullChartModal() {
      this.showFullChartModal = !this.showFullChartModal
    }
  }
})
</script>

<style lang="scss" scoped>
.dc-FullSize {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  // background: rgba($primary-color-next, 0.1);
  background-image: url(~@/assets/images/okr-chart-bg.jpg);
  transition: opacity $transition-normal;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  z-index: 2;
  border-radius: $border-radius-md-next;

  .dc-Wrapper:hover & {
    opacity: 1;
    pointer-events: all;
  }
}
.dc-FullSize_Toggle {
  padding: 0 16px 0 6px;
}

.dc-FullSizeModal {
  :deep(.uim-TitleText) {
    margin: 0;
  }
}

.dc-Wrapper {
  position: relative;
  overflow: hidden;
  background: $white;
  &:after {
    position: absolute;
    content: '';
    display: block;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: $border-radius-md-next;
    border: 2px solid $grey-19;
    padding: 21px 0;
    z-index: 1;
  }
}
</style>
