<template>
  <!--  <CellValue
    :id="`as${uid}`"
    :cell-value="displayName"
    :user-has-update-access="updatable"
    :user-has-read-access="readable"
    @click="editInitialised = true"
  >

  </CellValue>-->

  <CellSelectItem
    :id="`as${uid}`"
    :active="isOpened"
    :is-empty="!displayName"
    :plug-icon="PLUG_ICONS.ASSIGNEE"
    :user-has-read-access="readable"
    :user-has-update-access="updatable"
    is-transparent
    item-value="name"
    truncated
    @click="editInitialised = true"
  >
    {{ displayName }}
  </CellSelectItem>
  <template v-if="updatable">
    <AppSelect
      v-if="editInitialised"
      :inline-loader="false"
      :loading="areAssigneesLoading"
      :model-value="selectedUser"
      :offset="[0, 0]"
      :options="ownerFieldOptions"
      :search-function="getAssigneesInfo"
      :to-selector="`#as${uid}`"
      append-to=".o-objective-table"
      boundary="scrollParent"
      dropdown-search
      dropdown-width="200px"
      item-label="name"
      item-value="accountId"
      show-on-init
      @hide="hideSelect"
      @open="areAssigneesLoading = true"
      @opened="getAssignees"
      @update:model-value="onAssigneeUpdate"
    >
      <template #option-label="{ option }">
        <OwnerFieldOption :option="option" />
      </template>
    </AppSelect>
  </template>
  <template v-else>
    <AppTippy :content="displayName" :to="`#as${uid}`" />
  </template>
</template>

<script>
import { uniqBy } from 'lodash'
import { defineComponent } from 'vue'

import AssigneesInfoApiHandler from '@/api/assignees-info'
import ObjectivesInfoApiHandler from '@/api/okr-elements'
import { PLUG_ICONS } from '@/utils/cell-helper'
import { ASSIGNEE_ENTITY_KEYS } from '@/utils/entity-keys'
import { handleError } from '@/utils/error-handling'
import {
  convertUsersListToOwnerAndStakeholders,
  createUsersList,
  getOwnerFromUsersList,
  getOwnerName,
  getNotSetOwnerOption
} from '@/utils/objectives'
import { uid } from '@/utils/uid'

import OwnerFieldOption from '@/components/form/OwnerFieldOption'
import CellSelectItem from '@/components/objectives/table/cells/CellSelectItem'
import AppSelect from '@/components/ui/AppSelect/AppSelect'

export default defineComponent({
  name: 'AssigneeCell',

  components: {
    OwnerFieldOption,
    CellSelectItem,
    AppSelect
  },

  inheritAttrs: false,

  props: {
    item: {
      type: Object,
      required: true
    },

    readable: {
      type: Boolean
    },

    updatable: {
      type: Boolean
    }
  },

  emits: { 'update-assignee': null },

  data() {
    return {
      uid: uid(),
      assignees: [],
      areAssigneesLoading: false,

      editInitialised: false,

      isOpened: false
    }
  },

  computed: {
    PLUG_ICONS() {
      return PLUG_ICONS
    },

    selectedUser() {
      return getOwnerFromUsersList(this.item.users)?.accountId
    },

    displayName() {
      return getOwnerName(this.item)
    },

    ownerFieldOptions() {
      return uniqBy(
        [getNotSetOwnerOption(this.$t), ...this.assignees],
        ASSIGNEE_ENTITY_KEYS.ACCOUNT_ID
      )
    }
  },

  methods: {
    async getAssignees() {
      this.isOpened = true
      this.assignees = await this.getAssigneesInfo()
    },

    async getAssigneesInfo(searchString = null) {
      let result = []
      const api = new AssigneesInfoApiHandler()

      const owner = getOwnerFromUsersList(this.item.users)
      const ownerId = owner?.accountId || null

      const parameters = {
        requiredUserAccountIds: ownerId ? [ownerId] : null,
        workspaceId: this.item.workspaceId,
        searchString
      }

      try {
        result = await api.getUsers(parameters)
      } catch (error) {
        handleError({ error })
      } finally {
        this.areAssigneesLoading = false
      }
      return result
    },

    async onAssigneeUpdate(value) {
      const api = new ObjectivesInfoApiHandler()
      const { stakeholders } = convertUsersListToOwnerAndStakeholders(this.item.users)
      try {
        const result = await api.updateOkrElement({
          ...this.item,
          elementId: this.item.id,
          users: createUsersList({ ownerId: value, stakeholders })
        })
        const { hiddenByFilter } = result.element
        const updatedItem = {
          ...this.item,
          hiddenByFilter
        }

        this.$emit('update-assignee', { ...updatedItem, ...result })
      } catch (error) {
        handleError({ error })
      }
    },

    hideSelect() {
      this.isOpened = false
      this.assignees = []
    }
  }
})
</script>
