<template>
  <div class="udh-Loader">
    <div class="udh-Loader_Top">
      <SkeletonItem :appearance="SQUARE" :size="SM" border-radius="6px" />
      <SkeletonItem :appearance="SQUARE" :size="SM" border-radius="6px" />
    </div>
    <div class="udh-Loader_Bottom">
      <SkeletonItem :appearance="CIRCLE" :size="XL" />
      <SkeletonItem border-radius="6px" class="udh-LoaderUerName" height="40px" width="190px" />
      <SkeletonItem v-if="isJiraApp" border-radius="6px" height="24px" width="92px" />
    </div>
  </div>
</template>

<script setup>
defineOptions({
  name: 'UserDetailsHeadLoader'
})

import {
  SKELETON_APPEARANCES,
  SKELETON_SIZES
} from '@/utils/components-configurations/skeleton-item'

import SkeletonItem from '@/components/ui/SkeletonLoaders/SkeletonItem'

defineProps({
  isJiraApp: {
    type: Boolean
  }
})

const { XL, SM } = SKELETON_SIZES
const { CIRCLE, SQUARE } = SKELETON_APPEARANCES
</script>

<style lang="scss" scoped>
.udh-Loader {
  width: 100%;
  max-width: $page-width-md-next;
  padding: 0 $page-right-padding 0 $page-left-padding;
  display: grid;
  gap: 29px;
}

.udh-Loader_Top {
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: space-between;
}

.udh-Loader_Bottom {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
}

.udh-LoaderUerName {
  margin-right: auto;
}
</style>
