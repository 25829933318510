import ApiHandler from '@/api/base'

class FileUploaderApiHandler extends ApiHandler {
  async uploadImage({ formData }) {
    const { data } = await this.http.post('/image', formData, {
      timeout: 0
    })
    return data
  }
}

export default FileUploaderApiHandler
