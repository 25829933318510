<template>
  <div
    :class="{
      'is-InfiniteScroll': !statuses.LOADING
    }"
    class="isl-Loader"
  >
    <slot />

    <template v-if="showNoResults">
      <slot name="no-results">
        <div class="is-NoItems">{{ $t('table.no_items') }}</div>
      </slot>
    </template>

    <template v-if="isLoading">
      <slot :is-first-load="isFirstLoad" name="loader"> {{ $t('field.select.loading') }}...</slot>
    </template>
  </div>
</template>

<script>
import { isNumber } from 'lodash'
import { defineComponent } from 'vue'

import { CUSTOM_SCROLLBAR_CLASS } from '@/utils/general'

export default defineComponent({
  name: 'InfiniteScrollLoader',

  props: {
    identifier: {
      type: Number,
      default: 0
    },

    loadOnStart: {
      type: Boolean,
      default: true
    }
  },

  emits: { infinite: null, 'update-is-infinite-loading': null },

  data() {
    const statuses = {
      READY_TO_LOAD: 1,
      LOADING: 2,
      COMPLETED: 3
    }
    return {
      statuses,
      status: statuses.READY_TO_LOAD,
      // save the current loading whether it is the first loading
      // in combination with status gives state 'no results'
      isFirstLoad: true,
      scrollParent: this.$el
    }
  },

  computed: {
    showNoResults() {
      return this.status === this.statuses.COMPLETED && this.isFirstLoad
    },

    isLoading() {
      return this.status === this.statuses.LOADING
    }
  },

  watch: {
    identifier() {
      this.isFirstLoad = true
      this.loadNextItems(false)
    },

    isLoading: {
      immediate: true,
      handler(isLoading) {
        this.$emit('update-is-infinite-loading', isLoading)
      }
    }
  },

  mounted() {
    this.scrollParent = this.getScrollParent()
    this.scrollParent.addEventListener('scroll', this.scrollEventListener, {
      passive: true
    })
    if (this.loadOnStart) {
      this.scrollEventListener()
    }
  },

  beforeUnmount() {
    this.scrollParent.removeEventListener('scroll', this.scrollEventListener)
  },

  methods: {
    scrollEventListener() {
      if (this.getCurrentDistance() < 100) {
        this.loadNextItems()
      }
    },

    // Public API
    loadNextItems(checkStatus = true) {
      if (checkStatus && this.status !== this.statuses.READY_TO_LOAD) {
        return
      }

      const statusChanger = {
        loaded: () => {
          this.isFirstLoad = false
          this.status = this.statuses.READY_TO_LOAD

          // if items were loaded, but there is still place for next items, load them
          // wait for rendering(tick) of items before check
          this.$nextTick(() => {
            this.scrollEventListener()
          })
        },

        complete: () => {
          this.status = this.statuses.COMPLETED
        }
      }
      this.status = this.statuses.LOADING
      this.$emit('infinite', statusChanger)
    },

    /**
     * get the first scroll parent of an element
     * @param  {DOM} elm    cache element for recursive search
     * @return {DOM}        the first scroll parent
     */
    getScrollParent(elm = this.$el) {
      let result

      if (elm.tagName === 'BODY') {
        result = window
      } else if (['scroll', 'auto'].indexOf(getComputedStyle(elm).overflowY) > -1) {
        result = elm
      } else if (elm.classList.contains(CUSTOM_SCROLLBAR_CLASS)) {
        result = elm
      }

      return result || this.getScrollParent(elm.parentNode)
    },

    /**
     * get current distance from the specified direction
     * @return {Number}     distance
     */
    getCurrentDistance() {
      let distance
      if (this.direction === 'top') {
        distance = isNumber(this.scrollParent.scrollTop)
          ? this.scrollParent.scrollTop
          : this.scrollParent.pageYOffset
      } else {
        const infiniteElmOffsetTopFromBottom = this.$el.getBoundingClientRect().top
        const scrollElmOffsetTopFromBottom =
          this.scrollParent === window
            ? window.innerHeight
            : this.scrollParent.getBoundingClientRect().bottom
        distance = infiniteElmOffsetTopFromBottom - scrollElmOffsetTopFromBottom
      }
      return distance
    }
  }
})
</script>

<style lang="scss" scoped>
.is-InfiniteScroll {
  padding-bottom: 24px;
}
</style>
