<template>
  <div ref="groupReference" class="aufg-Group">
    <div class="aufg-FieldItem_User">
      <AppSelect
        ref="userSelectRef"
        :allow-create-on-search="allowAddEmailsFromSelect"
        :entity-for-create="CREATE_ON_SEARCH_ENTITIES.EMAIL"
        :hidden-items="hiddenUsers"
        :is-error="!item[USER_IDENTIFIER_VALID_KEY]"
        :model-value="item[REQUEST_ENTITY_KEYS.IDENTIFIER]"
        :offset="[0, -40]"
        :options="users"
        :search-function="getUsersList"
        :type="SELECT_TYPES.MODERN"
        boundary="scrollParent"
        dropdown-search
        item-label="name"
        item-value="accountId"
        @opened="removeError(USER_IDENTIFIER_VALID_KEY)"
        @update:options="users = $event"
        @update:model-value="onSelectUser($event)"
      >
        <template #button-content="{ option }">
          <OwnerFieldOption v-if="option" :option="option" label-key="name" />
          <div v-else class="aufg-SelectEmptyLabel">
            {{ $t('admin.user') }}
          </div>
        </template>
        <template #option-label="{ option }">
          <OwnerFieldOption :option="option" label-key="name" />
        </template>
      </AppSelect>
      <AppFieldError
        v-if="!item[USER_IDENTIFIER_VALID_KEY]"
        :show="!item[USER_IDENTIFIER_VALID_KEY]"
        class="aufg-Error"
      >
        {{ $t('field.required') }}
      </AppFieldError>
    </div>
    <div class="aufg-WorkspacesContainer">
      <div
        v-for="(workspace, workspaceIndex) in item.workspaceIds"
        :key="workspace.uid"
        class="aufg-Workspaces"
      >
        <div class="aufg-FieldItem">
          <AppSelect
            ref="workspaceSelectRefs"
            :disabled-items="existedWorkspacesIds"
            :hidden-items="hiddenItems"
            :is-error="!workspace.workspaceValid"
            :model-value="workspace.workspaceId"
            :offset="[0, -40]"
            :options="allWorkspaces"
            :search-function="getAllUserWorkspaces"
            :type="SELECT_TYPES.MODERN"
            boundary="scrollParent"
            dropdown-search
            item-label="name"
            item-value="id"
            @opened="removeError('workspaceValid', workspaceIndex)"
            @update:model-value="onSelectWorkspace($event, workspace.uid)"
          >
            <template #option-label="{ option }">
              <WorkspaceSelectOption
                v-if="option"
                :show-badge="existedWorkspacesIds.includes(option.id)"
                :workspace="option"
                show-tooltip
              />
            </template>

            <template #button-content="{ option }">
              <WorkspaceSelectOption v-if="option" :workspace="option" hide-lock-icon />
              <template v-else>
                <span class="oboard-truncated-text">{{ $t('group.select_workspace') }}</span>
              </template>
            </template>
          </AppSelect>
          <AppFieldError
            v-if="!workspace.workspaceValid"
            :show="!workspace.workspaceValid"
            class="aufg-Error"
          >
            {{ $t('field.required') }}
          </AppFieldError>
        </div>
        <div class="aufg-FieldItem">
          <AppSelect
            ref="rolesSelectRefs"
            :dropdown-search="false"
            :inline-loader="false"
            :model-value="workspace.roleId"
            :offset="[0, 0]"
            :options="resolvedRoles"
            :type="SELECT_TYPES.MODERN"
            boundary="scrollParent"
            item-label="label"
            item-value="value"
            @update:model-value="onSelectValue($event, workspace.uid, 'roleId')"
          />
        </div>
        <AppButton
          v-if="showClearWsButton"
          :data-testid="`remove-user-workspace-${workspace.uid}`"
          class="aufg-ClearButton"
          icon="close-sm"
          size="sm"
          type="ghost"
          @click="onClearWsClick(workspace.uid)"
        />
      </div>

      <AppInfoMessage class="aufg-Fields_Message">
        {{ $t('add_users_without_group') }}
      </AppInfoMessage>
      <AppTableCreateButton
        v-if="isShowAddButton"
        :data-testid="`add-user-workspace-${item.id}`"
        class="aufg-AddButton"
        icon-name="plus-small"
        no-hover
        no-padding
        @click="onAddItemClick"
      >
        {{ $t('users.add_workspace') }}
      </AppTableCreateButton>
    </div>

    <AppButton
      v-if="showClearButton"
      class="aufg-ClearButton"
      icon="close-sm"
      size="sm"
      type="ghost"
      @click="onClearClick"
    />
  </div>
</template>

<script setup>
import { useIntersectionObserver } from '@vueuse/core'
import { cloneDeep, isNull } from 'lodash'
import { computed, onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'

import PlatformApiHandler from '@/api/platform'
import WorkspacesApiHandler from '@/api/workspaces'
import { SELECT_TYPES } from '@/utils/components-configurations/app-select'
import { REQUEST_ENTITY_KEYS } from '@/utils/entity-keys'
import { handleError } from '@/utils/error-handling'
import { WORKSPACE_USER_ROLES } from '@/utils/objectives'
import { CREATE_ON_SEARCH_ENTITIES } from '@/utils/select'
import { uid } from '@/utils/uid'
import { useEmailsInSelect, USER_IDENTIFIER_VALID_KEY } from '@/utils/web-app/emails-in-select'

import AppFieldError from '@/components/form/AppFieldError'
import OwnerFieldOption from '@/components/form/OwnerFieldOption'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppInfoMessage from '@/components/ui/AppInfoMessage'
import AppSelect from '@/components/ui/AppSelect/AppSelect'
import AppTableCreateButton from '@/components/ui/AppTableCreateButton'
import WorkspaceSelectOption from '@/components/ui/WorkspaceSelectOption/WorkspaceSelectOption'

defineOptions({
  name: 'AddUsersFieldGroup'
})
const props = defineProps({
  item: {
    type: Object,
    required: true
  },

  showClearButton: {
    type: Boolean,
    default: true
  },

  usersToAdd: {
    type: Array,
    required: true
  },

  roles: {
    type: Array,
    default: () => []
  }
})
const { allowAddEmailsFromSelect } = useEmailsInSelect()

const userSelectRef = ref(null)
const workspaceSelectRefs = ref([])
const rolesSelectRefs = ref([])

const groupReference = ref(null)
const groupReferenceIsVisible = ref(false)

useIntersectionObserver(
  groupReference,
  ([{ isIntersecting }]) => {
    groupReferenceIsVisible.value = isIntersecting
  },
  { threshold: 1 }
)

watch(
  () => groupReferenceIsVisible.value,
  newValue => {
    if (!newValue) {
      userSelectRef.value?.hideDropdown()
      // https://vuejs.org/guide/essentials/template-refs.html#refs-inside-v-for
      workspaceSelectRefs.value.forEach(item => item.hideDropdown())
      rolesSelectRefs.value.forEach(item => item.hideDropdown())
    }
  },
  { immediate: true }
)

const emit = defineEmits([
  'remove-item',
  'update:users-to-add',
  'add-workspace',
  'remove-workspace'
])
const onClearClick = () => {
  emit('remove-item', props.item.id)
}
const onClearWsClick = uid => {
  emit('remove-workspace', { itemId: props.item.id, workspaceUid: uid })
}

const existedWorkspacesIds = computed(() => {
  return (
    users.value.find(
      user => user[REQUEST_ENTITY_KEYS.IDENTIFIER] === props.item[REQUEST_ENTITY_KEYS.IDENTIFIER]
    )?.workspaceIds || []
  )
})

const sortWorkspaces = workspaces => {
  const clone = cloneDeep(workspaces)
  return clone.sort((a, b) => {
    return existedWorkspacesIds.value.includes(a.id) - existedWorkspacesIds.value.includes(b.id)
  })
}
const store = useStore()

const allWorkspaces = computed(() => {
  return sortWorkspaces(store.state.workspaces.workspaces)
})

const getAllUserWorkspaces = async (searchString = null) => {
  const api = new WorkspacesApiHandler()
  try {
    const workspaces = await api.getAllUserWorkspaces({ searchString })
    return sortWorkspaces(workspaces)
  } catch (error) {
    handleError({ error })
  }
}
const currentItemIndex = computed(() => {
  return props.usersToAdd.findIndex(item => item.id === props.item.id)
})
const getUpdatedList = newItem => {
  const updatedList = cloneDeep(props.usersToAdd)
  updatedList.splice(currentItemIndex.value, 1, newItem)
  return updatedList
}

const removeError = (key, index = null) => {
  const workspaceIds = [...props.item.workspaceIds]
  let newItem
  if (!isNull(index)) {
    workspaceIds[index][key] = true
    newItem = {
      ...props.item,
      workspaceIds
    }
  } else {
    newItem = {
      ...props.item,
      [key]: true
    }
  }

  const updatedList = getUpdatedList(newItem)

  emit('update:users-to-add', updatedList)
}
const onSelectWorkspace = (workspaceId, uid) => {
  const newItem = {
    ...props.item,
    workspaceIds: [
      ...props.item.workspaceIds.map(item => {
        if (item.uid === uid) {
          return {
            ...item,
            workspaceId,
            groupIds: []
          }
        }
        return item
      })
    ]
  }

  const updatedList = getUpdatedList(newItem)

  emit('update:users-to-add', updatedList)
}

const onSelectUser = identifier => {
  const newItem = {
    ...props.item,
    identifier,
    groupIds: [],
    workspaceIds: [
      {
        workspaceId: null,
        groupIds: [],
        roleId: WORKSPACE_USER_ROLES.USER,
        workspaceValid: true,
        uid: uid()
      }
    ]
  }

  const updatedList = getUpdatedList(newItem)

  emit('update:users-to-add', updatedList)
}

const onSelectValue = (value, uid, key) => {
  const newItem = {
    ...props.item,
    workspaceIds: [
      ...props.item.workspaceIds.map(item => {
        if (item.uid === uid) {
          return {
            ...item,
            [key]: value
          }
        }
        return item
      })
    ]
  }

  const updatedList = getUpdatedList(newItem)
  emit('update:users-to-add', updatedList)
}

const hiddenItems = computed(() => {
  return props.item.workspaceIds.map(({ workspaceId }) => workspaceId)
})

const resolvedRoles = computed(() => {
  return props.roles.filter(({ value }) => value)
})

const MAX_COUNT = 10
const MIN_COUNT = 1

const isShowAddButton = computed(() => {
  return props.item.workspaceIds.length < MAX_COUNT
})
const onAddItemClick = () => {
  emit('add-workspace', props.item.id)
}

const showClearWsButton = computed(() => {
  return props.item.workspaceIds.length > MIN_COUNT
})

onMounted(async () => {
  users.value = await getUsersList()
})
const users = ref([])

const hiddenUsers = computed(() => {
  return props.usersToAdd
    .map(user => user[REQUEST_ENTITY_KEYS.IDENTIFIER])
    .filter(userId => userId !== props.item[REQUEST_ENTITY_KEYS.IDENTIFIER])
})
const getUsersList = async (searchString = null) => {
  const platformApi = new PlatformApiHandler()

  try {
    return await platformApi.getPlatformUsers({
      searchString
    })
  } catch (error) {
    handleError({ error })
  }
}
</script>

<style lang="scss" scoped>
$group-gap: 20px;
$items-count: var(--items-count, 2);

.aufg-Group {
  font-family: $system-ui;
  position: relative;
  flex: 1 0 auto;
  display: flex;
  align-items: flex-start;
  gap: $group-gap;
  width: var(--items-width, 100%);
}

.aufg-FieldItem_User {
  min-width: 200px;
  width: 200px;
  position: relative;
  --gap: 0;
  --label-bottom-offset: 6px;
}
.aufg-FieldItem {
  width: calc((100% - (#{$group-gap} * (#{$items-count} - 1))) / #{$items-count});
  position: relative;
  --gap: 0;
  --label-bottom-offset: 6px;
  max-width: 160px;
}

.aufg-ClearButton {
  position: absolute;
  right: -24px;
  top: 8px; // 40px - 24px / 2 where 40px is height of field and 24px is height of button
  color: $grey-1-next;
}

.aufg-Error {
  min-height: 20px;
  display: flex;
  align-items: center;
  // position: absolute;
  // bottom: -20px;
  // left: 0;
}

// .aufg-GroupsSelect {
//   :deep(.as-AppDroplistButton_Content) {
//     padding: 6px 0 6px 6px;
//   }
//
//   // :deep(.as-AppDroplistButton-focused) {
//   //   max-height: 40px;
//   //   overflow: hidden;
//   // }
// }

.aufg-SelectEmptyLabel {
  padding-left: 2px;
}
.aufg-WorkspacesContainer {
  width: calc(
    100% - 170px - 20px
  ); // 170px is width of user field, 20px is gap between user field and workspaces
  display: flex;
  flex-direction: column;
}
.aufg-Workspaces {
  flex: 1 0 auto;
  display: flex;
  align-items: flex-start;
  gap: 20px;
  width: var(--items-width, 100%);
  position: relative;
  &:not(:first-child) {
    margin-top: 20px;
  }
}
.aufg-Fields_Message {
  margin-top: 10px;
}

.aufg-AddButton {
  font-size: $fs-12;
  margin-top: 10px;
}
</style>
