<template>
  <div v-if="!route.meta.hideNav" class="ogp-Page">
    <PageContentHeader
      :level="3"
      :max-width="HEADER_SIZES.LG"
      :title="$t('menu.organization.description')"
      no-margin
      style="--padding-bottom: 9px"
    />

    <div :class="{ 'ogp-Toolbar-full-width': route.meta.fullWidthToolbar }" class="ogp-Toolbar">
      <AppRadioGroup
        :model-value="currentView"
        :options="pages"
        name="page-navigation"
        type="tab-like"
        @update:model-value="onViewChange"
      >
        <template #item-label="{ item }">
          <AppBadge
            v-if="item.badgeTitle"
            :content="item.badgeTitle"
            class="ogp-Badge"
            is-uppercase
          >
            {{ item.label }}
          </AppBadge>
        </template>
      </AppRadioGroup>
    </div>
  </div>

  <router-view v-if="accessValidated" />
</template>

<script setup>
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

import { ROUTE_NAMES } from '@/routes/route-helpers'
import { tracker } from '@/tracking/amplitude'
import { EVENT_CATEGORIES, EVENT_NAMES } from '@/tracking/amplitude-helpers'
import { HEADER_SIZES } from '@/utils/components-configurations/page-content-header'

import AppBadge from '@/components/ui/AppBadge/AppBadge'
import AppRadioGroup from '@/components/ui/AppRadioGroup/AppRadioGroup'
import PageContentHeader from '@/components/ui/PageContentHeader/PageContentHeader'

defineOptions({
  name: 'OrganizationLayout'
})

const { t } = useI18n()

const PAGES = [
  {
    value: ROUTE_NAMES.ORGANIZATION_GLOBAL_GROUPS,
    label: t('field.groups.title'),
    trackingTab: 'field.groups.title'
  },
  {
    value: ROUTE_NAMES.ORGANIZATION_USERS,
    label: t('common.users'),
    trackingTab: 'common.users'
  },
  {
    value: ROUTE_NAMES.ORGANIZATION_ADMINISTRATORS,
    label: t('menu.admins'),
    trackingTab: 'menu.admins',
    allowedOnlyForPluginAdmin: true
  }
]

const store = useStore()

const userRoleForTracking = computed(() => {
  return store.getters['system/userRoleForTracking']
})

const isPluginAdmin = computed(() => {
  return store.state.pluginOptions.isPluginAdmin
})

const userData = computed(() => {
  return store.state.system.userData
})

const pages = computed(() => {
  if (isPluginAdmin.value) {
    return PAGES
  }

  return PAGES.filter(page => !page.allowedOnlyForPluginAdmin)
})

const route = useRoute()

const currentView = computed(() => route.name)

const router = useRouter()

const onViewChange = view => {
  const page = pages.value.find(page => page.value === view)
  if (page) {
    router.push({ name: view })

    const trackerData = {
      category: EVENT_CATEGORIES.SETTINGS,
      subCategory: t('menu.organization', 1, { locale: 'en' }),
      role: userRoleForTracking.value
    }

    if (page.trackingTab) {
      trackerData.tab = t(page.trackingTab, 1, { locale: 'en' })
    }

    tracker.logEvent(EVENT_NAMES.SETTINGS_OPENED, trackerData)
  }
}

const accessValidated = ref(false)

watch(
  userData,
  newValue => {
    const userHasAccessToPage = newValue.hasAccessToGlobalGroupsPage

    const pageExists =
      pages.value.some(page => page.value === route.name) ||
      route.name === ROUTE_NAMES.ORGANIZATION_USER_DETAILS

    if (!userHasAccessToPage || !pageExists) {
      router.push({ name: ROUTE_NAMES.MISSING_PAGE })
      return
    }

    accessValidated.value = true
  },
  { immediate: true, deep: true }
)
</script>

<style lang="scss" scoped>
.ogp-Toolbar {
  padding: 5px $page-right-padding 0 $page-left-padding;

  &:not(&-full-width) {
    width: 100%;
    max-width: $page-width-lg;
  }

  &-full-width {
    width: $page-width-full;
  }
}

.ogp-Badge {
  --color: var(--grade-low-color-next);
  --badge-size: 12px;
  --padding: 2px 4px;
  --top: -10px;
  --right: -18px;
}
</style>
