<template>
  <div :class="{ 'crl-Wrapper-is-server': isServer }" class="crl-Wrapper">
    <div :class="classes" class="crl-ReportItem" @click="emit('click')">
      <div v-if="isEmptyData" class="crl-ReportItem_Content">
        <AppIcon height="64" icon-name="plus-secondary-next" width="36" />
        <span class="crl-ReportItem_Text">{{ title }}</span>
      </div>
      <div v-else>
        <div class="crl-DataWrapper">
          <span class="crl-DataWrapper_Title">
            <AnimatedNumber
              v-slot="{ displayValue }"
              :end-amount="getSimplifiedNumber(getProgress)[PLURALIZATION_KEYS.FLOAT_NUMBER]"
            >
              {{ `${displayValue}${getSimplifiedNumber(getProgress)[PLURALIZATION_KEYS.SYMBOL]}` }}
            </AnimatedNumber>
            <sub class="crl-Title_SubText"> % </sub>
          </span>
          <span class="crl-DataWrapper_Name">
            {{ data.name }}
          </span>
        </div>
      </div>
      <DropdownMenu
        v-if="!isEmptyData"
        ref="dropdown"
        :items="MODAL_ACTIONS_MENU_ITEMS"
        :offset="[0, 0]"
        :type="DROPDOWN_MENU_TYPES.DEFAULT_NEXT"
        class="crl-DropdownMenu"
        position="bottom-end"
        @item-click="onMenuActionsClick"
      >
        <template #activator="{ isOpened }">
          <!-- prevent and stop modifiers both to stop propagation on click from button to parent router-link (stop) and prevent default behavior for <a> tag from router-link  -->
          <AppButton
            :class="{ 'crl-DropdownMenu_Button-opened': isOpened }"
            class="crl-DropdownMenu_Button"
            icon="more-next"
            size="sm"
            type="primary-next"
            @click.prevent.stop="toggleDropdown"
          />
        </template>
      </DropdownMenu>
      <IndicatorPanel
        :background-color="indicatorData.background"
        :color="indicatorData.color"
        :indicator-width="indicatorData.value"
        :style="{ '--height': indicatorData.height }"
      />
    </div>
  </div>
</template>

<script setup>
import { isEmpty } from 'lodash'
import { computed, ref } from 'vue'
import { useStore } from 'vuex'

import { DROPDOWN_MENU_TYPES } from '@/utils/components-configurations/dropdown-menu'
import { MENU_ITEMS_GROUPS } from '@/utils/dropdown-menu'
import { OKR_ELEMENTS_TABLE_ACTIONS_MENU_ITEMS } from '@/utils/objectives'
import { getSimplifiedNumber, PLURALIZATION_KEYS } from '@/utils/pluralization'

import AnimatedNumber from '@/components/ui/AnimatedNumber/AnimatedNumber'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppIcon from '@/components/ui/AppIcon/AppIcon'
import DropdownMenu from '@/components/ui/DropdownMenu/DropdownMenu'
import IndicatorPanel from '@/components/ui/IndicatorPanel/IndicatorPanel'

defineOptions({
  name: 'CustomDashboardListItem'
})

const props = defineProps({
  title: {
    type: String,
    default: ''
  },
  data: {
    type: Object,
    default: () => ({})
  },
  size: {
    type: String,
    default: 'default'
  },
  withoutBackground: {
    type: Boolean
  }
})

const emit = defineEmits({
  click: null,
  'remove-dashboard': null,
  'edit-dashboard': null
})

const classes = computed(() => {
  return {
    'crl-ReportItem-empty': isEmptyData.value,
    'crl-ReportItem-imgLighten': true,
    'crl-ReportItem-imgDarken': false,
    [`crl-ReportItem-${props.size}`]: props.size,
    'crl-ReportItem-withoutBackground': props.withoutBackground
  }
})
const isEmptyData = computed(() => {
  return isEmpty(props.data)
})

const indicatorData = computed(() => {
  return {
    value: props.data.progress,
    color: isEmptyData.value ? 'var(--primary-color-next)' : 'var(--primary-color-next)',
    background: isEmptyData.value
      ? 'var(--grey-3-next)'
      : 'rgba(var(--primary-color-rgb-next), 0.2)',
    height: isEmptyData.value ? '2px' : '6px'
  }
})

const onMenuActionsClick = name => {
  const { REMOVE, EDIT } = OKR_ELEMENTS_TABLE_ACTIONS_MENU_ITEMS

  if (name === REMOVE.name) {
    emit('remove-dashboard', { id: props.data.id, name: props.data.name })
  } else if (name === EDIT.name) {
    emit('edit-dashboard', props.data)
  }
}
const MODAL_ACTIONS_MENU_ITEMS = [
  {
    name: OKR_ELEMENTS_TABLE_ACTIONS_MENU_ITEMS.EDIT.name,
    title: 'action.edit',
    icon: 'edit-next',
    group: MENU_ITEMS_GROUPS.EDITING
  },
  {
    name: OKR_ELEMENTS_TABLE_ACTIONS_MENU_ITEMS.REMOVE.name,
    title: 'action.delete',
    icon: 'delete-next',
    group: MENU_ITEMS_GROUPS.REMOVING,
    color: 'var(--grade-low-color-next)'
  }
]

const dropdown = ref(null)
const toggleDropdown = () => {
  dropdown.value.toggle()
}

const getProgress = computed(() => {
  return isNaN(props.data.progress) ? 0 : props.data.progress
})

const store = useStore()
const isServer = computed(() => store.getters['pluginOptions/isPluginServer'])
</script>

<style lang="scss" scoped>
$customBackground: 'https://s3-oboard-public-static.s3.eu-central-1.amazonaws.com/img/dashboard_map.png';
.crl-Wrapper {
  max-width: 250px;
}
.crl-ReportItem {
  position: relative;
  min-height: 184px;
  height: 100%;
  border: 2px var(--border-style, solid) var(--border-color, $grey-3-next);
  border-radius: $border-radius-lg-next;
  font-weight: fw('semi-bold');
  font-size: $fs-14;
  line-height: 20px;
  color: $dark-3;
  padding: 20px;
  cursor: pointer;
  font-family: $system-ui;
  &:hover {
    color: $primary-color-next;
    background: $grey-3-next;
  }
  &:not(.crl-ReportItem-empty) {
    background: $primary-color-next;
    color: $white;
    &:hover {
      background: lighten($primary-color-next, 10%);
      color: $white;
    }
  }
  &-imgLighten {
    &:not(.crl-ReportItem-empty) {
      box-shadow: inset 0 0 0 6px $white;
      background: url($customBackground) top/cover no-repeat;
      color: $dark-1;
      .crl-Wrapper-is-server & {
        background-image: url('~assets/images/dashboard_map.png');
      }
      &:hover {
        overflow: hidden;
        background: url($customBackground) top/cover no-repeat;
        color: $dark-1;
        .crl-Wrapper-is-server & {
          background-image: url('~assets/images/dashboard_map.png');
        }
        &:after {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          content: '';
          background: rgba(var(--grade-default-color-rgb), 0.05);
          display: block;
        }
      }
      .crl-DropdownMenu_Button {
        color: $dark-1;
      }
    }
  }
  &-imgDarken {
    box-shadow: inset 0 0 0 6px $dark-1;
  }
  &-grow {
    min-width: 180px;
    width: 100%;
    height: 100%;
    &.crl-ReportItem-empty {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      .crl-ReportItem_Text {
        margin-top: 11px;
      }
    }
  }
  &-withoutBackground {
    background: none;
    &:hover {
      background: none;
    }
  }
}
.crl-ReportItem_Content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.crl-ReportItem_Text {
  margin: 14px 0 24px 0;
  line-height: 40px;
}
.crl-DataWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 0 20px 0;
}
.crl-DataWrapper_Title {
  font-family: $system-ui;
  font-size: $fs-56;
  font-weight: fw('bold');
  line-height: 64px;
  display: flex;
  align-items: center;
  gap: 4px;
  margin: 0 0 14px 0;
}
.crl-Title_SubText {
  font-size: $fs-20;
  line-height: 24px;
  font-weight: fw('semi-bold');
  bottom: -0.7em;
}
.crl-DropdownMenu {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1;
}
.crl-DropdownMenu_Button {
  background: transparent;
  &:hover {
    background: rgba(245, 246, 247, 0.2);
  }
  &:active {
    background: rgba(245, 246, 247, 0.4);
  }
  &-opened {
    background: rgba(245, 246, 247, 0.2);
  }
}
.crl-DataWrapper_Name {
  height: 100%;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: normal;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1 1 40px;
  word-break: break-all;
}
</style>
