export const FILTERS_OPERATORS = {
  SHOW_ALL: 0,
  EQUAL: 1,
  NOT_EQUAL: 2,
  GREATER_THAN_OR_EQUAL: 3,
  GREATER_THAN: 4,
  LESS_THAN_OR_EQUAL: 5,
  LESS_THAN: 6,
  BETWEEN: 7,
  CONTAINS: 8,
  IS_EMPTY: 9,
  IS_NOT_EMPTY: 10
}
