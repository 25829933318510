import { has, isEmpty, isFunction, isObject, isUndefined } from 'lodash'

export const GROUP_ID_KEY = 'groupId'
export const CALLBACK_KEY = 'callBack'

export const emitsValidator = ({ obj, key }) => {
  return (
    isObject(obj) &&
    !isEmpty(obj) &&
    has(obj, key) &&
    !isUndefined(obj[key]) &&
    has(obj, CALLBACK_KEY) &&
    isFunction(obj[CALLBACK_KEY])
  )
}

export const DEFAULT_USERS_LIMIT = 50
export const DEFAULT_GROUPS_LIMIT = 1000

export const USERS = 'users'
export const SYNCED = 'synced'

export const ACCOUNT_ID = 'accountId'
export const ID = 'id'

export const TABLE_ITEM_TYPES = {
  GROUP: 'group',
  CATEGORY: 'category',
  USER: 'user'
}

export const ERRORS_TEXTS = {
  NOT_DEFINED_GROUP: 'Group is not defined',
  NOT_DEFINED_USER: 'User is not defined',
  NOT_DEFINED_WORKSPACE_ID: 'Workspace id is not defined',
  NORMALIZE_USER_REQUIRED_KEYS: 'groupId and categoryKey are required'
}
