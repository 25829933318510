<template>
  <div class="it-Loader">
    <div v-for="row in ROWS" :key="row.id" class="it-Loader_Row">
      <div class="it-Cell">
        <SkeletonItem :appearance="SQUARE" :size="SM" />
        <SkeletonItem :width="row.name" :size="XS" />
      </div>

      <div class="it-Cell">
        <div class="it-Cell_Icon">
          <SkeletonItem v-if="row.isBacklog" :appearance="CIRCLE" width="14px" height="14px" />
          <SkeletonItem
            v-if="row.active"
            color="var(--grade-high-color-next)"
            :appearance="CIRCLE"
            width="6px"
            height="6px"
          />
        </div>
        <SkeletonItem :size="XS" :width="row.period" />
      </div>

      <div class="it-Cell">
        <SkeletonItem
          v-if="!row.isBacklog"
          height="20px"
          border-radius="6px"
          :width="row.isArchived ? '68px' : '40px'"
          :color="row.isArchived ? '#6B778C' : 'var(--grade-high-color-next)'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'IntervalsTableLoader'
})
</script>

<script setup>
import {
  SKELETON_APPEARANCES,
  SKELETON_SIZES
} from '@/utils/components-configurations/skeleton-item'

import SkeletonItem from '@/components/ui/SkeletonLoaders/SkeletonItem'

const { XS, SM } = SKELETON_SIZES
const { SQUARE, CIRCLE } = SKELETON_APPEARANCES

const ROWS = [
  {
    id: 666,
    name: '104px',
    period: '388px',
    isBacklog: true
  },
  {
    id: 0,
    name: '55px',
    period: '180px'
  },
  {
    id: 1,
    name: '80px',
    period: '170px',
    active: true
  },
  {
    id: 2,
    name: '48px',
    period: '175px',
    isArchived: true
  },
  {
    id: 3,
    name: '90px',
    period: '180px',
    active: true
  },
  {
    id: 4,
    name: '55px',
    period: '160px'
  },
  {
    id: 5,
    name: '100px',
    period: '190px',
    isArchived: true,
    active: true
  },
  {
    id: 6,
    name: '65px',
    period: '170px',
    active: true
  },
  {
    id: 7,
    name: '70px',
    period: '190px',
    isArchived: true
  },
  {
    id: 8,
    name: '120px',
    period: '180px'
  }
]
</script>

<style scoped lang="scss">
.it-Loader_Row {
  display: flex;
  align-items: center;
  height: 45px;
  padding: 0 40px;
}

.it-Cell {
  &:first-child {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 248px;
    flex: 0 0 248px;
  }

  &:nth-child(2) {
    width: auto;
    flex: 1 1 100%;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &:nth-child(3) {
    width: 108px;
    flex: 0 0 108px;
    opacity: 0.3;
  }
}

.it-Cell_Icon {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
