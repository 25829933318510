<template>
  <AppModalWithConfirmation
    :confirm-close="areDataChanged"
    :show="show"
    :size="isBulkAdd ? 744 : 510"
    class="autg-Modal"
    hide-hero
    @on-close="onClose"
  >
    <AppTitle :level="3" class="autg-ModalTitle" disable-margin>
      {{ modalTitle }}
    </AppTitle>

    <AppRadioGroup
      v-if="needShowSwitcher"
      v-model="addUsersConfiguration"
      :options="ADD_USERS_CONFIGURATIONS"
      data-testid="add-users-navigation"
      name="add-users-navigation"
      size="xs"
      style="--option-padding: 0 8px 0 4px"
      type="primary-next"
      @update:model-value="onTabChange"
    >
      <template #item-label="{ item }">
        <AppRadioGroupNextOptionWithIcon :option="item" />
      </template>
    </AppRadioGroup>

    <div class="autg-Body">
      <template v-if="isBulkAdd">
        <FormFieldNext
          :label="fieldLabel"
          class="autg-FormField"
          data-testid="bulk-mode-groups-field"
        >
          <div class="autg-GroupsField">
            <AppSelect
              v-model="selectedGroups"
              :is-error="isGroupsFieldEmpty"
              :loading="isSearchLoading"
              :offset="[0, '-100%']"
              :options="platformGroups"
              :placeholder="$t('placeholder.search')"
              :search-function="getPlatformGroups"
              append-to=".autg-GroupsField"
              boundary="scrollParent"
              class="autg-Select"
              data-testid="bulk-mode-groups-field-select"
              dropdown-search
              hide-selected-items-in-dropdown
              item-label="name"
              item-value="groupId"
              multi
              show-all-selected
              show-selected-options-inside
              skeleton-loader
              @open="isGroupsFieldEmpty = false"
              @update:options="platformGroups = $event"
            >
              <template #button-content="{ options }">
                <span v-if="isEmpty(options)" class="autg-SelectEmptyLabel">
                  {{ fieldLabel }}
                </span>
              </template>
            </AppSelect>
          </div>
          <AppFieldError
            v-if="isGroupsFieldEmpty"
            :show="isGroupsFieldEmpty"
            class="autg-FieldError"
            data-testid="groups-field-error"
          >
            {{ $t('field.required') }}
          </AppFieldError>
        </FormFieldNext>
        <WorkspacesAffectMessage
          :limit="5"
          :workspaces="group.workspaces"
          class="autg-AffectedWorkspaces"
        >
          {{ $t('group.user_will_be_added_to_the_workspaces', 2) }}
        </WorkspacesAffectMessage>
        <FormFieldNext
          class="autg-StrategyField autg-FormField"
          data-testid="bulk-mode-strategy-field"
          label="Select import options"
        >
          <AppRadioGroup
            v-model="importStrategy"
            :is-error="isStrategyFieldEmpty"
            :options="IMPORT_STRATEGY_OPTIONS"
            :type="APP_RADIO_GROUP_TYPES.BLOCKS"
            data-testid="bulk-mode-strategy-field-switch"
            name="strategy"
            @update:model-value="isStrategyFieldEmpty = false"
          />

          <AppFieldError
            v-if="isStrategyFieldEmpty"
            :show="isStrategyFieldEmpty"
            class="autg-FieldError"
            data-testid="strategy-field-error"
          >
            {{ $t('field.required') }}
          </AppFieldError>
        </FormFieldNext>
      </template>

      <template v-else>
        <FormFieldNext
          :label="fieldLabel"
          class="autg-FormField"
          data-testid="non-bulk-mode-users-field"
        >
          <div class="autg-UsersField">
            <AppSelect
              ref="usersSelectReference"
              v-model="selectedUsers"
              :allow-create-on-search="allowAddEmailsFromSelect"
              :disabled-items="disabledItems"
              :entity-for-create="CREATE_ON_SEARCH_ENTITIES.EMAIL"
              :is-error="isUsersFieldEmpty"
              :loading="isSearchLoading"
              :offset="[0, '-100%']"
              :options="users"
              :placeholder="$t('placeholder.search')"
              :search-function="getUsers"
              allow-add-emails-from-select
              append-to=".autg-UsersField"
              boundary="scrollParent"
              class="autg-Select"
              content-class="autg-SelectContent"
              data-testid="non-bulk-mode-users-field-select"
              dropdown-search
              hide-selected-items-in-dropdown
              item-label="name"
              item-value="accountId"
              multi
              show-all-selected
              show-selected-options-inside
              skeleton-loader
              @open="isUsersFieldEmpty = false"
              @update:options="users = $event"
            >
              <template #option-label="{ option }">
                <OwnerFieldOption
                  :already-added="option.alreadyAdded"
                  :option="option"
                  label-key="name"
                >
                  <template v-if="option.alreadyAdded" #badge>
                    {{ $t('badge.already_added') }}
                  </template>
                </OwnerFieldOption>
              </template>

              <template #button-content="{ options }">
                <span v-if="isEmpty(options)" class="autg-SelectEmptyLabel">
                  {{ fieldLabel }}
                </span>
              </template>
            </AppSelect>
          </div>

          <AppFieldError
            :show="isUsersFieldEmpty"
            class="autg-FieldError"
            data-testid="users-field-error"
          >
            {{ $t('field.required') }}
          </AppFieldError>
        </FormFieldNext>
        <WorkspacesAffectMessage
          :limit="5"
          :workspaces="group.workspaces"
          class="autg-AffectedWorkspaces"
        >
          {{ $t('group.user_will_be_added_to_the_workspaces', selectedUsers.length) }}
        </WorkspacesAffectMessage>
      </template>
    </div>

    <template #footer-actions>
      <AppButton type="ghost-next" @click="onClose">
        {{ $t('action.cancel') }}
      </AppButton>

      <AppButton
        :disable="isLoading"
        :loading="isLoading"
        data-testid="add-users-to-group-modal-submit-button"
        type="primary-next"
        @click="addUsers"
      >
        {{ confirmButtonText }}
      </AppButton>
    </template>
  </AppModalWithConfirmation>
</template>

<script setup>
import { isEmpty, isNull, uniq } from 'lodash'
import { computed, nextTick, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

import GlobalGroupsApiHandler from '@/api/global-groups'
import PlatformApiHandler from '@/api/platform'
import {
  EVENT_SOURCES,
  MODE_NAMES_FOR_TRACKING,
  trackAddUserEvent,
  trackAddUserEventBulk,
  TRACKING_UNKNOWN,
  trackLinkJiraGroupsEvent,
  trackOpenAddUserModalEvent
} from '@/tracking/amplitude-helpers'
import {
  ADD_USER_TAB,
  BULK_ADD_TAB,
  useBulkOperationSwitcher
} from '@/utils/bulk-operation-switcher'
import { RADIO_GROUP_TYPES as APP_RADIO_GROUP_TYPES } from '@/utils/components-configurations/app-radio-group'
import { handleError } from '@/utils/error-handling'
import { CREATE_ON_SEARCH_ENTITIES } from '@/utils/select'
import {
  createPayloadForAccountIdsAndEmails,
  enrichPayloadWithUserIdentifier,
  useEmailsInSelect
} from '@/utils/web-app/emails-in-select'

import AppModalWithConfirmation from '@/components/AppModalWithConfirmation'
import AppFieldError from '@/components/form/AppFieldError'
import FormFieldNext from '@/components/form/FormFieldNext'
import OwnerFieldOption from '@/components/form/OwnerFieldOption'
import WorkspacesAffectMessage from '@/components/global-groups/WorkspacesAffectMessage'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppRadioGroup from '@/components/ui/AppRadioGroup/AppRadioGroup'
import AppRadioGroupNextOptionWithIcon from '@/components/ui/AppRadioGroup/AppRadioGroupNextOptionWithIcon'
import AppSelect from '@/components/ui/AppSelect/AppSelect'
import AppTitle from '@/components/ui/AppTitle/AppTitle'

defineOptions({
  name: 'AddUsersToGroupModal'
})

const { allowAddEmailsFromSelect, userSelectLabelWithoutTranslation } = useEmailsInSelect()

const props = defineProps({
  show: {
    type: Boolean
  },

  group: {
    type: Object,
    required: true
  },

  trackingSource: {
    type: String,
    default: TRACKING_UNKNOWN
  }
})

const areDataChanged = computed(() => {
  if (isBulkAdd.value) {
    return !isEmpty(selectedGroups.value)
  }

  return !isEmpty(selectedUsers.value)
})

const emit = defineEmits({
  close: null,
  'on-users-added': null
})

const onClose = () => {
  emit('close')
}

const { t } = useI18n()

const modalTitle = computed(() => {
  return t('workspaces.add_users_to_group', { group: props.group.name })
})

const addUsersConfiguration = ref(ADD_USER_TAB)
const importStrategy = ref(null)

const { resolvedOptions: ADD_USERS_CONFIGURATIONS, needShowSwitcher } = useBulkOperationSwitcher()

const IMPORT_STRATEGY_OPTIONS = [
  {
    label: t('action.import_users'),
    message: t('users.import_users_description'),
    value: false,
    icon: 'import-users-large'
  },
  {
    label: t('users.link_jira_groups'),
    message: t('users.link_jira_groups_description'),
    value: true,
    icon: 'replace_jira-large'
  }
]

const isBulkAdd = computed(() => addUsersConfiguration.value === BULK_ADD_TAB)

const selectedUsers = ref([])
const selectedGroups = ref([])
const isSearchLoading = ref(false)
const users = ref([])
const platformGroups = ref([])
const isUsersFieldEmpty = ref(false)
const isGroupsFieldEmpty = ref(false)
const isStrategyFieldEmpty = ref(false)
const disabledItems = ref([])

const setDefaultState = () => {
  selectedUsers.value = []
  selectedGroups.value = []
  isSearchLoading.value = false
  users.value = []
  platformGroups.value = []
  disabledItems.value = []
  addUsersConfiguration.value = ADD_USER_TAB
  importStrategy.value = null
  isGroupsFieldEmpty.value = false
  isStrategyFieldEmpty.value = false
  isUsersFieldEmpty.value = false
}

const fieldLabel = computed(() => {
  if (isBulkAdd.value) {
    return t('workspaces.select_platform_groups', {
      platform: t('app.platform.jira')
    })
  }

  return t(userSelectLabelWithoutTranslation.value, 2)
})

const confirmButtonText = computed(() => {
  if (isBulkAdd.value) {
    return !importStrategy.value
      ? t('action.import_users')
      : t('action.link_platform_groups', {
          platform: t('app.platform.jira')
        })
  }

  return t('action.add_users')
})

const getUsers = async (searchString = null) => {
  const api = new GlobalGroupsApiHandler()

  let usersList = []
  isSearchLoading.value = true

  try {
    usersList = await api.getUsersForAdditionToGlobalGroup({
      groupId: props.group.id,
      searchString
    })
  } catch (error) {
    handleError({ error })
  } finally {
    isSearchLoading.value = false
  }

  const normalizedUsers = usersList
    .map(user => {
      return {
        ...user,
        alreadyAdded: user.existInGroup
      }
    })
    .sort((a, b) => {
      return a.alreadyAdded - b.alreadyAdded
    })

  disabledItems.value = uniq([
    ...disabledItems.value,
    ...normalizedUsers.filter(user => user.alreadyAdded).map(user => user.accountId)
  ])
  return normalizedUsers
}

const getPlatformGroups = async (searchString = null) => {
  const api = new PlatformApiHandler()

  isSearchLoading.value = true

  let platformGroupsList = []

  try {
    platformGroupsList = await api.getPlatformGroups({
      searchString
    })
  } catch (error) {
    handleError({ error })
  } finally {
    isSearchLoading.value = false
  }

  return platformGroupsList
}

const usersSelectReference = ref(null)

watch(
  () => props.show,
  async newValue => {
    if (newValue) {
      users.value = await getUsers()
      // nextTick and setTimeout(both!) are needed to make input always focused on modal window open
      await nextTick()
      setTimeout(() => {
        usersSelectReference.value?.focus()
      }, 200)
    } else {
      setDefaultState()
    }
  },
  {
    immediate: true
  }
)

// const usersSelectReference = ref(null)
// const groupsSelectReference = ref(null)

watch(
  () => isBulkAdd.value,
  async newValue => {
    if (newValue && isEmpty(platformGroups.value)) {
      platformGroups.value = await getPlatformGroups()
    }
    // if (newValue) {
    //   await nextTick()
    //   groupsSelectReference.value.focus()
    // } else {
    //   await nextTick()
    //   usersSelectReference.value.focus()
    // }
  },
  {
    immediate: true
  }
)

const store = useStore()

const userRoleForTracking = computed(() => {
  return store.getters['system/userRoleForTracking']
})

const isLoading = ref(false)
const addUsers = async () => {
  const api = new GlobalGroupsApiHandler()

  if (isBulkAdd.value) {
    const isNoGroups = isEmpty(selectedGroups.value)
    const isNoStrategy = isNull(importStrategy.value)
    if (isNoGroups || isNoStrategy) {
      isGroupsFieldEmpty.value = isNoGroups
      isStrategyFieldEmpty.value = isNoStrategy
      return
    }

    isLoading.value = true

    try {
      const resolvedPlatformGroupIds = uniq([
        ...props.group.associatedPlatformGroups.map(group => group.groupId),
        ...selectedGroups.value
      ])

      const payload = {
        id: props.group.id,
        platformGroupIds: resolvedPlatformGroupIds
      }

      if (importStrategy.value) {
        await api.updateGlobalGroup({
          ...payload,
          synced: true,
          parentId: props.group.parentId
        })
      } else {
        await api.importUsersToGroup(payload)
      }

      trackLinkJiraGroupsEvent({
        role: userRoleForTracking.value,
        source: props.trackingSource
      })

      trackAddUserEventBulk({
        synced: importStrategy.value,
        source: props.trackingSource
      })

      emit('on-users-added', props.group.id)
    } catch (error) {
      handleError({ error })
    } finally {
      isLoading.value = false
    }
  } else {
    if (isEmpty(selectedUsers.value)) {
      isUsersFieldEmpty.value = true
      return
    }

    isLoading.value = true

    const { emails, accountIds } = createPayloadForAccountIdsAndEmails({
      selectedItems: selectedUsers.value
    })

    const items = enrichPayloadWithUserIdentifier({
      payload: [...emails, ...accountIds]
    }).map(item => {
      return {
        ...item,
        groupIds: [props.group.id]
      }
    })

    trackAddUserEvent({
      source: props.trackingSource,
      selectedUsers: items
    })

    try {
      await api.addUsersToGlobalGroups({
        items
      })

      emit('on-users-added', props.group.id)
    } catch (error) {
      handleError({ error })
    } finally {
      isLoading.value = false
    }
  }
}

const onTabChange = value => {
  const tab = ADD_USERS_CONFIGURATIONS.find(item => item.value === value).trackingName
  trackOpenAddUserModalEvent({
    tab,
    source: EVENT_SOURCES.GROUP_USER_TAB,
    mode: MODE_NAMES_FOR_TRACKING.ADD_TO_GROUP,
    role: userRoleForTracking.value
  })
}
</script>

<style lang="scss" scoped>
.autg-UsersField,
.autg-GroupsField {
  position: relative;
}

.autg-StrategyField {
  margin-top: 20px;
}

.autg-FormField {
  --gap: 0;
  --label-bottom-offset: 6px;
}

.autg-Select {
  :deep(.as-AppDroplistButton_Content) {
    padding: 6px 0 6px 6px;
  }
}
:deep(.autg-SelectContent) {
  .o-checkbox-label-inner,
  .o-checkbox-label-text {
    width: 100%;
  }

  .o-checkbox-disabled {
    opacity: 1;
  }
}
.autg-SelectEmptyLabel {
  padding-left: 2px;
}

.autg-ModalTitle {
  font-family: $system-ui;
  color: $dark-1;
  font-weight: fw('semi-bold');
  line-height: 28px;
  margin-bottom: 9px;
}

.autg-Body {
  margin-top: 20px;
}

.autg-FieldError {
  line-height: 20px;
}

.autg-AffectedWorkspaces {
  margin: 10px 0 0 0;
}
</style>
