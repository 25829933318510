<template>
  <slot
    :connection-status="connectionStatus"
    :has-access-to-jira="hasAccessToJira"
    :is-jira-app="isJiraApp"
    :is-jira-connected="isJiraConnected"
    :is-salesforce-app="isSalesforceApp"
    :is-web-app="isWebApp"
    :is-web-app-connected="isWebAppConnected"
  />
</template>

<script setup>
import { computed, inject } from 'vue'
import { useStore } from 'vuex'

import {
  isJiraAppInjectionKey,
  isSalesforceAppInjectionKey,
  isWebAppInjectionKey
} from '@/utils/injection-keys'
import { PLUGIN_OPTIONS_KEYS } from '@root/template-options-keys'

const isJiraApp = inject(isJiraAppInjectionKey)
const isWebApp = inject(isWebAppInjectionKey)
const isSalesforceApp = inject(isSalesforceAppInjectionKey)

const store = useStore()

const isJiraConnected = computed(() => {
  return store.state.pluginOptions[PLUGIN_OPTIONS_KEYS.JIRA_CONNECTED]
})

const isWebAppConnected = computed(() => {
  return store.state.pluginOptions[PLUGIN_OPTIONS_KEYS.WEB_APP_CONNECTED]
})

const connectionStatus = computed(() => {
  return (
    (isWebApp && isJiraConnected.value) ||
    (isJiraApp && isWebAppConnected.value) ||
    (isSalesforceApp && isJiraConnected.value)
  )
})

const hasAccessToJira = computed(() => store.state.system.userData?.hasAccessToJira || false)
</script>
