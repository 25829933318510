<template>
  <DropdownMenu
    v-if="itemMenuActions.length"
    ref="dropdown"
    :append-to="appendTo"
    :content-class="[`sflo-ActionsMenu-${item.id}`]"
    :items="itemMenuActions"
    :offset="[0, 0]"
    :type="DROPDOWN_MENU_TYPES.DEFAULT_NEXT"
    preferred-position="right-start"
    @item-click="onActionClick($event)"
  >
    <template #activator="{ isOpened }">
      <!-- prevent and stop modifiers both to stop propagation on click from button to parent router-link (stop) and prevent default behavior for <a> tag from router-link  -->
      <AppButton
        :class="{
          'mia-Trigger-active': isOpened || showForm
        }"
        class="mia-Trigger"
        icon="more"
        size="sm"
        type="ghost-next"
        @click.prevent.stop="toggleDropdown"
      />
    </template>
  </DropdownMenu>

  <AppDroplist
    v-model="showForm"
    :append-to="formAppendTo"
    :has-fixed-parent="true"
    :offset="[0, 0]"
    max-width="372px"
    position="right"
  >
    <OkrFilterSaverForm
      v-if="showForm"
      :form-model="item"
      :placement="placement"
      @close-form="showForm = false"
    />
  </AppDroplist>
</template>

<script>
import { defineComponent } from 'vue'
import { mapActions, mapState } from 'vuex'

import CustomDashboardApiHandler from '@/api/custom-dashboard'
import OkrFiltersApiHandler from '@/api/okr-filters'
import { ROUTE_NAMES } from '@/routes/route-helpers'
import { trackDeleteCustomDashboardEvent } from '@/tracking/amplitude-helpers'
import { DROPDOWN_MENU_TYPES } from '@/utils/components-configurations/dropdown-menu'
import { MENU_ITEMS_GROUPS } from '@/utils/dropdown-menu'
import { handleError } from '@/utils/error-handling'
import { showNotify } from '@/utils/notify'
import { FILTER_PRESET_KEY } from '@/utils/query-parameters'
import { deleteQueryParameter } from '@/utils/router'

import AppDroplist from '@/components/AppDroplist'
import { DASHBOARD, FILTERS, placementPropValidator } from '@/components/AppMenu/props-validators'
import OkrFilterSaverForm from '@/components/objectives/toolbar/OkrFilterSaverForm'
import AppButton from '@/components/ui/AppButton/AppButton'
import DropdownMenu from '@/components/ui/DropdownMenu/DropdownMenu'

const MENU_ACTIONS = {
  RENAME: {
    name: 'edit',
    title: 'action.rename',
    icon: 'edit-next',
    group: MENU_ITEMS_GROUPS.EDITING
  },
  REMOVE: {
    name: 'remove',
    title: 'action.delete',
    icon: 'delete-next',
    group: MENU_ITEMS_GROUPS.REMOVING,
    color: 'var(--grade-low-color-next)'
  }
}

export default defineComponent({
  name: 'MenuItemActions',

  components: { OkrFilterSaverForm, AppDroplist, AppButton, DropdownMenu },
  props: {
    item: {
      type: Object,
      required: true
    },

    appendTo: {
      type: String,
      required: true
    },

    formAppendTo: {
      type: String,
      default: '.app-Aside'
    },

    placement: {
      type: String,
      default: FILTERS,
      validator: v => placementPropValidator(v)
    }
  },

  emits: {
    edit: null
  },

  data() {
    return {
      showForm: false
    }
  },

  computed: {
    ...mapState('system', {
      menuShowed: state => state.appMenu.showed,
      menuPinned: state => state.appMenu.pinned
    }),

    ...mapState('pluginOptions', {
      isPluginAdmin: state => state.isPluginAdmin
    }),

    DROPDOWN_MENU_TYPES: () => DROPDOWN_MENU_TYPES,

    itemMenuActions() {
      return Object.values(MENU_ACTIONS)
    }
  },

  watch: {
    menuPinned(newValue) {
      if (!newValue) {
        this.$refs.dropdown.close()
        this.closeForm()
      }
    },

    menuShowed(newValue) {
      if (!newValue) {
        this.$refs.dropdown.close()
        this.closeForm()
      }
    }
  },

  methods: {
    ...mapActions('dashboard', ['getDashboardList', 'getFavoriteList']),
    closeForm() {
      if (this.showForm) {
        this.showForm = false
      }
    },

    toggleDropdown() {
      this.$refs.dropdown.toggle()
    },

    async onActionClick(action) {
      const menuActionsNames = Object.fromEntries(
        Object.entries(MENU_ACTIONS).map(([key, value]) => {
          return [key, value.name]
        })
      )
      if (action === menuActionsNames.REMOVE) {
        if (this.placement === FILTERS) {
          const api = new OkrFiltersApiHandler()
          try {
            await api.deleteFilterView(this.item.id)
            const routeSavedFilterId = Number(this.$route.query[FILTER_PRESET_KEY])
            if (routeSavedFilterId === this.item.id) {
              deleteQueryParameter(this.$router, this.$route, FILTER_PRESET_KEY)
            }
            showNotify({
              title: this.$t('filters.filter_deleted', { filterName: this.item.name })
            })
            this.$store.dispatch('okrFilters/deleteFilter', this.item.id)
          } catch (error) {
            handleError({ error })
          }
        } else if (this.placement === DASHBOARD) {
          const api = new CustomDashboardApiHandler()
          try {
            const { id } = this.item
            await api.deleteDashboard({ id })
            trackDeleteCustomDashboardEvent({
              id,
              isPluginAdmin: this.isPluginAdmin
            })
            await Promise.all([this.getDashboardList(), this.getFavoriteList()])
            showNotify({
              title: this.$t('dashboard.dashboard_deleted', { dashboardName: this.item.name })
            })
            if (this.$route.params.id?.toString() === this.item.id?.toString()) {
              this.$router.push({ name: ROUTE_NAMES.CUSTOM_DASHBOARD_LIST })
            }
          } catch (error) {
            handleError({ error })
          }
        }
      }
      if (action === menuActionsNames.RENAME) {
        this.showForm = true
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.mia-Trigger {
  transition: none;

  &:not(&-active) {
    &:not(.amni-Item:hover &) {
      &:not(.sflo-Option:hover &) {
        display: none;
      }
    }
  }
}
</style>
