<template>
  <div
    :class="{
      'dth-HeaderCell-sorting': column.sortable
    }"
    class="dth-HeaderCell"
    @click="changeSortBy(column)"
  >
    <span class="oboard-truncated-text dth-HeaderCell_Label">
      <slot>
        {{ column.title }}
      </slot>
    </span>
    <AppIcon
      v-if="sortingColumns.includes(column.key)"
      :class="{
        'dth-HeaderCell_DefaultStateSorting': defaultStateSorting(getSortIconName(column.key))
      }"
      :icon-name="getSortIconName(column.key)"
      height="20"
      width="20"
    />
  </div>
</template>

<script setup>
import { computed } from 'vue'

import {
  ascSortValue,
  avgDecreaseValue,
  avgIncreaseValue,
  descSortValue
} from '@/utils/sort-options'
import { TABLE_COLUMNS_KEYS } from '@/utils/table-columns'

import AppIcon from '@/components/ui/AppIcon/AppIcon'

defineOptions({
  name: 'TableHeaderCellWithSorting'
})

const SORT_TWO_WAYS_NEXT = 'sort-two-ways-next'
const props = defineProps({
  column: {
    type: Object,
    required: true
  },

  sortBy: {
    type: Object,
    default: () => ({})
  },

  additionalStringSortingColumns: {
    type: Array,
    default: () => []
  },

  additionalNumberSortingColumns: {
    type: Array,
    default: () => []
  },

  sortKey: {
    type: String,
    default: 'key'
  },

  sortValue: {
    type: String,
    default: 'value'
  }
})

const changeSortBy = column => {
  if (!column.sortable) {
    return
  }

  if (sortingByNumberColumns.includes(column.key)) {
    sortByAverage(column.key)
  } else if (sortingByNameColumns.includes(column.key)) {
    sortByName(column.key)
  }
}

const emit = defineEmits({
  'update:sortBy': null
})
const updateSortBy = (key, value) =>
  emit('update:sortBy', { [props.sortKey]: key, [props.sortValue]: value })

const getNewSortValue = (currentValue, increaseValue, decreaseValue) => {
  return currentValue === increaseValue ? decreaseValue : increaseValue
}

const sortByAverage = key => {
  const sortBy = getNewSortValue(props.sortBy[props.sortValue], avgIncreaseValue, avgDecreaseValue)
  updateSortBy(key, sortBy)
}

const sortByName = key => {
  const sortBy = getNewSortValue(props.sortBy[props.sortValue], ascSortValue, descSortValue)
  updateSortBy(key, sortBy)
}

const defaultStateSorting = iconName => {
  return iconName === SORT_TWO_WAYS_NEXT
}

const sortingByNameColumns = [
  TABLE_COLUMNS_KEYS.LABEL,
  TABLE_COLUMNS_KEYS.OWNERS,
  ...props.additionalStringSortingColumns
]

const sortingByNumberColumns = [
  TABLE_COLUMNS_KEYS.AVERAGE_TOTAL,
  ...props.additionalNumberSortingColumns
]

const sortingColumns = [...sortingByNameColumns, ...sortingByNumberColumns]

const getSortIconName = key => {
  const iconArrowName = [ascSortValue, avgIncreaseValue].includes(props.sortBy[props.sortValue])
    ? 'arrow-up-next'
    : 'arrow-down-next'

  const isSortingColumnActive = (columns, isActive) =>
    columns.includes(key) && props.sortBy[props.sortKey] === key && isActive

  if (
    isSortingColumnActive(sortingByNameColumns, isNameSortActive.value) ||
    isSortingColumnActive(sortingByNumberColumns, isNumberSortActive.value)
  ) {
    return iconArrowName
  }

  return SORT_TWO_WAYS_NEXT
}

const isNameSortActive = computed(() =>
  [ascSortValue, descSortValue].includes(props.sortBy[props.sortValue])
)

const isNumberSortActive = computed(() =>
  [avgIncreaseValue, avgDecreaseValue].includes(props.sortBy[props.sortValue])
)
</script>

<style lang="scss" scoped>
.dth-HeaderCell {
  display: inline-flex;
  align-items: center;
  cursor: default;
  max-width: 100%;
  &-sorting {
    cursor: pointer;
  }
}
.dth-HeaderCell_DefaultStateSorting {
  color: $grey-2-next;
  flex-shrink: 0;
}
</style>
