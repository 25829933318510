<template>
  <AppRadioGroup
    :model-value="modelValue"
    :name="name"
    :options="options"
    style="--option-padding: 0 8px 0 0"
    type="primary-next"
    @update:model-value="$emit('update:model-value', $event)"
  >
    <template #item-label="{ item }">
      <span class="ss-AutoSwitchOption">
        <AppRadioGroupNextOptionWithIcon :option="item" />
      </span>
    </template>
  </AppRadioGroup>
</template>

<script setup>
import { isBoolean } from 'lodash'

import AppRadioGroup from '@/components/ui/AppRadioGroup/AppRadioGroup'
import AppRadioGroupNextOptionWithIcon from '@/components/ui/AppRadioGroup/AppRadioGroupNextOptionWithIcon'

defineOptions({
  name: 'OkrTableShowKrsSwitch'
})

const options = [
  {
    label: 'filter.show_krs',
    value: true,
    icon: 'watch-line-grey'
  },
  {
    label: 'filter.hide_krs',
    value: false,
    icon: 'not-watch'
  }
]

defineProps({
  modelValue: {
    type: Boolean,
    required: true
  },

  name: {
    type: String,
    required: true
  }
})

defineEmits({
  'update:model-value': value => isBoolean(value)
})
</script>

<style scoped></style>
