<template>
  <div class="ml-Items">
    <button
      v-for="(item, index) in items"
      :key="index"
      :class="{ 'ml-Item-selected': index === selectedIndex }"
      class="ml-Item"
      @click="selectItem(index)"
    >
      <AppAvatar :avatar-url="item.avatarUrl" />
      <span class="ml-Item_Text">{{ item.name }}</span>
    </button>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

import { KEY_CODES } from '@/utils/key-codes'

import AppAvatar from '@/components/ui/AppAvatar/AppAvatar'

export default defineComponent({
  name: 'MentionList',
  components: {
    AppAvatar
  },

  props: {
    items: {
      type: Array,
      required: true
    },

    command: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      selectedIndex: 0
    }
  },

  watch: {
    items: {
      handler() {
        this.selectedIndex = 0
      },

      deep: true
    }
  },

  methods: {
    /** @public */
    onKeyDown({ event }) {
      if (event.keyCode === KEY_CODES.UP_ARROW) {
        this.upHandler()
        return true
      }

      if (event.keyCode === KEY_CODES.DOWN_ARROW) {
        this.downHandler()
        return true
      }

      if (event.keyCode === KEY_CODES.ENTER) {
        this.enterHandler()
        return true
      }

      return false
    },

    upHandler() {
      this.selectedIndex = (this.selectedIndex + this.items.length - 1) % this.items.length
    },

    downHandler() {
      this.selectedIndex = (this.selectedIndex + 1) % this.items.length
    },

    enterHandler() {
      this.selectItem(this.selectedIndex)
    },

    selectItem(index) {
      const item = this.items[index]

      if (item) {
        this.command({ id: item.accountId, label: item.name })
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.ml-Items {
  position: relative;
  overflow: hidden;
  font-size: 0.9rem;
  border-radius: $border-radius-sm;
}

.ml-Item {
  width: 100%;
  text-align: left;
  background: transparent;
  border: none;
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  &_Text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: $system-ui;
  }
  &.ml-Item-selected,
  &:hover {
    background-color: $grey-3-next;
  }
}
</style>
