<template>
  <div :class="{ 'oni-Wrapper-disabled': !userHasUpdateAccess }" class="oni-Wrapper">
    <OkrFormFieldSelectTriggerNext
      :data-auto-testid="`${NUMBER_MONEY_INPUT_TEST_ID}-trigger`"
      :data-testid="`${NUMBER_MONEY_INPUT_TEST_ID}-trigger`"
      :empty-state-label="emptyStateLabel"
      :label="fieldName"
      :selected-options="selectedOptions"
      separated-label
      truncated-label
      @click.stop="activateInput"
    >
      <template #label="{ resolvedLabel }">
        <CustomFieldLabel :field-id="fieldId"> {{ resolvedLabel }} </CustomFieldLabel>
      </template>
    </OkrFormFieldSelectTriggerNext>
    <transition name="scale">
      <!-- do not replace v-show with v-if -->
      <!-- this cause an error when ypu pick another browser tab with active input -->
      <AppInputNumberNext
        v-show="active"
        :key="fieldId"
        ref="inputReference"
        v-model="localValue"
        :data-auto-testid="NUMBER_MONEY_INPUT_TEST_ID"
        :data-testid="NUMBER_MONEY_INPUT_TEST_ID"
        :digit-max-length="NUMBER_FILTER_DEFAULT_DIGIT_MAX_LENGTH"
        :fraction="2"
        :prefix="prefix"
        allow-empty-value
        allow-negative
        class="oni-Wrapper_Input"
        @blur="onBlur"
      />
    </transition>
  </div>
</template>

<script setup>
import { isNull, isUndefined } from 'lodash'
import { computed, nextTick, ref } from 'vue'

import { NUMBER_MONEY_INPUT_TEST_ID } from '@/utils/custom-fields/jest-helpers'
import { useCustomFieldsHelpers } from '@/utils/custom-fields/use-custom-fields'
import { memoizeFormatNumber } from '@/utils/memoizations'
import { NUMBER_FILTER_DEFAULT_DIGIT_MAX_LENGTH } from '@/utils/okr-elements/number-filter'
import { numberOrNullProp } from '@/utils/prop-validators'

import CustomFieldLabel from '@/components/custom-fields/okr-elements-form/CustomFieldLabel'
import AppInputNumberNext from '@/components/ui/AppInputNumberNext/AppInputNumberNext'
import OkrFormFieldSelectTriggerNext from '@/components/ui/AppSelect/TriggerButtons/OkrFormFieldSelectTriggerNext'

defineOptions({
  name: 'OkrElementsFormNumberInput'
})

const props = defineProps({
  userHasUpdateAccess: {
    type: Boolean
  },

  modelValue: {
    required: true,
    validator: v => numberOrNullProp(v)
  },

  prefix: {
    type: String,
    default: ''
  },

  fieldId: {
    type: [String, Number],
    required: true
  }
})

const emit = defineEmits({
  'update:model-value': null,
  blur: null
})

const active = ref(false)

const localValue = computed({
  get: () => {
    return isNull(props.modelValue) ? '' : props.modelValue
  },
  set: value => {
    const resolvedValue = value === '' ? null : value
    emit('update:model-value', resolvedValue)
  }
})

const snapshot = ref(undefined)

const inputReference = ref(null)
const activateInput = () => {
  if (props.userHasUpdateAccess) {
    active.value = true
    snapshot.value = props.modelValue
    nextTick(() => {
      inputReference.value.focus()
    })
  }
}

const selectedOptions = computed(() => {
  return memoizeFormatNumber({
    value: props.modelValue,
    prefix: props.prefix
  })
})

const { fieldName, emptyStateLabel } = useCustomFieldsHelpers({
  fieldId: props.fieldId
})

const onBlur = () => {
  active.value = false
  if (!isUndefined(snapshot.value) && props.modelValue !== snapshot.value) {
    emit('blur')
  }

  nextTick(() => {
    snapshot.value = undefined
  })
}
</script>

<style lang="scss" scoped>
.oni-Wrapper {
  position: relative;

  &-disabled {
    opacity: var(--disabled-opacity, 0.8);
    pointer-events: none;
  }
}
.oni-Wrapper_Input {
  --font-size: $fs-14;
  --font-weight: #{fw('regular')};
  width: 100%;
  position: absolute;
  left: 0;
  top: 2px;
  transition: $transition-fast;

  &:deep(.ain-InputWrapper) {
    width: 100%;
    box-shadow: $common-box-shadow;
  }
}

.scale-enter-from,
.scale-leave-to {
  opacity: 0;
  transform: scale(1.05);
  pointer-events: none;
}
</style>
