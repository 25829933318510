<template>
  <div class="lwl-Loader">
    <div v-for="row in rows" :key="row.id" class="lwl-Loader_Row">
      <div class="lwl-Cell">
        <SkeletonItem :appearance="SQUARE" :size="XS" border-radius="4px" />
      </div>
      <div class="lwl-Cell">
        <SkeletonItem :appearance="SQUARE" :size="SM" border-radius="6px" />
        <SkeletonItem :size="XS" :width="row.name" border-radius="6px" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'

import {
  SKELETON_APPEARANCES,
  SKELETON_SIZES
} from '@/utils/components-configurations/skeleton-item'

const { SM, XS } = SKELETON_SIZES

const { SQUARE } = SKELETON_APPEARANCES

import SkeletonItem from '@/components/ui/SkeletonLoaders/SkeletonItem'

defineOptions({
  name: 'LevelWorkspacesLoader'
})

const props = defineProps({
  levelsCount: {
    type: Number,
    default: 6
  }
})

const DEFAULT_ROWS = [
  {
    id: 0,
    name: '90px'
  },
  {
    id: 1,
    name: '130px'
  },
  {
    id: 2,
    name: '50px'
  },
  {
    id: 3,
    name: '192px'
  },
  {
    id: 4,
    name: '72px'
  },
  {
    id: 5,
    name: '80px'
  }
]

const rows = computed(() => {
  return Array.from(
    { length: props.levelsCount },
    (_, index) => DEFAULT_ROWS[index % DEFAULT_ROWS.length]
  )
})
</script>

<style lang="scss" scoped>
.lwl-Loader_Row {
  height: 45px;
  padding: 0 $page-right-padding 0 $page-left-padding;
  display: flex;
  align-items: center;
}

.lwl-Cell {
  display: flex;
  align-items: center;

  &:first-child {
    width: 32px;
    padding-left: 4px;
  }

  &:nth-child(2) {
    gap: 8px;
    flex: 1 0 auto;
  }
}
</style>
