<template>
  <AppDialog
    :show="show"
    :title="$t('delete_user.count', usersCount)"
    :type="DIALOG_TYPES.DELETE"
    style="--footer-padding-top: 20px"
    @on-confirm="onConfirm"
    @on-close="onClose"
  >
    <template #confirm-btn-text>
      {{ $t('action.revoke_web_access') }}
    </template>
    <i18n-t
      v-if="show"
      class="rwad-DeleteConfirmMessage"
      keypath="confirm.delete_entity"
      scope="global"
      tag="div"
    >
      <template #entity>
        <b>
          {{ $t('workspaces.table.cell.users', usersCount) }}
        </b>
      </template>
    </i18n-t>

    <KeywordInput ref="keywordInputReference" :keyword="REVOKE" />
  </AppDialog>
</template>

<script>
import { defineComponent } from 'vue'

import { DIALOG_TYPES } from '@/utils/components-configurations/app-dialog'
import { REVOKE } from '@/utils/helpers'

import AppDialog from '@/components/AppDialog'
import KeywordInput from '@/components/ui/KeywordInput/KeywordInput'

export default defineComponent({
  name: 'RevokeWebAccessDialog',

  components: {
    KeywordInput,
    AppDialog
  },

  props: {
    show: {
      type: Boolean,
      default: false
    },

    usersCount: {
      type: Number,
      default: 0
    }
  },

  emits: { 'on-confirm': null, 'on-close': null },

  data() {
    return {
      confirmKey: '',
      isConfirmKeyLengthNotValid: false,
      isConfirmKeyWrong: false
    }
  },

  computed: {
    DIALOG_TYPES: () => DIALOG_TYPES,
    REVOKE: () => REVOKE
  },

  methods: {
    clearForm() {
      this.confirmKey = ''
      this.isConfirmKeyLengthNotValid = false
      this.isConfirmKeyWrong = false
    },

    onClose() {
      this.$emit('on-close')
      this.clearForm()
    },

    onConfirm() {
      const isKeywordValid = this.$refs.keywordInputReference.validate()

      if (isKeywordValid) {
        this.$emit('on-confirm')
        this.clearForm()
      }
    }
  }
})
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/modal-danger-text';

.rwad-DeleteConfirmMessage {
  padding-bottom: 24px;
}
</style>
