<template>
  <img
    :alt="$attrs.alt"
    :height="$attrs.height"
    :src="attrs.src"
    :srcset="attrs.srcSet"
    :width="$attrs.width"
    class="ai-Image"
  />
</template>

<script>
// eslint-disable-next-line import/order
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AppImage'
})
</script>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  name: {
    type: String,
    required: true
  },

  format: {
    type: String,
    default: 'png'
  },

  imageDestination: {
    type: String,
    default: 'https://s3-oboard-public-static.s3.eu-central-1.amazonaws.com/img/'
  }
})

const attrs = computed(() => {
  const sizes = [1, 2, 3, 4]

  const srcSetArray = sizes.map(size => {
    const resolvedSize = `@${size}x`
    const name = `${props.name}${resolvedSize}.${props.format}`
    const imageUrl = `${props.imageDestination}${name}`
    return `${imageUrl} ${size}x`
  })

  const [src] = srcSetArray
  const srcSet = srcSetArray.join(', ')

  return {
    srcSet,
    src
  }
})
</script>

<style lang="scss" scoped>
.ai-Image {
  display: block;
  max-width: 100%;
}
</style>
