import { cloneDeep, has, isEmpty, isEqual, isNull } from 'lodash'

import { SEARCH_TYPES } from '@/api/okr-elements'
import { OKR_ELEMENT_ENTITY_KEYS, REQUEST_ENTITY_KEYS } from '@/utils/entity-keys'
import { OBJECTIVE_TYPES } from '@/utils/objective-types'
import {
  currentUserCanUpdateObjective,
  isOkrElementClosed,
  OBJECTIVE_SORT_OPTIONS
} from '@/utils/objectives'
import { EDIT_ELEMENT_QUERY_KEYS } from '@/utils/query-parameters'

export const createPayloadForExpandAllNestedItems = ({
  modelValue = {},
  childrenOrder = [OBJECTIVE_SORT_OPTIONS.ORDER_ASC]
}) => {
  return {
    parentIds: [modelValue.id],
    workspaceId: modelValue.workspaceId,
    intervalIds: [modelValue.intervalId],
    searchType: SEARCH_TYPES.ALIGNMENT,
    order: childrenOrder,
    childOrder: childrenOrder,
    offset: 0,
    limit: 1000,
    expandAll: true,
    maxOpenLevel: 1
  }
}

export const checkIsExpandableNestedItemsExist = items =>
  items.some(item => !isEmpty(item.childElements) || item.childCount > 0)

export const isFormModelsEqual = ({
  localFormModel = {},
  formModel = {},
  skipDescriptionCheck = false
} = {}) => {
  const clonedLocalFormModel = cloneDeep(localFormModel)
  delete clonedLocalFormModel.timePassedPercent
  delete clonedLocalFormModel.predictedGrade
  delete clonedLocalFormModel.lastGradeUpdateDate
  delete clonedLocalFormModel.lastUpdateDate
  delete clonedLocalFormModel.automaticDueDate
  delete clonedLocalFormModel.automaticElementStartDate
  const clonedFormModel = cloneDeep(formModel)
  delete clonedFormModel.timePassedPercent
  delete clonedFormModel.predictedGrade
  delete clonedFormModel.lastGradeUpdateDate
  delete clonedFormModel.lastUpdateDate
  delete clonedFormModel.automaticDueDate
  delete clonedFormModel.automaticElementStartDate

  const brTagRegex = /(<br ?\/?>)+/g

  // replace all <br>, <br/>, <br /> tags with empty string, because our wysiwyg editor parse <br /> tags to <br> tags
  let localDescription = clonedLocalFormModel.description.replace(brTagRegex, '')
  let description = clonedFormModel.description.replace(brTagRegex, '')

  const wrapInPTag = text => {
    const pTagRegex = /^<p>.*<\/p>$/

    if (!pTagRegex.test(text)) {
      return `<p>${text}</p>`
    }

    return text
  }

  // this is necessary because for some reason, we can get data from server without wrapping in <p> tags
  // but our wysiwyg editor always wraps text in <p> tags
  // so with absolutely equal text, we can get negative result
  // so we need to wrap text in <p> tags programmatically
  // to avoid this issue
  localDescription = wrapInPTag(localDescription)
  description = wrapInPTag(description)

  delete clonedLocalFormModel.description
  delete clonedFormModel.description

  const isDescriptionEqual = skipDescriptionCheck || localDescription === description

  return isEqual(clonedLocalFormModel, clonedFormModel) && isDescriptionEqual
}

export const getUpdatedGroupsList = ({
  users = [],
  ownerId = null,
  isGroupManuallyChanged = false,
  selectedGroups = []
}) => {
  const currentOwner = users.find(user => user.accountId === ownerId)
  // update groups only when owner is changed to another except "unassigned" && when groups are not manually changed
  if (ownerId && currentOwner && !isGroupManuallyChanged) {
    // if owner has no groups, then clear groups
    if (isEmpty(currentOwner.groups)) {
      return []
    } else {
      const { defaultGroupId } = currentOwner
      // set default group it they exists
      if (!isNull(defaultGroupId)) {
        return [defaultGroupId]
      }

      return []
    }
  }

  return selectedGroups
}

export const createGetGroupsPayload = ({
  isGroupManuallyChanged = false,
  selectedGroups = [],
  ownerId = null,
  users = [],
  searchString = null,
  workspaceId = null
}) => {
  const { WORKSPACE_IDS, GROUP_IDS } = REQUEST_ENTITY_KEYS

  let groupIds

  if (isGroupManuallyChanged) {
    groupIds = selectedGroups
  } else {
    groupIds = getUpdatedGroupsList({
      users,
      ownerId,
      isGroupManuallyChanged,
      selectedGroups
    })
  }

  return {
    searchString,
    [GROUP_IDS]: groupIds,
    [WORKSPACE_IDS]: [workspaceId]
  }
}

export const createGetUsersPayload = ({
  workspaceId = null,
  searchString = null,
  userData = {},
  isOwnerManuallyChanged = false,
  isEdit = false,
  formModel = {}
}) => {
  const { WORKSPACE_ID, OWNER_ID, STAKEHOLDERS } = OKR_ELEMENT_ENTITY_KEYS
  const { REQUIRED_USERS_ACCOUNT_IDS } = REQUEST_ENTITY_KEYS

  const payload = {
    [WORKSPACE_ID]: workspaceId,
    searchString,
    [REQUIRED_USERS_ACCOUNT_IDS]: [userData.userAccountId]
  }
  if ((isOwnerManuallyChanged || isEdit) && !isNull(formModel[OWNER_ID])) {
    payload[REQUIRED_USERS_ACCOUNT_IDS] = [formModel[OWNER_ID]]
  }
  if (isEdit && !isEmpty(formModel[STAKEHOLDERS]) && isNull(searchString)) {
    // filter to avoid duplicates, because user can be both owner and stakeholder
    payload[REQUIRED_USERS_ACCOUNT_IDS].push(
      ...formModel[STAKEHOLDERS].filter(u => u !== formModel[OWNER_ID])
    )
  }

  return payload
}

export const getEditValueFromQuery = ({ query = {} } = {}) => {
  const { EDIT_KR, EDIT_OBJECTIVE, EDIT_LINK_JIRA_ISSUE } = EDIT_ELEMENT_QUERY_KEYS

  const editObjectiveInQuery = has(query, EDIT_OBJECTIVE)
  const editKRInQuery = has(query, EDIT_KR)
  const editJiraIssueInQuery = has(query, EDIT_LINK_JIRA_ISSUE)

  const onlyEditObjective = editObjectiveInQuery && !editJiraIssueInQuery
  const onlyEditKR = editKRInQuery && !editJiraIssueInQuery
  const editObjectiveAndJiraIssue = editObjectiveInQuery && editJiraIssueInQuery
  const editKRAndJiraIssue = editKRInQuery && editJiraIssueInQuery
  const onlyEditJiraIssue = editJiraIssueInQuery && !editObjectiveInQuery && !editKRInQuery

  const objectivePayload = {
    typeId: OBJECTIVE_TYPES.PERSONAL,
    id: Number(query[EDIT_OBJECTIVE])
  }

  const krPayload = {
    typeId: OBJECTIVE_TYPES.KR,
    id: Number(query[EDIT_KR])
  }

  const jiraIssuePayload = {
    typeId: OBJECTIVE_TYPES.TASK,
    id: Number(query[EDIT_LINK_JIRA_ISSUE])
  }

  const [editValue] = [
    onlyEditObjective && objectivePayload,
    onlyEditKR && krPayload,
    editObjectiveAndJiraIssue && {
      ...objectivePayload,
      children: {
        ...jiraIssuePayload
      }
    },
    editKRAndJiraIssue && {
      ...krPayload,
      children: {
        ...jiraIssuePayload
      }
    },
    onlyEditJiraIssue && jiraIssuePayload
  ].filter(Boolean)

  return editValue
}

export const getLockMessagesStates = ({
  isEdit = false,
  modelValue = {},
  isClosedParent = false,
  contribute = false
} = {}) => {
  const editable = isEdit && currentUserCanUpdateObjective(modelValue)

  const isShowNoPermissionsMessage = isEdit && !editable
  const isShowClosedElementMessage = !isShowNoPermissionsMessage && isOkrElementClosed(modelValue)
  const isShowClosedParentMessage =
    !isShowNoPermissionsMessage &&
    !isShowClosedElementMessage &&
    isClosedParent &&
    contribute &&
    isEdit

  return {
    isShowNoPermissionsMessage,
    isShowClosedElementMessage,
    isShowClosedParentMessage
  }
}
