<template>
  <div class="vp-VisualPresentation">
    <div class="vp-VisualPresentation_Head">
      <div class="vp-Field" style="--r: 2px" />
      <div class="vp-Head_Id">
        <div class="vp-Field" style="--w: 8px; --r: 2px" />
        <div class="vp-Field" style="--w: 30px; --r: 2px" />
      </div>
    </div>
    <div class="vp-VisualPresentation_Body">
      <div class="vp-Body_Content">
        <div class="vp-Field" style="--h: 16px" />
        <div class="vp-Field" style="--w: 96px; --r: 2px" />
        <div class="vp-Field" style="--h: 39px; --mt: 4px" />
        <div class="vp-Field vp-Field-transparent" style="--h: 16px" />

        <SlickList
          :distance="10"
          :dragged-settling-duration="1"
          :list="contentFields"
          :lock-offset="['0%', '0%']"
          :transition-duration="200"
          class="vp-ContentCustomFields"
          helper-class="vp-Field-custom-grabbing"
          lock-axis="y"
          lock-to-container-edges
          @sort-end="onSortEnd({ ...$event })"
        >
          <SlickItem
            v-for="(item, index) in contentFields"
            :key="item.id"
            :index="index"
            class="vp-Field vp-Field-transparent vp-Field-custom"
            style="--h: 20px"
            @click="onItemClick(item)"
          >
            <AppIcon
              :icon-name="`cf-${getCustomFieldOptions(item.typeId).icon}`"
              height="12"
              width="12"
            />
          </SlickItem>
        </SlickList>

        <div class="vp-Body_Comment">
          <div class="vp-Field" style="--h: 16px; --w: 16px; --r: 50%" />
          <div class="vp-Field vp-Field-transparent" style="--h: 16px" />
        </div>
      </div>
      <div class="vp-Body_Sidebar">
        <div class="vp-GradeAndStatus">
          <div class="vp-Field" style="--h: 16px; --w: 24px" />
          <div class="vp-Field" style="--h: 2px" />
          <div class="vp-Field" style="--w: 24px; --r: 2px" />
        </div>
        <div class="vp-Field" style="--h: 39px; --mt: 2px" />
        <div class="vp-Field" style="--h: 80px" />

        <SlickList
          :distance="10"
          :dragged-settling-duration="1"
          :list="sidebarFields"
          :lock-offset="['0%', '0%']"
          :transition-duration="200"
          class="vp-SidebarCustomFields"
          helper-class="vp-Field-custom-grabbing"
          lock-axis="y"
          lock-to-container-edges
          @sort-end="onSortEnd({ ...$event, placement: CUSTOM_FIELDS_PLACEMENTS.SIDEBAR })"
        >
          <SlickItem
            v-for="(item, index) in sidebarFields"
            :key="item.id"
            :index="index"
            class="vp-Field vp-Field-transparent vp-Field-custom"
            style="--h: 20px"
            @click="onItemClick(item)"
          >
            <AppIcon
              :icon-name="`cf-${getCustomFieldOptions(item.typeId).icon}`"
              height="12"
              width="12"
            />
          </SlickItem>
        </SlickList>
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line import/order
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'VisualPresentation'
})
</script>

<script setup>
// eslint-disable-next-line import/order
import { cloneDeep, isNull } from 'lodash'

import { SlickList, SlickItem } from 'vue-slicksort'

import { ALL_CUSTOM_FIELDS, CUSTOM_FIELDS_PLACEMENTS } from '@/utils/custom-fields/factory'
import { handleOrder } from '@/utils/drag-n-drop'

import AppIcon from '@/components/ui/AppIcon/AppIcon'

const props = defineProps({
  contentFields: {
    type: Array,
    required: true
  },

  sidebarFields: {
    type: Array,
    required: true
  }
})

const emit = defineEmits({
  drop: null,
  'update:content-fields': null,
  'update:sidebar-fields': null,
  'edit-custom-field': null
})

const onItemClick = item => {
  emit('edit-custom-field', item)
}

const getCustomFieldOptions = typeId => {
  return ALL_CUSTOM_FIELDS.getFieldOptionsByTypeId(typeId)
}
const onSortEnd = async ({ oldIndex, newIndex, placement = CUSTOM_FIELDS_PLACEMENTS.CONTENT }) => {
  if (isNull(newIndex) || oldIndex === newIndex) {
    return
  }

  const data =
    placement === CUSTOM_FIELDS_PLACEMENTS.CONTENT
      ? cloneDeep(props.contentFields)
      : cloneDeep(props.sidebarFields)

  data.splice(newIndex, 0, data.splice(oldIndex, 1)[0])

  const item = data[newIndex]

  const { orderValue, newRealIndex } = handleOrder({
    oldIndex,
    newIndex,
    data
  })

  emit('drop', { orderValue, item, newIndex, oldIndex, newRealIndex, placement })
}
</script>

<style lang="scss" scoped>
.vp-VisualPresentation {
  width: 500px;
  padding: 30px;
  border-radius: $border-radius-lg-next;
  border: 2px solid $grey-3-next;
}

.vp-Field {
  border-radius: var(--r, #{$border-radius-sm});
  width: var(--w, 100%);
  height: var(--h, 8px);
  margin-top: var(--mt, 0);
  font-size: 10px;

  &:not(&-transparent) {
    background: $grey-3-next;
  }

  &-transparent {
    border: 2px solid $grey-3-next;
  }

  // eslint-disable-next-line vue-scoped-css/no-unused-selector
  &-custom#{&}-transparent {
    border-color: $primary-color-next;
    display: flex;
    align-items: center;
    padding-left: 2px;
  }
}

.vp-Field-custom {
  color: $primary-color-next;
  cursor: grab;
  // eslint-disable-next-line vue-scoped-css/no-unused-selector
  &-grabbing {
    cursor: grabbing;
  }

  @media (any-hover: hover) {
    &:not(&-grabbing):hover {
      background-color: rgba($primary-color-next, 0.5);
    }
  }
}

.vp-VisualPresentation_Head {
  display: grid;
  gap: 12px;
}

.vp-Head_Id,
.vp-Body_Comment {
  display: flex;
  gap: 4px;
}

.vp-VisualPresentation_Body {
  display: grid;
  margin-top: 12px;
  gap: 32px;
  grid-template-columns: 13fr 7fr;
  align-items: flex-start;
}

.vp-Body_Content,
.vp-Body_Sidebar {
  display: grid;
  gap: 4px;
}

.vp-GradeAndStatus {
  display: grid;
  gap: 2px;
}

.vp-ContentCustomFields,
.vp-SidebarCustomFields {
  display: grid;
  gap: 4px;
}

.vp-ContentCustomFields {
  margin: 4px 0 12px;
}
</style>

<style lang="scss">
.vp-Field-custom-grabbing {
  z-index: 10000;
  background-color: rgba($primary-color-next, 0.8);

  pointer-events: all !important;
  box-shadow: 0 4px 16px rgba($dark-1, 0.2), 0 1px 2px rgba($dark-1, 0.31);

  &.vp-Field-custom {
    color: $white;
  }
}
</style>
