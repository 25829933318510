import { uniq } from 'lodash'

import { uid } from '@/utils/uid'

export const convertExtendedOptionsListToSimple = ({ options }) => {
  return uniq(
    options.map(option => {
      return option.name.trim()
    })
  )
}
export const convertSimpleOptionsListToExtended = ({ options }) => {
  return options.map((option, index) => {
    const uniqId = uid()
    return {
      id: index + 1,
      uniqId,
      name: option
    }
  })
}

export const normalizeCurrency = ({ value = '' } = {}) => {
  return String(value).trim().slice(0, 3).toUpperCase()
}
