export const FILTER_PRESET_KEY = 'filterPreset'

export const CREATE_GROUP_KEY = 'createGroup'

export const EDIT_GROUP_KEY = 'editGroup'

export const WORKSPACE_ID_KEY = 'workspaceId'

export const INTERVAL_ID_KEY = 'intervalId'
export const LEVEL_ID = 'levelId'
export const REFERER = 'referer'
export const EDIT_ELEMENT_QUERY_KEYS = {
  EDIT_KR: 'editKR',
  EDIT_LINK_JIRA_ISSUE: 'editLinkJiraIssue',
  EDIT_OBJECTIVE: 'editObjective'
}

export const SHOW_COMMENT_KEY = 'showComment'

export const OPENED_NOTIFICATIONS_KEY = 'openedNotifications'

export const EDIT_WORKSPACE_QUERY_KEY = 'editWorkspace'
export const EDIT_WORKSPACE_ACTIVE_TAB_QUERY_KEY = 'activeTab'

export const GLOBAL_GROUPS_EXPANDED_ITEMS = 'expandedGroups'

export const PAYMENT_STATUS = 'payment_status' // it's send from our backend
