<template>
  <DashboardBoxesLoader />
</template>

<script>
import { defineComponent } from 'vue'

import DashboardBoxesLoader from '@/components/ui/SkeletonLoaders/DashboardBoxesLoader'

export default defineComponent({
  name: 'DashboardGeneralReportLoader',
  components: { DashboardBoxesLoader }
})
</script>
