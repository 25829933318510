import { OUTDATED_ROUTE_PATHS, ROUTE_NAMES, ROUTE_PATHS } from '@/routes/route-helpers'

import ElementsAuditLog from '@/components/audit-log/elements-audit-log'
import GroupsAuditLogPage from '@/components/audit-log/groups-audit-log'
import GlobalGroupsPage from '@/components/global-groups/global-groups'
import AuditLogsLayout from '@/views/workspaces/settings/audit-logs'
import CheckInsLayout from '@/views/workspaces/settings/check-ins'
import CompanySettings from '@/views/workspaces/settings/company'
import CustomFieldsLayout from '@/views/workspaces/settings/custom-fields'
import IntegrationsLayout from '@/views/workspaces/settings/integrations'
import { ALLOWED_MESSENGERS_NAMES } from '@/views/workspaces/settings/integrations/integration-utils'
import ApiTokens from '@/views/workspaces/settings/integrations/pages/ApiTokens'
import ConnectionsList from '@/views/workspaces/settings/integrations/pages/ConnectionsList'
import JiraConnectionSettings from '@/views/workspaces/settings/integrations/pages/JiraConnectionSettings'
import WebhooksConnectionSettings from '@/views/workspaces/settings/integrations/pages/WebhooksConnectionSettings'
import Labels from '@/views/workspaces/settings/labels'
import WorkspaceLevels from '@/views/workspaces/settings/levels'
import OrganizationLayout from '@/views/workspaces/settings/organization'
import AdministratorsPage from '@/views/workspaces/settings/plugin-users/administrators'
import UserDetailsPage from '@/views/workspaces/settings/plugin-users/user-details'
import UsersPage from '@/views/workspaces/settings/plugin-users/users'
import StatusSettingsLayout from '@/views/workspaces/settings/status-settings'
import UserNotificationsSettings from '@/views/workspaces/settings/user-notifications-settings'
import UserPreferences from '@/views/workspaces/settings/user-preferences'
import SettingsWorkspaces from '@/views/workspaces/settings/workspaces'

export const CORE_SETTINGS_ROUTES = [
  {
    path: ROUTE_PATHS.SETTINGS_WORKSPACES,
    name: ROUTE_NAMES.SETTINGS_WORKSPACES,
    component: SettingsWorkspaces
  },

  {
    path: ROUTE_PATHS.SETTINGS_LEVELS,
    name: ROUTE_NAMES.SETTINGS_LEVELS,
    component: WorkspaceLevels
  },
  {
    path: OUTDATED_ROUTE_PATHS.ACCESS_MANAGEMENT,
    redirect: { name: ROUTE_NAMES.ORGANIZATION_ADMINISTRATORS }
  },
  {
    path: OUTDATED_ROUTE_PATHS.PLUGIN_USERS,
    redirect: { name: ROUTE_NAMES.ORGANIZATION },
    children: [
      {
        path: ROUTE_PATHS.ORGANIZATION_USERS,
        redirect: { name: ROUTE_NAMES.ORGANIZATION_USERS }
      },
      {
        path: ROUTE_PATHS.ORGANIZATION_ADMINISTRATORS,
        redirect: { name: ROUTE_NAMES.ORGANIZATION_ADMINISTRATORS }
      },
      {
        path: ROUTE_PATHS.ORGANIZATION_USER_DETAILS,
        redirect: { name: ROUTE_NAMES.ORGANIZATION_USER_DETAILS }
      }
    ]
  },
  {
    path: ROUTE_PATHS.SETTINGS_STATUSES,
    name: ROUTE_NAMES.SETTINGS_STATUSES,
    component: StatusSettingsLayout
  },
  {
    path: ROUTE_PATHS.SETTINGS_CUSTOM_FIELDS,
    name: ROUTE_NAMES.SETTINGS_CUSTOM_FIELDS,
    component: CustomFieldsLayout
  },
  {
    path: ROUTE_PATHS.SETTINGS_CHECK_INS,
    name: ROUTE_NAMES.SETTINGS_CHECK_INS,
    component: CheckInsLayout
  },
  {
    path: ROUTE_PATHS.ORGANIZATION,
    name: ROUTE_NAMES.ORGANIZATION,
    component: OrganizationLayout,
    redirect: { name: ROUTE_NAMES.ORGANIZATION_GLOBAL_GROUPS },
    children: [
      {
        path: ROUTE_PATHS.ORGANIZATION_USERS,
        name: ROUTE_NAMES.ORGANIZATION_USERS,
        component: UsersPage
      },
      {
        path: ROUTE_PATHS.ORGANIZATION_ADMINISTRATORS,
        name: ROUTE_NAMES.ORGANIZATION_ADMINISTRATORS,
        component: AdministratorsPage
      },
      {
        path: ROUTE_PATHS.ORGANIZATION_USER_DETAILS,
        name: ROUTE_NAMES.ORGANIZATION_USER_DETAILS,
        component: UserDetailsPage,
        meta: {
          hideNav: true
        }
      },
      {
        path: ROUTE_PATHS.ORGANIZATION_GLOBAL_GROUPS,
        name: ROUTE_NAMES.ORGANIZATION_GLOBAL_GROUPS,
        component: GlobalGroupsPage
      },
      {
        path: ROUTE_PATHS.ORGANIZATION_AUDIT_LOG,
        name: ROUTE_NAMES.ORGANIZATION_AUDIT_LOG,
        redirect: { name: ROUTE_NAMES.AUDIT_LOGS_GROUPS }
      }
    ]
  },
  {
    path: ROUTE_PATHS.SETTINGS_INTEGRATIONS,
    name: ROUTE_NAMES.SETTINGS_INTEGRATIONS,
    component: IntegrationsLayout,
    redirect: { name: ROUTE_NAMES.SETTINGS_INTEGRATIONS_CONNECTIONS_LIST },
    children: [
      {
        path: '',
        name: ROUTE_NAMES.SETTINGS_INTEGRATIONS_CONNECTIONS_LIST,
        component: ConnectionsList
      },
      {
        path: ROUTE_PATHS.SETTINGS_INTEGRATIONS_API_TOKENS,
        name: ROUTE_NAMES.SETTINGS_INTEGRATIONS_API_TOKENS,
        component: ApiTokens
      },
      {
        path: ROUTE_PATHS.SETTINGS_INTEGRATIONS_OBOARD_CONNECTION,
        name: ROUTE_NAMES.SETTINGS_INTEGRATIONS_OBOARD_CONNECTION,
        component: JiraConnectionSettings
      },
      {
        path: `${ROUTE_PATHS.SETTINGS_INTEGRATIONS_MESSENGER_CONNECTION}/:name`,
        name: ROUTE_NAMES.SETTINGS_INTEGRATIONS_MESSENGER_CONNECTION,
        component: WebhooksConnectionSettings,
        beforeEnter: (to, from, next) => {
          const { name } = to.params
          if (!name || !ALLOWED_MESSENGERS_NAMES.includes(name)) {
            next({ name: ROUTE_NAMES.SETTINGS_INTEGRATIONS })
          } else {
            next()
          }
        }
      }
    ]
  },
  {
    path: ROUTE_PATHS.AUDIT_LOGS,
    name: ROUTE_NAMES.AUDIT_LOGS,
    component: AuditLogsLayout,
    redirect: { name: ROUTE_NAMES.AUDIT_LOGS_GROUPS },
    children: [
      {
        path: ROUTE_PATHS.AUDIT_LOGS_GROUPS,
        name: ROUTE_NAMES.AUDIT_LOGS_GROUPS,
        component: GroupsAuditLogPage
      },
      {
        path: ROUTE_PATHS.AUDIT_LOGS_ELEMENTS,
        name: ROUTE_NAMES.AUDIT_LOGS_ELEMENTS,
        component: ElementsAuditLog
      }
    ]
  },
  {
    path: ROUTE_PATHS.SETTINGS_LABELS,
    name: ROUTE_NAMES.SETTINGS_LABELS,
    component: Labels
  },
  {
    path: ROUTE_PATHS.SETTINGS_COMPANY,
    name: ROUTE_NAMES.SETTINGS_COMPANY,
    component: CompanySettings
  },
  {
    path: ROUTE_PATHS.SETTINGS_USER_PREFERENCES,
    name: ROUTE_NAMES.SETTINGS_USER_PREFERENCES,
    component: UserPreferences
  },
  {
    path: ROUTE_PATHS.SETTINGS_NOTIFICATIONS,
    name: ROUTE_NAMES.SETTINGS_NOTIFICATIONS,
    component: UserNotificationsSettings
  }
]
