<template>
  <template v-if="!item.isNestedTask">
    <CellSelectItem
      :id="`in${uid}`"
      :active="isOpened"
      :is-empty="!item.intervalName"
      :user-has-read-access="readable && !isJiraIssue"
      :user-has-update-access="updatable && !isJiraIssue"
      is-transparent
      truncated
      @click="onClick"
    >
      {{ item.intervalName }}
    </CellSelectItem>
    <template v-if="updatable">
      <AppSelect
        v-if="editInitialised && !isJiraIssue"
        :inline-loader="false"
        :loading="areIntervalsLoading"
        :model-value="item.intervalId"
        :offset="[0, 0]"
        :options="intervals"
        :to-selector="`#in${uid}`"
        append-to=".o-objective-table"
        boundary="scrollParent"
        dropdown-width="200px"
        item-label="name"
        item-value="id"
        show-on-init
        @hide="hideSelect"
        @opened="getIntervals"
        @update:model-value="onIntervalUpdate"
      />
      <portal v-if="editInitialised" to="modal-windows">
        <AppDialog
          :data-auto-testid="CONFIRM_INTERVAL_CHANGE_MODAL_TEST_ID"
          :data-testid="CONFIRM_INTERVAL_CHANGE_MODAL_TEST_ID"
          :show="isConfirmIntervalChangeShow"
          :title="$t('confirm_interval_change.title')"
          :type="DIALOG_TYPES.WARNING"
          @on-close="hideConfirmIntervalChange"
          @on-confirm="onConfirmIntervalChange"
        >
          {{ confirmDescription }}
        </AppDialog>
      </portal>
    </template>
    <template v-else>
      <AppTippy :content="item.intervalName" :to="`#in${uid}`" />
    </template>
  </template>
</template>

<script>
import { defineComponent } from 'vue'

import IntervalsInfoApiHandler from '@/api/intervals-info'
import ObjectivesInfoApiHandler from '@/api/okr-elements'
import { DIALOG_TYPES } from '@/utils/components-configurations/app-dialog'
import { handleError } from '@/utils/error-handling'
import { getActiveIntervals } from '@/utils/interval'
import {
  getDescriptionForIntervalChangeConfirmation,
  objectiveIsJiraTask
} from '@/utils/objectives'
import { uid } from '@/utils/uid'

import AppDialog from '@/components/AppDialog'
import { CONFIRM_INTERVAL_CHANGE_MODAL_TEST_ID } from '@/components/objectives/forms/jest-helpers'
import CellSelectItem from '@/components/objectives/table/cells/CellSelectItem'
import AppSelect from '@/components/ui/AppSelect/AppSelect'

export default defineComponent({
  name: 'IntervalCell',

  components: {
    AppDialog,
    CellSelectItem,
    AppSelect
  },

  inheritAttrs: false,

  props: {
    item: {
      type: Object,
      required: true
    },

    readable: {
      type: Boolean
    },

    updatable: {
      type: Boolean
    }
  },

  emits: { 'update-interval': null },

  data() {
    return {
      uid: uid(),
      intervals: [],
      areIntervalsLoading: true,
      editInitialised: false,
      isConfirmIntervalChangeShow: false,
      temporaryIntervalId: null,
      isOpened: false
    }
  },

  computed: {
    DIALOG_TYPES: () => DIALOG_TYPES,
    CONFIRM_INTERVAL_CHANGE_MODAL_TEST_ID: () => CONFIRM_INTERVAL_CHANGE_MODAL_TEST_ID,

    isJiraIssue() {
      return objectiveIsJiraTask(this.item)
    },

    confirmDescription() {
      return getDescriptionForIntervalChangeConfirmation({
        formModel: this.item,
        i18nInstance: this.$t
      })
    }
  },

  methods: {
    onClick() {
      if (!this.isJiraIssue) {
        this.editInitialised = true
      }
    },

    async getIntervals() {
      this.isOpened = true
      this.intervals = await this.getIntervalsInfo()
    },

    async getIntervalsInfo() {
      let result = []
      const api = new IntervalsInfoApiHandler()

      const parameters = {
        workspaceId: this.item.workspaceId
      }

      try {
        const { intervals } = await api.getIntervalsInfo(parameters)

        result = getActiveIntervals(intervals)
      } catch (error) {
        handleError({ error })
      } finally {
        this.areIntervalsLoading = false
      }
      return result
    },

    async onIntervalUpdate(value, additionalParams = {}) {
      const api = new ObjectivesInfoApiHandler()
      if (
        (this.item.elementStartDate || this.item.dueDate) &&
        !Object.keys(additionalParams).length
      ) {
        this.temporaryIntervalId = value
        this.isConfirmIntervalChangeShow = true
      } else {
        try {
          const result = await api.updateOkrElement({
            ...this.item,
            elementId: this.item.id,
            intervalId: value
          })
          this.$emit('update-interval', { ...this.item, ...result, ...additionalParams })
        } catch (error) {
          handleError({ error })
        }
      }
    },

    hideSelect() {
      this.isOpened = false
      this.intervals = []
      this.areIntervalsLoading = true
    },

    hideConfirmIntervalChange() {
      this.isConfirmIntervalChangeShow = false
      this.temporaryIntervalId = null
    },

    onConfirmIntervalChange() {
      this.onIntervalUpdate(this.temporaryIntervalId, { elementStartDate: null, dueDate: null })
      this.hideConfirmIntervalChange()
    }
  }
})
</script>
