export const BULK_EDIT_MODAL_TEST_IDS = {
  CONFIRM_BUTTON: 'bulk-edit-modal-confirm-button',
  CANCEL_BUTTON: 'bulk-edit-modal-cancel-button',
  DISABLED_FIELDS_LIST: 'bulk-edit-modal-disabled-fields-list',
  DISABLED_FIELDS_LIST_ITEM: 'bulk-edit-modal-disabled-fields-list-item',
  CONFIRM_CLOSE_MODAL: 'bulk-edit-modal-confirm-close-modal'
}

export const TEST_CUSTOM_FIELDS_IDS = {
  SINGLE_SELECT: 'jest single select',
  MULTI_SELECT: 'jest multi select',
  DATE: 'jest date',
  NUMBER: 'jest number',
  MONEY: 'jest money',
  ASSIGNEE: 'jest assignee',
  ANOTHER_MONEY: 'jest another money'
}

export const normalizeCustomFieldId = fieldId => `${fieldId.replace(/ /g, '_')}_CF`

export const TEST_CUSTOM_FIELDS = Object.values(TEST_CUSTOM_FIELDS_IDS).map(normalizeCustomFieldId)

export const BULK_ACTIONS_OPERATION_VALUE_CONTROL_TEST_IDS = {
  OPERATION_SELECT: 'bulk-actions-operation-select',
  VALUE_SELECT: 'bulk-actions-value-select'
}
