<template>
  <AppSelect
    ref="filterSelectRef"
    :append-to="appendTo"
    :bottom-fixed-items="bottomFixedItems"
    :class="{ 'ofs-Select': true, 'ofs-Select-all-selected': selectAllIsSelected(modelValue) }"
    :dropdown-min-width="220"
    :dropdown-search="search"
    :group-by="groupBy"
    :has-only-this-button="hasOnlyThisButton"
    :hidden-items="resolvedHiddenItems"
    :hide-selected-items-in-dropdown="hideSelectedItemsInDropdown"
    :highlight-option="highlightOption"
    :icon="prependIcon"
    :is-highlighted="isHighlighted"
    :item-label="itemLabel"
    :item-value="itemValue"
    :loading="loading"
    :max-height="maxHeight"
    :model-value="modelValue"
    :n-selected-label="nSelectedLabel"
    :offset="[0, 0]"
    :options="options"
    :search-function="searchFunction"
    :search-max-length="50"
    :show-all-selected="additional"
    :show-selected-count-circle="!additional"
    :show-selected-options-inside="showSelectedOptionsInside"
    :simplified-selected="additional"
    :skeleton-loader-color="isHighlighted ? '#42526E' : '#f4f5f7'"
    :split-first-option="splitFirstOption"
    :type="type"
    append-icon-height="20"
    append-icon-width="20"
    boundary="scrollParent"
    multi
    show-group-divider
    skeleton-loader
    skeleton-loader-height="100%"
    skeleton-loader-width="100%"
    @hide="$emit('hide', $event)"
    @open="$emit('open', $event)"
    @update:options="$emit('update:options', $event)"
    @update:model-value="$emit('update:model-value', $event)"
  >
    <template #option="props">
      <slot name="option" v-bind="props" />
    </template>
    <template #bottom-fixed-items="{ bottomFixedItems: fixedItems }">
      <slot :bottom-fixed-items="fixedItems" name="bottom-fixed-items" />
    </template>
    <template #option-label="{ option }">
      <slot :option="option" name="option-label" />
    </template>
    <template #button-content="{ options: buttonContentOptions }">
      <AppSelectItem
        v-tippy="{
          ...TOOLTIP_CONFIGURATION,
          content: getButtonContentWithoutNSelectedLabel(buttonContentOptions)
        }"
        :show-delete-button="false"
        :show-selected-count-circle="false"
        :type="SELECT_TYPES.SECONDARY_NEXT"
        @delete="clearField"
      >
        <div class="ofs-SelectedItemWrapper">
          <span class="ofs-SelectedItemLabel">
            <slot name="button-content">
              {{ getButtonContent(buttonContentOptions) }}
            </slot>
          </span>
          <div v-if="buttonContentOptions.length > 1" class="ofs-SelectedItemCounter">
            {{ buttonContentOptions.length }}
          </div>
        </div>
      </AppSelectItem>
    </template>
    <template #button="{ option, active, fullDataOption }">
      <slot :active="active" :full-data-option="fullDataOption" :option="option" name="button" />
    </template>
  </AppSelect>
</template>

<script>
import { defineComponent } from 'vue'

import { SELECT_TYPES } from '@/utils/components-configurations/app-select'
import { DEFAULT_VALUE_FOR_FILTER, SELECT_ALL_VALUE } from '@/utils/okr-elements/filters'
import { selectAllIsSelected } from '@/utils/select'
import { TOOLTIP_CONFIGURATION } from '@/utils/tippy-config'

import AppSelect from '@/components/ui/AppSelect/AppSelect'
import AppSelectItem from '@/components/ui/AppSelect/AppSelectItem'

export default defineComponent({
  name: 'OkrFilterSelect',

  components: {
    AppSelect,
    AppSelectItem
  },

  props: {
    modelValue: {
      type: [Array, Number, String],
      required: true
    },

    loading: {
      type: Boolean
    },

    options: {
      type: Array,
      required: true
    },

    searchFunction: {
      type: Function,
      default: () => []
    },

    // compared to AppSelect, it supports only string
    itemValue: {
      type: String,
      default: 'id'
    },

    // compared to AppSelect, it supports only string
    itemLabel: {
      type: String,
      default: 'name'
    },

    search: {
      type: Boolean,
      default: true
    },

    nSelectedLabel: {
      type: String,
      required: true
    },

    bottomFixedItems: {
      type: Array,
      default: () => []
    },

    appendTo: {
      type: String,
      default: ''
    },

    additional: {
      type: Boolean
    },

    highlightOption: {
      type: Boolean
    },

    showSelectedOptionsInside: {
      type: Boolean
    },

    hideSelectedItemsInDropdown: {
      type: Boolean
    },

    prependIcon: {
      type: String,
      default: ''
    },

    type: {
      type: String,
      default: SELECT_TYPES.SECONDARY_NEXT,
      validator: v => Object.values(SELECT_TYPES).includes(v)
    },

    hiddenItems: {
      type: Array,
      default: () => []
    },

    groupBy: {
      type: Function,
      default: null
    },

    disableSelectHighlighting: {
      type: Boolean
    },

    hasOnlyThisButton: {
      type: Boolean
    },

    maxHeight: {
      type: Number,
      default: 220
    },

    splitFirstOption: {
      type: Boolean
    }
  },

  emits: { 'update:model-value': null, 'update:options': null, open: null, hide: null },

  computed: {
    SELECT_TYPES: () => SELECT_TYPES,

    TOOLTIP_CONFIGURATION: () => TOOLTIP_CONFIGURATION,

    isHighlighted() {
      return this.disableSelectHighlighting ? false : this.notEmpty
    },

    notEmpty() {
      return !this.modelValue.includes(SELECT_ALL_VALUE)
    },

    resolvedHiddenItems() {
      return [...DEFAULT_VALUE_FOR_FILTER, ...this.hiddenItems]
    }
  },

  methods: {
    selectAllIsSelected,
    getButtonContent(buttonContentOptions) {
      return buttonContentOptions.length === 1
        ? this.getSelectedOptionLabel(buttonContentOptions)
        : this.$t(this.nSelectedLabel)
    },

    getButtonContentWithoutNSelectedLabel(buttonContentOptions) {
      return buttonContentOptions.length === 1
        ? this.getSelectedOptionLabel(buttonContentOptions)
        : null
    },

    clearField() {
      this.$refs.filterSelectRef.deleteItemValue()
    },

    getSelectedOptionLabel(options) {
      return options[0].label
    }
  }
})
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/selected-items-counter';
.ofs-Select {
  color: $primary-color;

  &-all-selected {
    color: $dark-1;
  }

  &:deep(.si-SelectedItem) {
    span {
      line-height: 20px;
    }
  }
  &:deep(.ad-Loader) {
    right: 5px;
  }
}
.ofs-SelectedItemWrapper {
  display: flex;
  align-items: center;
}
.ofs-SelectedItemCounter {
  @extend %selected-items-counter;
  background: $dark-3;
  font-weight: fw('bold');
  font-size: $fs-12;
  line-height: 16px;
  color: $white;
}
</style>
