<template>
  <div v-show="!areOwnersLoading" class="dpp-DashboardPeoplePage">
    <AppTable
      v-if="filteredOwners.length"
      :columns="getTableColumns"
      :data="filteredOwners"
      :loading="areOwnersLoading"
      border-on-last-row
      class="dpp-AppTable"
      no-border
      offset-left="var(--page-left-padding)"
      offset-right="var(--page-right-padding)"
      type="primary-next"
    >
      <template #header-cell="{ column }">
        <TableHeaderCellWithSorting v-model:sort-by="sortBy" :column="column" />
      </template>

      <template #cell="{ columnKey, item }">
        <template v-if="columnKey === TABLE_COLUMNS_KEYS.LABEL">
          <DashboardCellAssigneedOwner :clickable="isLinkOnJiraProfileClickable" :item="item" />
        </template>

        <template v-else-if="columnKey === TABLE_COLUMNS_KEYS.OKR_COUNT">
          <DashboardCellOkrCount :item="item" :selected-table-view="selectedTableView" />
        </template>

        <template v-else-if="getTableCell[columnKey]">
          <div class="dpp-OkrCounts">
            <DashboardCount
              v-for="type in columnKeys"
              :key="type + columnKey"
              :clickable="STATUS_TABLE_VIEW === selectedTableView"
              :route-to="
                getRouteTo({
                  item,
                  gradeType: getTableCell[columnKey].gradeType,
                  OKRTypeIds: memoizeOkrTypeIds({ objectiveLevels, type }),
                  workspaceId,
                  intervalId
                })
              "
              :style="getTableCell[columnKey].style"
              :value="getTableCellValue({ item, type, valueKey: getTableCell[columnKey].valueKey })"
            />
          </div>
        </template>

        <template v-else-if="columnKey === TABLE_COLUMNS_KEYS.AVERAGE_TOTAL">
          <div class="dpp-OkrCounts">
            <template v-for="type in columnKeys" :key="type + columnKey">
              <DashboardCellAvearageTotal :item="item" :type="type" />
            </template>
          </div>
        </template>
      </template>
    </AppTable>
    <AppPagination
      v-if="filteredOwners.length"
      :current-page="currentPage"
      :items-count="usersTotal"
      :items-on-page="itemsOnPage"
      :items-shown="filteredOwners.length"
      :total-page="totalPage"
      items-name="pagination.owners"
      @update:items-on-page="itemsOnPage = $event"
      @update:current-page="currentPage = $event"
    />

    <template v-if="isEmptyState">
      <OkrElementsEmpty v-if="isFiltersUsed" :list-type="null">
        {{ emptyStateTitle }}
      </OkrElementsEmpty>

      <EmptyStateDashboard v-else>
        {{ $t('dashboard.no_owners') }}
      </EmptyStateDashboard>
    </template>
  </div>

  <DashboardTableLoader v-if="areOwnersLoading" is-for-people />
</template>

<script setup>
import { computed, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

import DashboardApiHandler from '@/api/dashboard'
import LevelApiHandler from '@/api/level'
import { useGetFilteredData } from '@/composables/fetchingData'
import { useGetPaginationData } from '@/composables/pagination'
import {
  columnKeys,
  getRouteTo,
  getTableCellValue,
  STATUS_TABLE_VIEW,
  useGetPerformanceDashboardColumns,
  useLinkOnProfileAvailability
} from '@/utils/dashboard'
import { handleError } from '@/utils/error-handling'
import { memoizeOkrTypeIds } from '@/utils/objectives'
import { DEFAULT_VALUE_FOR_FILTER } from '@/utils/okr-elements/filters'
import { PERFORMANCE_DASHBOARD_CELLS } from '@/utils/performance-dashboard'
import { getSelectWithSelectAllApiParameter, selectAllIsSelected } from '@/utils/select'
import { ascSortValue } from '@/utils/sort-options'
import { TABLE_COLUMNS_KEYS } from '@/utils/table-columns'

import DashboardCellAssigneedOwner from '@/components/dashboard/DashboardCellAssigneedOwner'
import DashboardCellAvearageTotal from '@/components/dashboard/DashboardCellAvearageTotal'
import DashboardCellOkrCount from '@/components/dashboard/DashboardCellOkrCount'
import DashboardCount from '@/components/dashboard/DashboardCount'
import EmptyStateDashboard from '@/components/dashboard/EmptyStateDashboard'
import TableHeaderCellWithSorting from '@/components/dashboard/TableHeaderCellWithSorting'
import OkrElementsEmpty from '@/components/home-page/OkrElementsEmpty'
import AppPagination from '@/components/ui/AppPagination/AppPagination'
import DashboardTableLoader from '@/components/ui/SkeletonLoaders/DashboardTableLoader'
import AppTable from '@/components/ui/Table/AppTable'

const props = defineProps({
  intervalId: {
    type: Number,
    required: true
  },
  selectedAssigneeIds: {
    type: Array,
    default: () => DEFAULT_VALUE_FOR_FILTER
  },
  selectedGroupIds: {
    type: Array,
    default: () => DEFAULT_VALUE_FOR_FILTER
  },
  searchString: {
    type: String,
    default: ''
  },
  selectedTableView: {
    type: String,
    default: STATUS_TABLE_VIEW
  }
})

defineOptions({
  name: 'DashboardPeople'
})

const store = useStore()
const systemSettings = computed(() => store.state.system.settings)

const getTableColumns = computed(() => {
  const { data } = useGetPerformanceDashboardColumns({
    selectedTableView: props.selectedTableView,
    systemSettings: systemSettings.value
  })
  return data.value
})

const getTableCell = PERFORMANCE_DASHBOARD_CELLS

const sortBy = ref(ascSortValue)
const owners = ref([])
const areOwnersLoading = ref(false)

const {
  START_PAGE,
  currentPage,
  itemsOnPage,
  total: usersTotal,
  totalPage
} = useGetPaginationData()
watch(
  () => itemsOnPage.value,
  () => {
    currentPage.value = START_PAGE
  }
)

const workspaceId = computed(() => store.state.workspaces.workspaceId)

const isFiltersUsed = computed(() => {
  return !allOwnersSelected.value || !allGroupsSelected.value || !searchIsEmpty.value
})

const { t } = useI18n()
const emptyStateTitle = computed(() => {
  return isFiltersUsed.value ? t('dashboard.no_owners_match') : t('dashboard.no_owners')
})

const allGroupsSelected = computed(() => {
  return selectAllIsSelected(props.selectedGroupIds)
})

const allOwnersSelected = computed(() => {
  return selectAllIsSelected(props.selectedAssigneeIds)
})

const searchIsEmpty = computed(() => {
  return !props.searchString
})

const isEmptyState = computed(() => {
  return !filteredOwners.value.length && !areOwnersLoading.value
})

const filteredOwners = computed(() => {
  return useGetFilteredData({
    data: owners.value,
    options: owners.value,
    sortBy: sortBy.value,
    searchString: props.searchString,
    currentPage: currentPage.value,
    itemsOnPage: itemsOnPage.value,
    key: 'ownerName'
  })
})

const objectiveLevels = computed(() => store.state.objectives.levels)

const getReportByUser = async () => {
  try {
    const dashboardApi = new DashboardApiHandler()
    areOwnersLoading.value = true
    const { users } = await dashboardApi.getReportByUser({
      intervalId: props.intervalId,
      workspaceId: workspaceId.value,
      userIds: getSelectWithSelectAllApiParameter(props.selectedAssigneeIds),
      groupIds: getSelectWithSelectAllApiParameter(props.selectedGroupIds)
    })
    owners.value = users
    usersTotal.value = users.length
  } catch (error) {
    handleError({ error })
  } finally {
    areOwnersLoading.value = false
  }
}

const setLevels = async levels => {
  await store.dispatch('objectives/setLevels', {
    levels
  })
}
const getLevelsForFilter = async workspaceId => {
  const levelApi = new LevelApiHandler()
  try {
    const levels = await levelApi.getLevelsForFilter({
      workspaceId
    })
    await setLevels(levels)
  } catch (error) {
    handleError({ error })
  }
}

const { isLinkOnJiraProfileClickable } = useLinkOnProfileAvailability()

watch(
  () => [props.selectedAssigneeIds, props.selectedGroupIds, props.intervalId],
  () => {
    getReportByUser()
  },
  {
    deep: true
  }
)

onMounted(() => {
  getLevelsForFilter(workspaceId.value)
  getReportByUser()
})
</script>

<style lang="scss" scoped>
.dpp-OkrCounts {
  display: flex;
  flex-direction: column;
  gap: 14px;
  text-align: right;
  font-weight: fw('semi-bold');
  font-size: $fs-16;
  font-style: normal;
  line-height: 20px;

  &:deep(.dc-DashboardCount) {
    text-align: right;
  }
}

.dpp-DashboardPeoplePage {
  &:deep(.tb-Row) {
    padding: 10px var(--offset-right, 0px) 10px calc(var(--offset, 0px) + var(--offset-left, 0px));
  }
}
</style>

<style lang="scss">
.dpp-AppTable .tb-RowWrapper {
  &:hover {
    background-color: inherit;
  }
}

.dpp-AppTable {
  .tb-Cell-averageTotal {
    padding-right: 20px;
  }
  .te-Cell-averageTotal {
    padding-right: 20px;
  }
}
.dpp-GroupName_defaultSorting {
  color: $grey-2-next;
}
.dpp-HeaderCell {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
</style>

<style lang="scss">
.dpp-AppTable .tb-RowWrapper {
  &:hover {
    background-color: inherit;
  }
}

.dpp-AppTable {
  .tb-HeaderCell-owners.tb-HeaderCell {
    padding-left: 0;
    padding-right: 24px;
    .dpp-HeaderCell {
      justify-content: flex-start;
    }
  }
  .tb-HeaderCell-averageTotal {
    .dpp-HeaderCell {
      justify-content: flex-end;
    }
  }
}
</style>
