import ApiHandler from '@/api/base'

class DashboardApiHandler extends ApiHandler {
  async getGeneralReport({ intervalId = null, workspaceId = null } = {}) {
    const { data } = await this.http.post('/getGeneralReport', {
      intervalId,
      workspaceId
    })
    return data
  }

  async getReportByGroup({
    groupIds = null,
    intervalId = null,
    searchString = null,
    userIds = null,
    workspaceId = null
  } = {}) {
    const { data } = await this.http.post('/getReportByGroup', {
      groupIds,
      intervalId,
      searchString,
      userIds,
      workspaceId
    })
    return data
  }

  async getReportByUser({
    groupIds = null,
    intervalId = null,
    searchString = null,
    userIds = null,
    workspaceId = null
  } = {}) {
    const { data } = await this.http.post('/getReportByUser', {
      groupIds,
      intervalId,
      searchString,
      userIds,
      workspaceId
    })
    return data
  }
}

export default DashboardApiHandler
