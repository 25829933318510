import dayjs from 'dayjs'
import { cloneDeep, isEmpty, isNumber, merge } from 'lodash'

import { dateFormat } from '@/utils/date'
import { memoizeGetCssVariableValue } from '@/utils/memoizations'
import { getSimplifiedNumber, PLURALIZATION_KEYS } from '@/utils/pluralization'
import { THRESHOLDS_DEFAULT_VALUES } from '@/utils/thresholds'
import { OKR_ELEMENT_KEYS } from '@/utils/view-row-actions'

const systemUiFontFamily =
  "'-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'sans-serif'"
export const CHART_TYPES = {
  simple: 'simple',
  simpleNext: 'simple-next',
  detailed: 'detailed',
  detailedNext: 'detailed-next',
  dashboardPreview: 'dashboardPreview',
  homepagePreview: 'homepagePreview',
  predictedScorePreview: 'predictedScorePreview'
}

export const TIME_INTERVALS_COUNT = 12
export const AGGREGATED_INTERVALS_COUNT = 12

export const TRANSPARENT_COLOR = 'transparent'

export const LINEAR_LABEL = 'Linear'

export const THRESHOLD_LABEL = 'Threshold'

const DEFAULT_GRID_COLOR = memoizeGetCssVariableValue('--grey-2-next', '#DFE1E6')

const DEFAULT_TOOLTIP_OPTIONS = {
  mode: 'nearest',
  intersect: false,
  callbacks: {
    label: ({ dataset, formattedValue }) => {
      const label = dataset.label
      return `${
        label.length > 30 ? label.substring(0, 30).concat('...') : label
      }: ${formattedValue}%`
    }
  },
  filter: ({ dataset }) => {
    return dataset.label !== LINEAR_LABEL
  }
}

const roundToBigger = (num = 0) => {
  if (num <= 0) {
    return 0
  }
  const digitCapacity = Number(`1e${Math.trunc(num).toString().length - 1}`)
  return Math.ceil(num / digitCapacity) * digitCapacity
}

const getChartLinesDataPoints = chartLines => {
  return {
    yPoints: chartLines.flatMap(cl => Object.values(cl.data)),
    xPoints: chartLines.flatMap(cl => Object.keys(cl.data))
  }
}

const getMinMax = (chartLines, fullSizeChart) => {
  const { yPoints, xPoints } = getChartLinesDataPoints(chartLines)
  if (fullSizeChart) {
    return {
      xMin: Math.min(...xPoints),
      xMax: Math.max(...xPoints),
      yMin: Math.min(...yPoints),
      yMax: Math.max(...yPoints)
    }
  } else {
    const isSomeYEqual100 = yPoints.some(cl => cl === 100)
    return chartLines.reduce(
      (acc, cl) => {
        return {
          xMin: Math.min(acc.xMin, cl.xmin),
          xMax: Math.max(acc.xMax, cl.xmax),
          yMin: Math.min(acc.yMin, cl.ymin),
          yMax: isSomeYEqual100 ? 150 : roundToBigger(Math.max(acc.yMax, cl.ymax))
        }
      },
      {
        xMin: Infinity,
        xMax: -Infinity,
        yMin: Infinity,
        yMax: -Infinity
      }
    )
  }
}

const convertDatasetData = (item, tableData = {}) => {
  return Object.entries(item.data).map(([x, y]) => ({
    x: Number(x),
    y,
    ...tableData
  }))
}

const prepareDatasets = (
  chartLines,
  splitEnabled,
  pointBackgroundColor,
  pointHoverBackgroundColor,
  tableData
) => {
  return chartLines
    .map(item => {
      return {
        label: `${item.name}`,
        id: item.id,
        borderColor: item.color,
        data: convertDatasetData(item, tableData),
        tension: 0.4,
        pointBackgroundColor: pointBackgroundColor || item.color,
        pointHoverBackgroundColor: pointHoverBackgroundColor || item.color,
        borderCapStyle: 'round'
      }
    })
    .filter(item => {
      return !isEmpty(item.data)
    })
}

const prepareLinear = chartLines => {
  const { xMin, xMax, yMin } = getMinMax(chartLines)
  return {
    borderColor: memoizeGetCssVariableValue('--grey-1-next', '#B5BBC5'),
    borderWidth: 0.3,
    pointRadius: 0,
    borderDash: [10, 3],
    label: LINEAR_LABEL,
    data: [
      { x: xMin, y: yMin },
      { x: xMax, y: 100 }
    ]
  }
}

const prepareThresholds = ({
  thresholdBehind = THRESHOLDS_DEFAULT_VALUES.BEHIND,
  thresholdOnTrack = THRESHOLDS_DEFAULT_VALUES.ON_TRACK,
  chartLines,
  fillThresholdLines = false
}) => {
  const { xMax, xMin } = getMinMax(chartLines, true)

  const fill = fillThresholdLines

  const THRESHOLD_LINE_DEFAULT_OPTIONS = {
    borderWidth: 1,
    pointRadius: 0,
    borderDash: fill ? undefined : [4, 4],
    fill,
    label: THRESHOLD_LABEL
  }

  const THRESHOLD_LINES = [
    {
      backgroundColor: '#FDDBE0',
      borderColor: memoizeGetCssVariableValue('--grade-low-color-rgb-next', '246, 73, 99'),
      y2: thresholdBehind
    },
    {
      backgroundColor: '#FFE7D9',
      borderColor: memoizeGetCssVariableValue('--grade-medium-color-rgb-next', '255, 133, 65'),

      y2: thresholdOnTrack
    },
    {
      backgroundColor: '#D7F0E5',
      borderColor: memoizeGetCssVariableValue('--grade-high-color-rgb-next', '54, 178, 126'),

      y2: 100
    }
  ]

  return THRESHOLD_LINES.map(({ borderColor, backgroundColor, y2 }) => {
    return {
      ...THRESHOLD_LINE_DEFAULT_OPTIONS,
      borderColor: fill ? backgroundColor : `rgb(${borderColor}, 0.5)`,
      backgroundColor: backgroundColor,
      data: [
        { x: xMin, y: 0 },
        { x: xMax, y: y2 }
      ]
    }
  })
}

const getTitle = ({ startDate, endDate, dateFormat, isSingleDateFormat = true }) => {
  return isSingleDateFormat
    ? `${dayjs(startDate).utc().format(dateFormat)}`
    : `${dayjs(startDate).utc().format(dateFormat)} - ${dayjs(endDate).utc().format(dateFormat)}`
}

export const CHECKER_COLUMN_WIDTH = 24
export const MARKER_COLUMN_WIDTH = 24

const prepareColumns = (columns, granulation) => {
  const defaultColumns = [
    {
      key: 'marker',
      width: MARKER_COLUMN_WIDTH
    },
    {
      key: 'checker',
      width: CHECKER_COLUMN_WIDTH
    },
    {
      title: 'Name',
      key: 'name',
      width: 276
    }
  ]

  const restColumns = columns.map(column => {
    const { startDate, endDate } = column
    const isSingleDateFormat =
      granulation === PERIODS.DAILY.value || granulation === PERIODS.MONTHLY.value
    const { format } = Object.values(PERIODS).find(({ value }) => value === granulation)
    return {
      title: getTitle({ startDate, endDate, dateFormat: format, isSingleDateFormat }),
      key: endDate,
      width: 110,
      align: 'left'
    }
  })

  return [...defaultColumns, ...restColumns]
}

const prepareRows = source => {
  return source.chartLines.map((chartLine, index) => {
    const entries = source.tableData.columns.map((column, columnIndex) => {
      const value = source.tableData.data[index][columnIndex]
      const newValue = isNumber(value) ? value : '—'
      return [column.endDate, newValue]
    })
    const { name, color, id, displayId, levelColor, levelPrefix, issueIcon, typeId, permissions } =
      chartLine
    return {
      permissions,
      name,
      color,
      id,
      displayId,
      levelColor,
      levelPrefix,
      issueIcon,
      typeId,
      ...Object.fromEntries(entries)
    }
  })
}

const prepareLabels = ({ chartLines, fullSizeChart, timeIntervalsCount, granulation }) => {
  if (chartLines.length === 0) {
    return []
  }
  let interval = timeIntervalsCount
  const { xMin: min, xMax: max } = getMinMax(chartLines, fullSizeChart)
  const step = (max - min) / (interval - 1)

  if (granulation) {
    const period = Object.values(PERIODS).find(({ value }) => value === granulation)

    const dateMax = dayjs(max)
    const dateMin = dayjs(min)
    interval = dateMax.diff(dateMin, period.unit)
    return Array.from({ length: interval + 2 }, (value, index) => {
      const date = dayjs(min).add(index, period.unit)

      if (period.value === PERIODS.QUARTERLY.value) {
        return `${dayjs(date).startOf('quarters')}`
      }

      return `${date}`
    })
  }
  return Array.from({ length: interval }, (value, index) => {
    if (index === interval) {
      return max
    }
    const val = min + index * step
    return val >= max ? max : val
  })
}

const getDefaultOptions = (chartLines, fullSizeChart, aspectRatio, setYFrom0To100 = false) => {
  const checkBeginAtZeroNeed = () => {
    const { yPoints } = getChartLinesDataPoints(chartLines)
    return setYFrom0To100 || yPoints.filter(point => point !== null).every(point => point === 0)
  }
  const beginAtZero = fullSizeChart ? checkBeginAtZeroNeed() : true
  return {
    elements: {
      line: {
        borderWidth: 2
      }
    },
    aspectRatio,
    pointRadius: 3,
    scales: {
      x: {
        type: 'time',
        ticks: {
          source: 'labels'
        },
        grid: {
          display: true
        }
      },
      y: {
        beginAtZero,
        grid: {
          display: true
        }
      }
    }
  }
}

const getMinMaxOptions = (chartLines, fullSizeChart) => {
  const options = {
    scales: {
      y: {
        max: 0
      }
    }
  }
  if (chartLines.length) {
    const { yMax } = getMinMax(chartLines, fullSizeChart)
    options.scales.y.max = yMax
  }
  return options
}

const getMinMaxOptionsForFullSizeChart = (
  chartLines,
  setYFrom0To100,
  roundYMaxToBigger = false
) => {
  const options = {
    scales: {
      y: {
        max: 100
      }
    }
  }

  if (chartLines.length) {
    const { yMax } = getMinMax(chartLines, true)
    if (yMax > 100) {
      options.scales.y.max = roundYMaxToBigger ? roundToBigger(yMax) : yMax
    }
  }
  return setYFrom0To100 ? options : {}
}

const getSimpleChartOptions = ({
  chartLines,
  fullSizeChart,
  splitEnabled,
  aspectRatio,
  setYFrom0To100
}) => {
  const defaultOptions = getDefaultOptions(chartLines, fullSizeChart, aspectRatio, setYFrom0To100)
  const minMaxOptions = fullSizeChart
    ? getMinMaxOptionsForFullSizeChart(chartLines, setYFrom0To100, true)
    : getMinMaxOptions(chartLines, fullSizeChart)

  const getPointRadius = () => {
    if (splitEnabled) {
      return 2
    } else {
      return Object.keys(chartLines[0].data).length === 1 ? 2 : 0
    }
  }

  const options = {
    elements: {
      line: {}
    },
    pointRadius: getPointRadius(),

    scales: {
      x: {
        display: false
      },
      y: {
        display: fullSizeChart
      }
    },
    plugins: {
      tooltip: {
        enabled: false
      }
    }
  }

  return merge(defaultOptions, minMaxOptions, options)
}

const getSimpleNextChartOptions = ({
  chartLines,
  fullSizeChart,
  splitEnabled,
  aspectRatio,
  setYFrom0To100
}) => {
  const defaultOptions = getDefaultOptions(chartLines, fullSizeChart, aspectRatio, setYFrom0To100)
  const minMaxOptions = fullSizeChart
    ? getMinMaxOptionsForFullSizeChart(chartLines, setYFrom0To100, true)
    : getMinMaxOptions(chartLines, fullSizeChart)

  const DOT_RADIUS = 4.5 // dot is 6 px diameter so radius is 3 px, border width is 3px so radius of border is 1.5 px
  const DOT_DIAMETER = DOT_RADIUS * 2
  const chartLinesCount = Object.keys(chartLines[0].data).length

  const getPointRadius = () => {
    if (splitEnabled) {
      return 2
    } else {
      return [...Array(chartLinesCount).keys()].map(i => {
        return i + 1 === chartLinesCount ? DOT_RADIUS : 0
      })
    }
  }

  const options = {
    elements: {
      line: {
        borderWidth: 6
      }
    },
    pointRadius: getPointRadius(),
    pointHoverRadius: getPointRadius(),
    pointBorderWidth: 3,

    layout: {
      autoPadding: chartLinesCount <= 1,
      // necessary because `chart.js` adds a padding to center the chart.
      // but if we hide all dots except the last one, line chart keep being centered and start
      // not from canvas border. so we remove all paddings except left and add rest padding
      // equals to point diameter for prevent point cropping
      padding:
        chartLinesCount === 1
          ? DOT_DIAMETER
          : { top: DOT_DIAMETER, right: DOT_DIAMETER, bottom: DOT_DIAMETER }
    },

    scales: {
      x: {
        display: false
      },
      y: {
        display: false //fullSizeChart
      }
    },
    plugins: {
      tooltip: {
        enabled: false
      }
    }
  }

  return merge(defaultOptions, minMaxOptions, options)
}

const getDetailedChartOptions = ({ chartLines, fullSizeChart, aspectRatio }) => {
  const defaultOptions = getDefaultOptions(chartLines, fullSizeChart, aspectRatio)
  const minMaxOptions = fullSizeChart ? {} : getMinMaxOptions(chartLines, fullSizeChart)
  const { yMax } = getMinMax(chartLines, fullSizeChart)

  const options = {
    plugins: {
      tooltip: {
        ...DEFAULT_TOOLTIP_OPTIONS,
        callbacks: {
          ...DEFAULT_TOOLTIP_OPTIONS.callbacks,
          title: data => {
            if (data?.length) {
              const [{ raw }] = data
              const { format } = Object.values(PERIODS).find(
                ({ value }) => value === raw.granulation
              )
              const granulation = raw.granulation
              const isSingleDateFormat =
                granulation === PERIODS.DAILY.value || granulation === PERIODS.MONTHLY.value
              const tableColumn = raw.columns.find(
                ({ startDate, endDate }) => startDate <= raw.x && endDate >= raw.x
              )

              return getTitle({
                startDate: tableColumn?.startDate || raw.x,
                endDate: tableColumn?.endDate,
                dateFormat: format,
                isSingleDateFormat
              })
            } else {
              return null
            }
          }
        }
      }
    },
    scales: {
      y: {
        ticks: {
          stepSize: calculateYAxesStepSize(yMax),
          font: {
            family: systemUiFontFamily
          }
        }
      }
    }
  }

  return merge(defaultOptions, minMaxOptions, options)
}

const getDetailedNextChartOptions = ({ chartLines, fullSizeChart, aspectRatio, splitEnabled }) => {
  const clone = cloneDeep(
    getDetailedChartOptions({
      chartLines,
      fullSizeChart,
      aspectRatio
    })
  )

  clone.elements.line.borderWidth = splitEnabled ? 2 : 6
  clone.pointRadius = splitEnabled ? 3 : 4.5
  clone.pointBorderWidth = splitEnabled ? 0 : 3
  clone.scales.y.grid = {
    ...clone.scales.y.grid,
    borderDash: [4, 2],
    tickColor: TRANSPARENT_COLOR,
    tickLength: 20,
    color: DEFAULT_GRID_COLOR,
    borderColor: DEFAULT_GRID_COLOR
  }

  clone.scales.x.grid = {
    ...clone.scales.x.grid,
    tickColor: TRANSPARENT_COLOR,
    color: DEFAULT_GRID_COLOR,
    borderColor: DEFAULT_GRID_COLOR
  }
  clone.scales.y.ticks = {
    ...clone.scales.y.ticks,
    font: {
      family: systemUiFontFamily
    }
  }
  clone.scales.x.ticks = {
    ...clone.scales.x.ticks,
    font: {
      family: systemUiFontFamily
    }
  }

  return clone
}

const getDashboardPreviewChartOptions = ({
  chartLines,
  fullSizeChart,
  aspectRatio,
  splitEnabled
}) => {
  const clone = cloneDeep(
    getDetailedNextChartOptions({
      chartLines,
      fullSizeChart,
      aspectRatio
    })
  )
  const minMaxOptions = fullSizeChart
    ? getMinMaxOptionsForFullSizeChart(chartLines, true, true)
    : getMinMaxOptions(chartLines, fullSizeChart)

  clone.plugins.tooltip = null
  clone.elements.line.borderWidth = splitEnabled ? 2 : 6
  clone.pointRadius = splitEnabled ? 3 : 4.5
  clone.pointBorderWidth = splitEnabled ? 0 : 3
  clone.scales.y.grid = {
    ...clone.scales.y.grid,
    borderDash: [4, 2],
    tickLength: 0,
    color: DEFAULT_GRID_COLOR,
    drawBorder: false
  }
  clone.scales.y = {
    ...clone.scales.y,
    position: 'right',
    offset: true
  }
  clone.scales.x = {
    ...clone.scales.x,
    offset: true
    // time: {
    //   parser: value => {
    //     return dayjs(value).format('YYYY')
    //   }
    // },
    // adapters: {
    //   date: dayjs
    // }
  }

  clone.scales.x.border = {
    display: false
  }

  clone.scales.y.ticks = {
    ...clone.scales.y.ticks,
    padding: 8,
    paddingRight: 0,
    font: {
      size: 10,
      family: systemUiFontFamily
    },
    callback: value => {
      return `${getSimplifiedNumber(value, true)[PLURALIZATION_KEYS.SIMPLIFIED]}%`
    }
  }
  clone.scales.x.ticks = {
    ...clone.scales.x.ticks,
    autoSkip: true,
    format: 'dates',
    maxRotation: 0,
    font: {
      size: 12,
      family: systemUiFontFamily
    },
    callback: (value, index, items) => {
      // show first, median and last label
      const medianIndex = Math.floor(items.length / 2)
      // return index === 0 || index === items.length - 1 ? value : null
      return index === 0 || index === medianIndex || index === items.length - 1 ? value : null
    }
  }

  clone.scales.x.grid = {
    ...clone.scales.x.grid,
    color: DEFAULT_GRID_COLOR,
    tickLength: 0,
    drawTicks: true,
    drawBorder: false
  }

  return merge(clone, minMaxOptions)
}
const getHomepagePreviewChartOptions = ({ chartLines, fullSizeChart, aspectRatio }) => {
  const clone = cloneDeep(
    getDetailedNextChartOptions({
      chartLines,
      fullSizeChart,
      aspectRatio
    })
  )
  const minMaxOptions = fullSizeChart
    ? getMinMaxOptionsForFullSizeChart(chartLines, true, true)
    : getMinMaxOptions(chartLines, fullSizeChart)

  const TICK_COLOR = memoizeGetCssVariableValue('--grey-1-next', '#B5BBC5')

  clone.elements.line.borderWidth = 4
  clone.pointRadius = 6
  clone.pointHoverRadius = 6
  clone.pointBorderWidth = 2
  clone.pointHoverBorderWidth = 2
  clone.pointHitRadius = 6
  clone.pointBorderColor = TRANSPARENT_COLOR
  clone.pointHoverBorderColor = memoizeGetCssVariableValue('--white-color', '#fff')

  clone.interaction = {
    intersect: false,
    mode: 'index'
  }

  const getGridLineWidth = ctx => {
    // calculation using indexes is not suitable for last grid line
    // because grid lines can be hidden by ticks
    // and indexes can be even or odd or skipped
    // so we probably need to use ticks values
    // and check for max and min values
    const { index, tick, chart } = ctx
    if (!chart) {
      return 1
    }

    const { value } = tick

    const allGridLinesValues = chart.scales.y.ticks.map(t => t.value)

    const maxGridLineValue = Math.max(...allGridLinesValues)

    return !index || value >= maxGridLineValue ? 2 : 1 // first and last grid lines are bold
  }

  const { y } = clone.scales

  const font = {
    size: 10,
    family: systemUiFontFamily
  }

  clone.scales.y = {
    ...y,
    position: 'left',
    // offset: true
    grid: {
      ...y.grid,
      borderDash: null,
      tickLength: 11, // space between tick and grid line
      color: memoizeGetCssVariableValue('--grey-3-next', '#F5F6F7'),
      lineWidth: ctx => getGridLineWidth(ctx),
      drawBorder: false
    },
    ticks: {
      padding: 0,
      color: TICK_COLOR,
      align: 'inner',
      // ↑ ALIGN TICK TO GRID LINE
      // EXAMPLES

      // INNER
      // 100% ————————————
      //
      // 50% ————————————
      //
      // 0% ————————————

      // START
      //     _______________
      // 100%
      //
      //     _______________
      // 50%
      //
      //     _______________
      // 0%

      // END
      // 100% _______________
      //
      // 50% _______________
      //
      // 0% _______________
      // https://www.chartjs.org/docs/latest/api/interfaces/TickOptions.html

      crossAlign: 'left', // text align for ticks
      font,
      callback: value => {
        return value % 20 === 0
          ? `${getSimplifiedNumber(value, true)[PLURALIZATION_KEYS.SIMPLIFIED]}%`
          : null
      }
    }
  }

  clone.scales.x.grid = {
    ...clone.scales.x.grid,
    display: false,
    tickLength: 6, // space between tick and grid line
    drawBorder: false
  }

  clone.scales.x.ticks = {
    ...clone.scales.x.ticks,
    autoSkip: true,
    format: 'dates',
    color: TICK_COLOR,
    maxRotation: 0,
    align: 'inner', // works with reverse logic as y.ticks (text align for ticks)
    // crossAlign: 'center', // text align for ticks (vertical align)
    // https://www.chartjs.org/docs/latest/api/interfaces/TickOptions.html
    font
  }

  clone.plugins.tooltip = {
    caretPadding: 10,
    caretSize: 4,
    backgroundColor: '#0052CC',
    cornerRadius: 12,
    displayColors: false,
    bodyAlign: 'center',
    titleAlign: 'center',
    yAlign: ({ tooltip }) => {
      const chartMaxY = minMaxOptions.scales.y.max
      const currentPointY = tooltip.dataPoints[0].parsed.y

      if (currentPointY > chartMaxY / 2) {
        return 'top'
      }

      return 'bottom'
    },
    titleFont: {
      size: 12,
      weight: '400',
      family: systemUiFontFamily
    },
    bodyFont: {
      size: 20,
      weight: '600',
      family: systemUiFontFamily
    },
    padding: {
      x: 16,
      y: 10
    },
    callbacks: {
      title: function (elements) {
        return dayjs(elements[0].parsed.x).format(dateFormat)
      },
      label: function (elements) {
        return `${getSimplifiedNumber(elements.parsed.y, true)[PLURALIZATION_KEYS.SIMPLIFIED]}%`
      }
    }
  }

  return merge(clone, minMaxOptions)
}

const getPredictedScoreChartOptions = ({
  chartLines,
  fullSizeChart,
  aspectRatio,
  setYFrom0To100,
  currentGrade = 0,
  predictedScore = 0
}) => {
  const defaultOptions = getDefaultOptions(chartLines, fullSizeChart, aspectRatio, setYFrom0To100)

  const clone = cloneDeep(
    getHomepagePreviewChartOptions({
      chartLines,
      fullSizeChart,
      aspectRatio
    })
  )

  const options = {
    pointRadius: 5,
    pointHoverRadius: 6,
    pointBorderWidth: 3,
    pointHoverBorderWidth: 3,
    scales: {
      x: {
        grid: {
          ...clone.scales.x.grid,
          drawBorder: true
        },
        ticks: {
          ...clone.scales.x.ticks,
          callback: (value, index, items) => {
            // show first and last label
            return index === 0 || index === items.length - 1 ? value : null
          }
        }
      },
      y: {
        ...clone.scales.y,
        max: 110,
        grid: {
          ...clone.scales.y.grid,
          display: false
        },
        ticks: {
          ...clone.scales.y.ticks,
          stepSize: 1,

          callback: value => {
            return value === currentGrade || value === predictedScore
              ? `${getSimplifiedNumber(value, true)[PLURALIZATION_KEYS.SIMPLIFIED]}%`
              : null
          },
          display: false
        }
      }
    },
    plugins: {
      tooltip: {
        enabled: false
      }
    }
  }

  return merge(defaultOptions, options)
}

const calculateYAxesStepSize = yMax => {
  const [stepSize] = [yMax <= 1e2 && 10, yMax <= 1e3 && 50, yMax <= 1e4 && 100, 1000].filter(
    Boolean
  )
  return stepSize
}

export const prepareChart = ({
  chartLines,
  chartType = CHART_TYPES.simple,
  splitEnabled = false,
  fullSizeChart = false,
  aspectRatio = 2.4,
  setYFrom0To100 = false,
  pointBackgroundColor = null,
  pointHoverBackgroundColor = null,
  timeIntervalsCount = TIME_INTERVALS_COUNT,
  isLinear = false,
  dateFormat = 'DD/MMM',
  tableData = [],
  granulation,
  gradeValues = {
    thresholdBehind: THRESHOLDS_DEFAULT_VALUES.BEHIND,
    thresholdOnTrack: THRESHOLDS_DEFAULT_VALUES.ON_TRACK,
    currentGrade: 0,
    predictedScore: 0,
    fillThresholdLines: false
  }
}) => {
  const labels = prepareLabels({ chartLines, fullSizeChart, timeIntervalsCount, granulation })
  const linear = prepareLinear(chartLines)

  const { thresholdBehind, thresholdOnTrack, currentGrade, predictedScore, fillThresholdLines } =
    gradeValues

  let thresholdLines = []

  if (chartType === CHART_TYPES.predictedScorePreview) {
    thresholdLines = prepareThresholds({
      thresholdBehind,
      thresholdOnTrack,
      chartLines,
      fillThresholdLines
    })
  }

  const datasets = prepareDatasets(
    chartLines,
    splitEnabled,
    pointBackgroundColor,
    pointHoverBackgroundColor,
    tableData
  )

  if (isLinear && !splitEnabled) {
    datasets.push(linear)
  }

  if (!isEmpty(thresholdLines)) {
    datasets.push(...thresholdLines)
  }

  const {
    detailed,
    simple,
    simpleNext,
    detailedNext,
    dashboardPreview,
    homepagePreview,
    predictedScorePreview
  } = CHART_TYPES
  const optionsByChartType = {
    [detailed]: getDetailedChartOptions({
      chartLines,
      fullSizeChart,
      aspectRatio,
      dateFormat
    }),
    [detailedNext]: getDetailedNextChartOptions({
      chartLines,
      fullSizeChart,
      aspectRatio,
      dateFormat,
      splitEnabled
    }),
    [simple]: getSimpleChartOptions({
      chartLines,
      fullSizeChart,
      splitEnabled,
      aspectRatio,
      setYFrom0To100
    }),
    [simpleNext]: getSimpleNextChartOptions({
      chartLines,
      fullSizeChart,
      splitEnabled,
      aspectRatio,
      setYFrom0To100
    }),
    [dashboardPreview]: getDashboardPreviewChartOptions({
      chartLines,
      fullSizeChart,
      aspectRatio,
      dateFormat,
      splitEnabled
    }),
    [homepagePreview]: getHomepagePreviewChartOptions({
      chartLines,
      fullSizeChart,
      aspectRatio,
      dateFormat,
      splitEnabled
    }),
    [predictedScorePreview]: getPredictedScoreChartOptions({
      chartLines,
      fullSizeChart,
      aspectRatio,
      setYFrom0To100,
      currentGrade,
      predictedScore
    })
  }

  const options = optionsByChartType[chartType]

  return {
    labels,
    datasets,
    options
  }
}

export const prepareTable = source => {
  const columns = prepareColumns(source.tableData.columns, source.tableData.granulation)
  const rows = prepareRows(source)

  return {
    columns,
    rows
  }
}

export const PERIODS = {
  DEFAULT: {
    value: null,
    unit: 'day',
    format: 'DD/MMM/YYYY'
  },
  DAILY: {
    value: 1,
    unit: 'day',
    format: 'DD/MMM'
  },
  WEEKLY: {
    value: 2,
    unit: 'week',
    format: 'DD/MMM'
  },
  MONTHLY: {
    value: 3,
    unit: 'month',
    format: 'MMM/YYYY'
  },
  QUARTERLY: {
    value: 4,
    unit: 'quarter',
    format: 'MMM/YYYY'
  },
  YEARS: {
    value: 5,
    unit: 'year',
    format: 'MMM/YYYY'
  }
}

export const isRefreshChartDataNeed = ({ oldValue, newValue, showChart }) => {
  const { GRADE, DUE_DATE, ELEMENT_START_DATE, ID } = OKR_ELEMENT_KEYS
  return (
    showChart &&
    [GRADE, DUE_DATE, ELEMENT_START_DATE, ID].some(key => oldValue[key] !== newValue[key])
  )
}
