<template>
  <AppButton
    v-if="item.private"
    class="pai-IconLockButton"
    data-testid="locked-button"
    size="sm"
    @click="onPrivacyUpdate(false)"
  >
    <svg
      :class="{
        'pai-IconLock-animated': isAnimated,
        'pai-IconLock-disabled': isLoading || !canUpdateObjectivePrivacy
      }"
      class="pai-IconLock"
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 8C9 6.34315 10.3431 5 12 5C13.6569 5 15 6.34315 15 8V15C15 16.6569 13.6569 18 12 18C10.3431 18 9 16.6569 9 15V8Z"
        stroke="currentColor"
        stroke-width="2"
      />
      <path
        clip-rule="evenodd"
        d="M8 9C6.89543 9 6 9.89543 6 11V17C6 18.1046 6.89543 19 8 19H16C17.1046 19 18 18.1046 18 17V11C18 9.89543 17.1046 9 16 9H8ZM12 16C13.1046 16 14 15.1046 14 14C14 12.8954 13.1046 12 12 12C10.8954 12 10 12.8954 10 14C10 15.1046 10.8954 16 12 16Z"
        fill="currentColor"
        fill-rule="evenodd"
      />
      <path class="pai-Bling" d="M20 11L22 13" />
      <path class="pai-Bling" d="M20 8H22.5" />
      <path class="pai-Bling" d="M20 5L22 3" />
    </svg>
  </AppButton>
  <AppButton
    v-else
    :class="{
      'pai-IconUnlocked-disabled': isLoading || !canUpdateObjectivePrivacy
    }"
    class="pai-IconUnlocked"
    data-testid="unlocked-button"
    height="24"
    icon="unlocked"
    remove-padding
    size="sm"
    type="link-next"
    width="24"
    @click="onPrivacyUpdate(true)"
  />
</template>

<script setup>
import AppButton from '@/components/ui/AppButton/AppButton'

defineProps({
  isLoading: {
    type: Boolean
  },
  item: {
    type: Object,
    required: true
  },
  isAnimated: {
    type: Boolean
  },
  canUpdateObjectivePrivacy: {
    type: Boolean
  }
})

const emit = defineEmits({
  'update-privacy': null
})

const onPrivacyUpdate = value => emit('update-privacy', value)
</script>

<style lang="scss" scoped>
.pai-IconLock {
  cursor: pointer;
  color: $dark-2;

  &:hover {
    color: $primary-color-next;
  }

  &-disabled {
    &:hover {
      cursor: not-allowed;
      color: $dark-2;
    }

    &:active {
      pointer-events: none;
    }
  }
}

.pai-IconUnlocked {
  color: $grey-1-next;

  .o-objective-row &,
  .oeli-ItemContent & {
    visibility: hidden;
  }

  .o-objective-row:hover &,
  .oeli-ItemContent:hover & {
    visibility: visible;
  }

  &:hover {
    color: $primary-color-next;
  }

  &-disabled {
    &:hover {
      cursor: not-allowed;
      color: $grey-1-next;
    }

    &:active {
      pointer-events: none;
    }
  }
}

.pai-IconLock-animated {
  .pai-Bling {
    animation: bling 0.3s linear forwards;
    animation-delay: 0s;
  }
}

@keyframes bling {
  50% {
    stroke-dasharray: 3;
    stroke-dashoffset: 12;
  }

  100% {
    stroke-dasharray: 3;
    stroke-dashoffset: 9;
  }
}

.pai-Bling {
  stroke: currentColor;
  stroke-width: 2.5;
  stroke-linecap: round;
  stroke-dasharray: 3;
  stroke-dashoffset: 15;
  transition: all 0.3s ease;
}

.pai-IconLockButton {
  padding: 0;
  margin: 0;
  background: transparent;
  border: none;
  display: flex;
}
</style>
