<template>
  <AppModalWithConfirmation
    :confirm-close="areDataChanged"
    :loading="isLoading"
    :show="show"
    :title="$t('integration_settings.link_webhook')"
    hide-hero
    @on-close="close"
    @on-confirm="save"
  >
    <div class="cwm-Description">
      {{ $t('integration_settings.create_webhook_subtitle', { name: webhook.title }) }}
    </div>

    <AppHrefLink :href="webhook.guideLink" class="cwm-GuideDescription" prepend-icon="guide">
      {{ $t('integration_settings.guide_name', { name: webhook.title }) }}
    </AppHrefLink>

    <div class="cwm-FormFields">
      <FormFieldNext :label="$t('integration_settings.path')">
        <AppInput
          v-model.trim="formModel[PATH]"
          :is-error="getPathError"
          :placeholder="$t('integration_settings.add_webhook_path')"
          max-length="1024"
          size="xlg"
          style-type="primary"
          @focus="resetPathError"
        />
        <AppFieldError v-if="getPathError" :show="getPathError">
          {{ getPathMsg }}
        </AppFieldError>
      </FormFieldNext>
      <FormFieldNext :label="$t('integration_settings.channel_name')">
        <AppInput
          v-model.trim="formModel[ALIAS]"
          :is-error="isAliasError"
          :placeholder="$t('integration_settings.custom_name')"
          max-length="30"
          size="xlg"
          style-type="primary"
          @focus="isAliasError = false"
        />
        <AppFieldError v-if="isAliasError" :show="isAliasError">
          {{ $t('field.required') }}
        </AppFieldError>
      </FormFieldNext>
    </div>
    <template #confirm-btn-text>{{ $t('action.link') }}</template>
  </AppModalWithConfirmation>
</template>

<script setup>
import { cloneDeep, isEqual } from 'lodash'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import IntegrationApiHandler from '@/api/integrations'
import { handleError } from '@/utils/error-handling'
import { isStringEmpty, isUrl } from '@/utils/general'

import AppHrefLink from '@/components/AppHrefLink'
import AppModalWithConfirmation from '@/components/AppModalWithConfirmation'
import AppFieldError from '@/components/form/AppFieldError'
import FormFieldNext from '@/components/form/FormFieldNext'
import AppInput from '@/components/ui/AppInput/AppInput'

defineOptions({
  name: 'WebhookCreateModal'
})

const props = defineProps({
  show: {
    type: Boolean
  },

  webhook: {
    type: Object,
    default: () => ({})
  }
})
const emit = defineEmits({
  'update:show': null,
  'on-close': null,
  create: null
})

const isLoading = ref(false)

const close = () => {
  emit('update:show', false)
  clearStates()
}

const save = async () => {
  validateForm()
  if (!isValidForm.value) {
    return
  }
  const api = new IntegrationApiHandler()
  try {
    isLoading.value = true
    await api.createChannelsAttributes({
      channelTypeId: props.webhook.typeId,
      attributeTypeId: props.webhook.attributeTypeId,
      attributeValue: {
        name: formModel.value[ALIAS],
        url: formModel.value[PATH]
      }
    })
    emit('create')
    close()
  } catch (error) {
    handleError({ error })
  } finally {
    isLoading.value = false
  }
}

const ALIAS = 'alias'
const PATH = 'path'
const DEFAULT_FORM_MODEL = {
  [PATH]: '',
  [ALIAS]: ''
}
const areDataChanged = computed(() => !isEqual(DEFAULT_FORM_MODEL, formModel.value))

const formModel = ref(cloneDeep(DEFAULT_FORM_MODEL))

const PATH_ERROR_DEFAULT_STATE = {
  fieldRequired: false,
  urlIncorrect: false
}
const isPathError = ref({ ...PATH_ERROR_DEFAULT_STATE })

const getPathError = computed(() => {
  return isPathError.value.fieldRequired || isPathError.value.urlIncorrect
})

const { t } = useI18n()

const getPathMsg = computed(() => {
  if (isPathError.value.fieldRequired) {
    return t('field.required')
  } else if (isPathError.value.urlIncorrect) {
    return t('field.url_incorrect')
  }
  return ''
})

const isAliasError = ref(false)

const validatePath = () => {
  isPathError.value.fieldRequired = isStringEmpty(formModel.value[PATH])
  isPathError.value.urlIncorrect = !isUrl(formModel.value[PATH])
}

const validateAlias = () => {
  isAliasError.value = isStringEmpty(formModel.value[ALIAS])
}

const validateForm = () => {
  validateAlias()
  validatePath()
}

const isValidForm = computed(() => {
  return !(isPathError.value.fieldRequired || isPathError.value.urlIncorrect || isAliasError.value)
})

const resetPathError = () => {
  isPathError.value = { ...PATH_ERROR_DEFAULT_STATE }
}

const clearStates = () => {
  formModel.value = cloneDeep(DEFAULT_FORM_MODEL)
  isAliasError.value = false
  resetPathError()
}
</script>

<style lang="scss" scoped>
.cwm-Description {
  margin-bottom: 26px;
}

.cwm-GuideDescription {
  margin-bottom: 20px;
}

.cwm-FormFields {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
</style>
