<template>
  <FormFieldNext :label="$t('levels.color')">
    <AppDroplist
      v-if="displayAsDropdown"
      v-model="colorPickerOpened"
      :disabled="loading"
      :offset="[0, 0]"
      append-to="parent"
      max-width="unset"
      position="bottom-start"
    >
      <template #button="{ expanded }">
        <SkeletonItem v-if="loading" class="acp-Loader" height="100%" width="100%" />

        <div
          :class="{
            'apc-ColorSelectTrigger-opened': expanded,
            'apc-ColorSelectTrigger-white': isWhiteColor(selectedColor)
          }"
          :style="{
            '--color': selectedColor
          }"
          class="apc-ColorSelectTrigger"
          data-auto-testid="color-palette-trigger"
          data-testid="color-palette-trigger"
        >
          <AppIcon
            class="apc-ColorSelectTriggerChevron"
            height="24"
            icon-name="arrow-down-black"
            width="24"
          />
        </div>
      </template>
      <portal-target name="palette-dropdown-content" />
    </AppDroplist>

    <portal :disabled="!displayAsDropdown" to="palette-dropdown-content">
      <div
        :style="styles"
        class="apc-ColorsMenu"
        data-auto-testid="color-palette"
        data-testid="color-palette"
      >
        <div
          v-for="item in COLORS"
          :key="item"
          :class="{
            'apc-Color-selected': item === selectedColor
          }"
          :style="{ '--color': item }"
          class="apc-Color"
          @click="onColorClick(item)"
        >
          <AppIcon v-if="item === selectedColor" height="24" icon-name="check" width="24" />
        </div>
      </div>
    </portal>
  </FormFieldNext>
</template>

<script setup>
import { computed, ref } from 'vue'

import { isWhiteColor, stringIsHex } from '@/utils/okr-levels'

import AppDroplist from '@/components/AppDroplist'
import FormFieldNext from '@/components/form/FormFieldNext'
import AppIcon from '@/components/ui/AppIcon/AppIcon'
import SkeletonItem from '@/components/ui/SkeletonLoaders/SkeletonItem'

defineOptions({
  name: 'AppPaletteColorPicker'
})

const props = defineProps({
  selectedColor: {
    type: String,
    required: true
  },

  palette: {
    type: Object,
    required: true
  },

  displayAsDropdown: {
    type: Boolean,
    default: true
  },

  loading: {
    type: Boolean
  }
})

const COLORS = computed(() => {
  return Object.values(props.palette)
    .map(colors => {
      return colors.map((color, index) => {
        return {
          color,
          index
        }
      })
    })
    .flat()
    .sort((a, b) => {
      return a.index - b.index
    })
    .map(({ color }) => color)
})

const styles = computed(() => {
  return {
    'grid-template-columns': `repeat(${Object.keys(props.palette).length}, 1fr)`
  }
})

const colorPickerOpened = ref(false)

const emit = defineEmits({
  'update:selectedColor': color => stringIsHex(color)
})
const onColorClick = color => {
  emit('update:selectedColor', color)
  colorPickerOpened.value = false
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/palette-picker-styles';

.acp-Loader {
  position: absolute;
  left: 0;
  top: 0;
}

.apc-ColorSelectTrigger {
  @extend %palette-picker-trigger;
  color: var(--thumb-color, #{$white});
  justify-content: flex-end;
  background-color: var(--color);
  border-color: var(--color);

  &-white {
    border-color: $grey-2-next;
    color: $grey-2-next;
  }

  &-opened {
    border-color: $primary-color-next;
  }
}

.apc-ColorSelectTriggerChevron {
  transition: transform $transition-fast;
  .apc-ColorSelectTrigger-opened & {
    transform: rotate(180deg);
  }
}

.apc-ColorsMenu {
  @extend %palette-dropdown-content;
}

.apc-Color {
  width: 24px;
  height: 24px;
  background-color: var(--color);
  border-radius: $border-radius-sm-next;
  flex-shrink: 0;
  cursor: pointer;
  color: var(--thumb-color, #{$white});
  transition: transform $transition-fast;

  &:not(&-selected) {
    @media (any-hover: hover) {
      &:hover {
        transform: scale(1.1);
      }
    }
  }
}
</style>
