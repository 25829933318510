<template>
  <OboardLogoLoader />
</template>

<script setup>
import { computed, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

import ShortLinkApiHandler from '@/api/short-link'
import { ROUTE_NAMES } from '@/routes/route-helpers'
import { handleError } from '@/utils/error-handling'

import OboardLogoLoader from '@/components/ui/SkeletonLoaders/OboardLogoLoader'

defineOptions({
  name: 'ShareLinkHandler'
})

const store = useStore()
const route = useRoute()
const router = useRouter()

const workspaceId = computed(() => `${store.state.workspaces.workspaceId}`)

const goHome = () => {
  router.push({ name: ROUTE_NAMES.HOME_PAGE, params: { workspaceId: workspaceId.value } })
}

onMounted(() => {
  const token = route.params.token
  if (!token) {
    goHome()
  } else {
    getShareLink(token)
  }
})

const getWorkspaceIdFromSavedLink = ({ link = '' } = {}) => {
  const { workspaceId } = router.resolve(link).params
  if (!workspaceId) {
    throw new Error('Seems link is corrupted')
  }

  return workspaceId
}

const getShareLink = async token => {
  try {
    const api = new ShortLinkApiHandler()
    const data = await api.getFullLink({ token })

    const workspaceId = getWorkspaceIdFromSavedLink({ link: data.link })

    await store.dispatch('workspaces/setWorkspaceId', Number(workspaceId))

    await router.push(data.link)
  } catch (error) {
    handleError({ error })
    goHome()
  }
}
</script>

<style lang="scss" scoped></style>
