import ApiHandler from '@/api/base'

class WorkspacesApiHandler extends ApiHandler {
  async getWorkspaces() {
    const { data } = await this.http.get('/settings/workspaces')

    return data
  }

  async getAllUserWorkspaces({ searchString = null } = {}) {
    const { data } = await this.http.post('/getAllUserWorkspaces', {
      searchString
    })
    return data
  }

  async createWorkspace({
    key = null,
    name = null,
    description = null,
    isPublic = false,
    timezone = 0,
    color = null,
    levelIds = null,
    addToAllLevels = false
  } = {}) {
    const { data } = await this.http.post('/createWorkspace', {
      key,
      name,
      description,
      public: isPublic,
      timezone,
      color,
      addToAllLevels,
      levelIds
    })
    return data
  }

  async updateWorkspace({
    id = null,
    key = null,
    name = null,
    description = null,
    isPublic = false,
    timezone = 0,
    color = null
  } = {}) {
    const { data } = await this.http.post('/updateWorkspace', {
      id,
      key,
      name,
      description,
      public: isPublic,
      timezone,
      color
    })
    return data
  }

  async removeWorkspace({ id } = {}) {
    await this.http.post('/removeWorkspace', {
      id
    })
  }
}

export default WorkspacesApiHandler
