<template>
  <div class="cl-ConnectionsListPage">
    <PageContentHeader
      :level="3"
      :max-width="HEADER_SIZES.LG"
      :title="t('menu.integrations')"
      class="cl-TokensPage_Header"
      no-margin
    >
    </PageContentHeader>

    <div class="cl-PageWrapper">
      <div class="cl-List">
        <div v-for="item in INTEGRATIONS_DATA" :key="item.id" class="cl-List_Item">
          <FormCaption :title="item.title">
            <template #subtitle>
              <div class="cl-ListItem_Description">
                {{ item.description }}
                <AppHrefLink v-if="item.guide" :href="item.guide.link" prepend-icon="guide">
                  {{ item.guide.text }}
                </AppHrefLink>
              </div>
            </template>
          </FormCaption>
          <div class="cl-ListItem_ConnectionItems">
            <AppButton
              v-for="connectionItem in item.connectionItems"
              :key="connectionItem.typeId || connectionItem.id"
              :icon="connectionItem.icon"
              class="cl-ConnectionItem"
              height="32px"
              type="ghost-next"
              width="32px"
              @click="connectionItem.callback()"
            >
              <div class="cl-ConnectionItem_Text">
                <div class="cl-ConnectionItem_Title">{{ connectionItem.title }}</div>
                <div class="cl-ConnectionItem_Description">{{ connectionItem.description }}</div>
              </div>
            </AppButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { inject } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

import { ROUTE_NAMES } from '@/routes/route-helpers'
import { HEADER_SIZES } from '@/utils/components-configurations/page-content-header'
import { isJiraAppInjectionKey } from '@/utils/injection-keys'

import AppHrefLink from '@/components/AppHrefLink'
import FormCaption from '@/components/check-ins/FormCaption'
import AppButton from '@/components/ui/AppButton/AppButton'
import PageContentHeader from '@/components/ui/PageContentHeader/PageContentHeader'
import { getMessengersConnectionItems } from '@/views/workspaces/settings/integrations/integration-utils'

defineOptions({
  name: 'ConnectionsList'
})

const isJiraApp = inject(isJiraAppInjectionKey)

const { t } = useI18n()
const router = useRouter()
const openConnectionSettings = () => {
  router.push({ name: ROUTE_NAMES.SETTINGS_INTEGRATIONS_OBOARD_CONNECTION })
}

const openTokenPage = () => {
  router.push({ name: ROUTE_NAMES.SETTINGS_INTEGRATIONS_API_TOKENS })
}

const openMessengerPage = name => {
  router.push({
    name: ROUTE_NAMES.SETTINGS_INTEGRATIONS_MESSENGER_CONNECTION,
    params: {
      name
    }
  })
}
const INTEGRATIONS_DATA = [
  {
    id: 0,
    title: t('menu.integrations'),
    description: t('integration_settings.integration_oboard_description'),
    guide: {
      icon: 'guide',
      text: t('connections.guide.link_text'),
      link: 'https://oboard.atlassian.net/wiki/spaces/OBPFJ/pages/1162018833'
    },
    connectionItems: [
      isJiraApp
        ? {
            id: 1,
            icon: 'connection-web-app',
            title: t('app.platform.web_app'),
            description: t('integration_settings.get_access_ws_jira'),
            callback: openConnectionSettings
          }
        : {
            id: 0,
            icon: 'connection-jira-default',
            title: t('app.platform.jira'),
            description: t('integration_settings.link_staff'),
            callback: openConnectionSettings
          }
    ]
  },
  {
    id: 1,
    title: t('integration_settings.messengers'),
    description: t('integration_settings.integration_messengers_description'),
    guide: {
      icon: 'guide',
      text: t('integration_settings.messenger_guide'),
      link: 'https://oboard.atlassian.net/wiki/spaces/OBPFJ/pages/1467318289'
    },
    connectionItems: getMessengersConnectionItems().map(item => ({
      ...item,
      callback: () => openMessengerPage(item.messengerName)
    }))
  },
  {
    id: 2,
    title: t('integration_settings.developers'),
    description: t('integration_settings.integration_api_description'),
    connectionItems: [
      {
        id: 5,
        icon: 'connection-tokens',
        title: t('menu.api_tokens'),
        description: t('integration_settings.operate_wo_gui'),
        callback: openTokenPage
      }
    ]
  }
]
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';

.cl-ConnectionsListPage {
  width: 100%;
  max-width: $page-width-lg;
  padding-bottom: $page-bottom-padding;
}

.cl-List_Item {
  &:not(&:last-child) {
    margin-bottom: 64px;
  }
}

.cl-PageWrapper {
  width: 100%;
  padding: 20px $page-right-padding 0 $page-left-padding;
}

.cl-ListItem_Description {
  font-size: $fs-14;
  line-height: 28px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
  color: $dark-3;
  margin: 0 0 24px 0;
}

.cl-ListItem_ConnectionItems {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
}

.cl-ConnectionItem {
  border: 1px solid $grey-2-next;
  --justify-content: flex-start;
  --gap: 16px;
  --border-radius: #{$border-radius-lg};
  height: 92px;
  --button-high-padding: 24px;
  --button-low-padding: 24px;
  min-width: 272px;
}

.cl-ConnectionItem_Text {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 4px;
}

.cl-ConnectionItem_Title {
  color: $dark-2;
  font-weight: fw('semi-bold');
}

.cl-ConnectionItem_Title,
.cl-ConnectionItem_Description {
  font-size: $fs-14;
  line-height: 20px;
}

.cl-ConnectionItem_Description {
  color: $dark-3;
  font-weight: fw('regular');
}
</style>
