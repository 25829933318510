<template>
  <div class="wcs-WebhooksPage">
    <AppBackButton />

    <PageContentHeader
      :description="
        $t('integration_settings.messenger_webhook_subtitle', {
          messengerName: getMessengersWebhookData.title
        })
      "
      :level="3"
      :max-width="HEADER_SIZES.LG"
      :title="
        t('integration_settings.messenger_webhook_title', {
          messengerName: getMessengersWebhookData.title
        })
      "
      class="wcs-WebhooksPage_Header"
      no-margin
    >
      <AppButton
        v-if="!isNoWebhooks"
        v-tippy="{
          content: createButtonDisabled ? $t('integration_settings.limit_integration_msg') : null,
          placement: 'top'
        }"
        :disable="createButtonDisabled"
        height="24"
        icon="plus-next"
        type="primary-next"
        width="24"
        @click="onCreateWebhookClick"
      >
        {{ t('integration_settings.link_webhook') }}
      </AppButton>
    </PageContentHeader>
    <div class="wcs-MainContent">
      <AppTable
        v-if="!isNoWebhooks"
        :columns="tableColumns"
        :data="webhooks"
        :disable-user-select="false"
        :loading="areWebhooksLoading"
        border-on-last-row
        no-border
        offset-left="var(--page-left-padding)"
        offset-right="var(--page-right-padding)"
        sticky-header
        type="primary-next"
      >
        <template #header-cell="{ column }">
          <template v-if="column.title">
            {{ t(column.title) }}
          </template>
        </template>
        <template #cell="{ columnKey, item, index }">
          <template v-if="columnKey === TABLE_COLUMNS_KEYS.NUMBER">
            <div class="wcs-WebhookNumber">
              {{ index + 1 }}
            </div>
          </template>
          <template v-if="columnKey === TABLE_COLUMNS_KEYS.ALIAS">
            <div class="wcs-WebhookName oboard-truncated-text">{{ item.alias }}</div>
          </template>
          <template v-if="columnKey === TABLE_COLUMNS_KEYS.URL">
            <div class="wcs-WebhookUrl oboard-truncated-text">{{ item.url }}</div>
          </template>

          <template v-else-if="columnKey === TABLE_COLUMNS_KEYS.ACTION">
            <AppButton
              class="wcs-DeleteWebhookBtn"
              icon="unlink-next"
              size="sm"
              type="tertiary-next"
              @click="onDeleteWebhook(item)"
            />
          </template>
        </template>
        <template #loading>
          <MessengersWebhooksLoader />
        </template>

        <template #footer>
          <AppTableCreateButton
            v-if="!createButtonDisabled"
            full-width
            icon-name="plus-next"
            @click="onCreateWebhookClick"
          >
            {{ t('integration_settings.link_webhook') }}
          </AppTableCreateButton>

          <AppInfoMessage
            v-if="isWebhooksLimitExceeded"
            :type="INFO_MESSAGE_TYPES.WARNING"
            class="wcs-Message"
          >
            {{
              t('integration_settings.limit_exceeded', {
                limit: MAX_WEBHOOKS_COUNT
              })
            }}
          </AppInfoMessage>
        </template>
      </AppTable>
    </div>
    <SettingsPagesEmptyState
      v-if="isNoWebhooks"
      hero-height="128"
      hero-icon="no-tokens-hero"
      hero-width="142"
    >
      <template #title>
        {{ t('integration_settings.no_webhooks') }}
      </template>
      <template #subtitle> {{ t('integration_settings.create_first_webhook') }}</template>

      <template #action>
        <AppButton
          height="24"
          icon="plus-next"
          type="primary-next"
          width="24"
          @click="onCreateWebhookClick"
        >
          {{ t('integration_settings.link_webhook') }}
        </AppButton>
      </template>
    </SettingsPagesEmptyState>
    <WebhookDeleteModal
      :show="showWebhookDeleteModal"
      :webhook-to-delete="webhookToDelete"
      name-key="alias"
      @on-close="hideWebhookDeleteModal"
      @on-webhook-deleted="onConfirmDelete"
    />
    <WebhookCreateModal
      v-model:show="showCreateModal"
      :webhook="getMessengersWebhookData"
      @create="onCreateWebhook"
    />
  </div>
</template>

<script setup>
import { isEmpty } from 'lodash'
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import IntegrationApiHandler from '@/api/integrations'
import { INFO_MESSAGE_TYPES } from '@/utils/components-configurations/app-info-message'
import { HEADER_SIZES } from '@/utils/components-configurations/page-content-header'
import { handleError } from '@/utils/error-handling'
import { TABLE_COLUMNS_KEYS } from '@/utils/table-columns'

import AppBackButton from '@/components/ui/AppBackButton'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppInfoMessage from '@/components/ui/AppInfoMessage'
import AppTableCreateButton from '@/components/ui/AppTableCreateButton'
import PageContentHeader from '@/components/ui/PageContentHeader/PageContentHeader'
import SettingsPagesEmptyState from '@/components/ui/SettingsPagesEmptyState/SettingsPagesEmptyState'
import MessengersWebhooksLoader from '@/components/ui/SkeletonLoaders/MessengersWebhooksLoader'
import AppTable from '@/components/ui/Table/AppTable'
import { getMessengersConnectionItems } from '@/views/workspaces/settings/integrations/integration-utils'
import WebhookCreateModal from '@/views/workspaces/settings/integrations/WebhookCreateModal'
import WebhookDeleteModal from '@/views/workspaces/settings/integrations/WebhookDeleteModal'

defineOptions({
  name: 'WebhooksConnectionSettings'
})

const { t } = useI18n()

const tableColumns = [
  {
    title: 'tokens.token_number',
    key: TABLE_COLUMNS_KEYS.NUMBER,
    width: 24
  },
  {
    title: 'integration_settings.channel_name',
    key: TABLE_COLUMNS_KEYS.ALIAS,
    width: 420
  },
  {
    title: 'integration_settings.url',
    key: TABLE_COLUMNS_KEYS.URL,
    width: 'auto'
  },
  {
    key: TABLE_COLUMNS_KEYS.ACTION,
    width: 24
  }
]

const webhooks = ref([])
const areWebhooksLoading = ref(false)

const isNoWebhooks = computed(() => {
  return isEmpty(webhooks.value) && !areWebhooksLoading.value
})

const MAX_WEBHOOKS_COUNT = 20
const isWebhooksLimitExceeded = computed(() => {
  return webhooks.value.length >= MAX_WEBHOOKS_COUNT
})

const createButtonDisabled = computed(() => {
  return areWebhooksLoading.value || isWebhooksLimitExceeded.value
})

const showCreateModal = ref(false)
const onCreateWebhookClick = () => {
  if (!createButtonDisabled.value) {
    showCreateModal.value = true
  }
}

const route = useRoute()

const getMessengersWebhookData = computed(() => {
  return (
    getMessengersConnectionItems().find(item => item.messengerName === route.params.name) ||
    getMessengersConnectionItems()[0]
  )
})

const showWebhookDeleteModal = ref(false)
const webhookToDelete = ref(null)
const hideWebhookDeleteModal = () => {
  showWebhookDeleteModal.value = false
}

const onDeleteWebhook = webhook => {
  webhookToDelete.value = webhook
  showWebhookDeleteModal.value = true
}

const integrationsApi = new IntegrationApiHandler()

onMounted(() => {
  getWebhooks()
})

const getWebhooks = async () => {
  try {
    areWebhooksLoading.value = true

    const { typeId, attributeTypeId } = getMessengersWebhookData.value
    const data = await integrationsApi.getChannelsAttributes({
      attributeTypeId,
      channelTypeId: typeId
    })

    webhooks.value = data.map(item => {
      const { name, url } = item.attributeValue
      return {
        id: item.id,
        alias: name,
        url
      }
    })
  } catch (error) {
    handleError({ error })
  } finally {
    areWebhooksLoading.value = false
  }
}

const onConfirmDelete = async () => {
  showWebhookDeleteModal.value = false
  webhookToDelete.value = null
  await getWebhooks()
}

const onCreateWebhook = async () => {
  showCreateModal.value = false
  await getWebhooks()
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/app-table-helpers';

.wcs-WebhooksPage {
  width: 100%;
  max-width: $page-width-lg;
  padding-bottom: $page-bottom-padding;
  --description-max-width: 100%;
}

.wcs-WebhooksPage_Header {
  &:deep(.pc-Title) {
    min-height: 32px;
  }
}

.wcs-MainContent {
  width: 100%;
  padding-top: 16px;
  padding-left: $page-left-padding;
  padding-right: $page-right-padding;
}

.wcs-WebhookNumber {
  font-weight: fw('bold');
  color: $dark-3;
}

.wcs-WebhookName,
.wcs-WebhookUrl {
  padding-right: 8px;
  font-weight: fw('semi-bold');
}

.wcs-DeleteWebhookBtn {
  @extend %app-table-hidden-items;
}

.wcs-Message {
  margin-top: 10px;
  padding-left: $page-left-padding;
}
</style>
