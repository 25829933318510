<template>
  <div class="ml-Loader">
    <div v-for="(row, i) in ROWS" :key="i" class="ml-Loader_Row">
      <SkeletonItem :height="row" border-radius="26px" width="340px" />
      <template v-if="i % 2 === 0">
        <div class="ml-ExpandButton">
          <SkeletonItem :appearance="SKELETON_APPEARANCES.CIRCLE" :size="SKELETON_SIZES.SM" />
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
import {
  SKELETON_APPEARANCES,
  SKELETON_SIZES
} from '@/utils/components-configurations/skeleton-item'

import SkeletonItem from '@/components/ui/SkeletonLoaders/SkeletonItem'

defineOptions({
  name: 'MindmapLoader'
})

const ROWS = ['120px', '148px', '120px', '176px', '120px']
</script>

<style lang="scss" scoped>
.ml-Loader {
  max-width: 100%;
  display: grid;
  gap: 30px;
  justify-content: flex-start;
}

.ml-Loader_Row {
  position: relative;
}

.ml-ExpandButton {
  position: absolute;
  right: -44px;
  top: 20px;
  display: flex;
  align-items: center;
  &:before {
    height: 2px;
    width: 20px;
    content: '';
    background-color: #f4f5f7;
    position: relative;
    display: block;
  }
}
</style>
