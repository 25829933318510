<template>
  <div class="spes-Wrapper">
    <AppIcon
      v-if="heroIcon"
      :height="heroHeight"
      :icon-name="heroIcon"
      :width="heroWidth"
      class="spes-Hero"
    />
    <AppTitle v-if="$slots.title" :level="1" disable-margin>
      <slot name="title" />
    </AppTitle>
    <div v-if="$slots.subtitle" class="spes-Subtitle">
      <slot name="subtitle" />
    </div>

    <AppDivider v-if="$slots.action" class="spes-Divider" no-margin />

    <slot name="action" />
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SettingsPagesEmptyState'
})
</script>

<script setup>
import AppDivider from '@/components/ui/AppDivider'
import AppIcon from '@/components/ui/AppIcon/AppIcon'
import AppTitle from '@/components/ui/AppTitle/AppTitle'

defineProps({
  heroIcon: {
    type: String,
    default: ''
  },

  heroHeight: {
    type: [String, Number],
    default: 0
  },

  heroWidth: {
    type: [String, Number],
    default: 0
  }
})
</script>

<style lang="scss" scoped>
.spes-Wrapper {
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
  padding: 95px 0;
  font-family: $system-ui;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.spes-Hero {
  margin-bottom: 49px;
}

.spes-Subtitle {
  font-style: normal;
  font-weight: fw('regular');
  font-size: $fs-14;
  line-height: 20px;
  color: $dark-3;
  margin: 16px 0 18px;
}

.spes-Divider {
  width: 100%;
  margin-bottom: 24px;
}
</style>
