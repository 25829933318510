<template>
  <div :id="objective.id" :class="classes" :style="{ '--color': `#${objective.levelColor}` }">
    <div class="ti-TreeItem_Content">
      <div class="ti-TopPanel">
        <OkrIconAndId
          :clickable="userHasReadAccess"
          :okr-element="objective"
          id-disabled-color="var(--dark-2)"
          style="--id-font-weight: 600"
          @click-on-id="$emit('click-on-id')"
        />
        <DropdownMenu
          v-if="showMoreMenuDropdown"
          :content-class="[ACTIONS_DROPDOWN_CLASS_NAME]"
          :items="optionsMenuItems"
          :offset="[0, 0]"
          :to-selector="`.ti-Actions_More-${objective.id}`"
          :type="DROPDOWN_MENU_TYPES.DEFAULT_NEXT"
          append-to=".oob-ContentWrapper"
          preferred-position="bottom-end"
          show-on-init
          @item-click="onActionsMenuItemClick"
        />

        <div class="ti-Actions">
          <ExternalJiraLink v-if="isTask" :jira-issue-id="objective.jiraIssueId" />

          <div v-click-away="onClickOutside" class="ti-Actions_Menu">
            <AppButton
              v-if="showActionsButton"
              :class="{
                [`ti-Actions_More-${objective.id}`]: true,
                'ti-Actions_More-opened': moreMenuInitialized
              }"
              class="ti-Actions_More"
              icon="more-next"
              size="sm"
              type="tertiary-next"
              @click="moreMenuInitialized = true"
            />
          </div>
        </div>
      </div>

      <div>
        <div class="ti-TitleAndGrade">
          <AppTitle
            :class="{ 'ti-Title-deep': !!depth }"
            :level="depth ? 6 : 3"
            class="ti-Title"
            disable-margin
          >
            <OkrElementCipheredName :okr-element="objective" />
          </AppTitle>

          <OkrGrade
            :class="{ 'ti-Grade-bigger': !depth }"
            :color="objective.gradeColor"
            :grade="objective.gradeToUse"
            :type-id="objective.typeId"
            :weight="null"
            :weight-multiplier="null"
            show-only-value
            type="default-next"
          />
        </div>

        <CurrentTargetCell :is-kr="isKR" :item="objective" class="ti-CurrentTarget" readable />
      </div>

      <IndicatorPanel
        v-if="!isTask && !isKR"
        :background-color="gradeValueStyles.backgroundColor"
        :color="gradeValueStyles.color"
        :indicator-width="objective.gradeToUse"
        class="ti-IndicatorPanel"
      />
    </div>

    <div v-if="treeState.activeTreeType === TREE_TYPES.DETAILED.value" class="ti-AdditionalInfo">
      <AppAvatar :avatar-url="avatarUrl" no-margins />

      <div class="ti-AdditionalInfo_Groups">
        <GroupsCell :item="objective" />
      </div>

      <div
        v-tippy="{
          content: resolvedDate.tooltipText
        }"
        class="ti-AdditionalInfo_Date"
      >
        {{ resolvedDate.text }}
      </div>
    </div>

    <AppDivider v-if="(isTask || isKR) && !isLast" class="ti-Divider" no-margin />

    <AppButton
      v-if="showExpandButton"
      :icon="expanded ? 'mindmap-collapse' : 'mindmap-expand'"
      class="ti-ExpandButton"
      remove-padding
      round
      size="sm"
      type="primary-next"
      @click="expanded ? $emit('collapse') : $emit('expand')"
    >
    </AppButton>

    <AppButton
      v-if="showFilterButton"
      v-tippy="{
        content: $t(`filter.${showFilteredChildren ? 'hide' : 'show'}_filtered_items`, {
          count: filteredChildItemsCount
        })
      }"
      :data-items="filteredChildItemsCount"
      :icon="showFilteredChildren ? 'not-watch' : 'watch-line-grey'"
      class="ti-FilterButton"
      remove-padding
      round
      size="sm"
      type="primary-next"
      @click="onFilteredChildrenButtonClick"
    >
    </AppButton>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { defineComponent } from 'vue'
import { mapGetters, mapState } from 'vuex'

import { tracker } from '@/tracking/amplitude'
import { EVENT_CATEGORIES } from '@/tracking/amplitude-helpers'
import { DROPDOWN_MENU_TYPES } from '@/utils/components-configurations/dropdown-menu'
import {
  CONTROLS_BUTTON_LEFT_OFFSET,
  EXPAND_BUTTON_TOP_OFFSET,
  FILTER_BUTTON_TOP_OFFSET
} from '@/utils/connections'
import { dateFormat } from '@/utils/date'
import {
  isJiraAppInjectionKey,
  isSalesforceAppInjectionKey,
  isWebAppInjectionKey,
  treeStateInjectionKey
} from '@/utils/injection-keys'
import { OBJECTIVE_TYPES } from '@/utils/objective-types'
import {
  getObjectiveAddMenuItems,
  getObjectiveActionsMenuItems,
  getOwnerFromUsersList,
  currentUserCanCreateObjective,
  getObjectiveLevelsForCreate,
  currentUserCanReadObjective,
  objectiveIsJiraTask,
  isKR,
  OKR_ELEMENTS_TABLE_ADD_MENU_ITEMS,
  OKR_ELEMENTS_TABLE_ACTIONS_MENU_ITEMS,
  okrElementIsExpandable
} from '@/utils/objectives'
import { isManualPeriodModeEnabled } from '@/utils/okr-element-dates'
import { getGradeColorStyle } from '@/utils/okr-elements/grade'
import { TREE_TYPES } from '@/utils/okr-map'
import { usePlansLimitations } from '@/utils/web-app/plans-limitations'
import { PLUGIN_OPTIONS_KEYS } from '@root/template-options-keys'

import OkrIconAndId from '@/components/form/OkrIconAndId'
import ExternalJiraLink from '@/components/objectives/ExternalJiraLink'
import OkrGrade from '@/components/objectives/OkrGrade'
import CurrentTargetCell from '@/components/objectives/table/cells/CurrentTargetCell'
import GroupsCell from '@/components/objectives/table/cells/GroupsCell'
import AppAvatar from '@/components/ui/AppAvatar/AppAvatar'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppDivider from '@/components/ui/AppDivider'
import AppTitle from '@/components/ui/AppTitle/AppTitle'
import DropdownMenu from '@/components/ui/DropdownMenu/DropdownMenu'
import IndicatorPanel from '@/components/ui/IndicatorPanel/IndicatorPanel'
import OkrElementCipheredName from '@/components/ui/OkrElementCipheredName'

const ACTIONS_DROPDOWN_CLASS_NAME = 'ti-ActionsMore_Dropdown'

export default defineComponent({
  name: 'ObjectiveTreeItem',

  components: {
    GroupsCell,
    OkrElementCipheredName,
    ExternalJiraLink,
    AppDivider,
    CurrentTargetCell,
    OkrGrade,
    AppTitle,
    OkrIconAndId,
    AppButton,
    DropdownMenu,
    AppAvatar,
    IndicatorPanel
  },

  inject: {
    treeState: {
      from: treeStateInjectionKey
    },

    isWebApp: {
      from: isWebAppInjectionKey
    },

    isSalesforceApp: {
      from: isSalesforceAppInjectionKey
    },

    isJiraApp: {
      from: isJiraAppInjectionKey
    }
  },

  props: {
    objective: {
      type: Object,
      required: true
    },

    expanded: {
      type: Boolean
    },

    visibleChildren: {
      type: Array,
      required: true
    },

    depth: {
      type: Number,
      default: 0
    },

    isFirst: {
      type: Boolean
    },

    isLast: {
      type: Boolean
    },

    showFilteredChildren: {
      type: Boolean
    },

    allChildren: {
      type: Array,
      required: true
    },

    parentElementIsObjective: {
      type: Boolean
    }
  },

  emits: {
    'click-on-id': null,
    expand: null,
    collapse: null,
    edit: null,
    add: null,
    delete: null,
    duplicate: null,
    'edit-weights': null,
    move: null,
    'update:showFilteredChildren': null
  },

  setup() {
    const { okrWeightsLimitations } = usePlansLimitations()

    return {
      okrWeightsLimitations
    }
  },

  data() {
    return {
      moreMenuInitialized: false
    }
  },

  computed: {
    ...mapGetters('objectives', {
      getLevelItem: 'getLevelItem',
      getLevelsByWorkspaceId: 'getLevelsByWorkspaceId'
    }),

    ...mapGetters('workspaces', {
      selectedWorkspace: 'selectedWorkspace'
    }),

    ...mapGetters('system', {
      allowLinkOkrElementsToKr: 'allowLinkOkrElementsToKr'
    }),

    ...mapState('system', {
      userData: state => state.userData || {}
    }),

    ...mapState('pluginOptions', {
      isPluginAdmin: state => state[PLUGIN_OPTIONS_KEYS.IS_PLUGIN_ADMIN],
      isJiraConnected: state => state[PLUGIN_OPTIONS_KEYS.JIRA_CONNECTED]
    }),

    expandButtonTopOffset: () => `${EXPAND_BUTTON_TOP_OFFSET}px`,
    filterButtonTopOffset: () => `${FILTER_BUTTON_TOP_OFFSET}px`,
    controlsButtonsLeftOffset: () => `${CONTROLS_BUTTON_LEFT_OFFSET}px`,

    ACTIONS_DROPDOWN_CLASS_NAME: () => ACTIONS_DROPDOWN_CLASS_NAME,

    DROPDOWN_MENU_TYPES: () => DROPDOWN_MENU_TYPES,

    objectiveLevels() {
      return this.getLevelsByWorkspaceId(this.objective.workspaceId)
    },

    isWeightsActionDisabled() {
      return this.okrWeightsLimitations.isDisabled({ okrElement: this.objective })
    },

    weightTriggerTooltip() {
      return this.okrWeightsLimitations.getTooltip({ okrElement: this.objective })
    },

    isAutoPeriodMode() {
      return !isManualPeriodModeEnabled(
        this.objective.startDateManual,
        this.objective.dueDateManual
      )
    },

    resolvedDate() {
      const { isAutoPeriodMode } = this
      const { dueDate, automaticDueDate, intervalName } = this.objective
      const tooltipText = intervalName

      if (isAutoPeriodMode) {
        if (automaticDueDate) {
          return {
            text: dayjs(automaticDueDate).utc().format(dateFormat),
            tooltipText
          }
        }
      } else {
        if (dueDate) {
          return {
            text: dayjs(dueDate).utc().format(dateFormat),
            tooltipText
          }
        }
      }

      return {
        text: intervalName,
        tooltipText: null
      }
    },

    optionsMenuItems() {
      const actions = getObjectiveActionsMenuItems(
        this.objective,
        this.isWeightsActionDisabled,
        this.weightTriggerTooltip
      )

      const addItems = getObjectiveAddMenuItems({
        objective: this.objective,
        objectiveLevels: this.objectiveLevelsForCreate,
        allowLinkOkrElementsToKr: this.allowLinkOkrElementsToKr,
        isJiraApp: this.isJiraApp,
        isWebApp: this.isWebApp,
        isSalesforceApp: this.isSalesforceApp,
        isJiraConnected: this.isJiraConnected,
        hasAccessToJira: this.userData.hasAccessToJira
      })

      return [...addItems, ...actions]
    },

    showExpandButton() {
      return (
        this.visibleChildren.length > 0 &&
        this.allChildren.length > 0 &&
        okrElementIsExpandable({
          element: this.objective,
          filterButtonEnabled: true,
          filteredChildrenVisible: this.showFilteredChildren
        })
      )
    },

    showFilterButton() {
      /*      return (
        this.userHasReadAccess &&
        this.objective.childCount > 0 &&
        this.objective.visibleCount !== this.objective.childCount
      )*/

      return (
        this.userHasReadAccess &&
        this.resolvedChildCount > 0 &&
        this.filteredChildItemsCount > 0 &&
        this.resolvedVisibleCount !== this.resolvedChildCount
      )
    },

    avatarUrl() {
      return getOwnerFromUsersList(this.objective.users)?.avatarUrl
    },

    isTask() {
      return objectiveIsJiraTask(this.objective)
    },

    isKR() {
      return isKR(this.objective)
    },

    userHasReadAccess() {
      return currentUserCanReadObjective(this.objective)
    },

    userHasCreateAccess() {
      return currentUserCanCreateObjective(this.selectedWorkspace, this.isPluginAdmin)
    },

    showActionsButton() {
      return this.optionsMenuItems.length && this.userHasCreateAccess && this.userHasReadAccess
    },

    showMoreMenuDropdown() {
      return this.moreMenuInitialized && this.showActionsButton
    },

    objectiveLevelsForCreate() {
      return getObjectiveLevelsForCreate(this.objectiveLevels)
    },

    gradeValueStyles() {
      const baseColorStyles = getGradeColorStyle(this.objective.gradeColor)

      return {
        ...baseColorStyles,
        backgroundColor: `rgba(${baseColorStyles['--rgb-color']}, 0.2)`
      }
    },

    classes() {
      return {
        'ti-TreeItem': true,
        [`ti-TreeItem-${this.treeState.activeTreeType}`]: true,
        'ti-TreeItem-semitransparent': !this.objective.fitFilterCriteria,
        'ti-TreeItem-kr': this.isKR || this.isTask,
        'ti-TreeItem-first': this.isFirst,
        'ti-TreeItem-last': this.isLast,
        'ti-TreeItem-immediate-from-objective': this.parentElementIsObjective,
        'ti-TreeItem-objective': !this.isKR && !this.isTask,
        'ti-TreeItem-deep': this.depth
      }
    },

    resolvedChildCount() {
      const { showKrs } = this.treeState
      if (showKrs) {
        return this.objective.childCount
      } else {
        const childObjectives = this.objective.childElements.filter(objective => {
          return !isKR(objective) && !objectiveIsJiraTask(objective)
        })

        return childObjectives.length || 0
      }
    },

    resolvedVisibleCount() {
      const { showKrs } = this.treeState
      if (showKrs) {
        return this.objective.visibleCount
      } else {
        const childKrs = this.objective.childElements.filter(objective => {
          return isKR(objective) && objectiveIsJiraTask(objective)
        })

        const count = this.objective.visibleCount - childKrs.length

        return count > 0 ? count : 0
      }
    },

    filteredChildItemsCount() {
      return this.resolvedChildCount - this.resolvedVisibleCount
    },

    TREE_TYPES: () => TREE_TYPES
  },

  methods: {
    onFilteredChildrenButtonClick() {
      const wasHiddenAndShow = !this.showFilteredChildren && !this.expanded
      const wasVisibleAndHide = this.showFilteredChildren && this.expanded
      const allAreFiltered = this.visibleChildren.length - this.filteredChildItemsCount === 0
      if (wasHiddenAndShow || (wasVisibleAndHide && allAreFiltered)) {
        this.expanded ? this.$emit('collapse') : this.$emit('expand')
      }
      this.$emit('update:showFilteredChildren', !this.showFilteredChildren)
      tracker.logEvent(this.showFilteredChildren ? 'Show filtered items' : 'Hide filtered items', {
        category: EVENT_CATEGORIES.ALIGNMENT_NAVIGATION,
        filterState: allAreFiltered ? 'hidden all' : 'hidden partially'
      })
    },

    onActionsMenuItemClick(name) {
      const { REMOVE, EDIT, COPY, WEIGHTS, MOVE } = OKR_ELEMENTS_TABLE_ACTIONS_MENU_ITEMS
      if (name === REMOVE.name) {
        this.$emit(REMOVE.action)
      } else if (name === EDIT.name) {
        this.$emit(EDIT.action)
      } else if (name === COPY.name) {
        this.$emit(COPY.action)
      } else if (name === WEIGHTS.name) {
        this.$emit(WEIGHTS.action)
      } else if (name === MOVE.name) {
        this.$emit(MOVE.action)
      }

      const { CREATE_OBJECTIVE, CREATE_KR, LINK_TASK, CREATE_JIRA_ISSUE } =
        OKR_ELEMENTS_TABLE_ADD_MENU_ITEMS
      const payload = {}
      let typeId = null
      if (name === CREATE_KR.name) {
        typeId = OBJECTIVE_TYPES.KR
      } else if (name === LINK_TASK.name) {
        typeId = OBJECTIVE_TYPES.TASK
        payload.action = LINK_TASK.action
      } else if (name === CREATE_OBJECTIVE.name) {
        typeId = OBJECTIVE_TYPES.PERSONAL
        payload.action = CREATE_OBJECTIVE.action
      } else if (name === CREATE_JIRA_ISSUE.name) {
        typeId = OBJECTIVE_TYPES.TASK
        payload.action = CREATE_JIRA_ISSUE.action
      }

      if (typeId) {
        payload.levelId = this.getLevelItem('typeId', typeId).id
        payload.typeId = typeId
      } else {
        payload.levelId = name
        payload.typeId = this.getLevelItem('id', name).typeId
        payload.intervalId = this.objective.intervalId
      }

      this.$emit('add', payload)
    },

    onClickOutside(e) {
      if (this.moreMenuInitialized && !e.target.closest(`.${ACTIONS_DROPDOWN_CLASS_NAME}`)) {
        this.moreMenuInitialized = false
      }
    }
  }
})
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/okr-map';

$controls-buttons-size: var(--okr-table-row-controls-button-width, 24px);

.ti-TreeItem {
  position: relative;
  width: $tree-item-width;
  border-radius: $border-radius-lg-next;
  box-shadow: $common-box-shadow;
  background-color: $white;
  cursor: default;

  font-size: $fs-12;
  font-family: $system-ui;

  &-objective {
    min-height: $tree-item-min-height;
  }

  &-kr {
    background: $grey-3-next;
    box-shadow: none;
    border-radius: $border-radius-lg-next;
    height: 100%;
    padding-bottom: 0;

    // box-shadow: inset 0 0 0 2px $grade-medium-color-next;
    // border: 2px solid $grade-medium-color-next;
  }

  &-kr#{&}-first#{&}-last#{&}-immediate-from-objective {
    min-height: $tree-item-min-height;
  }

  &-objective {
    .is-safari & {
      // box-shadow: inset 0 0 0 2px var(--color), $common-box-shadow;
      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        border-radius: inherit;
        border: 2px solid var(--color);
        pointer-events: none;
      }
    }

    &:not(.is-safari &) {
      outline: 2px solid var(--color);
      outline-offset: -2px;
    }
  }
}

.ti-TreeItem_Content {
  display: flex;
  flex-direction: column;
  gap: 9px;
  padding: $tree-item-padding;

  .ti-TreeItem-kr & {
    padding-bottom: 0;
  }

  .ti-TreeItem-kr.ti-TreeItem-last & {
    padding-bottom: $tree-item-padding;
  }

  .ti-TreeItem-kr.ti-TreeItem-last.ti-TreeItem-detailed & {
    padding-bottom: 0;
  }

  .ti-TreeItem-semitransparent & {
    opacity: 0.5;
  }
}

.ti-TopPanel {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ti-IndicatorPanel {
  --height: 6px;
  margin-top: 4px;
  .ti-TreeItem-deep & {
    margin-top: 6px;
  }
  // border-radius: 0 0 $border-radius-md $border-radius-md;
}

.ti-TitleAndGrade {
  display: flex;
  align-items: baseline;
  gap: 10px;

  .ti-TreeItem-deep & {
    margin-top: 6px;
  }
}
.ti-Title {
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  overflow-wrap: break-word;
  white-space: normal;
  flex: 1 1 auto;

  color: $dark-1;
  --text-color: #{$dark-1};
  font-family: $system-ui;

  &:not(.ti-TreeItem-kr &) {
    font-weight: fw('semi-bold');
  }

  &:not(&-deep) {
    line-height: 28px;
  }

  &-deep {
    line-height: 20px;
  }
}

.ti-Grade-bigger {
  :deep(.okg-OkrGrade_Value-next) {
    font-size: $fs-20;
    line-height: 28px;
    max-width: 54px;
  }
}

.ti-AdditionalInfo {
  display: flex;
  gap: 8px;
  align-items: center;
  padding: $tree-item-padding;
  background-color: $grey-3-next;
  border-bottom-left-radius: $border-radius-lg-next;
  border-bottom-right-radius: $border-radius-lg-next;

  --item-background: #{$grey-2-next};

  .ti-TreeItem-kr & {
    padding-top: 14px;
    background-color: transparent;
    border-radius: 0;

    --overlay-color: #{$grey-3-next};
  }
}

.ti-Actions {
  display: flex;
  gap: 16px;
}

.ti-Actions_Menu {
  display: flex;
}

.ti-ExpandButton,
.ti-FilterButton {
  position: absolute;
  display: block;
  right: calc((v-bind(controlsButtonsLeftOffset) + #{$controls-buttons-size}) * -1);
  border-radius: 50%;
  width: $controls-buttons-size;
  height: $controls-buttons-size;
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: calc(50% - 1px);
    left: calc(v-bind(controlsButtonsLeftOffset) * -1);
    right: 0;
    height: 2px;
    background-color: inherit;
  }
}

.ti-ExpandButton {
  top: v-bind(expandButtonTopOffset);
  color: $white;
  background-color: var(--color, #{$primary-color-next});
}

.ti-FilterButton {
  top: v-bind(filterButtonTopOffset);
  background-color: $white;

  &:after {
    background-color: $grey-2-next;
    content: attr(data-items);
    position: absolute;
    font-style: normal;
    font-weight: fw('bold');
    font-size: $fs-12;
    line-height: 15px;
    top: 4px;
    color: $dark-3;
    min-width: 16px;
    min-height: 16px;
    padding: 0 4px;
    border-radius: 8px;
  }
}

.ti-Actions_More {
  &:not(&-opened) {
    background-color: transparent;
  }
}

.ti-CurrentTarget {
  text-align: right;
}

.ti-Divider {
  margin: 14px auto 0;
  width: calc(100% - (#{$tree-item-padding} * 2));

  .ti-TreeItem-detailed & {
    margin: 0 auto;
  }
}

.ti-AdditionalInfo_Groups {
  overflow: hidden;
}

.ti-AdditionalInfo_Date {
  margin-left: auto;
  flex: 0 0 auto;
  font-style: normal;
  font-weight: fw('regular');
  font-size: $fs-14;
  line-height: 20px;
  color: $dark-1;
}
</style>
