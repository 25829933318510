<template>
  <div v-if="(hideBulletsOnEmpty && totalCount > 1) || !hideBulletsOnEmpty" class="sb-Wrapper">
    <template v-if="totalCount > 1">
      <div
        v-for="n in totalCount"
        :key="n"
        :class="{
          'sb-Bullet-passed': n < currentStep,
          'sb-Bullet-active': n === currentStep
        }"
        class="sb-Bullet"
      />
    </template>
  </div>
</template>

<script setup>
defineOptions({
  name: 'StepBullets'
})

defineProps({
  totalCount: {
    type: Number,
    required: true,
    validator: v => v > 0 && Number.isInteger(v) && Number.isFinite(v)
  },

  currentStep: {
    type: Number,
    required: true,
    validator: (v, props) => {
      const isValid = v > 0 && Number.isInteger(v) && Number.isFinite(v) && v <= props.totalCount
      if (!isValid) {
        throw new Error(
          `Invalid prop: currentStep must be a number between 1 and ${props.totalCount}`
        )
      }
      return isValid
    }
  },

  hideBulletsOnEmpty: {
    type: Boolean
  }
})
</script>

<style lang="scss" scoped>
.sb-Wrapper {
  display: flex;
  gap: 4px;
  margin-inline: auto;
  height: 6px;
  width: var(--bulltes-width, 100%);
}

.sb-Bullet {
  height: inherit;
  border-radius: $border-radius-sm-next;
  flex: 1;
  position: relative;
  background-color: $grey-2-next;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: inherit;
    transition: width $transition-fast;
    background-color: $primary-color-next;
    border-radius: inherit;
  }

  &-passed,
  &-active {
    &:after {
      width: 100%;
    }
  }
}
</style>
