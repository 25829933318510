import amplitude from 'amplitude-js'
import dayjs from 'dayjs'
import { lowerFirst } from 'lodash'

const AMPLITUDE_API_KEY = 'c96c68486dfbbf780607d378475fca7b'

class Tracker {
  constructor() {
    this.key = AMPLITUDE_API_KEY
    this.tracker = amplitude.getInstance()
    this.identify = new amplitude.Identify()
  }

  init(pluginType, ISS) {
    this.pluginType = pluginType
    this.tracker.init(this.key, ISS, {
      logLevel: 'INFO'
    })
  }

  setUserData({
    instance,
    license,
    last_seen,
    account_id,
    onboarding,
    locale,
    source_platform,
    integration
  }) {
    const properties = {
      instance,
      license,
      last_seen,
      account_id,
      locale,
      source_platform,
      integration
    }
    if (onboarding) {
      properties.installation_date = dayjs().format()
    }
    this.tracker.setUserProperties(properties)
  }

  logEvent(eventName, props = {}) {
    if (!eventName) return

    const loverEventName = lowerFirst(eventName)

    this.tracker.logEvent(loverEventName, props)
  }
}

export const tracker = new Tracker()
