<template>
  <AppModalWithConfirmation
    :confirm-close="areDataChanged"
    :loading="isLoading"
    :show="show"
    :size="480"
    :title="modalTitle"
    class="lwtl-Modal"
    data-auto-testid="link-ws-to-level-modal"
    hide-hero
    @on-close="onClose"
    @on-confirm="onConfirm"
  >
    <GlobalGroupsWorkspaceSelect
      ref="workspaceSelectReference"
      v-model:selected-workspaces="workspacesToAdd"
      :already-added-workspaces="existedWorkspaces"
      hide-all-workspaces-option-in-selected-items-list
      hide-label
      show-on-init
    />

    <template #confirm-btn-text> {{ conFirmBtnText }}</template>
  </AppModalWithConfirmation>
</template>

<script setup>
import { isEmpty } from 'lodash'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import LevelsApiHandler from '@/api/level'
import { OKR_LEVEL_ENTITY_KEYS } from '@/utils/entity-keys'
import { handleError } from '@/utils/error-handling'
import { selectAllIsSelected } from '@/utils/select'

import AppModalWithConfirmation from '@/components/AppModalWithConfirmation'
import GlobalGroupsWorkspaceSelect from '@/components/global-groups/GlobalGroupsWorkspaceSelect'

defineOptions({
  name: 'LinkWorkspacesToLevelModal'
})

const props = defineProps({
  show: {
    type: Boolean
  },

  existedWorkspaces: {
    type: Array,
    default: () => []
  },

  level: {
    type: Object,
    required: true
  }
})

const workspacesToAdd = ref([])

const setDefaultState = () => {
  workspacesToAdd.value = []
}

const workspaceSelectReference = ref(null)

watch(
  () => props.show,
  async newValue => {
    if (!newValue) {
      setDefaultState()
    }
  }
)

const areDataChanged = computed(() => {
  return !isEmpty(workspacesToAdd.value)
})

const emit = defineEmits({
  'on-close': null,
  'on-workspaces-linked': null
})

const onClose = () => {
  emit('on-close')
}

const { t } = useI18n()

const isAllWorkspacesSelected = computed(() => {
  return selectAllIsSelected(workspacesToAdd.value)
})

const countOfWorkspacesForPluralize = computed(() => {
  if (isEmpty(workspacesToAdd.value)) {
    return 1
  }

  if (isAllWorkspacesSelected.value) {
    return 2
  }

  return workspacesToAdd.value.length
})

const modalTitle = computed(() => {
  return t(
    'levels.add_workspaces',
    { levelName: props.level[OKR_LEVEL_ENTITY_KEYS.NAME] },
    countOfWorkspacesForPluralize.value
  )
})

const conFirmBtnText = computed(() => {
  return t('levels.add_workspace', countOfWorkspacesForPluralize.value)
})

const isLoading = ref(false)

const onConfirm = async () => {
  if (isEmpty(workspacesToAdd.value)) {
    onClose()
  } else {
    const api = new LevelsApiHandler()

    const payload = {
      levelId: props.level[OKR_LEVEL_ENTITY_KEYS.ID],
      workspaceIds: isAllWorkspacesSelected.value ? [] : workspacesToAdd.value,
      addToAllWorkspaces: isAllWorkspacesSelected.value
    }
    try {
      isLoading.value = true
      await api.linkWorkspaceToLevel(payload)
      emit('on-workspaces-linked')
    } catch (error) {
      handleError({ error })
    } finally {
      isLoading.value = false
    }
  }
}
</script>

<style lang="scss" scoped></style>
