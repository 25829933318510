<template>
  <div
    v-if="showOption"
    :class="{ 'sflo-Option-selected': selected }"
    class="sflo-Option"
    @click.self="onFilterClick"
  >
    <SavedFiltersWarningIcon v-if="option.customFilter && option.hasUnexpectedCustomFields" />

    <div
      :title="option.showNativeTooltip ? option.name : null"
      class="sflo-Option_Name"
      @click.self="onFilterClick"
    >
      {{ option.name }}
    </div>
    <div class="sflo-Option_Actions">
      <MenuItemActions
        :item="option"
        :placement="placement"
        append-to="parent"
        form-append-to="parent"
      />
    </div>
  </div>
</template>

<script>
import { isEmpty } from 'lodash'
import { defineComponent } from 'vue'

import MenuItemActions from '@/components/AppMenu/MenuItemActions'
import { FILTERS, placementPropValidator } from '@/components/AppMenu/props-validators'
import SavedFiltersWarningIcon from '@/components/AppMenu/SavedFiltersWarningIcon'

export default defineComponent({
  name: 'SavedFiltersDropDownListOption',
  components: { SavedFiltersWarningIcon, MenuItemActions },
  props: {
    option: {
      type: Object,
      required: true
    },

    selected: {
      type: Boolean
    },

    placement: {
      type: String,
      default: FILTERS,
      validator: v => placementPropValidator(v)
    }
  },

  emits: {
    'update:model-value': null
  },

  computed: {
    showOption() {
      return !isEmpty(this.option)
    }
  },

  methods: {
    onFilterClick() {
      this.$emit('update:model-value', this.option.id)
    }
  }
})
</script>

<style lang="scss" scoped>
.sflo-Option {
  cursor: pointer;
  height: 32px;
  padding: 4px 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: flex-start;
  color: $dark-2;
  &-selected {
    background-color: rgba($primary-color-next, 0.15);
  }

  &:not(&-selected) {
    @media (any-hover: hover) {
      &:hover {
        background-color: $grey-3-next;
      }
    }
  }
}

.sflo-Option_Name {
  font-size: $fs-12;
  font-style: normal;
  font-weight: fw('regular');
  // max-width: 168px;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: normal;
}
.sflo-Option_Actions {
  margin-left: auto;
}
</style>
