<template>
  <div
    :class="{
      'cdth-HeaderCell-sorting': column.sortable
    }"
    class="cdth-HeaderCell"
    @click="changeSortBy(column)"
  >
    <slot :name="`column-title-${column.key}`">
      <span>{{ column.title }}</span>
    </slot>
    <AppIcon
      v-if="sortingColumns.includes(column.key)"
      :class="{
        'cdth-HeaderCell_DefaultStateSorting': defaultStateSorting(getSortIconName(column.key))
      }"
      :icon-name="getSortIconName(column.key)"
      height="20"
      width="20"
    />
  </div>
</template>

<script setup>
import { ref, watch } from 'vue'

import { ascSortValue, avgIncreaseValue, descSortValue } from '@/utils/sort-options'
import { TABLE_COLUMNS_KEYS } from '@/utils/table-columns'

import AppIcon from '@/components/ui/AppIcon/AppIcon'

defineOptions({
  name: 'CustomDashboardTableHeaderCell'
})

const SORT_TWO_WAYS_NEXT = 'sort-two-ways-next'
const props = defineProps({
  column: {
    type: Object,
    required: true
  },
  sortBy: {
    type: String,
    default: descSortValue
  },
  sortKey: {
    type: String,
    default: ''
  }
})

const changeSortBy = column => {
  if (!column.sortable) {
    return
  }
  sortByDefault()
}

const emit = defineEmits({
  'update:sortBy': null,
  'update:sortKey': null,
  'update:list': null
})
const updateSortBy = (sortBy, sortKey = props.column.key) => {
  localSortBy.value = sortBy
  emit('update:sortBy', sortBy)
  emit('update:sortKey', sortKey)
  emit('update:list')
}

const localSortBy = ref(props.sortBy)

const sortByDefault = () => {
  const sortBy = localSortBy.value === ascSortValue ? descSortValue : ascSortValue

  if (localSortBy.value === descSortValue && props.column.key === props.sortKey) {
    updateSortBy(null, null)
  } else {
    updateSortBy(sortBy)
  }
}

watch(
  () => props.sortKey,
  () => {
    if (props.sortKey !== props.column.key) {
      localSortBy.value = descSortValue
    }
  }
)
const defaultStateSorting = iconName => {
  return iconName === SORT_TWO_WAYS_NEXT
}
const sortingColumns = [
  TABLE_COLUMNS_KEYS.FAVORITES,
  TABLE_COLUMNS_KEYS.NAME,
  TABLE_COLUMNS_KEYS.OWNER,
  TABLE_COLUMNS_KEYS.VISIBILITY
]
const getSortIconName = key => {
  const iconArrowName =
    props.sortBy === ascSortValue || props.sortBy === avgIncreaseValue
      ? 'arrow-up-next'
      : 'arrow-down-next'
  return props.sortKey === key ? iconArrowName : SORT_TWO_WAYS_NEXT
}
</script>

<style lang="scss" scoped>
.cdth-HeaderCell {
  display: inline-flex;
  align-items: center;
  cursor: default;
  &-sorting {
    cursor: pointer;
  }
}
.cdth-HeaderCell_DefaultStateSorting {
  color: $grey-2-next;
}
</style>
