import { isEmpty } from 'lodash'

import ApiHandler from '@/api/base'
import {
  CUSTOM_FIELDS_PLACEMENTS,
  CUSTOM_FIELDS_PLACEMENTS_KEYS
} from '@/utils/custom-fields/factory'
import { normalizeCustomFieldData, normalizeCustomFieldsData } from '@/utils/custom-fields/helpers'
import { CUSTOM_FIELD_ENTITY_KEYS_IDS } from '@/utils/entity-keys'

class CustomFieldsApiHandler extends ApiHandler {
  async getCustomFields() {
    const { data } = await this.http.get('/customfields/admin')

    const { CONTENT, SIDEBAR } = CUSTOM_FIELDS_PLACEMENTS

    let normalizedResult = {
      [CUSTOM_FIELDS_PLACEMENTS_KEYS[CONTENT]]: [],
      [CUSTOM_FIELDS_PLACEMENTS_KEYS[SIDEBAR]]: []
    }

    if (!isEmpty(data)) {
      if (data[CONTENT] && !isEmpty(data[CONTENT])) {
        normalizedResult[CUSTOM_FIELDS_PLACEMENTS_KEYS[CONTENT]] = data[CONTENT]
      }

      if (data[SIDEBAR] && !isEmpty(data[SIDEBAR])) {
        normalizedResult[CUSTOM_FIELDS_PLACEMENTS_KEYS[SIDEBAR]] = data[SIDEBAR]
      }
    }

    return {
      [CUSTOM_FIELDS_PLACEMENTS_KEYS[CONTENT]]: normalizeCustomFieldsData({
        // customFields: cloneDeep(MOCKED_FIELDS.contentFields)
        customFields: normalizedResult.contentFields
      }),
      [CUSTOM_FIELDS_PLACEMENTS_KEYS[SIDEBAR]]: normalizeCustomFieldsData({
        // customFields: cloneDeep(MOCKED_FIELDS.sidebarFields)
        customFields: normalizedResult.sidebarFields
      })
    }
  }

  async createCustomField({
    typeId = null,
    name = null,
    elementTypeIds = [],
    workspaceIds = [],
    helpText = null,
    additionalParameters = [],
    active = true,
    required = false,
    filterable = false,
    sortable = false
  } = {}) {
    const { data } = await this.http.post('/customfields', {
      typeId,
      name: name.trim(),
      elementTypeIds,
      helpText: helpText ? helpText.trim() : null,
      active,
      required,
      filterable,
      sortable,
      workspaceIds,
      additionalParameters,
      additionalFields: additionalParameters
    })

    return data
  }

  async updateCustomField({
    id = null,
    name = null,
    workspaceIds = [],
    helpText = null,
    additionalParameters = [],
    active = true
  } = {}) {
    const { data } = await this.http.put(`/customfields/${id}`, {
      active,
      additionalFields: additionalParameters,
      name: name.trim(),
      helpText: helpText ? helpText.trim() : null,
      workspaceIds
    })

    return data
  }

  async updateCustomFieldActiveStatus({ fieldId, newValue }) {
    await this.http.patch(`/customfields/${fieldId}`, [
      {
        propertyId: CUSTOM_FIELD_ENTITY_KEYS_IDS.ACTIVE,
        newValue
      }
    ])
  }

  async updateCustomFieldOrder({ id, orderValue }) {
    const { data } = await this.http.patch(`/customfields/${id}`, [
      {
        propertyId: CUSTOM_FIELD_ENTITY_KEYS_IDS.ORDER_VALUE,
        newValue: orderValue
      }
    ])

    return data
  }

  async getCustomFieldById({ id } = {}) {
    const { data } = await this.http.get(`/customfields/${id}`)

    return normalizeCustomFieldData({ customField: data, keepAdditionalFieldsKey: true })
  }

  async getCustomFieldsData() {
    const { data: customFields } = await this.http.get('/customfields')
    return normalizeCustomFieldsData({ customFields })
  }

  async deleteCustomField({ id } = {}) {
    await this.http.delete(`/customfields/${id}`)
  }
}

export default CustomFieldsApiHandler
