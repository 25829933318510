<template>
  <AppDialog
    :show="show"
    :title="
      $t('confirm.admin.title', {
        userName: resolvedUserName
      })
    "
    :type="DIALOG_TYPES.DELETE"
    style="--title-white-space: normal"
    @on-confirm="onConfirm"
    @on-close="onClose"
  >
    <i18n-t keypath="confirm.admin.message" scope="global">
      <template #userName>
        <span class="adm-DangerText">
          {{ adminToDelete.displayName }}
        </span>
      </template>
    </i18n-t>
  </AppDialog>
</template>

<script>
import { isEmpty } from 'lodash'
import { defineComponent } from 'vue'

import { DIALOG_TYPES } from '@/utils/components-configurations/app-dialog'
import { objectOrNullProp } from '@/utils/prop-validators'

import AppDialog from '@/components/AppDialog'

export default defineComponent({
  name: 'AdminDeleteModal',

  components: {
    AppDialog
  },

  props: {
    show: {
      type: Boolean,
      default: false
    },

    adminToDelete: {
      required: true,
      validator: v => objectOrNullProp(v)
    }
  },

  emits: { 'on-confirm': null, 'on-close': null },

  computed: {
    DIALOG_TYPES: () => DIALOG_TYPES,

    resolvedUserName() {
      if (isEmpty(this.adminToDelete)) return this.$t('roles.user')

      return this.adminToDelete.displayName.split(' ')[0]
    }
  },

  methods: {
    onClose() {
      this.$emit('on-close')
    },

    onConfirm() {
      this.$emit('on-confirm')
    }
  }
})
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/modal-danger-text';

.adm-DangerText {
  @extend %modal-danger-text;
}
</style>
