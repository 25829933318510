<template>
  <FormItemsGroup
    v-if="!isEmpty(fieldsByPlacement) && !isEmpty(fieldsValues)"
    :class="{
      [`oef-Hub-${placementNames[placement]}`]: true
    }"
    :disabled="!userHasUpdateAccess"
    :no-divider="placement === CUSTOM_FIELDS_PLACEMENTS.CONTENT"
    class="oef-Hub"
  >
    <Component
      :is="VIEW_COMPONENTS_BY_TYPE_ID[field.typeId]"
      v-for="field in fieldsByPlacement"
      :key="field.id"
      :data-auto-testid="`custom-field-${field.id}`"
      :data-testid="`custom-field-${field.id}`"
      :field-id="field.id"
      :is-edit="isEdit"
      :model-value="fieldsValues[field.id]"
      :user-has-update-access="userHasUpdateAccess"
      :workspace-id="workspaceId"
      @update:model-value="onUpdateModelValue"
      @update-element="onUpdateElement"
    />
  </FormItemsGroup>
</template>

<script setup>
import { isEmpty } from 'lodash'
import { computed, nextTick, ref, watch } from 'vue'
import { useStore } from 'vuex'

import ObjectivesInfoApiHandler from '@/api/okr-elements'
import { ALL_CUSTOM_FIELDS, CUSTOM_FIELDS_PLACEMENTS } from '@/utils/custom-fields/factory'
import { getResolvedValueForUpdateCustomFieldValue } from '@/utils/custom-fields/helpers'
import { handleError } from '@/utils/error-handling'
import { checkSomeValueIsTruthy } from '@/utils/general'
import { numberOrNullProp, stringOrNullProp } from '@/utils/prop-validators'

import AssigneeField from '@/components/custom-fields/okr-elements-form/AssigneeField'
import DateField from '@/components/custom-fields/okr-elements-form/DateField'
import LinksList from '@/components/custom-fields/okr-elements-form/LinksField'
import LongText from '@/components/custom-fields/okr-elements-form/LongText'
import MoneyField from '@/components/custom-fields/okr-elements-form/MoneyField'
import MultiSelect from '@/components/custom-fields/okr-elements-form/MultiSelect'
import NumberField from '@/components/custom-fields/okr-elements-form/NumberField'
import SingleLineText from '@/components/custom-fields/okr-elements-form/SingleLineText'
import SingleSelect from '@/components/custom-fields/okr-elements-form/SingleSelect'
import FormItemsGroup from '@/components/form/FormItemsGroup'

defineOptions({
  name: 'OkrElementsFormCustomFieldsHub'
})

const props = defineProps({
  placement: {
    type: Number,
    default: CUSTOM_FIELDS_PLACEMENTS.CONTENT,
    validator: v => Object.values(CUSTOM_FIELDS_PLACEMENTS).includes(v)
  },

  userHasUpdateAccess: {
    type: Boolean
  },

  workspaceId: {
    type: [String, Number],
    required: true
  },

  isEdit: {
    type: Boolean
  },

  okrTypeId: {
    type: [String, Number],
    required: true
  },

  fieldsValues: {
    type: Object,
    required: true
  },

  elementId: {
    validator: v => numberOrNullProp(v) || stringOrNullProp(v),
    required: true
  }

  // fields: {
  //   type: Object,
  //   default: () => ({
  //     98: 'test'
  //   })
  // }
})

const emit = defineEmits({
  'update:loading': null,
  'update-field-value': null,
  'set-default-values': null,
  'update-activities': null
})

const placementNames = {
  [CUSTOM_FIELDS_PLACEMENTS.CONTENT]: 'content',
  [CUSTOM_FIELDS_PLACEMENTS.SIDEBAR]: 'sidebar'
}

const loadingStates = ref({})

const VIEW_COMPONENTS_BY_TYPE_ID = {
  [ALL_CUSTOM_FIELDS.getTypeIds().SINGLE_LINE_TEXT]: SingleLineText,
  [ALL_CUSTOM_FIELDS.getTypeIds().LONG_TEXT]: LongText,
  [ALL_CUSTOM_FIELDS.getTypeIds().MULTI_SELECT]: MultiSelect,
  [ALL_CUSTOM_FIELDS.getTypeIds().SINGLE_SELECT]: SingleSelect,
  [ALL_CUSTOM_FIELDS.getTypeIds().DATE]: DateField,
  [ALL_CUSTOM_FIELDS.getTypeIds().ASSIGNEE]: AssigneeField,
  [ALL_CUSTOM_FIELDS.getTypeIds().LINK]: LinksList,
  [ALL_CUSTOM_FIELDS.getTypeIds().NUMBER]: NumberField,
  [ALL_CUSTOM_FIELDS.getTypeIds().MONEY]: MoneyField
}

const fieldsByPlacement = computed(() => {
  return store.getters['customFields/fieldsByPlacement']({
    placement: props.placement,
    workspaceId: props.workspaceId,
    okrElementTypeId: props.okrTypeId
  })
})

const onUpdateModelValue = async ({ fieldId, value, updateElement = true, fieldTypeId = null }) => {
  const availableFieldIds = fieldsByPlacement.value.map(field => field.id)
  if (availableFieldIds.includes(fieldId)) {
    emit('update-field-value', {
      fieldId,
      value
    })
  }

  await nextTick()

  if (updateElement && props.isEdit) {
    await onUpdateElement(fieldId, fieldTypeId)
  }
}

const onUpdateElement = async (fieldId, fieldTypeId = null) => {
  if (props.isEdit) {
    const api = new ObjectivesInfoApiHandler()
    loadingStates.value[fieldId] = true

    const value = getResolvedValueForUpdateCustomFieldValue({
      value: props.fieldsValues[fieldId],
      fieldTypeId
    })

    try {
      const savedValue = await api.updateOkrElementCustomFieldValue({
        elementId: props.elementId,
        fieldId,
        value
      })

      emit('update-field-value', {
        fieldId,
        value: savedValue
      })
      emit('update-activities')
    } catch (error) {
      handleError({ error })
    } finally {
      loadingStates.value[fieldId] = false
    }
  }
}

const store = useStore()

// watch(
//   fieldsByPlacement,
//   newValue => {
//     if (!isEmpty(newValue)) {
//       loadingStates.value = Object.fromEntries(
//         Object.entries(newValue).map(([key]) => [key, false])
//       )
//     }
//   },
//   { immediate: true }
// )

const editDataAreSaving = computed(() => {
  return props.isEdit && checkSomeValueIsTruthy(...Object.values(loadingStates.value))
})

watch(editDataAreSaving, newValue => {
  emit('update:loading', newValue)
})
</script>

<style lang="scss" scoped>
.oef-Hub {
  &-content {
    --group-padding: 18px;
    margin-top: 8px;
  }
}
</style>
