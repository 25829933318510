import ApiHandler from '@/api/base'

class JiraDashboardApiHandler extends ApiHandler {
  async createFilterViewForDashboard({ dashboardId, filters, name }) {
    const { data } = await this.http.post('/createFilterViewForDashboard', {
      dashboardId,
      filters,
      name
    })
    return data
  }

  async updateFilterViewForDashboard({ dashboardId, filters, name }) {
    const { data } = await this.http.post('/updateFilterViewForDashboard', {
      dashboardId,
      filters,
      name
    })
    return data
  }

  async getFiltersForDashboard(dashboardId) {
    const { data } = await this.http.post('/getFiltersForDashboard', {
      dashboardId
    })
    return data
  }
}

export default JiraDashboardApiHandler
