<template>
  <FormFieldNext :label="$t('levels.icon')">
    <AppDroplist
      v-if="displayAsDropdown"
      v-model="iconPickerOpened"
      :disabled="loading"
      :offset="[0, 0]"
      max-width="unset"
      position="bottom-start"
    >
      <template #button="{ expanded }">
        <SkeletonItem v-if="loading" class="gip-Loader" height="100%" width="100%" />
        <div
          :class="{
            'gip-IconSelectTrigger-opened': expanded
          }"
          :style="{}"
          class="gip-IconSelectTrigger"
        >
          <GroupIcon :icon-name="selectedIcon" color="transparent" height="24" width="24" />
          <AppIcon
            class="gip-IconSelectTriggerChevron"
            height="24"
            icon-name="arrow-down-black"
            width="24"
          />
        </div>
      </template>

      <portal-target name="icons-dropdown-content" />
    </AppDroplist>

    <portal :disabled="!displayAsDropdown" to="icons-dropdown-content">
      <div
        :style="{
          '--selected-color': selectedColor
        }"
        class="gip-IconsMenu"
      >
        <GroupIcon
          v-for="icon in result"
          :key="icon"
          :class="{
            'gip-Icon-selected': icon === selectedIcon
          }"
          :color="icon === selectedIcon ? selectedColor : 'transparent'"
          :icon-name="icon"
          class="gip-Icon"
          clickable
          @click="onIconClick(icon)"
        />
      </div>
    </portal>
  </FormFieldNext>
</template>

<script setup>
import { ref } from 'vue'

import { GLOBAL_GROUPS_ICON_PREFIX, GROUPS_CATEGORIZED_COLORS } from '@/utils/global-groups'

import AppDroplist from '@/components/AppDroplist'
import FormFieldNext from '@/components/form/FormFieldNext'
import GroupIcon from '@/components/global-groups/GroupIcon'
import AppIcon from '@/components/ui/AppIcon/AppIcon'
import SkeletonItem from '@/components/ui/SkeletonLoaders/SkeletonItem'

defineOptions({
  name: 'GroupIconPicker'
})

const requireAll = requireContext => requireContext.keys().map(requireContext)
const globalGroupsIcons = require.context('@/assets/icons/global-groups/', false, /\.svg$/)

const result = requireAll(globalGroupsIcons).map(icon => {
  const SPRITE_ICON_PREFIX = 'icon-'
  const COMPOSED_ICON_PREFIX = `${SPRITE_ICON_PREFIX}${GLOBAL_GROUPS_ICON_PREFIX}`
  if (!icon.default.id.startsWith(COMPOSED_ICON_PREFIX)) {
    const realIconName = icon.default.id.replace(SPRITE_ICON_PREFIX, '')
    throw new Error(
      `

      Invalid icon name → ${realIconName}
      Global group icons must be named with pattern: "${GLOBAL_GROUPS_ICON_PREFIX}[index]"
      `
    )
  }
  return icon.default.id.replace(COMPOSED_ICON_PREFIX, '')
})

const iconPickerOpened = ref(false)

const emit = defineEmits({
  'update:selectedIcon': null
})
const onIconClick = icon => {
  emit('update:selectedIcon', icon)
  iconPickerOpened.value = false
}

defineProps({
  selectedIcon: {
    type: [String, Number],
    required: true
  },

  selectedColor: {
    type: String,
    default: 'transparent',
    validator: v => ['transparent', ...Object.values(GROUPS_CATEGORIZED_COLORS).flat()].includes(v)
  },

  displayAsDropdown: {
    type: Boolean,
    default: true
  },

  loading: {
    type: Boolean
  }
})
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/palette-picker-styles';

.gip-Loader {
  position: absolute;
  left: 0;
  top: 0;
}

.gip-IconSelectTrigger {
  @extend %palette-picker-trigger;
  color: $dark-2;
  justify-content: space-between;
  border-color: $grey-2-next;

  &-opened {
    border-color: $primary-color-next;
  }
}

.gip-IconSelectTriggerChevron {
  transition: transform $transition-fast;
  .gip-IconSelectTrigger-opened & {
    transform: rotate(180deg);
  }
}

.gip-IconsMenu {
  @extend %palette-dropdown-content;
  color: $dark-2;
  grid-template-columns: repeat(var(--items-per-row, 8), 1fr);
}

.gip-Icon {
  cursor: pointer;
  flex-shrink: 0;
  transition: background-color $transition-fast;
  &:not(&-selected) {
    &:hover {
      background-color: $grey-2-next;
    }
  }
}
</style>
