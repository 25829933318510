<template>
  <AppSelect
    :data-auto-testid="SINGLE_SELECT_FORM_FIELD_TEST_ID"
    :data-testid="SINGLE_SELECT_FORM_FIELD_TEST_ID"
    :disabled="!userHasUpdateAccess"
    :model-value="modelValue"
    :offset="[0, 0]"
    :options="options"
    :search-function="value => localSearch({ value, options })"
    dropdown-search
    item-label="name"
    item-value="id"
    skeleton-loader
    @update:model-value="onUpdateModelValue"
  >
    <template #button="{ option, active }">
      <OkrFormFieldSelectTriggerNext
        :empty-state-label="emptyStateLabel"
        :label="fieldName"
        :opened="active"
        :selected-options="getSelectedOptions(option)"
        separated-label
        truncated-label
      >
        <template #label="{ resolvedLabel }">
          <CustomFieldLabel :field-id="fieldId"> {{ resolvedLabel }} </CustomFieldLabel>
        </template>
      </OkrFormFieldSelectTriggerNext>
    </template>
  </AppSelect>
</template>
<script setup>
import { isNull } from 'lodash'

import { SINGLE_SELECT_FORM_FIELD_TEST_ID } from '@/utils/custom-fields/jest-helpers'
import { useCustomFieldsHelpers } from '@/utils/custom-fields/use-custom-fields'
import { localSearch } from '@/utils/objectives'
import { numberOrNullProp, stringOrNullProp } from '@/utils/prop-validators'

import CustomFieldLabel from '@/components/custom-fields/okr-elements-form/CustomFieldLabel'
import AppSelect from '@/components/ui/AppSelect/AppSelect'
import OkrFormFieldSelectTriggerNext from '@/components/ui/AppSelect/TriggerButtons/OkrFormFieldSelectTriggerNext'

defineOptions({
  name: 'SingleSelect',
  inheritAttrs: false
})

const props = defineProps({
  userHasUpdateAccess: {
    type: Boolean,
    required: true
  },

  fieldId: {
    type: [String, Number],
    required: true
  },

  modelValue: {
    required: true,
    validator: v => stringOrNullProp(v) || numberOrNullProp(v)
  }
})

const { options, fieldName, emptyStateLabel } = useCustomFieldsHelpers({
  fieldId: props.fieldId
})

const emit = defineEmits({
  'update:model-value': null
})
const onUpdateModelValue = value => {
  emit('update:model-value', {
    fieldId: props.fieldId,
    value
  })
}

const getSelectedOptions = option => {
  if (!option) {
    return null
  }

  if (isNull(option.id)) {
    return null
  }

  return option.name
}
</script>

<style lang="scss" scoped></style>
