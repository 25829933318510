import { inRange, isEmpty, isFunction, isNull, isUndefined, memoize } from 'lodash'

import { TRACKING_UNKNOWN } from '@/tracking/amplitude-helpers'
import { KR_KEY } from '@/utils/dashboard'
import { MENU_ITEMS_GROUPS } from '@/utils/dropdown-menu'
import { ASSIGNEE_ENTITY_KEYS } from '@/utils/entity-keys'
import { memoizeGetCssVariableValue, memoizeHexToRgb } from '@/utils/memoizations'
import { OBJECTIVE_TYPES } from '@/utils/objective-types'
import { FILTERS_KEYS } from '@/utils/okr-elements/filters'
import { isWhiteColor } from '@/utils/okr-levels'
import { UID_MAX_LENGTH } from '@/utils/uid'

export const AUTO_GRADE_OPTION_VALUE = 4

export const KR_VALUE_TYPES = {
  NUMBER: 1,
  PERCENT: 2,
  BINARY: 3
}

export const OKR_STATUSES = {
  AUTO: 0,
  ON_TRACK: 1,
  BEHIND: 2,
  AT_RISK: 3,
  NOT_STARTED: 4,
  CLOSED: 5,
  ABANDONED: 6,
  BACKLOG: 9
}

export const OKR_STATUSES_DISABLED = [OKR_STATUSES.CLOSED, OKR_STATUSES.ABANDONED]

export const isOkrElementClosed = objective =>
  OKR_STATUSES_DISABLED.includes(objective.confidenceLevelId)

export const ROLE_IDS = {
  OWNER: 1,
  STAKEHOLDER: 2
}

export const NOT_STARTED_OPTION = {
  label: 'dashboard.not_started',
  value: 4,
  icon: 'okr-status-circle',
  color: 'var(--grade-abandoned-color)',
  colorVariable: '--grade-abandoned-color',
  hex: '#6B778C'
}

export const OBJECTIVE_STATUS_OPTIONS = [
  NOT_STARTED_OPTION,
  {
    label: 'dashboard.on_track',
    value: 1,
    icon: 'okr-status-circle',
    color: 'var(--grade-high-color-next)',
    colorVariable: '--grade-high-color-next',
    backgroundColor: 'transparent',
    hex: '#36B27E'
  },
  {
    label: 'dashboard.behind',
    value: 2,
    icon: 'okr-status-circle',
    color: 'var(--grade-medium-color-next)',
    colorVariable: '--grade-medium-color-next',
    backgroundColor: 'transparent',
    hex: '#FF8541'
  },
  {
    label: 'dashboard.at_risk',
    value: 3,
    icon: 'okr-status-circle',
    color: 'var(--grade-low-color-next)',
    colorVariable: '--grade-low-color-next',
    backgroundColor: 'transparent',
    hex: '#F64963'
  }
].map(option => {
  const hex = memoizeGetCssVariableValue(option.colorVariable, option.hex)
  const rgbColor = memoizeHexToRgb(hex.trim())
  return {
    ...option,
    rgbColor
  }
})

const CLOSED_STATUS_LABEL = 'dashboard.closed'
const ABANDONED_STATUS_LABEL = 'dashboard.abandoned'

export const ADDITIONAL_OBJECTIVE_STATUS_OPTIONS = [
  {
    label: CLOSED_STATUS_LABEL,
    value: 5,
    icon: 'okr-status-circle',
    color: 'var(--grade-closed-color)',
    colorVariable: '--grade-closed-color',
    backgroundColor: '#42526E26',
    hex: '#42526E',
    borderColor: 'transparent'
  },
  {
    label: ABANDONED_STATUS_LABEL,
    value: 6,
    icon: 'okr-status-circle',
    color: 'var(--grade-abandoned-color)',
    colorVariable: '--grade-abandoned-color',
    backgroundColor: '#6B778C1A',
    hex: '#6B778C',
    borderColor: 'transparent'
  }
].map(option => {
  const hex = memoizeGetCssVariableValue(option.colorVariable, option.hex)
  const rgbColor = memoizeHexToRgb(hex.trim())
  return {
    ...option,
    rgbColor
  }
})

export const REOPEN_OBJECTIVE_STATUS_OPTIONS = [
  {
    label: 'dashboard.reopen',
    value: OKR_STATUSES.AUTO,
    icon: 'period-mode-auto-next',
    color: 'var(--grade-closed-color)',
    colorVariable: '--grade-closed-color',
    backgroundColor: '#42526e'
  }
].map(option => {
  const hex = memoizeGetCssVariableValue(option.colorVariable, option.hex)
  const rgbColor = memoizeHexToRgb(hex.trim())
  return {
    ...option,
    rgbColor
  }
})

export const BACKLOG_STATUS_VALUE = 9
export const OBJECTIVE_STATUS_BACKLOG = {
  value: BACKLOG_STATUS_VALUE,
  icon: 'status-none',
  label: 'status.backlog',
  color: 'var(--dark-3)',
  colorVariable: '--dark-3',
  hex: '#6B778C'
}

export const BACKLOG_OBJECTIVE_STATUS_OPTIONS = [OBJECTIVE_STATUS_BACKLOG].map(option => {
  const hex = memoizeGetCssVariableValue(option.colorVariable, option.hex)
  const rgbColor = memoizeHexToRgb(hex.trim())
  return {
    ...option,
    rgbColor
  }
})

export const ALL_STATUS_OPTIONS = [
  ...OBJECTIVE_STATUS_OPTIONS,
  ...ADDITIONAL_OBJECTIVE_STATUS_OPTIONS
]

export const OKR_VIEW_PAGES = {
  OKREXPLORER: 'OKREXPLORER',
  ALIGNMENT: 'ALIGNMENT',
  MINDMAP: 'MINDMAP',
  ROADMAP: 'ROADMAP'
}

export const OKR_VIEW_PAGES_IDS = Object.fromEntries(
  Object.entries({
    ...OKR_VIEW_PAGES
  }).map(([key, val]) => {
    const resolvedVal = key === OKR_VIEW_PAGES.OKREXPLORER ? val.slice(3) : val
    return [key, resolvedVal.toLowerCase()]
  })
)

export const objectiveStatusAutocalculatedOptions = OBJECTIVE_STATUS_OPTIONS.map(option => ({
  ...option,
  icon: `${option.icon}-based`,
  value: -option.value
}))

export const additionalObjectiveStatusAutocalculatedOptions =
  ADDITIONAL_OBJECTIVE_STATUS_OPTIONS.map(option => ({
    ...option,
    value: -option.value
  }))

export const backlogObjectiveStatusAutocalculatedOptions = BACKLOG_OBJECTIVE_STATUS_OPTIONS.map(
  option => ({
    ...option,
    value: -option.value
  })
)

export const OBJECTIVE_STATUS_AUTO = {
  value: OKR_STATUSES.AUTO,
  icon: 'status-none',
  label: 'status.switch_auto',
  color: 'var(--dark-3)',
  colorVariable: '--dark-3',
  rgbColor: '107, 119, 140'
}

export const OBJECTIVE_STATUS_ALL_OPTIONS = [
  ...OBJECTIVE_STATUS_OPTIONS,
  ...objectiveStatusAutocalculatedOptions,
  OBJECTIVE_STATUS_AUTO,
  ...BACKLOG_OBJECTIVE_STATUS_OPTIONS,
  ...ADDITIONAL_OBJECTIVE_STATUS_OPTIONS,
  ...REOPEN_OBJECTIVE_STATUS_OPTIONS,
  ...additionalObjectiveStatusAutocalculatedOptions,
  ...backlogObjectiveStatusAutocalculatedOptions
]

export const calculateObjectiveStatus = (confidenceLevelId, automaticConfidenceLevelId) => {
  let value = confidenceLevelId || OKR_STATUSES.AUTO
  if (!value) {
    value = automaticConfidenceLevelId ? -automaticConfidenceLevelId : OKR_STATUSES.AUTO
  }
  return value
}

export const OBJECTIVE_SORT_OPTIONS = {
  ORDER_ASC: 1,
  ORDER_DESC: 2,
  LEVEL_ASC: 3,
  LEVEL_DESC: 4,
  ASSIGNEE_ASC: 5,
  ASSIGNEE_DESC: 6,
  GROUPS_ASC: 7,
  GROUPS_DESC: 8,
  STATUS_ASC: 9,
  STATUS_DESC: 10,
  DUE_DATE_ASC: 11,
  DUE_DATE_DESC: 12,
  START_DATE_ASC: 23,
  START_DATE_DESC: 24,
  GRADE_ASC: 15,
  GRADE_DESC: 16,
  NAME_ASC: 17,
  NAME_DESC: 18,
  TYPE_ASC: 19,
  TYPE_DESC: 20,
  LABELS_ASC: 21,
  LABELS_DESC: 22,
  CREATE_DATE_ASC: 13,
  CREATE_DATE_DESC: 14
}

export const WORKSPACE_USER_ROLES = {
  USER: 1,
  TEAM_LEAD: 2,
  WORKSPACE_ADMIN: 3,
  USER_NO_DELETE: 4,
  VIEWER: 5,
  NO_ACCESS: 6
}

export const findGraterRole = ({ roles = [] } = {}) => {
  const ROLES_WEIGHTS = {
    [WORKSPACE_USER_ROLES.NO_ACCESS]: 1,
    [WORKSPACE_USER_ROLES.VIEWER]: 2,
    [WORKSPACE_USER_ROLES.USER_NO_DELETE]: 3,
    [WORKSPACE_USER_ROLES.USER]: 4,
    [WORKSPACE_USER_ROLES.TEAM_LEAD]: 5,
    [WORKSPACE_USER_ROLES.WORKSPACE_ADMIN]: 6
  }

  return roles.reduce((acc, role) => {
    return ROLES_WEIGHTS[role] > ROLES_WEIGHTS[acc] ? role : acc
  })
}

export const OKR_ELEMENT_PARAMETERS_SAVING_STATUSES = {
  TYPE: 'typeIsSaving',
  OWNER: 'ownerIsSaving',
  STAKEHOLDERS: 'stakeholdersIsSaving',
  GROUP: 'groupIsSaving',
  LABELS: 'labelIsSaving',
  DUE_DATE: 'dueDateIsSaving',
  START_DATE: 'startDateIsSaving',
  INTERVAL: 'intervalIsSaving',
  PARENT: 'parentObjectiveIsSaving',
  CONTRIBUTE: 'contributeIsSaving',
  NAME: 'nameIsSaving',
  PERIOD_MODE: 'periodModeIsSaving',
  STATUS: 'statusIsSaving',
  DESCRIPTION: 'descriptionIsSaving'
}

export const getObjectiveIconName = objective => {
  // handle jira issue
  if (objective && objective.issueType) {
    return `jira-${objective.issueType.toLowerCase()}`
  }

  if (!(objective && objective.typeId)) {
    return TRACKING_UNKNOWN
  }

  const typeName = Object.keys(OBJECTIVE_TYPES).find(
    type => OBJECTIVE_TYPES[type] === objective.typeId
  )

  const objectiveIsAvailable = currentUserCanReadObjective(objective)

  let iconName = `${typeName.toLowerCase()}-objective`
  if (!objectiveIsAvailable) {
    iconName += '-unavailable'
  }
  return iconName
}

export const getObjectiveTypeName = (id, defaultValue = '') => {
  const cases = {
    [OBJECTIVE_TYPES.PERSONAL]: 'Personal',
    [OBJECTIVE_TYPES.GROUP]: 'Group',
    [OBJECTIVE_TYPES.COMPANY]: 'Company',
    [OBJECTIVE_TYPES.KR]: 'KR',
    [OBJECTIVE_TYPES.TASK]: 'Jira Issue'
  }
  return cases[id] || defaultValue
}

export const objectiveIsJiraTask = objective => {
  return objective.typeId === OBJECTIVE_TYPES.TASK
}

export const isKR = objective => {
  return objective.typeId === OBJECTIVE_TYPES.KR
}

// Permissions

export const OKR_ELEMENT_PERMISSIONS = {
  READ: 'READ',
  UPDATE: 'UPDATE',
  REMOVE: 'REMOVE',
  COPY: 'COPY'
}

const { READ, UPDATE, REMOVE, COPY } = OKR_ELEMENT_PERMISSIONS

const permissionsCacheKeyResolver = objective => {
  return objective.permissions.join('|')
}

export const currentUserCanReadObjective = memoize(objective => {
  return objective.permissions.includes(READ)
}, permissionsCacheKeyResolver)

export const currentUserCanUpdateObjective = memoize(objective => {
  return objective.permissions.includes(UPDATE)
}, permissionsCacheKeyResolver)

export const currentUserCanCopyObjective = memoize(objective => {
  return objective.permissions.includes(COPY)
}, permissionsCacheKeyResolver)

export const currentUserCanDeleteObjective = memoize(objective => {
  return objective.permissions.includes(REMOVE)
}, permissionsCacheKeyResolver)

export const currentUserCanCreateObjective = (workspace, isPluginAdmin = undefined) => {
  if (isPluginAdmin) {
    return true
  }
  return workspace && workspace.userRoleId !== WORKSPACE_USER_ROLES.VIEWER
}

/* Check whether kr is editable */
export const okrElementGradeIsEditable = objective => {
  return !objective?.existContributeChildren && currentUserCanUpdateObjective(objective)
}

// objective views
export const getExpandedItemList = expandedItemsObj => {
  return Object.entries(expandedItemsObj).reduce((acc, [key, value]) => {
    if (value) {
      acc.push(key)
    }
    return acc
  }, [])
}

export const findIntervalNameById = (intervals, intervalId) => {
  const interval = intervals.find(item => item.id === intervalId)
  if (interval) {
    return interval.name
  }
  return TRACKING_UNKNOWN
}

export const OKR_ELEMENTS_TABLE_ADD_MENU_ITEMS = {
  CREATE_OBJECTIVE: {
    name: 'create_objective',
    action: 'create'
  },
  CREATE_KR: {
    name: 'create_kr'
  },
  LINK_TASK: {
    name: 'link_task',
    action: 'link'
  },
  CREATE_JIRA_ISSUE: {
    name: 'create_jira_issue',
    action: 'create'
  }
}

export const OKR_ELEMENTS_TABLE_ACTIONS_MENU_ITEMS = {
  REMOVE: {
    name: 'remove',
    action: 'delete'
  },
  EDIT: {
    name: 'edit',
    action: 'edit'
  },
  COPY: {
    name: 'copy',
    action: 'duplicate'
  },
  WEIGHTS: {
    name: 'weights',
    action: 'edit-weights'
  },
  MOVE: {
    name: 'move',
    action: 'move'
  }
}

// ui helpers

const { CREATING, EDITING } = MENU_ITEMS_GROUPS

const taskMenuItemsCacheKeyResolver = ({
  isJiraApp,
  isWebApp,
  isSalesforceApp,
  isJiraConnected,
  isOkrElementClosed,
  hasAccessToJira
}) => {
  return `is-jira-app-${isJiraApp}|is-web-app-${isWebApp}|is-salesforce-app${isSalesforceApp}|is-jira-connected-${isJiraConnected}|is-okr-element-closed-${isOkrElementClosed}|has-access-to-jira-${hasAccessToJira}`
}

export const getTaskMenuItems = memoize(
  ({
    isJiraApp,
    isWebApp,
    isJiraConnected,
    isOkrElementClosed,
    hasAccessToJira,
    isSalesforceApp
  }) => {
    const linkJiraIssueMenuItem = {
      id: OKR_ELEMENT_FORM_MENU_ACTIONS.LINK_TASK,
      title: 'action.link_jira_issue',
      icon: 'link-task',
      disabled: isOkrElementClosed
    }

    const createJiraIssueMenuItem = {
      id: OKR_ELEMENT_FORM_MENU_ACTIONS.CREATE_JIRA_ISSUE,
      title: 'action.create_jira_issue',
      icon: 'create-task',
      disabled: isOkrElementClosed
    }

    const result = []

    if (isJiraApp) {
      result.push(linkJiraIssueMenuItem, createJiraIssueMenuItem)
      return result
    }

    if (isWebApp) {
      return isJiraConnected && hasAccessToJira ? [linkJiraIssueMenuItem] : []
    }

    if (isSalesforceApp) {
      return []
    }
  },
  taskMenuItemsCacheKeyResolver
)

const menuAddItemsCacheKeyResolver = ({
  objective,
  objectiveLevels,
  allowLinkOkrElementsToKr,
  isJiraApp,
  isWebApp,
  isSalesforceApp,
  isJiraConnected,
  hasAccessToJira
}) => {
  const levelsKey = objectiveLevels
    .map(({ id, prefix, name, color }) => [id, prefix, name, color])
    .join('|')
  return `typeId-${objective.typeId}|confidenceLevelId-${objective.confidenceLevelId}|levels-${levelsKey}|allow-link-okr-elements-${allowLinkOkrElementsToKr}|is-jira-app-${isJiraApp}|is-web-app-${isWebApp}|is-salesforce-app${isSalesforceApp}|is-jira-connected-${isJiraConnected}|has-access-to-jira-${hasAccessToJira}`
}

const createMenuItemFromOkrLevel = memoize(level => {
  const { id, name, color, ...rest } = level

  const resolvedColor = isWhiteColor(color) ? 'fff' : color

  return {
    ...rest,
    id,
    color: resolvedColor,
    label: name,
    name: id,
    group: CREATING
  }
})
export const getObjectiveAddMenuItems = memoize(
  ({
    objective,
    objectiveLevels,
    allowLinkOkrElementsToKr,
    isJiraApp,
    isWebApp,
    isSalesforceApp,
    isJiraConnected,
    hasAccessToJira
  }) => {
    const result = []

    const MIN_LEVELS_COUNT = 2
    const MAX_LEVELS_COUNT = 9

    const isKrElement = isKR(objective)
    const isTaskElement = objectiveIsJiraTask(objective)
    const isObjectiveElement = !isKrElement && !isTaskElement

    const createObjectiveMenuItem = {
      name: OKR_ELEMENTS_TABLE_ADD_MENU_ITEMS.CREATE_OBJECTIVE.name,
      title: 'action.create_objective',
      icon: 'company-objective-next',
      group: CREATING
    }

    const createKrMenuItem = {
      name: OKR_ELEMENTS_TABLE_ADD_MENU_ITEMS.CREATE_KR.name,
      title: 'action.create_keyResult',
      icon: 'kr-objective-next',
      group: CREATING
    }

    const createObjectiveWithNestedMenuItem = {
      name: OKR_ELEMENTS_TABLE_ADD_MENU_ITEMS.CREATE_OBJECTIVE.name,
      title: 'action.create_objective',
      icon: 'dropdown-nested-item',
      group: CREATING
    }

    const linkJiraIssueMenuItem = {
      name: OKR_ELEMENTS_TABLE_ADD_MENU_ITEMS.LINK_TASK.name,
      title: 'action.link_jira_issue',
      icon: 'link-task',
      group: CREATING,
      disabled: isOkrElementClosed(objective)
    }

    const createJiraIssueMenuItem = {
      name: OKR_ELEMENTS_TABLE_ADD_MENU_ITEMS.CREATE_JIRA_ISSUE.name,
      title: 'action.create_jira_issue',
      icon: 'create-task',
      group: CREATING,
      disabled: isOkrElementClosed(objective)
    }

    if (isObjectiveElement || (isKrElement && allowLinkOkrElementsToKr)) {
      const levelsCount = objectiveLevels.length
      const isNestedLevelMenuNeed = inRange(levelsCount, MIN_LEVELS_COUNT, MAX_LEVELS_COUNT) // that means that we have levels count between >= MIN and < MAX
      const isOnlyOneLevel = levelsCount === 1
      if (isOnlyOneLevel) {
        const [level] = objectiveLevels
        result.push({
          ...createMenuItemFromOkrLevel(level)
        })
      } else if (isNestedLevelMenuNeed) {
        result.push({
          ...createObjectiveWithNestedMenuItem,
          nestedItems: objectiveLevels.map(level => {
            return createMenuItemFromOkrLevel(level)
          })
        })
        // const levelsMenuItems = objectiveLevels.map(level => {
        //   return createMenuItemFromOkrLevel(level)
        // })
        // result.push(...levelsMenuItems)
      } else {
        result.push({
          ...createObjectiveMenuItem
        })
      }

      result.push({
        ...createKrMenuItem
      })
    }

    if (!isTaskElement) {
      if (isJiraApp) {
        result.push(linkJiraIssueMenuItem, createJiraIssueMenuItem)
      }

      if ((isWebApp || isSalesforceApp) && isJiraConnected && hasAccessToJira) {
        result.push(linkJiraIssueMenuItem)
      }
    }

    return result
  },
  menuAddItemsCacheKeyResolver
)

const menuActionsCacheKeyResolver = (
  objective,
  isWeightsActionDisabled = false,
  weightTriggerTooltip = null
) => {
  const { id, permissions, childCount, typeId, confidenceLevelId } = objective
  return `${id}|${typeId}|${childCount}|${confidenceLevelId}|${permissions.join(
    '|'
  )}|${isWeightsActionDisabled}|${weightTriggerTooltip}`.trim()
}

export const getObjectiveActionsMenuItems = memoize(
  (objective, isWeightsActionDisabled = false, weightTriggerTooltip = null) => {
    const result = []
    if (currentUserCanUpdateObjective(objective)) {
      result.push({
        name: OKR_ELEMENTS_TABLE_ACTIONS_MENU_ITEMS.EDIT.name,
        title: 'action.edit',
        icon: 'edit-next',
        group: EDITING
      })

      if (!objectiveIsJiraTask(objective)) {
        result.push({
          name: OKR_ELEMENTS_TABLE_ACTIONS_MENU_ITEMS.WEIGHTS.name,
          title: 'dropdown.weights',
          icon: 'custom-weights',
          group: EDITING,
          disabled: isWeightsActionDisabled,
          tooltipContent: weightTriggerTooltip
        })
      }
    }

    if (objective.typeId !== OBJECTIVE_TYPES.TASK && currentUserCanCopyObjective(objective)) {
      result.push({
        name: OKR_ELEMENTS_TABLE_ACTIONS_MENU_ITEMS.COPY.name,
        title: 'dropdown.copy',
        icon: 'duplicate-next',
        group: EDITING
      })
    }

    if (
      currentUserCanUpdateObjective(objective) &&
      objective.typeId !== OBJECTIVE_TYPES.TASK &&
      objective.typeId !== OBJECTIVE_TYPES.KR
    ) {
      result.push({
        name: OKR_ELEMENTS_TABLE_ACTIONS_MENU_ITEMS.MOVE.name,
        title: 'action.move',
        icon: 'move-next',
        group: EDITING,
        disabled: isOkrElementClosed(objective)
      })
    }

    if (currentUserCanDeleteObjective(objective)) {
      const isJiraIssue = objectiveIsJiraTask(objective)

      result.push({
        name: OKR_ELEMENTS_TABLE_ACTIONS_MENU_ITEMS.REMOVE.name,
        title: isJiraIssue ? 'action.unlink_issue' : 'action.delete',
        icon: isJiraIssue ? 'unlink-next' : 'delete-next',
        group: MENU_ITEMS_GROUPS.REMOVING,
        color: 'var(--grade-low-color-next)'
      })
    }

    return result
  },

  menuActionsCacheKeyResolver
)

export const saveUpdatedElementParameters = (listState, updatedElementParameters) => {
  updatedElementParameters.forEach(updatedOkrElementInfo => {
    const { weight, weightMultiplier, elementId } = updatedOkrElementInfo
    listState.okrElements[elementId] = {
      ...listState.okrElements[elementId],
      predictedGrade: updatedOkrElementInfo.predictedGrade,
      grade: updatedOkrElementInfo.updatedGrade,
      gradeToUse: updatedOkrElementInfo.updatedGrade,
      gradeColor: updatedOkrElementInfo.updatedColor,
      confidenceLevelId: updatedOkrElementInfo.confidenceLevelId,
      automaticConfidenceLevelId: updatedOkrElementInfo.automaticConfidenceLevelId,
      existContributeChildren: updatedOkrElementInfo.existContributeChildren,
      lastGradeUpdateDate: updatedOkrElementInfo.lastGradeUpdateDate
    }

    if (!isUndefined(weight)) {
      listState.okrElements[elementId].weight = weight
    }

    if (!isUndefined(weightMultiplier)) {
      listState.okrElements[elementId].weightMultiplier = weightMultiplier
    }
  })
}

export const saveUpdatedChildParameters = (elems, updatedElementParameters) => {
  updatedElementParameters.forEach(
    ({
      confidenceLevelId,
      automaticConfidenceLevelId,
      elementId,
      existContributeChildren,
      lastGradeUpdateDate,
      predictedGrade,
      updatedColor,
      updatedGrade
    }) => {
      const child = elems.find(child => child.id === elementId)
      if (child) {
        child.automaticConfidenceLevelId = automaticConfidenceLevelId
        child.confidenceLevelId = confidenceLevelId
        child.existContributeChildren = existContributeChildren
        child.lastGradeUpdateDate = lastGradeUpdateDate
        child.predictedGrade = predictedGrade
        child.updatedColor = updatedColor
        child.updatedGrade = updatedGrade
      }
    }
  )
}

export const updateDependedElements = (listState, dependedElements) => {
  if (!isEmpty(dependedElements)) {
    dependedElements.forEach(element => {
      const {
        elementId,
        automaticDueDate: newAutoDueDate,
        automaticElementStartDate: newAutoStartDate
      } = element

      const target = listState.okrElements[elementId]

      if (target) {
        if (target.automaticElementStartDate !== newAutoStartDate) {
          target.automaticElementStartDate = newAutoStartDate
        }

        if (target.automaticDueDate !== newAutoDueDate) {
          target.automaticDueDate = newAutoDueDate
        }
      }
    })
  }
}

export const levelToOkrIconElement = level => {
  return {
    levelColor: level.color,
    levelPrefix: level.prefix,
    typeId: level.typeId
  }
}

export const getDescriptionForIntervalChangeConfirmation = ({ formModel, i18nInstance }) => {
  const { elementStartDate, dueDate } = formModel
  const dueDateProp = i18nInstance('objectives.table_header_duedate')
  const startDateProp = i18nInstance('objectives.table_header_startDate')

  const [dateProp] = [
    elementStartDate && dueDate && `${startDateProp} & ${dueDateProp}`,
    elementStartDate && !dueDate && startDateProp,
    !elementStartDate && dueDate && dueDateProp
  ].filter(Boolean)

  return i18nInstance('confirm.interval_change_message', { dateProp })
}

export const getOwnerFromUsersList = (users = []) => {
  return users.find(({ elementRoleId }) => elementRoleId === ROLE_IDS.OWNER) || null
}

export const getOwnerName = okrElement => {
  return getOwnerFromUsersList(okrElement.users)?.displayName || ''
}

export const getStakeholdersFromUsersList = (users = []) => {
  return users.filter(({ elementRoleId }) => elementRoleId === ROLE_IDS.STAKEHOLDER) || []
}

export const getStakeholdersNames = okrElement => {
  return getStakeholdersFromUsersList(okrElement.users)
    .map(({ displayName }) => displayName)
    .join(', ')
}
export const createUsersList = ({ ownerId = null, stakeholders = [] }) => {
  const isOwnerNeed = !isNull(ownerId)
  const preparedStakeholders = stakeholders.map(accountId => {
    return {
      accountId,
      elementRoleId: ROLE_IDS.STAKEHOLDER
    }
  })
  return isOwnerNeed
    ? [{ accountId: ownerId, elementRoleId: ROLE_IDS.OWNER }, ...preparedStakeholders]
    : preparedStakeholders
}

export const convertUsersListToOwnerAndStakeholders = (users = []) => {
  const owner = getOwnerFromUsersList(users)
  const ownerId = owner?.accountId || null
  const stakeholders = users
    .filter(({ elementRoleId }) => elementRoleId === ROLE_IDS.STAKEHOLDER)
    .map(({ accountId }) => accountId)
  return {
    ownerId,
    stakeholders
  }
}

export const getNotSetOwnerOption = t => {
  if (!t || !isFunction(t)) {
    throw new Error('Function getNotSetOwnerOption requires i18n instance as first argument')
  }

  return {
    [ASSIGNEE_ENTITY_KEYS.NAME]: t('field.not_set'),
    [ASSIGNEE_ENTITY_KEYS.ACCOUNT_ID]: null
  }
}

export const createGroupsList = (groupIds = []) => {
  return groupIds
    .filter(groupId => !isNull(groupId))
    .map(groupId => {
      return { id: groupId }
    })
}

export const convertGroupsListToGroups = (groupsList = []) => {
  return Array.isArray(groupsList) ? groupsList.map(({ id }) => id).filter(id => !isNull(id)) : []
}

export const childObjectivesShowedByFilterExist = childObjectives =>
  childObjectives.some(o => !o.hiddenByFilter)

export const filteredObjectivesToDisplay = (objectives, listState) => {
  return objectives.filter(
    elementId =>
      listState.okrElements[elementId] && !listState.okrElements[elementId].hiddenByFilter
  )
}

export const okrElementIsExpandable = ({
  element,
  filterButtonEnabled = false,
  filteredChildrenVisible = false
}) => {
  const { childCount, childElements, visibleCount } = element
  let result = childCount > 0
  if (filterButtonEnabled) {
    result =
      (result && visibleCount > 0 && childObjectivesShowedByFilterExist(childElements)) ||
      filteredChildrenVisible
  }
  return result
}

export const OKR_FORM_VIEWS = {
  CUSTOM_WEIGHTS: 0,
  KR: 1,
  OBJECTIVE: 2,
  LINK_JIRA_ISSUE: 3,
  DUPLICATE_OBJECTIVE: 4
}

export const OKR_TYPE_TO_FORM_VIEW = {
  [OBJECTIVE_TYPES.PERSONAL]: OKR_FORM_VIEWS.OBJECTIVE,
  [OBJECTIVE_TYPES.KR]: OKR_FORM_VIEWS.KR,
  [OBJECTIVE_TYPES.TASK]: OKR_FORM_VIEWS.LINK_JIRA_ISSUE
}

export const okrElementToLinkIssuePayload = okrElement => {
  const { id, intervalId, workspaceId, groups } = okrElement
  return {
    parentId: id,
    intervalId,
    workspaceId,
    automaticElementStartDate: okrElement.automaticElementStartDate,
    automaticDueDate: okrElement.automaticDueDate,
    intervalStartDate: okrElement.intervalStartDate,
    intervalEndDate: okrElement.intervalEndDate,
    groups: convertGroupsListToGroups(groups)
  }
}

export const OKR_ELEMENT_FORM_MENU_ACTIONS = {
  LINK_TASK: OKR_ELEMENTS_TABLE_ADD_MENU_ITEMS.LINK_TASK.name,
  CREATE_JIRA_ISSUE: OKR_ELEMENTS_TABLE_ADD_MENU_ITEMS.CREATE_JIRA_ISSUE.name,
  CREATE_OBJECTIVE: OKR_ELEMENTS_TABLE_ADD_MENU_ITEMS.CREATE_OBJECTIVE.name,
  CREATE_KR: OKR_ELEMENTS_TABLE_ADD_MENU_ITEMS.CREATE_KR.name
}

export const getObjectiveLevelsForCreate = allLevels => {
  return allLevels.filter(level =>
    [OBJECTIVE_TYPES.PERSONAL, OBJECTIVE_TYPES.GROUP, OBJECTIVE_TYPES.COMPANY].includes(
      level.typeId
    )
  )
}

/**
 *
 * @param {string} value — your search string
 * @param {Array<Object>} options — Array of options where you want to search
 * @param {string|Array<String>} [key] - One or more keys in the options object to search for.
 * @returns {Array<Object>} - Array of options that match the search string
 *
 */
export const localSearch = ({ value, options, key = 'name' }) => {
  const searchKeys = Array.isArray(key) ? key : [key]

  return options.filter(option => {
    const optionValues = searchKeys.map(key => option[key])

    return optionValues.some(optionValue => {
      return optionValue.toLowerCase().trim().includes(value.toLowerCase().trim())
    })
  })
}

export const getShowChartStatus = ({ intervals, intervalId, isEdit }) => {
  if (isEmpty(intervals)) {
    return false
  } else {
    const isBacklogIntervalSelected = intervals.find(
      interval => interval.id === intervalId
    )?.backlog

    return isEdit && !isBacklogIntervalSelected
  }
}

export const HOMEPAGE_OKR_SEARCH_TYPE_IDS = {
  OBJECTIVES: 4,
  KEY_RESULTS: 5
}

export const memoizeOkrTypeIds = memoize(
  ({ objectiveLevels, type }) => {
    // this function places here because of circular dependencies
    if (type === KR_KEY) {
      const krLevel = objectiveLevels.find(level => isKR(level))
      if (!krLevel) return []
      return [krLevel.id]
    }
    return objectiveLevels
      .filter(level => !isKR(level) && !objectiveIsJiraTask(level))
      .map(level => level.id)
  },
  ({ type }) => {
    return type
  }
)

// const OKRExplorerUniqueIds = new Set()
//
// export const clearExplorerUniqueIds = () => {
//   OKRExplorerUniqueIds.clear()
// }

const OKRExplorerUidPattern = new RegExp(`^[a-zA-Z0-9]{${UID_MAX_LENGTH}}.D[0-9]+$`)

export const flattenOkrElements = (elements, depth = 0, { listState, tableState }) => {
  let result = []

  for (const element of elements) {
    const payload = { ...element, depth }

    if (listState.view === OKR_VIEW_PAGES.OKREXPLORER) {
      // we should generate new uid cause we can render the same element more than once
      // and our virtualized list will be broken
      // so we generate new uid for each element
      // but we should not generate new uid for the same element every time
      // so we use Set to store unique ids
      // and prevent rerender

      // if (!OKRExplorerUniqueIds.has(element.uid)) {
      //   OKRExplorerUniqueIds.add(element.uid)
      //   payload.uid = uid()
      //   OKRExplorerUniqueIds.add(payload.uid)
      // }

      if (!OKRExplorerUidPattern.test(payload.uid)) {
        payload.uid = `${payload.uid}.D${depth}`
      }
    }

    result.push(payload)

    const isTask = objectiveIsJiraTask(element)

    let expandedItems = {}

    if (isTask && listState.view === OKR_VIEW_PAGES.ALIGNMENT) {
      expandedItems = listState.expandedTasks

      if (expandedItems[element.id]) {
        const childElements = listState.tasksChildren[element.id].map(id => ({
          ...listState.nestedTasks[id]
        }))

        let flattenedChildren = flattenOkrElements(childElements, depth + 1, {
          listState,
          tableState
        })

        result = result.concat(flattenedChildren)
      }
    } else {
      expandedItems = listState.filtersValues[FILTERS_KEYS.EXPANDED_ITEMS]

      const showFilteredChildrenObjectives = Boolean(
        listState.showFilteredChildrenObjectives[element.id]
      )
      const showFilterButton = isShowFilterButton({ element, tableState })

      if (element.childCount && expandedItems[getExpandedKey({ id: element.id, depth })]) {
        const childIds = listState.okrElementChildren[element.id] || []

        const childElements = childIds.map(id => ({
          ...listState.okrElements[id]
        }))

        const filteredChildren =
          !showFilterButton || showFilteredChildrenObjectives
            ? childElements
            : childElements.filter(
                ({ id }) => listState.okrElements[id] && !listState.okrElements[id].hiddenByFilter
              )

        let flattenedChildren = flattenOkrElements(filteredChildren, depth + 1, {
          listState,
          tableState
        })
        result = result.concat(flattenedChildren)
      }
    }
  }

  return result.filter(item => {
    // added in scope of https://oboard.atlassian.net/jira/software/c/projects/OK/boards/14?quickFilter=114&selectedIssue=OK-3140
    return !isUndefined(item) && Object.keys(item).length > 1
  })
}

export const getExpandedKey = element => {
  return `${element.id}-${element.depth}`
}

export const isShowFilterButton = ({ element, tableState }) => {
  if (element.isNestedTask) {
    return false
  }
  return (
    tableState.showFilterButtons &&
    currentUserCanReadObjective(element) &&
    element.childCount > 0 &&
    element.visibleCount !== element.childCount
  )
}

export const getClosedStatusName = ({ okrElement }) => {
  const { confidenceLevelId } = okrElement

  const [statusName] = [
    confidenceLevelId === OKR_STATUSES.CLOSED && CLOSED_STATUS_LABEL,
    confidenceLevelId === OKR_STATUSES.ABANDONED && ABANDONED_STATUS_LABEL,
    'unknown closed status'
  ].filter(Boolean)

  return statusName
}
