<template>
  <component
    :is="clickable ? 'a' : 'span'"
    v-if="value"
    :class="{
      'oi-ObjectiveId': true,
      'oi-ObjectiveId-disabled': !clickable,
      [`oi-ObjectiveId-${size}`]: true,
      [`oi-ObjectiveId-filteredout`]: filteredOut
    }"
    @click.stop.prevent="onClick"
  >
    {{ value }}
  </component>
</template>

<script>
import { defineComponent } from 'vue'

import { doubleClickHandler, checkIsTextSelectionProceed } from '@/utils/text-selection'

export default defineComponent({
  name: 'ObjectiveId',

  props: {
    value: {
      type: String,
      required: true
    },

    clickable: {
      type: Boolean,
      default: true
    },

    size: {
      type: String,
      default: 'default',
      validator: v => ['xs', 'small', 'default', 'large'].includes(v)
    },

    filteredOut: {
      type: Boolean
    },

    disabledColor: {
      type: String,
      default: '#172B4D'
    }
  },

  emits: { click: null },

  beforeUnmount() {
    doubleClickHandler.destroy()
  },

  methods: {
    onClick() {
      if (this.clickable) {
        doubleClickHandler.handleClick(this.handleClick)
      }
    },

    handleClick() {
      if (!checkIsTextSelectionProceed()) {
        this.$emit('click')
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.oi-ObjectiveId {
  color: $primary-color-next;
  white-space: nowrap;
  font-size: $fs-16;
  cursor: pointer;
  font-weight: var(--id-font-weight, initial);
  font-family: $system-ui;

  &:hover {
    text-decoration: underline;
  }

  &-xs {
    font-size: $fs-12;
    line-height: 1;
  }

  &-small {
    line-height: 20px;
    font-size: $fs-14;
  }

  &-large {
    font-weight: fw('medium');
    font-size: $fs-16;
  }

  &-disabled {
    cursor: default;
    &:hover {
      text-decoration: none;
    }
  }

  &-disabled {
    color: v-bind(disabledColor);
  }

  &-filteredout {
    opacity: 0.5;
  }
}
</style>
