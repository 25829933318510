<template>
  <div class="urc-Cell">
    <CellSelectItem
      :id="`ro${id}`"
      :active="isOpened"
      is-transparent
      truncated
      user-has-read-access
      user-has-update-access
      @click="editInitialised = true"
    >
      {{ roleName }}
    </CellSelectItem>
    <AppSelect
      v-if="editInitialised"
      v-model="selectedRole"
      :append-to="appendTo"
      :dropdown-search="false"
      :dropdown-width="dropdownWidth"
      :inline-loader="false"
      :max-height="264"
      :offset="[0, 0]"
      :options="resolvedRoles"
      :to-selector="`#ro${id}`"
      boundary="scrollParent"
      item-label="label"
      item-value="value"
      show-on-init
      @hide="hideSelect"
      @opened="isOpened = true"
    />
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'

import UserApiHandler from '@/api/user-details'
import { tracker } from '@/tracking/amplitude'
import { EVENT_CATEGORIES, TRACKING_UNKNOWN } from '@/tracking/amplitude-helpers'
import { handleError } from '@/utils/error-handling'
import { uid } from '@/utils/uid'

import CellSelectItem from '@/components/objectives/table/cells/CellSelectItem'
import AppSelect from '@/components/ui/AppSelect/AppSelect'

defineOptions({
  name: 'UserRoleCell'
})

const props = defineProps({
  roleId: {
    type: [String, Number],
    required: true
  },

  roles: {
    type: Array,
    required: true
  },

  workspaceId: {
    type: [String, Number],
    required: true
  },

  accountId: {
    type: [String, Number],
    required: true
  },

  appendTo: {
    type: String,
    default: 'parent'
  },

  dropdownWidth: {
    type: String,
    default: '160px'
  },

  userWorkspacesData: {
    type: Array,
    required: true
  },

  trackingSource: {
    type: String,
    default: TRACKING_UNKNOWN
  }
})

const id = uid()

const editInitialised = ref(false)

const isOpened = ref(false)

const hideSelect = () => {
  isOpened.value = false
}

const resolvedRoles = computed(() => {
  return props.roles.filter(({ value }) => value)
})

const roleName = computed(() => {
  return resolvedRoles.value.find(role => role.value === props.roleId)?.label || ''
})

const emit = defineEmits(['update-role-id'])

const updateRole = async roleId => {
  const userApi = new UserApiHandler()
  try {
    const payload = {
      accountId: props.accountId,
      workspaceParameters: [
        {
          workspaceId: props.workspaceId,
          roleId,
          defaultGroupId: props.userWorkspacesData.find(
            workspace => workspace.workspaceId === props.workspaceId
          )?.defaultGroupId
        }
      ]
    }

    const updatedUser = await userApi.updateUser(payload)
    const updatedRoleId = updatedUser.workspaces.find(
      workspace => workspace.workspaceId === props.workspaceId
    ).roleId

    tracker.logEvent('User role changed', {
      category: EVENT_CATEGORIES.USER_MANAGEMENT,
      workspace: props.workspaceId,
      source: props.trackingSource,
      value:
        props.roles.find(role => role.value === updatedRoleId)?.trackingName || TRACKING_UNKNOWN
    })

    emit('update-role-id', updatedRoleId)
  } catch (error) {
    handleError({ error })
  }
}

const selectedRole = computed({
  get: () => props.roleId,
  set: value => updateRole(value)
})
</script>

<style lang="scss" scoped>
.urc-Cell {
  display: flex;
  padding-right: 8px;
}
</style>
