<script>
import dayjs from 'dayjs'
import { isEmpty, isNull } from 'lodash'
import { defineComponent, h, computed } from 'vue'
import { useI18n } from 'vue-i18n'

import { PLUG_ICONS } from '@/utils/cell-helper'
import { dateFormat } from '@/utils/date'
import { OKR_DATES_SELECT_DATE_PROPS } from '@/utils/okr-element-dates'
import { stringOrNullProp } from '@/utils/prop-validators'

import OkrElementsTableCellPlug from '@/components/objectives/table/cells/OkrElementsTableCellPlug'

const TYPES = {
  DEFAULT: 'default',
  DEFAULT_NEXT: 'default-next'
}

export default defineComponent({
  name: 'OkrDatesDropdownTrigger',
  props: {
    formModel: {
      type: Object,
      required: true
    },

    active: {
      type: Boolean
    },

    disabled: {
      type: Boolean
    },

    isAutoPeriodMode: {
      type: Boolean
    },

    dateForShow: {
      type: String,
      default: null,
      validator: v => v === null || Object.values(OKR_DATES_SELECT_DATE_PROPS).includes(v)
    },

    type: {
      type: String,
      default: TYPES.DEFAULT,
      validator: v => Object.values(TYPES).includes(v)
    },

    multiLine: {
      type: Boolean
    },

    shortDate: {
      type: Boolean
    },

    userHasReadAccess: {
      type: Boolean,
      default: true
    },

    userHasUpdateAccess: {
      type: Boolean,
      default: true
    },

    plugIcon: {
      type: String,
      default: null,
      validator: v => stringOrNullProp(v) && Object.values(PLUG_ICONS).includes(v)
    }
  },

  emits: {
    click: null
  },

  setup(props, { emit }) {
    const { t } = useI18n()

    const showOnlyOneDate = computed(() => !isEmpty(props.dateForShow))
    const spacer = showOnlyOneDate.value ? '' : '–'

    const text = computed(() => {
      const resolvedDateFormat = props.shortDate ? 'DD MMM' : dateFormat

      const { dueDate, elementStartDate, automaticDueDate, automaticElementStartDate } =
        props.formModel

      const startOfIntervalText = t('objectives.due_date.start_of_interval')
      const endOfIntervalText = t('objectives.due_date.end_of_interval')
      const defaultStartDateText = showOnlyOneDate.value ? ' ' : startOfIntervalText
      const defaultDueDateText = showOnlyOneDate.value ? ' ' : endOfIntervalText

      let leftPart = defaultStartDateText
      let rightPart = defaultDueDateText

      if (props.isAutoPeriodMode) {
        if (automaticElementStartDate) {
          leftPart = dayjs(automaticElementStartDate).utc().format(resolvedDateFormat)
        }

        if (automaticDueDate) {
          rightPart = dayjs(automaticDueDate).utc().format(resolvedDateFormat)
        }
      } else {
        if (elementStartDate) {
          leftPart = dayjs(elementStartDate).utc().format(resolvedDateFormat)
        }

        if (dueDate) {
          rightPart = dayjs(dueDate).utc().format(resolvedDateFormat)
        }
      }

      const { dateForShow } = props

      if (props.multiLine && !showOnlyOneDate.value) {
        return [leftPart, ' ', h('br'), spacer, ' ', rightPart.trim()]
      } else {
        const [result] = [
          dateForShow === OKR_DATES_SELECT_DATE_PROPS.DUE_DATE && `${rightPart}`,
          dateForShow === OKR_DATES_SELECT_DATE_PROPS.START_DATE && `${leftPart}`,
          `${leftPart} ${spacer} ${rightPart}`
        ].filter(Boolean)

        return `${result.trim()}`
      }
    })

    const showPlugAsIcon = computed(() => {
      return !isNull(props.plugIcon) && props.userHasUpdateAccess
    })

    return () => {
      const isShowIconPlug = showPlugAsIcon.value && !text.value
      return h(
        'div',
        {
          onClick: () => {
            emit('click')
          },
          class: {
            'odt-Trigger': true,
            'odt-Trigger-active': props.active,
            'odt-Trigger-inaccessible': !props.userHasReadAccess || !props.userHasUpdateAccess,
            'odt-Trigger-disabled': props.disabled,
            [`odt-Trigger-${props.type}`]: true,
            'odt-Trigger-empty': !text.value && !props.showPlugAsIcon,
            'odt-Trigger-empty-with-icon': isShowIconPlug
          },
          'data-testid': 'okr-dates-dropdown-trigger',
          'data-auto-testid': 'okr-dates-dropdown-trigger'
        },
        isShowIconPlug ? h(OkrElementsTableCellPlug, { iconName: props.plugIcon }) : text.value
      )
    }
  }
})
</script>

<style lang="scss" scoped>
.odt-Trigger {
  font-family: $system-ui;
  line-height: 20px;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 20px;

  @-moz-document url-prefix() {
    wbr {
      display: block;
    }
  }
  .is-safari & {
    // Fix for safari breaking wbr
    //display: contents;
  }

  &-default {
    width: calc(100% + 8px);
    color: $primary-color;
    padding: 4px 8px;
    margin-left: -8px;
    border: 1px solid transparent;
    border-radius: $border-radius-sm;

    &:not(.odt-Trigger-active) {
      @media (any-hover: hover) {
        &:hover {
          background-color: $azure-medium;
        }
      }
    }
  }

  &-default-next {
    color: $dark-1;
    white-space: nowrap;
  }

  &-active#{&}-default {
    border-color: $primary-color;
  }

  &-disabled {
    pointer-events: none;
    opacity: var(--disabled-opacity, 0.8);
  }
}
</style>
