<template>
  <div class="ite-InfiniteTableExpandable">
    <AppTable
      :columns="columns"
      :data="listState.items"
      :disable-user-select="disableUserSelect"
      :loading="loading"
      :offset-left="offsetLeft"
      :offset-right="offsetRight"
      :sticky-header="stickyHeader"
      class="ite-AppTable"
      no-border
      type="primary-next"
    >
      <template #header-cell="{ column }">
        <div
          :class="{ 'ite-HeadCell-with-button': isShowExpandCollapseButton(column.key) }"
          class="ite-HeadCell"
        >
          <ExpandCollapseButton
            v-if="isShowExpandCollapseButton(column.key)"
            :loading="listState.isExpandCollapseAllLoading || loading"
            @collapse="collapseAll"
            @expand="expandAll"
          />
          <slot :column="column" name="header-cell" />
        </div>
      </template>

      <template #row="rowSlotData">
        <TableExpandableRowNext
          :child-items-key="childItemsKey"
          :column-styles="rowSlotData.columnStyles"
          :columns="rowSlotData.columns"
          :hover-row="hoverRow"
          :item="rowSlotData.row"
          :list-state="listState"
          :offset-left="offsetLeft"
          :offset-right="offsetRight"
          :selected-items="selectedItems"
          @toggle-expand-collapse="toggleExpandCollapse($event)"
        >
          <template #cell="slotData">
            <slot
              :column-key="slotData.columnKey"
              :depth="slotData.depth"
              :item="slotData.item"
              name="cell"
            />
          </template>
        </TableExpandableRowNext>
      </template>

      <template #loading>
        <slot name="loading" />
      </template>

      <template #footer>
        <slot name="footer" />
      </template>
    </AppTable>
  </div>
</template>

<script setup>
import { has } from 'lodash'

import { DEFAULT_LIST_STATE } from '@/composables/infinite-expandable-table'
import { stringOrNullProp } from '@/utils/prop-validators'

import ExpandCollapseButton from '@/components/ui/ExpandCollapseButton/ExpandCollapseButton'
import TableExpandableRowNext from '@/components/ui/InfiniteTableExpandable/InfiniteTableExpandableRow'
import AppTable from '@/components/ui/Table/AppTable'

defineOptions({
  name: 'InfiniteTableExpandable'
})

const props = defineProps({
  columns: {
    type: Array,
    required: true,
    validator: v => {
      const bulkActionsColumns = v.filter(column => column.isBulkAction)
      if (bulkActionsColumns.length > 1) {
        throw new Error('Only one column can be bulk action')
      }
      return bulkActionsColumns.length <= 1
    }
  },

  offsetLeft: {
    type: [String, Number],
    default: ''
  },

  offsetRight: {
    type: [String, Number],
    default: ''
  },

  stickyHeader: {
    type: Boolean
  },

  loading: {
    type: Boolean,
    default: false
  },

  columnWithExpandCollapseButton: {
    type: String,
    default: ''
  },

  childItemsKey: {
    type: String,
    default: 'childItems'
  },

  hoverRow: {
    default: null,
    validator: v => stringOrNullProp(v)
  },

  selectedItems: {
    type: Array,
    default: () => []
  },

  listState: {
    type: Object,
    required: true,
    validator: v => {
      const isListStateValid = Object.keys(DEFAULT_LIST_STATE).every(key => {
        return has(v, key)
      })

      const isDataTypesValid = Object.keys(DEFAULT_LIST_STATE).every(key => {
        return typeof v[key] === typeof DEFAULT_LIST_STATE[key]
      })

      return isListStateValid && isDataTypesValid
    }
  },

  disableUserSelect: {
    type: Boolean,
    default: true
  }
})

const emit = defineEmits({
  'toggle-expand-collapse': null,
  'expand-all': null,
  'collapse-all': null
})
const toggleExpandCollapse = itemId => {
  emit('toggle-expand-collapse', itemId)
}

const expandAll = () => {
  emit('expand-all')
}

const collapseAll = () => {
  emit('collapse-all')
}

const isShowExpandCollapseButton = columnKey => {
  return columnKey === props.columnWithExpandCollapseButton
}
</script>

<style lang="scss" scoped>
.ite-HeadCell {
  &-with-button {
    display: flex;
    align-items: center;
    gap: var(--okr-table-row-gap);
  }
}
</style>

<style lang="scss">
.ite-InfiniteTableExpandable .ite-AppTable .tb-RowWrapper {
  border-bottom: none;
  &:hover {
    background-color: inherit;
  }

  &:after {
    display: none;
  }
}

.ite-InfiniteTableExpandable .ite-AppTable {
  .tb-Table-primary-next.tb-Table-with-offset .tb-RowWrapper {
    padding-bottom: 0;
  }
}
</style>
