export const KEY_CODES = {
  BACKSPACE: 8,
  TAB: 9,
  ENTER: 13,
  ESCAPE: 27,
  SPACE: 32,
  LEFT_ARROW: 37,
  UP_ARROW: 38,
  RIGHT_ARROW: 39,
  DOWN_ARROW: 40,
  DELETE: 46
}

export const isEscape = keyCode => keyCode === KEY_CODES.ESCAPE

export const isEnter = keyCode => keyCode === KEY_CODES.ENTER
