<template>
  <AppDroplist
    v-model="gifsOpened"
    :disabled="disabled"
    :offset="[0, 0]"
    dropdown-width="376px"
    position="bottom-end"
  >
    <template #button>
      <DescriptionButton :active="gifsOpened" :disable="disabled" icon="gif" />
    </template>
    <div class="agd-GifsContainer">
      <div class="agd-GifsWrapper">
        <Search v-model="gifListState.searchString" class="agd-GifSearch" width="100%" />
        <div v-if="noSearchResults" class="agd-NoResults">{{ $t('common.no_results') }}</div>
        <div class="agd-GifItems">
          <div>
            <img
              v-for="gif in gifListState.data[LEFT_COLUMN]"
              :key="gif.url"
              :alt="gif.alt"
              :src="gif.url"
              class="agd-GifsItem"
              @click="addGif(gif)"
            />
          </div>
          <div>
            <img
              v-for="gif in gifListState.data[RIGHT_COLUMN]"
              :key="gif.url"
              :alt="gif.alt"
              :src="gif.url"
              class="agd-GifsItem"
              @click="addGif(gif)"
            />
          </div>
        </div>
        <div v-if="gifListState.isLoading" class="agd-GifItems">
          <SkeletonItem
            v-for="n in GIFS_DEFAULT_VALUES.limit"
            :key="n"
            class="agd-GifsItem agd-GifsItem-loader"
            height="150px"
            width="100%"
          />
        </div>
        <InfiniteScrollLoaderNext
          v-if="!gifListState.isAllItemsLoaded"
          :loading="gifListState.isLoading"
          @load-more="onLoadMore"
        />
      </div>
      <div class="agd-GifsFooter">
        <AppIcon height="11" icon-name="powered-by-giphy" width="95" />
      </div>
    </div>
  </AppDroplist>
</template>

<script setup>
import { GiphyFetch } from '@giphy/js-fetch-api'
import axios from 'axios'
import { cloneDeep, isEmpty } from 'lodash'
import { ref, watch, computed } from 'vue'

import { handleError } from '@/utils/error-handling'
import {
  DESCRIPTION_FIELD_PLACEMENTS,
  GIFS_PLACEMENTS_API_KEYS,
  removeSlashCommandShortcut,
  TIPTAP_EXTENSION_NAMES
} from '@/utils/tiptap-utils'

import AppDroplist from '@/components/AppDroplist'
import DescriptionButton from '@/components/objectives/forms/DescriptionButton'
import AppIcon from '@/components/ui/AppIcon/AppIcon'
import InfiniteScrollLoaderNext from '@/components/ui/InfiniteScrollLoaderNext'
import Search from '@/components/ui/Search/Search'
import SkeletonItem from '@/components/ui/SkeletonLoaders/SkeletonItem'

const props = defineProps({
  editor: {
    type: Object,
    required: true
  },

  placement: {
    type: String,
    default: DESCRIPTION_FIELD_PLACEMENTS
  },

  disabled: {
    type: Boolean
  }
})

const GIFS_API_KEY = GIFS_PLACEMENTS_API_KEYS[props.placement]

const LEFT_COLUMN = 'left'
const RIGHT_COLUMN = 'right'

const gifApi = new GiphyFetch(GIFS_API_KEY)
const gifsOpened = ref(false)

const GIFS_DEFAULT_VALUES = {
  isAllItemsLoaded: false,
  isLoading: false,
  data: {
    [LEFT_COLUMN]: [],
    [RIGHT_COLUMN]: []
  },
  limit: 20,
  offset: 0,
  searchString: '',
  gifsTotalCount: 0
}

const gifListState = ref({
  ...cloneDeep(GIFS_DEFAULT_VALUES)
})

const noSearchResults = computed(
  () =>
    !gifListState.value.isLoading &&
    isEmpty([...gifListState.value.data[LEFT_COLUMN], ...gifListState.value.data[RIGHT_COLUMN]])
)

const getGifMappedData = data => {
  return data.reduce(
    (acc, val, index) => {
      const item = {
        analyticsOnClickUrl: val.analytics.onclick.url,
        url: val.images.downsized.url,
        alt: val.alt_text
      }
      const column = index % 2 === 0 ? LEFT_COLUMN : RIGHT_COLUMN

      acc[column].push(item)

      return acc
    },
    { [LEFT_COLUMN]: [], [RIGHT_COLUMN]: [] }
  )
}

const REQUEST_TYPES = {
  TRENDING: 'trending',
  SEARCH: 'search'
}

const fetchRelatedGifs = async ({ requestType = REQUEST_TYPES.TRENDING } = {}) => {
  try {
    gifListState.value.isLoading = true
    let response

    const payload = {
      offset: gifListState.value.offset,
      limit: GIFS_DEFAULT_VALUES.limit,
      random_id: randomId.value
    }

    if (requestType === REQUEST_TYPES.TRENDING) {
      response = await gifApi.trending(payload)
    } else if (requestType === REQUEST_TYPES.SEARCH) {
      response = await gifApi.search(gifListState.value.searchString, payload)
    }

    const { data, pagination } = response

    const { left, right } = getGifMappedData(data)

    gifListState.value.data[LEFT_COLUMN].push(...left)
    gifListState.value.data[RIGHT_COLUMN].push(...right)

    gifListState.value.gifsTotalCount = pagination.total_count

    if (gifListState.value.offset >= gifListState.value.gifsTotalCount) {
      gifListState.value.isAllItemsLoaded = true
    }
  } catch (error) {
    gifListState.value.isAllItemsLoaded = true
    handleError({ error })
  } finally {
    gifListState.value.isLoading = false
  }
}

const cleanAxiosInstance = axios.create({
  transformRequest: (_, headers) => {
    headers.clear()
  }
})

const addGif = item => {
  cleanAxiosInstance.get(item.analyticsOnClickUrl, {
    params: {
      random_id: randomId.value,
      ts: +new Date()
    }
  })

  removeSlashCommandShortcut(props.editor)

  props.editor.commands.enter()
  props.editor.commands.insertContent({
    type: TIPTAP_EXTENSION_NAMES.IMAGE_BLOCK,
    attrs: { src: item.url }
  })
  props.editor.commands.enter()
  props.editor.commands.focus('end')
  gifsOpened.value = false
}

const fetchGifs = () => {
  if (gifListState.value.searchString) {
    fetchRelatedGifs({
      requestType: REQUEST_TYPES.SEARCH
    })
  } else {
    fetchRelatedGifs({
      requestType: REQUEST_TYPES.TRENDING
    })
  }
}

const onLoadMore = () => {
  if (gifsOpened.value) {
    if (gifListState.value.isAllItemsLoaded) return

    gifListState.value.offset += GIFS_DEFAULT_VALUES.limit
    fetchGifs()
  }
}

const randomId = ref(null)
const getRandomId = async () => {
  try {
    const {
      data: {
        data: { random_id }
      }
    } = await cleanAxiosInstance.get('https://api.giphy.com/v1/randomid', {
      params: {
        api_key: GIFS_API_KEY
      }
    })

    randomId.value = random_id
  } catch (error) {
    handleError({ error })
  }
}

watch(
  () => gifListState.value.searchString,
  () => {
    gifListState.value.data = cloneDeep(GIFS_DEFAULT_VALUES.data)
    gifListState.value.offset = GIFS_DEFAULT_VALUES.offset
    fetchGifs()
  }
)

watch(
  () => gifsOpened.value,
  async newValue => {
    if (newValue) {
      if (!randomId.value) {
        await getRandomId()
      }
      await fetchRelatedGifs({
        requestType: REQUEST_TYPES.TRENDING
      })
    } else {
      gifListState.value = {
        ...cloneDeep(GIFS_DEFAULT_VALUES)
      }
    }
  }
)

const openGifs = () => {
  gifsOpened.value = true
}

defineExpose({
  openGifs
})
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';

.agd-GifsContainer {
  height: 472px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  @include styled-native-scrollbar();
}

.agd-GifsWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0 20px 0 20px;
  flex: 1 1 100%;
}

.agd-GifSearch {
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  background: $white;
  padding: 20px 0;
  z-index: 5;
}

.agd-GifItems {
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr 1fr;
  white-space: normal; // necessary for alt text
  // display: block;
  // column-count: 2;
  // column-gap: 16px;
}

.agd-GifsFooter {
  padding: 6px 20px;
  position: sticky;
  bottom: 0;
  background: $white;
  border-radius: $border-radius-sm-next;
}

.agd-GifsItem {
  display: block;
  cursor: pointer;
  max-width: 100%;
  margin: 0 0 16px 0;
  page-break-inside: avoid;
  break-inside: avoid;
  min-height: 90px;

  &-loader {
    cursor: default;
  }
}

.agd-NoResults {
  color: $dark-2;
  font-size: $fs-12;
  line-height: 16px;
  font-weight: fw('semi-bold');
}
</style>
