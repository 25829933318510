<template>
  <span :class="{ 'cs-BottomFixedItem-no-hover': noHover }" class="cs-BottomFixedItem">
    <slot name="icon">
      <AppIcon :icon-name="iconName" height="24" width="24" />
    </slot>
    <slot name="default">{{ text }}</slot>
  </span>
</template>

<script setup>
import AppIcon from '@/components/ui/AppIcon/AppIcon'

defineOptions({
  name: 'BottomFixedSelectItem'
})

defineProps({
  text: {
    type: String,
    default: ''
  },
  iconName: {
    type: String,
    default: 'cross-circle-next'
  },
  noHover: {
    type: Boolean
  }
})
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';
.cs-BottomFixedItem {
  display: flex;
  align-items: center;
  text-align: center;
  padding: 10px;
  gap: 8px;
  text-decoration: none;
  transition: background-color $transition-fast ease-in-out;
  font-family: $system-ui;
  font-weight: fw('regular');
  font-size: $fs-14;
  line-height: 20px;
  color: $dark-3;
  position: relative;
  margin: 9px 0 0 0;
  &:before {
    background: $grey-2-next;
    height: 1px;
    margin: 0 17px 0 auto;
    pointer-events: none;
    display: flex;
    content: '';
    width: calc(100% - 27px);
    position: absolute;
    top: -5px;
    left: 0;
    right: 0;
  }
  &:not(&-no-hover) {
    cursor: pointer;
    @media (any-hover: hover) {
      &:hover {
        background-color: $grey-3-next;
      }
    }
    @include activeState($grey-3-next, 10%);
  }
}
</style>
