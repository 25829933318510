<template>
  <div v-if="showPage" class="wp-WorkspacesPage">
    <PageContentHeader
      :description="isNoWorkspaces ? null : $t('workspaces.page_description')"
      :level="3"
      :max-width="HEADER_SIZES.LG"
      :title="$t('workspaces.workspaces')"
      no-margin
      style="--align-items: flex-start"
    >
      <div v-if="!isNoWorkspaces" class="wp-Toolbar">
        <AppSearch v-model="searchString" />

        <AppButton
          v-tippy="{
            content: isPluginAdmin ? null : $t('workspaces.no_rights')
          }"
          :disable="!isPluginAdmin"
          height="24"
          icon="plus-next"
          type="primary-next"
          width="24"
          @click="onCreateWorkspaceClick"
        >
          {{ $t('workspaces.create') }}
        </AppButton>
      </div>
    </PageContentHeader>

    <div class="wp-MainContent">
      <AppTable
        v-show="!isNoWorkspaces"
        :columns="COLUMNS"
        :data="filteredWorkspaces"
        :disable-user-select="false"
        :hover-row="tableHoverRow"
        :loading="isLoading"
        border-on-last-row
        class="wp-Table"
        no-border
        offset-left="var(--page-left-padding)"
        offset-right="var(--page-right-padding)"
        sticky-header
        type="primary-next"
      >
        <template #header-cell="{ column }">
          <template v-if="column.title">
            {{ $t(column.title) }}
          </template>
        </template>
        <template #cell="{ columnKey, item, index }">
          <template v-if="columnKey === TABLE_COLUMNS_KEYS.NAME">
            <WorkspaceNameCell :workspace="item" @on-name-click="editWorkspace(item)" />
          </template>
          <template v-else-if="columnKey === TABLE_COLUMNS_KEYS.USERS">
            <div class="wp-InteractionCell" @click="editWorkspace(item, TABLE_COLUMNS_KEYS.USERS)">
              {{ $t('workspaces.table.cell.users', { count: item.usersCount }) }}
            </div>
          </template>
          <template v-else-if="columnKey === TABLE_COLUMNS_KEYS.GROUPS">
            <div class="wp-InteractionCell" @click="editWorkspace(item, TABLE_COLUMNS_KEYS.GROUPS)">
              {{ $t('workspaces.table.cell.groups', { count: item.groupsCount }) }}
            </div>
          </template>
          <template v-else-if="columnKey === TABLE_COLUMNS_KEYS.INTERVALS">
            <div
              class="wp-InteractionCell"
              @click="editWorkspace(item, TABLE_COLUMNS_KEYS.INTERVALS)"
            >
              {{ $t('workspaces.table.cell.intervals', { count: item.intervalsCount }) }}
            </div>
          </template>
          <template v-else-if="columnKey === TABLE_COLUMNS_KEYS.VISIBILITY_ICON">
            <div class="wp-VisibilityIconCell">
              <AppIcon v-if="!item.public" height="24" icon-name="lock-next" width="24" />
            </div>
          </template>
          <template v-else-if="columnKey === TABLE_COLUMNS_KEYS.VISIBILITY">
            {{ item.public ? $t('workspaces.public') : $t('workspaces.private') }}
          </template>
          <template v-else-if="columnKey === TABLE_COLUMNS_KEYS.ACTIONS">
            <div class="wp-Actions">
              <DropdownMenu
                :items="menuActions(item)"
                :offset="[0, 0]"
                :type="DROPDOWN_MENU_TYPES.DEFAULT_NEXT"
                preferred-position="bottom-end"
                @close="tableHoverRow = -1"
                @open="tableHoverRow = index"
                @item-click="onMenuActionsClick($event, item)"
              >
                <template #activator>
                  <AppButton icon="more-next" size="sm" type="tertiary-next" />
                </template>
              </DropdownMenu>
            </div>
          </template>
          <template v-else>
            {{ item[columnKey] }}
          </template>
        </template>

        <template #loading>
          <WorkspacesTableLoader />
        </template>

        <template #footer>
          <NoSearchResults
            v-if="isNoSearchResults"
            offset-left="var(--page-left-padding)"
            offset-right="var(--page-right-padding)"
          >
            {{ $t('workspaces.no_results') }}
          </NoSearchResults>
          <AppTableCreateButton
            v-if="isPluginAdmin"
            full-width
            icon-name="plus-next"
            @click="onCreateWorkspaceClick"
          >
            {{ $t('workspaces.create') }}
          </AppTableCreateButton>
        </template>
      </AppTable>
    </div>

    <SettingsPagesEmptyState
      v-if="isNoWorkspaces"
      hero-height="160"
      hero-icon="no-labels-hero"
      hero-width="142"
    >
      <template #title> {{ $t('workspaces.no_workspaces') }} </template>
      <template #subtitle> {{ $t('workspaces.no_workspaces_subtitle') }} </template>

      <template #action>
        <AppButton
          v-tippy="{
            content: isPluginAdmin ? null : $t('workspaces.no_rights')
          }"
          :disable="!isPluginAdmin"
          height="24"
          icon="plus-next"
          type="primary-next"
          width="24"
          @click="onCreateWorkspaceClick"
        >
          {{ $t('workspaces.create') }}
        </AppButton>
      </template>
    </SettingsPagesEmptyState>

    <WorkspaceCreateFastEditModal
      v-model:show="showWorkspaceModal"
      data-auto-testid="create-workspace-modal"
      @on-created="onWorkspaceCreated"
    />

    <WorkspaceConfigurationModal
      v-if="showConfigurationModal"
      :modal-open-data="configurationModalOpenData"
      :opened="wsModalOpened"
      @on-menu-action-click="onMenuActionsClick"
      @on-close="onWorkspaceModalClose"
    />

    <WorkspaceDeleteModal
      :is-loading="isDeleteWorkspaceLoading"
      :show="isConfirmDeleteShow"
      :workspace-to-delete="workspaceToDelete"
      @on-close="hideConfirmDeleteWorkspace"
      @on-confirm="deleteWorkspace"
    />
  </div>
</template>

<script>
import FileSaver from 'file-saver'
import { has, isEmpty } from 'lodash'
import { defineComponent } from 'vue'
import { mapActions, mapGetters, mapState } from 'vuex'

import ObjectivesApiHandler from '@/api/okr-elements'
import WorkspacesApiHandler from '@/api/workspaces'
import { ROUTE_NAMES } from '@/routes/route-helpers'
import { tracker } from '@/tracking/amplitude'
import { EVENT_CATEGORIES, trackWorkspaceFormOpenedEvent } from '@/tracking/amplitude-helpers'
import { ACTIONS_KEYS } from '@/utils/actions-keys'
import { DROPDOWN_MENU_TYPES } from '@/utils/components-configurations/dropdown-menu'
import { HEADER_SIZES } from '@/utils/components-configurations/page-content-header'
import { WS_CONFIGURATION_TABS } from '@/utils/components-configurations/workspace-configuration-modal'
import { MENU_ITEMS_GROUPS } from '@/utils/dropdown-menu'
import { WORKSPACE_ENTITY_KEYS } from '@/utils/entity-keys'
import { handleError } from '@/utils/error-handling'
import { showNotify } from '@/utils/notify'
import { localSearch } from '@/utils/objectives'
import {
  EDIT_WORKSPACE_ACTIVE_TAB_QUERY_KEY,
  EDIT_WORKSPACE_QUERY_KEY
} from '@/utils/query-parameters'
import { replaceQueryParameters } from '@/utils/router'
import { TABLE_COLUMNS_KEYS } from '@/utils/table-columns'
import { saveLastSelectedWorkspace } from '@/utils/workspaces'

import AppButton from '@/components/ui/AppButton/AppButton'
import AppIcon from '@/components/ui/AppIcon/AppIcon'
import AppTableCreateButton from '@/components/ui/AppTableCreateButton'
import DropdownMenu from '@/components/ui/DropdownMenu/DropdownMenu'
import NoSearchResults from '@/components/ui/NoSearchResults/NoSearchResults'
import PageContentHeader from '@/components/ui/PageContentHeader/PageContentHeader'
import AppSearch from '@/components/ui/Search/Search'
import SettingsPagesEmptyState from '@/components/ui/SettingsPagesEmptyState/SettingsPagesEmptyState'
import WorkspacesTableLoader from '@/components/ui/SkeletonLoaders/WorkspacesTableLoader'
import AppTable from '@/components/ui/Table/AppTable'
import WorkspaceConfigurationModal from '@/components/workspaces/configuration-modal/WorkspaceConfigurationModal'
import WorkspaceCreateFastEditModal from '@/components/workspaces/WorkspaceCreateFastEditModal'
import WorkspaceDeleteModal from '@/components/workspaces/WorkspaceDeleteModal'
import WorkspaceNameCell from '@/components/workspaces/WorkspaceNameCell'

const workspacesApi = new WorkspacesApiHandler()
const objectivesApi = new ObjectivesApiHandler()

const { NAME, USERS, GROUPS, INTERVALS, ACTIONS, VISIBILITY, VISIBILITY_ICON } = TABLE_COLUMNS_KEYS

const COLUMNS = [
  {
    title: 'workspaces.name',
    key: NAME,
    width: 'auto'
  },
  {
    title: 'field.groups.title',
    key: GROUPS,
    width: 116
  },
  {
    title: 'common.users',
    key: USERS,
    width: 110
  },
  {
    title: 'workspaces.intervals',
    key: INTERVALS,
    width: 125
  },
  {
    key: VISIBILITY_ICON,
    width: 32
  },
  {
    title: 'workspaces.visibility',
    key: VISIBILITY,
    width: 105
  },
  {
    key: ACTIONS,
    width: 24
  }
]

export default defineComponent({
  name: 'SettingsWorkspaces',

  components: {
    NoSearchResults,
    AppSearch,
    WorkspaceNameCell,
    SettingsPagesEmptyState,
    WorkspacesTableLoader,
    WorkspaceCreateFastEditModal,
    WorkspaceConfigurationModal,
    AppTableCreateButton,
    AppButton,
    AppTable,
    WorkspaceDeleteModal,
    DropdownMenu,
    AppIcon,
    PageContentHeader
  },

  data() {
    return {
      showWorkspaceModal: false,
      isConfirmDeleteShow: false,
      configurationModalOpenData: {},
      workspaceToEdit: undefined,
      workspaceToDelete: undefined,
      wsModalOpened: false,
      showPage: false,
      searchString: '',

      tableHoverRow: -1,

      workspaces: [],
      isLoading: false,
      isDeleteWorkspaceLoading: false
    }
  },

  computed: {
    ...mapState('system', {
      userData: state => state.userData
    }),

    ...mapState('pluginOptions', {
      isPluginAdmin: state => state.isPluginAdmin
    }),

    ...mapGetters('system', {
      userRoleForTracking: 'userRoleForTracking'
    }),

    HEADER_SIZES: () => HEADER_SIZES,

    DROPDOWN_MENU_TYPES: () => DROPDOWN_MENU_TYPES,

    TABLE_COLUMNS_KEYS: () => TABLE_COLUMNS_KEYS,

    COLUMNS: () => COLUMNS,

    isNoWorkspaces() {
      return isEmpty(this.workspaces) && !this.isLoading
    },

    isNoSearchResults() {
      return isEmpty(this.filteredWorkspaces) && !this.isLoading && !this.isNoWorkspaces
    },

    showConfigurationModal() {
      return !isEmpty(this.configurationModalOpenData)
    },

    filteredWorkspaces() {
      return localSearch({
        value: this.searchString,
        options: this.workspaces,
        key: [WORKSPACE_ENTITY_KEYS.NAME, WORKSPACE_ENTITY_KEYS.KEY]
      })
    }
  },

  watch: {
    userData: {
      handler(newValue) {
        if (newValue.hasAccessToWorkspaceSettingsPage) {
          this.getWorkspaces()
        } else {
          this.$router.push({ name: ROUTE_NAMES.MISSING_PAGE })
        }
      },

      deep: true,
      immediate: true
    }
  },

  methods: {
    ...mapActions('workspaces', {
      addAndSelectWorkspace: 'addAndSelectWorkspace',
      fetchWorkspaces: 'fetchWorkspaces'
    }),

    async getWorkspaces() {
      if (!this.showPage) {
        this.showPage = true
      }
      const api = new WorkspacesApiHandler()
      try {
        this.isLoading = true
        this.workspaces = await api.getWorkspaces()
        await this.$nextTick()
        this.openWorkspaceFromQuery()
      } catch (error) {
        handleError({ error })
      } finally {
        this.isLoading = false
      }
    },

    removeEditWorkspaceQueryParameters() {
      replaceQueryParameters(this.$router, this.$route, {
        [EDIT_WORKSPACE_QUERY_KEY]: undefined,
        [EDIT_WORKSPACE_ACTIVE_TAB_QUERY_KEY]: undefined
      })
    },

    openWorkspaceFromQuery() {
      const { query } = this.$route
      if (has(query, EDIT_WORKSPACE_QUERY_KEY)) {
        let activeTab = TABLE_COLUMNS_KEYS.GROUPS

        if (has(query, EDIT_WORKSPACE_ACTIVE_TAB_QUERY_KEY)) {
          const tabFromQuery = query[EDIT_WORKSPACE_ACTIVE_TAB_QUERY_KEY]
          const isTabFromQueryExist = Object.values(WS_CONFIGURATION_TABS).includes(tabFromQuery)

          if (isTabFromQueryExist) {
            activeTab = tabFromQuery
          }
        }

        const workspaceId = Number(query[EDIT_WORKSPACE_QUERY_KEY])

        const workspaceToOpen = this.workspaces.find(workspace => workspace.id === workspaceId)

        //         if (workspaceToOpen && workspaceToOpen.workspaceAdmin) {
        if (workspaceToOpen) {
          this.configurationModalOpenData = {
            workspaceId,
            activeTab
          }
          this.wsModalOpened = true
        } else {
          this.removeEditWorkspaceQueryParameters()
        }
      }
    },

    onCreateWorkspaceClick() {
      if (this.isPluginAdmin) {
        this.showWorkspaceModal = true
      }
    },

    async deleteWorkspace() {
      try {
        this.isDeleteWorkspaceLoading = true
        await workspacesApi.removeWorkspace({ id: this.workspaceToDelete.id })
        this.updateWorkspaces()
        tracker.logEvent('Deleted workspace', {
          category: EVENT_CATEGORIES.WORKSPACE_MANAGEMENT,
          value: this.workspaceToDelete.name
        })
      } catch (error) {
        handleError({ error })
      } finally {
        this.isDeleteWorkspaceLoading = false
      }
      this.onWorkspaceModalClose(false)
      this.hideConfirmDeleteWorkspace()
    },

    showConfirmDeleteWorkspace() {
      this.isConfirmDeleteShow = true
    },

    hideConfirmDeleteWorkspace() {
      if (!this.isDeleteWorkspaceLoading) {
        this.isConfirmDeleteShow = false
        this.workspaceToDelete = undefined
      }
    },

    onDeleteWorkspace() {
      this.showConfirmDeleteWorkspace()
    },

    menuActions() {
      const { ACTIONS, REMOVING } = MENU_ITEMS_GROUPS
      // workspace
      const result = [
        {
          name: ACTIONS_KEYS.EDIT,
          title: 'action.edit',
          icon: 'edit-next',
          group: ACTIONS
        },
        {
          name: ACTIONS_KEYS.REMOVE,
          title: 'action.delete',
          icon: 'delete-next',
          color: 'var(--grade-low-color-next)',
          group: REMOVING
        }
      ]
      //  if (workspace.workspaceAdmin && this.isPluginAdmin) {

      if (this.isPluginAdmin) {
        result.push({
          name: ACTIONS_KEYS.EXPORT,
          title: 'dropdown.export_csv',
          icon: 'file-blank',
          group: ACTIONS
        })
      }
      return result
    },

    onMenuActionsClick(name, workspace) {
      if (name === ACTIONS_KEYS.REMOVE) {
        this.workspaceToDelete = workspace
        this.onDeleteWorkspace()
      } else if (name === ACTIONS_KEYS.EDIT) {
        this.editWorkspace(workspace)
      } else {
        this.exportCsv(workspace)
      }
    },

    editWorkspace(workspace, activeTab = TABLE_COLUMNS_KEYS.GROUPS) {
      const { id } = workspace

      replaceQueryParameters(this.$router, this.$route, {
        [EDIT_WORKSPACE_QUERY_KEY]: id,
        [EDIT_WORKSPACE_ACTIVE_TAB_QUERY_KEY]: activeTab
      })

      this.configurationModalOpenData = {
        workspaceId: id,
        activeTab
      }
      this.workspaceToEdit = this.workspaces.find(ws => ws.id === id)

      if (this.workspaceToEdit) {
        trackWorkspaceFormOpenedEvent({
          isNewlyCreated: false,
          tab: activeTab,
          role: this.userRoleForTracking
        })
        this.wsModalOpened = true
      }
    },

    async exportCsv(workspace) {
      try {
        const response = await objectivesApi.getCsvExport({
          workspaceId: workspace.id
        })
        FileSaver.saveAs(response.data, response.filename)
      } catch (error) {
        handleError({ error })
      }
    },

    onWorkspaceCreated(createdWorkspace) {
      saveLastSelectedWorkspace(createdWorkspace.id)

      this.showWorkspaceModal = false
      showNotify({ title: this.$t('workspaces.workspace_created') })

      this.updateWorkspaces(createdWorkspace)
    },

    onWorkspaceModalClose(reloadWorkspaces = true) {
      this.removeEditWorkspaceQueryParameters()

      this.workspaceToEdit = undefined
      this.configurationModalOpenData = {}
      this.wsModalOpened = false

      if (reloadWorkspaces) {
        this.updateWorkspaces()
      }
    },

    updateWorkspaces(createdWorkspace = {}) {
      if (!isEmpty(createdWorkspace)) {
        this.addAndSelectWorkspace(createdWorkspace)

        this.workspaces.push({
          ...createdWorkspace,
          usersCount: 1,
          groupsCount: 0,
          intervalsCount: 5
        })

        trackWorkspaceFormOpenedEvent({
          isNewlyCreated: true,
          tab: this.$t('field.groups.title', 1, { locale: 'en' }),
          role: this.userRoleForTracking
        })

        this.editWorkspace(createdWorkspace)
      } else {
        this.fetchWorkspaces()
        this.getWorkspaces()
      }
    }
  }
})
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/app-table-helpers';

.wp-WorkspacesPage {
  padding-bottom: $page-bottom-padding;
  font-family: $system-ui;
}

.wp-Toolbar {
  display: flex;
  gap: 16px;
  align-items: center;
}

.wp-MainContent {
  width: 100%;
  max-width: $page-width-lg;
  padding-left: $page-left-padding;
  padding-right: $page-right-padding;
  font-family: $system-ui;
  margin-top: 7px;
}

.wp-InteractionCell {
  display: inline-block;
  color: $primary-color-next;
  font-style: normal;
  font-size: $fs-14;
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.wp-VisibilityIconCell {
  display: flex;
  align-items: center;
}

.wp-Actions {
  @extend %app-table-hidden-items;
}

.wp-Table {
  --head-padding-top: 16px;
}
</style>
