<template>
  <div class="rul-Loader">
    <SkeletonItem class="rul-Loader_Title" width="378px" />

    <div class="rul-Loader_Text">
      <SkeletonItem v-for="n in 5" :key="n" :size="XS" :width="n < 5 ? '100%' : '70%'" />
    </div>

    <SkeletonItem border-radius="24px" class="rul-Loader_Hero" height="376px" width="100%" />

    <SkeletonItem
      class="rul-Loader_Title"
      color="var(--grade-high-color-next)"
      height="20px"
      width="28px"
    />

    <div class="rul-Loader_List">
      <SkeletonItem v-for="n in 5" :key="n" :size="XS" :width="`${120 + n * 15}px`" />
    </div>

    <SkeletonItem
      class="rul-Loader_Title"
      color="var(--primary-color-next)"
      height="20px"
      width="108px"
    />

    <div class="rul-Loader_List">
      <SkeletonItem v-for="n in 5" :key="n" :size="XS" :width="`${180 - n * 15}px`" />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ReleaseUpdatesLoader'
})
</script>

<script setup>
import { SKELETON_SIZES } from '@/utils/components-configurations/skeleton-item'

import SkeletonItem from '@/components/ui/SkeletonLoaders/SkeletonItem'

const { XS } = SKELETON_SIZES
</script>

<style lang="scss" scoped>
.rul-Loader {
  padding: 40px 40px 40px var(--iframe-content-left-offset);
}

.rul-Loader_Text {
  display: grid;
  gap: 5px;
}

.rul-Loader_List {
  display: grid;
  gap: 10px;
  margin-bottom: 35px;
}

.rul-Loader_Hero {
  margin: 40px 0;
}

.rul-Loader_Title {
  margin-bottom: 17px;
}
</style>
