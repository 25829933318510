import { tracker } from '@/tracking/amplitude'
import { OBJECTIVE_TYPES, TYPE_ID_NAMES } from '@/utils/objective-types'

import { USERS_QUERY_KEYS } from '@/views/workspaces/settings/plugin-users/users-query-params'

export const EVENT_CATEGORIES = {
  ROADMAP: 'roadmap',
  SETTINGS: 'settings',
  VISIT: 'Visit',
  MAIN_NAVIGATION: 'main navigation',
  OKR_MANAGEMENT: 'OKR management',
  GRID: 'Grid',
  CYCLE_MANAGEMENT: 'Cycle management',
  FEEDBACK: 'feedback',
  ERRORS: 'Errors',
  FILTERING: 'Filtering',
  EXPORT: 'Export',
  ALIGNMENT_NAVIGATION: 'alignment navigation',
  CONFLUENCE_GADGET: 'Confluence gadget',
  EXTERNAL_RESOURCES: 'External resources',
  ONBOARDING: 'onboarding',
  WORKSPACE_MANAGEMENT: 'Workspace management',
  GROUP_MANAGEMENT: 'Group management',
  USER_MANAGEMENT: 'User management',
  LEVEL_MANAGEMENT: 'level management',
  PERSONAL_SETTINGS: 'personal settings',
  DASHBOARD: 'dashboard',
  COMMENTS: 'comments',
  NOTIFICATIONS: 'notifications',
  BILLING: 'billing'
}
export const EVENT_SOURCES = {
  GRID: 'grid',
  FORM: 'form',
  HOMEPAGE: 'homepage',
  FILTER: 'filter',
  VIEW_TYPE: 'View type',
  SUPPORT: 'support section',
  ONBOARDING: 'onboarding',
  LIST: 'list',
  NEWLY_CREATED: 'newly created',
  GROUPS_SECTION: 'groups section',
  ADD_GROUP_MODAL: 'add group modal',
  CREATE_SUBGROUP_BUTTON: 'create subgroup button',
  GROUP_TAB_MENU: 'group modal tab menu',
  WS_GROUP_TAB_GRID: 'ws group tab grid',
  GROUP_SETTINGS: 'group settings',
  WS_GROUPS_TAB: 'ws groups tab',
  WS_USERS_TAB: 'ws users tab',
  GROUP_USER_TAB: 'group user tab',
  GROUP_LIST: 'group list',
  GROUP_FORM: 'group form',
  GLOBAL_GROUPS_ALL_USERS_TAB: 'Global groups all users tab',
  USER_PROFILE_PAGE: 'user profile page',
  SETTINGS: 'settings',
  OKR_FORM: 'OKR form',
  WS_MODAL_TAB_MENU: 'ws modal tab menu',
  DASHBOARD_GENERAL: 'general',
  DASHBOARD_PERFORMANCE: 'performance',
  DASHBOARD_CUSTOM: 'custom',
  DASHBOARD: 'dashboard',
  TABLE: 'table',
  ADMINISTRATOR_TAB: 'administrator tab',
  INVITATION_FORM: 'invitation form'
}

export const EVENT_SUBCATEGORIES = {
  SINGLE: 'single',
  BULK: 'bulk',
  FILTERS: 'Filters',
  DASHBOARD: 'Dashboard',
  HOME_SCREEN: 'Home screen',
  PARENT_GROUP: 'parent group',
  USER: 'user',
  TEAM: 'team',
  CUSTOM_DASHBOARD: 'Custom dashboard'
}

export const EVENT_NAMES = {
  FILTER_APPLIED: 'Filter applied',
  CHANGE_FAVORITES: 'change favorites',
  SETTINGS_OPENED: 'settings opened',
  SECTION_OPENED: 'section opened'
}

export const FILTER_LABELS_FOR_TRACKING = {
  GROUP: 'group',
  GROUPS: 'groups',
  USER: 'user',
  ROLE: 'role',
  STATUS: 'status',
  INTERVALS: 'intervals',
  OWNERS: 'owners',
  [USERS_QUERY_KEYS.GROUP_IDS]: 'group',
  [USERS_QUERY_KEYS.WORKSPACE_IDS]: 'workspace',
  [USERS_QUERY_KEYS.ACCOUNT_IDS]: 'user',
  [USERS_QUERY_KEYS.ROLE_IDS]: 'role'
}

export const EVENT_SECTIONS = {
  WORKSPACE: 'workspace',
  GROUPS_AND_USERS: 'groups and users',
  GROUP_MODAL: 'group modal',
  ADD_GROUP_MODAL: 'add group modal',
  DASHBOARD: 'dashboard',
  BILLING: 'billing',
  OBJECTIVES: 'objectives'
}

export const TAB_NAMES_FOR_TRACKING = {
  GROUPS: 'groups',
  USERS: 'users',
  SETTINGS: 'settings',
  INTERVALS: 'intervals',
  SINGLE: 'single',
  BULK: 'bulk',
  NONE: 'none',
  GENERAL: 'general',
  PERFORMANCE: 'performance',
  CUSTOM: 'custom'
}

export const MODE_NAMES_FOR_TRACKING = {
  WORKSPACE: 'workspace',
  GROUP: 'group',
  PLUGIN: 'plugin',
  KEEP_USERS: 'keep users',
  DELETE_USERS: 'delete users',
  ADD_TO_GROUP: 'add to group',
  ADD_TO_WORKSPACE: 'add to workspace',
  BULK_ADD_TO_WORKSPACE: 'bulk add to workspace',
  EDIT: 'edit',
  NEW: 'new',
  MAIN: 'main',
  REPLY: 'reply'
}

export const TRACKING_UNKNOWN = 'unknown'
export const TRACKING_NONE = 'none'

export const TRACKING_SHOW_ALL = 'show all'
export const trackWorkspaceFormOpenedEvent = ({
  role = TRACKING_UNKNOWN,
  tab = TRACKING_UNKNOWN,
  isNewlyCreated = false,
  source = undefined
} = {}) => {
  const resolvedSource = isNewlyCreated ? EVENT_SOURCES.NEWLY_CREATED : EVENT_SOURCES.LIST
  tracker.logEvent('ws form opened', {
    category: EVENT_CATEGORIES.WORKSPACE_MANAGEMENT,
    source: source || resolvedSource,
    tab: tab.toLowerCase(),
    role
  })
}

export const trackCreateGlobalGroupModalOpenedEvent = ({
  role = TRACKING_UNKNOWN,
  source = TRACKING_UNKNOWN
}) => {
  tracker.logEvent('Opened create group from', {
    category: EVENT_CATEGORIES.GROUP_MANAGEMENT,
    role,
    source
  })
}

export const trackManageGlobalGroupModalOpenedEvent = ({
  source = TRACKING_UNKNOWN,
  tab = TRACKING_UNKNOWN,
  role = TRACKING_UNKNOWN,
  workspaces = TRACKING_UNKNOWN
} = {}) => {
  tracker.logEvent('group mgm form opened', {
    category: EVENT_CATEGORIES.GROUP_MANAGEMENT,
    source,
    tab,
    role,
    workspaces
  })
}

export const trackAddRemoveGroupToWorkspaceEvent = ({
  subcategory = TRACKING_UNKNOWN,
  source = TRACKING_UNKNOWN,
  label = TRACKING_UNKNOWN,
  role = TRACKING_UNKNOWN,
  eventName = 'Group added to workspace'
} = {}) => {
  tracker.logEvent(eventName, {
    category: EVENT_CATEGORIES.GROUP_MANAGEMENT,
    subcategory,
    source,
    label,
    role
  })
}

export const trackLinkJiraGroupsEvent = ({
  source = TRACKING_UNKNOWN,
  role = TRACKING_UNKNOWN
} = {}) => {
  tracker.logEvent('Jira group linked', {
    category: EVENT_CATEGORIES.GROUP_MANAGEMENT,
    source,
    role
  })
}

export const trackForceSyncJiraGroups = ({
  source = TRACKING_UNKNOWN,
  role = TRACKING_UNKNOWN
} = {}) => {
  tracker.logEvent('Force jira group sync', {
    category: EVENT_CATEGORIES.GROUP_MANAGEMENT,
    source,
    role
  })
}

export const trackOpenAddUserModalEvent = ({
  role = TRACKING_UNKNOWN,
  mode = TRACKING_UNKNOWN,
  tab = TRACKING_UNKNOWN,
  source = TRACKING_UNKNOWN
} = {}) => {
  tracker.logEvent('Add user form opened', {
    category: EVENT_CATEGORIES.USER_MANAGEMENT,
    role,
    mode,
    tab,
    source
  })
}

export const trackAddUserEvent = ({
  mode = MODE_NAMES_FOR_TRACKING.ADD_TO_GROUP,
  selectedUsers = [],
  source = TRACKING_UNKNOWN
} = {}) => {
  tracker.logEvent('User added', {
    category: EVENT_CATEGORIES.USER_MANAGEMENT,
    mode,
    value: selectedUsers.length,
    source
  })
}
export const trackAddUserEventBulk = ({
  mode = MODE_NAMES_FOR_TRACKING.ADD_TO_GROUP,
  synced = false,
  source = TRACKING_UNKNOWN
} = {}) => {
  const bulkOption = synced ? 'link jira groups' : 'import users'
  tracker.logEvent('User added', {
    category: EVENT_CATEGORIES.USER_MANAGEMENT,
    mode,
    bulkOption,
    source
  })
}

export const trackRemoveUserEvent = ({
  mode = TRACKING_UNKNOWN,
  source = TRACKING_UNKNOWN
} = {}) => {
  tracker.logEvent('User removed', {
    category: EVENT_CATEGORIES.USER_MANAGEMENT,
    mode,
    source
  })
}

export const trackApplySettingsPagesFilterEvent = ({
  section = TRACKING_UNKNOWN,
  tab = TRACKING_UNKNOWN,
  label = TRACKING_UNKNOWN
} = {}) => {
  tracker.logEvent(EVENT_NAMES.FILTER_APPLIED, {
    category: EVENT_CATEGORIES.FILTERING,
    section,
    tab,
    label
  })
}

export const trackLabelCreatedEvent = ({
  source = TRACKING_UNKNOWN,
  label = TRACKING_UNKNOWN
} = {}) => {
  tracker.logEvent('Label created', {
    category: EVENT_CATEGORIES.SETTINGS,
    source,
    label
  })
}

export const trackUserOpenedDashboardsEvent = ({ tab, mode, value, grouped }) => {
  const payload = {
    category: EVENT_CATEGORIES.DASHBOARD,
    tab,
    mode,
    value,
    grouped
  }
  tracker.logEvent('dashboard opened', payload)
}

export const trackDeleteCustomDashboardEvent = ({ id, isPluginAdmin }) => {
  const role = isPluginAdmin ? 'plugin admin' : undefined
  tracker.logEvent('dashboard deleted', {
    category: EVENT_CATEGORIES.DASHBOARD,
    id,
    role
  })
}

export const trackManualUpdateGradeEvent = ({ source, confidenceLevel, id, issueType, value }) => {
  const category = EVENT_CATEGORIES.OKR_MANAGEMENT

  let label = confidenceLevel

  if (issueType === TYPE_ID_NAMES[OBJECTIVE_TYPES.KR]) {
    const labelByConfidenceLevel = {
      0: 'auto',
      1: 'on track',
      2: 'behind',
      3: 'at risk'
    }

    label = labelByConfidenceLevel[confidenceLevel] || 'unknown label'
  }

  tracker.logEvent('updated grade', {
    category,
    label,
    id,
    source,
    issueType,
    value: Number(value)
  })
}
