<template>
  <div :class="{ 'udt-Loader-with-platform': showPlatformsCell }" class="udt-Loader">
    <div v-for="row in ROWS" :key="row" class="udt-Loader_Row">
      <div class="udt-Cell">
        <SkeletonItem
          :appearance="SKELETON_APPEARANCES.SQUARE"
          :size="SM"
          border-radius="3px"
          height="14px"
          width="14px"
        />
      </div>
      <div class="udt-Cell">
        <SkeletonItem v-if="row.id !== 0" :appearance="SKELETON_APPEARANCES.CIRCLE" :size="SM" />
        <SkeletonItem :size="XS" :width="row.name" />
      </div>
      <div class="udt-Cell">
        <SkeletonItem
          v-for="n in row.id ? row.groups : 1"
          :key="n"
          :color="row.id ? getRandomGroupColor() : '#f4f5f7'"
          :height="row.id ? '24px' : '16px'"
          :width="row.id ? '24px' : '41px'"
          border-radius="6px"
        />
      </div>
      <div class="udt-Cell">
        <SkeletonItem
          v-for="n in row.id ? row.privateWs : 1"
          :key="n"
          :height="row.id ? '24px' : '16px'"
          :width="row.id ? '24px' : '108px'"
          border-radius="6px"
        />
      </div>
      <div class="udt-Cell">
        <SkeletonItem
          v-for="n in row.id ? row.publicWs : 1"
          :key="n"
          :height="row.id ? '24px' : '16px'"
          :width="row.id ? '24px' : '103px'"
          border-radius="6px"
        />
      </div>
      <div v-if="showPlatformsCell" class="udt-Cell">
        <UserPlatformsCell v-if="row.id !== 0" :platforms="row.platforms" />
        <SkeletonItem v-else :size="XS" width="53px" />
      </div>
      <div class="udt-Cell">
        <SkeletonItem :size="XS" :width="row.role" />
      </div>
      <div class="udt-Cell" />
    </div>
    <div class="udt-AddUser">
      <SkeletonItem :size="SM" />
      <SkeletonItem :size="XS" width="65px" />
    </div>
    <div class="udt-FooterNav">
      <SkeletonItem height="32px" width="92px" />
      <SkeletonItem :size="XS" width="65px" />
    </div>
  </div>
</template>

<script setup>
import {
  SKELETON_APPEARANCES,
  SKELETON_SIZES
} from '@/utils/components-configurations/skeleton-item'
import { getRandomGroupColor } from '@/utils/global-groups'
import { JIRA_PLATFORM_ID, WEB_APP_PLATFORM_ID } from '@/utils/platforms-helpers'

import SkeletonItem from '@/components/ui/SkeletonLoaders/SkeletonItem'
import UserPlatformsCell from '@/views/workspaces/settings/plugin-users/UserPlatformsCell'

defineOptions({
  name: 'UsersTableLoader'
})

const props = defineProps({
  showPlatformsCell: {
    type: Boolean
  },

  maxRows: {
    type: Number,
    default: 11
  }
})

const { SM, XS } = SKELETON_SIZES

const ROWS = [
  {
    id: 0,
    name: '90px',
    groups: 1,
    privateWs: 3,
    publicWs: 3,
    role: '122px',
    action: '24px',
    platforms: [JIRA_PLATFORM_ID]
  },
  {
    id: 1,
    name: '110px',
    groups: 3,
    privateWs: 2,
    publicWs: 3,
    role: '40px',
    action: '24px',
    platforms: [JIRA_PLATFORM_ID, WEB_APP_PLATFORM_ID]
  },
  {
    id: 2,
    name: '60px',
    groups: 3,
    privateWs: 3,
    publicWs: 3,
    role: '70px',
    action: '24px',
    platforms: [WEB_APP_PLATFORM_ID]
  },
  {
    id: 3,
    name: '150px',
    groups: 1,
    privateWs: 4,
    publicWs: 1,
    role: '40px',
    action: '24px',
    platforms: [WEB_APP_PLATFORM_ID]
  },
  {
    id: 4,
    name: '100px',
    groups: 4,
    privateWs: 4,
    publicWs: 4,
    role: '40px',
    action: '24px',
    platforms: [JIRA_PLATFORM_ID, WEB_APP_PLATFORM_ID]
  },
  {
    id: 5,
    name: '100px',
    groups: 3,
    privateWs: 2,
    publicWs: 2,
    role: '40px',
    action: '24px',
    platforms: [JIRA_PLATFORM_ID]
  },
  {
    id: 6,
    name: '100px',
    groups: 1,
    privateWs: 2,
    publicWs: 3,
    role: '40px',
    action: '24px',
    platforms: [JIRA_PLATFORM_ID]
  },
  {
    id: 7,
    name: '100px',
    groups: 3,
    privateWs: 2,
    publicWs: 1,
    role: '40px',
    action: '24px',
    platforms: [WEB_APP_PLATFORM_ID]
  },
  {
    id: 8,
    name: '100px',
    groups: 4,
    privateWs: 2,
    publicWs: 4,
    role: '40px',
    action: '24px',
    platforms: [JIRA_PLATFORM_ID]
  },
  {
    id: 9,
    name: '100px',
    groups: 2,
    privateWs: 4,
    publicWs: 2,
    role: '40px',
    action: '24px',
    platforms: [JIRA_PLATFORM_ID, WEB_APP_PLATFORM_ID]
  },
  {
    id: 10,
    name: '100px',
    groups: 1,
    privateWs: 3,
    publicWs: 3,
    role: '40px',
    action: '24px',
    platforms: [JIRA_PLATFORM_ID, WEB_APP_PLATFORM_ID]
  }
].splice(0, props.maxRows)
</script>

<style lang="scss" scoped>
.udt-Loader_Row {
  height: 45px;
  display: flex;
  align-items: center;
  &:first-child {
    height: 24px;
    padding-bottom: 6px;
  }
}

.udt-Cell {
  display: flex;
  align-items: center;

  &:not(:first-child) {
    gap: 8px;
  }

  &:first-child {
    flex: 0 0 28px;
  }

  &:nth-child(2) {
    gap: 4px;
    flex: 1 1 100%;
  }
  &:nth-child(3) {
    flex: 0 0 160px;
    gap: 4px;
  }

  &:nth-child(4) {
    flex: 0 0 140px;

    gap: 4px;
  }

  &:nth-child(5) {
    flex: 0 0 140px;

    gap: 4px;
  }

  &:not(.udt-Loader-with-platform &) {
    &:nth-child(6) {
      flex: 0 0 146px;
      gap: 8px;
    }

    &:nth-child(7) {
      flex: 0 0 24px;
      gap: 8px;
    }
  }

  .udt-Loader-with-platform & {
    &:nth-child(6) {
      flex: 0 0 94px;
    }

    &:nth-child(7) {
      flex: 0 0 146px;
      gap: 8px;
    }

    &:nth-child(8) {
      flex: 0 0 24px;
      gap: 8px;
    }
  }
}
.udt-FooterNav {
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0 32px;
}
.udt-AddUser {
  display: flex;
  align-items: center;
  gap: 8px;
}
</style>
