import { isNull } from 'lodash'

import { KR_VALUE_TYPES } from '@/utils/objectives'

export const currentTargetVisibility = objective => {
  const isNotBinary = objective.fieldTypeId !== KR_VALUE_TYPES.BINARY
  const percentValueOriginalValue =
    objective.fieldTypeId === KR_VALUE_TYPES.PERCENT && objective.originalValue !== 0
  const percentValueTargetValue =
    objective.fieldTypeId === KR_VALUE_TYPES.PERCENT && objective.targetValue !== 100
  const numberValueOriginalValue =
    objective.fieldTypeId === KR_VALUE_TYPES.NUMBER && objective.originalValue !== 0
  const numberValueTargetValue =
    objective.fieldTypeId === KR_VALUE_TYPES.NUMBER && objective.targetValue !== 100
  const isValidValues = !isNull(objective.targetValue) && !isNull(objective.originalValue)
  return (
    isNotBinary &&
    (percentValueOriginalValue ||
      percentValueTargetValue ||
      numberValueOriginalValue ||
      numberValueTargetValue) &&
    isValidValues
  )
}

export const PLUG_ICONS = {
  ASSIGNEE: 'cell-plug-assignee',
  DATE: 'cell-plug-date',
  SELECT: 'cell-plug-select',
  COMMENT: 'cell-plug-comment',
  COMMON: 'cell-plug-common'
}
