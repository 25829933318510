<template>
  <div ref="wrapperReference" class="nl-List">
    <template v-if="isNotificationsExist">
      <div
        v-for="key in notificationsGroups"
        :key="key"
        class="nl-List_Category"
        data-testid="notifications-category"
      >
        <AppTitle
          v-if="notificationsGroups.length > 1"
          :level="6"
          class="nl-CategoryTitle"
          data-testid="category-title"
          disable-margin
        >
          {{ $t(`in_app_notifications.category.${key}`) }}
        </AppTitle>
        <NotificationItem
          v-for="notification in itemsByGroup(key)"
          :key="notification.id"
          :item="notification"
          @mark-as-read="markAsRead"
          @update-notifications-statuses="$emit('update-notifications-statuses')"
        />
      </div>
      <InAppNotificationsLoader v-if="loading" />
      <div v-show="!loading" ref="observerReference" class="nl-Observer" />
    </template>
    <div
      v-else
      :class="{
        'nl-NoNotifications': !loading
      }"
      data-testid="no-notifications"
    >
      <InAppNotificationsLoader v-if="loading" />

      <template v-else>
        <ObjectivesEmptyHero />
        <div class="nl-NoNotifications_Text">
          {{ $t('in_app_notifications.no_notifications') }}
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
import { isEmpty } from 'lodash'
import { onBeforeUnmount, onMounted, ref, computed, useTemplateRef, provide } from 'vue'

import { inAppNotificationsStateInjectionKey } from '@/utils/injection-keys'

import ObjectivesEmptyHero from '@/components/objectives/ObjectivesEmptyHero'
import AppTitle from '@/components/ui/AppTitle/AppTitle'
import NotificationItem from '@/components/ui/InAppNotifications/NotificationItem'
import InAppNotificationsLoader from '@/components/ui/SkeletonLoaders/InAppNotificationsLoader'

defineOptions({
  name: 'NotificationsList'
})

const props = defineProps({
  items: {
    type: Object,
    required: true
  },

  readedNotifications: {
    type: Array,
    required: true
  },

  loading: {
    type: Boolean
  }
})

const emit = defineEmits([
  'update:readedNotifications',
  'load-more',
  'update-notifications-statuses',
  'close-modal'
])

provide(inAppNotificationsStateInjectionKey, {
  closeModal: () => emit('close-modal')
})

const markAsRead = id => {
  emit('update:readedNotifications', [...props.readedNotifications, id])
}

const observerReference = ref(null)

const intersectionObserver = ref(null)
const initIntersectionObserver = () => {
  intersectionObserver.value = new IntersectionObserver(
    entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          emit('load-more')
        }
      })
    },
    {
      threshold: 1
    }
  )
  intersectionObserver.value.observe(observerReference.value)
}
const wrapperReference = useTemplateRef(null)

const notificationsGroups = computed(() => {
  return Object.keys(props.items)
})

const isNotificationsExist = computed(() => {
  return !isEmpty(props.items)
})

onMounted(() => {
  if (isNotificationsExist.value) {
    initIntersectionObserver()
  }
})

const destroyObserver = () => {
  if (intersectionObserver.value) {
    intersectionObserver.value.disconnect()
    intersectionObserver.value = null
  }
}

const itemsByGroup = key => {
  if (props.items[key]) {
    return props.items[key].filter(item => item.attributes.length || item.childNotifications.length)
  }

  return []
}

defineExpose({
  destroyObserver
})

onBeforeUnmount(() => {
  destroyObserver()
})
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';
.nl-List {
  @include styled-native-scrollbar();
  position: relative;
  overflow-y: auto;
  width: 100%;
  padding: 0 30px 30px;
  box-sizing: border-box;
  height: calc(100% - 95px); // 95px modal header height
}

.nl-List_Category {
  display: grid;
  gap: 14px;
  width: 100%;
}

.nl-CategoryTitle {
  color: $dark-3;
  font-family: $system-ui;
  font-style: normal;
  font-size: $fs-12;
  line-height: 16px;
  margin-bottom: 10px;
  font-weight: fw('bold');
  &:not(.nl-List_Category:first-child &) {
    margin-top: 14px;
  }
}

.nl-Observer {
  height: 1px;
  width: 100%;
  pointer-events: none;
}

.nl-NoNotifications {
  margin-top: 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 29px;
}

.nl-NoNotifications_Text {
  font-family: $system-ui;
  font-style: normal;
  font-weight: fw('normal');
  font-size: $fs-32;
  line-height: 40px;
  text-align: center;
  color: $dark-1;
}
</style>
