<template>
  <LockMessage>
    <slot />
  </LockMessage>
</template>

<script setup>
import { getCurrentInstance, h } from 'vue'

import { LOCK_MESSAGE_TYPES } from '@/utils/components-configurations/lock-message'
import { getScopeId } from '@/utils/render-function-helpers'

import AppIcon from '@/components/ui/AppIcon/AppIcon'

defineOptions({
  name: 'LockMessage'
})

const props = defineProps({
  iconName: {
    type: String,
    default: 'lock-next'
  },

  type: {
    type: String,
    default: LOCK_MESSAGE_TYPES.LOCK,
    validator: value => Object.values(LOCK_MESSAGE_TYPES).includes(value)
  }
})
const LockMessage = (_, { slots }) => {
  const scopeId = getScopeId(getCurrentInstance())

  return h(
    'div',
    {
      class: { 'lm-LockMessage': true, [`lm-LockMessage-${props.type}`]: props.type }
    },
    [
      h(AppIcon, {
        ...scopeId,
        iconName: props.iconName,
        class: 'lm-LockMessage_Icon',
        width: '24',
        height: '24'
      }),
      h(slots.default)
    ]
  )
}
</script>

<style lang="scss" scoped>
/* this eslint plugin won't work with vue 3 render function in <script setup> */
/* eslint-disable vue-scoped-css/no-unused-selector */
.lm-LockMessage {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: var(--padding, (10px 0));
  font-family: $system-ui;
  font-style: normal;
  font-weight: fw('bold');
  font-size: $fs-12;
  line-height: 16px;

  &-info {
    background: $grey-3-next;
    color: var(--color, #{$dark-3});
  }

  &-warning {
    background: rgba(var(--grade-medium-color-rgb-next), 0.1);
  }

  &-lock,
  &-warning {
    color: var(--color, #{$dark-2});
  }

  &-info,
  &-warning {
    padding: 20px;
    border-radius: $border-radius-md-next;
  }
}

.lm-LockMessage_Icon {
  flex: 0 0 auto;
  color: var(--icon-color, var(--color, #{$dark-2}));
}
/* eslint-enable vue-scoped-css/no-unused-selector */
</style>
