import { isEqual } from 'lodash'

import { DEFAULT_VALUE_FOR_FILTER, SELECT_ALL_VALUE } from '@/utils/okr-elements/filters'

export const CLEAR_SELECTION = 'CLEAR_SELECTION'
export const BOTTOM_INFO_PANEL = 'BOTTOM_INFO_PANEL'
export const SELECTS_WITH_CHECKBOX_ITEMS = [
  {
    id: 0,
    text: 'filter.clear_selection',
    action: CLEAR_SELECTION
  }
]

export const getBottomFixedItemsClearSelection = (data, key) => {
  return data[key].includes(SELECT_ALL_VALUE) ? [] : SELECTS_WITH_CHECKBOX_ITEMS
}

export const getBottomFixedItemsClearSelectionForCustomFieldFilter = ({ filterValue }) => {
  return filterValue.includes(SELECT_ALL_VALUE) ? [] : SELECTS_WITH_CHECKBOX_ITEMS
}

export const clearSelection = (data, key) => {
  data[key] = DEFAULT_VALUE_FOR_FILTER
}

export const isClearSelectionAction = action => action === CLEAR_SELECTION

export const getFilterBottomItems = ({ filtersValues = {}, filterKey = null } = {}) => {
  if (isEqual(filtersValues[filterKey], DEFAULT_VALUE_FOR_FILTER)) {
    return []
  }
  return SELECTS_WITH_CHECKBOX_ITEMS
}
