<template>
  <!--
    If slot #trigger used we should use trigger-selector from scoped slot
    for attaching select to passed trigger into slot
  -->
  <slot
    :on-trigger-click="onTriggerClick"
    :show-dropdown="showDropdown"
    :trigger-selector="`oec-Trigger-${uid}`"
    name="trigger"
  >
    <AppButton :class="{ [`oec-Trigger-${uid}`]: true }" :size="buttonSize" class="oec-Trigger">
      <slot />
    </AppButton>
  </slot>
  <AppDroplist
    v-if="type === TYPES.DEFAULT_NEXT"
    :follow-cursor="followCursor"
    :model-value="showDropdown"
    dropdown-width="200px"
    @hidden="showDropdown = false"
  >
    <div class="oec-Dropdown">
      <div
        :data-auto-testid="selectOptionsListTestId"
        :data-testid="selectOptionsListTestId"
        class="oec-Dropdown_Items"
      >
        <div
          v-for="option in objectiveLevelsForCreate"
          :key="option.id"
          class="oec-Dropdown_Option"
          @click="onOptionClick(option.id)"
        >
          <OkrIcon :objective="levelToOkrIconElement(option)" />
          <span class="oec-OptionName">{{ option.name }}</span>
        </div>
      </div>
    </div>
  </AppDroplist>
  <AppSelect
    v-else
    ref="select"
    :append-to="appendTo"
    :dropdown-width="dropdownWidth"
    :offset="[0, 0]"
    :options="objectiveLevelsForCreate"
    :position="dropdownPosition"
    :select-options-list-test-id="selectOptionsListTestId"
    :to-selector="`.oec-Trigger-${uid}`"
    :width-as-parent="dropdownWidthAsParent"
    has-fixed-parent
    item-label="name"
    item-value="id"
    max-width="220px"
    @hide="showDropdown = false"
    @open="showDropdown = true"
    @update:model-value="$emit('on-create-okr-element', $event)"
  >
    <template #option-label="{ option }">
      <OkrTypeFieldOption :option="option" />
    </template>
  </AppSelect>
</template>

<script>
import { defineComponent } from 'vue'

import { getObjectiveLevelsForCreate, levelToOkrIconElement } from '@/utils/objectives'
import { uid } from '@/utils/uid'

import AppDroplist from '@/components/AppDroplist'
import OkrTypeFieldOption from '@/components/form/OkrTypeFieldOption'
import OkrIcon from '@/components/objectives/items/OkrIcon'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppSelect from '@/components/ui/AppSelect/AppSelect'
import { OKR_ELEMENT_CREATOR_OPTIONS_LIST_TEST_ID } from '@/components/ui/AppSelect/jest-helpers'

const TYPES = {
  DEFAULT: 'default',
  DEFAULT_NEXT: 'default-next'
}

export default defineComponent({
  name: 'OkrElementCreator',
  components: { OkrTypeFieldOption, AppDroplist, OkrIcon, AppSelect, AppButton },

  props: {
    buttonSize: {
      type: String,
      default: 'md'
    },

    dropdownPosition: {
      type: String,
      default: 'bottom-start'
    },

    objectiveLevels: {
      type: Array,
      required: true
    },

    dropdownWidth: {
      type: String,
      default: '188px'
    },

    dropdownWidthAsParent: {
      type: Boolean
    },

    appendTo: {
      type: String,
      default: ''
    },

    type: {
      type: String,
      default: TYPES.DEFAULT,
      validator: v => Object.values(TYPES).includes(v)
    },

    followCursor: {
      type: [String, Boolean],
      default: 'initial'
    },

    selectOptionsListTestId: {
      type: String,
      default: OKR_ELEMENT_CREATOR_OPTIONS_LIST_TEST_ID
    }
  },

  emits: {
    'on-create-okr-element': null
  },

  data() {
    return {
      uid: uid(),
      showDropdown: false
    }
  },

  computed: {
    TYPES: () => TYPES,
    objectiveLevelsForCreate() {
      return getObjectiveLevelsForCreate(this.objectiveLevels)
    }
  },

  methods: {
    levelToOkrIconElement,

    onTriggerClick() {
      if (this.type === TYPES.DEFAULT_NEXT) {
        this.showDropdown = !this.showDropdown
      }
    },

    onOptionClick(id) {
      this.$emit('on-create-okr-element', id)
      this.showDropdown = false
    },

    /** @public */
    hideDropdown() {
      if (this.type === TYPES.DEFAULT) {
        const { select } = this.$refs
        if (select) {
          select.hideDropdown()
        }
      }
      if (this.type === TYPES.DEFAULT_NEXT) {
        this.showDropdown = false
      }
    }
  }
})
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';
@import '~@/assets/styles/form-add-nested-item.scss';

.oec-Dropdown {
  border-radius: inherit;
  overflow: hidden;
}

.oec-Dropdown_Items {
  max-height: 220px;
  overflow-y: auto;
  @include styled-native-scrollbar();
}

.oec-Dropdown_Option {
  @extend %nested-item-option;
}

.oec-OptionName {
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
