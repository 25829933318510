import { mergeAttributes } from '@tiptap/core'
import Table from '@tiptap/extension-table'
import { VueNodeViewRenderer } from '@tiptap/vue-3'

import { DATA_OBOARD_UID } from '@/utils/uid'

// eslint-disable-next-line import/extensions
import CustomTableTiptap from '@/components/TipTapExtensions/Table/CustomTableTiptap.vue'

export const TiptapTable = Table.extend({
  name: 'table',
  tableRole: 'table',
  addAttributes() {
    return {
      ...this.parent?.(),
      [DATA_OBOARD_UID]: {
        default: '',
        parseHTML: element => element.getAttribute(DATA_OBOARD_UID),
        renderHTML: attributes => {
          return {
            [DATA_OBOARD_UID]: attributes[DATA_OBOARD_UID]
          }
        }
      }
    }
  },
  parseHTML() {
    return [
      {
        // Do not change this name its reserved name for correct parsing HTML. If you change this name it will be break all saved table in each objective
        tag: 'table',
        getAttrs: dom => ({
          [DATA_OBOARD_UID]: dom.getAttribute(DATA_OBOARD_UID)
        })
      }
    ]
  },
  renderHTML({ HTMLAttributes }) {
    // Do not change this name and structure
    return ['table', mergeAttributes(HTMLAttributes), ['tbody', 0]]
  },
  addNodeView() {
    return VueNodeViewRenderer(CustomTableTiptap)
  }
})

export default TiptapTable
