export const INSTANCE_STATES = {
  PRE_INIT: {
    id: '0',
    blockApp: false
  },
  ACTIVE: {
    id: '1',
    blockApp: false
  },
  CONNECTION_IN_PROGRESS: {
    id: '2',
    blockApp: true
  },
  DISCONNECTION_IN_PROGRESS: {
    id: '4',
    blockApp: true
  },
  CONNECTION_LOST: {
    id: '5',
    blockApp: true
  },
  BULK_OPERATION_IN_PROGRESS: {
    id: '6',
    blockApp: true
  }
}

export const getValidatedInstanceStateId = ({ instanceStateId }) => {
  if (!instanceStateId) {
    return INSTANCE_STATES.ACTIVE.id
  }

  if (
    Object.values(INSTANCE_STATES)
      .map(({ id }) => id)
      .includes(instanceStateId)
  ) {
    return instanceStateId
  }

  return INSTANCE_STATES.ACTIVE.id
}

export const isInstanceStateBlocked = ({ instanceStateId }) => {
  const validatedInstanceStateId = getValidatedInstanceStateId({ instanceStateId })
  const blockAppInstanceStates = Object.values(INSTANCE_STATES)
    .filter(({ blockApp }) => blockApp)
    .map(({ id }) => id)

  return blockAppInstanceStates.includes(validatedInstanceStateId)
}
