export const LIST_ITEM_TYPES = {
  PRIMARY: 'primary',
  PRIMARY_NEXT: 'primary-next',
  SECONDARY: 'secondary'
}

export const LIST_ITEM_PLACEMENTS = {
  UNKNOWN: 'unknown',
  OKR_ELEMENT_FORM: 'okr-element-form'
}
