<template>
  <div
    :class="{
      'agc-Wrapper-empty': isEmptyValues,
      'agc-Wrapper-horizontal': horizontal
    }"
    class="agc-Wrapper"
  >
    <div
      v-for="item in chartItems"
      :key="item.key"
      :class="{ [`agc-Item-${item.className}`]: true }"
      class="agc-Item"
    >
      <div v-if="!hideLabels" class="agc-Labels">
        <div class="agc-Labels_Item">
          <ObjectiveStatus
            :status="item.status"
            no-border
            style="--padding: 4px 0 2px; --background-color: transparent"
          >
            <template #default="{ label }"> {{ label }}: {{ item.value }}</template>
          </ObjectiveStatus>
        </div>
      </div>
      <IndicatorPanel
        :background-color="`rgba(var(${item.rgb}), 0.2)`"
        :color="`var(${item.color})`"
        :indicator-width="item.progress"
        style="--height: 4px"
      />
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line import/order
import { OKR_STATUSES } from '@/utils/objectives'

const OPTIONS = {
  NOT_STARTED: {
    color: '--grade-abandoned-color',
    rgb: '--grade-abandoned-color-rgb',
    key: 'notStarted',
    status: OKR_STATUSES.NOT_STARTED,
    className: 'notStarted'
  },

  AT_RISK: {
    color: '--grade-low-color-next',
    rgb: '--grade-low-color-rgb-next',
    key: 'atRisk',
    status: OKR_STATUSES.AT_RISK,
    className: 'atRisk'
  },

  BEHIND: {
    color: '--grade-medium-color-next',
    rgb: '--grade-medium-color-rgb-next',
    key: 'behind',
    status: OKR_STATUSES.BEHIND,
    className: 'behind'
  },

  ON_TRACK: {
    color: '--grade-high-color-next',
    rgb: '--grade-high-color-rgb-next',
    key: 'onTrack',
    status: OKR_STATUSES.ON_TRACK,
    className: 'onTrack'
  },

  CLOSED: {
    color: '--grade-closed-color',
    rgb: '--grade-closed-color-rgb',
    key: 'closed',
    status: OKR_STATUSES.CLOSED,
    className: 'closed'
  },

  ABANDONED: {
    color: '--grade-abandoned-color',
    rgb: '--grade-abandoned-color-rgb',
    key: 'abandoned',
    status: OKR_STATUSES.ABANDONED,
    className: 'abandoned'
  }
}
</script>
<script setup>
import { isNumber } from 'lodash'
import { computed } from 'vue'

import ObjectiveStatus from '@/components/objectives/ObjectiveStatus'
import IndicatorPanel from '@/components/ui/IndicatorPanel/IndicatorPanel'

defineOptions({
  name: 'AppGradeChart'
})

const props = defineProps({
  gradeData: {
    type: Object,
    required: true,
    validator: v => {
      const isValidLength = Object.values(v).length === Object.values(OPTIONS).length
      const isValidKeys = Object.keys(v).every(key =>
        Object.values(OPTIONS)
          .map(({ key }) => key)
          .includes(key)
      )
      const isValidValues = Object.values(v).every(value => isNumber(value))

      return isValidLength && isValidKeys && isValidValues
    }
  },

  hideLabels: {
    type: Boolean
  },

  horizontal: {
    type: Boolean
  }
})
const DEFAULT_VALUE = 0

const chartItems = computed(() => {
  const sumOfValues = Object.values(props.gradeData).reduce((acc, val) => {
    acc += val
    return acc
  }, DEFAULT_VALUE)

  return Object.values(OPTIONS).map(item => {
    return {
      ...item,
      value: props.gradeData[item.key] || DEFAULT_VALUE,
      progress: ((props.gradeData[item.key] || DEFAULT_VALUE) / sumOfValues) * 100 || DEFAULT_VALUE
    }
  })
})

const templates = computed(() => {
  return chartItems.value.reduce(
    (acc, val) => {
      const labelVariable = props.horizontal ? '--label-min-width' : '--label-min-height'
      return {
        bars: `${acc.bars} minmax(var(--bar-min-height), ${val.value}fr)`,
        labels: `${acc.labels} minmax(var(${labelVariable}), ${val.value}fr)`
      }
    },
    { bars: '', labels: '' }
  )
})

const isEmptyValues = computed(() => {
  return chartItems.value.every(item => item.value === 0)
})
</script>

<style lang="scss" scoped>
.agc-Wrapper {
  display: flex;
  gap: 12px;
  --bar-min-height: 6px;
  --label-min-height: 20px;
  --label-min-width: max-content;

  &:not(&-horizontal) {
    max-height: var(--max-height, 200px);
  }

  &-horizontal {
    flex-direction: column;
    width: 100%;
  }
}

.agc-Labels {
  display: grid;
  .agc-Wrapper-empty & {
    align-self: flex-end;
  }

  .agc-Wrapper-empty.agc-Wrapper-horizontal & {
    align-self: flex-start;
  }
}

$bar-width: 6px;

.agc-Labels {
  gap: 4px;
  &:not(.agc-Wrapper-horizontal &) {
    grid-template-rows: v-bind('templates.labels');
  }

  .agc-Wrapper-horizontal & {
    grid-template-columns: v-bind('templates.labels');
  }

  .agc-Wrapper & {
    display: flex;
  }
}

.agc-Labels_Item {
  line-height: 16px;
  &:not(:first-child):not(:last-child) {
    &:not(.agc-Wrapper-horizontal &) {
      align-self: center;
    }

    .agc-Wrapper-horizontal & {
      justify-self: center;
    }
  }

  &:first-child {
    &:not(.agc-Wrapper-horizontal &) {
      align-self: flex-start;
    }

    .agc-Wrapper-horizontal & {
      justify-self: flex-start;
    }
  }
  &:last-child {
    &:not(.agc-Wrapper-horizontal &) {
      align-self: flex-end;
    }

    .agc-Wrapper-horizontal & {
      justify-self: flex-end;
    }
  }
}
.agc-Item {
  @media screen and (max-width: 1080px) {
    &-notStarted {
      grid-area: d;
    }
  }
}
</style>
