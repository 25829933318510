<template>
  <div class="ot-Controls">
    <RoadmapExport
      is-in-navigation
      @toggle-show-area="$emit('toggle-show-area', $event)"
      @export-roadmap="$emit('export-roadmap', $event)"
    />

    <div class="ot-Controls_Divider" />

    <div :class="{ 'ot-Controls_Move-only-arrows': !showTodayButton }" class="ot-Controls_Move">
      <AppButton
        class="ot-Controls_Button ot-Controls_Button-move"
        data-testid="move-button-left"
        icon="chevron-left-next"
        remove-padding
        type="tertiary-next"
        @click="moveTimelines('left')"
      />

      <AppButton
        v-if="showTodayButton"
        class="ot-Controls_Button ot-Controls_Button-today"
        data-testid="today-button"
        remove-padding
        type="tertiary-next"
        @click="onScrollToTodayClick"
      >
        {{ $t('filter.today') }}
      </AppButton>

      <AppButton
        class="ot-Controls_Button ot-Controls_Button-move"
        data-testid="move-button-right"
        icon="chevron-right-next"
        remove-padding
        type="tertiary-next"
        @click="moveTimelines('right')"
      />
    </div>

    <AppDroplist v-model="calendarOpened" :theme="DROP_LIST_THEMES.LIGHT" position="top-start">
      <template #button>
        <AppButton
          :class="{ 'ot-Controls_Button-active': calendarOpened }"
          class="ot-Controls_Button ot-Controls_Button-calendar"
          icon="calendar-outline-next"
          remove-padding
          type="tertiary-next"
        />
      </template>

      <DatePicker
        :clearable="false"
        :disabled-date="disabledDates"
        :format="dateFormat"
        :lang="datepickerOptions"
        inline
        @update:model-value="onDateSelected"
      />
    </AppDroplist>

    <div class="ot-Controls_Views">
      <AppRadioGroup
        :model-value="activeView"
        :options="views"
        name="views"
        style="--option-padding: 0"
        type="primary-next"
        @update:model-value="setActiveView"
      >
        <template #item-label="{ item }">
          <span
            :class="{ 'ot-ControlsViewsLabel_Content-with-controls': isActiveOption(item) }"
            class="ot-ControlsViewsLabel_Content"
          >
            <AppButton
              v-if="isActiveOption(item)"
              :disable="isMinScale"
              class="ot-ViewsControlButton"
              icon="minus-small"
              remove-padding
              size="sm"
              type="ghost"
              @click="updateScale()"
            />

            {{ item.label }}

            <AppButton
              v-if="isActiveOption(item)"
              :disable="isMaxScale"
              class="ot-ViewsControlButton"
              icon="plus-small"
              remove-padding
              size="sm"
              type="ghost"
              @click="updateScale(true)"
            />
          </span>

          <span
            v-if="isActiveOption(item)"
            :style="{ width: scalePercent }"
            class="ot-ControlsViewsLabel_Bar"
          />
        </template>
      </AppRadioGroup>
    </div>

    <AppButton
      :icon="fullscreenIcon"
      class="ot-Controls_Button ot-Controls_Button-fullScreen"
      data-testid="fullscreen-button"
      remove-padding
      type="tertiary-next"
      @click="onFullscreenClick"
    />
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { capitalize, isEmpty, isEqual } from 'lodash'
import { defineComponent } from 'vue'
import DatePicker from 'vue2-datepicker'
import { mapActions, mapGetters, mapState } from 'vuex'

import { tracker } from '@/tracking/amplitude'
import { EVENT_CATEGORIES } from '@/tracking/amplitude-helpers'
import { DROP_LIST_THEMES } from '@/utils/components-configurations/app-droplist'
import { dateFormat, datepickerLang } from '@/utils/date'
import { updateStorageByKey } from '@/utils/persist'
import { getRangePercentage } from '@/utils/range-percentage'
import {
  ONE_DAY_WIDTH_BY_VIEW,
  TIMELINES_TABLE_VIEW_SETTINGS,
  TIMELINES_VIEWS
} from '@/utils/roadmap'
import {
  getResolvedRestoredValue,
  ROADMAP_VIEW_SCALE,
  USER_SETTINGS_MAPPER
} from '@/utils/user-settings'

import AppDroplist from '@/components/AppDroplist'
import RoadmapExport from '@/components/objectives/roadmap/RoadmapExport'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppRadioGroup from '@/components/ui/AppRadioGroup/AppRadioGroup'

import 'vue2-datepicker/index.css'

const LS_ROADMAP_VIEW_SCALE_KEY = 'roadmapViewScale'

const MOVE_OFFSET_BY_VIEW = {
  [TIMELINES_VIEWS.WEEKS]: 7,
  [TIMELINES_VIEWS.MONTHS]: 30,
  [TIMELINES_VIEWS.QUARTERS]: 90
}

export default defineComponent({
  name: 'OkrTimelinesControls',
  components: { RoadmapExport, AppDroplist, AppRadioGroup, AppButton, DatePicker },
  props: {
    activeView: {
      type: String,
      default: TIMELINES_VIEWS.QUARTERS
    },

    showTodayButton: {
      type: Boolean
    },

    minMaxDate: {
      type: Object,
      required: true
    },

    oneDayWidth: {
      type: Number,
      required: true
    }
  },

  emits: {
    'scroll-to-today': null,
    'full-size': null,
    'set-active-view': null,
    'update:oneDayWidth': null,
    'toggle-show-area': null,
    'export-roadmap': null,
    'scroll-to-point': null,
    'move-timelines': null
  },

  data() {
    return {
      dateFormat,
      calendarOpened: false
    }
  },

  computed: {
    DROP_LIST_THEMES: () => DROP_LIST_THEMES,

    ...mapState('system', {
      fullscreen: state => state.fullscreen
    }),

    ...mapState('intervals', {
      selectedIntervalsNames: state => state.selectedIntervalsNames
    }),

    ...mapGetters('system', {
      roadmapViewScale: 'roadmapViewScale'
    }),

    scalePercent() {
      const { MIN, MAX, STEP } = this.settingsByView
      return getRangePercentage({
        min: MIN,
        max: MAX,
        step: STEP,
        current: this.oneDayWidth
      })
    },

    isMaxScale() {
      return this.oneDayWidth >= this.settingsByView.MAX
    },

    isMinScale() {
      return this.oneDayWidth <= this.settingsByView.MIN
    },

    datepickerOptions() {
      return datepickerLang()
    },

    settingsByView() {
      return ONE_DAY_WIDTH_BY_VIEW[this.activeView]
    },

    fullscreenIcon() {
      return this.fullscreen ? 'full-screen-off-next' : 'full-screen-on-next'
    },

    views() {
      return Object.values(TIMELINES_TABLE_VIEW_SETTINGS).map(view => {
        return {
          ...view,
          label: this.$t(view.label)
        }
      })
    },

    resolvedOneDayWidth() {
      const resolvedRestoredRoadmapViewScale = getResolvedRestoredValue({
        valueFromSettings: this.roadmapViewScale,
        localStorageKey: LS_ROADMAP_VIEW_SCALE_KEY
      })

      return (
        resolvedRestoredRoadmapViewScale?.[this.activeView] ||
        ONE_DAY_WIDTH_BY_VIEW[this.activeView].DEFAULT
      )
    }
  },

  watch: {
    oneDayWidth: {
      async handler(newValue) {
        // this.$emit('update:oneDayWidth', newValue)

        const resolvedRestoredRoadmapViewScale = getResolvedRestoredValue({
          valueFromSettings: this.roadmapViewScale,
          localStorageKey: LS_ROADMAP_VIEW_SCALE_KEY
        })

        const payload = {
          ...resolvedRestoredRoadmapViewScale,
          [this.activeView]: newValue
        }

        updateStorageByKey(LS_ROADMAP_VIEW_SCALE_KEY, {
          ...payload
        })

        if (!isEqual({ ...payload }, this.roadmapViewScale)) {
          await this.updateUserSettings({
            [USER_SETTINGS_MAPPER[ROADMAP_VIEW_SCALE]]: { ...payload }
          })
        }
      }
    }
  },

  mounted() {
    this.$nextTick(() => {
      // this.oneDayWidth = this.resolvedOneDayWidth
      this.$emit('update:oneDayWidth', this.resolvedOneDayWidth)
    })
  },

  methods: {
    ...mapActions('system', {
      toggleFullscreen: 'toggleFullscreen',
      updateUserSettings: 'updateUserSettings'
    }),

    isActiveOption(option) {
      return option.value === this.activeView
    },

    updateScale(isIncrease = false) {
      if (isIncrease && !this.isMaxScale) {
        // this.oneDayWidth += this.settingsByView.STEP
        this.$emit('update:oneDayWidth', this.oneDayWidth + this.settingsByView.STEP)
      }

      if (!isIncrease && !this.isMinScale) {
        // this.oneDayWidth -= this.settingsByView.STEP
        this.$emit('update:oneDayWidth', this.oneDayWidth - this.settingsByView.STEP)
      }
    },

    disabledDates(date) {
      if (!isEmpty(this.minMaxDate)) {
        return (
          date.getTime() > new Date(this.minMaxDate.maxDate).getTime() ||
          date.getTime() < new Date(this.minMaxDate.minDate).getTime()
        )
      } else {
        return false
      }
    },

    onDateSelected(date) {
      const offset = dayjs(date).diff(this.minMaxDate.minDate, 'days') * this.oneDayWidth
      this.calendarOpened = false
      this.$emit('scroll-to-point', offset)
    },

    moveTimelines(direction) {
      const offset = MOVE_OFFSET_BY_VIEW[this.activeView] * this.oneDayWidth
      this.$emit('move-timelines', direction === 'left' ? -offset : offset)
    },

    setActiveView(view) {
      tracker.logEvent('set scale', {
        category: EVENT_CATEGORIES.ROADMAP,
        subcategory: capitalize(view),
        cycle: this.selectedIntervalsNames
      })

      this.$emit('set-active-view', view)

      this.$nextTick(() => {
        // this.oneDayWidth = this.resolvedOneDayWidth
        this.$emit('update:oneDayWidth', this.resolvedOneDayWidth)
      })
    },

    onScrollToTodayClick() {
      tracker.logEvent('navigated to current date', {
        category: EVENT_CATEGORIES.ROADMAP
      })

      this.$emit('scroll-to-today')
    },

    onFullscreenClick() {
      this.toggleFullscreen()
    }
  }
})
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';
@import '~@/assets/styles/mind-road-map-footer';

.ot-Controls {
  @extend %actions-wrapper;
  z-index: 4;
}

.ot-Controls_Button {
  @extend %button-styles;

  &-today {
    @extend %center-button;
  }

  &-active {
    @extend %active-button-styles;
  }
}

.ot-Controls_Move {
  @extend %buttons-group-styles;
  &-only-arrows {
    gap: 8px;
  }
}

.ot-Controls_Views {
  display: flex;
  align-items: center;
  gap: 8px;

  &:deep(.arg-Option) {
    position: relative;
    overflow: hidden;
  }
}

.ot-ControlsViewsLabel_Content {
  display: flex;
  align-items: center;

  &:not(&-with-controls) {
    padding: 5px 6px;
  }
}

.ot-ControlsViewsLabel_Bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  border-radius: $border-radius-xs;
  background-color: $primary-color-next;
  transition: width $transition-fast ease-in-out;
}

.ot-ViewsControlButton {
  border-radius: $border-radius-sm;
}

.ot-Controls_Divider {
  width: 1px;
  margin: 0 8px;
  height: 32px;
  background-color: $grey-2-next;
}
</style>
