<template>
  <div :class="classes">
    <AppIcon
      :class="{ 'ltp-Icon-hidden': hideIcon }"
      class="ltp-Icon"
      height="24"
      icon-name="link-to-parent"
      width="24"
    />
    <slot>{{ $t('objective.link_to_parent_okr') }}</slot>
  </div>
</template>

<script setup>
import { computed } from 'vue'

import { TYPES } from '@/utils/components-configurations/link-to-parent-button'

import AppIcon from '@/components/ui/AppIcon/AppIcon'

defineOptions({
  name: 'LinkToParentButton'
})

const props = defineProps({
  type: {
    type: String,
    default: TYPES.PRIMARY,
    validator: v => Object.values(TYPES).includes(v)
  },

  isActive: {
    type: Boolean
  },

  isError: {
    type: Boolean
  },

  disabled: {
    type: Boolean
  },

  hideIcon: {
    type: Boolean
  }
})

const classes = computed(() => {
  return {
    'ltp-Button': true,
    [`ltp-Button-${props.type}`]: props.type,
    'ltp-Button-active': props.isActive,
    'ltp-Button-error': props.isError,
    'ltp-Button-disabled': props.disabled
  }
})
</script>

<style lang="scss" scoped>
%secondary-active-state {
  background-color: $grey-3-next;
  color: $primary-color-next;
  border-color: $grey-2-next;
}

.ltp-Button {
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: $system-ui;
  color: $dark-3;
  font-style: normal;
  font-weight: fw('regular');
  font-size: $fs-14;
  line-height: 20px;
  cursor: pointer;

  &-primary {
    height: 44px;
    padding: 10px;
    transition: color $transition-normal;

    @media (any-hover: hover) {
      &:hover {
        color: $primary-color-next;
      }
    }
  }

  &-secondary {
    padding: 18px;
    height: 64px;
    border: 2px solid $grey-3-next;
    border-radius: $border-radius-md-next;
    flex-direction: row-reverse;
    justify-content: space-between;
    transition: color, border-color, background-color, $transition-normal;

    &:hover {
      @extend %secondary-active-state;
    }
  }

  &-active#{&}-secondary {
    @extend %secondary-active-state;
  }

  &-error#{&}-secondary {
    border-color: $grade-low-color-next;
    background-color: $white;
    color: $dark-3;
  }

  &-disabled {
    cursor: not-allowed;
    @media (any-hover: hover) {
      &:hover {
        color: inherit;
      }
    }
  }
}

.ltp-Icon {
  &-hidden {
    visibility: hidden;
  }

  .ltp-Button-secondary & {
    border-color: $primary-color;
  }

  .ltp-Button-error & {
    color: $primary-color-next;
  }
}
</style>
