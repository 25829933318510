import { createApp, h, resolveComponent } from 'vue'

import { getPluginOptions } from '@jira/app'
import AppAuth from '@jira/layout/AppAuth'
import { CONFLUENCE_APP_PLUGIN_OPTIONS } from '@root/template-options-keys'

export const createAuthApp = (component, rootElement, additionalProps) => {
  const { refreshToken, accessToken, pluginType, ...rest } = getPluginOptions(
    CONFLUENCE_APP_PLUGIN_OPTIONS,
    rootElement
  )

  return createApp({
    name: 'AppAuthWrapper',
    components: {
      AppAuth,
      'main-component': component
    },

    // use AppAuth as layout(root component) for all entrypoints
    render: () =>
      h(
        resolveComponent('AppAuth'),
        {
          accessToken,
          refreshToken,
          pluginType,
          pluginOptions: {
            pluginType,
            ...rest
          }
        },
        {
          default: props => h(resolveComponent('main-component'), { ...props, ...additionalProps })
        }
      )
  })
}
