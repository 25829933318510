<template>
  <div>
    <div v-if="!isEmptyCustomReports" class="cdl-DashboardHeader">
      <div class="cdl-DashboardHeader_LeftSide">
        <OkrFilterSelect
          :bottom-fixed-items="getBottomFixedItemsClearSelection(FILTERS_KEYS.OWNER_IDS)"
          :model-value="filters[FILTERS_KEYS.OWNER_IDS]"
          :options="assigneesOptions"
          :search-function="getAssignees"
          item-label="displayName"
          item-value="accountId"
          n-selected-label="filter.owners"
          prepend-icon="user-next"
          @update:options="onAssigneesUpdate"
          @update:model-value="selectListFilterValue(FILTERS_KEYS.OWNER_IDS, $event)"
        >
          <template #option-label="{ option }">
            <OwnerFieldOption :option="option" label-key="displayName" />
          </template>
          <template #bottom-fixed-items="{ bottomFixedItems }">
            <div v-for="item in bottomFixedItems" :key="item.id">
              <BottomFixedSelectItem
                v-if="isClearSelectionAction(item.action)"
                @click="bottomFixedItemsHandle(item.action, FILTERS_KEYS.OWNER_IDS)"
              >
                {{ $t(item.text) }}
              </BottomFixedSelectItem>
            </div>
          </template>
        </OkrFilterSelect>
        <Search
          v-model="filters.searchString"
          :placeholder="$t('placeholder.search')"
          width="160"
          @update:model-value="onUpdateFilters"
        />
        <slot name="filter-append" />
      </div>
      <AppButton
        v-if="!isReadMode"
        class="cdl-DashboardHeader_NewReport"
        icon="plus-next"
        type="primary-next"
        @click="openCreateEditModal"
      >
        {{ $t('action.create_dashboard') }}
      </AppButton>
    </div>

    <SettingsPagesEmptyState
      v-if="isEmptyCustomReports"
      hero-height="160"
      hero-icon="no-levels-hero"
      hero-width="142"
    >
      <template #title> {{ t('dashboard.no_results') }} </template>
      <template #subtitle> {{ t('dashboard.no_report_subtitle') }} </template>

      <template v-if="!isReadMode" #action>
        <AppButton
          height="24"
          icon="plus-next"
          type="primary-next"
          width="24"
          @click="showCreateEditModal = true"
        >
          {{ t('action.create_dashboard') }}
        </AppButton>
      </template>
    </SettingsPagesEmptyState>
    <NoSearchResults v-else-if="!dashboardListLoading && isEmpty(dashboardList)">
      {{ t('dashboard.no_matching_filters') }}
      <AppButton class="cdl-BtnResetFilters" size="sm" type="link-next" @click="resetFilters">
        {{ t('search_criteria.reset') }}
      </AppButton>
    </NoSearchResults>
    <AppTable
      v-else
      :columns="getTableColumns"
      :data="dashboardList"
      :hover-row="tableHoverRow"
      :loading="dashboardListLoading"
      no-border
      offset-left="var(--page-left-padding)"
      offset-right="var(--page-right-padding)"
      sticky-header
      type="primary-next"
      @row-click="onChangeCheckboxListItem($event.id)"
    >
      <template #header-cell="{ column }">
        <CustomDashboardTableHeaderCell
          v-model:sort-by="filters.sortBy"
          v-model:sort-key="filters.sortField"
          :column="column"
          class="cdl-TableHeaderCell"
          @update:list="onUpdateFilters"
        >
          {{ column }}
          <template #column-title-favorites>
            <AppIcon height="24" icon-name="add-to-favorites-black" width="24" />
          </template>
        </CustomDashboardTableHeaderCell>
      </template>
      <template #cell="{ columnKey, item, index }">
        <template v-if="columnKey === TABLE_COLUMNS_KEYS.CHECKBOX">
          <AppCheckboxListItem
            :model-value="selectedDashboard"
            :val="item.id"
            disable-toggling
            rounded-checkmark
            @click="onChangeCheckboxListItem(item.id)"
          />
        </template>
        <template v-if="columnKey === TABLE_COLUMNS_KEYS.FAVORITES">
          <div class="cdl-ActionFavoriteButton">
            <AppIcon
              :class="{
                'cdl-FavoriteIcon-active': animatedItemIds.includes(item.id),
                'cdl-FavoriteIcon-disable': isLoadingFavorite(item.id) || isReadMode
              }"
              :icon-name="item.favorite ? 'favorite-filled' : 'add-to-favorites-black'"
              class="cdl-FavoriteIcon"
              height="24"
              width="24"
              @click="toggleFavorite(item)"
            />
          </div>
        </template>
        <template v-if="columnKey === TABLE_COLUMNS_KEYS.NAME">
          <span
            :class="{ 'cdl-TableCellText-link': !isReadMode }"
            class="cdl-TableCellText oboard-truncated-text"
            @click="openDashboardDetails(item.id)"
          >
            {{ item.name }}
          </span>
        </template>
        <template v-if="columnKey === TABLE_COLUMNS_KEYS.PROGRESS">
          <span class="cdl-TableCellText">
            {{ getSimplifiedNumber(item.progress)[PLURALIZATION_KEYS.SIMPLIFIED] }}%
          </span>
        </template>
        <template v-if="columnKey === TABLE_COLUMNS_KEYS.OWNER">
          <UserNameCell
            :item="getDashboardOwner(item)"
            class="cdl-TableUserNameCell"
            item-label="ownerName"
          />
        </template>
        <template v-else-if="columnKey === TABLE_COLUMNS_KEYS.VISIBILITY_ICON">
          <div class="cdl-VisibilityIconCell">
            <AppIcon v-if="item.private" height="24" icon-name="lock-next" width="24" />
          </div>
        </template>
        <template v-if="columnKey === TABLE_COLUMNS_KEYS.VISIBILITY">
          <span v-if="item.private" class="cdl-VisibilityColumn">
            {{ t('workspaces.private') }}
          </span>
          <span v-else>{{ t('workspaces.public') }}</span>
        </template>
        <template v-if="columnKey === TABLE_COLUMNS_KEYS.ACTION">
          <!-- Actions dropplist -->
          <DropdownMenu
            :items="menuItemActions(item)"
            :offset="[0, 0]"
            :type="DROPDOWN_MENU_TYPES.DEFAULT_NEXT"
            preferred-position="left-start"
            @close="tableHoverRow = -1"
            @open="tableHoverRow = index"
            @item-click="onMenuActionsClick($event, item)"
          >
            <template #activator="{ isOpened }">
              <AppButton
                :class="{ 'cdl-ActionsButton-active': isOpened }"
                class="cdl-ActionsButton"
                icon="more-next"
                size="sm"
                type="tertiary-next"
              />
            </template>
          </DropdownMenu>
          <!-- Actions dropplist end -->
        </template>
      </template>
      <template #loading>
        <CustomDashboardTableLoader />
      </template>
      <template v-if="!isReadMode" #footer>
        <AppTableCreateButton
          data-testid="add-user-table-button"
          full-width
          icon-name="plus-next"
          @click="showCreateEditModal = true"
        >
          {{ t('action.create_dashboard') }}
        </AppTableCreateButton>
      </template>
    </AppTable>
    <AppPagination
      v-if="totalDashboardsCount"
      :current-page="currentPage"
      :items-count="totalDashboardsCount"
      :items-on-page="itemsOnPage"
      :items-shown="dashboardList.length"
      :total-page="totalPage"
      @update:items-on-page="changeItemsOnPage"
      @update:current-page="changeCurrentPage"
    />
    <template v-if="!isReadMode">
      <CustomDashboardModal
        v-model:form-model="formModel"
        v-model:show-modal="showCreateEditModal"
        @update-data="onEditDashboard({ id: $event })"
      />
      <RemoveCustomDashboard
        :dashboard-for-delete="dashboardForDelete"
        :show-confirm-delete-dialog="showConfirmDeleteDialog"
        @close="showConfirmDeleteDialog = false"
        @on-success="updateDashboardLists"
      />
      <ShareCustomDashboard
        v-model:show="isShowSharing"
        :can-edit="isOwner(dashboardItem) || isPluginAdmin"
        :dashboard-for-share="dashboardItem"
        @on-confirm="updateDashboardLists"
      />
      <DuplicateCustomDashboard
        v-model:show="showDuplicateModal"
        :dashboard-to-duplicate="dashboardToDuplicate"
        @confirm="updateDashboardLists"
      />
    </template>
  </div>
</template>

<script setup>
import { isEmpty, isEqual } from 'lodash'
import { computed, nextTick, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

import CustomDashboardApiHandler from '@/api/custom-dashboard'
import {
  clearSelection,
  isClearSelectionAction,
  SELECTS_WITH_CHECKBOX_ITEMS
} from '@/composables/bottom-fixed-items'
import { useGetPaginationData } from '@/composables/pagination'
import { ROUTE_NAMES } from '@/routes/route-helpers'
import {
  EVENT_SOURCES,
  FILTER_LABELS_FOR_TRACKING,
  TAB_NAMES_FOR_TRACKING,
  trackUserOpenedDashboardsEvent
} from '@/tracking/amplitude-helpers'
import { ACTIONS_KEYS } from '@/utils/actions-keys'
import { DROPDOWN_MENU_TYPES } from '@/utils/components-configurations/dropdown-menu'
import {
  CUSTOM_DASHBOARD_QUERY_KEYS,
  CUSTOM_DASHBOARD_SORT_TYPES,
  LAYOUT_VIEW_TYPES,
  VERTICAL
} from '@/utils/custom-dashboard-helper'
import { MENU_ITEMS_GROUPS } from '@/utils/dropdown-menu'
import { handleError } from '@/utils/error-handling'
import {
  DEFAULT_VALUE_FOR_FILTER,
  FILTERS_KEYS,
  restoreFilterValue,
  SELECT_ALL_VALUE,
  sendFilterTrackerEvent
} from '@/utils/okr-elements/filters'
import { updateStorageByKey } from '@/utils/persist'
import { getSimplifiedNumber, PLURALIZATION_KEYS } from '@/utils/pluralization'
import { replaceQueryParameters } from '@/utils/router'
import { getSelectWithSelectAllApiParameter, handleSelectInputWithSelectAll } from '@/utils/select'
import { TABLE_COLUMNS_KEYS } from '@/utils/table-columns'
import { CUSTOM_DASHBOARD_FILTERS, USER_SETTINGS_MAPPER } from '@/utils/user-settings'

import CustomDashboardModal from '@/components/dashboard/custom-dashboard/modals/CustomDashboardModal'
import DuplicateCustomDashboard from '@/components/dashboard/custom-dashboard/modals/DuplicateCustomDashboard'
import RemoveCustomDashboard from '@/components/dashboard/custom-dashboard/modals/RemoveCustomDashboard'
import ShareCustomDashboard from '@/components/dashboard/custom-dashboard/modals/ShareCustomDashboard'
import CustomDashboardTableHeaderCell from '@/components/dashboard/CustomDashboardTableHeaderCell'
import AppCheckboxListItem from '@/components/form/AppCheckboxListItem'
import OwnerFieldOption from '@/components/form/OwnerFieldOption'
import BottomFixedSelectItem from '@/components/objectives/toolbar/BottomFixedSelectItem'
import OkrFilterSelect from '@/components/objectives/toolbar/OkrFilterSelect'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppIcon from '@/components/ui/AppIcon/AppIcon'
import AppPagination from '@/components/ui/AppPagination/AppPagination'
import AppTableCreateButton from '@/components/ui/AppTableCreateButton'
import DropdownMenu from '@/components/ui/DropdownMenu/DropdownMenu'
import NoSearchResults from '@/components/ui/NoSearchResults/NoSearchResults'
import Search from '@/components/ui/Search/Search'
import SettingsPagesEmptyState from '@/components/ui/SettingsPagesEmptyState/SettingsPagesEmptyState'
import CustomDashboardTableLoader from '@/components/ui/SkeletonLoaders/CustomDashboardTableLoader'
import AppTable from '@/components/ui/Table/AppTable'
import UserNameCell from '@/views/workspaces/settings/plugin-users/UserNameCell'

defineOptions({
  name: 'CustomDashboardList'
})

const props = defineProps({
  isReadMode: {
    type: Boolean
  },

  selectedDashboard: {
    type: Array,
    default: () => []
  }
})

const emit = defineEmits({
  'update:selectedDashboard': null
})

const onChangeCheckboxListItem = value => {
  emit('update:selectedDashboard', [value])
}

const dashboardListLoading = ref(false)
const store = useStore()
const dashboardList = computed(() => store.state.dashboard.dashboardList)

const DEFAULT_SORT_VALUE = null
const DEFAULT_FILTERS = {
  ownerIds: DEFAULT_VALUE_FOR_FILTER,
  searchString: '',
  sortBy: DEFAULT_SORT_VALUE,
  sortField: DEFAULT_SORT_VALUE
}
const filters = ref({ ...DEFAULT_FILTERS })

const isEmptyFilters = computed(() => {
  return isEqual(filters.value, DEFAULT_FILTERS)
})
const resetFilters = () => {
  filters.value = { ...DEFAULT_FILTERS }
  onUpdateFilters()
}

const getBottomFixedItemsClearSelection = key => {
  return !filters.value[key].includes(SELECT_ALL_VALUE) ? SELECTS_WITH_CHECKBOX_ITEMS : []
}
const assignees = ref([])
const assigneesOptions = computed(() => {
  return [
    {
      accountId: 0,
      displayName: t('filter.all_owners'),
      iconName: 'team-next'
    },
    ...assignees.value
  ]
})

const isEmptyCustomReports = computed(
  () => !dashboardListLoading.value && isEmptyFilters.value && isEmpty(dashboardList.value)
)

const route = useRoute()

onMounted(() => {
  getInitData()
})
const customDashboardFilters = computed(() => store.getters['system/customDashboardFilters'])

const getInitData = async () => {
  dashboardListLoading.value = true
  if (!props.isReadMode) {
    await restoreQueryParams()
  }
  assignees.value = await getAssignees()
  await validateOwnersParam()
  await getDashboardList()
  await nextTick()

  trackUserOpenedDashboardsEvent({
    tab: TAB_NAMES_FOR_TRACKING.CUSTOM,
    value: dashboardCount.value
  })
}

const validateOwnersParam = async () => {
  const { ownerIds } = filters.value
  if (ownerIds.length > 0) {
    const assigneesIds = assignees.value.map(assignee => assignee.accountId)
    const filteredOwners = ownerIds.filter(ownerId => assigneesIds.includes(ownerId))

    if (filteredOwners.length) {
      filters.value.ownerIds = filteredOwners
    } else {
      filters.value.ownerIds = DEFAULT_VALUE_FOR_FILTER
    }
  }
}

const getAssignees = async (searchString = null) => {
  const api = new CustomDashboardApiHandler()
  let result = []
  try {
    const { users } = await api.getDashboardUsers({
      searchString,
      selectedIds:
        searchString === null
          ? getSelectWithSelectAllApiParameter(filters.value[FILTERS_KEYS.OWNER_IDS])
          : null
    })
    result = users
  } catch (error) {
    handleError({ error })
  }
  return result
}
const onAssigneesUpdate = newValue => {
  // filter added in computed 'All selected' option
  let { result } = handleSelectInputWithSelectAll(newValue, assignees.value)

  assignees.value = result
}

const selectListFilterValue = (filterKey, newValue) => {
  const oldValue = filters.value[filterKey]
  // eslint-disable-next-line prefer-const
  let { valueChanged, result } = handleSelectInputWithSelectAll(newValue, oldValue)
  valueChanged = valueChanged || !isEqual(oldValue, result)

  if (valueChanged) {
    filters.value[filterKey] = result
  }
  onUpdateFilters()

  sendFilterTrackerEvent({
    tab: EVENT_SOURCES.DASHBOARD_CUSTOM,
    section: EVENT_SOURCES.DASHBOARD,
    value: result,
    label: FILTER_LABELS_FOR_TRACKING.OWNERS
  })
}

const bottomFixedItemsHandle = (action, key) => {
  if (isClearSelectionAction(action)) {
    clearSelection(filters.value, key)
    onUpdateFilters()
  }
}

const dashboardCount = computed(() => store.state.dashboard.dashboardTotalCount)
watch(
  () => dashboardCount.value,
  () => {
    totalDashboardsCount.value = dashboardCount.value
  }
)

const getDashboardList = async () => {
  try {
    dashboardListLoading.value = true
    await Promise.all([
      store.dispatch('dashboard/getDashboardList'),
      store.dispatch('dashboard/getFavoriteList')
    ])
  } catch (error) {
    handleError({ error })
  } finally {
    dashboardListLoading.value = false
  }
}

const updateDashboardLists = () => {
  getDashboardList()
}

const { t } = useI18n()

const tableHoverRow = ref(-1)

const { EDIT, REMOVE, DUPLICATE, ADD_TO_FAVORITES, REMOVE_FROM_FAVORITES, SHARE } = ACTIONS_KEYS
const onMenuActionsClick = (name, item) => {
  if (name === REMOVE) {
    onRemoveDashboard(item)
  } else if (name === EDIT) {
    onEditDashboard(item)
  } else if (name === DUPLICATE) {
    onDuplicateDashboard(item)
  } else if (name === SHARE) {
    onShareDashboard(item)
  } else if (name === ADD_TO_FAVORITES || name === REMOVE_FROM_FAVORITES) {
    toggleFavorite(item)
  }
}
const menuItemActions = item => {
  const { EDITING, REMOVING } = MENU_ITEMS_GROUPS
  const result = []
  if (isOwner(item) || isPluginAdmin.value) {
    result.push({
      name: EDIT,
      title: 'action.edit',
      icon: 'edit-next',
      group: EDITING
    })
  }
  result.push({
    name: DUPLICATE,
    title: 'dropdown.copy',
    icon: 'duplicate-next',
    group: EDITING
  })

  if (isOwner(item) || isPluginAdmin.value) {
    result.push(
      {
        name: SHARE,
        title: 'action.share',
        icon: 'share',
        group: EDITING
      },
      {
        name: REMOVE,
        title: 'action.remove',
        icon: 'delete-next',
        color: 'var(--grade-low-color-next)',
        group: REMOVING
      }
    )
  }
  result.push({
    name: item.favorite ? REMOVE_FROM_FAVORITES : ADD_TO_FAVORITES,
    title: item.favorite ? 'action.remove_from_favorites' : 'action.add_to_favorites',
    icon: item.favorite ? 'favorite-filled' : 'add-to-favorites-black',
    disabled: isLoadingFavorite(item.id),
    group: EDITING
  })

  return result
}

const currentUser = computed(() => store.state.system.userData || {})
const isOwner = item => {
  return item?.ownerId === currentUser.value.userAccountId
}
const isPluginAdmin = computed(() => store.state.pluginOptions.isPluginAdmin)

const { FAVORITES, NAME, PROGRESS, OWNER, VISIBILITY, ACTION, VISIBILITY_ICON, CHECKBOX } =
  TABLE_COLUMNS_KEYS

const getTableColumns = computed(() => {
  if (props.isReadMode) {
    return [
      {
        key: CHECKBOX,
        width: 24,
        sortable: false,
        align: 'center'
      },
      ...TABLE_COLUMNS
    ]
  }
  return [
    ...TABLE_COLUMNS,
    {
      key: ACTION,
      width: 24
    }
  ]
})

const TABLE_COLUMNS = [
  {
    key: FAVORITES,
    width: 52,
    sortable: true
  },
  {
    title: t('users.table_header_name'),
    key: NAME,
    width: 'auto',
    sortable: true
  },
  {
    title: t('objectives.table_header_grade'),
    key: PROGRESS,
    width: 152,
    sortable: false
  },
  {
    title: t('roles.owner'),
    key: OWNER,
    width: 182,
    sortable: true
  },
  {
    key: VISIBILITY_ICON,
    width: 32
  },
  {
    title: t('workspaces.visibility'),
    key: VISIBILITY,
    width: 152,
    sortable: true
  }
]

const showConfirmDeleteDialog = ref(false)
const dashboardForDelete = ref({})
const onRemoveDashboard = ({ name, id }) => {
  dashboardForDelete.value = { name, id }
  showConfirmDeleteDialog.value = true
}

const DEFAULT_FORM_MODEL = {
  name: '',
  backgroundId: 0,
  layoutTypeId: LAYOUT_VIEW_TYPES[VERTICAL]
}

const showCreateEditModal = ref(false)
const openCreateEditModal = () => {
  showCreateEditModal.value = true
}
const formModel = ref({
  ...DEFAULT_FORM_MODEL
})

watch(
  () => showCreateEditModal.value,
  value => {
    if (!value) {
      formModel.value = {
        ...DEFAULT_FORM_MODEL
      }
      getDashboardList()
    }
  }
)

const getDashboard = async id => {
  try {
    const customDashboardApi = new CustomDashboardApiHandler()
    return await customDashboardApi.getDashboard({ id })
  } catch (error) {
    handleError({ error })
  }
}
const onEditDashboard = async payload => {
  try {
    formModel.value = await getDashboard(payload.id)
    openCreateEditModal()
  } catch (error) {
    handleError({ error })
  }
}

const router = useRouter()
const openDashboardDetails = id => {
  if (!props.isReadMode) {
    router.push({ name: ROUTE_NAMES.CUSTOM_DASHBOARD_ITEM, params: { id } })
  }
}

const loadingFavoriteIds = ref([])
const isLoadingFavorite = id => loadingFavoriteIds.value.includes(id)
const toggleFavorite = async item => {
  try {
    loadingFavoriteIds.value.push(item.id)
    await store.dispatch('dashboard/toggleFavorite', item)

    if (item.favorite) {
      setAnimatedItem(item.id)
    }
  } catch (error) {
    handleError({ error })
  } finally {
    loadingFavoriteIds.value = loadingFavoriteIds.value.filter(id => id !== item.id)
  }
}
const isShowSharing = ref(false)
const dashboardItem = ref({})
const onShareDashboard = async item => {
  dashboardItem.value = await getDashboard(item.id)
  isShowSharing.value = true
}

const getDashboardOwner = ({ ownerAvatarUrl, ownerId, ownerName }) => ({
  avatarUrl: ownerAvatarUrl,
  ownerId,
  ownerName
})

const getSortField = computed(() => {
  const sortElement = Object.values(CUSTOM_DASHBOARD_SORT_TYPES).find(
    item => item.sortBy === filters.value.sortBy && item.sortField === filters.value.sortField
  )
  return sortElement ? [sortElement.id] : null
})

const {
  START_PAGE,
  currentPage,
  itemsOnPage,
  total: totalDashboardsCount,
  totalPage
} = useGetPaginationData()

const changeItemsOnPage = value => {
  itemsOnPage.value = value
  currentPage.value = START_PAGE
  setFilters()
  getDashboardList()
}
const changeCurrentPage = page => {
  currentPage.value = page
  setFilters()
  getDashboardList()
}

const { SORT_BY, SORT_FIELD, OWNER_IDS } = CUSTOM_DASHBOARD_QUERY_KEYS

const LS_KEY = 'CUSTOM_DASHBOARD_FILTER'
const setQueryFilter = () => {
  const query = Object.values(CUSTOM_DASHBOARD_QUERY_KEYS).reduce((acc, key) => {
    const value = filters.value[key]
    if (value) {
      acc[key] = JSON.stringify(value)
    }
    return acc
  }, {})

  replaceQueryParameters(router, route, query)
  updateStorageByKey(LS_KEY, query)
}

watch(
  () => filters.value,
  () => {
    if (!props.isReadMode) {
      setQueryFilter()
    }
    currentPage.value = START_PAGE
  },
  { deep: true }
)

const onUpdateFilters = () => {
  setFilters()
  getDashboardList()
}

const setFilters = () => {
  store.dispatch('dashboard/setFilters', {
    searchString: filters.value.searchString || null,
    ownerIds: getSelectWithSelectAllApiParameter(filters.value.ownerIds),
    sort: getSortField.value,
    offset: (currentPage.value - 1) * itemsOnPage.value,
    limit: itemsOnPage.value
  })

  store.dispatch('system/updateUserSettings', {
    [USER_SETTINGS_MAPPER[CUSTOM_DASHBOARD_FILTERS]]: filters.value
  })
}

const restoreQueryParams = async () => {
  const restoredSortFieldValue = restoreFilterValue(route, SORT_FIELD, DEFAULT_SORT_VALUE)
  const restoredSortByValue = restoreFilterValue(route, SORT_BY, DEFAULT_SORT_VALUE)

  const isSortValueValid = Object.values(CUSTOM_DASHBOARD_SORT_TYPES).some(
    item => item.sortBy === restoredSortByValue && item.sortField === restoredSortFieldValue
  )

  const { sortBy, sortField, ownerIds } = customDashboardFilters.value || {}

  if (isSortValueValid) {
    filters.value.sortBy = restoredSortByValue
    filters.value.sortField = restoredSortFieldValue
  } else if (sortField && sortBy) {
    filters.value.sortBy = sortBy
    filters.value.sortField = sortField
  }

  const restoredOwnersValue = restoreFilterValue(route, OWNER_IDS, DEFAULT_SORT_VALUE)

  if (restoredOwnersValue?.length) {
    filters.value.ownerIds = restoredOwnersValue
  } else if (ownerIds) {
    filters.value.ownerIds = ownerIds
  }
  setFilters()
}

const dashboardToDuplicate = ref({})
const showDuplicateModal = ref(false)
const onDuplicateDashboard = item => {
  dashboardToDuplicate.value = item
  showDuplicateModal.value = true
}

const animatedItemIds = ref([])
const setAnimatedItem = itemId => {
  animatedItemIds.value.push(itemId)

  setTimeout(() => {
    animatedItemIds.value = animatedItemIds.value.filter(id => id !== itemId)
  }, 1500)
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/app-table-helpers';
@import '~@/assets/styles/_custom-dashboard-animations';
@import '~@/assets/styles/mixins';

.cdl-DashboardHeader {
  display: flex;
  justify-content: space-between;
  margin: 0 0 32px 0;
}

.cdl-DashboardHeader_NewReport {
  padding: 4px 16px 4px 6px;
}
.cdl-ActionsButton {
  &-active {
    background-color: $dark-2;
    color: $white;
  }

  @extend %app-table-hidden-items;
}
.cdl-ActionFavoriteButton {
  cursor: pointer;
  color: $grey-1-next;
}
.cdl-VisibilityColumn {
  display: flex;
  align-items: center;
  gap: 8px;
}
.cdl-TableCellText {
  font-weight: fw('semi-bold');
  font-size: $fs-14;
  line-height: 20px;
  color: $dark-1;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  &-link {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}
.cdl-TableUserNameCell {
  padding: 0 8px 0 0;
  &:deep(.unc-Name) {
    font-weight: fw('regular');
  }
}
.cdl-DashboardHeader_LeftSide {
  display: flex;
  align-items: center;
  gap: 8px;
}

.cdl-FavoriteIcon {
  &-active {
    @extend %energyPop;
  }
  &-disable {
    &:active {
      pointer-events: none;
    }
  }
}

.cdl-TableHeaderCell {
  display: flex;
  align-items: center;
}
.cdl-BtnResetFilters {
  margin: auto;
}
.cdl-VisibilityIconCell {
  display: flex;
}
</style>
