// export const getConnectionPoint = ({ baseRect, fromRect, toRect, zoom }) => {
//   const scale = zoom / 100
//
//   const from = {
//     x: (fromRect.right - baseRect.left) / scale,
//     y: (fromRect.top + fromRect.height / 2 - baseRect.top) / scale
//   }
//   const to = {
//     x: (toRect.left - baseRect.left) / scale,
//     y: (toRect.top + toRect.height / 2 - baseRect.top) / scale
//   }
//
//   return { from, to }
// }

import { CONTROLS_BUTTON_WIDTH } from '@/utils/memoizations'

export const CONNECTION_ARC_RADIUS = 24.5
export const CONNECTION_ARC_DIAMETER = CONNECTION_ARC_RADIUS * 2

export const TOP_ARC_DIRECTION = 1

export const BOTTOM_ARC_DIRECTION = 0

export const STABILIZER_1 = 2
export const STABILIZER_2 = 9

export const CONTROLS_BUTTON_LEFT_OFFSET = 20
export const EXPAND_BUTTON_TOP_OFFSET = 20
export const FILTER_BUTTON_TOP_OFFSET = EXPAND_BUTTON_TOP_OFFSET + 34 // 34 is the height of the + button and 10 is the margin between the buttons
export const getConnectionPoint = ({
  baseRect,
  fromRect,
  toRect,
  zoom,
  forFilteredItem = false
}) => {
  const scale = zoom / 100

  /*
  Our "Eye" button is under the "Plus" button
  But its target positions are same as the "Plus" button
  But if target of "Eye" button is against of this button target Y must be same as Y of "Eye" button and different from "Plus" button
   */

  const BUTTON_TOP_OFFSET = forFilteredItem ? FILTER_BUTTON_TOP_OFFSET : EXPAND_BUTTON_TOP_OFFSET

  let TARGET_Y_OFFSET = EXPAND_BUTTON_TOP_OFFSET
  if (forFilteredItem && fromRect.top === toRect.top) {
    TARGET_Y_OFFSET = FILTER_BUTTON_TOP_OFFSET
  }

  const HALF_BUTTON_SIZE = CONTROLS_BUTTON_WIDTH / 2

  const scaledSourceYOffset = (BUTTON_TOP_OFFSET + HALF_BUTTON_SIZE) * scale
  const scaledTargetYOffset = (TARGET_Y_OFFSET + HALF_BUTTON_SIZE) * scale
  const from = {
    x: (fromRect.right - baseRect.left) / scale,
    y: (fromRect.top + scaledSourceYOffset - baseRect.top) / scale
  }
  const to = {
    x: (toRect.left - baseRect.left) / scale,
    y: (toRect.top + scaledTargetYOffset - baseRect.top) / scale
  }

  return { from, to }
}
