<template>
  <router-view />
</template>

<script>
import { defineComponent } from 'vue'
import { mapState } from 'vuex'

import WorkspacesApiHandler from '@/api/workspaces'
import { ROUTE_PATHS } from '@/routes/route-helpers'
import { handleError } from '@/utils/error-handling'
import { restoreLastSelectedWorkspace } from '@/utils/workspaces'

export default defineComponent({
  name: 'MainPage',

  data() {
    return {
      redirectTo: undefined
    }
  },

  computed: {
    ...mapState('workspaces', {
      workspaceId: state => state.workspaceId
    })
  },

  mounted() {
    if (this.$route.path === '/') {
      if ('redirectTo' in this.$route.query) {
        this.redirectTo = this.$route.query.redirectTo
      }
      this.getWorkspaces()
    }
  },

  methods: {
    async getWorkspaces() {
      let linkToRedirect = '/workspaces'
      const workspacesApi = new WorkspacesApiHandler()
      try {
        this.workspaces = await workspacesApi.getAllUserWorkspaces()
        if (this.workspaces.length > 0) {
          const selectedWorkspaceId = this.workspaceId
          let workspaceIdToRedirect = restoreLastSelectedWorkspace(this.workspaces)

          if (
            selectedWorkspaceId >= 0 &&
            this.workspaces.findIndex(w => w.id === selectedWorkspaceId) >= 0
          ) {
            workspaceIdToRedirect = selectedWorkspaceId
          }
          if (this.redirectTo === 'first-alignment') {
            linkToRedirect = `${ROUTE_PATHS.WORKSPACES}${workspaceIdToRedirect}/${ROUTE_PATHS.OBJECTIVES}/${ROUTE_PATHS.ALIGNMENT}`
          } else {
            linkToRedirect = `${ROUTE_PATHS.WORKSPACES}${workspaceIdToRedirect}/${ROUTE_PATHS.OBJECTIVES}`
          }
        }
      } catch (error) {
        handleError({ error })
      }
      this.$router.push(linkToRedirect)
    }
  }
})
</script>

<style lang="scss" scoped>
/* styles */
</style>
