<template>
  <div class="cdm-ModalWrapper">
    <Modal
      :show="showModal"
      :size="APP_MODAL_SIZES.XL_NEXT"
      data-auto-testid="custom-dashboard-modal"
      data-testid="custom-dashboard-modal"
      hide-close
      manual-close
      scrollable-content
      @close="closeModal"
    >
      <template #loader>
        <SavingIndicator ref="savingIndicatorTop" :type="SAVING_INDICATOR_TYPES.LINE" />
      </template>
      <template #header>
        <div class="cdm-ModalHeader">
          <SavingIndicator ref="savingIndicator" />
          <AppButton
            v-if="formModel.id"
            class="cdm-Button"
            icon="table-settings-next"
            size="sm"
            type="ghost-next"
            @click="openSettings"
          />
          <AppButton
            class="cdm-Button"
            icon="close-next"
            size="sm"
            type="ghost-next"
            @click="closeModal"
          />
        </div>
      </template>
      <div class="cdm-BodyWrapper">
        <div ref="modalBodyRef" class="cdm-Body">
          <CustomDashboardContent
            ref="reportListItemRef"
            :form-model="localFormModel"
            @show-add-objectives-modal="showAddObjectiveModal = true"
            @remove-selected-objective="removeObjective"
            @update:form-model="updateFormModel"
            @blur:title-input="onBlurInputTitle"
          />
        </div>
        <div v-if="!formModel.id">
          <AppDivider />
          <div class="cdm-ModalFooter">
            <AppButton class="cdm-Button" type="ghost-next" @click="closeModal">
              {{ $t('action.cancel') }}
            </AppButton>
            <AppButton :disable="!localFormModel.name" type="primary-next" @click="createDashboard">
              {{ $t('action.create_dashboard') }}
            </AppButton>
          </div>
        </div>
      </div>
      <template #footer> </template>
    </Modal>
    <AddObjectiveModal
      :selected-objectives="formModel.elements"
      :show="showAddObjectiveModal"
      @on-close="showAddObjectiveModal = false"
      @on-select="selectObjective"
    />
  </div>
  <Modal
    :show="isShowSettingsModal"
    :title="$t('menu.settings')"
    class="cdm-SettingsModal"
    data-auto-testid="custom-dashboard-settings-modal"
    data-testid="custom-dashboard-settings-modal"
    hide-close
    manual-close
    scrollable-content
    size="xs"
    @close="closeSettingsModal"
  >
    <template #header>
      <div class="cdm-ModalHeader cdm-ModalHeader_Settings">
        <span class="cdm-SettingsModal_Title">{{ $t('menu.settings') }}</span>
        <AppButton
          icon="close-next"
          size="sm"
          type="ghost-next"
          @click="isShowSettingsModal = false"
        />
      </div>
    </template>
    <div class="cdm-SettingsBodyWrapper">
      <FormFieldNext :label="t('dashboard.layout_orientation')">
        <AppRadioGroupCommon
          :model-value="settings.layoutTypeId"
          :options="getLayoutTypeId"
          data-auto-testid="layout-switch"
          data-testid="layout-switch"
          name="layoutOrientation"
          @update:model-value="changeLayoutTypeId"
        />
      </FormFieldNext>
    </div>
    <template #footer>
      <div class="cdm-SettingsFooterWrapper">
        <AppDivider no-margin />
        <div class="cdm-ModalFooter">
          <AppButton
            data-auto-testid="close-settings-button"
            data-testid="close-settings-button"
            type="ghost-next"
            @click="closeSettingsModal"
          >
            {{ $t('action.cancel') }}
          </AppButton>
          <AppButton
            data-auto-testid="save-settings-button"
            data-testid="save-settings-button"
            type="primary-next"
            @click="saveSettings"
          >
            {{ $t('action.save') }}
          </AppButton>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script setup>
import PerfectScrollbar from 'perfect-scrollbar'
import { onBeforeUnmount, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

import CustomDashboardApiHandler from '@/api/custom-dashboard'
import { tracker } from '@/tracking/amplitude'
import { EVENT_CATEGORIES, EVENT_SECTIONS } from '@/tracking/amplitude-helpers'
import { EVENT_NAMES } from '@/tracking/gtm-helpers'
import { gtmTracker } from '@/tracking/gtm-tracking'
import { APP_MODAL_SIZES } from '@/utils/components-configurations/app-modal'
import { SAVING_INDICATOR_TYPES } from '@/utils/components-configurations/saving-indicator'
import {
  getLayoutTypeId,
  HORIZONTAL,
  LAYOUT_NAMES_BY_TYPE_ID,
  LAYOUT_VIEW_TYPES
} from '@/utils/custom-dashboard-helper'
import { handleError } from '@/utils/error-handling'
import { isEscape } from '@/utils/key-codes'

import CustomDashboardContent from '@/components/dashboard/custom-dashboard/CustomDashboardContent'
import AddObjectiveModal from '@/components/dashboard/custom-dashboard/modals/AddObjectiveModal'
import FormFieldNext from '@/components/form/FormFieldNext'
import SavingIndicator from '@/components/SavingIndicator'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppDivider from '@/components/ui/AppDivider'
import AppRadioGroupCommon from '@/components/ui/AppRadioGroup/AppRadioGroupCommon'
import Modal from '@/components/ui/Modal/Modal'

defineOptions({
  name: 'CustomDashboardModal'
})

const props = defineProps({
  showModal: {
    type: Boolean,
    default: false
  },

  formModel: {
    type: Object,
    required: true
  }
})

const { t } = useI18n()

const emit = defineEmits({
  'update:showModal': null,
  'update:form-model': null,
  'update-data': null
})
const closeModal = () => {
  emit('update:showModal', false)
}

const ps = ref(null)
const modalBodyRef = ref(null)

watch(
  () => modalBodyRef.value,
  val => {
    if (val) {
      ps.value = new PerfectScrollbar(modalBodyRef.value, {
        suppressScrollX: true,
        swipeEasing: true
      })
      ps.value.update()
    } else {
      ps.value.destroy()
      ps.value = null
    }
  }
)

const showAddObjectiveModal = ref(false)

const selectObjective = async elementId => {
  const customDashboardApi = new CustomDashboardApiHandler()
  try {
    savingIndicator.value.startSaving()
    savingIndicatorTop.value.startSaving()
    await customDashboardApi.addDashboardElement({
      dashboardId: props.formModel.id,
      elementId
    })
    emit('update-data', props.formModel.id)
  } catch (error) {
    handleError({ error })
  } finally {
    savingIndicatorTop.value.endSaving()
    savingIndicator.value.endSaving()
  }
}
const reportListItemRef = ref(null)
const removeObjective = async obj => {
  const customDashboardApi = new CustomDashboardApiHandler()
  try {
    savingIndicatorTop.value.startSaving()
    savingIndicator.value.startSaving()
    await customDashboardApi.removeDashboardElement({
      dashboardId: props.formModel.id,
      elementId: obj.id
    })
    emit('update-data', props.formModel.id)
  } catch (error) {
    handleError({ error })
  } finally {
    savingIndicatorTop.value.endSaving()
    savingIndicator.value.endSaving()
  }
}

const createDashboard = async () => {
  try {
    const customDashboardApi = new CustomDashboardApiHandler()
    savingIndicatorTop.value.startSaving()
    savingIndicator.value.startSaving()
    const { name, backgroundId } = localFormModel.value

    const data = await customDashboardApi.createDashboard({
      name,
      backgroundId
    })
    emit('update:form-model', data)

    tracker.logEvent('custom dashboard created', {
      category: EVENT_CATEGORIES.DASHBOARD
    })

    gtmTracker.logEvent(EVENT_NAMES.DASHBOARD_CREATED)
  } catch (error) {
    handleError({ error })
  } finally {
    savingIndicatorTop.value.endSaving()
    savingIndicator.value.endSaving()
  }
}

const getDashboard = async id => {
  try {
    const data = await store.dispatch('dashboard/getDashboardItem', id)
    emit('update:form-model', data)
  } catch (error) {
    handleError({ error })
  }
}
const store = useStore()

const updateDashboard = async () => {
  try {
    const { id, name, backgroundId, layoutTypeId } = localFormModel.value
    const customDashboardApi = new CustomDashboardApiHandler()
    savingIndicatorTop.value.startSaving()
    savingIndicator.value.startSaving()

    await customDashboardApi.updateDashboard({
      id,
      name,
      backgroundId,
      layoutTypeId,
      isPrivate: localFormModel.value.private
    })
    await getDashboard(id)
    await store.dispatch('dashboard/getDashboardList')
    await store.dispatch('dashboard/getFavoriteList')

    const mode = LAYOUT_NAMES_BY_TYPE_ID[layoutTypeId] || ''

    tracker.logEvent('custom dashboard updated', {
      category: EVENT_CATEGORIES.DASHBOARD,
      section: EVENT_SECTIONS.DASHBOARD,
      id,
      mode: mode.toLowerCase()
    })
  } catch (error) {
    handleError({ error })
  } finally {
    savingIndicatorTop.value?.endSaving()
    savingIndicator.value?.endSaving()
  }
}
const onBlurInputTitle = () => {
  if (localFormModel.value.id && localFormModel.value.name) {
    updateDashboard()
  }
}
const savingIndicatorTop = ref(null)
const savingIndicator = ref(null)

onMounted(() => {
  document.body.addEventListener('keyup', onEscPressed)
})
onBeforeUnmount(() => {
  document.body.removeEventListener('keyup', onEscPressed)
})
const onEscPressed = event => {
  if (isEscape(event.keyCode) && props.showModal) {
    closeModal()
  }
}

const localFormModel = ref({ ...props.formModel })

watch(
  () => props.formModel,
  val => {
    localFormModel.value = { ...val }
  },
  { deep: true }
)
const updateFormModel = data => {
  if (!props.formModel.id) {
    emit('update:form-model', data)
  }
  localFormModel.value = { ...data }
}

const isShowSettingsModal = ref(false)
const openSettings = () => {
  isShowSettingsModal.value = true
}
const closeSettingsModal = () => {
  isShowSettingsModal.value = false
}
const saveSettings = () => {
  localFormModel.value.layoutTypeId = settings.value.layoutTypeId
  updateFormModel(localFormModel.value)
  updateDashboard()
  closeSettingsModal()
}

watch(
  () => isShowSettingsModal.value,
  val => {
    if (val && props.formModel.layoutTypeId) {
      settings.value.layoutTypeId = props.formModel.layoutTypeId
    }
  }
)
const settings = ref({
  backgroundId: null,
  layoutTypeId: LAYOUT_VIEW_TYPES[HORIZONTAL]
})
const changeLayoutTypeId = value => {
  settings.value.layoutTypeId = value
}
</script>

<style lang="scss" scoped>
.cdm-ModalFooter {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  justify-content: flex-end;
  padding: 20px 40px;
}

.cdm-BodyWrapper {
  height: 100%;
  display: grid;
  grid-auto-rows: 6fr 1fr;
}
.cdm-Body {
  position: relative;

  //height: calc(100vh - 201px); // 201px => header + footer heights
  //padding: 0;
}
.cdm-ModalHeader {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  gap: 8px;
  &_Settings {
    justify-content: space-between;
  }
}
.cdm-SettingsFooterWrapper {
  width: 100%;
}
.cdm-Button {
  color: $dark-2;
}
</style>
<style lang="scss">
.cdm-ModalWrapper {
  .cdc-ModalContent {
    padding: 0 40px;
  }
  .o-modal-content {
    display: flex;
    flex-direction: column;
  }
  .ps__rail-y {
    z-index: 100;
  }
}
.cdm-ModalWrapper {
  .cdm-BodyWrapper {
    .cdc-ModalContent {
      padding: 0 40px;
    }
    .cdsv-Slider {
      padding: 0 40px;
    }
  }

  .o-modal-body {
    padding-right: 0;
  }
}
.cdm-SettingsModal {
  .o-modal-body {
    padding-top: 7px;
  }
  .o-modal-footer {
    padding: 20px 0 0 0;
  }
}
.cdm-SettingsBodyWrapper {
  padding: 0 20px;
}
.cdm-SettingsModal_Title {
  font-weight: fw('semi-bold');
  font-size: $fs-20;
  line-height: 28px;
  color: $dark-1;
}
</style>
