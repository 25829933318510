import ApiHandler from '@/api/base'

class WebAppUsersApiHandler extends ApiHandler {
  async renameUser({ name }) {
    const { data } = await this.http.patch('/user/profile', [
      {
        propertyName: 'name',
        newValue: name
      }
    ])

    return data
  }

  async grantWebAppAccess({ accountId }) {
    const { data } = await this.http.post('/user/grantWebAccess', {
      accountId
    })

    return data
  }

  async revokeWebAppAccess({ accountId }) {
    const { data } = await this.http.post('/user/revokeWebAccess', {
      accountId
    })

    return data
  }

  async revokeWebAccessOrRemove({ accountIds }) {
    const { data } = await this.http.post('/users/revokeWebAccessOrRemove', {
      accountIds
    })
    return data
  }
}

export default WebAppUsersApiHandler
