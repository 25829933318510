<template>
  <div class="ael-ExportLogo">
    <AppLogo height="16" width="84" />
  </div>
</template>

<script setup>
import AppLogo from '@/components/AppLogo'

defineOptions({
  name: 'AppExportLogo'
})
</script>

<style lang="scss" scoped>
.ael-ExportLogo {
  display: none;
  pointer-events: none;

  &.visible {
    display: block;
  }
}
</style>
