import { SET_PLUGIN_OPTIONS } from '@/store/mutation-types'
import { INSTANCE_STATES } from '@/utils/instance-states'
import { getInstanceDomainName } from '@/utils/web-app/connections'
import { SUBSCRIPTION_PLANS } from '@/utils/web-app/plans-limitations'
import { PLUGIN_TYPES } from '@jira/util'
import { PLUGIN_OPTIONS_KEYS } from '@root/template-options-keys'

const state = {
  [PLUGIN_OPTIONS_KEYS.PLUGIN_TYPE]: null,
  [PLUGIN_OPTIONS_KEYS.BASE_URL]: null,
  [PLUGIN_OPTIONS_KEYS.ONBOARDING]: false,
  [PLUGIN_OPTIONS_KEYS.CREATE_ORGANIZATION]: false,
  [PLUGIN_OPTIONS_KEYS.ATLASSIAN_BASE_URL]: null,
  [PLUGIN_OPTIONS_KEYS.IS_PLUGIN_ADMIN]: false,
  [PLUGIN_OPTIONS_KEYS.LICENSE_STATUS_ID]: null,
  [PLUGIN_OPTIONS_KEYS.LICENSE_STATUS_DAYS_LEFT]: null,
  [PLUGIN_OPTIONS_KEYS.BACKEND_VERSION]: null,
  [PLUGIN_OPTIONS_KEYS.WEB_APP_CONNECTED]: false,
  [PLUGIN_OPTIONS_KEYS.JIRA_CONNECTED]: false,
  [PLUGIN_OPTIONS_KEYS.SETUP_NAME]: false,
  [PLUGIN_OPTIONS_KEYS.IS_OWNER]: false,
  [PLUGIN_OPTIONS_KEYS.SUBSCRIPTION_PLAN]: SUBSCRIPTION_PLANS.ESSENTIAL,
  [PLUGIN_OPTIONS_KEYS.INSTANCE_STATE_ID]: INSTANCE_STATES.ACTIVE.id,
  [PLUGIN_OPTIONS_KEYS.ISS]: null
}

const getters = {
  isPluginServer: state => state[PLUGIN_OPTIONS_KEYS.PLUGIN_TYPE] === PLUGIN_TYPES.server,
  isPluginCloud: state => state[PLUGIN_OPTIONS_KEYS.PLUGIN_TYPE] === PLUGIN_TYPES.cloud,
  getAtlassianInstanceName: state => {
    return getInstanceDomainName({
      atlassianBaseUrl: state[PLUGIN_OPTIONS_KEYS.ATLASSIAN_BASE_URL]
    })
  }
}

const actions = {
  setPluginOptions({ commit }, pluginOptions) {
    commit('SET_PLUGIN_OPTIONS', pluginOptions)
  }
}

const mutations = {
  [SET_PLUGIN_OPTIONS](state, pluginOptions) {
    Object.entries(pluginOptions).forEach(([key, val]) => {
      if (Object.keys(state).includes(key)) {
        // if (key === 'isPluginAdmin') {
        //   key = false
        // }
        state[key] = val
      }
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
