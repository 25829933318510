<template>
  <div :class="{ 'otf-Field-readonly': readonly }" class="otf-Field">
    <textarea
      ref="textarea"
      v-model="localValue"
      :class="{ 'otf-Field_Input-error': isError }"
      :maxlength="maxLength"
      :placeholder="placeholder"
      :readonly="readonly"
      class="otf-Field_Input"
      rows="1"
      v-bind="$attrs"
      @blur="$emit('blur')"
    />
    <AppFieldError v-if="isError" :show="isError" class="otf-Field_Error">
      {{ $t('field.required') }}
    </AppFieldError>
  </div>
</template>

<script setup>
import { useTextareaAutosize } from '@vueuse/core'
import { ref, computed, watch, onMounted, onBeforeUnmount, nextTick } from 'vue'

import AppFieldError from '@/components/form/AppFieldError'

defineOptions({
  name: 'OkrTitleFiled'
})

const { triggerResize, textarea } = useTextareaAutosize()

const props = defineProps({
  modelValue: {
    required: true,
    type: String
  },

  readonly: {
    type: Boolean
  },

  placeholder: {
    type: String,
    default: ''
  },

  isError: {
    type: Boolean
  },
  maxLength: {
    type: Number,
    default: 512
  }
})

watch(
  () => props.modelValue,
  () => {
    nextTick(() => {
      triggerResize()
    })
  },
  { immediate: true }
)

const emit = defineEmits(['blur', 'update:model-value'])

const focus = () => {
  textarea.value.focus()
}

defineExpose({ focus })

const resizeObserver = ref(null)

const textareaWidth = ref(null)

watch(textareaWidth, (newValue, oldValue) => {
  if (newValue !== oldValue && oldValue !== null) {
    nextTick(() => {
      triggerResize()
    })
  }
})

const setTextareaWidth = () => {
  if (textarea.value) {
    textareaWidth.value = textarea.value.offsetWidth
  }
}

const initResizeObserver = () => {
  resizeObserver.value = new ResizeObserver(() => {
    setTextareaWidth()
  })

  resizeObserver.value.observe(textarea.value)
}

const disconnectResizeObserver = () => {
  if (resizeObserver.value) {
    resizeObserver.value.disconnect()
    resizeObserver.value = null
  }
}

onMounted(() => {
  initResizeObserver()
  setTextareaWidth()
})

onBeforeUnmount(() => {
  disconnectResizeObserver()
})

const localValue = computed({
  get() {
    return props.modelValue
  },
  set(v) {
    if (!props.readonly) {
      emit('update:model-value', v)
    }
  }
})
</script>

<style lang="scss" scoped>
.otf-Field {
  display: grid;
  gap: 4px;
  position: relative;

  &:not(&:focus-within) {
    &:hover {
      &:after {
        display: block;
        content: '';
        position: absolute;
        height: calc(100% + 4px);
        left: -10px;
        top: -2px;
        border-radius: 6px;
        z-index: 1;
        width: calc(100% + 20px);
        pointer-events: none;
        cursor: text;
        background-color: $grey-3-next;
      }
      .otf-Field_Input {
        z-index: 2;
        background: transparent;
      }

      &.otf-Field-readonly {
        &:after {
          display: none;
        }
      }
    }
  }

  &-readonly {
    pointer-events: none;
  }
}

.otf-Field_Input {
  resize: none;
  overflow: hidden;
  width: 100%;
  padding: 0;
  border: none;

  font-family: $system-ui;
  font-style: normal;
  font-weight: fw('medium');
  font-size: $fs-32;
  line-height: 40px;
  color: $dark-1;
  caret-color: $primary-color-next;

  &::placeholder {
    color: $grey-1-next;
  }

  &:-moz-placeholder,
  &::-moz-placeholder {
    color: $grey-1-next;
  }

  &:read-only {
    cursor: default;
    color: $grey-1-next;
  }

  &-error {
    color: $grade-low-color-next;
    caret-color: $grade-low-color-next;

    &::placeholder {
      color: inherit;
    }

    &:-moz-placeholder,
    &::-moz-placeholder {
      color: inherit;
    }
  }
}

.otf-Field_Error {
  justify-self: flex-start;
}
</style>
