<template>
  <div
    v-if="!isEmpty(userData)"
    :data-auto-testid="USER_ACTIONS_MENU_WRAPPER_TEST_ID"
    :data-testid="USER_ACTIONS_MENU_WRAPPER_TEST_ID"
    class="amu-Wrapper"
  >
    <portal v-slot="{ expanded }" :disabled="!isWebApp" to="user-menu-trigger">
      <div
        :class="{
          'amu-Trigger-active': expanded,
          'amu-Trigger-web-app': isWebApp,
          'amu-Trigger-disabled': isTriggerDisabled
        }"
        :data-auto-testid="USER_ACTIONS_MENU_TRIGGER_TEST_ID"
        :data-testid="USER_ACTIONS_MENU_TRIGGER_TEST_ID"
        class="amu-Trigger"
        @click="onAvatarClick"
      >
        <AppAvatar :avatar-url="userData.userAvatarUrl" no-margins />
      </div>
    </portal>
    <AppDroplist
      v-if="isWebApp"
      ref="dropDownRef"
      :append-to="appendTo"
      :data-auto-testid="USER_ACTIONS_MENU_DROPDOWN_TEST_ID"
      :data-testid="USER_ACTIONS_MENU_DROPDOWN_TEST_ID"
      :offset="[0, 0]"
      position="top-end"
    >
      <template #button="{ expanded }">
        <portal-target :slot-props="{ expanded }" name="user-menu-trigger" />
      </template>

      <div
        :data-auto-testid="USER_ACTIONS_MENU_DROPDOWN_CONTENT_TEST_ID"
        :data-testid="USER_ACTIONS_MENU_DROPDOWN_CONTENT_TEST_ID"
        class="amu-Content"
      >
        <div class="amu-Content_User">
          <AppAvatar :avatar-url="userData.userAvatarUrl" :size="AVATAR_SIZES.XXL" no-margins />

          <div class="amu-UserData">
            <div class="amu-UserData_Name oboard-truncated-text">
              {{ userData.userName }}
            </div>
            <div class="amu-UserData_Email oboard-truncated-text">
              {{ userData.email }}
            </div>
          </div>
        </div>
        <AppDivider class="amu-Divider" no-margin />
        <ul class="amu-Content_Actions">
          <AppSelectListItem
            v-for="action in ACTIONS"
            :id="`user-action-${action.id}`"
            :key="action.id"
            :data-auto-testid="`dropdown-action-${action.id}`"
            :data-testid="`dropdown-action-${action.id}`"
            :model-value="null"
            @select="onActionClick(action)"
          >
            <template #option-label>
              <div
                :class="{
                  'amu-Action-disabled': action.disabled
                }"
                class="amu-Action"
              >
                <AppIcon :icon-name="action.icon" class="amu-Action_Icon" height="24" width="24" />
                <span class="oboard-truncated-text">
                  {{ action.text }}
                </span>

                <div
                  v-if="action.nestedItems && !isEmpty(action.nestedItems)"
                  class="amu-Action_NestedTrigger"
                >
                  <AppIcon
                    class="amu-Action_Icon"
                    height="24"
                    icon-name="okr-table-expand"
                    width="24"
                  />

                  <AppSelect
                    :bottom-fixed-items="action.bottomFixedItems"
                    :disabled="action.disabled"
                    :dropdown-min-width="260"
                    :dropdown-search="action.nestedItems.length > 10"
                    :item-label="WEB_APP_ORGANIZATION_KEYS.NAME"
                    :item-value="WEB_APP_ORGANIZATION_KEYS.ID"
                    :model-value="selectedOrganizationId"
                    :offset="[0, 0]"
                    :options="action.nestedItems"
                    :search-function="value => localSearch({ value, options: action.nestedItems })"
                    :to-selector="`#user-action-${action.id}`"
                    append-to="parent"
                    max-height="352"
                    position="right-end"
                    trigger="mouseenter click"
                    @update:model-value="onNestedItemClick"
                  >
                    <template #option-label="{ option }">
                      <div v-if="option" class="amu-Action">
                        <OkrIcon :objective="levelToOkrIconElement(option)" />
                        <span class="oboard-truncated-text">
                          {{ option.name }}
                        </span>
                      </div>
                    </template>

                    <template #bottom-fixed-items="{ bottomFixedItems }">
                      <AppDivider class="amu-Divider amu-Divider-nested" no-margin />

                      <template
                        v-for="item in bottomFixedItems"
                        :key="item[WEB_APP_ORGANIZATION_KEYS.ID]"
                      >
                        <template
                          v-if="
                            item[WEB_APP_ORGANIZATION_KEYS.ID] ===
                            CREATE_ORGANIZATION_ITEM[WEB_APP_ORGANIZATION_KEYS.ID]
                          "
                        >
                          <AppTableCreateButton
                            :data-auto-testid="CREATE_ORGANIZATION_ACTON_TEST_ID"
                            :data-testid="CREATE_ORGANIZATION_ACTON_TEST_ID"
                            class="amu-Action-create"
                            full-width
                            icon-name="plus-next"
                            @click="onNestedItemClick(item[WEB_APP_ORGANIZATION_KEYS.ID])"
                          >
                            {{ item.name }}
                          </AppTableCreateButton>
                        </template>
                      </template>
                    </template>
                  </AppSelect>
                </div>
              </div>
            </template>
          </AppSelectListItem>
        </ul>
      </div>
    </AppDroplist>
  </div>
</template>

<script setup>
import { isEmpty } from 'lodash'
import { PortalTarget } from 'portal-vue'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

import WebAppAuthenticationApiHandler from '@/api/web-app-authentication'
import WebAppOrganizationApiHandler from '@/api/web-app-organization'
import { useBackSettingsBackButton } from '@/composables/settings-back-button'
import { ROUTE_NAMES } from '@/routes/route-helpers'
import { ACTIONS_KEYS } from '@/utils/actions-keys'
import { AVATAR_SIZES } from '@/utils/components-configurations/app-avatar'
import { handleError } from '@/utils/error-handling'
import { LS_KEYS } from '@/utils/local-storage-keys'
import { levelToOkrIconElement, localSearch } from '@/utils/objectives'
import { createUniqueKey, readFromStorageByKey } from '@/utils/persist'
import { getPluginOption } from '@/utils/plugin-options'
import { useReloadPage } from '@/utils/reload-page'
import { uid } from '@/utils/uid'
import { redirectToLogout } from '@/utils/web-app/auth-helpers'
import { WEB_APP_ORGANIZATION_KEYS } from '@/utils/web-app/keys'

import AppDroplist from '@/components/AppDroplist'
import {
  CREATE_ORGANIZATION_ACTON_TEST_ID,
  USER_ACTIONS_MENU_DROPDOWN_CONTENT_TEST_ID,
  USER_ACTIONS_MENU_DROPDOWN_TEST_ID,
  USER_ACTIONS_MENU_TRIGGER_TEST_ID,
  USER_ACTIONS_MENU_WRAPPER_TEST_ID
} from '@/components/AppMenu/jest-helpers'
import OkrIcon from '@/components/objectives/items/OkrIcon'
import AppAvatar from '@/components/ui/AppAvatar/AppAvatar'
import AppDivider from '@/components/ui/AppDivider'
import AppIcon from '@/components/ui/AppIcon/AppIcon'
import AppSelect from '@/components/ui/AppSelect/AppSelect'
import AppSelectListItem from '@/components/ui/AppSelect/AppSelectListItem'
import AppTableCreateButton from '@/components/ui/AppTableCreateButton'

defineOptions({
  name: 'AppMenuUserActions'
})

const props = defineProps({
  menuPinned: {
    type: Boolean
  },

  menuShowed: {
    type: Boolean
  },

  appendTo: {
    type: String,
    default: '.app-Aside'
  },

  disabledActions: {
    type: Array,
    default: () => []
  }
})

watch(
  () => props.menuShowed,
  newValue => {
    if (!newValue) {
      closeMenu()
    }
  }
)

watch(
  () => props.menuPinned,
  newValue => {
    if (!newValue) {
      closeMenu()
    }
  }
)

const store = useStore()

const userData = computed(() => store.state.system.userData)

const isWebApp = computed(() => store.state.appContext.isWebApp)

const router = useRouter()

const { t } = useI18n()

const CREATE_ORGANIZATION_ITEM = {
  [WEB_APP_ORGANIZATION_KEYS.ID]: uid(),
  [WEB_APP_ORGANIZATION_KEYS.NAME]: t('user_menu.create_organization')
}

const dropDownRef = ref(null)

const closeMenu = () => {
  dropDownRef.value?.hide()
}

const organizations = computed(() => {
  return store.state.appContext.organizations
})

const ACTIONS = computed(() => {
  return [
    {
      id: ACTIONS_KEYS.GO_TO_PROFILE,
      icon: 'user-menu-profile',
      text: t('user_menu.profile'),
      disabled: false,
      handler: () => {
        closeMenu()
        router.push({ name: ROUTE_NAMES.SETTINGS_USER_PREFERENCES })
      }
    },
    {
      id: ACTIONS_KEYS.SWITCH_ORGANIZATION,
      icon: 'user-menu-switch-org',
      text: t('user_menu.switch_org'),
      disabled: false,
      nestedItems: [...organizations.value],
      bottomFixedItems: [CREATE_ORGANIZATION_ITEM]
    },
    {
      id: ACTIONS_KEYS.LOGOUT,
      icon: 'user-menu-logout',
      text: t('user_menu.logout'),
      disabled: false,
      handler: () => {
        logout()
      }
    }
  ].map(action => {
    return {
      ...action,
      disabled: props.disabledActions.includes(action.id)
    }
  })
})

const loginUrl = computed(() => store.state.appContext.urls.webAppLoginUrl)

const logout = async () => {
  const api = new WebAppAuthenticationApiHandler()
  try {
    await api.preLogout()
    closeMenu()
    await redirectToLogout({
      webAppLoginUrl: loginUrl.value
    })
  } catch (error) {
    handleError({ error })
  }
}

const { saveSettingsReferer } = useBackSettingsBackButton()

const onActionClick = action => {
  if (action.handler && !action.disabled) {
    if (action.id === ACTIONS_KEYS.GO_TO_PROFILE) {
      saveSettingsReferer()
    }

    action.handler()
  }
}

const selectedOrganizationId = computed(() => store.state.appContext.selectedOrganizationId)

const emit = defineEmits({
  'create-organization': null
})
const createNewOrganization = () => {
  emit('create-organization')
}

const { reloadPage } = useReloadPage()

const switchOrganization = async id => {
  if (id !== selectedOrganizationId.value) {
    await store.dispatch('system/toggleFullAppLoader')
    const api = new WebAppOrganizationApiHandler()

    const baseUrl = getPluginOption('baseUrl')
    const uniqueKey = createUniqueKey(baseUrl, id)

    const nextPath = readFromStorageByKey(LS_KEYS.LAST_ROUTE_PATH, uniqueKey) || ''
    const newFullPath = new URL(window.location.origin + '#' + nextPath)

    try {
      await api.switchOrganization({ iss: id })
      history.pushState(null, null, newFullPath.href)
      reloadPage()
    } catch (error) {
      handleError({ error })
    } finally {
      await store.dispatch('system/toggleFullAppLoader')
    }
  }
}
const onNestedItemClick = id => {
  closeMenu()
  if (id === CREATE_ORGANIZATION_ITEM.id) {
    createNewOrganization()
  } else {
    switchOrganization(id)
  }
}

const isTriggerDisabled = computed(() => {
  if (!isWebApp.value) {
    return props.disabledActions.includes(ACTIONS_KEYS.GO_TO_PROFILE)
  }

  return false
})

const onAvatarClick = () => {
  if (!isWebApp.value) {
    if (!isTriggerDisabled.value) {
      onActionClick(ACTIONS.value.find(action => action.id === ACTIONS_KEYS.GO_TO_PROFILE))
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';

.amu-Trigger {
  display: flex;
  padding: 8px;
  min-height: 40px;
  gap: 8px;
  align-items: center;
  font-weight: fw('semi-bold');
  font-size: $fs-14;
  border-radius: $border-radius-md-next;
  color: $dark-2;

  &-active {
    background-color: $grey-3-next;
  }

  &:not(&-disabled) {
    cursor: pointer;
    @media (any-hover: hover) {
      &:hover {
        background-color: $grey-3-next;
      }
    }
  }
}

.amu-Content {
  width: 240px;
  max-width: 240px;
  display: flex;
  gap: 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: inherit;
}

.amu-Content_User {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  padding: 10px;
}

.amu-UserData {
  text-align: center;
  display: grid;
  gap: 3px;
}

.amu-UserData_Name {
  color: $dark-2;
  font-size: $fs-14;
  font-weight: fw('semi-bold');
  line-height: 20px;
  text-align: center;
}

.amu-UserData_Email {
  color: $dark-3;
  font-size: $fs-12;
  font-weight: fw('regular');
  line-height: 16px;
}

.amu-Divider {
  width: calc(100% - 20px);

  &-nested {
    margin: 4px auto;
  }
}

.amu-Content_Actions {
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  border-radius: inherit;
  overflow: hidden;
}

.amu-Action {
  color: $dark-1;
  line-height: 20px;
  display: flex;
  gap: 8px;
  align-items: center;
  width: 100%;
  --icon-color: #{$dark-2};

  &-create {
    --padding-left: 10px;
    --gap: 8px;
    color: $dark-1;
  }

  &-disabled {
    color: $grey-1-next;
    --icon-color: #{$grey-1-next};
  }
}

.amu-Action_Icon {
  flex-shrink: 0;
  color: var(--icon-color);
}

.amu-Action_NestedTrigger {
  display: flex;
  color: $dark-2;
  margin-left: auto;
}
</style>
