<template>
  <BulkActionsOperationValueControl
    v-model="localModelValue"
    :field-id="fieldTypeId"
    is-custom-field
  >
    <template #value-controller>
      <AppInputNumberNext
        :key="fieldId"
        v-model="localValue"
        :data-auto-testid="NUMBER_MONEY_INPUT_TEST_ID"
        :data-testid="NUMBER_MONEY_INPUT_TEST_ID"
        :digit-max-length="NUMBER_FILTER_DEFAULT_DIGIT_MAX_LENGTH"
        :fraction="2"
        :placeholder="
          t('bulk_actions.set_custom_field_value', {
            customFieldName: fieldName
          })
        "
        :prefix="prefix"
        allow-empty-value
        allow-negative
        auto-focus
        class="cfni-Input"
      />
    </template>
  </BulkActionsOperationValueControl>
</template>

<script setup>
import { isNull } from 'lodash'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import { NUMBER_MONEY_INPUT_TEST_ID } from '@/utils/custom-fields/jest-helpers'
import { useCustomFieldsHelpers } from '@/utils/custom-fields/use-custom-fields'
import { OKR_BULK_UPDATE_ENTITY_KEYS } from '@/utils/entity-keys'
import { NUMBER_FILTER_DEFAULT_DIGIT_MAX_LENGTH } from '@/utils/okr-elements/number-filter'
import { BULK_FIELD_OPERATION_TYPE_IDS } from '@/utils/okr-elements-table-bulk-actions'

import BulkActionsOperationValueControl from '@/components/objectives/bulk-edit/BulkActionsOperationValueControl'
import AppInputNumberNext from '@/components/ui/AppInputNumberNext/AppInputNumberNext'

defineOptions({
  name: 'CustomFieldNumberInput',
  inheritAttrs: false
})

const props = defineProps({
  modelValue: {
    type: Object,
    required: true
  },

  fieldId: {
    type: [String, Number],
    required: true
  },

  fieldTypeId: {
    type: [String, Number],
    required: true
  },

  prefix: {
    type: String,
    default: ''
  }
})

const { fieldName } = useCustomFieldsHelpers({ fieldId: props.fieldId })

const { t } = useI18n()

const emit = defineEmits({
  'update:model-value': null
})

const localValue = computed({
  get: () => {
    return isNull(props.modelValue.value) ? '' : props.modelValue.value
  },
  set: value => {
    const resolvedValue = value === '' ? null : value
    emit('update:model-value', {
      [OKR_BULK_UPDATE_ENTITY_KEYS.OPERATION]:
        props.modelValue[OKR_BULK_UPDATE_ENTITY_KEYS.OPERATION] ||
        BULK_FIELD_OPERATION_TYPE_IDS.REPLACE,
      value: resolvedValue
    })
  }
})

const localModelValue = computed({
  get: () => {
    const { value, operation } = props.modelValue
    return {
      value,
      operation
    }
  },
  set: value => {
    emit('update:model-value', value)
  }
})
</script>

<style lang="scss" scoped>
.cfni-Input {
  --font-size: $fs-14;
  --font-weight: #{fw('regular')};
  width: 100%;

  &:deep(.ain-InputWrapper) {
    width: 100%;
  }
}
</style>
