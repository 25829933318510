<template>
  <div class="cd-PageWrapper">
    <router-view>
      <template #default="{ Component }">
        <component :is="Component" :workspace-id="workspaceId" />
      </template>
    </router-view>
  </div>
</template>

<script setup>
defineOptions({
  name: 'CustomDashboard'
})

defineProps({
  workspaceId: {
    type: [Number, String],
    required: true
  }
})
</script>

<style lang="scss" scoped>
.cd-PageWrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
</style>
