<template>
  <div class="oev-Header">
    <portal-target name="okr-sticky-header" />
  </div>

  <ObjectiveListPage
    ref="list"
    :fetch-expand-all="isTree"
    :filter-preset="filterPreset"
    :objective-levels="objectiveLevels"
    :user-can-create-objectives="userCanCreateObjectives"
    :view="view"
    :workspace-id="workspaceId"
    offset-left="var(--page-left-padding)"
    offset-right="var(--page-right-padding)"
    @data-request="onDataRequest"
    @initial-data-loaded="initialDataLoaded = true"
  >
    <router-view v-slot="{ Component }">
      <component
        :is="Component"
        ref="viewComponent"
        @update-data="updateOkrData"
        @load-more="loadMore"
        @create-objective="createObjective"
        @reset-search-criteria="resetSearchCriteria"
        @expand-all="expandAll"
        @collapse-all="collapseAll"
      />
    </router-view>
  </ObjectiveListPage>
</template>

<script>
import { defineComponent } from 'vue'
import { mapState, mapGetters } from 'vuex'

import { currentUserCanCreateObjective, OKR_VIEW_PAGES } from '@/utils/objectives'
import { FILTER_PRESETS } from '@/utils/okr-elements/filters'
import { FILTER_PRESET_KEY } from '@/utils/query-parameters'

import ObjectiveListPage from '@/components/objectives/ObjectiveListPage'

export default defineComponent({
  name: 'OkrElementsView',

  components: {
    ObjectiveListPage
  },

  beforeRouteEnter(to, _, next) {
    next(vm => {
      vm.updateFilterValueFromQuery(to.query)
    })
  },

  beforeRouteUpdate(to, from, next) {
    if (to.query[FILTER_PRESET_KEY] !== from.query[FILTER_PRESET_KEY]) {
      this.updateFilterValueFromQuery(to.query)
    }
    next()
  },

  data() {
    return {
      filterPreset: FILTER_PRESETS.NONE,
      initialDataLoaded: false
    }
  },

  computed: {
    ...mapState('workspaces', {
      workspaceId: state => state.workspaceId
    }),

    ...mapState('pluginOptions', {
      isPluginAdmin: state => state.isPluginAdmin
    }),

    ...mapState('objectives', {
      objectiveLevels: state => state.levels
    }),

    ...mapGetters('okrFilters', {
      currentSavedFilter: 'currentSavedFilter'
    }),

    ...mapGetters('workspaces', {
      currentWorkspaceSettings: 'selectedWorkspace'
    }),

    isTree() {
      return this.$route.path.includes('/tree')
    },

    userCanCreateObjectives() {
      return currentUserCanCreateObjective(this.currentWorkspaceSettings, this.isPluginAdmin)
    },

    view() {
      if (this.$route.path.includes('okr-explorer/table')) {
        return OKR_VIEW_PAGES.OKREXPLORER
      }
      if (this.$route.path.includes('alignment/table')) {
        return OKR_VIEW_PAGES.ALIGNMENT
      }
      if (this.$route.path.includes('alignment/tree')) {
        return OKR_VIEW_PAGES.MINDMAP
      }
      if (this.$route.path.includes('alignment/roadmap')) {
        return OKR_VIEW_PAGES.ROADMAP
      }
      return ''
    }

    // onboardingStartCondition() {
    //   return this.onboarding && this.userCanCreateObjectives && this.initialDataLoaded
    // }
  },

  // watch: {
  // async onboardingStartCondition(newValue) {
  //   if (newValue) {
  //     // wait for rendering and start tour
  //     await this.$nextTick()
  //     this.$tours.myTour.start()
  //   }
  // }
  // },

  methods: {
    expandAll() {
      this.$refs.list.onExpandAll()
    },

    collapseAll() {
      this.$refs.list.onCollapseAll()
    },

    async resetSearchCriteria() {
      if (!this.$refs.list) {
        await this.$nextTick()
      }

      this.$refs.list.resetSearchCriteria()
    },

    async updateOkrData(eventData) {
      if (!this.$refs.list) {
        // wait for mounting
        await this.$nextTick()
      }
      this.$refs.list.tryToUpdateOkrElements(eventData)
    },

    onDataRequest(expandAll) {
      this.$refs.viewComponent.requestData(expandAll)
    },

    loadMore(data) {
      this.$refs.list.loadMore(data)
    },

    createObjective(data) {
      this.$refs.list.createOkrElement(data)
    },

    updateFilterValueFromQuery(query) {
      const queryValue = query[FILTER_PRESET_KEY]
      let newFilterPreset = FILTER_PRESETS.NONE

      if (queryValue) {
        newFilterPreset = queryValue
      }

      if (this.filterPreset !== newFilterPreset) {
        this.filterPreset = newFilterPreset

        if (!isNaN(Number(newFilterPreset))) {
          const isOnlyFilterPresetQuery =
            Object.keys(query).length === 1 && Object.keys(query)[0] === FILTER_PRESET_KEY

          const currentSavedFilter = this.currentSavedFilter(this.filterPreset)

          const resolvedQuery = isOnlyFilterPresetQuery ? currentSavedFilter.query : query

          // temporary commented for tests remove before merge now I don't know if it's needed
          // replaceQueryParameters(this.$router, this.$route, resolvedQuery);

          this.$refs.list.restoreSavedFilters(resolvedQuery)
        }
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.oev-Header {
  z-index: 10;
  position: sticky;
  top: var(--app-license-banner-height);
}
</style>
