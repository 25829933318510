<template>
  <div
    :class="{ 'o-checkbox-disabled': disabled, [`o-checkbox-${type}`]: type }"
    class="o-checkbox"
  >
    <label class="o-checkbox-label">
      <span class="o-checkbox-label-inner">
        <input
          ref="input"
          v-model="localValue"
          :disabled="disabled"
          :value="val"
          class="o-checkbox-default"
          type="checkbox"
        />
        <slot name="checkmark">
          <span
            v-show="!hideCheckbox"
            :class="{ 'checkmark-rounded': roundedCheckmark }"
            class="checkmark"
          />
        </slot>
        <span
          :class="{ 'o-checkbox-label-text-only-text': hideCheckbox }"
          class="o-checkbox-label-text"
        >
          <slot :label="label" name="label">
            {{ label }}
          </slot>
        </span>
      </span>
      <slot name="after" />
    </label>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

import { SELECT_TYPES } from '@/utils/components-configurations/app-select'

export default defineComponent({
  name: 'AppCheckboxListItem',
  props: {
    label: {
      type: String,
      default: ''
    },

    disabled: {
      type: Boolean,
      default: false
    },

    val: {
      type: [String, Number],
      required: true
    },

    modelValue: {
      type: Array,
      default: () => []
    },

    hideCheckbox: {
      type: Boolean
    },

    type: {
      type: String,
      default: SELECT_TYPES.DEFAULT_NEXT,
      validator: v => Object.values(SELECT_TYPES).includes(v)
    },

    roundedCheckmark: {
      type: Boolean
    },

    disableToggling: {
      type: Boolean
    }
  },

  emits: { 'update:modelValue': null, checked: null },

  data() {
    return {
      localValue: JSON.parse(JSON.stringify(this.modelValue))
    }
  },

  watch: {
    modelValue(newValue) {
      if (JSON.stringify(this.localValue) !== JSON.stringify(newValue)) {
        this.localValue = JSON.parse(JSON.stringify(newValue))
        this.$nextTick(() => {
          if (this.$refs.input) {
            this.$refs.input.checked = newValue.includes(this.val)
          }
        })
      }
    },

    localValue: {
      handler(newValue) {
        if (JSON.stringify(this.modelValue) !== JSON.stringify(newValue)) {
          const result = [...this.modelValue]

          const selected = !result.includes(this.val)
          if (selected) {
            result.push(this.val)
          } else {
            const valIndex = this.modelValue.findIndex(option => option === this.val)
            if (valIndex >= 0) {
              result.splice(valIndex, 1)
            }
          }

          this.$nextTick(() => {
            if (this.$refs.input) {
              this.$refs.input.checked = selected
            }
          })
          this.$emit('update:modelValue', result)
          this.$emit('checked', result)
        }
      },

      deep: true
    }
  },

  methods: {
    /** @public */
    toggle() {
      if (this.disabled || this.disableToggling) {
        return
      }

      const valIndex = this.localValue.findIndex(item => item === this.val)
      if (valIndex === -1) {
        this.localValue.push(this.val)
      } else {
        this.localValue.splice(valIndex, 1)
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.o-checkbox {
  max-width: 100%;
  width: 100%;
}

.o-checkbox-disabled {
  opacity: 0.5;
  cursor: default;
}

.o-checkbox-label {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  font-size: $fs-22;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  justify-content: space-between;

  .o-checkbox-disabled & {
    cursor: default;
  }
}

.o-checkbox-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  left: 0;
  height: 14px;
  width: 14px;
  border: 2px solid $dark-3;
  background-color: $white;
  box-sizing: border-box;

  &:not(&-rounded) {
    border-radius: $border-radius-sm;
  }

  &-rounded {
    border-radius: 50%;
  }
}

.o-checkbox-label input:checked {
  ~ .checkmark {
    border-color: $primary-color-next;
    display: block;

    &:not(&-rounded) {
      border-radius: $border-radius-sm;
      background-color: $primary-color-next;

      background-image: url(~@/assets/images/icon-checkbox.svg);
      background-repeat: no-repeat;
      background-size: 80% auto;
      background-position: center;
    }

    &-rounded {
      border-radius: 50%;
      border-width: 4px;
    }
  }
}

.o-checkbox-label-text {
  font-size: $fs-14;
  font-weight: fw('regular');

  display: flex;
  align-items: center;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:not(&-only-text) {
    padding-left: 26px;
  }
}

.o-checkbox-label-inner {
  position: relative;
  display: flex;
  align-items: center;
  max-width: 100%;
}
</style>
