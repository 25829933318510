<template>
  <div :class="classes">
    <slot v-if="!simplify" />
    <AppIcon v-if="showIcon" class="aob-Button_Icon" height="24" icon-name="plus-next" width="24" />
  </div>
</template>

<script>
import { defineComponent } from 'vue'

import AppIcon from '@/components/ui/AppIcon/AppIcon'

export default defineComponent({
  name: 'AddOkrButton',
  components: { AppIcon },
  props: {
    disableMargin: {
      type: Boolean
    },

    isActive: {
      type: Boolean
    },

    simplify: {
      type: Boolean
    },

    disabled: {
      type: Boolean
    }
  },

  computed: {
    classes() {
      return {
        'aob-Button': true,
        'aob-Button-no-margin': this.disableMargin,
        'aob-Button-active': this.isActive,
        'aob-Button-simplify': this.simplify,
        'aob-Button-disabled': this.disabled
      }
    },

    showIcon() {
      if (this.simplify) {
        return true
      } else {
        return !this.disabled
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.aob-Button {
  display: flex;
  align-items: center;
  width: 100%;

  transition: background-color $transition-normal, color $transition-normal;
  font-family: $system-ui;

  justify-content: space-between;
  font-style: normal;
  font-weight: fw('semi-bold');
  font-size: $fs-14;
  line-height: 20px;
  color: var(--button-text-color, #{$dark-3});

  &:hover,
  &-active {
    background-color: $grey-3-next;
    color: $primary-color-next;
  }

  &:not(&-disabled) {
    cursor: pointer;
  }

  &-disabled {
    cursor: not-allowed;
    pointer-events: none;
  }

  &:not(&-no-margin) {
    margin-bottom: 12px;
  }

  &:not(&-simplify) {
    padding: var(--button-padding, 18px);
    border: 2px solid $grey-3-next;
    border-radius: $border-radius-md-next;
  }

  &-simplify {
    border-radius: $border-radius-sm-next;
  }
}

.aob-Button_Icon {
  color: $primary-color-next;
}
</style>
