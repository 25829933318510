export const RADIO_GROUP_TYPES = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  SEPARATED: 'separated',
  LIGHT: 'light',
  SEPARATED_MODERN: 'separated-modern',
  PRIMARY_NEXT: 'primary-next',
  VERTICAL: 'vertical',
  TAB_LIKE: 'tab-like',
  BLOCKS: 'blocks'
}

export const RADIO_GROUP_SIZES = ['large', 'default', 'small', 'xs', 'md']
