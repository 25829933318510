import { isEmpty } from 'lodash'
import { computed, ref } from 'vue'
import { useStore } from 'vuex'

export const useExternalEnvironmentElementsHeight = () => {
  const appReference = ref(null)
  const store = useStore()
  const isPluginServer = computed(() => store.getters['pluginOptions/isPluginServer'])

  const SERVER_JIRA_HEADER = () => document.querySelector('header#header')
  // get selectors as functions coz they can be not rendered when our app started
  const SERVER_JIRA_ERROR_BANNER = () => document.querySelector('.aui-banner')
  const SERVER_JIRA_WARNING_MESSAGE = () => document.querySelector('.aui-message')

  // const serverJiraNavbarScrolledOutVal = ref(0)

  const getExternalEnvironmentElementsHeight = () => {
    if (isPluginServer.value) {
      let jiraServerMessageHeight = SERVER_JIRA_WARNING_MESSAGE()?.offsetHeight || 0

      if (jiraServerMessageHeight) {
        const jiraMessageParentHeight =
          SERVER_JIRA_WARNING_MESSAGE()?.parentElement?.offsetHeight || 0
        if (!jiraMessageParentHeight) {
          jiraServerMessageHeight = 0
        }
      }
      const navbarHeight =
        document.querySelector('nav.aui-header').offsetHeight +
        (SERVER_JIRA_ERROR_BANNER()?.offsetHeight || 0) +
        jiraServerMessageHeight

      const footerHeight = document.querySelector('footer#footer')?.offsetHeight || 155

      return { navbarHeight, footerHeight }
    }
    // There will be more conditions for other platforms later
    return { navbarHeight: 0, footerHeight: 0 }
  }

  const setExternalEnvironmentElementsHeight = () => {
    const { navbarHeight, footerHeight } = getExternalEnvironmentElementsHeight()
    store.dispatch('system/setExternalEnvironmentElementsHeight', {
      navbar: navbarHeight,
      footer: footerHeight
    })
  }

  const calculateExternalEnvironmentElementsHeight = () => {
    if (isPluginServer.value) {
      const SERVER_JIRA_NOTIFICATIONS = [
        SERVER_JIRA_ERROR_BANNER(),
        SERVER_JIRA_WARNING_MESSAGE()
      ].filter(Boolean)

      const resizeObserver = new ResizeObserver(() => {
        setExternalEnvironmentElementsHeight()
      })

      if (!isEmpty(SERVER_JIRA_NOTIFICATIONS)) {
        resizeObserver.observe(appReference.value)
      }

      resizeObserver.observe(SERVER_JIRA_HEADER())

      // const intersectionObserver = new IntersectionObserver(
      //   entries => {
      //     entries.forEach(entry => {
      //       serverJiraNavbarScrolledOutVal.value =
      //         entry.intersectionRatio * externalEnvironmentElementsHeight.value.navbar
      //     })
      //   },
      //   {
      //     threshold: [...Array(101).keys()].map(i => i / 100)
      //   }
      // )
      //
      // intersectionObserver.observe(SERVER_JIRA_HEADER())
    }

    // There will be more conditions for other platforms later
  }

  return {
    calculateExternalEnvironmentElementsHeight,
    appReference
  }
}
