<template>
  <div class="ws-Step">
    <slot name="step-title" />

    <FormFieldNext :label="$t('workspace.label.name')" class="ws-FormField">
      <EntityButton style="--button-inline-padding: 8px" @click="onEditWorkspace">
        <span class="oboard-truncated-text">
          {{ selectedWorkspace[WORKSPACE_ENTITY_KEYS.NAME] }}
        </span>
      </EntityButton>
    </FormFieldNext>

    <slot name="step-message" />

    <portal to="modal-windows">
      <WorkspaceCreateFastEditModal
        v-model:show="isEditModalShow"
        :model-value="selectedWorkspace"
        @on-updated="onWorkspaceUpdated"
      />
    </portal>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'

import { WORKSPACE_ENTITY_KEYS } from '@/utils/entity-keys'

import FormFieldNext from '@/components/form/FormFieldNext'
import EntityButton from '@/components/ui/WorkspaceSetupSteps/EntityButton'
import WorkspaceCreateFastEditModal from '@/components/workspaces/WorkspaceCreateFastEditModal'

defineOptions({
  name: 'WorkspaceStep'
})

const store = useStore()

const selectedWorkspace = computed(() => {
  return store.getters['workspaces/selectedWorkspace']
})

const onEditWorkspace = () => {
  isEditModalShow.value = true
}

const isEditModalShow = ref(false)

const onWorkspaceUpdated = async updatedWorkspace => {
  await store.dispatch('workspaces/updateWorkspace', updatedWorkspace)
  isEditModalShow.value = false
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/wizard-steps';

.ws-Step {
  @extend %wizard-step-styles;
}

.ws-FormField {
  width: 100%;
}
</style>
