<template>
  <AppButton
    :class="{
      'eb-Button-with-scale': scaleOnHover
    }"
    class="eb-Button"
    size="lg-next"
    type="outline"
    @click="onClick"
  >
    <span class="eb-Button_Content">
      <slot />
    </span>
    <AppIcon class="eb-Button_Icon" height="24" icon-name="edit-next" width="24" />
  </AppButton>
</template>

<script setup>
import AppButton from '@/components/ui/AppButton/AppButton'
import AppIcon from '@/components/ui/AppIcon/AppIcon'

defineOptions({
  name: 'EntityButton'
})

defineProps({
  scaleOnHover: {
    type: Boolean
  }
})

const emit = defineEmits({
  click: null
})

const onClick = () => {
  emit('click')
}
</script>

<style lang="scss" scoped>
.eb-Button {
  --justify-content: flex-start;
  --button-high-padding: var(--button-inline-padding, 6px);
  --gap: 8px;
  transition: $transition-fast;

  &-with-scale {
    @media (any-hover: hover) {
      &:hover {
        transform: scale(1.06);

        + .eb-Button {
          transform: scale(1.03);
        }
      }
    }

    &:has(+ .eb-Button:hover) {
      transform: scale(1.03);
    }
  }

  &:deep(.after) {
    margin-left: auto;
  }
}

.eb-Button_Content {
  display: inherit;
  gap: inherit;
  align-items: inherit;
  justify-content: inherit;
  overflow: hidden;
}

.eb-Button_Icon {
  margin-left: auto;
  flex-shrink: 0;
  display: none;

  .eb-Button:hover & {
    display: block;
  }
}
</style>
