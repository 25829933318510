<template>
  <div class="oai-Wrapper">
    <OkrIcon :objective="okrElement" :size="iconSize" />
    <ObjectiveId
      v-tippy="{
        content: okrElement.name,
        theme: DROP_LIST_THEMES.COMMON_TOOLTIP_THEMES
      }"
      :clickable="clickable"
      :disabled-color="idDisabledColor"
      :size="idSize"
      :value="okrElement.displayId"
      @click="$emit('click-on-id')"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'OkrIconAndId'
})
</script>

<script setup>
import { DROP_LIST_THEMES } from '@/utils/components-configurations/app-droplist'
import { SIZES } from '@/utils/components-configurations/okr-icon'
import { objectOrNullProp } from '@/utils/prop-validators'

import OkrIcon from '@/components/objectives/items/OkrIcon'
import ObjectiveId from '@/components/objectives/ObjectiveId'

defineProps({
  okrElement: {
    type: Object,
    required: true,
    validator: v => objectOrNullProp(v)
  },

  iconSize: {
    type: String,
    default: SIZES.MD
  },

  idSize: {
    type: String,
    default: 'small'
  },

  clickable: {
    type: Boolean,
    default: true
  },

  idDisabledColor: {
    type: String,
    default: '#B5BBC5'
  }
})

defineEmits(['click-on-id'])
</script>

<style lang="scss" scoped>
.oai-Wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: $system-ui;
  font-size: 14px;
  line-height: 20px;
}
</style>
