<template>
  <div :class="{ 'tb-Scrollable': hScrollable }">
    <div ref="table" :class="tableClasses" :style="tableStyles">
      <div :style="headerStyles" class="tb-Header">
        <slot
          v-for="(column, index) in columns"
          :cell-style="columnStyles[column.key]"
          :column="column"
          :name="`header-cell-${column.key}`"
        >
          <div
            :key="column.key"
            :class="[`tb-HeaderCell-${column.key}`]"
            :style="columnStyles[column.key]"
            class="tb-HeaderCell"
          >
            <slot :column="column" :index="index" name="header-cell">
              {{ column.title }}
            </slot>
          </div>
        </slot>
      </div>

      <div class="tb-Body">
        <template v-for="(row, index) in data" :key="index">
          <div
            :class="{
              'tb-RowWrapper-hovered': hoverRow === index,
              'tb-RowWrapper-error': !!row.error
            }"
            class="tb-RowWrapper"
          >
            <slot
              :column-styles="columnStyles"
              :columns="columns"
              :index="index"
              :on-cell-click-self="onCellClickSelf"
              :on-row-click-self="onRowClickSelf"
              :row="row"
              :row-styles="rowStyles"
              name="row"
            >
              <div
                :data-testid="rowTestId"
                :style="rowStyles"
                class="tb-Row"
                @click="onRowClick(row, $event)"
                @click.self="onRowClickSelf(row, $event)"
              >
                <slot
                  v-for="column in columns"
                  :cell-style="columnStyles[column.key]"
                  :item="row"
                  :name="`body-cell-${column.key}`"
                >
                  <div
                    :key="column.key"
                    :class="[`tb-Cell-${column.key}`, [`tb-Cell-${column.key}-${index}`]]"
                    :style="columnStyles[column.key]"
                    class="tb-Cell"
                    @click.self="onCellClickSelf(row, column, $event)"
                  >
                    <slot :column-key="column.key" :index="index" :item="row" name="cell">
                      {{ row[column.key] }}
                    </slot>
                  </div>
                </slot>
              </div>
            </slot>
          </div>

          <div v-if="!!row.error" :style="rowStyles" class="tb-RowError">
            <AppFieldError show>
              {{ row.error }}
            </AppFieldError>
          </div>
        </template>
      </div>

      <template v-if="loading && !infiniteLoading">
        <slot name="loading">
          <div class="tb-Loading">
            <LoadingCircle size="small" />
          </div>
        </slot>
      </template>

      <InfiniteScrollLoader
        v-if="infiniteLoading"
        :identifier="infiniteId"
        @infinite="$emit('infinite-load', $event)"
      >
        <template #loader>
          <slot name="loading">
            <div class="o-infinite-loading">
              <LoadingCircle size="small" />
            </div>
          </slot>
        </template>

        <template #no-results>
          <slot name="no-results"> {{ $t('table.no_items') }} </slot>
        </template>
      </InfiniteScrollLoader>

      <slot name="footer" />

      <div class="tb-EndBlock" />

      <span v-if="hScrollable" ref="rightObserver" class="tb-RightObserver" />
    </div>
    <transition name="fade">
      <span
        v-if="hScrollable && tableScrolledToRight"
        :style="{ '--height': `${gradientHeight}px` }"
        class="tb-RightGradient"
      ></span>
    </transition>
  </div>
</template>

<script>
import { isEmpty } from 'lodash'
import { defineComponent } from 'vue'

import AppFieldError from '@/components/form/AppFieldError'
import InfiniteScrollLoader from '@/components/ui/InfiniteScrollLoader/InfiniteScrollLoader'
import LoadingCircle from '@/components/ui/LoadingCircle/LoadingCircle'

const TABLE_TYPES = ['primary-next', 'primary', 'secondary', 'mini', 'mini-next']

export default defineComponent({
  name: 'AppTable',

  components: {
    AppFieldError,
    InfiniteScrollLoader,
    LoadingCircle
  },

  props: {
    columns: {
      type: Array,
      required: true
    },

    data: {
      type: Array,
      required: true
    },

    loading: {
      type: Boolean,
      default: false
    },

    hoverRow: {
      type: Number,
      default: -1
    },

    infiniteLoading: {
      type: Boolean
    },

    type: {
      type: String,
      default: 'primary',
      validator: v => TABLE_TYPES.includes(v)
    },

    offsetLeft: {
      type: [String, Number],
      default: ''
    },

    offsetRight: {
      type: [String, Number],
      default: ''
    },

    disableUserSelect: {
      type: Boolean,
      default: true
    },

    hScrollable: {
      type: Boolean
    },

    noBorder: {
      type: Boolean
    },

    rowTestId: {
      type: String,
      default: 'table-row'
    },

    noHover: {
      type: Boolean
    },

    borderOnLastRow: {
      type: Boolean
    },

    stickyHeader: {
      type: Boolean
    }
  },

  emits: {
    'infinite-load': null,
    'row-click-self': null,
    'cell-click-self': null,
    'row-click': null
  },

  data() {
    return {
      infiniteId: +new Date(),
      tableScrolledToRight: false,
      gradientHeight: 0,
      tableScrollRightObserver: null
    }
  },

  computed: {
    setWithAsStyleProperty() {
      const someColumnHasDefinedWidth = this.columns.some(column => column?.width !== 'auto')
      return this.hScrollable && someColumnHasDefinedWidth
    },

    columnStyles() {
      return this.columns.reduce((acc, column) => {
        const columnStyles = {}
        if (!column.width || column.width === 'auto') {
          columnStyles.flex = '1 1 100%'
        } else {
          // this is necessary because flexbox with fixed width and overflow-x
          // calculates wrong parent flex-row width with 'flex: 0 0 Xpx'
          if (this.setWithAsStyleProperty) {
            columnStyles.width = `${column.width}px`
          }
          columnStyles.flex = `0 0 ${column.width}px`
        }
        if (column.align) {
          columnStyles.textAlign = column.align
        }
        acc[column.key] = columnStyles
        return acc
      }, {})
    },

    tableStyles() {
      return {
        // negate value(also variable):
        // https://github.com/css-modules/postcss-icss-values/issues/64#issuecomment-241285628
        'margin-left': `calc(${this.offsetLeft} * -1)`,
        'margin-right': `calc(${this.offsetRight} * -1)`
      }
    },

    tableClasses() {
      return {
        'tb-Table': true,
        [`tb-Table-${this.type}`]: true,
        'tb-Table-empty': isEmpty(this.data),
        'tb-Table-no-select': this.disableUserSelect,
        'tb-Table-noBorder': this.noBorder,
        'tb-Table-no-hover': this.noHover,
        'tb-Table-with-offset': this.offsetLeft || this.offsetRight,
        'tb-Table-bordered-last-row': this.borderOnLastRow,
        'tb-Table-sticky-header': this.stickyHeader
      }
    },

    headerStyles() {
      return {
        'padding-left': this.offsetLeft,
        'padding-right': this.offsetRight,
        '--padding-left': this.offsetLeft,
        '--padding-right': this.offsetRight
      }
    },

    rowStyles() {
      return {
        'padding-left': this.offsetLeft,
        'padding-right': this.offsetRight,
        '--padding-left': this.offsetLeft,
        '--padding-right': this.offsetRight
      }
    }
  },

  watch: {
    data: {
      async handler() {
        if (this.hScrollable) {
          await this.$nextTick()
          this.gradientHeight = this.$refs.table.offsetHeight
        }
      },

      immediate: true,
      deep: true
    }
  },

  mounted() {
    if (this.hScrollable) {
      this.tableScrollRightObserver = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          this.tableScrolledToRight = !entry.isIntersecting
        })
      }, {})

      this.tableScrollRightObserver.observe(this.$refs.rightObserver)
    }
  },

  beforeUnmount() {
    if (this.hScrollable) {
      this.tableScrollRightObserver.unobserve(this.$refs.rightObserver)
      this.tableScrollRightObserver = null
    }
  },

  methods: {
    onRowClickSelf(row) {
      this.$emit('row-click-self', row)
    },

    onRowClick(row) {
      this.$emit('row-click', row)
    },

    onCellClickSelf(row, column) {
      this.$emit('cell-click-self', { row, column })
    },

    /** @public */
    updateItems() {
      this.infiniteId += 1
    }
  }
})
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';

.tb-Scrollable {
  overflow-x: auto;
  max-width: 100%;
  display: grid;
  @include styled-native-scrollbar();
}

.tb-Table {
  font-size: $fs-14;
  border: 1px solid $azure-medium;
  .tb-Scrollable & {
    position: relative;
  }
  &-no-select {
    user-select: none;
  }
  &-noBorder {
    border: none;
  }
  &-primary-next {
    font-family: $system-ui;
  }
}

.tb-Header {
  display: flex;
  align-items: flex-end;
  background: $azure-medium;
  border-bottom: 1px solid $azure-medium;
  padding: 14px 24px;

  .tb-Table-mini &,
  .tb-Table-mini-next & {
    margin: 0;
    align-items: center;
  }

  .tb-Table-mini & {
    padding: 5px 0;
  }

  .tb-Table-mini-next & {
    padding: 8px 0 6px;
    background: transparent;
    border-bottom: 2px solid $grey-2-next;
  }

  .tb-Table-primary-next & {
    padding: var(--head-padding-top, 0) 0 var(--head-padding-bottom, 6px);
    margin: 0;
    align-items: center;
    background-color: transparent;
    border: none;
    border-bottom: var(--heade-border-bottom, 2px solid #{$grey-2-next});
  }

  .tb-Table-primary-next.tb-Table-with-offset & {
    border-bottom: none;
    padding-bottom: var(--head-padding-bottom, 8px);
    position: relative;
    &:after {
      content: '';
      position: absolute;
      height: 2px;
      background: $grey-2-next;
      bottom: 0;
      width: calc(100% - var(--padding-left, 0px) - var(--padding-right, 0px));
      left: var(--padding-left, 0);
    }
  }

  .tb-Table-sticky-header &,
  .tb-Table-primary-next.tb-Table-sticky-header.tb-Table-with-offset & {
    position: sticky;
    top: var(--sticky-top, 0);
    background: $white;
    z-index: 5;
  }
}

.tb-HeaderCell {
  font-size: $fs-14;
  color: $dark-1;
  font-weight: fw('medium');
  position: relative;
  z-index: 6;
  line-height: 20px;

  .tb-Table-mini &,
  .tb-Table-mini-next & {
    font-style: normal;

    z-index: 1;
    min-width: 60px;
    padding: var(--header-cell-padding, 0 5px);

    font-size: $fs-12;
    line-height: 16px;

    &:last-child {
      padding-right: 0;
    }
  }

  .tb-Table-mini & {
    color: $medium-black;
    font-family: $system-ui;
    font-weight: fw('regular');
  }

  .tb-Table-mini-next & {
    color: $dark-3;
    font-family: $system-ui;
    font-weight: fw('bold');
  }

  .tb-Table-primary-next & {
    font-style: normal;
    color: $dark-3;
    z-index: 1;
    font-size: $fs-12;
    line-height: 16px;
    font-weight: fw('bold');
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.tb-Body {
  .tb-Scrollable & {
    position: relative;
    background: $white;
    border-bottom: 1px solid $grey-10;

    .tb-HeaderCell {
      font-size: $fs-14;
      font-weight: fw('medium');
      padding-bottom: 8px;
      position: relative;
      z-index: 6;
      line-height: 16px;
      padding-right: 12px;

      &:first-child {
        padding-left: 8px;
      }
    }
  }
}

.tb-Row {
  display: flex;
  align-items: center;
  padding: 14px 24px;
  min-height: 48px;
  .tb-Table-mini &,
  .tb-Table-mini-next & {
    padding: 0;
    min-height: unset;
  }

  .tb-Table-primary-next & {
    padding: 0;
    min-height: 44px;
  }
}

.tb-RowError {
  margin-block: 4px;
}

.tb-Cell {
  flex-shrink: 0;
  min-width: 0;
  color: $dark-1;

  .tb-Table-mini &,
  .tb-Table-mini-next & {
    font-style: normal;
    min-width: 60px;
    padding: var(--cell-padding, 13px 5px);
    text-align: center;

    font-size: $fs-14;
    line-height: 20px;

    &:last-child {
      padding-right: 0;
    }
  }

  .tb-Table-mini & {
    color: $dark-1;
    font-weight: fw('regular');
  }

  .tb-Table-mini-next & {
    font-weight: fw('semi-bold');
    color: $dark-1;
  }
  &-action {
    &:deep(.ab-Button) {
      font-weight: fw('regular');
    }
  }
}

.tb-RowWrapper {
  position: relative;
  border-radius: var(--row-border-radius, 0);

  &-error {
    --row-border-color: #{$grade-low-color-next};
    --row-border-height: 2px;
  }

  .tb-Table-bordered-last-row & {
    border-bottom: 1px solid $grey-10;
  }

  border-bottom: 1px solid $grey-10;

  .tb-Table-primary-next & {
    border-color: $grey-2-next;
  }

  .tb-Table-primary-next.tb-Table-with-offset & {
    border: none;
    position: relative;
    padding-bottom: 1px;
    &:after {
      content: '';
      position: absolute;
      height: var(--row-border-height, 1px);
      background: var(--row-border-color, #{$grey-2-next});
      bottom: 0;
      width: calc(100% - v-bind(offsetLeft) - v-bind(offsetRight));
      left: v-bind(offsetLeft);
    }
  }

  &:not(.tb-Table-bordered-last-row &) {
    &:last-child {
      border-bottom: none;
      &:after {
        display: none;
      }
    }
  }

  .tb-Table-mini-next & {
    border-color: $grey-2-next;
  }

  &:hover,
  &-hovered {
    &:not(.tb-Table-primary-next &) {
      background-color: $grey-2;
    }

    .tb-Table-primary-next & {
      background-color: $grey-3-next;
    }

    .tb-Table-no-hover & {
      background-color: initial;
    }
  }
}

.tb-Loading {
  display: flex;
  justify-content: center;
  position: relative;
  min-height: 49px;
  border-bottom: 1px solid $grey-medium;
  margin: 0 40px;
}

.tb-EndBlock {
  .tb-Table-secondary & {
    box-shadow: inset 0 -1px 0 $grey-10;
    height: 1px;
    margin: 0;
  }

  .tb-Table-empty.tb-Table-secondary & {
    display: none;
  }
}

%table-right-element {
  position: absolute;
  right: 0;
  top: 0;
  pointer-events: none;
}

.tb-RightObserver {
  @extend %table-right-element;
  height: 100%;
  width: 10px;
}

.tb-RightGradient {
  @extend %table-right-element;
  height: var(--height);
  width: 60px;
  background: linear-gradient(270deg, $white 0%, rgba($white, 0) 134.75%);
  z-index: 1;
}
</style>
