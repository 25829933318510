<template>
  <Modal
    :data-testid="$attrs['data-testid'] || null"
    :scrollable-wrapper="true"
    :show="opened"
    class="mlm-Modal"
    manual-close
    size="lg-next"
    sticky-header
    @close="onClose"
    @after-enter="onAfterEnter"
    @before-enter="onBeforeEnter"
  >
    <template #header>
      <div class="mlm-Header">
        <OkrIcon :objective="levelToOkrIconElement(formModel)" />
        <TitleWithAction class="oboard-truncated-text">
          {{ snapshot[NAME] }}
        </TitleWithAction>
      </div>
    </template>

    <template #before-close>
      <SavingIndicator ref="savingIndicator" />
    </template>

    <template #loader>
      <SavingIndicator ref="savingIndicatorTop" :type="SAVING_INDICATOR_TYPES.LINE" />
    </template>

    <template #modal-body>
      <div class="mlm-Body">
        <FormFieldNext :label="$t('levels.name')" data-auto-testid="manage-level-name">
          <AppInput
            ref="nameInputReference"
            v-model="formModel[NAME]"
            :is-error="fieldErrors[NAME].isEmpty"
            :max-length="LEVEL_NAME_MAX_LENGTH"
            blur-on-enter
            size="xlg"
            style-type="primary"
            @blur="onTextFieldBlur({ key: NAME })"
            @focus="fieldErrors[NAME].isEmpty = false"
          />
          <AppFieldError
            v-if="fieldErrors[NAME].isEmpty"
            :show="fieldErrors[NAME].isEmpty"
            data-auto-testid="manage-level-name-is-error"
          >
            {{ $t('field.required') }}
          </AppFieldError>
        </FormFieldNext>

        <div data-auto-testid="manage-level-icon-create">
          <AppIconCreator v-if="formModel[COLOR]" class="mlm-IconCreator" hide-result>
            <template #first-term>
              <FormFieldNext :label="$t('levels.prefix')" class="lm-Prefix">
                <AppInput
                  v-model="formModel[PREFIX]"
                  :is-error="fieldErrors[PREFIX].isEmpty"
                  :max-length="LEVEL_PREFIX_MAX_LENGTH"
                  blur-on-enter
                  data-auto-testid="level-prefix-input"
                  data-testid="level-prefix-input"
                  size="xlg"
                  style-type="primary"
                  @blur="onTextFieldBlur({ key: PREFIX })"
                  @focus="fieldErrors[PREFIX].isEmpty = false"
                />
              </FormFieldNext>
            </template>

            <template #second-term>
              <AppPaletteColorPicker
                v-model:selected-color="selectedColor"
                :palette="CATEGORIZED_COLORS"
              />
            </template>
          </AppIconCreator>
          <AppFieldError
            v-if="fieldErrors[PREFIX].isEmpty"
            :show="fieldErrors[PREFIX].isEmpty"
            data-auto-testid="manage-level-prefix-is-error"
          >
            {{ $t('field.required') }}
          </AppFieldError>
        </div>

        <AppTable
          :border-on-last-row="isEmpty(selectedWorkspaces)"
          :columns="TABLE_COLUMNS"
          :data="tableData"
          :loading="isLevelLoading"
          class="mlm-Table"
          data-auto-testid="manage-level-table"
          no-border
          offset-left="var(--page-left-padding)"
          offset-right="var(--page-right-padding)"
          sticky-header
          type="primary-next"
        >
          <template #header-cell="{ column }">
            <AppCheckbox
              v-if="column.key === TABLE_COLUMNS_KEYS.CHECKBOX"
              :disabled="isEmpty(workspacesAvailableForSelection) || isUnlinkWorkspaceLoading"
              :model-value="isAllSelected"
              data-auto-testid="manage-level-select-all"
              name="select-all-workspaces"
              @update:model-value="onToggleSelectAll"
            />
          </template>

          <template #cell="{ columnKey, item }">
            <AppCheckbox
              v-if="columnKey === TABLE_COLUMNS_KEYS.CHECKBOX"
              v-model="selectedWorkspaces"
              :disabled="!!item.error || isUnlinkWorkspaceLoading"
              :value="item[WORKSPACE_ENTITY_KEYS.ID]"
              data-auto-testid="manage-level-select-item"
              name="workspace-checkbox"
            />

            <template v-if="columnKey === TABLE_COLUMNS_KEYS.NAME">
              <WorkspaceNameCell :workspace="item" />
            </template>

            <template v-if="columnKey === TABLE_COLUMNS_KEYS.ACTION">
              <AppButton
                :disable="!!item.error || isUnlinkWorkspaceLoading"
                class="mlm-DeleteWorkspaceBtn"
                data-auto-testid="manage-level-delete-item"
                icon="delete-next"
                size="sm"
                type="tertiary-next"
                @click="onUnlinkWorkspace(item)"
              />
            </template>
          </template>

          <template #loading>
            <LevelWorkspacesLoader :levels-count="levelsCount" />
          </template>

          <template #footer>
            <AppTableCreateButton
              v-if="isEmpty(selectedWorkspaces)"
              :disable="isLevelLoading"
              full-width
              icon-name="plus-next"
              style="--gap: 8px"
              @click="isLinkWorkspacesToLevelModalShow = true"
            >
              {{ t('levels.add_workspace') }}
            </AppTableCreateButton>

            <AppSnackbar
              :actions="snackbarActions"
              :count="selectedWorkspaces.length"
              class="mlm-Snackbar"
              inline
              sticky
              style="--text-color: var(--dark-2)"
              @close="selectedWorkspaces = []"
              @action-click="onSnackbarActionClick"
            />
          </template>
        </AppTable>
      </div>
    </template>
  </Modal>

  <portal to="modal-windows">
    <AppDialog
      :show="isConfirmCloseShow"
      :title="$t('confirm_modal.title')"
      :type="DIALOG_TYPES.WARNING"
      @on-close="hideConfirmCloseModal"
      @on-confirm="onConfirmClose"
    >
      {{ $t('confirm_modal.description') }}

      <template #confirm-btn-text>
        {{ $t('confirm.discard_btn') }}
      </template>
    </AppDialog>

    <LinkWorkspacesToLevelModal
      :existed-workspaces="levelWorkspaces.map(ws => ws[WORKSPACE_ENTITY_KEYS.ID])"
      :level="formModel"
      :show="isLinkWorkspacesToLevelModalShow"
      @on-close="isLinkWorkspacesToLevelModalShow = false"
      @on-workspaces-linked="
        () => {
          isLinkWorkspacesToLevelModalShow = false
          reloadLevel()
        }
      "
    />
  </portal>
</template>

<script setup>
import { cloneDeep, isEmpty, isEqual } from 'lodash'
import { computed, nextTick, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import LevelsApiHandler from '@/api/level'
import { tracker } from '@/tracking/amplitude'
import { EVENT_CATEGORIES } from '@/tracking/amplitude-helpers'
import { ACTIONS_KEYS } from '@/utils/actions-keys'
import { DIALOG_TYPES } from '@/utils/components-configurations/app-dialog'
import { SAVING_INDICATOR_TYPES } from '@/utils/components-configurations/saving-indicator'
import { OKR_LEVEL_ENTITY_KEYS, WORKSPACE_ENTITY_KEYS } from '@/utils/entity-keys'
import { handleError } from '@/utils/error-handling'
import { levelToOkrIconElement } from '@/utils/objectives'
import {
  CATEGORIZED_COLORS,
  getErrorMessageByTypeId,
  LEVEL_NAME_MAX_LENGTH,
  LEVEL_PREFIX_MAX_LENGTH,
  normalizeLevelColor
} from '@/utils/okr-levels'
import { objectOrNullProp } from '@/utils/prop-validators'
import { TABLE_COLUMNS_KEYS } from '@/utils/table-columns'

import AppDialog from '@/components/AppDialog'
import AppFieldError from '@/components/form/AppFieldError'
import FormFieldNext from '@/components/form/FormFieldNext'
import OkrIcon from '@/components/objectives/items/OkrIcon'
import TitleWithAction from '@/components/objectives/TitleWithAction'
import SavingIndicator from '@/components/SavingIndicator'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppCheckbox from '@/components/ui/AppCheckbox/AppCheckbox'
import AppIconCreator from '@/components/ui/AppIconCreator/AppIconCreator'
import AppInput from '@/components/ui/AppInput/AppInput'
import AppPaletteColorPicker from '@/components/ui/AppPaletteColorPIcker/AppPaletteColorPicker'
import AppSnackbar from '@/components/ui/AppSnackbar/AppSnackbar'
import AppTableCreateButton from '@/components/ui/AppTableCreateButton'
import Modal from '@/components/ui/Modal/Modal'
import LevelWorkspacesLoader from '@/components/ui/SkeletonLoaders/LevelWorkspacesLoader'
import AppTable from '@/components/ui/Table/AppTable'
import LinkWorkspacesToLevelModal from '@/components/workspaces/LinkWorkspacesToLevelModal'
import WorkspaceNameCell from '@/components/workspaces/WorkspaceNameCell'

defineOptions({
  name: 'ManageLevelModal'
})

const props = defineProps({
  opened: {
    type: Boolean
  },

  level: {
    required: true,
    validator: v => objectOrNullProp(v)
  }
})

const { ID, NAME, COLOR, PREFIX, ORDER_VALUE, LEVEL_ID } = OKR_LEVEL_ENTITY_KEYS

const DEFAULT_FIELD_ERRORS_STATE = {
  [OKR_LEVEL_ENTITY_KEYS.PREFIX]: {
    isEmpty: false
  },
  [OKR_LEVEL_ENTITY_KEYS.NAME]: {
    isEmpty: false
  }
}

const { t } = useI18n()

const TABLE_COLUMNS = [
  {
    key: TABLE_COLUMNS_KEYS.CHECKBOX,
    width: 32
  },

  {
    title: t('workspaces.name'),
    key: NAME,
    width: 'auto'
  },
  {
    key: TABLE_COLUMNS_KEYS.ACTION,
    width: 24
  }
]

const snackbarActions = computed(() => {
  return [
    {
      name: ACTIONS_KEYS.DELETE,
      loading: isUnlinkWorkspaceLoading.value,
      icon: 'delete-next',
      title: 'action.delete',
      color: 'var(--grade-low-color-next)'
    }
  ]
})

watch(
  () => props.opened,
  newValue => {
    if (!newValue) {
      setDefaultState()
    }
  }
)

const setDefaultState = () => {
  formModel.value = {}
  fieldErrors.value = cloneDeep(DEFAULT_FIELD_ERRORS_STATE)
  isConfirmCloseShow.value = false
  closeModalOnSavingFinished.value = false
  isSaving.value = false
  snapshot.value = {}
  levelWorkspaces.value = []
  tableHoverRow.value = null
  selectedWorkspaces.value = []
  isAllSelected.value = false
  workspacesErrors.value = {}
  levelsCount.value = 0
}

const emit = defineEmits({
  'on-close': null
})

const savingIndicator = ref(null)
const savingIndicatorTop = ref(null)
const isSaving = ref(false)
const closeModalOnSavingFinished = ref(false)

const isConfirmCloseShow = ref(false)

const hideConfirmCloseModal = () => {
  isConfirmCloseShow.value = false
}

const onConfirmClose = () => {
  hideConfirmCloseModal()
  checkConfirmationAndCloseModal({ checkDataChange: false })
}

const checkConfirmationAndCloseModal = ({ checkDataChange = true } = {}) => {
  if (checkDataChange !== false && areDataChanged.value) {
    isConfirmCloseShow.value = true
  } else {
    emit('on-close')
  }
}

const onClose = () => {
  if (isSaving.value) {
    closeModalOnSavingFinished.value = true
  } else {
    checkConfirmationAndCloseModal()
  }
}

const snapshot = ref({})

const onBeforeEnter = () => {
  setupFormModel({
    level: props.level
  })
}

const nameInputReference = ref(null)

const onAfterEnter = async () => {
  await nextTick()
  nameInputReference.value.focus()
}

const formModel = ref({})
const levelWorkspaces = ref([])
const tableHoverRow = ref(null)
const levelsCount = ref(0)

const setupFormModel = ({ level } = {}) => {
  const { color } = level
  const clone = cloneDeep(level)
  clone[COLOR] = normalizeLevelColor({ color })
  delete clone[OKR_LEVEL_ENTITY_KEYS.WORKSPACES]
  snapshot.value = cloneDeep(clone)
  formModel.value = cloneDeep(clone)
  levelWorkspaces.value = cloneDeep(level[OKR_LEVEL_ENTITY_KEYS.WORKSPACES])
  levelsCount.value = levelWorkspaces.value.length
}

const onSavingStarted = () => {
  isSaving.value = true
  savingIndicator.value.startSaving()
  savingIndicatorTop.value.startSaving()
}

const onSavingFinished = () => {
  isSaving.value = false
  savingIndicator.value.endSaving()
  savingIndicatorTop.value.endSaving()
  if (closeModalOnSavingFinished.value) {
    checkConfirmationAndCloseModal({ checkDataChange: false })
  }
}

const updateLevel = async ({ key = null, value = null }) => {
  if (!key) {
    return
  }

  const payload = {
    [LEVEL_ID]: props.level[ID],
    [COLOR]: formModel.value[COLOR],
    [PREFIX]: formModel.value[PREFIX],
    [ORDER_VALUE]: props.level[ORDER_VALUE],
    [NAME]: formModel.value[NAME],
    [key]: value
  }

  payload[COLOR] = payload[COLOR].substring(1)

  const api = new LevelsApiHandler()

  try {
    onSavingStarted()
    const level = await api.updateLevel(payload)

    setupFormModel({ level })

    tracker.logEvent('Level updated', {
      category: EVENT_CATEGORIES.LEVEL_MANAGEMENT,
      oldLabel: snapshot.value[NAME],
      label: payload[NAME],
      prefix: payload[PREFIX],
      color: payload[COLOR],
      oldPrefix: snapshot.value[PREFIX],
      oldColor: snapshot.value[COLOR]
    })
  } catch (error) {
    handleError({ error })
    closeModalOnSavingFinished.value = false
  } finally {
    onSavingFinished()
  }
}

const isLevelLoading = ref(false)

const reloadLevel = async () => {
  const api = new LevelsApiHandler()
  isLevelLoading.value = true
  levelWorkspaces.value = []
  try {
    const level = await api.getLevelById({
      levelId: props.level[ID]
    })
    setupFormModel({ level })
  } catch (error) {
    handleError({ error })
  } finally {
    isLevelLoading.value = false
  }
}

const onTextFieldBlur = async ({ key }) => {
  if (formModel.value[key].trim() === '') {
    fieldErrors.value[key].isEmpty = true
    return
  }

  if (snapshot.value[key] === formModel.value[key]) {
    return
  }

  await updateLevel({
    key,
    value: formModel.value[key]
  })
}

const fieldErrors = ref(cloneDeep(DEFAULT_FIELD_ERRORS_STATE))

const selectedColor = computed({
  get: () => normalizeLevelColor({ color: formModel.value[COLOR] }),
  set: value => {
    updateLevel({ key: COLOR, value })
  }
})

const areDataChanged = computed(() => !isEqual(formModel.value, snapshot.value))

const selectedWorkspaces = ref([])

const isAllSelected = ref(false)
const onToggleSelectAll = value => {
  if (value) {
    selectedWorkspaces.value = [...workspacesAvailableForSelection.value]
  } else {
    selectedWorkspaces.value = []
  }
  isAllSelected.value = value
}

const workspacesAvailableForSelection = computed(() => {
  return levelWorkspaces.value
    .filter(ws => !Object.keys(workspacesErrors.value).includes(`${ws[ID]}`))
    .map(ws => ws[ID])
})

watch(
  () => selectedWorkspaces.value,
  newValue => {
    isAllSelected.value =
      newValue.length === workspacesAvailableForSelection.value.length &&
      workspacesAvailableForSelection.value.length > 0
  }
)

const handleFailedWorkspaces = ({ failedWorkspaces = [] } = {}) => {
  selectedWorkspaces.value = []

  const newErrors = failedWorkspaces.reduce((acc, ws) => {
    return {
      ...acc,
      [ws[WORKSPACE_ENTITY_KEYS.WORKSPACE_ID]]: ws.reasonTypeId
    }
  }, {})

  workspacesErrors.value = {
    ...workspacesErrors.value,
    ...newErrors
  }
}

const onUnlinkWorkspace = async ws => {
  isUnlinkWorkspaceLoading.value = true
  onSavingStarted()
  const api = new LevelsApiHandler()
  try {
    const { failedWorkspaces } = await api.unlinkWorkspaceFromLevel({
      levelId: props.level[ID],
      workspaceId: ws[ID]
    })

    handleFailedWorkspaces({ failedWorkspaces })

    await reloadLevel()
  } catch (error) {
    handleError({ error })
  } finally {
    isUnlinkWorkspaceLoading.value = false
    onSavingFinished()
  }
}

const workspacesErrors = ref({})
const isUnlinkWorkspaceLoading = ref(false)

const onUnlinkWorkspaces = async () => {
  isUnlinkWorkspaceLoading.value = true
  onSavingStarted()
  const api = new LevelsApiHandler()
  try {
    const { failedWorkspaces } = await api.bulkUnlinkWorkspacesFromLevel({
      levelId: props.level[ID],
      workspaceIds: selectedWorkspaces.value
    })

    handleFailedWorkspaces({ failedWorkspaces })

    await reloadLevel()
  } catch (error) {
    handleError({ error })
  } finally {
    isUnlinkWorkspaceLoading.value = false
    onSavingFinished()
  }
}

const onSnackbarActionClick = name => {
  if (name === ACTIONS_KEYS.DELETE) {
    onUnlinkWorkspaces()
  }
}

const tableData = computed(() => {
  return levelWorkspaces.value.map(ws => {
    const errorTypeId = workspacesErrors.value[ws[ID]]

    return {
      ...ws,
      error: !!errorTypeId && t(getErrorMessageByTypeId({ typeId: errorTypeId }))
    }
  })
})

const isLinkWorkspacesToLevelModalShow = ref(false)
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/app-table-helpers';

.mlm-Modal {
  --modal-header-padding: 30px #{$page-right-padding-next} 30px #{$page-left-padding-next};

  :deep(.o-modal-content) {
    height: auto;
  }
}

.mlm-Header {
  display: flex;
  align-items: center;
  gap: 18px;
  overflow: hidden;
}

.mlm-IconCreator {
  --select-width: 180px;
  --symbol-width: 40px;
}

.mlm-Body {
  padding: 0 $page-right-padding-next 20px $page-left-padding-next;
  display: flex;
  flex-direction: column;
  gap: 28px;
  width: calc(400px + #{$page-right-padding-next} + #{$page-left-padding-next});
}

.mlm-DeleteWorkspaceBtn {
  @extend %app-table-hidden-items;
}

.mlm-Table {
  --sticky-top: 100px;
  --row-border-radius: #{$border-radius-sm-next};
  --checkmark-margin: 0 4px;
}

.mlm-Snackbar {
  --padding-right: #{$page-right-padding};
  --padding-left: #{$page-left-padding};
}
</style>
