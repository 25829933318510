import ApiHandler from '@/api/base'

class WorkspaceUsersApiHandler extends ApiHandler {
  async getUsersForMentioning({ accountIds, searchString, limit, workspaceId } = {}) {
    const { data } = await this.http.post('/getUsersForMentioning', {
      accountIds,
      searchString,
      limit,
      workspaceId
    })
    return data
  }

  async updateUser({
    accountId = null,
    defaultTeamId = null,
    name = null,
    roleId = null,
    teamIds = null,
    workspaceId = null
  } = {}) {
    const { data } = await this.http.post('/updateUser', {
      accountId,
      defaultTeamId,
      name,
      roleId,
      teamIds,
      workspaceId
    })
    return data
  }

  async getUsersFromWorkspace({
    workspaceId,
    groupIds,
    roleIds,
    searchString = null,
    accountIds = [],
    startAt = 0,
    limit = 50
  } = {}) {
    const { data } = await this.http.get('/users', {
      params: {
        workspaceId,
        groupIds,
        roleIds,
        searchString,
        accountIds,
        startAt,
        limit
      }
    })

    return data
  }
  async updateUserRoleBulk({ accountIds = null, roleId = null, workspaceId = null } = {}) {
    const { data } = await this.http.put('/users/updateRoleBulk', {
      accountIds,
      roleId,
      workspaceId
    })
    return data
  }

  async addUsersToWorkspace({ usersToCreate = [], workspaceId = null } = {}) {
    const { data } = await this.http.post('/user', {
      usersToCreate,
      workspaceId
    })
    return data
  }
}

export default WorkspaceUsersApiHandler
