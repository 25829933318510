import { ref } from 'vue'

import AssigneesInfoApiHandler from '@/api/assignees-info'
import { REQUEST_ENTITY_KEYS } from '@/utils/entity-keys'
import { handleError } from '@/utils/error-handling'

export const useFetchAssignees = ({ workspaceId, selectedAssignees = [] } = {}) => {
  const isLoading = ref(false)

  const getAssignees = async (searchString = null) => {
    const api = new AssigneesInfoApiHandler()
    let result = []
    try {
      isLoading.value = true
      result = await api.getUsers({
        searchString,
        workspaceId,
        [REQUEST_ENTITY_KEYS.REQUIRED_USERS_ACCOUNT_IDS]: selectedAssignees
      })
    } catch (error) {
      handleError({ error })
    } finally {
      isLoading.value = false
    }
    return result
  }

  return {
    isLoading,
    getAssignees
  }
}
