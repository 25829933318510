<template>
  <div class="udt-Loader">
    <div v-for="row in ROWS" :key="row" class="udt-Loader_Row">
      <div class="udt-Cell">
        <SkeletonItem
          :appearance="SKELETON_APPEARANCES.SQUARE"
          :size="SM"
          border-radius="3px"
          height="14px"
          width="14px"
        />
      </div>
      <div class="udt-Cell">
        <SkeletonItem :appearance="SKELETON_APPEARANCES.CIRCLE" height="24px" width="24px" />
        <SkeletonItem :size="XS" :width="row.name" />
      </div>
      <div class="udt-Cell">
        <SkeletonItem
          v-if="row.withSync"
          :appearance="SKELETON_APPEARANCES.CIRCLE"
          border-radius="6px"
          height="24px"
          width="24px"
        />
      </div>
      <div class="udt-Cell">
        <SkeletonItem
          v-for="n in row.groups"
          :key="n"
          :color="getRandomGroupColor()"
          :size="SM"
          border-radius="6px"
          height="24px"
          width="24px"
        />
      </div>
      <div class="udt-Cell">
        <SkeletonItem :size="XS" :width="row.role" />
      </div>
      <div class="udt-Cell" />
    </div>
    <div class="udt-AddUser">
      <SkeletonItem height="24px" width="24px" />
      <SkeletonItem :size="XS" width="65px" />
    </div>
    <div class="udt-FooterNav">
      <SkeletonItem height="32px" width="92px" />
      <SkeletonItem :size="XS" width="65px" />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'WorkspaceUsersTableLoader'
})
</script>
<script setup>
import {
  SKELETON_APPEARANCES,
  SKELETON_SIZES
} from '@/utils/components-configurations/skeleton-item'
import { getRandomGroupColor } from '@/utils/global-groups'

import SkeletonItem from '@/components/ui/SkeletonLoaders/SkeletonItem'

const { SM, XS } = SKELETON_SIZES

const ROWS = [
  {
    id: 0,
    name: '90px',
    groups: 1,
    role: '122px',
    action: '24px'
  },
  {
    id: 1,
    name: '110px',
    groups: 3,
    role: '40px',
    action: '24px'
  },
  {
    id: 2,
    name: '60px',
    groups: 3,
    role: '70px',
    action: '24px',
    withSync: true
  },
  {
    id: 3,
    name: '150px',
    groups: 2,
    role: '40px',
    action: '24px',
    withSync: true
  },
  {
    id: 4,
    name: '100px',
    groups: 1,
    role: '40px',
    action: '24px',
    withSync: true
  },
  {
    id: 5,
    name: '100px',
    groups: 7,
    role: '40px',
    action: '24px'
  },
  {
    id: 6,
    name: '100px',
    groups: 10,
    role: '40px',
    action: '24px',
    withSync: true
  },
  {
    id: 7,
    name: '100px',
    groups: 3,
    role: '40px',
    action: '24px'
  },
  {
    id: 8,
    name: '100px',
    groups: 1,
    role: '40px',
    action: '24px'
  },
  {
    id: 9,
    name: '100px',
    groups: 2,
    role: '40px',
    action: '24px',
    withSync: true
  },
  {
    id: 10,
    name: '100px',
    groups: 4,
    role: '40px',
    action: '24px',
    withSync: true
  }
]
</script>

<style lang="scss" scoped>
.udt-Loader_Row {
  height: 45px;
  display: flex;
  align-items: center;
}

.udt-Cell {
  display: flex;
  align-items: center;

  &:first-child {
    flex: 0 0 28px;
  }

  &:nth-child(2) {
    gap: 8px;
    flex: 1 1 100%;
  }
  &:nth-child(3) {
    flex: 0 0 32px;
    gap: 8px;
  }

  &:nth-child(4) {
    flex: 1 0 346px;
    gap: 4px;
  }

  &:nth-child(5) {
    flex: 1 0 152px;
    gap: 8px;
  }

  &:nth-child(6) {
    flex: 0 0 24px;
    gap: 8px;
  }
}
.udt-FooterNav {
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0 32px;
}
.udt-AddUser {
  display: flex;
  align-items: center;
  gap: 8px;
}
</style>
