<template>
  <div class="smc-Content">
    <div class="smc-Subtitle">
      {{ t('support.subtitle') }}
    </div>
    <BoxItems :items-per-row="2" :options="BOXES" />

    <AppTitle :level="3" class="smc-Title" disable-margin>
      {{ t('support.consulting') }}
    </AppTitle>

    <BoxItems :options="CONSULTING_BOXES" />

    <div class="smc-AboutApp">
      <div>
        {{ t('support.response_time') }}
        <!-- eslint-disable @intlify/vue-i18n/no-raw-text -->
        <i18n-t keypath="support.support_hours" scope="global" tag="b">
          <template #hours> 9<sup>AM</sup> - 5<sup>PM</sup></template>
        </i18n-t>
        <!-- eslint-enable @intlify/vue-i18n/no-raw-text -->
      </div>

      <div class="smc-AboutApp_Info">
        <div class="smc-AppInfo_Item">
          {{ t('support.plugin_version') }}
          <div class="smc-AppProperty">
            <span class="oboard-truncated-text">
              {{ version }}
            </span>

            <AppButton
              class="smc-CopyButton"
              height="24"
              icon="copy-standard"
              remove-padding
              size="sm"
              type="ghost-next"
              width="24"
              @click="onCopyVersionClick(version)"
            />
          </div>
        </div>

        <div v-if="!isPluginServer" class="smc-AppInfo_Item">
          {{ t('support.instance_id') }}
          <div class="smc-AppProperty">
            <span class="oboard-truncated-text">
              {{ iss }}
            </span>
            <AppButton
              class="smc-CopyButton"
              height="24"
              icon="copy-standard"
              remove-padding
              size="sm"
              type="ghost-next"
              width="24"
              @click="onCopyVersionClick(iss)"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="smc-Footer">
      <a :href="SECURITY_URL" class="smc-BtnSecurity" target="_blank">
        <AppIcon height="36" icon-name="atlassian-logo" width="30" />
        {{ t('support.security_program') }}
      </a>

      <AppDivider class="smc-Footer_Divider" vertical />

      <AppSocialMediaLinks class="smc-Links" />
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

import { copyToClipboard, getPluginVersion } from '@/utils/general'
import { memoizeGetCssVariableValue, memoizeHexToRgb } from '@/utils/memoizations'
import { showNotify } from '@/utils/notify'
import { SECURITY_URL, SUPPORT_BOXES, useSupportHelpers } from '@/utils/support'
import { PLUGIN_OPTIONS_KEYS } from '@root/template-options-keys'

import BoxItems from '@/components/support/BoxItems'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppDivider from '@/components/ui/AppDivider'
import AppIcon from '@/components/ui/AppIcon/AppIcon'
import AppSocialMediaLinks from '@/components/ui/AppSocialMediaLinks/AppSocialMediaLinks'
import AppTitle from '@/components/ui/AppTitle/AppTitle'

defineOptions({
  name: 'SupportModalContent'
})

const store = useStore()

const backendVersion = computed(() => store.state.pluginOptions.backendVersion)

const version = computed(() => getPluginVersion(backendVersion.value))

const iss = computed(() => {
  return (
    store.state.appContext.selectedOrganizationId ||
    store.state.pluginOptions[PLUGIN_OPTIONS_KEYS.ISS]
  )
})

const onCopyVersionClick = text => {
  copyToClipboard(text)
  showNotify({ title: t('notifications.plugin_version_copied') })
}

const { t } = useI18n()

const { prepareBoxItem } = useSupportHelpers()

const BOXES = [
  {
    ...SUPPORT_BOXES.CONTACT_US,
    hero: 'mail-small',
    heroSize: 24
  },

  {
    ...SUPPORT_BOXES.HELP_CENTER,
    subtitle: 'support.help_center.description',
    heroSize: 24,
    hero: 'book'
  },

  {
    ...SUPPORT_BOXES.ONBOARDING_CALL,
    heroSize: 24,
    person: 'onboarding-call',
    hero: 'phone'
  },

  {
    ...SUPPORT_BOXES.VIDEO_GUIDES,
    hero: 'youtube-colored-small',
    heroSize: 24
  }
].map(prepareBoxItem)

const CONSULTING_BOXES = [
  {
    ...SUPPORT_BOXES.OKR_CONSULTING,
    hero: 'okr-consulting',
    heroSize: 24,
    person: 'okr-consulting'
  },
  {
    ...SUPPORT_BOXES.DEFINE_YOUR_OKR,
    heroSize: 24
  }
].map(box => {
  const { color, title, linkText, ...rest } = box
  const hex = memoizeGetCssVariableValue(color)
  const rgb = memoizeHexToRgb(hex.trim())
  return {
    ...rest,
    hex,
    rgb,
    title: t(title),
    linkText: t(linkText)
  }
})

const isPluginServer = computed(() => store.getters['pluginOptions/isPluginServer'])
</script>

<style lang="scss" scoped>
.smc-Subtitle {
  color: $dark-2;
  font-size: $fs-14;
  line-height: 20px;
  white-space: break-spaces;
}

.smc-Content {
  display: flex;
  gap: 20px;
  flex-direction: column;
  --box-item-gap: 8px;
  --box-item-header-gap: 8px;
}

.smc-Title {
  line-height: 28px;
  margin-top: 12px;
}

.smc-AboutApp {
  display: grid;
  gap: 14px;
  padding: 20px;
  background-color: $grey-3-next;
  border-radius: $border-radius-lg-next;

  font-weight: fw('regular');
  font-size: $fs-12;
  line-height: 16px;
  color: $dark-2;
}

$min-width: 180px;

.smc-AboutApp_Info {
  display: grid;
  grid-template-columns: auto minmax(#{$min-width}, 1fr);
  gap: 8px;
}

.smc-AppInfo_Item {
  overflow: hidden;
  min-width: $min-width;

  &:first-child {
    flex-shrink: 0;
  }
}

.smc-CopyButton {
  color: $dark-2;
  flex-shrink: 0;
}

.smc-AppProperty {
  color: $dark-2;
  font-weight: fw('bold');
  display: flex;
  align-items: center;
  gap: 4px;
  max-width: 100%;
}

.smc-Footer {
  border: 2px solid $grey-3-next;
  border-radius: $border-radius-lg-next;
  min-height: 76px;
  display: flex;
  align-items: center;
}

.smc-Footer_Divider {
  min-height: 35px;
  align-self: center;
}

.smc-BtnSecurity {
  font-style: normal;
  font-weight: fw('regular');
  font-size: $fs-14;
  line-height: 20px;
  color: $dark-3;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  gap: 20px;
  padding-inline: 20px;
  justify-self: center;
  width: calc((100% - 1px) / 2);
}

.smc-Links {
  width: calc((100% - 1px) / 2);
}
</style>
