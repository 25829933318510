import ApiHandler from '@/api/base'
import { OKR_BULK_UPDATE_ENTITY_KEYS } from '@/utils/entity-keys'

const normalizeFieldsResponse = ({ fields = [] } = {}) => {
  return fields.map(({ id, disabled }) => ({
    fieldId: id,
    disabled
  }))
}

class OkrElementsBulkUpdateApiHandler extends ApiHandler {
  async bulkDeleteOkrElements({ elementIds }) {
    const { data } = await this.http.post('/elements/bulk/remove', {
      elementIds
    })

    return data
  }

  async validateBulkUpdate({ elementIds, workspaceId }) {
    const { data } = await this.http.post('/elements/bulk/validate', {
      elementIds,
      workspaceId
    })

    return {
      [OKR_BULK_UPDATE_ENTITY_KEYS.CORE_FIELDS]: normalizeFieldsResponse({
        fields: data[OKR_BULK_UPDATE_ENTITY_KEYS.CORE_FIELDS]
      }),
      [OKR_BULK_UPDATE_ENTITY_KEYS.CUSTOM_FIELDS]: normalizeFieldsResponse({
        fields: data[OKR_BULK_UPDATE_ENTITY_KEYS.CUSTOM_FIELDS]
      })
    }

    // const result = {
    //   coreFields: Object.values(CORE_FIELDS_IDS).map(fieldId => {
    //     return {
    //       fieldId,
    //       disabled: false // ![4].includes(fieldId) //random(1, 100) > 50
    //     }
    //   }),
    //   // warden 6
    //   // cake size 7
    //   // date of release 9
    //   // birds in the sky 10
    //   // budget 11
    //   // your price 13
    //   // Favorite character 16
    //   customFields: [6, 7, 9, 10, 11, 13, 16].map(fieldId => {
    //     return {
    //       fieldId,
    //       disabled: false // ![7].includes(fieldId) //random(1, 100) > 50
    //     }
    //   })
    // }
    //
    // return result
  }

  async bulkUpdateOkrElements({ elementIds, workspaceId, coreFieldUpdates, customFieldUpdates }) {
    const { data } = await this.http.post('/elements/bulk/update', {
      elementIds,
      workspaceId,
      coreFieldUpdates,
      customFieldUpdates
    })

    return data

    /*    logAttentionPhrase('do not forget to add the implementation')

    console.table({
      name: 'mocked request bulk delete',
      elementIds,
      workspaceId,
      coreFieldUpdates,
      customFieldUpdates
    })

    const response = {
      total: elementIds.length,
      success: random(1, 100) % 2 === 0 ? elementIds.length : random(0, elementIds.length)
    }

    return new Promise(resolve => {
      setTimeout(() => {
        resolve(response)
      }, 1000)
    })*/
  }
}

export default OkrElementsBulkUpdateApiHandler
