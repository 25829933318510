<template>
  <div
    :class="{
      'fsb-Button-active': opened,
      'fsb-Button-disabled': disabled,
      'fsb-Button-outline': outlineButtonStyle
    }"
    :style="{ '--color': option.color, '--rgb-color': option.rgbColor }"
    class="fsb-Button"
  >
    <div class="fsb-Button_Content oboard-truncated-text">
      <slot>
        {{ option.label }}
      </slot>
    </div>
    <AppIcon
      v-if="!disabled"
      :height="getIconSize"
      :width="getIconSize"
      class="fsb-Button_Icon"
      icon-name="arrow-down-black"
    />
  </div>
</template>

<script>
// eslint-disable-next-line import/order
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'FormStatusButton'
})
</script>

<script setup>
import { computed } from 'vue'

import AppIcon from '@/components/ui/AppIcon/AppIcon'

const props = defineProps({
  option: {
    type: Object,
    required: true
  },
  opened: {
    type: Boolean
  },
  disabled: {
    type: Boolean
  },
  outlineButtonStyle: {
    type: Boolean
  }
})

const getIconSize = computed(() => {
  if (props.outlineButtonStyle) {
    return 20
  }

  return 24
})
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';
.fsb-Button {
  display: flex;
  align-items: center;
  gap: 4px;

  border-radius: $border-radius-sm-next;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  color: $white;
  background-color: var(--color);
  font-family: $system-ui;
  font-size: $fs-12;
  text-transform: uppercase;
  line-height: 20px;
  font-weight: fw('semi-bold');

  &:not(&-disabled) {
    padding: 4px 4px 4px 12px;
  }
  &-outline {
    border: 2px solid rgba(var(--rgb-color), 0.3);
    color: var(--color);
    background-color: $white;
    &:not(&-disabled) {
      padding: 0 0 0 6px;
    }
  }

  &-disabled {
    padding: 4px 12px;
    cursor: default;
  }
}

.fsb-Button_Icon {
  transition: transform $transition-fast ease-in-out;
  flex-shrink: 0;
  .fsb-Button-active & {
    transform: rotate(180deg);
  }
}
</style>
