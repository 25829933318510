export const APP_SELECT_OPTIONS_LIST_TEST_ID = 'select-options-list'

export const OKR_ELEMENT_CREATOR_OPTIONS_LIST_TEST_ID = 'okr-element-creator-list'

export const EMPTY_STATE_OKR_ELEMENT_CREATOR_TEST_ID = `no-results-${OKR_ELEMENT_CREATOR_OPTIONS_LIST_TEST_ID}`

export const EMPTY_STATE_BY_INTERVALS_OKR_ELEMENT_CREATOR_TEST_ID = `no-results-by-intervals-${OKR_ELEMENT_CREATOR_OPTIONS_LIST_TEST_ID}`

export const MENU_OKR_ELEMENT_CREATOR_TEST_ID = `menu-${OKR_ELEMENT_CREATOR_OPTIONS_LIST_TEST_ID}`

export const TOOLBAR_OKR_ELEMENT_CREATOR_TEST_ID = `toolbar-${OKR_ELEMENT_CREATOR_OPTIONS_LIST_TEST_ID}`

export const HOMEPAGE_OKR_ELEMENT_CREATOR_TEST_ID = `homepage-${OKR_ELEMENT_CREATOR_OPTIONS_LIST_TEST_ID}`

export const OBJECTIVE_FORM_OKR_ELEMENT_CREATOR_TEST_ID = `child-objectives-${OKR_ELEMENT_CREATOR_OPTIONS_LIST_TEST_ID}`
