<template>
  <OkrElementsFormNumberInput
    v-model="localValue"
    :data-auto-testid="MONEY_FORM_FIELD_TEST_ID"
    :data-testid="MONEY_FORM_FIELD_TEST_ID"
    :field-id="fieldId"
    :prefix="currency"
    :user-has-update-access="userHasUpdateAccess"
    @blur="onBlur"
  />
</template>

<script setup>
import { computed } from 'vue'

import { MONEY_FORM_FIELD_TEST_ID } from '@/utils/custom-fields/jest-helpers'
import { useCustomFieldsHelpers } from '@/utils/custom-fields/use-custom-fields'
import { numberOrNullProp } from '@/utils/prop-validators'

import OkrElementsFormNumberInput from '@/components/ui/AppInputNumberNext/OkrElementsFormNumberInput'

defineOptions({
  name: 'MoneyField',
  inheritAttrs: false
})

const props = defineProps({
  userHasUpdateAccess: {
    type: Boolean,
    required: true
  },

  modelValue: {
    required: true,
    validator: v => numberOrNullProp(v)
  },

  fieldId: {
    type: [String, Number],
    required: true
  }
})

const emit = defineEmits({
  'update:model-value': null,
  'update-element': null
})

const localValue = computed({
  get: () => props.modelValue,
  set: value => {
    emit('update:model-value', {
      fieldId: props.fieldId,
      value,
      updateElement: false
    })
  }
})

const onBlur = () => emit('update-element', props.fieldId)

const { currency } = useCustomFieldsHelpers({
  fieldId: props.fieldId
})
</script>

<style lang="scss" scoped></style>
