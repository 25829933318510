import dayjs from 'dayjs'
import { ref } from 'vue'

import { tracker } from '@/tracking/amplitude'
import { EVENT_CATEGORIES } from '@/tracking/amplitude-helpers'
import { dateFormat } from '@/utils/date'
import { LICENSE_TYPES } from '@/utils/license-types'

export const YEAR = 'year'
export const MONTH = 'month'

const {
  WEB_APP_TRIAL,
  WEB_APP_OVERLIMIT,
  WEB_APP_TO_BE_CANCELED,
  WEB_APP_CANCELED,
  WEB_APP_BLOCKED,
  WEB_APP_PAST_DUE,
  WEB_APP_ACTIVE,
  WEB_APP_NONE
} = LICENSE_TYPES
export const ALL_SUBSCRIPTION_STATUSES = [
  {
    label: 'TRIALING',
    value: WEB_APP_TRIAL,
    color: 'var(--grade-medium-color-next)',
    backgroundColor: 'rgba(var(--grade-medium-color-rgb-next), 0.2)'
  },
  {
    label: 'OVERLIMIT',
    value: WEB_APP_OVERLIMIT,
    color: 'var(--grade-medium-color-next)',
    backgroundColor: 'rgba(var(--grade-medium-color-rgb-next), 0.2)'
  },
  // {
  //   label: 'INCOMPLETE EXPIRED',
  //   value: 'incomplete_expired',
  //   color: 'var(--grade-low-color-next)',
  //   backgroundColor: 'rgba(var(--grade-low-color-rgb-next), 0.2)'
  // },
  {
    label: 'TO BE CANCELLED',
    value: WEB_APP_TO_BE_CANCELED,
    color: 'var(--grade-medium-color-next)',
    backgroundColor: 'rgba(var(--grade-medium-color-rgb-next), 0.2)'
  },
  {
    label: 'CANCELED',
    value: WEB_APP_CANCELED,
    color: 'var(--grey-2-next)',
    backgroundColor: 'var(--dark-2)'
  },
  {
    label: 'BLOCKED',
    value: WEB_APP_BLOCKED,
    color: 'var(--grey-2-next)',
    backgroundColor: 'var(--dark-2)'
  },
  // {
  //   label: 'incomplete',
  //   value: 'incomplete',
  //   color: 'var(--grade-medium-color-next)',
  //   backgroundColor: 'rgba(var(--grade-medium-color-rgb-next), 0.2)'
  // },
  {
    label: 'PAST DUE',
    value: WEB_APP_PAST_DUE,
    color: 'var(--grade-medium-color-next)',
    backgroundColor: 'rgba(var(--grade-medium-color-rgb-next), 0.2)'
  },
  // {
  //   label: 'UNPAID',
  //   value: 'unpaid',
  //   color: 'var(--grade-low-color-next)',
  //   backgroundColor: 'rgba(var(--grade-low-color-rgb-next), 0.2)'
  // },
  {
    label: 'ACTIVE',
    value: WEB_APP_ACTIVE,
    color: 'var(--grade-high-color-next)',
    backgroundColor: 'rgba(var(--grade-high-color-rgb-next), 0.2)'
  },
  {
    label: 'NONE',
    value: WEB_APP_NONE,
    color: 'var(--grey-2-next)',
    backgroundColor: 'var(--dark-2)'
  }
]

export const ALL_INVOICES_STATUSES = [
  {
    label: 'Uncollectible',
    value: 'uncollectible',
    color: 'var(--grade-low-color-next)',
    backgroundColor: 'rgba(var(--grade-low-color-rgb-next), 0.2)'
  },
  {
    label: 'Draft',
    value: 'draft',
    color: 'var(--grey-semi-medium)',
    backgroundColor: 'rgba(var(--grey-semi-medium-rgb), 0.2)'
  },
  {
    label: 'Open',
    value: 'open',
    color: 'var(--primary-color-next)',
    backgroundColor: 'rgba(var(--primary-color-rgb-next), 0.2)'
  },
  {
    label: 'Paid',
    value: 'paid',
    color: 'var(--grade-high-color-next)',
    backgroundColor: 'rgba(var(--grade-high-color-rgb-next), 0.2)'
  },
  {
    label: 'Void',
    value: 'void',
    color: 'var(--grade-low-color-next)',
    backgroundColor: 'rgba(var(--grade-low-color-rgb-next), 0.2)'
  }
]

export const ESSENTIAL = 'Essential'
export const PROFESSIONAL = 'Professional'

export const ESSENTIAL_MONTHLY = {
  lookup_key: 'essential_month_v1',
  plan: ESSENTIAL,
  period: MONTH,
  title: 'Monthly',
  price: 7.5,
  billedPeriod: 'Billed monthly',
  total: 'Total $37.5 / month',
  discount: 0,
  isPopular: false,
  isEnterprise: false,
  name: 'Essential',
  subTitle: 'A place for small groups to plan & get organized.',
  priceWithSign: '$7.5',
  description: 'per user',
  description2: 'per month',
  features: ['OKR management', 'Roadmap', 'Reporting', 'Jira integration']
}

export const ESSENTIAL_ANNUALLY = {
  lookup_key: 'essential_year_v1',
  plan: ESSENTIAL,
  period: YEAR,
  title: 'Annually',
  price: 6,
  total: 'Total $30 / month',
  billedPeriod: 'Billed annually',
  discount: 18,
  isAnnual: true,
  isPopular: false,
  isEnterprise: false,
  name: 'Essential',
  subTitle: 'A place for small groups to plan & get organized.',
  priceWithSign: '$6',
  description: 'per user',
  description2: 'per month',
  features: ['OKR management', 'Roadmap', 'Reporting', 'Jira integration']
}

export const PROFESSIONAL_MONTHLY = {
  lookup_key: 'professional_month_v1',
  period: MONTH,
  title: 'Monthly',
  plan: PROFESSIONAL,
  price: 12,
  billedPeriod: 'Billed monthly',
  total: 'Total $60 / month',
  discount: 0,
  isPopular: true,
  isEnterprise: false,
  name: 'Professional',
  subTitle: 'For companies using Oboard to connect several teams.',
  priceWithSign: '$12',
  description: 'per user',
  description2: 'per month',
  features: ['All in essential', 'Weighting', 'Additional levels', 'Additional custom fields']
}

export const PROFESSIONAL_ANNUALLY = {
  lookup_key: 'professional_year_v1',
  plan: PROFESSIONAL,
  period: YEAR,
  title: 'Annually',
  price: 10,
  total: 'Total $50 / month',
  billedPeriod: 'Billed annually',
  discount: 18,
  isAnnual: true,
  isPopular: true,
  isEnterprise: false,
  name: 'Professional',
  subTitle: 'For companies using Oboard to connect several teams.',
  priceWithSign: '$10',
  description: 'per user',
  description2: 'per month',
  features: ['All in essential', 'Weighting', 'Additional levels', 'Additional custom fields']
}

export const SUBSCRIPTION_PLANS = [
  {
    name: 'Essential',
    value: ESSENTIAL,
    cycles: [ESSENTIAL_MONTHLY, ESSENTIAL_ANNUALLY]
  },
  {
    name: 'Professional',
    value: PROFESSIONAL,
    cycles: [PROFESSIONAL_MONTHLY, PROFESSIONAL_ANNUALLY]
  }
]

const USDollar = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2
})

export const formatAmount = amount => USDollar.format(amount)

export const getAmount = (amount = 0) => {
  return formatAmount(amount / 100)
}

export const getDateFormatted = (date, format = dateFormat) => {
  return dayjs(date * 1000).format(format)
}

export const SUBSCRIPTION_TIERS = [
  {
    period: MONTH,
    tiers: [
      ESSENTIAL_MONTHLY,
      PROFESSIONAL_MONTHLY,
      {
        isPopular: false,
        isEnterprise: true,
        name: 'Enterprise',
        subTitle: 'Advanced controls & support to run your entire organization.',
        priceWithSign: 'Let’s talk',
        subTitle2: 'Contact us to discuss',
        features: [
          'All in professional',
          'Custom rights',
          'Priority support',
          'Consulting discount'
        ]
      }
    ]
  },
  {
    period: YEAR,
    tiers: [
      ESSENTIAL_ANNUALLY,
      PROFESSIONAL_ANNUALLY,
      {
        isPopular: false,
        isEnterprise: true,
        name: 'Enterprise',
        subTitle: 'Advanced controls & support to run your entire organization.',
        priceWithSign: 'Let’s talk',
        subTitle2: 'Contact us to discuss',
        features: [
          'All in professional',
          'Custom rights',
          'Priority support',
          'Consulting discount'
        ]
      }
    ]
  }
]
//
// export const CVC_CHECK_STATUS = {
//   PASS: 'pass',
//   FAIL: 'fail',
//   UNCHECKED: 'unchecked',
//   UNAVAILABLE: 'unavailable'
// }
export const useManagePlanDialog = ({ subscription, licenseStatusId }) => {
  const managePlanPayload = ref({})
  const isShowManagePlan = ref(false)

  const onSubscribe = payload => {
    isShowManagePlan.value = true
    managePlanPayload.value = payload

    tracker.logEvent('subscription window shown', {
      category: EVENT_CATEGORIES.BILLING,
      plan: payload.name.toLowerCase(),
      subscription_cycle: payload.title.toLowerCase(),
      number_of_users: subscription.maxUsersCount || subscription.usersCount,
      current_license: licenseStatusId
    })
  }

  const isShowBillingContactDialog = ref(false)

  const onContactUs = () => {
    isShowBillingContactDialog.value = true
  }

  return {
    onSubscribe,
    onContactUs,
    isShowBillingContactDialog,
    isShowManagePlan,
    managePlanPayload
  }
}

const ANNUALLY = 'annually'
const MONTHLY = 'monthly'

export const getPeriodName = period => (period === YEAR ? ANNUALLY : MONTHLY)
