<template>
  <div class="asm-AggregatedNotificationWrapper">
    <div v-if="!isOpen" class="asm-AggregatedAvatars">
      <SelectedUsersAvatarsList :avatar-size="AVATAR_SIZES.XS" :users="aggregatedItemsAvatars" />
    </div>

    <span v-if="aggregatedItemsCount" class="asm-AggregatedLink" @click="toggle">
      {{
        isOpen
          ? $t('in_app_notifications.notification.show_less')
          : $t('in_app_notifications.notification.updates.title', {
              qty: aggregatedItemsCount,
              userName: getFirstAggregatedAuthorName
            })
      }}
    </span>
  </div>
</template>

<script setup>
import { uniqBy } from 'lodash'
import { computed } from 'vue'

import { AVATAR_SIZES } from '@/utils/components-configurations/app-avatar'
import { NOTIFICATION_ATTRIBUTES } from '@/utils/in-app-notifications'
import { getFirstName } from '@/utils/users-helpers'

import SelectedUsersAvatarsList from '@/components/form/SelectedUsersAvatarsList'

defineOptions({
  name: 'AggregatedShowMore'
})

const props = defineProps({
  aggregatedItems: {
    type: Array,
    required: true
  },

  isOpen: {
    type: Boolean,
    required: true
  }
})

const { AUTHOR_NAME, AUTHOR_AVATAR } = NOTIFICATION_ATTRIBUTES

const aggregatedItemsAvatars = computed(() => {
  if (aggregatedItemsCount.value) {
    return uniqBy(
      props.aggregatedItems.map(item => ({
        accountId: item.id,
        displayName: item.normalizedAttributes[AUTHOR_NAME],
        avatarUrl: item.normalizedAttributes[AUTHOR_AVATAR]
      })),
      'avatar'
    )
  } else {
    return []
  }
})
const aggregatedItemsCount = computed(() => props.aggregatedItems.length)

const getFirstAggregatedAuthorName = computed(() => {
  if (aggregatedItemsCount.value) {
    return getFirstName(props.aggregatedItems[0].normalizedAttributes[AUTHOR_NAME])
  } else {
    return ''
  }
})

const emit = defineEmits(['toggle'])
const toggle = () => emit('toggle')
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/_notification-center.scss';

.asm-AggregatedLink {
  cursor: pointer;
  color: $primary-color-next;
  font-size: $fs-14;
  line-height: 20px;

  &:hover {
    text-decoration: underline;
  }
}
.asm-AggregatedNotificationWrapper {
  display: flex;
  width: 100%;
  max-width: $notification-max-width;
  margin-left: auto;
}
.asm-AggregatedAvatars {
  display: flex;
  align-items: center;
  margin-right: 8px;
}
</style>
