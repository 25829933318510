import WorkspacesApiHandler from '@/api/workspaces'
import {
  ADD_WORKSPACE,
  SET_WORKSPACE_ID,
  SET_WORKSPACES,
  SET_WORKSPACES_ARE_LOADED,
  UPDATE_WORKSPACE
} from '@/store/mutation-types'

const updateWorkspaceIdAvailable = workspaceId => {
  return state.workspaces.some(ws => ws.id === workspaceId)
}

const getFirstWorkspaceId = () => {
  const [firstWorkspace] = state.workspaces
  return firstWorkspace?.id || null
}

const state = {
  workspaceId: null,
  workspaces: [],
  workspacesAreLoaded: false
}

const getters = {
  selectedWorkspace: state =>
    state.workspaces.find(workspace => workspace.id === state.workspaceId),
  getWorkspaceById: state => workspaceId =>
    state.workspaces.find(workspace => workspace.id === workspaceId)
}

const mutations = {
  [SET_WORKSPACE_ID](state, value) {
    state.workspaceId = value

    const selectedWorkspaceIndex = state.workspaces.findIndex(workspace => workspace.id === value)
    if (selectedWorkspaceIndex !== -1) {
      const [selectedWorkspace] = state.workspaces.splice(selectedWorkspaceIndex, 1)
      state.workspaces.unshift(selectedWorkspace)
    }
  },
  [SET_WORKSPACES](state, value) {
    state.workspaces = value
  },

  [SET_WORKSPACES_ARE_LOADED](state, value) {
    state.workspacesAreLoaded = value
  },

  [ADD_WORKSPACE](state, workspace) {
    state.workspaces.push(workspace)
  },

  [UPDATE_WORKSPACE](state, workspace) {
    const workspaceIndex = state.workspaces.findIndex(ws => ws.id === workspace.id)
    state.workspaces[workspaceIndex] = workspace
  }
}

const actions = {
  setWorkspaceId({ commit }, value) {
    if (updateWorkspaceIdAvailable(value)) {
      commit(SET_WORKSPACE_ID, value)
    } else {
      commit(SET_WORKSPACE_ID, getFirstWorkspaceId())
    }
  },

  setWorkspaces({ commit }, value) {
    commit(SET_WORKSPACES, value)
  },

  setWorkspacesAreLoaded({ commit }, value) {
    commit(SET_WORKSPACES_ARE_LOADED, value)
  },

  // eslint-disable-next-line consistent-return
  async fetchWorkspaces({ commit, dispatch, state }, { setWorkspaceId = null } = {}) {
    const api = new WorkspacesApiHandler()
    try {
      const workspaces = await api.getAllUserWorkspaces()
      commit(SET_WORKSPACES, workspaces)

      let workspaceId = setWorkspaceId || state.workspaceId

      dispatch('setWorkspaceId', workspaceId)
    } catch (error) {
      return error
    } finally {
      commit(SET_WORKSPACES_ARE_LOADED, true)
    }
  },

  addAndSelectWorkspace({ commit }, workspace) {
    commit(ADD_WORKSPACE, workspace)
    commit(SET_WORKSPACE_ID, workspace.id)
  },

  updateWorkspace({ commit }, workspace) {
    commit(UPDATE_WORKSPACE, workspace)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
