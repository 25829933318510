<template>
  <NodeViewWrapper>
    <div :style="{ width: props.node.attrs.width }" data-type="image-block">
      <div contenteditable="false">
        <img
          :data-uid="props.node.attrs['data-uid']"
          :src="imgSrc"
          alt=""
          class="ibv-Image"
          @click="onClick"
          @error="onImageError"
        />
      </div>
    </div>
  </NodeViewWrapper>
</template>

<script setup>
import { nodeViewProps, NodeViewWrapper } from '@tiptap/vue-3'
import { ref } from 'vue'

const props = defineProps(nodeViewProps)

// const wrapperClassName = computed(() => {
//   // const align = props.node.attrs.align
//   // TODO: for future feature aligning img
//   // return [
//   //   align === 'left' ? 'ml-0' : 'ml-auto',
//   //   align === 'right' ? 'mr-0' : 'mr-auto',
//   //   align === 'center' && 'mx-auto'
//   // ]
//   return []
// })

const onClick = () => {
  if (props.editor && props.getPos) {
    props.editor.commands.setNodeSelection(props.getPos())
  }
}

const imgSrc = ref(props.node.attrs.src)

const FALLBACK_IMG = 'https://s3-oboard-public-static.s3.eu-central-1.amazonaws.com/img/IMG.png'
const onImageError = () => {
  imgSrc.value = FALLBACK_IMG
}
</script>

<style lang="scss" scoped>
.ibv-Image {
  max-height: 300px;
  max-width: 100%;
  object-fit: cover;
}
</style>
