// eslint-disable-next-line import/prefer-default-export
export const removeSelection = () => {
  if (window.getSelection) {
    if (window.getSelection().empty) {
      // Chrome
      window.getSelection().empty()
    } else if (window.getSelection().removeAllRanges) {
      // Firefox
      window.getSelection().removeAllRanges()
    }
  } else if (document.selection) {
    // IE?
    document.selection.empty()
  }
}

/**
 * get the first scroll parent of an element
 * @param  {DOM} elm    cache element for recursive search
 * @return {DOM}        the first scroll parent
 */
export const getScrollParent = elm => {
  let result

  if (elm.tagName === 'BODY') {
    result = window
  } else if (['scroll', 'auto'].indexOf(getComputedStyle(elm).overflowY) > -1) {
    result = elm
  }

  return result || getScrollParent(elm.parentNode)
}

export const isVisibleInScrollableParent = element => {
  const parent = getScrollParent(element)
  const topIsVisible =
    element.offsetTop > parent.scrollTop &&
    element.offsetTop < parent.scrollTop + parent.offsetHeight
  const bottomIsVisible =
    element.offsetTop + element.offsetHeight > parent.scrollTop &&
    element.offsetTop + element.offsetHeight < parent.scrollTop + parent.offsetHeight
  return topIsVisible && bottomIsVisible
}

/**
 * stashing of old window scroll Y position and scroll window to it
 *  cause .scrollIntoView() method can't scroll only by one axes
 * @param {HTMLElement} element element what we want to scroll
 * @param {object} options options for native scrollIntoView function
 * @returns void
 */
export const scrollIntoViewOnlyHorizontally = (element, options) => {
  const prevWindowScrollYPosition = window.scrollY
  element.scrollIntoView(options)
  window.scrollTo({
    top: prevWindowScrollYPosition
  })
}
