<template>
  <div :class="{ 'mfa-Footer-without-checkbox': hideCheckbox }" class="mfa-Footer">
    <AppCheckbox
      v-if="!hideCheckbox"
      :data-auto-testid="OKR_ELEMENT_MODAL_FOOTER_CHECKBOX_TEST_ID"
      :data-testid="OKR_ELEMENT_MODAL_FOOTER_CHECKBOX_TEST_ID"
      :model-value="modelValue"
      class="mfa-Footer_Checkbox"
      @update:model-value="$emit('update:model-value', $event)"
    >
      <slot name="checkbox-label" />
    </AppCheckbox>

    <AppButton
      :data-auto-testid="OKR_ELEMENT_MODAL_FOOTER_CANCEL_BUTTON_TEST_ID"
      :data-testid="OKR_ELEMENT_MODAL_FOOTER_CANCEL_BUTTON_TEST_ID"
      type="ghost-next"
      @click="$emit('close', $event)"
    >
      <slot name="cancel-button-text">
        {{ $t('action.cancel') }}
      </slot>
    </AppButton>

    <slot name="confirm-button">
      <AppButton
        v-if="showConfirmButton"
        :data-auto-testid="OKR_ELEMENT_MODAL_FOOTER_CONFIRM_BUTTON_TEST_ID"
        :data-testid="OKR_ELEMENT_MODAL_FOOTER_CONFIRM_BUTTON_TEST_ID"
        :disable="disabled"
        :loading="loading"
        type="primary-next"
        @click="onCreateClick"
      >
        <slot name="confirm-button-text" />
      </AppButton>
    </slot>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ModalFooterActions'
})
</script>

<script setup>
import {
  OKR_ELEMENT_MODAL_FOOTER_CANCEL_BUTTON_TEST_ID,
  OKR_ELEMENT_MODAL_FOOTER_CHECKBOX_TEST_ID,
  OKR_ELEMENT_MODAL_FOOTER_CONFIRM_BUTTON_TEST_ID
} from '@/components/objectives/forms/jest-helpers'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppCheckbox from '@/components/ui/AppCheckbox/AppCheckbox'

const emit = defineEmits(['update:model-value', 'close', 'create'])

const props = defineProps({
  modelValue: {
    type: Boolean
  },

  loading: {
    type: Boolean
  },

  disabled: {
    type: Boolean
  },

  showConfirmButton: {
    type: Boolean,
    default: true
  },

  hideCheckbox: {
    type: Boolean
  }
})

const onCreateClick = () => {
  if (!props.disabled) {
    emit('create')
  }
}
</script>

<style lang="scss" scoped>
.mfa-Footer {
  flex: 1;
  gap: 8px;
  display: flex;
  align-items: center;

  padding: var(--padding, 0);

  &:not(&-without-checkbox) {
    justify-content: space-between;
  }

  &-without-checkbox {
    justify-content: flex-end;
  }
}

.mfa-Footer_Checkbox {
  margin-right: auto;
}
</style>
