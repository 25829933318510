<template>
  <AppButton
    v-if="withLocalModalTrigger"
    v-tippy="{
      content: $t('menu.support'),
      placement: 'right'
    }"
    class="sm-Trigger"
    height="24"
    icon="menu-support"
    remove-padding
    size="sm"
    type="ghost-next"
    width="24"
    @click="onOpenModal"
  />

  <portal to="modal-windows">
    <Modal
      :scrollable-content="false"
      :show="resolvedShow"
      :size="600"
      :title="$t('menu.support')"
      class="sm-Modal"
      manual-close
      sticky-header
      @close="onClose"
    >
      <template v-if="isPluginAdmin" #before-close>
        <AppButton
          size="sm"
          type="subtle"
          @click="goToDebug"
          @mouseleave="changePlayState(false)"
          @mouseover="changePlayState(true)"
        >
          <AppIcon v-if="isRiveShutDown" height="24" icon-name="bug" width="24" />
          <canvas v-else ref="canvas" class="sm-Modal_Bug" height="24" width="24"></canvas>
        </AppButton>
      </template>

      <SupportModalContent />
    </Modal>
  </portal>
</template>

<script setup>
import { Rive } from '@rive-app/canvas'
import { computed, nextTick, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

import { ROUTE_NAMES } from '@/routes/route-helpers'
import { PLUGIN_OPTIONS_KEYS } from '@root/template-options-keys'

import SupportModalContent from '@/components/support/SupportModalContent'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppIcon from '@/components/ui/AppIcon/AppIcon'
import Modal from '@/components/ui/Modal/Modal'

defineOptions({
  name: 'SupportModal'
})

const props = defineProps({
  show: {
    type: Boolean
  },

  withLocalModalTrigger: {
    type: Boolean
  }
})

const emit = defineEmits({
  'update:show': null
})

const onUpdateShow = (value = false) => {
  emit('update:show', value)
}

const onClose = () => {
  if (props.withLocalModalTrigger) {
    localShow.value = false
  } else {
    onUpdateShow()
  }
}

const localShow = ref(false)

const resolvedShow = computed(() => {
  return props.withLocalModalTrigger ? localShow.value : props.show
})

const onOpenModal = () => {
  if (props.withLocalModalTrigger) {
    localShow.value = true
  } else {
    onUpdateShow(true)
  }
}

const store = useStore()

const isPluginAdmin = computed(() => store.state.pluginOptions[PLUGIN_OPTIONS_KEYS.IS_PLUGIN_ADMIN])

const router = useRouter()

const goToDebug = () => {
  onClose()
  router.push({ name: ROUTE_NAMES.DEBUG })
}

const changePlayState = state => {
  if (isRiveShutDown.value) return
  if (state) {
    riveInstance.play()
  } else {
    riveInstance.reset()
  }
}

const isRiveShutDown = ref(false)

const canvas = ref(null)

watch(
  resolvedShow,
  async newValue => {
    if (newValue) {
      // nextTick and setTimeout(both!) are needed to make input always focused on modal window open
      await nextTick()
      setTimeout(() => {
        initRive()
      }, 200)
    }
  },
  {
    immediate: true
  }
)

let riveInstance = null

const initRive = () => {
  if (canvas.value) {
    riveInstance = new Rive({
      canvas: canvas.value,
      src: 'https://s3-oboard-public-static.s3.eu-central-1.amazonaws.com/img/debug.riv',
      autoplay: false,
      onLoadError: () => {
        isRiveShutDown.value = true
      },
      onloaderror: () => {
        isRiveShutDown.value = true
      },
      onLoad: () => {
        riveInstance.resizeDrawingSurfaceToCanvas()
      }
    })
  } else {
    isRiveShutDown.value = true
  }
}
</script>

<style lang="scss" scoped>
.sm-Modal {
  --modal-header-padding: 14px #{$page-right-padding} 0 #{$page-left-padding};
  --modal-title-margin: 0 0 18px 0;
  --modal-body-padding: 0 #{$page-right-padding} #{$page-bottom-padding} #{$page-left-padding};
}

.sm-Trigger {
  position: relative;
  width: 100%;
  justify-content: flex-start;
  min-height: 40px;
  padding: 8px;
  gap: 8px;
  border-radius: $border-radius-md-next;

  color: $dark-2;

  &:hover {
    color: $primary-color-next;
  }
}

.sm-Modal_Bug {
  max-width: 24px;
  max-height: 24px;
}
</style>
