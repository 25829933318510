import { OKR_VIEW_PAGES_IDS } from '@/utils/objectives'

const COMMON_ROUTE_PATH = '/workspaces/:workspaceId/objectives'

export const getNavigationTabName = route => {
  const isRouteMatch = path => {
    return route.matched.findIndex(item => item.path === path) !== -1
  }

  const [tabName] = [
    isRouteMatch(`${COMMON_ROUTE_PATH}/alignment/table`) && OKR_VIEW_PAGES_IDS.ALIGNMENT,
    isRouteMatch(`${COMMON_ROUTE_PATH}/okr-explorer`) && OKR_VIEW_PAGES_IDS.OKREXPLORER,
    isRouteMatch(`${COMMON_ROUTE_PATH}/alignment/tree`) && OKR_VIEW_PAGES_IDS.MINDMAP,
    isRouteMatch(`${COMMON_ROUTE_PATH}/alignment/roadmap`) && OKR_VIEW_PAGES_IDS.ROADMAP
  ].filter(Boolean)

  return tabName || ''
}
