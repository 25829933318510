<template>
  <AppButton type="ghost" icon="cross-circle-next" size="sm" @click="$emit('click', $event)" />
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ClearButton'
})
</script>

<script setup>
import AppButton from '@/components/ui/AppButton/AppButton'

defineEmits({ click: null })
</script>

<style scoped></style>
