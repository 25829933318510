<template>
  <FormFieldNext
    v-for="field in fieldsForBulkUpdate"
    :key="field[CUSTOM_FIELD_ENTITY_KEYS.ID]"
    :label="`${field[CUSTOM_FIELD_ENTITY_KEYS.NAME]} (${t('common.custom')})`"
    class="cufh-FormField"
  >
    <Component
      :is="FIELD_COMPONENTS_BY_TYPE_ID[field[CUSTOM_FIELD_ENTITY_KEYS.TYPE_ID]]"
      :field-id="field[CUSTOM_FIELD_ENTITY_KEYS.ID]"
      :field-type-id="field[CUSTOM_FIELD_ENTITY_KEYS.TYPE_ID]"
      :model-value="modelValue[field[CUSTOM_FIELD_ENTITY_KEYS.ID]]"
      :workspace-id="workspaceId"
      @update:model-value="
        onUpdateModelValue({
          ...$event,
          fieldId: field[CUSTOM_FIELD_ENTITY_KEYS.ID]
        })
      "
      @toggle-value-dropdown="
        onToggleValueDropdown({
          value: $event,
          fieldId: field[CUSTOM_FIELD_ENTITY_KEYS.ID]
        })
      "
    />
  </FormFieldNext>
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

import { ALL_CUSTOM_FIELDS } from '@/utils/custom-fields/factory'
import { CUSTOM_FIELD_ENTITY_KEYS } from '@/utils/entity-keys'

import FormFieldNext from '@/components/form/FormFieldNext'
import AssigneeField from '@/components/objectives/bulk-edit/custom-fields/AssigneeField'
import DateField from '@/components/objectives/bulk-edit/custom-fields/DateField'
import MoneyField from '@/components/objectives/bulk-edit/custom-fields/MoneyField'
import MultiSelectField from '@/components/objectives/bulk-edit/custom-fields/MultiSelectField'
import NumberField from '@/components/objectives/bulk-edit/custom-fields/NumberField'
import SingleSelectField from '@/components/objectives/bulk-edit/custom-fields/SingleSelectField'

defineOptions({
  name: 'BulkEditCustomFieldsHub',
  inheritAttrs: false
})

const props = defineProps({
  fields: {
    type: Array,
    required: true
  },

  modelValue: {
    type: Object,
    required: true
  },

  workspaceId: {
    type: [String, Number],
    required: true
  }
})

const { t } = useI18n()

const FIELD_COMPONENTS_BY_TYPE_ID = {
  [ALL_CUSTOM_FIELDS.getTypeIds().MULTI_SELECT]: MultiSelectField,
  [ALL_CUSTOM_FIELDS.getTypeIds().SINGLE_SELECT]: SingleSelectField,
  [ALL_CUSTOM_FIELDS.getTypeIds().DATE]: DateField,
  [ALL_CUSTOM_FIELDS.getTypeIds().ASSIGNEE]: AssigneeField,
  [ALL_CUSTOM_FIELDS.getTypeIds().NUMBER]: NumberField,
  [ALL_CUSTOM_FIELDS.getTypeIds().MONEY]: MoneyField
}

const emit = defineEmits({
  'update:model-value': null,
  'toggle-value-dropdown': null
})

const onUpdateModelValue = data => {
  emit('update:model-value', data)
}

const onToggleValueDropdown = data => {
  emit('toggle-value-dropdown', data)
}

const store = useStore()

const fieldsForBulkUpdate = computed(() => {
  return store.getters['customFields/fieldsForBulkUpdate']({
    fieldIds: props.fields.map(field => field.fieldId)
  })
})
</script>

<style lang="scss" scoped>
.cufh-FormField {
  position: relative;
}
</style>
