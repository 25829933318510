<template>
  <div :class="{ [`lc-Wrapper-${chartType}`]: true }" class="lc-Wrapper">
    <div
      v-if="noItemsSelected"
      :style="{ height: `${chartCanvasHeight}px` }"
      class="lc-Wrapper_Plug"
    >
      <slot>
        {{ $t('objective.chart.no_items_selected') }}
      </slot>
    </div>
    <canvas v-show="!noItemsSelected" ref="chart" />
  </div>
</template>

<script>
import {
  Chart,
  LineController,
  LinearScale,
  Filler,
  LineElement,
  PointElement,
  CategoryScale,
  TimeScale,
  Tooltip
} from 'chart.js'
import { isEmpty, isNull } from 'lodash'
import { defineComponent } from 'vue'

import 'chartjs-adapter-dayjs-3'

import { memoizeGetCssVariableValue } from '@/utils/memoizations'
import {
  CHART_TYPES,
  LINEAR_LABEL,
  prepareChart,
  THRESHOLD_LABEL,
  TIME_INTERVALS_COUNT,
  TRANSPARENT_COLOR
} from '@/utils/objective-chart'
import { stringOrNullProp } from '@/utils/prop-validators'
import { THRESHOLDS_DEFAULT_VALUES } from '@/utils/thresholds'

Chart.register(
  LineController,
  LinearScale,
  Filler,
  LineElement,
  PointElement,
  CategoryScale,
  TimeScale,
  Tooltip
)
const hexToRgba = (hex, alpha = 1) => {
  const r = parseInt(hex.slice(1, 3), 16)
  const g = parseInt(hex.slice(3, 5), 16)
  const b = parseInt(hex.slice(5, 7), 16)
  return `rgb(${r}, ${g}, ${b}, ${alpha})`
}

const createChartGradients = (context, datasetColor) => {
  const chart = context.chart
  const { ctx, chartArea } = chart

  if (!chartArea) {
    // This case happens on initial chart load
    return
  }
  let width, height, gradientStroke, gradientFill
  const chartWidth = chartArea.right - chartArea.left
  const chartHeight = chartArea.bottom - chartArea.top
  if (!gradientStroke || !gradientFill || width !== chartWidth || height !== chartHeight) {
    // Create the gradient because this is either the first render
    // or the size of the chart has changed
    width = chartWidth
    height = chartHeight

    gradientStroke = ctx.createLinearGradient(chartArea.width, 0, chartArea.width / 4, 0)
    gradientStroke.addColorStop(0, datasetColor)
    gradientStroke.addColorStop(1, 'rgba(66, 82, 110, 1)')

    gradientFill = ctx.createLinearGradient(0, 0, 0, chartArea.height)
    gradientFill.addColorStop(0, hexToRgba(datasetColor, 0.8))
    gradientFill.addColorStop(1, 'rgba(255, 255, 255, 0)')
  }
  return { gradientStroke, gradientFill }
}

export default defineComponent({
  name: 'LineChart',

  props: {
    chartData: {
      type: Object,
      required: true
    },

    tableConfiguredChartLines: {
      type: Array,
      default: () => []
    },

    chartType: {
      type: String,
      default: CHART_TYPES.simple,
      validator: v => Object.values(CHART_TYPES).includes(v)
    },

    splitEnabled: {
      type: Boolean
    },

    fullSizeChart: {
      type: Boolean
    },

    aspectRatio: {
      type: Number,
      default: 2.4
    },

    setYFrom0To100: {
      type: Boolean
    },

    pointBackgroundColor: {
      type: String,
      default: null
    },

    pointHoverBackgroundColor: {
      type: String,
      default: null
    },

    timeIntervalsCount: {
      type: Number,
      default: TIME_INTERVALS_COUNT
    },

    isLinear: {
      type: Boolean
    },

    dateFormat: {
      type: String,
      default: 'DD/MMM'
    },

    granulation: {
      type: Number,
      default: null
    },

    withoutBackground: {
      type: Boolean
    },

    borderColor: {
      type: String,
      default: null,
      validator: v => stringOrNullProp(v)
    },

    gradeValues: {
      type: Object,
      default: () => ({
        currentGrade: 0,
        predictedScore: 0,
        thresholdBehind: THRESHOLDS_DEFAULT_VALUES.BEHIND,
        thresholdOnTrack: THRESHOLDS_DEFAULT_VALUES.ON_TRACK
      })
    }
  },

  data() {
    return {
      chartCanvasHeight: 340,
      noItemsSelected: false
    }
  },

  computed: {
    isHomepagePreview() {
      return this.chartType === CHART_TYPES.homepagePreview
    },

    // not defined in data property coz Vue 3 cover it in Proxy and chart.js make same thing for reactivity
    // it causes a conflict with maximum call stack
    chartProperties() {
      const { chartData, tableConfiguredChartLines, gradeValues } = this
      const chartLines = isEmpty(tableConfiguredChartLines)
        ? chartData.chartLines
        : tableConfiguredChartLines
      const {
        chartType,
        splitEnabled,
        fullSizeChart,
        aspectRatio,
        setYFrom0To100,
        pointBackgroundColor,
        pointHoverBackgroundColor,
        timeIntervalsCount,
        isLinear,
        dateFormat
      } = this
      const { labels, datasets, options } = prepareChart({
        chartLines,
        chartType,
        splitEnabled,
        fullSizeChart,
        aspectRatio,
        setYFrom0To100,
        pointBackgroundColor,
        pointHoverBackgroundColor,
        timeIntervalsCount,
        isLinear,
        dateFormat,
        tableData: this.chartData.tableData,
        granulation: this.granulation,
        backgroundColor: this.borderColor,
        gradeValues
      })

      const newDatasets = datasets.map(dataset => {
        // TEMPORARY COMMENTED FUTURE IT WILL BE BACK
        if (
          this.splitEnabled ||
          dataset.label === LINEAR_LABEL ||
          dataset.label === THRESHOLD_LABEL
        ) {
          // if (this.splitEnabled) {
          return dataset
        } else {
          const whiteColor = memoizeGetCssVariableValue('--white-color', '#fff')
          return {
            ...dataset,
            borderColor: context => {
              if (this.chartType === CHART_TYPES.predictedScorePreview) {
                return dataset.borderColor || whiteColor
              }
              return (
                this.borderColor ||
                createChartGradients(context, dataset.borderColor)?.gradientStroke
              )
            },
            backgroundColor: context => {
              return this.withoutBackground
                ? TRANSPARENT_COLOR
                : createChartGradients(context, dataset.borderColor)?.gradientFill
            },
            pointBackgroundColor: pointBackgroundColor || whiteColor,
            pointHoverBackgroundColor: pointHoverBackgroundColor || whiteColor
          }
        }
      })

      return {
        labels,
        datasets: newDatasets,
        options,
        borderColor: this.borderColor
      }
    }
  },

  watch: {
    tableConfiguredChartLines: {
      handler(newValue) {
        this.noItemsSelected = isEmpty(newValue)
      },

      deep: true
    },

    chartProperties: {
      async handler(newValue) {
        const { labels, datasets, options } = newValue
        this.chart.data.labels = labels

        if (this.splitEnabled) {
          // the chart triggers new animations for every added dataset,
          // even if you replace it to the equal one.
          // that's why we can't just replace an old array with a new one.
          // instead we are going to carefully keep old ones,
          // and push new ones to the dataset
          const oldLabels = this.chart.data.datasets.map(d => d.id)
          const newLabels = datasets.map(d => d.id)

          const labelsOut = oldLabels.filter(label => !newLabels.includes(label))
          const labelsIn = newLabels.filter(label => !oldLabels.includes(label))

          labelsOut.forEach(label => {
            const index = this.chart.data.datasets.findIndex(dataset => dataset.id === label)
            this.chart.data.datasets.splice(index, 1)
          })

          labelsIn.forEach(label => {
            const dataset = datasets.find(dataset => dataset.id === label)
            this.chart.data.datasets.push(dataset)
          })

          // TEMPORARY COMMENTED FUTURE IT WILL BE BACK

          // if (!this.splitEnabled) {
          //   const oldLinear = this.chart.data.datasets.find((dataset) => dataset.label === 'Linear');
          //   const newLinear = datasets.find((dataset) => dataset.label === 'Linear');
          //
          //   oldLinear.data = newLinear.data;
          // }
        } else {
          this.chart.data.datasets = datasets
        }

        this.chart.options = options

        await this.$nextTick()

        this.chart.update()

        this.highlightLastPointOnChart()
      },

      deep: true
    }
  },

  mounted() {
    this.initChart()
  },

  beforeUnmount() {
    this.chart.destroy()
  },

  methods: {
    initChart() {
      const self = this

      let customPlugins = []
      if (this.isHomepagePreview) {
        customPlugins.push({
          id: 'myEventCatcher', // EVENT CATCHER CUSTOM PLUGIN
          beforeEvent(chart, args) {
            const event = args.event
            if (event.type === 'mouseout') {
              // process the event
              self.$nextTick(() => {
                self.highlightLastPointOnChart()
              })
            }
          }
        })
      }

      // not defined in data property coz Vue 3 cover it in Proxy and chart.js make same thing for reactivity
      // it causes a conflict with maximum call stack
      this.chart = new Chart(this.$refs.chart, {
        type: 'line',
        data: {
          labels: this.chartProperties.labels,
          datasets: this.chartProperties.datasets
        },

        options: this.chartProperties.options,

        plugins: customPlugins
      })

      this.highlightLastPointOnChart()

      this.chartCanvasHeight = this.$refs.chart.offsetHeight
    },

    highlightLastPointOnChart() {
      const { datasets } = this.chart.data
      if (!isEmpty(datasets) && this.isHomepagePreview) {
        const [firstDataset] = datasets
        const lastValidPointIndex = firstDataset.data.findLastIndex(point => !isNull(point.y))
        if (lastValidPointIndex >= 0) {
          // clear active elements
          // this is needed when we call chart.update() fn in watch handler
          // chart redrawing without active elements but still contains them in memory
          // as we have a new instance of chart but with old active point we don't see it on chart
          // so we need to clear active elements before we set new one
          this.chart.setActiveElements([])
          this.chart.setActiveElements([{ datasetIndex: 0, index: lastValidPointIndex }])
          this.chart.update()
        }
      }
    }

    // showTooltip() {
    //   const { tooltip } = this.chart
    //   if (tooltip) {
    //     if (tooltip.getActiveElements().length === 0) {
    //       tooltip.setActiveElements([
    //         {
    //           datasetIndex: 0,
    //           index: 13
    //         }
    //       ])
    //       this.chart.setActiveElements([{ datasetIndex: 0, index: 13 }])
    //       this.chart.update()
    //     }
    //     tooltip.setActiveElements([
    //       {
    //         datasetIndex: 0,
    //         index: 13
    //       }
    //     ])
    //   }
    // }
  }
})
</script>

<style lang="scss" scoped>
.lc-Wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  &-detailed {
    // max-width: 482px; // temporary commented while block 'Updates' not implemented
    max-width: 100%;
    padding: 24px 16px;
  }

  &-simple {
    max-width: 100%;
  }

  &-simpleWithAllAxis {
    max-width: 100%;
  }
}

.lc-Wrapper_Plug {
  text-align: center;
  display: flex;
  align-items: center;
  font-size: $fs-14;
  line-height: 16px;
  color: $placeholder-color;
}
</style>
