<template>
  <div class="sr-Rating">
    <template v-for="n in max" :key="n">
      <label
        :class="{
          'sr-Rating_Label-empty': !localValue
        }"
        :for="`rating-${n}`"
        class="sr-Rating_Label"
      >
        <AppIcon class="sr-Star" height="48" icon-name="rating-star" width="48" />
      </label>
      <input
        :id="`rating-${n}`"
        v-model="localValue"
        :name="name"
        :value="n"
        class="sr-Rating_Input"
        hidden
        type="radio"
      />
    </template>
  </div>
</template>

<script setup>
import { computed } from 'vue'

import { uid } from '@/utils/uid'

import AppIcon from '@/components/ui/AppIcon/AppIcon'

defineOptions({
  name: 'StarRating'
})

const props = defineProps({
  modelValue: {
    type: Number,
    required: true,
    default: 0,
    validator: value => value >= 0 && Number.isInteger(value) && Number.isFinite(value)
  },

  max: {
    type: Number,
    default: 5,
    validator: (value, props) => {
      if (props.value > value) {
        throw new Error('MAX VALUE should be greater than or equal to the value prop')
      }
      return (
        value > 2 && Number.isInteger(value) && Number.isFinite(value) && value >= props.modelValue
      )
    }
  }
})

const emit = defineEmits({
  'update:model-value': null
})

const localValue = computed({
  get() {
    return props.modelValue
  },
  set(v) {
    emit('update:model-value', v)
  }
})

const name = `rating-${uid()}`
</script>

<style lang="scss" scoped>
.sr-Rating {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

/*
██████████████████████████████████████████████████████████████████████████
██████████████████████████████████████████████████████████████████████████
██████████████████████████████████████████████████████████████████████████
██████████████████████████████████████████████████████████████████████████
██████████████████████████████████████████████████████████████████████████
████████████████ DO NOT MODIFY ORDER OF STYLES BELOW █████████████████████
██████████████████████████████████████████████████████████████████████████
██████████████████████████████████████████████████████████████████████████
██████████████████████████████████████████████████████████████████████████
██████████████████████████████████████████████████████████████████████████
 */
.sr-Rating_Input {
  &:checked ~ .sr-Rating_Label {
    color: $grey-2-next;
  }
}

.sr-Rating_Label {
  cursor: pointer;
  display: flex;
  transition: color $transition-fast cubic-bezier(1, 0.5, 0.8, 1);
  color: $grade-medium-color-next;

  &-empty {
    color: $grey-2-next;
  }

  .sr-Rating:hover & {
    color: $grade-medium-color-next;
  }
}

.sr-Rating_Input {
  &:hover ~ .sr-Rating_Label {
    color: $grey-2-next;
  }
}

/*
██████████████████████████████████████████████████████████████████████████
██████████████████████████████████████████████████████████████████████████
██████████████████████████████████████████████████████████████████████████
██████████████████████████████████████████████████████████████████████████
██████████████████████████████████████████████████████████████████████████
████████████████ DO NOT MODIFY ORDER OF STYLES BEFORE ████████████████████
██████████████████████████████████████████████████████████████████████████
██████████████████████████████████████████████████████████████████████████
██████████████████████████████████████████████████████████████████████████
██████████████████████████████████████████████████████████████████████████
 */
</style>
