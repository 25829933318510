import { DEFAULT_CUSTOM_FIELDS_VALUES_FOR_OKR_ELEMENT } from '@/utils/custom-fields/helpers'

const DATES_KEYS = {
  DUE_DATE: 'dueDate',
  ELEMENT_START_DATE: 'elementStartDate',
  AUTOMATIC_DUE_DATE: 'automaticDueDate',
  AUTOMATIC_ELEMENT_START_DATE: 'automaticElementStartDate'
}

export const OKR_ELEMENT_KEYS = {
  ID: 'id',
  TYPE_ID: 'typeId',
  INTERVAL_ID: 'intervalId',
  INTERVAL_START_DATE: 'intervalStartDate',
  INTERVAL_END_DATE: 'intervalEndDate',
  OBJECTIVE_ID: 'objectiveId',
  OWNER_ID: 'ownerId',
  CONTRIBUTE: 'contribute',
  DESCRIPTION: 'description',
  CHILD_COUNT: 'childCount',
  GROUP_ID: 'groupId',
  WORKSPACE_ID: 'workspaceId',
  PARENT_ID: 'parentId',
  NAME: 'name',
  GRADE: 'grade',
  GRADE_TO_USE: 'gradeToUse',
  GRADE_COLOR: 'gradeColor',
  ...DATES_KEYS,
  START_DATE_MANUAL: 'startDateManual',
  DUE_DATE_MANUAL: 'dueDateManual',
  PERMISSIONS: 'permissions',
  USERS: 'users',
  GROUPS: 'groups',
  LABELS: 'labels',
  LEVEL_ID: 'levelId',
  CONFIDENCE_LEVEL_ID: 'confidenceLevelId',
  AUTOMATIC_CONFIDENCE_LEVEL_ID: 'automaticConfidenceLevelId',
  UNIQUE_ID: 'uniqueId',
  EXIST_CONTRIBUTE_CHILDREN: 'existContributeChildren',
  PREDICTED_GRADE: 'predictedGrade',
  STATUS_INTERVALS: 'statusIntervals',
  TIME_PASSED_PERCENT: 'timePassedPercent',
  DISPLAY_ID: 'displayId',
  PRIVATE: 'private',
  LEVEL_NAME: 'levelName',
  LEVEL_PREFIX: 'levelPrefix',
  LEVEL_COLOR: 'levelColor',
  CUSTOM_FIELDS: 'customFields',
  CHILD_ELEMENTS: 'childElements'
}

const REQUIRED_KEYS_FOR_EDIT_OKR_ELEMENT = Object.values(OKR_ELEMENT_KEYS)

export const createEditOkrElementPayload = element => {
  return REQUIRED_KEYS_FOR_EDIT_OKR_ELEMENT.reduce((acc, key) => {
    let resolvedValue = element[key]

    if (key === OKR_ELEMENT_KEYS.TYPE_ID) {
      resolvedValue = element[key] || 0
    }

    if (key === OKR_ELEMENT_KEYS.OBJECTIVE_ID) {
      resolvedValue = element.id
    }

    if (key === OKR_ELEMENT_KEYS.CUSTOM_FIELDS) {
      resolvedValue = element[key] || DEFAULT_CUSTOM_FIELDS_VALUES_FOR_OKR_ELEMENT
    }

    if (Object.values(DATES_KEYS).includes(key)) {
      resolvedValue = element[key] ? new Date(element[key]) : null
    }

    return { ...acc, [key]: resolvedValue }
  }, {})
}
