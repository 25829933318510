<template>
  <AppDialog
    :closeable="false"
    :show="isShow"
    :size="600"
    :type="DIALOG_TYPES.INFO"
    hide-hero
    @on-close="onClose"
  >
    <div class="sud-Content">
      <AppIcon height="152px" icon-name="empty-state-hero-alternate" width="424px" />

      <AppTitle :level="2" class="sud-Title" disable-margin>
        {{ $t('subscription.subscription_wasnt_updated') }}
      </AppTitle>
    </div>
    <template #footer>
      <AppButton class="sud-Confirm" @click="onClose">{{ $t('action.close') }}</AppButton>
    </template>
  </AppDialog>
</template>

<script setup>
import { DIALOG_TYPES } from '@/utils/components-configurations/app-dialog'

import AppDialog from '@/components/AppDialog'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppIcon from '@/components/ui/AppIcon/AppIcon'
import AppTitle from '@/components/ui/AppTitle/AppTitle'

defineOptions({
  name: 'SubscriptionWasntUpdatedDialog'
})

defineProps({
  isShow: {
    type: Boolean
  }
})

const emit = defineEmits(['update:isShow'])

const onClose = () => {
  emit('update:isShow', false)
}
</script>

<style lang="scss" scoped>
.sud-Content {
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: center;
}

.sud-Confirm {
  margin: auto;
}

.sud-Title {
  font-size: $fs-32;
  line-height: 40px;
  white-space-collapse: break-spaces;
  text-align: center;
}
</style>
