<template>
  <AppTable
    :columns="COLUMNS"
    :data="dataMapped"
    :loading="loading"
    border-on-last-row
    no-border
    offset-left="var(--page-left-padding)"
    offset-right="var(--page-right-padding)"
    type="primary-next"
  >
    <template #cell="{ item, columnKey }">
      <div v-if="columnKey === TABLE_COLUMNS_KEYS.PRICE">
        <b class="ih-AmountCell oboard-truncated-text">{{ item.amount }}</b>
      </div>

      <template v-if="columnKey === TABLE_COLUMNS_KEYS.STATUS">
        <StatusBadge :data="item.status" no-border style="--padding: 4px 6px">
          {{ item.status.label }}
        </StatusBadge>
      </template>

      <template v-if="columnKey === TABLE_COLUMNS_KEYS.ACTIONS">
        <div class="ih-Cell_Actions">
          <AppButton v-if="item.invoiceLink" type="link-next" @click="openPdf(item.invoiceLink)">
            {{ $t('subscription.invoice') }}
          </AppButton>
          <AppDivider v-if="item.invoiceLink && item.receiptLink" vertical />
          <AppButton v-if="item.receiptLink" type="link-next" @click="openPdf(item.receiptLink)">
            {{ $t('subscription.receipt') }}
          </AppButton>
        </div>
      </template>
    </template>
  </AppTable>
  <AppLoadMoreButton v-if="isHasMore && !loading" @click="loadMore" />
</template>

<script setup>
defineOptions({
  name: 'InvoiceHistory'
})

import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import BillingApiHandler from '@/api/billing'
import { ALL_INVOICES_STATUSES, getAmount, getDateFormatted } from '@/utils/billing-settings'
import { handleError } from '@/utils/error-handling'
import { openLink } from '@/utils/external-link-helper'
import { TABLE_COLUMNS_KEYS } from '@/utils/table-columns'

import StatusBadge from '@/components/objectives/StatusBadge'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppDivider from '@/components/ui/AppDivider'
import AppLoadMoreButton from '@/components/ui/AppLoadMoreButton/AppLoadMoreButton'
import AppTable from '@/components/ui/Table/AppTable'

const { t } = useI18n()
const COLUMNS = [
  {
    title: t('subscription.invoice_history'),
    key: TABLE_COLUMNS_KEYS.DATE,
    width: 'auto'
  },
  {
    key: TABLE_COLUMNS_KEYS.PRICE,
    width: 113,
    align: 'right'
  },
  {
    key: TABLE_COLUMNS_KEYS.STATUS,
    width: 122
  },
  {
    key: TABLE_COLUMNS_KEYS.PLAN_NAME,
    width: 190
  },
  {
    key: TABLE_COLUMNS_KEYS.ACTIONS,
    width: 160
  }
]

const data = ref([])

const getAppliedBalance = amount => {
  return `(${getAmount(Math.abs(amount))})`
}

const dataMapped = computed(() =>
  data.value.map(item => ({
    ...item,
    date: getDateFormatted(item.date),
    status: ALL_INVOICES_STATUSES.find(status => status.value === item.status),
    amount: item.amount > 0 ? getAmount(item.amount) : getAppliedBalance(item.amount)
  }))
)
const LIMIT = 5

const loadMore = () => {
  startingAfter.value = data.value[data.value.length - 1].id
  fetchBillingInvoices(LIMIT)
}

const startingAfter = ref(null)
const loading = ref(false)
const isHasMore = ref(false)
const fetchBillingInvoices = async (limit = 3) => {
  const BillingApi = new BillingApiHandler()

  try {
    loading.value = true
    const { invoices, hasMore } = await BillingApi.getBillingInvoices({
      limit,
      startingAfter: startingAfter.value
    })
    data.value = [...data.value, ...invoices]
    isHasMore.value = hasMore
  } catch (error) {
    handleError({ error })
  } finally {
    loading.value = false
  }
}

onMounted(() => {
  fetchBillingInvoices()
})

const openPdf = link => {
  openLink({
    url: link
  })
}
</script>

<style lang="scss" scoped>
.ih-Cell_Actions {
  height: 24px;
  display: flex;
  gap: 8px;
  align-items: center;
}

.ih-AmountCell {
  display: inline-block;
  max-width: 140px;
  padding-right: 24px;
}
</style>
