<template>
  <div>
    <div ref="list" :class="{ 'otl-TreeRowList-root': !depth }" class="otl-TreeRowList">
      <div
        v-if="depth && filteredValue.krs.length"
        :id="krsWrapperId"
        :class="{
          'otl-TreeRowList_KrsWrapper': filteredValue.krs.length > 1 // add this class only if there are more than 1 child KR, its design request. Do not stretch KR lists BG if there is only 1 KR
        }"
      >
        <div
          v-for="(elementId, index) in filteredValue.krs"
          :key="elementId"
          :ref="setItemRef"
          class="otl-TreeRowList_Item"
        >
          <ObjectiveTreeRow
            :ref="data => setChildRef(elementId, data)"
            :close-id="closeId"
            :depth="depth"
            :expanded="
              listState.filtersValues[$options.FILTERS_KEYS.EXPANDED_ITEMS][`${elementId}-${depth}`]
            "
            :is-first="index === 0"
            :is-last="index === filteredValue.krs.length - 1"
            :objective="listState.okrElements[elementId]"
            @add="addElement(listState.okrElements[elementId], $event)"
            @delete="deleteElement(listState.okrElements[elementId])"
            @duplicate="duplicateElement(listState.okrElements[elementId])"
            @edit="editElement(listState.okrElements[elementId])"
            @move="moveElement(listState.okrElements[elementId])"
            @update="updateElements"
            @request-connection="$emit('request-connection')"
            @request-offset="offset => $emit('request-offset', offset)"
            @edit-weights="editElementWeights(listState.okrElements[elementId])"
            @update:expanded="onRowExpandedChange(elementId, depth, $event)"
          />
        </div>
      </div>

      <div
        v-for="(elementId, index) in filteredValue.objectives"
        :key="elementId"
        :ref="setItemRef"
        class="otl-TreeRowList_Item"
      >
        <ObjectiveTreeRow
          :ref="data => setChildRef(elementId, data)"
          :close-id="closeId"
          :depth="depth"
          :expanded="
            listState.filtersValues[$options.FILTERS_KEYS.EXPANDED_ITEMS][`${elementId}-${depth}`]
          "
          :is-first="index === 0"
          :is-last="index === filteredValue.objectives.length - 1"
          :objective="listState.okrElements[elementId]"
          @add="addElement(listState.okrElements[elementId], $event)"
          @delete="deleteElement(listState.okrElements[elementId])"
          @duplicate="duplicateElement(listState.okrElements[elementId])"
          @edit="editElement(listState.okrElements[elementId])"
          @move="moveElement(listState.okrElements[elementId])"
          @update="updateElements"
          @request-connection="$emit('request-connection')"
          @request-offset="offset => $emit('request-offset', offset)"
          @edit-weights="editElementWeights(listState.okrElements[elementId])"
          @update:expanded="onRowExpandedChange(elementId, depth, $event)"
        />
      </div>
    </div>

    <ObjectiveViewRowActions ref="actions" :depth="depth" source="tree" @update="updateElements" />
  </div>
</template>

<script>
import { defineComponent } from 'vue'

import { listStateInjectionKey, treeStateInjectionKey } from '@/utils/injection-keys'
import {
  filteredObjectivesToDisplay,
  getExpandedItemList,
  isKR,
  objectiveIsJiraTask
} from '@/utils/objectives'
import { FILTERS_KEYS, saveFilterValues } from '@/utils/okr-elements/filters'
import { createKrsWrapperId } from '@/utils/okr-map'

import ObjectiveViewRowActions from '@/components/objectives/ObjectiveViewRowActions'
import ObjectiveTreeRow from '@/components/objectives/tree/ObjectiveTreeRow'

export default defineComponent({
  name: 'ObjectiveTreeRowList',

  components: {
    ObjectiveTreeRow,
    ObjectiveViewRowActions
  },

  inject: {
    listState: {
      from: listStateInjectionKey
    },

    treeState: {
      from: treeStateInjectionKey
    }
  },

  props: {
    modelValue: {
      type: Array,
      required: true
    },

    depth: {
      type: Number,
      default: 0
    },

    // height (in px) of parent card.
    // used exclusively for animations
    // its value passed only when parent card `expand` button is clicked
    // otherwise its value is `0`
    parentCardHeight: {
      type: Number,
      default: 0
    },

    closeId: {
      type: Number,
      default: 0
    },

    // is this a direct child of close initiator
    isCloseRootList: {
      type: Boolean,
      default: false
    },

    parentId: {
      type: [String, Number],
      default: null
    },

    showFilteredChildren: {
      type: Boolean
    }
  },

  emits: {
    'request-connection': null,
    'request-offset': null,
    'ready-to-close': null,
    update: null
  },

  FILTERS_KEYS,

  data() {
    return {
      itemRefs: [],
      childRefs: {}
    }
  },

  computed: {
    krsWrapperId() {
      return createKrsWrapperId(this.parentId)
    },

    filteredValue() {
      const filteredElements = [...filteredObjectivesToDisplay(this.modelValue, this.listState)]

      if (this.depth) {
        const resolvedElements = this.showFilteredChildren
          ? [...this.modelValue]
          : [...filteredElements]

        return resolvedElements.reduce(
          (acc, val) => {
            const { krs, objectives } = acc
            const okrElement = this.listState.okrElements[val]

            if (isKR(okrElement) || objectiveIsJiraTask(okrElement)) {
              acc.krs = [...krs, val]
            } else {
              acc.objectives = [...objectives, val]
            }
            return acc
          },
          {
            krs: [],
            objectives: []
          }
        )
      }
      return {
        objectives: [...filteredElements]
      }
    }
  },

  watch: {
    closeId() {
      // close
      const listHeight = this.$refs.list.clientHeight
      const itemsHeight = this.itemRefs.map(item => item.clientHeight)
      const diff = listHeight - this.parentCardHeight
      // const { scale, translateX, translateY } = getTransform(this.treeState.canvas)

      this.$refs.list.style.height = `${listHeight}px`
      this.itemRefs.forEach((item, index) => {
        item.style.height = `${itemsHeight[index]}px`
      })

      requestAnimationFrame(() => {
        if (this.isCloseRootList && diff > 0) {
          this.treeState.canvas.classList.add('oob-Content-animate')
        }
        if (this.$refs.list) {
          this.$refs.list.classList.add('otl-TreeRowList-animate')
          this.itemRefs.forEach(item => {
            item.classList.add('otl-TreeRowList_Item-animate')
          })
        }

        requestAnimationFrame(() => {
          if (this.$refs.list) {
            this.$refs.list.classList.add('otl-TreeRowList-collapsed')
            this.itemRefs.forEach(item => {
              item.classList.add('otl-TreeRowList_Item-collapsed')
            })

            if (this.isCloseRootList && diff > 0) {
              // this.treeState.canvas.style.top = `${(diff * scale) / 2}px`
            }
            this.$refs.list.style.height = `${this.parentCardHeight}px`
            this.itemRefs.forEach(item => {
              item.style.height = ''
            })
          }
        })
      })

      this.$refs.list.addEventListener(
        'transitionend',
        () => {
          if (this.isCloseRootList && diff > 0) {
            this.treeState.canvas.classList.remove('oob-Content-animate')
            // this.treeState.canvas.style.top = ``
            // this.treeState.canvas.style.transform = `scale(${scale}) translate(${translateX}px, ${
            //   translateY + diff / 2
            // }px)`
          }
          this.$emit('ready-to-close')
        },
        { once: true }
      )
    }
  },

  beforeUpdate() {
    this.itemRefs = []
    this.childRefs = {}
  },

  mounted() {
    // parentCardHeight === 0 means, that this list component was rendered
    // not by `expand` button of the parent, but `as is` on initial render
    // no animations needed in this case
    if (this.depth === 0 || this.parentCardHeight === 0) {
      return
    }
    const listHeight = this.$refs.list.clientHeight
    const itemsHeight = this.itemRefs.map(item => item.clientHeight)
    const diff = listHeight - this.parentCardHeight
    this.$refs.list.style.height = `${this.parentCardHeight}px`
    // const { scale, translateX, translateY } = getTransform(this.treeState.canvas)

    this.$refs.list.classList.add('otl-TreeRowList-collapsed')
    this.itemRefs.forEach(item => {
      item.classList.add('otl-TreeRowList_Item-collapsed')
    })

    requestAnimationFrame(() => {
      if (diff > 0) {
        this.treeState.canvas.classList.add('oob-Content-animate')
      }
      this.$refs.list.classList.add('otl-TreeRowList-animate')
      this.itemRefs.forEach(item => {
        item.classList.add('otl-TreeRowList_Item-animate')
      })

      requestAnimationFrame(() => {
        this.$refs.list.classList.remove('otl-TreeRowList-collapsed')
        this.itemRefs.forEach(item => {
          item.classList.remove('otl-TreeRowList_Item-collapsed')
        })
        if (diff > 0) {
          // this.treeState.canvas.style.top = `${-((diff * scale) / 2)}px`
        }
        this.$refs.list.style.height = `${listHeight}px`
        this.itemRefs.forEach((item, index) => {
          item.style.height = `${itemsHeight[index]}px`
        })
      })
    })

    this.$refs.list.addEventListener(
      'transitionend',
      () => {
        if (diff > 0) {
          this.treeState.canvas.classList.remove('oob-Content-animate')
          //this.treeState.canvas.style.top = ''
          // this.treeState.canvas.style.transform = `scale(${scale}) translate(${translateX}px, ${
          //   translateY - diff / 2
          // }px)`
        }
        this.$refs.list.classList.remove('otl-TreeRowList-animate')
        this.$refs.list.style.height = ''
        this.itemRefs.forEach(item => {
          item.classList.remove('otl-TreeRowList_Item-animate')
          item.style.height = ''
        })
      },
      { once: true }
    )
  },

  methods: {
    moveElement(element) {
      this.$refs.actions.moveOkrElement(element)
    },

    setItemRef(el) {
      if (el) {
        this.itemRefs.push(el)
      }
    },

    setChildRef(elementId, component) {
      if (component) {
        this.childRefs[elementId] = component
      }
    },

    updateElements(eventData) {
      this.$emit('update', eventData)
    },

    editElement(element) {
      this.$refs.actions.editOkrElement(element)
    },

    deleteElement(element) {
      this.$refs.actions.deleteOkrElement(element)
    },

    duplicateElement(element) {
      this.$refs.actions.duplicateOkrElement(element)
    },

    editElementWeights(element) {
      this.$refs.actions.editOkrElementWeights(element)
    },

    addElement(parentElement, payload) {
      this.$refs.actions.createNewOkrElement(parentElement, payload)
    },

    onRowExpandedChange(elementId, depth, newValue) {
      if (newValue) {
        this.listState.filtersValues[FILTERS_KEYS.EXPANDED_ITEMS][`${elementId}-${depth}`] = true
      } else {
        const expandedItems = { ...this.listState.filtersValues[FILTERS_KEYS.EXPANDED_ITEMS] }
        expandedItems[`${elementId}-${depth}`] = false
        if (elementId in this.childRefs) {
          const expandedChildren = this.childRefs[elementId].getExpandedChildren()
          expandedChildren.forEach(child => {
            expandedItems[child] = false
          })
        }
        this.listState.filtersValues[FILTERS_KEYS.EXPANDED_ITEMS] = { ...expandedItems }
      }
      saveFilterValues(
        this.$router,
        this.$route,
        [FILTERS_KEYS.EXPANDED_ITEMS, FILTERS_KEYS.FILTER_COLLAPSED_ITEMS],
        [
          getExpandedItemList(this.listState.filtersValues[FILTERS_KEYS.EXPANDED_ITEMS]),
          getExpandedItemList(this.listState.filtersValues[FILTERS_KEYS.FILTER_COLLAPSED_ITEMS])
        ]
      )
    },

    // returns array of expanded children(their ids as in listState.filtersValues[FILTERS_KEYS.EXPANDED_ITEMS])
    /** @public */
    getExpandedChildren() {
      return Object.values(this.childRefs).reduce(
        (accumulator, currentValue) => accumulator.concat(currentValue.getExpandedChildren()),
        []
      )
    }
  }
})
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/okr-map';

.otl-TreeRowList {
  display: grid;
  grid-auto-rows: min-content;

  &:not(&-root) {
    gap: 20px;
  }

  &-root {
    gap: 30px;
    &:not(:empty) {
      margin-bottom: 30px;
    }
  }

  &-animate {
    transition: $transition-fast all linear;
  }

  &-collapsed {
    gap: 0;
  }

  &_Item {
    &-animate {
      transition: $transition-fast all linear;
    }

    &-collapsed {
      height: 0;
    }
  }
}

.otl-TreeRowList_KrsWrapper {
  position: relative;
  // background: $grey-3-next;
  // box-shadow: 0 0 0 2px black;

  &:before {
    pointer-events: none;
    z-index: -1;
    content: '';
    position: absolute;
    height: calc(100% - ((#{$tree-item-padding} + #{$tree-item-header-height}) * 2));
    width: $tree-item-width;
    top: calc(#{$tree-item-padding} + #{$tree-item-header-height});
    left: 0;
    background: $grey-3-next;
    border-radius: $border-radius-lg-next;
    // box-shadow: 0 0 1px rgba($dark-1, 0.31);
  }
}
</style>
