<template>
  <div class="ci-Page">
    <PageContentHeader
      :level="3"
      :max-width="HEADER_SIZES.LG"
      :title="$t('schedules.title')"
      no-margin
      style="--padding-bottom: 24px; --align-items: flex-start"
    >
      <AppButton
        v-if="!isEmptySchedules"
        v-tippy="{
          placement: 'top-start',
          content: isLimitExceeded ? t('schedule.reached_schedule_limit') : null,
          theme: 'translucent-next'
        }"
        :disable="isLimitExceeded"
        height="24"
        icon="plus-next"
        type="primary-next"
        width="24"
        @click="createSchedule"
      >
        {{ t('schedules.create') }}
      </AppButton>
    </PageContentHeader>
    <div class="ci-Page_Content">
      <AppTable
        v-if="!isEmptySchedules"
        :columns="tableColumns"
        :data="sortedData"
        :disable-user-select="false"
        :hover-row="tableHoverRow"
        :loading="loaders.table"
        border-on-last-row
        no-border
        offset-left="var(--page-left-padding)"
        offset-right="var(--page-right-padding)"
        type="primary-next"
      >
        <template #loading>
          <CheckinsTableLoader />
        </template>

        <template #header-cell="{ column }">
          <TableHeaderCellWithSorting
            v-model:sort-by="sortBy"
            :additional-string-sorting-columns="[SCHEDULE_NAME]"
            :column="column"
          >
            <span v-if="column.key === REMINDER_FULL_NAME" class="ci-HeaderReminderCell">
              {{ column.title }}
            </span>
          </TableHeaderCellWithSorting>
        </template>

        <template #cell="{ columnKey, item, index }">
          <template v-if="columnKey === SCHEDULE_NAME">
            <div class="ci-CheckinsCell_Name ci-CheckinsCell_Link" @click="editSchedule(item)">
              <AppIcon class="ci-CalendarIcon" height="24" icon-name="calendar-check" width="24" />
              <span class="oboard-truncated-text">
                {{ item[NAME] }}
              </span>
            </div>
          </template>

          <template v-if="columnKey === FREQUENCY_FULL_NAME">
            <div class="ci-CheckinsCell">
              <span class="oboard-truncated-text">
                {{ item[FREQUENCY_FULL_NAME] }}
              </span>
            </div>
          </template>

          <template v-if="columnKey === REMINDER_FULL_NAME">
            <div class="ci-CheckinsCell ci-CheckinsCell-reminder">
              <span class="oboard-truncated-text">
                {{ item[REMINDER_FULL_NAME] }}
              </span>
            </div>
          </template>

          <template v-if="columnKey === ACTION">
            <DropdownMenu
              :items="MENU_ITEM_ACTIONS"
              :offset="[0, 0]"
              position="bottom-end"
              type="default-next"
              @close="tableHoverRow = -1"
              @open="tableHoverRow = index"
              @item-click="onMenuActionsClick($event, item)"
            >
              <template #activator="{ isOpened }">
                <AppButton
                  :class="{ 'ci-ActionsButton-active': isOpened }"
                  class="ci-ActionsButton"
                  icon="more-next"
                  size="sm"
                  type="tertiary-next"
                />
              </template>
            </DropdownMenu>
          </template>
        </template>
        <template #footer>
          <AppInfoMessage
            v-if="isLimitExceeded"
            :type="INFO_MESSAGE_TYPES.WARNING"
            class="ci-InfoWarningText"
          >
            {{ t('schedule.reached_schedule_limit') }}
          </AppInfoMessage>
          <AppTableCreateButton v-else full-width icon-name="plus-next" @click="createSchedule">
            {{ t('schedules.create') }}
          </AppTableCreateButton>
        </template>
      </AppTable>

      <SettingsPagesEmptyState
        v-if="isEmptySchedules"
        hero-height="160"
        hero-icon="no-checkins-hero"
        hero-width="142"
      >
        <template #title> {{ $t('schedule.no_fields.title') }} </template>
        <template #subtitle> {{ $t('schedule.fields.subtitle') }} </template>

        <template #action>
          <AppButton
            height="24"
            icon="plus-next"
            type="primary-next"
            width="24"
            @click="createSchedule"
          >
            {{ $t('schedules.create') }}
          </AppButton>
        </template>
      </SettingsPagesEmptyState>
    </div>
  </div>

  <portal to="modal-windows">
    <ScheduleModal
      :opened="isShowScheduleModal"
      :schedule-id="scheduleIdForEdit"
      @on-close="closeScheduleModal"
      @reload-schedules="onReloadSchedules"
    />

    <ScheduleDeleteModal
      :schedule-for-delete="scheduleForDelete"
      :show="isShowDeleteScheduleModal"
      @close="onDeleteScheduleModalClose"
      @on-schedule-deleted="onScheduleDeleted"
    />
  </portal>
</template>

<script setup>
import { isEmpty } from 'lodash'
import { computed, nextTick, onMounted, ref, toValue } from 'vue'
import { useI18n } from 'vue-i18n'

import SchedulesApiHandler from '@/api/schedules'
import { ACTIONS_KEYS } from '@/utils/actions-keys'
import { useCheckinTableHelpers } from '@/utils/check-ins/schedule-composables'
import { SCHEDULE_LIMIT } from '@/utils/check-ins/schedules-helpers'
import { INFO_MESSAGE_TYPES } from '@/utils/components-configurations/app-info-message'
import { HEADER_SIZES } from '@/utils/components-configurations/page-content-header'
import { SCHEDULE_ENTITY_KEYS } from '@/utils/entity-keys'
import { handleError } from '@/utils/error-handling'
import { ascSortValue, descSortValue } from '@/utils/sort-options'
import { TABLE_COLUMNS_KEYS } from '@/utils/table-columns'

import ScheduleDeleteModal from '@/components/check-ins/ScheduleDeleteModal'
import ScheduleModal from '@/components/check-ins/ScheduleModal'
import TableHeaderCellWithSorting from '@/components/dashboard/TableHeaderCellWithSorting'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppIcon from '@/components/ui/AppIcon/AppIcon'
import AppInfoMessage from '@/components/ui/AppInfoMessage'
import AppTableCreateButton from '@/components/ui/AppTableCreateButton'
import DropdownMenu from '@/components/ui/DropdownMenu/DropdownMenu'
import PageContentHeader from '@/components/ui/PageContentHeader/PageContentHeader'
import SettingsPagesEmptyState from '@/components/ui/SettingsPagesEmptyState/SettingsPagesEmptyState'
import CheckinsTableLoader from '@/components/ui/SkeletonLoaders/CheckinsTableLoader'
import AppTable from '@/components/ui/Table/AppTable'

const { NAME } = SCHEDULE_ENTITY_KEYS

defineOptions({
  name: 'CheckInsLayout'
})
const { t } = useI18n()

const { SCHEDULE_NAME, FREQUENCY_FULL_NAME, ACTION, REMINDER_FULL_NAME } = TABLE_COLUMNS_KEYS

const tableHoverRow = ref(-1)
const schedulesData = ref([])

const isLimitExceeded = computed(() => schedulesData.value.length >= SCHEDULE_LIMIT)

const tableColumns = ref([
  {
    title: t('schedule.schedule_name'),
    key: SCHEDULE_NAME,
    width: 'auto',
    sortable: true
  },

  {
    title: t('schedule.frequency'),
    key: FREQUENCY_FULL_NAME,
    width: 220
  },
  {
    title: t('schedule.reminder'),
    key: REMINDER_FULL_NAME,
    width: 220,
    align: 'right'
  },
  {
    key: ACTION,
    width: 24
  }
])

// const DEFAULT_FILTERS_VALUES = {
//   [SEARCH_STRING]: ''
// }

// const filtersValues = ref({
//   ...cloneDeep(DEFAULT_FILTERS_VALUES)
// })

const loaders = ref({
  table: false
})

const fetchSchedules = async () => {
  try {
    loaders.value.table = true
    const api = new SchedulesApiHandler()
    // const result = await api.fetchSchedules({
    //   ...filtersValues.value
    //   // [FREQUENCIES_IDS]: getSelectWithSelectAllApiParameter(filtersValues.value[FREQUENCIES_IDS])
    // })

    const result = await api.fetchSchedules()

    setSchedulesData(result)
  } catch (error) {
    handleError({ error })
  } finally {
    loaders.value.table = false
  }
}

const setSchedulesData = data => {
  schedulesData.value = data.map(item => {
    const { frequencyFullName, reminderFullName } = useCheckinTableHelpers({
      schedule: item
    })

    return {
      ...item,
      [FREQUENCY_FULL_NAME]: frequencyFullName,
      [REMINDER_FULL_NAME]: reminderFullName
    }
  })
}

onMounted(async () => {
  await fetchSchedules()
})

const isEmptySchedules = computed(() => {
  return !loaders.value.table && isEmpty(schedulesData.value)
})

const isShowScheduleModal = ref(false)

const createSchedule = () => {
  isShowScheduleModal.value = true
}

const scheduleIdForEdit = ref(null)

const closeScheduleModal = () => {
  isShowScheduleModal.value = false
  scheduleIdForEdit.value = null
}

const editSchedule = ({ id }) => {
  scheduleIdForEdit.value = id
  isShowScheduleModal.value = true
}

const onReloadSchedules = () => {
  fetchSchedules()
}

const isShowDeleteScheduleModal = ref(false)
const scheduleForDelete = ref({})

const onDeleteScheduleModalClose = () => {
  isShowDeleteScheduleModal.value = false
  scheduleForDelete.value = {}
}

const onScheduleDeleted = () => {
  onReloadSchedules()
  onDeleteScheduleModalClose()
}

const onDeleteSchedule = async schedule => {
  scheduleForDelete.value = schedule
  await nextTick()
  isShowDeleteScheduleModal.value = true
}

const MENU_ITEM_ACTIONS = [
  {
    name: ACTIONS_KEYS.EDIT,
    title: 'action.edit',
    icon: 'edit-next'
  },
  {
    name: ACTIONS_KEYS.REMOVE,
    title: 'action.delete',
    icon: 'delete-next',
    color: 'var(--grade-low-color-next)'
  }
]

const onMenuActionsClick = (name, item) => {
  if (name === ACTIONS_KEYS.EDIT) {
    editSchedule(item)
  }
  if (name === ACTIONS_KEYS.REMOVE) {
    onDeleteSchedule(item)
  }
}

const sortBy = ref({
  key: SCHEDULE_NAME,
  value: null
})

const sortedData = computed(() => {
  if (isEmpty(schedulesData.value)) {
    return []
  }

  const { value } = toValue(sortBy)

  if (value && value === ascSortValue) {
    return schedulesData.value.toSorted((a, b) => a[SCHEDULE_NAME].localeCompare(b[SCHEDULE_NAME]))
  }

  if (value && value === descSortValue) {
    return schedulesData.value.toSorted((a, b) => b[SCHEDULE_NAME].localeCompare(a[SCHEDULE_NAME]))
  }

  return schedulesData.value
})
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/app-table-helpers';

.ci-Page_Content {
  width: 100%;
  max-width: $page-width-lg;
  padding: 0 $page-right-padding 0 $page-left-padding;
  gap: 24px;
}

.ci-CalendarIcon {
  display: flex;
  min-width: 24px;
  min-height: 24px;
}

.ci-CheckinsCell_Link {
  color: $primary-color-next;
  &:hover {
    text-decoration: underline;
  }
}

.ci-CheckinsCell_Name {
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: fw('semi-bold');
  cursor: pointer;
  padding-right: 8px;
}

.ci-InfoWarningText {
  margin-top: 10px;
  padding: 0 $page-right-padding 0 $page-left-padding;
}

.ci-ActionsButton {
  &-active {
    background-color: $dark-2;
    color: $white;
  }
  @extend %app-table-hidden-items;
}

.ci-HeaderReminderCell {
  padding-right: 12px;
}

.ci-CheckinsCell {
  padding-right: 8px;
  display: flex;

  &-reminder {
    padding-right: 12px;

    > * {
      width: 100%;
    }
  }
}
</style>
