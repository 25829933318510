<template>
  <AppIcon :icon-name="iconName" class="oet-Plug" height="24" width="24" />
</template>

<script setup>
import { PLUG_ICONS } from '@/utils/cell-helper'

import AppIcon from '@/components/ui/AppIcon/AppIcon'

defineOptions({
  name: 'OkrElementsTableCellPlug'
})

defineProps({
  iconName: {
    type: String,
    default: PLUG_ICONS.COMMON,
    validator: v => Object.values(PLUG_ICONS).includes(v)
  }
})
</script>

<style lang="scss" scoped>
.oet-Plug {
  color: $grey-1-next;
  @media (any-hover: hover) {
    &:hover {
      color: $primary-color-next;
    }
  }

  .csi-Item-active &,
  .csi-Plug-active &,
  .odt-Trigger-active & {
    color: inherit;
  }
}
</style>
