<template>
  <div class="otl-BacklogError">
    <ObjectivesEmptyHero class="otl-BacklogError_Hero" height="184" is-alternate width="424" />
    <div class="otl-BacklogError_Title">
      {{ $t('roadmap.backlog_error') }}
    </div>

    <div class="otl-BacklogError_List">
      <OkrElementsList
        :objectives="elements"
        :show-grade="false"
        hide-actions
        item-type="primary-next"
        no-link-id
        show-interval
        some-item-is-expandable
        transparent-filtered
        type="default-next"
      >
        <AppTitle :level="6" class="otl-BacklogError_Subtitle" disable-margin>
          {{ $t('roadmap.backlog_error.list.title') }}
        </AppTitle>
      </OkrElementsList>
    </div>
  </div>
</template>

<script setup>
import ObjectivesEmptyHero from '@/components/objectives/ObjectivesEmptyHero'
import OkrElementsList from '@/components/objectives/okr-elements-list/OkrElementsList'
import AppTitle from '@/components/ui/AppTitle/AppTitle'

defineOptions({
  name: 'OkrTimelinesBacklogError'
})

defineProps({
  elements: {
    type: Array,
    required: true
  }
})
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/roadmap';

.otl-BacklogError {
  padding: 56px 40px calc(#{$timelines-head-height} + 44px);
}

.otl-BacklogError_Title {
  text-align: center;
  font-size: $fs-32;
  font-weight: fw('medium');
  font-family: $system-ui;
  margin-bottom: 16px;
  color: $dark-1;
}

.otl-BacklogError_Subtitle {
  text-align: center;
  color: $dark-3;
  padding-bottom: 34px;
  margin-bottom: 40px;
  box-shadow: inset 0 -1px 0 $grey-2-next;
  font-family: $system-ui;
}

.otl-BacklogError_Hero {
  margin: 0 auto 50px;
  display: block;
}

.otl-BacklogError_List {
  max-width: 864px;
  margin: 0 auto;
  --item-border-radius: #{$border-radius-md};
}
</style>
