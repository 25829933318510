import { isEmpty } from 'lodash'

import { UNSELECTED_DATE } from '@/utils/date'
import { HOMEPAGE_OKR_SEARCH_TYPE_IDS } from '@/utils/objectives'

export const USER_LIST_TYPES = {
  OWNERS: 'owners',
  FOREIGN_OBJECTIVES: 'foreignObjectives',
  STAKEHOLDERS: 'stakeholders',
  WATCHERS: 'watchers'
}

export const GROUP_LIST_TYPES = {
  OWNERS: 'owners',
  FOREIGN_OBJECTIVES: 'foreignObjectives'
}

export const SEARCH_TYPE_OPTIONS = [
  {
    label: 'homepage.progress.switch.objectives',
    value: HOMEPAGE_OKR_SEARCH_TYPE_IDS.OBJECTIVES,
    color: 'var(--primary-color-next)',
    gapKey: 'objGap'
  },
  {
    label: 'homepage.progress.switch.krs',
    value: HOMEPAGE_OKR_SEARCH_TYPE_IDS.KEY_RESULTS,
    color: 'var(--primary-color-next)',
    gapKey: 'krGap'
  }
]

export const getAvailableCustomRangeParams = ({ selectedDates, intervalIds }) => {
  if (isEmpty(intervalIds)) {
    const { startDates, dueDates } = selectedDates
    return {
      startDateFrom: startDates ? startDates[0] : UNSELECTED_DATE,
      dueDateTo: dueDates ? dueDates[1] : UNSELECTED_DATE
    }
  } else {
    return {
      startDateFrom: UNSELECTED_DATE,
      dueDateTo: UNSELECTED_DATE
    }
  }
}
