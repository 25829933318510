<template>
  <div class="al-Wrapper">
    <div v-for="(activity, index) in activitiesToShow" :key="index" class="al-ActivityWrapper">
      <AppAvatar :avatar-url="activity.who.icon" class="al-Avatar" no-margins />
      <div class="al-Content">
        <div class="al-Content_Header">
          <span class="al-Name">
            {{ activity.who.name }}
          </span>
          <span class="al-Action">
            {{ activity.action }}
          </span>
          <!-- eslint-disable vue/no-v-html -->
          <span class="al-Object" v-html="activity.changedObject" />
          <!-- eslint-enable vue/no-v-html -->
          <span class="al-Time">
            {{ calculateActivityTime(activity.date) }}
          </span>
        </div>
        <div v-if="activity.changedValue" class="al-Description">
          <ContributeLine v-if="activity.changedValue" class="al-Description_ContributeLine" />

          <span v-if="!activity.html" class="al-DescriptionText">
            {{ activity.changedValue }}
          </span>

          <!-- eslint-disable vue/no-v-html
           <span
               v-if="activity.changedObject !== 'the <strong>description</strong>'"
               class="al-DescriptionText"
               v-html="activity.changedValue"
           />
          eslint-enable vue/no-v-html -->

          <DescriptionField
            v-else
            v-model="activity.changedValue"
            :show-placeholder="false"
            :styled="false"
            :workspace-id="workspaceId"
            readonly
            simple-view
          />
        </div>
      </div>
    </div>

    <InfiniteScrollLoader :identifier="infiniteId" @infinite="infiniteHandler">
      <template #no-results>
        <div class="al-NoItems">
          {{ $t('objective.activity_log_empty') }}
        </div>
      </template>
      <template #loader>
        <ActivityLogLoader />
      </template>
    </InfiniteScrollLoader>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapState } from 'vuex'

import ObjectivesInfoApiHandler from '@/api/okr-elements'
import { getTimeDiffs, getMonthList } from '@/utils/date'
import { handleError } from '@/utils/error-handling'

import ContributeLine from '@/components/objectives/forms/ContributeLine'
import DescriptionField from '@/components/objectives/forms/DescriptionField'
import AppAvatar from '@/components/ui/AppAvatar/AppAvatar'
import InfiniteScrollLoader from '@/components/ui/InfiniteScrollLoader/InfiniteScrollLoader'
import ActivityLogLoader from '@/components/ui/SkeletonLoaders/ActivityLogLoader'

export default defineComponent({
  name: 'ActivityLog',
  components: {
    ActivityLogLoader,
    InfiniteScrollLoader,
    DescriptionField,
    ContributeLine,
    AppAvatar
  },

  props: {
    objective: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      activities: [],
      page: 0,
      infiniteId: +new Date()
    }
  },

  computed: {
    ...mapState('workspaces', {
      workspaceId: state => state.workspaceId
    }),

    activitiesToShow() {
      return this.activities.slice(0, 10 * this.page)
    }
  },

  methods: {
    /** @public */
    refreshData() {
      this.page = 0
      this.activities = []
      this.infiniteId += 1
    },

    calculateActivityTime(item) {
      const editedOn = new Date(item)
      const now = new Date()

      const { diffForDay, diffForHour, diffForMin } = getTimeDiffs(now, editedOn)

      const formatHours = `0${editedOn.getHours()}`.slice(-2)
      const formatMins = `0${editedOn.getMinutes()}`.slice(-2)

      if (diffForDay > 7) {
        return `${
          getMonthList()[editedOn.getMonth()]
        } ${editedOn.getDate()}, ${formatHours}:${formatMins}`
      }

      if (diffForDay >= 1) {
        return `${
          getMonthList()[editedOn.getMonth()]
        } ${editedOn.getDate()}, ${editedOn.getFullYear()} ${formatHours}:${formatMins}`
      }

      if (diffForHour >= 1 && diffForDay < 1) {
        return `${diffForHour} ${this.$t('objective.hours_ago')}`
      }

      if (diffForHour < 1 && diffForMin >= 1) {
        return `${diffForMin} ${this.$t('token.minutes_ago')}`
      }

      if (diffForMin < 1) {
        return this.$t('objective.a_moment_ago')
      }

      return `${diffForDay} ${this.$t('objectives.days_ago')}`
    },

    async getActivities() {
      const api = new ObjectivesInfoApiHandler()
      try {
        this.activities = await api.getHistoryForObject({
          objectId: this.objective.id,
          objectTypeId: this.objective.typeId
        })
      } catch (error) {
        handleError({ error })
      }
    },

    async infiniteHandler($state) {
      if (this.page === 0) {
        await this.getActivities()
      }

      if (this.activitiesToShow.length === this.activities.length) {
        $state.complete()
      } else {
        this.page += 1
        $state.loaded()
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.al-Wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.al-ActivityWrapper {
  display: flex;
  align-items: flex-start;
  color: $blue-4;
  line-height: 24px;
  font-size: $fs-14;
  gap: 8px;
  font-family: $system-ui;
  position: relative;
}

.al-Name {
  font-weight: fw('semi-bold');
  color: $primary-color-next;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.al-Avatar {
}

.al-Content {
  // to move a long text with a long word to the next line
  // 32px is padding left and width of rounded arrow with margin
  width: calc(100% - 32px);
}

.al-Action,
.al-Object,
.al-Time {
  flex: 0 0 auto;
}

.al-Time {
  color: $dark-3;
  margin-left: 4px;
}

.al-Description {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  position: relative;
}

// to move a long text with a long word to the next line
.al-DescriptionText {
  overflow-wrap: break-word;
  hyphens: auto;
  width: 100%;
  font-style: normal;
  font-weight: fw('regular');
  font-size: $fs-14;
  line-height: 20px;
  color: $dark-1;
}

.al-NoItems {
  color: $grey-1;
  text-align: center;
}
.al-Content_Header {
  font-weight: fw('regular');
  font-size: $fs-14;
  line-height: 20px;
  color: $dark-1;
  margin-bottom: 10px;
  padding-top: 2px;
  display: flex;
  gap: 4px;
}
.al-Description_ContributeLine {
  position: absolute;
  top: 0;
  left: -19px; // 11px Half width of the avatar + 8px gap
  --height: 11px; // Half height of the avatar
  --width: 11px; // Half width of the avatar
}
</style>
