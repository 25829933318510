<template>
  <div class="ian-Loader">
    <div v-for="n in 6" :key="n" class="ian-LoaderItem">
      <div class="ian-LoaderItem_Top">
        <SkeletonItem :appearance="CIRCLE" :size="SM" class="ian-Loader_Avatar" />
        <SkeletonItem :size="XS" width="200px" />
        <SkeletonItem :size="XS" width="120px" />
      </div>
      <div class="ian-LoaderItem_Bottom">
        <SkeletonItem :size="XS" width="360px" />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'InAppNotificationsLoader'
})
</script>

<script setup>
import {
  SKELETON_APPEARANCES,
  SKELETON_SIZES
} from '@/utils/components-configurations/skeleton-item'

import SkeletonItem from '@/components/ui/SkeletonLoaders/SkeletonItem'

const { CIRCLE } = SKELETON_APPEARANCES
const { SM, XS } = SKELETON_SIZES
</script>

<style scoped lang="scss">
.ian-Loader {
  display: grid;
  gap: 14px;
}

.ian-LoaderItem {
  min-height: 92px;
  border-radius: $border-radius-sm-next;
  display: grid;
  gap: 8px;
  padding: 10px;
}

.ian-LoaderItem_Top {
  display: flex;
  align-items: center;
  gap: 8px;
}

.ian-LoaderItem_Bottom {
  margin-left: 32px;
  padding: 10px;
  background-color: $white;
  border: 2px solid $grey-3-next;
  border-radius: $border-radius-md-next;
}
</style>
