<template>
  <div
    v-show="loading"
    :class="['o-loading-circle', `o-loading-circle-${size}`, `o-loading-circle-${align}`]"
    :style="{ 'min-height': minHeight ? minHeight : '' }"
  >
    <span :style="`stroke: ${color}`" class="o-loading-icon">
      <svg
        class="sc-bwzfXH egrAnw"
        focusable="false"
        height="48"
        size="48"
        viewBox="0 0 48 48"
        width="48"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="24" cy="24" r="21.5" />
      </svg>
    </span>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'LoadingCircle',

  props: {
    loading: {
      type: Boolean,
      default: true
    },

    size: {
      type: String,
      default: 'large',
      validator: v => ['xl', 'large', 'medium', 'small', 'xsmall', 'xxs'].includes(v)
    },

    align: {
      type: String,
      default: 'center'
    },

    color: {
      type: String,
      default: '#42526e'
    },

    minHeight: {
      type: String,
      default: null
    }
  }
})
</script>

<style lang="scss">
.o-loading-circle {
  display: flex;
  align-items: center;

  &-xl {
    .o-loading-icon {
      width: 64px;
      height: 64px;
    }
  }

  &-large {
    height: 144px;
    .o-loading-icon {
      width: 48px;
      height: 48px;
    }
  }

  &-medium {
    .o-loading-icon {
      width: 20px;
      height: 20px;
    }
  }

  &-small {
    .o-loading-icon {
      width: 24px;
      height: 24px;
    }
  }

  &-xsmall {
    .o-loading-icon {
      width: 16px;
      height: 16px;
    }
  }

  &-xxs {
    .o-loading-icon {
      width: 12px;
      height: 12px;
    }
  }

  &-left {
    justify-content: flex-start;
  }
  &-center {
    justify-content: center;
  }
  &-right {
    justify-content: flex-start;
  }

  svg {
    width: 100%;
    height: 100%;
  }

  .o-loading-icon {
    display: flex;
    animation: 0.86s cubic-bezier(0.4, 0.15, 0.6, 0.85) infinite rotate;
    fill: none;
    stroke: $dark-grey;
    stroke-dasharray: 135.0884841043611px;
    stroke-dashoffset: 108.07078728348888px;
    stroke-linecap: round;
    stroke-width: 5px;
  }
}
</style>
