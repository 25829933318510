import { useI18n } from 'vue-i18n'

export const ASC = 'ASC'

export const DESC = 'DESC'

export const ascSortValue = 'A-Z'
export const descSortValue = 'Z-A'
export const AZOptions = [
  { label: 'filter.A_Z', value: ascSortValue },
  { label: 'filter.Z_A', value: descSortValue }
]

export const avgDecreaseValue = 'avg-decrease'
export const avgIncreaseValue = 'avg-increase'
export const AvgOptions = [
  { label: 'filter.avg', value: avgDecreaseValue },
  { label: 'filter.avg', value: avgIncreaseValue }
]

export const sortOptions = () => {
  const { t } = useI18n()

  return [...AZOptions, ...AvgOptions].map(option => ({
    ...option,
    label: t(option.label)
  }))
}

export const avgDecreaseIcon = value => (value ? 'arrow-down-inverse' : 'arrow-down')
export const avgIncreaseIcon = value => (value ? 'arrow-up-inverse' : 'arrow-up')

export const sortAvgDecreaseValue = payload =>
  payload.sort((a, b) => b.averageTotalPercent - a.averageTotalPercent)
export const sortAvgIncreaseValue = payload =>
  payload.sort((a, b) => a.averageTotalPercent - b.averageTotalPercent)
