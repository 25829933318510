<template>
  <div class="cll-Wrapper">
    <div v-for="item in ROWS" :key="item.id" class="cll-Item">
      <SkeletonItem :appearance="CIRCLE" :size="SM" />
      <div class="cll-Body">
        <div class="cll-Body_Top">
          <SkeletonItem :size="XS" :width="item.author" />
          <SkeletonItem :size="XS" :width="item.date" />
        </div>
        <div class="cll-Body_Bottom">
          <SkeletonItem
            v-for="line in item.description"
            :key="line.id"
            :size="XS"
            :width="line.width || '100%'"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CommentsListLoader'
})
</script>

<script setup>
import {
  SKELETON_APPEARANCES,
  SKELETON_SIZES
} from '@/utils/components-configurations/skeleton-item'

import SkeletonItem from '@/components/ui/SkeletonLoaders/SkeletonItem'

const { CIRCLE } = SKELETON_APPEARANCES
const { XS, SM } = SKELETON_SIZES

const props = defineProps({
  elementsCount: {
    type: Number,
    default: 5
  }
})

const ROWS = [
  {
    id: 0,
    author: '113px',
    event: '180px',
    date: '148px',
    description: [
      {
        id: 0
      }
    ]
  },
  {
    id: 1,
    author: '70px',
    event: '213px',
    date: '60px',
    description: [
      {
        id: 0
      },
      {
        id: 1,
        width: '50%'
      }
    ]
  },
  {
    id: 2,
    author: '164px',
    event: '30px',
    date: '90px',
    description: [
      {
        id: 0
      },
      {
        id: 1
      },
      {
        id: 2
      },
      {
        id: 3,
        width: '20%'
      }
    ]
  },
  {
    id: 3,
    author: '100px',
    event: '160px',
    date: '120px',
    description: [
      {
        id: 0
      }
    ]
  },
  {
    id: 4,
    author: '150px',
    event: '100px',
    date: '100px',
    description: [
      {
        id: 0
      },
      {
        id: 1
      }
    ]
  }
].slice(0, props.elementsCount)
</script>

<style lang="scss" scoped>
.cll-Wrapper {
  display: grid;
  gap: 24px;
  padding-top: 16px;
}

.cll-Item {
  display: flex;
  gap: 8px;
  width: 100%;
}

.cll-Body {
  flex: 1;
  display: grid;
  gap: 6px;
  &_Top,
  &_Bottom {
    display: flex;
    gap: 8px;
  }

  &_Top {
    align-items: center;
    height: 24px;
  }

  &_Bottom {
    flex-direction: column;
    min-height: 20px;
  }
}
</style>
