<template>
  <div class="ms-Statistics">
    <AppRadioGroup
      :model-value="searchTypeId"
      :options="searchTypeIdOptions"
      class="ms-SearchTypeIdOptions"
      data-testid="search-type-id"
      name="okr"
      type="tab-like"
      @update:model-value="updateSearchTypeId"
    >
      <template #item-label="{ item }">
        <div class="ms-Progress_WeekValue">
          <span class="ms-SearchTypeIdLabel">
            {{ item.label }}
            <span class="ms-SearchTypeIdLabel">
              <span class="ms-ProgressValue">
                {{
                  displayProgress(item.gapKey)[PLURALIZATION_KEYS.FLOAT_NUMBER] +
                  displayProgress(item.gapKey)[PLURALIZATION_KEYS.SYMBOL] +
                  '%'
                }}
              </span>
            </span>
          </span>
        </div>
      </template>
    </AppRadioGroup>

    <SkeletonItem
      v-if="userOrGroupDataLoading"
      border-radius="24px"
      class="ms-Loader"
      height="275px"
      width="100%"
    />
    <div v-else class="ms-Container">
      <div class="ms-Charts">
        <div class="ms-ProgressChart">
          <LineChart
            v-if="showChart"
            :aspect-ratio="2.035"
            :border-color="pointHoverBackgroundColor"
            :chart-data="chartData"
            :chart-type="CHART_TYPES.homepagePreview"
            :data-auto-testid="chartTestId"
            :data-testid="chartTestId"
            :point-background-color="TRANSPARENT_COLOR"
            :point-hover-background-color="pointHoverBackgroundColor"
            :time-intervals-count="4"
            full-size-chart
            set-y-from0-to100
            without-background
          />
        </div>
        <div class="ms-SelectedProgress">
          <span :style="{ '--fs': responsiveFontSize }" class="ms-SelectedProgressValue">
            {{
              displayProgress(getCurrentGapKey)[PLURALIZATION_KEYS.FLOAT_NUMBER] +
              displayProgress(getCurrentGapKey)[PLURALIZATION_KEYS.SYMBOL]
            }}
          </span>
          <span
            :class="{
              'ms-LastChangeValue-gapIsPositive': gapIsPositive(getCurrentGapKey),
              'ms-LastChangeValue-gapIsNegative': gapIsNegative(getCurrentGapKey)
            }"
            class="ms-LastChangeValue"
          >
            {{ displayedGap(getCurrentGapKey) + '%' }}
            {{ $t('homepage.progress.last_week_change') }}
          </span>
        </div>
      </div>
      <div class="ms-GradesChart">
        <AppGradeChart :grade-data="gradeData" horizontal />
      </div>
    </div>
  </div>
</template>

<script>
import { isEmpty } from 'lodash'
import { defineComponent } from 'vue'
import { mapActions, mapGetters } from 'vuex'

import { SEARCH_TYPE_OPTIONS } from '@/utils/homepage'
import { memoizeGetCssVariableValue } from '@/utils/memoizations'
import { CHART_TYPES, TRANSPARENT_COLOR } from '@/utils/objective-chart'
import { HOMEPAGE_OKR_SEARCH_TYPE_IDS } from '@/utils/objectives'
import { getSimplifiedNumber, PLURALIZATION_KEYS } from '@/utils/pluralization'
import { HOMEPAGE_SEARCH_TYPE_ID, USER_SETTINGS_MAPPER } from '@/utils/user-settings'

import LineChart from '@/components/LineChart/LineChart'
import AppGradeChart from '@/components/ui/AppGradeChart/AppGradeChart'
import AppRadioGroup from '@/components/ui/AppRadioGroup/AppRadioGroup'
import SkeletonItem from '@/components/ui/SkeletonLoaders/SkeletonItem'

export default defineComponent({
  name: 'MainStatistics',
  components: {
    AppRadioGroup,
    AppGradeChart,
    LineChart,
    SkeletonItem
  },

  props: {
    userOrGroupDataLoading: {
      type: Boolean
    },

    progressData: {
      type: Object,
      default: () => ({
        objGap: {
          gap: 0,
          progress: 0
        },

        krGap: {
          gap: 0,
          progress: 0
        }
      })
    },

    chartData: {
      type: Object,
      required: true
    },

    gradeData: {
      type: Object,
      default: () => ({
        notStarted: 0,
        atRisk: 0,
        behind: 0,
        onTrack: 0,
        closed: 0,
        abandoned: 0
      })
    },

    searchTypeId: {
      type: Number,
      default: HOMEPAGE_OKR_SEARCH_TYPE_IDS.OBJECTIVES
    },

    preventUserSettings: {
      type: Boolean
    }
  },

  emits: {
    'update:searchTypeId': null
  },

  data() {
    return {
      showTypeIdTooltip: false
    }
  },

  computed: {
    TRANSPARENT_COLOR: () => TRANSPARENT_COLOR,

    PLURALIZATION_KEYS: () => PLURALIZATION_KEYS,

    pointHoverBackgroundColor() {
      return memoizeGetCssVariableValue('--primary-color-next', '#0052cc')
    },

    chartTestId() {
      const { searchTypeId } = this
      const types = {
        [HOMEPAGE_OKR_SEARCH_TYPE_IDS.OBJECTIVES]: 'obj',
        [HOMEPAGE_OKR_SEARCH_TYPE_IDS.KEY_RESULTS]: 'kr'
      }
      return `progress-chart-${types[searchTypeId]}`
    },

    ...mapGetters('system', {
      searchTypeIdTooltip: 'searchTypeIdTooltip'
    }),

    responsiveFontSize() {
      const { floatNumber, symbol } = this.displayProgress(this.getCurrentGapKey)
      const DEFAULT_FONT_SIZE = 96
      const MIN_STRING_LENGTH = 3
      const stringLength = `${floatNumber}${symbol}`.length
      let fontSize = DEFAULT_FONT_SIZE

      if (stringLength === MIN_STRING_LENGTH) {
        fontSize = 92
      } else if (stringLength > MIN_STRING_LENGTH) {
        fontSize = Math.floor((DEFAULT_FONT_SIZE / stringLength) * MIN_STRING_LENGTH)
      }
      return `${fontSize}px`
    },

    searchTypeIdOptions() {
      return SEARCH_TYPE_OPTIONS.map(option => ({
        ...option,
        label: this.$t(option.label)
      }))
    },

    CHART_TYPES: () => CHART_TYPES,

    showChart() {
      return !isEmpty(this.chartData)
    },

    getCurrentGapKey() {
      return this.searchTypeIdOptions.find(option => option.value === this.searchTypeId).gapKey
    }
  },

  watch: {
    userOrGroupDataLoading: {
      handler(newValue) {
        if (!newValue) {
          this.$nextTick(() => {
            this.showTypeIdTooltip = this.searchTypeIdTooltip
          })
        }
      },

      immediate: true
    }
  },

  methods: {
    ...mapActions({
      updateUserSettings: 'system/updateUserSettings'
    }),

    displayedGap(gapKey) {
      const { gap } = this.progressData[gapKey]
      const result = getSimplifiedNumber(gap)[PLURALIZATION_KEYS.SIMPLIFIED]
      return this.gapIsPositive(gapKey) ? `+${result}` : result
    },

    gapIsPositive(gapKey) {
      return Number(this.progressData[gapKey].gap) > 0
    },

    gapIsNegative(gapKey) {
      return Number(this.progressData[gapKey].gap) < 0
    },

    displayProgress(gapKey) {
      return getSimplifiedNumber(this.progressData[gapKey].progress)
    },

    updateSearchTypeId(value) {
      this.$emit('update:searchTypeId', value)

      if (this.preventUserSettings) return
      this.updateUserSettings({
        [USER_SETTINGS_MAPPER[HOMEPAGE_SEARCH_TYPE_ID]]: value
      })
    }
  }
})
</script>

<style lang="scss" scoped>
.ms-Loader {
  max-width: 976px;
  margin: 0 auto;
}

.ms-Statistics {
  display: flex;
  margin: 0 auto;
  border-radius: 24px;
  max-width: var(--max-width, 976px);
  width: 100%;
  padding: 32px;
  gap: 18px;
  flex-direction: column;
}

.ms-ProgressValue {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ms-Container {
  display: flex;
  gap: 32px;
}

.ms-Charts {
  display: flex;
  gap: 8px 32px;
  width: 100%;
  overflow: hidden;
  padding-top: 16px;
}

.ms-ProgressChart {
  width: 454px; // calc(100% - 180px); // 180px = 140px - right side width + 40px gap
  overflow: hidden; // need for recalculate chart size on resize browser
}

.ms-GradesChart {
  display: flex;
  align-self: normal;
  flex: 1 0 auto;
  max-width: 190px;
  width: 190px;
}

.ms-SearchTypeIdLabel {
  font-size: $fs-20;
  font-style: normal;
  font-weight: fw('semit-bold');
  line-height: 28px;
}

.ms-SearchTypeIdOptions {
  &:deep(.arg-Options) {
    gap: 10px 32px;
    flex-wrap: wrap;
  }

  &:deep(.arg-Option) {
    padding-top: 0;
  }

  &:deep(.arg-Item) {
    display: flex;
  }

  &:deep(.ab-Badge) {
    position: relative;
    left: 0;
  }
}
.ms-SelectedProgress {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  //min-width: fit-content;
  flex: 1 0 auto;
  text-align: center;
  margin: 7px 0 0 0;
  height: 193px; // высота подобрана попиксельно и отступ подобран что бы линии совпадали
  border-top: 2px solid $grey-3-next;
  border-bottom: 2px solid $grey-3-next;
}
.ms-SelectedProgressValue {
  font-size: var(--fs);
  font-weight: fw('regular');
  color: $primary-color-next;
  max-width: 100%;
  text-align: center;
  &:after {
    content: '%';
    font-weight: fw('semi-bold');
    font-size: calc(var(--fs) / 2);
  }
}
.ms-LastChangeValue {
  font-size: $fs-12;
  font-weight: fw('bold');
  line-height: 16px;
  color: $dark-3;

  &-gapIsPositive {
    color: var(--grade-high-color-next);
  }
  &-gapIsNegative {
    color: var(--grade-low-color-next);
  }
}
@media screen and (max-width: 1080px) {
  .ms-SelectedProgress {
    min-width: fit-content;
  }
  .ms-Container {
    flex-wrap: wrap;
  }
  .ms-GradesChart {
    max-width: 100%;
    width: 100%;
  }
  :deep(.agc-Wrapper) {
    gap: 24px 32px;
    display: grid;
    grid-template-areas:
      'a b c'
      'd e f';
  }
}
</style>
