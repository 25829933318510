import { isEqual } from 'lodash'

import { KR_VALUE_TYPES } from '@/utils/objectives'

export const getTargetValue = formModel => {
  if (formModel.fieldTypeId === KR_VALUE_TYPES.NUMBER) {
    return formModel.numberTargetValue
  }
  if (formModel.fieldTypeId === KR_VALUE_TYPES.PERCENT) {
    return formModel.percentTargetValue
  }
  return formModel.targetValue
}
export const isBinarySelectDisabled = modelValue => {
  return (
    modelValue &&
    modelValue.originalValue === modelValue.currentValue &&
    modelValue.originalValue === KR_VALUE_TYPES.NUMBER
  )
}
export const getOriginalValue = formModel => {
  if (formModel.fieldTypeId === KR_VALUE_TYPES.NUMBER) {
    return formModel.numberOriginalValue
  }
  if (formModel.fieldTypeId === KR_VALUE_TYPES.PERCENT) {
    return formModel.percentOriginalValue
  }
  return formModel.originalValue
}

export const getCurrentValue = payload => {
  const currentValueByFiledTypeId = {
    [KR_VALUE_TYPES.NUMBER]: payload.numberCurrentValue,
    [KR_VALUE_TYPES.PERCENT]: payload.percentCurrentValue,
    [KR_VALUE_TYPES.BINARY]: payload.binaryValue
  }
  return currentValueByFiledTypeId[payload.fieldTypeId]
}
export const valueToStringOrNull = value => (value !== null ? value.toString() : null)

export const GRADE_MODES = {
  AUTO: 1,
  MANUAL: 2
}

export const DEFAULT_RESULT_TYPE_OPTIONS = [
  { icon: 'result-type-percent', label: 'objectives.percent', value: 2 },
  { icon: 'result-type-binary', label: 'objectives.binary', value: 3 },
  { icon: 'result-type-number', label: 'objectives.number', value: 1 }
]

export const DEFAULT_TARGET_VALUE = 100
export const DEFAULT_ORIGINAL_VALUE = 0
export const DEFAULT_CURRENT_VALUE = 0
export const DEFAULT_RESULT_TYPE_VALUES = {
  fieldTypeId: 2,
  binaryValue: 0,
  percentOriginalValue: '0',
  percentCurrentValue: '0',
  percentTargetValue: '100',
  numberOriginalValue: '0',
  numberCurrentValue: '0',
  numberTargetValue: '100',
  originalValue: `${DEFAULT_ORIGINAL_VALUE}`,
  currentValue: `${DEFAULT_CURRENT_VALUE}`,
  targetValue: `${DEFAULT_TARGET_VALUE}`
}

export const getResultTypeValues = ({
  fieldTypeId,
  originalValue,
  currentValue,
  targetValue
} = {}) => {
  let result = {}

  if (fieldTypeId === KR_VALUE_TYPES.NUMBER) {
    result.numberOriginalValue = valueToStringOrNull(originalValue)
    result.numberCurrentValue = valueToStringOrNull(currentValue)
    result.numberTargetValue = valueToStringOrNull(targetValue)
  } else if (fieldTypeId === KR_VALUE_TYPES.PERCENT) {
    result.percentOriginalValue = valueToStringOrNull(originalValue)
    result.percentCurrentValue = valueToStringOrNull(currentValue)
    result.percentTargetValue = valueToStringOrNull(targetValue)
  } else if (fieldTypeId === KR_VALUE_TYPES.BINARY) {
    result.binaryValue = currentValue
  }
  return result
}

export const notEqualStringifiedValues = (value1, value2) => !isEqual(`${value1}`, `${value2}`)

export const getResultTypeSettingsDisabledStates = ({
  isEdit,
  editable,
  isLockedOnPercent,
  isBinarySelectDisabled,
  valuesAreReadonly
}) => {
  const mainCondition = isEdit && !editable
  const inputCondition = valuesAreReadonly || mainCondition
  return {
    select: isLockedOnPercent || mainCondition,
    isSwitch: isBinarySelectDisabled || inputCondition,
    input: inputCondition
  }
}
