<template>
  <div class="cpl-Wrapper">
    <AppIcon class="cpl-EmptyImage" height="240" icon-name="empty-state-hero" width="424" />
    <AppTitle :level="5" disable-margin>
      <i18n-t
        class="cpl-Title"
        keypath="confluence.permission_less.title"
        tag="div"
        scope="global"
      />
    </AppTitle>
    <AppTitle :level="6" disable-margin>
      <i18n-t
        class="cpl-Description"
        keypath="confluence.permission_less.description"
        tag="div"
        scope="global"
      />
    </AppTitle>
  </div>
</template>

<script setup>
import AppIcon from '@/components/ui/AppIcon/AppIcon'
//eslint-disable-next-line
import AppTitle from '@/components/ui/AppTitle/AppTitle'
</script>
<script>
//eslint-disable-next-line
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'JiraPermissionLess'
})
</script>

<style lang="scss" scoped>
.cpl-Title {
  text-align: center;
  margin-bottom: 9px;
}
.cpl-Wrapper {
  text-align: center;
}
.cpl-EmptyImage {
  margin-bottom: 32px;
}
</style>
