<template>
  <div class="fc-Caption">
    <AppTitle v-if="title || $slots.title" :level="5" disable-margin>
      <slot name="title">
        {{ title }}
      </slot>
    </AppTitle>
    <AppTitle
      v-if="subtitle || $slots.subtitle"
      :level="6"
      class="fc-Caption_Subtitle"
      color="var(--dark-3)"
      disable-margin
    >
      <slot name="subtitle">
        {{ subtitle }}
      </slot>
    </AppTitle>
    <div v-if="$slots.default" class="fc-CaptionContent">
      <slot />
    </div>
  </div>
</template>

<script setup>
import AppTitle from '@/components/ui/AppTitle/AppTitle'

defineOptions({
  name: 'FormCaption'
})

defineProps({
  title: {
    type: String,
    default: ''
  },

  subtitle: {
    type: String,
    default: ''
  }
})
</script>

<style lang="scss" scoped>
.fc-CaptionContent {
  margin-top: 12px;
}
</style>
