import { isArray, isNull, isNumber, isObject, isString } from 'lodash'

import { APP_MODAL_SIZES } from '@/utils/components-configurations/app-modal'

export const indicatorPanelValidator = v => !Number.isNaN(v) && v <= Infinity && v >= 0
export const objectOrNullProp = prop => (isObject(prop) && !isArray(prop)) || isNull(prop)
export const numberOrNullProp = prop => isNumber(prop) || isNull(prop)
export const stringOrNullProp = prop => isString(prop) || isNull(prop)

export const modalSizePropValidator = prop => {
  const isSizeInPixels = isNumber(prop)

  if (isSizeInPixels) {
    return prop >= 0 && Number.isSafeInteger(prop) && Number.isFinite(prop) && !Number.isNaN(prop)
  } else {
    return Object.values(APP_MODAL_SIZES).includes(prop) // DO NOT REMOVE [SM, SM-NEXT, LG, LG-NEXT] SIZES WHILE REFACTORING
  }
}
