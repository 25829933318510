<template>
  <div class="ols-Step">
    <slot name="step-title" />

    <FormFieldNext :label="t('levels.header_title')" class="ols-Step_levels">
      <template v-if="isLoading">
        <SkeletonItem
          v-for="n in 3"
          :key="n"
          :size="SKELETON_SIZES.LG"
          style="--skeleton-width: 100%"
        />
      </template>
      <template v-else>
        <EntityButton
          v-for="level in levels"
          :key="level.id"
          :size="BUTTON_SIZES.LG_NEXT"
          type="outline"
          @click="onEditLevel({ level })"
        >
          <OkrIcon :objective="levelToOkrIconElement(level)" />
          <span class="oboard-truncated-text">
            {{ level.name }}
          </span>
        </EntityButton>
      </template>
    </FormFieldNext>

    <slot name="step-message" />

    <portal to="modal-windows">
      <LevelModal
        v-model:show="showEditLevelModal"
        :model-value="levelForEdit"
        @edit="onLevelUpdated"
        @update:show="!$event ? (levelForEdit = null) : null"
      />
    </portal>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import LevelApiHandler from '@/api/level'
import { BUTTON_SIZES } from '@/utils/components-configurations/app-button'
import { SKELETON_SIZES } from '@/utils/components-configurations/skeleton-item'
import { handleError } from '@/utils/error-handling'
import { levelToOkrIconElement } from '@/utils/objectives'

import FormFieldNext from '@/components/form/FormFieldNext'
import OkrIcon from '@/components/objectives/items/OkrIcon'
import SkeletonItem from '@/components/ui/SkeletonLoaders/SkeletonItem'
import EntityButton from '@/components/ui/WorkspaceSetupSteps/EntityButton'
import LevelModal from '@/components/workspaces/LevelModal'

defineOptions({
  name: 'OkrLevelsStep'
})

const { t } = useI18n()

const isLoading = ref(false)

const levels = ref([])

const levelApi = new LevelApiHandler()

const getLevels = async () => {
  isLoading.value = true
  try {
    levels.value = await levelApi.getLevels({
      workspaceIds: null,
      levelIds: null
    })
  } catch (error) {
    handleError({ error })
  } finally {
    isLoading.value = false
  }
}

onMounted(() => {
  getLevels()
})

const levelForEdit = ref(null)
const showEditLevelModal = ref(false)

const onEditLevel = ({ level }) => {
  levelForEdit.value = level
  showEditLevelModal.value = true
}

const onLevelUpdated = () => {
  showEditLevelModal.value = false
  levelForEdit.value = null
  getLevels()
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/wizard-steps';

.ols-Step {
  @extend %wizard-step-styles;
}

.ols-Step_levels {
  width: 100%;
}
</style>
