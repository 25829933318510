<template>
  <AppDialog
    :show="show"
    :title="$t('levels.delete_title', { levelName: levelToDelete?.name })"
    :type="DIALOG_TYPES.DELETE"
    @on-confirm="onConfirm"
    @on-close="onClose"
  >
    <i18n-t keypath="levels.delete_description" scope="global">
      <template #levelName>
        <span class="ld-DangerText">
          {{ levelToDelete?.name }}
        </span>
      </template>
    </i18n-t>

    <!-- <div class="ld-ConvertBlock">
  <div v-if="false">
    {{ $t('levels.convert_label', { levelName: levelToDelete.name }) }}

    <AppSelect
      v-model="levelIdToConvert"
      :options="filteredLevels"
      item-icon="icon"
      item-value="id"
      item-label="name"
    >
      <template #button-content="{ option }">
        <template v-if="option">
          <OkrIcon :objective="levelToOkrIconElement(option)" />
          <span class="ld-SelectedName">{{ option.name }}</span>
        </template>
      </template>
    </AppSelect>
  </div>
</div> -->
  </AppDialog>
</template>

<script>
import { defineComponent } from 'vue'

// import { levelToOkrIconElement } from '@/utils/objectives'

import { DIALOG_TYPES } from '@/utils/components-configurations/app-dialog'
import { objectOrNullProp } from '@/utils/prop-validators'

import AppDialog from '@/components/AppDialog'

export default defineComponent({
  name: 'LevelDeleteModal',

  components: {
    AppDialog
  },

  props: {
    levelToDelete: {
      required: true,
      validator: v => objectOrNullProp(v)
    },

    // levels: {
    //   type: Array,
    //   default: () => []
    // },

    show: {
      type: Boolean
    }
  },

  emits: { close: null, confirm: null },

  computed: {
    DIALOG_TYPES: () => DIALOG_TYPES
  },

  // data() {
  //   return {
  //     levelIdToConvert: null
  //   }
  // },

  // computed: {
  //   filteredLevels() {
  //     return this.levels.filter(level => level.id !== this.levelToDelete.id)
  //   }
  // },
  //
  // watch: {
  //   filteredLevels: {
  //     handler(newValue) {
  //       this.levelIdToConvert = newValue[0].id
  //     },
  //
  //     immediate: true,
  //     deep: true
  //   }
  // },

  methods: {
    // levelToOkrIconElement,

    onConfirm() {
      this.$emit('confirm')
    },

    onClose() {
      this.$emit('close')
    }
  }
})
</script>

<style lang="scss" scoped>
// .ld-ConvertBlock {
//   display: flex;
//   align-items: center;
//   margin-bottom: 24px;
//
//   .ld-SelectedName {
//     margin-left: 4px;
//   }
// }
@import '~@/assets/styles/modal-danger-text';

.ld-DangerText {
  @extend %modal-danger-text;
}
</style>

<style lang="scss">
// .ld-ConvertBlock {
//   .as-AppDroplistButton,
//   .as-AppDroplistButton:hover {
//     background-color: transparent;
//     border-color: transparent;
//   }
//
//   .as-AppDroplistButton_Content {
//     color: $primary-color;
//   }
// }
</style>
