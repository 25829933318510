<template>
  <AppSelect
    :data-auto-testid="ASSIGNEE_FORM_FIELD_TEST_ID"
    :data-testid="ASSIGNEE_FORM_FIELD_TEST_ID"
    :disabled="!userHasUpdateAccess"
    :item-label="ASSIGNEE_ENTITY_KEYS.NAME"
    :item-value="ASSIGNEE_ENTITY_KEYS.ACCOUNT_ID"
    :loading="isLoading"
    :model-value="selectedAssignees"
    :offset="[0, 0]"
    :options="assignees"
    :search-function="getAssignees"
    dropdown-search
    has-only-this-button
    hide-selected-items-in-dropdown
    multi
    show-selected-options-inside
    skeleton-loader
    @update:options="assignees = $event"
    @update:model-value="onUpdateModelValue"
  >
    <template #button="{ fullDataOption, active }">
      <OkrFormFieldSelectTriggerNext
        :empty-state-label="emptyStateLabel"
        :label="fieldName"
        :opened="active"
        :selected-options="fullDataOption"
        separated-label
        truncated-label
      >
        <template #label="{ resolvedLabel }">
          <CustomFieldLabel :field-id="fieldId"> {{ resolvedLabel }}</CustomFieldLabel>
        </template>
        <template #values>
          <SelectedUsersAvatarsList :users="fullDataOption" />
        </template>
      </OkrFormFieldSelectTriggerNext>
    </template>
    <template #option-label="{ option }">
      <OwnerFieldOption :option="option" />
    </template>
  </AppSelect>
</template>

<script setup>
import { computed, onMounted, ref, unref } from 'vue'

import { ALL_CUSTOM_FIELDS } from '@/utils/custom-fields/factory'
import {
  getAssigneeCustomFieldEmitValue,
  getAssigneeCustomFieldSelectedItems
} from '@/utils/custom-fields/helpers'
import { ASSIGNEE_FORM_FIELD_TEST_ID } from '@/utils/custom-fields/jest-helpers'
import { useFetchAssignees } from '@/utils/custom-fields/use-assignees'
import { useCustomFieldsHelpers } from '@/utils/custom-fields/use-custom-fields'
import { ASSIGNEE_ENTITY_KEYS } from '@/utils/entity-keys'

import CustomFieldLabel from '@/components/custom-fields/okr-elements-form/CustomFieldLabel'
import OwnerFieldOption from '@/components/form/OwnerFieldOption'
import SelectedUsersAvatarsList from '@/components/form/SelectedUsersAvatarsList'
import AppSelect from '@/components/ui/AppSelect/AppSelect'
import OkrFormFieldSelectTriggerNext from '@/components/ui/AppSelect/TriggerButtons/OkrFormFieldSelectTriggerNext'

defineOptions({
  name: 'AssigneeField',
  inheritAttrs: false
})

const props = defineProps({
  userHasUpdateAccess: {
    type: Boolean,
    required: true
  },

  workspaceId: {
    type: [String, Number],
    required: true
  },

  fieldId: {
    type: [String, Number],
    required: true
  },

  modelValue: {
    type: Array,
    required: true
  },

  isEdit: {
    type: Boolean
  }
})

const assignees = ref([])

const selectedAssignees = computed(() => {
  return getAssigneeCustomFieldSelectedItems({
    fieldValue: props.modelValue,
    isEdit: props.isEdit
  })
})

const { isLoading, getAssignees } = useFetchAssignees({
  workspaceId: props.workspaceId,
  selectedAssignees: selectedAssignees.value
})

onMounted(async () => {
  assignees.value = await getAssignees()
})

const { fieldName, emptyStateLabel } = useCustomFieldsHelpers({
  fieldId: props.fieldId
})

const emit = defineEmits({
  'update:model-value': null
})
const onUpdateModelValue = accountIds => {
  const value = getAssigneeCustomFieldEmitValue({
    accountIds,
    allAssignees: unref(assignees),
    isEdit: props.isEdit
  })

  emit('update:model-value', {
    fieldId: props.fieldId,
    value,
    fieldTypeId: ALL_CUSTOM_FIELDS.getTypeIds().ASSIGNEE
  })
}
</script>

<style lang="scss" scoped></style>
