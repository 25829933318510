import { isEmpty } from 'lodash'
import { reactive } from 'vue'

import i18n from '@/i18n'
import { STATUS_NAMES_ENTITY_KEYS } from '@/utils/entity-keys'
import { OKR_STATUSES } from '@/utils/objectives'

const { ON_TRACK, AT_RISK, BEHIND, ABANDONED, CLOSED, NOT_STARTED } = OKR_STATUSES

const {
  DEFAULT_NAME,
  CUSTOMER_NAME,
  TRANSLATION_KEYS,
  KEY,
  TEMPLATE,
  I18N_CONTEXT,
  DEFAULT_TEMPLATE
} = STATUS_NAMES_ENTITY_KEYS

const DEFAULT_NAME_SHORTCUT = '<!-~DEFAULT_NAME_SHORTCUT~-!>'

export const DEFAULT_STATUS_NAMES = {
  [ON_TRACK]: 'On track',
  [AT_RISK]: 'At risk',
  [BEHIND]: 'Behind',
  [ABANDONED]: 'Abandoned',
  [CLOSED]: 'Closed',
  [NOT_STARTED]: 'Not started'
}

const isStatusCanBeRenamed = ({ statusId }) => {
  return Object.keys(DEFAULT_STATUS_NAMES)
    .map(status => [status, Number(status)])
    .flat()
    .includes(statusId)
}

const createTranslationTemplate = ({ defaultTemplate = '', i18nContext = undefined }) => {
  if (!i18nContext) {
    throw new Error('i18nContext is required')
  }

  const { normalize, interpolate, named } = i18nContext

  const slotRegex = /(\{[^{}]*}|[^{}]+)/g
  const segments = defaultTemplate.split(slotRegex)

  const segmentRegex = /(\{[^{}]*})/

  const result = segments.map(segment => {
    if (segmentRegex.test(segment)) {
      const slotContent = segment.slice(1, -1)
      return interpolate(named(slotContent))
    } else {
      return segment
    }
  })

  return normalize(result)
}

class STATUS {
  defaultName
  translationKeys
  customerName

  constructor({ defaultName = '', translationKeys = [] }) {
    this[DEFAULT_NAME] = defaultName
    this[TRANSLATION_KEYS] = translationKeys
    this.updateTranslations(this[DEFAULT_NAME])
  }

  get isStatusRenamed() {
    return this[CUSTOMER_NAME] !== this[DEFAULT_NAME]
  }

  updateTranslations({ customerName = '' }) {
    let resolvedName = customerName || this[DEFAULT_NAME]

    if (this[CUSTOMER_NAME] !== resolvedName && !isEmpty(this[TRANSLATION_KEYS])) {
      resolvedName = resolvedName.replace(/[{}]/g, '').trim()
      this[CUSTOMER_NAME] = resolvedName

      i18n.global.mergeLocaleMessage('en', {
        ...this[TRANSLATION_KEYS].reduce((acc, { key, template }) => {
          return {
            ...acc,
            [key]: ctx => {
              return createTranslationTemplate({
                [DEFAULT_TEMPLATE]: template.replace(DEFAULT_NAME_SHORTCUT, resolvedName),
                [I18N_CONTEXT]: ctx
              })
            }
          }
        }, {})
      })
    }
  }

  restoreDefault() {
    this.updateTranslations(this[DEFAULT_NAME])
  }
}

export class STATUS_NAMES {
  #statuses

  constructor() {
    this.#statuses = reactive({
      [AT_RISK]: new STATUS({
        [DEFAULT_NAME]: DEFAULT_STATUS_NAMES[AT_RISK],
        [TRANSLATION_KEYS]: [
          {
            [KEY]: 'dashboard.at_risk',
            [TEMPLATE]: DEFAULT_NAME_SHORTCUT
          },
          {
            [KEY]: 'objectives.at_risk',
            [TEMPLATE]: `${DEFAULT_NAME_SHORTCUT}{after}`
          }
        ]
      }),

      [BEHIND]: new STATUS({
        [DEFAULT_NAME]: DEFAULT_STATUS_NAMES[BEHIND],
        [TRANSLATION_KEYS]: [
          {
            [KEY]: 'dashboard.behind',
            [TEMPLATE]: DEFAULT_NAME_SHORTCUT
          },
          {
            [KEY]: 'objectives.behind',
            [TEMPLATE]: `${DEFAULT_NAME_SHORTCUT}{after}`
          }
        ]
      }),

      [ON_TRACK]: new STATUS({
        [DEFAULT_NAME]: DEFAULT_STATUS_NAMES[ON_TRACK],
        [TRANSLATION_KEYS]: [
          {
            [KEY]: 'dashboard.on_track',
            [TEMPLATE]: DEFAULT_NAME_SHORTCUT
          },
          {
            [KEY]: 'objectives.on_track',
            [TEMPLATE]: `${DEFAULT_NAME_SHORTCUT}{after}`
          }
        ]
      }),

      [ABANDONED]: new STATUS({
        [DEFAULT_NAME]: DEFAULT_STATUS_NAMES[ABANDONED],
        [TRANSLATION_KEYS]: [
          {
            [KEY]: 'dashboard.abandoned',
            [TEMPLATE]: DEFAULT_NAME_SHORTCUT
          }
        ]
      }),

      [CLOSED]: new STATUS({
        [DEFAULT_NAME]: DEFAULT_STATUS_NAMES[CLOSED],
        [TRANSLATION_KEYS]: [
          {
            [KEY]: 'dashboard.closed',
            [TEMPLATE]: DEFAULT_NAME_SHORTCUT
          }
        ]
      }),

      [NOT_STARTED]: new STATUS({
        [DEFAULT_NAME]: DEFAULT_STATUS_NAMES[NOT_STARTED],
        [TRANSLATION_KEYS]: [
          {
            [KEY]: 'dashboard.not_started',
            [TEMPLATE]: DEFAULT_NAME_SHORTCUT
          }
        ]
      })
    })
  }

  get renamedState() {
    return Object.entries(this.#statuses).reduce((acc, [statusId, status]) => {
      return {
        ...acc,
        [statusId]: status.isStatusRenamed
      }
    }, {})
  }

  get statusProperties() {
    return Object.entries(this.#statuses).reduce((acc, [statusId, status]) => {
      return {
        ...acc,
        [statusId]: {
          [DEFAULT_NAME]: status[DEFAULT_NAME],
          [CUSTOMER_NAME]: status[CUSTOMER_NAME]
        }
      }
    }, {})
  }

  updateStatusTranslation({ statusId, customerName }) {
    if (!this.#statuses[statusId]) {
      throw new Error('Status not found')
    }
    this.#statuses[statusId].updateTranslations({ customerName })
  }

  bulkUpdateStatusTranslations(translations = {}) {
    for (const statusId in translations) {
      if (isStatusCanBeRenamed({ statusId })) {
        this.updateStatusTranslation({ statusId, [CUSTOMER_NAME]: translations[statusId] })
      }
    }
  }

  restoreDefault({ statusId }) {
    if (isStatusCanBeRenamed({ statusId })) {
      this.#statuses[statusId].restoreDefault()
    }
  }
}

export const ALL_STATUS_NAMES = new STATUS_NAMES()
