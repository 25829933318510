<template>
  <FormCaption
    :subtitle="t('schedule.start_from.subtitle')"
    :title="t('schedules.start_form.title')"
  >
    <FormFieldNext :label="t('common.on_entity')" inline>
      <DatePicker
        v-model="formModel[START_FROM]"
        :append-to-body="false"
        :clearable="false"
        :data-auto-testid="START_DATE_FIELD_TEST_ID"
        :data-testid="START_DATE_FIELD_TEST_ID"
        :editable="false"
        :format="DATE_FORMAT"
        :lang="datepickerLang()"
        :popup-style="{ left: 0 }"
        class="sfm-Datepicker"
        popup-class="dp-Datepicker"
        @update:model-value="onDateChange"
      >
        <template #input="{ class: className, value }">
          <div
            :class="{
              [className]: true
            }"
            class="sfm-Datepicker_Input"
          >
            {{ value }}
          </div>
        </template>
        <template #icon-calendar>
          <AppIcon height="24" icon-name="arrow-down-black" width="24" />
        </template>
      </DatePicker>
    </FormFieldNext>
  </FormCaption>
  <FormCaption :subtitle="t('schedule.frequency.subtitle')" :title="t('schedule.frequency.title')">
    <div class="sfm-FrequencyFields">
      <FormFieldNext :label="t('common.repeat')" inline>
        <AppSelect
          v-model="formModel[FREQUENCY_TYPE_ID]"
          :data-auto-testid="FREQUENCY_FIELD_TEST_ID"
          :data-testid="FREQUENCY_FIELD_TEST_ID"
          :item-label="SCHEDULE_ENTITY_KEYS.NAME"
          :item-value="SCHEDULE_ENTITY_KEYS.TYPE_ID"
          :options="Object.values(SCHEDULE_FREQUENCIES)"
          v-bind="SCHEDULE_SELECT_ATTRS"
        />
      </FormFieldNext>

      <FormFieldNext
        v-if="checkIsFieldAvailable(SCHEDULE_FREQUENCY_INNER_PARAMS_ENTITY_KEYS.REPEAT_INTERVAL)"
        :label="t('common.every')"
        inline
      >
        <AppInputNumberNext
          :data-auto-testid="REPEAT_INTERVAL_FIELD_TEST_ID"
          :data-testid="REPEAT_INTERVAL_FIELD_TEST_ID"
          :max="REPEAT_INTERVAL_MAX_VALUE"
          :min="REPEAT_INTERVAL_MIN_VALUE"
          :model-value="
            frequencyParamsModel[SCHEDULE_FREQUENCY_INNER_PARAMS_ENTITY_KEYS.REPEAT_INTERVAL]
          "
          class="sfm-Input"
          has-arrow-controls
          @update:model-value="onUpdateRepeatInterval"
        />

        <AppSelect
          v-if="checkIsFieldAvailable(SCHEDULE_FREQUENCY_INNER_PARAMS_ENTITY_KEYS.REPEAT_UNIT)"
          v-model="frequencyParamsModel[SCHEDULE_FREQUENCY_INNER_PARAMS_ENTITY_KEYS.REPEAT_UNIT]"
          :data-auto-testid="REPEAT_UNIT_FIELD_TEST_ID"
          :data-testid="REPEAT_UNIT_FIELD_TEST_ID"
          :options="getRepeatUnits"
          v-bind="SCHEDULE_SELECT_ATTRS"
        />
      </FormFieldNext>

      <FormFieldNext :label="t('common.on')" inline>
        <AppSelect
          v-if="checkIsFieldAvailable(SCHEDULE_FREQUENCY_INNER_PARAMS_ENTITY_KEYS.WEEKDAY)"
          v-model="frequencyParamsModel[SCHEDULE_FREQUENCY_INNER_PARAMS_ENTITY_KEYS.WEEKDAY]"
          :data-auto-testid="WEEKDAY_FIELD_TEST_ID"
          :data-testid="WEEKDAY_FIELD_TEST_ID"
          :item-label="SCHEDULE_ENTITY_KEYS.NAME"
          :options="SCHEDULE_DAYS_OF_WEEK"
          v-bind="SCHEDULE_SELECT_ATTRS"
        />

        <AppSelect
          v-if="checkIsFieldAvailable(SCHEDULE_FREQUENCY_INNER_PARAMS_ENTITY_KEYS.NTH_WEEKDAY)"
          v-model="frequencyParamsModel[SCHEDULE_FREQUENCY_INNER_PARAMS_ENTITY_KEYS.NTH_WEEKDAY]"
          :data-auto-testid="NTH_WEEKDAY_FIELD_TEST_ID"
          :data-testid="NTH_WEEKDAY_FIELD_TEST_ID"
          :options="getNthWeekdayOptions"
          v-bind="SCHEDULE_SELECT_ATTRS"
        />

        <WeekdayPicker
          v-if="checkIsFieldAvailable(SCHEDULE_FREQUENCY_INNER_PARAMS_ENTITY_KEYS.WEEKDAYS)"
          v-model="frequencyParamsModel[SCHEDULE_FREQUENCY_INNER_PARAMS_ENTITY_KEYS.WEEKDAYS]"
          :data-auto-testid="WEEKDAYS_FIELD_TEST_ID"
          :data-testid="WEEKDAYS_FIELD_TEST_ID"
          @update:model-value="onUpdatePickedWeekdays"
        />
      </FormFieldNext>

      <FormFieldNext :label="t('schedule.by_end_of_day')" inline />
    </div>
  </FormCaption>
</template>

<script setup>
import dayjs from 'dayjs'
import { clamp, isEmpty, isEqual, isUndefined } from 'lodash'
import { computed, onMounted, toValue, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import DatePicker from 'vue2-datepicker'

import { useScheduleDateCalculation } from '@/utils/check-ins/schedule-composables'
import {
  adjustDayIndex,
  SCHEDULE_DAYS_OF_WEEK,
  SCHEDULE_SELECT_ATTRS
} from '@/utils/check-ins/schedule-ui-helpers'
import {
  FREQUENCY_INNER_PARAMS,
  getScheduleTypeIdByFrequencyParams,
  isFieldAvailable,
  NTH_WEEKDAY_VALUES,
  REPEAT_INTERVAL_MAX_VALUE,
  REPEAT_INTERVAL_MIN_VALUE,
  REPEAT_UNITS,
  SCHEDULE_FREQUENCIES
} from '@/utils/check-ins/schedules-helpers'
import { datepickerLang, localDateToUtc } from '@/utils/date'
import {
  SCHEDULE_ENTITY_KEYS,
  SCHEDULE_FREQUENCY_INNER_PARAMS_ENTITY_KEYS
} from '@/utils/entity-keys'

import FormCaption from '@/components/check-ins/FormCaption'
import {
  FREQUENCY_FIELD_TEST_ID,
  NTH_WEEKDAY_FIELD_TEST_ID,
  REPEAT_INTERVAL_FIELD_TEST_ID,
  REPEAT_UNIT_FIELD_TEST_ID,
  START_DATE_FIELD_TEST_ID,
  WEEKDAY_FIELD_TEST_ID,
  WEEKDAYS_FIELD_TEST_ID
} from '@/components/check-ins/jest-helpers'
import WeekdayPicker from '@/components/check-ins/WeekdayPicker'
import FormFieldNext from '@/components/form/FormFieldNext'
import AppIcon from '@/components/ui/AppIcon/AppIcon'
import AppInputNumberNext from '@/components/ui/AppInputNumberNext/AppInputNumberNext'
import AppSelect from '@/components/ui/AppSelect/AppSelect'

defineOptions({
  name: 'ScheduleFrequencyManagement'
})

const { t } = useI18n()

const { START_FROM, FREQUENCY_TYPE_ID } = SCHEDULE_ENTITY_KEYS
const DATE_FORMAT = 'dddd, MMMM D, YYYY'

const getSelectedDayOfWeekNumber = computed(() => {
  const date = formModel.value[START_FROM]

  if (!date) {
    return null
  }

  return adjustDayIndex(dayjs(localDateToUtc(date)).day())
})

const formModel = defineModel('frequencyGeneralData', {
  type: Object,
  required: true
})

const frequencyParamsModel = defineModel('frequencyParams', {
  type: Object,
  required: true
})

const { selectedDateDayNumber, humanReadableRelativeDate } = useScheduleDateCalculation({
  formModel
})

const getNthWeekdayOptions = computed(() => {
  return [
    {
      value: NTH_WEEKDAY_VALUES.NTH_DAY,
      label: humanReadableRelativeDate.value.label
    },
    {
      value: NTH_WEEKDAY_VALUES.DAY_NUMBER,
      label: `${t('units.day', 1)} ${selectedDateDayNumber.value}`
    }
  ]
})

const getRepeatUnits = computed(() => {
  return [
    {
      value: REPEAT_UNITS.WEEK,
      label: t(
        'units.week',
        frequencyParamsModel.value[SCHEDULE_FREQUENCY_INNER_PARAMS_ENTITY_KEYS.REPEAT_INTERVAL]
      )
    },
    {
      value: REPEAT_UNITS.MONTH,
      label: t(
        'units.month',
        frequencyParamsModel.value[SCHEDULE_FREQUENCY_INNER_PARAMS_ENTITY_KEYS.REPEAT_INTERVAL]
      )
    }
  ]
})

const onUpdatePickedWeekdays = val => {
  if (isEmpty(val)) {
    setWeekDaysBasedOnSelectedDate()
  }
}

const setWeekDayBasedOnSelectedDate = () => {
  frequencyParamsModel.value[SCHEDULE_FREQUENCY_INNER_PARAMS_ENTITY_KEYS.WEEKDAY] =
    getSelectedDayOfWeekNumber.value
}

const setWeekDaysBasedOnSelectedDate = () => {
  frequencyParamsModel.value[SCHEDULE_FREQUENCY_INNER_PARAMS_ENTITY_KEYS.WEEKDAYS] = [
    getSelectedDayOfWeekNumber.value
  ]
}

const onDateChange = () => {
  setWeekDayBasedOnSelectedDate()
  setWeekDaysBasedOnSelectedDate()
}

const setParamsBasedOnSelectedDate = (source = frequencyParamsModel.value) => {
  const weekDay = source[SCHEDULE_FREQUENCY_INNER_PARAMS_ENTITY_KEYS.WEEKDAY]
  const weekDays = source[SCHEDULE_FREQUENCY_INNER_PARAMS_ENTITY_KEYS.WEEKDAYS]

  if (
    isEqual(weekDay, FREQUENCY_INNER_PARAMS[SCHEDULE_FREQUENCY_INNER_PARAMS_ENTITY_KEYS.WEEKDAY])
  ) {
    setWeekDayBasedOnSelectedDate()
  }

  if (
    isEqual(weekDays, FREQUENCY_INNER_PARAMS[SCHEDULE_FREQUENCY_INNER_PARAMS_ENTITY_KEYS.WEEKDAYS])
  ) {
    setWeekDaysBasedOnSelectedDate()
  }
}

onMounted(() => {
  setParamsBasedOnSelectedDate()
})

watch(
  () => frequencyParamsModel.value,
  (newValue, oldValue) => {
    if (isUndefined(oldValue) && newValue) {
      setParamsBasedOnSelectedDate(newValue)
    }
  },
  { immediate: true, deep: true }
)

const onUpdateRepeatInterval = val => {
  frequencyParamsModel.value[SCHEDULE_FREQUENCY_INNER_PARAMS_ENTITY_KEYS.REPEAT_INTERVAL] = clamp(
    Number(val),
    REPEAT_INTERVAL_MIN_VALUE,
    REPEAT_INTERVAL_MAX_VALUE
  )
}

const systemTypeId = computed(() => {
  return getScheduleTypeIdByFrequencyParams({
    currentTypeId: formModel.value[FREQUENCY_TYPE_ID],
    frequencyParams: frequencyParamsModel.value
  })
})

const checkIsFieldAvailable = field => {
  return isFieldAvailable({
    systemTypeId: toValue(systemTypeId),
    field
  })
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/interval-modal';

.sfm-Datepicker {
  @extend %interval-datepicker-styles;
  width: auto;
  // width: 100%;
  // max-width: 256px;
}

.sfm-FrequencyFields {
  display: flex;
  gap: 8px;
  --gap: 8px;
  align-items: center;
}

.sfm-Input {
  --font-size: #{$fs-14};
  width: 70px;
  --font-weight: #{fw('regular')};
}

.sfm-Datepicker_Input {
  display: flex;
  align-items: center;
  cursor: pointer;
}
</style>
