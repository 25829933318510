import ApiHandler from '@/api/base'

class WebAppAuthenticationApiHandler extends ApiHandler {
  async preLogout() {
    await this.http.post('/prelogout')
  }

  async postLogin({ token } = {}) {
    await this.http.post('/postlogin', null, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
  }
}

export default WebAppAuthenticationApiHandler
