<template>
  <div :class="{ 'oc-Wrapper-disabled': disabled }" class="oc-Wrapper">
    <div v-if="hasFullSizeToggle && chartDataReceived && !disabled" class="oc-FullSize">
      <AppButton icon="full-screen-next-on" type="primary-next" @click="toggleFullChartModal">
        {{ $t('objective.chart.fullSize') }}
      </AppButton>
    </div>
    <div class="oc-Content">
      <!-- :aspect-ratio="4.72" -->

      <LineChart
        v-if="chartDataReceived"
        :aspect-ratio="3.8"
        :border-color="borderColor"
        :chart-data="chartData"
        :chart-type="chartType"
        full-size-chart
        without-background
      />
      <LoadingCircle v-else min-height="62px" size="small" />
    </div>
  </div>

  <portal to="modal-windows">
    <Modal
      v-if="chartDataReceived && hasFullSizeToggle"
      :scrollable-content="false"
      :show="showFullChartModal"
      :title="$t('objective.chart.progress')"
      class="oc-FullSizeModal"
      manual-close
      size="lg-next"
      @close="toggleFullChartModal"
    >
      <ObjectiveChartWithTable
        v-if="showFullChartModal"
        :chart-type="$options.CHART_TYPES.detailedNext"
        :element-id="elementId"
        :workspace-id="workspaceId"
        hide-title
        is-linear
      />
    </Modal>
  </portal>
</template>

<script>
import { isEmpty } from 'lodash'
import { defineComponent } from 'vue'

import ObjectiveChartsApiHandler from '@/api/objective-charts'
import { useNormalizeChartLines } from '@/composables/objectiveChartComposable'
import { handleError } from '@/utils/error-handling'
import { memoizeGetCssVariableValue } from '@/utils/memoizations'
import { CHART_TYPES } from '@/utils/objective-chart'
import { getGradeColorVariable } from '@/utils/okr-elements/grade'

import LineChart from '@/components/LineChart/LineChart'
import ObjectiveChartWithTable from '@/components/objectives/chart/ObjectiveChartWithTable'
import AppButton from '@/components/ui/AppButton/AppButton'
import LoadingCircle from '@/components/ui/LoadingCircle/LoadingCircle'
import Modal from '@/components/ui/Modal/Modal'

export default defineComponent({
  name: 'ObjectiveChart',

  components: {
    Modal,
    LineChart,
    AppButton,
    ObjectiveChartWithTable,
    LoadingCircle
  },

  inheritAttrs: false,

  props: {
    disabled: {
      type: Boolean
    },

    chartType: {
      type: String,
      default: CHART_TYPES.simple,
      validator: v => Object.values(CHART_TYPES).includes(v)
    },

    elementId: {
      type: [String, Number],
      required: true
    },

    workspaceId: {
      type: [String, Number],
      required: true
    },

    hasFullSizeToggle: {
      type: Boolean
    },

    gradeColor: {
      type: String,
      default: null
    }
  },

  CHART_TYPES: { ...CHART_TYPES },

  data() {
    return {
      showFullChartModal: false,
      chartData: {}
    }
  },

  computed: {
    chartDataReceived() {
      return !isEmpty(this.chartData)
    },

    borderColor() {
      if (this.gradeColor) {
        const variable = getGradeColorVariable(this.gradeColor)
        return memoizeGetCssVariableValue(variable)
      } else {
        return null
      }
    }
  },

  created() {
    this.getChartData()
  },

  methods: {
    async getChartData() {
      const api = new ObjectiveChartsApiHandler()
      let result = {}
      try {
        result = await api.getChartDataForElement({
          elementId: this.elementId,
          workspaceId: this.workspaceId
        })
        result.chartLines = useNormalizeChartLines(result.chartLines)
        this.chartData = result
      } catch (error) {
        handleError({ error })
      }
    },

    toggleFullChartModal() {
      this.showFullChartModal = !this.showFullChartModal
    },

    /** @public */
    refreshData() {
      this.chartData = {}
      this.getChartData()
    }
  }
})
</script>

<style lang="scss" scoped>
.oc-FullSize {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // background: rgba($primary-color-next, 0.1);
  background-image: url(~@/assets/images/okr-chart-bg.jpg);
  transition: opacity $transition-normal;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;

  .oc-Wrapper:hover & {
    opacity: 1;
    pointer-events: all;
  }
}

.oc-FullSizeModal {
  :deep(.uim-TitleText) {
    margin: 0;
  }
}

.oc-Wrapper {
  position: relative;
  border-radius: $border-radius-md-next;
  border: 2px solid $grey-19;
  padding: 21px 0;
  overflow: hidden;

  &-disabled {
    background: $grey-3-next;
  }
}
</style>
