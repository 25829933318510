import dayjs from 'dayjs'
import { clamp, isEmpty, isNull } from 'lodash'
import { computed, ref, toValue } from 'vue'

import SchedulesApiHandler from '@/api/schedules'
import i18n from '@/i18n'
import { SCHEDULE_DAYS_OF_WEEK } from '@/utils/check-ins/schedule-ui-helpers'
import {
  isWeeklyOrBiWeeklySchedule,
  NTH_WEEKDAY_VALUES,
  parsePayloadToFormModel,
  REMINDER_EXECUTION_DAY_OPTIONS,
  REMINDER_TIME_OF_DAY_OPTIONS,
  REPEAT_UNITS,
  SCHEDULE_FREQUENCIES
} from '@/utils/check-ins/schedules-helpers'
import {
  DEFAULT_SCHEDULE_PARTICIPANTS,
  STRATEGY_OPTIONS
} from '@/utils/check-ins/schedules-participants'
import { localDateToUtc } from '@/utils/date'
import {
  DAYS_OF_WEEK_ENTITY_KEYS,
  REMINDER_ENTITY_KEYS,
  SCHEDULE_ENTITY_KEYS,
  SCHEDULE_FREQUENCY_INNER_PARAMS_ENTITY_KEYS,
  SCHEDULE_PARTICIPANTS_ENTITY_KEYS
} from '@/utils/entity-keys'
import { handleError } from '@/utils/error-handling'

const { NAME, FREQUENCY_TYPE_ID, START_FROM, TYPE_ID, PARAMS } = SCHEDULE_ENTITY_KEYS

const { t } = i18n.global

export const useScheduleDateCalculation = ({ formModel = {} }) => {
  const selectedDateDayNumber = computed(() => {
    const localModel = toValue(formModel)

    const date = localModel[START_FROM]

    if (!date) {
      return null
    }

    return dayjs(localDateToUtc(date)).date()
  })

  const humanReadableRelativeDate = computed(() => {
    const localModel = toValue(formModel)

    const date = localModel[START_FROM]

    if (!date) {
      return ''
    }

    const utcStartDate = dayjs(localDateToUtc(date))

    const monthNumberOfStartDate = utcStartDate.month()

    let nthDayNumber = 0
    let deltaDate = utcStartDate.clone()

    while (deltaDate.month() === monthNumberOfStartDate) {
      nthDayNumber++
      deltaDate = deltaDate.subtract(1, 'week')
    }

    const resolvedIndex = clamp(nthDayNumber, 1, 5) - 1

    const humanReadableNthDay = t(`schedule_day_in_month_ordinals[${resolvedIndex}]`)

    return {
      label: `${humanReadableNthDay} ${utcStartDate.format('dddd')}`,
      nthDayNumber
    }
  })

  return {
    selectedDateDayNumber,
    humanReadableRelativeDate
  }
}
export const useCheckinTableHelpers = ({ schedule = null } = {}) => {
  if (!schedule) throw new Error('Schedule is required')

  const UNKNOWN_LABEL = 'unknown'

  const { generalData, frequencyParamsData, remindersData } = parsePayloadToFormModel({
    payload: toValue(schedule)
  })

  const { humanReadableRelativeDate } = useScheduleDateCalculation({ formModel: generalData })

  let article = t('common.on')

  const getWeeklyDayName = () => {
    const dayParams = SCHEDULE_DAYS_OF_WEEK.find(day => {
      return (
        day[DAYS_OF_WEEK_ENTITY_KEYS.VALUE] ===
        frequencyParamsData[SCHEDULE_FREQUENCY_INNER_PARAMS_ENTITY_KEYS.WEEKDAY]
      )
    })

    return dayParams?.[DAYS_OF_WEEK_ENTITY_KEYS.DAY_NAME] || UNKNOWN_LABEL
  }

  const getMonthlyDayName = () => {
    if (
      frequencyParamsData[SCHEDULE_FREQUENCY_INNER_PARAMS_ENTITY_KEYS.NTH_WEEKDAY] ===
      NTH_WEEKDAY_VALUES.NTH_DAY
    ) {
      return humanReadableRelativeDate.value.label || UNKNOWN_LABEL
    } else {
      const dayNumber =
        schedule[PARAMS][SCHEDULE_FREQUENCY_INNER_PARAMS_ENTITY_KEYS.MONTH_DAY] || UNKNOWN_LABEL

      return `${t('units.day', 1)} ${dayNumber}`
    }
  }

  const getCustomDayName = () => {
    const keyPathByUnit = {
      [REPEAT_UNITS.WEEK]: 'units.week',
      [REPEAT_UNITS.MONTH]: 'units.month'
    }

    const keyPath =
      keyPathByUnit[frequencyParamsData[SCHEDULE_FREQUENCY_INNER_PARAMS_ENTITY_KEYS.REPEAT_UNIT]]

    const interval =
      frequencyParamsData[SCHEDULE_FREQUENCY_INNER_PARAMS_ENTITY_KEYS.REPEAT_INTERVAL]

    const unit = keyPath ? t(keyPath, interval) : UNKNOWN_LABEL

    return `${interval} ${unit}`
  }

  let dayName = ''

  if (isWeeklyOrBiWeeklySchedule(generalData[FREQUENCY_TYPE_ID])) {
    dayName = getWeeklyDayName()
  } else if (generalData[FREQUENCY_TYPE_ID] === SCHEDULE_FREQUENCIES.MONTHLY[TYPE_ID]) {
    dayName = getMonthlyDayName()
  } else {
    article = t('common.every')
    dayName = getCustomDayName()
  }

  const frequencyFullName = computed(() => {
    const frequencyName =
      Object.values(SCHEDULE_FREQUENCIES).find(freq => {
        return freq[TYPE_ID] === generalData[FREQUENCY_TYPE_ID]
      })?.[NAME] || UNKNOWN_LABEL

    return `${frequencyName}, ${article} ${dayName}`
  })

  const reminderFullName = computed(() => {
    const [firstReminder] = remindersData
    const { daysShift, timeInMinutes } = firstReminder

    const reminderExecutionDayName =
      Object.values(REMINDER_EXECUTION_DAY_OPTIONS).find(day => {
        return day[REMINDER_ENTITY_KEYS.VALUE] === daysShift
      })?.[REMINDER_ENTITY_KEYS.LABEL] || UNKNOWN_LABEL

    const timeOfDayName =
      REMINDER_TIME_OF_DAY_OPTIONS.find(time => {
        return time.value === timeInMinutes
      })?.label || UNKNOWN_LABEL

    return `${reminderExecutionDayName} ${t('common.at')} ${timeOfDayName}`
  })

  return { frequencyFullName, reminderFullName }
}

export const useFetchUsers = ({ selectedUsers = [] } = {}) => {
  const loading = ref(false)
  const api = new SchedulesApiHandler()

  const doFetch = async (searchString = null) => {
    try {
      loading.value = true
      return await api.getParticipantsUsers({
        searchString,
        accountIds: selectedUsers
      })
    } catch (error) {
      handleError({ error })
    } finally {
      loading.value = false
    }
  }

  return { loading, doFetch }
}

export const useFetchGroups = ({ selectedGroups = [] } = {}) => {
  const loading = ref(false)
  const api = new SchedulesApiHandler()

  const doFetch = async (searchString = null) => {
    try {
      loading.value = isNull(searchString)
      return await api.getParticipantsGroups({
        searchString,
        groupIds: selectedGroups
      })
    } catch (error) {
      handleError({ error })
    } finally {
      loading.value = false
    }
  }

  return { loading, doFetch }
}

export const useParticipantsValidation = ({ formModel = DEFAULT_SCHEDULE_PARTICIPANTS } = {}) => {
  const isAllUsersStrategy = computed(() => {
    const model = toValue(formModel)

    return (
      model[SCHEDULE_PARTICIPANTS_ENTITY_KEYS.STRATEGY_TYPE_ID] ===
      STRATEGY_OPTIONS.ALL_USERS[SCHEDULE_PARTICIPANTS_ENTITY_KEYS.VALUE]
    )
  })

  const validationErrors = ref({
    isSegmentsEmpty: false
  })

  const removeError = key => {
    validationErrors.value[key] = false
  }

  const resetValidationErrors = () => {
    Object.keys(validationErrors.value).forEach(removeError)
  }

  const validate = () => {
    const model = toValue(formModel)

    resetValidationErrors()

    if (isAllUsersStrategy.value) {
      return true
    } else {
      const { groupIds, userAccountIds } = model

      validationErrors.value.isSegmentsEmpty = isEmpty(groupIds) && isEmpty(userAccountIds)

      return isEmpty(Object.values(validationErrors.value).filter(Boolean))
    }
  }

  return {
    validate,
    removeError,
    validationErrors,
    resetValidationErrors
  }
}
