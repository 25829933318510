<template>
  <div class="jigas-Wrapper" data-auto-testid="form-grade-and-status">
    <OkrGradeValue :grade="grade" />

    <IndicatorPanel
      :background-color="resolvedJiraStatusColor.background"
      :color="resolvedJiraStatusColor.text"
      :indicator-width="grade"
      rounded-bar
      style="--height: 6px; --border-radius: 6px"
    />

    <AppTag
      :content="jiraStatus"
      :content-background="resolvedJiraStatusColor.background"
      :content-color="resolvedJiraStatusColor.text"
      class="jigas-Status"
    />
  </div>
</template>

<script setup>
import { computed } from 'vue'

import OkrGradeValue from '@/components/form/OkrGradeValue'
import AppTag from '@/components/ui/AppTag/AppTag'
import IndicatorPanel from '@/components/ui/IndicatorPanel/IndicatorPanel'

defineOptions({
  name: 'FormJiraIssueGradeAndStatus'
})

const props = defineProps({
  grade: {
    type: Number,
    required: true
  },

  jiraStatus: {
    type: String,
    default: 'To Do',
    required: true
  },

  jiraStatusColor: {
    type: Object,
    default: () => ({
      background: '',
      text: ''
    }),
    required: true
  }
})

const resolvedJiraStatusColor = computed(() => {
  return {
    background: props.jiraStatusColor?.background || '#dfe1e6',
    text: props.jiraStatusColor?.text || '#42526e'
  }
})
</script>

<style lang="scss" scoped>
.jigas-Wrapper {
  display: grid;
  gap: 6px;
}

.jigas-Status {
  margin-top: 2px;
  line-height: 24px;
  padding: 4px 10px;
}
</style>
