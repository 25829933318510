<template>
  <AppModalWithConfirmation
    ref="confirmReference"
    :confirm-close="areDataChanged"
    :show="show"
    :size="640"
    :title="t('users.add_to_workspace', usersToAdd.length)"
    class="aum-Modal"
    hide-hero
    @on-close="onClose(false)"
  >
    <div class="aum-Head">
      <div class="aum-Head_Cell aum-Head_CellWs">
        {{ userSelectLabel }}
      </div>
      <div class="aum-Head_Cell">
        {{ $t('field.workspace.title') }}
      </div>
      <div class="aum-Head_Cell">
        {{ $t('workspaces.role') }}
      </div>
    </div>
    <div ref="fieldSetsReference" class="aum-ModalBody">
      <div class="aum-Fields">
        <AddUsersFieldGroup
          v-for="item in usersToAdd"
          :key="item.id"
          v-model:users-to-add="usersToAdd"
          :item="item"
          :roles="roles"
          :show-clear-button="isShowClearButton"
          @remove-item="onRemoveItem"
          @add-workspace="onAddWorkspace"
          @remove-workspace="onRemoveWorkspace"
        />
      </div>
    </div>
    <template v-if="isShowAddButton" #footer-prepend>
      <AppTableCreateButton
        data-testid="add-another-user"
        icon-name="plus-next"
        no-hover
        no-padding
        @click="onAddItemClick"
      >
        {{ $t('users.add_another') }}
      </AppTableCreateButton>
    </template>

    <template #footer-actions>
      <AppButton type="ghost-next" @click="close">
        {{ $t('action.cancel') }}
      </AppButton>
      <AppButton
        :disable="isLoading"
        :loading="isLoading"
        data-testid="save-button"
        type="primary-next"
        @click="save"
      >
        {{ $t('action.add') }}
      </AppButton>
    </template>
  </AppModalWithConfirmation>
</template>

<script setup>
import { isEqual } from 'lodash'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import UserApiHandler from '@/api/user-details'
import { scrollSmooth } from '@/composables/visualEffects'
import {
  EVENT_SOURCES,
  MODE_NAMES_FOR_TRACKING,
  trackAddUserEvent
} from '@/tracking/amplitude-helpers'
import { REQUEST_ENTITY_KEYS } from '@/utils/entity-keys'
import { handleError } from '@/utils/error-handling'
import { WORKSPACE_USER_ROLES } from '@/utils/objectives'
import { uid } from '@/utils/uid'
import {
  createPayloadForAccountIdsAndEmails,
  useEmailsInSelect,
  USER_IDENTIFIER_VALID_KEY
} from '@/utils/web-app/emails-in-select'

import AppModalWithConfirmation from '@/components/AppModalWithConfirmation'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppTableCreateButton from '@/components/ui/AppTableCreateButton'
import AddUsersFieldGroup from '@/views/workspaces/settings/plugin-users/AddUsersFieldGroup'

defineOptions({
  name: 'AddUsersToWorkspaceModal'
})

defineProps({
  show: {
    type: Boolean
  },

  roles: {
    type: Array,
    required: true
  }
})

const { userSelectLabel } = useEmailsInSelect()

const fieldSetsReference = ref(null)
const emit = defineEmits(['update:show', 'reload-data'])
const { t } = useI18n()

const DEFAULT_USER_ITEM = {
  [REQUEST_ENTITY_KEYS.IDENTIFIER]: null,
  [USER_IDENTIFIER_VALID_KEY]: true
}
const DEFAULT_WS_ITEM = {
  workspaceId: null,
  groupIds: [],
  roleId: WORKSPACE_USER_ROLES.USER,
  workspaceValid: true
}

const usersToAdd = ref([
  {
    id: uid(),
    ...DEFAULT_USER_ITEM,
    workspaceIds: [{ ...DEFAULT_WS_ITEM, uid: uid() }]
  }
])

const areDataChanged = computed(() => {
  const isMoreThenOneUser = usersToAdd.value.length > 1
  if (isMoreThenOneUser) {
    return true
  } else {
    const { identifier, workspaceIds } = usersToAdd.value[0]
    const defaultItem = {
      [REQUEST_ENTITY_KEYS.IDENTIFIER]: DEFAULT_USER_ITEM[REQUEST_ENTITY_KEYS.IDENTIFIER],
      workspaceIds: [
        {
          workspaceId: DEFAULT_WS_ITEM.workspaceId,
          groupIds: DEFAULT_WS_ITEM.groupIds,
          roleId: DEFAULT_WS_ITEM.roleId
        }
      ]
    }
    const firstItem = {
      identifier,
      workspaceIds: workspaceIds.map(({ workspaceId, groupIds, roleId }) => ({
        workspaceId,
        groupIds,
        roleId
      }))
    }
    return !isEqual(defaultItem, firstItem)
  }
})
const onClose = (reloadData = false) => {
  usersToAdd.value = [
    {
      id: uid(),
      ...DEFAULT_USER_ITEM,
      workspaceIds: [{ ...DEFAULT_WS_ITEM, uid: uid() }]
    }
  ]
  groupsToAdd.value = {
    ...DEFAULT_GROUPS_TO_ADD
  }
  emit('update:show', false)
  if (reloadData) {
    emit('reload-data')
  }
}

const confirmReference = ref(null)
const close = () => {
  confirmReference.value.close()
}

const isLoading = ref(false)

const save = async () => {
  let isValid = true
  usersToAdd.value.forEach(item => {
    item[USER_IDENTIFIER_VALID_KEY] = !!item[REQUEST_ENTITY_KEYS.IDENTIFIER]
    if (!item[USER_IDENTIFIER_VALID_KEY]) {
      isValid = false
    }
    item.workspaceIds.forEach(workspace => {
      workspace.workspaceValid = !!workspace.workspaceId
      if (!workspace.workspaceValid) {
        isValid = false
      }
    })
  })

  if (isValid) {
    isLoading.value = true

    const api = new UserApiHandler()
    const payload = usersToAdd.value.reduce((acc, curr) => {
      const { identifier, workspaceIds } = curr

      const wsItems = workspaceIds.map(({ workspaceId, groupIds, roleId }) => ({
        identifier,
        workspaceId,
        groupIds,
        roleId
      }))
      return [...acc, ...wsItems]
    }, [])

    const { emails, accountIds } = createPayloadForAccountIdsAndEmails({
      selectedItems: payload
    })

    try {
      await api.addUserToWorkspaces([...emails, ...accountIds])
      isLoading.value = false

      trackAddUserEvent({
        mode: MODE_NAMES_FOR_TRACKING.ADD_TO_WORKSPACE,
        selectedUsers: usersToAdd.value,
        source: EVENT_SOURCES.GLOBAL_GROUPS_ALL_USERS_TAB
      })

      onClose(true)
    } catch (error) {
      handleError({ error })
      isLoading.value = false
    }
  }
}

const MAX_COUNT = 10
const MIN_COUNT = 1
const isShowClearButton = computed(() => {
  return usersToAdd.value.length > MIN_COUNT
})
const isShowAddButton = computed(() => {
  return usersToAdd.value.length < MAX_COUNT
})

const onAddItemClick = () => {
  usersToAdd.value.push({
    id: uid(),
    ...DEFAULT_USER_ITEM,
    workspaceIds: [{ ...DEFAULT_WS_ITEM, uid: uid() }]
  })

  scrollSmooth(fieldSetsReference)
}
const onRemoveItem = id => {
  usersToAdd.value = usersToAdd.value.filter(item => item.id !== id)
}
const onAddWorkspace = id => {
  const index = usersToAdd.value.findIndex(item => item.id === id)
  usersToAdd.value[index].workspaceIds.push({
    uid: uid(),
    ...DEFAULT_WS_ITEM
  })
}
const onRemoveWorkspace = ({ itemId, workspaceUid }) => {
  const itemIndex = usersToAdd.value.findIndex(item => item.id === itemId)
  usersToAdd.value[itemIndex].workspaceIds = usersToAdd.value[itemIndex].workspaceIds.filter(
    workspace => workspace.uid !== workspaceUid
  )
}

const DEFAULT_GROUPS_TO_ADD = {
  keepSynced: null,
  sourcePlatformGroupIds: [],
  targetPluginGroupIds: [],
  workspaceId: null,
  workspaceValid: true,
  sourcePlatformGroupsValid: true,
  targetPluginGroupsValid: true,
  keepSyncedValid: true
}
const groupsToAdd = ref({
  ...DEFAULT_GROUPS_TO_ADD
})
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';

.aum-ModalBody {
  overflow-y: auto;
  max-height: calc(
    100vh - (60px * 2) - 44px - 28px - 20px - 22px - 92px - 32px - 20px
  ); // where 60 * 2 is Y margin of modal; 44px is modal head height; 28px is modal title height; 20px is modal title margin bottom; 22px is fields head height; 92px is modal footer height; 32px is radioGroup height
  padding: 0 40px;
  @include styled-native-scrollbar();
}
.aum-Head {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 0 40px 6px;
}

.aum-Head_Cell {
  font-weight: fw('bold');
  font-size: $fs-12;
  line-height: 16px;
  color: $dark-3;
  &.aum-Head_CellWs {
    width: 200px;
  }
}
.aum-Fields {
  gap: 20px;
  display: flex;
  flex-direction: column;
}
</style>

<style lang="scss">
.aum-Modal {
  .ad-Content_Title {
    padding: 0 40px;
  }
  .o-modal-content {
    .o-modal-body {
      padding: 0;
    }
  }
  // --footer-padding-top: 0;
  --dialog-content-padding-right: 0;
  --dialog-content-padding-left: 0;
}
</style>
