<template>
  <div class="gnc-Cell" @click="$emit('click')">
    <AppIcon v-if="!hideIcon" class="gnc-Cell_Icon" height="24" icon-name="team-next" width="24" />

    <span class="gnc-Cell_Text" data-testid="name-value">
      <slot />
    </span>

    <span v-if="!hideCount" class="gnc-UserCount" data-testid="count-value">
      <slot name="count" />
    </span>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'GroupNameCell'
})
</script>

<script setup>
import AppIcon from '@/components/ui/AppIcon/AppIcon'

defineEmits({
  click: null
})

defineProps({
  hideIcon: {
    type: Boolean
  },

  hideCount: {
    type: Boolean
  }
})
</script>

<style lang="scss" scoped>
.gnc-Cell {
  display: flex;
  align-items: center;
  padding: 0 12px 0 0;
}

.gnc-Cell_Icon {
  min-width: 24px;
  margin-right: 8px;
}

.gnc-Cell_Text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: var(--label-weight, fw('semi-bold'));
  color: var(--label-color, #{$dark-1});
}

.gnc-UserCount {
  font-weight: fw('bold');
  font-size: $fs-12;
  line-height: 16px;
  color: $dark-3;
  background: $grey-2-next;
  border-radius: 12px;
  margin-left: 4px;
  padding: 2px 6px;
  height: 20px;
  text-align: center;
  min-width: 20px;
  flex: 0 0 auto;
}
</style>
