import ApiHandler from '@/api/base'

class ShortLinkApiHandler extends ApiHandler {
  async createShortLink({ link }) {
    const { data } = await this.http.post('/short-link', {
      link
    })
    return data
  }

  async getFullLink({ token }) {
    const { data } = await this.http.get('/short-link', {
      params: {
        token
      }
    })
    return data
  }
}

export default ShortLinkApiHandler
