import { isEqual } from 'lodash'

import i18n from '@/i18n'
import { ACTIONS_KEYS } from '@/utils/actions-keys'
import { DEFAULT_VALUE_FOR_FILTER, SELECT_ALL_VALUE } from '@/utils/okr-elements/filters'
import { getSelectWithSelectAllApiParameter } from '@/utils/select'

export const prepareFilterParams = params => {
  return Object.entries(params).reduce((acc, [key, value]) => {
    if (value) {
      acc[key] = getSelectWithSelectAllApiParameter(value)
    }
    return acc
  }, {})
}

export const getFirstName = fullName => {
  return fullName.split(' ')[0]
}

export const USER_MANAGEMENT_ACTIONS_DROPDOWN_MENU_ITEMS = [
  {
    name: ACTIONS_KEYS.ADD_TO_GROUP,
    title: 'action.add_to_group',
    icon: 'team-next',
    description: 'action.add_to_group.description',
    align: 'flex-start'
  },
  {
    name: ACTIONS_KEYS.ADD_TO_WORKSPACE,
    title: 'user_details.add_user',
    icon: 'workspace',
    description: 'user_details.add_user.description',
    align: 'flex-start'
  }
]

export const DEFAULT_GROUP_ITEM = {
  name: i18n.global.t('filter.all_groups'),
  id: SELECT_ALL_VALUE
}

export const isShowSyncIconCell = item => {
  return item.syncedInAnyGroup || item.groups.some(group => group.userSyncedInGroup)
}

export const checkFormModelOnDefaultValue = payload => {
  return Object.values(payload).some(value => !isEqual(value, DEFAULT_VALUE_FOR_FILTER))
}
