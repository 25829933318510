<template>
  <div class="psi-Item">
    <div class="psi-Item_Content">
      <GroupIcon
        v-if="userSelected"
        :color="item.color"
        :icon-name="item.icon"
        class="psi-Item_GroupIcon"
      />
      <AppAvatar
        v-else
        :avatar-url="item.avatarUrl"
        :data-aria-label="ariaLabel"
        class="psi-Item_Avatar"
        show-avatar-replacer
      />
      <div class="psi-Item_Name">
        {{ item.name }}
      </div>
      <AppButton
        class="psi-Item_Button"
        height="24"
        icon="okr-table-expand"
        remove-padding
        size="sm"
        type="ghost-next"
        width="24"
        @click="selectItem"
      />

      <div class="psi-GradeWrapper">
        <div class="psi-GradeRow">
          {{ item.objectivesCount }}
          {{ $t('homepage.objective_qty', item.objectivesCount) }}
          <OkrGrade
            :color="getGradeColorOptions(item.objectivesProgress).name"
            :grade="item.objectivesProgress"
            :type-id="item.typeId"
            :weight="null"
            :weight-multiplier="null"
            hide-weight-icon
            type="default-next"
          />
        </div>

        <div class="psi-GradeRow">
          {{ item.krsAndTasksCount }}
          {{ $t('homepage.key_result_qty', item.krsAndTasksCount) }}
          <OkrGrade
            :color="getGradeColorOptions(item.krsAndTasksProgress).name"
            :grade="item.krsAndTasksProgress"
            :type-id="item.typeId"
            :weight="null"
            :weight-multiplier="null"
            hide-weight-icon
            type="default-next"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapState } from 'vuex'

import { getGradeColorNameByValue, getGradeColorStyle } from '@/utils/okr-elements/grade'
import { THRESHOLDS_DEFAULT_VALUES } from '@/utils/thresholds'

import GroupIcon from '@/components/global-groups/GroupIcon'
import OkrGrade from '@/components/objectives/OkrGrade'
import AppAvatar from '@/components/ui/AppAvatar/AppAvatar'
import AppButton from '@/components/ui/AppButton/AppButton'

export default defineComponent({
  name: 'HomePageSummaryItem',
  components: { GroupIcon, AppAvatar, OkrGrade, AppButton },
  props: {
    item: {
      type: Object,
      required: true
    },

    userSelected: {
      type: Boolean
    },

    withoutGradeColoring: {
      type: Boolean
    }
  },

  emits: {
    'update-selected-user-or-group': null
  },

  computed: {
    ...mapState('system', {
      settings: state => state.settings
    }),

    ariaLabel() {
      return this.item.name
        .split(' ')
        .slice(0, 2)
        .map(name => name[0])
        .join('')
    }
  },

  methods: {
    selectItem() {
      this.$emit('update-selected-user-or-group', this.item.id)
    },

    getGradeColorOptions(value) {
      if (this.withoutGradeColoring)
        return {
          name: 'grade-default'
        }
      const {
        thresholdBehind = THRESHOLDS_DEFAULT_VALUES.BEHIND,
        thresholdOnTrack = THRESHOLDS_DEFAULT_VALUES.ON_TRACK
      } = this.settings || {}
      const name = getGradeColorNameByValue({
        value,
        thresholdBehind,
        thresholdOnTrack
      })
      return {
        name,
        color: getGradeColorStyle(name).color
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.psi-Item {
  display: grid;
  gap: 10px;
  box-shadow: inset 0 -1px 0 $grey-2-next;
}

.psi-Item_Content {
  display: flex;
  align-items: center;
  padding: 10px 270px 10px 0;
  max-width: 100%;
  overflow: hidden;
}

/* eslint-disable-next-line */
%text-style {
  font-style: normal;
  font-size: $fs-14;
  line-height: 20px;
  font-family: $system-ui;
  color: $dark-1;
}

.psi-Item_GroupIcon {
  color: $dark-2;
}

.psi-Item_Avatar {
  height: 24px;
  width: 24px;
  border-radius: 50%;
}
.psi-Item_GroupIcon,
.psi-Item_Avatar {
  flex: 0 0 24px;
}

.psi-Item_Name {
  font-weight: fw('semi-bold');
  @extend %text-style;
  margin-left: 8px;
  padding-right: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.psi-Item_Button {
  color: $primary-color-next;
  margin-right: auto;
}

.psi-GradeWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.psi-GradeRow {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-family: $system-ui;
  font-weight: fw('regular');
  font-size: $fs-12;
  line-height: 16px;
  text-align: right;
  color: $grey-1-next;
  gap: 20px;
  &:deep(.okg-OkrGrade_Value-next) {
    font-size: $fs-16;
  }
}
</style>
