<template>
  <AppRadioGroup
    :disabled="disabled"
    :model-value="modelValue"
    :name="name"
    :options="getOptions"
    style="--option-padding: 0 8px 0 0"
    type="primary-next"
    @update:model-value="onUpdateModelValue"
  >
    <template #item-label="{ item }">
      <AppRadioGroupNextOptionWithIcon :option="item" />
    </template>
  </AppRadioGroup>
</template>

<script setup>
import { isEmpty } from 'lodash'
import { computed } from 'vue'

import AppRadioGroup from '@/components/ui/AppRadioGroup/AppRadioGroup'
import AppRadioGroupNextOptionWithIcon from '@/components/ui/AppRadioGroup/AppRadioGroupNextOptionWithIcon'

defineOptions({
  name: 'AppVisibilitySwitch'
})

const VISIBILITY_OPTIONS = [
  {
    value: true,
    label: 'workspaces.public',
    icon: 'public-ws'
  },
  {
    value: false,
    label: 'workspaces.private',
    icon: 'lock-next'
  }
]

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true
  },

  disabled: {
    type: Boolean
  },

  options: {
    type: Array,
    default: () => []
  },

  name: {
    type: String,
    default: 'entity-visibility'
  }
})

const getOptions = computed(() => {
  return isEmpty(props.options) ? VISIBILITY_OPTIONS : props.options
})

const emit = defineEmits(['update:model-value'])
const onUpdateModelValue = value => emit('update:model-value', value)
</script>

<style scoped></style>
