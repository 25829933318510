<template>
  <div class="jai-Step">
    <div class="jai-Step_Header">
      <AppTitle :level="4" disable-margin>
        {{ getStepContent().title }}
      </AppTitle>
      <div class="jai-Step_Description">
        {{ getStepContent().description }}
      </div>
    </div>

    <template v-if="isSafariBrowser && !!integrationToken">
      <div :class="{ 'jai-Token-copied': isTokenCopied }" class="jai-Token">
        {{ integrationToken }}
      </div>

      <AppButton height="24" v-bind="copyTokenButtonProps" width="24" @click="onCopySafariToken">
        {{ copyTokenButtonProps.text }}
      </AppButton>
    </template>

    <AppButton
      v-else
      :loading="integrationTokenLoading"
      height="24"
      v-bind="copyTokenButtonProps"
      width="24"
      @click="onCopyToken"
    >
      {{ copyTokenButtonProps.text }}
    </AppButton>
  </div>

  <div class="jai-Step">
    <div class="jai-Step_Header">
      <AppTitle :level="4" disable-margin>
        {{ getStepContent({ stepNumber: 2 }).title }}
      </AppTitle>

      <i18n-t
        :keypath="getStepContent({ stepNumber: 2 }).description"
        class="jai-Step_Description"
        scope="global"
        tag="div"
      >
        <template #break>
          <br />
        </template>
      </i18n-t>
    </div>

    <AppButton height="24" icon="shortcut" type="primary-next" width="24" @click="onOpenWebApp">
      {{ `${t('action.open')} ${t('app.platform.web_app')}` }}
    </AppButton>
  </div>
</template>

<script setup>
import { computed, inject, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

import WebAppConnectionsApiHandler from '@/api/web-app-connections'
import { ROUTE_PATHS } from '@/routes/route-helpers'
import { handleError } from '@/utils/error-handling'
import { openLink } from '@/utils/external-link-helper'
import { copyToClipboard, isSafari } from '@/utils/general'
import { isJiraAppInjectionKey } from '@/utils/injection-keys'
import { PLUGIN_OPTIONS_KEYS } from '@root/template-options-keys'

import AppButton from '@/components/ui/AppButton/AppButton'
import AppTitle from '@/components/ui/AppTitle/AppTitle'

defineOptions({
  name: 'JiraAppIntegration'
})

const { t } = useI18n()

const copyTokenButtonProps = computed(() => {
  if (isSafariBrowser.value && !integrationToken.value && !isTokenCopied.value) {
    return {
      type: 'primary-next',
      icon: 'plus-next',
      text: t('connections.get_token')
    }
  }

  if (isTokenCopied.value) {
    return {
      type: 'success',
      icon: 'check-done',
      text: t('connections.copied')
    }
  }

  return {
    type: 'primary-next',
    icon: 'copy-standard',
    text: t('connections.copy_token')
  }
})

const isJiraApp = inject(isJiraAppInjectionKey)
const store = useStore()

const isWebAppConnected = computed(() => {
  return store.state.pluginOptions[PLUGIN_OPTIONS_KEYS.WEB_APP_CONNECTED]
})

const integrationToken = ref(null)
const integrationTokenLoading = ref(false)

const getIntegrationToken = async () => {
  if (isJiraApp && !isWebAppConnected.value) {
    integrationTokenLoading.value = true
    const api = new WebAppConnectionsApiHandler()
    try {
      return await api.getIntegrationToken()
    } catch (error) {
      handleError({ error })
    } finally {
      integrationTokenLoading.value = false
    }
  }
}

const isTokenCopied = ref(false)
const onCopyToken = async () => {
  if (!integrationToken.value) {
    integrationToken.value = await getIntegrationToken()
  }

  if (!isTokenCopied.value && integrationToken.value) {
    if (!isSafariBrowser.value) {
      copyToClipboard(integrationToken.value)
      isTokenCopied.value = true
    }
  }
}

const onCopySafariToken = () => {
  if (!isTokenCopied.value) {
    copyToClipboard(integrationToken.value)
    isTokenCopied.value = true
  }
}

const webAppUrl = computed(() => store.state.appContext.urls.webAppUrl)

const onOpenWebApp = () => {
  const fullPath = `${webAppUrl.value}/#${ROUTE_PATHS.WORKSPACES}${ROUTE_PATHS.SETTINGS}/${ROUTE_PATHS.SETTINGS_INTEGRATIONS}`
  openLink({
    url: fullPath
  })
}

const getStepContent = ({ stepNumber = 1 } = {}) => {
  const stepName = t('step_with_number', stepNumber)

  let secondPart = ''
  let description = ''

  if (stepNumber === 1) {
    secondPart = t('connections.copy_token')
    description = t('integration_settings.jira.step1.description')
  }

  if (stepNumber === 2) {
    secondPart = t('actions.chain', {
      firstAction: `${t('action.open')} ${t('app.platform.web_app')}`,
      secondAction: `${t('connections.paste_token')}`.toLowerCase()
    })
    description = 'integration_settings.jira.step2.description'
  }

  return {
    title: `${stepName} ${secondPart}`,
    description
  }
}

const isSafariBrowser = computed(() => {
  return isSafari()
})
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/integrations-steps';

.jai-Step {
  @extend %step-styles;
}

.jai-Step_Description {
  @extend %step-description-styles;
}

.jai-Step_Header {
  @extend %step-header-styles;
}

.jai-Token {
  border-radius: $border-radius-sm-next;
  padding: 10px;
  font-style: normal;
  font-weight: fw('regular');
  font-size: $fs-14;
  color: $dark-1;
  word-break: break-word;

  &:not(&-copied) {
    background-color: $grey-3-next;
  }

  &-copied {
    background-color: rgba($grade-high-color-next, 0.1);
  }
}
</style>
