import i18n from '@/i18n'
import { NOTIFICATION_CHANNELS } from '@/utils/okr-elements/notifications'

const generateWebhookItems = channels => {
  return Object.values(channels).flatMap(channel =>
    Object.entries(channel.attributes || {})
      .filter(([, attribute]) => attribute.isWebHook)
      .map(([attributeTypeId, attribute]) => ({
        typeId: channel.typeId,
        attributeTypeId,
        guideLink: channel.guideLink,
        title: channel.title,
        messengerName: (channel.title || 'unknown_messenger').toLowerCase(),
        icon: channel.icon || null,
        uid: attribute.uid || null,
        description: i18n.global.t('integration_settings.send_reminders_to_channels')
      }))
  )
}

export const getMessengersConnectionItems = () => {
  return generateWebhookItems(NOTIFICATION_CHANNELS)
}

const getAllowedMessengerParams = (paramKey = 'typeId') => {
  return Object.values(NOTIFICATION_CHANNELS)
    .filter(notification => {
      return Object.values(notification.attributes).some(attribute => attribute.isWebHook)
    })
    .map(notification => notification[paramKey])
}

export const ALLOWED_MESSENGERS_TYPE_IDS = getAllowedMessengerParams('typeId')

export const ALLOWED_MESSENGERS_NAMES = getAllowedMessengerParams('title').map(item =>
  item.toLowerCase()
)
