import { isEmpty, isNull } from 'lodash'

import ApiHandler from '@/api/base'
import { REQUEST_ENTITY_KEYS } from '@/utils/entity-keys'
import {
  DEFAULT_LIMIT,
  GLOBAL_GROUPS_SEARCH_TYPES,
  NO_PARENT_GROUP_ID
} from '@/utils/global-groups'
import { DEFAULT_USERS_LIMIT } from '@/utils/workspace-configuration-modal-helpers'

class GlobalGroupsApiHandler extends ApiHandler {
  async getGlobalGroups({
    workspaceIds = null,
    groupIds = null,
    limit = DEFAULT_LIMIT,
    startAt = 0,
    searchString = null,
    searchTypeId = GLOBAL_GROUPS_SEARCH_TYPES.BOTTOM_UP,
    expandAll = undefined
  } = {}) {
    const { data } = await this.http.get('/internal/v2/groups', {
      params: {
        workspaceIds,
        groupIds,
        searchTypeId,
        searchString,
        limit,
        startAt,
        expandAll
      }
    })
    return data
  }

  async getGlobalGroupById({ groupId = null } = {}) {
    const [data] = await this.getGlobalGroups({
      groupIds: [groupId],
      searchTypeId: GLOBAL_GROUPS_SEARCH_TYPES.PLAIN,
      limit: 1
    })

    return data
  }

  async getGlobalGroupsByIds({ groupIds = [] } = {}) {
    return await this.getGlobalGroups({
      groupIds,
      searchTypeId: GLOBAL_GROUPS_SEARCH_TYPES.PLAIN,
      limit: groupIds.length,
      startAt: 0
    })
  }

  async getGroupsForFilter({ searchString = null, groupIds = [], workspaceIds = [] } = {}) {
    const { data } = await this.http.get('/internal/v2/groupsForFilter', {
      params: {
        searchString,
        groupIds,
        workspaceIds,
        startAt: 0,
        limit: DEFAULT_LIMIT
      }
    })

    return data
  }

  async getParentGroups({ groupId = null, parentGroupId = null, searchString = null } = {}) {
    const { data } = await this.http.get('/internal/v2/parentGroups', {
      params: {
        groupId,
        parentGroupId,
        searchString,
        startAt: 0,
        limit: DEFAULT_LIMIT
      }
    })
    return data
  }

  async createGlobalGroup({
    addToAllWorkspaces = false,
    color = null,
    name = null,
    description = null,
    icon = null,
    parentId = NO_PARENT_GROUP_ID,
    platformGroupIds = null,
    syncWithPlatform = false,
    workspaceIds = null
  } = {}) {
    const { data } = await this.http.post('/internal/v2/group', {
      addToAllWorkspaces,
      color,
      name,
      description,
      icon,
      parentId,
      platformGroupIds,
      syncWithPlatform,
      workspaceIds
    })

    return data
  }

  async updateGlobalGroup({
    addToAllWorkspaces = false,
    color = null,
    icon = null,
    id = null,
    name = null,
    description = null,
    parentId = NO_PARENT_GROUP_ID,
    platformGroupIds = null,
    platformUserAccountIds = null,
    synced = null,
    workspaceIds = null
  } = {}) {
    const { data } = await this.http.put('/internal/v2/group', {
      addToAllWorkspaces,
      id,
      color,
      name,
      description,
      icon,
      parentId,
      platformGroupIds,
      workspaceIds,
      platformUserAccountIds,
      synced
    })
    return data
  }

  // async removeGlobalGroupFromWorkspace({ group = {}, workspaceIdForRemove = null } = {}) {
  //   if (!workspaceIdForRemove || isEmpty(group)) {
  //     throw new Error('workspaceIdForRemove or group is not defined')
  //   }
  //
  //   const newWorkspacesList = group.workspaces
  //     .map(({ id }) => id)
  //     .filter(id => id !== workspaceIdForRemove)
  //
  //   await this.updateGlobalGroup({
  //     id: group.id,
  //     addToAllWorkspaces: null,
  //     parentId: group.parentId,
  //     workspaceIds: newWorkspacesList
  //   })
  // }

  async removeGlobalGroupFromWorkspace({ group = {}, workspaceIdForRemove = null } = {}) {
    if (!workspaceIdForRemove || isEmpty(group)) {
      throw new Error('workspaceIdForRemove or group is not defined')
    }

    await this.http.delete('/internal/v2/group/removeFromWorkspace', {
      data: {
        groupId: group.id,
        workspaceId: workspaceIdForRemove
      }
    })
  }

  async getUsersFromGlobalGroup({
    groupId = null,
    limit = DEFAULT_USERS_LIMIT,
    startAt = 0,
    synced = false,
    accountIds = [],
    searchString = null
  } = {}) {
    const { data } = await this.http.get('/groupUsers', {
      params: {
        groupId,
        limit,
        startAt,
        synced,
        accountIds,
        searchString
      }
    })

    return data
  }

  async deleteGlobalGroup({ id = null, keepUsers = true } = {}) {
    await this.http.delete('/internal/v2/group', {
      data: {
        id,
        keepUsers
      }
    })
  }

  async addGlobalGroupsToWorkspace({ workspaceId = null, groupIds = [] } = {}) {
    await this.http.post('/internal/v2/addGroupsToWorkspaces', {
      workspaceIds: [workspaceId],
      groupIds
    })
  }

  async addUsersToGlobalGroups({ items = [] } = {}) {
    const { data } = await this.http.put('/users/addToGroups', {
      items
    })

    return data
  }

  async removeUsersFromGlobalGroup({ groupId = null, accountIds = [] } = {}) {
    await this.http.delete('/users/bulkRemoveFromGroup', {
      data: {
        groupId,
        accountIds
      }
    })
  }

  async removeUserFromGlobalGroup({ groupId = null, accountId = null } = {}) {
    await this.removeUsersFromGlobalGroup({
      groupId,
      accountIds: [accountId]
    })
  }

  async getUsersForAdditionToGlobalGroup({
    groupId = null,
    limit = DEFAULT_LIMIT,
    startAt = 0,
    searchString = null
  } = {}) {
    const { data } = await this.http.get('/internal/v2/users/getForGroupAddition', {
      params: {
        groupId,
        limit,
        startAt,
        searchString
      }
    })

    return data
  }

  async syncGlobalGroup({ groupId = null } = {}) {
    const { data } = await this.http.post('/group/sync', {
      groupId
    })
    return data
  }

  async unlinkPlatformGroupsFromGlobalGroup({
    groupId = null,
    platformGroupIds = [],
    keepUsers = true
  } = {}) {
    const { data } = await this.http.post('/group/unlinkPlatformGroups', {
      groupId,
      keepUsers,
      platformGroupIds
    })
    return data
  }

  async linkPlatformGroupsToGlobalGroup({ groupId = null, platformGroupIds = [] } = {}) {
    const { data } = await this.http.post('/group/linkPlatformGroups', {
      groupId,
      platformGroupIds
    })
    return data
  }

  async importUsersToGroup({ id = null, platformGroupIds = [] } = {}) {
    const { data } = await this.http.post('/internal/v2/importUsersToGroup', {
      groupId: id,
      platformGroupIds
    })
    return data
  }

  async getWorkspacesForManageGlobalGroup({
    searchString = null,
    workspaceIds = null,
    requiredWorkspaceIds = null
  } = {}) {
    const resolvedRequiredWorkspaceIds = isNull(searchString) ? requiredWorkspaceIds : null
    const { data } = await this.http.get('/getWorkspacesForGroupManage', {
      params: {
        searchString,
        workspaceIds,
        [REQUEST_ENTITY_KEYS.REQUIRED_WORKSPACE_IDS]: resolvedRequiredWorkspaceIds
      }
    })
    return data
  }

  async deleteUserFromGlobalGroup({ groupIds = null, accountId = null } = {}) {
    await this.http.delete('/internal/v2/deleteUserFromGroups', {
      data: {
        groupIds,
        accountId
      }
    })
  }

  async mergeGlobalGroups({
    groupIds = [],
    name = null,
    description = null,
    color = null,
    icon = null,
    parentId = NO_PARENT_GROUP_ID
  } = {}) {
    const { data } = await this.http.post('/internal/v2/group/merge', {
      groupIds,
      name,
      description,
      color,
      icon,
      parentId
    })

    return data
  }
}

export default GlobalGroupsApiHandler
