import ApiHandler from '@/api/base'

class JiraFieldsMappingHandler extends ApiHandler {
  async getFields() {
    const { data } = await this.http.get('/jira/fields')
    return data
  }

  async getProjects() {
    const { data } = await this.http.get('/jira/projects')
    return data
  }

  async getJiraFieldMappings() {
    const { data } = await this.http.get('/jiraFieldMappings')
    return data
  }

  async saveJiraFieldMappings({ mappings = [], syncDirection = undefined }) {
    const { data } = await this.http.post('/jiraFieldMappings', {
      mappings,
      syncDirection
    })
    return data
  }
}

export default JiraFieldsMappingHandler
