<template>
  <AppButton
    v-tippy="{
      content: buttonSettings.tooltip,
      placement: 'top'
    }"
    :loading="loading"
    class="ec-Button"
    loading-circle-color="var(--dark-2)"
    remove-padding
    size="sm"
    type="ghost-next"
    @click="handle"
  >
    <AppIcon v-if="!loading" :icon-name="buttonSettings.icon" height="24" width="24" />
  </AppButton>
</template>

<script setup>
import { ref, computed, nextTick } from 'vue'
import { useI18n } from 'vue-i18n'

import AppButton from '@/components/ui/AppButton/AppButton'
import AppIcon from '@/components/ui/AppIcon/AppIcon'

defineOptions({
  name: 'ExpandCollapseButton'
})

const props = defineProps({
  loading: {
    type: Boolean
  }
})

const isExpanded = ref(false)

const { t } = useI18n()

const buttonSettings = computed(() => ({
  icon: isExpanded.value ? 'table-collapse-next' : 'table-expand-next',
  tooltip: isExpanded.value ? t('filter.collapse_all') : t('filter.expand_all')
}))

const emit = defineEmits(['expand', 'collapse'])

const handle = () => {
  if (!props.loading) {
    nextTick(() => {
      const emitEvent = isExpanded.value ? 'collapse' : 'expand'
      emit(emitEvent)
      isExpanded.value = !isExpanded.value
    })
  }
}
</script>

<style lang="scss" scoped>
.ec-Button {
  min-width: 24px;
}
</style>
