<template>
  <SkeletonItem />
</template>

<script setup>
import { h } from 'vue'

import {
  SKELETON_APPEARANCES,
  SKELETON_SIZES
} from '@/utils/components-configurations/skeleton-item'

defineOptions({
  name: 'SkeletonItem'
})

const props = defineProps({
  size: {
    type: String,
    default: SKELETON_SIZES.MD,
    validator: v => Object.values(SKELETON_SIZES).includes(v)
  },

  appearance: {
    type: String,
    default: SKELETON_APPEARANCES.RECTANGLE,
    validator: v => Object.values(SKELETON_APPEARANCES).includes(v)
  },

  animated: {
    type: Boolean,
    default: true
  },

  color: {
    type: String,
    default: '#f4f5f7'
  },

  animationColor: {
    type: String,
    default: '255, 255, 255'
  },

  animationOpacity: {
    type: [String, Number],
    default: 0.5
  },

  width: {
    type: String,
    default: ''
  },

  height: {
    type: String,
    default: ''
  },

  borderRadius: {
    type: String,
    default: ''
  }
})

const SkeletonItem = () => {
  return h('div', {
    class: {
      'si-SkeletonItem': true,
      [`si-SkeletonItem-${props.appearance}`]: true,
      [`si-SkeletonItem-${props.size}`]: true,
      [`si-SkeletonItem-animated`]: props.animated
    },
    style: {
      '--skeleton-border-radius': props.borderRadius,
      '--skeleton-width': props.width,
      '--skeleton-height': props.height,
      '--animation-color': props.animationColor,
      '--animation-opacity': props.animationOpacity
    }
  })
}
</script>

<style lang="scss" scoped>
/* this eslint plugin won't work with vue 3 render function in <script setup> */
/* eslint-disable vue-scoped-css/no-unused-selector */
.si-SkeletonItem {
  background-color: v-bind(color);
  border-radius: var(--skeleton-border-radius, $border-radius-sm-next);
  position: relative;
  overflow: hidden;

  &-circle {
    border-radius: 50%;
  }

  &-xs {
    height: var(--skeleton-height, 16px);
  }

  &-xs#{&}-rectangle {
    width: var(--skeleton-width, 80px);
  }

  &-xs#{&}-circle,
  &-xs#{&}-square {
    width: var(--skeleton-width, 16px);
  }

  &-sm {
    height: var(--skeleton-height, 24px);
  }

  &-sm#{&}-rectangle {
    width: var(--skeleton-width, 100px);
  }

  &-sm#{&}-circle,
  &-sm#{&}-square {
    width: var(--skeleton-width, 24px);
  }

  &-md {
    height: var(--skeleton-height, 32px);
  }

  &-md#{&}-rectangle {
    width: var(--skeleton-width, 120px);
  }

  &-md#{&}-circle,
  &-md#{&}-square {
    width: var(--skeleton-width, 32px);
  }

  &-lg {
    height: var(--skeleton-height, 40px);
  }

  &-lg#{&}-rectangle {
    width: var(--skeleton-width, 140px);
  }

  &-lg#{&}-circle,
  &-lg#{&}-square {
    width: var(--skeleton-width, 40px);
  }

  &-xl {
    height: var(--skeleton-height, 48px);
  }

  &-xl#{&}-rectangle {
    width: var(--skeleton-width, 160px);
  }

  &-xl#{&}-circle,
  &-xl#{&}-square {
    width: var(--skeleton-width, 48px);
  }

  // rgba(var(--animation-color, #{$white}), 0.5),

  &-animated {
    &:after {
      animation: loading 1.5s infinite;
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      transform: translateX(-100%);
      z-index: 1;
      background: linear-gradient(
        90deg,
        transparent,
        rgba(var(--animation-color, #{$white}), var(--animation-opacity, 0.5)),
        transparent
      );
    }
  }
}
/* eslint-enable vue-scoped-css/no-unused-selector */
@keyframes loading {
  to {
    transform: translateX(100%);
  }
}
</style>
