<template>
  <div
    :class="{
      'si-SelectedItem-circle': showSelectedCountCircle,
      [`si-SelectedItem-${type}`]: true,
      'si-SelectedItem-without-delete-button': !showDeleteButton,
      'si-SelectedItem-disabled': disabled
    }"
    class="si-SelectedItem"
  >
    <span>
      <slot />
    </span>
    <button v-if="showDeleteButton" class="si-SelectedTagClear" @click.stop="$emit('delete')">
      <slot name="delete-button">
        <span class="si-ClearButtonIcon">
          <AppIcon height="6" icon-name="select-tag-clear" width="6" />
        </span>
      </slot>
      <AppIcon v-if="showSelectedCountCircle" height="6" icon-name="select-tag-clear" width="6" />
    </button>
    <button
      v-else-if="showSelectedCountCircle"
      class="si-SelectedTagClear"
      @click.stop="$emit('delete')"
    >
      <AppIcon v-if="showSelectedCountCircle" height="6" icon-name="select-tag-clear" width="6" />

      <slot name="delete-button" />
    </button>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

import { SELECT_TYPES } from '@/utils/components-configurations/app-select'

import AppIcon from '@/components/ui/AppIcon/AppIcon'

export default defineComponent({
  name: 'AppSelectItem',
  components: { AppIcon },
  props: {
    showSelectedCountCircle: {
      type: Boolean
    },

    showDeleteButton: {
      type: Boolean,
      default: true
    },

    type: {
      type: String,
      default: SELECT_TYPES.DEFAULT_NEXT,
      validator: v => Object.values(SELECT_TYPES).includes(v)
    },

    disabled: {
      type: Boolean
    }
  },

  emits: { delete: null }
})
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/selected-items-counter';

.si-SelectedItem {
  display: inline-block;
  background-color: rgba($primary-color-next, 0.1);
  border-radius: $border-radius-sm-next;
  padding-left: 6px;
  font-size: $fs-12;
  line-height: 16px;
  transition: color $transition-fast ease-in-out;
  color: $primary-color-next;
  font-family: inherit;
  cursor: pointer;

  &-default-next {
    min-height: 24px;
  }

  &-default-next#{&}-without-delete-button {
    padding-right: 6px;
  }

  &-default-next#{&}-disabled {
    opacity: var(--select-item-disabled-opacity, 1);
  }

  &.si-SelectedItem-secondary-next {
    padding-left: 0;
    background-color: transparent;
    color: inherit;
    font-size: $fs-14;
    line-height: 20px;
    font-weight: fw('semi-bold');
    .si-SelectedTagClear {
      @extend %selected-items-counter;
      background: $dark-3;
      font-weight: fw('bold');
      font-size: $fs-12;
      line-height: 16px;
      color: $white;

      @media (any-hover: hover) {
        &:hover {
          &:deep(.svg-icon) {
            color: $grade-low-color-next;
          }

          background-color: lighten($grade-low-color-next, 15%);
        }
      }
      // &-withoutHover {
      //   @media (any-hover: hover) {
      //     &:hover {
      //       color: $white;
      //       background: $dark-3;
      //     }
      //   }
      // }
    }
  }
  &.si-SelectedItem-secondary-next {
    font-size: $fs-14;
    line-height: 20px;
  }
  &-circle {
    padding-left: 0;
    background-color: transparent;
    color: inherit;
    font-size: $fs-16;
    line-height: 24px;
    font-weight: fw('regular');
  }
}

.si-ClearButtonIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: transparent;
  transition: $transition-fast ease-in-out;
}

.si-SelectedTagClear {
  color: $dark-grey;
  padding: 0;
  border: 0;
  background-color: transparent;
  height: 24px;
  width: 24px;
  flex: 0 0 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    // color: $grade-low-color-next;
    color: $white;
    .si-ClearButtonIcon {
      // background-color: lighten($grade-low-color-next, 20%);
      background-color: $primary-color-next;
    }
  }

  .si-SelectedItem-circle & {
    @extend %selected-items-counter;
    &:deep(.svg-icon) {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      pointer-events: none;
    }
    @media (any-hover: hover) {
      &:hover {
        &:deep(.svg-icon) {
          opacity: 1;
        }
        color: transparent;
        background-color: rgba($red-2, 0.5);
      }
    }
  }
}
</style>
