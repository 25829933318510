<template>
  <AppSelect
    ref="select"
    :dropdown-search="true"
    :model-value="activeFilter"
    :offset="[0, 0]"
    :options="filters"
    :search-function="searchFunction"
    append-to=".app-Aside"
    dropdown-width="220px"
    item-label="name"
    item-value="id"
    position="right-start"
    @update:model-value="selectFilter($event)"
  >
    <template #button="{ active, option }">
      <div
        :class="{
          'sfd-Trigger-active': active,
          'sfd-Trigger-exact-active': option && option.id === activeFilter
        }"
        class="sfd-Trigger"
      >
        <span class="oboard-truncated-text">
          <slot name="title">{{ $t('menu.show_other_views') }}</slot>
        </span>
        <AppIcon class="sfd-Trigger_Icon" height="24" icon-name="chevron-right-sm" width="24" />
      </div>
    </template>

    <template #option="props">
      <SavedFiltersDropDownListOption :placement="placement" v-bind="props" />
    </template>
  </AppSelect>
</template>

<script>
import { isEqual } from 'lodash'
import { defineComponent } from 'vue'
import { mapState } from 'vuex'

import { FILTER_PRESET_KEY } from '@/utils/query-parameters'

import { DASHBOARD, FILTERS, placementPropValidator } from '@/components/AppMenu/props-validators'
import SavedFiltersDropDownListOption from '@/components/AppMenu/SavedFiltersDropDownListOption'
import AppIcon from '@/components/ui/AppIcon/AppIcon'
import AppSelect from '@/components/ui/AppSelect/AppSelect'

export default defineComponent({
  name: 'SavedFiltersDropDown',
  components: {
    SavedFiltersDropDownListOption,
    AppSelect,
    AppIcon
  },

  props: {
    filters: {
      type: Array,
      required: true
    },

    placement: {
      type: String,
      default: FILTERS,
      validator: v => placementPropValidator(v)
    }
  },

  emits: {
    'select-filter': null
  },

  data() {
    return {
      searchString: null
    }
  },

  computed: {
    ...mapState('system', {
      menuShowed: state => state.appMenu.showed,
      menuPinned: state => state.appMenu.pinned
    }),

    activeFilter() {
      if (this.placement === FILTERS) {
        return Number(this.$route.query[FILTER_PRESET_KEY])
      }

      if (this.placement === DASHBOARD) {
        return Number(this.$route.params.id)
      }

      return null
    }
  },

  watch: {
    menuPinned(newValue) {
      if (!newValue) {
        this.$refs.select.hideDropdown()
      }
    },

    menuShowed(newValue) {
      if (!newValue) {
        this.$refs.select.hideDropdown()
      }
    },

    filters(newValue, oldValue) {
      if (!isEqual(newValue, oldValue) && !!this.searchString) {
        this.$refs.select.searchItems(this.searchString)
      }
    }
  },

  methods: {
    selectFilter(val) {
      this.$emit('select-filter', val)
    },

    searchFunction(searchString) {
      this.searchString = searchString
      const searchQuery = searchString.trim().toUpperCase()
      return this.filters.filter(item => {
        return item.name.toUpperCase().includes(searchQuery)
      })
    }
  }
})
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/app-menu';
@import '~@/assets/styles/mixins';

.sfd-List {
  max-height: 343px;
  overflow-y: auto;
  border-radius: $border-radius-md;
  @include styled-native-scrollbar();
}

.sfd-List_Search {
  padding: 1px 0;
}

.sfd-Trigger {
  @extend %nav-item-styles;
  // padding: 10px 10px 10px 50px; // where 50 is 10px padding + 24px icon + 16px gap
  padding: 4px 4px 4px 36px; // where 36px is 4px padding + 24px icon + 8px gap
  font-size: $fs-12;
  line-height: normal;
  font-style: normal;
  justify-content: space-between;
  display: flex;
  align-items: center;
  font-weight: fw('regular');

  @media (any-hover: hover) {
    &:hover {
      background-color: $grey-3-next;
      color: $primary-color-next;
    }
  }

  &-exact-active {
    color: $dark-2;
    background-color: rgba($primary-color-next, 0.15);

    &:hover {
      color: $dark-2;
      background-color: rgba($primary-color-next, 0.15);
    }
  }

  &-active {
    background-color: $grey-3-next;
    color: $primary-color-next;
  }
}

.sfd-NoOptions {
  padding: 10px 12px;
  text-align: center;
  color: $grey-7;
}

.sfd-Trigger_Icon {
  flex: 0 0 auto;
}
</style>
