import { cloneDeep, has, isEqual } from 'lodash'

import OkrFiltersApiHandler from '@/api/okr-filters'
import {
  ADD_NEW_FILTER,
  DELETE_FILTER,
  SET_SAVED_FILTERS,
  UPDATE_FILTER,
  UPDATE_SAVED_FILTERS_LOADED_STATUS
} from '@/store/mutation-types'
import {
  CUSTOM_FIELDS_FILTERS_QUERY_KEY,
  DEFAULT_CUSTOM_FIELD_FILTERS_VALUE
} from '@/utils/custom-fields/helpers'
import { UNSELECTED_DATE } from '@/utils/date'
import { handleError } from '@/utils/error-handling'
import { BACKEND_FILTER_IDS, FRONTEND_FILTER_NAMES_BY_ID } from '@/utils/okr-custom-filters'
import {
  DATES_KEYS,
  FILTER_DEFAULT_VALUES,
  FILTERS_KEYS,
  isTimeRangeSelectedInsteadOfInterval,
  isValidDateString
} from '@/utils/okr-elements/filters'
import { FILTER_PRESET_KEY } from '@/utils/query-parameters'

const getMissingFiltersWithDefaultValues = ({ filter }) => {
  // каждый раз добавляя новый фильтр, в приложении
  // например, когда давили фильтр по startDates или фильтр по lastGradeUpdateDates
  // у нас добавились id: 13, 14, 15, 16
  // а в ранее сохраненных фильтрах этих id не будет из-за чего мы получаем баг на таблице
  // когда сравниваем объект фильтров таблицы с объектом фильтров сохраненного фильтра
  // для вывода кнпоки 'clear'
  // таким образом получается что сохраненный фильтр не соотвестует фильтру таблицы
  // даже если мы не меняли никакие фильтры и эта кнопка выводится и НИЧЕГО не делает
  // так что теперь мы находим список отсутсвующих фильтров в сохраненном фильтре
  // и добавляем их в сохраненный фильтр с дефолтными значениями
  // в идее это должно помочь :)
  const missingFiltersIds = Object.values(BACKEND_FILTER_IDS).filter(id => !has(filter.filters, id))

  return missingFiltersIds.reduce((acc, id) => {
    const isCustomFieldFilter = id === BACKEND_FILTER_IDS[CUSTOM_FIELDS_FILTERS_QUERY_KEY]
    let resolvedValue = null
    if (isCustomFieldFilter) {
      resolvedValue = JSON.stringify(DEFAULT_CUSTOM_FIELD_FILTERS_VALUE)
    } else {
      const dateFiltersIds = Object.values(DATES_KEYS).map(item => BACKEND_FILTER_IDS[item])
      const isDateFilter = dateFiltersIds.includes(id)

      resolvedValue = isDateFilter
        ? JSON.stringify(UNSELECTED_DATE)
        : JSON.stringify(FILTER_DEFAULT_VALUES[FRONTEND_FILTER_NAMES_BY_ID[id]])
    }
    return {
      ...acc,
      [id]: resolvedValue
    }
  }, {})
}

const checkIsSavedFilterHasUnexpectedCustomFields = ({
  combinedFilters,
  availableCustomFieldsForWorkspace
}) => {
  const customFieldsSavedFilters = JSON.parse(
    combinedFilters[BACKEND_FILTER_IDS[CUSTOM_FIELDS_FILTERS_QUERY_KEY]]
  )

  // saved custom fields filters is array like where first element is id of custom field
  // and second element is array of selected values for this custom field
  // we need to get only ids of custom fields
  const customFieldsSavedFiltersIds = customFieldsSavedFilters.map(([id]) => id)

  return customFieldsSavedFiltersIds.some(id => !availableCustomFieldsForWorkspace.includes(id))
}

const addQueryToFilter = filter => {
  const { id, filters } = filter

  const normalizedSavedFilters = Object.fromEntries(
    Object.entries(filters).map(([key, val]) => {
      return [FRONTEND_FILTER_NAMES_BY_ID[key], JSON.parse(val)]
    })
  )

  const {
    dueDateFrom,
    dueDateTo,
    startDateFrom,
    startDateTo,
    lastGradeUpdateDateFrom,
    lastGradeUpdateDateTo,
    intervalIds
  } = normalizedSavedFilters

  const { isTimeRangeSelected } = isTimeRangeSelectedInsteadOfInterval({
    startDates: [startDateFrom, startDateTo],
    dueDates: [dueDateFrom, dueDateTo],
    intervals: intervalIds
  })

  if (isTimeRangeSelected) {
    normalizedSavedFilters[FILTERS_KEYS.INTERVAL_IDS] = cloneDeep(
      FILTER_DEFAULT_VALUES[FILTERS_KEYS.INTERVAL_IDS]
    )
    normalizedSavedFilters[FILTERS_KEYS.DUE_DATES] = [UNSELECTED_DATE, dueDateTo]
    normalizedSavedFilters[FILTERS_KEYS.START_DATES] = [startDateFrom, UNSELECTED_DATE]
  } else {
    if (
      dueDateFrom &&
      dueDateTo &&
      isValidDateString(dueDateFrom) &&
      isValidDateString(dueDateTo)
    ) {
      normalizedSavedFilters[FILTERS_KEYS.DUE_DATES] = [dueDateFrom, dueDateTo]
    } else {
      normalizedSavedFilters[FILTERS_KEYS.DUE_DATES] = FILTER_DEFAULT_VALUES[FILTERS_KEYS.DUE_DATES]
    }

    if (
      startDateFrom &&
      startDateTo &&
      isValidDateString(startDateFrom) &&
      isValidDateString(startDateTo)
    ) {
      normalizedSavedFilters[FILTERS_KEYS.START_DATES] = [startDateFrom, startDateTo]
    } else {
      normalizedSavedFilters[FILTERS_KEYS.START_DATES] =
        FILTER_DEFAULT_VALUES[FILTERS_KEYS.START_DATES]
    }
  }

  if (
    lastGradeUpdateDateFrom &&
    lastGradeUpdateDateTo &&
    isValidDateString(lastGradeUpdateDateFrom) &&
    isValidDateString(lastGradeUpdateDateTo)
  ) {
    normalizedSavedFilters[FILTERS_KEYS.LAST_GRADE_UPDATE_DATES] = [
      lastGradeUpdateDateFrom,
      lastGradeUpdateDateTo
    ]
  } else {
    normalizedSavedFilters[FILTERS_KEYS.LAST_GRADE_UPDATE_DATES] =
      FILTER_DEFAULT_VALUES[FILTERS_KEYS.LAST_GRADE_UPDATE_DATES]
  }

  const query = Object.entries(normalizedSavedFilters).reduce((acc, val) => {
    const [key, value] = val
    if ([...Object.values(FILTERS_KEYS), CUSTOM_FIELDS_FILTERS_QUERY_KEY].includes(key)) {
      acc[key] = JSON.stringify(value)
    }
    return acc
  }, {})

  return {
    ...filter,
    query: { [FILTER_PRESET_KEY]: `${id}`, ...query }
  }
}

const DEFAULT_SAVED_FILTERS_LIST = []

const state = {
  savedFilters: DEFAULT_SAVED_FILTERS_LIST,
  savedFiltersLoaded: false
}

const mutations = {
  [SET_SAVED_FILTERS](state, filters) {
    if (!isEqual(state.savedFilters, filters)) {
      state.savedFilters = filters
    }
  },

  [ADD_NEW_FILTER](state, newFilter) {
    state.savedFilters.push(newFilter)
  },

  [DELETE_FILTER](state, filterId) {
    state.savedFilters = state.savedFilters.filter(item => item.id !== filterId)
  },

  [UPDATE_FILTER](state, filter) {
    const index = state.savedFilters.findIndex(item => item.id === filter.id)
    state.savedFilters.splice(index, 1, filter)
  },

  [UPDATE_SAVED_FILTERS_LOADED_STATUS](state, status) {
    state.savedFiltersLoaded = status
  }
}

const actions = {
  async getSavedFilters({ commit, rootGetters }, { accountId, workspaceId }) {
    commit(SET_SAVED_FILTERS, DEFAULT_SAVED_FILTERS_LIST)
    const api = new OkrFiltersApiHandler()
    try {
      const data = await api.getFiltersForUser({
        accountId,
        workspaceId
      })

      const savedFilters = data.filterViews.map(filter => {
        const missingFiltersWithDefaultValues = getMissingFiltersWithDefaultValues({ filter })

        const combinedFilters = {
          ...filter.filters,
          ...missingFiltersWithDefaultValues
        }

        const availableCustomFieldsForWorkspace =
          rootGetters['customFields/columnsIdsByWorkspaceId'](workspaceId)

        return addQueryToFilter({
          ...filter,
          hasUnexpectedCustomFields: checkIsSavedFilterHasUnexpectedCustomFields({
            combinedFilters,
            availableCustomFieldsForWorkspace
          }),
          filters: combinedFilters
        })
      })

      commit(SET_SAVED_FILTERS, savedFilters)
      commit(UPDATE_SAVED_FILTERS_LOADED_STATUS, true)
    } catch (error) {
      handleError({ error })
    }
  },

  addNewFilter({ commit }, newFilter) {
    commit(ADD_NEW_FILTER, addQueryToFilter(newFilter))
  },

  deleteFilter({ commit }, filterId) {
    commit(DELETE_FILTER, filterId)
  },

  updateFilter({ commit, rootGetters, rootState }, filter) {
    const missingFiltersWithDefaultValues = getMissingFiltersWithDefaultValues({ filter })

    const combinedFilters = {
      ...filter.filters,
      ...missingFiltersWithDefaultValues
    }

    const workspaceId = rootState.workspaces.workspaceId

    const availableCustomFieldsForWorkspace =
      rootGetters['customFields/columnsIdsByWorkspaceId'](workspaceId)

    const updatedFilter = addQueryToFilter({
      ...filter,
      hasUnexpectedCustomFields: checkIsSavedFilterHasUnexpectedCustomFields({
        combinedFilters,
        availableCustomFieldsForWorkspace
      }),
      filters: combinedFilters
    })

    commit(UPDATE_FILTER, addQueryToFilter(updatedFilter))
  }
}

const getters = {
  currentSavedFilter: state => filterId => {
    return state.savedFilters.find(filter => filter.id === Number(filterId))
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
