<template>
  <div ref="groupReference" class="aufg-Group">
    <div class="aufg-FieldItem">
      <AppSelect
        ref="workspaceSelect"
        :disabled-items="existedWorkspacesIds"
        :hidden-items="hiddenItems"
        :is-error="!item.workspaceValid"
        :model-value="item.workspaceId"
        :offset="[0, -40]"
        :options="allWorkspaces"
        :search-function="getAllUserWorkspaces"
        boundary="scrollParent"
        data-testid="workspace-select"
        dropdown-search
        item-label="name"
        item-value="id"
        @opened="removeError"
        @update:model-value="onSelectWorkspace"
      >
        <template #option-label="{ option }">
          <WorkspaceSelectOption
            v-if="option"
            :show-badge="existedWorkspacesIds.includes(option.id)"
            :workspace="option"
          />
        </template>

        <template #button-content="{ option }">
          <WorkspaceSelectOption v-if="option" :workspace="option" hide-lock-icon />
          <template v-else>
            {{ $t('group.select_workspace') }}
          </template>
        </template>
      </AppSelect>

      <AppFieldError
        v-if="!item.workspaceValid"
        :show="!item.workspaceValid"
        class="aufg-Error"
        data-testid="workspace-field-error"
      >
        {{ $t('field.required') }}
      </AppFieldError>
    </div>

    <div v-if="!hideRoles" class="aufg-FieldItem">
      <AppSelect
        ref="rolesSelect"
        :dropdown-search="false"
        :inline-loader="false"
        :model-value="item.roleId"
        :offset="[0, 0]"
        :options="resolvedRoles"
        boundary="scrollParent"
        data-testid="role-select"
        item-label="label"
        item-value="value"
        @update:model-value="onSelectRole"
      />
    </div>

    <AppButton
      v-if="showClearButton"
      class="aufg-ClearButton"
      data-testid="remove-filed-group-button"
      icon="close-sm"
      size="sm"
      type="ghost"
      @click="onClearClick"
    />
  </div>
</template>

<script setup>
import { useIntersectionObserver } from '@vueuse/core'
import { cloneDeep } from 'lodash'
import { computed, ref, watch } from 'vue'
import { useStore } from 'vuex'

import WorkspacesApiHandler from '@/api/workspaces'
import { handleError } from '@/utils/error-handling'

import AppFieldError from '@/components/form/AppFieldError'
import AppButton from '@/components/ui/AppButton/AppButton'
// import AppIcon from '@/components/ui/AppIcon/AppIcon'
import AppSelect from '@/components/ui/AppSelect/AppSelect'
// import AppSelectListItem from '@/components/ui/AppSelect/AppSelectListItem'
import WorkspaceSelectOption from '@/components/ui/WorkspaceSelectOption/WorkspaceSelectOption'

defineOptions({
  name: 'AddUserFieldGroup'
})

const props = defineProps({
  item: {
    type: Object,
    required: true
  },

  showClearButton: {
    type: Boolean
  },

  existedWorkspacesIds: {
    type: Array,
    required: true
  },

  workspacesToAdd: {
    type: Array,
    required: true
  },

  roles: {
    type: Array,
    default: () => []
  },

  hideRoles: {
    type: Boolean
  }
})

const workspaceSelect = ref(null)
const rolesSelect = ref(null)

const emit = defineEmits(['remove-item', 'update:workspaces-to-add'])
const onClearClick = () => {
  emit('remove-item', props.item.id)
}

const sortWorkspaces = workspaces => {
  const clone = cloneDeep(workspaces)
  return clone.sort((a, b) => {
    return props.existedWorkspacesIds.includes(a.id) - props.existedWorkspacesIds.includes(b.id)
  })
}

const store = useStore()

const allWorkspaces = computed(() => {
  return sortWorkspaces(store.state.workspaces.workspaces)
})

const getAllUserWorkspaces = async (searchString = null) => {
  const api = new WorkspacesApiHandler()
  try {
    const workspaces = await api.getAllUserWorkspaces({ searchString })
    return sortWorkspaces(workspaces)
  } catch (error) {
    handleError({ error })
  }
}

const currentItemIndex = computed(() => {
  return props.workspacesToAdd.findIndex(item => item.id === props.item.id)
})

const getUpdatedList = newItem => {
  const updatedList = cloneDeep(props.workspacesToAdd)
  updatedList.splice(currentItemIndex.value, 1, newItem)
  return updatedList
}

const removeError = () => {
  const newItem = {
    ...props.item,
    workspaceValid: true
  }

  const updatedList = getUpdatedList(newItem)

  emit('update:workspaces-to-add', updatedList)
}
const onSelectWorkspace = workspaceId => {
  const newItem = {
    ...props.item,
    workspaceId,
    groupIds: []
  }

  const updatedList = getUpdatedList(newItem)

  emit('update:workspaces-to-add', updatedList)
}

const onSelectRole = roleId => {
  const newItem = {
    ...props.item,
    roleId
  }

  const updatedList = getUpdatedList(newItem)

  emit('update:workspaces-to-add', updatedList)
}

const hiddenItems = computed(() => {
  return props.workspacesToAdd
    .filter(item => item.id !== props.item.id)
    .map(item => item.workspaceId)
})

const resolvedRoles = computed(() => {
  return props.roles.filter(({ value }) => value)
})

const groupReference = ref(null)
const groupReferenceIsVisible = ref(false)

useIntersectionObserver(
  groupReference,
  ([{ isIntersecting }]) => {
    groupReferenceIsVisible.value = isIntersecting
  },
  { threshold: 1 }
)

watch(groupReferenceIsVisible, newValue => {
  if (!newValue) {
    workspaceSelect.value?.hideDropdown()
    rolesSelect.value?.hideDropdown()
  }
})
</script>

<style lang="scss" scoped>
$group-gap: 20px;
$items-count: var(--items-count, 2);

.aufg-Group {
  font-family: $system-ui;
  position: relative;
  flex: 1 0 auto;
  display: flex;
  align-items: flex-start;
  gap: $group-gap;
  width: var(--items-width, 420px);
}

.aufg-FieldItem {
  width: calc((100% - (#{$group-gap} * (#{$items-count} - 1))) / #{$items-count});
  position: relative;
  --gap: 0;
  --label-bottom-offset: 6px;
}

.aufg-ClearButton {
  position: absolute;
  right: -24px;
  top: 8px; // 40px - 24px / 2 where 40px is height of field and 24px is height of button
  color: $grey-1-next;
}

.aufg-Error {
  min-height: 20px;
  display: flex;
  align-items: center;
  // position: absolute;
  // bottom: -20px;
  // left: 0;
}
</style>
