<template>
  <transition :duration="DURATION" name="modal">
    <div
      v-if="show"
      :class="classes"
      :style="{
        '--size': isSizeInPixels ? `${size}px` : null
      }"
    >
      <div class="o-modal-backdrop" @click.self="onClose" />
      <div class="o-modal-content-wrapper">
        <div class="o-modal-content">
          <header v-if="!hideHeader" class="o-modal-header">
            <div :class="{ 'am-Header_Top-no-title': !title }" class="am-Header_Top">
              <slot name="title">
                <AppTitle v-if="title" :level="3" class="am-Header_TopTitle" disable-margin>
                  {{ title }}
                </AppTitle>
              </slot>

              <slot name="close-button">
                <AppButton
                  v-if="closable"
                  :icon="closeButtonIcon"
                  class="am-Close"
                  size="sm"
                  type="subtle"
                  @click="onClose"
                />
              </slot>
            </div>
            <AppTitle v-if="subtitle" :level="6" class="am-Header_Subtitle" disable-margin>
              {{ subtitle }}
            </AppTitle>
          </header>
          <div class="o-modal-body">
            <slot />
          </div>
          <div class="o-modal-footer">
            <slot name="footer" />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { isNumber } from 'lodash'
import { defineComponent } from 'vue'

import { APP_MODAL_SIZES } from '@/utils/components-configurations/app-modal'
import { modalSizePropValidator } from '@/utils/prop-validators'

import AppButton from '@/components/ui/AppButton/AppButton'
import AppTitle from '@/components/ui/AppTitle/AppTitle'

const DURATION = 200

export default defineComponent({
  name: 'AppModal',

  components: {
    AppTitle,
    AppButton
  },

  props: {
    title: {
      type: String,
      default: ''
    },

    subtitle: {
      type: String,
      default: ''
    },

    size: {
      type: [String, Number],
      default: APP_MODAL_SIZES.SM,
      validator: v => modalSizePropValidator(v)
    },

    show: {
      type: Boolean,
      required: true
    },

    closable: {
      type: Boolean,
      default: true
    },

    hideHeader: {
      type: Boolean
    },

    closeButtonIcon: {
      type: String,
      default: 'close'
    }
  },

  emits: { 'on-close': null },

  computed: {
    DURATION: () => DURATION,

    isSizeInPixels() {
      return isNumber(this.size)
    },

    classes() {
      return {
        'apm-AppModal': true,
        'o-modal': true,
        [`o-modal-${this.size}`]: !this.isSizeInPixels,
        'o-modal-custom-size': this.isSizeInPixels,
        'o-modal-visible': this.show
      }
    }
  },

  watch: {
    show: {
      handler(newValue) {
        if (newValue) {
          document.body.classList.add('overflow-hidden')
        } else {
          const stopInterval = intervalIdentifier => {
            clearInterval(intervalIdentifier)
          }
          this.$nextTick(() => {
            let counter = 0
            const closeTimer = setInterval(() => {
              // remove class if there are no more opened modals
              // doing this in interval because of transition and animation
              // and slow performance PC and mobile devices
              // so we can check this condition but some modal can be still exist in DOM
              // so we schedule this check for 2 times
              // and after second check or even if there are no opened modals
              // we remove class and stop interval
              const openedModals = document.querySelectorAll('.o-modal-visible')
              if (openedModals.length === 0) {
                stopInterval(closeTimer)
                document.body.classList.remove('overflow-hidden')
              }

              counter += 1

              if (counter === 2) {
                stopInterval(closeTimer)
              }
            }, DURATION * 2)

            // setTimeout(() => {
            //   stopInterval(closeTimer)
            // }, DURATION * 4)

            // setTimeout(() => {
            //   // remove class if there are no more opened modals
            //   const openedModals = document.querySelectorAll('.o-modal-visible')
            //   console.log(openedModals)
            //
            //   if (openedModals.length === 0) {
            //     document.body.classList.remove('overflow-hidden')
            //   }
            // }, DURATION * 2.5)
          })
        }
      }
    }
  },

  created() {
    if (this.show) {
      document.body.classList.add('overflow-hidden')
    }
  },

  unmounted() {
    // remove class if there are no more opened modals
    const openedModals = document.querySelectorAll('.o-modal-visible')
    if (openedModals.length === 0) {
      document.body.classList.remove('overflow-hidden')
    }
  },

  methods: {
    onClose() {
      if (this.closable) {
        this.$emit('on-close')
      }
    }
  }
})
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';

.o-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  // height: 100vh;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  // opacity: 0;
  // visibility: hidden;
  // will-change: opacity, visibility;
  // transition: opacity $transition-fast ease, visibility $transition-fast ease;

  &-visible {
    opacity: 1;
    visibility: visible;

    .o-modal-content {
      // transform: none;
    }
  }

  &-content-wrapper {
    overflow-y: auto;
    @include styled-native-scrollbar();
  }

  &-backdrop,
  &-content-wrapper {
    height: 100%;
    width: 100%;

    .o-modal-full-page & {
      height: auto;
      width: 100vw;
      background-color: $white;
    }
  }

  &-backdrop {
    background-color: rgba($dark-1, 0.8);
    position: fixed;
    left: 0;
    top: 0;
    backdrop-filter: blur(4px);
  }

  &-content {
    position: relative;
    // margin-top: 60px;
    margin: 60px auto;
    // display: flex;
    // flex-direction: column;

    background-color: $white;
    border-radius: $border-radius-md;
    box-shadow: 0 0 0 1px rgba($dark-1, 0.08), 0 2px 1px rgba($dark-1, 0.08),
      0 0 20px -6px rgba($dark-1, 0.31);

    text-align: left;
    // overflow: auto;
    // max-height: calc(100% - 120px);
    // transform: translateY(-20px);
    // transition: transform $transition-fast ease;
  }

  &-header {
    padding: 24px;
  }

  .am-Header_Top {
    display: flex;
    justify-content: space-between;
  }

  .am-Header_TopTitle {
    font-size: $fs-20;
    line-height: 28px;
    font-family: $system-ui;
  }

  .am-Header_Subtitle {
    color: $dark-grey;
    margin-top: 22px;
    font-size: $fs-12;
  }

  &-close {
    border: none;
    outline: none !important;
    cursor: pointer;
    padding: 0;
    background-color: transparent;

    svg {
      max-height: 100%;
      max-width: 100%;
      overflow: hidden;
      pointer-events: none;
      vertical-align: bottom;
    }
  }

  &-body {
    padding: 0 24px 0 24px;
  }
  &-footer {
    padding: 24px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    &:empty {
      display: none;
    }
  }
}

.am-Close {
  .am-Header_Top-no-title & {
    margin-left: auto;
  }
}

.o-modal-content {
  .o-modal-xs > .o-modal-content-wrapper > & {
    width: 320px;
  }
  .o-modal-sm > .o-modal-content-wrapper > & {
    width: 480px;
  }

  .o-modal-sm-next > .o-modal-content-wrapper > & {
    width: 540px;
  }

  .o-modal-md > .o-modal-content-wrapper > & {
    width: 600px;
  }

  .o-modal-md-next > .o-modal-content-wrapper > & {
    width: 720px;
  }

  .o-modal-lg > .o-modal-content-wrapper > & {
    width: 800px;
  }
  .o-modal-lg-next > .o-modal-content-wrapper > & {
    width: 820px;
  }

  .o-modal-xl > .o-modal-content-wrapper > & {
    width: 1000px;
  }

  .o-modal-custom-size > .o-modal-content-wrapper > & {
    width: var(--size, 1000px);
  }

  .o-modal-full-page > .o-modal-content-wrapper > & {
    width: 100vw;
    height: 100vh;
    border-radius: 0;
    box-shadow: none;
    margin: 0;
    padding: 0;
  }
}

.modal-enter-active .o-modal-content,
.modal-leave-active .o-modal-content {
  transition: $transition-fast;
}

.modal-enter-active.o-modal-full-page .o-modal-content-wrapper,
.modal-leave-active.o-modal-full-page .o-modal-content-wrapper {
  transition: $transition-fast;
}

.modal-enter-from:not(.o-modal-full-page) .o-modal-content,
.modal-leave-to:not(.o-modal-full-page) .o-modal-content {
  opacity: 0;
  transform: scale(1.05);
}

.modal-enter-from.o-modal-full-page .o-modal-content-wrapper,
.modal-leave-to.o-modal-full-page .o-modal-content-wrapper {
  opacity: 0;
  transform: scale(1.05);
}
</style>

<style lang="scss">
/* Temporary solution until forms will be not refactored */
.apm-AppModal .o-modal-body .o-input-label,
.apm-AppModal .o-modal-body .as-AppDroplistLabel {
  text-transform: none;
  color: $grey-semi-medium;
  font-weight: fw('semi-bold');
  font-style: normal;
  font-size: $fs-12;
  line-height: 14px;
  margin-bottom: 8px;
}

.o-modal-footer .o-modal-actions {
  margin-left: auto;
  display: flex;

  .ab-Button {
    margin-left: 8px;
    min-width: 74px;

    &:first-of-type {
      margin-left: 0;
    }
  }
}
</style>
