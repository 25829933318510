<template>
  <AppDialog
    :disable-confirm-button="isLoading"
    :show="show"
    :title="t('dashboard.share_dashboard')"
    hide-hero
    @on-confirm="onConfirm"
    @on-close="onClose"
  >
    <template #footer>
      <div class="scd-DialogFooterWrapper">
        <AppButton
          v-if="!isSalesforceApp"
          v-tippy="{
            content: $t('filter.copy_link'),
            placement: 'top'
          }"
          height="24"
          icon="social-share"
          remove-padding
          type="link-next"
          width="24"
          @click="onCopyLinkClick"
        >
          {{ t('breadcrumb.copy_link') }}
        </AppButton>

        <AppButton
          v-if="canEdit"
          :loading="isLoading"
          class="scd-ConfirmButton"
          data-testid="dialog-submit-button"
          type="primary-next"
          @click="onConfirm"
        >
          {{ t('action.done') }}
        </AppButton>
      </div>
    </template>

    <div class="scd-DialogWrapper">
      <AppRadioGroupCommon
        :disabled="!canEdit"
        :model-value="isPrivate"
        :options="getShareTypes"
        name="isPrivate"
        @update:model-value="changeShareType"
      />

      <div v-if="!isPrivate" class="scd-DescriptionPublicMode">
        <AppIcon class="scd-Icon" height="24" icon-name="info-next" width="24" />
        <i18n-t keypath="dashboard.share.public.description" scope="global">
          <template #break>
            <br />
          </template>
        </i18n-t>
      </div>
      <div v-else>
        <FormFieldNext v-if="canEdit" :label="t('dashboard.add_people')">
          <AppSelect
            :hidden-items="selectedUserIds"
            :model-value="tableUsersData"
            :offset="[0, -40]"
            :options="shareUserList"
            :placeholder="t('placeholder.search')"
            :search-function="getShareUsers"
            dropdown-search
            item-text="displayName"
            item-value="accountId"
            @update:options="shareUserList = $event"
            @update:model-value="onSelectUser"
          >
            <template #button-content="{ options }">
              <span v-if="isEmpty(options)" class="scd-DarkStateText">
                {{ t('admin.user') }}
              </span>
            </template>
            <template #option-label="{ option }">
              <OwnerFieldOption :option="option" label-key="displayName" />
            </template>
          </AppSelect>
        </FormFieldNext>

        <AppTable
          ref="tableUsersRef"
          :columns="tableColumns"
          :data="getTableUsersData"
          :hover-row="tableHoverRow"
          class="scd-UsersTable"
          no-border
          offset-left="40px"
          offset-right="40px"
          style="--head-padding-bottom: 0"
          type="primary-next"
        >
          <template #cell="{ columnKey, item, index }">
            <template v-if="columnKey === TABLE_COLUMNS_KEYS.NAME">
              <UserNameCell :item="item" item-label="displayName" />
            </template>

            <template v-else-if="columnKey === TABLE_COLUMNS_KEYS.ACTION">
              <span v-if="isOwner(item)" class="scd-DarkStateText">
                {{ $t('roles.owner') }}
              </span>
              <DropdownMenu
                v-else
                :items="itemMenuActions"
                :offset="[0, 0]"
                :type="DROPDOWN_MENU_TYPES.DEFAULT_NEXT"
                preferred-position="bottom-end"
                @close="tableHoverRow = -1"
                @open="tableHoverRow = index"
                @item-click="onMenuActionsClick($event, item)"
              >
                <template #activator>
                  <AppButton
                    icon-after="arrow-down-black-next"
                    size="sm"
                    type="ghost"
                    without-activity-state
                  >
                    <span class="scd-DarkStateText">
                      {{ $t('dropdown.viewer') }}
                    </span>
                  </AppButton>
                </template>
              </DropdownMenu>
            </template>
          </template>
        </AppTable>
      </div>
    </div>
  </AppDialog>
</template>

<script setup>
import { cloneDeep, differenceBy, isEmpty } from 'lodash'
import { computed, inject, nextTick, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'

import CustomDashboardApiHandler from '@/api/custom-dashboard'
import { scrollSmooth } from '@/composables/visualEffects'
import { ROUTE_PATHS } from '@/routes/route-helpers'
import { ACTIONS_KEYS } from '@/utils/actions-keys'
import { DROPDOWN_MENU_TYPES } from '@/utils/components-configurations/dropdown-menu'
import { USER_SHARING_ROLE } from '@/utils/custom-dashboard-helper'
import { handleError } from '@/utils/error-handling'
import { OBOARD_CLOUD_PLUGIN_ROUTE, removeUnnecessarySlashes } from '@/utils/external-link-helper'
import { copyToClipboard } from '@/utils/general'
import {
  isJiraAppInjectionKey,
  isSalesforceAppInjectionKey,
  isWebAppInjectionKey
} from '@/utils/injection-keys'
import { showNotify } from '@/utils/notify'
import { TABLE_COLUMNS_KEYS } from '@/utils/table-columns'
import { usePlansLimitations } from '@/utils/web-app/plans-limitations'
import { getAtlassianBaseUrl } from '@jira/util'
import { IS_DEVELOPMENT } from '@root/app-modes'

import AppDialog from '@/components/AppDialog'
import FormFieldNext from '@/components/form/FormFieldNext'
import OwnerFieldOption from '@/components/form/OwnerFieldOption'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppIcon from '@/components/ui/AppIcon/AppIcon'
import AppRadioGroupCommon from '@/components/ui/AppRadioGroup/AppRadioGroupCommon'
import AppSelect from '@/components/ui/AppSelect/AppSelect'
import DropdownMenu from '@/components/ui/DropdownMenu/DropdownMenu'
import AppTable from '@/components/ui/Table/AppTable'
import UserNameCell from '@/views/workspaces/settings/plugin-users/UserNameCell'

const props = defineProps({
  show: {
    type: Boolean
  },
  dashboardForShare: {
    type: Object,
    default: () => ({})
  },
  canEdit: {
    type: Boolean
  }
})

const { customDashboardPublicLimitations } = usePlansLimitations()
const isPrivate = ref(false)

const totalPublicDashboards = ref(0)
const getTotalDashboardCount = async () => {
  const customDashboardApi = new CustomDashboardApiHandler()
  try {
    const { count } = await customDashboardApi.getDashboardCount({ isPrivate: false })
    totalPublicDashboards.value = count
  } catch (error) {
    handleError({ error })
  }
}

watch(
  () => props.show,
  value => {
    if (value) {
      getTotalDashboardCount()
    }
  }
)

const isLimitExceeded = computed(() =>
  customDashboardPublicLimitations.value.isLimitExceeded({
    count: totalPublicDashboards.value
  })
)

const isDisablePublicButton = computed(() => {
  return isLimitExceeded.value && isPrivate.value
})

const getDisabledButtonTooltip = computed(() =>
  customDashboardPublicLimitations.value.getDisabledButtonTooltip()
)

const emit = defineEmits(['update:show', 'on-confirm'])
const isLoading = ref(false)

const onConfirm = async () => {
  try {
    isLoading.value = true
    const customDashboardApi = new CustomDashboardApiHandler()

    if (isPrivate.value) {
      const removedUsers = differenceBy(
        props.dashboardForShare.userShares,
        tableUsersData.value,
        'accountId'
      )
      const addedUsers = differenceBy(
        tableUsersData.value,
        props.dashboardForShare.userShares,
        'accountId'
      )

      const req = []

      await customDashboardApi.updateDashboard({ ...props.dashboardForShare, isPrivate: true })

      if (!isEmpty(addedUsers)) {
        req.push(
          customDashboardApi.addShareEntities({
            dashboardId: props.dashboardForShare.id,
            users: addedUsers.map(user => ({
              accountId: user.accountId,
              roleId: USER_SHARING_ROLE.VIEWER
            }))
          })
        )
      }
      if (!isEmpty(removedUsers)) {
        req.push(
          customDashboardApi.removeShareEntities({
            dashboardId: props.dashboardForShare.id,
            userShareIds: removedUsers.map(user => user.shareId)
          })
        )
      }

      if (!isEmpty(req)) {
        await Promise.all(req)
      }
    } else {
      await customDashboardApi.updateDashboard({ ...props.dashboardForShare, isPrivate: false })
    }
    showNotify({
      type: 'success'
    })
    onClose()
    emit('on-confirm')
  } catch (error) {
    handleError({ error })
  } finally {
    isLoading.value = false
  }
}

const onClose = () => {
  emit('update:show', false)
}

const { t } = useI18n()
const getShareTypes = computed(() => [
  {
    label: 'workspaces.public',
    value: false,
    icon: 'public-ws',
    disabled: isDisablePublicButton.value,
    tooltip: isDisablePublicButton.value && getDisabledButtonTooltip.value
  },
  {
    label: 'workspaces.private',
    value: true,
    icon: 'lock-next'
  }
])

const shareUserList = ref([])

watch(
  () => props.show,
  newValue => {
    if (newValue) {
      fetchShareUserList()
      isPrivate.value = props.dashboardForShare.private
      tableUsersData.value = cloneDeep(props.dashboardForShare.userShares)
    }
  }
)
const changeShareType = value => {
  isPrivate.value = value
}
const getShareUsers = async (searchString = null) => {
  const customDashboardApi = new CustomDashboardApiHandler()
  try {
    const { users } = await customDashboardApi.getShareCandidates({
      dashboardId: props.dashboardForShare.id,
      searchString
    })
    return users
  } catch (error) {
    handleError({ error })
  }
}

const fetchShareUserList = async () => {
  shareUserList.value = await getShareUsers()
}

const store = useStore()
const route = useRoute()

const isPluginServer = computed(() => store.getters['pluginOptions/isPluginServer'])
const workspaceId = computed(() => route.params.workspaceId)

const isJiraApp = inject(isJiraAppInjectionKey)
const isWebApp = inject(isWebAppInjectionKey)
const isSalesforceApp = inject(isSalesforceAppInjectionKey)

const onCopyLinkClick = () => {
  if (isSalesforceApp) {
    return
  }

  let url = ''

  if (isWebApp || isPluginServer.value || IS_DEVELOPMENT) {
    url = location.href
  } else if (isJiraApp) {
    url = [
      getAtlassianBaseUrl(),
      OBOARD_CLOUD_PLUGIN_ROUTE,
      ROUTE_PATHS.WORKSPACES,
      workspaceId.value,
      ROUTE_PATHS.DASHBOARD,
      ROUTE_PATHS.CUSTOM_DASHBOARD,
      ROUTE_PATHS.CUSTOM_DASHBOARD_ITEM,
      props.dashboardForShare.id
    ]
      .map(removeUnnecessarySlashes)
      .join('/')
  }

  if (url) {
    copyToClipboard(url)
    showNotify({ title: t('notifications.link_copied') })
  }
}
const tableColumns = [
  {
    title: '',
    key: TABLE_COLUMNS_KEYS.NAME,
    width: 'auto'
  },
  {
    title: '',
    key: TABLE_COLUMNS_KEYS.ACTION,
    width: 75
  }
]
const tableHoverRow = ref(-1)

const getTableUsersData = computed(() => [
  {
    accountId: props.dashboardForShare.ownerId,
    avatarUrl: props.dashboardForShare.ownerAvatarUrl,
    displayName: props.dashboardForShare.ownerName,
    roleId: USER_SHARING_ROLE.OWNER
  },
  ...tableUsersData.value
])
const tableUsersData = ref([])
const selectedUserIds = computed(() => [
  ...tableUsersData.value.map(item => item.accountId),
  props.dashboardForShare.ownerId
])

const tableUsersRef = ref(null)
const onSelectUser = async value => {
  await nextTick() // wait for tableUsersData.value update by update:options emit
  const user = shareUserList.value.find(item => item.accountId === value)
  tableUsersData.value.push({ ...user, displayName: user.displayName })

  await scrollSmooth({ value: tableUsersRef.value.$el.firstChild })
}

const itemMenuActions = computed(() => {
  return [
    // {
    //   name: ACTIONS_KEYS.VIEWER,
    //   title: 'dropdown.viewer',
    //   icon: 'eye',
    //   group: 'viewer'
    // },
    {
      name: ACTIONS_KEYS.REMOVE,
      title: 'action.delete',
      icon: 'delete-next',
      color: 'var(--grade-low-color-next)',
      group: 'remove'
    }
  ]
})

const onMenuActionsClick = (name, item) => {
  if (name === ACTIONS_KEYS.REMOVE) {
    tableUsersData.value = tableUsersData.value.filter(user => user.accountId !== item.accountId)
  }
}
const getOwner = computed(() => {
  const { ownerAvatarUrl, ownerId, ownerName } = props.dashboardForShare

  return {
    ownerAvatarUrl,
    ownerId,
    ownerName
  }
})

const isOwner = item => {
  return getOwner.value.ownerId === item.accountId
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';

.scd-DescriptionPublicMode {
  display: flex;
  gap: 8px;
  color: $dark-3;
  font-size: $fs-14;
  line-height: 20px;
}
.scd-Icon {
  display: flex;
  min-width: 24px;
  height: 24px;
}
.scd-DialogWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  &:deep(.tb-Header) {
    &:after {
      display: none;
    }
  }
}
.scd-DialogFooterWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.scd-ConfirmButton {
  margin-left: auto;
}

.scd-DarkStateText {
  color: $dark-3;
}

.scd-UsersTable {
  margin: 20px 0 0 0;
  &:deep(.tb-Table) {
    max-height: 225px;
    overflow: auto;
    position: relative;
    @include styled-native-scrollbar();
  }
}
</style>
