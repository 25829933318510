import { isNull, isUndefined } from 'lodash'

import i18n from '@/i18n'
import { ALL_CUSTOM_FIELDS } from '@/utils/custom-fields/factory'
import { OKR_BULK_UPDATE_ENTITY_KEYS } from '@/utils/entity-keys'

export const MAX_SELECTED_ELEMENTS_FOR_BULK_ACTIONS = 50

export const CORE_FIELDS_IDS = {
  LABELS: 1,
  GROUPS: 2,
  STAKEHOLDERS: 3,
  OWNER: 4,
  INTERVAL: 5,
  CONTRIBUTE: 6,
  OKR_LEVELS: 7
}

export const CORE_FIELD_NAMES = {
  [CORE_FIELDS_IDS.LABELS]: 'field.labels.title',
  [CORE_FIELDS_IDS.GROUPS]: 'field.groups.title',
  [CORE_FIELDS_IDS.STAKEHOLDERS]: 'field.assignee.stakeholders',
  [CORE_FIELDS_IDS.OWNER]: 'objectives.table_header_assignee',
  [CORE_FIELDS_IDS.INTERVAL]: 'field.quoter.title',
  [CORE_FIELDS_IDS.CONTRIBUTE]: 'objectives.table_header_contribute',
  [CORE_FIELDS_IDS.OKR_LEVELS]: 'levels.header_title'
}

export const BULK_FIELD_OPERATION_TYPE_IDS = {
  KEEP_AS_IS: 1000,
  ADD: 1,
  REPLACE: 2,
  CLEAR: 3
}

export const BULK_FIELD_OPERATION_NAMES = {
  [BULK_FIELD_OPERATION_TYPE_IDS.KEEP_AS_IS]: 'bulk_update.keep_as_is',
  [BULK_FIELD_OPERATION_TYPE_IDS.REPLACE]: 'bulk_update.replace_all',
  [BULK_FIELD_OPERATION_TYPE_IDS.ADD]: 'bulk_update.add_new',
  [BULK_FIELD_OPERATION_TYPE_IDS.CLEAR]: 'filter.clear_all'
}

const { LABELS, GROUPS, STAKEHOLDERS } = CORE_FIELDS_IDS
const { MULTI_SELECT, DATE, ASSIGNEE, NUMBER, MONEY } = ALL_CUSTOM_FIELDS.getTypeIds()

const ALLOWED_BULK_OPERATIONS = {
  [BULK_FIELD_OPERATION_TYPE_IDS.KEEP_AS_IS]: {
    ALLOWED_CORE_FIELDS: [LABELS, GROUPS, STAKEHOLDERS],
    ALLOWED_CUSTOM_FIELDS: [MULTI_SELECT, DATE, ASSIGNEE, NUMBER, MONEY]
  },
  [BULK_FIELD_OPERATION_TYPE_IDS.REPLACE]: {
    ALLOWED_CORE_FIELDS: [LABELS, GROUPS, STAKEHOLDERS],
    ALLOWED_CUSTOM_FIELDS: [MULTI_SELECT, DATE, ASSIGNEE, NUMBER, MONEY]
  },
  [BULK_FIELD_OPERATION_TYPE_IDS.ADD]: {
    ALLOWED_CORE_FIELDS: [LABELS, GROUPS, STAKEHOLDERS],
    ALLOWED_CUSTOM_FIELDS: [MULTI_SELECT, ASSIGNEE]
  },
  [BULK_FIELD_OPERATION_TYPE_IDS.CLEAR]: {
    ALLOWED_CORE_FIELDS: [LABELS, GROUPS, STAKEHOLDERS],
    ALLOWED_CUSTOM_FIELDS: [MULTI_SELECT, DATE, ASSIGNEE, NUMBER, MONEY]
  }
}

const createAllowedOperationsListForField = ({ fieldId, isCustom = false } = {}) => {
  return Object.entries(ALLOWED_BULK_OPERATIONS)
    .map(([operationTypeId, settings]) => {
      if (!fieldId) {
        return null
      }
      const targetFields = isCustom ? settings.ALLOWED_CUSTOM_FIELDS : settings.ALLOWED_CORE_FIELDS
      return targetFields.includes(fieldId) ? Number(operationTypeId) : null
    })
    .filter(Boolean)
    .toSorted(operationId => {
      return operationId === BULK_FIELD_OPERATION_TYPE_IDS.KEEP_AS_IS ? -1 : 1
    })
}

const createAllowedOperationsList = ({ fieldIds = [], isCustom = false } = {}) => {
  return fieldIds.reduce((acc, fieldId) => {
    return {
      ...acc,
      [fieldId]: createAllowedOperationsListForField({ fieldId, isCustom })
    }
  }, {})
}

export const CORE_FIELD_ALLOWED_BULK_OPERATIONS = createAllowedOperationsList({
  fieldIds: [LABELS, GROUPS, STAKEHOLDERS]
})

export const CUSTOM_FIELD_ALLOWED_BULK_OPERATIONS = createAllowedOperationsList({
  fieldIds: [MULTI_SELECT, DATE, ASSIGNEE, NUMBER, MONEY],
  isCustom: true
})

export const createKeepAsIsOption = ({ valueKey = 'value', labelKey = 'label' } = {}) => {
  return {
    [valueKey]: BULK_FIELD_OPERATION_TYPE_IDS.KEEP_AS_IS,
    [OKR_BULK_UPDATE_ENTITY_KEYS.OPERATION]: BULK_FIELD_OPERATION_TYPE_IDS.KEEP_AS_IS,
    [labelKey]: i18n.global.t('bulk_update.keep_as_is')
  }
}

export const getOkrBulkFieldModelValueGetter = ({ modelValue = {} } = {}) => {
  const { value } = modelValue

  return isNull(value) ? BULK_FIELD_OPERATION_TYPE_IDS.KEEP_AS_IS : value
}

export const getOkrBulkFiledModelValueEmitPayload = ({
  newValue = undefined,
  options = [],
  valueKey = 'value'
} = {}) => {
  if (isUndefined(newValue)) {
    throw new Error('newValue is required')
  }

  if (newValue === BULK_FIELD_OPERATION_TYPE_IDS.KEEP_AS_IS) {
    return { [OKR_BULK_UPDATE_ENTITY_KEYS.OPERATION]: newValue }
  }

  const operation =
    options.find(option => option[valueKey] === newValue)?.[
      OKR_BULK_UPDATE_ENTITY_KEYS.OPERATION
    ] || BULK_FIELD_OPERATION_TYPE_IDS.KEEP_AS_IS

  return {
    operation,
    value: newValue
  }
}

export const getMaxDropdownHeight = ({
  optionsToShow = 5,
  withKeepAsIsOption = true,
  splitFirstOption = true
} = {}) => {
  const baseOptionHeight = 44

  const keepAsIsOptionHeight = withKeepAsIsOption ? baseOptionHeight : 0

  return baseOptionHeight * optionsToShow + keepAsIsOptionHeight + (splitFirstOption ? 9 : 0)
}
