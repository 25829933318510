import ApiHandler from '@/api/base'
import { DEFAULT_LIMIT } from '@/utils/global-groups'

class AuditLogsApiHandler extends ApiHandler {
  async getGroupsAuditLog({ startAt = 0, limit = DEFAULT_LIMIT } = {}) {
    const { data } = await this.http.get('/getGroupHistory', {
      params: {
        limit,
        startAt
      }
    })

    return data
  }

  async getElementsAuditLog({ typeIds = null, startAt = 0, limit = DEFAULT_LIMIT } = {}) {
    const { data } = await this.http.get('/getElementHistory', {
      params: {
        typeIds,
        limit,
        startAt
      }
    })
    return data
  }
}

export default AuditLogsApiHandler
