<template>
  <Modal
    :data-testid="$attrs['data-testid'] || null"
    :scrollable-content="false"
    :show="opened"
    :style="{ '--modal-content-bottom-padding': modalContentBottomPadding }"
    class="scm-Modal"
    manual-close
    size="lg-next"
    @close="onClose"
    @after-enter="onAfterEnter"
    @before-enter="onBeforeEnter"
  >
    <template #header>
      <div class="scm-TitleWrapper">
        <SkeletonItem
          v-if="isScheduleDataLoading || !frequencyGeneralData"
          height="28px"
          width="100%"
        />

        <template v-else>
          <AppInput
            ref="nameInput"
            v-model="frequencyGeneralData[NAME]"
            :data-auto-testid="NAME_FIELD_TEST_ID"
            :data-testid="NAME_FIELD_TEST_ID"
            :is-error="!isNameValid"
            :max-length="SCHEDULE_NAME_MAX_LENGTH"
            :placeholder="t('schedule.name.placeholder')"
            class="scm-Title"
            show-error-for-placeholder
            style-type="title"
            @focus="isNameValid = true"
          />
          <AppFieldError v-if="!isNameValid" :show="!isNameValid" class="scm-TitleError">
            {{ t('field.required') }}
          </AppFieldError>
        </template>
      </div>
    </template>

    <template #before-close>
      <SavingIndicator ref="savingIndicator" />
    </template>

    <template #loader>
      <SavingIndicator ref="savingIndicatorTop" :type="SAVING_INDICATOR_TYPES.LINE" />
    </template>

    <div class="scm-Body">
      <AppRadioGroup
        :disabled="isScheduleDataLoading"
        :model-value="currentTab"
        :options="Object.values(TABS)"
        name="manage-schedule-tabs"
        type="tab-like"
        @update:model-value="onTabChange"
      />

      <template v-if="currentTab === TABS.FREQUENCY.value">
        <ScheduleModalLoader v-if="isScheduleDataLoading" />

        <template v-else>
          <ScheduleFrequencyManagement
            v-if="frequencyGeneralData && frequencyParams"
            v-model:frequency-general-data="frequencyGeneralData"
            v-model:frequency-params="frequencyParams"
          />

          <FormCaption
            v-if="!isEmpty(scheduleReminders)"
            :subtitle="t('schedule.reminders.subtitle')"
            :title="t('schedule.reminders.title')"
          >
            <ScheduleReminderItem
              v-for="(reminder, index) in scheduleReminders"
              :key="reminder[REMINDER_ENTITY_KEYS.ID]"
              v-model="scheduleReminders[index]"
            />
          </FormCaption>
        </template>
      </template>

      <ScheduleParticipantsManagement
        v-else-if="currentTab === TABS.PARTICIPANTS.value"
        v-model="scheduleParticipants"
        v-model:is-some-dropdown-opened="isSomeParticipantsDropdownOpen"
        :validation-errors="validationErrors"
        @remove-error="removeError"
      />

      <ScheduleNotificationsManagement
        v-else-if="currentTab === TABS.NOTIFICATIONS.value"
        v-model:channels-attributes="scheduleNotifications.channelsAttributes"
        v-model:selected-channels="scheduleNotifications.selectedChannels"
      />
    </div>

    <template #footer>
      <div class="scm-Footer">
        <AppDivider class="scm-Footer_Divider" no-margin />
        <ModalFooterActions
          :disabled="isCreateButtonDisabled"
          :loading="isSaving || isScheduleDataLoading"
          hide-checkbox
          @close="onClose"
          @create="onConfirm"
        >
          <template #confirm-button-text> {{ confirmButtonText }}</template>
        </ModalFooterActions>
      </div>
    </template>
  </Modal>

  <portal to="modal-windows">
    <AppDialog
      :show="isConfirmCloseShow"
      :title="$t('confirm_modal.title')"
      :type="DIALOG_TYPES.WARNING"
      @on-close="hideConfirmCloseModal"
      @on-confirm="onConfirmClose"
    >
      {{ $t('confirm_modal.description') }}

      <template #confirm-btn-text>
        {{ $t('confirm.discard_btn') }}
      </template>
    </AppDialog>
  </portal>
</template>

<script setup>
import { cloneDeep, isEmpty, isEqual, isNull } from 'lodash'
import { computed, nextTick, ref, shallowRef, toValue, useTemplateRef, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import SchedulesApiHandler from '@/api/schedules'
import {
  useParticipantsValidation,
  useScheduleDateCalculation
} from '@/utils/check-ins/schedule-composables'
import { useScheduleModalTabs } from '@/utils/check-ins/schedule-ui-helpers'
import {
  createNotificationsPayload,
  createRemindersPayload,
  DEFAULT_FORM_MODEL,
  DEFAULT_NOTIFICATIONS,
  DEFAULT_REMINDERS,
  FREQUENCY_INNER_PARAMS,
  createScheduleParamsPayload,
  parsePayloadToFormModel,
  SCHEDULE_NAME_MAX_LENGTH
} from '@/utils/check-ins/schedules-helpers'
import {
  createParticipantsPayload,
  DEFAULT_SCHEDULE_PARTICIPANTS
} from '@/utils/check-ins/schedules-participants'
import { DIALOG_TYPES } from '@/utils/components-configurations/app-dialog'
import { SAVING_INDICATOR_TYPES } from '@/utils/components-configurations/saving-indicator'
import { REMINDER_ENTITY_KEYS, SCHEDULE_ENTITY_KEYS } from '@/utils/entity-keys'
import { handleError } from '@/utils/error-handling'
import { isStringEmpty } from '@/utils/general'
import { showNotify } from '@/utils/notify'
import { numberOrNullProp } from '@/utils/prop-validators'

import AppDialog from '@/components/AppDialog'
import FormCaption from '@/components/check-ins/FormCaption'
import { NAME_FIELD_TEST_ID } from '@/components/check-ins/jest-helpers'
import ScheduleFrequencyManagement from '@/components/check-ins/ScheduleFrequencyManagement'
import ScheduleNotificationsManagement from '@/components/check-ins/ScheduleNotificationsManagement'
import ScheduleParticipantsManagement from '@/components/check-ins/ScheduleParticipantsManagement'
import ScheduleReminderItem from '@/components/check-ins/ScheduleReminderItem'
import AppFieldError from '@/components/form/AppFieldError'
import ModalFooterActions from '@/components/objectives/forms/ModalFooterActions'
import SavingIndicator from '@/components/SavingIndicator'
import AppDivider from '@/components/ui/AppDivider'
import AppInput from '@/components/ui/AppInput/AppInput'
import AppRadioGroup from '@/components/ui/AppRadioGroup/AppRadioGroup'
import Modal from '@/components/ui/Modal/Modal'
import ScheduleModalLoader from '@/components/ui/SkeletonLoaders/ScheduleModalLoader'
import SkeletonItem from '@/components/ui/SkeletonLoaders/SkeletonItem'

defineOptions({
  name: 'ScheduleModal'
})

const { NAME, ID } = SCHEDULE_ENTITY_KEYS

const props = defineProps({
  opened: {
    type: Boolean
  },

  scheduleId: {
    required: true,
    validator: v => numberOrNullProp(v)
  }
})

const { t } = useI18n()

const { TABS } = useScheduleModalTabs()

const currentTab = ref(TABS.FREQUENCY.value)

const onTabChange = value => {
  currentTab.value = value
}

const frequencyGeneralData = ref(null)
const frequencyParams = ref(null)
const scheduleReminders = ref([])
const scheduleNotifications = ref({})
const scheduleParticipants = ref(null)

const emit = defineEmits({
  'on-close': null,
  'reload-schedules': null
})

watch(
  () => props.opened,
  newValue => {
    if (!newValue) {
      frequencyGeneralData.value = null
      frequencyParams.value = null
      scheduleReminders.value = []
      scheduleNotifications.value = []
      currentTab.value = TABS.FREQUENCY.value
      isNameValid.value = true
      schedulePayloadSnapshot.value = null
    }
  }
)

const isSaving = ref(false)
const closeModalOnSavingFinished = ref(false)
const isConfirmCloseShow = ref(false)

const checkConfirmationAndCloseModal = ({ checkDataChange = true } = {}) => {
  if (checkDataChange !== false && areDataChanged.value) {
    isConfirmCloseShow.value = true
  } else {
    emit('on-close')
  }
}

const hideConfirmCloseModal = () => {
  isConfirmCloseShow.value = false
}

const onConfirmClose = () => {
  hideConfirmCloseModal()
  checkConfirmationAndCloseModal({ checkDataChange: false })
}

const onClose = () => {
  if (isSaving.value) {
    closeModalOnSavingFinished.value = true
  } else {
    checkConfirmationAndCloseModal()
  }
}

const nameInputReference = useTemplateRef('nameInput')

const onAfterEnter = async () => {
  if (!isEdit.value) {
    await nextTick()
    nameInputReference.value?.focus()
  }
}

const isScheduleDataLoading = ref(false)

const schedulePayloadSnapshot = shallowRef(null)

const applyEditState = async () => {
  const api = new SchedulesApiHandler()

  try {
    isScheduleDataLoading.value = true

    const scheduleData = await api.getScheduleById({ id: props.scheduleId })

    setFormModels({ scheduleData })
  } catch (error) {
    handleError({ error })
  } finally {
    isScheduleDataLoading.value = false
  }
}

const setFormModels = ({ scheduleData = {} } = {}) => {
  schedulePayloadSnapshot.value = cloneDeep({ ...scheduleData })

  const { generalData, frequencyParamsData, remindersData, notificationsData, participantsData } =
    parsePayloadToFormModel({
      payload: scheduleData
    })

  frequencyGeneralData.value = cloneDeep(generalData)
  frequencyParams.value = cloneDeep(frequencyParamsData)
  scheduleReminders.value = cloneDeep(remindersData)
  scheduleNotifications.value = cloneDeep(notificationsData)
  scheduleParticipants.value = cloneDeep(participantsData)
  resetValidationErrors()
}

const applyCreateState = () => {
  frequencyGeneralData.value = cloneDeep(DEFAULT_FORM_MODEL)
  frequencyParams.value = cloneDeep(FREQUENCY_INNER_PARAMS)
  scheduleReminders.value = cloneDeep(DEFAULT_REMINDERS)
  scheduleNotifications.value = cloneDeep(DEFAULT_NOTIFICATIONS)
  scheduleParticipants.value = cloneDeep(DEFAULT_SCHEDULE_PARTICIPANTS)
  resetValidationErrors()
}

const onBeforeEnter = () => {
  if (isEdit.value) {
    applyEditState()
  } else {
    applyCreateState()
  }
}

const isEdit = computed(() => !isNull(props.scheduleId))

const confirmButtonText = computed(() => {
  if (isEdit.value) {
    return t('action.save')
  }

  if (toValue(nextTab)) {
    return t('schedule.next_step', {
      stepName: t('common.select_entity', { entity: toValue(nextTab).label }).toLowerCase()
    })
  }

  return t('action.create')
})

const isNameValid = ref(true)

const savingIndicator = ref(null)
const savingIndicatorTop = ref(null)

const onSavingStarted = () => {
  isSaving.value = true
  savingIndicator.value.startSaving()
  savingIndicatorTop.value.startSaving()
}

const onSavingFinished = () => {
  isSaving.value = false
  savingIndicator.value.endSaving()
  savingIndicatorTop.value.endSaving()
  if (closeModalOnSavingFinished.value) {
    checkConfirmationAndCloseModal({ checkDataChange: false })
  }
}

const { selectedDateDayNumber, humanReadableRelativeDate } = useScheduleDateCalculation({
  formModel: frequencyGeneralData
})

const getFullPayload = () => {
  const frequencyDataPayload = createScheduleParamsPayload({
    scheduleData: toValue(frequencyGeneralData),
    frequencyParams: toValue(frequencyParams),
    selectedDateDayNumber: toValue(selectedDateDayNumber),
    nthDayNumber: humanReadableRelativeDate.value.nthDayNumber
  })

  return {
    ...frequencyDataPayload,
    [SCHEDULE_ENTITY_KEYS.REMINDERS]: createRemindersPayload({
      reminders: toValue(scheduleReminders)
    }),
    [SCHEDULE_ENTITY_KEYS.NOTIFICATION_CHANNEL_IDS]: createNotificationsPayload({
      ...toValue(scheduleNotifications)
    }),
    [SCHEDULE_ENTITY_KEYS.PARTICIPANTS]: createParticipantsPayload({
      participants: toValue(scheduleParticipants)
    })
  }
}

const areDataChanged = computed(() => {
  if (schedulePayloadSnapshot.value) {
    const clonedSnapshot = cloneDeep(schedulePayloadSnapshot.value)
    delete clonedSnapshot[ID]
    clonedSnapshot.notificationChannels.sort((a, b) => a.typeId - b.typeId)

    const payload = getFullPayload()
    payload.notificationChannels.sort((a, b) => a.typeId - b.typeId)

    return !isEqual(payload, { ...clonedSnapshot })
  }
  return false
})

watch(
  frequencyParams,
  (newValue, oldValue) => {
    if (!isEdit.value && oldValue && newValue && !schedulePayloadSnapshot.value) {
      schedulePayloadSnapshot.value = {
        ...cloneDeep(getFullPayload())
      }
    }
  },
  { deep: true, immediate: true }
)

const isCreateButtonDisabled = computed(() => {
  return isEdit.value && !areDataChanged.value
})

const nextTab = computed(() => {
  const indexOfSelectedTab = Object.values(TABS).findIndex(tab => tab.value === currentTab.value)

  return Object.values(TABS)[indexOfSelectedTab + 1]
})

const {
  validate: validateParticipants,
  validationErrors,
  resetValidationErrors,
  removeError
} = useParticipantsValidation({
  formModel: scheduleParticipants
})

const onConfirm = async () => {
  if (isCreateButtonDisabled.value) return

  if (!isEdit.value && toValue(nextTab)) {
    onTabChange(toValue(nextTab).value)
    return
  }

  isNameValid.value = !isStringEmpty(frequencyGeneralData.value[NAME])

  if (!isNameValid.value) {
    frequencyGeneralData.value[NAME] = ''
    return
  }

  const isParticipantsValid = validateParticipants()

  if (!isParticipantsValid) {
    if (isEdit.value && currentTab.value !== TABS.PARTICIPANTS.value) {
      onTabChange(TABS.PARTICIPANTS.value)
    }

    return
  }

  const api = new SchedulesApiHandler()

  try {
    closeModalOnSavingFinished.value = !isEdit.value

    onSavingStarted()

    const requestMethod = isEdit.value
      ? payload => api.updateSchedule({ id: props.scheduleId, ...payload })
      : payload => api.createSchedule(payload)

    const payload = getFullPayload()

    const schedule = await requestMethod(payload)

    if (isEdit.value) {
      setFormModels({ scheduleData: schedule })
    }

    const notificationTitleKeyPath = isEdit.value
      ? 'schedule.updated_notification'
      : 'schedule.created_notification'

    showNotify({
      title: t(notificationTitleKeyPath, {
        name: payload[NAME]
      })
    })

    emit('reload-schedules')
  } catch (error) {
    closeModalOnSavingFinished.value = false
    handleError({ error })
  } finally {
    onSavingFinished()
  }
}

const isSomeParticipantsDropdownOpen = ref(false)

const modalContentBottomPadding = computed(() => {
  const DEFAULT_PADDING = '0px'

  if (!props.opened) return DEFAULT_PADDING

  return isSomeParticipantsDropdownOpen.value ? '200px' : DEFAULT_PADDING
})
</script>

<style lang="scss" scoped>
.scm-Modal {
  --modal-body-padding-left: #{$page-left-padding-next};
  --modal-header-padding: #{$page-top-padding} #{$page-right-padding-next} 15px 30px;
  --modal-body-padding: 15px #{$page-right-padding-next} 44px;
  --modal-footer-padding: 0;
  // --modal-header-padding: 32px #{$page-right-padding} 28px #{$page-left-padding};
  // --modal-body-padding: 0 #{$page-right-padding} 20px #{$page-left-padding};
  // --select-skeleton-top: 0;
  // --select-skeleton-left: 0;
  //
  // --modal-footer-padding: 20px 0;
  &:deep(.o-modal-content) {
    // I don't know reason why, but binding computed styles here broke code with hot reload in mutation observer
    //  at runtime-dom.esm-bundler.js
    // but it works with css-var bounded in template
    // padding-bottom: v-bind(modalContentBottomPadding);
    padding-bottom: var(--modal-content-bottom-padding, 0px);
    transition: $menu-transition;
  }
}

.scm-Body {
  display: flex;
  flex-direction: column;
  gap: 44px;
}

.scm-Footer {
  box-shadow: inset 0 1px 0 0 $grey-2-next;
  width: 100%;
  --padding: 0 var(--page-right-padding-next) 20px;
}

.scm-Footer_Divider {
  display: flex;
  margin-bottom: 20px;
}

.scm-TitleWrapper {
  width: 100%;
}

.scm-Title {
  --input-font-size: #{$fs-24};
  --input-line-height: 28px;
  --input-wrapper-height: 28px;
  --input-wrapper-hover-background-color: transparent;
  --error-border-color: transparent;
}

.scm-TitleError {
  position: absolute;
  pointer-events: none;
  padding: 5px 10px;
}
</style>
