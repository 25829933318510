<template>
  <Modal
    :data-testid="$attrs['data-testid'] || null"
    :scrollable-content="false"
    :show="opened"
    :size="APP_MODAL_SIZES.XS_NEXT"
    class="cfm-Modal"
    manual-close
    @close="onClose"
    @after-enter="onAfterEnter"
    @before-enter="onBeforeEnter"
  >
    <template #header>
      <div class="cfm-Title">
        <SkeletonItem v-if="isEditFieldDataLoading" height="28px" width="200px" />

        <template v-else>
          {{ modalTitle }}
        </template>
      </div>
    </template>

    <template #before-close>
      <SavingIndicator ref="savingIndicator" />
    </template>

    <template #loader>
      <SavingIndicator ref="savingIndicatorTop" :type="SAVING_INDICATOR_TYPES.LINE" />
    </template>

    <div class="cfm-Fields">
      <FormFieldNext v-if="!isEdit" :label="t('custom_fields.field_type')">
        <div class="cfm-SelectWrapper">
          <AppSelect
            ref="typeSelectReference"
            v-model="formModel[TYPE_ID]"
            :data-testid="CUSTOM_FIELD_MODAL_FIELD_TYPE_SELECT_TEST_ID"
            :group-by="item => item[PLACEMENT]"
            :groups="groups"
            :is-error="!getRequiredValidationStatus(TYPE_ID)"
            :item-label="DISPLAY_NAME"
            :item-value="TYPE_ID"
            :offset="[0, -40]"
            :options="fieldTypes"
            :search-function="
              value => localSearch({ value, options: fieldTypes, key: DISPLAY_NAME })
            "
            append-to=".cfm-SelectWrapper"
            dropdown-search
            max-height="484px"
            @open="removeError(TYPE_ID)"
            @update:model-value="onTypeUpdate"
          >
            <template #option-label="{ option }">
              <CustomFieldNameCell v-if="option" :clickable="false" :field="option">
                {{ $t(option[DISPLAY_NAME]) }}
              </CustomFieldNameCell>
            </template>

            <template #button-content="{ option }">
              <CustomFieldNameCell v-if="option" :clickable="false" :field="option">
                {{ $t(option[DISPLAY_NAME]) }}
              </CustomFieldNameCell>
              <span v-else class="cfm-SelectEmptyLabel">
                {{ t('custom_fields.field_type.placeholder') }}
              </span>
            </template>
          </AppSelect>
        </div>

        <AppFieldError
          v-if="!getRequiredValidationStatus(TYPE_ID)"
          :data-testid="CUSTOM_FIELD_MODAL_ERROR_MESSAGE_TEST_ID"
          :show="!getRequiredValidationStatus(TYPE_ID)"
        >
          {{ $t('field.required') }}
        </AppFieldError>

        <AppInfoMessage
          v-if="isShowFieldTypeWarning"
          :type="INFO_MESSAGE_TYPES.WARNING"
          class="cfm-Message"
        >
          {{ t('custom_fields.warning.filtering') }}
        </AppInfoMessage>
      </FormFieldNext>

      <FormFieldNext :label="t('custom_fields.field_name')">
        <AppInput
          v-model="formModel[NAME]"
          :data-testid="CUSTOM_FIELD_MODAL_FIELD_NAME_INPUT_TEST_ID"
          :is-error="!getRequiredValidationStatus(NAME)"
          :loading="isEditFieldDataLoading"
          :placeholder="t('custom_fields.field_name.placeholder')"
          max-length="20"
          size="xlg"
          style-type="primary"
          @focus="removeError(NAME)"
        />

        <AppFieldError
          v-if="!getRequiredValidationStatus(NAME)"
          :data-testid="CUSTOM_FIELD_MODAL_ERROR_MESSAGE_TEST_ID"
          :show="!getRequiredValidationStatus(NAME)"
        >
          {{ t('field.required') }}
        </AppFieldError>
      </FormFieldNext>

      <CustomFieldModalTeleportTarget
        :available-teleports="availableTeleports"
        :placement="ADDITIONAL_PARAMS_FIELDS_PLACEMENTS.BEFORE_FIELD_ADD_FOR"
      />

      <FormFieldNext
        :disabled="isEdit"
        :label="t('custom_fields.add_for')"
        class="cfm-SelectWrapper"
      >
        <AppSelect
          v-model="formModel[ELEMENT_TYPE_IDS]"
          :data-testid="CUSTOM_FIELD_MODAL_ADD_FOR_SELECT_TEST_ID"
          :disabled="isEdit"
          :dropdown-search="false"
          :is-error="!getRequiredValidationStatus(ELEMENT_TYPE_IDS)"
          :loading="isEditFieldDataLoading"
          :offset="[0, -40]"
          :options="okrTypes"
          append-to="parent"
          hide-selected-items-in-dropdown
          item-label="name"
          item-value="id"
          multi
          show-all-selected
          show-selected-options-inside
          skeleton-loader
          skeleton-loader-height="100%"
          skeleton-loader-width="100%"
          style="
            --disabled-opacity: 1;
            --select-item-disabled-opacity: 0.5;
            --select-skeleton-top: 0;
            --select-skeleton-left: 0;
          "
          @open="removeError(ELEMENT_TYPE_IDS)"
        >
          <template #button-content="{ options }">
            <span v-if="isEmpty(options)" class="cfm-SelectEmptyLabel">
              {{ t('groups.unlink.strategy_label') }}
            </span>
          </template>

          <template #option-label="{ option }">
            <div v-if="option" class="cfm-AddForOption">
              <AppIcon
                :icon-name="option.icon"
                class="cfm-AddForOption_Icon"
                height="24"
                width="24"
              />
              <span class="cfm-AddForOption_Label oboard-truncated-text">
                {{ option.name }}
              </span>
            </div>
          </template>
        </AppSelect>
        <AppFieldError
          v-if="!getRequiredValidationStatus(ELEMENT_TYPE_IDS)"
          :data-testid="CUSTOM_FIELD_MODAL_ERROR_MESSAGE_TEST_ID"
          :show="!getRequiredValidationStatus(ELEMENT_TYPE_IDS)"
        >
          {{ $t('field.required') }}
        </AppFieldError>

        <AppInfoMessage
          v-if="!isEdit"
          :data-testid="CUSTOM_FIELD_MODAL_ADD_FOR_WARNING_MESSAGE_TEST_ID"
          :type="INFO_MESSAGE_TYPES.WARNING"
          class="cfm-Message"
        >
          {{
            t('custom_fields.warning.not_editable', {
              fieldName: t('custom_fields.add_for')
            })
          }}
        </AppInfoMessage>
      </FormFieldNext>

      <GlobalGroupsWorkspaceSelect
        v-if="isShowWorkspacesSelect"
        v-model:selected-workspaces="formModel[WORKSPACE_IDS]"
        :data-testid="CUSTOM_FIELD_MODAL_WORKSPACE_SELECT_TEST_ID"
        :error-message-test-id="CUSTOM_FIELD_MODAL_ERROR_MESSAGE_TEST_ID"
        :is-error="!getRequiredValidationStatus(WORKSPACE_IDS)"
        hide-all-workspaces-option
        @open="removeError(WORKSPACE_IDS)"
      />

      <template v-if="isFieldTypeSelected">
        <Teleport
          v-for="param in additionalParams"
          :key="param.name"
          :disabled="param.placement === ADDITIONAL_PARAMS_FIELDS_PLACEMENTS.DEFAULT"
          :to="`#${param.placement}`"
        >
          <Component
            :is="ADDITIONAL_PARAMS_FIELDS_CONFIGURATION_COMPONENTS[param.typeId]"
            v-model="additionalParamsFormModel[param.typeId]"
            :disabled="isEdit"
            :validation-status="additionalParamsValidationStatuses[param.typeId]"
            @remove-error="removeAdditionalFieldError"
          />
        </Teleport>
      </template>

      <div class="cfm-HelpText">
        <AppCheckbox
          v-model="isHelpTextEnabled"
          :align-start="isHelpTextEnabled"
          :data-testid="CUSTOM_FIELD_MODAL_HELP_TEXT_CHECKBOX_TEST_ID"
          :disabled="isEditFieldDataLoading"
          full-width
          @update:model-value="removeError(HELP_TEXT)"
        >
          {{ t('custom_fields.help_text') }}
          <AppInput
            v-if="isHelpTextEnabled"
            v-model="formModel[HELP_TEXT]"
            :data-testid="CUSTOM_FIELD_MODAL_HELP_TEXT_INPUT_TEST_ID"
            :is-error="!getRequiredValidationStatus(HELP_TEXT)"
            :placeholder="t('custom_fields.help_text.placeholder')"
            class="cfm-HelpText_Input"
            max-length="200"
            size="xlg"
            style-type="primary"
            @focus="removeError(HELP_TEXT)"
          />
          <AppFieldError
            v-if="!getRequiredValidationStatus(HELP_TEXT)"
            :data-testid="CUSTOM_FIELD_MODAL_ERROR_MESSAGE_TEST_ID"
            :show="!getRequiredValidationStatus(HELP_TEXT)"
            class="cfm-HelpText_Error"
          >
            {{ $t('field.required') }}
          </AppFieldError>
        </AppCheckbox>
      </div>
    </div>

    <template #footer>
      <div class="cfm-Footer">
        <AppDivider class="cfm-Footer_Divider" no-margin />
        <ModalFooterActions
          :disabled="isEdit && !areDataChanged"
          :loading="isSaving || isEditFieldDataLoading"
          hide-checkbox
          style="--padding: 0 var(--page-right-padding-next)"
          @close="onClose"
          @create="onConfirm"
        >
          <template #confirm-button-text> {{ confirmButtonText }}</template>
        </ModalFooterActions>
      </div>
    </template>
  </Modal>

  <portal to="modal-windows">
    <AppDialog
      :show="isConfirmCloseShow"
      :title="$t('confirm_modal.title')"
      :type="DIALOG_TYPES.WARNING"
      @on-close="hideConfirmCloseModal"
      @on-confirm="onConfirmClose"
    >
      {{ $t('confirm_modal.description') }}

      <template #confirm-btn-text>
        {{ $t('confirm.discard_btn') }}
      </template>
    </AppDialog>
  </portal>
</template>

<script setup>
import { cloneDeep, has, isEmpty, isEqual, isNull } from 'lodash'
import { computed, nextTick, ref, shallowRef, watch, inject } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

import CustomFieldsApiHandler from '@/api/custom-fields'
import { tracker } from '@/tracking/amplitude'
import { EVENT_CATEGORIES } from '@/tracking/amplitude-helpers'
import { DIALOG_TYPES } from '@/utils/components-configurations/app-dialog'
import { INFO_MESSAGE_TYPES } from '@/utils/components-configurations/app-info-message'
import { APP_MODAL_SIZES } from '@/utils/components-configurations/app-modal'
import { SAVING_INDICATOR_TYPES } from '@/utils/components-configurations/saving-indicator'
import {
  ADDITIONAL_PARAMS_FIELDS_PLACEMENTS,
  ADDITIONAL_PARAMS_SETTINGS,
  ADDITIONAL_PARAMS_SETTINGS_BY_ID,
  ALL_CUSTOM_FIELDS,
  CUSTOM_FIELDS_PLACEMENTS
} from '@/utils/custom-fields/factory'
import {
  CUSTOM_FIELDS_OKR_TYPE_IDS,
  REQUIRED_STATUS,
  validateArray,
  validateSelectValue,
  validateString
} from '@/utils/custom-fields/helpers'
import {
  CUSTOM_FIELD_MODAL_ADD_FOR_SELECT_TEST_ID,
  CUSTOM_FIELD_MODAL_ADD_FOR_WARNING_MESSAGE_TEST_ID,
  CUSTOM_FIELD_MODAL_ERROR_MESSAGE_TEST_ID,
  CUSTOM_FIELD_MODAL_FIELD_NAME_INPUT_TEST_ID,
  CUSTOM_FIELD_MODAL_FIELD_TYPE_SELECT_TEST_ID,
  CUSTOM_FIELD_MODAL_HELP_TEXT_CHECKBOX_TEST_ID,
  CUSTOM_FIELD_MODAL_HELP_TEXT_INPUT_TEST_ID,
  CUSTOM_FIELD_MODAL_WORKSPACE_SELECT_TEST_ID
} from '@/utils/custom-fields/jest-helpers'
import { CUSTOM_FIELD_ENTITY_KEYS } from '@/utils/entity-keys'
import { handleError } from '@/utils/error-handling'
import {
  isJiraAppInjectionKey,
  isSalesforceAppInjectionKey,
  isWebAppInjectionKey
} from '@/utils/injection-keys'
import { showNotify } from '@/utils/notify'
import { OBJECTIVE_TYPES } from '@/utils/objective-types'
import { localSearch } from '@/utils/objectives'
import { objectOrNullProp } from '@/utils/prop-validators'
import { PLUGIN_OPTIONS_KEYS } from '@root/template-options-keys'

import AppDialog from '@/components/AppDialog'
import CurrencyField from '@/components/custom-fields/configurations/CurrencyField'
import CustomFieldModalTeleportTarget from '@/components/custom-fields/configurations/CustomFieldModalTeleportTarget'
import OptionsField from '@/components/custom-fields/configurations/OptionsField'
import CustomFieldNameCell from '@/components/custom-fields/CustomFieldNameCell'
import AppFieldError from '@/components/form/AppFieldError'
import FormFieldNext from '@/components/form/FormFieldNext'
import GlobalGroupsWorkspaceSelect from '@/components/global-groups/GlobalGroupsWorkspaceSelect'
import ModalFooterActions from '@/components/objectives/forms/ModalFooterActions'
import SavingIndicator from '@/components/SavingIndicator'
import AppCheckbox from '@/components/ui/AppCheckbox/AppCheckbox'
import AppDivider from '@/components/ui/AppDivider'
import AppIcon from '@/components/ui/AppIcon/AppIcon'
import AppInfoMessage from '@/components/ui/AppInfoMessage'
import AppInput from '@/components/ui/AppInput/AppInput'
import AppSelect from '@/components/ui/AppSelect/AppSelect'
import Modal from '@/components/ui/Modal/Modal'
import SkeletonItem from '@/components/ui/SkeletonLoaders/SkeletonItem'

defineOptions({
  name: 'CustomFieldModal'
})

const {
  NAME,
  TYPE_ID,
  ELEMENT_TYPE_IDS,
  HELP_TEXT,
  PLACEMENT,
  WORKSPACE_IDS,
  WORKSPACES,
  DISPLAY_NAME,
  FILTERABLE,
  SORTABLE,
  ADDITIONAL_PARAMETERS,
  ID,
  ADDITIONAL_FIELDS,
  ACTIVE
} = CUSTOM_FIELD_ENTITY_KEYS

const DEFAULT_FORM_MODEL = {
  [NAME]: '',
  [TYPE_ID]: null,
  [ELEMENT_TYPE_IDS]: [],
  [WORKSPACE_IDS]: [],
  [HELP_TEXT]: ''
}

const DEFAULT_VALIDATION_STATUS = {
  [REQUIRED_STATUS]: true
}

const DEFAULT_VALIDATION_STATUSES = {
  [NAME]: {
    ...DEFAULT_VALIDATION_STATUS
  },
  [TYPE_ID]: {
    ...DEFAULT_VALIDATION_STATUS
  },
  [ELEMENT_TYPE_IDS]: {
    ...DEFAULT_VALIDATION_STATUS
  },
  [HELP_TEXT]: {
    ...DEFAULT_VALIDATION_STATUS
  },
  [WORKSPACE_IDS]: {
    ...DEFAULT_VALIDATION_STATUS
  }
}

const ADDITIONAL_PARAMS_FIELDS_CONFIGURATION_COMPONENTS = {
  [ADDITIONAL_PARAMS_SETTINGS.OPTIONS.typeId]: OptionsField,
  [ADDITIONAL_PARAMS_SETTINGS.CURRENCY.typeId]: CurrencyField
}

const props = defineProps({
  opened: {
    type: Boolean
  },

  editFieldData: {
    required: true,
    validator: v => objectOrNullProp(v)
  }
})

const emit = defineEmits({
  'on-close': null,
  'reload-fields': null
})

const isSaving = ref(false)
const closeModalOnSavingFinished = ref(false)
const savingIndicator = ref(null)
const savingIndicatorTop = ref(null)

const onSavingStarted = () => {
  isSaving.value = true
  savingIndicator.value.startSaving()
  savingIndicatorTop.value.startSaving()
}

const onSavingFinished = () => {
  isSaving.value = false
  savingIndicator.value.endSaving()
  savingIndicatorTop.value.endSaving()
  if (closeModalOnSavingFinished.value) {
    checkConfirmationAndCloseModal({ checkDataChange: false })
  }
}

const areDataChanged = computed(() => {
  if (isEdit.value) {
    if (isEmpty(fieldDataSnapshot.value)) {
      return false
    }
    const isNameChanged = fieldDataSnapshot.value[NAME] !== formModel.value[NAME]
    const workspacesSnapshot = fieldDataSnapshot.value[WORKSPACES].map(({ id }) => id)
    const isWorkspacesChanged = !isEqual(workspacesSnapshot, formModel.value[WORKSPACE_IDS])

    let newHelpText = isHelpTextEnabled.value ? formModel.value[HELP_TEXT] : null
    const isHelpTextChanged = newHelpText !== fieldDataSnapshot.value[HELP_TEXT]

    let isCurrencyChanged = false

    const { typeId: currencyTypeId } = ADDITIONAL_PARAMS_SETTINGS.CURRENCY

    const snapshotCurrency = fieldDataSnapshot.value.additionalParameters[currencyTypeId]

    if (snapshotCurrency && has(additionalParamsFormModel.value, currencyTypeId)) {
      isCurrencyChanged = snapshotCurrency.value !== additionalParamsFormModel.value[currencyTypeId]
    }

    return isNameChanged || isWorkspacesChanged || isHelpTextChanged || isCurrencyChanged
  }

  if (isHelpTextEnabled.value) {
    return true
  }

  return !isEqual(formModel.value, DEFAULT_FORM_MODEL)
})

const isConfirmCloseShow = ref(false)

const checkConfirmationAndCloseModal = ({ checkDataChange = true } = {}) => {
  if (checkDataChange !== false && areDataChanged.value) {
    isConfirmCloseShow.value = true
  } else {
    emit('on-close')
  }
}
const hideConfirmCloseModal = () => {
  isConfirmCloseShow.value = false
}

const onConfirmClose = () => {
  hideConfirmCloseModal()
  checkConfirmationAndCloseModal({ checkDataChange: false })
}

const onClose = () => {
  if (isSaving.value) {
    closeModalOnSavingFinished.value = true
  } else {
    checkConfirmationAndCloseModal()
  }
}

const isEdit = computed(() => !isEmpty(props.editFieldData))

const { t } = useI18n()

const modalTitle = computed(() => {
  if (isEdit.value && isFieldTypeSelected.value) {
    return `${t('action.edit')} ${t(currentFieldOptions.value[DISPLAY_NAME])}`
  }
  return t('custom_fields.create_field')
})

const confirmButtonText = computed(() => (isEdit.value ? t('action.update') : t('action.create')))

const formModel = ref({
  ...DEFAULT_FORM_MODEL
})

const fieldDataSnapshot = shallowRef(null)

const additionalParamsFormModel = ref({})
const additionalParamsValidationStatuses = ref({})

const onTypeUpdate = () => {
  const paramsObject = additionalParams.value.reduce((acc, val) => {
    return {
      ...acc,
      [val.typeId]: val.defaultValue
    }
  }, {})

  additionalParamsFormModel.value = cloneDeep(paramsObject)
  additionalParamsValidationStatuses.value = Object.keys(additionalParamsFormModel.value).reduce(
    (acc, val) => {
      return {
        ...acc,
        [val]: { ...DEFAULT_VALIDATION_STATUS }
      }
    },
    {}
  )
}

const fieldTypes = computed(() =>
  Object.values(ALL_CUSTOM_FIELDS.getListOfFields()).map(item => {
    return item.getData()
  })
)

const groups = computed(() => {
  const keyPath = 'custom_fields.add_field_to_placement'
  return {
    [CUSTOM_FIELDS_PLACEMENTS.CONTENT]: {
      name: t(keyPath, {
        placement: t('custom_fields.content_area')
      })
    },
    [CUSTOM_FIELDS_PLACEMENTS.SIDEBAR]: {
      name: t(keyPath, {
        placement: t('custom_fields.right_panel')
      })
    }
  }
})

const typeSelectReference = ref(null)

watch(
  () => props.opened,
  newValue => {
    if (!newValue) {
      setDefaultState()
    }
  }
)

const onAfterEnter = async () => {
  if (!isEdit.value) {
    await nextTick()
    typeSelectReference.value.focus()
  }
}

const onBeforeEnter = () => {
  if (isEdit.value) {
    applyEditState()
  }
}

const isHelpTextEnabled = ref(false)

const setDefaultState = () => {
  formModel.value = cloneDeep(DEFAULT_FORM_MODEL)
  additionalParamsFormModel.value = {}
  validationStatuses.value = cloneDeep(DEFAULT_VALIDATION_STATUSES)
  additionalParamsValidationStatuses.value = {}
  closeModalOnSavingFinished.value = false
  isHelpTextEnabled.value = false
  fieldDataSnapshot.value = null
  isInitialDataLoaded.value = false
}

const isEditFieldDataLoading = ref(false)
const isInitialDataLoaded = ref(false)
const isShowWorkspacesSelect = computed(() => {
  if (isEdit.value) {
    return isInitialDataLoaded.value
  }
  return true
})
const applyEditState = async () => {
  isEditFieldDataLoading.value = true
  const { id } = props.editFieldData
  const api = new CustomFieldsApiHandler()
  try {
    const fieldProperties = await api.getCustomFieldById({ id })
    const { typeId, name, helpText, elementTypeIds, workspaces } = fieldProperties
    fieldDataSnapshot.value = cloneDeep(fieldProperties)
    formModel.value = {
      ...formModel.value,
      [TYPE_ID]: typeId,
      [NAME]: name,
      [ELEMENT_TYPE_IDS]: elementTypeIds,
      [WORKSPACE_IDS]: workspaces.map(({ id }) => id)
    }

    onTypeUpdate(typeId)

    if (helpText) {
      formModel.value[HELP_TEXT] = helpText
      isHelpTextEnabled.value = true
    }

    if (!isEmpty(additionalParamsFormModel.value)) {
      for (const key in additionalParamsFormModel.value) {
        const { additionalParameters: fieldAdditionalParams } = fieldProperties

        if (has(fieldAdditionalParams, key)) {
          additionalParamsFormModel.value[key] = fieldAdditionalParams[key].value
        }
      }
    }

    if (!isInitialDataLoaded.value) {
      isInitialDataLoaded.value = true
    }
  } catch (error) {
    handleError({ error })
    checkConfirmationAndCloseModal({
      checkDataChange: false
    })
  } finally {
    isEditFieldDataLoading.value = false
  }
}

const isJiraApp = inject(isJiraAppInjectionKey)
const isWebApp = inject(isWebAppInjectionKey)
const isSalesforceApp = inject(isSalesforceAppInjectionKey)

const store = useStore()

const isJiraConnected = computed(
  () => store.state.pluginOptions[PLUGIN_OPTIONS_KEYS.JIRA_CONNECTED]
)

const okrTypes = computed(() => {
  return Object.values(CUSTOM_FIELDS_OKR_TYPE_IDS)
    .filter(item => {
      const isNeedTask =
        isJiraApp ||
        (isWebApp && isJiraConnected.value) ||
        (isSalesforceApp && isJiraConnected.value)
      return isNeedTask ? true : item.id !== OBJECTIVE_TYPES.TASK
    })
    .map(item => {
      return {
        ...item,
        name: t(item.name)
      }
    })
})

const isFieldTypeSelected = computed(() => {
  return !isNull(formModel.value[TYPE_ID])
})

const isShowTeleports = computed(() => {
  if (!isFieldTypeSelected.value) {
    return false
  }

  return !isEmpty(additionalParams.value)
})

const availableTeleports = computed(() => {
  if (!isShowTeleports.value) {
    return []
  }

  return additionalParams.value.map(param => {
    return param.placement
  })
})

const currentFieldOptions = computed(() => {
  return ALL_CUSTOM_FIELDS.getFieldOptionsByTypeId(formModel.value[TYPE_ID])
})

const additionalParams = computed(() => {
  if (currentFieldOptions.value) {
    return currentFieldOptions.value[ADDITIONAL_PARAMETERS]
  }

  return []
})

const isShowFieldTypeWarning = computed(() => {
  return currentFieldOptions.value ? !currentFieldOptions.value[FILTERABLE] : false
})

const validationStatuses = ref(cloneDeep(DEFAULT_VALIDATION_STATUSES))

const getRequiredValidationStatus = key => {
  if (!key) {
    throw new Error('To get validation status You need to pass Field key')
  }

  if (!Object.keys(validationStatuses.value).includes(key)) {
    throw new Error(`Unknown key: ${key}`)
  }

  return validationStatuses.value[key][REQUIRED_STATUS]
}

const removeError = key => {
  if (!key) return

  if (!Object.keys(validationStatuses.value).includes(key)) return

  validationStatuses.value[key] = {
    ...DEFAULT_VALIDATION_STATUS
  }
}

const removeAdditionalFieldError = ({ fieldKey = null, errorKey = null } = {}) => {
  if (!fieldKey) return

  if (!Object.keys(additionalParamsValidationStatuses.value).includes(`${fieldKey}`)) return

  const isSetToDefault = isNull(errorKey)

  if (isSetToDefault) {
    additionalParamsValidationStatuses.value[fieldKey] = {
      ...DEFAULT_VALIDATION_STATUS
    }
  } else {
    additionalParamsValidationStatuses.value[fieldKey][errorKey] =
      DEFAULT_VALIDATION_STATUS[REQUIRED_STATUS]
  }
}

const validateAdditionalParameters = () => {
  Object.keys(additionalParamsFormModel.value).forEach(key => {
    const optionsTypeId = ADDITIONAL_PARAMS_SETTINGS.OPTIONS.typeId
    const currencyTypeId = ADDITIONAL_PARAMS_SETTINGS.CURRENCY.typeId
    if (Number(key) === currencyTypeId) {
      additionalParamsValidationStatuses.value[currencyTypeId][REQUIRED_STATUS] = validateString(
        additionalParamsFormModel.value[currencyTypeId]
      )
    }

    if (Number(key) === optionsTypeId) {
      additionalParamsValidationStatuses.value[optionsTypeId][REQUIRED_STATUS] = validateArray(
        additionalParamsFormModel.value[optionsTypeId]
      )

      const validatedOptions = additionalParamsFormModel.value[optionsTypeId].reduce(
        (acc, option) => {
          return {
            ...acc,
            [option.id]: validateString(option.name)
          }
        },
        {}
      )

      additionalParamsValidationStatuses.value[optionsTypeId] = {
        [REQUIRED_STATUS]: additionalParamsValidationStatuses.value[optionsTypeId][REQUIRED_STATUS],
        ...validatedOptions
      }
    }
  })
}
const validateForm = () => {
  validationStatuses.value[NAME][REQUIRED_STATUS] = validateString(formModel.value[NAME])
  validationStatuses.value[TYPE_ID][REQUIRED_STATUS] = validateSelectValue(formModel.value[TYPE_ID])
  validationStatuses.value[ELEMENT_TYPE_IDS][REQUIRED_STATUS] = validateArray(
    formModel.value[ELEMENT_TYPE_IDS]
  )
  validationStatuses.value[WORKSPACE_IDS][REQUIRED_STATUS] = validateArray(
    formModel.value[WORKSPACE_IDS]
  )

  if (isHelpTextEnabled.value) {
    validationStatuses.value[HELP_TEXT][REQUIRED_STATUS] = validateString(
      formModel.value[HELP_TEXT]
    )
  }

  validateAdditionalParameters()
}

const isFormValid = computed(() => {
  const combinedStatuses = {
    ...cloneDeep(validationStatuses.value),
    ...cloneDeep(additionalParamsValidationStatuses.value)
  }

  let isValid = true

  const isValidationPassed = ({ validationObject }) => {
    return Object.values(validationObject).every(status => status)
  }

  for (const key in combinedStatuses) {
    const validationObject = combinedStatuses[key]

    if (key === HELP_TEXT) {
      if (isHelpTextEnabled.value) {
        isValid = isValidationPassed({ validationObject })
      } else {
        isValid = true
      }
    } else {
      isValid = isValidationPassed({ validationObject })
    }

    if (!isValid) {
      break
    }
  }

  return isValid
})

const handleFieldData = ({ fieldData } = {}) => {
  const { id, name: label, typeId, elementTypeIds, helpText } = fieldData

  const available = okrTypes.value
    .filter(({ id }) => elementTypeIds.includes(id))
    .map(({ trackingName }) => trackingName)

  const type = t(ALL_CUSTOM_FIELDS.getFieldOptionsByTypeId(typeId).displayName, 1, { locale: 'en' })

  const eventName = isEdit.value ? 'custom field updated' : 'custom field created'

  tracker.logEvent(eventName, {
    category: EVENT_CATEGORIES.SETTINGS,
    label,
    type,
    available,
    workspaces: formModel.value[WORKSPACE_IDS],
    id,
    helpText: helpText || undefined
  })

  const notificationAction = isEdit.value ? t('action.updated') : t('action.created')

  showNotify({
    content: t('custom_fields.notification.created_updated', {
      action: notificationAction,
      fieldName: label
    }),
    expanded: true
  })
}
const onConfirm = async () => {
  validateForm()

  await nextTick()

  if (isFormValid.value) {
    const api = new CustomFieldsApiHandler()

    try {
      closeModalOnSavingFinished.value = true
      onSavingStarted()

      const payload = createPayload()

      const requestMethod = isEdit.value
        ? payload => api.updateCustomField(payload)
        : payload => api.createCustomField(payload)

      const fieldData = await requestMethod(payload)

      handleFieldData({ fieldData })

      emit('reload-fields')
    } catch (error) {
      closeModalOnSavingFinished.value = false
      handleError({ error })
    } finally {
      onSavingFinished()
    }
  }
}

const createPayload = () => {
  const payload = cloneDeep(formModel.value)
  if (!isHelpTextEnabled.value) {
    delete payload[HELP_TEXT]
  }

  const additionalParameters = cloneDeep(additionalParamsFormModel.value)

  if (isEdit.value) {
    payload[ID] = props.editFieldData[ID]
    payload[ACTIVE] = props.editFieldData[ACTIVE]

    const additionalParamsPayload = Object.entries(additionalParameters)
      .map(([typeId, value]) => {
        if (has(ADDITIONAL_PARAMS_SETTINGS_BY_ID, typeId)) {
          return ADDITIONAL_PARAMS_SETTINGS_BY_ID[typeId].getPayloadForUpdateField({
            value,
            originalValue: fieldDataSnapshot.value[ADDITIONAL_FIELDS][typeId]
          })
        } else {
          return undefined
        }
      })
      .filter(Boolean)
      .flat()

    if (!isEmpty(additionalParamsPayload)) {
      payload[ADDITIONAL_PARAMETERS] = additionalParamsPayload
    }
  } else {
    payload[SORTABLE] = currentFieldOptions.value[SORTABLE]
    payload[FILTERABLE] = currentFieldOptions.value[FILTERABLE]

    payload[ADDITIONAL_PARAMETERS] = Object.entries(additionalParameters)
      .map(([typeId, value]) => {
        if (has(ADDITIONAL_PARAMS_SETTINGS_BY_ID, typeId)) {
          return ADDITIONAL_PARAMS_SETTINGS_BY_ID[typeId].getPayloadForCreateField({ value })
        } else {
          return undefined
        }
      })
      .filter(Boolean)
  }

  return payload
}
</script>

<style lang="scss" scoped>
.cfm-SelectWrapper {
  position: relative;
  :deep(.as-AppDroplistButton_Content) {
    padding: 6px 0 6px 6px;
  }
}

.cfm-Modal {
  --modal-footer-padding: 20px 0;
  :deep(.o-modal-body) {
    padding-top: 10px;
  }
}

.cfm-Title {
  color: $dark-1;
  font-weight: fw('semi-bold');
  font-size: $fs-20;
  line-height: 28px;
}

.cfm-HelpText_Input {
  margin-top: 16px;
}

.cfm-HelpText_Error {
  margin-top: 6px;
  display: block;
}

.cfm-Fields {
  display: grid;
  gap: 20px;
}

.cfm-Message {
  margin-top: 2px;
}

.cfm-Fields {
  padding: 0 20px;
}

.cfm-Footer {
  width: 100%;
}

.cfm-Footer_Divider {
  display: flex;
  margin-bottom: 20px;
}

.cfm-HelpText {
  padding-left: 4px;
}

.cfm-SelectEmptyLabel {
  padding-left: 2px;
}

.cfm-AddForOption {
  display: flex;
  align-items: center;
  gap: 8px;
  max-width: 100%;
  width: 100%;
}

.cfm-AddForOption_Icon {
  flex-shrink: 0;
}

.cfm-AddForOption_Label {
  font-style: normal;
  font-weight: fw('regular');
  font-size: $fs-14;
  line-height: 20px;
}
</style>
