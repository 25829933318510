<script>
import { defineComponent, h } from 'vue'

import AppIcon from '@/components/ui/AppIcon/AppIcon'

export default defineComponent({
  name: 'ObjectivesEmptyHero',
  props: {
    isAlternate: {
      type: Boolean
    },

    height: {
      type: String,
      default: '128'
    },

    width: {
      type: String,
      default: '142'
    },

    name: {
      type: String,
      default: 'empty-state-hero'
    }
  },

  render() {
    return h(AppIcon, {
      iconName: this.isAlternate ? `${this.name}-alternate` : this.name,
      height: this.height,
      width: this.width
    })
  }
})
</script>
