<template>
  <div class="gp-GeneralPageToolbar">
    <portal-target
      :slot-props="{ tab: EVENT_SOURCES.DASHBOARD_GENERAL }"
      name="dashboard-interval-select"
    />
  </div>
  <div v-if="generalReport !== null" class="gp-GeneralPage">
    <DashboardItem
      :style="CARD_STYLES.AVG_PROGRESS"
      :subtitle="$t('dashboard.in_this_cycle')"
      :title="$t('dashboard.avg_progress')"
    >
      <span class="gp-Amount gp-Amount-whiteText">
        <AnimatedNumber
          v-slot="{ displayValue }"
          :end-amount="
            getSimplifiedNumber(generalReport.averageGrade)[PLURALIZATION_KEYS.FLOAT_NUMBER]
          "
        >
          {{
            displayValue +
            getSimplifiedNumber(generalReport.averageGrade)[PLURALIZATION_KEYS.SYMBOL]
          }}
        </AnimatedNumber>
        <sub class="gp-Amount_SubText"> % </sub>
      </span>
      <template #bottom>
        <IndicatorPanel
          :indicator-width="generalReport.averageGrade"
          background-color="rgba(var(--white-rgb), 0.1)"
          color="var(--white-color)"
          style="--height: 6px"
        />
      </template>
    </DashboardItem>

    <DashboardItem
      :data="generalReport.notStartedCount"
      :style="CARD_STYLES.NOT_STARTED"
      :subtitle="$t('dashboard.in_this_ws')"
      :title="$t('dashboard.objectives')"
    >
      <template #bottom>
        <ObjectiveStatus :status="OKR_STATUSES.NOT_STARTED" />
      </template>
    </DashboardItem>

    <DashboardItem
      :data="generalReport.onTrackCount"
      :style="CARD_STYLES.ON_TRACK"
      :subtitle="$t('dashboard.in_this_ws')"
      :title="$t('dashboard.objectives')"
    >
      <template #bottom>
        <ObjectiveStatus :status="OKR_STATUSES.ON_TRACK" />
      </template>
    </DashboardItem>
    <DashboardItem
      :data="generalReport.behindCount"
      :style="CARD_STYLES.BEHIND"
      :subtitle="$t('dashboard.in_this_ws')"
      :title="$t('dashboard.objectives')"
    >
      <template #bottom>
        <ObjectiveStatus :status="OKR_STATUSES.BEHIND" />
      </template>
    </DashboardItem>
    <DashboardItem
      :data="generalReport.atRiskCount"
      :style="CARD_STYLES.AT_RISK"
      :subtitle="$t('dashboard.in_this_ws')"
      :title="$t('dashboard.objectives')"
    >
      <template #bottom>
        <ObjectiveStatus :status="OKR_STATUSES.AT_RISK" />
      </template>
    </DashboardItem>
    <DashboardItem
      :data="generalReport.closedCount"
      :style="CARD_STYLES.CLOSED"
      :subtitle="$t('dashboard.in_this_ws')"
      :title="$t('dashboard.objectives')"
    >
      <template #bottom>
        <ObjectiveStatus :status="OKR_STATUSES.CLOSED" />
      </template>
    </DashboardItem>
    <DashboardItem
      :data="generalReport.abandonedCount"
      :style="CARD_STYLES.ABANDONED"
      :subtitle="$t('dashboard.in_this_ws')"
      :title="$t('dashboard.objectives')"
    >
      <template #bottom>
        <ObjectiveStatus :status="OKR_STATUSES.ABANDONED" />
      </template>
    </DashboardItem>
    <DashboardItem
      :style="CARD_STYLES.CYCLE_PASSED"
      :subtitle="
        $t('dashboard.interval_passed_percent', {
          cyclePassedPercent: generalReport.cyclePassedPercent
        })
      "
      :title="$t('dashboard.days_left_in_cycle')"
    >
      <span class="gp-Amount gp-Amount-whiteText">
        <AnimatedNumber v-slot="{ displayValue }" :end-amount="generalReport.dayLeft">
          {{ displayValue }}
        </AnimatedNumber>
      </span>
      <template #bottom>
        <IndicatorPanel
          :indicator-width="generalReport.cyclePassedPercent"
          background-color="rgba(var(--white-rgb), 0.1)"
          color="var(--white-color)"
          style="--height: 6px"
        />
      </template>
    </DashboardItem>
    <DashboardItem
      v-if="isShowJiraIssuesBox"
      :data="generalReport.jiraIssueLinked"
      :style="CARD_STYLES.JIRA_ISSUE_LINKED"
      :subtitle="$t('dashboard.linked')"
      :title="$t('dashboard.jira_issue_linked')"
      inline-text
    >
      <template #bottom>
        <IndicatorPanel
          :indicator-width="0"
          background-color="rgba(var(--white-rgb), 0.1)"
          color="var(--white-color)"
          style="--height: 2px"
        />
      </template>
    </DashboardItem>
    <DashboardItem
      :data="generalReport.objectivesWithoutChild"
      :style="CARD_STYLES.OBJECTIVES_WITHOUT_CHILD"
      :subtitle="nestedItemsSubtitle"
      :title="$t('dashboard.objectives')"
      inline-text
    >
      <template #bottom>
        <IndicatorPanel
          :indicator-width="0"
          background-color="rgba(var(--white-rgb), 0.1)"
          color="var(--white-color)"
          style="--height: 2px"
        />
      </template>
    </DashboardItem>
    <DashboardItem
      :data="generalReport.groups"
      :subtitle="$t('dashboard.in_this_ws')"
      :title="$t('field.groups.title')"
    />

    <DashboardItem
      :data="generalReport.people"
      :subtitle="$t('dashboard.in_this_ws')"
      :title="$t('dashboard.all_people')"
    />

    <DashboardItem
      v-for="item in generalReport.levelsObjectivesInfo"
      :key="item.levelName"
      :data="item.objectivesCount"
      :subtitle="$t('dashboard.objectives')"
      :title="`${item.levelName} Level`"
    />
  </div>
  <DashboardGeneralReportLoader v-else />
</template>

<script>
import { defineComponent } from 'vue'
import { mapState } from 'vuex'

import DashboardApiHandler from '@/api/dashboard'
import {
  EVENT_SOURCES,
  TAB_NAMES_FOR_TRACKING,
  trackUserOpenedDashboardsEvent
} from '@/tracking/amplitude-helpers'
import { CARD_STYLES } from '@/utils/dashboard'
import { handleError } from '@/utils/error-handling'
import {
  isJiraAppInjectionKey,
  isSalesforceAppInjectionKey,
  isWebAppInjectionKey
} from '@/utils/injection-keys'
import { OKR_STATUSES } from '@/utils/objectives'
import { getSimplifiedNumber, PLURALIZATION_KEYS } from '@/utils/pluralization'
import { PLUGIN_OPTIONS_KEYS } from '@root/template-options-keys'

import DashboardItem from '@/components/dashboard/DashboardItem'
import ObjectiveStatus from '@/components/objectives/ObjectiveStatus'
import AnimatedNumber from '@/components/ui/AnimatedNumber/AnimatedNumber'
import IndicatorPanel from '@/components/ui/IndicatorPanel/IndicatorPanel'
import DashboardGeneralReportLoader from '@/components/ui/SkeletonLoaders/DashboardGeneralReportLoader'

export default defineComponent({
  name: 'DashboardGeneral',

  components: {
    ObjectiveStatus,
    DashboardGeneralReportLoader,
    AnimatedNumber,
    DashboardItem,
    IndicatorPanel
  },

  inject: {
    isWebApp: {
      from: isWebAppInjectionKey
    },

    isSalesforceApp: {
      from: isSalesforceAppInjectionKey
    },

    isJiraApp: {
      from: isJiraAppInjectionKey
    }
  },

  inheritAttrs: false,

  props: {
    intervalId: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      generalReport: null
    }
  },

  computed: {
    OKR_STATUSES: () => OKR_STATUSES,

    EVENT_SOURCES: () => EVENT_SOURCES,

    CARD_STYLES: () => CARD_STYLES,

    PLURALIZATION_KEYS: () => PLURALIZATION_KEYS,

    ...mapState('pluginOptions', {
      isJiraConnected: state => state[PLUGIN_OPTIONS_KEYS.JIRA_CONNECTED]
    }),

    isShowJiraIssuesBox() {
      return this.isJiraApp || ((this.isWebApp || this.isSalesforceApp) && this.isJiraConnected)
    },

    nestedItemsSubtitle() {
      return this.isShowJiraIssuesBox
        ? this.$t('dashboard.no_linked_krs_nested_jira_issues')
        : this.$t('dashboard.no_linked_krs_nested')
    }
  },

  watch: {
    intervalId() {
      this.getGeneralReport()
    }
  },

  mounted() {
    this.getGeneralReport()
    trackUserOpenedDashboardsEvent({
      tab: TAB_NAMES_FOR_TRACKING.GENERAL
    })
  },

  methods: {
    getSimplifiedNumber,
    async getGeneralReport() {
      const api = new DashboardApiHandler()
      if (this.intervalId) {
        try {
          this.generalReport = await api.getGeneralReport({
            intervalId: this.intervalId,
            workspaceId: this.$route.params.workspaceId
          })
        } catch (error) {
          handleError({ error })
        }
      }
    }
  }
})
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';

.gp-GeneralPage {
  @include dashboard-card-layout();
  margin-right: auto;
}

.gp-Amount {
  font-family: $system-ui;
  font-size: $fs-56;
  font-weight: fw('medium');
  line-height: 64px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.gp-GeneralPageToolbar {
  margin: 0 0 32px 0;
}
.gp-Amount {
  font-family: $system-ui;
  font-size: $fs-56;
  font-weight: fw('medium');
  line-height: 64px;
  display: flex;
  align-items: center;
  gap: 4px;
  &-whiteText {
    color: $white;
  }
}
.gp-Amount_SubText {
  font-size: $fs-20;
  line-height: 24px;
  font-weight: fw('semi-bold');
  bottom: -0.7em;
}
</style>
