<template>
  <a class="ahl-Link" rel="noopener noreferrer" target="_blank" v-bind="$attrs">
    <AppIcon v-if="prependIcon" class="ahl-Link_Icon" height="24" icon-name="guide" width="24" />

    <span class="oboard-truncated-text">
      <slot />
    </span>
  </a>
</template>

<script setup>
import AppIcon from '@/components/ui/AppIcon/AppIcon'

defineOptions({
  name: 'AppHrefLink'
})

defineProps({
  prependIcon: {
    type: String,
    default: ''
  }
})
</script>

<style lang="scss" scoped>
.ahl-Link {
  display: inline-flex;
  align-items: center;
  gap: var(--gap, 8px);
  max-width: 100%;
  overflow: hidden;
}

.ahl-Link_Icon {
  flex-shrink: 0;
}
</style>
