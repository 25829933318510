<template>
  <div v-if="groupReport" class="dg-DashboardGroupPage">
    <div class="dg-GroupsOverview">
      <DashboardItem
        :style="CARD_STYLES.AVG_PROGRESS"
        :subtitle="
          allGroupsSelected
            ? $t('dashboard.across_all_the_groups')
            : $t('dashboard.across_all_the_group')
        "
        :title="$t('dashboard.avg_progress')"
      >
        <span class="dg-Amount dg-Amount-whiteText">
          <AnimatedNumber
            v-slot="{ displayValue }"
            :end-amount="
              getSimplifiedNumber(groupReport.progressInTheCycle)[PLURALIZATION_KEYS.FLOAT_NUMBER]
            "
          >
            {{
              displayValue +
              getSimplifiedNumber(groupReport.progressInTheCycle)[PLURALIZATION_KEYS.SYMBOL]
            }}
          </AnimatedNumber>
          <sub class="dg-Amount_SubText"> % </sub>
        </span>
        <template #bottom>
          <IndicatorPanel
            :indicator-width="groupReport.progressInTheCycle"
            background-color="rgba(var(--white-rgb), 0.1)"
            color="var(--white-color)"
            style="--height: 6px"
          />
        </template>
      </DashboardItem>

      <DashboardItem
        :data="groupReport.notStartedCount"
        :style="CARD_STYLES.NOT_STARTED"
        :subtitle="
          allGroupsSelected
            ? $t('dashboard.assigned_to_all_groups')
            : $t('dashboard.assigned_to_the_group')
        "
        :title="$t('okr_elements.objectives')"
      >
        <template #bottom>
          <ObjectiveStatus :status="OKR_STATUSES.NOT_STARTED" />
        </template>
      </DashboardItem>

      <DashboardItem
        :data="groupReport.onTrack"
        :style="CARD_STYLES.ON_TRACK"
        :subtitle="
          allGroupsSelected
            ? $t('dashboard.assigned_to_all_groups')
            : $t('dashboard.assigned_to_the_group')
        "
        :title="$t('okr_elements.objectives')"
      >
        <template #bottom>
          <ObjectiveStatus :status="OKR_STATUSES.ON_TRACK" />
        </template>
      </DashboardItem>
      <DashboardItem
        :data="groupReport.behind"
        :style="CARD_STYLES.BEHIND"
        :subtitle="
          allGroupsSelected
            ? $t('dashboard.assigned_to_all_groups')
            : $t('dashboard.assigned_to_the_group')
        "
        :title="$t('okr_elements.objectives')"
      >
        <template #bottom>
          <ObjectiveStatus :status="OKR_STATUSES.BEHIND" />
        </template>
      </DashboardItem>
      <DashboardItem
        :data="groupReport.atRisk"
        :style="CARD_STYLES.AT_RISK"
        :subtitle="
          allGroupsSelected
            ? $t('dashboard.assigned_to_all_groups')
            : $t('dashboard.assigned_to_the_group')
        "
        :title="$t('okr_elements.objectives')"
      >
        <template #bottom>
          <ObjectiveStatus :status="OKR_STATUSES.AT_RISK" />
        </template>
      </DashboardItem>
      <DashboardItem
        :data="groupReport.closedCount"
        :style="CARD_STYLES.CLOSED"
        :subtitle="
          allGroupsSelected
            ? $t('dashboard.assigned_to_all_groups')
            : $t('dashboard.assigned_to_the_group')
        "
        :title="$t('okr_elements.objectives')"
      >
        <template #bottom>
          <ObjectiveStatus :status="OKR_STATUSES.CLOSED" />
        </template>
      </DashboardItem>
      <DashboardItem
        :data="groupReport.abandonedCount"
        :style="CARD_STYLES.ABANDONED"
        :subtitle="
          allGroupsSelected
            ? $t('dashboard.assigned_to_all_groups')
            : $t('dashboard.assigned_to_the_group')
        "
        :title="$t('okr_elements.objectives')"
      >
        <template #bottom>
          <ObjectiveStatus :status="OKR_STATUSES.ABANDONED" />
        </template>
      </DashboardItem>

      <DashboardItem
        :data="groupReport.peopleWithOKR"
        :style="CARD_STYLES.PEOPLE_WITH_OKR"
        :subtitle="`${groupReport.peopleWithOKRPercent}%`"
        :title="$t('dashboard.people_with_okr')"
        inline-text
      >
        <template #bottom>
          <IndicatorPanel
            :indicator-width="groupReport.peopleWithOKRPercent"
            color="var(--white-color)"
            style="--height: 6px"
          />
        </template>
      </DashboardItem>

      <DashboardItem
        :data="groupReport.peopleWithoutOKR"
        :style="CARD_STYLES.PEOPLE_WITHOUT_OKR"
        :subtitle="`${groupReport.peopleWithoutOKRPercent}%`"
        :title="$t('dashboard.people_without_okr')"
        inline-text
      >
        <template #bottom>
          <IndicatorPanel
            :indicator-width="groupReport.peopleWithoutOKRPercent"
            background-color="rgba(var(--white-rgb), 0.1)"
            color="var(--white-color)"
            style="--height: 6px"
          />
        </template>
      </DashboardItem>
      <DashboardItem
        v-if="allGroupsSelected"
        key="allGroups"
        :data="groupReport.groups"
        :subtitle="$t('dashboard.in_this_ws')"
        :title="$t('field.groups.title')"
      />

      <DashboardItem
        v-else
        :data="groupReport.people"
        :subtitle="$t('dashboard.in_this_group')"
        :title="$t('dashboard.all_people')"
      />
    </div>

    <DashboardTableLoader v-if="isGroupReportLoading" />
    <template v-else>
      <TableExpandable
        v-if="filteredGroups.length > 0"
        ref="tableRef"
        :child-items="childItems"
        :columns="getTableColumns"
        :data="filteredGroups"
        :is-auto-collapse="false"
        :row-is-expandable="rowIsExpandable"
        item-value="groupId"
        offset-left="var(--page-left-padding)"
        offset-right="var(--page-right-padding)"
        sticky-header
        style="--offset-top: 54px; --height: calc(100% - 98px); --offset-contribute-top: 30px"
        @expand="getReportByUser"
      >
        <template #row-wrapper-after="{ childItems: childItemsRow, rowSlotData }">
          <template
            v-if="
              reportByUserLoaders[rowSlotData.row.groupId] &&
              isEmpty(childItemsRow[rowSlotData.row.groupId])
            "
          >
            <DashboardTableRowLoader
              v-for="row in getRowsTableLoader(rowSlotData.row.userCount)"
              :key="row.id"
              :row="row"
              class="dg-TableRowLoader"
              is-for-people
            />
          </template>
        </template>
        <template #header-cell="{ column }">
          <TableHeaderCellWithSorting
            v-model:sort-by="sortBy"
            :column="column"
            :sort-key="SORT_KEY"
            :sort-value="SORT_VALUE"
          />
        </template>
        <template #cell="{ item, columnKey, depth, index }">
          <template v-if="columnKey === TABLE_COLUMNS_KEYS.LABEL">
            <GlobalGroupNameCell
              v-if="depth === 0"
              :group="item"
              :labels-list="[GLOBAL_GROUPS_NAME_CELL_LABELS.USERS_COUNT]"
              color-key="groupColor"
              hide-actions
              hide-cipher
              hide-warning-message
              icon-key="groupIcon"
              label-key="groupName"
              @click="onGroupNameClick({ item, depth, index })"
            />

            <DashboardCellAssigneedOwner
              v-else
              :clickable="isLinkOnJiraProfileClickable"
              :item="item"
            />
          </template>

          <template v-else-if="columnKey === TABLE_COLUMNS_KEYS.OKR_COUNT">
            <DashboardCellOkrCount :item="item" :selected-table-view="selectedTableView" />
          </template>

          <template v-else-if="getTableCell[columnKey]">
            <div
              :class="{
                [`dg-OkrCounts-depth-${depth}`]: true
              }"
              class="dg-OkrCounts"
            >
              <DashboardCount
                v-for="type in columnKeys"
                :key="type + columnKey"
                :clickable="selectedTableView === STATUS_TABLE_VIEW"
                :route-to="
                  getRouteTo({
                    item,
                    gradeType: getTableCell[columnKey].gradeType,
                    OKRTypeIds: memoizeOkrTypeIds({ objectiveLevels, type }),
                    workspaceId,
                    intervalId
                  })
                "
                :style="getTableCell[columnKey].style"
                :value="
                  getTableCellValue({ item, type, valueKey: getTableCell[columnKey].valueKey })
                "
              />
            </div>
          </template>

          <template v-else-if="columnKey === TABLE_COLUMNS_KEYS.AVERAGE_TOTAL">
            <div
              :class="{
                [`dg-OkrCounts-depth-${depth}`]: true
              }"
              class="dg-OkrCounts"
            >
              <template v-for="type in columnKeys" :key="type + columnKey">
                <DashboardCellAvearageTotal
                  :item="item"
                  :type="type"
                  :with-styles="STATUS_TABLE_VIEW === selectedTableView"
                />
              </template>
            </div>
          </template>
        </template>
      </TableExpandable>

      <AppPagination
        v-if="filteredGroups.length > 0"
        :current-page="currentPage"
        :items-count="groupsTotal"
        :items-on-page="itemsOnPage"
        :items-shown="filteredGroups.length"
        :total-page="totalPage"
        items-name="field.groups.title"
        @update:items-on-page="itemsOnPage = $event"
        @update:current-page="currentPage = $event"
      />

      <template v-if="filteredGroups.length === 0 && !isGroupReportLoading">
        <OkrElementsEmpty v-if="isFiltersUsed" :list-type="null">
          {{ emptyStateTitle }}
        </OkrElementsEmpty>

        <EmptyStateDashboard v-else>
          {{ emptyStateTitle }}
        </EmptyStateDashboard>
      </template>
    </template>
  </div>
  <DashboardGroupsReportLoader v-else />
</template>

<script setup>
import { isEmpty } from 'lodash'
import { computed, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

import DashboardApiHandler from '@/api/dashboard'
import LevelApiHandler from '@/api/level'
import { useGetFilteredData } from '@/composables/fetchingData'
import { useGetPaginationData } from '@/composables/pagination'
import {
  CARD_STYLES,
  columnKeys,
  getRouteTo,
  getRowsTableLoader,
  getTableCellValue,
  STATUS_TABLE_VIEW,
  useGetPerformanceDashboardColumns,
  useGetReportByUser,
  useLinkOnProfileAvailability
} from '@/utils/dashboard'
import { handleError } from '@/utils/error-handling'
import { GLOBAL_GROUPS_NAME_CELL_LABELS } from '@/utils/global-groups'
import { memoizeOkrTypeIds, OKR_STATUSES } from '@/utils/objectives'
import { DEFAULT_VALUE_FOR_FILTER } from '@/utils/okr-elements/filters'
import { PERFORMANCE_DASHBOARD_CELLS } from '@/utils/performance-dashboard'
import { getSimplifiedNumber, PLURALIZATION_KEYS } from '@/utils/pluralization'
import { getSelectWithSelectAllApiParameter, selectAllIsSelected } from '@/utils/select'
import { ascSortValue } from '@/utils/sort-options'
import { TABLE_COLUMNS_KEYS } from '@/utils/table-columns'

import DashboardCellAssigneedOwner from '@/components/dashboard/DashboardCellAssigneedOwner'
import DashboardCellAvearageTotal from '@/components/dashboard/DashboardCellAvearageTotal'
import DashboardCellOkrCount from '@/components/dashboard/DashboardCellOkrCount'
import DashboardCount from '@/components/dashboard/DashboardCount'
import DashboardItem from '@/components/dashboard/DashboardItem'
import EmptyStateDashboard from '@/components/dashboard/EmptyStateDashboard'
import TableHeaderCellWithSorting from '@/components/dashboard/TableHeaderCellWithSorting'
import GlobalGroupNameCell from '@/components/global-groups/GlobalGroupNameCell'
import OkrElementsEmpty from '@/components/home-page/OkrElementsEmpty'
import ObjectiveStatus from '@/components/objectives/ObjectiveStatus'
import AnimatedNumber from '@/components/ui/AnimatedNumber/AnimatedNumber'
import AppPagination from '@/components/ui/AppPagination/AppPagination'
import IndicatorPanel from '@/components/ui/IndicatorPanel/IndicatorPanel'
import DashboardGroupsReportLoader from '@/components/ui/SkeletonLoaders/DashboardGroupsReportLoader'
import DashboardTableLoader from '@/components/ui/SkeletonLoaders/DashboardTableLoader'
import DashboardTableRowLoader from '@/components/ui/SkeletonLoaders/DashboardTableRowLoader'
import TableExpandable from '@/components/ui/TableExpandable/TableExpandable'

const dashboardApi = new DashboardApiHandler()

defineOptions({
  name: 'DashboardGroups'
})
const props = defineProps({
  intervalId: {
    type: Number,
    required: true
  },

  selectedAssigneeIds: {
    type: Array,
    default: () => DEFAULT_VALUE_FOR_FILTER
  },

  selectedGroupIds: {
    type: Array,
    default: () => DEFAULT_VALUE_FOR_FILTER
  },

  searchString: {
    type: String,
    default: ''
  },

  selectedTableView: {
    type: String,
    default: STATUS_TABLE_VIEW
  }
})

const {
  START_PAGE,
  currentPage,
  itemsOnPage,
  total: groupsTotal,
  totalPage
} = useGetPaginationData()

const childItems = ref({})
const isGroupReportLoading = ref(false)
const groupReport = ref(null)
const reportForWorkspace = ref(null)

const { LABEL } = TABLE_COLUMNS_KEYS

const SORT_VALUE = 'sortValue'
const SORT_KEY = 'sortKey'

const sortBy = ref({
  [SORT_KEY]: LABEL,
  [SORT_VALUE]: ascSortValue
})

const store = useStore()
const systemSettings = computed(() => store.state.system.settings)

const getTableColumns = computed(() => {
  const { data } = useGetPerformanceDashboardColumns({
    selectedTableView: props.selectedTableView,
    systemSettings: systemSettings.value
  })
  return data.value
})

const getTableCell = PERFORMANCE_DASHBOARD_CELLS

const isFiltersUsed = computed(() => {
  return !allOwnersSelected.value || !allGroupsSelected.value || !searchIsEmpty.value
})

const { t } = useI18n()
const emptyStateTitle = computed(() => {
  if (isFiltersUsed.value) {
    const isOnlyGroupsFilter =
      allOwnersSelected.value && searchIsEmpty.value && !allGroupsSelected.value
    return isOnlyGroupsFilter ? t('dashboard.no_objectives_in_group') : t('groups.no_groups')
  } else {
    return t('dashboard.no_groups')
  }
})

watch(
  () => [props.selectedAssigneeIds, props.selectedGroupIds],
  () => {
    getReportByGroup()
  },

  { deep: true }
)

watch(
  () => itemsOnPage.value,
  () => {
    currentPage.value = START_PAGE
  }
)
const workspaceId = computed(() => store.state.workspaces.workspaceId)
watch(
  () => props.intervalId,
  async () => {
    if (reportForWorkspace.value !== workspaceId.value) {
      reportForWorkspace.value = workspaceId.value
    }
    await getReportByGroup()
  }
)

const getLevelsForFilter = async workspaceId => {
  const levelApi = new LevelApiHandler()
  try {
    const levels = await levelApi.getLevelsForFilter({
      workspaceId
    })
    await setLevels(levels)
  } catch (error) {
    handleError({ error })
  }
}

const setLevels = async levels => {
  await store.dispatch('objectives/setLevels', {
    levels
  })
}

const objectiveLevels = computed(() => store.state.objectives.levels)

const getReportByGroup = async () => {
  try {
    isGroupReportLoading.value = true
    groupReport.value = await dashboardApi.getReportByGroup({
      groupIds: !allGroupsSelected.value
        ? getSelectWithSelectAllApiParameter(props.selectedGroupIds)
        : null,
      intervalId: props.intervalId,
      workspaceId: workspaceId.value,
      userIds: getSelectWithSelectAllApiParameter(props.selectedAssigneeIds)
    })
    groupsTotal.value = groupReport.value.groupsInfo.length
  } catch (error) {
    handleError({ error })
  } finally {
    isGroupReportLoading.value = false
  }
}
const reportByUserLoaders = ref({})
const getReportByUser = async row => {
  try {
    reportByUserLoaders.value[row.item.groupId] = true
    const data = await useGetReportByUser({
      groupIds: [row.item.groupId],
      selectedAssigneeIds: props.selectedAssigneeIds,
      intervalId: props.intervalId,
      workspaceId: workspaceId.value
    })
    childItems.value[row.item.groupId] = data.map(user => ({
      ...user,
      label: user.ownerName
    }))
  } finally {
    reportByUserLoaders.value[row.item.groupId] = false
  }
}

const tableRef = ref(null)
const onGroupNameClick = data => {
  tableRef.value.expand(data)
}

const allGroupsSelected = computed(() => {
  return selectAllIsSelected(props.selectedGroupIds)
})

const allOwnersSelected = computed(() => {
  return selectAllIsSelected(props.selectedAssigneeIds)
})

const searchIsEmpty = computed(() => {
  return !props.searchString
})

const rowIsExpandable = computed(() => {
  const result = {}
  filteredGroups.value.forEach(group => {
    result[group.groupId] = group.userCount > 0
  })
  return result
})

const filteredGroups = computed(() => {
  return useGetFilteredData({
    data: groupReport,
    options: groupReport.value.groupsInfo,
    sortBy: sortBy.value[SORT_VALUE],
    searchString: props.searchString,
    currentPage: currentPage.value,
    itemsOnPage: itemsOnPage.value
  })
})

onMounted(() => {
  getLevelsForFilter(workspaceId.value)
  getReportByGroup()
})

const { isLinkOnJiraProfileClickable } = useLinkOnProfileAvailability()
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';
.dg-GroupsOverview {
  @include dashboard-card-layout();
  margin: 0 auto 32px 0;
}

.dg-Amount {
  font-family: $system-ui;
  font-size: $fs-56;
  font-weight: fw('medium');
  line-height: 64px;
  display: flex;
  align-items: center;
  gap: 4px;
  &-whiteText {
    color: $white;
  }
}
.dg-Amount_SubText {
  font-size: $fs-20;
  line-height: 24px;
  font-weight: fw('semi-bold');
  bottom: -0.7em;
}

.dg-Amount {
  font-family: $system-ui;
  font-size: $fs-56;
  font-weight: fw('medium');
  line-height: 64px;
  display: flex;
  align-items: center;
  gap: 4px;
  &-whiteText {
    color: $white;
  }
}
.dg-Amount_SubText {
  font-size: $fs-20;
  line-height: 24px;
  font-weight: fw('semi-bold');
  bottom: -0.7em;
}

.dg-OkrCounts {
  display: flex;
  flex-direction: column;
  gap: 14px;
  text-align: right;
  font-size: $fs-14;
  font-style: normal;
  line-height: 20px;
  cursor: default;

  &-depth-0 {
    font-weight: fw('semi-bold');
    font-size: $fs-16;
  }

  &:deep(.dc-DashboardCount) {
    text-align: right;
  }
}

.dg-TableRowLoader {
  --offset-left: var(--page-left-padding);
  --offset-right: var(--page-right-padding);
  --offset: 64px;
  --border-offset: 64px;
  --cl-offset: 12px;
  --cl-width: 44px;
  padding: 10px var(--offset-right, 0px) 10px calc(var(--offset, 0px) + var(--offset-left, 0px));
}
</style>

<style lang="scss">
.dg-DashboardGroupPage {
  .te-Row-depth-1 {
    .te-Cell-label {
      // margin-left: 13px;
    }
  }
  .te-Row-depth-0 {
    .te-Cell-averageTotal {
      font-weight: fw('semi-bold');
    }
  }
  .te-Cell-averageTotal {
    padding-right: 20px;
  }
}
</style>
