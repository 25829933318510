<template>
  <span :style="gradeValueStyles" class="okg-OkrGrade" @click="$emit('click', $event)">
    <span
      :class="{ 'okg-OkrGrade_Value-next': type === OKR_GRADE_TYPES.DEFAULT_NEXT }"
      class="okg-OkrGrade_Value"
    >
      <span v-if="additionalWrapperOnValue" class="okg-OkrGradeValueWrapper">
        {{ gradeDisplayValue }}%
      </span>
      <template v-else> {{ gradeDisplayValue }}% </template>
    </span>

    <template v-if="!showOnlyValue">
      <template v-if="type === OKR_GRADE_TYPES.DEFAULT">
        <span v-if="showMultiplierLocal" class="okg-OkrGrade_Multiplier">
          ×{{ weightMultiplier }}
        </span>

        <template v-else>
          <span v-if="!showEditButton" class="okg-OkrGrade_MultiplierPlug" />
        </template>
      </template>

      <template v-else>
        <span
          v-if="alwaysShowWeight || showMultiplierLocal"
          :class="{
            'okg-OkrGrade_MultiplierNext-dim': resolvedDimStatus,
            'okg-OkrGrade_MultiplierNext-no-weight-icon': hideWeightIcon,
            'okg-OkrGrade_MultiplierNext-with-manual-mode-icon': isManualGradeMode
          }"
          class="okg-OkrGrade_MultiplierNext"
        >
          <AppIcon
            :height="multiplierIconSize"
            :icon-name="multiplierIconName"
            :width="multiplierIconSize"
            class="okg-MultiplierIcon"
          />
          {{ resolvedWeightMultiplier }}
          <AppIcon
            v-if="!hideWeightIcon"
            class="okg-MultiplierIcon"
            height="24"
            icon-name="custom-weights"
            width="24"
          />
        </span>
        <AppIcon
          v-if="isManualGradeMode"
          class="okg-ManualModeIcon"
          height="24"
          icon-name="period-mode-manual-next"
          width="24"
        />
        <template v-if="!(alwaysShowWeight || showMultiplierLocal)">
          <span
            v-if="!showEditButton"
            :class="{ 'okg-OkrGrade_MultiplierPlug-next-no-weight-icon': hideWeightIcon }"
            class="okg-OkrGrade_MultiplierPlug-next"
          />
        </template>
      </template>

      <AppButton
        v-if="!readonly"
        v-show="showEditButton"
        height="16"
        icon="edit-grey"
        size="sm"
        type="link"
        width="16"
      />
    </template>
  </span>
</template>

<script>
import { isNull } from 'lodash'
import { defineComponent } from 'vue'

import { OKR_GRADE_TYPES } from '@/utils/components-configurations/okr-grade'
import { objectiveIsJiraTask } from '@/utils/objectives'
import { GRADE_MODES } from '@/utils/okr-element-values'
import {
  getGradeDisplayValue,
  getGradeColorStyle,
  showGradeMultiplier
} from '@/utils/okr-elements/grade'
import {
  CONTRIBUTE_DEFAULT_WEIGHT_MULTIPLIER,
  UNCONTRIBUTE_DEFAULT_WEIGHT_MULTIPLIER
} from '@/utils/okr-weights-helpers'
import { numberOrNullProp } from '@/utils/prop-validators'

import AppButton from '@/components/ui/AppButton/AppButton'
import AppIcon from '@/components/ui/AppIcon/AppIcon'

export default defineComponent({
  name: 'OkrGrade',
  components: {
    AppIcon,
    AppButton
  },

  props: {
    grade: {
      type: Number,
      required: true
    },

    weight: {
      required: true,
      validator: value => numberOrNullProp(value)
    },

    weightMultiplier: {
      required: true,
      validator: value => numberOrNullProp(value)
    },

    color: {
      type: String,
      required: true
    },

    showMultiplier: {
      type: Boolean,
      default: true
    },

    readonly: {
      type: Boolean
    },

    showEditButton: {
      type: Boolean
    },

    showOnlyValue: {
      type: Boolean
    },

    hideWeightIcon: {
      type: Boolean
    },

    multiplierIconSize: {
      type: [String, Number],
      default: 24
    },

    multiplierIconName: {
      type: String,
      default: 'custom-weights-multiplier'
    },

    isContribute: {
      type: Boolean
    },

    type: {
      type: String,
      default: OKR_GRADE_TYPES.DEFAULT,
      validator: v => Object.values(OKR_GRADE_TYPES).includes(v)
    },

    weightIsDimmed: {
      type: Boolean
    },

    alwaysShowWeight: {
      type: Boolean
    },

    additionalWrapperOnValue: {
      type: Boolean
    },

    gradeMode: {
      type: Number,
      default: GRADE_MODES.AUTO
    },

    typeId: {
      type: Number,
      default: null
    }
  },

  emits: { click: null },

  computed: {
    OKR_GRADE_TYPES: () => OKR_GRADE_TYPES,

    isTask() {
      return objectiveIsJiraTask({ typeId: this.typeId })
    },

    isManualGradeMode() {
      return this.gradeMode === GRADE_MODES.MANUAL && this.isTask
    },

    resolvedDimStatus() {
      return this.weightIsDimmed || !this.resolvedWeightMultiplier
    },

    resolvedWeightMultiplier() {
      if (this.type === OKR_GRADE_TYPES.DEFAULT) {
        return this.weightMultiplier
      } else {
        const isAutoWeights = isNull(this.weight) && isNull(this.weightMultiplier)
        const resolvedValue = this.isContribute
          ? CONTRIBUTE_DEFAULT_WEIGHT_MULTIPLIER
          : UNCONTRIBUTE_DEFAULT_WEIGHT_MULTIPLIER
        return isAutoWeights ? resolvedValue : this.weightMultiplier
      }
    },

    gradeDisplayValue() {
      return getGradeDisplayValue(this.grade)
    },

    gradeValueStyles() {
      return getGradeColorStyle(this.color)
    },

    showMultiplierLocal() {
      const { showMultiplier, weight, readonly, showEditButton, weightMultiplier } = this
      const defaultCondition = showMultiplier && showGradeMultiplier(weight, weightMultiplier)
      return readonly ? defaultCondition : defaultCondition && !showEditButton
    }
  }
})
</script>

<style lang="scss" scoped>
.okg-OkrGrade {
  display: flex;
  align-items: center;
  line-height: normal;
  // height: 24px; // keep height the same with and without button
}

.okg-OkrGrade_Value {
  min-width: 37px;
  text-align: right;
  max-width: 40px;
  overflow: hidden;
  text-overflow: ellipsis;

  &-next {
    font-weight: fw('semi-bold');
    font-size: 14px;
    font-family: $system-ui;
  }
}

.okg-OkrGrade_Multiplier {
  color: rgba($placeholder-color, 0.7);
  font-size: $fs-13;
  display: flex;
  align-items: center;
  width: 26px;
}

.okg-OkrGrade_MultiplierNext {
  display: flex;
  align-items: center;
  width: 75px;
  font-size: $fs-14;
  line-height: normal; //20px;
  font-weight: fw('semi-bold');

  &:not(&-dim) {
    color: $dark-1;
  }

  &-dim {
    color: $grey-1-next;
  }

  &-no-weight-icon {
    width: 51px;
  }
  &-with-manual-mode-icon {
    width: auto;
  }
}
.okg-ManualModeIcon {
  color: $grey-1-next;
  margin-left: 4px;
}
.okg-MultiplierIcon,
.okg-ManualModeIcon {
  flex: 0 0 auto;
}

.okg-OkrGrade_MultiplierPlug {
  display: block;
  pointer-events: none;

  &:not(&-next) {
    width: 26px;
  }

  &-next {
    width: 75px;

    &-no-weight-icon {
      width: 51px;
    }
  }
}
</style>
