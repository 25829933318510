<template>
  <AppButton
    :class="{ 'wmt-Button-disabled': disabled }"
    :disable="disabled"
    class="wmt-Button"
    icon="custom-weights"
    size="sm"
    type="ghost-next"
    @click="onClick"
  >
    {{ $t('objective.btn.custom_weights') }}
  </AppButton>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'WeightsModalTrigger'
})
</script>

<script setup>
import AppButton from '@/components/ui/AppButton/AppButton'

const emit = defineEmits({ click: null })

const props = defineProps({
  disabled: {
    type: Boolean
  }
})

const onClick = () => {
  if (!props.disabled) {
    emit('click')
  }
}
</script>

<style lang="scss" scoped>
.wmt-Button {
  font-weight: fw('regular');
  color: $dark-2;
  padding: 0 8px 0 4px;

  &:deep(.svg-icon) {
    border-radius: $border-radius-sm-next;
  }

  &:hover {
    color: $primary-color-next;
    background-color: transparent;
    &:deep(.svg-icon) {
      background-color: $grey-3-next;
    }
  }

  &:active {
    background-color: transparent;
    &:deep(.svg-icon) {
      background-color: darken($grey-3-next, 10%);
    }
  }

  &-disabled {
    color: $grey-1-next;

    &:hover {
      color: $grey-1-next;

      &:deep(.svg-icon) {
        background-color: transparent;
      }
    }

    &:active {
      color: $grey-1-next;

      &:deep(.svg-icon) {
        background-color: transparent;
      }
    }
  }
}
</style>
