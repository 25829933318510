<template>
  <div class="bis-BillingInformationPage">
    <AppButton class="bis-BackBtn" type="link-next" @click="gotToBilling">
      {{ t('subscription.back_to_billing') }}
    </AppButton>
    <PageContentHeader
      :level="3"
      :max-width="HEADER_SIZES.LG"
      :title="t('subscription.billing_address')"
      class="bl-PageContentHeader"
      no-margin
      style="--padding-top: 29px"
    />
    <div class="bis-Wrapper">
      <FormFieldNext :label="t('users.table_header_name') + '*'">
        <AppInput
          v-model="customerInfo.billingName"
          :is-error="checkIsError(NAME)"
          :loading="dataFetchLoading"
          :max-length="256"
          size="xlg"
          style-type="primary"
        />
      </FormFieldNext>
      <FormFieldNext :label="t('user.preferences.user.email.label') + '*'">
        <AppInput
          v-model="customerInfo.billingEmail"
          :is-error="checkIsError(EMAIL)"
          :loading="dataFetchLoading"
          :max-length="256"
          size="xlg"
          style-type="primary"
          @change="validateEmail"
        />
      </FormFieldNext>
      <FormFieldNext :label="t('subscription.address') + '*'">
        <div class="bis-AddressWrapper">
          <AppSelect
            v-model="customerInfo.billingAddressCountry"
            :is-error="checkIsError(BILLING_ADDRESS_COUNTRY)"
            :loading="dataFetchLoading"
            :offset="[0, 0]"
            :options="countryCodesOptions"
            :search-function="
              value => localSearch({ value, options: countryCodesOptions, label: 'name' })
            "
            dropdown-search
            item-label="name"
            item-value="code"
            skeleton-loader
            skeleton-loader-height="100%"
            skeleton-loader-width="100%"
            style="--select-skeleton-left: 0; --select-skeleton-top: 0"
            @change="getStateCodes"
          >
            <template #button-content="{ option }">
              <span v-if="option">{{ option.name }}</span>
              <span v-else>{{ t('subscription.country') }}</span>
            </template>
          </AppSelect>
          <AppInput
            v-model="customerInfo.billingAddressLine1"
            :loading="dataFetchLoading"
            :max-length="256"
            :placeholder="$t('subscription.address_line_1')"
            size="xlg"
            style-type="primary"
          />
          <AppInput
            v-model="customerInfo.billingAddressLine2"
            :loading="dataFetchLoading"
            :max-length="256"
            :placeholder="$t('subscription.address_line_2')"
            size="xlg"
            style-type="primary"
          />
          <AppInput
            v-model="customerInfo.billingAddressCity"
            :loading="dataFetchLoading"
            :max-length="256"
            :placeholder="$t('subscription.city')"
            size="xlg"
            style-type="primary"
          />
          <SkeletonItem v-if="dataFetchLoading" height="40px" width="100%"></SkeletonItem>
          <AppSelect
            v-else
            v-model="customerInfo.billingAddressState"
            :loading="dataFetchLoading"
            :offset="[0, 0]"
            :options="stateCodesOptions"
            :search-function="
              value => localSearch({ value, options: stateCodesOptions, label: 'name' })
            "
            dropdown-search
            item-label="name"
            item-value="code"
            skeleton-loader-height="100%"
            skeleton-loader-width="100%"
            style="--select-skeleton-left: 0; --select-skeleton-top: 0"
          >
            <template #button-content="{ option }">
              <span v-if="option">{{ option.name }}</span>
              <span v-else>{{ t('subscription.state') }}</span>
            </template>
          </AppSelect>
          <AppInput
            v-model="customerInfo.billingAddressPostalCode"
            :loading="dataFetchLoading"
            :max-length="256"
            :placeholder="$t('subscription.postal_code')"
            size="xlg"
            style-type="primary"
          />
        </div>
      </FormFieldNext>
      <FormFieldNext :label="t('subscription.phone_number')">
        <SkeletonItem v-if="dataFetchLoading" height="40px" width="100%" />
        <AppInputNumberNext
          v-else
          v-model="customerInfo.billingPhone"
          :digit-max-length="12"
          allow-empty-value
          class="bis-PhoneInput"
          style="--font-size: 16px; --font-weight: 400"
        />
      </FormFieldNext>

      <AppButton :loading="saveBtnLoading" class="bis-SaveBtn" @click="saveCustomerInfo">
        {{ t('create.objective.btn.save') }}
      </AppButton>
    </div>
  </div>
</template>

<script setup>
import { has, isNull } from 'lodash'
import { onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

import BillingApiHandler from '@/api/billing'
import { ROUTE_NAMES } from '@/routes/route-helpers'
import { tracker } from '@/tracking/amplitude'
import { EVENT_CATEGORIES } from '@/tracking/amplitude-helpers'
import { HEADER_SIZES } from '@/utils/components-configurations/page-content-header'
import { handleError } from '@/utils/error-handling'
import { showNotify } from '@/utils/notify'
import { localSearch } from '@/utils/objectives'

import FormFieldNext from '@/components/form/FormFieldNext'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppInput from '@/components/ui/AppInput/AppInput'
import AppInputNumberNext from '@/components/ui/AppInputNumberNext/AppInputNumberNext'
import AppSelect from '@/components/ui/AppSelect/AppSelect'
import PageContentHeader from '@/components/ui/PageContentHeader/PageContentHeader'
import SkeletonItem from '@/components/ui/SkeletonLoaders/SkeletonItem'

const { t } = useI18n()

const router = useRouter()

const gotToBilling = () => {
  router.push({ name: ROUTE_NAMES.SETTINGS_BILLING_SETTINGS })
}

const dataFetchLoading = ref(true)
const saveBtnLoading = ref(false)
const customerInfo = ref({
  billingName: '',
  billingEmail: '',
  billingPhone: '',
  billingAddressLine1: '',
  billingAddressLine2: '',
  billingAddressCity: '',
  billingAddressState: null,
  billingAddressPostalCode: '',
  billingAddressCountry: null
})
const getCustomerInfo = async () => {
  const BillingApi = new BillingApiHandler()
  try {
    const data = await BillingApi.getCustomerInfo()

    for (const key in data) {
      if (!isNull(data[key]) && has(customerInfo.value, key)) {
        customerInfo.value[key] = data[key]
      }
    }
  } catch (error) {
    handleError({ error })
  }
}

const countryCodesOptions = ref([])
const getCountryCodes = async () => {
  const BillingApi = new BillingApiHandler()
  try {
    const { countryCodes } = await BillingApi.getCountryCodes()
    countryCodesOptions.value = countryCodes
  } catch (error) {
    handleError({ error })
  }
}

onMounted(async () => {
  try {
    dataFetchLoading.value = true
    await Promise.all([getCustomerInfo(), getCountryCodes()])
    await getStateCodes()
  } finally {
    dataFetchLoading.value = false
  }
})

const invalidFields = ref([])

const validateEmail = () => {
  const email = customerInfo.value.email
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  if (!emailRegex.test(email)) {
    invalidFields.value.push(EMAIL)
  }
}

const NAME = 'billingName'
const EMAIL = 'billingEmail'
const BILLING_ADDRESS_COUNTRY = 'billingAddressCountry'

const validate = () => {
  const requiredFields = [NAME, EMAIL, BILLING_ADDRESS_COUNTRY]
  invalidFields.value = requiredFields.filter(field => !customerInfo.value[field])
}

const saveCustomerInfo = async () => {
  validate()
  if (invalidFields.value.length) return

  saveBtnLoading.value = true
  const BillingApi = new BillingApiHandler()
  try {
    await BillingApi.saveCustomerInfo(customerInfo.value)
    showNotify()
    tracker.logEvent('billing details updated', {
      category: EVENT_CATEGORIES.BILLING
    })
  } catch (error) {
    handleError({ error })
  } finally {
    saveBtnLoading.value = false
  }
}

watch(
  () => customerInfo.value,
  () => {
    invalidFields.value = []
  },
  { deep: true }
)

const checkIsError = field => invalidFields.value.find(invalidField => invalidField === field)

const stateCodesOptions = ref([])
const getStateCodes = async () => {
  const BillingApi = new BillingApiHandler()
  if (!customerInfo.value.billingAddressCountry) return
  try {
    const { stateCodes } = await BillingApi.getStateCodes({
      countryCode: customerInfo.value.billingAddressCountry
    })
    stateCodesOptions.value = stateCodes
  } catch (error) {
    handleError({ error })
  }
}
</script>

<style lang="scss" scoped>
.bis-BillingInformationPage {
  padding-bottom: $page-bottom-padding;
}

.bis-BackBtn {
  padding: 32px $page-right-padding 16px $page-left-padding;
}

.bis-Wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: $page-width-sm-next;
  padding: 0 $page-right-padding 0 $page-left-padding;
}

.bis-AddressWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.bis-SaveBtn {
  width: fit-content;
}

.bis-PhoneInput {
  &:deep(.ain-InputWrapper) {
    width: 100%;
  }
}
</style>
