<template>
  <AppUnavailablePageLayout>
    <div class="te-Content">
      <slot name="logo">
        <AppLogo class="te-Logo" height="40px" width="143px" />
      </slot>

      <AppTitle :level="1" class="te-Title" disable-margin>
        <slot>
          {{ $t('subscription.license_inactive') }}
        </slot>
      </AppTitle>
      <span class="te-SubTitle">{{ $t('subscription.contact_your_manager') }}</span>
    </div>
  </AppUnavailablePageLayout>
</template>

<script setup>
import AppUnavailablePageLayout from '@shared-modules/components/AppUnavailablePageLayout'

import AppLogo from '@/components/AppLogo'
import AppTitle from '@/components/ui/AppTitle/AppTitle'
</script>

<style lang="scss" scoped>
.te-Logo {
  margin: 0 0 80px 0;
}

.te-Title {
  font-size: $fs-56;
  margin: 0 0 20px 0;
}

.te-SubTitle {
  color: $dark-3;
}

.te-Content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: auto;
  height: 100%;
  padding: 40px;
}
</style>
