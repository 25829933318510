<template>
  <div class="otf-Option">
    <slot name="icon">
      <OkrIcon :objective="levelToOkrIconElement(option)" :size="MD" />
    </slot>
    <span class="otf-Option_Label">
      {{ option.name }}
    </span>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'OkrTypeFieldOption'
})
</script>

<script setup>
import { SIZES } from '@/utils/components-configurations/okr-icon'
import { levelToOkrIconElement } from '@/utils/objectives'

import OkrIcon from '@/components/objectives/items/OkrIcon'

const { MD } = SIZES

defineProps({
  option: {
    type: Object,
    required: true
  }
})
</script>

<style lang="scss" scoped>
.otf-Option {
  display: flex;
  align-items: center;
  gap: 8px;

  max-width: 100%;
  color: $dark-1;
}

.otf-Option_Label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
