import { ref } from 'vue'
import { useStore } from 'vuex'

import WebAppUsersApiHandler from '@/api/web-app-users'

export const useRenameUser = ({ initialName = '' } = {}) => {
  const prevName = ref(initialName)
  const isNameValid = ref(true)
  const name = ref(initialName)
  const loading = ref(false)

  const store = useStore()

  const onRenameUser = async () => {
    const resolvedName = name.value.trim()
    isNameValid.value = resolvedName.length > 0

    if (isNameValid.value && resolvedName !== prevName.value) {
      loading.value = true
      const api = new WebAppUsersApiHandler()

      try {
        const { name: newName } = await api.renameUser({ name: resolvedName })
        await store.dispatch('system/updateUserName', newName)
        prevName.value = newName
        // eslint-disable-next-line no-useless-catch
      } catch (error) {
        isNameValid.value = false
        throw error
      } finally {
        loading.value = false
      }
    }
  }

  return {
    name,
    isNameValid,
    onRenameUser,
    loading
  }
}
