import { has, isEmpty } from 'lodash'

import {
  createQueryForExternalLink,
  getDefaultAppRoute,
  getHashFromLocation,
  OBOARD_CLOUD_PLUGIN_ROUTE,
  removeUnnecessarySlashes
} from '@/utils/external-link-helper'
import { copyToClipboard } from '@/utils/general'
import { OKR_FORM_VIEWS } from '@/utils/objectives'
import { getAtlassianBaseUrl } from '@jira/util'
import { IS_DEVELOPMENT } from '@root/app-modes'

export const createdObjectiveModalNotificationActions = {
  OPEN: {
    id: 0,
    label: 'action.open',
    closeOnHandle: true
  },
  COPY_LINK: {
    id: 1,
    label: 'breadcrumb.copy_link',
    closeOnHandle: true,
    handler: ({
      workspaceId,
      createdElement,
      isPluginServer = false,
      isJiraApp = true,
      isWebApp = false
    }) => {
      if (!String(workspaceId)) {
        throw new Error('workspaceId is required')
      }

      let baseUrl = getAtlassianBaseUrl()

      if (isWebApp) {
        baseUrl = `${location.origin}/${getHashFromLocation()}`
      }

      const composedQueryParameter = createQueryForExternalLink({
        ...createdElement,
        isJiraApp
      })

      const DEFAULT_APP_ROUTE = getDefaultAppRoute({
        workspaceId: workspaceId,
        query: composedQueryParameter
      })

      let oboardPluginRoute = OBOARD_CLOUD_PLUGIN_ROUTE

      if (IS_DEVELOPMENT || isPluginServer || isWebApp) {
        oboardPluginRoute = ''
      }

      const url = [baseUrl, oboardPluginRoute, DEFAULT_APP_ROUTE]
        .map(removeUnnecessarySlashes)
        .filter(Boolean)
        .join('/')

      copyToClipboard(url)
    }
  }
}

export const getCreatedElementNotificationTitle = elementType => {
  const { KR, OBJECTIVE, LINK_JIRA_ISSUE } = OKR_FORM_VIEWS

  const titles = {
    [KR]: 'create.keyResult.success_message',
    [LINK_JIRA_ISSUE]: 'create.task.success_message',
    [OBJECTIVE]: 'create.objective.success_title'
  }

  return titles[elementType] || 'create.objective.success_title'
}

export const checkIsShowNotification = eventData => {
  return {
    OBJECTIVE:
      has(eventData, 'isChildModal') &&
      !eventData.isChidlModal &&
      !isEmpty(eventData.createdObjective),
    KR: !isEmpty(eventData.createdKR),
    LINK_JIRA_ISSUE: !isEmpty(eventData.createdTasks)
  }
}

export const NOTIFICATION_CHANNEL_ATTRIBUTES_TYPE_IDS = {
  SLACK_WEBHOOKS: 1,
  TEAMS_WEBHOOKS: 2,
  DISCORD_WEBHOOKS: 3
}

export const NOTIFICATION_CHANNELS = {
  INBOX: {
    typeId: 1,
    attributes: {},
    guideLink: null,
    title: 'Inbox'
  },
  EMAIL: {
    typeId: 2,
    attributes: {},
    guideLink: null,
    title: 'Email'
  },
  SLACK: {
    typeId: 3,
    attributes: {
      [NOTIFICATION_CHANNEL_ATTRIBUTES_TYPE_IDS.SLACK_WEBHOOKS]: {
        isWebHook: true,
        defaultValue: [],
        uid: 'tjuee672b'
      }
    },
    guideLink: 'https://oboard.atlassian.net/wiki/spaces/OBPFJ/pages/1467219975',
    icon: 'connection-slack',
    title: 'Slack'
  },
  TEAMS: {
    typeId: 4,
    attributes: {
      [NOTIFICATION_CHANNEL_ATTRIBUTES_TYPE_IDS.TEAMS_WEBHOOKS]: {
        isWebHook: true,
        defaultValue: [],
        uid: 'a5k65kvl4'
      }
    },
    guideLink: 'https://oboard.atlassian.net/wiki/spaces/OBPFJ/pages/1467318278',
    icon: 'connection-teams',
    title: 'Teams'
  },
  DISCORD: {
    typeId: 5,
    attributes: {
      [NOTIFICATION_CHANNEL_ATTRIBUTES_TYPE_IDS.DISCORD_WEBHOOKS]: {
        isWebHook: true,
        defaultValue: [],
        uid: 'l4che2wrq'
      }
    },
    guideLink: 'https://oboard.atlassian.net/wiki/spaces/OBPFJ/pages/1467809798',
    icon: 'connection-discord',
    title: 'Discord'
  }
}

export const NOTIFICATIONS_CHANNELS_BY_TYPE_ID = Object.values(NOTIFICATION_CHANNELS).reduce(
  (acc, channel) => {
    return {
      ...acc,
      [channel.typeId]: channel
    }
  },
  {}
)
