<template>
  <div class="cb-Body">
    <div class="cb-Body_Toolbar">
      <AppRadioGroup
        :model-value="currentTab"
        :options="VISIBILITY_OPTIONS"
        data-testid="configuration-body-nav"
        name="configuration-tabs"
        type="tab-like"
        @update:model-value="onTabChange"
      />
      <!-- FOR FUTURE
      <template #item-label="{ item }">
        {{ $t(item.label) }}
        <span class="cb-TabsLabelCount"> X </span>
      </template>
      -->
    </div>

    <div
      ref="contentReference"
      class="cb-Body_Content"
      data-testid="configuration-body-tabs-wrapper"
    >
      <component
        :is="currentTabComponent"
        :are-data-changed="areDataChanged"
        :workspace-data="workspaceData"
        :ws-key="wsKey"
        @update:are-data-changed="emit('update:are-data-changed', $event)"
        @child-modal-opened="emit('child-modal-opened')"
        @child-modal-closed="emit('child-modal-closed')"
        @update:ws-key="emit('update:ws-key', $event)"
        @saving-started="emit('saving-started')"
        @saving-finished="emit('saving-finished')"
      />
    </div>
  </div>
</template>

<script setup>
// import PerfectScrollbar from 'perfect-scrollbar'
import { computed, onBeforeMount, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

import { EVENT_SOURCES, trackWorkspaceFormOpenedEvent } from '@/tracking/amplitude-helpers'
import { WS_CONFIGURATION_TABS } from '@/utils/components-configurations/workspace-configuration-modal'
import { deferExecutionByCondition } from '@/utils/general'
import { EDIT_WORKSPACE_ACTIVE_TAB_QUERY_KEY } from '@/utils/query-parameters'
import { updateQueryParameter } from '@/utils/router'

import AppRadioGroup from '@/components/ui/AppRadioGroup/AppRadioGroup'
import WorkspaceGroups from '@/components/workspaces/configuration-modal/WorkspaceGroups'
import WorkspaceIntervals from '@/components/workspaces/configuration-modal/WorkspaceIntervals'
import WorkspaceSettings from '@/components/workspaces/configuration-modal/WorkspaceSettings'
import WorkspaceUsers from '@/components/workspaces/configuration-modal/WorkspaceUsers'

defineOptions({
  name: 'ConfigurationBody'
})

// import 'perfect-scrollbar/css/perfect-scrollbar.css'

const { GROUPS, USERS, INTERVALS, SETTINGS } = WS_CONFIGURATION_TABS

const currentTab = ref(GROUPS)

const router = useRouter()
const route = useRoute()

const props = defineProps({
  activeTab: {
    type: String,
    default: WS_CONFIGURATION_TABS.GROUPS,
    validator: v => Object.values(WS_CONFIGURATION_TABS).includes(v)
  },

  workspaceData: {
    type: Object,
    required: true
  },

  wsKey: {
    type: String,
    required: true
  },

  areDataChanged: {
    type: Boolean,
    required: true
  },

  isSaving: {
    type: Boolean
  }
})

const emit = defineEmits({
  'child-modal-opened': null,
  'child-modal-closed': null,
  'update:ws-key': null,
  'update:are-data-changed': null,
  'saving-started': null,
  'saving-finished': null,
  'restore-default-settings': null
})

const { t } = useI18n()

const VISIBILITY_OPTIONS = [
  {
    value: GROUPS,
    label: t('field.groups.title')
  },
  {
    value: USERS,
    label: t('common.users')
  },
  {
    value: INTERVALS,
    label: t('workspaces.intervals')
  },
  {
    value: SETTINGS,
    label: t('common.settings')
  }
]

const COMPONENT_BY_TAB_NAME = {
  [GROUPS]: WorkspaceGroups,
  [USERS]: WorkspaceUsers,
  [INTERVALS]: WorkspaceIntervals,
  [SETTINGS]: WorkspaceSettings
}

const currentTabComponent = computed(() => COMPONENT_BY_TAB_NAME[currentTab.value])

onBeforeMount(() => {
  if (currentTab.value !== props.activeTab) {
    currentTab.value = props.activeTab
  }
})

const store = useStore()

const userRoleForTracking = computed(() => {
  return store.getters['system/userRoleForTracking']
})

const onTabChange = async activeTab => {
  await deferExecutionByCondition({
    condition: () => props.isSaving,
    delay: 100
  })
  currentTab.value = activeTab
  updateQueryParameter(router, route, EDIT_WORKSPACE_ACTIVE_TAB_QUERY_KEY, activeTab, false)

  trackWorkspaceFormOpenedEvent({
    isNewlyCreated: false,
    tab: activeTab,
    role: userRoleForTracking.value,
    source: EVENT_SOURCES.WS_MODAL_TAB_MENU
  })

  if (activeTab !== SETTINGS) {
    emit('restore-default-settings')
  }
}

const openSettings = () => {
  currentTab.value = SETTINGS
  onTabChange(SETTINGS)
}

defineExpose({
  openSettings
})

// const ps = ref(null)
const contentReference = ref(null)
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';

.cb-Body {
}
.cb-Body_Toolbar {
  padding: 0 40px;
}

.cb-Body_Content {
  padding: 0 0 40px;
  position: relative;
}
</style>
