<template>
  <div class="gi-Wrapper">
    <AppDroplist
      v-if="showGradeInfo"
      :theme="`${DROP_LIST_THEMES.TRANSLUCENT_NEXT} ${DROP_LIST_THEMES.NO_PADDING_FORCE}`"
      position="top"
      trigger="mouseenter"
    >
      <template #button>
        <div class="gi-Icon" data-export-ignore>
          <AppIcon :height="iconHeight" :width="iconWidth" icon-name="info-next" />
        </div>
      </template>
      <div class="gi-GradeInfo">
        <div v-for="item in resolvedItems" :key="item.id" class="gi-GradeInfo_Item">
          <div class="gi-ItemTitle">
            {{ item.title }}
          </div>
          <div class="gi-ItemValue">
            <span class="gi-ItemValue_Grade"> {{ item.value }}% </span>
            <span v-if="item.showMultiplier" class="gi-ItemValue_Multiplier">
              ×{{ item.multiplier }}
            </span>
          </div>
        </div>
      </div>
    </AppDroplist>
  </div>
</template>

<script>
import { isEmpty } from 'lodash'
import { defineComponent } from 'vue'

import { DROP_LIST_THEMES } from '@/utils/components-configurations/app-droplist'
import { getGradeDisplayValue } from '@/utils/okr-elements/grade'
import { objectOrNullProp } from '@/utils/prop-validators'

import AppDroplist from '@/components/AppDroplist'
import AppIcon from '@/components/ui/AppIcon/AppIcon'

export default defineComponent({
  name: 'ListItemGradeInfo',
  components: { AppIcon, AppDroplist },
  props: {
    gradeInfo: {
      required: true,
      validator: v => objectOrNullProp(v)
    },

    grade: {
      type: Number,
      required: true
    },

    options: {
      type: Array,
      default: () => []
    },

    iconHeight: {
      type: [String, Number],
      default: 20
    },

    iconWidth: {
      type: [String, Number],
      default: 20
    }
  },

  computed: {
    DROP_LIST_THEMES: () => DROP_LIST_THEMES,

    showGradeInfo() {
      return this.gradeInfo && this.gradeDisplayValue > 0
    },

    gradeDisplayValue() {
      return getGradeDisplayValue(this.grade)
    },

    resolvedItems() {
      return isEmpty(this.options) ? this.infoItems : this.options
    },

    infoItems() {
      const { $t, gradeDisplayValue, gradeInfo } = this
      const { otherGrade, otherWeight, filteredGrade, filteredWeight } = gradeInfo
      return [
        {
          id: 0,
          title: $t('grade_info.total_grade'),
          value: gradeDisplayValue
        },
        {
          id: 1,
          title: $t('grade_info.your_krs'),
          value: filteredGrade,
          multiplier: otherWeight,
          showMultiplier: otherWeight !== null
        },
        {
          id: 2,
          title: $t('grade_info.filtered_items'),
          value: otherGrade,
          multiplier: filteredWeight,
          showMultiplier: filteredWeight !== null
        }
      ]
    }
  }
})
</script>

<style lang="scss" scoped>
.gi-Icon {
  display: flex;
  cursor: help;
  color: $grey-1-next;
}

.gi-Wrapper {
  width: 20px;
}

.gi-GradeInfo {
  padding: 16px;
  display: grid;
}

.gi-GradeInfo_Item {
  display: flex;
  justify-content: flex-start;
  gap: 7px;
  font-family: $system-ui;
  color: $white;
  font-style: normal;
  font-weight: fw('regular');
  font-size: $fs-14;
}

.gi-ItemTitle {
  min-width: 85px;
}

.gi-ItemValue {
  display: flex;
  align-items: center;
  gap: 2px;
}

.gi-ItemValue_Grade {
  min-width: 42px;
  text-align: right;
  display: inline-block;
}

.gi-ItemValue_Multiplier {
  font-size: $fs-10;
  color: $lines-grey;
}
</style>
