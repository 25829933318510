<template>
  <div
    :class="{
      'gsl-Label-no-fill': noFill,
      'gsl-Label-loading': loading
    }"
    class="gsl-Label"
  >
    <SkeletonItem
      v-if="loading"
      :color="group.color"
      class="gsl-Label_Loader"
      height="24px"
      width="100%"
    />
    <GroupIcon :icon-name="resolvedGroupIcon" color="transparent" />
    <span class="oboard-truncated-text">
      <slot>
        {{ group.name }}
      </slot>
    </span>
  </div>
</template>

<script setup>
import { inRange } from 'lodash'
import { computed } from 'vue'

import GroupIcon from '@/components/global-groups/GroupIcon'
import SkeletonItem from '@/components/ui/SkeletonLoaders/SkeletonItem'

defineOptions({
  name: 'GroupSelectLabel'
})

const props = defineProps({
  group: {
    type: Object,
    required: true
  },

  noFill: {
    type: Boolean
  },

  loading: {
    type: Boolean
  }
})

const resolvedGroupIcon = computed(() => {
  const { icon } = props.group

  if (!icon || !inRange(Number(icon), 1, 33)) {
    return 1
  }

  return icon
})
</script>

<style lang="scss" scoped>
.gsl-Label {
  display: flex;
  gap: 4px;
  align-items: center;
  padding: var(--padding, (0 8px 0 0));

  &:not(&-no-fill) {
    background-color: v-bind('group.color');
  }

  &-loading {
    position: relative;
  }
}

.gsl-Label_Loader {
  position: absolute;
  left: 0;
  top: 0;
}
</style>
