<template>
  <div :class="classes">
    <AppIcon
      v-if="iconBefore"
      :icon-name="iconBefore"
      class="cm-Trigger_Hero"
      height="24"
      width="24"
    />
    <span v-if="$slots.default" class="cm-Trigger_Text">
      <slot />
    </span>
    <AppIcon
      v-if="!hideArrow"
      class="cm-Trigger_Chevron"
      height="24"
      icon-name="arrow-down-black"
      width="24"
    />
  </div>
</template>

<script setup>
import { computed } from 'vue'

import AppIcon from '@/components/ui/AppIcon/AppIcon'

defineOptions({
  name: 'ConfigurationModalSelectTrigger'
})

const props = defineProps({
  opened: {
    type: Boolean
  },

  hideArrow: {
    type: Boolean
  },

  iconBefore: {
    type: String,
    default: ''
  }
})

const classes = computed(() => {
  return {
    'cm-Trigger': true,
    'cm-Trigger-opened': props.opened,
    'cm-Trigger-with-arrow': !props.hideArrow && !props.iconBefore,
    'cm-Trigger-with-hero': props.iconBefore && props.hideArrow,
    'cm-Trigger-both-icons': !props.hideArrow && props.iconBefore
  }
})
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';

.cm-Trigger {
  padding: 4px 12px;
  display: inline-flex;
  align-items: center;
  min-height: 32px;
  gap: 4px;
  background: $grey-3-next;
  border-radius: $border-radius-sm-next;
  font-family: $system-ui;
  font-style: normal;
  font-weight: fw('semi-bold');
  font-size: $fs-14;
  line-height: 20px;
  color: $dark-2;
  cursor: pointer;
  max-width: 240px;
  transition: background $transition-fast ease-in-out, color $transition-fast ease-in-out;

  @include activeState($dark-2);

  &:not(&-opened) {
    @include hoverState($grey-2-next, 1%);
  }

  &-opened {
    background-color: $dark-2;
    color: $white;
  }

  &-with-arrow {
    padding-right: 4px;
  }

  &-with-hero {
    padding-left: 4px;
  }

  &-both-icons {
    padding: 4px;
  }
}

.cm-Trigger_Text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cm-Trigger_Chevron {
  transition: transform $transition-fast ease-in-out;
  .cm-Trigger-opened & {
    transform: rotate(180deg);
  }
}

.cm-Trigger {
  &_Chevron,
  &_Hero {
    flex: 1 0 auto;
  }
}
</style>
