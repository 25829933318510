<template>
  <div ref="groupReference" class="agfg-FieldContainer">
    <div>
      <div class="agfg-Head_Cell">
        {{ $t('users.select_jira_group') }}
      </div>
      <div ref="sourcePlatformSelectField">
        <AppSelect
          ref="sourcePlatformGroupRef"
          :dropdown-min-width="200"
          :is-error="!groupsToAdd.sourcePlatformGroupsValid"
          :model-value="groupsToAdd.sourcePlatformGroupIds"
          :offset="[0, sourcePlatformDropdownYOffset]"
          :options="platformGroups"
          :search-function="getPlatformGroups"
          :type="SELECT_TYPES.MODERN"
          class="agfg-GroupsSelect"
          data-testid="source-groups"
          dropdown-search
          item-label="name"
          item-value="groupId"
          multi
          show-all-selected
          show-selected-options-inside
          @open="fetchGroups"
          @opened="removeError('sourcePlatformGroupsValid')"
          @update:model-value="onSelectPlatformGroup"
        >
          <template #button-content="{ options }">
            <span v-if="isEmpty(options)" class="agfg-GroupsSelectEmptyLabel">
              {{ $t('user_details.select_groups') }}
            </span>
          </template>
        </AppSelect>
        <AppFieldError
          v-if="!groupsToAdd.sourcePlatformGroupsValid"
          :show="!groupsToAdd.sourcePlatformGroupsValid"
          class="aufg-Error"
        >
          {{ $t('field.required') }}
        </AppFieldError>
      </div>
    </div>
    <div>
      <div class="agfg-Head">
        <div class="agfg-Head_Cell">
          {{ $t('objective.select_group') }}
        </div>
      </div>
      <div class="agfg-Group">
        <div ref="groupsSelectField" class="agfg-FieldItem">
          <AppSelect
            ref="targetPluginGroupSelect"
            :is-error="!groupsToAdd.targetPluginGroupsValid"
            :loading="areGroupsLoading"
            :model-value="groupsToAdd.targetPluginGroupId"
            :offset="[0, groupsDropdownYOffset]"
            :options="groups"
            :search-function="getGroupsList"
            :type="SELECT_TYPES.MODERN"
            boundary="scrollParent"
            class="agfg-GroupsSelect"
            data-testid="target-groups"
            dropdown-search
            item-label="name"
            item-value="id"
            @opened="getGroups"
            @update:model-value="onSelectGroups"
            @update:options="groups = $event"
          >
            <template #button-content="{ option }">
              <span v-if="!option" class="agfg-GroupsSelectEmptyLabel">
                {{ $t('user_details.select_groups') }}
              </span>
              <GlobalGroupsSelectOption v-else-if="option" :group="option" />
            </template>
            <template #option-label="{ option }">
              <GlobalGroupsSelectOption v-if="option" :group="option" />
            </template>
          </AppSelect>
          <AppFieldError
            v-if="!groupsToAdd.targetPluginGroupsValid"
            :show="!groupsToAdd.targetPluginGroupsValid"
            class="aufg-Error"
          >
            {{ $t('field.required') }}
          </AppFieldError>
        </div>
        <WorkspacesAffectMessage :limit="5" :workspaces="affectedWorkspaces">
          {{ $t('group.user_will_be_added_to_the_workspaces') }}
        </WorkspacesAffectMessage>
      </div>
    </div>
    <div>
      <div class="agfg-Head_Cell">
        {{ $t('users.select_import_options') }}
      </div>

      <AppRadioGroup
        :is-error="!groupsToAdd.keepSyncedValid"
        :model-value="groupsToAdd.keepSynced"
        :options="syncImportOptions"
        :type="APP_RADIO_GROUP_TYPES.BLOCKS"
        name="sync-type"
        @update:model-value="onSelectSync"
      />

      <AppFieldError
        v-if="!groupsToAdd.keepSyncedValid"
        :show="!groupsToAdd.keepSyncedValid"
        class="aufg-Error"
      >
        {{ $t('field.required') }}
      </AppFieldError>
    </div>
  </div>
</template>

<script setup>
import { useIntersectionObserver, useResizeObserver } from '@vueuse/core'
import { cloneDeep, isEmpty } from 'lodash'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import PlatformApiHandler from '@/api/platform'
import { useGlobalGroups } from '@/composables/global-groups'
import { RADIO_GROUP_TYPES as APP_RADIO_GROUP_TYPES } from '@/utils/components-configurations/app-radio-group'
import { SELECT_TYPES } from '@/utils/components-configurations/app-select'
import { REQUEST_ENTITY_KEYS } from '@/utils/entity-keys'
import { handleError } from '@/utils/error-handling'

import AppFieldError from '@/components/form/AppFieldError'
import GlobalGroupsSelectOption from '@/components/global-groups/GlobalGroupsSelectOption'
import WorkspacesAffectMessage from '@/components/global-groups/WorkspacesAffectMessage'
import AppRadioGroup from '@/components/ui/AppRadioGroup/AppRadioGroup'
import AppSelect from '@/components/ui/AppSelect/AppSelect'

defineOptions({
  name: 'AddGlobalGroupsFieldGroup'
})

const props = defineProps({
  groupsToAdd: {
    type: Object,
    required: true
  }
})

const sourcePlatformGroupRef = ref(null)
const targetPluginGroupSelect = ref(null)

const groupReference = ref(null)
const groupReferenceIsVisible = ref(false)

useIntersectionObserver(
  groupReference,
  ([{ isIntersecting }], observerElement) => {
    groupReferenceIsVisible.value = isIntersecting
  },
  {
    threshold: 0.99
  }
)

watch(
  () => groupReferenceIsVisible,
  newValue => {
    if (!newValue) {
      sourcePlatformGroupRef.value.hideDropdown()
      targetPluginGroupSelect.value.hideDropdown()
    }
  },
  { immediate: true }
)
const getUpdatedList = newItem => {
  const updatedData = cloneDeep(props.groupsToAdd)
  return {
    ...updatedData,
    ...newItem
  }
}

const emit = defineEmits(['update:groups-to-add'])

const platformGroups = ref([])

const getPlatformGroups = async (searchString = null) => {
  const api = new PlatformApiHandler()
  try {
    return await api.getPlatformGroups({ searchString, [REQUEST_ENTITY_KEYS.LIMIT]: 10 })
  } catch (error) {
    handleError({ error })
  }
}
const fetchGroups = async () => {
  platformGroups.value = await getPlatformGroups()
}

const removeError = prop => {
  const newItem = {
    ...props.groupsToAdd,
    [prop]: true
  }

  const updatedList = getUpdatedList(newItem)

  emit('update:groups-to-add', updatedList)
}

const onSelectPlatformGroup = sourcePlatformGroupIds => {
  const newItem = {
    ...props.groupsToAdd,
    sourcePlatformGroupIds
  }

  const updatedList = getUpdatedList(newItem)

  emit('update:groups-to-add', updatedList)
}
const onSelectGroups = targetPluginGroupId => {
  const newItem = {
    ...props.groupsToAdd,
    targetPluginGroupId
  }

  const updatedList = getUpdatedList(newItem)

  emit('update:groups-to-add', updatedList)
}

const onSelectSync = keepSynced => {
  const newItem = {
    ...props.groupsToAdd,
    keepSynced
  }

  const updatedList = getUpdatedList(newItem)

  emit('update:groups-to-add', { ...updatedList, keepSyncedValid: true })
}

const groups = ref([])
const areGroupsLoading = ref(false)
const groupsDropdownYOffset = ref(0)
const sourcePlatformDropdownYOffset = ref(0)
const groupsSelectField = ref(null)
const sourcePlatformSelectField = ref(null)

useResizeObserver(groupsSelectField, entries => {
  // calculate offset dynamically for dropdown | offset should be negative to show dropdown above the field
  // thats why calculate button height and use -height

  const entry = entries[0]
  const { height } = entry.contentRect
  groupsDropdownYOffset.value = -height
})
useResizeObserver(sourcePlatformSelectField, entries => {
  // calculate offset dynamically for dropdown | offset should be negative to show dropdown above the field
  // thats why calculate button height and use -height

  const entry = entries[0]
  const { height } = entry.contentRect
  sourcePlatformDropdownYOffset.value = -height
})

const { fetchGroupsForFilter } = useGlobalGroups()

const getGroupsList = async () => {
  areGroupsLoading.value = true
  try {
    return await fetchGroupsForFilter()
  } catch (error) {
    handleError({ error })
  } finally {
    areGroupsLoading.value = false
  }
}
const getGroups = async () => {
  removeError('targetPluginGroupsValid')
  if (isEmpty(groups.value)) {
    groups.value = await getGroupsList(null)
  }
}

const { t } = useI18n()
const syncImportOptions = [
  {
    icon: 'add-jira',
    label: t('action.import_users'),
    message: t('users.import_users_description'),
    value: false
  },
  {
    icon: 'replace_jira',
    label: t('users.link_jira_groups'),
    message: t('users.link_jira_groups_description'),
    value: true
  }
]

const affectedWorkspaces = computed(() => {
  const workspaces = groups.value.find(
    group => group.id === props.groupsToAdd.targetPluginGroupId
  )?.workspaces

  return workspaces || []
})
</script>
<script>
//eslint-disable-next-line import/order
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AddGlobalGroupsFieldGroup'
})
</script>

<style lang="scss" scoped>
$group-gap: 20px;
$items-count: 1;

.agfg-Group {
  font-family: $system-ui;
  position: relative;
  flex: 1 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: $group-gap;
  width: var(--items-width, 100%);
}

.agfg-FieldContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.agfg-Head_Cell {
  font-weight: fw('bold');
  font-size: $fs-12;
  line-height: 16px;
  color: $dark-3;
  margin-bottom: 6px;
}
.agfg-GroupsSelect {
  :deep(.as-AppDroplistButton_Content) {
    padding: 6px 0 6px 6px;
  }
}
.agfg-FieldItem {
  width: calc((100% - (#{$group-gap} * (#{$items-count} - 1))) / #{$items-count});
  position: relative;
  --gap: 0;
  --label-bottom-offset: 6px;
}
.agfg-Head {
  display: grid;
  grid-template-columns: repeat($items-count, 1fr);
  gap: 20px;
}

.agfg-GroupsSelectEmptyLabel {
  padding-left: 2px;
}
</style>
