import { OKR_STATUSES } from '@/utils/objectives'
import { TABLE_COLUMNS_KEYS } from '@/utils/table-columns'

const {
  ON_TRACK_COUNT,
  BEHIND_COUNT,
  AT_RISK_COUNT,
  CLOSED_COUNT,
  ABANDONED_COUNT,
  ZERO_GRADE_COUNT,
  MORE_THAN_100_GRADE_COUNT,
  NOT_STARTED_COUNT,
  GRADE_BEHIND_COUNT,
  GRADE_AT_RISK_COUNT,
  GRADE_ON_TRACK_COUNT
} = TABLE_COLUMNS_KEYS

export const PERFORMANCE_DASHBOARD_CELLS = {
  [NOT_STARTED_COUNT]: {
    gradeType: OKR_STATUSES.NOT_STARTED,
    valueKey: NOT_STARTED_COUNT,
    style: '--text-color: var(--grey-1-next)'
  },
  [ON_TRACK_COUNT]: {
    gradeType: OKR_STATUSES.ON_TRACK,
    valueKey: ON_TRACK_COUNT,
    style: '--text-color: var(--grade-high-color-next)'
  },
  [GRADE_ON_TRACK_COUNT]: {
    gradeType: OKR_STATUSES.ON_TRACK,
    valueKey: GRADE_ON_TRACK_COUNT,
    style: '--text-color: var(--grade-default-color)'
  },
  [BEHIND_COUNT]: {
    gradeType: OKR_STATUSES.BEHIND,
    valueKey: BEHIND_COUNT,
    style: '--text-color: var(--grade-medium-color-next)'
  },
  [GRADE_BEHIND_COUNT]: {
    gradeType: OKR_STATUSES.BEHIND,
    valueKey: GRADE_BEHIND_COUNT,
    style: '--text-color: var(--grade-default-color)'
  },
  [AT_RISK_COUNT]: {
    gradeType: OKR_STATUSES.AT_RISK,
    valueKey: AT_RISK_COUNT,
    style: '--text-color: var(--grade-low-color-next)'
  },
  [GRADE_AT_RISK_COUNT]: {
    gradeType: OKR_STATUSES.AT_RISK,
    valueKey: GRADE_AT_RISK_COUNT,
    style: '--text-color: var(--grade-default-color)'
  },
  [CLOSED_COUNT]: {
    gradeType: OKR_STATUSES.CLOSED,
    valueKey: CLOSED_COUNT,
    style: '--text-color: var(--grade-closed-color)'
  },
  [ABANDONED_COUNT]: {
    gradeType: OKR_STATUSES.ABANDONED,
    valueKey: ABANDONED_COUNT,
    style: '--text-color: var(--grade-abandoned-color)'
  },
  [MORE_THAN_100_GRADE_COUNT]: {
    gradeType: OKR_STATUSES.ON_TRACK,
    valueKey: MORE_THAN_100_GRADE_COUNT,
    style: '--text-color: var(--grade-default-color)'
  },
  [ZERO_GRADE_COUNT]: {
    gradeType: OKR_STATUSES.NOT_STARTED,
    valueKey: ZERO_GRADE_COUNT,
    style: '--text-color: var(--grade-default-color)'
  }
}
