<template>
  <OkrElementsTableNumberInput
    :field-id="fieldId"
    :field-params="fieldParams"
    :item="item"
    :user-has-read-access="userHasUpdateAccess"
    :user-has-update-access="userHasUpdateAccess"
    @update-field-value="onUpdateFieldValue"
  />
</template>

<script setup>
import OkrElementsTableNumberInput from '@/components/custom-fields/okr-elements-table-cells/OkrElementsTableNumberInput'

defineOptions({
  name: 'NumberCell'
})

defineProps({
  item: {
    type: Object,
    required: true
  },

  fieldId: {
    type: [String, Number],
    required: true
  },

  userHasUpdateAccess: {
    type: Boolean
  },

  fieldParams: {
    type: Object,
    required: true
  }
})

const emit = defineEmits({
  'update-field-value': null
})

const onUpdateFieldValue = ({ fieldId, value }) => {
  emit('update-field-value', {
    fieldId,
    value
  })
}
</script>

<style lang="scss" scoped></style>
