<template>
  <AppModalWithConfirmation
    ref="appModalWithConfirmation"
    :confirm-close="areDataChanged"
    :show="show"
    :title="getModalTitle"
    hide-hero
    @on-close="onWorkspaceModalClose"
  >
    <div class="wm-Fields">
      <FormFieldNext :label="$t('workspace.label.name')">
        <AppInput
          ref="name"
          v-model.trim="formModel[WORKSPACE_ENTITY_KEYS.NAME]"
          :is-error="showNameErrors"
          max-length="30"
          size="xlg"
          style-type="primary"
          @update:model-value="validateName"
        />

        <AppFieldError v-if="showNameErrors" :show="showNameErrors" class="wm-FieldError">
          <span v-show="isNameEmpty || isNameLengthNotValid">
            {{ $t(isNameEmpty ? 'field.required' : 'field.workspace_name') }}
          </span>
          <template v-if="formErrors !== null && formErrors[WORKSPACE_ENTITY_KEYS.NAME]">
            <span v-for="error in formErrors[WORKSPACE_ENTITY_KEYS.NAME]" :key="error">
              {{ error }}
            </span>
          </template>
        </AppFieldError>
      </FormFieldNext>

      <FormFieldNext :label="$t('workspace.label.description')">
        <AppTextarea
          v-model="formModel[WORKSPACE_ENTITY_KEYS.DESCRIPTION]"
          :max-length="DESCRIPTION_MAX_LENGTH"
          :placeholder="$t('workspace.label.add_description')"
          :rows="1"
          style="--input-padding: 8px"
        />
      </FormFieldNext>

      <div>
        <AppIconCreator class="wm-IconCreator">
          <template #first-term>
            <FormFieldNext :label="$t('workspace.label.key')">
              <AppInput
                v-model.trim.uppercase="formModel[WORKSPACE_ENTITY_KEYS.KEY]"
                :is-error="showKeyErrors"
                class="wm-Key"
                max-length="3"
                size="xlg"
                style-type="primary"
                @update:model-value="onUpdateInput"
              >
                <template #append-after>
                  <TooltipButtonInfo
                    class="wm-TooltipButton"
                    content="workspaces.key.tooltip"
                    placement="top"
                  />
                </template>
              </AppInput>
            </FormFieldNext>
          </template>

          <template #second-term>
            <AppPaletteColorPicker
              v-model:selected-color="formModel[WORKSPACE_ENTITY_KEYS.COLOR]"
              :palette="WORKSPACE_CATEGORIZED_COLORS"
            />
          </template>

          <template #result>
            <WorkspaceIcon :option="formModel" :with-lock-icon="false" />
          </template>
        </AppIconCreator>

        <AppFieldError v-if="showKeyErrors" :show="showKeyErrors" class="wm-FieldError">
          <span v-show="isKeyLengthNotValid">
            {{ $t('field.workspace_key_length') }}
          </span>
          <template v-if="formErrors !== null && formErrors[WORKSPACE_ENTITY_KEYS.KEY]">
            <span v-for="error in formErrors[WORKSPACE_ENTITY_KEYS.KEY]" :key="error">
              {{ error }}
            </span>
          </template>
        </AppFieldError>
      </div>

      <FormFieldNext v-if="!isEdit" :label="$t('levels.header_title')">
        <div class="wm-LevelsFieldWrapper">
          <AppSelect
            v-if="show"
            ref="levelsSelectReference"
            :loading="levelsLoading"
            :max-height="isAllLevelsOptionSelected ? 220 : 229"
            :model-value="formModel[WORKSPACE_ENTITY_KEYS.LEVELS]"
            :offset="[0, '-100%']"
            :options="levelsOptions"
            :search-function="getLevels"
            :split-first-option="splitLevelsFirstOption"
            append-to="parent"
            dropdown-search
            hide-selected-items-in-dropdown
            item-label="name"
            item-value="id"
            multi
            show-all-selected
            show-selected-options-inside
            skeleton-loader
            skeleton-loader-height="100%"
            skeleton-loader-width="100%"
            @update:model-value="onSelectLevels"
            @update:options="levels = $event"
          >
            <template #button-content>
              <OkrTypeFieldOption v-if="isAllLevelsOptionSelected" :option="ALL_LEVELS_OPTION">
                <template #icon>
                  <AppIcon
                    :icon-name="ALL_LEVELS_OPTION.icon"
                    class="wm-AllLevelsOption_Icon"
                    height="24"
                    width="24"
                  />
                </template>
              </OkrTypeFieldOption>
            </template>
            <template #option-label="{ option }">
              <OkrTypeFieldOption v-if="option" :option="option">
                <template
                  v-if="
                    option[OKR_LEVEL_ENTITY_KEYS.ID] === ALL_LEVELS_OPTION[OKR_LEVEL_ENTITY_KEYS.ID]
                  "
                  #icon
                >
                  <AppIcon
                    :icon-name="ALL_LEVELS_OPTION.icon"
                    class="wm-AllLevelsOption_Icon"
                    height="24"
                    width="24"
                  />
                </template>
              </OkrTypeFieldOption>
            </template>
          </AppSelect>
        </div>
      </FormFieldNext>

      <FormFieldNext :label="$t('workspace.timezone')">
        <div style="position: relative">
          <AppSelect
            v-if="show"
            v-model="formModel[WORKSPACE_ENTITY_KEYS.TIMEZONE]"
            :offset="[0, '-100%']"
            :options="timezonesOptions"
            :search-function="value => localSearch({ value, options: timezonesOptions })"
            :type="SELECT_TYPES.MODERN"
            append-to="parent"
            class="wm-TimezoneSelect"
            dropdown-search
            item-label="label"
            item-value="value"
            show-all-selected
          />
        </div>
      </FormFieldNext>
    </div>
    <template #footer>
      <div class="wm-Footer">
        <AppVisibilitySwitch v-if="!isEdit" v-model="formModel[WORKSPACE_ENTITY_KEYS.PUBLIC]" />

        <div class="wm-Footer_Actions">
          <AppButton type="ghost-next" @click="close">
            {{ $t('action.cancel') }}
          </AppButton>

          <AppButton :disable="loading" :loading="loading" type="primary-next" @click="save">
            {{ isEdit ? $t('action.update') : $t('action.confirm') }}
          </AppButton>
        </div>
      </div>
    </template>
  </AppModalWithConfirmation>
</template>

<script>
import { cloneDeep, has, isEmpty, isEqual, isNull } from 'lodash'
import { defineComponent } from 'vue'
import { mapActions } from 'vuex'

import LevelApiHandler from '@/api/level'
import WorkspacesApiHandler from '@/api/workspaces'
import { tracker } from '@/tracking/amplitude'
import { EVENT_CATEGORIES } from '@/tracking/amplitude-helpers'
import { EVENT_NAMES } from '@/tracking/gtm-helpers'
import { gtmTracker } from '@/tracking/gtm-tracking'
import { SELECT_TYPES } from '@/utils/components-configurations/app-select'
import { WORKSPACE_CATEGORIZED_COLORS } from '@/utils/components-configurations/workspace-configuration-modal'
import { OKR_LEVEL_ENTITY_KEYS, WORKSPACE_ENTITY_KEYS } from '@/utils/entity-keys'
import { handleError } from '@/utils/error-handling'
import { isStringEmpty } from '@/utils/general'
import { DESCRIPTION_MAX_LENGTH } from '@/utils/global-groups'
import { localSearch } from '@/utils/objectives'
import { DEFAULT_VALUE_FOR_FILTER, SELECT_ALL_VALUE } from '@/utils/okr-elements/filters'
import { deleteQueryParameter } from '@/utils/router'
import {
  APP_SELECT_SELECTED_ITEMS_LIST_KEYS,
  getSelectWithSelectAllApiParameter,
  handleSelectInputWithSelectAll,
  selectAllIsSelected
} from '@/utils/select'
import { timezonesOptions } from '@/utils/timezones'

import AppModalWithConfirmation from '@/components/AppModalWithConfirmation'
import AppFieldError from '@/components/form/AppFieldError'
import FormFieldNext from '@/components/form/FormFieldNext'
import OkrTypeFieldOption from '@/components/form/OkrTypeFieldOption'
import TooltipButtonInfo from '@/components/TooltipButtonInfo'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppIcon from '@/components/ui/AppIcon/AppIcon'
import AppIconCreator from '@/components/ui/AppIconCreator/AppIconCreator'
import AppInput from '@/components/ui/AppInput/AppInput'
import AppPaletteColorPicker from '@/components/ui/AppPaletteColorPIcker/AppPaletteColorPicker'
import AppSelect from '@/components/ui/AppSelect/AppSelect'
import AppTextarea from '@/components/ui/AppTextarea/AppTextarea'
import AppVisibilitySwitch from '@/components/workspaces/AppVisibilitySwitch'
import WorkspaceIcon from '@/components/workspaces/WorkspaceIcon'

const workspacesApi = new WorkspacesApiHandler()
const levelApi = new LevelApiHandler()

const DEFAULT_FORM_MODEL = {
  [WORKSPACE_ENTITY_KEYS.NAME]: '',
  [WORKSPACE_ENTITY_KEYS.DESCRIPTION]: '',
  [WORKSPACE_ENTITY_KEYS.KEY]: '',
  [WORKSPACE_ENTITY_KEYS.PUBLIC]: false,
  [WORKSPACE_ENTITY_KEYS.TIMEZONE]: 0, // UTC timezone
  [WORKSPACE_ENTITY_KEYS.COLOR]: WORKSPACE_CATEGORIZED_COLORS.BLUE[0],
  [WORKSPACE_ENTITY_KEYS.LEVELS]: cloneDeep(DEFAULT_VALUE_FOR_FILTER)
}

const ALL_LEVELS_OPTION = {
  icon: 'okr-levels',
  [OKR_LEVEL_ENTITY_KEYS.ID]: SELECT_ALL_VALUE,
  [OKR_LEVEL_ENTITY_KEYS.NAME]: 'filter.all_okr_levels',
  [APP_SELECT_SELECTED_ITEMS_LIST_KEYS.HIDE_FROM_LIST]: true
}

const NAME_ERRORS_KEY = WORKSPACE_ENTITY_KEYS.NAME
const KEY_ERRORS_KEY = WORKSPACE_ENTITY_KEYS.KEY

export default defineComponent({
  name: 'WorkspaceCreateFastEditModal',

  components: {
    AppIcon,
    OkrTypeFieldOption,
    AppTextarea,
    WorkspaceIcon,
    AppPaletteColorPicker,
    AppIconCreator,
    AppVisibilitySwitch,
    FormFieldNext,
    AppModalWithConfirmation,
    AppButton,
    AppInput,
    AppFieldError,
    TooltipButtonInfo,
    AppSelect
  },

  props: {
    show: {
      type: Boolean,
      default: false
    },

    modelValue: {
      type: Object,
      default: null
    }
  },

  emits: { 'update:show': null, 'on-created': null, 'on-updated': null },

  data() {
    return {
      timezonesOptions,
      formModel: cloneDeep(DEFAULT_FORM_MODEL),
      localFormModel: cloneDeep(DEFAULT_FORM_MODEL),
      isNameEmpty: false,
      isNameLengthNotValid: false,
      isKeyLengthNotValid: false,
      loading: false,
      formErrors: null,
      levels: [],
      levelsLoading: false
    }
  },

  computed: {
    SELECT_TYPES: () => SELECT_TYPES,

    OKR_LEVEL_ENTITY_KEYS: () => OKR_LEVEL_ENTITY_KEYS,

    DESCRIPTION_MAX_LENGTH: () => DESCRIPTION_MAX_LENGTH,

    WORKSPACE_ENTITY_KEYS: () => WORKSPACE_ENTITY_KEYS,

    ALL_LEVELS_OPTION: function () {
      return {
        ...ALL_LEVELS_OPTION,
        [OKR_LEVEL_ENTITY_KEYS.NAME]: this.$t(ALL_LEVELS_OPTION[OKR_LEVEL_ENTITY_KEYS.NAME])
      }
    },

    WORKSPACE_CATEGORIZED_COLORS: () => WORKSPACE_CATEGORIZED_COLORS,

    isEdit() {
      return !isNull(this.modelValue)
    },

    areDataChanged() {
      return !isEqual(this.localFormModel, this.formModel)
    },

    getModalTitle() {
      return this.isEdit
        ? this.$t('workspaces.edit_workspace')
        : this.$t('workspaces.new_workspace')
    },

    showNameErrors() {
      return (
        this.isNameEmpty ||
        this.isNameLengthNotValid ||
        (!isNull(this.formErrors) && has(this.formErrors, NAME_ERRORS_KEY))
      )
    },

    showKeyErrors() {
      return (
        this.isKeyLengthNotValid ||
        (!isNull(this.formErrors) && has(this.formErrors, KEY_ERRORS_KEY))
      )
    },

    levelsOptions() {
      return [
        {
          ...this.ALL_LEVELS_OPTION
        },
        ...this.levels
      ]
    },

    isAllLevelsOptionSelected() {
      return selectAllIsSelected(this.formModel[WORKSPACE_ENTITY_KEYS.LEVELS])
    },

    splitLevelsFirstOption() {
      return !this.isAllLevelsOptionSelected
    }
  },

  watch: {
    async show(bool) {
      if (!bool) {
        this.onWorkspaceModalClose()
        return
      }

      if (this.isEdit) {
        Object.keys(DEFAULT_FORM_MODEL).forEach(key => {
          this.formModel[key] = this.modelValue[key] || DEFAULT_FORM_MODEL[key]
        })
      }

      if (!this.isEdit) {
        this.levels = await this.getLevels()
      }

      this.localFormModel = cloneDeep(this.formModel)

      this.setFocusOnName()
    }
  },

  methods: {
    ...mapActions('objectives', {
      setLevels: 'setLevels'
    }),

    localSearch,

    async getLevels(searchString = null) {
      this.levelsLoading = true
      try {
        const levelIds = searchString
          ? null
          : getSelectWithSelectAllApiParameter(this.formModel[WORKSPACE_ENTITY_KEYS.LEVELS])

        return await levelApi.getLevels({
          workspaceIds: null,
          levelIds,
          searchString
        })
      } catch (error) {
        handleError({ error })
      } finally {
        this.levelsLoading = false
      }
    },

    onSelectLevels(newValue) {
      if (isEmpty(newValue)) {
        this.formModel[WORKSPACE_ENTITY_KEYS.LEVELS] = cloneDeep(DEFAULT_VALUE_FOR_FILTER)
        return
      }

      const oldValue = cloneDeep(this.formModel[WORKSPACE_ENTITY_KEYS.LEVELS])
      let { valueChanged, result } = handleSelectInputWithSelectAll(newValue, oldValue)
      valueChanged = valueChanged || !isEqual(oldValue, result)

      if (valueChanged) {
        if (isEqual(result, DEFAULT_VALUE_FOR_FILTER)) {
          this.$refs.levelsSelectReference.hideDropdown()
        }
        this.formModel[WORKSPACE_ENTITY_KEYS.LEVELS] = result
      }
    },

    onUpdateInput(key) {
      this.validateKey(key)
    },

    onWorkspaceModalClose() {
      this.formModel = cloneDeep(DEFAULT_FORM_MODEL)
      this.localFormModel = cloneDeep(DEFAULT_FORM_MODEL)
      this.isNameEmpty = false
      this.isNameLengthNotValid = false
      this.isKeyLengthNotValid = false
      this.formErrors = null

      deleteQueryParameter(this.$router, this.$route, 'editWorkspace')
      this.$emit('update:show', false)
    },

    validateName() {
      this.isNameEmpty = isStringEmpty(this.formModel[WORKSPACE_ENTITY_KEYS.NAME])
      this.isNameLengthNotValid = this.formModel[WORKSPACE_ENTITY_KEYS.NAME].length < 2
    },

    validateKey() {
      this.formErrors = null
      this.isKeyLengthNotValid =
        this.formModel[WORKSPACE_ENTITY_KEYS.KEY].length === 0 ||
        this.formModel[WORKSPACE_ENTITY_KEYS.KEY].length > 3
    },

    async save() {
      this.validateName()
      if (this.isNameEmpty || this.isNameLengthNotValid) {
        return
      }

      this.validateKey()
      if (this.isKeyLengthNotValid) {
        return
      }

      let isSuccessful = true
      this.loading = true

      let workspace = null

      const requestMethod = this.isEdit
        ? payload => workspacesApi.updateWorkspace(payload)
        : payload => workspacesApi.createWorkspace(payload)

      const payload = {
        [WORKSPACE_ENTITY_KEYS.ID]: this.isEdit
          ? this.modelValue[WORKSPACE_ENTITY_KEYS.ID]
          : undefined,

        ...this.formModel,
        isPublic: this.formModel[WORKSPACE_ENTITY_KEYS.PUBLIC]
      }

      if (!this.isEdit) {
        ;(payload.levelIds = this.isAllLevelsOptionSelected
          ? []
          : this.formModel[WORKSPACE_ENTITY_KEYS.LEVELS]),
          (payload.addToAllLevels = this.isAllLevelsOptionSelected)
      }

      try {
        const response = await requestMethod(payload)
        if (response.errors.length > 0) {
          isSuccessful = false
          this.formErrors = {}
          response.errors.forEach(error => {
            if (!(error.fieldName in this.formErrors)) {
              this.formErrors[error.fieldName] = []
            }
            this.formErrors[error.fieldName].push(error.error)
          })
        } else {
          workspace = response.workspace

          if (!this.isEdit) {
            tracker.logEvent('Workspace created', {
              category: EVENT_CATEGORIES.WORKSPACE_MANAGEMENT
            })

            gtmTracker.logEvent(EVENT_NAMES.WORKSPACE_CREATED)
          }
        }
      } catch (error) {
        isSuccessful = false
        handleError({ error })
      }

      this.loading = false
      if (isSuccessful) {
        const emitMethod = this.isEdit ? 'on-updated' : 'on-created'
        this.$emit(emitMethod, workspace)

        if (!this.isEdit) {
          this.updateSystemLevels()
        }
      }
    },

    async updateSystemLevels() {
      try {
        const levels = await levelApi.getLevels({
          workspaceIds: null,
          levelIds: null
        })

        this.setLevels({
          levels: levels,
          updateFullLevelsList: true
        })
      } catch (error) {
        handleError({ error })
      }
    },

    close() {
      this.$refs.appModalWithConfirmation.close()
    },

    setFocusOnName() {
      // nextTick and setTimeout(both!) are needed to make input always focused on modal window
      // opening because modal window(o-modal) has transition of opacity with 0.2s
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.name.focus()
        }, 100)
      })
    }
  }
})
</script>

<style lang="scss" scoped>
.wm-Fields {
  display: grid;
  gap: 20px;
}

.wm-TooltipButton {
  margin-right: 6px;
}
.wm-FieldError {
  min-height: 20px;
  display: flex;
  align-items: center;
}

.wm-Footer {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-between;

  width: 100%;
}

.wm-Footer_Actions {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: inherit;
}

.wm-IconCreator {
  --select-width: 148px;
}

.wm-LevelsFieldWrapper {
  position: relative;

  --select-skeleton-top: 0;
  --select-skeleton-left: 0;

  :deep(.as-AppDroplistButton_Content) {
    padding: 6px 0 6px 6px;
  }

  :deep(.o-checkbox-label-text) {
    overflow: visible;
  }
}

.wm-AllLevelsOption_Icon {
  color: $dark-2;
}
</style>

<style lang="scss">
.wm-Key .o-input-field {
  width: 100%;
}
</style>
