<template>
  <div class="sst-Trigger">
    <div class="sst-Trigger_Content">
      <slot />
    </div>
    <AppIcon
      :class="{ 'sst-Trigger_Icon-active': opened }"
      class="sst-Trigger_Icon"
      height="24"
      icon-name="arrow-down-black-next"
      width="24"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SimpleSelectTrigger'
})
</script>

<script setup>
import AppIcon from '@/components/ui/AppIcon/AppIcon'

defineProps({
  opened: {
    type: Boolean
  }
})
</script>

<style lang="scss" scoped>
.sst-Trigger {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.sst-Trigger_Icon {
  transition: transform $transition-fast ease-in-out;
  &-active {
    transform: rotate(180deg);
  }
}

.sst-Trigger_Content {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
