<template>
  <span
    v-if="isVisible"
    :class="{ [`si-SavingIndicator-${type}`]: true, 'si-SavingIndicator-saved': saved }"
    class="si-SavingIndicator"
  >
    <template v-if="type === SAVING_INDICATOR_TYPES.LOADER">
      <AppIcon :icon-name="iconName" />
      <span class="si-Text">
        {{ statusText }}
      </span>
    </template>
  </span>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'

import { SAVING_INDICATOR_TYPES } from '@/utils/components-configurations/saving-indicator'

import AppIcon from '@/components/ui/AppIcon/AppIcon'

defineOptions({
  name: 'SavingIndicator'
})

const SAVING_TIME = 400 // ms // 700 - old
const SAVED_TIME = 500 // ms // 2000 - old

const saved = ref(false)
const startTime = ref(0)
const isVisible = ref(false)
const savedTimerId = ref(null)
const hideTimerId = ref(null)

const animationDuration = computed(() => `${SAVING_TIME}ms`)

const startSaving = () => {
  saved.value = false
  isVisible.value = true
  startTime.value = +new Date()
  if (!saved.value) {
    if (hideTimerId.value !== null) {
      clearTimeout(hideTimerId.value)
    }

    if (savedTimerId.value !== null) {
      clearTimeout(savedTimerId.value)
    }
  }
}

const endSaving = () => {
  const endTime = +new Date()
  const duration = endTime - startTime.value
  if (duration >= SAVING_TIME) {
    scheduleHide()
  } else {
    savedTimerId.value = setTimeout(() => {
      scheduleHide()
      savedTimerId.value = null
    }, SAVING_TIME - duration)
  }
}

defineExpose({
  startSaving,
  endSaving
})

defineProps({
  type: {
    type: String,
    default: SAVING_INDICATOR_TYPES.LOADER,
    validator: v => Object.values(SAVING_INDICATOR_TYPES).includes(v)
  }
})

const scheduleHide = () => {
  saved.value = true
  hideTimerId.value = setTimeout(() => {
    isVisible.value = false
    hideTimerId.value = null
  }, SAVED_TIME)
}

const iconName = computed(() => (saved.value ? 'active' : 'pipelines'))
const { t } = useI18n()
const statusText = computed(() =>
  saved.value ? t('saving_indicator.saved') : t('saving_indicator.saving')
)
</script>

<style lang="scss" scoped>
.si-SavingIndicator {
  color: $dark-3;
  &-loader {
    display: flex;
    align-items: flex-end;
    gap: 2px;
  }

  &-line {
    width: 100%;
    height: 2px;
    display: block;
    position: relative;
    z-index: 7;

    &:after {
      content: '';
      height: 100%;
      width: 99%;
      background-color: $primary-color-next;
      position: absolute;
      left: 0;
      top: 0;
      animation: loading cubic-bezier(0.17, 0.67, 0.83, 0.67) v-bind(animationDuration);
    }
  }

  &-line#{&}-saved {
    &:after {
      width: 100%;
    }
  }
}

.si-Text {
  font-family: $system-ui;

  font-size: $fs-12;
  line-height: 16px;
}

@keyframes loading {
  from {
    width: 0;
  }
  to {
    width: 99%;
  }
}

// .si-LoadIcon {
//   animation: spin 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
// }
//
// @keyframes spin {
//   0% {
//     transform: rotate(0deg);
//   }
//   100% {
//     transform: rotate(360deg);
//   }
// }
</style>
