<template>
  <TableHead />
</template>

<script setup>
import { computed, h, withDirectives } from 'vue'

import { ONE_DAY_WIDTH_BY_VIEW } from '@/utils/roadmap'

import OkrTimelinesTableHeadCell from '@/components/objectives/roadmap/OkrTimelinesTableHeadCell'

defineOptions({
  name: 'OkrTimelinesTableHead'
})

const props = defineProps({
  data: {
    type: Array,
    required: true
  },
  activeView: {
    type: String,
    required: true
  },

  oneDayWidth: {
    type: Number,
    required: true
  }
})

const emit = defineEmits(['select-active-date-reference'])

const isOneDayWidthLessThanDefault = computed(
  () => props.oneDayWidth < ONE_DAY_WIDTH_BY_VIEW[props.activeView].DEFAULT
)

const firstLetterFontSize = computed(() => (isOneDayWidthLessThanDefault.value ? '0px' : 'inherit'))

const TableHead = () => {
  return h(
    'div',
    {
      class: 'ott-Head',
      style: {
        '--first-letter-font-size': firstLetterFontSize.value
      }
    },
    props.data.map(item => {
      return withDirectives(
        h(OkrTimelinesTableHeadCell, {
          data: item,
          activeView: props.activeView,
          oneDayWidth: props.oneDayWidth,
          key: item.key,
          onSelectActiveDateReference: payload => {
            emit('select-active-date-reference', payload)
          }
        }),
        ['memo', item.title]
      )
    })
  )
}
</script>

<style lang="scss" scoped>
/* this eslint plugin won't work with vue 3 render function in <script setup> */
/* eslint-disable vue-scoped-css/no-unused-selector */
.ott-Head {
  display: flex;
  // box-shadow: inset 0 -1px 0 $grey-medium;
  border-bottom: 2px solid $grey-2-next;
  width: fit-content;
}
/* eslint-enable vue-scoped-css/no-unused-selector */
</style>
