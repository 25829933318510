<template>
  <AppButton
    :class="classes"
    :disable="disable"
    class="atcb-Button"
    remove-padding
    type="link-dark"
    @click="onClick"
  >
    <slot name="before" />
    <AppIcon
      v-if="iconName"
      :icon-name="iconName"
      class="actb-Button_Icon"
      height="24"
      width="24"
    />
    <slot />
  </AppButton>
</template>

<script setup>
import { computed } from 'vue'

import AppButton from '@/components/ui/AppButton/AppButton'
import AppIcon from '@/components/ui/AppIcon/AppIcon'

defineOptions({
  name: 'AppTableCreateButton'
})

const props = defineProps({
  disable: {
    type: Boolean
  },

  iconName: {
    type: String,
    default: ''
  },

  fullWidth: {
    type: Boolean
  },

  noPadding: {
    type: Boolean
  },

  noHover: {
    type: Boolean
  }
})

const emit = defineEmits({
  click: null
})
const onClick = () => emit('click')

const classes = computed(() => {
  return {
    'atcb-Button-full-width': props.fullWidth,
    'atcb-Button-no-padding': props.noPadding,
    'atcb-Button-no-hover': props.noHover
  }
})
</script>

<style lang="scss" scoped>
.atcb-Button {
  height: auto;
  border-radius: 0;
  justify-content: flex-start;
  position: relative;
  --gap: var(--gap, 4px);
  &:not(&-no-padding) {
    padding: 10px $page-right-padding var(--padding-bottom, 10px)
      var(--padding-left, #{$page-left-padding});
  }

  &-full-width {
    width: 100%;
  }

  &:not(&-no-hover) {
    &:hover {
      background-color: $grey-3-next;
    }
  }
}

.actb-Button_Icon {
  color: var(--icon-color, inherit);
}
</style>
