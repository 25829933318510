<template>
  <div class="all-Wrapper">
    <div v-for="item in ROWS" :key="item.id" class="all-Item">
      <SkeletonItem :appearance="CIRCLE" :size="SM" />
      <div class="all-Body">
        <div class="all-Body_Top">
          <SkeletonItem :size="XS" :width="item.author" />
          <SkeletonItem :size="XS" :width="item.event" />
          <SkeletonItem :size="XS" :width="item.date" />
        </div>
        <div class="all-Body_Bottom">
          <SkeletonItem :size="XS" :width="item.description" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ActivityLogLoader'
})
</script>

<script setup>
import {
  SKELETON_APPEARANCES,
  SKELETON_SIZES
} from '@/utils/components-configurations/skeleton-item'

import SkeletonItem from '@/components/ui/SkeletonLoaders/SkeletonItem'

const { CIRCLE } = SKELETON_APPEARANCES
const { XS, SM } = SKELETON_SIZES

const ROWS = [
  {
    id: 0,
    author: '113px',
    event: '180px',
    date: '148px',
    description: '291px'
  },
  {
    id: 1,
    author: '70px',
    event: '213px',
    date: '60px',
    description: '100%'
  },
  {
    id: 2,
    author: '164px',
    event: '30px',
    date: '90px',
    description: '360px'
  },
  {
    id: 3,
    author: '100px',
    event: '160px',
    date: '120px',
    description: '180px'
  },
  {
    id: 4,
    author: '150px',
    event: '100px',
    date: '100px',
    description: '262px'
  }
]
</script>

<style scoped lang="scss">
.all-Wrapper {
  display: grid;
  gap: 24px;
}

.all-Item {
  display: flex;
  gap: 8px;
}

.all-Body {
  display: grid;
  gap: 6px;
  &_Top,
  &_Bottom {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  &_Top {
    height: 24px;
  }

  &_Bottom {
    height: 20px;
  }
}
</style>
