// Sort key used in the sorting functional
export const COLUMNS_SETTINGS = {
  NAME: {
    key: 'name',
    sortKey: 'NAME',
    defaultWidth: 654,
    minWidth: 358
  },
  CONTRIBUTE: {
    key: 'contribute',
    minWidth: 52,
    defaultWidth: 82,
    isConfigurable: true
  },
  CURRENT_TARGET: {
    key: 'currentTarget',
    defaultWidth: 120,
    minWidth: 120,
    isConfigurable: true,
    enabledByDefault: true
  },
  GRADE: {
    key: 'grade',
    sortKey: 'GRADE',
    defaultWidth: 136,
    minWidth: 136,
    isConfigurable: true,
    enabledByDefault: true,
    enabledByDefaultOnRoadmap: true
  },
  STATUS: {
    key: 'status',
    sortKey: 'STATUS',
    defaultWidth: 115,
    minWidth: 68,
    isConfigurable: true,
    enabledByDefault: true
  },
  INTERVAL: {
    key: 'interval',
    sortKey: 'INTERVAL',
    defaultWidth: 100,
    minWidth: 58,
    isConfigurable: true,
    enabledByDefault: true
  },
  GROUPS: {
    key: 'groups',
    sortKey: 'GROUPS',
    defaultWidth: 115,
    minWidth: 84,
    isConfigurable: true,
    enabledByDefault: true
  },
  ASSIGNEE: {
    key: 'assignee',
    sortKey: 'ASSIGNEE',
    defaultWidth: 125,
    minWidth: 78,
    isConfigurable: true,
    enabledByDefault: true
  },
  STAKEHOLDERS: {
    key: 'stakeholders',
    defaultWidth: 120,
    minWidth: 120,
    isConfigurable: true
  },
  COMMENTS: {
    key: 'comments',
    defaultWidth: 130,
    minWidth: 84,
    isConfigurable: true,
    enabledByDefault: true
  },
  LAST_GRADE_UPDATE: {
    key: 'lastGradeUpdate',
    defaultWidth: 130,
    minWidth: 84,
    isConfigurable: true,
    enabledByDefault: true
  },
  START_DATE: {
    key: 'startDate',
    sortKey: 'START_DATE',
    defaultWidth: 105,
    minWidth: 86,
    isConfigurable: true
  },
  DUE_DATE: {
    key: 'duedate',
    sortKey: 'DUE_DATE',
    defaultWidth: 105,
    minWidth: 84,
    isConfigurable: true,
    enabledByDefault: true
  },

  LABELS: {
    key: 'labels',
    sortKey: 'LABELS',
    defaultWidth: 105,
    minWidth: 84,
    isConfigurable: true
  },
  PRIVACY: {
    key: 'privacy',
    defaultWidth: 65,
    minWidth: 65,
    isConfigurable: true
  },
  OPTIONS: {
    key: 'options',
    defaultWidth: 88,
    minWidth: 88
  }
}

export const COLUMNS = Object.fromEntries(
  Object.entries(COLUMNS_SETTINGS).map(([columnKey, val]) => {
    const { key } = val
    return [columnKey, key]
  })
)

export const CONFIGURABLE_COLUMNS = Object.values(COLUMNS_SETTINGS)
  .filter(column => {
    return column.isConfigurable
  })
  .map(column => {
    const { key } = column
    return key
  })

export const ENABLED_COLUMNS_BY_DEFAULT = Object.values(COLUMNS_SETTINGS)
  .filter(column => {
    return column.enabledByDefault
  })
  .map(column => {
    const { key } = column
    return key
  })

export const ROADMAP_ENABLED_COLUMNS_BY_DEFAULT = Object.values(COLUMNS_SETTINGS)
  .filter(column => {
    return column.enabledByDefaultOnRoadmap
  })
  .map(column => {
    const { key } = column
    return key
  })

export const highlightRow = row => {
  row.classList.add('animation')
  setTimeout(() => {
    row.classList.remove('animation')
  }, 1500) // duration of animation
}

export const isElementInViewPort = element => {
  const { top, left, bottom, right } = element.getBoundingClientRect()
  return (
    top >= 0 &&
    left >= 0 &&
    bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    right <= (window.innerWidth || document.documentElement.clientWidth)
  )
}

export const getAvailableColumns = ({
  columnsList = [],
  currentWorkspaceCustomFieldsIds = []
} = {}) => {
  return columnsList.filter(column => {
    return (
      Object.values(COLUMNS).includes(column) ||
      currentWorkspaceCustomFieldsIds.includes(Number(column))
    )
  })
}
