<template>
  <div class="at-Loader">
    <div v-for="row in ROWS" :key="row.id" class="at-Loader_Row">
      <div class="at-Cell">
        <SkeletonItem :size="XS" width="10px" />
      </div>
      <div class="at-Cell">
        <SkeletonItem :size="XS" :width="row.alias" />
      </div>
      <div class="at-Cell">
        <SkeletonItem :size="XS" :width="row.link" />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'MessengersWebhooksLoader'
})
</script>

<script setup>
import { SKELETON_SIZES } from '@/utils/components-configurations/skeleton-item'

import SkeletonItem from '@/components/ui/SkeletonLoaders/SkeletonItem'

const { XS } = SKELETON_SIZES
const ROWS = [
  { id: 0, alias: '75px', link: '200px' },
  {
    id: 1,
    alias: '120px',
    link: '220px'
  },
  {
    id: 2,
    alias: '80px',
    link: '180px'
  },
  {
    id: 3,
    alias: '50px',
    link: '300px'
  },
  {
    id: 4,
    alias: '90px',
    link: '120px'
  }
]
</script>

<style lang="scss" scoped>
.at-Loader_Row {
  height: 45px;
  padding: 0 32px;
  display: flex;
  align-items: center;
}

.at-Cell {
  display: flex;
  align-items: center;

  &:first-child {
    width: 24px;
  }

  &:nth-child(2) {
    flex: 0 1 420px;
  }
  &:nth-child(3) {
    flex: 1 1 auto;
  }
}
</style>
