<template>
  <div :class="[`ff-FormField-${type}`]">
    <div class="ff-LabelField">
      <label :class="['ff-Label', `ff-Label-${type}`]">
        <slot name="label">
          <span>{{ label }}</span>
        </slot>
        <span v-if="required" class="ff-SymbolRequired"> * </span>
      </label>
      <slot name="label-after" />
    </div>
    <slot />
  </div>
</template>

<script>
import { defineComponent } from 'vue'

const TYPES = {
  DEFAULT: 'default',
  INLINE: 'inline',
  FONT_MEDIUM: 'font-medium',
  DEFAULT_NEXT: 'default-next'
}

export default defineComponent({
  name: 'FormField',
  props: {
    label: {
      type: String,
      default: ''
    },

    required: {
      type: Boolean
    },

    type: {
      type: String,
      default: TYPES.DEFAULT,
      validator: v => Object.values(TYPES).includes(v)
    }
  }
})
</script>

<style lang="scss" scoped>
.ff-FormField-inline {
  margin-bottom: 16px;
}

.ff-LabelField {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
  .ff-FormField-default-next & {
    margin-bottom: 6px;
  }
}

.ff-Label {
  font-weight: fw('regular');
  font-size: $fs-14;
  font-family: $system-ui;
  display: inline-block;
  &-default {
    line-height: 24px;
  }
  &-inline {
    line-height: 1;
  }
  &-font-medium {
    font-weight: fw('medium');
  }
}

.ff-SymbolRequired {
  color: $red-1;
}
</style>
