import { useElementBounding, useScroll } from '@vueuse/core'
import { debounce } from 'lodash'
import { ref, watch } from 'vue'

export const MENU_PINNED_STATUS_LS_KEY = 'MENU_PINNED_STATUS'
export const NAV_ITEM_PLACEMENTS = {
  TOP: 'top',
  BOTTOM: 'bottom'
}

export const APP_MENU_NAV_LAYOUTS = {
  VERTICAL: 'vertical',
  HORIZONTAL: 'horizontal'
}

export const APP_MENU_LOGO_PLACEMENTS = {
  MAIN_MENU: 'main-menu',
  ADDITIONAL_MENU: 'additional-menu'
}
export const BOTTOM_NAV_NOTIFICATIONS_PORTAL_NAME = 'app-menu-bottom-nav-notifications'

export const useAppMenuHelpers = () => {
  const menuReference = ref(null)
  const { arrivedState, measure } = useScroll(menuReference)
  const { height } = useElementBounding(menuReference)

  const debouncedMeasure = debounce(() => measure(), 500)

  watch(
    height,
    () => {
      debouncedMeasure()
    },
    { immediate: true }
  )

  return {
    menuReference,
    arrivedState
  }
}
