import { cloneDeep, isEmpty } from 'lodash'
import { computed, nextTick } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

import GlobalGroupsApiHandler from '@/api/global-groups'
import { useInfiniteExpandableTable } from '@/composables/infinite-expandable-table'
import { ROUTE_NAMES } from '@/routes/route-helpers'
import { OKR_ELEMENT_ENTITY_KEYS, REQUEST_ENTITY_KEYS } from '@/utils/entity-keys'
import { handleError } from '@/utils/error-handling'
import { DEFAULT_LIMIT, GLOBAL_GROUPS_SEARCH_TYPES } from '@/utils/global-groups'
import { DEFAULT_VALUE_FOR_FILTER, SELECT_ALL_VALUE } from '@/utils/okr-elements/filters'
import { EDIT_GROUP_KEY, GLOBAL_GROUPS_EXPANDED_ITEMS } from '@/utils/query-parameters'
import { getSelectWithSelectAllApiParameter } from '@/utils/select'

export const useGlobalGroups = () => {
  const { t } = useI18n()
  const { WORKSPACE_IDS, GROUP_IDS, SEARCH_TYPE_ID, START_AT } = REQUEST_ENTITY_KEYS

  const useGlobalGroupsTableHelpers = ({
    childItemsKey = 'childGroups',
    saveExpandedItemsAndFiltersToQuery = true
  } = {}) => {
    const DEFAULT_FILTERS_VALUES = {
      [WORKSPACE_IDS]: cloneDeep(DEFAULT_VALUE_FOR_FILTER),
      [GROUP_IDS]: cloneDeep(DEFAULT_VALUE_FOR_FILTER)
    }

    const {
      listState,
      onExpandCollapseItem,
      restoreFiltersValuesAndExpandedItems,
      expandableItemsChains,
      expandAll,
      collapseAll,
      expandItemsRecursively,
      isFiltersUsed,
      resetListState
    } = useInfiniteExpandableTable({
      defaultFiltersValues: cloneDeep(DEFAULT_FILTERS_VALUES),
      saveExpandedItemsAndFiltersToQuery,
      expandedItemsQueryKey: GLOBAL_GROUPS_EXPANDED_ITEMS,
      childItemsKey
    })

    const requestParameters = computed(() => {
      return Object.fromEntries(
        Object.entries(listState.value.filtersValues).map(([key, value]) => {
          return [key, getSelectWithSelectAllApiParameter(value)]
        })
      )
    })

    const fetchGroupsForTable = async ({
      limit = DEFAULT_LIMIT,
      keepLoadedItems = false,
      expandAll
    } = {}) => {
      if (listState.value.isLoading || listState.value.isAllItemsLoaded) {
        return
      }
      const api = new GlobalGroupsApiHandler()
      try {
        listState.value.isLoading = true
        const result = await api.getGlobalGroups({
          ...requestParameters.value,
          [SEARCH_TYPE_ID]: GLOBAL_GROUPS_SEARCH_TYPES.BOTTOM_UP,
          limit,
          [START_AT]: listState.value.offset,
          expandAll: isFiltersUsed.value ? undefined : expandAll
        })

        if (!listState.value.offset) {
          listState.value.items = []
        }

        if (result.length < DEFAULT_LIMIT) {
          listState.value.isAllItemsLoaded = true
        }

        if (isFiltersUsed.value) {
          if (listState.value.initialDataLoaded) {
            expandItemsRecursively({
              items: result,
              mergeWithCurrentList: listState.value.offset > 0 || keepLoadedItems
            })
          }
        }

        if (isEmpty(listState.value.expandedItems) && !isFiltersUsed.value && expandAll) {
          await expandItemsRecursively({
            items: result,
            mergeWithCurrentList: listState.value.offset > 0 || keepLoadedItems,
            awaitForWorker: true
          })
        }

        listState.value.offset += result.length < DEFAULT_LIMIT ? DEFAULT_LIMIT : result.length

        listState.value.items = [...listState.value.items, ...result]

        listState.value.isLoading = false

        if (!listState.value.initialDataLoaded) {
          listState.value.initialDataLoaded = true
        }

        await nextTick()
      } catch (error) {
        handleError({ error })
      }
    }

    const reloadAllGroups = async ({
      resetFilters = false,
      keepLoadedItems = false,
      resetItems = true
    } = {}) => {
      if (resetFilters) {
        listState.value.filtersValues = cloneDeep(DEFAULT_FILTERS_VALUES)
      }

      const limit = keepLoadedItems ? listState.value.offset : DEFAULT_LIMIT
      listState.value.offset = 0
      listState.value.isAllItemsLoaded = false

      if (resetItems) {
        listState.value.items = []
      }

      await fetchGroupsForTable({ limit, keepLoadedItems, expandAll: false })
    }

    return {
      expandAll,
      collapseAll,
      restoreFiltersValuesAndExpandedItems,
      expandableItemsChains,
      listState,
      onExpandCollapseItem,
      isFiltersUsed,
      fetchGroupsForTable,
      reloadAllGroups,
      resetListState
    }
  }

  const fetchGroupsForFilter = async ({
    searchString = null,
    groupIds = [],
    workspaceIds = []
  } = {}) => {
    const api = new GlobalGroupsApiHandler()
    return await api.getGroupsForFilter({
      searchString,
      workspaceIds,
      [GROUP_IDS]: getSelectWithSelectAllApiParameter(groupIds)
    })
  }

  const ALL_WORKSPACES_OPTION = {
    [OKR_ELEMENT_ENTITY_KEYS.NAME]: t('users.all_workspaces'),
    [OKR_ELEMENT_ENTITY_KEYS.ID]: SELECT_ALL_VALUE
  }

  const ALL_GROUPS_OPTION = {
    [OKR_ELEMENT_ENTITY_KEYS.ID]: SELECT_ALL_VALUE,
    [OKR_ELEMENT_ENTITY_KEYS.NAME]: t('filter.all_groups')
  }

  return {
    useGlobalGroupsTableHelpers,
    fetchGroupsForFilter,
    ALL_WORKSPACES_OPTION,
    ALL_GROUPS_OPTION
  }
}

export const useGoToEditGroup = () => {
  const router = useRouter()
  const goToEditGroup = ({ groupId }) => {
    router.push({
      name: ROUTE_NAMES.ORGANIZATION_GLOBAL_GROUPS,
      query: { [EDIT_GROUP_KEY]: groupId }
    })
  }

  return {
    goToEditGroup
  }
}
