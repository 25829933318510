<template>
  <FormFieldNext :label="t('field.workspace.title')" class="cmdm-WorkspaceSelect">
    <ConfluenceWorkspaceSelect
      :workspace-id="settings.workspaceId"
      dropdown-width=""
      @update:workspace-id="updateWorkspaceId"
    />
  </FormFieldNext>
  <div class="cmdm-AdditionalSelects">
    <FormFieldNext :label="t('field.quoter.title')">
      <slot name="interval">
        <ConfluenceIntervals
          v-if="settings.workspaceId"
          :interval="settings.intervalId"
          :workspace-id="settings.workspaceId"
          @update:interval="updateValue($event, INTERVAL)"
        />
      </slot>
    </FormFieldNext>
    <template v-if="settings.workspaceId && groupSettings.groupType">
      <FormFieldNext :label="t('roles.owner')">
        <ConfluenceDashboardOwners
          :max-height="120"
          :model-value="settings.assigneeId"
          :workspace-id="settings.workspaceId"
          @update:model-value="updateValue($event, ASSIGNEE_ID)"
          @update:options="optionsUpdated"
        />
      </FormFieldNext>
      <FormFieldNext :label="t('field.groups.title')">
        <ConfluenceDashboardGroups
          :model-value="settings.groupId"
          :workspace-id="settings.workspaceId"
          class="cmdm-Select"
          @update:model-value="updateValue($event, GROUP_ID)"
          @update:options="optionsUpdated"
        />
      </FormFieldNext>
    </template>

    <div v-else class="cmdm-AdditionalSelects">
      <FormFieldNext :label="t('roles.owner')">
        <ConfluenceHomeScreenOwners
          v-if="settings.workspaceId"
          :assignee-id="settings.assigneeId"
          :category="settings.category"
          :max-height="230"
          :workspace-id="settings.workspaceId"
          @update:assignee-id="updateValue($event, ASSIGNEE_ID)"
          @update:category="updateValue($event, CATEGORY)"
          @update:options="optionsUpdated"
        />
      </FormFieldNext>
      <FormFieldNext :label="t('group.group_by')">
        <AppSelect
          :dropdown-min-width="200"
          :is-highlighted="!!settings.searchTypeId"
          :model-value="settings.searchTypeId"
          :offset="[0, 0]"
          :options="searchTypeOptions"
          :search="false"
          :search-max-length="50"
          :type="SELECT_TYPES.SECONDARY_NEXT"
          append-icon-height="20"
          append-icon-width="20"
          has-fixed-parent
          highlight-option
          icon="sort-two-ways-next"
          no-left-padding
          position="bottom-start"
          prepend-icon="calendar-next"
          skeleton-loader
          skeleton-loader-height="100%"
          skeleton-loader-width="100%"
          style="--select-skeleton-left: 0; --select-skeleton-top: 0"
          @update:model-value="updateValue($event, SEARCH_TYPE_ID)"
        >
          <template #button-content="{ option }">
            <AppSelectItem
              v-if="option"
              :show-delete-button="false"
              :show-selected-count-circle="false"
              :type="SELECT_TYPES.SECONDARY_NEXT"
            >
              {{ option.label }}
            </AppSelectItem>
          </template>
        </AppSelect>
      </FormFieldNext>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import { SELECT_TYPES } from '@/utils/components-configurations/app-select'
import { SEARCH_TYPE_OPTIONS } from '@/utils/homepage'
import { DEFAULT_VALUE_FOR_FILTER } from '@/utils/okr-elements/filters'

import FormFieldNext from '@/components/form/FormFieldNext'
import AppSelect from '@/components/ui/AppSelect/AppSelect'
import AppSelectItem from '@/components/ui/AppSelect/AppSelectItem'
import ConfluenceDashboardGroups from '@jira/components/confluence-macro-dashboard/Toolbar/ConfluenceDashboardGroups'
import ConfluenceDashboardOwners from '@jira/components/confluence-macro-dashboard/Toolbar/ConfluenceDashboardOwners'
import ConfluenceHomeScreenOwners from '@jira/components/confluence-macro-home-screen/Toolbar/ConfluenceHomeScreenOwners'
import ConfluenceIntervals from '@jira/components/ConfluenceIntervals'
import ConfluenceWorkspaceSelect from '@jira/components/ConfluenceWorkspaceSelect'

const WORKSPACE_ID = 'workspaceId'
const INTERVAL = 'intervalId'
const ASSIGNEE_ID = 'assigneeId'
const GROUP_ID = 'groupId'
const CATEGORY = 'category'
const SEARCH_TYPE_ID = 'searchTypeId'

const { t } = useI18n()
const props = defineProps({
  settings: {
    type: Object,
    required: true
  },
  groupSettings: {
    type: Object,
    default: () => ({})
  },
  assigneeDefaultValue: {
    type: [String, Number, Array],
    default: DEFAULT_VALUE_FOR_FILTER
  }
})

const emit = defineEmits({
  'update:workspaceId': null,
  'update:intervalId': null,
  'update:options': null,
  'update:assigneeId': null,
  'update:groupId': null,
  'update:category': null,
  'update:searchTypeId': null
})

const emitList = {
  [WORKSPACE_ID]: 'update:workspaceId',
  [INTERVAL]: 'update:intervalId',
  [ASSIGNEE_ID]: 'update:assigneeId',
  [CATEGORY]: 'update:category',
  [GROUP_ID]: 'update:groupId',
  [SEARCH_TYPE_ID]: 'update:searchTypeId'
}
const updateValue = (value, key) => {
  emit(emitList[key], value)
}

const optionsUpdated = options => {
  emit('update:options', options)
}
const updateWorkspaceId = workspaceId => {
  if (props.settings.workspaceId !== workspaceId) {
    emit(emitList[WORKSPACE_ID], workspaceId)
    emit(emitList[INTERVAL], null)
    emit(emitList[ASSIGNEE_ID], props.assigneeDefaultValue)
    emit(emitList[GROUP_ID], DEFAULT_VALUE_FOR_FILTER)
  }
}

const searchTypeOptions = computed(() =>
  SEARCH_TYPE_OPTIONS.map(({ value, label }) => ({
    value,
    label: t(label)
  }))
)
</script>
<script>
//eslint-disable-next-line
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ConfluenceMacroDashboardMainSettings'
})
</script>

<style lang="scss" scoped>
.cmdm-WorkspaceSelect {
  width: 100%;
}
.cmdm-AdditionalSelects {
  display: flex;
  gap: 8px;
  align-items: center;
}
</style>
