<template>
  <div :style="{ '--toolbar-switcher-width': `${toolbarSwitcherWidth}px` }" class="spm-Wrapper">
    <FormCaption
      :subtitle="t('schedule.participants.subtitle')"
      :title="t('schedule.participants.title')"
    >
      <div class="spm-Toolbar">
        <FormFieldNext
          ref="toolbarSwitcher"
          :label="t('action.add')"
          class="smp-Toolbar_Switcher"
          inline
        >
          <AppRadioGroup
            v-model="formModel[SCHEDULE_PARTICIPANTS_ENTITY_KEYS.STRATEGY_TYPE_ID]"
            :data-auto-testid="PARTICIPANTS_STRATEGY_FIELD_TEST_ID"
            :data-testid="PARTICIPANTS_STRATEGY_FIELD_TEST_ID"
            :options="strategies"
            name="participants-strategy"
            @update:model-value="onUpdateStrategy"
          />
        </FormFieldNext>

        <div v-if="!isAllUsersStrategy" class="spm-Segments">
          <ScheduleParticipantsSelect
            v-model="formModel[SCHEDULE_PARTICIPANTS_ENTITY_KEYS.GROUP_IDS]"
            :data-auto-testid="GROUPS_FIELD_TEST_ID"
            :data-testid="GROUPS_FIELD_TEST_ID"
            :is-error="validationErrors.isSegmentsEmpty"
            :item-label="GLOBAL_GROUP_KEYS.NAME"
            :item-value="GLOBAL_GROUP_KEYS.ID"
            :label="
              t('common.of_entity', {
                entity: t('filter.groups')
              })
            "
            :loading="groupsForAddLoading"
            :options="groupsForAddList"
            :search-function="fetchGroupsForAdd"
            empty-state-icon="team-next-small"
            @open="removeError('isSegmentsEmpty')"
            @update:options="groupsForAddList = $event"
            @toggle-dropdown="openedDropdowns[SCHEDULE_PARTICIPANTS_ENTITY_KEYS.GROUP_IDS] = $event"
          >
            <template #option-label="{ option }">
              <GlobalGroupsSelectOption v-if="option" :group="option" />
            </template>

            <template #empty-state-label>
              {{ t('user_details.select_groups') }}
            </template>

            <template #error>
              <AppFieldError show>
                {{ t('schedule.participants.empty_segments_error') }}
              </AppFieldError>
            </template>
          </ScheduleParticipantsSelect>

          <ScheduleParticipantsSelect
            v-model="formModel[SCHEDULE_PARTICIPANTS_ENTITY_KEYS.USER_ACCOUNT_IDS]"
            :data-auto-testid="USERS_FIELD_TEST_ID"
            :data-testid="USERS_FIELD_TEST_ID"
            :is-error="validationErrors.isSegmentsEmpty"
            :item-label="ASSIGNEE_ENTITY_KEYS.NAME"
            :item-value="ASSIGNEE_ENTITY_KEYS.ACCOUNT_ID"
            :label="
              t('common.and_entity', {
                entity: t('common.users')
              })
            "
            :loading="usersForAddLoading"
            :options="usersForAddList"
            :search-function="fetchUsersForAdd"
            empty-state-icon="user-next-small"
            @open="removeError('isSegmentsEmpty')"
            @update:options="usersForAddList = $event"
            @update:model-value="onUpdateUserSegments"
            @toggle-dropdown="
              openedDropdowns[SCHEDULE_PARTICIPANTS_ENTITY_KEYS.USER_ACCOUNT_IDS] = $event
            "
          >
            <template #option-label="{ option }">
              <OwnerFieldOption :label-key="ASSIGNEE_ENTITY_KEYS.NAME" :option="option" />
            </template>

            <template #empty-state-label>
              {{ t('action.select_user', 0) }}
            </template>

            <template #error>
              <AppFieldError show>
                {{ t('schedule.participants.empty_segments_error') }}
              </AppFieldError>
            </template>
          </ScheduleParticipantsSelect>
        </div>
      </div>
    </FormCaption>

    <AddOkrButton
      v-if="!isFilterAdded"
      :data-auto-testid="ADD_FILTER_BUTTON_TEST_ID"
      :data-testid="ADD_FILTER_BUTTON_TEST_ID"
      class="spm-Button"
      disable-margin
      @click="onAddFilter"
    >
      {{ t('schedule.participants.add_filter') }}
    </AddOkrButton>

    <div
      v-else
      :data-auto-testid="FILTERS_TABLE_TEST_ID"
      :data-testid="FILTERS_TABLE_TEST_ID"
      class="spm-Filter"
    >
      <div class="spm-Filter_Head">
        <AppTitle :level="5" disable-margin>
          {{ t('schedule.participants_filter.title') }}
        </AppTitle>

        <AppButton
          :data-auto-testid="REMOVE_FILTER_BUTTON_TEST_ID"
          :data-testid="REMOVE_FILTER_BUTTON_TEST_ID"
          icon="delete-next"
          size="sm"
          type="ghost-next"
          @click="onRemoveAllFilters"
        />
      </div>
      <div class="spm-Filter_Body">
        <ScheduleParticipantsSelect
          v-model="formModel[SCHEDULE_PARTICIPANTS_ENTITY_KEYS.EXCLUDED_USER_ACCOUNT_IDS]"
          :data-auto-testid="EXCLUDED_USERS_FIELD_TEST_ID"
          :data-testid="EXCLUDED_USERS_FIELD_TEST_ID"
          :hidden-items="hiddenItemsForUserExclusion"
          :item-label="ASSIGNEE_ENTITY_KEYS.NAME"
          :item-value="ASSIGNEE_ENTITY_KEYS.ACCOUNT_ID"
          :label="t('common.users')"
          :loading="excludeUsersLoading"
          :options="usersForExclusionList"
          :search-function="fetchUsersForExclusion"
          empty-state-icon="user-next-small"
          @update:options="usersForExclusionList = $event"
          @toggle-dropdown="
            openedDropdowns[SCHEDULE_PARTICIPANTS_ENTITY_KEYS.EXCLUDED_USER_ACCOUNT_IDS] = $event
          "
        >
          <template #option-label="{ option }">
            <OwnerFieldOption :label-key="ASSIGNEE_ENTITY_KEYS.NAME" :option="option" />
          </template>

          <template #empty-state-label>
            {{ t('action.select_user', 0) }}
          </template>
        </ScheduleParticipantsSelect>

        <ScheduleParticipantsSelect
          v-if="isAllUsersStrategy"
          v-model="formModel[SCHEDULE_PARTICIPANTS_ENTITY_KEYS.EXCLUDED_GROUP_IDS]"
          :data-auto-testid="EXCLUDED_GROUPS_FIELD_TEST_ID"
          :data-testid="EXCLUDED_GROUPS_FIELD_TEST_ID"
          :item-label="GLOBAL_GROUP_KEYS.NAME"
          :item-value="GLOBAL_GROUP_KEYS.ID"
          :label="
            t('common.and_entity', {
              entity: t('filter.groups')
            })
          "
          :loading="excludeGroupsLoading"
          :options="groupsForExclusionList"
          :search-function="fetchGroupsForExclusion"
          empty-state-icon="team-next-small"
          @open="removeError('isSegmentsEmpty')"
          @update:options="groupsForExclusionList = $event"
          @toggle-dropdown="
            openedDropdowns[SCHEDULE_PARTICIPANTS_ENTITY_KEYS.EXCLUDED_GROUP_IDS] = $event
          "
        >
          <template #option-label="{ option }">
            <GlobalGroupsSelectOption v-if="option" :group="option" />
          </template>

          <template #empty-state-label>
            {{ t('user_details.select_groups') }}
          </template>
        </ScheduleParticipantsSelect>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useResizeObserver } from '@vueuse/core'
import { cloneDeep, isEmpty } from 'lodash'
import { computed, ref, useTemplateRef, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import { useFetchGroups, useFetchUsers } from '@/utils/check-ins/schedule-composables'
import {
  DEFAULT_SCHEDULE_PARTICIPANTS,
  STRATEGY_OPTIONS
} from '@/utils/check-ins/schedules-participants'
import { ASSIGNEE_ENTITY_KEYS, SCHEDULE_PARTICIPANTS_ENTITY_KEYS } from '@/utils/entity-keys'
import { GLOBAL_GROUP_KEYS } from '@/utils/global-groups'

import FormCaption from '@/components/check-ins/FormCaption'
import {
  ADD_FILTER_BUTTON_TEST_ID,
  EXCLUDED_GROUPS_FIELD_TEST_ID,
  EXCLUDED_USERS_FIELD_TEST_ID,
  FILTERS_TABLE_TEST_ID,
  GROUPS_FIELD_TEST_ID,
  PARTICIPANTS_STRATEGY_FIELD_TEST_ID,
  REMOVE_FILTER_BUTTON_TEST_ID,
  USERS_FIELD_TEST_ID
} from '@/components/check-ins/jest-helpers'
import ScheduleParticipantsSelect from '@/components/check-ins/ScheduleParticipantsSelect'
import AppFieldError from '@/components/form/AppFieldError'
import FormFieldNext from '@/components/form/FormFieldNext'
import OwnerFieldOption from '@/components/form/OwnerFieldOption'
import GlobalGroupsSelectOption from '@/components/global-groups/GlobalGroupsSelectOption'
import AddOkrButton from '@/components/objectives/forms/AddOkrButton'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppRadioGroup from '@/components/ui/AppRadioGroup/AppRadioGroup'
import AppTitle from '@/components/ui/AppTitle/AppTitle'

defineOptions({
  name: 'ScheduleParticipantsManagement'
})

const formModel = defineModel({
  type: Object,
  required: true,
  set: value => value
})

defineProps({
  validationErrors: {
    type: Object,
    default: () => ({
      isSegmentsEmpty: false
    })
  }
})

const { t } = useI18n()

const isFilterAdded = ref(false)

const usersForExclusionList = ref([])

const { doFetch: fetchUsersForExclusion, loading: excludeUsersLoading } = useFetchUsers({
  selectedUsers: formModel.value[SCHEDULE_PARTICIPANTS_ENTITY_KEYS.EXCLUDED_USER_ACCOUNT_IDS]
})

const groupsForExclusionList = ref([])

const { doFetch: fetchGroupsForExclusion, loading: excludeGroupsLoading } = useFetchGroups({
  selectedGroups: formModel.value[SCHEDULE_PARTICIPANTS_ENTITY_KEYS.EXCLUDED_GROUP_IDS]
})

const groupsForAddList = ref([])

const { doFetch: fetchGroupsForAdd, loading: groupsForAddLoading } = useFetchGroups({
  selectedGroups: formModel.value[SCHEDULE_PARTICIPANTS_ENTITY_KEYS.GROUP_IDS]
})

const usersForAddList = ref([])

const { doFetch: fetchUsersForAdd, loading: usersForAddLoading } = useFetchUsers({
  selectedUsers: formModel.value[SCHEDULE_PARTICIPANTS_ENTITY_KEYS.USER_ACCOUNT_IDS]
})

const setListsForFilters = async () => {
  const setUsersFilter = async () => {
    usersForExclusionList.value = await fetchUsersForExclusion()
  }

  const setGroupsFilter = async () => {
    groupsForExclusionList.value = await fetchGroupsForExclusion()
  }

  const promises = []

  if (isEmpty(usersForExclusionList.value)) {
    promises.push(setUsersFilter())
  }

  if (isEmpty(groupsForExclusionList.value) && isAllUsersStrategy.value) {
    promises.push(setGroupsFilter())
  }

  await Promise.all(promises)
}

const onAddFilter = async () => {
  isFilterAdded.value = true

  await setListsForFilters()
}

const setListsForSegmentStrategy = async () => {
  const setUsersForAdd = async () => {
    usersForAddList.value = await fetchUsersForAdd()
  }

  const setGroupsForAdd = async () => {
    groupsForAddList.value = await fetchGroupsForAdd()
  }

  const promises = []

  if (isEmpty(usersForAddList.value)) {
    promises.push(setUsersForAdd())
  }

  if (isEmpty(groupsForAddList.value)) {
    promises.push(setGroupsForAdd())
  }

  await Promise.all(promises)
}

const onUpdateStrategy = async newStrategy => {
  onRemoveAllFilters()

  if (newStrategy === STRATEGY_OPTIONS.SELECTED_SEGMENT[SCHEDULE_PARTICIPANTS_ENTITY_KEYS.VALUE]) {
    await setListsForSegmentStrategy()
  }
}

const onRemoveAllFilters = () => {
  if (isFilterAdded.value) {
    isFilterAdded.value = false
    formModel.value[SCHEDULE_PARTICIPANTS_ENTITY_KEYS.EXCLUDED_USER_ACCOUNT_IDS] = cloneDeep(
      DEFAULT_SCHEDULE_PARTICIPANTS[SCHEDULE_PARTICIPANTS_ENTITY_KEYS.EXCLUDED_USER_ACCOUNT_IDS]
    )

    formModel.value[SCHEDULE_PARTICIPANTS_ENTITY_KEYS.EXCLUDED_GROUP_IDS] = cloneDeep(
      DEFAULT_SCHEDULE_PARTICIPANTS[SCHEDULE_PARTICIPANTS_ENTITY_KEYS.EXCLUDED_GROUP_IDS]
    )
  }
}

const isAllUsersStrategy = computed(() => {
  return (
    formModel.value[SCHEDULE_PARTICIPANTS_ENTITY_KEYS.STRATEGY_TYPE_ID] ===
    STRATEGY_OPTIONS.ALL_USERS[SCHEDULE_PARTICIPANTS_ENTITY_KEYS.VALUE]
  )
})

const hiddenItemsForUserExclusion = computed(() => {
  return isAllUsersStrategy.value
    ? []
    : formModel.value[SCHEDULE_PARTICIPANTS_ENTITY_KEYS.USER_ACCOUNT_IDS]
})

const onUpdateUserSegments = newValue => {
  formModel.value[SCHEDULE_PARTICIPANTS_ENTITY_KEYS.EXCLUDED_USER_ACCOUNT_IDS] = formModel.value[
    SCHEDULE_PARTICIPANTS_ENTITY_KEYS.EXCLUDED_USER_ACCOUNT_IDS
  ].filter(id => !newValue.includes(id))
}

const emit = defineEmits({
  'remove-error': null
})

const removeError = key => {
  emit('remove-error', key)
}

watch(
  () => formModel.value,
  async (newValue, oldValue) => {
    if (newValue && !oldValue) {
      const promises = []

      const isSomeFilterAdded =
        !isEmpty(newValue[SCHEDULE_PARTICIPANTS_ENTITY_KEYS.EXCLUDED_USER_ACCOUNT_IDS]) ||
        !isEmpty(newValue[SCHEDULE_PARTICIPANTS_ENTITY_KEYS.EXCLUDED_GROUP_IDS])

      if (isSomeFilterAdded) {
        promises.push(onAddFilter())
      }

      const isSelectedSegmentStrategy =
        newValue[SCHEDULE_PARTICIPANTS_ENTITY_KEYS.STRATEGY_TYPE_ID] ===
        STRATEGY_OPTIONS.SELECTED_SEGMENT[SCHEDULE_PARTICIPANTS_ENTITY_KEYS.VALUE]

      if (isSelectedSegmentStrategy) {
        promises.push(setListsForSegmentStrategy())
      }

      await Promise.all(promises)
    }
  },
  { deep: true, immediate: true }
)

const strategies = computed(() => {
  return Object.values(STRATEGY_OPTIONS).map(option => {
    return {
      ...option,
      label: t(option[SCHEDULE_PARTICIPANTS_ENTITY_KEYS.LABEL])
    }
  })
})

const isSomeDropdownOpenedModel = defineModel('isSomeDropdownOpened', {
  type: Boolean,
  required: true,
  set: value => value
})

const openedDropdowns = ref({})

watch(
  openedDropdowns,
  (newValue = {}) => {
    isSomeDropdownOpenedModel.value = Object.values(newValue).some(Boolean)
  },
  {
    deep: true
  }
)

const toolbarSwitcherRef = useTemplateRef('toolbarSwitcher')

const toolbarSwitcherWidth = ref(0)

useResizeObserver(toolbarSwitcherRef, entries => {
  toolbarSwitcherWidth.value = entries[0].contentRect.width
})
</script>

<style lang="scss" scoped>
.spm-Toolbar {
  max-width: 100%;
  --option-padding: 8px 12px;
  --option-font-size: #{$fs-14};
}

.smp-Toolbar_Switcher {
  width: max-content;
}

.spm-Segments {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 12px;
}

.spm-Button {
  --button-text-color: #{$primary-color-next};
  --button-padding: 6px 10px;
  margin-top: 20px;
  width: max(var(--toolbar-switcher-width), 240px);
}

.spm-Filter {
  border: 2px solid $grey-3-next;
  border-radius: $border-radius-md-next;
  width: 100%;
}

.spm-Filter {
  margin-top: 20px;
}

.spm-Filter_Head,
.spm-Filter_Body {
  padding-inline: 22px 18px;
}

.spm-Filter_Head {
  padding-block: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: inherit;
}

.spm-Filter_Body {
  padding-block: 22px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
</style>
