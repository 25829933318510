<template>
  <AppDialog
    :loading="isLoading"
    :show="show"
    :size="680"
    :title="$t('groups.delete_title')"
    :type="DIALOG_TYPES.DELETE"
    @on-confirm="onConfirm"
    @on-close="onClose"
  >
    <div class="ggd-ModalBody">
      <AppInfoMessage :type="infoMessageType">
        <i18n-t keypath="global_groups.delete_note" scope="global">
          <template #groupName>
            <b>{{ groupToDelete.name }}</b>
          </template>
        </i18n-t>
      </AppInfoMessage>

      <KeywordInput ref="keywordInputReference" :keyword="KEYWORD" />

      <FormFieldNext
        v-if="isShowDeleteStrategy"
        :label="$t('groups.unlink.strategy_label')"
        class="ggd-FormField"
      >
        <AppRadioGroup
          v-model="deleteStrategy"
          :is-error="!isDeleteStrategyValid"
          :options="DELETE_STRATEGY_OPTIONS"
          :type="APP_RADIO_GROUP_TYPES.BLOCKS"
          data-auto-testid="delete-strategy-switch"
          name="unlink-strategy"
          @update:model-value="isDeleteStrategyValid = true"
        />

        <AppFieldError
          v-if="!isDeleteStrategyValid"
          :show="!isDeleteStrategyValid"
          class="ggd-FieldError"
        >
          {{ $t('field.required') }}
        </AppFieldError>
      </FormFieldNext>
    </div>
  </AppDialog>
</template>

<script setup>
import { isEmpty, isNull } from 'lodash'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

import GlobalGroupsApiHandler from '@/api/global-groups'
import { tracker } from '@/tracking/amplitude'
import {
  EVENT_CATEGORIES,
  MODE_NAMES_FOR_TRACKING,
  TRACKING_UNKNOWN
} from '@/tracking/amplitude-helpers'
import { DIALOG_TYPES } from '@/utils/components-configurations/app-dialog'
import { INFO_MESSAGE_TYPES } from '@/utils/components-configurations/app-info-message'
import { RADIO_GROUP_TYPES as APP_RADIO_GROUP_TYPES } from '@/utils/components-configurations/app-radio-group'
import { handleError } from '@/utils/error-handling'
import { KEYWORD } from '@/utils/helpers'

import AppDialog from '@/components/AppDialog'
import AppFieldError from '@/components/form/AppFieldError'
import FormFieldNext from '@/components/form/FormFieldNext'
import AppInfoMessage from '@/components/ui/AppInfoMessage'
import AppRadioGroup from '@/components/ui/AppRadioGroup/AppRadioGroup'
import KeywordInput from '@/components/ui/KeywordInput/KeywordInput'

defineOptions({
  name: 'GlobalGroupsDeleteModal'
})

const props = defineProps({
  show: {
    type: Boolean
  },

  groupToDelete: {
    type: Object,
    default: () => ({})
  },

  trackingSource: {
    type: String,
    default: TRACKING_UNKNOWN
  }
})

const emit = defineEmits({
  close: null,
  'on-group-deleted': null
})

const { t } = useI18n()
const DELETE_STRATEGY_OPTIONS = [
  {
    label: t('groups.unlink.keep_users.label'),
    message: t('global_groups.keep_users.message'),
    value: true,
    icon: 'keep-users'
  },
  {
    label: t('groups.unlink.delete_users.label'),
    message: t('global_groups.delete_users.message'),
    value: false,
    icon: 'delete-users'
  }
]

const isKeywordFieldValid = ref(true)
const deleteStrategy = ref(null)
const isDeleteStrategyValid = ref(true)

const isFormValid = computed(() => isKeywordFieldValid.value && isDeleteStrategyValid.value)

const isShowDeleteStrategy = computed(() => {
  // we don't need make decision keep or delete users if group has no users or not linked to any workspace
  const { groupToDelete } = props

  return !isEmpty(groupToDelete.workspaces) && Boolean(groupToDelete.userCount)
})

const store = useStore()

const userRoleForTracking = computed(() => {
  return store.getters['system/userRoleForTracking']
})

const keywordInputReference = ref(null)

const isLoading = ref(false)
const onConfirm = async () => {
  if (!isShowDeleteStrategy.value) {
    // if we don't need make decision keep or delete users we can delete group without confirmation
    // set default value for deleteStrategy to avoid error in api call
    deleteStrategy.value = false
  }
  const isKeywordValid = keywordInputReference.value.validate()
  const isStrategyValid = !isNull(deleteStrategy.value)
  if (!isKeywordValid || !isStrategyValid) {
    isKeywordFieldValid.value = isKeywordValid
    isDeleteStrategyValid.value = isStrategyValid
    return
  }

  isLoading.value = true
  const api = new GlobalGroupsApiHandler()

  try {
    await api.deleteGlobalGroup({
      id: props.groupToDelete.id,
      keepUsers: deleteStrategy.value
    })

    tracker.logEvent('Group deleted', {
      category: EVENT_CATEGORIES.GROUP_MANAGEMENT,
      source: props.trackingSource,
      role: userRoleForTracking.value,
      mode: deleteStrategy.value
        ? MODE_NAMES_FOR_TRACKING.KEEP_USERS
        : MODE_NAMES_FOR_TRACKING.DELETE_USERS
    })

    emit('on-group-deleted', props.groupToDelete.id)
  } catch (error) {
    handleError({ error })
  } finally {
    isLoading.value = false
  }
}

const onClose = () => {
  emit('close')
}

const setDefaultState = () => {
  isKeywordFieldValid.value = true
  deleteStrategy.value = null
  isDeleteStrategyValid.value = true
}

watch(
  () => props.show,
  async newValue => {
    if (!newValue) {
      setDefaultState()
    }
  },
  {
    immediate: true
  }
)

const infoMessageType = computed(() => {
  return isFormValid.value ? INFO_MESSAGE_TYPES.WARNING : INFO_MESSAGE_TYPES.ERROR
})
</script>

<style lang="scss" scoped>
.ggd-ModalBody {
  display: grid;
  gap: 20px;
}

.ggd-FieldError {
  line-height: 20px;
}
</style>
