<template>
  <div class="uro-Loader">
    <div v-for="row in ROWS" :key="row.id" class="uro-Loader_Item">
      <div
        class="uro-LoaderItem_Left"
        :class="{ 'uro-LoaderItem_Left-no-expand': !row.expnadable }"
      >
        <SkeletonItem v-if="row.expnadable" :appearance="SQUARE" :size="SM" />
        <SkeletonItem :appearance="SQUARE" :size="SM" border-radius="6px" />
        <SkeletonItem :size="XS" width="64px" />
        <SkeletonItem :size="XS" :width="row.name" />
      </div>
      <div class="uro-LoaderItem_Right">
        <div class="uro-LoaderItem_Grade">
          <SkeletonItem :size="XS" :width="row.grade" />
        </div>
        <div class="uro-Loader_User">
          <SkeletonItem v-if="row.info" :size="XS" :appearance="CIRCLE" />
          <SkeletonItem :size="SM" :appearance="CIRCLE" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'UserReportOkrElementsLoader'
})
</script>

<script setup>
import {
  SKELETON_APPEARANCES,
  SKELETON_SIZES
} from '@/utils/components-configurations/skeleton-item'

import SkeletonItem from '@/components/ui/SkeletonLoaders/SkeletonItem'

const { SQUARE, CIRCLE } = SKELETON_APPEARANCES
const { SM, XS } = SKELETON_SIZES

const ROWS = [
  {
    id: 1,
    expnadable: true,
    name: '116px',
    grade: '37px'
  },
  {
    id: 2,
    name: '204px',
    grade: '24px',
    info: true
  },
  {
    expnadable: true,

    id: 3,
    name: '36px',
    grade: '17px'
  },
  {
    id: 4,
    expnadable: true,
    name: '309px',

    grade: '37px',
    info: true
  },
  {
    id: 5,
    grade: '37px',
    name: '270px'
  }
]
</script>

<style scoped lang="scss">
.uro-Loader_Item {
  width: 100%;
  display: flex;
  align-items: center;
  height: 44px;
  gap: 12px;
}

.uro-LoaderItem_Left,
.uro-LoaderItem_Right,
.uro-Loader_User {
  align-items: center;
  display: flex;
}

.uro-LoaderItem_Left {
  gap: 8px;

  &-no-expand {
    padding-left: 32px;
  }
}

.uro-LoaderItem_Right {
  gap: 26px;
  justify-content: space-between;
  width: 180px;
  margin: 0 48px 0 auto;
}

.uro-LoaderItem_Grade {
  width: 37px;
  display: flex;
  justify-content: flex-end;
}

.uro-Loader_User {
  gap: inherit;
}
</style>
