<template>
  <div>
    <div v-for="objective in elements" :key="objective.id" class="cdvv-Wrapper">
      <div class="cdvv-Content">
        <div class="cdvv-ObjectiveWrapper">
          <CustomDashboardListItemDetailed
            :objective="objective"
            :readonly="isReadonly"
            :style="{
              '--border-color': getBorderColor(objective),
              ...getGradeColorStyle(objective.gradeColor)
            }"
            @open-objective="onOpenObjectiveDetails"
          />
        </div>
        <CustomDashboardNestedItems
          :objective="objective"
          @open-objective="onOpenObjectiveDetails"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { getGradeColorStyle } from '@/utils/okr-elements/grade'

import CustomDashboardListItemDetailed from '@/components/dashboard/custom-dashboard/CustomDashboardListItemDetailed'
import CustomDashboardNestedItems from '@/components/dashboard/custom-dashboard/CustomDashboardNestedItems'

defineOptions({
  name: 'CustomDashboardVerticalView'
})

defineProps({
  elements: {
    type: Object,
    required: true
  },
  isReadonly: {
    type: Boolean
  }
})

const emit = defineEmits({
  'open-objective': null
})
const onOpenObjectiveDetails = payload => {
  emit('open-objective', payload)
}
const getBorderColor = objective => {
  return `rgba(${getGradeColorStyle(objective.gradeColor)['--rgb-color']}, 0.2)`
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/custom-dashboard';

.cdvv-Wrapper {
  padding-left: $page-left-padding;
  padding-right: $page-right-padding;
  &:not(:last-child) {
    margin-bottom: 40px;
  }
}
.cdvv-Content {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  &:deep(.cdni-EmptyState) {
    padding: 0;
  }
}
.cdvv-ObjectiveWrapper {
  &:deep(.cdl-Container) {
    width: 240px;
  }
  &:deep(.cdl-Wrapper) {
    min-height: 180px;
    @extend %high-title;
  }
  &:deep(.cdl-CaretDown) {
    display: none;
  }
}
</style>
