import ApiHandler from '@/api/base'

class DebugApiHandler extends ApiHandler {
  async claimAdminRights() {
    return this.http.post('/claimAdminRights')
  }

  async restoreTaskChain() {
    return this.http.post('/migrateTaskChain')
  }

  async restoreJiraDetails() {
    return this.http.post('/restoreJiraDetails')
  }

  async restoreIntervalDatesForElements() {
    return this.http.post('/restoreIntervalDatesForElements')
  }

  async restoreUsersAndGroupsForElements() {
    return this.http.post('restoreUsersAndGroupsForElements')
  }

  async migrateTasks() {
    return this.http.post('/migrateTasks')
  }

  async migrateLevels() {
    return this.http.post('/migrateLevels')
  }

  async migrateLabels() {
    return this.http.post('/migrateLabelProps')
  }

  async migrateAttributes() {
    return this.http.post('/migrateTaskDetails')
  }

  async migrateIntervals() {
    return this.http.post('/migrateIntervals')
  }

  async restoreElementDetails() {
    return this.http.post('/restoreElementDetails')
  }

  async restoreLevels() {
    return this.http.post('/addDefaultLevelsInEmptyWorkspaces')
  }

  async cleanUpCorruptedLinks() {
    return this.http.post('/deleteTasksWithNotExistingParents')
  }

  async fixIntervalDuplication() {
    return this.http.post('/fixIntervalDuplication')
  }

  async removeUsersWithoutGroups() {
    return this.http.post('/removeUsersWithoutGroups')
  }

  async removeGlobalPermissionsFromGroups() {
    return this.http.post('/removeOboardPermissionFromAllGroups')
  }

  async updateGrades() {
    return this.http.post('/updateGrades')
  }

  async getDebugInformation() {
    const response = await this.http.post(
      '/getDebugInformation',
      {},
      // debug log might be large, disable timeout
      { responseType: 'blob', timeout: 0 }
    )
    return {
      data: response.data,
      filename: 'oboard_debug_info.txt',
      filetype: response.headers['content-type']
    }
  }
  async exportInstance() {
    return this.http.get('/instance/export', {
      timeout: 0,
      responseType: 'blob',
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }
  async importInstance({ formData }) {
    return await this.http.post('/instance/import', formData, {
      timeout: 0
    })
  }

  async importStatus() {
    return await this.http.get('/instance/importStatus')
  }

  async getLogs() {
    const response = await this.http.post('/getLogs', {}, { responseType: 'blob' })
    return {
      data: response.data,
      filename: 'oboard_log.txt',
      filetype: response.headers['content-type']
    }
  }

  async migrateEmail() {
    return this.http.post('/migrateEmails')
  }

  async updateUsersInfo() {
    return this.http.post('/updateUsersInfo')
  }

  async deleteDeactivatedUsers() {
    return this.http.post('/deleteDeactivatedUsers')
  }
}

export default DebugApiHandler
