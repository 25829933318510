export const getIdsInMentions = text => {
  const userIdGroups = text.matchAll(/data-id="((\w|\s|\d|-|_|:)*)"/g)
  return [...userIdGroups].map(group => group[1])
}

export const resolveMentions = (text, userIds, users) => {
  const div = document.createElement('div')
  div.innerHTML = text
  userIds.forEach(userId => {
    if (!(users && userId in users && users[userId] && 'name' in users[userId])) {
      return div.innerHTML
    }
    const mentions = div.querySelectorAll(`span[data-id="${userId}"]`)
    mentions.forEach(mention => {
      mention.dataset.label = users[userId].name
      mention.textContent = '@' + users[userId].name
    })
  })
  return div.innerHTML
}
