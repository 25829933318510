import dayjs from 'dayjs'
import { has, isEmpty, isNumber, isUndefined, memoize } from 'lodash'

import { ACTIONS_KEYS } from '@/utils/actions-keys'
import { LIMIT_TAG_LIST_ENTITIES } from '@/utils/components-configurations/limit-tag-list'
import {
  BOTTOM_ARC_DIRECTION,
  CONNECTION_ARC_DIAMETER,
  CONNECTION_ARC_RADIUS,
  STABILIZER_1,
  TOP_ARC_DIRECTION
} from '@/utils/connections'
import { MENU_ITEMS_GROUPS } from '@/utils/dropdown-menu'
import { GROUP_ENTITY_KEYS } from '@/utils/entity-keys'
import {
  GLOBAL_GROUPS_SELECT_OPTION_PARAMS,
  userCanDeleteGroup,
  userCanUnlinkGroups,
  userCanUpdateGroup
} from '@/utils/global-groups'
import { isWhiteColor, stringIsHex } from '@/utils/okr-levels'
import { ROADMAP_DATE_FORMAT } from '@/utils/roadmap'

/**
 * The function to resolve the cache key.
 * For generate more flexible cache
 * @param args - The arguments of the function
 * @returns {string} - The cache key
 */
export const cacheKeyResolver = (...args) => {
  return JSON.stringify(Object.values({ ...args }).join('|'))
}

const objectCacheKeyResolver = args => {
  return Object.entries(args).flat().join('|')
}
const globalGroupCacheKeyResolver = ({ group }) => {
  return group.id
}

export const memoizeFormatDate = memoize(date => dayjs(date).format(ROADMAP_DATE_FORMAT))

export const memoizeHexToRgb = memoize(hex => {
  if (!hex) {
    return '0, 0, 0'
  }
  const r = parseInt(hex.slice(1, 3), 16)
  const g = parseInt(hex.slice(3, 5), 16)
  const b = parseInt(hex.slice(5, 7), 16)
  return `${r}, ${g}, ${b}`
})

export const memoizeExplanationBg = memoize((RGBColor, isAutoPeriodMode) => {
  return isAutoPeriodMode ? `rgba(${RGBColor}, 0.1)` : 'transparent'
}, cacheKeyResolver)

const memoizeCellWidthValue = memoize((valueToCalculate, oneDayWidth) => {
  return valueToCalculate * oneDayWidth
}, cacheKeyResolver)

export const getCellWidth = ({ data, oneDayWidth, isWeeksView }) => {
  const valueToCalculate = isWeeksView ? data.dates.length : data.daysCount
  return memoizeCellWidthValue(valueToCalculate, oneDayWidth)
}

export const memoizeOkrIconStyles = memoize((absoluteSize, levelColor, withFill) => {
  const defaultStyles = {
    width: `${absoluteSize}px`,
    height: `${absoluteSize}px`
  }

  const isHex = levelColor && stringIsHex(levelColor)

  const isBlackPrefix = levelColor && isWhiteColor(levelColor)

  if (isBlackPrefix) {
    defaultStyles.color = '#172B4D'
    defaultStyles.border = '1px solid'
  }

  return withFill
    ? { ...defaultStyles, backgroundColor: isHex ? levelColor : `#${levelColor}` }
    : defaultStyles
}, cacheKeyResolver)

export const memoizeGetCssVariableValue = memoize((variableName, defaultValue = 0) => {
  return getComputedStyle(document.documentElement).getPropertyValue(variableName) || defaultValue
})

export const CONTROLS_BUTTON_WIDTH = parseInt(
  memoizeGetCssVariableValue('--okr-table-row-controls-button-width', 24)
)
const GAP_OKR_ICON_AND_EXPAND_COLLAPSE_BUTTON = parseInt(
  memoizeGetCssVariableValue('--okr-table-row-gap', 8)
)
export const EXPAND_COLLAPSE_BUTTON_WIDTH_WITH_GAP =
  CONTROLS_BUTTON_WIDTH + GAP_OKR_ICON_AND_EXPAND_COLLAPSE_BUTTON

export const memoizeTableRowContentOffsetLeft = memoize((depth, offsetLeft = 0) => {
  const contentOffsetLeft = (depth + 1) * EXPAND_COLLAPSE_BUTTON_WIDTH_WITH_GAP // depth + 1 because now we need offset on first level too

  return {
    '--content-offset-left': `calc(${offsetLeft} + ${contentOffsetLeft}px + 24px)`
  }
}, cacheKeyResolver)

export const memoizeTableNameCellOffsetLeft = memoize(depth => {
  const margin =
    depth === 0
      ? EXPAND_COLLAPSE_BUTTON_WIDTH_WITH_GAP
      : depth * EXPAND_COLLAPSE_BUTTON_WIDTH_WITH_GAP
  const padding = depth === 0 ? 0 : EXPAND_COLLAPSE_BUTTON_WIDTH_WITH_GAP
  return {
    '--margin-left': `${margin}px`,
    'padding-left': `${padding}px`
  }
}, cacheKeyResolver)

const clearCache = (...memoizedFns) => memoizedFns.forEach(memoizedFn => memoizedFn.cache.clear())

export const clearRoadmapCache = () =>
  clearCache(memoizeFormatDate, memoizeHexToRgb, memoizeExplanationBg, memoizeCellWidthValue)

export const clearTableCache = () =>
  clearCache(memoizeTableNameCellOffsetLeft, memoizeTableRowContentOffsetLeft)

export const memoizeExpandableTableRowOffset = memoize(depth => {
  const additionalOffset = depth && EXPAND_COLLAPSE_BUTTON_WIDTH_WITH_GAP // use 0 if depth === 0 else use EXPAND_COLLAPSE_BUTTON_WIDTH_WITH_GAP
  return depth * EXPAND_COLLAPSE_BUTTON_WIDTH_WITH_GAP + additionalOffset
}, cacheKeyResolver)

export const memoizeInfiniteExpandableTableRowOffset = memoize(depth => {
  return depth * EXPAND_COLLAPSE_BUTTON_WIDTH_WITH_GAP
}, cacheKeyResolver)

export const memoizeExpandableTableRowBorderStyle = memoize((rowOffset, isShowExpandButton) => {
  const offset = rowOffset + (isShowExpandButton ? EXPAND_COLLAPSE_BUTTON_WIDTH_WITH_GAP : 0)
  return `${offset}px`
}, cacheKeyResolver)

export const memoizeInfiniteExpandableTableRowBorderStyle = memoize(rowOffset => {
  const offset = rowOffset + EXPAND_COLLAPSE_BUTTON_WIDTH_WITH_GAP
  return `${offset}px`
}, cacheKeyResolver)

export const memoizeExpandableTableContributeLineStyle = memoize((rowOffset, depth) => {
  const HAlF_EXPAND_COLLAPSE_BUTTON_WIDTH = CONTROLS_BUTTON_WIDTH / 2
  const delta = depth * EXPAND_COLLAPSE_BUTTON_WIDTH_WITH_GAP
  const offset = HAlF_EXPAND_COLLAPSE_BUTTON_WIDTH + delta * depth - delta
  const width = rowOffset - offset - GAP_OKR_ICON_AND_EXPAND_COLLAPSE_BUTTON

  return {
    '--cl-offset': `${offset}px`,
    '--cl-width': `${width}px`
  }
}, cacheKeyResolver)

export const memoizeInfiniteExpandableTableContributeLineStyle = memoize(
  (rowOffset, expandable) => {
    const HAlF_EXPAND_COLLAPSE_BUTTON_WIDTH = CONTROLS_BUTTON_WIDTH / 2
    const offset =
      rowOffset - HAlF_EXPAND_COLLAPSE_BUTTON_WIDTH - GAP_OKR_ICON_AND_EXPAND_COLLAPSE_BUTTON
    const delta = expandable ? 0 : CONTROLS_BUTTON_WIDTH
    const width =
      HAlF_EXPAND_COLLAPSE_BUTTON_WIDTH + GAP_OKR_ICON_AND_EXPAND_COLLAPSE_BUTTON + delta

    return {
      '--cl-offset': `${offset}px`,
      '--cl-width': `${width}px`
    }
  },
  cacheKeyResolver
)

const { ADD_SUB_GROUP, MANAGE_GROUP, DELETE, MERGE_GROUPS, UNLINK_PLATFORM_GROUP } = ACTIONS_KEYS
const { REMOVING, ACTIONS, EDITING } = MENU_ITEMS_GROUPS

export const memoizeGetGlobalGroupDropdownMenuItems = memoize(({ group, t }) => {
  const noAccessMessage = t('global_groups.unavailable_group')
  return [
    {
      name: MANAGE_GROUP,
      icon: 'edit-next',
      title: 'global_groups.manage_group',
      group: EDITING,
      color: 'var(--dark-2)'
    },

    {
      name: MERGE_GROUPS,
      title: 'global_groups.merge_groups',
      icon: 'merge-groups',
      group: ACTIONS,
      color: 'var(--dark-2)'
    },

    {
      name: ADD_SUB_GROUP,
      title: 'global_groups.add_sub_group',
      icon: 'add-sub-group',
      group: ACTIONS,
      color: 'var(--dark-2)'
    },

    {
      name: UNLINK_PLATFORM_GROUP,
      label: t('dropdown.unlink_platform_groups', { platform: t('app.platform.jira') }),
      icon: 'unlink-next',
      color: 'var(--grade-low-color-next)',
      group: REMOVING,
      disabled: !userCanUnlinkGroups(group),
      tooltipContent: (!userCanUnlinkGroups(group) && noAccessMessage) || null
    },

    {
      name: DELETE,
      icon: 'delete-next',
      title: 'action.delete',
      color: 'var(--grade-low-color-next)',
      group: REMOVING,
      disabled: !userCanDeleteGroup(group),
      tooltipContent: (!userCanDeleteGroup(group) && noAccessMessage) || null
    }
  ].filter(menuItem => {
    const isNoLinkedGroups = isEmpty(group[GROUP_ENTITY_KEYS.ASSOCIATED_PLATFORM_GROUPS])

    if (menuItem.name === UNLINK_PLATFORM_GROUP) {
      return !isNoLinkedGroups
    }

    return true
  })
}, globalGroupCacheKeyResolver)

export const memoizeGetTargetAgainstSourceNodeConnectionPath = memoize((startX, startY, toX) => {
  return `M ${startX} ${startY} h ${Math.abs(toX - startX)}`
}, cacheKeyResolver)

export const memoizeGetConnectionWithoutArcHeight = memoize((toY, startY) => {
  return toY - startY - CONNECTION_ARC_DIAMETER
}, cacheKeyResolver)

export const memoizeGetFilteredItemConnectionMinY = memoize(startY => {
  return startY + CONNECTION_ARC_DIAMETER
})

export const memoizeGetFilteredItemConnectionArcPath = memoize((x, y) => {
  return `A ${CONNECTION_ARC_RADIUS} ${CONNECTION_ARC_RADIUS}
          0 0 ${BOTTOM_ARC_DIRECTION}
          ${x + CONNECTION_ARC_RADIUS} ${y}
          `
}, cacheKeyResolver)

export const memoizeLineTo = memoize((x, y) => {
  return `L ${x} ${y}`
}, cacheKeyResolver)

export const memoizeGetDefaultItemConnectionMinY = memoize(endY => {
  return endY + STABILIZER_1 + CONNECTION_ARC_RADIUS
})

export const memoizeGetDefaultItemConnectionTopArcPath = memoize((x, y) => {
  return `A ${CONNECTION_ARC_RADIUS} ${CONNECTION_ARC_RADIUS}
          0 0 ${TOP_ARC_DIRECTION}
          ${x + CONNECTION_ARC_RADIUS + STABILIZER_1} ${y}
          `
}, cacheKeyResolver)

export const memoizeGetDefaultItemConnectionBottomArcPath = memoize((x, y) => {
  return `A ${CONNECTION_ARC_RADIUS} ${CONNECTION_ARC_RADIUS}
          0 0 ${BOTTOM_ARC_DIRECTION}
          ${x + CONNECTION_ARC_RADIUS + STABILIZER_1 + CONNECTION_ARC_RADIUS} ${y}
          `
}, cacheKeyResolver)

export const clearMindmapCache = () =>
  clearCache(
    memoizeGetTargetAgainstSourceNodeConnectionPath,
    memoizeGetConnectionWithoutArcHeight,
    memoizeGetFilteredItemConnectionMinY,
    memoizeGetFilteredItemConnectionArcPath,
    memoizeLineTo,
    memoizeGetDefaultItemConnectionMinY,
    memoizeGetDefaultItemConnectionTopArcPath,
    memoizeGetDefaultItemConnectionBottomArcPath
  )

export const memoizeGetCurrentGroupMaxWidth = memoize(({ isRootLevel, isShowParent }) => {
  const { BREADCRUMB_ARROW_SIZE, IMMEDIATE_PARENT_MIN_WIDTH } = GLOBAL_GROUPS_SELECT_OPTION_PARAMS
  const FULL_WIDTH = '100%'
  const IMMEDIATE_PARENT_OFFSET = IMMEDIATE_PARENT_MIN_WIDTH + BREADCRUMB_ARROW_SIZE
  const [maxWidth] = [
    isRootLevel && FULL_WIDTH,
    isShowParent && `calc(${FULL_WIDTH} - ${IMMEDIATE_PARENT_OFFSET}px)`
  ].filter(Boolean)

  return maxWidth
}, objectCacheKeyResolver)

const globalGroupPermissionsCacheKeyResolver = ({ group }) => {
  if (isEmpty(group) || !has(group, 'permissions')) {
    return ''
  }
  return group.permissions.join('|')
}
export const memoizeGetGlobalGroupActionsTooltipOptions = memoize(({ group, t }) => {
  const DEFAULT_OPTIONS = {
    placement: 'top-start',
    content: null
  }

  if (isEmpty(group) || !has(group, 'permissions')) {
    return {
      ...DEFAULT_OPTIONS,
      content: t('global_groups.unavailable_group')
    }
  }

  if (!userCanUpdateGroup(group)) {
    return {
      ...DEFAULT_OPTIONS,
      content: t('global_groups.unavailable_group')
    }
  }

  return DEFAULT_OPTIONS
}, globalGroupPermissionsCacheKeyResolver)

const okrChildrenElement = (elements, depth) => {
  return Object.entries({ elements: elements.map(el => el.id), depth })
    .flat()
    .join('|')
}

export const memoizeOkrChildren = memoize((elements, depth = 0) => {
  const flattened = []

  elements.forEach(element => {
    const { childElements, childCount, ...rest } = element
    flattened.push({ ...rest, childCount, depth })

    if (childCount) {
      const childFlattened = memoizeOkrChildren(childElements, depth + 1)
      flattened.push(...childFlattened)
    }
  })

  return flattened
}, okrChildrenElement)

export const memoizeFormatNumber = memoize(({ value, prefix = '' }) => {
  if (isNumber(value)) {
    const formattedValue = value.toLocaleString('fr-FR').replace(',', '.').replace(/\s/g, ' ')
    return `${prefix} ${formattedValue}`.trim()
  }
  return ''
}, objectCacheKeyResolver)

export const shouldDisplayFieldByOkrElementTypeId = memoize(
  ({
    okrElementTypeId = null,
    elementTypeIds = [],
    okrElementWorkspaceId = null,
    workspaceIds = [],
    active = false
  } = {}) => {
    if (
      !okrElementTypeId ||
      !okrElementWorkspaceId ||
      !active ||
      isEmpty(workspaceIds) ||
      isEmpty(elementTypeIds)
    ) {
      return false
    }
    // also check cross-workspace elements
    return (
      elementTypeIds.includes(okrElementTypeId) &&
      workspaceIds.includes(Number(okrElementWorkspaceId))
    )
  },
  objectCacheKeyResolver
)

const limitCacheKeyResolver = ({ items, limit, entity }) => {
  if (isUndefined(entity) || !Object.values(LIMIT_TAG_LIST_ENTITIES).includes(entity)) {
    throw new Error('Invalid entity')
  }

  const createKey = (item, extraFields = []) => {
    const { id = 'unknown_id', color = 'unknown_color' } = item
    return [id, ...extraFields, color].join('-')
  }

  const entityMapper = {
    [LIMIT_TAG_LIST_ENTITIES.WORKSPACE]: item => createKey(item, [item.key || 'unknown_key']),
    [LIMIT_TAG_LIST_ENTITIES.GROUP]: item => createKey(item, [item.icon || 'unknown_icon'])
  }

  const mapperFn = entityMapper[entity] || (item => createKey(item))

  const keyEnding = [`L.${limit}`, `E.${entity}`]

  return [...items.map(mapperFn), ...keyEnding].join('|')
}

export const memoizeLimitedItems = memoize(({ items, limit }) => {
  return [...items].slice(0, limit)
}, limitCacheKeyResolver)
