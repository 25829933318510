import BigNumber from 'bignumber.js'
import { isEmpty, isNull } from 'lodash'

import i18n from '@/i18n'
import { FILTERS_OPERATORS } from '@/utils/custom-fields/filters-operators'
import { memoizeFormatNumber } from '@/utils/memoizations'
import { RELATIVE_VALUES_FILTER_KEYS } from '@/utils/okr-elements/relative-values-filter'

export const MAX_NUMBER = 1e10 - 0.01
export const MIN_NUMBER = -1e10 + 0.01

export const NUMBER_FILTER_DEFAULT_DIGIT_MAX_LENGTH = 10
const COMBINATION_GROUP = 'combination'
const CONDITION_GROUP = 'condition'

const { t } = i18n.global

const {
  LABEL,
  WITH_INPUT,
  OPERATOR,
  VALUE,
  DEFAULT_INPUT_VALUE,
  DIGIT_MAX_LENGTH,
  FRACTION,
  ALLOW_NEGATIVE,
  MIN_INPUT_VALUE,
  GROUP
} = RELATIVE_VALUES_FILTER_KEYS

const {
  SHOW_ALL,
  EQUAL,
  NOT_EQUAL,
  GREATER_THAN_OR_EQUAL,
  GREATER_THAN,
  LESS_THAN_OR_EQUAL,
  LESS_THAN,
  IS_EMPTY,
  IS_NOT_EMPTY
} = FILTERS_OPERATORS

export const NUMBER_FILTER_DEFAULT_VALUE_FOR_INPUT = 1
export const NUMBER_FILTER_OPTIONS = {
  SHOW_ALL: {
    [LABEL]: t('filter.show_all'),
    [WITH_INPUT]: false,
    [OPERATOR]: SHOW_ALL,
    [VALUE]: SHOW_ALL,
    [GROUP]: COMBINATION_GROUP
  },
  EQUAL: {
    [LABEL]: '=',
    [WITH_INPUT]: true,
    [OPERATOR]: EQUAL,
    [VALUE]: EQUAL,
    [DEFAULT_INPUT_VALUE]: NUMBER_FILTER_DEFAULT_VALUE_FOR_INPUT,
    [DIGIT_MAX_LENGTH]: NUMBER_FILTER_DEFAULT_DIGIT_MAX_LENGTH,
    [FRACTION]: 2,
    [ALLOW_NEGATIVE]: true,
    [MIN_INPUT_VALUE]: null,
    [GROUP]: COMBINATION_GROUP
  },
  NOT_EQUAL: {
    [LABEL]: '≠',
    [WITH_INPUT]: true,
    [OPERATOR]: NOT_EQUAL,
    [VALUE]: NOT_EQUAL,
    [DEFAULT_INPUT_VALUE]: NUMBER_FILTER_DEFAULT_VALUE_FOR_INPUT,
    [DIGIT_MAX_LENGTH]: NUMBER_FILTER_DEFAULT_DIGIT_MAX_LENGTH,
    [FRACTION]: 2,
    [ALLOW_NEGATIVE]: true,
    [MIN_INPUT_VALUE]: null,
    [GROUP]: COMBINATION_GROUP
  },
  GREATER_THAN_OR_EQUAL: {
    [LABEL]: '≥',
    [WITH_INPUT]: true,
    [OPERATOR]: GREATER_THAN_OR_EQUAL,
    [VALUE]: GREATER_THAN_OR_EQUAL,
    [DEFAULT_INPUT_VALUE]: NUMBER_FILTER_DEFAULT_VALUE_FOR_INPUT,
    [DIGIT_MAX_LENGTH]: NUMBER_FILTER_DEFAULT_DIGIT_MAX_LENGTH,
    [FRACTION]: 2,
    [ALLOW_NEGATIVE]: true,
    [MIN_INPUT_VALUE]: null,
    [GROUP]: COMBINATION_GROUP
  },
  GREATER_THAN: {
    [LABEL]: '>',
    [WITH_INPUT]: true,
    [OPERATOR]: GREATER_THAN,
    [VALUE]: GREATER_THAN,
    [DEFAULT_INPUT_VALUE]: NUMBER_FILTER_DEFAULT_VALUE_FOR_INPUT,
    [DIGIT_MAX_LENGTH]: NUMBER_FILTER_DEFAULT_DIGIT_MAX_LENGTH,
    [FRACTION]: 2,
    [ALLOW_NEGATIVE]: true,
    [MIN_INPUT_VALUE]: null,
    [GROUP]: COMBINATION_GROUP
  },
  LESS_THAN_OR_EQUAL: {
    [LABEL]: '≤',
    [WITH_INPUT]: true,
    [OPERATOR]: LESS_THAN_OR_EQUAL,
    [VALUE]: LESS_THAN_OR_EQUAL,
    [DEFAULT_INPUT_VALUE]: NUMBER_FILTER_DEFAULT_VALUE_FOR_INPUT,
    [DIGIT_MAX_LENGTH]: NUMBER_FILTER_DEFAULT_DIGIT_MAX_LENGTH,
    [FRACTION]: 2,
    [ALLOW_NEGATIVE]: true,
    [MIN_INPUT_VALUE]: null,
    [GROUP]: COMBINATION_GROUP
  },
  LESS_THAN: {
    [LABEL]: '<',
    [WITH_INPUT]: true,
    [OPERATOR]: LESS_THAN,
    [VALUE]: LESS_THAN,
    [DEFAULT_INPUT_VALUE]: NUMBER_FILTER_DEFAULT_VALUE_FOR_INPUT,
    [DIGIT_MAX_LENGTH]: NUMBER_FILTER_DEFAULT_DIGIT_MAX_LENGTH,
    [FRACTION]: 2,
    [ALLOW_NEGATIVE]: true,
    [MIN_INPUT_VALUE]: null,
    [GROUP]: COMBINATION_GROUP
  },
  IS_EMPTY: {
    [LABEL]: t('filter.is_empty'),
    [WITH_INPUT]: false,
    [OPERATOR]: IS_EMPTY,
    [VALUE]: IS_EMPTY,
    [GROUP]: CONDITION_GROUP
  },
  IS_NOT_EMPTY: {
    [LABEL]: t('filter.is_not_empty'),
    [WITH_INPUT]: false,
    [OPERATOR]: IS_NOT_EMPTY,
    [VALUE]: IS_NOT_EMPTY,
    [GROUP]: CONDITION_GROUP
  }
}
export const NUMBER_FILTER_OPTIONS_WITHOUT_INPUT_OPERATORS = Object.values(NUMBER_FILTER_OPTIONS)
  .filter(option => !option[WITH_INPUT])
  .map(option => option[OPERATOR])

const getNumberFilterOptions = ({ operator }) => {
  return Object.values(NUMBER_FILTER_OPTIONS).find(option => option[OPERATOR] === operator)
}

export const getNumberFilterDisplayValue = ({
  isFilterValidated = false,
  filterValue = [],
  currency = null
} = {}) => {
  if (!isFilterValidated) {
    return ''
  }

  const [operator, value] = filterValue

  const currentOptionByOperator = getNumberFilterOptions({ operator })

  let displayLabel = ''

  if (currentOptionByOperator[RELATIVE_VALUES_FILTER_KEYS.LABEL]) {
    displayLabel = currentOptionByOperator[RELATIVE_VALUES_FILTER_KEYS.LABEL]
  }

  let displayValue = ''

  let displayCurrency = ''

  if (currency) {
    displayCurrency = NUMBER_FILTER_OPTIONS_WITHOUT_INPUT_OPERATORS.includes(operator)
      ? ''
      : currency
  }

  if (!isNull(value)) {
    displayValue = memoizeFormatNumber({
      value,
      prefix: displayCurrency
    })
  }

  return `${displayLabel} ${displayValue}`.trim()
}
export const NUMBER_FILTER_DEFAULT_VALUE = [
  NUMBER_FILTER_OPTIONS.SHOW_ALL[RELATIVE_VALUES_FILTER_KEYS.VALUE],
  null
]
export const restoreNumberFilter = ({
  defaultValue = NUMBER_FILTER_DEFAULT_VALUE,
  restoredValue = []
}) => {
  if (!Array.isArray(restoredValue) || isEmpty(restoredValue) || restoredValue.length < 2) {
    return defaultValue
  }

  const [operator, value] = restoredValue

  const isOperatorValid = Object.values(NUMBER_FILTER_OPTIONS).some(
    option => option[OPERATOR] === operator
  )

  if (isOperatorValid) {
    const [, valueForOptionWithoutInput] = NUMBER_FILTER_DEFAULT_VALUE
    if (NUMBER_FILTER_OPTIONS_WITHOUT_INPUT_OPERATORS.includes(operator)) {
      return [operator, valueForOptionWithoutInput]
    }

    const numberedValue = Number(value)

    const isValueValid = Number.isFinite(numberedValue) && !Number.isNaN(numberedValue)

    if (isValueValid) {
      let normalizedNumber = numberedValue

      if (normalizedNumber > MAX_NUMBER) {
        normalizedNumber = MAX_NUMBER
      } else if (normalizedNumber < MIN_NUMBER) {
        normalizedNumber = MIN_NUMBER
      } else {
        // note: standard Math and Number methods don't work with big numbers,
        // so we use big number library

        const bigNumber = new BigNumber(normalizedNumber)

        normalizedNumber = bigNumber
          .times(100)
          .integerValue(BigNumber.ROUND_DOWN)
          .dividedBy(100)
          .toNumber()
      }
      return [operator, normalizedNumber]
    } else {
      return [operator, NUMBER_FILTER_DEFAULT_VALUE_FOR_INPUT]
    }
  } else {
    return defaultValue
  }
}
