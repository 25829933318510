import dayjs from 'dayjs'
import localeData from 'dayjs/plugin/localeData'
import { useI18n } from 'vue-i18n'

import { SELECT_TYPES } from '@/utils/components-configurations/app-select'
import { DAYS_OF_WEEK_ENTITY_KEYS, SCHEDULE_ENTITY_KEYS } from '@/utils/entity-keys'

export const SCHEDULE_SELECT_ATTRS = {
  'skeleton-loader': true,
  'skeleton-loader-height': '100%',
  'skeleton-loader-width': '100%',
  type: SELECT_TYPES.MODERN,
  offset: [0, 0],
  dropdownMinWidth: 150
}
const { SHORT_NAME } = SCHEDULE_ENTITY_KEYS

dayjs.extend(localeData)

export const adjustDayIndex = (dayIndex = 0) => dayIndex || 7

export const SCHEDULE_DAYS_OF_WEEK = dayjs
  .weekdays()
  .map((dayName, index) => {
    const { NAME, VALUE } = DAYS_OF_WEEK_ENTITY_KEYS
    return {
      dayName,
      [SHORT_NAME]: dayName.slice(0, 1),
      [NAME]: dayName,
      [VALUE]: adjustDayIndex(index)
    }
  })
  .toSorted((a, b) => {
    const { VALUE } = DAYS_OF_WEEK_ENTITY_KEYS

    return a[VALUE] - b[VALUE]
  })

export const DEFAULT_SCHEDULE_NAME = 'Untitled check-in'

export const useScheduleModalTabs = () => {
  const { t } = useI18n()

  const TABS = {
    FREQUENCY: {
      value: 'frequency',
      label: t('schedule.frequency')
    },
    PARTICIPANTS: {
      value: 'participants',
      label: t('schedule.participants')
    },
    NOTIFICATIONS: {
      value: 'notifications',
      label: t('schedule.notifications')
    }
  }

  return {
    TABS
  }
}
