<template>
  <AppModal
    :show="show"
    :subtitle="subTitle"
    :title="title"
    class="omm-ObjectiveMoveModal gl-ModalTypePrimary-next"
    @on-close="proceedEmit('close')"
  >
    <template #title>
      <div class="omm-Title">
        <AppButton
          v-if="withBackButton"
          icon="arrow-left-simple-next"
          remove-padding
          size="sm"
          type="subtle"
          @click="proceedEmit('prev-step')"
        />
        {{ title }}
      </div>
    </template>
    <template #close-button>
      <AppButton icon="close-next" size="sm" type="ghost-next" @click="proceedEmit('close')" />
    </template>
    <slot />
    <template #footer>
      <slot name="footer">
        <div class="o-confirm-actions">
          <AppButton type="ghost-next" @click="proceedEmit('close')">
            {{ t('action.cancel') }}
          </AppButton>
          <AppButton
            :disable="disable"
            :loading="loading"
            type="primary-next"
            @click="proceedEmit('proceed')"
          >
            <slot name="confirm">
              {{ t('action.proceed') }}
            </slot>
          </AppButton>
        </div>
      </slot>
    </template>
  </AppModal>
</template>

<script setup>
import { useI18n } from 'vue-i18n'

import AppButton from '@/components/ui/AppButton/AppButton'
import AppModal from '@/components/ui/AppModal/AppModal'

defineProps({
  title: {
    type: String,
    default: ''
  },
  show: {
    type: Boolean
  },
  loading: {
    type: Boolean
  },
  disable: {
    type: Boolean
  },
  subTitle: {
    type: String,
    default: ''
  },
  withBackButton: {
    type: Boolean,
    default: true
  }
})
const emit = defineEmits({
  close: null,
  proceed: null,
  'prev-step': null
})
const { t } = useI18n()

const proceedEmit = emitName => {
  emit(emitName)
}
</script>
<script>
//eslint-disable-next-line
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ObjectiveMoveModal'
})
</script>

<style lang="scss" scoped>
.omm-Title {
  display: flex;
  align-items: center;
  font-family: $system-ui;
  font-weight: fw('semi-bold');
  font-size: $fs-20;
  line-height: 28px;
  color: $dark-1;
  gap: 8px;
}
</style>
<style lang="scss">
.omm-ObjectiveMoveModal {
  font-family: $system-ui;
  &.o-modal-sm > .o-modal-content-wrapper > .o-modal-content {
    width: 536px;

    .o-modal-header {
      padding: 20px 20px 20px 40px;
    }

    div.o-modal-body {
      padding: 0 40px;
    }

    .o-modal-footer {
      padding: 0 40px 40px;
    }
  }
}
</style>
