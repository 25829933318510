<template>
  <div class="dt-TableBody_Row">
    <div class="dt-Cell dt-CellName">
      <SkeletonItem :appearance="isForPeople ? CIRCLE : SQUARE" height="20px" width="20px" />
      <SkeletonItem :width="row.name" height="14px" />
      <SkeletonItem v-if="!isForPeople" :appearance="CIRCLE" height="20px" width="20px" />
    </div>
    <div
      v-for="n in BODY_CELLS"
      :key="n.id"
      :style="{ width: n.width }"
      class="dt-Cell dt-CellNumber"
    >
      <template v-for="type in columnKeys" :key="type">
        <SkeletonItem height="14px" width="10px" />
      </template>
    </div>
    <div class="dt-Cell dt-CellTotal dt-CellNumber">
      <template v-for="type in columnKeys" :key="type">
        <SkeletonItem :width="row.total" height="14px" />
      </template>
    </div>
  </div>
</template>

<script setup>
import { SKELETON_APPEARANCES } from '@/utils/components-configurations/skeleton-item'
import { columnKeys } from '@/utils/dashboard'

import SkeletonItem from '@/components/ui/SkeletonLoaders/SkeletonItem'

const { CIRCLE, SQUARE } = SKELETON_APPEARANCES

defineProps({
  isForPeople: {
    type: Boolean
  },
  row: {
    type: Object,
    default: () => ({})
  }
})

const BODY_CELLS = [
  {
    id: 1,
    width: '80px'
  },
  {
    id: 2,
    width: '80px'
  },
  {
    id: 3,
    width: '80px'
  },
  {
    id: 4,
    width: '80px'
  },
  {
    id: 5,
    width: '80px'
  },
  {
    id: 6,
    width: '80px'
  }
]
</script>

<style lang="scss" scoped>
.dt-TableBody_Row {
  display: flex;
  align-items: center;
  height: 75px;
  width: 100%;
}

$cell-width: 140px;

.dt-Cell {
  display: flex;
  align-items: center;
  gap: 8px;

  &:not(.dt-CellName) {
    width: $cell-width;
    justify-content: flex-start;
  }

  &.dt-CellTotal {
    justify-content: flex-end;
    padding-right: 20px;
    width: 100px;
  }

  &.dt-CellNumber {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 10px;
  }
}

.dt-CellName {
  flex: 1 1 auto;
}
</style>
