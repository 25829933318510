import ApiHandler from '@/api/base'
import GlobalGroupsApiHandler from '@/api/global-groups'
import UsersApiHandler from '@/api/users'

class SchedulesApiHandler extends ApiHandler {
  async fetchSchedules({ searchString = null, id = null } = {}) {
    const url = ['/schedules', id].filter(Boolean).join('/')
    const { data } = await this.http.get(url, {
      params: {
        searchString
      }
    })

    return data
  }

  async createSchedule(schedule) {
    return await this.http.post('/schedules', schedule)
  }

  async getScheduleById({ id }) {
    return await this.fetchSchedules({ id })
  }

  async updateSchedule(schedule) {
    const { data } = await this.http.put('/schedules', {
      ...schedule
    })

    return data
  }

  async deleteSchedule({ id }) {
    return await this.http.delete(`/schedules/${id}`)
  }

  async getParticipantsUsers({ searchString = null, accountIds = [] } = {}) {
    const api = new UsersApiHandler()

    const resolvedAccountIds = searchString ? null : accountIds

    return await api.getUserFilter({
      searchString,
      accountIds: resolvedAccountIds
    })
  }

  async getParticipantsGroups({ searchString = null, groupIds = [] } = {}) {
    const api = new GlobalGroupsApiHandler()

    const resolvedGroupIds = searchString ? null : groupIds

    return await api.getGroupsForFilter({
      searchString,
      groupIds: resolvedGroupIds
    })
  }
}

export default SchedulesApiHandler
