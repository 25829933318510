<template>
  <slot :display-value="formattedAmount" />
</template>

<script setup>
import { isNumber } from 'lodash'
import { computed, onBeforeUnmount, watch, ref, onMounted } from 'vue'

defineOptions({
  name: 'AnimatedNumber'
})

const props = defineProps({
  endAmount: {
    type: [String, Number],
    default: 0,
    required: true,
    validator: v => {
      const parsed = Number(v)
      const isValid = isNumber(parsed) && Number.isFinite(parsed)
      if (!isValid) {
        throw new Error('AnimatedNumber: endAmount prop must be a number')
      }
      return isValid
    }
  },

  separator: {
    type: String,
    default: ''
  },

  decimalSeparator: {
    type: String,
    default: '.'
  },

  decimals: {
    type: Number,
    default: 0,
    validator: v => v >= 0
  }
})
const resolvedEndAmount = computed(() => Number(props.endAmount))

const startAmount = ref(0)

const isCountingUp = computed(() => resolvedEndAmount.value > startAmount.value)

const currentAmount = ref(0)

const formattedAmount = computed(() => {
  const regex = /(\d+)(\d{3})/
  let numberString = currentAmount.value.toFixed(props.decimals)
  numberString += ''
  let numberArray = numberString.split('.')
  let numbers = numberArray[0]
  let decimals = numberArray.length > 1 ? props.decimalSeparator + numberArray[1] : ''
  let isNumber = !isNaN(parseFloat(props.separator))
  if (props.separator && !isNumber) {
    while (regex.test(numbers)) numbers = numbers.replace(regex, '$1' + props.separator + '$2')
  }

  return numbers + decimals
})

const currentStartAmount = ref(0)
const currentDuration = ref(0)
const remaining = ref(0)
const startTimeStamp = ref(0)
const animationFrame = ref(0)

const DURATION = 1

const cancelAnimation = () => {
  if (animationFrame.value) window.cancelAnimationFrame(animationFrame.value)
}

const timeStamp = ref(0)
const counting = timestamp => {
  timeStamp.value = timestamp
  if (!startTimeStamp.value) startTimeStamp.value = timestamp
  let progress = timestamp - startTimeStamp.value
  remaining.value = currentDuration.value - progress

  if (!isCountingUp.value) {
    currentAmount.value =
      currentStartAmount.value -
      (currentStartAmount.value - resolvedEndAmount.value) * (progress / currentDuration.value)
    currentAmount.value =
      currentAmount.value < resolvedEndAmount.value ? resolvedEndAmount.value : currentAmount.value
  } else {
    currentAmount.value =
      currentStartAmount.value +
      (resolvedEndAmount.value - currentStartAmount.value) * (progress / currentDuration.value)
    currentAmount.value =
      currentAmount.value > resolvedEndAmount.value ? resolvedEndAmount.value : currentAmount.value
  }
  if (progress < currentDuration.value) {
    animationFrame.value = window.requestAnimationFrame(counting)
  }
  // else {
  //   this.$emit('finished')
  // }
}

const start = () => {
  cancelAnimation()
  currentStartAmount.value = startAmount.value
  startTimeStamp.value = null
  currentDuration.value = DURATION * 1000
  animationFrame.value = window.requestAnimationFrame(counting)
}

const reset = () => {
  startTimeStamp.value = null
  cancelAnimation()
  currentAmount.value = startAmount.value
  start()
}

onMounted(() => {
  currentAmount.value = startAmount.value
  currentStartAmount.value = startAmount.value
  currentDuration.value = DURATION * 1000
  remaining.value = DURATION * 1000
  start()
})

onBeforeUnmount(() => {
  cancelAnimation()
})

watch(
  () => props.endAmount,
  () => {
    startAmount.value = currentAmount.value
    reset()
  }
)
</script>
