<template>
  <router-view />
</template>

<script setup>
defineOptions({
  name: 'IntegrationsLayout'
})
</script>

<style lang="scss" scoped></style>
