import { camelCase, cloneDeep, isEmpty, isNull, isUndefined, upperFirst } from 'lodash'

import { convertExtendedOptionsListToSimple, normalizeCurrency } from '@/utils/custom-fields/utils'
import { CUSTOM_FIELD_ENTITY_KEYS } from '@/utils/entity-keys'
import { COLUMNS_SETTINGS } from '@/utils/objective-table'

export const CUSTOM_FIELDS_PLACEMENTS = {
  CONTENT: 1,
  SIDEBAR: 2
}

export const CUSTOM_FIELDS_PLACEMENTS_KEYS = {
  [CUSTOM_FIELDS_PLACEMENTS.CONTENT]: 'contentFields',
  [CUSTOM_FIELDS_PLACEMENTS.SIDEBAR]: 'sidebarFields'
}

const { CONTENT, SIDEBAR } = CUSTOM_FIELDS_PLACEMENTS

export const ADDITIONAL_PARAMS_FIELDS_PLACEMENTS = {
  DEFAULT: 'default-placement', // default placement in bottom part of modal (after all the default fields)
  BEFORE_FIELD_TYPE: 'before-field-type',
  BEFORE_FIELD_NAME: 'before-field-name',
  BEFORE_FIELD_ADD_FOR: 'before-field-add-for',
  BEFORE_FIELD_WORKSPACES: 'before-field-workspaces'
}

export const ADDITIONAL_PARAMS_SETTINGS = {
  OPTIONS: {
    defaultValue: [],
    typeId: 1,
    placement: ADDITIONAL_PARAMS_FIELDS_PLACEMENTS.DEFAULT,
    getPayloadForCreateField: function ({ value = [] }) {
      if (isEmpty(value) || !Array.isArray(value)) {
        throw new Error('Value is required and should be array of Strings')
      }
      return {
        typeId: this.typeId,
        names: convertExtendedOptionsListToSimple({ options: value })
      }
    },

    getPayloadForUpdateField: function ({ value = [] } = {}) {
      return value
    }
  },

  CURRENCY: {
    defaultValue: '',
    typeId: 2,
    placement: ADDITIONAL_PARAMS_FIELDS_PLACEMENTS.BEFORE_FIELD_ADD_FOR,
    getPayloadForCreateField: function ({ value = undefined }) {
      if (isUndefined(value)) {
        throw new Error('value is required')
      }

      const currency = normalizeCurrency({ value })

      return {
        typeId: this.typeId,
        // additional param value always pass as array
        names: [currency]
      }
    },

    getPayloadForUpdateField: function ({ value = undefined, originalValue = undefined } = {}) {
      if (isUndefined(originalValue)) {
        throw new Error('originalValue is required')
      }
      const currency = normalizeCurrency({ value })

      return originalValue.map(additionalFiled => {
        if (additionalFiled[TYPE_ID] === this.typeId) {
          return {
            ...additionalFiled,
            name: currency
          }
        }
        return additionalFiled
      })
    }
  }
}

export const ADDITIONAL_PARAMS_SETTINGS_BY_ID = Object.values(ADDITIONAL_PARAMS_SETTINGS).reduce(
  (acc, val) => {
    const { typeId } = val
    return {
      ...acc,
      [typeId]: val
    }
  },
  {}
)

const {
  TYPE_ID,
  PLACEMENT,
  FILTERABLE,
  SORTABLE,
  ICON,
  DISPLAY_NAME,
  ADDITIONAL_PARAMETERS,
  DEFAULT_WIDTH,
  MIN_WIDTH,
  HAS_TABLE_COLUMN,
  DEFAULT_VALUE
} = CUSTOM_FIELD_ENTITY_KEYS

export class CUSTOM_FIELD {
  #typeId
  #placement
  #filterable
  #sortable
  #icon
  #displayName
  #additionalParameters
  #defaultWidth
  #minWidth
  #hasTableColumn
  #defaultValue

  constructor({
    typeId = null,
    placement = SIDEBAR,
    filterable = true,
    sortable = true,
    icon = '',
    displayName = '',
    additionalParameters = [],
    defaultWidth = 0,
    minWidth = 0,
    hasTableColumn = true,
    defaultValue = null
  }) {
    if (!typeId || !icon || !displayName) {
      throw new Error('Please fill required params')
    }

    if (hasTableColumn && (!defaultWidth || !minWidth)) {
      throw new Error('Please fill required params for column')
    }

    if (Object.values(CUSTOM_FIELDS_PLACEMENTS).indexOf(placement) === -1) {
      throw new Error('Placement is not valid')
    }

    this.#typeId = typeId
    this.#placement = placement
    this.#filterable = filterable
    this.#sortable = sortable
    this.#icon = icon
    this.#displayName = displayName
    this.#additionalParameters = additionalParameters
    this.#defaultWidth = defaultWidth
    this.#minWidth = minWidth
    this.#hasTableColumn = hasTableColumn
    this.#defaultValue = defaultValue
  }

  getData() {
    return {
      [TYPE_ID]: this.#typeId,
      [PLACEMENT]: this.#placement,
      [FILTERABLE]: this.#filterable,
      [SORTABLE]: this.#sortable,
      [ICON]: this.#icon,
      [DISPLAY_NAME]: this.#displayName,
      [ADDITIONAL_PARAMETERS]: this.#additionalParameters,
      [DEFAULT_WIDTH]: this.#defaultWidth,
      [MIN_WIDTH]: this.#minWidth,
      [HAS_TABLE_COLUMN]: this.#hasTableColumn,
      [DEFAULT_VALUE]: this.#defaultValue
    }
  }

  getTypeId() {
    return this.#typeId
  }
}

export class CUSTOM_FIELDS {
  #fields
  #fieldsById
  #typeIds
  #fieldNameById

  constructor() {
    this.#fields = {
      SINGLE_LINE_TEXT: new CUSTOM_FIELD({
        [TYPE_ID]: 1,
        [FILTERABLE]: false,
        [SORTABLE]: false,
        [PLACEMENT]: CONTENT,
        [ICON]: 'single-line',
        [DISPLAY_NAME]: 'custom_fields.single_line_text',
        [HAS_TABLE_COLUMN]: false,
        [DEFAULT_VALUE]: ''
      }),

      LONG_TEXT: new CUSTOM_FIELD({
        [TYPE_ID]: 2,
        [FILTERABLE]: false,
        [SORTABLE]: false,
        [PLACEMENT]: CONTENT,
        [ICON]: 'long-text',
        [DISPLAY_NAME]: 'custom_fields.long_text',
        [DEFAULT_WIDTH]: 120,
        [MIN_WIDTH]: 120,
        [HAS_TABLE_COLUMN]: false,
        [DEFAULT_VALUE]: ''
      }),

      LINK: new CUSTOM_FIELD({
        [TYPE_ID]: 8,
        [FILTERABLE]: false,
        [SORTABLE]: false,
        [PLACEMENT]: CONTENT,
        [ICON]: 'link',
        [DISPLAY_NAME]: 'custom_fields.link',
        [DEFAULT_WIDTH]: 160,
        [MIN_WIDTH]: 160,
        [HAS_TABLE_COLUMN]: false,
        [DEFAULT_VALUE]: []
      }),

      ASSIGNEE: new CUSTOM_FIELD({
        [TYPE_ID]: 6,
        [ICON]: 'assignee',
        [DISPLAY_NAME]: 'custom_fields.assignee',
        [DEFAULT_WIDTH]: COLUMNS_SETTINGS.ASSIGNEE.defaultWidth,
        [MIN_WIDTH]: COLUMNS_SETTINGS.ASSIGNEE.minWidth,
        [DEFAULT_VALUE]: []
      }),

      DATE: new CUSTOM_FIELD({
        [TYPE_ID]: 5,
        [ICON]: 'date',
        [DISPLAY_NAME]: 'custom_fields.date',
        [DEFAULT_WIDTH]: COLUMNS_SETTINGS.DUE_DATE.defaultWidth,
        [MIN_WIDTH]: COLUMNS_SETTINGS.DUE_DATE.minWidth,
        [DEFAULT_VALUE]: null
      }),

      SINGLE_SELECT: new CUSTOM_FIELD({
        [TYPE_ID]: 3,
        [ICON]: 'single-select',
        [DISPLAY_NAME]: 'custom_fields.single_select',
        [ADDITIONAL_PARAMETERS]: [cloneDeep(ADDITIONAL_PARAMS_SETTINGS.OPTIONS)],
        [DEFAULT_WIDTH]: COLUMNS_SETTINGS.INTERVAL.defaultWidth,
        [MIN_WIDTH]: COLUMNS_SETTINGS.INTERVAL.minWidth,
        [DEFAULT_VALUE]: null
      }),

      MULTI_SELECT: new CUSTOM_FIELD({
        [TYPE_ID]: 4,
        [ICON]: 'multi-select',
        [DISPLAY_NAME]: 'custom_fields.multi_select',
        [ADDITIONAL_PARAMETERS]: [cloneDeep(ADDITIONAL_PARAMS_SETTINGS.OPTIONS)],
        [DEFAULT_WIDTH]: COLUMNS_SETTINGS.GROUPS.defaultWidth,
        [MIN_WIDTH]: COLUMNS_SETTINGS.GROUPS.minWidth,
        [DEFAULT_VALUE]: []
      }),

      NUMBER: new CUSTOM_FIELD({
        [TYPE_ID]: 7,
        [ICON]: 'number',
        [DISPLAY_NAME]: 'custom_fields.number',
        [DEFAULT_WIDTH]: 74,
        [MIN_WIDTH]: 64,
        [DEFAULT_VALUE]: null
      }),

      MONEY: new CUSTOM_FIELD({
        [TYPE_ID]: 9,
        [ICON]: 'money',
        [DISPLAY_NAME]: 'custom_fields.money',
        [ADDITIONAL_PARAMETERS]: [cloneDeep(ADDITIONAL_PARAMS_SETTINGS.CURRENCY)],
        [DEFAULT_WIDTH]: 74,
        [MIN_WIDTH]: 64,
        [DEFAULT_VALUE]: null
      })
    }

    const { fieldsById, typeIds, fieldNameById } = Object.entries(this.#fields).reduce(
      (acc, val) => {
        const [key, field] = val
        const typeId = field.getTypeId()

        acc.fieldsById[typeId] = field
        acc.typeIds[key] = typeId
        acc.fieldNameById[typeId] = upperFirst(camelCase(`${key}`))

        return acc
      },
      {
        fieldsById: {},
        typeIds: {},
        fieldNameById: {}
      }
    )

    this.#fieldsById = fieldsById
    this.#typeIds = typeIds
    this.#fieldNameById = fieldNameById
  }

  getListOfFields() {
    return this.#fields
  }

  getTypeIds() {
    return this.#typeIds
  }

  getNames() {
    return this.#fieldNameById
  }

  getFieldsByPlacement(placement = CUSTOM_FIELDS_PLACEMENTS.SIDEBAR) {
    return Object.values(this.#fields)
      .filter(field => field.getData()[PLACEMENT] === placement)
      .map(field => field.getTypeId())
  }

  getFieldOptionsByTypeId(id = undefined) {
    if (isUndefined(id)) {
      throw new Error('Id is required')
    }

    if (isNull(id)) {
      return null
    }

    if (isUndefined(this.#fieldsById[id])) {
      throw new Error(`Field with id ${id} is not found`)
    }

    return this.#fieldsById[id].getData()
  }
}

export const ALL_CUSTOM_FIELDS = new CUSTOM_FIELDS()
