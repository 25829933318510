import { isElement, isEmpty, isObject, isString } from 'lodash'

const cache = new Map()
export const SYNC_AXES = {
  X: 'x',
  Y: 'y'
}

/**
 * Disconnect sync scroll from group
 * @param groupName {string} Group name
 * @returns void
 */
export const disconnectSyncScroll = groupName => {
  if (cache.has(groupName)) {
    const els = cache.get(groupName)
    els.forEach(element => {
      element.removeEventListener('scroll', element.syn)
    })

    cache.delete(groupName)
  }
}

/**
 * Initialize sync scroll
 * @param elements {{element: HTMLElement, skipAddingListener: (Boolean|undefined)}[]} Array of elements
 * @param syncAxes {Array} Array of axes to sync
 * @param groupName {string} Group name
 * @returns void
 */
export const initSyncScroll = ({
  elements = [],
  syncAxes = [...Object.values(SYNC_AXES)],
  groupName = ''
}) => {
  if (elements.length < 2) {
    throw new Error('at least two elements are required')
  }

  const isWrongStruct = elements.some(element => !isObject(element) || isElement(element))

  if (isWrongStruct) {
    throw new Error(
      'elements must be an array of objects [{element: HTMLElement, skipAddingListener?: Boolean}, ...]'
    )
  }

  const isSomeElementIsNoHtmlElement = elements.some(element => !isElement(element.element))

  if (isSomeElementIsNoHtmlElement) {
    throw new Error('each element must be an HTMLElement')
  }

  if (isEmpty(syncAxes)) {
    throw new Error(
      'at least one axis must be passed to the syncAxes array (take them from SYNC_AXES)'
    )
  }

  if (syncAxes.length > 2) {
    throw new Error('support only 2 axes')
  }

  const isAxesSupported = syncAxes.every(axes => Object.values(SYNC_AXES).includes(axes))

  if (!isAxesSupported) {
    const unsupportedAxes = syncAxes.filter(axes => !Object.values(SYNC_AXES).includes(axes))
    throw new Error(`not supported axes: ${unsupportedAxes.join(', ')}`)
  }

  if (!isString(groupName) || groupName.trim().length === 0) {
    throw new Error('groupName must be set and must be a String')
  }

  const syncByX = syncAxes.includes(SYNC_AXES.X)

  const syncByY = syncAxes.includes(SYNC_AXES.Y)

  elements.forEach(item => {
    const { element, skipAddingListener } = item

    element.eX = element.eY = 0
    ;(function (element) {
      if (!skipAddingListener) {
        cache.set(groupName, [...(cache.get(groupName) || []), element])
        element.addEventListener(
          'scroll',
          (element.syn = function () {
            let scrollX = element.scrollLeft
            let scrollY = element.scrollTop

            const xRate = scrollX / (element.scrollWidth - element.clientWidth)
            const yRate = scrollY / (element.scrollHeight - element.clientHeight)

            const updateX = syncByX && scrollX !== element.eX
            const updateY = syncByY && scrollY !== element.eY

            element.eX = scrollX
            element.eY = scrollY

            elements.forEach(item => {
              const { element: otherElement } = item
              if (otherElement !== element) {
                if (
                  updateX &&
                  Math.round(
                    otherElement.scrollLeft -
                      (scrollX = otherElement.eX =
                        Math.round(xRate * (otherElement.scrollWidth - otherElement.clientWidth)))
                  )
                ) {
                  otherElement.scrollLeft = scrollX
                }

                if (
                  updateY &&
                  Math.round(
                    otherElement.scrollTop -
                      (scrollY = otherElement.eY =
                        Math.round(yRate * (otherElement.scrollHeight - otherElement.clientHeight)))
                  )
                ) {
                  otherElement.scrollTop = scrollY
                }
              }
            })
          }),
          0
        )
      }
    })(element)
  })
}
