<template>
  <div class="ow-Loader">
    <div v-for="row in resolvedRows" :key="row.id" class="ow-Loader_Row">
      <div class="ow-Cell">
        <SkeletonItem :appearance="SQUARE" :size="XS" border-radius="3px" />
        <div class="ow-Cell_Name">
          <SkeletonItem :appearance="SQUARE" :size="SM" border-radius="6px" />
          <SkeletonItem :size="XS" width="86px" />
          <SkeletonItem :size="XS" :width="row.name" />
        </div>
      </div>
      <div class="ow-Cell">
        <SkeletonItem :size="XS" :width="row.interval" />
      </div>
      <div class="ow-Cell">
        <SkeletonItem :size="XS" width="54px" />
      </div>
      <div class="ow-Cell">
        <SkeletonItem :size="XS" :width="row.impact" />
      </div>
      <div class="ow-Cell">
        <SkeletonItem :size="XS" :width="row.grade" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'

import {
  SKELETON_APPEARANCES,
  SKELETON_SIZES
} from '@/utils/components-configurations/skeleton-item'

import SkeletonItem from '@/components/ui/SkeletonLoaders/SkeletonItem'

defineOptions({
  name: 'ObjectivesWeightsLoader'
})

const { XS, SM } = SKELETON_SIZES
const { SQUARE } = SKELETON_APPEARANCES

const props = defineProps({
  count: {
    type: Number,
    default: ROWS.length
  }
})
const resolvedRows = computed(() => {
  if (props.count <= ROWS.length) {
    return ROWS.slice(0, props.count)
  } else {
    const rowsCount = Math.trunc(props.count / ROWS.length)
    const restRows = props.count % ROWS.length
    return [...Array(rowsCount).fill(ROWS).flat(), ...ROWS.slice(0, restRows)]
  }
})
</script>

<script>
const ROWS = [
  {
    id: 0,
    name: '147px',
    interval: '70px',
    impact: '26px',
    grade: '16px'
  },
  {
    id: 1,
    name: '120px',
    interval: '50px',
    impact: '20px',
    grade: '24px'
  },
  {
    id: 2,
    name: '60px',
    interval: '70px',
    impact: '26px',
    grade: '37px'
  },
  {
    id: 3,
    name: '210px',
    interval: '70px',
    impact: '26px',

    grade: '37px'
  },
  {
    id: 4,
    name: '30px',
    interval: '32px',
    impact: '20px',

    grade: '16px'
  }
]
</script>

<style lang="scss" scoped>
.ow-Loader_Row {
  height: 45px;
  display: flex;
  align-items: center;
  width: 100%;
}

.ow-Cell {
  display: flex;
  align-items: center;
  gap: 12px;

  &:not(:first-child) {
    justify-content: center;
  }

  &:first-child {
    width: auto;
    flex: 1;
    padding-left: 4px;
  }

  &:nth-child(2) {
    width: 124px;
    justify-content: flex-end;
  }

  &:nth-child(3) {
    width: 133px;
  }

  &:nth-child(4) {
    width: 50px;
    justify-content: flex-end;
  }

  &:nth-child(5) {
    width: 93px;
    justify-content: flex-end;
  }
}

.ow-Cell_Name {
  display: flex;
  align-items: center;
  gap: 8px;
}
</style>
