<template>
  <div
    ref="wrapper"
    :class="{
      'otr-ObjectiveTreeRowWrapper-highlighted': !depth && showRowList,
      'otr-ObjectiveTreeRowWrapper-expanded': depth && showRowList && !isLast
    }"
    class="otr-ObjectiveTreeRowWrapper"
  >
    <svg v-if="showNodeConnections" class="otr-Lines">
      <NodeConnection
        v-for="line in visibleElementsConnections"
        :key="line.id"
        :dashed="!line.contribute"
        :from="line.from"
        :stroke-color="`#${objective.levelColor}`"
        :to="line.to"
      />

      <NodeConnection
        v-for="line in filteredElementsConnections"
        :key="line.id"
        :dashed="!line.contribute"
        :from="line.from"
        :to="line.to"
        for-filtered-item
      />
    </svg>

    <div ref="col" class="otr-ObjectiveTreeRow">
      <ObjectiveTreeItem
        ref="item"
        v-model:show-filtered-children="showFilteredChildren"
        :all-children="allChildren"
        :depth="depth"
        :expanded="expanded"
        :is-first="isFirst"
        :is-last="isLast"
        :objective="objective"
        :parent-element-is-objective="parentElementIsObjective"
        :type="treeState.activeTreeType"
        :visible-children="resolvedChildren"
        @add="$emit('add', $event)"
        @collapse="onExpandCollapse"
        @delete="$emit('delete', $event)"
        @duplicate="$emit('duplicate', $event)"
        @edit="$emit('edit', $event)"
        @expand="onExpandCollapse"
        @move="$emit('move', $event)"
        @click-on-id="$emit('edit')"
        @edit-weights="$emit('edit-weights', $event)"
        @update:expanded="$emit('update:expanded')"
      />
    </div>

    <div ref="listWrapper" class="otr-ChildTreeRowList">
      <ObjectiveTreeRowList
        v-if="showRowList"
        ref="childrenList"
        :close-id="resolvedCloseId"
        :depth="depth + 1"
        :is-close-root-list="isCloseInitiator"
        :model-value="filteredChildrenIds"
        :parent-card-height="cardHeight"
        :parent-id="objective.id"
        :show-filtered-children="showFilteredChildren"
        @update="$emit('update', $event)"
        @ready-to-close="onReadyToClose"
        @request-connection="getConnectionPointsThrottled"
        @request-offset="offset => $emit('request-offset', offset)"
      />
    </div>
  </div>
</template>

<script>
import { isEmpty, isUndefined, throttle } from 'lodash'
import { defineAsyncComponent, defineComponent } from 'vue'

import { getConnectionPoint } from '@/utils/connections'
import { listStateInjectionKey, treeStateInjectionKey } from '@/utils/injection-keys'
import { OBJECTIVE_TYPES } from '@/utils/objective-types'
import { getExpandedItemList, isKR, objectiveIsJiraTask } from '@/utils/objectives'
import { FILTERS_KEYS, saveFilterValue } from '@/utils/okr-elements/filters'
import { createKrsWrapperId } from '@/utils/okr-map'

import NodeConnection from '@/components/objectives/tree/NodeConnection'
import ObjectiveTreeItem from '@/components/objectives/tree/ObjectiveTreeItem'

// import ObjectiveTreeKrList from '@/components/objectives/tree/ObjectiveTreeKrList';

export default defineComponent({
  name: 'ObjectiveTreeRow',

  components: {
    ObjectiveTreeRowList: defineAsyncComponent(() =>
      import('@/components/objectives/tree/ObjectiveTreeRowList')
    ),

    // ObjectiveTreeKrList,
    ObjectiveTreeItem,
    NodeConnection
  },

  inject: {
    listState: {
      from: listStateInjectionKey
    },

    treeState: {
      from: treeStateInjectionKey
    }
  },

  props: {
    depth: {
      type: Number,
      default: 0
    },

    objective: {
      type: Object,
      required: true
    },

    closeId: {
      type: Number,
      default: 0
    },

    expanded: {
      type: Boolean
    },

    isFirst: {
      type: Boolean
    },

    isLast: {
      type: Boolean
    }
  },

  emits: {
    add: null,
    edit: null,
    delete: null,
    duplicate: null,
    'edit-weights': null,
    update: null,
    'request-offset': null,
    'request-connection': null,
    'update:expanded': null,
    move: null
  },

  data() {
    return {
      visibleElementsConnections: [],
      filteredElementsConnections: [],
      cardHeight: 0,
      internalCloseId: 0,
      isCloseInitiator: false,
      resizeObserver: null,
      showFilteredChildren: false
    }
  },

  computed: {
    /* childrenByType() {
      return this.objective.childElements.reduce(
        (acc, el) => {
         if (el.typeId === OBJECTIVE_TYPES.KR) {
           acc.krs.push(el.id);
          } else if (
           [OBJECTIVE_TYPES.PERSONAL, OBJECTIVE_TYPES.GROUP, OBJECTIVE_TYPES.COMPANY].includes(
             el.typeId
            )
         ) {
            acc.objectives.push(el.id);
          }
         return acc;
       },
     { objectives: [], krs: [] }
    );
  }, */

    parentElementIsObjective() {
      const { parentId } = this.objective
      if (parentId) {
        const parentElement = this.listState.okrElements[parentId]
        return parentElement && !isKR(parentElement) && !objectiveIsJiraTask(parentElement)
      }
      return false
    },

    showNodeConnections() {
      return !isEmpty(this.visibleElementsConnections) || !isEmpty(this.filteredElementsConnections)
    },

    filteredChildren() {
      return this.allChildren.filter(el => !el.hiddenByFilter)
    },

    allChildren() {
      const defaultTypes = [OBJECTIVE_TYPES.PERSONAL]
      const okrElementTypes = this.treeState.showKrs
        ? [...defaultTypes, OBJECTIVE_TYPES.KR, OBJECTIVE_TYPES.TASK]
        : defaultTypes
      return this.objective.childElements.filter(el => okrElementTypes.includes(el.typeId))
    },

    resolvedChildren() {
      return this.showFilteredChildren ? this.allChildren : this.filteredChildren
    },

    filteredChildrenIds() {
      return this.resolvedChildren.map(el => el.id)
    },

    okrElementsIdsForNodeConnections() {
      // return this.filteredChildren
      //   .filter(el => {
      //     return !(el.typeId === OBJECTIVE_TYPES.KR || el.typeId === OBJECTIVE_TYPES.TASK)
      //   })
      //   .map(el => el.id)

      const visibleChildrenConnections = this.filteredChildren.reduce(
        (acc, val) => {
          const { krs, objectives } = acc
          if (isKR(val) || objectiveIsJiraTask(val)) {
            acc.krs = [...krs, val.id]
          } else {
            acc.objectives = [...objectives, val.id]
          }
          return acc
        },
        {
          krs: [],
          objectives: []
        }
      )

      const filteredChildrenConnections = this.showFilteredChildren
        ? this.resolvedChildren
            .filter(el => {
              return el.hiddenByFilter
            })
            .map(el => el.id)
        : []

      return {
        visibleChildrenConnections,
        filteredChildrenConnections
      }
    },

    resolvedCloseId() {
      return Math.max(this.closeId, this.internalCloseId)
    },

    showRowList() {
      return this.expanded && this.filteredChildrenIds.length > 0
    }
  },

  watch: {
    expanded(newValue) {
      if (!this.listState.haveAllFiltersDefaultValues) {
        const id = `${this.objective.id}-${this.depth}`
        const filtersValues = this.listState.filtersValues
        if (newValue && id in filtersValues[FILTERS_KEYS.FILTER_COLLAPSED_ITEMS]) {
          // collapsed, save value
          filtersValues[FILTERS_KEYS.FILTER_COLLAPSED_ITEMS][id] = false
          saveFilterValue(
            this.$router,
            this.$route,
            FILTERS_KEYS.FILTER_COLLAPSED_ITEMS,
            getExpandedItemList(this.listState.filtersValues[FILTERS_KEYS.FILTER_COLLAPSED_ITEMS])
          )
        } else if (!newValue) {
          // expanded, value will be saved after data load, no additional save is needed
          filtersValues[FILTERS_KEYS.FILTER_COLLAPSED_ITEMS][id] = true
        }
      }
    }
  },

  created() {
    this.getConnectionPointsThrottled = throttle(this.setupConnections, 5, {
      leading: true,
      trailing: true
    })
  },

  mounted() {
    this.setupResizeObserver()
  },

  beforeUnmount() {
    if (this.expanded) {
      this.$emit('update:expanded', false)
    }

    if (this.resizeObserver) {
      this.resizeObserver.disconnect()
    }
  },

  methods: {
    getElementsConnections({
      elementsNodesIds = [],
      forFilteredItem = false,
      contribute = undefined
    }) {
      const baseRect = this.$refs.wrapper.getBoundingClientRect()
      const fromRect = this.$refs.item.$el.getBoundingClientRect()
      const { zoom } = this.treeState

      return elementsNodesIds.map(elId => {
        const toRect = document.getElementById(elId).getBoundingClientRect()
        return {
          ...getConnectionPoint({
            baseRect,
            fromRect,
            toRect,
            zoom,
            forFilteredItem
          }),
          id: elId,
          contribute: isUndefined(contribute)
            ? this.listState.okrElements[elId].contribute
            : contribute
        }
      })
    },

    setupConnections() {
      if (!this.expanded) {
        this.filteredElementsConnections = []
        this.visibleElementsConnections = []
        return
      }

      const { visibleChildrenConnections, filteredChildrenConnections } =
        this.okrElementsIdsForNodeConnections

      this.visibleElementsConnections = this.getElementsConnections({
        elementsNodesIds: visibleChildrenConnections.objectives
      })

      if (!isEmpty(visibleChildrenConnections.krs)) {
        const krsWrapperId = createKrsWrapperId(this.objective.id)
        const krsWrapper = document.getElementById(krsWrapperId)
        if (krsWrapper) {
          const contribute = visibleChildrenConnections.krs.some(
            elId => this.listState.okrElements[elId].contribute
          )
          this.visibleElementsConnections = [
            ...this.visibleElementsConnections,
            ...this.getElementsConnections({
              elementsNodesIds: [krsWrapperId],
              contribute
            })
          ]
        }
      }

      if (!isEmpty(filteredChildrenConnections)) {
        this.filteredElementsConnections = this.getElementsConnections({
          elementsNodesIds: filteredChildrenConnections,
          forFilteredItem: true
        })
      } else {
        this.filteredElementsConnections = []
      }
    },

    setupResizeObserver() {
      const RO = new ResizeObserver(() => {
        this.getConnectionPointsThrottled()
        this.$emit('request-connection')
      })

      RO.observe(this.$refs.wrapper)
      RO.observe(this.$refs.col)
      RO.observe(this.$refs.item.$el)
      RO.observe(this.$refs.listWrapper)
      this.resizeObserver = RO
    },

    onExpandCollapse() {
      this.cardHeight = this.$refs.col.clientHeight

      if (this.expanded) {
        // inform all children via signal-like prop to initiate nested animations.
        this.internalCloseId = Date.now()
        this.isCloseInitiator = true
      } else {
        this.$emit('update:expanded', true)
      }
    },

    onReadyToClose() {
      // close the children list only when all animations will be finished
      this.$emit('update:expanded', false)
      this.isCloseInitiator = false
    },

    // returns array of expanded children(their ids as in listState.filtersValues[FILTERS_KEYS.EXPANDED_ITEMS])
    // including current row
    /** @public */
    getExpandedChildren() {
      let result = []
      if (this.expanded) {
        result.push(`${this.objective.id}-${this.depth}`)
      }
      if (this.$refs.childrenList) {
        result = result.concat(this.$refs.childrenList.getExpandedChildren())
      }

      return result
    }
  }
})
</script>

<style lang="scss" scoped>
$connection-border-radius: 10px;
$connection-border-size: 2px;

.otr-ObjectiveTreeRowWrapper {
  &-highlighted {
    border-radius: $border-radius-lg-next;
    width: max-content;

    --outline-width: 2px;
    --outline-offset: 9px;

    .is-safari & {
      --outline-size: calc(100% + (var(--outline-offset) * 2) + (var(--outline-width) * 2));
      $outline-offset: calc(var(--outline-offset) * -1 + var(--outline-width) * -1);
      &:after {
        content: '';
        position: absolute;
        top: $outline-offset;
        left: $outline-offset;
        width: var(--outline-size);
        height: var(--outline-size);
        pointer-events: none;
        border-radius: calc(
          #{$border-radius-lg-next} + var(--outline-width) + var(--outline-offset)
        );
        border: var(--outline-width) solid rgba($white, 0.5);
      }
    }

    &:not(.is-safari &) {
      outline: var(--outline-width) solid rgba($white, 0.5);
      outline-offset: var(--outline-offset);
    }
  }

  &-expanded {
    margin-bottom: 20px;
  }

  position: relative;
  z-index: 1;
  display: flex;
  // align-items: center;
  align-items: flex-start;
  width: fit-content;

  // &:first-child {
  //   margin-top: 0;
  // }
  //
  // &:last-child {
  //   margin-bottom: 0;
  // }
}

.otr-ObjectiveTreeRow {
  .otr-ObjectiveTreeRowWrapper & {
    flex-shrink: 0;
    // align-self: normal; // stretch;
  }

  & + .otr-ChildTreeRowList {
    // margin-left: 100px;
    &:not(:empty) {
      margin-left: 104px;
    }
  }
}

.otr-Lines {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
