import { cloneDeep, isEmpty, isNull, isUndefined } from 'lodash'

import { ADDITIONAL_PARAMS_SETTINGS, ALL_CUSTOM_FIELDS } from '@/utils/custom-fields/factory'
import { ASSIGNEE_ENTITY_KEYS, CUSTOM_FIELD_ENTITY_KEYS } from '@/utils/entity-keys'
import { OBJECTIVE_TYPES } from '@/utils/objective-types'
import { selectAllIsSelected } from '@/utils/select'

export const DEFAULT_CUSTOM_FIELD_FILTERS_VALUE = []
export const DEFAULT_CUSTOM_FIELDS_VALUES_FOR_OKR_ELEMENT = {}
export const CUSTOM_FIELDS_FILTERS_QUERY_KEY = 'cff'
export const CUSTOM_FIELDS_OKR_TYPE_IDS = {
  OBJECTIVE: {
    id: OBJECTIVE_TYPES.PERSONAL,
    icon: 'objective',
    name: 'okr_elements.objective',
    trackingName: 'obj'
  },
  KR: {
    id: OBJECTIVE_TYPES.KR,
    icon: 'kr-objective-next',
    name: 'element.type.key_result',
    trackingName: 'kr'
  },
  TASK: {
    id: OBJECTIVE_TYPES.TASK,
    icon: 'jira-objective',
    name: 'element.type.jira_issue',
    trackingName: 'task'
  }
}

export const MAX_CUSTOM_FIELDS_COUNT = 50
export const MAX_OPTIONS_COUNT = 20

export const validateString = string => string.trim() !== ''
export const validateArray = array => !isEmpty(array)
export const validateSelectValue = value => !isNull(value)
export const REQUIRED_STATUS = 'required'

export const getCustomFieldFiltersQuery = ({ customFieldsFiltersValues }) => {
  return Object.entries(customFieldsFiltersValues)
    .map(([key, value]) => {
      // do not save filter with select all value
      if (selectAllIsSelected(value)) {
        return null
      }
      return [Number(key), value]
    })
    .filter(Boolean)
}

export const getResolvedCustomFieldFiltersValues = ({
  customFieldsFiltersValues = {},
  haveCustomFieldFiltersDefaultValues = true
} = {}) => {
  return isEmpty(customFieldsFiltersValues) || haveCustomFieldFiltersDefaultValues
    ? DEFAULT_CUSTOM_FIELD_FILTERS_VALUE
    : customFieldsFiltersValues
}

export const createInitialCustomFieldsValuesForOkrElement = ({
  isEdit = false,
  elementTypeId = null,
  modelValueCustomFields = DEFAULT_CUSTOM_FIELDS_VALUES_FOR_OKR_ELEMENT,
  currentWorkspaceCustomFields = []
} = {}) => {
  if (isNull(elementTypeId)) {
    return cloneDeep(DEFAULT_CUSTOM_FIELDS_VALUES_FOR_OKR_ELEMENT)
  }
  const okrElementCustomFieldsValues = isEdit
    ? cloneDeep(modelValueCustomFields)
    : DEFAULT_CUSTOM_FIELDS_VALUES_FOR_OKR_ELEMENT

  if (isEmpty(currentWorkspaceCustomFields)) {
    return cloneDeep(DEFAULT_CUSTOM_FIELDS_VALUES_FOR_OKR_ELEMENT)
  }

  const defaultValues = currentWorkspaceCustomFields
    .filter(field => {
      return field[CUSTOM_FIELD_ENTITY_KEYS.ELEMENT_TYPE_IDS].includes(elementTypeId)
    })
    .reduce((acc, field) => {
      return {
        ...acc,
        [field.id]: ALL_CUSTOM_FIELDS.getFieldOptionsByTypeId(field.typeId)[
          CUSTOM_FIELD_ENTITY_KEYS.DEFAULT_VALUE
        ]
      }
    }, DEFAULT_CUSTOM_FIELDS_VALUES_FOR_OKR_ELEMENT)

  if (isEdit) {
    return {
      ...defaultValues,
      ...okrElementCustomFieldsValues
    }
  }

  return defaultValues
}

export const normalizeCustomFieldData = ({ customField = {}, keepAdditionalFieldsKey = false }) => {
  const { ADDITIONAL_PARAMETERS, ADDITIONAL_FIELDS } = CUSTOM_FIELD_ENTITY_KEYS
  const { typeId, additionalFields, ...rest } = customField

  const additionalParameters = additionalFields

  const placement = ALL_CUSTOM_FIELDS.getFieldOptionsByTypeId(typeId).placement

  if (isEmpty(additionalParameters) || isUndefined(additionalParameters)) {
    if (
      typeId === ALL_CUSTOM_FIELDS.getTypeIds().SINGLE_SELECT ||
      typeId === ALL_CUSTOM_FIELDS.getTypeIds().MULTI_SELECT
    ) {
      return {
        ...rest,
        typeId,
        placement,
        [ADDITIONAL_PARAMETERS]: {
          [ADDITIONAL_PARAMS_SETTINGS.OPTIONS.typeId]: {
            typeId: ADDITIONAL_PARAMS_SETTINGS.OPTIONS.typeId,
            value: []
          }
        }
      }
    }

    if (typeId === ALL_CUSTOM_FIELDS.getTypeIds().MONEY) {
      return {
        ...rest,
        typeId,
        placement,
        [ADDITIONAL_PARAMETERS]: {
          [ADDITIONAL_PARAMS_SETTINGS.CURRENCY.typeId]: {
            typeId: ADDITIONAL_PARAMS_SETTINGS.CURRENCY.typeId,
            value: ''
          }
        }
      }
    }

    const result = {
      ...rest,
      typeId,
      placement,
      [ADDITIONAL_PARAMETERS]: {}
    }

    if (keepAdditionalFieldsKey) {
      result[ADDITIONAL_FIELDS] = []
    }

    return result
  }

  const optionsTypeId = ADDITIONAL_PARAMS_SETTINGS.OPTIONS.typeId
  const currencyTypeId = ADDITIONAL_PARAMS_SETTINGS.CURRENCY.typeId

  const additionalParametersObject = Object.entries(additionalParameters).reduce(
    (acc, [typeId, param]) => {
      if (Number(typeId) === optionsTypeId) {
        return {
          ...acc,
          [typeId]: {
            typeId: Number(typeId),
            value: param
          }
        }
      }

      if (Number(typeId) === currencyTypeId) {
        return {
          ...acc,
          [typeId]: {
            typeId: Number(typeId),
            value: param[0].name || ''
          }
        }
      }

      return {
        ...acc,
        [typeId]: param
      }
    },
    {}
  )

  const result = {
    ...rest,
    typeId,
    placement,
    [ADDITIONAL_PARAMETERS]: additionalParametersObject
  }

  if (keepAdditionalFieldsKey) {
    result[ADDITIONAL_FIELDS] = additionalFields
  }

  return result
}
export const normalizeCustomFieldsData = ({ customFields = [] }) => {
  return customFields.map(customField => {
    return normalizeCustomFieldData({ customField })
  })
}

export const getAssigneeCustomFieldSelectedItems = ({ fieldValue = [], isEdit = true } = {}) => {
  if (!fieldValue || isEmpty(fieldValue)) {
    return ALL_CUSTOM_FIELDS.getFieldOptionsByTypeId(ALL_CUSTOM_FIELDS.getTypeIds().ASSIGNEE)[
      CUSTOM_FIELD_ENTITY_KEYS.DEFAULT_VALUE
    ]
  }

  return isEdit ? fieldValue.map(item => item[ASSIGNEE_ENTITY_KEYS.ACCOUNT_ID]) : fieldValue
}

export const getAssigneeCustomFieldEmitValue = ({
  accountIds = [],
  allAssignees = [],
  isEdit = true
} = {}) => {
  return accountIds
    .map(id => {
      const userData = allAssignees.find(
        assignee => assignee[ASSIGNEE_ENTITY_KEYS.ACCOUNT_ID] === id
      )
      if (userData) {
        const { accountId, avatarUrl, name, displayName } = userData
        return isEdit
          ? {
              accountId,
              avatarUrl,
              [ASSIGNEE_ENTITY_KEYS.DISPLAY_NAME]: name || displayName
            }
          : accountId
      } else {
        return null
      }
    })
    .filter(Boolean)
}

export const getResolvedValueForUpdateCustomFieldValue = ({ value, fieldTypeId = null } = {}) => {
  let resolvedValue = value

  if (fieldTypeId === ALL_CUSTOM_FIELDS.getTypeIds().ASSIGNEE) {
    resolvedValue = resolvedValue.map(item => item[ASSIGNEE_ENTITY_KEYS.ACCOUNT_ID])
  }

  return resolvedValue
}
