<template>
  <router-view v-if="settingsAreLoaded" />
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters, mapState } from 'vuex'

import { ROUTE_PATHS } from '@/routes/route-helpers'
import { routeNamesAllowedOnlyForPluginAdmin } from '@/utils/menu-items'

export default defineComponent({
  name: 'SettingsLayout',

  beforeRouteEnter(to, _, next) {
    next(vm => {
      const userIsAdmin = vm.userIsAdmin
      const userIsPluginAdmin = vm.isPluginAdmin
      const selectedWorkspaceId = vm.workspaceId

      const isParentRouteAllowedOnlyForPluginAdmin = routeNamesAllowedOnlyForPluginAdmin.some(
        routeName => to.matched.some(route => route.name === routeName)
      )

      if (
        (!userIsAdmin && isParentRouteAllowedOnlyForPluginAdmin) ||
        (!userIsAdmin && routeNamesAllowedOnlyForPluginAdmin.includes(to.name)) ||
        (!userIsPluginAdmin && routeNamesAllowedOnlyForPluginAdmin.includes(to.name))
      ) {
        // wait for finishing initial route resolve and redirect
        vm.$nextTick(() => {
          vm.$router.push(`${ROUTE_PATHS.WORKSPACES}${selectedWorkspaceId}`)
        })
      }
    })
  },

  computed: {
    ...mapState('system', {
      settingsAreLoaded: state => state.settingsAreLoaded
    }),

    ...mapState('pluginOptions', {
      // eslint-disable-next-line vue/no-unused-properties
      isPluginAdmin: state => state.isPluginAdmin
    }),

    ...mapGetters('system', {
      // eslint-disable-next-line vue/no-unused-properties
      userIsAdmin: 'userIsAdmin'
    }),

    ...mapState('workspaces', {
      // eslint-disable-next-line vue/no-unused-properties
      workspaceId: 'workspaceId'
    })
  }
})
</script>

<style lang="scss" scoped></style>
