<template>
  <AppDialog
    :show="show"
    :title="$t('labels.delete_title')"
    :type="DIALOG_TYPES.DELETE"
    @on-confirm="onConfirm"
    @on-close="onClose"
  >
    <i18n-t keypath="labels.delete_message" scope="global">
      <template #labelName>
        <span class="ld-DangerText">{{ labelToDelete.name }}</span>
      </template>
    </i18n-t>

    <template #confirm-btn-text>
      {{ $t('action.remove') }}
    </template>
  </AppDialog>
</template>

<script>
import { defineComponent } from 'vue'

import { DIALOG_TYPES } from '@/utils/components-configurations/app-dialog'
import { objectOrNullProp } from '@/utils/prop-validators'

import AppDialog from '@/components/AppDialog'

export default defineComponent({
  name: 'LabelDeleteModal',

  components: {
    AppDialog
  },

  props: {
    show: {
      type: Boolean,
      default: false
    },

    labelToDelete: {
      validator: v => objectOrNullProp(v),
      required: true
    }
  },

  emits: { 'on-confirm': null, 'on-close': null },

  computed: {
    DIALOG_TYPES: () => DIALOG_TYPES
  },

  methods: {
    onClose() {
      this.$emit('on-close')
    },

    onConfirm() {
      this.$emit('on-confirm')
    }
  }
})
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/modal-danger-text';

.ld-DangerText {
  @extend %modal-danger-text;
}
</style>
