<template>
  <div
    :class="{
      'oeli-ItemWrapper-expanded': isExpanded,
      'oeli-ItemWrapper-focused': focused,
      [`oeli-ItemWrapper-${type}`]: type,
      [customClass]: customClass,
      'oeli-ItemWrapper-highlighted': highlightZeroDepth && !currentDepth,
      [`oeli-ItemWrapper-obj-type-${getOkrElementType}`]: true,
      [`oeli-ItemWrapper-bordered-last-row`]: borderOnLastRow
    }"
    :data-auto-testid="$attrs['data-auto-testid'] || 'okr-elements-list-item'"
    class="oeli-ItemWrapper"
  >
    <slot
      v-if="$slots.default"
      :current-depth-is-allowed="currentDepthIsAllowed"
      :is-expandable="isExpandable"
      :show-toggle-sign="showToggleSign"
    />
    <div :class="{ [`oeli-ItemContent-on-depth-${currentDepth}`]: true }" class="oeli-ItemContent">
      <template v-if="showToggleSign">
        <template v-if="hideToggleSign">
          <AppIcon
            v-if="type === LIST_ITEM_TYPES.PRIMARY"
            :icon-name="rotateToggleSign ? 'arrow-up-light' : 'arrow-down-light'"
            class="oeli-ArrowPlug"
            height="20"
            width="20"
          />
        </template>
        <div v-else :class="toggleSignClasses">
          <AppButton
            v-if="isExpandable && currentDepthIsAllowed"
            :class="{ 'oeli-ExpandButton-expanded': isExpanded }"
            :size="expandButtonSize"
            class="oeli-ExpandButton"
            remove-padding
            type="ghost-next"
            @click="toggleExpand(objective.id)"
          >
            <AppIcon class="oeli-ArrowIcon" height="24" icon-name="okr-table-expand" width="24" />
          </AppButton>
        </div>
      </template>

      <div class="oeli-Item" @click.self="onObjectiveClick(objective.id)">
        <div class="oeli-Item_Left">
          <OkrIcon :objective="objective" />

          <TitleItem
            v-tippy="{
              content: tooltipName,
              theme: DROP_LIST_THEMES.COMMON_TOOLTIP_THEMES
            }"
            :id-as-link="idAsLink"
            :objective="objective"
            :semi-transparent-id="transparentFiltered && !objective.fitFilterCriteria"
            class="oeli-Title"
            id-size="small"
            style="--id-font-weight: 600"
            @click-on-id="onMenuActionsClick('edit', objective)"
          />
        </div>

        <div class="oeli-Item_Right">
          <div
            v-if="showInterval"
            v-tippy="{
              content: objective.intervalName
            }"
            class="oeli-Interval"
          >
            {{ objective.intervalName }}
          </div>

          <StatusCell v-if="showStatus" :item="objective" readable />

          <div v-if="showCurrentTarget" class="oeli-CurrentTargetWrapper">
            <div v-if="isVisibleCurrentTargetValues">
              <span class="oeli-CurrentValue">{{ objective.currentValue }}</span>
              /
              <span class="oeli-TargetValue">{{ objective.targetValue }}</span>
            </div>
          </div>

          <ListItemGradeInfo
            v-if="showGradeInfo && showGrade"
            :grade="objective.gradeToUse"
            :grade-info="objective.gradeInfo"
          />

          <OkrGrade
            v-if="showGrade"
            :additional-wrapper-on-value="additionalWrapperOnValue"
            :always-show-weight="alwaysShowWeight"
            :color="objective.gradeColor"
            :grade="objective.gradeToUse"
            :hide-weight-icon="hideWeightIcon"
            :is-contribute="objective.contribute"
            :show-only-value="showOnlyGradeValue"
            :type="gradeType"
            :type-id="objective.typeId"
            :weight="objective.weight"
            :weight-is-dimmed="dimAllWeights ? true : weightIsDimmed"
            :weight-multiplier="objective.weightMultiplier"
            show-multiplier
          />

          <AppAvatar
            v-if="showAvatar"
            v-tippy="{
              content: ownerName,
              theme: DROP_LIST_THEMES.COMMON_TOOLTIP_THEMES
            }"
            :avatar-url="avatarUrl"
            :data-aria-label="ariaLabel"
            :show-avatar-replacer="showAvatarReplacer"
            no-margins
          />

          <div v-if="showGroups" class="oeli-GroupsList">
            <GroupsCell v-if="showGroups" :item="objective" />
          </div>

          <div v-if="showLastComment" class="oeli-CommentsCell">
            <CommentsCell
              :item="objective"
              :tracking-source="trackingSource"
              append-to=".ol-ObjectivesList"
              @comments-scroll-to="commentsScrollTo"
              @update-comment="updateElements"
            />
          </div>

          <div v-if="showGradeUpdate" class="oeli-GradeUpdateCell oboard-truncated-text">
            <span
              v-tippy="{
                content: getLastGradeUpdateDate.formatted
              }"
            >
              {{ getLastGradeUpdateDate.to }}
            </span>
          </div>

          <!-- Actions droplist -->
          <slot v-if="!hideActions" name="actions">
            <DropdownMenu
              v-if="itemMenuActions.length"
              :items="itemMenuActions"
              :type="dropdownMenuType"
              class="oeli-More"
              @item-click="onMenuActionsClick($event, objective)"
            >
              <template #activator>
                <AppButton
                  :icon="menuButtonOptions.icon"
                  :type="menuButtonOptions.type"
                  size="sm"
                />
              </template>
            </DropdownMenu>
            <AppIcon
              v-else
              v-tippy="{
                placement: 'top',
                content: $t('objective.btn.read_only'),
                boundary: 'viewport'
              }"
              :icon-name="menuButtonOptions.icon"
              class="oeli-MorePlug"
              height="24"
              width="24"
            />
          </slot>
          <!-- Actions droplist end -->
        </div>
      </div>
    </div>
    <div v-if="showChildObjectivesList" class="oeli-ChildObjectives">
      <span v-if="isPrimaryNext" class="oeli-ChildItemsLine" />
      <VirtualizationList
        :data="objective.childElements"
        :with-virtual-scroll="false"
        data-key="id"
      >
        <template #default="{ item }">
          <OkrElementsListItem
            :key="item.uniqueId"
            :additional-wrapper-on-value="additionalWrapperOnValue"
            :allowed-depth="allowedDepth"
            :always-show-weight="alwaysShowWeight"
            :current-depth="currentDepth + 1"
            :dim-all-weights="dimAllWeights"
            :dropdown-menu-type="dropdownMenuType"
            :external-actions-listener="externalActionsListener"
            :fetch-child-objectives-on-expand="fetchChildObjectivesOnExpand"
            :has-add-kr-action="hasAddKrAction"
            :has-jira-issue-actions="hasJiraIssueActions"
            :has-unlink-action="hasUnlinkAction"
            :hide-actions="hideActions"
            :hide-weight-icon="hideWeightIcon"
            :id-as-link="objectiveReadable(item)"
            :objective="item"
            :parent-objective="objective"
            :placement="placement"
            :show-avatar-replacer="showAvatarReplacer"
            :show-current-target="showCurrentTarget"
            :show-grade="showGrade"
            :show-grade-info="showGradeInfo"
            :show-grade-update="showGradeUpdate"
            :show-groups="showGroups"
            :show-interval="showInterval"
            :show-last-comment="showLastComment"
            :show-only-grade-value="showOnlyGradeValue"
            :show-status="showStatus"
            :some-item-is-expandable="someItemIsExpandable"
            :transparent-filtered="transparentFiltered"
            :type="type"
            :weight-is-dimmed="dimNestedItemsWeights"
            @expand-item="$emit('expand-item', $event)"
            @update-elements="updateElements"
            @okr-element-deleted="onOkrElementDeleted(item.uniqueId)"
            @edit-element="$emit(EMIT_EVENTS.EDIT_ELEMENT, $event)"
            @link-jira-issue="$emit(EMIT_EVENTS.LINK_JIRA_ISSUE, $event)"
            @create-jira-issue="$emit(EMIT_EVENTS.CREATE_JIRA_ISSUE, $event)"
            @on-menu-item-click="$emit('on-menu-item-click', $event)"
          >
            <template
              #default="{
                isExpandable: currentItemIsExpandable,
                currentDepthIsAllowed: currentItemDepthIsAllowed,
                showToggleSign: showCurrentItemToggleSign
              }"
            >
              <ContributeLine
                v-if="isPrimaryNext"
                :class="{
                  'oeli-ChildContributeLine-short':
                    currentItemDepthIsAllowed &&
                    currentItemIsExpandable &&
                    showCurrentItemToggleSign
                }"
                class="oeli-ChildContributeLine"
              />
            </template>
          </OkrElementsListItem>
        </template>
      </VirtualizationList>
    </div>
  </div>

  <ObjectiveDeleteModal
    v-model:show="showConfirmDelete"
    :objective="deleteObjectiveData"
    :source="EVENT_SOURCES.FORM"
    is-child
    @deleted="onOkrElementDeleted"
    @update:show="!$event ? (deleteObjectiveData = null) : undefined"
  />
</template>

<script>
import dayjs from 'dayjs'
import { isEmpty, isUndefined } from 'lodash'
import { defineComponent } from 'vue'
import { mapState } from 'vuex'

import ObjectivesInfoApiHandler, { SEARCH_TYPES } from '@/api/okr-elements'
import { EVENT_SOURCES, TRACKING_UNKNOWN } from '@/tracking/amplitude-helpers'
import { currentTargetVisibility } from '@/utils/cell-helper'
import { BUTTON_SIZES } from '@/utils/components-configurations/app-button'
import { DROP_LIST_THEMES } from '@/utils/components-configurations/app-droplist'
import { DROPDOWN_MENU_TYPES } from '@/utils/components-configurations/dropdown-menu'
import {
  LIST_ITEM_PLACEMENTS,
  LIST_ITEM_TYPES
} from '@/utils/components-configurations/okr-elements-list-item'
import { OKR_GRADE_TYPES } from '@/utils/components-configurations/okr-grade'
import { dateFormat } from '@/utils/date'
import { MENU_ITEMS_GROUPS } from '@/utils/dropdown-menu'
import { handleError } from '@/utils/error-handling'
import {
  isJiraAppInjectionKey,
  isSalesforceAppInjectionKey,
  isWebAppInjectionKey
} from '@/utils/injection-keys'
import { OBJECTIVE_TYPES } from '@/utils/objective-types'
import {
  currentUserCanCreateObjective,
  currentUserCanDeleteObjective,
  currentUserCanReadObjective,
  currentUserCanUpdateObjective,
  getOwnerFromUsersList,
  getOwnerName,
  objectiveIsJiraTask,
  OBJECTIVE_SORT_OPTIONS,
  OKR_ELEMENTS_TABLE_ACTIONS_MENU_ITEMS,
  OKR_ELEMENTS_TABLE_ADD_MENU_ITEMS,
  OKR_TYPE_TO_FORM_VIEW,
  isKR,
  isOkrElementClosed
} from '@/utils/objectives'
import { isAllNestedItemsWeightsDimmed } from '@/utils/okr-weights-helpers'
import { PLUGIN_OPTIONS_KEYS } from '@root/template-options-keys'

import ContributeLine from '@/components/objectives/forms/ContributeLine'
import OkrIcon from '@/components/objectives/items/OkrIcon'
import TitleItem from '@/components/objectives/items/TitleItems'
import ObjectiveDeleteModal from '@/components/objectives/ObjectiveDeleteModal'
import ListItemGradeInfo from '@/components/objectives/okr-elements-list/ListItemGradeInfo'
import OkrGrade from '@/components/objectives/OkrGrade'
import CommentsCell from '@/components/objectives/table/cells/CommentsCell'
import GroupsCell from '@/components/objectives/table/cells/GroupsCell'
import StatusCell from '@/components/objectives/table/cells/StatusCell'
import AppAvatar from '@/components/ui/AppAvatar/AppAvatar'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppIcon from '@/components/ui/AppIcon/AppIcon'
import DropdownMenu from '@/components/ui/DropdownMenu/DropdownMenu'
import VirtualizationList from '@/components/VirtualizationList'

const { REMOVE, EDIT } = OKR_ELEMENTS_TABLE_ACTIONS_MENU_ITEMS

const { LINK_TASK, CREATE_JIRA_ISSUE } = OKR_ELEMENTS_TABLE_ADD_MENU_ITEMS

const EMIT_EVENTS = {
  CREATE_JIRA_ISSUE: 'create-jira-issue',
  LINK_JIRA_ISSUE: 'link-jira-issue',
  EDIT_ELEMENT: 'edit-element'
}

const { REMOVING, EDITING, CREATING } = MENU_ITEMS_GROUPS

const MENU_ACTIONS = {
  REMOVE: {
    name: REMOVE.name,
    title: 'action.delete',
    icon: 'delete',
    iconNext: 'delete-next',
    color: 'var(--grade-low-color-next)',
    group: REMOVING
  },

  EDIT: {
    name: EDIT.name,
    title: 'action.edit',
    icon: 'edit-orange',
    iconNext: 'edit-next',
    event: EMIT_EVENTS.EDIT_ELEMENT,
    group: EDITING
  },

  UNLINK: {
    name: 'unlink',
    title: 'action.unlink',
    icon: 'unlink',
    iconNext: 'unlink-next',
    group: REMOVING
  },

  LINK_TASK: {
    name: LINK_TASK.name,
    title: 'action.link_jira_issue',
    icon: 'link-green',
    iconNext: 'link-task',
    event: EMIT_EVENTS.LINK_JIRA_ISSUE,
    group: CREATING
  },

  CREATE_JIRA_ISSUE: {
    name: CREATE_JIRA_ISSUE.name,
    title: 'action.create_jira_issue',
    icon: 'add-item',
    iconNext: 'create-task',
    event: EMIT_EVENTS.CREATE_JIRA_ISSUE,
    group: CREATING
  },

  ADD_KR: {
    name: 'add_key_result',
    title: 'action.create_keyResult',
    icon: 'kr-objective',
    iconNext: 'kr-objective-next',
    event: 'create-key-result',
    group: CREATING
  },

  UNLINK_JIRA_ISSUE: {
    name: REMOVE.name,
    title: 'action.unlink_issue',
    icon: 'unlink',
    iconNext: 'unlink-next',
    color: 'var(--grade-low-color-next)',
    group: REMOVING
  }
}

const getValueInPx = val => `${val}px`

const CONTRIBUTE_LINE_HEIGHT = '10px'

export default defineComponent({
  name: 'OkrElementsListItem',

  components: {
    StatusCell,
    VirtualizationList,
    GroupsCell,
    CommentsCell,
    ContributeLine,
    ListItemGradeInfo,
    OkrGrade,
    ObjectiveDeleteModal,
    AppIcon,
    DropdownMenu,
    AppAvatar,
    TitleItem,
    OkrIcon,
    AppButton
  },

  inject: {
    isWebApp: {
      from: isWebAppInjectionKey
    },

    isSalesforceApp: {
      from: isSalesforceAppInjectionKey
    },

    isJiraApp: {
      from: isJiraAppInjectionKey
    }
  },

  inheritAttrs: false,

  props: {
    objective: {
      type: Object,
      required: true
    },

    currentDepth: {
      type: Number,
      default: 0
    },

    allowedDepth: {
      // calculated from 0 so if we need 2-levels depth we must set 1
      type: Number,
      default: Infinity
    },

    showStatus: {
      type: Boolean
    },

    showGroups: {
      type: Boolean
    },

    showInterval: {
      type: Boolean
    },

    showGradeInfo: {
      type: Boolean
    },

    showGrade: {
      type: Boolean,
      default: true
    },

    showAvatar: {
      type: Boolean,
      default: true
    },

    idAsLink: {
      type: Boolean,
      default: true
    },

    hasUnlinkAction: {
      type: Boolean
    },

    hasAddKrAction: {
      type: Boolean
    },

    hasJiraIssueActions: {
      type: Boolean,
      default: true
    },

    externalActionsListener: {
      type: Boolean
    },

    fetchChildObjectivesOnExpand: {
      type: Boolean
    },

    handleClick: {
      type: Boolean,
      default: true
    },

    focused: {
      type: Boolean
    },

    transparentFiltered: {
      type: Boolean
    },

    rotateToggleSign: {
      type: Boolean
    },

    hideActions: {
      type: Boolean
    },

    customClass: {
      type: String,
      default: ''
    },

    type: {
      type: String,
      default: LIST_ITEM_TYPES.PRIMARY,
      validator: v => Object.values(LIST_ITEM_TYPES).includes(v)
    },

    someItemIsExpandable: {
      type: Boolean
    },

    dropdownMenuType: {
      type: String,
      default: DROPDOWN_MENU_TYPES.DEFAULT,
      validator: v => Object.values(DROPDOWN_MENU_TYPES).includes(v)
    },

    weightIsDimmed: {
      type: Boolean
    },

    alwaysShowWeight: {
      type: Boolean
    },

    showOnlyGradeValue: {
      type: Boolean
    },

    showCurrentTarget: {
      type: Boolean
    },

    hideWeightIcon: {
      type: Boolean
    },

    dimAllWeights: {
      type: Boolean
    },

    showAvatarReplacer: {
      type: Boolean
    },

    highlightZeroDepth: {
      type: Boolean
    },

    additionalWrapperOnValue: {
      type: Boolean
    },

    borderOnLastRow: {
      type: Boolean
    },

    showLastComment: {
      type: Boolean
    },

    showGradeUpdate: {
      type: Boolean
    },

    noLinkId: {
      type: Boolean
    },

    allowUnlinkAction: {
      type: Boolean,
      default: true
    },

    allowDeleteAction: {
      type: Boolean,
      default: true
    },

    trackingSource: {
      type: String,
      default: TRACKING_UNKNOWN
    },

    parentObjective: {
      type: Object,
      default: null
    },

    placement: {
      type: String,
      default: LIST_ITEM_PLACEMENTS.UNKNOWN
    }
  },

  emits: {
    'expand-item': null,
    'update-elements': null,
    [EMIT_EVENTS.LINK_JIRA_ISSUE]: null,
    [EMIT_EVENTS.CREATE_JIRA_ISSUE]: null,
    'on-menu-item-click': null,
    'okr-element-deleted': null,
    [EMIT_EVENTS.EDIT_ELEMENT]: null,
    'create-key-result': null,
    'set-expand': null
  },

  data() {
    return {
      isExpanded: false,

      showConfirmDelete: false,
      deleteObjectiveData: null,

      weightIsManual: false
    }
  },

  computed: {
    EVENT_SOURCES: () => EVENT_SOURCES,

    EMIT_EVENTS: () => EMIT_EVENTS,

    LIST_ITEM_TYPES: () => LIST_ITEM_TYPES,

    DROP_LIST_THEMES: () => DROP_LIST_THEMES,

    CONTRIBUTE_LINE_HEIGHT: () => CONTRIBUTE_LINE_HEIGHT,

    ...mapState('pluginOptions', {
      isJiraConnected: state => state[PLUGIN_OPTIONS_KEYS.JIRA_CONNECTED]
    }),

    ...mapState('system', {
      userData: state => state.userData || {}
    }),

    getLastGradeUpdateDate() {
      return {
        formatted: dayjs(this.objective.lastGradeUpdateDate).format(dateFormat),
        to: dayjs().to(dayjs(this.objective.lastGradeUpdateDate))
      }
    },

    getOkrElementType() {
      const isTask = objectiveIsJiraTask(this.objective)
      const isKr = isKR(this.objective)
      const isObjective = !isTask && !isKr
      return isObjective ? 'objective' : 'kr-or-task'
    },

    isVisibleCurrentTargetValues() {
      return currentTargetVisibility(this.objective)
    },

    dimNestedItemsWeights() {
      if (this.dimAllWeights) {
        return true
      }

      if (this.showChildObjectivesList) {
        return isAllNestedItemsWeightsDimmed({
          weightIsManual: this.weightIsManual,
          nestedItems: this.objective.childElements
        })
      } else {
        return false
      }
    },

    isPrimaryNext() {
      return this.type === LIST_ITEM_TYPES.PRIMARY_NEXT
    },

    gradeType() {
      return this.isPrimaryNext ? OKR_GRADE_TYPES.DEFAULT_NEXT : OKR_GRADE_TYPES.DEFAULT
    },

    contentGap() {
      return this.isPrimaryNext ? 8 : 2
    },

    expandButtonWidth() {
      return this.isPrimaryNext ? 24 : 20
    },

    menuButtonOptions() {
      const options = {
        type: 'subtle',
        icon: 'more'
      }

      if (this.isPrimaryNext) {
        options.type = 'tertiary-next'
        options.icon = 'more-next'
      }

      return options
    },

    expandButtonOffset() {
      if (this.isPrimaryNext) {
        return this.someItemIsExpandable
          ? getValueInPx(this.expandButtonWidth + this.contentGap)
          : getValueInPx(0)
      } else {
        return getValueInPx(this.expandButtonWidth + this.contentGap)
      }
    },

    expandButtonSize() {
      return this.isPrimaryNext ? BUTTON_SIZES.SM : BUTTON_SIZES.XSS
    },

    toggleSignClasses() {
      return {
        'oeli-ToggleSign': true,
        'oeli-ToggleSign-dot':
          !this.isPrimaryNext && (!this.isExpandable || !this.currentDepthIsAllowed)
      }
    },

    tooltipName() {
      if (!this.objective) {
        return ''
      }

      if (!currentUserCanReadObjective(this.objective)) {
        return this.$t('objectives.unavailable_description')
      }

      if (this.objective.id === 0) {
        return this.objective.name
      }
      return `${this.objective.displayId} ${this.objective.name}`
    },

    hideToggleSign() {
      return this.allowedDepth === 0
    },

    showToggleSign() {
      return this.isPrimaryNext ? this.someItemIsExpandable : true
    },

    currentDepthIsAllowed() {
      return this.currentDepth < this.allowedDepth
    },

    showChildObjectivesList() {
      return this.isExpandable && this.isExpanded && this.currentDepthIsAllowed
    },

    childObjectivesPaddingLeft() {
      if (this.isPrimaryNext) {
        // 24px — expand button width + 8px — gap between icon and expand button
        return `${(this.expandButtonWidth + this.contentGap) * this.currentDepth}px`
      } else {
        // all nested items has their own 8px padding-left + 12px for every depth level
        return `${12 * this.currentDepth + 8}px`
      }
    },

    isExpandable() {
      return !isEmpty(this.objective.childElements) || this.objective.childCount > 0
    },

    ownerName() {
      return getOwnerName(this.objective) || this.$t('objective.no_owner')
    },

    ariaLabel() {
      const ownerName = getOwnerName(this.objective)
      return ownerName
        .split(' ')
        .slice(0, 2)
        .map(name => name[0])
        .join('')
    },

    avatarUrl() {
      return getOwnerFromUsersList(this.objective.users)?.avatarUrl
    },

    allowCreateNew() {
      if (!this.$store) {
        return false
      }
      const { isPluginAdmin } = this.$store.state.pluginOptions
      return currentUserCanCreateObjective(
        // Should be with current condition this.$store? because we use this component in widget
        this.$store?.getters['workspaces/selectedWorkspace'],
        isPluginAdmin
      )
    },

    isParentOkrClosed() {
      return this.parentObjective && isOkrElementClosed(this.parentObjective)
    },

    itemMenuActions() {
      const { objective, hasUnlinkAction, hasJiraIssueActions, hasAddKrAction } = this
      const result = []

      const isUserCanRead = currentUserCanReadObjective(objective)

      if (
        isUserCanRead &&
        this.allowCreateNew &&
        objective.typeId === OBJECTIVE_TYPES.PERSONAL &&
        hasAddKrAction
      ) {
        result.push(MENU_ACTIONS.ADD_KR)
      }

      if (
        isUserCanRead &&
        this.allowCreateNew &&
        !objectiveIsJiraTask(objective) &&
        hasJiraIssueActions
      ) {
        if (
          this.isJiraApp ||
          ((this.isWebApp || this.isSalesforceApp) &&
            this.isJiraConnected &&
            this.userData.hasAccessToJira)
        ) {
          result.push({
            ...MENU_ACTIONS.LINK_TASK,
            disabled: isOkrElementClosed(this.objective)
          })
        }

        if (this.isJiraApp) {
          result.push({
            ...MENU_ACTIONS.CREATE_JIRA_ISSUE,
            disabled: isOkrElementClosed(this.objective)
          })
        }
      }

      if (currentUserCanUpdateObjective(objective)) {
        result.push(MENU_ACTIONS.EDIT)
        if (hasUnlinkAction) {
          result.push({
            ...MENU_ACTIONS.UNLINK,
            disabled: !this.allowUnlinkAction || this.isParentOkrClosed
          })
        }
      }

      if (currentUserCanDeleteObjective(objective)) {
        const deleteAction = objectiveIsJiraTask(this.objective)
          ? {
              ...MENU_ACTIONS.UNLINK_JIRA_ISSUE,
              disabled: !this.allowDeleteAction || this.isParentOkrClosed
            }
          : {
              ...MENU_ACTIONS.REMOVE,
              disabled:
                !this.allowDeleteAction ||
                this.isParentOkrClosed ||
                isOkrElementClosed(this.objective)
            }
        result.push(deleteAction)
      }

      return result.map(action => {
        const { iconNext, ...rest } = action
        if (this.isPrimaryNext) {
          return {
            ...rest,
            icon: iconNext
          }
        }
        return action
      })
    }
  },

  methods: {
    objectiveReadable(objective) {
      if (this.noLinkId) {
        return false
      }
      return currentUserCanReadObjective(objective)
    },

    commentsScrollTo() {
      this.$emit(MENU_ACTIONS.EDIT.event, {
        view: OKR_TYPE_TO_FORM_VIEW[this.objective.typeId],
        item: this.objective,
        scrollToComments: true
      })
    },

    updateElements() {
      this.$emit('update-elements')
    },

    onOkrElementDeleted(elementId) {
      this.$emit('okr-element-deleted', elementId)
    },

    /** @public */
    async toggleExpand(id, value = undefined) {
      if (!isUndefined(value)) {
        if (this.isExpandable && this.currentDepthIsAllowed) {
          this.isExpanded = value
          this.$emit('set-expand', {
            id,
            value
          })
        }
        return
      }
      if (this.fetchChildObjectivesOnExpand) {
        if (!this.isExpanded) {
          const api = new ObjectivesInfoApiHandler()

          try {
            const { items, weightManual } = await api.getChildObjectives({
              parentId: id,
              workspaceId: this.$route.params.workspaceId,
              intervalIds:
                this.placement === LIST_ITEM_PLACEMENTS.OKR_ELEMENT_FORM
                  ? undefined
                  : [this.objective.intervalId],
              searchType: SEARCH_TYPES.OKR_EXPLORER,
              order: [OBJECTIVE_SORT_OPTIONS.ORDER_ASC],
              childOrder: [OBJECTIVE_SORT_OPTIONS.ORDER_ASC],
              offset: 0,
              limit: 1000
            })
            this.$emit('expand-item', { parentId: id, items })
            this.weightIsManual = weightManual
          } catch (error) {
            handleError({ error })
          }
        } else {
          this.$emit('expand-item', id)
        }
      } else {
        this.$emit('expand-item', id)
      }

      this.isExpanded = !this.isExpanded
    },

    onObjectiveClick(id) {
      if (this.isExpandable && this.handleClick) {
        this.toggleExpand(id)
      }
    },

    deleteItem(objective) {
      this.deleteObjectiveData = objective
      this.showConfirmDelete = true
    },

    onMenuActionsClick(name, item) {
      if (this.externalActionsListener) {
        this.$emit('on-menu-item-click', { name, item })
      } else {
        if (name === MENU_ACTIONS.LINK_TASK.name) {
          this.$emit(MENU_ACTIONS.LINK_TASK.event, item)
        } else if (name === MENU_ACTIONS.CREATE_JIRA_ISSUE.name) {
          this.$emit(MENU_ACTIONS.CREATE_JIRA_ISSUE.event, item)
        } else if (name === MENU_ACTIONS.REMOVE.name) {
          this.deleteItem(item)
        } else if (name === MENU_ACTIONS.EDIT.name) {
          this.$emit(MENU_ACTIONS.EDIT.event, { view: OKR_TYPE_TO_FORM_VIEW[item.typeId], item })
        } else if (name === MENU_ACTIONS.ADD_KR.name) {
          this.$emit(MENU_ACTIONS.ADD_KR.event, item)
        }
      }
    }
  }
})
</script>

<style lang="scss" scoped>
$primary-next-item-horizontal-padding: 18px;
$primary-next-item-vertical-padding: 10px;

$padding-left: var(--item-padding-left, #{$primary-next-item-horizontal-padding});
$padding-right: var(--item-padding-right, #{$primary-next-item-horizontal-padding});

.oeli-ItemWrapper {
  display: grid;
  overflow: hidden;

  &:not(&-primary-next) {
    &:not(:last-child) {
      box-shadow: inset 0 -1px 0 $grey-2;
    }
  }

  &-primary-next {
    position: relative;

    font-family: $system-ui;

    &:not(:last-child),
    &.oeli-ItemWrapper-bordered-last-row {
      .oeli-ItemContent {
        &:after {
          content: '';
          position: absolute;
          right: $primary-next-item-horizontal-padding;
          bottom: 0;
          width: calc(
            100% - v-bind(expandButtonOffset) - v-bind(childObjectivesPaddingLeft) -
              (#{$primary-next-item-horizontal-padding} * 2)
          );
          background-color: $grey-2-next;
          pointer-events: none;
          height: 1px;
        }
      }
    }
  }
}

.oeli-ItemWrapper-primary {
  &:not(.oeli-ChildObjectives &) {
    background-color: $azure;
  }
}

.oeli-ItemWrapper-secondary {
  &:not(.oeli-ChildObjectives &) {
    background-color: $white;
  }
}

.oeli-ItemWrapper-primary-next {
  background-color: transparent;
}

.oeli-ExpandButton {
  transition: transform $transition-fast;

  &-expanded {
    transform: rotate(90deg);
  }
}

.oeli-ItemWrapper-highlighted {
  &.oeli-ItemWrapper-primary-next > {
    .oeli-ItemContent {
      padding-top: 18px;
      padding-bottom: 18px;
      .oeli-Title {
        font-size: $fs-16;
        line-height: 20px;
        font-weight: fw('semi-bold');
      }
      &:deep(.okg-OkrGrade_Value-next) {
        font-size: $fs-16;
        line-height: 20px;
        font-weight: fw('semi-bold');
      }
    }
  }
}
// .oeli-ArrowIcon {
//   $size: calc(v-bind(expandButtonWidth) * 1px);
//   // this icon is inserted as background and not as AppIcon because
//   // AppIcon rotating doesn't work as expected in Safari
//   width: $size;
//   height: $size;
//   background-position: center center;
//
//   // &:not(.oeli-ItemWrapper-primary-next &) {
//   //   //background-image: url('~@/assets/images/arrow-dark.svg');
//   // }
//   //
//   // .oeli-ItemWrapper-primary-next & {
//   //   //background-image: url('~@/assets/images/arrow-dark-next.svg');
//   // }
//   //
//   // &-rotated {
//   //   transform: rotate(90deg);
//   // }
// }

.oeli-ItemContent {
  overflow: hidden;
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: var(--item-border-radius, 0);

  .oeli-ItemWrapper-primary & {
    padding: 10px 8px 10px v-bind(childObjectivesPaddingLeft);
  }

  .oeli-ItemWrapper-secondary & {
    padding: 10px;
  }

  .oeli-ItemWrapper-primary-next & {
    padding: 10px $padding-right 10px calc(#{$padding-left} + v-bind(childObjectivesPaddingLeft));
    position: relative;

    @media (any-hover: hover) {
      &:hover {
        background-color: $grey-3-next;
        .oeli-More,
        .oeli-MorePlug {
          opacity: 1;
        }
      }
    }
  }

  .oeli-ItemWrapper-expanded:not(.oeli-ItemWrapper-primary-next) & {
    &:not(:last-child) {
      box-shadow: inset 0 -1px 0 $grey-2;
    }
  }
}

.oeli-Item {
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

/* eslint-disable-next-line */
%flex-row-center {
  display: flex;
  align-items: center;
}

.oeli-Item_Left,
.oeli-Item_Right {
  @extend %flex-row-center;
}

.oeli-Item_Left {
  overflow: hidden;
  gap: 8px;
}

.oeli-Item_Right {
  gap: var(--right-items-gap, 12px);
}

.oeli-Title {
  line-height: 20px;
  font-size: $fs-14;
}

.oeli-Interval {
  white-space: nowrap;
}

.oeli-ChildObjectives {
  width: 100%;
  position: relative;

  &:not(.oeli-ItemWrapper-primary-next &) {
    background-color: $grey-3;
  }
}

.oeli-ChildItemsLine {
  position: absolute;
  width: 1px;
  height: calc(100% - 12px - v-bind(CONTRIBUTE_LINE_HEIGHT)); // 12px - half height of okr icon;
  background-color: $grey-2-next;
  left: calc(((v-bind(expandButtonWidth) / 2) * 1px) + #{$padding-left});
  top: -#{$primary-next-item-vertical-padding};
  pointer-events: none;
  z-index: 1;
}

.oeli-ChildContributeLine {
  position: absolute;
  left: calc(((v-bind(expandButtonWidth) / 2) * 1px) + #{$padding-left});
  --height: #{$primary-next-item-vertical-padding};
  top: 12px;
  --width: calc(v-bind(childObjectivesPaddingLeft) + (v-bind(contentGap) * 1px));
  z-index: 1;

  &-short {
    --width: calc(
      v-bind(childObjectivesPaddingLeft) + (v-bind(contentGap) * 1px) -
        (v-bind(expandButtonWidth) * 1px)
    );
  }
}

.oeli-ToggleSign {
  $size: calc(v-bind(expandButtonWidth) * 1px);
  $gap: calc(v-bind(contentGap) * 1px);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: $gap;
  min-width: $size;
  min-height: $size;

  &-dot {
    justify-content: center;
    &:after {
      content: '';
      position: relative;
      display: block;
      height: 4px;
      width: 4px;
      background-color: $grey-medium;
      border-radius: 50%;
    }
  }
}

.oeli-ArrowPlug {
  margin-right: 2px;
  flex: 0 0 auto;
}

.oeli-More,
.oeli-MorePlug {
  .oeli-ItemWrapper-primary-next & {
    position: var(--menu-trigger-postion, absolute);
    right: $padding-right;
    opacity: 0;
  }
}

.oeli-MorePlug {
  background-color: $grey-2-next;
  border-radius: $border-radius-sm-next;
}
.oeli-CurrentTargetWrapper {
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: fw('regular');
  font-size: $fs-14;
  line-height: 20px;
  color: $dark-3;
  min-width: 65px;
  white-space: nowrap;
  justify-content: flex-end;
}
.oeli-CurrentValue {
  color: $dark-1;
  font-weight: fw('semi-bold');
}
.oeli-GroupsList {
  width: 124px;
  display: flex;
}
.oeli-CommentsCell {
  max-width: 24px;
  width: 24px;
}
.oeli-GradeUpdateCell {
  width: 100px;
}
</style>
