<template>
  <div
    :class="{
      'otr-VirtualizationList-dropImpossible': !dropIsPossible
    }"
  >
    <VirtualizationList
      :data="items"
      data-key="uid"
      @drag-start="onDragStart"
      @drag-end="onSortEnd"
      @drag-change="onDragChange"
    >
      <template #default="{ item }">
        <ObjectiveTableRow
          :key="item.uid"
          :ref="data => setChildRef(item.id, data)"
          :depth="item.depth"
          :draggable="isRowDraggable(item)"
          :expanded="isRowExpanded({ item })"
          :hide-okr-element-rename="hideOkrElementRename"
          :hide-table-row-actions="hideTableRowActions"
          :is-last="isItemLast(item)"
          :objective="listState.okrElements[item.id] || listState.nestedTasks[item.id]"
          :style="getActiveChildrenStyles(item)"
          :table-row-grade-configurable="tableRowGradeConfigurable"
          :virtual-uid="item.uid"
          :workspace-id="workspaceId"
          @add="addRow(listState.okrElements[item.id], $event)"
          @delete="deleteRow(listState.okrElements[item.id])"
          @duplicate="duplicateRow(listState.okrElements[item.id])"
          @edit="editRow(listState.okrElements[item.id], $event)"
          @move="moveObjective(listState.okrElements[item.id])"
          @updated="onRowUpdated"
          @update:expanded="onRowExpandedChange(item, $event)"
          @edit-weights="editRowWeights(listState.okrElements[item.id])"
          @update-show-actions="onUpdateShowActions(item.depth)"
        />
      </template>
    </VirtualizationList>

    <slot name="footer" />

    <ObjectiveViewRowActions
      v-if="!hideTableRowActions"
      ref="actions"
      :depth="activeDepth"
      source="table"
      @update="updateObjectives($event)"
    />
  </div>
</template>

<script>
import { isString } from 'lodash'
import { defineComponent } from 'vue'

import ObjectivesApiHandler from '@/api/okr-elements'
import { handleError } from '@/utils/error-handling'
import { useExternalLinkHelper } from '@/utils/external-link-composables'
import {
  CONFLUENCE_MACRO_MODULE_TYPE,
  EXTERNAL_LINK_HANDLER_SOURCES
} from '@/utils/external-link-helper'
import {
  appPlatformInjectionKey,
  listStateInjectionKey,
  tableStateInjectionKey
} from '@/utils/injection-keys'
import { memoizeOkrChildren } from '@/utils/memoizations'
import { NOTIFICATION_TYPES, showNotify } from '@/utils/notify'
import { OBJECTIVE_TYPES } from '@/utils/objective-types'
import {
  getExpandedItemList,
  flattenOkrElements,
  getExpandedKey,
  objectiveIsJiraTask
} from '@/utils/objectives'
import { FILTERS_KEYS, saveFilterValues } from '@/utils/okr-elements/filters'
import { removeSelection } from '@/utils/window'
import { JIRA_CLOUD_API } from '@jira/util'

import ObjectiveViewRowActions from '@/components/objectives/ObjectiveViewRowActions'
import ObjectiveTableRow from '@/components/objectives/table/ObjectiveTableRow'
import VirtualizationList from '@/components/VirtualizationList'

const objectivesApi = new ObjectivesApiHandler()

/*
 * grade:
 *  is editable when all conditions are met:
 *    - the objective is a Key Result
 *    - user has the permission to edit
 *    - the objective has no children that contribute to grade
 *  the value is hidden when:
 *    - there are
 */

const checkIsItemLast = ({ items, id }) => {
  return items[items.length - 1] === id
}

export default defineComponent({
  name: 'ObjectiveTableRowList',

  components: {
    VirtualizationList,
    ObjectiveTableRow,
    ObjectiveViewRowActions
  },

  inject: {
    listState: {
      from: listStateInjectionKey
    },

    tableState: {
      from: tableStateInjectionKey
    },

    appPlatform: {
      from: appPlatformInjectionKey
    }
  },

  props: {
    modelValue: {
      type: Array,
      required: true
    },

    // depth: {
    //   type: Number,
    //   default: 0
    // },

    draggable: {
      type: Boolean,
      default: true
    },

    hideTableRowActions: {
      type: Boolean
    },

    firstLevelDraggable: {
      type: Boolean
    },

    workspaceId: {
      type: [String, Number],
      required: true
    },

    tableRowGradeConfigurable: {
      type: Boolean,
      default: true
    },

    editExternal: {
      type: Boolean
    },

    disableSaveFilterValues: {
      type: Boolean
    },

    hideOkrElementRename: {
      type: Boolean
    }
  },

  emits: { 'update:modelValue': null, 'drag-end': null, 'drag-start': null, updated: null },

  setup() {
    const { handleExternalLink } = useExternalLinkHelper()

    return {
      handleExternalLink
    }
  },

  data() {
    return {
      childRefs: {},
      dragChangeElements: null,
      dragStartElement: null,
      activeDepth: null
    }
  },

  computed: {
    onboarding() {
      return this.$store?.state.pluginOptions.onboarding || false
    },

    getFlattedChildren() {
      const EMPTY_LIST = []

      if (!this.dragStartElement) return EMPTY_LIST

      const { item } = this.dragStartElement.from

      const isTaskChosen = objectiveIsJiraTask(item)

      if (isTaskChosen) {
        return this.getFlattedNestedTasksInDeep({ taskId: item.id })
      }

      const { childElements } = item
      const flattedChildren = memoizeOkrChildren(childElements || EMPTY_LIST, 0)
      const onlyChildTasks = flattedChildren.filter(item => objectiveIsJiraTask(item))

      const tasksAboveTasks = onlyChildTasks.reduce((acc, task) => {
        const nestedTasks = this.getFlattedNestedTasksInDeep({ taskId: task.id })
        return [...acc, ...nestedTasks]
      }, [])

      return [...flattedChildren, ...tasksAboveTasks]
    },

    dropIsPossible() {
      if (this.dragChangeElements && this.dragStartElement) {
        if (!this.dragChangeElements.from.item) return false
        const { parentId: parentIdFrom, id: idFrom } = this.dragChangeElements.from.item
        const { parentId: parentIdTo, id: idTo } = this.dragChangeElements.to.item

        const isRootLevel = this.modelValue.includes(idFrom) && this.modelValue.includes(idTo)

        if (
          (!parentIdFrom && !parentIdTo) || // we cant move in the different parentId
          isRootLevel // but 0 (root) level elements possible have different parentId, then we check if it contains in the modelValue, then we can move it
        ) {
          return true
        }

        if (this.dragStartElement.from.index === this.dragChangeElements.to.index) {
          // possible return to the same index (not change position)
          return true
        }

        return parentIdFrom === parentIdTo
      } else {
        return true
      }
    },

    items() {
      const rootElements = [...this.modelValue].map(id => ({
        ...this.listState.okrElements[id]
      }))

      return flattenOkrElements(rootElements, 0, {
        listState: this.listState,
        tableState: this.tableState
      })
    }

    // rowsAreDraggable() {
    //   return (
    //     this.draggable &&
    //     (this.firstLevelDraggable || this.depth > 0) &&
    //     this.modelValue.length > 1 &&
    //     this.tableState.mayBeDraggable
    //   )
    // }
  },

  beforeUpdate() {
    this.childRefs = {}
  },

  methods: {
    getExpandedKey,
    onUpdateShowActions(depth) {
      this.activeDepth = depth
    },

    isItemLast(item) {
      if (!this.onboarding) return false
      const { depth, id, parentId } = item
      if (!depth) {
        return checkIsItemLast({ items: this.modelValue, id })
      }

      const siblings = this.listState.okrElementChildren[parentId] || []
      return checkIsItemLast({ items: siblings, id })
    },

    isRowDraggable(item) {
      return (
        this.draggable &&
        (this.firstLevelDraggable || item.depth > 0) &&
        this.tableState.mayBeDraggable
      )
    },

    isRowExpanded({ item }) {
      const isTask = objectiveIsJiraTask(item)
      if (isTask) {
        return this.listState.expandedTasks[item.id] || false
      }

      return this.listState.filtersValues[FILTERS_KEYS.EXPANDED_ITEMS][getExpandedKey(item)]
    },

    getActiveChildrenStyles(item) {
      return this.getFlattedChildren.find(i => i.id === item.id)
        ? { visibility: 'hidden', height: 0 }
        : {}
    },

    getFlattedNestedTasksInDeep({ taskId }) {
      const nestedTasksIds = this.listState.tasksChildren[taskId] || []

      return nestedTasksIds.reduce((acc, id) => {
        const nestedTasks = this.getFlattedNestedTasksInDeep({ taskId: id })
        return [...acc, ...nestedTasks, { id }]
      }, [])
    },

    onDragChange(payload) {
      this.dragChangeElements = payload
    },

    onUpdateList(value) {
      const payload = value.filter(item => this.modelValue.includes(item))
      // on callback we get flatted list with all children, so we filter this list by root level with modelValue for rerender lists
      // in the modelValue we have only root level elements
      this.$emit('update:modelValue', payload)
    },

    moveObjective(element) {
      this.$refs.actions.moveOkrElement(element)
    },

    async onSortEnd($event) {
      this.onDragEnd($event)
      if (this.dropIsPossible) {
        await this.onDrop($event)
        this.onUpdateList($event.list.map(item => item.id)) // when sort ending send changed list
      } else {
        this.onUpdateList(this.modelValue)
      }

      await this.$nextTick()
      this.dragStartElement = null
      this.dragChangeElements = null
    },

    setChildRef(elementId, component) {
      if (component) {
        this.childRefs[elementId] = component
      }
    },

    addRow(parentObjective, payload) {
      this.$refs.actions.createNewOkrElement(parentObjective, payload)
    },

    editRow(objective, payload) {
      if (this.editExternal) {
        let source = EXTERNAL_LINK_HANDLER_SOURCES.JIRA_DASHBOARD_GADGET
        if (JIRA_CLOUD_API?._data?.options?.moduleType === CONFLUENCE_MACRO_MODULE_TYPE) {
          source = EXTERNAL_LINK_HANDLER_SOURCES.CONFLUENCE_MACRO
        }
        this.handleExternalLink({
          ...objective,
          source,
          appPlatform: this.appPlatform
        })
      } else {
        this.$refs.actions.editOkrElement(objective, payload)
      }
    },

    deleteRow(objective) {
      this.$refs.actions.deleteOkrElement(objective)
    },

    duplicateRow(objective) {
      this.$refs.actions.duplicateOkrElement(objective)
    },

    editRowWeights(objective) {
      this.$refs.actions.editOkrElementWeights(objective)
    },

    updateObjectives(parameters) {
      this.$emit('updated', parameters)
    },

    onDragStart(e) {
      this.dragStartElement = e
      removeSelection()
    },

    onDragEnd() {
      this.$nextTick(() => {
        removeSelection()
      })
    },

    async onDrop(dropResult) {
      // const { oldIndex, newIndex } = dropResult
      // if (newIndex === null || oldIndex === newIndex) {
      //   return
      // }
      // const { result, itemToAdd } = this.applyDrag(this.modelValue, oldIndex, newIndex)
      // this.$emit('update:modelValue', result)
      //
      // // wait for value update
      // await this.$nextTick()
      // this.onMoved(itemToAdd, oldIndex, newIndex)

      if (dropResult.changed) {
        const {
          from: {
            index: fromIndex,
            item: { id: fromId, orderValue: fromOrderValue }
          },
          to: {
            index: toIndex,
            item: { orderValue: toOrderValue }
          }
        } = dropResult
        await this.$nextTick()

        await this.onMoved({
          fromId,
          fromIndex,
          toIndex,
          toOrderValue,
          fromOrderValue
        })
      }
    },

    async onMoved({ fromId: id, fromIndex, toIndex, toOrderValue }) {
      const okrElement = this.listState.okrElements[id]

      let orderValue
      if (fromIndex < toIndex) {
        // move to bottom
        // const previousElementId = this.modelValue[newIndex - 1]
        // const previousElement = this.listState.okrElements[previousElementId]
        // const elBeforeOrderValue = previousElement.orderValue
        // orderValue = elBeforeOrderValue + 0.5

        orderValue = toOrderValue + 0.5
      } else {
        // move to top
        // const previousElementId = this.modelValue[newIndex + 1]
        // const previousElement = this.listState.okrElements[previousElementId]
        // const elBeforeOrderValue = previousElement.orderValue
        // orderValue = elBeforeOrderValue - 0.5

        orderValue = toOrderValue - 0.5
      }

      this.isChildLoading = true
      try {
        if (okrElement.typeId !== OBJECTIVE_TYPES.TASK) {
          await this.updateObjectiveIndex(okrElement, orderValue)
        } else {
          await this.updateTaskIndex(okrElement, orderValue)
        }
      } catch (error) {
        if (
          isString(error.response?.data) &&
          error.response.data.includes('is unavalable for the objective with parent from another')
        ) {
          showNotify({
            title: error.response?.data,
            type: NOTIFICATION_TYPES.WARNING
          })
        } else {
          handleError({ error })
        }
        // return item back
        // const { result } = this.applyDrag(this.modelValue, newIndex, oldIndex)
        // this.$emit('update:modelValue', result)

        this.onUpdateList(this.modelValue)
      }
      this.isChildLoading = false
    },

    async updateObjectiveIndex(objective, orderValue) {
      const payload = {
        elementId: objective.id,
        ...objective,
        orderValue
      }

      // errors are handled on level above
      const { orderChanged, element } = await objectivesApi.updateOkrElement(payload)
      this.saveOrderValues(orderChanged, element)
    },

    async updateTaskIndex(task, orderValue) {
      const payload = {
        ...task,
        elementId: task.id,
        orderValue
      }
      delete payload.id
      // errors are handled on level above
      const { orderChanged, element } = await objectivesApi.updateOkrElement(payload)
      this.saveOrderValues(orderChanged, element)
    },

    saveOrderValues(orderChangedElements, element) {
      const { parentId } = element
      const siblings = this.listState.okrElementChildren[parentId]

      orderChangedElements.forEach(item => {
        // in key which name is value we have new element orderValue
        const { elementId, value: newOrderValue } = item
        // check is required, because we get all updated elements, not only visible in table
        if (elementId in this.listState.okrElements) {
          this.listState.okrElements[elementId].orderValue = newOrderValue
        }

        if (parentId && siblings) {
          // search parent item in global list, change index (value its index from API) and save new element id
          // siblings is array of element ids like [1, 2, 3, 4, 5]
          // we set new element id in new index (newOrderValue) and save old element id in old index (orderValue)
          siblings[newOrderValue] = elementId // in compile its looks like siblings[1] = 'SOME ID'
        }
      })
    },

    onRowUpdated(eventData) {
      this.updateObjectives(eventData)
    },

    // returns array of expanded children(their ids as in listState.filtersValues[FILTERS_KEYS.EXPANDED_ITEMS])
    /** @public */
    getExpandedChildren() {
      return Object.values(this.childRefs).reduce(
        (accumulator, currentValue) => accumulator.concat(currentValue.getExpandedChildren()),
        []
      )
    },

    onRowExpandedChange(element, newValue) {
      const isTask = objectiveIsJiraTask(element)

      removeSelection()

      if (isTask) {
        this.listState.expandedTasks[element.id] = newValue
      } else {
        const { id: elementId, depth } = element

        const id = this.getExpandedKey({ id: elementId, depth })
        const filtersValues = this.listState.filtersValues
        const haveAllFiltersDefaultValues = this.listState.haveAllFiltersDefaultValues
        if (newValue) {
          filtersValues[FILTERS_KEYS.EXPANDED_ITEMS][id] = true

          if (
            !haveAllFiltersDefaultValues &&
            id in filtersValues[FILTERS_KEYS.FILTER_COLLAPSED_ITEMS]
          ) {
            filtersValues[FILTERS_KEYS.FILTER_COLLAPSED_ITEMS][id] = false
          }
        } else {
          const expandedItems = { ...filtersValues[FILTERS_KEYS.EXPANDED_ITEMS] }
          expandedItems[id] = false
          if (elementId in this.childRefs) {
            const flattedChildElements = memoizeOkrChildren(
              this.listState.okrElements[elementId].childElements,
              depth + 1
            )

            flattedChildElements.forEach(child => {
              const expandedKey = this.getExpandedKey(child)
              if (expandedKey in expandedItems && expandedItems[expandedKey]) {
                expandedItems[this.getExpandedKey(child)] = false
              }
            })
          }
          filtersValues[FILTERS_KEYS.EXPANDED_ITEMS] = { ...expandedItems }

          if (!haveAllFiltersDefaultValues) {
            filtersValues[FILTERS_KEYS.FILTER_COLLAPSED_ITEMS][id] = true
          }
        }
        if (!this.disableSaveFilterValues) {
          saveFilterValues(
            this.$router,
            this.$route,
            [FILTERS_KEYS.EXPANDED_ITEMS, FILTERS_KEYS.FILTER_COLLAPSED_ITEMS],
            [
              getExpandedItemList(filtersValues[FILTERS_KEYS.EXPANDED_ITEMS]),
              getExpandedItemList(filtersValues[FILTERS_KEYS.FILTER_COLLAPSED_ITEMS])
            ]
          )
        }
      }
    }
  }
})
</script>

<style lang="scss" scoped>
:deep(.sortable-chosen) {
  &.o-objective-nested {
    .o-objective-row {
    }

    & > .o-objective-rowgroup {
      & > .o-objective-row {
        .o-objective-row-drag {
          opacity: 1;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.o-objective-table {
  .otr-VirtualizationList-dropImpossible {
    .sortable-chosen {
      background: #feefef;
      box-shadow: inset 0 0 0 2px rgba(var(--grade-low-color-rgb-next), 0.2);

      .o-objective-row {
        background: rgba(var(--grade-low-color-rgb-next), 0.05);
      }

      .ac-Cell-rowHovered {
        background: transparent;
      }

      .otr-Objective_ChildCount {
        display: none;
      }
    }
  }

  .original-chosen {
    opacity: 0;
  }

  .sortable-chosen {
    opacity: 1 !important;

    .otr-Objective_ChildCount {
      display: block;
    }

    .otr-Row_Border {
      display: none;
    }
  }
}
</style>
