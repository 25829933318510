export const checkIsTextSelectionProceed = () => {
  const selection = window.getSelection()
  return selection.toString().length
}

export const doubleClickHandler = {
  doubleClickDelay: 300,
  clicksTimer: null,
  isFirstClick: false,
  /**
   * if apply to multiple elements in the same component, unexpected behavior may occur.
   * Need to check
   * @param { () => {} } handler Function that will be called on single click
   * @returns void
   */
  handleClick: function (handler) {
    this.isFirstClick = true

    if (this.isFirstClick) {
      this.clicksTimer = setTimeout(() => {
        this.isFirstClick = false
        handler()
      }, this.doubleClickDelay)

      return
    }

    this.destroy()
    this.isFirstClick = false
  },
  /**
   * manually clear timer
   * @returns void
   */
  destroy: function () {
    clearTimeout(this.clicksTimer)
  }
}
