<template>
  <BulkActionsOperationValueControl
    v-model="localModelValue"
    :empty-state-label="t('objective.select_group')"
    :field-id="CORE_FIELDS_IDS.GROUPS"
    :loading="isLoading"
    :options="options"
    :search-function="getGroups"
    item-label="name"
    item-value="id"
    @update:options="options = $event"
    @toggle-value-dropdown="emit('toggle-value-dropdown', $event)"
  />
</template>

<script setup>
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import GlobalGroupsApiHandler from '@/api/global-groups'
import { handleError } from '@/utils/error-handling'
import { createGetGroupsPayload } from '@/utils/okr-elements-forms-helpers'
import { CORE_FIELDS_IDS } from '@/utils/okr-elements-table-bulk-actions'

import BulkActionsOperationValueControl from '@/components/objectives/bulk-edit/BulkActionsOperationValueControl'

defineOptions({
  name: 'GroupsField'
})

const emit = defineEmits({
  'update:model-value': null,
  'toggle-value-dropdown': null
})

const props = defineProps({
  modelValue: {
    type: Object,
    required: true
  },

  workspaceId: {
    type: [String, Number],
    required: true
  }
})

const { t } = useI18n()

const options = ref([])

const isLoading = ref(false)

const getGroups = async (searchString = null) => {
  const api = new GlobalGroupsApiHandler()
  isLoading.value = true
  let result = []

  try {
    const payload = createGetGroupsPayload({
      selectedGroups: props.modelValue.value,
      searchString,
      workspaceId: props.workspaceId
    })

    result = await api.getGroupsForFilter(payload)
  } catch (error) {
    handleError({ error })
  } finally {
    isLoading.value = false
  }

  return result
}

onMounted(async () => {
  options.value = await getGroups()
})

const localModelValue = computed({
  get: () => {
    const { value, operation } = props.modelValue

    return {
      value,
      operation
    }
  },
  set: newValue => {
    emit('update:model-value', newValue)
  }
})
</script>

<style lang="scss" scoped></style>
