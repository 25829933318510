<template>
  <div class="cfp-Page">
    <PageContentHeader
      :level="3"
      :max-width="HEADER_SIZES.LG"
      :title="$t('custom_fields.title')"
      no-margin
      style="--padding-bottom: 24px; --align-items: flex-start"
    >
      <AppButton
        v-if="!isNoCustomFields"
        v-tippy="{
          content: isCustomFieldsLimitExceeded
            ? customFieldsLimitations.getDisabledButtonTooltip()
            : null
        }"
        :data-testid="CUSTOM_FIELDS_PAGE_CREATE_BUTTON_TEST_ID"
        :disable="isCustomFieldsLimitExceeded"
        height="24"
        icon="plus-next"
        type="primary-next"
        width="24"
        @click="showCustomFieldModal"
      >
        {{ $t('custom_fields.create_field') }}
      </AppButton>
    </PageContentHeader>

    <SettingsPagesEmptyState
      v-if="isNoCustomFields"
      :data-testid="EMPTY_STATE_TEST_ID"
      hero-height="128"
      hero-icon="no-custom-fields-hero"
      hero-width="142"
    >
      <template #title> {{ $t('custom_fields.no_fields.title') }} </template>
      <template #subtitle> {{ $t('custom_fields.fields.subtitle') }} </template>

      <template #action>
        <AppButton
          :data-testid="CUSTOM_FIELDS_EMPTY_STATE_CREATE_BUTTON_TEST_ID"
          height="24"
          icon="plus-next"
          type="primary-next"
          width="24"
          @click="showCustomFieldModal"
        >
          {{ $t('custom_fields.create_field') }}
        </AppButton>
      </template>
    </SettingsPagesEmptyState>

    <div v-else class="cfp-Page_Content">
      <CustomFieldsTable
        v-if="!isNoContentFields"
        :data="contentCustomFields"
        :data-testid="CONTENT_FIELDS_TABLE_TEST_ID"
        :loading="isLoading"
        :show-footer-button="isShowTableFooterButton && isNoSidebarFields"
        :show-limit-message="isCustomFieldsLimitExceeded && isNoSidebarFields && !isLoading"
        :status-cells-loading="customFieldsStatusCellsLoading"
        @drop="onDrop({ ...$event })"
        @create-custom-field="showCustomFieldModal"
        @edit-custom-field="onEditCustomField"
        @on-menu-actions-click="
          onMenuActionsClick({ ...$event, placement: CUSTOM_FIELDS_PLACEMENTS.CONTENT })
        "
        @update:data="contentCustomFields = $event"
      >
        <template #header-cell="{ column }">
          <template v-if="column.key === TABLE_COLUMNS_KEYS.CONTENT_AREA">
            {{ $t('custom_fields.content_area') }}
          </template>
        </template>
      </CustomFieldsTable>

      <CustomFieldsTable
        v-if="!isNoSidebarFields"
        :data="sidebarCustomFields"
        :data-testid="SIDEBAR_FIELDS_TABLE_TEST_ID"
        :loading="isLoading"
        :show-footer-button="isShowTableFooterButton"
        :show-limit-message="isCustomFieldsLimitExceeded && !isLoading"
        :status-cells-loading="customFieldsStatusCellsLoading"
        @drop="onDrop({ ...$event, placement: CUSTOM_FIELDS_PLACEMENTS.SIDEBAR })"
        @create-custom-field="showCustomFieldModal"
        @edit-custom-field="onEditCustomField"
        @on-menu-actions-click="
          onMenuActionsClick({ ...$event, placement: CUSTOM_FIELDS_PLACEMENTS.SIDEBAR })
        "
        @update:data="sidebarCustomFields = $event"
      >
        <template #header-cell="{ column }">
          <template v-if="column.key === TABLE_COLUMNS_KEYS.CONTENT_AREA">
            {{ $t('custom_fields.right_panel') }}
          </template>
        </template>
      </CustomFieldsTable>

      <VisualPresentation
        :content-fields="contentCustomFields"
        :data-testid="VISUAL_PRESENTATION_TEST_ID"
        :sidebar-fields="sidebarCustomFields"
        @drop="onDrop($event)"
        @edit-custom-field="onEditCustomField"
      />
    </div>
  </div>

  <portal to="modal-windows">
    <CustomFieldModal
      :data-testid="CUSTOM_FIELDS_MODAL_TEST_ID"
      :edit-field-data="fieldForEdit"
      :opened="isShowCustomFieldModal"
      @on-close="closeCustomFieldModal"
      @reload-fields="onReloadFields"
    />

    <CustomFieldDeleteModal
      :data-testid="CUSTOM_FIELDS_DELETE_MODAL_TEST_ID"
      :field-to-delete="fieldToDelete"
      :show="isShowDeleteFieldModal"
      @close="onDeleteCustomFieldModalClose"
      @on-field-deleted="onFieldDeleted"
    />
  </portal>
</template>
<script>
// eslint-disable-next-line import/order
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CustomFieldsLayout'
})
</script>

<script setup>
// eslint-disable-next-line import/order
import { cloneDeep, isEmpty } from 'lodash'

import { computed, nextTick, onMounted, ref } from 'vue'
import { useStore } from 'vuex'

import CustomFieldsApiHandler from '@/api/custom-fields'
import { tracker } from '@/tracking/amplitude'
import { EVENT_CATEGORIES } from '@/tracking/amplitude-helpers'
import { ACTIONS_KEYS } from '@/utils/actions-keys'
import { HEADER_SIZES } from '@/utils/components-configurations/page-content-header'
import { CUSTOM_FIELDS_PLACEMENTS } from '@/utils/custom-fields/factory'
import {
  CONTENT_FIELDS_TABLE_TEST_ID,
  CUSTOM_FIELDS_DELETE_MODAL_TEST_ID,
  CUSTOM_FIELDS_EMPTY_STATE_CREATE_BUTTON_TEST_ID,
  CUSTOM_FIELDS_MODAL_TEST_ID,
  CUSTOM_FIELDS_PAGE_CREATE_BUTTON_TEST_ID,
  EMPTY_STATE_TEST_ID,
  SIDEBAR_FIELDS_TABLE_TEST_ID,
  VISUAL_PRESENTATION_TEST_ID
} from '@/utils/custom-fields/jest-helpers'
import { CUSTOM_FIELD_ENTITY_KEYS } from '@/utils/entity-keys'
import { handleError } from '@/utils/error-handling'
import { TABLE_COLUMNS_KEYS } from '@/utils/table-columns'
import { usePlansLimitations } from '@/utils/web-app/plans-limitations'

import CustomFieldDeleteModal from '@/components/custom-fields/CustomFieldDeleteModal'
import CustomFieldModal from '@/components/custom-fields/CustomFieldModal'
import CustomFieldsTable from '@/components/custom-fields/CustomFieldsTable'
import VisualPresentation from '@/components/custom-fields/VisualPrenestation'
import AppButton from '@/components/ui/AppButton/AppButton'
import PageContentHeader from '@/components/ui/PageContentHeader/PageContentHeader'
import SettingsPagesEmptyState from '@/components/ui/SettingsPagesEmptyState/SettingsPagesEmptyState'

const { EDIT, DELETE, ACTIVATE, INACTIVATE } = ACTIONS_KEYS

const contentCustomFields = ref([])

const sidebarCustomFields = ref([])

const { customFieldsLimitations } = usePlansLimitations()

const applyReorderedCustomFields = ({ reorderedCustomFields, placement }) => {
  const target =
    placement === CUSTOM_FIELDS_PLACEMENTS.CONTENT
      ? contentCustomFields.value
      : sidebarCustomFields.value

  reorderedCustomFields.forEach(({ id, orderValue }) => {
    const targetField = target.find(field => field[CUSTOM_FIELD_ENTITY_KEYS.ID] === id)

    if (targetField) {
      targetField[CUSTOM_FIELD_ENTITY_KEYS.ORDER_VALUE] = orderValue
    }
  })

  target.sort(
    (a, b) => a[CUSTOM_FIELD_ENTITY_KEYS.ORDER_VALUE] - b[CUSTOM_FIELD_ENTITY_KEYS.ORDER_VALUE]
  )
}
const onDrop = async ({ item, placement = CUSTOM_FIELDS_PLACEMENTS.CONTENT, orderValue }) => {
  const api = new CustomFieldsApiHandler()

  try {
    const { reorderedCustomFields } = await api.updateCustomFieldOrder({
      id: item.id,
      orderValue
    })
    applyReorderedCustomFields({ reorderedCustomFields, placement })
  } catch (error) {
    handleError({ error })
    await getCustomFields(true)
  }
}

const isLoading = ref(false)
const getCustomFields = async (reloadAll = false) => {
  const api = new CustomFieldsApiHandler()
  const snapshot = {}

  if (reloadAll) {
    snapshot.contentCustomFields = cloneDeep(contentCustomFields.value)
    snapshot.sidebarCustomFields = cloneDeep(sidebarCustomFields.value)
    contentCustomFields.value = []
    sidebarCustomFields.value = []
  }
  try {
    isLoading.value = true
    const { contentFields, sidebarFields } = await api.getCustomFields()
    contentCustomFields.value = contentFields
    sidebarCustomFields.value = sidebarFields
  } catch (error) {
    if (reloadAll) {
      contentCustomFields.value = snapshot.contentCustomFields
      sidebarCustomFields.value = snapshot.sidebarCustomFields
    }
    handleError({ error })
  } finally {
    isLoading.value = false
  }
}

const onReloadFields = () => {
  Promise.all([getCustomFields(true), store.dispatch('customFields/getAllCustomFields')])
}

const isNoContentFields = computed(() => {
  return !isLoading.value && isEmpty(contentCustomFields.value)
})

const isNoSidebarFields = computed(() => {
  return !isLoading.value && isEmpty(sidebarCustomFields.value)
})

const isNoCustomFields = computed(() => {
  return isNoSidebarFields.value && isNoContentFields.value
})

const isShowCustomFieldModal = ref(false)
const showCustomFieldModal = () => {
  if (fieldForEdit.value) {
    isShowCustomFieldModal.value = true
  } else if (!isCustomFieldsLimitExceeded.value) {
    isShowCustomFieldModal.value = true
  }
}

const closeCustomFieldModal = () => {
  isShowCustomFieldModal.value = false
  fieldForEdit.value = null
}

const fieldForEdit = ref(null)
const onEditCustomField = customField => {
  fieldForEdit.value = customField
  showCustomFieldModal()
}

const customFieldsStatusCellsLoading = ref({})

const store = useStore()
const onUpdateCustomFieldActiveStatus = async (item, placement) => {
  const { active } = item
  const newValue = !active

  const api = new CustomFieldsApiHandler()

  try {
    customFieldsStatusCellsLoading.value[item.id] = true
    await api.updateCustomFieldActiveStatus({
      fieldId: item.id,
      newValue
    })

    await store.dispatch('customFields/updateCustomField', {
      id: item.id,
      active: newValue
    })

    const target =
      placement === CUSTOM_FIELDS_PLACEMENTS.CONTENT
        ? contentCustomFields.value
        : sidebarCustomFields.value

    const updatedField = target.find(
      field => field[CUSTOM_FIELD_ENTITY_KEYS.ID] === item[CUSTOM_FIELD_ENTITY_KEYS.ID]
    )
    if (updatedField) {
      updatedField[CUSTOM_FIELD_ENTITY_KEYS.ACTIVE] = newValue
    }

    const eventName = newValue ? 'custom field activated' : 'custom field inactivated'

    tracker.logEvent(eventName, {
      category: EVENT_CATEGORIES.SETTINGS,
      id: item.id,
      label: item.name
    })
  } catch (error) {
    handleError({ error })
  } finally {
    customFieldsStatusCellsLoading.value[item.id] = false
  }
}

const isShowDeleteFieldModal = ref(false)
const fieldToDelete = ref({})

const onDeleteCustomField = async item => {
  fieldToDelete.value = item
  await nextTick()
  isShowDeleteFieldModal.value = true
}

const onDeleteCustomFieldModalClose = () => {
  isShowDeleteFieldModal.value = false
  fieldToDelete.value = {}
}

const onFieldDeleted = () => {
  getCustomFields(true)
  store.dispatch('customFields/deleteCustomField', {
    id: fieldToDelete.value.id
  })
  onDeleteCustomFieldModalClose()
}

const onMenuActionsClick = ({ action, item, placement } = {}) => {
  const [method] = [
    action === EDIT && onEditCustomField,
    action === ACTIVATE && onUpdateCustomFieldActiveStatus,
    action === INACTIVATE && onUpdateCustomFieldActiveStatus,
    action === DELETE && onDeleteCustomField
  ].filter(Boolean)

  if (method) {
    method(item, placement)
  }
}

onMounted(() => {
  getCustomFields()
})

const isCustomFieldsLimitExceeded = computed(() => {
  const contentCustomFieldsCount = contentCustomFields.value.length
  const sidebarCustomFieldsCount = sidebarCustomFields.value.length

  return customFieldsLimitations.value.isLimitExceeded({
    count: contentCustomFieldsCount + sidebarCustomFieldsCount
  })
})

const isShowTableFooterButton = computed(() => {
  return !isCustomFieldsLimitExceeded.value && !isLoading.value
})
</script>

<style lang="scss" scoped>
.cfp-Page_Content {
  padding: 0 $page-right-padding 0 $page-left-padding;
  display: grid;
  gap: 32px;
  width: 100%;
  max-width: $page-width-lg;
}
</style>
