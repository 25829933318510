<template>
  <div ref="wrapperReference" class="sua-Wrapper">
    <AppAvatar
      v-for="item in resolvedUsers"
      :key="item.accountId"
      v-tippy="{
        content: item[labelKey],
        placement: 'top',
        theme: DROP_LIST_THEMES.COMMON_TOOLTIP_THEMES
      }"
      :avatar-url="item.avatarUrl"
      :size="avatarSize"
      class="sua-Avatar"
      no-margins
    />

    <div
      v-if="showMoreCount"
      v-tippy="{
        content: otherNames,
        placement: 'top',
        theme: DROP_LIST_THEMES.COMMON_TOOLTIP_THEMES
      }"
      class="sua-MoreUsers"
    >
      +{{ showMoreCount }}
    </div>
  </div>
</template>

<script setup>
import { onBeforeUnmount, onMounted, ref, computed } from 'vue'

import { AVATAR_SIZES, AVATAR_DIMENSIONS } from '@/utils/components-configurations/app-avatar'
import { DROP_LIST_THEMES } from '@/utils/components-configurations/app-droplist'

import AppAvatar from '@/components/ui/AppAvatar/AppAvatar'

defineOptions({
  name: 'SelectedUsersAvatarsList'
})

const props = defineProps({
  users: {
    type: Array,
    required: true
  },

  avatarSize: {
    type: String,
    default: AVATAR_SIZES.MD
  },

  labelKey: {
    type: String,
    default: 'name'
  }
})

const wrapperReference = ref(null)
const width = ref(0)
const resizeObserver = ref(null)

const initResizeObserver = () => {
  resizeObserver.value = new ResizeObserver(entries => {
    entries.forEach(entry => {
      width.value = entry.contentRect.width
    })
  })

  resizeObserver.value.observe(wrapperReference.value)
}

const destroyResizeObserver = () => {
  if (resizeObserver.value) {
    resizeObserver.value.disconnect()
    resizeObserver.value = null
  }
}

onMounted(() => {
  initResizeObserver()
})

onBeforeUnmount(() => {
  destroyResizeObserver()
})

const AVATAR_SHIFT = 4

const avatarSizeValue = computed(() => {
  return AVATAR_DIMENSIONS[props.avatarSize]
})

const visibleAvatarsCount = computed(() => {
  return Math.floor(width.value / (avatarSizeValue.value - AVATAR_SHIFT))
})

// const showMoreCount = computed(() => {
//   return props.users.length > visibleAvatarsCount.value
// })

const showMoreCount = computed(() => {
  return props.users.length > visibleAvatarsCount.value
    ? props.users.length - visibleAvatarsCount.value + 1
    : 0
})

const resolvedUsers = computed(() => {
  const sliceCount = showMoreCount.value ? visibleAvatarsCount.value - 1 : visibleAvatarsCount.value
  return props.users.slice(0, sliceCount)
})

const otherNames = computed(() => {
  const visibleUsers = resolvedUsers.value.map(({ accountId }) => accountId)

  return props.users
    .filter(({ accountId }) => !visibleUsers.includes(accountId))
    .map(user => user[props.labelKey])
    .join(', ')
})
</script>

<style lang="scss" scoped>
.sua-Wrapper {
  width: 100%;
  justify-content: flex-end;
  display: flex;
  align-items: center;
  font-family: $system-ui;
}

.sua-Avatar {
  margin-left: calc(v-bind(AVATAR_SHIFT) * -1px);
}

.sua-MoreUsers {
  $size: calc(v-bind(avatarSizeValue) * 1px);
  margin-left: calc(v-bind(AVATAR_SHIFT) * -1px);
  z-index: 1;
  width: $size;
  height: $size;
  border-radius: 50%;
  background-color: $dark-3;
  color: $white;
  font-style: normal;
  font-weight: fw('semi-bold');
  font-size: $fs-12;
  line-height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
