<template>
  <div v-if="items.length" class="cl-CommentItems">
    <CommentItem
      v-for="(comment, commentIndex) in items"
      :key="comment.id"
      :can-be-replied="canBeReplied"
      :hide-actions="hideActions"
      :is-last="commentIndex === items.length - 1"
      :is-reply-comment="isReplyComment"
      :item="comment"
      :objective="objective"
      :parent-reply-form-open="parentReplyFormOpen"
      :tracking-source="EVENT_SOURCES.FORM"
      :user-data="userData"
      @remove="emit('remove', $event)"
      @reply="emit('reply', $event)"
      @update="emit('update', $event)"
      @create-reply="emit('update-from-data', $event)"
      @update-reply="emit('update', $event)"
    />
  </div>

  <InfiniteScrollLoader
    v-if="infiniteLoading"
    :identifier="infiniteId"
    @infinite="emit('infinite-load', $event)"
  >
    <template #loader>
      <slot name="loading">
        <CommentsListLoader />
      </slot>
    </template>
    <template #no-results>
      {{ '' }}
    </template>
  </InfiniteScrollLoader>
</template>

<script setup>
import { ref } from 'vue'

import { EVENT_SOURCES } from '@/tracking/amplitude-helpers'

import CommentItem from '@/components/objectives/forms/objective-form/CommentItem'
import InfiniteScrollLoader from '@/components/ui/InfiniteScrollLoader/InfiniteScrollLoader'
// eslint-disable-next-line import/order
import CommentsListLoader from '@/components/ui/SkeletonLoaders/CommentsListLoader'

defineOptions({
  name: 'CommentList'
})

defineProps({
  items: {
    type: Array,
    required: true
  },

  infiniteLoading: {
    type: Boolean
  },

  userData: {
    type: Object,
    required: true
  },

  objective: {
    type: Object,
    required: true
  },

  canBeReplied: {
    type: Boolean
  },

  hideActions: {
    type: Boolean
  },

  isReplyComment: {
    type: Boolean
  },

  parentReplyFormOpen: {
    type: Boolean
  }
})

const emit = defineEmits({
  reply: null,
  remove: null,
  update: null,
  'update-from-data': null,
  'infinite-load': null
})
const infiniteId = ref(+new Date())

const updateItems = () => {
  infiniteId.value += 1
}

defineExpose({
  updateItems
})
</script>

<style lang="scss" scoped>
.cl-CommentItems {
  display: flex;
  flex-direction: column;
  gap: 24px;
  &:deep(.ci-CommentItem) {
    //margin: 0 0 8px 0;
  }
  &:deep(.ci-Replies .ci-CommentItem) {
    //margin: 0 0 8px 0;
  }
  &:deep(.ci-Replies .cf-CommentForm) {
    //margin: 8px 0 0 0;
  }
}
</style>
