<template>
  <DashboardBoxesLoader :count="7" />
  <DashboardTableLoader />
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'DashboardGroupsReportLoader',
  inheritAttrs: false
})
</script>

<script setup>
import DashboardBoxesLoader from '@/components/ui/SkeletonLoaders/DashboardBoxesLoader'
import DashboardTableLoader from '@/components/ui/SkeletonLoaders/DashboardTableLoader'
</script>

<style lang="scss" scoped></style>
