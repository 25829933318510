<template>
  <div
    :class="{
      'cfnc-Cell-clickable': clickable
    }"
    class="cfnc-Cell"
    @click="onNameClick"
  >
    <AppIcon
      :icon-name="`cf-${currentCustomFieldOptions[CUSTOM_FIELD_ENTITY_KEYS.ICON]}`"
      class="cfnc-Cell_Icon"
      height="24"
      width="24"
    />
    <span class="cfnc-Cell_Label oboard-truncated-text">
      <slot>
        {{ field[CUSTOM_FIELD_ENTITY_KEYS.NAME] }} ({{
          $t(currentCustomFieldOptions[CUSTOM_FIELD_ENTITY_KEYS.DISPLAY_NAME])
        }})
      </slot>
    </span>
  </div>
</template>

<script>
// eslint-disable-next-line import/order
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CustomFieldNameCell'
})
</script>

<script setup>
import { computed } from 'vue'

import { ALL_CUSTOM_FIELDS } from '@/utils/custom-fields/factory'
import { CUSTOM_FIELD_ENTITY_KEYS } from '@/utils/entity-keys'

import AppIcon from '@/components/ui/AppIcon/AppIcon'

const props = defineProps({
  field: {
    type: Object,
    required: true
  },

  clickable: {
    type: Boolean,
    default: true
  }
})

const currentCustomFieldOptions = computed(() => {
  return ALL_CUSTOM_FIELDS.getFieldOptionsByTypeId(props.field[CUSTOM_FIELD_ENTITY_KEYS.TYPE_ID])
})

const emit = defineEmits({
  'on-name-click': null
})
const onNameClick = () => {
  if (props.clickable) {
    emit('on-name-click')
  }
}
</script>

<style lang="scss" scoped>
.cfnc-Cell {
  display: flex;
  align-items: center;
  gap: 8px;
  padding-right: 8px;

  font-size: $fs-14;
  line-height: 20px;
  font-weight: fw('semi-bold');
  width: fit-content;
  max-width: 100%;

  &-clickable {
    cursor: pointer;
    color: $primary-color-next;

    @media (any-hover: hover) {
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &:not(&-clickable) {
    color: $dark-2;
  }
}

.cfnc-Cell_Icon {
  flex: 0 0 24px;
}
</style>
