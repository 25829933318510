<template>
  <AppModalWithConfirmation
    :confirm-close="areDataChanged"
    :show="show"
    :title="modalTitle"
    class="lpg-Modal"
    hide-hero
    @on-close="onClose"
  >
    <FormFieldNext :label="fieldLabel" class="lpg-FormField">
      <div class="lpg-SelectField">
        <AppSelect
          v-model="selectedGroups"
          :disabled-items="disabledItems"
          :is-error="isGroupsFieldEmpty"
          :loading="isSearchLoading"
          :offset="[0, '-100%']"
          :options="platformGroups"
          :placeholder="$t('placeholder.search')"
          :search-function="getPlatformGroups"
          append-to=".lpg-SelectField"
          boundary="scrollParent"
          class="lpg-Select"
          content-class="lpg-SelectContent"
          data-testid="link-platform-groups-modal-groups-field-select"
          dropdown-search
          item-label="name"
          item-value="groupId"
          multi
          show-all-selected
          show-selected-options-inside
          skeleton-loader
          @open="isGroupsFieldEmpty = false"
          @update:options="platformGroups = $event"
        >
          <template #option="optionProps">
            <AppSelectListItem
              :class="{
                'lpg-SelectOption-alreadyAdded': optionProps.option.alreadyAdded
              }"
              class="lpg-SelectOption"
              v-bind="optionProps"
            >
              <template v-if="optionProps.option.alreadyAdded" #checkmark>
                <AppCheckbox
                  v-model="optionProps.option.alreadyAdded"
                  :size="CHECKBOX_SIZES.SM"
                  :value="true"
                  disabled
                />
              </template>
              <template #option-label>
                <span class="lpg-OptionLabel">
                  <span class="lpg-OptionLabel_Text">
                    {{ optionProps.label }}
                  </span>

                  <span v-if="optionProps.option.alreadyAdded" class="lpg-OptionLabel_Badge">
                    {{ $t('badge.already_linked') }}
                  </span>
                </span>
              </template>
            </AppSelectListItem>
          </template>

          <template #button-content="{ options }">
            <span v-if="isEmpty(options)" class="lpg-SelectEmptyLabel">
              {{ fieldLabel }}
            </span>
          </template>
        </AppSelect>
      </div>
      <AppFieldError
        v-if="isGroupsFieldEmpty"
        :show="isGroupsFieldEmpty"
        class="lpg-FieldError"
        data-testid="groups-field-error"
      >
        {{ $t('field.required') }}
      </AppFieldError>
    </FormFieldNext>
    <template #footer-actions>
      <AppButton type="ghost-next" @click="onClose">
        {{ $t('action.cancel') }}
      </AppButton>

      <AppButton
        :disable="isLoading"
        :loading="isLoading"
        data-testid="link-platform-groups-modal-submit-button"
        type="primary-next"
        @click="linkGroups"
      >
        {{
          $t('action.link_platform_groups', {
            platform: platformName
          })
        }}
      </AppButton>
    </template>
  </AppModalWithConfirmation>
</template>

<script setup>
import { isEmpty, uniq } from 'lodash'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

import GlobalGroupsApiHandler from '@/api/global-groups'
import PlatformApiHandler from '@/api/platform'
import { TRACKING_UNKNOWN, trackLinkJiraGroupsEvent } from '@/tracking/amplitude-helpers'
import { CHECKBOX_SIZES } from '@/utils/components-configurations/app-checkbox'
import { handleError } from '@/utils/error-handling'

import AppModalWithConfirmation from '@/components/AppModalWithConfirmation'
import AppFieldError from '@/components/form/AppFieldError'
import FormFieldNext from '@/components/form/FormFieldNext'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppCheckbox from '@/components/ui/AppCheckbox/AppCheckbox'
import AppSelect from '@/components/ui/AppSelect/AppSelect'
import AppSelectListItem from '@/components/ui/AppSelect/AppSelectListItem'

defineOptions({
  name: 'LinkPlatformGroupsModal'
})

const props = defineProps({
  show: {
    type: Boolean
  },

  group: {
    type: Object,
    required: true
  },

  trackingSource: {
    type: String,
    default: TRACKING_UNKNOWN
  }
})

const isSearchLoading = ref(false)
const disabledItems = ref([])
const getPlatformGroups = async (searchString = null) => {
  const api = new PlatformApiHandler()

  isSearchLoading.value = true

  let platformGroupsList = []

  try {
    platformGroupsList = await api.getPlatformGroups({
      searchString
    })
  } catch (error) {
    handleError({ error })
  } finally {
    isSearchLoading.value = false
  }

  const alreadyLinkedGroupIds = props.group.linkedGroups.map(group => group.groupId) || []

  let normalizedList = []

  if (!isEmpty(platformGroupsList)) {
    normalizedList = platformGroupsList
      .map(group => {
        return {
          ...group,
          alreadyAdded: alreadyLinkedGroupIds.includes(group.groupId)
        }
      })
      .sort((a, b) => {
        return a.alreadyAdded - b.alreadyAdded
      })
  }

  normalizedList.forEach(group => {
    if (group.alreadyAdded) {
      disabledItems.value = uniq([...disabledItems.value, group.groupId])
    }
  })

  return normalizedList
}

const areDataChanged = computed(() => {
  return !isEmpty(selectedGroups.value)
})

const isGroupsFieldEmpty = ref(false)

const platformGroups = ref([])
const selectedGroups = ref([])

const emit = defineEmits({
  close: null,
  'on-groups-linked': null
})

const onClose = () => {
  emit('close')
}

const { t } = useI18n()

const platformName = computed(() => {
  return t('app.platform.jira')
})

const modalTitle = computed(() => {
  return t('dropdown.link_new_platform_groups', { platform: platformName.value })
})

const fieldLabel = computed(() => {
  return t('workspaces.select_platform_groups', {
    platform: platformName.value
  })
})

const store = useStore()

const userRoleForTracking = computed(() => {
  return store.getters['system/userRoleForTracking']
})
const isLoading = ref(false)

const linkGroups = async () => {
  if (isEmpty(selectedGroups.value)) {
    isGroupsFieldEmpty.value = true
    return
  }

  const api = new GlobalGroupsApiHandler()

  isLoading.value = true
  try {
    await api.linkPlatformGroupsToGlobalGroup({
      groupId: props.group.groupId || props.group.id,
      platformGroupIds: selectedGroups.value
    })

    trackLinkJiraGroupsEvent({
      role: userRoleForTracking.value,
      source: props.trackingSource
    })

    emit('on-groups-linked', props.group.groupId || props.group.id)
  } catch (error) {
    handleError({ error })
  } finally {
    isLoading.value = false
  }
}

const setDefaultState = () => {
  selectedGroups.value = []
  disabledItems.value = []
  isGroupsFieldEmpty.value = false
  isLoading.value = false
  isSearchLoading.value = false
  platformGroups.value = []
}

watch(
  () => props.show,
  async newValue => {
    if (newValue) {
      platformGroups.value = await getPlatformGroups()
    } else {
      setDefaultState()
    }
  },
  {
    immediate: true
  }
)
</script>

<style lang="scss" scoped>
:deep(.lpg-SelectContent) {
  .o-checkbox-label-inner,
  .o-checkbox-label-text {
    width: 100%;
  }

  .o-checkbox-disabled {
    opacity: 1;
  }
}

.lpg-SelectOption {
  --checkmark-color: #{$grey-1-next};

  &-alreadyAdded {
    :deep(.o-checkbox-label-text) {
      padding-left: 12px;
    }
  }
}

.lpg-SelectField {
  position: relative;
}

.lpg-OptionLabel {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
}
.lpg-OptionLabel_Text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.lpg-OptionLabel_Badge {
  font-style: normal;
  font-weight: fw('regular');
  font-size: $fs-12;
  line-height: 16px;
  color: $grey-1-next;
  margin-left: auto;
  flex: 0 1 auto;
}

.lpg-FormField {
  --gap: 0;
  --label-bottom-offset: 6px;
}

.lpg-FieldError {
  line-height: 20px;
}

.lpg-SelectEmptyLabel {
  padding-left: 2px;
}

.lpg-Select {
  :deep(.as-AppDroplistButton_Content) {
    padding: 6px 0 6px 6px;
  }
}
</style>
