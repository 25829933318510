<template>
  <AppInput
    :data-auto-testid="$attrs['data-auto-testid'] || null"
    :data-testid="$attrs['data-testid'] || null"
    :debounce="500"
    :max-length="512"
    :model-value="modelValue"
    :placeholder="placeholder"
    :style="{ width: `${width}px` }"
    :width="width"
    clearable
    icon="search-next"
    icon-height="24"
    icon-width="24"
    size="xmd"
    style-type="primary"
    @blur="onBlur"
    @update:model-value="$emit('update:modelValue', $event)"
  />
</template>

<script>
import { defineComponent } from 'vue'

import i18n from '@/i18n'

import AppInput from '@/components/ui/AppInput/AppInput'

export default defineComponent({
  name: 'AppSearch',

  components: {
    AppInput
  },

  props: {
    modelValue: {
      type: String,
      default: ''
    },

    placeholder: {
      type: String,
      default: () => i18n.global.t('placeholder.search')
    },

    width: {
      type: [String, Number],
      default: 200
    }
  },

  emits: { 'update:modelValue': null },

  methods: {
    onBlur(inputValue) {
      if (inputValue && inputValue !== this.modelValue) {
        this.$emit('update:modelValue', inputValue)
      }
    }
  }
})
</script>

<style lang="scss"></style>
