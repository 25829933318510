<template>
  <DateFilter
    :model-value="filterValue"
    :popup-style="DATE_FILTER_POPUP_STYLE"
    append-icon-height="20"
    append-icon-width="20"
    append-to=".ot-AdditionalFiltersContent_Filters"
    no-left-padding
    @update:model-value="onDateSelect"
  >
    <template #button="{ active, option, displayedDates }">
      <OkrFormFieldSelectTriggerNext
        :empty-state-label="emptyStateLabel"
        :label="fieldName"
        :opened="active"
        :selected-options="option.label"
        separated-label
        type="secondary"
      >
        <template #values>
          <div class="cfdf-SelectButton_Value">
            <div v-if="displayedDates" class="cfdf-SelectButton_ValueText">
              <i18n-t keypath="date.date_with_word_break" scope="global">
                <template #start>{{ displayedDates.start }}</template>
                <template #end>{{ displayedDates.end }}</template>
                <template #break><br /></template>
              </i18n-t>
            </div>
            <span v-else>{{ $t('filter.show_all') }}</span>
          </div>
        </template>
      </OkrFormFieldSelectTriggerNext>
    </template>
  </DateFilter>
</template>

<script setup>
defineOptions({
  name: 'CustomFieldDateFilter',
  inheritAttrs: false
})
import { useCustomFieldsHelpers } from '@/utils/custom-fields/use-custom-fields'
import { DATE_FILTER_POPUP_STYLE } from '@/utils/date'

import DateFilter from '@/components/objectives/filter-field/DateFilter'
import OkrFormFieldSelectTriggerNext from '@/components/ui/AppSelect/TriggerButtons/OkrFormFieldSelectTriggerNext'

const props = defineProps({
  fieldId: {
    type: [String, Number],
    required: true
  },

  filterValue: {
    required: true,
    validator: v => {
      return v === null || (Array.isArray(v) && v.length === 2)
    }
  }
})

const { fieldName, emptyStateLabel } = useCustomFieldsHelpers({
  fieldId: props.fieldId
})

const emit = defineEmits({
  'update-filter-value': null
})

const onDateSelect = value => {
  emit('update-filter-value', {
    value,
    fieldId: props.fieldId
  })
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/filters';

.cfdf-SelectButton_Value {
  @extend %select-button-value;
}

.cfdf-SelectButton_ValueText {
  @extend %select-button-value-text;
}
</style>
