<template>
  <div :class="wrapperClasses">
    <div v-if="groupLabel" class="arg-GroupLabel">
      {{ groupLabel }}
    </div>

    <div :class="optionsClasses">
      <div
        v-for="option in options"
        :key="option.value"
        :class="{ 'arg-Item-disabled': option.disabled }"
        :style="{ '--divider-color': option.color }"
        class="arg-Item"
      >
        <input
          :id="`input-${uid}-${option.value}`"
          v-model="getValue"
          :data-testid="`arg-input-${uid}-${option.value}`"
          :name="name"
          :value="option.value"
          class="arg-OptionInput"
          hidden
          type="radio"
        />
        <label
          :id="`option-${uid}-${option.value}`"
          :for="`input-${uid}-${option.value}`"
          :style="{ '--bg': option.color }"
          class="arg-Option"
        >
          <AppTippy
            v-if="option.tooltip"
            :arrow="showTooltipArrow"
            :offset="[0, showTooltipArrow ? 14 : 8]"
            :theme="tippyTheme"
            :to="`#option-${uid}-${option.value}`"
            placement="top"
          >
            <template #content>
              <i18n-t
                :keypath="option.tooltip"
                class="arg-Option_TooltipContent"
                scope="global"
                tag="span"
              >
                <template #break><br /></template>
              </i18n-t>
            </template>
          </AppTippy>

          <AppIcon
            v-if="type === RADIO_GROUP_TYPES.BLOCKS"
            :icon-name="option.icon || 'info-next'"
            class="arg-Option_Icon"
            height="48"
            width="48"
          />
          <slot :item="option" name="item-label">
            <span v-if="type === RADIO_GROUP_TYPES.BLOCKS" class="arg-Option_Label">
              {{ option.label }}
            </span>
            <template v-else>
              {{ option.label }}
            </template>
            <span
              v-if="option.message && type === RADIO_GROUP_TYPES.BLOCKS"
              class="arg-Option_Message oboard-truncated-text"
            >
              <slot :option="option" name="message">
                {{ option.message }}
              </slot>
            </span>
          </slot>
        </label>
        <slot :item="option" name="item-after" />
      </div>
    </div>
  </div>
</template>

<script>
import { isNull } from 'lodash'
import { defineComponent } from 'vue'

import { DROP_LIST_THEMES } from '@/utils/components-configurations/app-droplist'
import {
  RADIO_GROUP_SIZES,
  RADIO_GROUP_TYPES
} from '@/utils/components-configurations/app-radio-group'
import { DATA_TYPES } from '@/utils/data-types'
import { uid } from '@/utils/uid'

import AppIcon from '@/components/ui/AppIcon/AppIcon'

const { STRING, BOOLEAN, NUMBER } = DATA_TYPES
export default defineComponent({
  name: 'AppRadioGroup',
  components: { AppIcon },
  props: {
    modelValue: {
      required: true,
      validator: v => [STRING, NUMBER, BOOLEAN].includes(typeof v) || isNull(v)
    },

    options: {
      type: Array,
      required: true
    },

    name: {
      type: String,
      required: true
    },

    disabled: {
      type: Boolean,
      default: false
    },

    groupLabel: {
      type: String,
      default: ''
    },

    type: {
      type: String,
      default: RADIO_GROUP_TYPES.PRIMARY_NEXT,
      validator: v => Object.values(RADIO_GROUP_TYPES).includes(v)
    },

    size: {
      type: String,
      default: 'default',
      validator: v => RADIO_GROUP_SIZES.includes(v)
    },

    alignWidth: {
      type: Boolean
    },

    fullWidth: {
      type: Boolean
    },

    oneLine: {
      type: Boolean
    },

    isError: {
      type: Boolean
    }
  },

  emits: { 'update:modelValue': null },
  data() {
    return {
      uid: uid()
    }
  },

  computed: {
    RADIO_GROUP_TYPES: () => RADIO_GROUP_TYPES,

    showTooltipArrow() {
      return this.type === RADIO_GROUP_TYPES.PRIMARY_NEXT || this.type === RADIO_GROUP_TYPES.BLOCKS
    },

    tippyTheme() {
      return this.showTooltipArrow
        ? DROP_LIST_THEMES.TRANSLUCENT_NEXT
        : DROP_LIST_THEMES.TRANSLUCENT
    },

    wrapperClasses() {
      return {
        'arg-AppRadioGroup': true,
        [`arg-AppRadioGroup-${this.type}`]: true,
        [`arg-AppRadioGroup-size${this.size}`]: true,
        'arg-AppRadioGroup-alignWidth': this.alignWidth,
        'arg-AppRadioGroup-fullWidth': this.fullWidth,
        'arg-AppRadioGroup-oneLine': this.oneLine,
        'arg-AppRadioGroup-disabled': this.disabled,
        'arg-AppRadioGroup-error': this.isError
      }
    },

    optionsClasses() {
      return {
        'arg-Options': true,
        'arg-Options-disabled': this.disabled,
        'arg-Options-alignWidth': this.alignWidth,
        'arg-Options-fullWidth': this.fullWidth
      }
    },

    optionsCount() {
      return this.options.length
    },

    getValue: {
      get() {
        return this.modelValue
      },

      set(v) {
        if (!this.disabled) {
          this.$emit('update:modelValue', v)
        }
      }
    }
  }
})
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';

%primary-size-default {
  padding: var(--option-padding, 5px 6px);
  font-size: var(--option-font-size, #{$fs-12});
  font-weight: fw('bold');
  line-height: var(--option-line-height, 16px);
}
%primary-size-large {
  padding: var(--option-padding, 6px);
  font-size: $fs-20;
  font-weight: fw('semi-bold');
  line-height: 28px;
}

.arg-GroupLabel {
  color: $dark-1;
  margin-right: 12px;
  font-size: $fs-12;
  font-family: $system-ui;
  white-space: nowrap;
}

.arg-AppRadioGroup {
  display: inline-flex;
  align-items: center;
  width: fit-content;

  &-disabled {
    pointer-events: none;
    opacity: 0.9;
  }

  &-primary-next {
    padding: var(--backdrop-padding, 4px);
    border-radius: $border-radius-sm-next;
    background-color: var(--fill, $grey-19);
  }

  &-primary-next#{&}-disabled {
    opacity: 0.5;
  }

  &-vertical {
    border-radius: 0 0 $border-radius-sm-next $border-radius-sm-next;
    background-color: $grey-19;
    overflow: hidden;
  }

  &-tab-like {
    width: auto;
    position: relative;
    min-width: 100%;
    box-shadow: inset 0 -2px 0 $grey-3-next;
  }

  &-blocks {
    width: 100%;
  }

  &-fullWidth {
    width: 100%;
  }
}

.arg-Options {
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;

  &:not(&-alignWidth) {
    display: flex;
  }

  &-alignWidth {
    display: grid;
    grid-template-columns: repeat(v-bind(optionsCount), 1fr);
  }

  &-fullWidth {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(v-bind(optionsCount), 1fr);
  }

  .arg-AppRadioGroup-light & {
    box-shadow: 0 0 1px rgba($dark-1, 0.31), 0 4px 8px rgba($dark-1, 0.25);
    border-radius: $border-radius-sm;
    font-family: $system-ui;
  }

  .arg-AppRadioGroup-vertical & {
    display: grid;
    width: 100%;
  }

  .arg-AppRadioGroup-primary-next &,
  .arg-AppRadioGroup-blocks & {
    gap: 8px;
  }

  .arg-AppRadioGroup-tab-like & {
    gap: 20px;
  }

  .arg-AppRadioGroup-blocks & {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(v-bind(optionsCount), 1fr);
  }
}

.arg-Item {
  .arg-AppRadioGroup-blocks & {
    height: 100%;
  }
  &-disabled {
    opacity: 0.5;
    &:active {
      pointer-events: none;
    }
    .arg-Option {
      cursor: not-allowed;
    }
  }
}

.arg-Option {
  &:first-of-type {
    border-radius: $border-radius-md 0 0 $border-radius-md;
    border-left-width: 1px;

    .arg-AppRadioGroup-light & {
      border-radius: $border-radius-sm 0 0 $border-radius-sm;
    }
  }

  &:last-of-type {
    border-radius: 0 $border-radius-md $border-radius-md 0;

    .arg-AppRadioGroup-light & {
      border-radius: 0 $border-radius-sm $border-radius-sm 0;
    }
  }

  padding: 8px 10px;
  border: 1px solid $primary-color-next;
  border-left-width: 0;
  color: $primary-color-next;
  display: block;
  cursor: pointer;
  transition: all $transition-fast ease;
  line-height: 14px;
  white-space: nowrap;
  @include activityStates($primary-color, 10%, 'color');

  .arg-AppRadioGroup-separated & {
    padding: 6px 8px;
    border-color: transparent;
    color: $dark-1;
    font-size: $fs-14;
    line-height: 18px;
    @include activityStates($dark-1, 10%, 'color');
  }

  .arg-AppRadioGroup-vertical & {
    border: none;
    font-family: $system-ui;
    color: $dark-1;
    background-color: transparent;
    font-style: normal;
    text-transform: capitalize;
    font-weight: fw('regular');
    font-size: $fs-14;
    line-height: 20px;
    padding: 10px;
    border-radius: var(--border-radius, 0);
    text-align: left;
    display: flex;
    align-items: center;
    gap: 8px;

    &:hover {
      background: $grey-2-next;
    }

    @include activeState($grey-2-next, 10%);
  }

  // arg-AppRadioGroup-sizelarge

  .arg-AppRadioGroup-primary-next & {
    color: $dark-grey;

    border: none;
    font-family: $system-ui;
    background-color: transparent;

    border-radius: $border-radius-sm;

    &:hover {
      background-color: rgba($white, 0.8);
    }
  }

  .arg-AppRadioGroup-tab-like & {
    color: $dark-2;

    border: none;
    font-family: $system-ui;
    background-color: transparent;

    border-radius: 0;

    font-style: normal;
    font-weight: fw('semi-bold');
    font-size: $fs-14;
    line-height: 20px;
    padding: var(--option-padding, 5px 0 7px);
    position: relative;

    &:after {
      content: '';
      transition: width $transition-fast ease-in-out;
      height: 2px;
      background-color: var(--divider-color, $primary-color-next);
      width: 0;
      border-radius: $border-radius-sm-next;
      position: absolute;
      bottom: 0;
      left: 0;
      pointer-events: none;
    }

    &:hover {
      color: $primary-color-next;
      --tab-color: #{$primary-color-next};
    }
  }

  .arg-AppRadioGroup-blocks & {
    border-width: 2px;
    border-radius: $border-radius-sm-next;
    border-color: $grey-2-next;
    color: $dark-2;
    padding: 14px;
    display: grid;
    column-gap: 16px;
    height: 100%;
    grid-template-columns: 48px 1fr;
    grid-template-rows: 48px 1fr;

    &:hover {
      border-color: $primary-color-next;
      background-color: rgba($primary-color-next, 0.1);
    }
  }

  .arg-AppRadioGroup-blocks.arg-AppRadioGroup-error & {
    border-color: $grade-low-color-next;

    &:hover {
      border-color: $primary-color-next;
    }
  }

  .arg-AppRadioGroup-primary-next.arg-AppRadioGroup-sizexs & {
    padding: var(--option-padding, 5px 6px);
    font-size: $fs-12;
    font-weight: fw('bold');
    line-height: 16px;
  }
  .arg-AppRadioGroup-primary-next.arg-AppRadioGroup-sizedefault & {
    @extend %primary-size-default;
  }

  .arg-AppRadioGroup-primary-next.arg-AppRadioGroup-sizelarge & {
    @extend %primary-size-large;
  }

  .arg-AppRadioGroup-separated-modern & {
    padding: var(--option-padding, 9px 16px);
    border-color: transparent;
    color: $dark-1;
    font-family: $system-ui;
    font-weight: fw('medium');
    font-size: $fs-14;
    line-height: 14px;
  }

  .arg-AppRadioGroup-secondary & {
    padding-left: 16px;
    padding-right: 16px;
    border-color: $dark-grey;
    color: $dark-grey;
    line-height: 16px;
    @include hoverState($dark-grey, 60%);
    @include activityStates($dark-grey, 5%, 'color');
    @include activeState($dark-grey, 5%, 'border-color');
  }

  .arg-AppRadioGroup-light & {
    border-color: transparent;
    color: $dark-1;
    font-size: $fs-14;
  }

  .arg-AppRadioGroup-alignWidth & {
    text-align: center;
  }

  .arg-AppRadioGroup-oneLine & {
    white-space: nowrap;
  }

  .arg-AppRadioGroup-sizesmall & {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .arg-AppRadioGroup-sizexs & {
    padding: 4px 14px;
  }

  .arg-AppRadioGroup-sizemd & {
    padding: 11px 13px 13px 15px;
    line-height: 24px;
  }
}

.arg-Option_Icon {
  grid-area: 1 / 1 / 2 / 2;
}

.arg-Option_Label {
  align-self: center;
  font-weight: fw('semi-bold');
  font-size: $fs-14;
  line-height: 20px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: var(--line-count, 2);
  white-space: normal;
  word-break: break-all;
  grid-area: 1 / 2 / 2 / 3;
}

.arg-Option_Message {
  white-space: normal;
  grid-column-start: 2;
  color: $dark-3;
  font-size: $fs-12;
  line-height: 16px;
}

.arg-OptionInput {
  &:checked + .arg-Option {
    background-color: $primary-color;
    color: $white;
    @include activityStates($primary-color);

    .arg-AppRadioGroup-separated & {
      background-color: $grey-13;
      color: $primary-color;
      border-radius: $border-radius-md;

      &:hover {
        background-color: lighten($grey-13, 10%);
        color: lighten($primary-color, 10%);
      }

      &:active {
        background-color: darken($grey-13, 10%);
        color: darken($primary-color, 10%);
      }
    }

    .arg-AppRadioGroup-primary-next & {
      background-color: $white;
      color: $dark-grey;
      // border-radius: $border-radius-sm-next;
      box-shadow: 0 0 1px rgba(0, 0, 0, 0.15), 0 2px 4px rgba(0, 0, 0, 0.15);
    }

    .arg-AppRadioGroup-tab-like & {
      background-color: transparent;
      color: $primary-color-next;
      --tab-color: #{$primary-color-next};

      &:after {
        width: 100%;
      }
    }

    .arg-AppRadioGroup-blocks & {
      background-color: rgba($primary-color-next, 0.1);
      color: $dark-2;
      border-color: $primary-color-next;
    }

    .arg-AppRadioGroup-vertical & {
      color: var(--option-color, $dark-1);
      background: rgba(0, 82, 204, 0.15);

      &:hover {
        background: rgba(0, 103, 255, 0.15);
      }

      @include activeState(rgba(0, 103, 255, 0.15), 10%);
    }

    .arg-AppRadioGroup-separated-modern & {
      background-color: $grey-13;
      color: $primary-color;
      border-radius: 24px;
    }

    .arg-AppRadioGroup-secondary & {
      background-color: $dark-grey;
      @include activityStates($dark-grey);
      color: $white;
    }

    .arg-AppRadioGroup-light & {
      background-color: $dark-grey;
    }
  }
}

.arg-Option_TooltipContent {
  text-align: center;
  display: inline-block;
}
</style>
