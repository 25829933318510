<template>
  <!--  TipTap component, do not remove and change it    -->
  <NodeViewWrapper
    :data-oboard-uid="props.node.attrs[DATA_OBOARD_UID]"
    as="div"
    class="tableWrapper"
  >
    <!--  TipTap component, do not remove and change it    -->
    <table :data-oboard-uid="props.node.attrs[DATA_OBOARD_UID]">
      <NodeViewContent as="tbody" class="content" />
    </table>
    <!-- contenteditable="false" need for dropdown because in other case it will lose focus on current block   -->
    <div v-if="isActive" class="ctt-MenuList" contenteditable="false">
      <AppDroplist
        :offset="[0, 0]"
        append-to=".df-DescriptionField"
        placement="bottom"
        trigger="click"
      >
        <template #button="">
          <AppButton
            class="cct-MenuList-Button"
            icon-after="arrow-down-black"
            size="md"
            type="simple"
          >
            {{ $t('action.table_options') }}
          </AppButton>
        </template>
        <CustomTableListActions
          :cell-actions="cellActions"
          :editor="editor"
          @exec-action="execAction"
        />
      </AppDroplist>
    </div>
  </NodeViewWrapper>
</template>

<script setup>
import { NodeViewWrapper, NodeViewContent, nodeViewProps } from '@tiptap/vue-3'
import { computed, inject, onMounted, ref } from 'vue'

import { MENU_ITEMS_GROUPS } from '@/utils/dropdown-menu'
import { descriptionFieldStateInjectionKey } from '@/utils/injection-keys'
import { DATA_OBOARD_UID, uid } from '@/utils/uid'

import AppDroplist from '@/components/AppDroplist'
import CustomTableListActions from '@/components/TipTapExtensions/Table/CustomTableListActions'
import AppButton from '@/components/ui/AppButton/AppButton'

defineOptions({
  name: 'CustomTableTiptap'
})

const { HEADER_ACTIONS, REMOVING } = MENU_ITEMS_GROUPS

const cellActions = computed(() => [
  {
    title: 'toggle_header_column',
    action: 'toggleHeaderColumn',
    icon: 'layout-column-fill',
    type: 'default',
    group: HEADER_ACTIONS
  },
  {
    title: 'toggle_header_row',
    action: 'toggleHeaderRow',
    icon: 'layout-row-fill',
    type: 'default',
    group: HEADER_ACTIONS
  },
  {
    title: 'delete_table',
    action: 'deleteTable',
    icon: 'delete-outline-currentColor-next',
    color: 'var(--grade-low-color-next)',
    group: REMOVING
  }
])

const props = defineProps(nodeViewProps)

const descriptionFieldState = inject(descriptionFieldStateInjectionKey)

const id = ref(null)

onMounted(() => {
  const nodeId = props.node.attrs[DATA_OBOARD_UID]

  if (!nodeId) {
    id.value = uid()
    props.updateAttributes({
      [DATA_OBOARD_UID]: id.value
    })
  } else {
    id.value = nodeId
  }
})

const isActive = computed(() => {
  return (
    descriptionFieldState?.getActiveState() &&
    props.editor.commandManager.editor.isActive('table', {
      [DATA_OBOARD_UID]: id.value
    })
  )
})

const execAction = action => {
  props.editor.chain().focus()[action]().run()
}
</script>

<style lang="scss" scoped>
.ctt-MenuList {
  display: flex;
  justify-content: center;
  margin: 5px;
  .cct-MenuList-Button {
    background-color: $grey-3-next;
    :deep(.svg-icon) {
      transition: transform $transition-fast ease-in-out;
    }
    &:hover {
      background-color: $grey-4;
    }
    &:active {
      color: $dark-grey;
    }
  }

  &:deep(.o-droplist--expanded) {
    .cct-MenuList-Button {
      background-color: $dark-grey;
      color: $white;

      .svg-icon {
        transform: rotate(180deg);
      }

      &:hover {
        background-color: $dark-grey;
        color: $white;
      }
    }
  }
}
</style>
