import { has, isEmpty, memoize } from 'lodash'

import { getIndicatorPanelStyles } from '@/utils/dashboard'
import { isFirefox } from '@/utils/general'
import { BASE64_JIRA_CLOUD_JIRA_ICONS, NO_JIRA_ISSUE_TYPE_ICON } from '@/utils/roadmap'

export const ignoreElementsForExport = () => {
  return el => {
    return el.dataset && has(el.dataset, 'exportIgnore')
    // return Array.from(el.classList).some(className => ignoredClasses.includes(className))
  }
}

const getRealIconChildNodes = memoize(spriteSymbolId => {
  const svgSprite = document.getElementById('__SVG_SPRITE_NODE__')

  const realIcon = svgSprite.getElementById(spriteSymbolId)
  return realIcon?.childNodes || []
})

export const replaceSvgIcons = clonedNode => {
  const svgIcons = clonedNode.querySelectorAll('svg.ai-Icon')

  svgIcons.forEach(icon => {
    const spriteSymbolId = icon.dataset.targetId

    const realIconChildNodes = getRealIconChildNodes(spriteSymbolId)

    if (!isEmpty(realIconChildNodes)) {
      icon.innerHTML = ''
      realIconChildNodes.forEach(node => {
        icon.appendChild(node.cloneNode(true))
      })
    }
  })
}

export const addExtraSpaceForFirefox = clonedNode => {
  // in FIREFOX for some reason we have a problem with spaces in text they are disappear
  // and for some unknown reason this style fix this problem
  if (isFirefox()) {
    clonedNode.body.style.overflowWrap = 'break-word'
  }
}

export const replaceTaskIcons = clonedNode => {
  // hardcode solution coz we cant render this icons in roadmap image export because of CORS
  const taskIcons = clonedNode.querySelectorAll('.oi-IconTask')
  taskIcons.forEach(icon => {
    icon.src = BASE64_JIRA_CLOUD_JIRA_ICONS[icon.dataset['issueType']] || NO_JIRA_ISSUE_TYPE_ICON
  })
}

export const alignOkrIcons = clonedNode => {
  const okrIcons = clonedNode.querySelectorAll('.oi-ObjectiveIcon')
  okrIcons.forEach(icon => {
    icon.classList.add('fix-align')
  })
}

export const replaceAvatars = clonedNode => {
  const avatars = clonedNode.querySelectorAll('.aa-Avatar')
  avatars.forEach(avatar => {
    avatar.remove()
  })

  const replaceAvatars = clonedNode.querySelectorAll('.aa-ReplaceAvatar')
  replaceAvatars.forEach(avatar => {
    avatar.classList.add('visible')
  })
}

export const fixIndicatorPanel = clonedNode => {
  const wrapper = clonedNode.querySelector('.ipwp-IndicatorPanel')
  const progress = wrapper.querySelector('.ipwp-IndicatorPanel-Progress')
  progress.style.animation = 'none'

  const content = wrapper.querySelector('.ipwp-IndicatorPanel-content')
  content.style.animation = 'none'

  const { width: progressWidth } = progress.getBoundingClientRect()
  const { width: contentWidth } = content.getBoundingClientRect()

  const { color, transform } = getIndicatorPanelStyles({
    progressWidth,
    contentWidth
  })
  content.style.color = color
  content.style.transform = transform
}

export const removeStatusBorders = clonedNode => {
  const gradeChartLabels = clonedNode.querySelectorAll('.agc-Wrapper .obs-ObjectiveStatus')
  gradeChartLabels.forEach(item => {
    item.style.borderColor = 'transparent'
  })
}

export const addVisibilityForLogo = clonedNode => {
  clonedNode.querySelector('.ael-ExportLogo').classList.add('visible')
}

export const removeStatusGradeBackground = clonedNode => {
  const statuses = clonedNode.querySelectorAll('.obs-ObjectiveStatus')
  statuses.forEach(item => {
    item.style.background = 'none'
  })
}
