const state = {
  selectedIntervalsNames: null
}

const mutations = {
  SET_SELECTED_INTERVALS_NAMES(state, selectedIntervalsNames) {
    state.selectedIntervalsNames = selectedIntervalsNames
  }
}

const actions = {
  setSelectedIntervalsNames({ commit }, selectedIntervalsNames) {
    commit('SET_SELECTED_INTERVALS_NAMES', selectedIntervalsNames)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
