import { isString } from 'lodash'

export const getInstanceDomainName = ({ atlassianBaseUrl } = {}) => {
  const DEFAULT_VALUE = ''
  if (!atlassianBaseUrl || !isString(atlassianBaseUrl)) {
    return DEFAULT_VALUE
  }

  const regex = /(?:https?:\/\/)?(?:www\.)?([^/]+)/
  const [, domain] = atlassianBaseUrl.match(regex) || []

  return domain || DEFAULT_VALUE
}

export const CONNECTION_STATUSES = {
  NONE: 0,
  CONNECTION_PENDING: 1,
  SUCCESS: 2,
  DISCONNECTION_PENDING: 3,
  FAILED: 4
}
