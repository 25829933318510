<template>
  <BulkActionsOperationValueControl
    v-model="localModelValue"
    :empty-state-label="t('field.assignee.stakeholders.bulk_placeholder')"
    :field-id="CORE_FIELDS_IDS.STAKEHOLDERS"
    :item-label="ASSIGNEE_ENTITY_KEYS.NAME"
    :item-value="ASSIGNEE_ENTITY_KEYS.ACCOUNT_ID"
    :loading="isLoading"
    :options="options"
    :search-function="getAssignees"
    @update:options="options = $event"
    @toggle-value-dropdown="emit('toggle-value-dropdown', $event)"
  />
</template>

<script setup>
import { isNull } from 'lodash'
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import AssigneesInfoApiHandler from '@/api/assignees-info'
import { ASSIGNEE_ENTITY_KEYS } from '@/utils/entity-keys'
import { handleError } from '@/utils/error-handling'
import { createGetUsersPayload } from '@/utils/okr-elements-forms-helpers'
import { CORE_FIELDS_IDS } from '@/utils/okr-elements-table-bulk-actions'

import BulkActionsOperationValueControl from '@/components/objectives/bulk-edit/BulkActionsOperationValueControl'

defineOptions({
  name: 'StakeholdersField'
})

const emit = defineEmits({
  'update:model-value': null,
  'toggle-value-dropdown': null
})

const props = defineProps({
  modelValue: {
    type: Object,
    required: true
  },

  workspaceId: {
    type: [String, Number],
    required: true
  }
})

const { t } = useI18n()

const options = ref([])

const isLoading = ref(false)

const getAssignees = async (searchString = null) => {
  let result = []
  const api = new AssigneesInfoApiHandler()

  isLoading.value = true
  const payload = createGetUsersPayload({
    workspaceId: props.workspaceId,
    searchString
  })

  try {
    const users = await api.getUsers(payload)

    result = [...users]

    if (!isNull(searchString)) {
      return users
    }
  } catch (error) {
    handleError({ error })
  } finally {
    isLoading.value = false
  }

  return result
}

onMounted(async () => {
  options.value = await getAssignees()
})

const localModelValue = computed({
  get: () => {
    const { value, operation } = props.modelValue

    return {
      value,
      operation
    }
  },
  set: newValue => {
    emit('update:model-value', newValue)
  }
})
</script>

<style lang="scss" scoped></style>
