<template>
  <HeadCell>
    <CellTitle />
    <TodayMarker />
    <CellDates />
  </HeadCell>
</template>

<script setup>
import dayjs from 'dayjs'
import {
  h,
  computed,
  withDirectives,
  ref,
  inject,
  resolveDirective,
  getCurrentInstance,
  watch,
  onMounted,
  onBeforeUnmount
} from 'vue'

import { roadmapStateInjectionKey } from '@/utils/injection-keys'
import { getScopeId } from '@/utils/render-function-helpers'
import { getTodayMarkerLeftOffset, ROADMAP_DATE_FORMAT } from '@/utils/roadmap'
import { uid as useUid } from '@/utils/uid'

defineOptions({
  name: 'OkrTimelinesTableHeadCell'
})

const props = defineProps({
  data: {
    type: Object,
    required: true
  },

  activeView: {
    type: String,
    required: true
  },

  oneDayWidth: {
    type: Number,
    required: true
  }
})

const roadmapState = inject(roadmapStateInjectionKey)

const emit = defineEmits(['select-active-date-reference'])

const cell = ref(null)

const HeadCell = (_, { slots }) => {
  return h(
    'div',
    {
      ref: cell,
      class: {
        'ott-HeadCell': true,
        'ott-HeadCell-withoutDates': !props.data.dates
      },
      style: {
        '--cell-width': `${props.data.cellWidth}px`
      }
    },
    h(slots.default)
  )
}

const CellTitle = () => h('div', { class: 'ott-HeadCell_Title' }, props.data.title)

const today = computed(() => dayjs().format(ROADMAP_DATE_FORMAT))

const isFirstLoad = ref(false)

watch(
  () => props.activeView,
  () => {
    isFirstLoad.value = true
  }
)

const uid = useUid()

onMounted(() => {
  isFirstLoad.value = true
  roadmapState.headCells[uid] = cell.value
})

onBeforeUnmount(() => {
  delete roadmapState.headCells[uid]
})

const setReference = (isActive, reference) => {
  if (isActive && reference) {
    emit('select-active-date-reference', { reference, isFirstLoad: isFirstLoad.value })
    isFirstLoad.value = false
  }
}

const TodayMarker = () => {
  if (props.data.includesToday && !props.data.dates) {
    const tippy = resolveDirective('tippy')
    return withDirectives(
      h('div', {
        class: 'ott-HeadCell_TodayMarker',
        ref: data => setReference(true, data),
        style: {
          '--left': getTodayMarkerLeftOffset(props.data.todayNumber, props.oneDayWidth)
        }
      }),
      [[tippy, { content: today.value, placement: 'top' }]]
    )
  }
}

const CellDates = () => {
  if (props.data.dates) {
    const scopeId = getScopeId(getCurrentInstance())
    return h(
      'div',
      {
        class: 'ott-HeadCell_Dates'
      },
      [
        props.data.dates.map(item => {
          return h(
            'div',
            {
              class: {
                'ott-Date': true,
                'ott-Date-active': item.active,
                'ott-Date-weekend': item.isWeekEnd
              },
              ...scopeId,
              ref: data => setReference(item.active, data),
              key: item.id
            },
            `${item.shortcut}${item.dateNumber}`
          )
        })
      ]
    )
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/roadmap';
/* this eslint plugin won't work with vue 3 render function in <script setup> */
/* eslint-disable vue-scoped-css/no-unused-selector */

/* eslint-disable-next-line */
%common-pseudo-element-styles {
  content: '';
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
}

/* eslint-disable-next-line */
%today-pseudo-element-styles {
  @extend %common-pseudo-element-styles;
  left: 0;
  margin: auto;
  width: $today-line-width;
  background-color: inherit;
  z-index: 2;
  top: unset;
  bottom: 0;
  transform: translateY(100%);
}

.ott-HeadCell {
  font-size: $fs-12;
  position: relative;
  width: var(--cell-width);
  flex: 1 0 var(--cell-width);
  height: calc(#{$timelines-head-height - 2px}); // 2px border on wrapper .ott-Head

  // &:not(:last-child) {
  //   // box-shadow: inset -1px 0 0 $lines-grey;
  //   &:after {
  //     @extend %common-pseudo-element-styles;
  //     height: 100%;
  //     width: 1px;
  //     background: $grey-2-next;
  //   }
  // }
  &-withoutDates {
    display: flex;
    align-items: center;
  }
}

.ott-HeadCell_TodayMarker {
  position: absolute;
  width: $marker-size;
  height: $marker-size;
  bottom: 8px;
  border-radius: 50%;
  background-color: $today-marker-color;
  left: calc(
    var(--left) - ((#{$marker-size} - #{$today-line-width}) / 2)
  ); // move line of marker to left offset position
  z-index: 2;

  &:after {
    @extend %today-pseudo-element-styles;
    height: 10px; // 8px + 2px // 2px to cover .ott-Head border-bottom
  }
}

.ott-HeadCell_Dates {
  display: flex;
  // gap: 22.5px;
  height: 23px; // 32px;
  align-items: flex-start;
  justify-content: space-around;
  // padding: 0 12px;
}

.ott-HeadCell_Title {
  font-style: normal;
  font-weight: fw('bold');
  text-transform: uppercase;
  font-size: $fs-12;
  line-height: 16px;
  color: $dark-3;
  padding: 11px 12px 4px;
  text-align: center;
  width: 100%;

  .ott-HeadCell-withoutDates & {
    padding: 16px 12px;
    min-height: 40px;
  }
}

.ott-Date {
  max-width: 35px;
  width: calc(var(--cell-width) / 7);
  color: $dark-3;
  text-align: center;
  &:first-letter {
    font-size: var(--first-letter-font-size);
    // color: $placeholder-color;
  }

  &-active {
    color: $today-marker-color;

    &:first-letter {
      color: inherit;
    }
  }
}
/* eslint-enable vue-scoped-css/no-unused-selector */
</style>
