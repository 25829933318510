import { uniq } from 'lodash'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

import RolesApiHandler from '@/api/roles'
import UsersApiHandler from '@/api/users'
import { useGlobalGroups } from '@/composables/global-groups'
import { handleError } from '@/utils/error-handling'
import { prepareFilterParams } from '@/utils/users-helpers'

export const useGetUserRolesText = (workspaces, roles) => {
  const { t } = useI18n()
  const uniqRoles = uniq(workspaces.map(ws => ws.userRoleId))
  if (uniqRoles.length === 1) {
    const role = roles.find(role => role.value === uniqRoles[0])
    return {
      label: role.label
    }
  }
  return {
    label: t('users.roles.total', { qty: uniqRoles.length }),
    tooltipText: uniqRoles
      .map(uniqRole => roles.find(role => role.value === uniqRole)?.label)
      .join('<br />')
  }
}

export const useGetDeleteUserName = (selectedUsers, tableUsersData, deleteUserConfig) => {
  if (selectedUsers.length === 1) {
    return tableUsersData.find(user => user.accountId === selectedUsers[0])?.name
  }
  return deleteUserConfig.name
}

export const useCanDeleteBulkUsers = (selectedUsers, tableUsersData) => {
  return selectedUsers.every(id => tableUsersData.find(user => user.accountId === id)?.canBeDeleted)
}

export const useGetSelectedUsers = tableData => {
  const selectedUsers = ref([])
  const selectAll = ref(false)
  const clearCheckboxes = () => {
    selectAll.value = false
    selectedUsers.value = []
  }
  const onToggleAllUsers = value => {
    if (value) {
      selectedUsers.value = tableData.value.map(user => user.accountId)
    } else {
      selectedUsers.value = []
    }
  }
  const onChangeCheckboxListItem = value => {
    selectedUsers.value = value
    selectAll.value = value.length === tableData.value.length
  }

  return {
    selectedUsers,
    selectAll,
    clearCheckboxes,
    onToggleAllUsers,
    onChangeCheckboxListItem
  }
}
// @API request

export const useFetchRoles = async () => {
  const { t } = useI18n()
  const rolesApi = new RolesApiHandler()
  try {
    const roles = await rolesApi.getRoles()
    return roles.map(role => {
      if (!role.value) {
        return {
          label: t('filter.all_roles'),
          value: 0
        }
      }
      return { label: t(role.label), value: role.value, trackingName: role.trackingName }
    })
  } catch (error) {
    handleError({ error })
  }
}

export const useFetchGroupFilter = async (searchString = null, filterParams) => {
  const usersApi = new UsersApiHandler()
  const { workspaceIds } = filterParams
  try {
    return await usersApi.getGroupFilter({
      ...prepareFilterParams({
        workspaceIds
        // groupIds: searchString ? [] : groupIds
      }),
      searchString
    })
  } catch (error) {
    handleError({ error })
    throw error
  }
}

export const useFetchUserFilter = async (searchString = null, filterParams) => {
  const usersApi = new UsersApiHandler()
  const { accountIds } = filterParams
  try {
    return await usersApi.getUserFilter({
      ...prepareFilterParams({
        // workspaceIds,
        accountIds: searchString ? [] : accountIds
      }),
      searchString
    })
  } catch (error) {
    handleError({ error })
    throw error
  }
}

export const useFetchPluginUsers = async (filterParams, { itemsOnPage, currentPage }) => {
  const usersApi = new UsersApiHandler()
  const { groupIds, accountIds, roleIds, workspaceIds, platformIds } = filterParams

  try {
    return await usersApi.getFilteredUsers({
      ...prepareFilterParams({
        groupIds,
        accountIds,
        roleIds,
        workspaceIds,
        platformIds
      }),
      limit: itemsOnPage,
      startAt: (currentPage - 1) * itemsOnPage // calculate pagination, example: (1 - 1) * 50 = 0, (2 - 1) * 50 = 50
    })
  } catch (error) {
    handleError({ error })
    throw error
  }
}

export const useDeleteUserFromPluginBulk = async accountIds => {
  const usersApi = new UsersApiHandler()
  await usersApi.bulkRemoveUserFromPlugin({
    accountIds
  })
}

export const useFetchWorkspaceFilter = async (searchString = null, filterParams) => {
  const { workspaceIds } = filterParams
  const usersApi = new UsersApiHandler()
  try {
    return await usersApi.getWorkspaceFilter({
      ...prepareFilterParams({
        workspaceIds: searchString ? [] : workspaceIds
      }),
      searchString
    })
  } catch (error) {
    handleError({ error })
    throw error
  }
}

export const useInitialGroupsForFilter = () => {
  const { fetchGroupsForFilter } = useGlobalGroups()

  const getInitialGroupsForFilter = async ({ selectedGroups }) => {
    try {
      return await fetchGroupsForFilter({ groupIds: selectedGroups })
    } catch (error) {
      handleError({ error })
    }
  }

  return {
    getInitialGroupsForFilter
  }
}
