<template>
  <AppModalWithConfirmation
    ref="confirmReference"
    :confirm-close="areDataChanged"
    :show="show"
    :title="modalTitle"
    class="baut-Modal"
    hide-hero
    size="sm-next"
    @on-close="onClose(false)"
  >
    <div class="baut-Head">
      <div class="baut-Head_Cell">
        {{ $t('field.workspace.title') }}
      </div>
    </div>
    <div class="baut-ModalBody">
      <div class="baut-Fields">
        <AddUserFieldGroup
          v-for="item in workspacesToAdd"
          :key="item.id"
          v-model:workspaces-to-add="workspacesToAdd"
          :existed-workspaces-ids="[]"
          :item="item"
          hide-roles
          style="--items-count: 1; --items-width: 100%"
        />
      </div>
    </div>

    <template #footer-actions>
      <AppButton type="ghost-next" @click="close">
        {{ $t('action.cancel') }}
      </AppButton>
      <AppButton :disable="isLoading" :loading="isLoading" type="primary-next" @click="save">
        {{ $t('action.add') }}
      </AppButton>
    </template>
  </AppModalWithConfirmation>
</template>

<script setup>
import { isEqual } from 'lodash'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import UserApiHandler from '@/api/user-details'
import {
  EVENT_SOURCES,
  MODE_NAMES_FOR_TRACKING,
  trackAddUserEvent
} from '@/tracking/amplitude-helpers'
import { REQUEST_ENTITY_KEYS } from '@/utils/entity-keys'
import { handleError } from '@/utils/error-handling'
import { WORKSPACE_USER_ROLES } from '@/utils/objectives'
import { uid } from '@/utils/uid'
import { IDENTIFIER_TYPE_IDS } from '@/utils/web-app/emails-in-select'

import AppModalWithConfirmation from '@/components/AppModalWithConfirmation'
import AppButton from '@/components/ui/AppButton/AppButton'
import AddUserFieldGroup from '@/views/workspaces/settings/plugin-users/AddUserFieldGroup'

defineOptions({
  name: 'BulkAddUserToWorkspaceModal'
})

const props = defineProps({
  selectedUsers: {
    type: Array,
    required: true
  },

  show: {
    type: Boolean
  }
})

const DEFAULT_ITEM = {
  workspaceId: null,
  groupIds: [],
  workspaceValid: true,
  groupsValid: true
}

const workspacesToAdd = ref([
  {
    id: uid(),
    ...DEFAULT_ITEM
  }
])

const emit = defineEmits(['update:show', 'reload-data'])

const onClose = (reloadData = false) => {
  workspacesToAdd.value = [
    {
      id: uid(),
      ...DEFAULT_ITEM
    }
  ]
  emit('update:show', false)
  if (reloadData) {
    emit('reload-data')
  }
}

const confirmReference = ref(null)
const close = () => {
  confirmReference.value.close()
}

const areDataChanged = computed(() => {
  const { workspaceId, groupIds, roleId } = workspacesToAdd.value[0]
  const defaultItem = {
    workspaceId: DEFAULT_ITEM.workspaceId,
    groupIds: DEFAULT_ITEM.groupIds,
    roleId: DEFAULT_ITEM.roleId
  }
  const firstItem = {
    workspaceId,
    groupIds,
    roleId
  }

  return !isEqual(defaultItem, firstItem)
})

const { t } = useI18n()

const modalTitle = computed(() => {
  const userCount = props.selectedUsers.length
  return `${t('users.add_users_to_workspace', userCount, { count: userCount })} `
})

const isLoading = ref(false)
const save = async () => {
  let isValid = true
  workspacesToAdd.value.forEach(item => {
    item.workspaceValid = !!item.workspaceId
    if (!item.workspaceValid) {
      isValid = false
    }
  })

  if (isValid) {
    isLoading.value = true

    const api = new UserApiHandler()
    const payload = props.selectedUsers.map(identifier => {
      const [workspace] = workspacesToAdd.value
      const { groupIds, workspaceId } = workspace
      return {
        groupIds,
        workspaceId,
        identifier,
        [REQUEST_ENTITY_KEYS.IDENTIFIER_TYPE_ID]: IDENTIFIER_TYPE_IDS.ACCOUNT_ID,
        roleId: WORKSPACE_USER_ROLES.USER
      }
    })

    try {
      await api.addUserToWorkspaces(payload)
      isLoading.value = false

      trackAddUserEvent({
        mode: MODE_NAMES_FOR_TRACKING.ADD_TO_WORKSPACE,
        selectedUsers: props.selectedUsers,
        source: EVENT_SOURCES.GLOBAL_GROUPS_ALL_USERS_TAB
      })

      onClose(true)
    } catch (error) {
      handleError({ error })
      isLoading.value = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';

.baut-ModalBody {
  overflow-y: auto;
  max-height: calc(
    100vh - (60px * 2) - 44px - 28px - 20px - 22px - 92px
  ); // where 60 * 2 is Y margin of modal; 44px is modal head height; 28px is modal title height; 20px is modal title margin bottom; 22px is fields head height; 92px is modal footer height
  padding: 0 40px;
  @include styled-native-scrollbar();
}

.baut-Head {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;
  padding: 0 40px 6px;
}

.baut-Head_Cell {
  font-weight: fw('bold');
  font-size: $fs-12;
  line-height: 16px;
  color: $dark-3;
}

.baut-Fields {
  gap: 20px;
  display: flex;
  flex-direction: column;
}
</style>

<style lang="scss">
.baut-Modal {
  .ad-Content_Title {
    padding: 0 40px;
    margin-bottom: 9px;
  }
  .o-modal-content {
    .o-modal-body {
      padding: 0;
    }
  }

  --dialog-content-padding-right: 0;
  --dialog-content-padding-left: 0;
}
</style>
