<template>
  <div v-if="!objectiveIsJiraTask(item)" :id="`cc-CellTrigger-${uniqueId}`" class="cc-Wrapper">
    <AppButton
      v-if="item.lastCommentId"
      v-tippy="{
        content: userHasReadAccess ? t('comments.see_last_comment') : null,
        placement: 'top',
        theme: DROP_LIST_THEMES.TRANSLUCENT_NEXT
      }"
      :class="{ 'cc-CommentIcon-active': show, 'cc-CommentIcon-disable': !userHasReadAccess }"
      :disable="!userHasReadAccess"
      class="cc-CommentIcon cc-CommentIcon-last"
      data-testid="last-comment-button"
      icon="conversation"
      remove-padding
      size="sm"
      type="link-next"
      @click="getLastComment"
    />
    <AppButton
      v-else-if="userHasReadAccess"
      v-tippy="{
        content: t('comments.add_a_comment'),
        placement: 'top',
        theme: DROP_LIST_THEMES.TRANSLUCENT_NEXT
      }"
      :class="{ 'cc-CommentIcon-active': show }"
      class="cc-CommentIcon cc-CommentIcon-add"
      data-testid="add-comment-button"
      icon="conversation-plus"
      remove-padding
      size="sm"
      type="link-next"
      @click="addComment"
    />
    <AppDroplist
      v-if="editInitialised"
      v-model="show"
      :dropdown-min-width="595"
      :offset="[0, 0]"
      :to-selector="`#cc-CellTrigger-${uniqueId}`"
      append-to="body"
      dropdown-width="595px"
      has-fixed-parent
      max-width="595px"
      position="bottom"
    >
      <div class="cc-DropdownWrapper">
        <CommentsListLoader
          v-if="lastCommentDataLoading"
          :elements-count="1"
          class="cc-CommentLoader"
          data-testid="last-comment-loader"
        />
        <CommentItem
          v-else-if="!isEmpty(lastCommentData) && item.lastCommentId"
          :append-mention-to-body="false"
          :hidden-features="HIDDEN_FEATURES"
          :item="lastCommentData"
          :objective="item"
          :tracking-source="trackingSource"
          :user-data="lastCommentData"
          can-be-replied
          data-testid="last-comment"
          hide-actions
          @create-reply="onCreateComment"
        />
        <CommentForm
          ref="commentFormRef"
          :active="commentFormIsActive"
          :append-mention-to-body="false"
          :hidden-features="HIDDEN_FEATURES"
          :my-avatar="userData.userAvatarUrl"
          :objective="item"
          :tracking-source="trackingSource"
          class="cc-CommentForm"
          data-testid="comment-form"
          tracking-mode="main"
          @create="onCreateComment"
          @update:active="commentFormIsActive = $event"
        />
        <AppDivider class="cc-DropdownDivider" />
        <div class="cc-DropdownFooter">
          <AppButton
            data-testid="open-comments-button"
            icon="conversation"
            size="sm"
            type="link-next"
            @click="openComments"
          >
            {{ t('comments.open_comments') }}
          </AppButton>
        </div>
      </div>
    </AppDroplist>
  </div>
</template>

<script setup>
import { isEmpty } from 'lodash'
import { ref, provide, computed, nextTick, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

import CommentsApiHandler from '@/api/comments'
import { tracker } from '@/tracking/amplitude'
import { EVENT_CATEGORIES, TRACKING_UNKNOWN } from '@/tracking/amplitude-helpers'
import { DROP_LIST_THEMES } from '@/utils/components-configurations/app-droplist'
import { handleError } from '@/utils/error-handling'
import { commentFormInjectionKey } from '@/utils/injection-keys'
import { currentUserCanReadObjective, objectiveIsJiraTask } from '@/utils/objectives'
import { TIPTAP_EXTENSION_NAMES } from '@/utils/tiptap-utils'
import { uid } from '@/utils/uid'

import AppDroplist from '@/components/AppDroplist'
import CommentForm from '@/components/objectives/forms/objective-form/CommentForm'
import CommentItem from '@/components/objectives/forms/objective-form/CommentItem'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppDivider from '@/components/ui/AppDivider'
import CommentsListLoader from '@/components/ui/SkeletonLoaders/CommentsListLoader'

const props = defineProps({
  item: {
    type: Object,
    default: () => ({})
  },

  trackingSource: {
    type: String,
    default: TRACKING_UNKNOWN
  }
})

const { t } = useI18n()

const userHasReadAccess = computed(() => {
  return currentUserCanReadObjective(props.item)
})

const commentFormData = ref({
  opened: undefined // undefined if no comment form is opened and uid of component if it is
})
provide(commentFormInjectionKey, commentFormData)

const emit = defineEmits(['comments-scroll-to', 'update-comment'])
const HIDDEN_FEATURES = [TIPTAP_EXTENSION_NAMES.GIFS, TIPTAP_EXTENSION_NAMES.EMOJI]
const show = ref(false)
const lastCommentData = ref({})
const lastCommentDataLoading = ref(false)

const fetchLastComment = async ({ commentId, workspaceId }) => {
  const commentsApi = new CommentsApiHandler()
  try {
    lastCommentDataLoading.value = true
    lastCommentData.value = await commentsApi.getCommentTree({
      commentId
    })

    show.value = true
  } catch (error) {
    handleError({ error })
  } finally {
    lastCommentDataLoading.value = false
  }
}

const uniqueId = ref(uid())

const editInitialised = ref(false)

const commentFormIsActive = ref(false)
const commentFormRef = ref(null)

const addComment = () => {
  initializeCommentForm()
  nextTick(() => {
    commentFormRef.value?.focus()
    commentFormIsActive.value = true
  })
}
const initializeCommentForm = () => {
  if (!editInitialised.value) {
    editInitialised.value = true
  }
}
const getLastComment = () => {
  initializeCommentForm()

  nextTick(async () => {
    if (!show.value && props.item.lastCommentId) {
      await fetchLastComment({
        commentId: props.item.lastCommentId,
        workspaceId: props.item.workspaceId
      })
    }
  })
}

const onCreateComment = async ({ createdCommentId }) => {
  await fetchLastComment({
    commentId: createdCommentId,
    workspaceId: props.item.workspaceId
  })
  emit('update-comment', { createdCommentId: createdCommentId })
}

const openComments = () => {
  show.value = false
  emit('comments-scroll-to')
}

const store = useStore()
const userData = computed(() => store.state.system.userData)

watch(
  () => show.value,
  value => {
    if (value) {
      tracker.logEvent('last comment opened', {
        category: EVENT_CATEGORIES.COMMENTS
      })
    }
  }
)
</script>

<script>
// eslint-disable-next-line import/order
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CommentsCell'
})
</script>
<style lang="scss" scoped>
.cc-Wrapper {
  display: flex;
  max-width: 86px;
  margin: 0;
  width: 100%;
  justify-content: center;
}
.cc-CommentIcon {
  cursor: pointer;
  border-radius: $border-radius-sm-next;
  &-last {
    color: $dark-2;
  }
  &-add {
    color: $grey-1-next;
    .o-objective-row &,
    .oeli-ItemContent & {
      visibility: hidden;
    }

    .o-objective-row:hover &,
    .oeli-ItemContent:hover & {
      visibility: visible;
    }
  }

  &-disable {
    cursor: default;
    pointer-events: none;
  }

  &:not(&-active) {
    &:hover {
      color: $primary-color-next;
    }
  }

  &-active {
    .o-objective-row &,
    .oeli-ItemContent & {
      visibility: visible;
    }

    background-color: $dark-2;
    color: $white;
  }
}
.cc-DropdownWrapper {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-height: 352px;
  overflow: auto;
  &:deep(.df-ActionsAndCounter, .df-DescriptionField) {
    margin-bottom: 0;
  }
  &:deep(.df-Toolbar) {
    top: -20px; // gap size
  }
}
.cc-DropdownFooter {
  display: flex;
  gap: 16px;
}
.cc-DropdownDivider {
  width: 100%;
  margin: 0;
}
.cc-CommentForm {
  padding: 0;
}
.cc-CommentLoader {
  padding: 0;
}
</style>

<style lang="scss"></style>
