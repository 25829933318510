<template>
  <component
    :is="showDeleteButton ? 'button' : 'span'"
    class="sfc-Count"
    :class="{ 'sfc-Count-button': showDeleteButton }"
    @click.stop="handle"
  >
    <slot />
    <AppIcon
      v-if="showDeleteButton"
      icon-name="select-clear"
      height="12"
      width="12"
      class="sfc-Count_Icon"
    />
  </component>
</template>

<script>
import { defineComponent } from 'vue'

import AppIcon from '@/components/ui/AppIcon/AppIcon'

export default defineComponent({
  name: 'SelectedFiltersCount',
  components: { AppIcon },
  props: {
    showDeleteButton: {
      type: Boolean
    }
  },

  emits: { delete: null },
  methods: {
    handle() {
      if (this.showDeleteButton) {
        this.$emit('delete')
      }
    }
  }
})
</script>

<style scoped lang="scss">
@import '~@/assets/styles/selected-items-counter';

.sfc-Count {
  @extend %selected-items-counter;
  border: none;
  box-shadow: none;

  &-button {
    cursor: pointer;
    transition: background-color $transition-fast ease-in-out;
    @media (any-hover: hover) {
      &:hover {
        color: transparent;
        background-color: rgba($red-2, 0.5);
      }
    }
  }
}

.sfc-Count_Icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  pointer-events: none;

  .sfc-Count-button:hover & {
    opacity: 1;
  }
}
</style>
