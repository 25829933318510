import { isUndefined } from 'lodash'
import mitt from 'mitt'

import { uid } from '@/utils/uid'

export const notifyBus = mitt()

export const NOTIFICATION_TYPES = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning'
}

export const NOTIFICATION_DURATIONS = {
  SHORT: 'short',
  MEDIUM: 'medium',
  LONG: 'long',
  INFINITE: 'infinite'
}

export const showNotify = ({
  title = '',
  content = '',
  type = NOTIFICATION_TYPES.SUCCESS,
  isHtml = false,
  duration = undefined,
  expandable = undefined,
  expanded = false,
  btnText = '',
  btnIcon = '',
  onBtnClick = null,
  hideOnBtnClick = false,
  withVerticalDivider = false,
  withButtonClose = undefined,
  actions = null
} = {}) => {
  let delay = duration || NOTIFICATION_DURATIONS.SHORT
  let showCloseButton = Boolean(withButtonClose)

  if (type === NOTIFICATION_TYPES.ERROR) {
    delay = isUndefined(duration) ? NOTIFICATION_DURATIONS.LONG : duration
    showCloseButton = isUndefined(withButtonClose) ? true : withButtonClose
  }

  const id = uid()
  notifyBus.emit('show-notify', {
    id,
    content,
    type,
    isHtml,
    duration,
    delay,
    title,
    expandable,
    expanded,
    btnText,
    btnIcon,
    onBtnClick,
    hideOnBtnClick,
    withVerticalDivider,
    withButtonClose: showCloseButton,
    actions
  })
}
