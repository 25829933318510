import { GROUPS_CATEGORIZED_COLORS } from '@/utils/global-groups'

const { BLACK, BLUE, GREEN, VIOLET, RED, ORANGE } = GROUPS_CATEGORIZED_COLORS

export const ROWS = [
  {
    id: 0,
    expandable: true,
    name: '269px',
    users: '45px',
    subGroups: '40px',
    depth: 0,
    color: BLACK[1],
    workspaces: 4
  },
  {
    id: 1,
    expandable: true,
    name: '112px',
    users: '42px',
    subGroups: '42px',
    depth: 1,
    color: BLUE[0],
    workspaces: 5
  },
  {
    id: 2,
    name: '80px',
    expandable: false,
    users: '44px',
    subGroups: '40px',
    depth: 2,
    color: VIOLET[2],
    workspaces: 3
  },
  {
    id: 3,
    name: '160px',
    expandable: false,
    users: '44px',
    subGroups: '40px',
    depth: 2,
    color: GREEN[1],
    workspaces: 0
  },
  {
    id: 4,
    name: '220px',
    expandable: false,
    users: '44px',
    subGroups: '40px',
    depth: 2,
    color: RED[0],
    workspaces: 1
  },
  {
    id: 5,
    expandable: true,
    name: '100px',
    users: '46px',
    subGroups: '40px',
    depth: 1,
    color: ORANGE[0],
    workspaces: 4
  },
  {
    id: 6,
    name: '120px',
    expandable: false,
    users: '44px',
    subGroups: '40px',
    depth: 2,
    color: ORANGE[2],
    workspaces: 2
  },
  {
    id: 7,
    name: '40px',
    expandable: false,
    users: '44px',
    subGroups: '40px',
    depth: 2,
    color: VIOLET[0],
    workspaces: 3
  },
  {
    id: 8,
    expandable: false,
    name: '90px',
    users: '40px',
    subGroups: '40px',
    depth: 0,
    color: GREEN[0],
    workspaces: 0
  },
  {
    id: 9,
    expandable: false,
    name: '230px',
    users: '42px',
    subGroups: '48px',
    depth: 0,
    color: BLACK[0],
    workspaces: 5
  }
]
