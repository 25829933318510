import { mergeAttributes } from '@tiptap/core'
import TableHeader from '@tiptap/extension-table-header'
import { VueNodeViewRenderer } from '@tiptap/vue-3'

import { DATA_OBOARD_UID } from '@/utils/uid'

// eslint-disable-next-line import/extensions
import CustomTableCellTiptap from '@/components/TipTapExtensions/Table/CustomTableCellTiptap.vue'

export const TiptapTableHeader = TableHeader.extend({
  name: 'tableHeader',
  addAttributes() {
    return {
      ...this.parent?.(),
      [DATA_OBOARD_UID]: {
        default: '',
        parseHTML: element => element.getAttribute(DATA_OBOARD_UID),
        renderHTML: attributes => {
          return {
            [DATA_OBOARD_UID]: attributes[DATA_OBOARD_UID]
          }
        }
      }
    }
  },
  parseHTML() {
    return [
      {
        // Do not change this name its reserved name for correct parsing HTML. If you change this name it will be break all saved table in each objective
        tag: 'vue-component-oboard-header',
        getAttrs: dom => ({
          [DATA_OBOARD_UID]: dom.getAttribute(DATA_OBOARD_UID)
        })
      }
    ]
  },
  renderHTML({ HTMLAttributes }) {
    // Do not change this name and structure
    return ['th', ['vue-component-oboard-header', mergeAttributes(HTMLAttributes), 0]]
  },
  addNodeView() {
    return VueNodeViewRenderer(CustomTableCellTiptap)
  }
})

export default TiptapTableHeader
