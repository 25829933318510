<template>
  <div class="dt-DashboardGroupsToolbar">
    <div class="dt-DashboardGroupsToolbar_LeftSide">
      <portal-target
        :slot-props="{ tab: EVENT_SOURCES.DASHBOARD_PERFORMANCE }"
        name="dashboard-interval-select"
      />
      <OkrFilterSelect
        :bottom-fixed-items="getBottomFixedItemsClearSelection('selectedAssigneeIds')"
        :loading="areAssigneesLoading"
        :model-value="selectedAssigneeIds"
        :options="assigneesOptions"
        :search-function="getAssignees"
        class="dt-DashboardGroupsToolbar_UserSelect"
        item-label="name"
        item-value="accountId"
        n-selected-label="filter.owners"
        prepend-icon="user-next"
        @update:model-value="changeSelectedAssigneeIds"
        @update:options="assignees = $event"
      >
        <template #option-label="{ option }">
          <OwnerFieldOption :option="option" />
        </template>
        <template #bottom-fixed-items="{ bottomFixedItems }">
          <div v-for="item in bottomFixedItems" :key="item.id">
            <BottomFixedSelectItem
              v-if="isClearSelectionAction(item.action)"
              @click="bottomFixedItemsHandle(item.action, 'selectedAssigneeIds')"
            >
              {{ $t(item.text) }}
            </BottomFixedSelectItem>
          </div>
        </template>
      </OkrFilterSelect>
      <OkrFilterSelect
        :bottom-fixed-items="getBottomFixedItemsClearSelection('selectedGroupIds')"
        :dropdown-min-width="300"
        :loading="areGroupsLoading"
        :model-value="selectedGroupIds"
        :options="groupsOptions"
        :search-function="getGroups"
        class="dt-DashboardGroupsToolbar_GroupSelect"
        n-selected-label="filter.groups"
        prepend-icon="team-next"
        @update:model-value="changeSelectedGroupIds"
        @update:options="groups = $event"
      >
        <template #option-label="{ option }">
          <GlobalGroupsSelectOption v-if="option" :group="option" />
        </template>
        <template #bottom-fixed-items="{ bottomFixedItems }">
          <div v-for="item in bottomFixedItems" :key="item.id">
            <BottomFixedSelectItem
              v-if="isClearSelectionAction(item.action)"
              @click="bottomFixedItemsHandle(item.action, 'selectedGroupIds')"
            >
              {{ $t(item.text) }}
            </BottomFixedSelectItem>
          </div>
        </template>
      </OkrFilterSelect>

      <AppRadioGroupCommon
        :model-value="currentRouteName"
        :options="getPageOptions"
        name="navigation"
        v-bind="RADIO_BUTTON_STYLES"
        @update:model-value="changePage"
      />
      <AppRadioGroup
        :model-value="selectedTableView"
        :options="getTableViewOptions"
        name="table-filter"
        v-bind="RADIO_BUTTON_STYLES"
        @update:model-value="changeTableView"
      >
        <template #item-label="{ item }">
          <AppRadioGroupNextOptionWithIcon :option="item" gap="4px" />
        </template>
      </AppRadioGroup>
    </div>
    <Search :model-value="searchString" @update:model-value="onUpdateSearchString" />
  </div>
</template>

<script setup>
import { computed, nextTick, onBeforeMount, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'

import AssigneesInfoApiHandler from '@/api/assignees-info'
import GlobalGroupsApiHandler from '@/api/global-groups'
import {
  isClearSelectionAction,
  SELECTS_WITH_CHECKBOX_ITEMS
} from '@/composables/bottom-fixed-items'
import { ROUTE_NAMES } from '@/routes/route-helpers'
import {
  EVENT_SOURCES,
  FILTER_LABELS_FOR_TRACKING,
  TAB_NAMES_FOR_TRACKING,
  trackUserOpenedDashboardsEvent
} from '@/tracking/amplitude-helpers'
import { getTableViewOptions, STATUS_TABLE_VIEW } from '@/utils/dashboard'
import { handleError } from '@/utils/error-handling'
import {
  DEFAULT_VALUE_FOR_FILTER,
  SELECT_ALL_VALUE,
  sendFilterTrackerEvent
} from '@/utils/okr-elements/filters'
import { getNewSelectWithSelectAllValue } from '@/utils/select'
import { PERFORMANCE_REPORT_FILTERS, USER_SETTINGS_MAPPER } from '@/utils/user-settings'

import OwnerFieldOption from '@/components/form/OwnerFieldOption'
import GlobalGroupsSelectOption from '@/components/global-groups/GlobalGroupsSelectOption'
import BottomFixedSelectItem from '@/components/objectives/toolbar/BottomFixedSelectItem'
import OkrFilterSelect from '@/components/objectives/toolbar/OkrFilterSelect'
import AppRadioGroup from '@/components/ui/AppRadioGroup/AppRadioGroup'
import AppRadioGroupCommon from '@/components/ui/AppRadioGroup/AppRadioGroupCommon'
import AppRadioGroupNextOptionWithIcon from '@/components/ui/AppRadioGroup/AppRadioGroupNextOptionWithIcon'
import Search from '@/components/ui/Search/Search'

defineOptions({
  name: 'DashboardToolbar'
})

const props = defineProps({
  searchString: {
    type: String,
    default: ''
  },

  selectedAssigneeIds: {
    type: Array,
    default: () => DEFAULT_VALUE_FOR_FILTER
  },

  selectedGroupIds: {
    type: Array,
    default: () => DEFAULT_VALUE_FOR_FILTER
  },

  selectedTableView: {
    type: String,
    default: STATUS_TABLE_VIEW
  }
})

const emit = defineEmits({
  'update:searchString': null,
  'update:selectedGroupIds': null,
  'update:selectedAssigneeIds': null,
  'update:selected-table-view': null
})

const onUpdateSearchString = value => emit('update:searchString', value)
const router = useRouter()
const route = useRoute()
const { t } = useI18n()

const currentRouteName = computed(() => {
  switch (route.name) {
    case ROUTE_NAMES.DASHBOARD_PERFORMANCE_REPORT_BY_GROUPS:
      return ROUTE_NAMES.DASHBOARD_PERFORMANCE_REPORT_BY_GROUPS
    case ROUTE_NAMES.DASHBOARD_PERFORMANCE_REPORT_BY_PEOPLE:
      return ROUTE_NAMES.DASHBOARD_PERFORMANCE_REPORT_BY_PEOPLE
    default:
      return null
  }
})

const store = useStore()

watch(
  () => [
    currentRouteName.value,
    props.selectedAssigneeIds,
    props.selectedGroupIds,
    props.selectedTableView
  ],
  data => {
    if (currentRouteName.value) {
      store.dispatch('system/updateUserSettings', {
        [USER_SETTINGS_MAPPER[PERFORMANCE_REPORT_FILTERS]]: data
      })
    }
  }
)
const performanceReportFilters = computed(() => store.getters['system/performanceReportFilters'])
onBeforeMount(() => {
  if (performanceReportFilters.value) {
    const [routeName, assigneeIds, groupIds, selectedTableView] = performanceReportFilters.value
    changePage(routeName)
    emit('update:selectedAssigneeIds', assigneeIds)
    emit('update:selectedGroupIds', groupIds)
    emit('update:selected-table-view', selectedTableView)
  }
})

const getPageOptions = [
  {
    label: 'dashboard.by_group',
    value: ROUTE_NAMES.DASHBOARD_PERFORMANCE_REPORT_BY_GROUPS,
    icon: 'team-next',
    trackingMode: 'by groups'
  },
  {
    label: 'dashboard.all_people',
    value: ROUTE_NAMES.DASHBOARD_PERFORMANCE_REPORT_BY_PEOPLE,
    icon: 'user-next',
    trackingMode: 'people'
  }
]

const changePage = name => {
  router.push({ name })

  nextTick(() => {
    handleTracking()
  })
}

const handleTracking = () => {
  const mode = getPageOptions.find(item => item.value === currentRouteName.value).trackingMode

  const grouped = getTableViewOptions.find(
    item => item.value === props.selectedTableView
  ).trackingGroup

  trackUserOpenedDashboardsEvent({
    tab: TAB_NAMES_FOR_TRACKING.PERFORMANCE,
    mode,
    grouped
  })
}
const bottomFixedItemsHandle = (action, key) => {
  if (isClearSelectionAction(action)) {
    emit(`update:${key}`, DEFAULT_VALUE_FOR_FILTER)
  }
}

const areGroupsLoading = ref(false)
const groups = ref([])
const groupsOptions = computed(() => {
  return [
    {
      id: 0,
      name: t('filter.all_groups')
    },
    ...groups.value
  ]
})
const changeSelectedGroupIds = newValue => {
  const value = getNewSelectWithSelectAllValue(newValue, props.selectedGroupIds)
  emit('update:selectedGroupIds', value)

  sendFilterTrackerEvent({
    tab: EVENT_SOURCES.DASHBOARD_PERFORMANCE,
    section: EVENT_SOURCES.DASHBOARD,
    value,
    label: FILTER_LABELS_FOR_TRACKING.GROUPS
  })
}
const getGroups = async (searchString = null) => {
  const api = new GlobalGroupsApiHandler()
  try {
    return await api.getGroupsForFilter({
      searchString,
      workspaceIds: [currentWorkspaceId.value]
    })
  } catch (error) {
    handleError({ error })
  }
  return []
}
const getBottomFixedItemsClearSelection = key => {
  return props[key].includes(SELECT_ALL_VALUE) ? [] : SELECTS_WITH_CHECKBOX_ITEMS
}

const currentWorkspaceId = computed(() => {
  return route.params.workspaceId
})

watch(
  () => currentWorkspaceId.value,
  async (newValue, oldValue) => {
    if (newValue && newValue !== oldValue) {
      await getInitData()

      emit('update:selectedGroupIds', DEFAULT_VALUE_FOR_FILTER)

      emit('update:selectedAssigneeIds', DEFAULT_VALUE_FOR_FILTER)
    }
  }
)

const assignees = ref([])
const areAssigneesLoading = ref(false)
const assigneesOptions = computed(() => {
  return [
    {
      accountId: 0,
      name: t('filter.all_owners')
    },
    ...assignees.value
  ]
})

const getAssignees = async (searchString = null) => {
  if (!currentWorkspaceId.value) return
  let result = []
  const api = new AssigneesInfoApiHandler()

  const payload = {
    workspaceId: currentWorkspaceId.value,
    searchString,
    requiredUserAccountIds: props.selectedAssigneeIds
  }

  try {
    result = await api.getUsers(payload)
  } catch (error) {
    handleError({ error })
  }
  return result
}
const changeSelectedAssigneeIds = newValue => {
  const value = getNewSelectWithSelectAllValue(newValue, props.selectedAssigneeIds)
  emit('update:selectedAssigneeIds', value)

  sendFilterTrackerEvent({
    tab: EVENT_SOURCES.DASHBOARD_PERFORMANCE,
    section: EVENT_SOURCES.DASHBOARD,
    value,
    label: FILTER_LABELS_FOR_TRACKING.OWNERS
  })
}

const getInitData = async () => {
  areAssigneesLoading.value = true
  areGroupsLoading.value = true
  try {
    groups.value = await getGroups()
    assignees.value = await getAssignees()
  } catch (error) {
    handleError({ error })
  } finally {
    areAssigneesLoading.value = false
    areGroupsLoading.value = false
  }
}

onMounted(() => {
  getInitData()
})

const changeTableView = value => {
  emit('update:selected-table-view', value)

  nextTick(() => {
    handleTracking()
  })
}

const RADIO_BUTTON_STYLES = {
  size: 'xs',
  style: '--fill: var(--grey-3-next); --option-padding: 0px 8px 0 4px',
  type: 'primary-next'
}
</script>

<style lang="scss" scoped>
.dt-DashboardGroupsToolbar {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  --select-skeleton-left: 0;
  --select-skeleton-top: 0;
}
.dt-DashboardGroupsToolbar_LeftSide {
  display: flex;
  gap: 8px;
}
.dt-DashboardGroupsToolbar_GroupSelect,
.dt-DashboardGroupsToolbar_UserSelect {
  max-width: 200px;
}
</style>
