<template>
  <Component
    :is="CELL_COMPONENTS_BY_TYPE_ID[fieldParams.typeId]"
    v-if="!item.isNestedTask"
    :key="column"
    :field-id="column"
    :field-params="fieldParams"
    :item="item"
    :user-has-read-access="readable"
    :user-has-update-access="updatable"
    @update-field-value="onUpdateFieldValue"
  />
</template>

<script setup>
import ObjectivesInfoApiHandler from '@/api/okr-elements'
import { ALL_CUSTOM_FIELDS } from '@/utils/custom-fields/factory'
import { getResolvedValueForUpdateCustomFieldValue } from '@/utils/custom-fields/helpers'
import { useCustomFieldsHelpers } from '@/utils/custom-fields/use-custom-fields'
import { handleError } from '@/utils/error-handling'

import AssigneeCell from '@/components/custom-fields/okr-elements-table-cells/AssigneeCell'
import DateCell from '@/components/custom-fields/okr-elements-table-cells/DateCell'
import MoneyCell from '@/components/custom-fields/okr-elements-table-cells/MoneyCell'
import MultiSelect from '@/components/custom-fields/okr-elements-table-cells/MultiSelect'
import NumberCell from '@/components/custom-fields/okr-elements-table-cells/NumberCell'
import SingleSelect from '@/components/custom-fields/okr-elements-table-cells/SingleSelect'

defineOptions({
  name: 'OkrElementsTableCustomFieldsHub',
  inheritAttrs: false
})

const props = defineProps({
  column: {
    type: [String, Number],
    required: true
  },

  item: {
    type: Object,
    required: true
  },

  readable: {
    type: Boolean
  },

  updatable: {
    type: Boolean
  }
})

const { fieldParams } = useCustomFieldsHelpers({
  fieldId: props.column
})

const CELL_COMPONENTS_BY_TYPE_ID = {
  [ALL_CUSTOM_FIELDS.getTypeIds().MULTI_SELECT]: MultiSelect,
  [ALL_CUSTOM_FIELDS.getTypeIds().SINGLE_SELECT]: SingleSelect,
  [ALL_CUSTOM_FIELDS.getTypeIds().DATE]: DateCell,
  [ALL_CUSTOM_FIELDS.getTypeIds().ASSIGNEE]: AssigneeCell,
  [ALL_CUSTOM_FIELDS.getTypeIds().NUMBER]: NumberCell,
  [ALL_CUSTOM_FIELDS.getTypeIds().MONEY]: MoneyCell
}

const emit = defineEmits({
  loading: null,
  'update-custom-field-value': null
})

const onUpdateFieldValue = async ({ fieldId, value }) => {
  const { typeId } = fieldParams.value

  const isNumberOrMoneyField = [
    ALL_CUSTOM_FIELDS.getTypeIds().MONEY,
    ALL_CUSTOM_FIELDS.getTypeIds().NUMBER
  ].includes(typeId)

  if (isNumberOrMoneyField) {
    emit('loading', true)
  }

  const resolvedValue = getResolvedValueForUpdateCustomFieldValue({
    value,
    fieldTypeId: typeId
  })

  const api = new ObjectivesInfoApiHandler()
  try {
    const result = await api.updateOkrElementCustomFieldValue({
      elementId: props.item.id,
      fieldId,
      value: resolvedValue
    })

    emit('update-custom-field-value', {
      fieldId,
      value: result
    })
  } catch (error) {
    handleError({ error })
  } finally {
    if (isNumberOrMoneyField) {
      emit('loading', false)
    }
  }
}
</script>

<style lang="scss" scoped></style>
