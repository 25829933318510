<template>
  <div class="wgnu-Loader">
    <div v-for="row in ROWS" :key="row" class="wgnu-Loader_Row">
      <div v-if="withCheckbox" class="wgnu-RowCheckbox">
        <SkeletonItem :appearance="SQUARE" :size="XS" border-radius="4px" />
      </div>
      <SkeletonItem :appearance="CIRCLE" :size="SM" />

      <SkeletonItem :size="XS" :width="row" />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'WorkspaceGroupsNestedUsersLoader'
})
</script>

<script setup>
import {
  SKELETON_APPEARANCES,
  SKELETON_SIZES
} from '@/utils/components-configurations/skeleton-item'

import SkeletonItem from '@/components/ui/SkeletonLoaders/SkeletonItem'

const { CIRCLE, SQUARE } = SKELETON_APPEARANCES
const { SM, XS } = SKELETON_SIZES

const ROWS = ['112px', '70px', '150px', '40px', '92px']

defineProps({
  withCheckbox: {
    type: Boolean
  }
})
</script>

<style lang="scss" scoped>
.wgnu-Loader {
  padding: 0 40px 0 calc(var(--offset, 0) + 40px);
}

.wgnu-Loader_Row {
  height: 45px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.wgnu-RowCheckbox {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
}
</style>
