<template>
  <AppUnavailablePageLayout v-if="isOwner">
    <div class="alc-Wrapper">
      <JiraConnectionSettings is-connection-lost>
        <AppTitle :level="3" disable-top-margin>
          {{ t('menu.integrations') }}
        </AppTitle>
      </JiraConnectionSettings>
    </div>
  </AppUnavailablePageLayout>
  <LicenseInactiveView v-else />
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

import { PLUGIN_OPTIONS_KEYS } from '@root/template-options-keys'
import AppUnavailablePageLayout from '@shared-modules/components/AppUnavailablePageLayout'

import AppTitle from '@/components/ui/AppTitle/AppTitle'
import LicenseInactiveView from '@/views/LicenseInactiveView'
import JiraConnectionSettings from '@/views/workspaces/settings/integrations/pages/JiraConnectionSettings'

defineOptions({
  name: 'AppLostConnection'
})

const { t } = useI18n()

const store = useStore()

const isOwner = computed(() => store.state.pluginOptions[PLUGIN_OPTIONS_KEYS.IS_OWNER])
</script>

<style lang="scss" scoped>
.alc-Wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>
