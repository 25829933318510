import ApiHandler from '@/api/base'

class WeightApiHandler extends ApiHandler {
  async updateWeights(payload) {
    const { data } = await this.http.post('/updateWeights', payload)
    return data
  }
}

export default WeightApiHandler
