<template>
  <div class="br-Wrapper">
    <div class="br-TextWrapper">
      <span class="br-Title">
        {{ $t('subscription.subscription_free_trial') }}
      </span>
      <span class="br-Subtitle">
        {{ licenseStatusDaysLeft }} {{ $t('subscription.days_remaining') }}
      </span>
    </div>
    <AppButton v-if="isOwner" class="br-Btn" @click="onClickUpgrade">
      {{ $t('subscription.upgrade') }}
    </AppButton>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

import { ROUTE_NAMES } from '@/routes/route-helpers'
import { PLUGIN_OPTIONS_KEYS } from '@root/template-options-keys'

import AppButton from '@/components/ui/AppButton/AppButton'

defineOptions({
  name: 'BillingReminder'
})

const router = useRouter()
const onClickUpgrade = () => {
  router.push({ name: ROUTE_NAMES.SETTINGS_BILLING })
}

const store = useStore()

const isOwner = computed(() => store.state.pluginOptions[PLUGIN_OPTIONS_KEYS.IS_OWNER])
const licenseStatusDaysLeft = computed(
  () => store.state.pluginOptions[PLUGIN_OPTIONS_KEYS.LICENSE_STATUS_DAYS_LEFT]
)
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';

.br-Wrapper {
  border: 2px solid $grey-2-next;
  border-radius: $border-radius-md-next;
  padding: 20px 8px 8px;
  display: flex;
  flex-direction: column;
}

.br-TextWrapper {
  display: flex;
  flex-direction: column;
  padding: 0 12px;
  gap: 3px;
  margin-bottom: 20px;
}

.br-Title {
  color: $dark-2;
  font-size: $fs-14;
  line-height: 20px;
  font-weight: fw('semi-bold');
}

.br-Subtitle {
  color: $dark-3;
  font-size: $fs-12;
  line-height: 16px;
}

.br-Btn {
  background: $grade-high-color-next;
  @include activityStates($grade-high-color-next, 10%);
}
</style>
