import ApiHandler from '@/api/base'

class WorkspaceAdministratorsApiHandler extends ApiHandler {
  async getAdmins() {
    const { data } = await this.http.post('/getAllPluginAdmins')
    return data
  }

  async removeAdmin({ accountId = null } = {}) {
    const { data } = await this.http.post('/removeUserFromPluginAdmins', {
      accountId
    })
    return data
  }

  async addAdmin({ accountId = undefined } = {}) {
    const { data } = await this.http.post('/addUserToPluginAdmins', {
      accountId
    })
    return data
  }
}

export default WorkspaceAdministratorsApiHandler
