import { cloneDeep } from 'lodash'

import { SCHEDULE_PARTICIPANTS_ENTITY_KEYS } from '@/utils/entity-keys'

const {
  VALUE,
  LABEL,
  STRATEGY_TYPE_ID,
  GROUP_IDS,
  EXCLUDED_GROUP_IDS,
  USER_ACCOUNT_IDS,
  EXCLUDED_USER_ACCOUNT_IDS
} = SCHEDULE_PARTICIPANTS_ENTITY_KEYS

export const STRATEGY_OPTIONS = {
  ALL_USERS: {
    [VALUE]: 1,
    [LABEL]: 'filter.all_users'
  },
  SELECTED_SEGMENT: {
    [VALUE]: 2,
    [LABEL]: 'filter.selected_segment'
  }
}

export const DEFAULT_SCHEDULE_PARTICIPANTS = {
  [STRATEGY_TYPE_ID]: STRATEGY_OPTIONS.ALL_USERS[VALUE],
  [GROUP_IDS]: [],
  [EXCLUDED_GROUP_IDS]: [],
  [USER_ACCOUNT_IDS]: [],
  [EXCLUDED_USER_ACCOUNT_IDS]: []
}

const isAllUsersStrategySelected = strategyTypeId =>
  strategyTypeId === STRATEGY_OPTIONS.ALL_USERS[VALUE]

export const createParticipantsPayload = ({
  participants = DEFAULT_SCHEDULE_PARTICIPANTS
} = {}) => {
  const { strategyTypeId, groupIds, excludedGroupIds, userAccountIds, excludedUserAccountIds } =
    participants

  const isAllUsersStrategy = isAllUsersStrategySelected(strategyTypeId)

  const payload = {
    strategyTypeId,
    groupIds: isAllUsersStrategy ? null : groupIds,
    userAccountIds: isAllUsersStrategy ? null : userAccountIds,
    excludedUserAccountIds,
    excludedGroupIds: isAllUsersStrategy ? excludedGroupIds : null
  }

  if (isAllUsersStrategy) {
    return payload
  } else {
    const normalizedExcludedUsers = excludedUserAccountIds.filter(
      item => !userAccountIds.includes(item)
    )

    return {
      ...payload,
      excludedUserAccountIds: normalizedExcludedUsers
    }
  }
}

export const parseParticipantsPayloadToFromModel = ({
  participants = DEFAULT_SCHEDULE_PARTICIPANTS
}) => {
  const { strategyTypeId, groupIds, excludedGroupIds, userAccountIds, excludedUserAccountIds } =
    participants

  const isAllUsersStrategy = isAllUsersStrategySelected(strategyTypeId)

  if (isAllUsersStrategy) {
    return {
      strategyTypeId,
      groupIds: cloneDeep(DEFAULT_SCHEDULE_PARTICIPANTS[GROUP_IDS]),
      excludedGroupIds,
      userAccountIds: cloneDeep(DEFAULT_SCHEDULE_PARTICIPANTS[USER_ACCOUNT_IDS]),
      excludedUserAccountIds
    }
  } else {
    return {
      strategyTypeId,
      groupIds,
      excludedGroupIds: cloneDeep(DEFAULT_SCHEDULE_PARTICIPANTS[EXCLUDED_GROUP_IDS]),
      userAccountIds,
      excludedUserAccountIds
    }
  }
}
