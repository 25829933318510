<template>
  <div :style="{ color: resolvedColor }" class="ogv-Value">
    {{ getSimplifiedNumber(getGradeDisplayValue(grade))[PLURALIZATION_KEYS.SIMPLIFIED] }}
  </div>
</template>

<script setup>
import { computed } from 'vue'

import { getGradeColorStyle, getGradeDisplayValue } from '@/utils/okr-elements/grade'
import { getSimplifiedNumber, PLURALIZATION_KEYS } from '@/utils/pluralization'

defineOptions({
  name: 'OkrGradeValue'
})

const props = defineProps({
  grade: {
    type: Number,
    required: true
  },
  color: {
    type: String,
    default: 'grade-default'
  }
})

const resolvedColor = computed(() => getGradeColorStyle(props.color).color)
</script>

<style lang="scss" scoped>
.ogv-Value {
  font-family: $system-ui;
  font-style: normal;
  font-size: $fs-56;
  font-weight: fw('medium');
  line-height: 1;
  max-width: 6ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:after {
    content: '%';
    font-weight: fw('semi-bold');
    font-size: $fs-32;
  }
}
</style>
