<template>
  <TargetCell />
</template>

<script setup>
import { computed, h, getCurrentInstance } from 'vue'

import { currentTargetVisibility } from '@/utils/cell-helper'
import { currentUserCanReadObjective, objectiveIsJiraTask } from '@/utils/objectives'
import { getScopeId } from '@/utils/render-function-helpers'
import { uid } from '@/utils/uid'

import CellValue from '@/components/objectives/table/cells/CellValue'

defineOptions({
  name: 'CurrentTargetCell'
})

const props = defineProps({
  item: {
    type: Object,
    required: true
  },

  readable: {
    type: Boolean
  },

  isKr: {
    type: Boolean
  }
})

const isTask = computed(() => objectiveIsJiraTask(props.item))
const isVisibleValue = computed(() => currentTargetVisibility(props.item))
const canRead = computed(() => currentUserCanReadObjective(props.item))
const id = `st${uid()}`

const TargetCell = () => {
  if (!canRead.value) {
    return null
  }

  if (props.item.isNestedTask) {
    return null
  }

  const scopeId = getScopeId(getCurrentInstance())

  if ((props.isKr || isTask.value) && isVisibleValue.value) {
    return h(
      CellValue,
      {
        id,
        'cell-value': '',
        'user-has-update-access': false,
        'user-has-read-access': props.readable
      },
      // arrow function is fix for "Non-function value encountered for default slot. Prefer function slots for better performance." warning
      () => [
        isVisibleValue.value
          ? [
              h(
                'span',
                {
                  ...scopeId,
                  class: 'ctc-CurrentValue'
                },
                props.item.currentValue
              ),
              ' ', // space between values
              h('span', { ...scopeId, class: 'ctc-TargetValue' }, `/ ${props.item.targetValue}`)
            ]
          : null
      ]
    )
  }
}
</script>

<style lang="scss" scoped>
/* eslint-disable vue-scoped-css/no-unused-selector */

.ctc-CurrentValue {
  font-family: $system-ui;
  font-style: normal;
  font-weight: fw('semi-bold');
  font-size: $fs-14;
  line-height: 20px;
  color: $dark-1;
}
.ctc-TargetValue {
  font-family: $system-ui;
  font-weight: fw('regular');
  color: $dark-3;
}

/* eslint-enable vue-scoped-css/no-unused-selector */
</style>
