import { isEmpty } from 'lodash'
import { computed } from 'vue'
import { useStore } from 'vuex'

import i18n from '@/i18n'
import { ADDITIONAL_PARAMS_SETTINGS, ALL_CUSTOM_FIELDS } from '@/utils/custom-fields/factory'

export const useCustomFieldsHelpers = ({ fieldId } = {}) => {
  if (!String(fieldId).trim()) {
    throw new Error('Field id is required')
  }
  const store = useStore()

  const { t } = i18n.global

  const fieldParams = computed(() => {
    // check store existing cause in jira gadget we haven't store and that might crash app
    if (!store) {
      return null
    }
    return store.getters['customFields/fieldById']({ fieldId })
  })

  const isCustomField = computed(() => {
    // check store existing cause in jira gadget we haven't store and that might crash app
    if (!store) {
      return false
    }
    return store.getters['customFields/isCustomField']({ fieldId })
  })

  const helpText = computed(() => fieldParams.value?.helpText || null)
  const fieldName = computed(() => fieldParams.value?.name || null)

  const options = computed(() => {
    const isSelectField = [
      ALL_CUSTOM_FIELDS.getTypeIds().SINGLE_SELECT,
      ALL_CUSTOM_FIELDS.getTypeIds().MULTI_SELECT
    ].includes(fieldParams.value.typeId)
    if (!isSelectField) {
      throw new Error('This field is not a select field')
    }

    const NOT_SET_OPTION = {
      id: null,
      value: null,
      isNotSetOption: true,
      name: t('field.not_set')
    }

    const fieldOptions =
      fieldParams.value.additionalParameters[ADDITIONAL_PARAMS_SETTINGS.OPTIONS.typeId]

    if (fieldOptions && !isEmpty(fieldOptions.value)) {
      const result = fieldOptions.value
      if (fieldParams.value.typeId === ALL_CUSTOM_FIELDS.getTypeIds().SINGLE_SELECT) {
        return [
          {
            ...NOT_SET_OPTION
          },
          ...result
        ]
      }
      return result
    }

    return []
  })

  const currency = computed(() => {
    if (fieldParams.value.typeId === ALL_CUSTOM_FIELDS.getTypeIds().MONEY) {
      return (
        fieldParams.value.additionalParameters[ADDITIONAL_PARAMS_SETTINGS.CURRENCY.typeId].value ||
        ''
      )
    }

    throw new Error('This field is not a money field')
  })

  const emptyStateLabel = computed(() => {
    const isTextField = [
      ALL_CUSTOM_FIELDS.getTypeIds().SINGLE_LINE_TEXT,
      ALL_CUSTOM_FIELDS.getTypeIds().LONG_TEXT
    ].includes(fieldParams.value.typeId)

    const keyPath = isTextField
      ? 'custom_fields.text_field.placeholder'
      : 'custom_fields.field.placeholder'
    return t(keyPath, {
      fieldName: fieldName.value
    })
  })

  const columnName = computed(() => {
    return isCustomField.value ? fieldName.value : t(`objectives.table_header_${fieldId}`)
  })

  return {
    options,
    helpText,
    fieldName,
    emptyStateLabel,
    currency,
    isCustomField,
    fieldParams,
    columnName
  }
}
