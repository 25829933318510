<script>
import { defineComponent, h } from 'vue'

import {
  CONNECTION_ARC_RADIUS,
  CONTROLS_BUTTON_LEFT_OFFSET,
  STABILIZER_1,
  STABILIZER_2
} from '@/utils/connections'
import {
  CONTROLS_BUTTON_WIDTH,
  memoizeGetConnectionWithoutArcHeight,
  memoizeGetDefaultItemConnectionBottomArcPath,
  memoizeGetDefaultItemConnectionMinY,
  memoizeGetDefaultItemConnectionTopArcPath,
  memoizeGetFilteredItemConnectionArcPath,
  memoizeGetFilteredItemConnectionMinY,
  memoizeGetTargetAgainstSourceNodeConnectionPath,
  memoizeLineTo
} from '@/utils/memoizations'

export default defineComponent({
  name: 'NodeConnection',

  props: {
    from: {
      type: Object,
      required: true
    },

    to: {
      type: Object,
      required: true
    },

    dashed: {
      type: Boolean
    },

    offsetX: {
      type: Number,
      default: CONTROLS_BUTTON_WIDTH + CONTROLS_BUTTON_LEFT_OFFSET
    },

    forFilteredItem: {
      type: Boolean
    },

    strokeColor: {
      type: String,
      default: props => {
        return props.forFilteredItem ? 'var(--white-color)' : 'var(--primary-color-next)'
      }
    }
  },

  /*  render() {
    const { from, to, dashed, offsetX } = this

    const startX = from.x + offsetX
    const startY = from.y

    // horizontal line that smooths start and end of the connection
    const stabilizer = 5

    const curveStartX = startX + stabilizer

    const curveEndX = to.x - stabilizer
    const curveEndY = to.y

    const curveWidth = Math.abs(curveEndX - curveStartX)

    const c1 = `${curveStartX + curveWidth / 2} ${from.y}`
    const c2 = `${curveStartX + curveWidth / 2} ${curveEndY}`

    const d = `M ${startX} ${startY} h ${stabilizer} C ${c1} ${c2} ${curveEndX} ${curveEndY} h ${stabilizer}`
    const attrs = {
       stroke: '#E0E0E0',
      'stroke-width': '2',
      'stroke-dasharray': dashed ? 6 : 0,
      fill: 'transparent',
      d
    }
    return h('path', { ...attrs })
  }*/

  render() {
    const { from, to, dashed, offsetX, strokeColor, forFilteredItem } = this

    const TOLERANCE = 5 // to avoid the arc to be too close to the target

    const isTargetAgainstSource = from.y === to.y
    let d = ''

    if (forFilteredItem) {
      const defaultStartX = from.x + offsetX
      const defaultStartY = from.y

      const startX = isTargetAgainstSource
        ? defaultStartX
        : defaultStartX - CONTROLS_BUTTON_WIDTH / 2
      const startY = isTargetAgainstSource
        ? defaultStartY
        : defaultStartY + CONTROLS_BUTTON_WIDTH / 2
      if (isTargetAgainstSource) {
        d = memoizeGetTargetAgainstSourceNodeConnectionPath(startX, startY, to.x)
      } else {
        const minYForStaticPath = memoizeGetFilteredItemConnectionMinY(startY)

        d = `M ${startX} ${startY}`

        // if target.y is to small we must draw a specific static path
        // to avoid glitches
        if (to.y <= minYForStaticPath + TOLERANCE) {
          const arcPath = memoizeGetFilteredItemConnectionArcPath(
            startX,
            startY + CONNECTION_ARC_RADIUS
          )
          d = `${d}
              ${arcPath}
              ${memoizeLineTo(to.x, startY + CONNECTION_ARC_RADIUS)}
              `
        } else {
          const heightWithoutArc = memoizeGetConnectionWithoutArcHeight(to.y, startY)
          const arcPath = memoizeGetFilteredItemConnectionArcPath(startX, to.y)
          d = `${d}
              v ${CONNECTION_ARC_RADIUS}
              v ${heightWithoutArc}
              ${arcPath}
              ${memoizeLineTo(to.x, to.y)}
              `
        }
      }
    } else {
      const startY = from.y
      const startX = from.x + offsetX

      const TOP_ARC_END_Y = startY + CONNECTION_ARC_RADIUS

      if (isTargetAgainstSource) {
        d = memoizeGetTargetAgainstSourceNodeConnectionPath(startX, startY, to.x)
      } else {
        const minYForStaticPath = memoizeGetDefaultItemConnectionMinY(TOP_ARC_END_Y)

        d = `M ${startX} ${startY} h ${STABILIZER_1}`
        const topArcPath = memoizeGetDefaultItemConnectionTopArcPath(startX, TOP_ARC_END_Y)

        // if target.y is to small we must draw a specific static path
        // to avoid glitches
        if (to.y <= minYForStaticPath + TOLERANCE) {
          const bottomArcPath = memoizeGetDefaultItemConnectionBottomArcPath(
            startX,
            TOP_ARC_END_Y + STABILIZER_1 + CONNECTION_ARC_RADIUS
          )

          d = `${d}
              ${topArcPath}
              v ${STABILIZER_1}
              ${bottomArcPath}
              h ${STABILIZER_2}`
        } else {
          const heightWithoutArc = memoizeGetConnectionWithoutArcHeight(to.y, startY)
          const bottomArcPath = memoizeGetDefaultItemConnectionBottomArcPath(startX, to.y)

          d = `${d}
              ${topArcPath}
              v ${STABILIZER_1}
              v ${heightWithoutArc}
              ${bottomArcPath}
              h ${STABILIZER_2}`
        }
      }
    }

    const attrs = {
      stroke: strokeColor,
      'stroke-width': '2',
      'stroke-dasharray': dashed ? 8 : 0,
      fill: 'transparent',
      d: d.replace(/\s\s+/g, ' ').trim() // remove extra spaces and combine path to one line
    }

    return h('path', { ...attrs })
  }
})
</script>
