<template>
  <AppModalWithConfirmation
    ref="appModalWithConfirmation"
    :confirm-close="areDataChanged"
    :show="show"
    :title="$t('token.create.title')"
    class="tm-TokenModal"
    hide-hero
    @on-close="onTokenModalClose"
  >
    <div class="tm-LabelNote">
      {{ $t('token.create.label_note') }}
    </div>
    <FormFieldNext :label="$t('tokens.label')">
      <AppInput
        ref="name"
        v-model.trim="formModel.name"
        :max-length="TOKEN_NAME_MAX_LENGTH"
        size="xlg"
        style-type="primary"
        @update:model-value="onTokenInput"
      />
    </FormFieldNext>

    <template #footer-actions>
      <AppButton type="ghost-next" @click="close">
        {{ $t('action.cancel') }}
      </AppButton>
      <AppButton
        :disable="loading || isNameEmpty"
        :loading="loading"
        type="primary-next"
        @click="save"
      >
        {{ $t('action.create') }}
      </AppButton>
    </template>
  </AppModalWithConfirmation>
</template>

<script>
import { defineComponent } from 'vue'

import WorkspaceTokensApiHandler from '@/api/workspace-tokens'
import { handleError } from '@/utils/error-handling'
import { isStringEmpty } from '@/utils/general'

import AppModalWithConfirmation from '@/components/AppModalWithConfirmation'
import FormFieldNext from '@/components/form/FormFieldNext'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppInput from '@/components/ui/AppInput/AppInput'

const tokensApi = new WorkspaceTokensApiHandler()

const DEFAULT_FORM_MODEL = {
  name: ''
}

const TOKEN_NAME_MAX_LENGTH = 255

export default defineComponent({
  name: 'TokenModal',

  components: {
    FormFieldNext,
    AppModalWithConfirmation,
    AppButton,
    AppInput
  },

  props: {
    show: {
      type: Boolean,
      default: false
    }
  },

  emits: { 'update:show': null, create: null },

  data() {
    return {
      formModel: { ...DEFAULT_FORM_MODEL },
      localFormModel: { ...DEFAULT_FORM_MODEL },
      isNameEmpty: false,
      isTokenLong: false,
      loading: false
    }
  },

  computed: {
    TOKEN_NAME_MAX_LENGTH: () => TOKEN_NAME_MAX_LENGTH,
    areDataChanged() {
      return JSON.stringify(this.localFormModel) !== JSON.stringify(this.formModel)
    }
  },

  watch: {
    show: {
      async handler(bool) {
        if (!bool) {
          this.onTokenModalClose()
          return
        }

        this.setFocusOnName()
      },

      immediate: true
    }
  },

  methods: {
    onTokenModalClose() {
      this.formModel = JSON.parse(JSON.stringify({ ...DEFAULT_FORM_MODEL }))
      this.localFormModel = JSON.parse(JSON.stringify({ ...DEFAULT_FORM_MODEL }))
      this.isNameEmpty = false
      this.isTokenLong = false

      this.$emit('update:show', false)
    },

    async save() {
      if (this.formModel.name.length > TOKEN_NAME_MAX_LENGTH) {
        this.isTokenLong = true
        return
      }

      if (isStringEmpty(this.formModel.name)) {
        this.isNameEmpty = true
        return
      }

      this.loading = true
      let token = null

      try {
        token = await tokensApi.createToken({ name: this.formModel.name })
        this.$emit('create', token)
      } catch (error) {
        handleError({ error })
      }

      this.loading = false
    },

    close() {
      this.$refs.appModalWithConfirmation.close()
    },

    setFocusOnName() {
      // nextTick is needed to make input always focused on modal window
      // opening because modal window(o-modal) has transition of opacity with 0.2s
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.name.focus()
        }, 100)
      })
    },

    onTokenInput() {
      this.isTokenLong = this.formModel.name.length > TOKEN_NAME_MAX_LENGTH
      this.isNameEmpty = isStringEmpty(this.formModel.name)
    }
  }
})
</script>

<style lang="scss" scoped>
.tm-LabelNote {
  margin-bottom: 23px;
  font-family: $system-ui;
}
</style>

<style lang="scss">
.o-form-error.tm-TokenNameFieldError {
  min-height: 20px;
}

.tm-TokenModal .o-modal-footer {
  padding-top: 4px;
}
</style>
