import { isNull } from 'lodash'

import ApiHandler from '@/api/base'
import { REQUEST_ENTITY_KEYS } from '@/utils/entity-keys'
import { DEFAULT_USERS_LIMIT } from '@/utils/workspace-configuration-modal-helpers'

class AssigneesInfoApiHandler extends ApiHandler {
  async getUsers({ searchString = null, requiredUserAccountIds = null, workspaceId = null }) {
    const resolvedRequiredUserAccountIds = isNull(searchString) ? requiredUserAccountIds : null

    const { data } = await this.http.get('/internal/v2/usersForFilter', {
      params: {
        searchString,
        [REQUEST_ENTITY_KEYS.REQUIRED_USERS_ACCOUNT_IDS]: resolvedRequiredUserAccountIds,
        workspaceId,
        [REQUEST_ENTITY_KEYS.LIMIT]: DEFAULT_USERS_LIMIT,
        [REQUEST_ENTITY_KEYS.START_AT]: 0
      }
    })

    return data
  }
}

export default AssigneesInfoApiHandler
