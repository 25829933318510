<template>
  <div
    :class="{
      'ts-TitleSelectItem': true,
      'ts-TitleSelectItem-active': active
    }"
  >
    <AppTitle :level="level" class="ts-TitleSelectItem_Text" disable-margin>
      <slot />
    </AppTitle>
    <div class="ts-ArrowIconWrapper">
      <AppIcon height="24" icon-name="arrow-down-black" width="24" />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

import AppIcon from '@/components/ui/AppIcon/AppIcon'
import AppTitle from '@/components/ui/AppTitle/AppTitle'

export default defineComponent({
  name: 'TitleSelectItem',

  components: {
    AppTitle,
    AppIcon
  },

  props: {
    level: {
      type: Number,
      default: 4
    },

    active: {
      type: Boolean
    }
  }
})
</script>

<style lang="scss" scoped>
.ts-TitleSelectItem {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    cursor: pointer;
    color: $primary-color;
  }
}

.ts-TitleSelectItem_Text {
  .ts-TitleSelectItem-active &,
  .ts-TitleSelectItem:hover & {
    color: $primary-color;
  }
}

.ts-ArrowIconWrapper {
  flex-shrink: 0;
  height: 24px;
  transition: transform $transition-fast ease-in-out;

  .ts-TitleSelectItem-active & {
    transform: rotate(180deg);
    color: $primary-color;
  }
}
</style>
