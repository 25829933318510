<template>
  <BulkActionsOperationValueControl
    v-model="localModelValue"
    :field-id="fieldTypeId"
    is-custom-field
  >
    <template #value-controller>
      <AppDroplist
        v-model="opened"
        :offset="[0, 0]"
        :theme="DROP_LIST_THEMES.LIGHT"
        position="bottom"
      >
        <template #button>
          <div
            :class="{
              'df-Trigger-active': opened
            }"
            class="df-Trigger"
          >
            {{ displayValue }}

            <AppIcon height="24" icon-name="calendar-next" width="24" />
          </div>
        </template>
        <DatePicker
          :clearable="false"
          :format="dateFormat"
          :inline="true"
          :lang="datepickerLang()"
          :model-value="innerModelValue"
          @update:model-value="onDatePickerInput"
        >
        </DatePicker>
      </AppDroplist>
    </template>
  </BulkActionsOperationValueControl>
</template>

<script setup>
import dayjs from 'dayjs'
import { isNull } from 'lodash'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import DatePicker from 'vue2-datepicker'

import { DROP_LIST_THEMES } from '@/utils/components-configurations/app-droplist'
import { useCustomFieldsHelpers } from '@/utils/custom-fields/use-custom-fields'
import {
  dateFormat,
  datepickerLang,
  getValidDueOrStartDate,
  localDateToUtc,
  UNSELECTED_DATE
} from '@/utils/date'
import { OKR_BULK_UPDATE_ENTITY_KEYS } from '@/utils/entity-keys'
import { OKR_DATES_SELECT_DATE_PROPS } from '@/utils/okr-element-dates'
import { BULK_FIELD_OPERATION_TYPE_IDS } from '@/utils/okr-elements-table-bulk-actions'

import AppDroplist from '@/components/AppDroplist'
import BulkActionsOperationValueControl from '@/components/objectives/bulk-edit/BulkActionsOperationValueControl'
import AppIcon from '@/components/ui/AppIcon/AppIcon'

defineOptions({
  name: 'DateField',
  inheritAttrs: false
})

const props = defineProps({
  modelValue: {
    type: Object,
    required: true
  },

  fieldTypeId: {
    type: [String, Number],
    required: true
  },

  fieldId: {
    type: [String, Number],
    required: true
  }
})

const { t } = useI18n()

const opened = ref(false)

const emit = defineEmits({
  'update:model-value': null
})

const onDatePickerInput = async date => {
  const value = localDateToUtc(getValidDueOrStartDate(date, OKR_DATES_SELECT_DATE_PROPS.START_DATE))

  emit('update:model-value', {
    [OKR_BULK_UPDATE_ENTITY_KEYS.OPERATION]:
      props.modelValue[OKR_BULK_UPDATE_ENTITY_KEYS.OPERATION] ||
      BULK_FIELD_OPERATION_TYPE_IDS.REPLACE,
    value
  })

  opened.value = false
}
const innerModelValue = computed(() => {
  if (!isNull(props.modelValue.value)) {
    return dayjs(props.modelValue.value).toDate()
  }

  return UNSELECTED_DATE
})

const { fieldName } = useCustomFieldsHelpers({ fieldId: props.fieldId })

const displayValue = computed(() => {
  const { value } = props.modelValue
  if (value && !isNull(value)) {
    return dayjs(value).format(dateFormat)
  }

  return t('bulk_actions.select_custom_field_value', {
    customFieldName: fieldName.value
  })
})

const localModelValue = computed({
  get: () => {
    const { value, operation } = props.modelValue
    return {
      value,
      operation
    }
  },
  set: value => {
    emit('update:model-value', value)
  }
})
</script>

<style lang="scss" scoped>
.df-Trigger {
  min-height: 40px;
  width: 100%;
  border-radius: $border-radius-sm-next;
  border: 2px solid $grey-2-next;
  transition: border-color $transition-fast;
  cursor: pointer;
  padding: 8px 6px 8px 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  &-active {
    border-color: $primary-color-next;
  }
}
</style>
