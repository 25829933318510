import { computed, ref } from 'vue'

export const ITEMS_ON_PAGE_DEFAULT = 50
const START_PAGE = 1

export const useGetPaginationData = (itemsOnPageDefault = ITEMS_ON_PAGE_DEFAULT) => {
  const currentPage = ref(START_PAGE)
  const itemsOnPage = ref(itemsOnPageDefault)
  const total = ref(0)
  const totalPage = computed(() => Math.ceil(total.value / itemsOnPage.value))

  return {
    START_PAGE,
    currentPage,
    itemsOnPage,
    total,
    totalPage
  }
}
