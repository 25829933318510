<template>
  <div>
    <div class="dad-Header">
      <AppButton
        class="dad-Header-Button"
        height="24"
        icon="arrow-left-next"
        remove-padding
        size="sm"
        type="ghost-next"
        width="24"
        @click="backToCustomDashboardList"
      >
        {{ $t('dashboard.menu.custom_dashboard', 2) }}
      </AppButton>
    </div>
    <div class="dad-ContentWrapper">
      <ObjectivesEmptyHero height="240" name="no-license-hero" width="424" />
      <h2 class="dad-Title">{{ $t('dashboard.access_denied') }}</h2>
      <span class="dad-description">{{ $t('dashboard.you_dont_have_permission') }}</span>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router'

import { ROUTE_NAMES } from '@/routes/route-helpers'

import ObjectivesEmptyHero from '@/components/objectives/ObjectivesEmptyHero'
import AppButton from '@/components/ui/AppButton/AppButton'

defineOptions({
  name: 'DashboardAccessDenied'
})

const router = useRouter()

const backToCustomDashboardList = () => {
  router.push({ name: ROUTE_NAMES.CUSTOM_DASHBOARD_LIST })
}
</script>

<style lang="scss" scoped>
.dad-Header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}
.dad-Header-Button {
  color: $primary-color-next;
}
.dad-Title {
  font-weight: fw('medium');
  font-size: $fs-32;
  line-height: 40px;
  text-align: center;
  color: $dark-1;
  margin: 0 0 16px 0;
}
.dad-ContentWrapper {
  text-align: center;
  font-family: $system-ui;
}
</style>
