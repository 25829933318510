<template>
  <div class="scl-Loader">
    <div v-for="row in ROWS" :key="row.id" class="scl-Loader_Row">
      <SkeletonItem :size="XS" :width="row.title" />
      <SkeletonItem v-for="subtitle in row.subtitle" :key="subtitle" :size="XS" :width="subtitle" />

      <div class="scl-Row_Input">
        <SkeletonItem :size="row.inputSize || LG" :width="row.input" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { SKELETON_SIZES } from '@/utils/components-configurations/skeleton-item'

import SkeletonItem from '@/components/ui/SkeletonLoaders/SkeletonItem'

defineOptions({
  name: 'ScheduleModalLoader'
})

const { LG, XS } = SKELETON_SIZES

const ROWS = [
  {
    id: 0,
    title: '150px',
    subtitle: ['220px'],
    input: '268px'
  },
  {
    id: 1,
    title: '280px',
    subtitle: ['410px'],
    input: '374px'
  },
  {
    id: 2,
    title: '165px',
    subtitle: ['520px'],
    input: '428px'
  },
  {
    id: 3,
    title: '94px',
    subtitle: ['465px', '420px'],
    width: '190px',
    inputSize: XS
  }
]
</script>

<style lang="scss" scoped>
.scl-Loader {
  display: grid;
  gap: 44px;
}

.scl-Loader_Row {
  display: grid;
  gap: 5px;
}

.scl-Row_Input {
  margin-top: 8px;
}
</style>
