const keydownCallbacks = {}

export const pushKeydownEventListener = (key, listener) => {
  if (!(key in keydownCallbacks)) {
    keydownCallbacks[key] = [listener]
  } else {
    keydownCallbacks[key].push(listener)
  }
}

export const unshiftKeydownEventListener = (key, listener) => {
  if (!(key in keydownCallbacks)) {
    keydownCallbacks[key] = [listener]
  } else {
    keydownCallbacks[key].unshift(listener)
  }
}

export const removeKeydownEventListener = (key, listener) => {
  if (!(key in keydownCallbacks)) {
    return
  }
  const listenerIndex = keydownCallbacks[key].findIndex(callback => callback === listener)
  if (listenerIndex !== -1) {
    keydownCallbacks[key].splice(listenerIndex, 1)
  }
}

export const raiseKeydownEvent = (key, event) => {
  if (!(key in keydownCallbacks)) {
    return
  }
  for (const listener of keydownCallbacks[key]) {
    if (listener(event) === false) {
      break
    }
  }
}
