<template>
  <FormCaption
    :subtitle="t('schedule.notifications.subtitle')"
    :title="t('schedule.notifications.title')"
  >
    <div class="snm-Management">
      <AppCheckbox
        v-for="channel in SCHEDULE_NOTIFICATION_CHANNELS"
        :key="channel[NOTIFICATION_CHANNEL_ENTITY_KEYS.VALUE]"
        v-model="selectedChannelsModel"
        :data-auto-testid="NOTIFICATION_CHANNEL_TOGGLE_TEST_ID"
        :data-testid="NOTIFICATION_CHANNEL_TOGGLE_TEST_ID"
        :disabled="Boolean(channel[NOTIFICATION_CHANNEL_ENTITY_KEYS.DISABLED])"
        :value="channel[NOTIFICATION_CHANNEL_ENTITY_KEYS.VALUE]"
        name="channel-picker"
      >
        {{ channel[NOTIFICATION_CHANNEL_ENTITY_KEYS.LABEL] }}
      </AppCheckbox>
    </div>

    <div v-if="!isEmpty(webHooksFields)" class="snm-WebHooks">
      <ScheduleWebhooksSelect
        v-for="{ channelTypeId, attributeTypeId, uid } in webHooksFields"
        :key="uid"
        v-model="channelsAttributesModel[channelTypeId][attributeTypeId]"
        :channel-type-id="channelTypeId"
        :data-auto-testid="NOTIFICATION_WEBHOOK_FIELD_WRAPPER_TEST_ID"
        :data-testid="NOTIFICATION_WEBHOOK_FIELD_WRAPPER_TEST_ID"
        :loading="webHooksLoaders[uid] || false"
        :options="webHooksOptions[uid] || []"
        @request-options="
          getWebHooks({
            uid,
            attributeTypeId,
            channelTypeId
          })
        "
      />
    </div>
  </FormCaption>
</template>

<script setup>
import { isEmpty } from 'lodash'
import { computed, reactive } from 'vue'
import { useI18n } from 'vue-i18n'

import IntegrationApiHandler from '@/api/integrations'
import { SCHEDULE_NOTIFICATION_CHANNELS } from '@/utils/check-ins/schedules-helpers'
import { NOTIFICATION_CHANNEL_ENTITY_KEYS } from '@/utils/entity-keys'
import { handleError } from '@/utils/error-handling'
import { NOTIFICATIONS_CHANNELS_BY_TYPE_ID } from '@/utils/okr-elements/notifications'

import FormCaption from '@/components/check-ins/FormCaption'
import {
  NOTIFICATION_CHANNEL_TOGGLE_TEST_ID,
  NOTIFICATION_WEBHOOK_FIELD_WRAPPER_TEST_ID
} from '@/components/check-ins/jest-helpers'
import ScheduleWebhooksSelect from '@/components/check-ins/ScheduleWebhooksSelect'
import AppCheckbox from '@/components/ui/AppCheckbox/AppCheckbox'

defineOptions({
  name: 'ScheduleNotificationsManagement'
})

const { t } = useI18n()

const selectedChannelsModel = defineModel('selectedChannels', {
  type: Object,
  required: true,
  set: value => value
})

const channelsAttributesModel = defineModel('channelsAttributes', {
  type: Object,
  required: true,
  set: value => value
})

const webHooksOptions = reactive({})
const webHooksLoaders = reactive({})
const api = new IntegrationApiHandler()

const getWebHooks = async ({ uid, channelTypeId, attributeTypeId } = {}) => {
  if (!uid || !channelTypeId || !attributeTypeId) {
    throw new Error('getWebHooks: uid, channelTypeId and attributeTypeId are required')
  }

  try {
    webHooksLoaders[uid] = true

    const data = await api.getChannelsAttributes({
      attributeTypeId,
      channelTypeId
    })

    webHooksOptions[uid] = data.map(item => {
      const { id, attributeValue } = item
      return {
        id: id,
        label: attributeValue.name,
        value: id
      }
    })
  } catch (error) {
    handleError({ error })
  } finally {
    webHooksLoaders[uid] = false
  }
}

const NOTIFICATIONS_CHANNELS_TYPE_IDS = Object.values(SCHEDULE_NOTIFICATION_CHANNELS).map(
  ch => ch[NOTIFICATION_CHANNEL_ENTITY_KEYS.VALUE]
)

const webHooksFields = computed(() => {
  // that's need to render fields for selected channels in same order as they are rendered as checkboxes
  const selectedChannels = NOTIFICATIONS_CHANNELS_TYPE_IDS.filter(channelTypeId =>
    selectedChannelsModel.value.includes(channelTypeId)
  )
  return selectedChannels.reduce((acc, channelTypeId) => {
    const channelAttributes = channelsAttributesModel.value[channelTypeId]

    if (isEmpty(channelAttributes)) {
      return acc
    }

    const allAttributes = Object.keys(channelAttributes)
      .filter(attributeId => {
        const attributeOptions =
          NOTIFICATIONS_CHANNELS_BY_TYPE_ID[channelTypeId].attributes[attributeId] || {}

        return attributeOptions.isWebHook
      })
      .map(attributeTypeId => {
        return {
          channelTypeId,
          attributeTypeId: Number(attributeTypeId),
          uid: NOTIFICATIONS_CHANNELS_BY_TYPE_ID[channelTypeId].attributes[attributeTypeId].uid
        }
      })

    return [...acc, ...allAttributes]
  }, [])
})
</script>

<style lang="scss" scoped>
.snm-Management {
  display: flex;
  gap: 12px;
}

.snm-WebHooks {
  display: flex;
  flex-direction: column;
  gap: 44px;

  &:not(:empty) {
    margin-top: 44px;
  }
}
</style>
