<template>
  <AppDialog
    :show="show"
    :title="$t(title)"
    :type="DIALOG_TYPES.ERROR"
    @on-confirm="onConfirm"
    @on-close="onClose"
  >
    {{ $t('debug.confirm_migration_description') }}

    <template #confirm-btn-text>
      {{ $t('debug.start_migration') }}
    </template>
  </AppDialog>
</template>

<script>
import { defineComponent } from 'vue'

import { DIALOG_TYPES } from '@/utils/components-configurations/app-dialog'

import AppDialog from '@/components/AppDialog'

export default defineComponent({
  name: 'MigrationConfirmModal',

  components: {
    AppDialog
  },

  props: {
    show: {
      type: Boolean,
      default: false
    },

    title: {
      type: String,
      default: 'debug.confirm_migration_title'
    }
  },

  emits: { 'on-confirm': null, 'on-close': null },

  computed: {
    DIALOG_TYPES: () => DIALOG_TYPES
  },

  methods: {
    onClose() {
      this.$emit('on-close')
    },

    onConfirm() {
      this.$emit('on-confirm')
    }
  }
})
</script>

<style lang="scss" scoped></style>
