import { Node, VueNodeViewRenderer } from '@tiptap/vue-3'

import { TIPTAP_EXTENSION_NAMES } from '@/utils/tiptap-utils'

import ImageUploadComponent from './ImageUpload'

export const ImageUpload = Node.create({
  name: TIPTAP_EXTENSION_NAMES.IMAGE_UPLOAD,

  isolating: true,
  defining: true,
  group: 'block',
  draggable: true,
  selectable: true,
  inline: false,
  atom: true,

  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`
      }
    ]
  },

  renderHTML() {
    return ['div', { 'data-type': this.name }]
  },

  addCommands() {
    return {
      setImageUpload:
        () =>
        ({ commands }) => {
          commands.insertContent(`<div data-type="${this.name}"></div>`)
        }
    }
  },

  addNodeView() {
    return VueNodeViewRenderer(ImageUploadComponent)
  }
})

export default ImageUpload
