import { isLocalStorageAvailable, readFromStorageByKey, updateStorageByKey } from '@/utils/persist'

const LAST_SELECTED_WORKSPACE = 'LAST_SELECTED_WORKSPACE'

export const restoreLastSelectedWorkspace = userWorkspaces => {
  const [firstWorkspace] = userWorkspaces
  if (isLocalStorageAvailable()) {
    const lastSelectedWorkspace = readFromStorageByKey(LAST_SELECTED_WORKSPACE)

    const lastSelectedWorkspaceStillExist = userWorkspaces.some(
      workspace => workspace.id === lastSelectedWorkspace
    )

    const restoredWorkspaceId = lastSelectedWorkspaceStillExist
      ? lastSelectedWorkspace
      : firstWorkspace.id

    updateStorageByKey(LAST_SELECTED_WORKSPACE, restoredWorkspaceId)

    return restoredWorkspaceId
  } else {
    return firstWorkspace.id
  }
}

export const saveLastSelectedWorkspace = workspaceId => {
  if (isLocalStorageAvailable()) {
    updateStorageByKey(LAST_SELECTED_WORKSPACE, workspaceId)
  }
}
