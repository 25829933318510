<template>
  <TableGrid />
</template>

<script setup>
import { h, getCurrentInstance } from 'vue'

import { getScopeId } from '@/utils/render-function-helpers'
import { getTodayMarkerLeftOffset } from '@/utils/roadmap'

defineOptions({
  name: 'OkrTimelinesTableGrid'
})

const props = defineProps({
  data: {
    type: Array,
    required: true
  },

  oneDayWidth: {
    type: Number,
    required: true
  },

  activeView: {
    type: String,
    required: true
  }
})
const todayMarkerForWeeks = (item, scopeId) => {
  const width = props.oneDayWidth
  // find index to render only active date marker and skip "<!-- v-if -->" comments
  const index = item.dates.findIndex(day => day.active)
  return h('div', {
    class: ['otg-GridCell_TodayMarker', 'otg-GridCell_TodayMarker-weeks'],
    ...scopeId,
    style: {
      '--width': `${width}px`,
      '--left': `${index * width}px`
    }
  })
}

const todayMarker = (item, scopeId) => {
  if (item.includesToday) {
    return item.dates
      ? todayMarkerForWeeks(item, scopeId)
      : h('div', {
          class: 'otg-GridCell_TodayMarker',
          ...scopeId,
          style: {
            '--left': getTodayMarkerLeftOffset(item.todayNumber, props.oneDayWidth)
          }
        })
  } else {
    return null
  }
}

const TableGrid = () => {
  const scopeId = getScopeId(getCurrentInstance())
  return h(
    'div',
    {
      class: ['otg-TableGrid', `otg-TableGrid-${props.activeView}`]
    },
    props.data.map(item => {
      return h(
        'div',
        {
          key: item.key,
          ...scopeId,
          class: 'otg-GridCell',
          style: {
            '--cell-width': `${item.cellWidth}px`
          }
        },
        todayMarker(item, scopeId)
      )
    })
  )
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/roadmap';
/* this eslint plugin won't work with vue 3 render function in <script setup> */
/* eslint-disable vue-scoped-css/no-unused-selector */
.otg-TableGrid {
  height: 0;
  display: flex;
  pointer-events: none;
}

.otg-GridCell {
  position: relative;
  width: var(--cell-width);
  flex: 1 0 var(--cell-width);
  height: calc(#{var(--timelines-body-height, 0)} * 1px);

  &:not(&:last-child) {
    &:after {
      content: '';
      pointer-events: none;
      position: absolute;
      right: 0;
      top: 0;
      width: 1px;
      height: 100%;
      .otg-TableGrid-weeks & {
        width: calc(100% / 7) * 2;
        background: rgba($grey-3-next, 0.5);
        border-right: 1px solid $grey-2-next;
      }
    }
  }
}

.otg-GridCell_TodayMarker {
  height: 100%;
  position: absolute;
  z-index: 4;
  left: var(--left);

  &:not(&-weeks) {
    background: $today-marker-color;
    width: $today-line-width;
  }

  &-weeks {
    width: var(--width);

    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      height: 100%;
      width: $today-line-width;
      background: $today-marker-color;
      margin: auto;
    }
  }
}
/* eslint-enable vue-scoped-css/no-unused-selector */
</style>
