<template>
  <div class="oct-Wrapper">
    <div class="oct-Wrapper_Header">
      <div class="oct-Wrapper_Header_LeftSide">
        <AppRadioGroup
          v-if="showSplitToggle"
          :model-value="split"
          :options="splitOptions"
          name="chart-split"
          style="--option-padding: 0 8px 0 0"
          type="primary-next"
          @update:model-value="onSplitChange"
        >
          <template #item-label="{ item }">
            <AppRadioGroupNextOptionWithIcon :option="item" />
          </template>
        </AppRadioGroup>

        <AppRadioGroup
          :model-value="period"
          :options="periodOptions"
          name="time-periods"
          type="primary-next"
          @update:model-value="onChangePeriod"
        />
      </div>
      <!--      For the next release-->
      <!--      <AppButton type="tertiary" class="oct-Wrapper_Header_ButtonDownload">-->
      <!--        {{ $t('objective.chart.downloadCSV') }}-->
      <!--      </AppButton>-->
    </div>
    <div class="oct-Wrapper_Data">
      <AppTable
        :columns="columns"
        :data="rows"
        :disable-user-select="false"
        class="oct-Table"
        h-scrollable
        no-border
        style="--cell-padding: 12px 20px; --header-cell-padding: 0 20px"
        type="mini-next"
      >
        <template #header-cell-marker="{ cellStyle }">
          <div :style="cellStyle" class="oct-TableHeadCell-marker">
            <ObjectiveChartTableColorMark color="#B5BBC5" />
          </div>
        </template>
        <template #header-cell-checker="{ cellStyle }">
          <div :style="cellStyle" class="oct-TableHeadCell-checker">
            <AppCheckbox v-model="all" />
          </div>
        </template>

        <template #header-cell-name="{ cellStyle, column }">
          <div :style="cellStyle" class="oct-TableHeadCell-name">
            {{ column.title }}
          </div>
        </template>

        <template #body-cell-marker="{ item, cellStyle }">
          <div :style="cellStyle" class="oct-TableBodyCell-marker">
            <ObjectiveChartTableColorMark :color="item.color" />
          </div>
        </template>

        <template #body-cell-checker="{ item, cellStyle }">
          <div :style="cellStyle" class="oct-TableBodyCell-checker">
            <AppCheckbox v-model="active" :value="item.id" />
          </div>
        </template>
        <template #body-cell-name="{ item, cellStyle }">
          <div :style="cellStyle" class="oct-TableBodyCell-name">
            <OkrIconAndId
              v-if="item.typeId"
              :clickable="false"
              :okr-element="item"
              id-disabled-color="var(--primary-color-next)"
            />
            <slot :item="item" name="body-cell-name-text">
              <span class="oct-TableBodyCell_Text">{{ item.name }}</span>
            </slot>
          </div>
        </template>
      </AppTable>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

import { CHECKER_COLUMN_WIDTH, MARKER_COLUMN_WIDTH, PERIODS } from '@/utils/objective-chart'

import OkrIconAndId from '@/components/form/OkrIconAndId'
import ObjectiveChartTableColorMark from '@/components/objectives/chart/ObjectiveChartTableColorMark'
import AppCheckbox from '@/components/ui/AppCheckbox/AppCheckbox'
import AppRadioGroup from '@/components/ui/AppRadioGroup/AppRadioGroup'
import AppRadioGroupNextOptionWithIcon from '@/components/ui/AppRadioGroup/AppRadioGroupNextOptionWithIcon'
import AppTable from '@/components/ui/Table/AppTable'

export default defineComponent({
  name: 'ObjectiveChartTable',
  components: {
    OkrIconAndId,
    AppRadioGroupNextOptionWithIcon,
    AppRadioGroup,
    ObjectiveChartTableColorMark,
    AppCheckbox,
    AppTable
  },

  props: {
    columns: {
      type: Array,
      required: true
    },

    rows: {
      type: Array,
      required: true
    },

    split: {
      type: Boolean
    },

    showSplitToggle: {
      type: Boolean
    },

    period: {
      type: Number,
      default: null
    },

    availableGranulation: {
      type: Array,
      required: true
    }
  },

  emits: { 'update:split': null, update: null, 'update:period': null, setTimeIntervalsCount: null },

  data() {
    return {
      active: this.rows.map(row => row.id)
    }
  },

  computed: {
    CHECKER_COLUMN_WIDTH: () => CHECKER_COLUMN_WIDTH,
    MARKER_COLUMN_WIDTH: () => MARKER_COLUMN_WIDTH,

    shadowHeight() {
      // 45 is the height of one table
      return `${this.rows.length * 45}px`
    },

    splitOptions() {
      return [
        {
          value: false,
          label: 'okr_elements.objective',
          icon: 'chart-table-unsplit'
        },
        {
          value: true,
          label: 'objective.chart_table.split',
          icon: 'chart-table-split'
        }
      ]
    },

    periodOptions() {
      return [
        {
          value: PERIODS.DEFAULT.value,
          label: this.$t('common.default')
        },

        ...[
          {
            value: PERIODS.DAILY.value,
            label: this.$t('objective.chart.daily'),
            timeIntervalsCount: 60
          },
          {
            value: PERIODS.WEEKLY.value,
            label: this.$t('objective.chart.weekly'),
            timeIntervalsCount: 8
          },
          {
            value: PERIODS.MONTHLY.value,
            label: this.$t('objective.chart.monthly'),
            timeIntervalsCount: 14
          },
          {
            value: PERIODS.QUARTERLY.value,
            label: this.$t('objective.chart.quarterly'),
            timeIntervalsCount: 12
          },
          {
            value: PERIODS.YEARS.value,
            label: this.$t('objective.chart.years'),
            timeIntervalsCount: 4
          }
        ].filter(({ value }) => this.availableGranulation.includes(value))
      ]
    },

    activeRows() {
      return this.rows.filter(row => this.active.includes(row.id))
    },

    all: {
      get() {
        return this.activeRows.length === this.rows.length
      },

      set(flag) {
        if (flag) {
          this.active = this.rows.map(row => row.id)
        } else {
          this.active = []
        }
      }
    }
  },

  watch: {
    rows: {
      handler(rows) {
        this.all = true
        this.active = rows.map(row => row.id)
      },

      deep: true
    },

    active(active) {
      this.$emit('update', active)
    }
  },

  methods: {
    onSplitChange(status) {
      this.$emit('update:split', status)
    },

    onChangePeriod(period) {
      this.$emit('update:period', period)
      const entity = this.periodOptions.find(option => option.value === period)
      this.$emit('setTimeIntervalsCount', entity.timeIntervalsCount)
    }
  }
})
</script>

<style lang="scss" scoped>
.oct-Wrapper_Data {
  position: relative;
}

/* eslint-disable-next-line */
.oct-TableHeadCell {
  &-checker,
  &-marker,
  &-name {
    font-style: normal;
    font-size: $fs-12;
    color: $dark-3;
    position: sticky;
    background: $white;
    z-index: 2;
    align-self: stretch;
    align-items: flex-end;
    display: flex;
    font-family: $system-ui;
    line-height: 16px;
    font-weight: fw('bold');
  }

  &-checker,
  &-marker {
    justify-content: center;
    display: flex;
  }

  &-marker {
    left: 0;
    padding-bottom: 5px;
  }

  &-checker {
    left: calc(v-bind(MARKER_COLUMN_WIDTH) * 1px);
  }

  &-name {
    left: calc((v-bind(CHECKER_COLUMN_WIDTH) + v-bind(MARKER_COLUMN_WIDTH)) * 1px);
    padding: 0 4px;
    font-size: $fs-14;

    &:after {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      transform: translateY(
        calc(100% + 6px + 2px)
      ); // where 6px is padding-bottom of header cell, 2px is border-bottom
      width: 10px;
      height: v-bind(shadowHeight); //45
      pointer-events: none;
      box-shadow: 8px 0 10px rgba($dark-1, 0.15);
    }
  }
}

.oct-Table {
  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar-thumb {
    border-radius: $border-radius-sm-next;
    overflow: hidden;
  }
}

/* eslint-disable-next-line */
.oct-TableBodyCell {
  &-checker,
  &-marker,
  &-name {
    font-style: normal;
    font-weight: fw('regular');
    font-size: $fs-14;
    line-height: 20px;
    color: $dark-1;

    position: sticky;
    background: $white;
    font-family: $system-ui;

    .tb-RowWrapper:hover &,
    .tb-RowWrapper-hovered & {
      background: $grey-2;
    }
  }

  &-checker,
  &-marker {
    justify-content: center;
    display: flex;
  }

  &-marker {
    left: 0;
    height: 24px;
    align-items: center;
  }

  &-checker {
    left: calc(v-bind(MARKER_COLUMN_WIDTH) * 1px);
  }

  &-name {
    min-width: 60px;
    display: inline-flex;
    align-items: center;
    left: calc((v-bind(CHECKER_COLUMN_WIDTH) + v-bind(MARKER_COLUMN_WIDTH)) * 1px);
    padding: 10px 4px;
    gap: 4px;

    --id-font-weight: 600;

    .oct-TableBodyCell_Text {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

.oct-Wrapper_Header {
  display: flex;
  align-items: center;
  margin: 24px 0;
}
.oct-Wrapper_Header_LeftSide {
  display: flex;
  align-items: center;
  gap: 8px;
}

:deep(.tb-Table-mini .tb-Header) {
  background: $azure;
}
//.oct-Wrapper_Header_ButtonDownload {
//  background: $main-bg-grey;
//  color: $dark-grey;
//}
</style>
