import ApiHandler from '@/api/base'

class LevelApiHandler extends ApiHandler {
  /**
   *
   * @param {null | Array<string | number>} levelIds
   * @param {null | Array<string | number>} workspaceIds
   * @param {null | string} searchString
   * @returns {Promise<any>}
   */
  async getLevels({ levelIds = null, workspaceIds = null, searchString = null } = {}) {
    const { data } = await this.http.post('/getLevels', {
      levelIds,
      workspaceIds,
      searchString
    })
    return data
  }

  async getLevelById({ levelId = null } = {}) {
    const [level] = await this.getLevels({ levelIds: [levelId] })
    return level
  }

  async createLevel({
    color = null,
    name,
    prefix = null,
    orderValue = -1,
    workspaceIds,
    addToAllWorkspaces = false
  } = {}) {
    const { data } = await this.http.post('/createLevel', {
      color,
      name,
      prefix,
      workspaceIds,
      orderValue,
      addToAllWorkspaces
    })
    return data
  }

  async updateLevel({
    color = null,
    levelId = null,
    name,
    prefix = null,
    workspaceIds,
    orderValue
  } = {}) {
    const { data } = await this.http.post('/updateLevel', {
      color,
      levelId,
      name,
      prefix,
      workspaceIds,
      orderValue
    })
    return data
  }

  async removeLevel({ levelId } = {}) {
    const { data } = await this.http.post('/removeLevel', {
      levelId
    })
    return data
  }

  async getLevelsForFilter({ workspaceId = null } = {}) {
    const { data } = await this.http.post('/getLevelsForFilter', {
      workspaceId
    })
    return data
  }

  async bulkUnlinkWorkspacesFromLevel({ levelId = null, workspaceIds = [] } = {}) {
    const { data } = await this.http.post('/bulkRemoveLevelFromWorkspaces', {
      levelId,
      workspaceIds
    })

    return data
  }

  async unlinkWorkspaceFromLevel({ levelId = null, workspaceId = null } = {}) {
    return await this.bulkUnlinkWorkspacesFromLevel({
      levelId,
      workspaceIds: [workspaceId]
    })
  }

  async linkWorkspaceToLevel({
    levelId = null,
    workspaceIds = [],
    addToAllWorkspaces = false
  } = {}) {
    const { data } = await this.http.post('/bulkAddLevelToWorkspaces', {
      id: levelId,
      workspaceIds,
      addToAllWorkspaces
    })

    return data
  }
}

export default LevelApiHandler
