<template>
  <div class="dt-Loader_Table">
    <div class="dt-TableWrapper">
      <div class="dt-TableHead">
        <div class="dt-HeadCell">
          <SkeletonItem height="12px" />
        </div>
        <div v-for="n in HEAD_CELLS" :key="n.id" :style="{ width: n.width }" class="dt-HeadCell">
          <SkeletonItem height="12px" width="40px" />
        </div>
      </div>
      <div class="dt-TableBody">
        <DashboardTableRowLoader
          v-for="row in ROWS"
          :key="row.id"
          :body-cells="BODY_CELLS"
          :is-for-people="isForPeople"
          :row="row"
        />
      </div>
    </div>

    <div class="dt-FooterNav">
      <SkeletonItem width="92px" />
      <SkeletonItem :size="XS" width="65px" />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'DashboardTableLoader'
})
</script>

<script setup>
import { SKELETON_SIZES } from '@/utils/components-configurations/skeleton-item'

import DashboardTableRowLoader from '@/components/ui/SkeletonLoaders/DashboardTableRowLoader'
import SkeletonItem from '@/components/ui/SkeletonLoaders/SkeletonItem'

defineProps({
  isForPeople: {
    type: Boolean
  }
})
const { XS } = SKELETON_SIZES
const BODY_CELLS = [
  {
    id: 1,
    width: 'auto'
  },
  {
    id: 2,
    width: '80px'
  },
  {
    id: 3,
    width: '80px'
  },
  {
    id: 4,
    width: '80px'
  },
  {
    id: 5,
    width: '80px'
  },
  {
    id: 6,
    width: '120px'
  }
]

const HEAD_CELLS = [
  ...BODY_CELLS,
  {
    id: 4,
    width: '100px'
  }
]

const ROWS = [
  {
    id: 0,
    name: '220px',
    total: '46px'
  },
  {
    id: 1,
    name: '170px',
    total: '38px'
  },
  {
    id: 2,
    name: '200px',
    total: '38px'
  },
  {
    id: 3,
    name: '60px',
    total: '46px'
  },
  {
    id: 4,
    name: '110px',
    total: '46px'
  },
  {
    id: 5,
    name: '130px',
    total: '46px'
  },
  {
    id: 6,
    name: '80px',
    total: '46px'
  },
  {
    id: 7,
    name: '90px',
    total: '46px'
  },
  {
    id: 8,
    name: '105px',
    total: '46px'
  },
  {
    id: 9,
    name: '100зч',
    total: '46px'
  },
  {
    id: 10,
    name: '55px',
    total: '46px'
  },
  {
    id: 11,
    name: '83px',
    total: '46px'
  }
]
</script>

<style lang="scss" scoped>
$cell-width: 140px;

.dt-TableHead {
  display: flex;
  align-items: center;
  height: 30px;
}
.dt-HeadCell {
  display: flex;
  justify-content: flex-end;
  &:not(:first-child) {
    width: $cell-width;
    justify-content: flex-end;
  }

  &:first-child {
    flex: 1 1 auto;
    align-items: center;
    gap: 8px;
    justify-content: flex-start;
  }
}

.dt-FooterNav {
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0 32px;
}
</style>
