<template>
  <div class="dof-DuplicateForm">
    <!-- <div v-if="false" class="gl-AppSelectWrapper gl-AppSelectWrapper-formstyle">
      <AppSelect
        v-if="false"
        v-model="formModel.workspaceId"
        :label="$t('field.workspace.title')"
        :options="workspaces"
        :search-function="workspaceSearch"
        inline-search
        :loading="workspacesAreLoading"
        item-label="name"
        item-value="id"
      />
    </div>
    -->

    <SidebarLayout reversed>
      <AppSelect
        v-model="formModel.parentId"
        :item-label="item => `${item.displayId} ${item.name}`"
        :loading="parentObjectivesAreLoading"
        :offset="[0, 0]"
        :options="getObjectivesAsOptions"
        :required="isKR"
        :search-function="fetchParentObjectives"
        dropdown-search
        item-value="id"
        @update:options="parentObjectives = $event"
        @update:model-value="isParentEmpty = $event === null"
      >
        <template #option-label="{ option }">
          <ObjectiveSelectItem v-if="option" :objective="option" show-interval show-workspace />
        </template>
        <template #button="{ active, option }">
          <LinkToParentButton
            v-if="!option || !option.id"
            :hide-icon="parentObjectivesAreLoading"
            :is-active="active"
            :is-error="isKR && isParentEmpty"
            type="secondary"
          />

          <OkrElementSelectItem
            v-else
            :active="active"
            :objective="option"
            rotate-toggle-sign
            type="secondary"
          />
        </template>
      </AppSelect>

      <AppFieldError v-if="isKR" :show="isParentEmpty">{{ $t('field.required') }}</AppFieldError>

      <template #sidebar>
        <FormItemsGroup>
          <AppSelect
            v-model="getIntervalId"
            :hidden-items="archivedIntervals"
            :loading="intervalsAreLoading"
            :offset="[0, 0]"
            :options="getIntervalsAsOptions"
            item-label="label"
          >
            <template #button="{ option, active }">
              <OkrFormFieldSelectTriggerNext
                :empty-state-label="$t('field.quoter.placeholder')"
                :label="$t('field.quoter.title')"
                :opened="active"
                :selected-options="option?.label || ''"
                separated-label
              />
            </template>
          </AppSelect>
        </FormItemsGroup>
      </template>

      <template #footer>
        <ModalFooterActions
          v-model="formModel.copyNested"
          :disabled="loading"
          :loading="loading"
          :show-confirm-button="isCanCopy"
          @close="close"
          @create="save"
        >
          <template #checkbox-label>
            {{ $t('move.copy_nested_objectives') }}
          </template>
          <template #cancel-button-text>
            {{ $t('action.cancel') }}
          </template>

          <template #confirm-button-text> {{ $t('action.confirm') }}</template>
        </ModalFooterActions>
      </template>
    </SidebarLayout>
  </div>
</template>

<script>
import { isEqual } from 'lodash'
import { defineComponent } from 'vue'
import { mapState } from 'vuex'

import IntervalsInfoApiHandler from '@/api/intervals-info'
import ObjectivesApiHandler from '@/api/okr-elements'
import WorkspacesApiHandler from '@/api/workspaces'
import { tracker } from '@/tracking/amplitude'
import { EVENT_CATEGORIES, EVENT_SOURCES } from '@/tracking/amplitude-helpers'
import { EVENT_NAMES } from '@/tracking/gtm-helpers'
import { gtmTracker } from '@/tracking/gtm-tracking'
import { handleError } from '@/utils/error-handling'
import {
  findIntervalNameById,
  getObjectiveLevelsForCreate,
  isKR,
  OKR_ELEMENT_PERMISSIONS
} from '@/utils/objectives'
import { getNavigationTabName } from '@/utils/tracking'

import AppFieldError from '@/components/form/AppFieldError'
import FormItemsGroup from '@/components/form/FormItemsGroup'
import LinkToParentButton from '@/components/objectives/forms/LinkToParentButton'
import ModalFooterActions from '@/components/objectives/forms/ModalFooterActions'
import ObjectiveSelectItem from '@/components/objectives/forms/ObjectiveSelectItem'
import OkrElementSelectItem from '@/components/objectives/forms/OkrElementSelectItem'
import AppSelect from '@/components/ui/AppSelect/AppSelect'
import OkrFormFieldSelectTriggerNext from '@/components/ui/AppSelect/TriggerButtons/OkrFormFieldSelectTriggerNext'
import SidebarLayout from '@/components/ui/SidebarLayout/SidebarLayout'

const objectivesApi = new ObjectivesApiHandler()
const workspacesApi = new WorkspacesApiHandler()

const DEFAULT_FORM_MODEL = {
  parentId: -1,
  intervalId: -1,
  workspaceId: -1,
  copyNested: false
}

export default defineComponent({
  name: 'DuplicateObjective',
  components: {
    ObjectiveSelectItem,
    OkrElementSelectItem,
    LinkToParentButton,
    ModalFooterActions,
    SidebarLayout,
    OkrFormFieldSelectTriggerNext,
    FormItemsGroup,
    AppSelect,
    AppFieldError
  },

  props: {
    modelValue: {
      type: Object,
      default: null
    }
  },

  emits: { duplicated: null, saved: null, close: null },

  data() {
    return {
      isParentEmpty: false,
      loading: false,
      areParentObjectivesLoaded: false,
      areIntervalsLoaded: false,
      localFormModel: { ...DEFAULT_FORM_MODEL },
      formModel: { ...DEFAULT_FORM_MODEL },

      workspaces: [],
      workspacesAreLoading: false,
      parentObjectives: [],
      parentObjectivesAreLoading: false,
      intervals: [],
      intervalsAreLoading: false
    }
  },

  computed: {
    ...mapState('workspaces', {
      workspaceId: state => state.workspaceId
    }),

    ...mapState('objectives', {
      objectiveLevels: state => state.levels
    }),

    isKR() {
      return isKR(this.modelValue)
    },

    objectiveLevelsForCreate() {
      return getObjectiveLevelsForCreate(this.objectiveLevels)
    },

    isCanCopy() {
      return Boolean(this.modelValue && this.modelValue.isCanCopy)
    },

    getIntervalId: {
      get() {
        return this.formModel.intervalId
      },

      set(value) {
        this.formModel.intervalId = value
        this.fetchParentObjectives()
      }
    },

    getIntervalsAsOptions() {
      return this.intervals.map(i => ({
        label: i.name,
        value: i.id
      }))
    },

    getObjectivesAsOptions() {
      let result = [...this.parentObjectives]
      if (!this.isKR) {
        result = [
          {
            displayId: '',
            customIcon: 'unassigned-owner',
            name: this.$t('field.not_set'),
            permissions: [OKR_ELEMENT_PERMISSIONS.READ],
            id: null
          },
          ...this.parentObjectives
        ]
      }
      return result
    },

    /** @public */
    areDataChanged() {
      return !isEqual(this.localFormModel, this.formModel)
    },

    archivedIntervals() {
      return this.intervals.filter(item => !item.open).map(item => item.id)
    }
  },

  watch: {
    getIntervalId() {
      if (!this.areIntervalsLoaded) {
        this.localFormModel.intervalId = this.getIntervalId
      }
    },

    'formModel.parentId': function onParentObjectiveUpdate() {
      if (!this.areParentObjectivesLoaded) {
        this.localFormModel.parentId = this.formModel.parentId
      }
    },

    'formModel.workspaceId': async function onWorkspaceChange() {
      await this.fetchIntervals()
      this.fetchParentObjectives()
    },

    areIntervalsLoaded(value) {
      if (value) {
        const level = this.objectiveLevelsForCreate.find(
          level => this.modelValue.levelId === level.id
        )
        // send event only after fetching intervals because interval name is required
        tracker.logEvent('Started copying', {
          category: EVENT_CATEGORIES.OKR_MANAGEMENT,
          label: level?.name,
          quarter: this.intervals.findIndex(interval => interval.id === this.formModel.intervalId),
          contribution: this.formModel.contribute ? 'Yes' : 'No',
          tab: getNavigationTabName(this.$route)
        })
      }
    }
  },

  mounted() {
    if (this.modelValue) {
      this.formModel = { ...this.formModel, ...this.modelValue }
    }
    this.localFormModel = { ...this.formModel }

    this.fetchWorkspaces().then(result => {
      this.workspaces = result
    })
  },

  methods: {
    async fetchIntervals() {
      const api = new IntervalsInfoApiHandler()
      this.intervalsAreLoading = true

      try {
        const { currentIndex, intervals } = await api.getIntervalsInfo({
          workspaceId: this.formModel.workspaceId
        })

        this.intervals = intervals

        if (intervals.findIndex(interval => this.formModel.intervalId === interval.id) === -1) {
          if (currentIndex !== null) {
            this.formModel.intervalId = intervals[currentIndex].id
          }
        }

        this.$nextTick(() => {
          if (!this.areIntervalsLoaded) {
            this.localFormModel.intervalId = this.formModel.intervalId
          }
          this.areIntervalsLoaded = true
        })
      } catch (error) {
        handleError({ error })
      }

      this.intervalsAreLoading = false
    },

    async save() {
      let isSuccessful = true
      let objective = null

      const payload = {
        intervalId: this.formModel.intervalId,
        elementId: this.formModel.objectiveId,
        parentId: this.formModel.parentId,
        workspaceId: this.formModel.workspaceId,
        copyNested: this.formModel.copyNested
      }

      this.isParentEmpty = this.formModel.parentId === null
      if (this.isKR && this.isParentEmpty) {
        return
      }

      this.loading = true

      try {
        objective = await objectivesApi.copyObjective(payload)
        const level = this.objectiveLevelsForCreate.find(
          level => this.modelValue.levelId === level.id
        )
        tracker.logEvent('Created objective', {
          category: EVENT_CATEGORIES.OKR_MANAGEMENT,
          label: level?.name,
          quarter: findIntervalNameById(this.intervals, objective.element.intervalId),
          contribution: objective.element.contribute ? 'Yes' : 'No',
          ent_id: objective.element.displayId,
          source: EVENT_SOURCES.GRID,
          tab: getNavigationTabName(this.$route),
          parent_objective: objective.element.parentId !== null ? objective.element.parentId : ''
        })

        gtmTracker.logEvent(EVENT_NAMES.OBJECTIVE_CREATED)

        this.$emit('duplicated', objective)
      } catch (error) {
        isSuccessful = false
        handleError({ error })
      }

      setTimeout(() => {
        this.loading = false
      }, 300)

      if (isSuccessful) {
        this.$emit('saved')
        this.close({
          checkDataChange: false,
          duplicatedObjective: objective
        })
      }
    },

    async fetchParentObjectives(searchString = null) {
      const {
        typeId,
        parentId
        // parentTypeId,
      } = this.formModel

      const payload = {
        elementTypeId: typeId,
        intervalId: this.formModel.intervalId,
        elementId: this.modelValue?.id || null,
        parentId,
        searchString,
        workspaceId: this.formModel.workspaceId
      }
      this.parentObjectivesAreLoading = true

      try {
        const { currentIndex, elements } = await objectivesApi.getParentObjectiveInfo(payload)

        if (searchString !== null) {
          this.parentObjectivesAreLoading = false
          return elements
        }

        this.parentObjectives = elements
        if (this.formModel.parentId === -1) {
          if (currentIndex === 0) {
            this.formModel.parentId = null
          } else {
            this.formModel.parentId = elements[currentIndex - 1].id
          }
        } else {
          this.formModel.parentId = null
        }
        this.$nextTick(() => {
          if (!this.areParentObjectivesLoaded) {
            this.localFormModel.parentId = this.formModel.parentId
          }
          this.areParentObjectivesLoaded = true
        })
      } catch (error) {
        handleError({ error })
      }

      this.parentObjectivesAreLoading = false

      return []
    },

    close({ checkDataChange = true, duplicatedObjective = null }) {
      this.$emit('close', { checkDataChange, duplicatedObjective })
    },

    async fetchWorkspaces() {
      let workspaces = []
      this.workspacesAreLoading = true
      try {
        workspaces = await workspacesApi.getAllUserWorkspaces()
        if (workspaces.length > 0) {
          this.formModel.workspaceId = this.workspaceId
          this.localFormModel.workspaceId = this.workspaceId
        }
      } catch (error) {
        handleError({ error })
      }
      this.workspacesAreLoading = false

      return workspaces
    }

    // workspaceSearch(searchString = null) {
    //   return this.workspaces.filter(workspace =>
    //     workspace.name.toLowerCase().includes(searchString.toLowerCase())
    //   )
    // }
  }
})
</script>

<style lang="scss" scoped>
.dof-DuplicateForm {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  padding-top: 14px;

  :deep(.slu-Content),
  :deep(.slu-Sidebar) {
    &:before {
      display: none;
    }
  }
}
</style>
