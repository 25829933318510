import { Server, Model } from 'miragejs'

import {
  users,
  groups,
  objectives,
  intervals,
  workspaces,
  parentObjectives,
  childObjectives,
  jiraGroups,
  jiraUsers,
  reportByUsers,
  reportByGroup,
  reportByObjectiveType,
  groupEngagement
} from './mock-db'

// eslint-disable-next-line import/prefer-default-export
export const makeServer = ({ environment = 'development' } = {}, apiBase) => {
  // eslint-disable-next-line no-new
  new Server({
    environment,
    models: {
      user: Model,
      group: Model,
      interval: Model,
      objective: Model,
      workspace: Model,
      parentObjective: Model,
      childObjective: Model,
      jiraGroup: Model,
      jiraUser: Model,
      reportByUsers: Model,
      reportByGroup: Model,
      reportByObjectiveType: Model,
      groupEngagement: Model
    },

    routes() {
      this.urlPrefix = apiBase

      this.post('/getAssignInfo', function getAssignInfo(schema) {
        const usersResult = this.serialize(schema.users.all())

        return {
          ...usersResult,
          notSet: true,
          currentIndexes: []
        }
      })

      this.post('/getGroupInfo', function getGroupInfo(schema) {
        const groupsResult = this.serialize(schema.groups.all())

        return {
          teams: [...groupsResult.groups],
          notSet: true,
          selectedIndexes: []
        }
      })

      this.post('/getIntervalInfo', function getIntervalInfo(schema) {
        const intervalsResult = this.serialize(schema.intervals.all())

        return {
          ...intervalsResult,
          currentIndex: 3
        }
      })

      this.post('/getAllIntervals', function getAllIntervals(schema) {
        const intervalsResult = this.serialize(schema.intervals.all())

        return [...intervalsResult.intervals]
      })

      this.post('/getObjectives', function getObjectives(scheme) {
        const objectivesResult = this.serialize(scheme.objectives.all())

        return [...objectivesResult.objectives]
      })

      this.post('/getAllUserWorkspaces', function getAllUserWorkspaces(scheme) {
        const workspacesResult = this.serialize(scheme.workspaces.all())

        return [...workspacesResult.workspaces]
      })

      this.post('/getParentObjectiveInfo', function getParentObjectiveInfo(scheme) {
        const parentObjectivesResult = this.serialize(scheme.parentObjectives.all())

        return {
          objectives: [...parentObjectivesResult.parentObjectives]
        }
      })

      this.post('/getChildObjectives', function getChildObjectives(scheme) {
        const childObjectivesResult = this.serialize(scheme.childObjectives.all())

        return {
          items: [...childObjectivesResult.childObjectives]
        }
      })

      this.post('/getJiraGroupsAndUsers', function getJiraGroupsAndUsers(scheme) {
        const jiraGroupsResult = this.serialize(scheme.jiraGroups.all())
        const jiraUsersResult = this.serialize(scheme.jiraUsers.all())

        return {
          groups: [...jiraGroupsResult.jiraGroups],
          users: [...jiraUsersResult.jiraUsers]
        }
      })

      this.post('/getReportByUsers', function getReportByUsers(scheme) {
        const reportByUsersResult = this.serialize(scheme.reportByUsers.all())

        return [...reportByUsersResult.reportByUsers]
      })

      this.post('/getReportByGroup', function getReportByGroup(scheme) {
        const reportByGroupResult = this.serialize(scheme.reportByGroup.all())

        return [...reportByGroupResult.reportByGroup]
      })

      this.post('/getReportByObjectiveType', function getReportByObjectiveType(scheme) {
        const reportByObjectiveTypeResult = this.serialize(scheme.reportByObjectiveTypes.all())

        return [...reportByObjectiveTypeResult.reportByObjectiveTypes]
      })

      this.post('/getGroupEngagement', function getGroupEngagement(scheme) {
        const groupEngagementResult = this.serialize(scheme.groupEngagements.all())

        return [...groupEngagementResult.groupEngagements]
      })
    },

    seeds(server) {
      if (environment === 'development') {
        users.forEach(user => server.create('user', user))
        groups.forEach(group => server.create('group', group))
        intervals.forEach(interval => server.create('interval', interval))
        objectives.forEach(objective => server.create('objective', objective))
        workspaces.forEach(workspace => server.create('workspace', workspace))
        parentObjectives.forEach(parentObjective =>
          server.create('parentObjective', parentObjective)
        )
        childObjectives.forEach(childObjective => server.create('childObjective', childObjective))
        jiraGroups.forEach(jiraGroup => server.create('jiraGroup', jiraGroup))
        jiraUsers.forEach(jiraUser => server.create('jiraUser', jiraUser))
        reportByUsers.forEach(reportByUser => server.create('reportByUser', reportByUser))
        reportByGroup.forEach(item => server.create('reportByGroup', item))
        reportByObjectiveType.forEach(item => server.create('reportByObjectiveType', item))
        groupEngagement.forEach(item => server.create('groupEngagement', item))
      }
    }
  })
}
