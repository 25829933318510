import { mergeAttributes } from '@tiptap/core'
import { Image as BaseImage } from '@tiptap/extension-image'
import { VueNodeViewRenderer } from '@tiptap/vue-3'

import { TIPTAP_EXTENSION_NAMES } from '@/utils/tiptap-utils'

// eslint-disable-next-line import/extensions
import ImageBlockView from './ImageBlockView.vue'

const Image = BaseImage.extend({
  group: 'block'
})

export const ImageBlock = Image.extend({
  name: TIPTAP_EXTENSION_NAMES.IMAGE_BLOCK,
  group: 'block',
  defining: true,
  isolating: true,
  addAttributes() {
    return {
      'data-uid': {
        default: '',
        parseHTML: element => {
          return element.children[0].getAttribute('data-uid')
        },
        renderHTML: attributes => {
          return {
            'data-uid': attributes['data-uid']
          }
        }
      },
      src: {
        default: '',
        parseHTML: element => {
          return element.children[0].getAttribute('src')
        },
        renderHTML: attributes => {
          return {
            src: attributes.src
          }
        }
      },
      width: {
        default: '100%',
        parseHTML: element => element.getAttribute('data-width'),
        renderHTML: attributes => ({
          'data-width': attributes.width
        })
      },
      align: {
        default: 'center',
        parseHTML: element => element.getAttribute('data-align'),
        renderHTML: attributes => ({
          'data-align': attributes.align
        })
      },
      alt: {
        default: undefined,
        parseHTML: element => element.getAttribute('alt'),
        renderHTML: attributes => ({
          alt: attributes.alt
        })
      }
    }
  },

  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`
      },
      {
        tag: 'img[src*="windows.net"]:not([src^="data:"])'
      }
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      { 'data-type': this.name },
      ['img', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)]
    ]
  },

  addCommands() {
    return {
      setImageBlock:
        attrs =>
        ({ commands }) => {
          return commands.insertContent({
            type: TIPTAP_EXTENSION_NAMES.IMAGE_BLOCK,
            attrs: { src: attrs.src, 'data-uid': attrs.id }
          })
        }
      //TODO: for future feature with img position

      // setImageBlockAt:
      //   attrs =>
      //   ({ commands }) => {
      //     return commands.insertContentAt(attrs.pos, {
      //       type: 'imageBlock',
      //       attrs: { src: attrs.src }
      //     })
      //   },
      // setImageBlockAlign:
      //   align =>
      //   ({ commands }) =>
      //     commands.updateAttributes('imageBlock', { align }),
      // setImageBlockWidth:
      //   width =>
      //   ({ commands }) =>
      //     commands.updateAttributes('imageBlock', {
      //       width: `${Math.max(0, Math.min(100, width))}%`
      //     })
    }
  },

  addNodeView() {
    return VueNodeViewRenderer(ImageBlockView)
  }
})

export default ImageBlock
