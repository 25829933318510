<script>
import { defineComponent, h } from 'vue'

export default defineComponent({
  name: 'AppDivider',
  props: {
    inset: {
      type: Boolean
    },

    vertical: {
      type: Boolean
    },

    noMargin: {
      type: Boolean
    },

    isError: {
      type: Boolean
    },

    borderWidth: {
      type: String,
      default: '1px'
    }
  },

  render() {
    return h('hr', {
      class: {
        'ad-Divider': true,
        'ad-Divider-inset': this.inset,
        'ad-Divider-vertical': this.vertical,
        'ad-Divider-noMargin': this.noMargin,
        'ad-Divider-error': this.isError
      },
      attrs: {
        role: 'separator'
      }
    })
  }
})
</script>

<style lang="scss" scoped>
$divider-inset-margin: 72px !default;
$divider-inset-margin-top: 8px !default;
$divider-inset-max-height: calc(100% - 16px) !default;

.ad-Divider {
  display: block;
  flex: 1 1 0;
  max-width: 100%;
  height: 0;
  max-height: 0;
  border: solid;
  border-width: v-bind(borderWidth) 0 0 0;
  transition: inherit;

  &:not(&-error) {
    color: var(--divider-color, #{$grey-2-next});
  }

  &-error {
    color: $grade-low-color-next;
  }

  &-inset:not(.ad-Divider-vertical) {
    max-width: calc(100% - #{$divider-inset-margin});
    margin-left: $divider-inset-margin;
  }
  &-vertical {
    align-self: stretch;
    border: solid;
    border-width: 0 thin 0 0;
    display: inline-flex;
    height: inherit;
    min-height: 100%;
    max-height: 100%;
    max-width: 0;
    width: 0;
    vertical-align: text-bottom;
    margin: 0 -1px; // Prevent wrapping in flex layouts
  }
  &.ad-Divider-inset {
    margin-top: $divider-inset-margin-top;
    min-height: 0;
    max-height: $divider-inset-max-height;
  }

  &-noMargin {
    margin: 0;
  }
}
</style>
