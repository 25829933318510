import { ASSIGNEE_ENTITY_KEYS } from '@/utils/entity-keys'

export const handleAdministratorsList = ({ allAdministrators, usersList }) => {
  return usersList
    .map(user => {
      return {
        ...user,
        alreadyAdded: allAdministrators.some(
          admin => user[ASSIGNEE_ENTITY_KEYS.ACCOUNT_ID] === admin[ASSIGNEE_ENTITY_KEYS.ACCOUNT_ID]
        )
      }
    })
    .sort((a, b) => {
      return a.alreadyAdded - b.alreadyAdded
    })
}
