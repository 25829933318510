<template>
  <div class="udt-Loader">
    <div v-for="row in ROWS" :key="row" class="udt-Loader_Row">
      <div class="udt-Cell">
        <SkeletonItem :appearance="SKELETON_APPEARANCES.SQUARE" :size="SM" border-radius="6px" />
        <SkeletonItem :size="XS" :width="row.name" />
      </div>
      <div class="udt-Cell">
        <SkeletonItem
          v-for="group in row.groups"
          :key="group"
          :size="SM"
          :width="group"
          border-radius="6px"
        />
      </div>
      <div class="udt-Cell">
        <SkeletonItem :size="XS" :width="row.role" />
      </div>
    </div>
  </div>
</template>

<script setup>
defineOptions({
  name: 'UserDetailsTableLoader'
})

import {
  SKELETON_APPEARANCES,
  SKELETON_SIZES
} from '@/utils/components-configurations/skeleton-item'

const { SM, XS } = SKELETON_SIZES

import SkeletonItem from '@/components/ui/SkeletonLoaders/SkeletonItem'

const ROWS = [
  {
    id: 0,
    name: '90px',
    groups: ['260px', '30px'],
    role: '122px'
  },
  {
    id: 1,
    name: '110px',
    groups: [],
    role: '40px'
  },
  {
    id: 2,
    name: '60px',
    groups: ['73px'],
    role: '70px'
  },
  {
    id: 3,
    name: '200px',
    groups: ['62px'],
    role: '40px'
  },
  {
    id: 4,
    name: '100px',
    groups: ['27px', '29px'],
    role: '40px'
  }
]
</script>

<style lang="scss" scoped>
.udt-Loader_Row {
  height: 45px;
  padding: 0 $page-right-padding 0 $page-left-padding;
  display: flex;
  align-items: center;
}

.udt-Cell {
  display: flex;
  align-items: center;

  &:not(:first-child) {
    gap: 4px;
  }

  &:first-child {
    gap: 8px;
    flex: 1 0 auto;
  }

  &:nth-child(2) {
    width: 308px;
  }

  &:nth-child(3) {
    width: 192px;
  }
}
</style>
