<template>
  <transition-group class="an-List" mode="out-in" name="slide-fade" tag="div">
    <div
      v-for="notification in notifications"
      :key="notification.id"
      :class="{ 'an-List_Item-expandable': notification.expandable }"
      :style="{
        '--animation-duration': notification.animationDuration + 'ms',
        '--progress-color': notification.color
      }"
      class="an-List_Item"
      @mouseout="onMouseOut(notification.id)"
      @mouseover="onMouseOver(notification.id)"
    >
      <div class="an-ListItemHead">
        <AppIcon
          :icon-name="notification.type"
          class="an-NotificationIcon"
          height="24"
          width="24"
        />
        <AppTitle v-if="notification.title" :level="6" class="an-Title" disable-margin>
          {{ notification.title }}
        </AppTitle>
        <div class="an-ButtonWrapper">
          <AppDivider v-if="notification.withVerticalDivider" vertical />
          <AppButton
            v-if="notification.expandable || notification.btnText || notification.btnIcon"
            :class="{ 'an-Toggle-expanded': notification.expanded }"
            :height="24"
            :icon="notification.btnIcon ? notification.btnIcon : 'arrow-down-black'"
            :width="24"
            class="an-Toggle"
            size="sm"
            type="subtle"
            @click="onNotificationButtonClick(notification)"
          >
            <span v-if="notification.btnText" class="an-NotificationBtnText">
              {{ notification.btnText }}
            </span>
          </AppButton>
          <AppButton
            v-if="notification.withButtonClose"
            :height="24"
            :width="24"
            class="an-Toggle"
            icon="close-next"
            size="sm"
            type="subtle"
            @click="close(notification.id)"
          />
        </div>
      </div>
      <div v-if="notification.expanded" class="an-ListItemDescription">
        <!-- eslint-disable vue/no-v-html -->
        <span v-if="notification.isHtml" v-html="notification.content" />
        <!-- eslint-enable vue/no-v-html -->
        <template v-else>
          {{ notification.content }}
        </template>
      </div>

      <template v-if="notification.actions && notification.expanded">
        <div class="an-ListItemActions">
          <AppButton
            v-for="action in notification.actions"
            :key="action.id"
            remove-padding
            type="link-next"
            @click="onNotificationActionClick(action, notification)"
          >
            {{ $t(action.label) }}
          </AppButton>
        </div>
      </template>
    </div>
  </transition-group>
</template>

<script>
import { capitalize, isEmpty } from 'lodash'
import { defineComponent } from 'vue'

import { NOTIFICATION_DURATIONS, NOTIFICATION_TYPES, notifyBus } from '@/utils/notify'

import AppButton from '@/components/ui/AppButton/AppButton'
import AppDivider from '@/components/ui/AppDivider'
import AppIcon from '@/components/ui/AppIcon/AppIcon'
import AppTitle from '@/components/ui/AppTitle/AppTitle'

const { SHORT, MEDIUM, LONG, INFINITE } = NOTIFICATION_DURATIONS

// in ms
const TIMEOUTS = {
  [SHORT]: 3000,
  [MEDIUM]: 5000,
  [LONG]: 8000,
  [INFINITE]: 100000000
}

const NOTIFICATIONS_COLORS = {
  [NOTIFICATION_TYPES.SUCCESS]: 'var(--grade-high-color-next)',
  [NOTIFICATION_TYPES.ERROR]: 'var(--grade-low-color-next)',
  [NOTIFICATION_TYPES.WARNING]: 'var(--grade-medium-color-next)'
}

const MAX_NOTIFICATIONS_COUNT = 5

class ExtendedTimeout {
  constructor(callback, delay) {
    this.timer = null
    this.start = null
    this.remaining = delay
    this.callback = callback
  }

  run() {
    if (this.timer) {
      return
    }

    this.start = Date.now()
    this.timer = setTimeout(() => {
      this.callback()
    }, this.remaining)
  }

  pause() {
    clearTimeout(this.timer)
    this.timer = null
    this.remaining -= Date.now() - this.start
  }

  destroy() {
    clearTimeout(this.timer)
  }
}

export default defineComponent({
  name: 'AppNotifications',

  components: {
    AppDivider,
    AppTitle,
    AppIcon,
    AppButton
  },

  data() {
    return {
      notifications: [],
      notificationsTimers: {}
    }
  },

  created() {
    notifyBus.on('show-notify', this.showNotification)
  },

  beforeUnmount() {
    notifyBus.off('show-notify', this.showNotification)
  },

  methods: {
    runTimer(id) {
      this.notificationsTimers[id]?.run()
    },

    onMouseOver(id) {
      this.notificationsTimers[id]?.pause()
    },

    onMouseOut(id) {
      this.runTimer(id)
    },

    maybeSpliceOld() {
      if (this.notifications.length >= MAX_NOTIFICATIONS_COUNT) {
        this.notifications.splice(0, 1)
      }
    },

    applyTimeout(delay, id) {
      if (!delay) return

      this.notificationsTimers[id] = new ExtendedTimeout(() => this.close(id), TIMEOUTS[delay])
      this.runTimer(id)

      // setTimeout(() => this.close(id), TIMEOUTS[delay])
    },

    showNotification({
      id,
      type = NOTIFICATION_TYPES.ERROR,
      delay = NOTIFICATION_DURATIONS.MEDIUM,
      expandable = undefined,
      expanded = false,
      title = '',
      content = '',
      isHtml = false,
      btnText = '',
      btnIcon = '',
      onBtnClick = null,
      hideOnBtnClick = false,
      withVerticalDivider = false,
      withButtonClose = false,
      actions = null
    }) {
      const { SUCCESS, ERROR, WARNING } = NOTIFICATION_TYPES
      const titlesByType = {
        [SUCCESS]: title || capitalize(SUCCESS),
        [ERROR]: title || capitalize(ERROR),
        [WARNING]: title || capitalize(WARNING)
      }

      title = titlesByType[type]

      if (expandable === undefined) {
        expandable = content.length > 0 || !isEmpty(actions)
      }

      this.maybeSpliceOld()
      this.notifications.push({
        id,
        type,
        expandable,
        expanded,
        title,
        content,
        isHtml,
        btnText,
        btnIcon,
        onBtnClick,
        hideOnBtnClick,
        withVerticalDivider,
        withButtonClose,
        actions,
        animationDuration: TIMEOUTS[delay],
        color: NOTIFICATIONS_COLORS[type]
      })
      this.applyTimeout(delay, id)
    },

    close(id) {
      this.notifications = this.notifications.filter(n => n.id !== id)
      this.notificationsTimers[id]?.destroy()
      delete this.notificationsTimers[id]
    },

    onNotificationButtonClick(notification) {
      if (notification.onBtnClick) {
        notification.onBtnClick()
        if (notification.hideOnBtnClick) {
          this.close(notification.id)
        }
      } else {
        notification.expanded = !notification.expanded
      }
    },

    onNotificationActionClick(action, notification) {
      action.handler()
      if (action.closeOnHandle) {
        this.close(notification.id)
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.an-List {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10000;
  width: 400px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  box-sizing: border-box;

  &:empty {
    visibility: hidden;
  }
}

.an-List_Item {
  position: relative;
  border-radius: $border-radius-sm-next;
  padding: 20px;
  background-color: $white;
  min-height: 46px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: default;
  gap: 2px;
  transition: all $transition-fast ease;
  box-shadow: $common-box-shadow;
  overflow-x: hidden;

  &:hover {
    &:after {
      animation-play-state: paused;
    }
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 2px;
    opacity: 0.5;
    pointer-events: none;
    background-color: var(--progress-color);
    width: 100%;
    animation: progressAnimation linear 1 forwards;
    transform-origin: left;
    animation-duration: var(--animation-duration);

    animation-play-state: running;
  }

  @keyframes progressAnimation {
    0% {
      transform: scale3d(1, 1, 1);
    }
    100% {
      transform: scale3d(0, 1, 1);
    }
  }
}

$column-gap: 16px;

.an-ListItemHead {
  display: flex;
  max-width: 100%;
  gap: 8px $column-gap;
}

.an-NotificationIcon {
  flex-shrink: 0;
}

.an-Title {
  flex: 1;
  white-space: normal;
  font-weight: fw('semi-bold');
  line-height: 20px;
  align-self: center;
  color: $dark-1;
  font-family: $system-ui;
}

.an-ListItemDescription {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  font-family: $system-ui;
  padding-left: calc(24px + #{$column-gap});
  font-weight: fw('regular');
  font-size: $fs-14;
  line-height: 20px;
  color: $dark-2;
}

.an-ListItemActions {
  padding-left: calc(24px + #{$column-gap});
  width: 100%;
  display: flex;
  align-items: center;
  gap: 24px;
  flex-wrap: wrap;
}

.an-Toggle {
  color: $dark-grey;

  &:deep(.svg-icon) {
    flex: 0 0 auto;
  }

  &-expanded {
    &:deep(.svg-icon) {
      transform: rotate(180deg);
    }
  }
}

.an-NotificationBtnText {
  color: $blue;
  font-size: $fs-14;
  font-weight: fw('regular');
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 20px;
  font-family: $system-ui;
}
.an-ButtonWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  //max-width: calc((100% - 20px - #{$column-gap} - #{$column-gap}) / 2);
}
</style>
