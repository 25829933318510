export const AVATAR_OPTIONS = {
  XS: {
    name: 'XS',
    size: 12,
    userPicSize: 8
  },
  MD: {
    name: 'MD',
    size: 24,
    userPicSize: 16
  },
  LG: {
    name: 'LG',
    size: 32,
    userPicSize: 24
  },
  XL: {
    name: 'XL',
    size: 48,
    userPicSize: 32
  },
  XXL: {
    name: 'XXL',
    size: 80,
    userPicSize: 48
  }
}

export const AVATAR_SIZES = Object.entries(AVATAR_OPTIONS).reduce((acc, [key, value]) => {
  acc[key] = value.name
  return acc
}, {})

export const AVATAR_DIMENSIONS = Object.entries(AVATAR_OPTIONS).reduce((acc, [key, value]) => {
  acc[key] = value.size
  return acc
}, {})

export const AVATAR_USER_PIC_DIMENSIONS = Object.entries(AVATAR_OPTIONS).reduce(
  (acc, [key, value]) => {
    acc[key] = value.userPicSize
    return acc
  },
  {}
)
