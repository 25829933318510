export const TREE_TYPES = {
  DETAILED: {
    value: 'detailed',
    icon: 'mindmap-detailed-view',
    tooltip: 'tree.switch_to_detailed_card_type'
  },
  COMPACT: {
    value: 'compact',
    icon: 'mindmap-compact-view',
    tooltip: 'tree.switch_to_compact_card_type'
  }
}

export const AVAILABLE_TREE_TYPES = Object.values(TREE_TYPES).map(({ value }) => value)

export const createKrsWrapperId = id => {
  return `${id}-krs-wrapper`
}
