<script setup>
import { h } from 'vue'

import { isFirefox, isMacOs, isSafari } from '@/utils/general'
import { BASE64_JIRA_CLOUD_JIRA_ICONS } from '@/utils/roadmap'

/**
 * @name TaskIconForExport
 * @description Render all our base64 Jira icons in the hidden div for export to PDF.
 * Coz Safari and some versions of Firefox can't render base64 images in the PDF
 * if they are not rendered on the page.
 */

defineOptions({
  name: 'TaskIconForExport'
})

const IconsNode = () => {
  if (isMacOs() && (isSafari() || isFirefox())) {
    return h(
      'div',
      {
        style: {
          display: 'none'
        }
      },
      Object.values(BASE64_JIRA_CLOUD_JIRA_ICONS).map((item, index) =>
        h('img', {
          key: index,
          src: item
        })
      )
    )
  }
}
</script>

<template>
  <IconsNode />
</template>
