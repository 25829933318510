<template>
  <div class="ggl-Loader">
    <div
      v-for="row in ROWS.slice(0, rowsCount)"
      :key="row.id"
      :style="{ '--depth': row.depth }"
      class="ggl-Loader_Row"
    >
      <div class="ggl-Cell">
        <SkeletonItem v-if="row.expandable" :appearance="SQUARE" :size="SM" border-radius="6px" />
        <div v-else class="ggl-Cell_ExpandPlug"></div>
        <SkeletonItem :appearance="SQUARE" :color="row.color" :size="SM" border-radius="6px" />
        <SkeletonItem :size="XS" :width="row.name" />
        <div v-if="!hideLabels" class="ggl-Cell_Labels">
          <SkeletonItem :size="SM" :width="row.users" />
          <SkeletonItem :size="SM" :width="row.subGroups" />
        </div>
      </div>
      <div v-if="!hideWorkspaces" class="ggl-Cell" style="--gap: 4px">
        <SkeletonItem
          v-for="n in row.workspaces"
          :key="n"
          :appearance="SQUARE"
          :size="SM"
          border-radius="6px"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'GlobalGroupsLoader'
})
</script>

<script setup>
import {
  SKELETON_APPEARANCES,
  SKELETON_SIZES
} from '@/utils/components-configurations/skeleton-item'
import { GLOBAL_GROUP_TABLE_OFFSET } from '@/utils/global-groups'

import { ROWS } from '@/components/ui/SkeletonLoaders/global-groups'
import SkeletonItem from '@/components/ui/SkeletonLoaders/SkeletonItem'

defineProps({
  hideWorkspaces: {
    type: Boolean
  },

  hideLabels: {
    type: Boolean
  },

  rowsCount: {
    type: Number,
    default: ROWS.length
  }
})

const { SQUARE } = SKELETON_APPEARANCES
const { XS, SM } = SKELETON_SIZES
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/global-groups-loaders';

.ggl-Loader {
  padding: 0 var(--inline-padding, v-bind(GLOBAL_GROUP_TABLE_OFFSET));
}
.ggl-Loader_Row {
  @extend %row-style;
}

.ggl-Cell {
  @extend %cell-style;
  &:nth-child(1) {
    @extend %deep-cell-style;
  }

  &:nth-child(2) {
    width: 153px;
    margin: 0 var(--okr-table-row-controls-button-width, 0px) 0 auto;
  }
}

.ggl-Cell_Labels {
  @extend %cell-labels-style;
}

.ggl-Cell_ExpandPlug {
  @extend %expand-plug-style;
}
</style>
