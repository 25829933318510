<template>
  <div class="oll-Loader">
    <div v-if="isRiveShutDown" class="oll-Loader_Logo">
      <SkeletonItem
        :appearance="SKELETON_APPEARANCES.CIRCLE"
        animation-opacity="0.2"
        class="oll-Loader_Item"
        color="#5879EE"
        height="48px"
        width="48px"
      />

      <div class="oll-Loader_Middle">
        <SkeletonItem
          animation-opacity="0.2"
          border-radius="24px"
          class="oll-Loader_Item"
          color="#FF8541"
          height="48px"
          width="96px"
        />

        <SkeletonItem
          animation-opacity="0.2"
          border-radius="24px"
          class="oll-Loader_Item"
          color="#A769F6"
          height="48px"
          width="100%"
        />
      </div>

      <SkeletonItem
        animation-opacity="0.2"
        border-radius="24px"
        class="oll-Loader_Item"
        color="#F64963"
        height="48px"
        width="96px"
      />
    </div>
    <canvas v-else ref="canvas" class="oll-Canvas" height="320" width="320"></canvas>
  </div>
</template>

<script setup>
import { Rive } from '@rive-app/canvas'
import { onMounted, ref } from 'vue'

import { SKELETON_APPEARANCES } from '@/utils/components-configurations/skeleton-item'

import SkeletonItem from '@/components/ui/SkeletonLoaders/SkeletonItem'

defineOptions({
  name: 'OboardLogoLoader'
})

const isRiveShutDown = ref(false)

const canvas = ref(null)
onMounted(() => {
  if (canvas.value) {
    const riveInstance = new Rive({
      canvas: canvas.value,
      src: 'https://s3-oboard-public-static.s3.eu-central-1.amazonaws.com/img/logo-loader.riv',
      autoplay: true,
      loop: true,
      onLoadError: () => {
        isRiveShutDown.value = true
      },
      onloaderror: () => {
        isRiveShutDown.value = true
      },
      onLoad: () => {
        riveInstance.resizeDrawingSurfaceToCanvas()
      }
    })
  } else {
    isRiveShutDown.value = true
  }
})
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/canvas-dimensions';

.oll-Loader {
  height: var(--height, #{$fullscreen-canvas-height});
  display: flex;
  justify-content: center;
  align-items: center;
}

.oll-Loader_Logo {
  height: 160px;
  width: 160px;
  display: grid;
  gap: 8px;
}

.oll-Loader_Item {
  &:nth-child(1) {
    margin-left: auto;
  }
}

.oll-Loader_Middle {
  position: relative;
  .oll-Loader_Item {
    &:nth-child(1) {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
    }
  }
}

.oll-Canvas {
  max-width: 320px;
  max-height: 320px;
}
</style>
