<template>
  <div class="urs-Loader">
    <div v-for="row in ROWS" :key="row.id" class="urs-Loader_Item">
      <div class="urs-LoaderItem_Left">
        <SkeletonItem :appearance="CIRCLE" :size="SM" border-radius="6px" />
        <SkeletonItem :size="XS" :width="row.name" />
        <SkeletonItem :appearance="SQUARE" :size="SM" />
      </div>
      <div class="urs-LoaderItem_Right">
        <div style="width: 37px; display: flex; justify-content: flex-end">
          <SkeletonItem :size="XS" :width="row.grade" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'UserReportSummaryLoader'
})
</script>

<script setup>
import {
  SKELETON_APPEARANCES,
  SKELETON_SIZES
} from '@/utils/components-configurations/skeleton-item'

import SkeletonItem from '@/components/ui/SkeletonLoaders/SkeletonItem'

const { SQUARE, CIRCLE } = SKELETON_APPEARANCES
const { SM, XS } = SKELETON_SIZES

const ROWS = [
  {
    id: 1,
    name: '116px',
    grade: '37px'
  },
  {
    id: 2,
    name: '204px',
    grade: '24px',
    info: true
  },
  {
    id: 3,
    name: '36px',
    grade: '17px'
  }
]
</script>

<style scoped lang="scss">
.urs-Loader_Item {
  width: 100%;
  display: flex;
  align-items: center;
  height: 44px;
  gap: 12px;
}

.urs-LoaderItem_Left {
  gap: 8px;
  align-items: center;
  display: flex;
}

.urs-LoaderItem_Right {
  margin: 0 163px 0 auto;
}
</style>
