<template>
  <div class="ofs-Form">
    <FormField required>
      <template #label>
        <AppTitle :disable-margin="true" :inline="true" :level="5">
          <span class="ofs-FieldName">
            {{ isCreateFilter ? $t('filters.save_filter_as') : updateFilterText }}
          </span>
        </AppTitle>
      </template>
      <AppInput
        ref="input"
        :max-length="placement === DASHBOARD ? 150 : 50"
        :model-value="filterName"
        size="xlg"
        style-type="secondary"
        @select="onSelect"
        @update:model-value="filterName = $event"
      />
      <AppFieldError :show="filterNameEmpty">
        {{ $t('field.required') }}
      </AppFieldError>
    </FormField>
    <div class="ofs-Form_Actions">
      <AppButton size="md" type="ghost-next" @click.stop.prevent="onCancel">
        {{ $t('action.cancel') }}
      </AppButton>
      <AppButton
        :disable="isFilterNameEmpty"
        :loading="loading"
        size="md"
        type="primary-next"
        @click.stop.prevent="onSave"
      >
        {{ $t('action.save') }}
      </AppButton>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapActions, mapState } from 'vuex'

import CustomDashboardApiHandler from '@/api/custom-dashboard'
import OkrFiltersApiHandler from '@/api/okr-filters'
import {
  CUSTOM_FIELDS_FILTERS_QUERY_KEY,
  getResolvedCustomFieldFiltersValues
} from '@/utils/custom-fields/helpers'
import { handleError } from '@/utils/error-handling'
import { showNotify } from '@/utils/notify'
import { prepareFiltersPayload } from '@/utils/okr-custom-filters'
import { FILTER_PRESET_KEY } from '@/utils/query-parameters'

import { DASHBOARD, FILTERS, placementPropValidator } from '@/components/AppMenu/props-validators'
import AppFieldError from '@/components/form/AppFieldError'
import FormField from '@/components/form/FormField'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppInput from '@/components/ui/AppInput/AppInput'
import AppTitle from '@/components/ui/AppTitle/AppTitle'

export default defineComponent({
  name: 'OkrFilterSaverForm',
  components: { AppButton, AppFieldError, AppInput, FormField, AppTitle },
  props: {
    isCreateFilter: {
      type: Boolean
    },

    formModel: {
      type: Object,
      required: true
    },

    placement: {
      type: String,
      default: FILTERS,
      validator: v => placementPropValidator(v)
    }
  },

  emits: {
    'close-form': null,
    'update:filterName': null
  },

  data() {
    return {
      filterNameEmpty: false,
      filterName: '',
      loading: false
    }
  },

  computed: {
    ...mapState('dashboard', ['favoriteList']),
    DASHBOARD() {
      return DASHBOARD
    },

    isEdit() {
      return this.placement === FILTERS
        ? Boolean(this.formModel.name !== '' && this.formModel.filters)
        : Boolean(this.formModel.name)
    },

    isFilterNameEmpty() {
      return this.filterName.trim().length === 0
    },

    updateFilterText() {
      return this.placement === FILTERS
        ? this.$t('filters.update_filter_as')
        : this.$t('filters.update_dashboard_as')
    }
  },

  watch: {
    filterName() {
      this.setFilterNameIsEmpty()
    }
  },

  created() {
    this.filterName = this.formModel.name
  },

  mounted() {
    this.$nextTick(() => {
      this.$refs.input.focus()
    })
  },

  methods: {
    ...mapActions('dashboard', ['getDashboardList', 'getFavoriteList']),
    onSelect(e) {
      // stop propagation of 'select' event from input coz we bind all props from appSelect
      // to scoped slot #option with 'select' event too.
      // and when we selects a text in input 'select' event propagated
      e.stopPropagation()
    },

    setFilterNameIsEmpty() {
      this.filterNameEmpty = this.isFilterNameEmpty
    },

    onSave() {
      if (this.isEdit) {
        this.renameFilter()
      } else {
        this.createFilterView()
      }
    },

    async renameFilter() {
      if (this.placement === FILTERS) {
        const api = new OkrFiltersApiHandler()
        this.loading = true
        try {
          const payload = {
            name: this.filterName,
            accountId: this.$store.state.system.userData.userAccountId,
            filters: {
              ...this.formModel.filters
            },

            filterViewId: this.formModel.id
          }
          const updatedFilter = await api.updateFilterViewForUser(payload)
          this.$store.dispatch('okrFilters/updateFilter', updatedFilter)
          showNotify({
            title: this.$t('filters_saver.filter_renamed', { filterName: this.filterName })
          })
        } catch (error) {
          handleError({ error })
        } finally {
          this.loading = false
        }
      } else if (this.placement === DASHBOARD) {
        const customDashboardApi = new CustomDashboardApiHandler()
        this.loading = true
        try {
          const currentDashboard = this.favoriteList.find(
            dashboard => dashboard.id === this.formModel.id
          )

          await customDashboardApi.updateDashboard({
            ...currentDashboard,
            id: this.formModel.id,
            name: this.filterName,
            isPrivate: currentDashboard.private
          })
          await Promise.all([this.getDashboardList(), this.getFavoriteList()])
          this.$store.dispatch('dashboard/getDashboardItem')
        } catch (error) {
          handleError({ error })
        } finally {
          this.loading = false
        }
      }
      this.closeForm()
    },

    async createFilterView() {
      this.loading = true
      const api = new OkrFiltersApiHandler()

      const { filtersValues, customFieldsFiltersValues, haveCustomFieldFiltersDefaultValues } =
        this.formModel

      try {
        const payload = {
          accountId: this.$store.state.system.userData.userAccountId,
          name: this.filterName,
          filters: prepareFiltersPayload(
            {
              ...filtersValues,
              [CUSTOM_FIELDS_FILTERS_QUERY_KEY]: getResolvedCustomFieldFiltersValues({
                customFieldsFiltersValues,
                haveCustomFieldFiltersDefaultValues
              })
            },
            this.$store.state.workspaces.workspaceId
          )
        }

        const newFilter = await api.createFilterViewForUser(payload)
        this.$store.dispatch('okrFilters/addNewFilter', newFilter)
        showNotify({
          title: this.$t('filters_saver.filter_saved', { filterName: this.filterName })
        })
        await this.$router.replace({
          query: {
            [FILTER_PRESET_KEY]: newFilter.id
          }
        })
        this.loading = false
        this.closeForm()
      } catch (error) {
        this.loading = false
        handleError({ error })
      }
    },

    onCancel() {
      this.closeForm()
    },

    closeForm() {
      this.$emit('close-form')
    }
  }
})
</script>

<style lang="scss" scoped>
.ofs-Form {
  padding: 20px;
  width: 260px;
  box-sizing: border-box;
}

.ofs-Form_Actions {
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

.ofs-FieldName {
  font-family: $system-ui;
  font-weight: fw('bold');
  font-size: $fs-12;
  line-height: 16px;
  color: $dark-3;
}
</style>
