<template>
  <AppIcon
    v-tippy="{
      content: $t('filters.custom_fields.warning'),
      placement: 'top'
    }"
    class="sf-WarningIcon"
    height="16"
    icon-name="custom-filters-warning-icon"
    width="16"
  />
</template>

<script setup>
import AppIcon from '@/components/ui/AppIcon/AppIcon'

defineOptions({
  name: 'SavedFiltersDropDownListOption'
})
</script>

<style lang="scss" scoped>
.sf-WarningIcon {
  flex: 0 0 auto;
}
</style>
