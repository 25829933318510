import { isEmpty } from 'lodash'

export const FILTERS = 'FILTERS'

export const DASHBOARD = 'DASHBOARD'

export const placementPropValidator = value => {
  return [FILTERS, DASHBOARD].includes(value)
}

export const globalGroupNameCellListValidator = ({ value, whiteList } = {}) => {
  const isValid =
    Array.isArray(value) &&
    !isEmpty(value) &&
    value.every(item => Object.values(whiteList).includes(item))

  if (!isValid) {
    throw new Error(`Unknown item, allowed items are: ${Object.values(whiteList).join(', ')}`)
  }

  return isValid
}
