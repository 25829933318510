<template>
  <Modal
    v-if="opened"
    :scrollable-wrapper="true"
    :show="opened"
    class="agwm-Modal"
    data-auto-testid="add-groups-to-workspace-modal"
    data-testid="add-groups-to-workspace-modal"
    manual-close
    size="lg-next"
    sticky-header
    @close="onClose"
  >
    <template #header>
      <TitleWithAction> {{ $t('global_groups.add_groups_to_workspace') }} </TitleWithAction>
    </template>
    <template #before-close>
      <SavingIndicator ref="savingIndicator" />
    </template>
    <template #loader>
      <SavingIndicator ref="savingIndicatorTop" :type="SAVING_INDICATOR_TYPES.LINE" />
    </template>
    <template #modal-body>
      <SettingsPagesEmptyState
        v-if="isNoGroups"
        data-testid="groups-empty-state"
        hero-height="128"
        hero-icon="no-groups-hero"
        hero-width="174"
      >
        <template #title> {{ $t('workspaces.no_groups.title') }} </template>
        <template #subtitle> {{ $t('workspaces.no_groups.subtitle') }} </template>

        <template #action>
          <AppButton
            data-testid="empty-state-create-button"
            height="24"
            icon="plus-next"
            type="primary-next"
            width="24"
            @click="onCreateGroupClick"
          >
            {{ $t('workspaces.create_group') }}
          </AppButton>
        </template>
      </SettingsPagesEmptyState>

      <template v-else>
        <div class="agwm-ModalBodyToolbar">
          <div class="agwm-ToolbarFilter">
            <OkrFilterSelect
              :bottom-fixed-items="filterBottomItems"
              :dropdown-min-width="300"
              :loading="isGroupsFilterLoading || isSaving"
              :model-value="listState.filtersValues[GROUP_IDS]"
              :options="groupsFilterOptions"
              :search-function="searchString => fetchGroupsForFilter({ searchString })"
              has-only-this-button
              n-selected-label="filter.groups"
              prepend-icon="team-next"
              @update:options="groupsFilterOptions = $event"
              @update:model-value="selectGroupsFilter"
            >
              <template #option-label="{ option }">
                <GlobalGroupsSelectOption v-if="option" :group="option" />
              </template>
              <template #bottom-fixed-items="{ bottomFixedItems }">
                <div v-for="item in bottomFixedItems" :key="item.id">
                  <BottomFixedSelectItem
                    v-if="isClearSelectionAction(item.action)"
                    @click="clearGroupsFilter"
                  >
                    {{ $t(item.text) }}
                  </BottomFixedSelectItem>
                </div>
              </template>
            </OkrFilterSelect>
          </div>

          <AppButton
            height="24"
            icon="plus-next"
            type="primary-next"
            width="24"
            @click="onCreateGroupClick"
          >
            {{ $t('workspaces.create_group') }}
          </AppButton>
        </div>
        <div class="agwm-ModalBodyTable">
          <InfiniteTableExpandable
            ref="tableReference"
            :child-items-key="CHILD_ITEMS_KEY"
            :column-with-expand-collapse-button="TABLE_COLUMNS_KEYS.NAME"
            :columns="COLUMNS"
            :disable-user-select="false"
            :list-state="listState"
            :loading="listState.isLoading"
            :offset-left="ADD_GROUP_TO_WORKSPACE_TABLE_OFFSET"
            :offset-right="ADD_GROUP_TO_WORKSPACE_TABLE_OFFSET"
            :selected-items="selectedGroups"
            sticky-header
            @toggle-expand-collapse="onExpandCollapseItem({ itemId: $event })"
            @expand-all="expandAll"
            @collapse-all="collapseAll"
          >
            <template #header-cell="{ column }">
              <div
                v-if="column.key === TABLE_COLUMNS_KEYS.BULK_CHECKBOX"
                class="agwm-CheckboxWrapper"
              >
                <AppCheckbox
                  v-model="allGroups"
                  :disabled="listState.isLoading"
                  full-height
                  full-width
                />
              </div>
              <template v-if="column.title">
                {{ $t(column.title) }}
              </template>
            </template>

            <template #cell="{ item, columnKey }">
              <div
                v-if="columnKey === TABLE_COLUMNS_KEYS.BULK_CHECKBOX"
                class="agwm-CheckboxWrapper"
              >
                <AppCheckbox
                  v-if="isGroupAlreadyAddedToWs(item)"
                  v-tippy="{
                    content: $t('badge.already_added'),
                    placement: 'top'
                  }"
                  :model-value="true"
                  :style="{
                    '--checkmark-color': 'var(--grey-1-next)'
                  }"
                  disabled
                  full-height
                  full-width
                  value
                />

                <AppCheckbox
                  v-else
                  v-model="selectedGroups"
                  v-tippy="{
                    content: userCanAddGroupToWorkspace(item)
                      ? null
                      : $t('global_groups.unavailable_for_add_to_ws'),
                    placement: 'top',
                    theme: `${DROP_LIST_THEMES.TRANSLUCENT_NEXT} ${DROP_LIST_THEMES.TEXT_CENTER}`
                  }"
                  :disabled="isGroupUnavailable(item) || !userCanAddGroupToWorkspace(item)"
                  :value="item.id"
                  full-height
                  full-width
                />
              </div>
              <template v-if="columnKey === TABLE_COLUMNS_KEYS.NAME">
                <GlobalGroupNameCell
                  :filtered-out="!item.fitFilterCriteria"
                  :group="item"
                  :style="{
                    '--cell-padding-right': 0
                  }"
                  hide-actions
                  @click="onExpandCollapseItem({ itemId: item.id })"
                />
              </template>
            </template>

            <template #loading>
              <AddGroupsToWorkspaceLoader />
            </template>

            <template #footer>
              <InfiniteScrollLoaderNext
                v-if="!listState.isAllItemsLoaded"
                :loading="listState.isLoading"
                @load-more="onLoadMore"
              />
            </template>
          </InfiniteTableExpandable>
        </div>
      </template>
    </template>

    <template #modal-footer>
      <div v-if="!isNoGroups" class="agwm-ModalFooter">
        <AppButton type="ghost-next" @click="onClose">
          {{ $t('action.cancel') }}
        </AppButton>

        <AppButton
          :disable="
            isConfirmButtonDisabled || listState.isLoading || isSaving || isGroupsFilterLoading
          "
          :loading="isSaving"
          type="primary-next"
          @click="addGroups"
        >
          {{ $t('global_groups.add_selected_groups', { count: selectedGroups.length }) }}
        </AppButton>
      </div>
    </template>
  </Modal>
  <portal to="modal-windows">
    <GlobalGroupModal
      v-model:show="isShowGlobalGroupModal"
      :predefined-workspace-id="workspaceId"
      :tracking-source="EVENT_SOURCES.ADD_GROUP_MODAL"
      @on-group-created="onCreateGroup"
    />

    <AppDialog
      :show="isConfirmCloseShow"
      :title="$t('confirm_modal.title')"
      :type="DIALOG_TYPES.WARNING"
      @on-close="hideConfirmCloseModal"
      @on-confirm="onConfirmClose"
    >
      {{ $t('confirm_modal.description') }}

      <template #confirm-btn-text>
        {{ $t('confirm.discard_btn') }}
      </template>
    </AppDialog>
  </portal>
</template>

<script setup>
import { isEmpty, isEqual } from 'lodash'
import { computed, ref, watch } from 'vue'
import { useStore } from 'vuex'

import GlobalGroupsApiHandler from '@/api/global-groups'
import {
  isClearSelectionAction,
  SELECTS_WITH_CHECKBOX_ITEMS
} from '@/composables/bottom-fixed-items'
import { useGlobalGroups } from '@/composables/global-groups'
import {
  EVENT_SECTIONS,
  EVENT_SOURCES,
  EVENT_SUBCATEGORIES,
  FILTER_LABELS_FOR_TRACKING,
  TAB_NAMES_FOR_TRACKING,
  trackAddRemoveGroupToWorkspaceEvent,
  trackApplySettingsPagesFilterEvent,
  trackCreateGlobalGroupModalOpenedEvent
} from '@/tracking/amplitude-helpers'
import { DIALOG_TYPES } from '@/utils/components-configurations/app-dialog'
import { DROP_LIST_THEMES } from '@/utils/components-configurations/app-droplist'
import { SAVING_INDICATOR_TYPES } from '@/utils/components-configurations/saving-indicator'
import { REQUEST_ENTITY_KEYS } from '@/utils/entity-keys'
import { handleError } from '@/utils/error-handling'
import {
  ADD_GROUP_TO_WORKSPACE_TABLE_OFFSET,
  CHILD_ITEMS_KEY,
  getAvailableGroupIdsDeep,
  isGroupUnavailable,
  userCanAddGroupToWorkspace
} from '@/utils/global-groups'
import { DEFAULT_VALUE_FOR_FILTER } from '@/utils/okr-elements/filters'
import { numberOrNullProp, stringOrNullProp } from '@/utils/prop-validators'
import { getNewSelectWithSelectAllValue, selectAllIsSelected } from '@/utils/select'
import { TABLE_COLUMNS_KEYS } from '@/utils/table-columns'

import AppDialog from '@/components/AppDialog'
import GlobalGroupModal from '@/components/global-groups/GlobalGroupModal'
import GlobalGroupNameCell from '@/components/global-groups/GlobalGroupNameCell'
import GlobalGroupsSelectOption from '@/components/global-groups/GlobalGroupsSelectOption'
import TitleWithAction from '@/components/objectives/TitleWithAction'
import BottomFixedSelectItem from '@/components/objectives/toolbar/BottomFixedSelectItem'
import OkrFilterSelect from '@/components/objectives/toolbar/OkrFilterSelect'
import SavingIndicator from '@/components/SavingIndicator'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppCheckbox from '@/components/ui/AppCheckbox/AppCheckbox'
import InfiniteScrollLoaderNext from '@/components/ui/InfiniteScrollLoaderNext'
import InfiniteTableExpandable from '@/components/ui/InfiniteTableExpandable/InfiniteTableExpandable'
import Modal from '@/components/ui/Modal/Modal'
import SettingsPagesEmptyState from '@/components/ui/SettingsPagesEmptyState/SettingsPagesEmptyState'
import AddGroupsToWorkspaceLoader from '@/components/ui/SkeletonLoaders/AddGroupsToWorkspaceLoader'
import { USERS_QUERY_KEYS } from '@/views/workspaces/settings/plugin-users/users-query-params'

defineOptions({
  name: 'AddGroupsToWorkspaceModal'
})

const { fetchGroupsForFilter, useGlobalGroupsTableHelpers, ALL_GROUPS_OPTION } = useGlobalGroups()

const {
  listState,
  fetchGroupsForTable,
  reloadAllGroups,
  onExpandCollapseItem,
  expandAll,
  collapseAll,
  resetListState
} = useGlobalGroupsTableHelpers({
  childItemsKey: CHILD_ITEMS_KEY,
  saveExpandedItemsAndFiltersToQuery: false
})
const { GROUP_IDS } = USERS_QUERY_KEYS

const COLUMNS = [
  {
    key: TABLE_COLUMNS_KEYS.BULK_CHECKBOX,
    width: 32,
    isBulkAction: true
  },
  {
    key: TABLE_COLUMNS_KEYS.NAME,
    title: 'group.label.name',
    width: 'auto'
  }
]

const props = defineProps({
  opened: {
    type: Boolean
  },

  workspaceId: {
    required: true,
    validator: v => stringOrNullProp(v) || numberOrNullProp(v)
  }
})

const selectedGroups = ref([])

const onLoadMore = async () => {
  await getGroupsForTable()
}

const groupsAvailableToAdd = computed(() => {
  return getAvailableGroupIdsDeep(listState.value.items).reduce(
    (acc, val) => {
      if (isGroupAlreadyAddedToWs(val)) {
        acc.alreadyAdded.push(val.id)
      } else {
        acc.availableToAdd.push(val.id)
      }
      return acc
    },
    {
      alreadyAdded: [],
      availableToAdd: []
    }
  )
})

const tableReference = ref(null)

const allGroups = computed({
  get: () =>
    isEqual(selectedGroups.value.toSorted(), groupsAvailableToAdd.value.availableToAdd.toSorted()),
  set: value => {
    if (value) {
      selectedGroups.value = [...groupsAvailableToAdd.value.availableToAdd]
      // expandAll()
    } else {
      selectedGroups.value = []
    }
  }
})

const isSaving = ref(false)
const savingIndicator = ref(null)
const savingIndicatorTop = ref(null)
const areDataChanged = computed(() => !isEmpty(selectedGroups.value))
const isConfirmCloseShow = ref(false)

const hideConfirmCloseModal = () => {
  isConfirmCloseShow.value = false
}

const onConfirmClose = () => {
  hideConfirmCloseModal()
  checkConfirmationAndCloseModal({ checkDataChange: false })
}

const emit = defineEmits({
  'on-close': null,
  'on-groups-added': null
})

const checkConfirmationAndCloseModal = ({ checkDataChange = true } = {}) => {
  if (checkDataChange !== false && areDataChanged.value) {
    isConfirmCloseShow.value = true
  } else {
    emit('on-close')
  }
}

const onSavingStarted = () => {
  isSaving.value = true
  savingIndicator.value.startSaving()
  savingIndicatorTop.value.startSaving()
}

const onSavingFinished = () => {
  isSaving.value = false
  savingIndicator.value.endSaving()
  savingIndicatorTop.value.endSaving()
}
const onClose = () => {
  if (!isSaving.value && !isGroupsFilterLoading.value && !listState.value.isLoading) {
    checkConfirmationAndCloseModal()
  }
}
const isGroupAlreadyAddedToWs = group => {
  return !isGroupUnavailable(group) && group.workspaces.some(({ id }) => id === props.workspaceId)
}

const isConfirmButtonDisabled = computed(() => isEmpty(selectedGroups.value))

const clearGroupsFilter = () => {
  if (!isEqual(listState.value.filtersValues[GROUP_IDS], DEFAULT_VALUE_FOR_FILTER)) {
    selectGroupsFilter([])
  }
}
const selectGroupsFilter = async newValue => {
  listState.value.filtersValues[GROUP_IDS] = getNewSelectWithSelectAllValue(
    newValue,
    listState.value.filtersValues[GROUP_IDS]
  )

  selectedGroups.value = []

  trackApplySettingsPagesFilterEvent({
    section: EVENT_SECTIONS.ADD_GROUP_MODAL,
    tab: TAB_NAMES_FOR_TRACKING.NONE,
    label: FILTER_LABELS_FOR_TRACKING.GROUP
  })

  await reloadAllGroups()
}
const filterBottomItems = computed(() => {
  if (isEqual(listState.value.filtersValues[GROUP_IDS], DEFAULT_VALUE_FOR_FILTER)) {
    return []
  }
  return SELECTS_WITH_CHECKBOX_ITEMS
})

const addGroups = async () => {
  const api = new GlobalGroupsApiHandler()
  try {
    onSavingStarted()
    await api.addGlobalGroupsToWorkspace({
      workspaceId: props.workspaceId,
      groupIds: selectedGroups.value
    })

    trackAddRemoveGroupToWorkspaceEvent({
      role: userRoleForTracking.value,
      subcategory: EVENT_SUBCATEGORIES.BULK,
      source: EVENT_SOURCES.ADD_GROUP_MODAL,
      label: props.workspaceId
    })
  } catch (error) {
    handleError({ error })
  } finally {
    onSavingFinished()
    emit('on-groups-added')
  }
}

const getGroupsForTable = async () => {
  await fetchGroupsForTable({
    [REQUEST_ENTITY_KEYS.EXPAND_ALL]: true
  })
}

const groupsFilterOptions = ref([ALL_GROUPS_OPTION])
const isGroupsFilterLoading = ref(false)

const getInitialGroupsForFilter = async () => {
  isGroupsFilterLoading.value = true
  try {
    const groups = await fetchGroupsForFilter()
    groupsFilterOptions.value = [ALL_GROUPS_OPTION, ...groups]
  } catch (error) {
    handleError({ error })
  } finally {
    isGroupsFilterLoading.value = false
  }
}

watch(
  () => props.opened,
  async newValue => {
    if (newValue) {
      await Promise.all([getInitialGroupsForFilter(), getGroupsForTable()])
    }
    if (!newValue) {
      resetListState()
      selectedGroups.value = []
      groupsFilterOptions.value = [ALL_GROUPS_OPTION]
    }
  },
  {
    immediate: true
  }
)

const isNoGroups = computed(() => {
  return (
    !listState.value.isLoading &&
    !isGroupsFilterLoading.value &&
    isEmpty(listState.value.items) &&
    (selectAllIsSelected(listState.value.filtersValues[GROUP_IDS]) ||
      isEmpty(listState.value.filtersValues[GROUP_IDS]))
  )
})

const store = useStore()

const userRoleForTracking = computed(() => {
  return store.getters['system/userRoleForTracking']
})

const isShowGlobalGroupModal = ref(false)

const onCreateGroupClick = () => {
  isShowGlobalGroupModal.value = true

  trackCreateGlobalGroupModalOpenedEvent({
    role: userRoleForTracking.value,
    source: EVENT_SOURCES.ADD_GROUP_MODAL
  })
}

const onCreateGroup = () => {
  isShowGlobalGroupModal.value = false
  emit('on-groups-added')
}
</script>

<style lang="scss" scoped>
$modal-header-height: 90px; // size that rendered in DOM
$modal-body-toolbar-height: 58px; // size that rendered in DOM
.agwm-Modal {
  --modal-header-sticky-top: 0;

  :deep(.o-modal-content) {
    height: auto;
  }
}

.agwm-ModalBodyToolbar {
  padding: 14px v-bind(ADD_GROUP_TO_WORKSPACE_TABLE_OFFSET) 12px;
  display: flex;
  align-items: center;
  position: sticky;
  top: $modal-header-height;
  z-index: 5;
  background-color: $white;
  justify-content: space-between;
  gap: 8px;
}

.agwm-ModalBodyTable {
  padding: 0 v-bind(ADD_GROUP_TO_WORKSPACE_TABLE_OFFSET);
  --head-padding-bottom: 4px;
  --head-padding-top: 12px;
  --sticky-top: calc(#{$modal-header-height} + #{$modal-body-toolbar-height});
  &:deep(.iter-Cell-name) {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.agwm-ModalFooter {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: flex-end;
  padding: 0 v-bind(ADD_GROUP_TO_WORKSPACE_TABLE_OFFSET);
  height: 65px;
  position: sticky;
  bottom: 0;
  z-index: 4;
  background-color: $white;
  box-shadow: inset 0 1px 0 $grey-2-next;
}

.agwm-ToolbarFilter {
  max-width: 300px;
  --select-skeleton-left: 0px;
  --select-skeleton-top: 0px;
}

.agwm-CheckboxWrapper {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  --label-justify-content: center;
}
</style>
