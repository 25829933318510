<template>
  <div class="utr-Wrapper">
    <div class="utr-TextWrapper">
      <span class="utr-Title">
        {{ $t('subscription.upgrade_your_tier') }}
      </span>
      <span class="utr-Subtitle">
        {{
          $t(
            'subscription.upgrade_your_tier_description',
            { day: licenseStatusDaysLeft },
            licenseStatusDaysLeft
          )
        }}
      </span>
    </div>
    <AppButton :loading="loading" icon="arrow-up-next" @click="onClickUpgrade">
      {{ $t('subscription.upgrade_tier') }}
    </AppButton>
    <portal to="modal-windows">
      <ManagePlanDialog
        v-model:show="isShowManagePlan"
        :current-subscription="subscription"
        is-annual-only
      />
    </portal>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'

import { handleError } from '@/utils/error-handling'
import { PLUGIN_OPTIONS_KEYS } from '@root/template-options-keys'
import ManagePlanDialog from '@shared-modules/components/billing-settings/ManagePlanDialog'

import AppButton from '@/components/ui/AppButton/AppButton'

defineOptions({
  name: 'UpgradeTierReminder'
})

const isShowManagePlan = ref(false)
const store = useStore()
const subscription = computed(() => store.state.system.billingPlanDetails.data || {})
const licenseStatusDaysLeft = computed(
  () => store.state.pluginOptions[PLUGIN_OPTIONS_KEYS.LICENSE_STATUS_DAYS_LEFT]
)
const loading = ref(false)
const onClickUpgrade = async () => {
  try {
    loading.value = true
    await store.dispatch('system/fetchBillingPlanDetails', { withLoading: false })
    isShowManagePlan.value = true
  } catch (error) {
    handleError({ error })
  } finally {
    loading.value = false
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';

.utr-Wrapper {
  border: 2px solid $grey-2-next;
  border-radius: $border-radius-md-next;
  padding: 20px 8px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.utr-TextWrapper {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.utr-Title {
  color: $dark-2;
  font-size: $fs-14;
  line-height: 20px;
  font-weight: fw('semi-bold');
}

.utr-Subtitle {
  color: $dark-3;
  font-size: $fs-12;
  line-height: 16px;
}
</style>
