<template>
  <template v-if="withVirtualScroll">
    <VirtualList
      :data-key="dataKey"
      :data-source="data"
      :disabled="disabled"
      :ghost-class="ghostClass"
      :handle="handleClass"
      :keeps="keeps"
      :page-mode="pageMode"
      :scroll-threshold="0"
      chosen-class="original-chosen"
      @change="dragChange"
      @drag="dragStart"
      @drop="dragEnd"
      @update:data-source="emit('update:data', $event)"
    >
      <template #item="{ record, index, dataKey: dataValue }">
        <slot :data-key="dataValue" :index="index" :item="record" />
      </template>
    </VirtualList>
  </template>

  <template v-else>
    <slot v-for="item in data" :key="item[dataKey]" :item="item" />
  </template>
</template>

<script setup>
import VirtualList from '@vaiyav/vue-virtual-draglist'

defineProps({
  withVirtualScroll: {
    type: Boolean,
    default: true
  },

  data: {
    type: Array,
    default: () => []
  },

  keeps: {
    type: Number,
    default: 50
  },

  dataKey: {
    type: String,
    default: 'id'
  },

  pageMode: {
    type: Boolean,
    default: true
  },

  disabled: {
    type: Boolean
  },

  handleClass: {
    type: String,
    default: '.drag-trigger'
  },

  ghostClass: {
    type: String,
    default: 'sortable-chosen'
  }
})
const emit = defineEmits({
  'drag-start': null,
  'drag-end': null,
  'drag-change': null,
  'update:data': null
})
const dragStart = e => {
  emit('drag-start', e)
}
const dragEnd = e => {
  emit('drag-end', e)
}
const dragChange = e => {
  emit('drag-change', e)
}
</script>

<style lang="scss" scoped></style>
