export const EMPTY_STATE_TEST_ID = 'custom-fields-empty-state'

export const SIDEBAR_FIELDS_TABLE_TEST_ID = 'sidebar-custom-fields-table'

export const CONTENT_FIELDS_TABLE_TEST_ID = 'content-custom-fields-table'

export const VISUAL_PRESENTATION_TEST_ID = 'visual-presentation'

export const CUSTOM_FIELDS_TABLE_CREATE_BUTTON_TEST_ID = 'custom-fields-table-create-button'

export const CUSTOM_FIELDS_TABLE_LIMIT_WARNING_TEST_ID = 'custom-fields-table-limit-warning-message'

export const CUSTOM_FIELDS_PAGE_CREATE_BUTTON_TEST_ID = 'custom-fields-page-create-button'

export const CUSTOM_FIELDS_EMPTY_STATE_CREATE_BUTTON_TEST_ID =
  'custom-fields-empty-state-create-button'

export const CUSTOM_FIELDS_MODAL_TEST_ID = 'custom-fields-modal'

export const CUSTOM_FIELDS_DELETE_MODAL_TEST_ID = 'custom-fields-delete-modal'

export const CUSTOM_FIELD_DELETE_MODAL_INPUT_TEST_ID = 'custom-field-delete-modal-input'

export const CUSTOM_FIELD_DELETE_MODAL_ERROR_MESSAGE_TEST_ID =
  'custom-field-delete-modal-error-message'

export const ROW_DROPDOWN_MENU_TEST_ID = 'custom-fields-table-row-dropdown-menu'

export const CUSTOM_FIELDS_TABLE_NAME_COLUMN_TEST_ID = 'custom-fields-table-name-cell'

export const CUSTOM_FIELD_MODAL_ERROR_MESSAGE_TEST_ID = 'custom-field-modal-error-message'

export const CUSTOM_FIELD_MODAL_HELP_TEXT_CHECKBOX_TEST_ID = 'custom-field-modal-help-text-checkbox'

export const CUSTOM_FIELD_MODAL_FIELD_TYPE_SELECT_TEST_ID = 'custom-field-modal-field-type-select'

export const CUSTOM_FIELD_MODAL_FIELD_NAME_INPUT_TEST_ID = 'custom-field-modal-field-name-input'

export const CUSTOM_FIELD_MODAL_ADD_FOR_SELECT_TEST_ID = 'custom-field-modal-add-for-select'

export const CUSTOM_FIELD_MODAL_ADD_FOR_WARNING_MESSAGE_TEST_ID =
  'custom-field-modal-add-for-warning-message'
export const CUSTOM_FIELD_MODAL_WORKSPACE_SELECT_TEST_ID = 'custom-field-modal-workspace-select'

export const CUSTOM_FIELD_MODAL_HELP_TEXT_INPUT_TEST_ID = 'custom-field-modal-help-text-input'

export const CUSTOM_FIELD_MODAL_CURRENCY_VALUE_INPUT_TEST_ID = 'custom-field-modal-currency-input'

export const CUSTOM_FIELD_MODAL_CURRENCY_FIELD_ERROR_TEST_ID = 'custom-field-modal-currency-error'

export const CUSTOM_FIELD_MODAL_OPTIONS_FIELD_ERROR_TEST_ID = 'custom-field-modal-options-error'

export const CUSTOM_FIELD_MODAL_OPTIONS_FIELD_ADD_BUTTON_TEST_ID =
  'custom-field-modal-options-add-button'

export const CUSTOM_FIELD_MODAL_OPTIONS_FIELD_OPTION_INPUT_TEST_ID =
  'custom-field-modal-option-input'

export const CUSTOM_FIELD_MODAL_OPTIONS_FIELD_OPTION_REMOVE_BUTTON_TEST_ID =
  'custom-field-modal-option-remove-button'

export const SINGLE_LINE_TEXT_FORM_FIELD_TEST_ID = 'single-line-text-form-custom-field'

export const LONG_TEXT_FORM_FIELD_TEST_ID = 'long-text-form-custom-field'

export const LINKS_FORM_FIELD_TEST_ID = 'links-form-custom-field'

export const LINKS_FORM_FIELD_ADD_BUTTON_TEST_ID = 'links-form-custom-field-add-button'

export const ASSIGNEE_FORM_FIELD_TEST_ID = 'assignee-form-custom-field'

export const DATE_FORM_FIELD_TEST_ID = 'date-form-custom-field'

export const SINGLE_SELECT_FORM_FIELD_TEST_ID = 'single-select-form-custom-field'

export const MULTI_SELECT_FORM_FIELD_TEST_ID = 'multi-select-form-custom-field'

export const NUMBER_FORM_FIELD_TEST_ID = 'number-form-custom-field'

export const MONEY_FORM_FIELD_TEST_ID = 'money-form-custom-field'

export const NUMBER_MONEY_INPUT_TEST_ID = 'form-custom-field-number-money-input'

export const OKR_ELEMENT_FORM_CUSTOM_FIELD_HUB_TEST_ID = 'okr-element-form-custom-field-hub'
