<script>
import { defineComponent, h } from 'vue'

export default defineComponent({
  name: 'ContributeLine',
  render() {
    return h('div', {
      class: 'cl-Line'
    })
  }
})
</script>

<style scoped lang="scss">
.cl-Line {
  height: var(--height, 32px);
  min-height: 6px;
  width: var(--width, 10px);
  border-left: 1px solid;
  border-bottom: 1px solid;
  border-color: $grey-2-next;
  border-radius: 0 0 0 $border-radius-sm-next;
  pointer-events: none;
}
</style>
