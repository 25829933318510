<template>
  <div class="al-Page">
    <PageContentHeader
      :description="$t('audit_logs.description')"
      :level="3"
      :max-width="HEADER_SIZES.LG"
      :title="$t('menu.audit_logs')"
      no-margin
      style="--padding-bottom: 18px"
    />

    <div class="al-Toolbar">
      <AppRadioGroup
        :model-value="currentView"
        :options="PAGES"
        name="logs-navigation"
        type="tab-like"
        @update:model-value="onViewChange"
      />
    </div>
  </div>
  <router-view v-if="accessValidated" />
</template>

<script setup>
import { isUndefined } from 'lodash'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

import { ROUTE_NAMES } from '@/routes/route-helpers'
import { tracker } from '@/tracking/amplitude'
import { EVENT_CATEGORIES, EVENT_NAMES } from '@/tracking/amplitude-helpers'
import { HEADER_SIZES } from '@/utils/components-configurations/page-content-header'
import { PLUGIN_OPTIONS_KEYS } from '@root/template-options-keys'

import AppRadioGroup from '@/components/ui/AppRadioGroup/AppRadioGroup'
import PageContentHeader from '@/components/ui/PageContentHeader/PageContentHeader'

defineOptions({
  name: 'AuditLogsLayout'
})

const { t } = useI18n()

const PAGES = [
  {
    value: ROUTE_NAMES.AUDIT_LOGS_GROUPS,
    label: t('filter.groups'),
    trackingTab: 'filter.groups'
  },
  {
    value: ROUTE_NAMES.AUDIT_LOGS_ELEMENTS,
    label: t('audit_logs.elements'),
    trackingTab: 'audit_logs.elements'
  }
]

const route = useRoute()

const currentView = computed(() => route.name)

const store = useStore()

const isPluginAdmin = computed(() => store.state.pluginOptions[PLUGIN_OPTIONS_KEYS.IS_PLUGIN_ADMIN])
const accessValidated = ref(false)
const router = useRouter()

watch(
  isPluginAdmin,
  newValue => {
    if (!isUndefined(newValue)) {
      if (newValue) {
        accessValidated.value = true
      } else {
        router.push({ name: ROUTE_NAMES.MISSING_PAGE })
      }
    }
  },
  { immediate: true }
)

const userRoleForTracking = computed(() => {
  return store.getters['system/userRoleForTracking']
})

const onViewChange = view => {
  const page = PAGES.find(page => page.value === view)

  if (page) {
    router.push({ name: view })
    const trackerData = {
      category: EVENT_CATEGORIES.SETTINGS,
      subCategory: t('menu.audit_logs', 1, { locale: 'en' }),
      role: userRoleForTracking.value
    }

    if (page.trackingTab) {
      trackerData.tab = t(page.trackingTab, 1, { locale: 'en' })
    }

    tracker.logEvent(EVENT_NAMES.SETTINGS_OPENED, trackerData)
  }
}
</script>

<style lang="scss" scoped>
.al-Toolbar {
  width: 100%;
  max-width: $page-width-lg;
  padding: 20px $page-right-padding 0 $page-left-padding;
}
</style>
