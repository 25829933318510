import ApiHandler from '@/api/base'

class LabelsApiHandler extends ApiHandler {
  async getLabels({ name = null } = {}) {
    const { data } = await this.http.get('/labels', {
      params: {
        name
      }
    })
    return data
  }

  async createLabel({ color = '#000000', name } = {}) {
    const { data } = await this.http.post('/labels', {
      color,
      name
    })
    return data
  }

  async updateLabel({ color = '#000000', id, name } = {}) {
    const { data } = await this.http.put('/labels', {
      color,
      id,
      name
    })
    return data
  }

  async deleteLabel({ id } = {}) {
    const { data } = await this.http.delete('/labels', {
      data: { id }
    })
    return data
  }
}

export default LabelsApiHandler
