import { useWebWorkerFn } from '@vueuse/core'
import { cloneDeep, has, isEmpty, isEqual } from 'lodash'
import { computed, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import { isItemHasChildren } from '@/utils/global-groups'
import { FILTERS_KEYS, restoreFilterValue } from '@/utils/okr-elements/filters'
import { replaceQueryParameters } from '@/utils/router'

export const DEFAULT_LIST_STATE = {
  filtersValues: {},
  expandedItems: {},
  offset: 0,
  isAllItemsLoaded: false,
  items: [],
  isLoading: false,
  isExpandCollapseAllLoading: false,
  restoredExpandedItems: null,
  initialDataLoaded: false
}
export const useInfiniteExpandableTable = ({
  defaultFiltersValues = {},
  saveExpandedItemsAndFiltersToQuery = false,
  expandedItemsQueryKey = FILTERS_KEYS.EXPANDED_ITEMS,
  childItemsKey = null
} = {}) => {
  if (!childItemsKey) {
    throw new Error('childItemsKey is required')
  }

  const listState = ref(
    cloneDeep({
      ...DEFAULT_LIST_STATE,
      filtersValues: cloneDeep(defaultFiltersValues)
    })
  )

  const resetListState = () => {
    listState.value = cloneDeep({
      ...DEFAULT_LIST_STATE,
      filtersValues: cloneDeep(defaultFiltersValues)
    })
  }

  const setExpandedItems = async ({ list = [], mergeWithCurrentList = true } = {}) => {
    listState.value.isExpandCollapseAllLoading = true

    const createExpandedItemsList = ({ list = [] } = {}) => {
      return list.reduce((acc, id) => {
        acc[id] = true
        return acc
      }, {})
    }

    const { workerFn } = useWebWorkerFn(createExpandedItemsList)

    const expandedItems = await workerFn({ list })

    if (mergeWithCurrentList) {
      listState.value.expandedItems = { ...listState.value.expandedItems, ...expandedItems }
    } else {
      listState.value.expandedItems = { ...expandedItems }
    }

    listState.value.isExpandCollapseAllLoading = false
  }

  const onExpandCollapseItem = ({ itemId = null, keepExpanded = false } = {}) => {
    if (!itemId || !Object.keys(expandableItemsChains.value).includes(String(itemId))) {
      return
    }
    const { expandedItems } = listState.value
    if (has(expandedItems, itemId) && keepExpanded) {
      expandedItems[itemId] = true
    } else if (has(expandedItems, itemId)) {
      expandedItems[itemId] = !expandedItems[itemId]
    } else {
      expandedItems[itemId] = true
    }

    if (!expandedItems[itemId]) {
      // that means we are collapsing item,
      // so we need to collapse all children

      const allCollapsedChildren = expandableItemsChains.value[itemId].reduce((acc, item) => {
        return {
          ...acc,
          [item]: false
        }
      }, {})

      listState.value.expandedItems = {
        ...listState.value.expandedItems,
        ...allCollapsedChildren
      }
    }
  }

  const expandItemsRecursively = async ({
    items = [],
    mergeWithCurrentList,
    awaitForWorker = false
  } = {}) => {
    const getExpandedItemsList = items => {
      return items.reduce((acc, item) => {
        const isChildrenExist = isItemHasChildren({
          item,
          childItemsKey
        })

        if (isChildrenExist && item.expanded && item.visibleCount > 0) {
          const expandedChildren = getExpandedItemsList(item[childItemsKey])
          acc = [...acc, item.id, ...expandedChildren]
        }

        return acc
      }, [])
    }

    const expandedItems = getExpandedItemsList(items)

    if (awaitForWorker) {
      await setExpandedItems({ list: expandedItems, mergeWithCurrentList })
    } else {
      setExpandedItems({ list: expandedItems, mergeWithCurrentList })
    }
  }

  const expandableItemsChains = computed(() => {
    if (isEmpty(listState.value.items)) {
      return {}
    }
    const getDeepFlattenChildrenList = list => {
      return Object.entries(list).reduce((acc, [groupId, childItems]) => {
        if (Array.isArray(childItems)) {
          return [...acc, groupId, ...getDeepFlattenChildrenList(childItems)]
        }
        return acc
      }, [])
    }
    const findExpandableItemsRecursively = arr => {
      return arr.reduce((acc, group) => {
        const isChildrenExist = isItemHasChildren({
          item: group,
          childItemsKey
        })
        if (isChildrenExist) {
          return {
            ...acc,
            [group.id]: getDeepFlattenChildrenList(
              findExpandableItemsRecursively(group[childItemsKey])
            ),
            ...findExpandableItemsRecursively(group[childItemsKey])
          }
        }
        return acc
      }, {})
    }

    return findExpandableItemsRecursively(listState.value.items)
  })

  const route = useRoute()
  const router = useRouter()
  const restoreFiltersValuesAndExpandedItems = () => {
    if (saveExpandedItemsAndFiltersToQuery) {
      const restoredFiltersValues = {}

      Object.keys(defaultFiltersValues).forEach(key => {
        restoredFiltersValues[key] = restoreFilterValue(route, key, defaultFiltersValues[key], true)
      })

      const restoredExpandedItems = restoreFilterValue(route, expandedItemsQueryKey, [], true)

      listState.value.filtersValues = { ...cloneDeep(restoredFiltersValues) }

      setExpandedItems({ list: restoredExpandedItems })

      listState.value.restoredExpandedItems = [...restoredExpandedItems]
    }
  }

  const collapseAll = () => {
    listState.value.expandedItems = {}
  }

  const expandAll = async () => {
    await setExpandedItems({
      list: Object.keys(expandableItemsChains.value),
      mergeWithCurrentList: false
    })
  }

  const isFiltersUsed = computed(() => {
    return !isEqual(listState.value.filtersValues, defaultFiltersValues)
  })

  const watchTriggers = computed(() => [
    listState.value.expandedItems,
    listState.value.filtersValues,
    listState.value.isLoading
  ])

  watch(
    watchTriggers,
    async () => {
      if (saveExpandedItemsAndFiltersToQuery && !listState.value.isLoading) {
        const onlyExpandedItems = Object.entries(listState.value.expandedItems)
          .filter(([, value]) => {
            return value
          })
          .map(([key]) => key)
          .filter(item => {
            return Object.keys(expandableItemsChains.value).includes(item)
          })

        const clonedFilters = cloneDeep(listState.value.filtersValues)

        for (const key in clonedFilters) {
          clonedFilters[key] = JSON.stringify(clonedFilters[key])
        }

        await replaceQueryParameters(router, route, {
          ...clonedFilters,
          [expandedItemsQueryKey]: JSON.stringify(onlyExpandedItems)
        })
      }
    },
    { deep: true }
  )

  return {
    listState,
    onExpandCollapseItem,
    expandItemsRecursively,
    restoreFiltersValuesAndExpandedItems,
    expandableItemsChains,
    collapseAll,
    expandAll,
    isFiltersUsed,
    resetListState
  }
}
