import ApiHandler from '@/api/base'
import { LIMIT, NOTIFICATIONS_STATUSES } from '@/utils/in-app-notifications'

class NotificationsApiHandler extends ApiHandler {
  async getNotifications({ limit = LIMIT, startAt = 0 } = {}) {
    const { data } = await this.http.get('/notification', {
      params: {
        limit,
        startAt
      }
    })
    return data
  }

  async updateNotificationsStatus({ notificationIds = [] } = {}) {
    const { data } = await this.http.put('/notification/status', {
      notificationIds,
      statusId: NOTIFICATIONS_STATUSES.DELIVERED
    })
    return data
  }

  async getNotificationTypeAndChannels() {
    const { data } = await this.http.get('/notification/typeAndChannel')
    return data
  }

  async getNotificationSettings() {
    const { data } = await this.http.get('/notification/settings')
    return data
  }
  async updateNotificationSettings({ notificationsSettings = [] } = {}) {
    const { data } = await this.http.put('/notification/settings', {
      notificationsSettings
    })
    return data
  }

  async patchNotificationSettings({ notificationsSettings = [] } = {}) {
    const { data } = await this.http.patch('/notification/settings', {
      notificationsSettings
    })
    return data
  }
}

export default NotificationsApiHandler
