import { IS_DEVELOPMENT } from '@root/app-modes'

const CONFLUENCE_ROUTES = [
  {
    path: '/jira-dashboard',
    name: 'JiraDashboard',
    component: () => import('@jira/views/jira-dashboard')
  },

  {
    path: '/jira-widget',
    name: 'JiraWidget',
    component: () => import('@jira/views/jira-issue-sidebar')
  },

  {
    path: '/confluence-macro-filter-gadget',
    name: 'ConfluenceMacroFilterGadget',
    component: () => import('@jira/views/confluence-macros/confluence-macro-filter-gadget')
  },

  {
    path: '/confluence-macro-home-screen',
    name: 'ConfluenceMacroHomescreen',
    component: () => import('@jira/views/confluence-macros/confluence-macro-home-screen')
  },

  {
    path: '/confluence-macro-dashboard',
    name: 'ConfluenceMacroDashboard',
    component: () => import('@jira/views/confluence-macros/confluence-macro-dashboard')
  },

  {
    path: '/confluence-settings',
    name: 'ConfluenceSettings',
    component: () => import('@jira/views/confluence-macros/confluence-settings')
  },

  {
    path: '/confluence-macro-dashboard-settings',
    name: 'ConfluenceMacroDashboardSettings',
    component: () =>
      import('@jira/views/confluence-macro-settings/confluence-macro-dashboard-settings')
  },

  {
    path: '/confluence-macro-home-screen-settings',
    name: 'ConfluenceMacroHomeScreenSettings',
    component: () =>
      import('@jira/views/confluence-macro-settings/confluence-macro-home-screen-settings')
  },

  {
    path: '/confluence-macro-filter-settings',
    name: 'ConfluenceMacroFilterSettings',
    component: () =>
      import('@jira/views/confluence-macro-settings/confluence-macro-filter-settings')
  },

  {
    path: '/confluence-macro-custom-dashboard',
    name: 'ConfluenceMacroCustomDashboard',
    component: () => import('@jira/views/confluence-macros/confluence-macro-custom-dashboard')
  },

  {
    path: '/confluence-macro-custom-dashboard-settings',
    name: 'ConfluenceMacroCustomDashboardSettings',
    component: () =>
      import('@jira/views/confluence-macro-settings/confluence-macro-custom-dashboard-settings')
  },

  {
    path: '/confluence-permission-less',
    name: 'ConfluencePermissionLess',
    component: () => import('@jira/views/confluence-permission-less')
  },

  {
    path: '/confluence-error-page',
    name: 'ConfluenceErrorPage',
    component: () => import('@jira/views/confluence-error-page')
  }
]

export const CONFLUENCE_DEV_ROUTES = IS_DEVELOPMENT ? CONFLUENCE_ROUTES : []
