import ApiHandler from '@/api/base'

class WorkspaceGroupsApiHandler extends ApiHandler {
  async getGroups({
    workspaceId,
    searchString = null,
    userAccountIds = [],
    startAt = 0,
    limit = 50
  } = {}) {
    const { data } = await this.http.get('/groups', {
      params: {
        workspaceId,
        searchString,
        userAccountIds,
        startAt,
        limit
      }
    })
    return data
  }

  async getGroupsByIds({
    workspaceId,
    searchString = null,
    groupIds = [],
    userAccountIds = [],
    startAt = 0,
    limit = 50
  } = {}) {
    const { data } = await this.http.get('/groups', {
      params: {
        workspaceId,
        searchString,
        groupIds,
        userAccountIds,
        startAt,
        limit
      }
    })

    return data
  }

  async getUsersFromGroup({
    workspaceId,
    groupId,
    searchString = null,
    accountIds = [],
    synced = false,
    startAt = 0,
    limit = 50
  } = {}) {
    const { data } = await this.http.get('/users', {
      params: {
        workspaceId,
        groupIds: [groupId],
        searchString,
        accountIds,
        synced,
        startAt,
        limit
      }
    })

    return data
  }

  async getInnerUsers({ workspaceId = null, searchString = null, startAt = 0, limit = 50 } = {}) {
    const { data } = await this.http.get('/users/platform', {
      params: {
        workspaceId,
        searchString,
        startAt,
        limit
      }
    })

    return data
  }

  async createGroup({ name = '', workspaceId = null } = {}) {
    const { data } = await this.http.post('/group', {
      name,
      workspaceId
    })
    return data
  }

  async renameGroup({ id = null, name = '', workspaceId = null } = {}) {
    const { data } = await this.http.put('/group', {
      id,
      name,
      workspaceId
    })
    return data
  }

  async addUsersToGroup({
    id = null,
    platformGroupIds = [],
    platformUserAccountIds = [],
    workspaceId = null
  } = {}) {
    const { data } = await this.http.post('/group/addUsersAndPlatformGroups', {
      id,
      platformUserAccountIds,
      platformGroupIds,
      workspaceId
    })
    return data
  }

  async bulkAddUserToGroup({
    workspaceId = null,
    sourcePlatformGroupIds = [],
    targetPluginGroupId = [],
    keepSynced = false
  } = {}) {
    const { data } = await this.http.post('/user/bulk', {
      workspaceId,
      sourcePlatformGroupIds,
      targetPluginGroupIds: [targetPluginGroupId],
      keepSynced
    })
    return data
  }

  async removeGroup({ id = null, workspaceId } = {}) {
    const { data } = await this.http.delete('/group', {
      data: {
        id,
        workspaceId
      }
    })
    return data
  }
}

export default WorkspaceGroupsApiHandler
