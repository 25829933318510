<template>
  <AppIcon
    v-tippy="{
      content: $t('global_groups.unavailable_group')
    }"
    class="ug-Group"
    height="24"
    icon-name="lock-next"
    width="24"
  />
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'UnavailableGroup'
})
</script>

<script setup>
import AppIcon from '@/components/ui/AppIcon/AppIcon'
</script>

<style lang="scss" scoped>
.ug-Group {
  flex-shrink: 0;
  color: var(--color, #{$dark-3});
}
</style>
