import { isEmpty, isNull } from 'lodash'

export const UNCONTRIBUTE_DEFAULT_WEIGHT_MULTIPLIER = 0
export const CONTRIBUTE_DEFAULT_WEIGHT_MULTIPLIER = 1

export const isAllNestedItemsWeightsDimmed = ({ weightIsManual = false, nestedItems = [] }) => {
  if (isEmpty(nestedItems)) {
    return false
  } else {
    const simplifiedState = nestedItems.map(({ weight, weightMultiplier, contribute }) => {
      return {
        weight,
        weightMultiplier,
        contribute
      }
    })

    if (weightIsManual) {
      return simplifiedState.every(({ weightMultiplier, contribute }) => {
        return contribute && weightMultiplier === CONTRIBUTE_DEFAULT_WEIGHT_MULTIPLIER
      })
    } else {
      return simplifiedState.every(({ weight, weightMultiplier, contribute }) => {
        return isNull(weight) && isNull(weightMultiplier) && contribute
      })
    }
  }
}
