import ApiHandler from '@/api/base'
import { ADMIN_ROLES } from '@/utils/admin-roles'
import { WORKSPACE_USER_ROLES } from '@/utils/objectives'

class RolesApiHandler extends ApiHandler {
  constructor() {
    super()

    this.roles = [
      { value: 0, label: 'roles.not_selected' },
      {
        value: WORKSPACE_USER_ROLES.WORKSPACE_ADMIN,
        label: 'roles.workspace_admin',
        trackingName: 'workspace admin'
      },
      {
        value: WORKSPACE_USER_ROLES.TEAM_LEAD,
        label: 'roles.group_leader',
        trackingName: 'group leader'
      },
      { value: WORKSPACE_USER_ROLES.USER, label: 'roles.user', trackingName: 'user' },
      {
        value: WORKSPACE_USER_ROLES.USER_NO_DELETE,
        label: 'roles.user_no_delete',
        trackingName: 'user/no delete'
      },
      { value: WORKSPACE_USER_ROLES.VIEWER, label: 'roles.read_only', trackingName: 'reader' },
      { value: WORKSPACE_USER_ROLES.NO_ACCESS, label: 'roles.suspended', trackingName: 'suspended' }
    ]

    this.adminRoles = [
      {
        value: ADMIN_ROLES.ADMIN,
        label: 'roles.admin'
      },
      {
        value: ADMIN_ROLES.OWNER,
        label: 'roles.owner'
      }
    ]
  }

  async getRoles() {
    return this.roles
  }

  async getAdminRoles() {
    return this.adminRoles
  }
}

export default RolesApiHandler
