import { computed, ref, unref } from 'vue'

import { isStringEmpty } from '@/utils/general'
import { saveDevAccessToken, saveDevRefreshToken } from '@/utils/web-app/tokens-helpers'
import { IS_DEVELOPMENT } from '@root/app-modes'

export const DEV_ORGANIZATION_ID_LS_KEY = 'DEV_ORGANIZATION_ID'

export const getLastDevOrganizationId = () => {
  const devOrganizationId = localStorage.getItem(DEV_ORGANIZATION_ID_LS_KEY)
  return devOrganizationId || process.env.VUE_APP_DEFAULT_DEV_ORGANIZATION_ID
}

export const saveDevOrganizationId = (id, saveFromTemplateOptions = false) => {
  if (saveFromTemplateOptions) {
    localStorage.setItem(
      DEV_ORGANIZATION_ID_LS_KEY,
      process.env.VUE_APP_DEFAULT_DEV_ORGANIZATION_ID
    )
    return
  }
  localStorage.setItem(DEV_ORGANIZATION_ID_LS_KEY, id)
}
export const switchDevOrganization = ({ iss, accessToken, refreshToken }) => {
  if (IS_DEVELOPMENT) {
    saveDevOrganizationId(iss)
    saveDevAccessToken(accessToken)
    saveDevRefreshToken(refreshToken)
  }
}

export const useOrganizationName = ({ initialName = '' } = {}) => {
  const name = ref(initialName)
  const isNameValid = ref(true)
  const isNameLengthValid = ref(true)

  const removeNameErrors = () => {
    isNameValid.value = true
    isNameLengthValid.value = true
  }

  const validateName = () => {
    isNameValid.value = !isStringEmpty(unref(name))
    isNameLengthValid.value = unref(name).trim().length > 1
  }

  const errorText = computed(() => {
    if (isNameValid.value && isNameLengthValid.value) {
      return null
    } else {
      return !isNameValid.value ? 'field.required' : 'field.workspace_name'
    }
  })

  return {
    name,
    isNameValid,
    isNameLengthValid,
    validateName,
    removeNameErrors,
    errorText
  }
}
