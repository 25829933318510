import { useGtm } from '@gtm-support/vue-gtm'

class GtmTracker {
  constructor() {}

  init({ instanceId = null, email = 'unknown' } = {}) {
    this.gtm = useGtm()
    this.instanceId = instanceId
    this.email = email
    this.isInitialized = true
  }

  logEvent(eventName, props = {}) {
    if (!this.isInitialized) return
    if (!eventName) return
    if (!this.gtm) return

    this.gtm.trackEvent({
      event: eventName,
      instance_id: this.instanceId,
      email: this.email,
      ...props
    })
  }
}

export const gtmTracker = new GtmTracker()
