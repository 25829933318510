import { OKR_ELEMENT_PERMISSIONS } from '@/utils/objectives'

const { READ, UPDATE, REMOVE, COPY } = OKR_ELEMENT_PERMISSIONS

export const users = [
  {
    accountId: '557058:77842674-0057-406d-bc21-1c7e9ad1df69',
    name: '557058:77842674-0057-406d-bc21-1c7e9ad1df69',
    key: '',
    html: 'Viacheslav Matsenko',
    displayName: 'Viacheslav Matsenko',
    avatarUrl:
      'https://secure.gravatar.com/avatar/737570dbd52ad4d742f7b56015d33e8b?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FVM-5.png&size=24&s=24',
    email: '',
    accountType: 'atlassian',
    groupId: 9213,
    userName: '557058:77842674-0057-406d-bc21-1c7e9ad1df69'
  },
  {
    accountId: '5bc7594642daa474bc92c19e',
    name: '5bc7594642daa474bc92c19e',
    key: '',
    html: 'Anton Ignatov',
    displayName: 'Anton Ignatov',
    avatarUrl:
      'https://secure.gravatar.com/avatar/9c92bd4445e598e729dae1c8402af47c?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FAI-5.png&size=24&s=24',
    email: '',
    accountType: 'atlassian',
    groupId: 9216,
    userName: '5bc7594642daa474bc92c19e'
  },
  {
    accountId: '557058:3153c60c-3976-4aae-bd57-904f8904e553',
    name: '557058:3153c60c-3976-4aae-bd57-904f8904e553',
    key: '',
    html: 'Vladyslav Hnatiuk',
    displayName: 'Vladyslav Hnatiuk',
    avatarUrl:
      'https://secure.gravatar.com/avatar/f9bd55e4c945f98f25a4b3b8b1340535?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FVH-3.png&size=24&s=24',
    email: '',
    accountType: 'atlassian',
    groupId: 8462,
    userName: '557058:3153c60c-3976-4aae-bd57-904f8904e553'
  }
]

export const groups = [
  {
    id: 9213,
    name: 'IT Operations',
    description: ''
  },
  {
    id: 8462,
    name: 'No Group',
    description: ''
  },
  {
    id: 9217,
    name: 'Product management',
    description: ''
  },
  {
    id: 9216,
    name: 'QA',
    description: ''
  }
]

export const intervals = [
  {
    id: 4066,
    name: 'Future (backlog)',
    startDate: '2019-11-08T18:12:05.000Z',
    endDate: '2019-11-08T18:12:05.000Z',
    open: true,
    backlog: true
  },
  {
    id: 4068,
    name: 'Q1 2020',
    startDate: '2020-01-01T00:00:00.000Z',
    endDate: '2020-03-31T00:00:00.000Z',
    open: true,
    backlog: false
  },
  {
    id: 8523,
    name: '1234a',
    startDate: '2020-03-31T22:00:00.000Z',
    endDate: '2020-06-30T21:59:59.000Z',
    open: true,
    backlog: false
  },
  {
    id: 4069,
    name: 'Q2 2020',
    startDate: '2020-04-01T00:00:00.000Z',
    endDate: '2020-06-30T00:00:00.000Z',
    open: true,
    backlog: false
  },
  {
    id: 9576,
    name: 'aa1',
    startDate: '2020-06-30T22:00:00.000Z',
    endDate: '2020-09-30T21:59:59.000Z',
    open: true,
    backlog: false
  },
  {
    id: 9983,
    name: '234234',
    startDate: '2020-06-30T22:00:00.000Z',
    endDate: '2020-09-30T21:59:59.000Z',
    open: true,
    backlog: false
  },
  {
    id: 4070,
    name: 'Q3 2020',
    startDate: '2020-07-01T00:00:00.000Z',
    endDate: '2020-09-30T00:00:00.000Z',
    open: true,
    backlog: false
  }
]

export const objectives = [
  {
    type: 'TEAM_OBJECTIVE',
    objective: {
      workspaceId: 3,
      workspaceKey: 'MW',
      id: 71348,
      parentObjectiveId: null,
      name: 't1',
      displayId: 'MW-O-4',
      description: '',
      ownerId: '5bc7594642daa474bc92c19e',
      ownerDisplayName: 'Anton Ignatov',
      ownerName: '5bc7594642daa474bc92c19e',
      ownerAvatarUrl:
        'https://avatar-management--avatars.us-west-2.prod.public.atl-paas.net/5bc356c363cbaa729c4c4f88/adeb3422-affa-4b5a-b15a-43e331c82764/24',
      typeId: 2,
      statusId: 1,
      createDate: '2020-04-28T15:48:35.000Z',
      lastUpdateDate: '2020-04-28T15:48:35.000Z',
      grade: 88,
      gradeColor: 'grade-green',
      innerId: 223,
      childCount: 7,
      expanded: true,
      tasks: [
        {
          id: 16697,
          workspaceId: 3,
          workspaceKey: 'MW',
          ownerId: '557058:77842674-0057-406d-bc21-1c7e9ad1df69',
          ownerDisplayName: 'Viacheslav Matsenko',
          ownerName: '557058:77842674-0057-406d-bc21-1c7e9ad1df69',
          ownerAvatarUrl:
            'https://avatar-management--avatars.us-west-2.prod.public.atl-paas.net/5bc356c363cbaa729c4c4f88/adeb3422-affa-4b5a-b15a-43e331c82764/24',
          jiraIssueId: 'DEV-4',
          grade: 0,
          name: 'DEV-4 Epic issue',
          level: 0,
          innerId: 541,
          objectiveId: 71348,
          gradeColor: 'grade-red',
          permissions: [REMOVE, UPDATE],
          contribute: true,
          iss: 'c78f8472-5cd9-3a2c-bb79-16373ac2dd92',
          issueType: 'Epic',
          orderValue: 5,
          weight: null,
          weightMultiplier: null,
          groupId: 9213,
          groupName: 'IT Operations',
          jiraStatus: 'In Progress',
          jiraStatusColor: {
            text: '#0747a6',
            background: '#deebff'
          },
          dueDate: '2020-12-22T14:18:06.573Z'
        }
      ],
      keyResults: [],
      childObjectives: [
        {
          id: 71351,
          workspaceId: 3,
          workspaceKey: 'MW',
          parentObjectiveId: 71348,
          displayId: 'MW-O-6',
          name: 'kr2',
          description: '',
          ownerId: '557058:77842674-0057-406d-bc21-1c7e9ad1df69',
          ownerDisplayName: 'Viacheslav Matsenko',
          ownerName: '557058:77842674-0057-406d-bc21-1c7e9ad1df69',
          ownerAvatarUrl:
            'https://avatar-management--avatars.us-west-2.prod.public.atl-paas.net/5bc356c363cbaa729c4c4f88/adeb3422-affa-4b5a-b15a-43e331c82764/24',
          typeId: 4,
          statusId: 1,
          createDate: '2020-04-28T15:50:00.000Z',
          lastUpdateDate: '2020-04-28T15:50:00.000Z',
          grade: 21,
          gradeColor: 'grade-red',
          innerId: 540,
          childCount: 0,
          expanded: false,
          tasks: [],
          keyResults: [],
          childObjectives: [],
          parentObjectives: [],
          permissions: [READ, REMOVE, UPDATE],
          level: 1,
          iss: 'c78f8472-5cd9-3a2c-bb79-16373ac2dd92',
          contribute: true,
          existContributeChildren: false,
          originalValue: 0,
          currentValue: 21,
          targetValue: 100,
          intervalId: 4069,
          intervalName: 'Q3 2020',
          orderValue: 1,
          weight: null,
          weightMultiplier: null,
          fieldTypeId: 2,
          groupId: 9213,
          groupName: 'IT Operations'
        },
        {
          id: 73887,
          workspaceId: 3,
          workspaceKey: 'MW',
          displayId: 'MW-O-7',
          parentObjectiveId: 71348,
          name: 'bin',
          description: '',
          ownerId: '557058:77842674-0057-406d-bc21-1c7e9ad1df69',
          ownerDisplayName: 'Viacheslav Matsenko',
          ownerName: '557058:77842674-0057-406d-bc21-1c7e9ad1df69',
          ownerAvatarUrl:
            'https://avatar-management--avatars.us-west-2.prod.public.atl-paas.net/5bc356c363cbaa729c4c4f88/adeb3422-affa-4b5a-b15a-43e331c82764/24',
          typeId: 4,
          statusId: 1,
          createDate: '2020-05-09T16:01:44.000Z',
          lastUpdateDate: '2020-05-09T16:01:44.000Z',
          grade: 100,
          gradeColor: 'grade-green',
          innerId: 582,
          childCount: 0,
          expanded: false,
          tasks: [],
          keyResults: [],
          childObjectives: [],
          parentObjectives: [],
          permissions: [READ, REMOVE, UPDATE],
          level: 1,
          iss: 'c78f8472-5cd9-3a2c-bb79-16373ac2dd92',
          contribute: true,
          existContributeChildren: false,
          originalValue: 0,
          currentValue: 1,
          targetValue: 1,
          intervalId: 4069,
          intervalName: 'Q3 2020',
          orderValue: 0,
          weight: null,
          weightMultiplier: null,
          fieldTypeId: 3,
          groupId: 9213,
          groupName: 'IT Operations'
        },
        {
          id: 73888,
          workspaceId: 3,
          workspaceKey: 'MW',
          displayId: 'MW-O-9',
          parentObjectiveId: 71348,
          name: 'num',
          description: '',
          ownerId: '557058:77842674-0057-406d-bc21-1c7e9ad1df69',
          ownerDisplayName: 'Viacheslav Matsenko',
          ownerName: '557058:77842674-0057-406d-bc21-1c7e9ad1df69',
          ownerAvatarUrl:
            'https://avatar-management--avatars.us-west-2.prod.public.atl-paas.net/5bc356c363cbaa729c4c4f88/adeb3422-affa-4b5a-b15a-43e331c82764/24',
          typeId: 4,
          statusId: 1,
          createDate: '2020-05-09T16:03:46.000Z',
          lastUpdateDate: '2020-05-09T16:03:46.000Z',
          grade: 320,
          gradeColor: 'grade-green',
          innerId: 583,
          childCount: 0,
          expanded: false,
          tasks: [],
          keyResults: [],
          childObjectives: [],
          parentObjectives: [],
          permissions: [READ, REMOVE, UPDATE],
          level: 1,
          iss: 'c78f8472-5cd9-3a2c-bb79-16373ac2dd92',
          contribute: true,
          existContributeChildren: false,
          originalValue: 0,
          currentValue: 320,
          targetValue: 100,
          intervalId: 4069,
          intervalName: 'Q3 2020',
          orderValue: 3,
          weight: null,
          weightMultiplier: null,
          fieldTypeId: 1,
          groupId: 9213,
          groupName: 'IT Operations'
        },
        {
          id: 73889,
          workspaceId: 3,
          workspaceKey: 'MW',
          displayId: 'MW-O-1',
          parentObjectiveId: 71348,
          name: 'percent',
          description: '',
          ownerId: '557058:77842674-0057-406d-bc21-1c7e9ad1df69',
          ownerDisplayName: 'Viacheslav Matsenko',
          ownerName: '557058:77842674-0057-406d-bc21-1c7e9ad1df69',
          ownerAvatarUrl:
            'https://avatar-management--avatars.us-west-2.prod.public.atl-paas.net/5bc356c363cbaa729c4c4f88/adeb3422-affa-4b5a-b15a-43e331c82764/24',
          typeId: 4,
          statusId: 1,
          createDate: '2020-05-09T16:08:48.000Z',
          lastUpdateDate: '2020-05-09T16:08:48.000Z',
          grade: 0,
          gradeColor: 'grade-red',
          innerId: 584,
          childCount: 0,
          expanded: false,
          tasks: [],
          keyResults: [],
          childObjectives: [],
          parentObjectives: [],
          permissions: [READ, REMOVE, UPDATE],
          level: 1,
          iss: 'c78f8472-5cd9-3a2c-bb79-16373ac2dd92',
          contribute: true,
          existContributeChildren: false,
          originalValue: 0,
          currentValue: 0,
          targetValue: 100,
          intervalId: 4069,
          intervalName: 'Q3 2020',
          orderValue: 4,
          weight: null,
          weightMultiplier: null,
          fieldTypeId: 2,
          groupId: 9213,
          groupName: 'IT Operations'
        },
        {
          id: 71349,
          workspaceId: 3,
          workspaceKey: 'MW',
          displayId: 'MW-O-2',
          parentObjectiveId: 71348,
          name: 't2 - my',
          description: '',
          ownerId: '557058:77842674-0057-406d-bc21-1c7e9ad1df69',
          ownerDisplayName: 'Viacheslav Matsenko',
          ownerName: '557058:77842674-0057-406d-bc21-1c7e9ad1df69',
          ownerAvatarUrl:
            'https://avatar-management--avatars.us-west-2.prod.public.atl-paas.net/5bc356c363cbaa729c4c4f88/adeb3422-affa-4b5a-b15a-43e331c82764/24',
          typeId: 2,
          statusId: 1,
          createDate: '2020-04-28T15:48:56.000Z',
          lastUpdateDate: '2020-04-28T15:48:56.000Z',
          grade: 0,
          gradeColor: 'grade-red',
          innerId: 224,
          childCount: 1,
          expanded: true,
          tasks: [],
          keyResults: [],
          childObjectives: [
            {
              id: 71350,
              workspaceId: 3,
              workspaceKey: 'MW',
              displayId: 'MW-O-12',
              parentObjectiveId: 71349,
              name: 'kr1 ',
              description: '',
              ownerId: '557058:77842674-0057-406d-bc21-1c7e9ad1df69',
              ownerDisplayName: 'Viacheslav Matsenko',
              ownerName: '557058:77842674-0057-406d-bc21-1c7e9ad1df69',
              ownerAvatarUrl:
                'https://avatar-management--avatars.us-west-2.prod.public.atl-paas.net/5bc356c363cbaa729c4c4f88/adeb3422-affa-4b5a-b15a-43e331c82764/24',
              typeId: 4,
              statusId: 1,
              createDate: '2020-04-28T15:49:29.000Z',
              lastUpdateDate: '2020-04-28T15:49:29.000Z',
              grade: 0,
              gradeColor: 'grade-red',
              innerId: 539,
              childCount: 0,
              expanded: false,
              tasks: [],
              keyResults: [],
              childObjectives: [],
              parentObjectives: [],
              permissions: [READ, REMOVE, UPDATE],
              level: 2,
              iss: 'c78f8472-5cd9-3a2c-bb79-16373ac2dd92',
              contribute: true,
              existContributeChildren: false,
              originalValue: 0,
              currentValue: 0,
              targetValue: 100,
              intervalId: 4069,
              intervalName: 'Q3 2020',
              orderValue: 0,
              weight: null,
              weightMultiplier: null,
              fieldTypeId: 2,
              groupId: 9213,
              groupName: 'IT Operations'
            }
          ],
          parentObjectives: [],
          permissions: [READ, REMOVE, UPDATE, COPY],
          level: 1,
          iss: 'c78f8472-5cd9-3a2c-bb79-16373ac2dd92',
          contribute: false,
          existContributeChildren: false,
          originalValue: null,
          currentValue: null,
          targetValue: null,
          intervalId: 4069,
          intervalName: 'Q3 2020',
          orderValue: 2,
          weight: null,
          weightMultiplier: null,
          fieldTypeId: 1,
          groupId: 9213,
          groupName: 'IT Operations'
        }
      ],
      parentObjectives: [],
      permissions: [
        // READ
        REMOVE,
        UPDATE,
        COPY
      ],
      level: 0,
      iss: 'c78f8472-5cd9-3a2c-bb79-16373ac2dd92',
      contribute: false,
      existContributeChildren: false,
      originalValue: null,
      currentValue: null,
      targetValue: null,
      intervalId: 4069,
      intervalName: 'Q3 2020',
      orderValue: 0,
      weight: null,
      weightMultiplier: null,
      fieldTypeId: 1,
      groupId: 9216,
      groupName: 'QA',
      dueDate: '2020-12-22T14:18:06.573Z'
    },
    task: null,
    expanded: true,
    childCount: 7,
    items: [
      {
        type: 'KR',
        objective: {
          id: 73887,
          workspaceId: 3,
          workspaceKey: 'MW',
          displayId: 'MW-O-14',
          parentObjectiveId: 71348,
          name: 'bin',
          description: '',
          ownerId: '557058:77842674-0057-406d-bc21-1c7e9ad1df69',
          ownerDisplayName: 'Viacheslav Matsenko',
          ownerName: '557058:77842674-0057-406d-bc21-1c7e9ad1df69',
          ownerAvatarUrl:
            'https://avatar-management--avatars.us-west-2.prod.public.atl-paas.net/5bc356c363cbaa729c4c4f88/adeb3422-affa-4b5a-b15a-43e331c82764/24',
          typeId: 4,
          statusId: 1,
          createDate: '2020-05-09T16:01:44.000Z',
          lastUpdateDate: '2020-05-09T16:01:44.000Z',
          grade: 100,
          gradeColor: 'grade-green',
          innerId: 582,
          childCount: 0,
          expanded: false,
          tasks: [],
          keyResults: [],
          childObjectives: [],
          parentObjectives: [],
          permissions: [READ, REMOVE, UPDATE],
          level: 1,
          iss: 'c78f8472-5cd9-3a2c-bb79-16373ac2dd92',
          contribute: true,
          existContributeChildren: false,
          originalValue: 0,
          currentValue: 1,
          targetValue: 1,
          intervalId: 4069,
          intervalName: 'Q3 2020',
          orderValue: 0,
          weight: null,
          weightMultiplier: null,
          fieldTypeId: 3,
          groupId: 9213,
          groupName: 'IT Operations'
        },
        task: null,
        expanded: false,
        childCount: 0,
        items: [],
        orderValue: 0
      },
      {
        type: 'KR',
        objective: {
          id: 71351,
          parentObjectiveId: 71348,
          name: 'kr2',
          displayId: 'KR-O-2',
          description: '',
          ownerId: '557058:77842674-0057-406d-bc21-1c7e9ad1df69',
          ownerDisplayName: 'Viacheslav Matsenko',
          ownerName: '557058:77842674-0057-406d-bc21-1c7e9ad1df69',
          ownerAvatarUrl:
            'https://avatar-management--avatars.us-west-2.prod.public.atl-paas.net/5bc356c363cbaa729c4c4f88/adeb3422-affa-4b5a-b15a-43e331c82764/24',
          typeId: 4,
          statusId: 1,
          createDate: '2020-04-28T15:50:00.000Z',
          lastUpdateDate: '2020-04-28T15:50:00.000Z',
          grade: 21,
          gradeColor: 'grade-red',
          innerId: 540,
          childCount: 0,
          expanded: false,
          tasks: [],
          keyResults: [],
          childObjectives: [],
          parentObjectives: [],
          permissions: [REMOVE, UPDATE],
          level: 1,
          iss: 'c78f8472-5cd9-3a2c-bb79-16373ac2dd92',
          contribute: true,
          existContributeChildren: false,
          originalValue: 0,
          currentValue: 21,
          targetValue: 100,
          intervalId: 4069,
          intervalName: 'Q3 2020',
          orderValue: 1,
          weight: null,
          weightMultiplier: null,
          fieldTypeId: 2,
          groupId: 9213,
          groupName: 'IT Operations'
        },
        task: null,
        expanded: false,
        childCount: 0,
        items: [],
        orderValue: 1
      },
      {
        type: 'TEAM_OBJECTIVE',
        objective: {
          id: 71349,
          workspaceId: 3,
          workspaceKey: 'MW',
          displayId: 'MW-O-15',
          parentObjectiveId: 71348,
          name: 't2 - my',
          description: '',
          ownerId: '557058:77842674-0057-406d-bc21-1c7e9ad1df69',
          ownerDisplayName: 'Viacheslav Matsenko',
          ownerName: '557058:77842674-0057-406d-bc21-1c7e9ad1df69',
          ownerAvatarUrl:
            'https://avatar-management--avatars.us-west-2.prod.public.atl-paas.net/5bc356c363cbaa729c4c4f88/adeb3422-affa-4b5a-b15a-43e331c82764/24',
          typeId: 2,
          statusId: 1,
          createDate: '2020-04-28T15:48:56.000Z',
          lastUpdateDate: '2020-04-28T15:48:56.000Z',
          grade: 0,
          gradeColor: 'grade-red',
          innerId: 224,
          childCount: 1,
          expanded: true,
          tasks: [],
          keyResults: [],
          childObjectives: [
            {
              id: 71350,
              workspaceId: 3,
              workspaceKey: 'MW',
              displayId: 'MW-O-16',
              parentObjectiveId: 71349,
              name: 'kr1 ',
              description: '',
              ownerId: '557058:77842674-0057-406d-bc21-1c7e9ad1df69',
              ownerDisplayName: 'Viacheslav Matsenko',
              ownerName: '557058:77842674-0057-406d-bc21-1c7e9ad1df69',
              ownerAvatarUrl:
                'https://avatar-management--avatars.us-west-2.prod.public.atl-paas.net/5bc356c363cbaa729c4c4f88/adeb3422-affa-4b5a-b15a-43e331c82764/24',
              typeId: 4,
              statusId: 1,
              createDate: '2020-04-28T15:49:29.000Z',
              lastUpdateDate: '2020-04-28T15:49:29.000Z',
              grade: 0,
              gradeColor: 'grade-red',
              innerId: 539,
              childCount: 0,
              expanded: false,
              tasks: [],
              keyResults: [],
              childObjectives: [],
              parentObjectives: [],
              permissions: [READ, REMOVE, UPDATE],
              level: 2,
              iss: 'c78f8472-5cd9-3a2c-bb79-16373ac2dd92',
              contribute: true,
              existContributeChildren: false,
              originalValue: 0,
              currentValue: 0,
              targetValue: 100,
              intervalId: 4069,
              intervalName: 'Q3 2020',
              orderValue: 0,
              weight: null,
              weightMultiplier: null,
              fieldTypeId: 2,
              groupId: 9213,
              groupName: 'IT Operations'
            }
          ],
          parentObjectives: [],
          permissions: [READ, REMOVE, UPDATE, COPY],
          level: 1,
          iss: 'c78f8472-5cd9-3a2c-bb79-16373ac2dd92',
          contribute: false,
          existContributeChildren: false,
          originalValue: null,
          currentValue: null,
          targetValue: null,
          intervalId: 4069,
          intervalName: 'Q3 2020',
          orderValue: 2,
          weight: null,
          weightMultiplier: null,
          fieldTypeId: 1,
          groupId: 9213,
          groupName: 'IT Operations'
        },
        task: null,
        expanded: true,
        childCount: 1,
        items: [
          {
            type: 'KR',
            objective: {
              id: 71350,
              workspaceId: 3,
              workspaceKey: 'MW',
              displayId: 'MW-O-17',
              parentObjectiveId: 71349,
              name: 'kr1 ',
              description: '',
              ownerId: '557058:77842674-0057-406d-bc21-1c7e9ad1df69',
              ownerDisplayName: 'Viacheslav Matsenko',
              ownerName: '557058:77842674-0057-406d-bc21-1c7e9ad1df69',
              ownerAvatarUrl:
                'https://avatar-management--avatars.us-west-2.prod.public.atl-paas.net/5bc356c363cbaa729c4c4f88/adeb3422-affa-4b5a-b15a-43e331c82764/24',
              typeId: 4,
              statusId: 1,
              createDate: '2020-04-28T15:49:29.000Z',
              lastUpdateDate: '2020-04-28T15:49:29.000Z',
              grade: 0,
              gradeColor: 'grade-red',
              innerId: 539,
              childCount: 0,
              expanded: false,
              tasks: [],
              keyResults: [],
              childObjectives: [],
              parentObjectives: [],
              permissions: [READ, REMOVE, UPDATE],
              level: 2,
              iss: 'c78f8472-5cd9-3a2c-bb79-16373ac2dd92',
              contribute: true,
              existContributeChildren: false,
              originalValue: 0,
              currentValue: 0,
              targetValue: 100,
              intervalId: 4069,
              intervalName: 'Q3 2020',
              orderValue: 0,
              weight: null,
              weightMultiplier: null,
              fieldTypeId: 2,
              groupId: 9213,
              groupName: 'IT Operations'
            },
            task: null,
            expanded: false,
            childCount: 0,
            items: [],
            orderValue: 0
          }
        ],
        orderValue: 2
      },
      {
        type: 'KR',
        objective: {
          id: 73888,
          workspaceId: 3,
          workspaceKey: 'MW',
          displayId: 'MW-O-18',
          parentObjectiveId: 71348,
          name: 'num',
          description: '',
          ownerId: '557058:77842674-0057-406d-bc21-1c7e9ad1df69',
          ownerDisplayName: 'Viacheslav Matsenko',
          ownerName: '557058:77842674-0057-406d-bc21-1c7e9ad1df69',
          ownerAvatarUrl:
            'https://avatar-management--avatars.us-west-2.prod.public.atl-paas.net/5bc356c363cbaa729c4c4f88/adeb3422-affa-4b5a-b15a-43e331c82764/24',
          typeId: 4,
          statusId: 1,
          createDate: '2020-05-09T16:03:46.000Z',
          lastUpdateDate: '2020-05-09T16:03:46.000Z',
          grade: 320,
          gradeColor: 'grade-green',
          innerId: 583,
          childCount: 0,
          expanded: false,
          tasks: [],
          keyResults: [],
          childObjectives: [],
          parentObjectives: [],
          permissions: [READ, REMOVE, UPDATE],
          level: 1,
          iss: 'c78f8472-5cd9-3a2c-bb79-16373ac2dd92',
          contribute: true,
          existContributeChildren: false,
          originalValue: 0,
          currentValue: 320,
          targetValue: 100,
          intervalId: 4069,
          intervalName: 'Q3 2020',
          orderValue: 3,
          weight: null,
          weightMultiplier: null,
          fieldTypeId: 1,
          groupId: 9213,
          groupName: 'IT Operations'
        },
        task: null,
        expanded: false,
        childCount: 0,
        items: [],
        orderValue: 3
      },
      {
        type: 'KR',
        objective: {
          id: 73889,
          workspaceId: 3,
          workspaceKey: 'MW',
          displayId: 'MW-O-19',
          parentObjectiveId: 71348,
          name: 'percent',
          description: '',
          ownerId: '557058:77842674-0057-406d-bc21-1c7e9ad1df69',
          ownerDisplayName: 'Viacheslav Matsenko',
          ownerName: '557058:77842674-0057-406d-bc21-1c7e9ad1df69',
          ownerAvatarUrl:
            'https://avatar-management--avatars.us-west-2.prod.public.atl-paas.net/5bc356c363cbaa729c4c4f88/adeb3422-affa-4b5a-b15a-43e331c82764/24',
          typeId: 4,
          statusId: 1,
          createDate: '2020-05-09T16:08:48.000Z',
          lastUpdateDate: '2020-05-09T16:08:48.000Z',
          grade: 0,
          gradeColor: 'grade-red',
          innerId: 584,
          childCount: 0,
          expanded: false,
          tasks: [],
          keyResults: [],
          childObjectives: [],
          parentObjectives: [],
          permissions: [READ, REMOVE, UPDATE],
          level: 1,
          iss: 'c78f8472-5cd9-3a2c-bb79-16373ac2dd92',
          contribute: true,
          existContributeChildren: false,
          originalValue: 0,
          currentValue: 0,
          targetValue: 100,
          intervalId: 4069,
          intervalName: 'Q3 2020',
          orderValue: 4,
          weight: null,
          weightMultiplier: null,
          fieldTypeId: 2,
          groupId: 9213,
          groupName: 'IT Operations'
        },
        task: null,
        expanded: false,
        childCount: 0,
        items: [],
        orderValue: 4
      },
      {
        type: 'TASK',
        objective: null,
        task: {
          id: 16697,
          workspaceId: 3,
          workspaceKey: 'MW',
          displayId: 'MW-O-22',
          ownerId: '557058:77842674-0057-406d-bc21-1c7e9ad1df69',
          ownerDisplayName: 'Viacheslav Matsenko',
          ownerName: '557058:77842674-0057-406d-bc21-1c7e9ad1df69',
          ownerAvatarUrl:
            'https://avatar-management--avatars.us-west-2.prod.public.atl-paas.net/5bc356c363cbaa729c4c4f88/adeb3422-affa-4b5a-b15a-43e331c82764/24',
          jiraIssueId: 'DEV-4',
          grade: 0,
          name: 'DEV-4 Epic issue',
          level: 0,
          innerId: 541,
          objectiveId: 71348,
          gradeColor: 'grade-red',
          permissions: [READ, REMOVE, UPDATE],
          contribute: true,
          iss: 'c78f8472-5cd9-3a2c-bb79-16373ac2dd92',
          issueType: 'Epic',
          orderValue: 5,
          weight: null,
          weightMultiplier: null,
          groupId: 9213,
          groupName: 'IT Operations',
          jiraStatus: 'In Progress',
          jiraStatusColor: {
            text: '#0747a6',
            background: '#deebff'
          }
        },
        expanded: false,
        childCount: 0,
        items: [],
        orderValue: 5
      }
    ],
    orderValue: 0
  }
]

export const workspaces = [
  {
    id: 6,
    iss: 'c78f8472-5cd9-3a2c-bb79-16373ac2dd92',
    name: 'My workspace',
    key: 'MW'
  },
  {
    id: 7,
    iss: 'c7123asd2-5cd9-3a2c-bb79-16373ac2dd92',
    name: 'Team Dream',
    key: 'TD'
  },
  {
    id: 8,
    iss: 'c7123asd2-5cd9-3a2c-bb79-1637fdasdf12',
    name: 'Oboard',
    key: 'O'
  }
]

export const parentObjectives = [
  {
    workspaceId: 3,
    workspaceKey: 'MW',
    id: 71348,
    parentObjectiveId: null,
    name: 't1',
    displayId: 'MW-O-4',
    description: '',
    ownerId: '5bc7594642daa474bc92c19e',
    ownerDisplayName: 'Anton Ignatov',
    ownerName: '5bc7594642daa474bc92c19e',
    typeId: 2,
    statusId: 1,
    createDate: '2020-04-28T15:48:35.000Z',
    lastUpdateDate: '2020-04-28T15:48:35.000Z',
    grade: 88,
    gradeColor: 'grade-green',
    innerId: 223,
    childCount: 7,
    expanded: true,
    tasks: [],
    keyResults: [],
    childObjectives: [],
    parentObjectives: [],
    permissions: [REMOVE, UPDATE, COPY],
    level: 0,
    iss: 'c78f8472-5cd9-3a2c-bb79-16373ac2dd92',
    contribute: false,
    existContributeChildren: false,
    originalValue: null,
    currentValue: null,
    targetValue: null,
    intervalId: 4069,
    intervalName: 'Q3 2020',
    orderValue: 0,
    weight: null,
    weightMultiplier: null,
    fieldTypeId: 1,
    groupId: 9216,
    groupName: 'QA'
  },
  {
    workspaceId: 1927,
    id: 101294,
    parentObjectiveId: null,
    parentTypeId: null,
    name: 't',
    description: '',
    ownerId: '5bfef7de6c48883c86379fd3',
    ownerDisplayName: null,
    ownerName: null,
    ownerAvatarUrl:
      'https://avatar-management--avatars.us-west-2.prod.public.atl-paas.net/5bc356c363cbaa729c4c4f88/adeb3422-affa-4b5a-b15a-43e331c82764/24',
    typeId: 3,
    statusId: 1,
    createDate: '2020-09-06T07:29:11.000Z',
    lastUpdateDate: '2020-09-06T07:29:11.000Z',
    grade: 0,
    gradeColor: 'grade-red',
    innerId: 1,
    childCount: 0,
    expanded: false,
    tasks: [],
    keyResults: [],
    childObjectives: [],
    parentObjectives: [],
    permissions: [READ, REMOVE, UPDATE, COPY],
    level: 0,
    iss: '732b9c02-f534-37f8-a18d-6a1f53e08479',
    contribute: false,
    existContributeChildren: false,
    originalValue: null,
    currentValue: null,
    targetValue: null,
    intervalId: 13455,
    intervalName: 'Q2 2020',
    orderValue: 0,
    weight: null,
    weightMultiplier: null,
    fieldTypeId: 1,
    groupId: 15903,
    groupName: null,
    displayId: 'UR-O-1'
  },

  {
    workspaceId: 1927,
    id: 101295,
    parentObjectiveId: null,
    parentTypeId: null,
    name: 't',
    description: '',
    ownerId: '5bfef7de6c48883c86379fd3',
    ownerDisplayName: null,
    ownerName: null,
    typeId: 3,
    statusId: 1,
    createDate: '2020-09-06T07:29:11.000Z',
    lastUpdateDate: '2020-09-06T07:29:11.000Z',
    grade: 0,
    gradeColor: 'grade-red',
    innerId: 1,
    childCount: 0,
    expanded: false,
    tasks: [],
    keyResults: [],
    childObjectives: [],
    parentObjectives: [],
    permissions: [REMOVE, UPDATE, COPY],
    level: 0,
    iss: '732b9c02-f534-37f8-a18d-6a1f53e08479',
    contribute: false,
    existContributeChildren: false,
    originalValue: null,
    currentValue: null,
    targetValue: null,
    intervalId: 13455,
    orderValue: 0,
    weight: null,
    weightMultiplier: null,
    fieldTypeId: 1,
    groupId: 15903,
    groupName: null,
    displayId: 'UK-O-1'
  }
]

export const childObjectives = [
  {
    type: 'TEAM_OBJECTIVE',
    objective: {
      workspaceId: 12,
      workspaceKey: 'TT',
      id: 3525,
      parentObjectiveId: 3524,
      parentTypeId: null,
      name: 'ttt',
      description: '',
      ownerId: '557058:3153c60c-3976-4aae-bd57-904f8904e553',
      ownerDisplayName: 'Vladyslav Hnatiuk',
      ownerName: '557058:3153c60c-3976-4aae-bd57-904f8904e553',
      typeId: 2,
      statusId: 1,
      createDate: '2020-08-21T19:27:49.000Z',
      lastUpdateDate: '2020-08-21T19:27:49.000Z',
      grade: 100,
      gradeColor: 'grade-green',
      innerId: 3,
      childCount: 2,
      expanded: false,
      tasks: [],
      keyResults: [],
      childObjectives: [],
      parentObjectives: [],
      permissions: [READ, REMOVE, UPDATE, COPY],
      level: 1,
      iss: 'c78f8472-5cd9-3a2c-bb79-16373ac2dd92',
      contribute: false,
      existContributeChildren: false,
      originalValue: null,
      currentValue: null,
      targetValue: null,
      intervalId: 1223,
      intervalName: 'Q3 2020',
      orderValue: 0.0,
      weight: null,
      weightMultiplier: null,
      fieldTypeId: 1,
      groupId: 474,
      groupName: 'No Group',
      displayId: 'TT-O-3'
    },
    task: null,
    expanded: false,
    childCount: 2,
    items: [],
    orderValue: 0.0
  },

  {
    type: 'TEAM_OBJECTIVE',
    objective: {
      workspaceId: 12,
      workspaceKey: 'TT',
      id: 3526,
      parentObjectiveId: 3524,
      parentTypeId: null,
      name: 'ttt',
      description: '',
      ownerId: '557058:3153c60c-3976-4aae-bd57-904f8904e553',
      ownerDisplayName: 'Vladyslav Hnatiuk',
      ownerName: '557058:3153c60c-3976-4aae-bd57-904f8904e553',
      ownerAvatarUrl:
        'https://avatar-management--avatars.us-west-2.prod.public.atl-paas.net/5bc356c363cbaa729c4c4f88/adeb3422-affa-4b5a-b15a-43e331c82764/24',
      typeId: 2,
      statusId: 1,
      createDate: '2020-08-21T19:27:49.000Z',
      lastUpdateDate: '2020-08-21T19:27:49.000Z',
      grade: 100,
      gradeColor: 'grade-green',
      innerId: 3,
      childCount: 2,
      expanded: false,
      tasks: [],
      keyResults: [],
      childObjectives: [],
      parentObjectives: [],
      permissions: [REMOVE, UPDATE, COPY],
      level: 1,
      iss: 'c78f8472-5cd9-3a2c-bb79-16373ac2dd92',
      contribute: false,
      existContributeChildren: false,
      originalValue: null,
      currentValue: null,
      targetValue: null,
      intervalId: 1223,
      intervalName: 'Q3 2020',
      orderValue: 0.0,
      weight: null,
      weightMultiplier: null,
      fieldTypeId: 1,
      groupId: 474,
      groupName: 'No Group',
      displayId: 'TN-O-3'
    },
    task: null,
    expanded: false,
    childCount: 2,
    items: [],
    orderValue: 0.0
  }
]

export const jiraGroups = [
  {
    name: 'adminis',
    groupId: '7b484d33-3c13-4ba6-b01a-283909ae9f81',
    labels: [
      {
        text: 'Admin',
        title: 'Users added to this group will be given administrative access',
        type: 'ADMIN'
      },
      {
        text: 'Jira Software',
        title: 'Users added to this group will be given access to <strong>Jira Software</strong>',
        type: 'SINGLE'
      }
    ]
  },
  {
    name: 'atlassian-addons-admin',
    groupId: '52778f8e-fabb-46dc-aa5b-e1e4113ed0e3',
    labels: [
      {
        text: 'Admin',
        title: 'Users added to this group will be given administrative access',
        type: 'ADMIN'
      },
      {
        text: 'Jira Software',
        title: 'Users added to this group will be given access to <strong>Jira Software</strong>',
        type: 'SINGLE'
      }
    ]
  }
]

export const jiraUsers = [
  {
    accountId: '5bc356c363cbaa729c4c4f88',
    name: null,
    key: null,
    html: 'oboard support',
    displayName: 'oboard support',
    avatarUrl: null,
    email: null,
    accountType: 'atlassian',
    defaultTeamId: null,
    teams: null,
    userName: null
  },

  {
    accountId: '557058:3153c60c-3976-4aae-bd57-904f8904e553',
    name: null,
    key: null,
    html: 'Vladyslav Hnatiuk',
    displayName: 'Vladyslav Hnatiuk',
    avatarUrl: null,
    email: null,
    accountType: 'atlassian',
    defaultTeamId: null,
    teams: null,
    userName: null
  }
]

export const reportByUsers = [
  {
    ownerId: 10,
    avatarUrl:
      'https://secure.gravatar.com/avatar/9c92bd4445e598e729dae1c8402af47c?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FAI-5.png&size=24&s=24',
    ownerName: 'Vyacheslav Bernat',
    ownerGroups: [
      {
        groupId: 1,
        groupName: 'Design'
      }
    ],
    onTrackCount: 3,
    onTrackPercent: 80,
    behindCount: 10,
    behindPercent: 45,
    atRiskCount: null,
    atRiskPercent: null,
    averageTotalPercent: 48,
    averageTotalColor: 'green'
  },
  {
    ownerId: 11,
    avatarUrl: null,
    ownerName: 'Viktor Grekov',
    ownerGroups: [
      {
        groupId: 1,
        groupName: 'Design'
      },
      {
        groupId: 2,
        groupName: 'Sales'
      },
      {
        groupId: 3,
        groupName: 'Marketing'
      }
    ],
    onTrackCount: 1,
    onTrackPercent: 80,
    behindCount: 1,
    behindPercent: 45,
    atRiskCount: 2,
    atRiskPercent: 20,
    averageTotalPercent: 59,
    averageTotalColor: 'green'
  }
]

export const reportByGroup = [
  {
    groupId: 1,
    groupName: 'Design',
    userCount: 5,
    onTrackCount: 3,
    onTrackPercent: 80,
    behindCount: 4,
    behindPercent: 45,
    atRiskCount: 5,
    atRiskPercent: 20,
    averageTotalPercent: 48,
    averageTotalColor: 'green'
  },
  {
    groupId: 2,
    groupName: 'Sales',
    userCount: 5,
    onTrackCount: 3,
    onTrackPercent: 80,
    behindCount: 4,
    behindPercent: 45,
    atRiskCount: 5,
    atRiskPercent: 20,
    averageTotalPercent: 48,
    averageTotalColor: 'green'
  }
]

export const reportByObjectiveType = [
  {
    OKRType: 'Company',
    OKRTypeId: 3,
    userCount: 5,
    onTrackCount: 3,
    onTrackPercent: 80,
    behindCount: 4,
    behindPercent: 45,
    atRiskCount: 5,
    atRiskPercent: 20,
    averageTotalPercent: 48,
    averageTotalColor: 'green'
  },
  {
    OKRType: 'Group',
    OKRTypeId: 2,
    userCount: 5,
    onTrackCount: 3,
    onTrackPercent: 80,
    behindCount: 4,
    behindPercent: 45,
    atRiskCount: 5,
    atRiskPercent: 20,
    averageTotalPercent: 48,
    averageTotalColor: 'green'
  }
]

export const groupEngagement = [
  {
    withObjectives: true,
    ownerId: 10,
    ownerName: 'Vyacheslav Bernat',
    ownerGroups: [
      {
        groupId: 1,
        groupName: 'Design'
      }
    ]
  },
  {
    withObjectives: false,
    ownerId: 11,
    ownerName: 'Viktor Grekov',
    ownerGroups: [
      {
        groupId: 1,
        groupName: 'Design'
      },
      {
        groupId: 2,
        groupName: 'Sales'
      },
      {
        groupId: 3,
        groupName: 'Marketing'
      }
    ]
  }
]
