<template>
  <span class="act-Text oboard-truncated-text"> ∗∗∗∗∗∗∗∗∗∗∗ </span>
</template>

<script setup>
defineOptions({
  name: 'AppCipheredText'
})
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/ciphered-text';

.act-Text {
  @include cipheredText(var(--text-color, #{$dark-1}), var(--overlay-color, #{$white}));
  padding-left: var(--padding-left, 0);
}
</style>
